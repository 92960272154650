import React from 'react'
import { useState, useEffect } from 'react'
import './EditPageForm.css'
import './EditPageFormCreateNew/EditPageFormCreateNew.css'
import './CreateNewPage.css'

const EditPageForm = (props) => {
    //This is the primary content manage system for the entire site.

    const [page, setPage] = useState([])
    const [pageLoaded, setPageLoaded] = useState([]);
    const [parents, setParents] = useState([])
    const [children, setChildren] = useState([]);
    const [ourResults, setOurResults] = useState([]);
    const [activeParent, setActiveParent] = useState('');
    const [activeChild, setActiveChild] = useState('');
    const [visibleNav, setVisibleNav] = useState(false);
    const [isArchived, setIsArchived] = useState(false);
    const [primaryHeaderImage, setPrimaryHeaderImage] = useState('');
    const [pageTitle, setPageTitle] = useState('');
    const [imagePopup, setImagePopup] = useState([])
    const [createNewPageOpen, setCreateNewPageOpen] = useState(false)

    useEffect(() => {
        fetch(`http://molly.igs.indiana.edu:8080/api/nav`)
            .then(res => res.json())
            .then(
                (result) => {
                    setOurResults(result);

                    //find all navigation parents
                    result.forEach(item => {
                        if (parents.includes(item.parent)) {
                        } else {
                            let newList = parents;
                            newList.push(item.parent);
                            setParents(newList);

                        }
                    })

                })
    }, [])

    function updatePageOptions(path) {
        setActiveParent(path)
        setActiveChild("");

        fetch(`http://molly.igs.indiana.edu:8080/api/nav/fullPath${path}`)
            .then(res => res.json())
            .then(
                (result) => {
                    setChildren(result);

                })
    }

    function updatePageInfo(path) {
        children.forEach(child => {
            if (child.path === path) {
                let ourPageTitle = child.title;
                setPageTitle(ourPageTitle)
                setNewPageTitle(ourPageTitle);
                let pageHeaderImage = child.primaryImage;
                setPrimaryHeaderImage(pageHeaderImage)
                let pageContent = child.content.split(";");
                pageContent.forEach((element, index) => {
                    pageContent[index] = element.split(",");
                })
                // pageContent.forEach(element => {
                //     element = element.split(',');
                // })
                pageContent.forEach(item => {
                    if(item[1] !== undefined) {
                        item[1] = item[1].replace('###Comma###', ',')
                        item[1] = item[1].replace('###Semicolon###', ';')
                        if(item[2] !== undefined) {
                            item[2] = item[2].replace('###Comma###', ',')
                            item[2] = item[2].replace('###Semicolon###', ';')
                        }
                    }
                })
                setPage(pageContent)
                
                let currentchild = (child.path);
                setActiveChild(currentchild);

                let parent = activeParent.replace("/", "@");
                child = currentchild.replace("/", "@");


                fetch(`http://molly.igs.indiana.edu:8080/api/nav/pathInfo/${parent}/${child}`)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            let navResults = (result[0].showInNavbar);
                            if (navResults === 1) {
                                setVisibleNav(true)
                            } else {
                                setVisibleNav(false)
                            }

                            let archiveResults = (result[0].archive);
                            console.log(result[0])
                            if (archiveResults === 1) {
                                setIsArchived(true)
                            } else {
                                setIsArchived(false)
                            }

                        })
                setPageLoaded([['Loaded']]);
                setIsCreateItem(false);

            }
        })
    }

    function updateImagePopup(element, index) {
        setImagePopup([element, index])
        let oldImageLink = element[1];
        let oldImageAlt = element[2];
        setNewImageLink(oldImageLink)
        setNewImageAlt(oldImageAlt)
    }

    function closeImagePopup() {
        setImagePopup([]);
    }

    const [isCreateItem, setIsCreateItem] = useState(false)
    const [previewContent, setPreviewContent] = useState([]);
    const [contentChoice, setContentChoice] = useState(['']);

    function toggleCreateItem() {
        setIsCreateItem(!isCreateItem);
        setPreviewContent([['', '']])
        setContentChoice([''])
        document.querySelector('.cms__form__create__new').selectedIndex = 0;
    }

    function updateSelectedItemType(selected) {

        if (selected === 'Paragraph') {
            setPreviewContent([['paragraph', '']])
            setContentChoice(['paragraph'])
        }
        if (selected === 'Image') {
            setPreviewContent([['image', '', '']])
            setContentChoice(['image'])
        }
        if (selected === 'Line') {
            setPreviewContent([['line']])
            setContentChoice(['line'])
        }
        if (selected === 'Subtitle') {
            setPreviewContent([['subtitle', '']])
            setContentChoice(['subtitle'])
        }
    }

    function updatePreview(input) {
        let tempPreviewContent = previewContent[0][0];
        setPreviewContent([[tempPreviewContent, input]])
    }

    function updatePreviewAlt(input) {
        let tempPreviewContent = previewContent[0][1];
        setPreviewContent([['image', tempPreviewContent, input]]);

    }



    const [newImageLink, setNewImageLink] = useState('');
    function updateImageLink(event) {
        let newLink = (event.target.value);
        setNewImageLink(newLink);
    }


    const [newImageAlt, setNewImageAlt] = useState('');
    function updateImageAlt(event) {
        let newAlt = (event.target.value);
        setNewImageAlt(newAlt);
    }


    function updateImage(index) {
        let tempPage = page;
        tempPage[index][1] = newImageLink;
        tempPage[index][2] = newImageAlt;
        closeImagePopup();
    }

    function addNewItem() {
        page.push(previewContent[0]);
        toggleCreateItem()
    }

    function deleteItemAtIndex(index) {
        if (window.confirm('Are you sure you want to delete this item?')) {
            setPageLoaded([false]);
            let tempPage = page
            tempPage.splice((index), 1);
            setPage(tempPage);
            setPageLoaded([true]);
        }
    }

    function toggleCreateNewPage() {
        setCreateNewPageOpen(!createNewPageOpen);
        console.log(createNewPageOpen)
    }

    function createEditDeleteMoveSection(index) {
        if (page.length === 1) {
            return (<div className='edit__page__cms__subwrapper'>
                <div className='edit__page__cms__delete__item' onClick={() => deleteItemAtIndex(index)}>🗑️</div>
                <div className='edit__page__cms__move__wrapper'>
                </div>
            </div>);
        }
        if ((index === (page.length - 1))) {
            return (<div className='edit__page__cms__subwrapper'>
                <div className='edit__page__cms__delete__item' onClick={() => deleteItemAtIndex(index)}>🗑️</div>
                <div className='edit__page__cms__move__wrapper'>
                    <div className='edit__page__cms__move__up' onClick={() => swapItems(index, (index - 1))}>⬆️</div>
                </div>
            </div>)
        }
        if (index === 0) {
            return (<div className='edit__page__cms__subwrapper'>
                <div className='edit__page__cms__delete__item' onClick={() => deleteItemAtIndex(index)}>🗑️</div>
                <div className='edit__page__cms__move__wrapper'>
                    <div className='edit__page__cms__move__down' onClick={() => swapItems(index, (index + 1))}>⬇️</div>
                </div>
            </div>)
        }

        return (<div className='edit__page__cms__subwrapper'>
            <div className='edit__page__cms__delete__item' onClick={() => deleteItemAtIndex(index)}>🗑️</div>
            <div className='edit__page__cms__move__wrapper'>
                <div className='edit__page__cms__move__up' onClick={() => swapItems(index, (index - 1))}>⬆️</div>
                <div className='edit__page__cms__move__down' onClick={() => swapItems(index, (index + 1))}>⬇️</div>
            </div>
        </div>)


    }

    function handleArchive() {
        if(!isArchived === true) {
            setVisibleNav(false);
        }
        setIsArchived(!isArchived);
    }

    function handleVisibleNav() {
        if(!visibleNav === true) {
            setIsArchived(false);
        }
        setVisibleNav(!visibleNav);
    }

    function swapItems(beforeIndex, afterIndex) {
        let tempBefore = page[beforeIndex];
        let tempAfter = page[afterIndex];
        let tempPage = page;
        tempPage[beforeIndex] = tempAfter;
        tempPage[afterIndex] = tempBefore;
        setPage(tempPage);
        setPageLoaded([true]);
    }

    function editElement(index, element) {
        let tempPage = page;
        tempPage[index][1] = element.target.innerText
        setPage(tempPage);
    }

    const [newPageTitle, setNewPageTitle] = useState(pageTitle);

    function editTitle(element) {
        let tempTitle = element.target.innerText;
        setNewPageTitle(tempTitle);
    }

    function updatePage() {
        let processedPage = [...page];
        processedPage.forEach(item => {
            if(item[1] !== undefined) {
                item[1] = item[1].replace(/,/g, '###Comma###')
                item[1] = item[1].replace(/;/g, '###Semicolon###')
                if(item[2] !== undefined) {
                    item[2] = item[2].replace(/,/g, '###Comma###')
                    item[2] = item[2].replace(/;/g, '###Semicolon###')
                }
            }
        })
        let newList = "";
        processedPage.forEach((item,itemIndex) => {
            item.forEach((element, index) => {
                newList += element;
                if(index !== (item.length - 1)) {
                    newList += ','
                }
            })
            if(itemIndex !== processedPage.length - 1) {
                newList += ';'
            }
        })
        page.forEach(item => {
            if(item[1] !== undefined) {
                item[1] = item[1].replace('###Comma###', ',')
                item[1] = item[1].replace('###Semicolon###', ';')
                if(item[2] !== undefined) {
                    item[2] = item[2].replace('###Comma###', ',')
                    item[2] = item[2].replace('###Semicolon###', ';')
                }
            }
        })
        console.log(newList);
        console.log('child: '  + activeChild);
        console.log('parent: '  + activeParent);
        console.log('Visible on Nav: ' + visibleNav);
        console.log('Page Archived: ' + isArchived);
        console.log('primary header img: ' + primaryHeaderImage);
        console.log('page title: ' + newPageTitle);

    function pushNewPage() {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
            path: activeChild,
            content: newList,
            title: newPageTitle,
            primaryImage: primaryHeaderImage,
            parent: activeParent.replace("/", ""),
            archive: isArchived,
            navVisability: visibleNav})
        };
        fetch('http://molly.igs.indiana.edu:8080/api/nav/pathInfo', requestOptions)
    }

    pushNewPage()
    window.location.reload(false);


    }

    function renderCreateNewPage() {
        if(createNewPageOpen) {
            return(<div className='create__new__page__cms'>
                <div className='create__new__page__cms__content'>

                <label>Navigation Parent</label>
                <select>
                {parents.map((i, index) => {
                                return (<option key={index} value={i}>{i}</option>)
                            })}

                            </select>

                            <label>Page Link</label>
                <input type="text" placeholder='enter page link name'></input>
                <span>Warning: keep to single word</span>

                <label>Page Title</label>
                <input type="text" placeholder='enter page title'></input>

                <span>Pages will be archived by default. After adding content, unarchive to make page live</span>
                </div>

          

                <div onClick={()=>toggleCreateNewPage()} className='create__new__page__cms__background'></div>
            </div>)
        }
    }


    return (
        <div className='edit__page__form'>
            {renderCreateNewPage()}
            <div className='edit__page__settings__wrapper'>
                <div className='edit__page__settings'>

                    <div className='edit__page__form__item'>
                        <label>Navigation Parent</label>
                        <select onChange={(e) => {
                            updatePageOptions(e.target.value)
                        }} >

                            <option value="" defaultValue hidden>Choose here</option>
                            {parents.map((i, index) => {
                                return (<option key={index} value={i}>{i}</option>)
                            })}
                        </select>
                    </div>

                    <div className='edit__page__form__item'>
                        <label>Specific Page</label>
                        <select onChange={(e) => { updatePageInfo(e.target.value) }}>
                            <option value="" defaultValue hidden>Choose here</option>

                            {children.map((i, index) => {
                                return (<option key={index} value={i.path}>{i.path}</option>)
                            })}
                        </select>
                    </div>

                    <div className='edit__page__form__item'>
                        <label>Visible on main nav bar</label>
                        <input type='checkbox' value={visibleNav} checked={visibleNav} onChange={() => { handleVisibleNav() }}></input>
                    </div>

                    <div className='edit__page__form__item'>
                        <label>Archive Page</label>
                        <input type='checkbox' value={isArchived} checked={isArchived} onChange={() => { handleArchive() }}></input>
                    </div>
                </div>

            </div>

            <div className='edit__page__cms__update__upload__container'>
                <button onClick={()=>{toggleCreateNewPage()}}>Create New Page</button>
                {pageLoaded.map((index) => {
                    return (<>
                        <button onClick={() => {
                            updatePage();
                        }} key={index}>Update Page</button>
                        <button>Browse Previous Versions</button></>)
                })}

            </div>

            <div className='edit__page__cms__wrapper__preview'>
                {pageLoaded.map((index) => {
                    return (<div key={index} className='edit__page__cms__header' style={{ backgroundImage: 'url(' + primaryHeaderImage + ')' }} onInput={(event) => { editTitle(event) }} contentEditable="true" suppressContentEditableWarning={true}>{pageTitle}</div>)
                })}


                <div className='edit__page__cms__wrapper__content'>
                    {page.map((i, index) => {
                        if (i[0] === 'paragraph') {
                            return (<div className='edit__page__cms__item__wrapper'><p contentEditable="true" suppressContentEditableWarning={true} onInput={(event) => { editElement(index, event) }} className='preview__paragraph__edit__cms' key={index}>{i[1]}</p>
                                {createEditDeleteMoveSection(index)}
                            </div>)
                        }
                        if (i[0] === 'line') {
                            return (<div className='edit__page__cms__item__wrapper'><div className='preview__line__edit__cms' key={index}></div>{createEditDeleteMoveSection(index)}

                            </div>)
                        }
                        if (i[0] === 'image') {
                            return (<div className='edit__page__cms__item__wrapper' key={index}><div className='preview__image__edit__cms__wrapper'><img src={i[1]} className='review__image__edit__cms' onClick={() => updateImagePopup(i, index)} /><p>{i[2]}</p></div>
                                {createEditDeleteMoveSection(index)}

                            </div>)
                        }
                        if (i[0] === 'subtitle') {
                            return (<div className='edit__page__cms__item__wrapper' key={index}><h2 className='review__subtitle__edit__cms' onInput={(event) => { editElement(index, event) }} contentEditable="true" suppressContentEditableWarning={true}>{i[1]}</h2>
                                {createEditDeleteMoveSection(index)}
                            </div>)
                        }

                    })}

                    {imagePopup.map((i, index) => {
                        return (
                            <div className='image__edit__form__popup' key={index}>
                                <div className='image__edit__form__popup__backdrop' onClick={() => closeImagePopup()}></div>
                                <div className='image__edit__form__popup__display'>
                                    <div className='image__edit__form__popup__display__left'>
                                        <div className='image__edit__form__popup__display__left__content'>
                                            <label>Image Link:</label>
                                            <input type='text' onInput={(event) => {
                                                updateImageLink(event);
                                            }} value={newImageLink}></input>

                                            <label>Alt Text:</label>
                                            <input type='text' onInput={(event) => {
                                                updateImageAlt(event);
                                            }} value={newImageAlt}></input>

                                            <span className='update__image__button__edit__form' onClick={() => updateImage(i)}>Update Image</span>
                                        </div>
                                    </div>

                                    <div className='image__edit__form__popup__display__right'>
                                        <img src={newImageLink}></img>
                                    </div>
                                </div>
                            </div>);
                    })}


                    {pageLoaded.map((i, index) => {
                        if (i[0] !== null) {
                            return (<div className='edit__form__new__element' key={index}>
                                <div className={isCreateItem ? 'open__create__item active' : 'open__create__item'}>


                                    <div className='edit__form__item__left'>
                                        <div className={isCreateItem ? 'edit__form__create__item active' : 'edit__form__create__item'} onClick={() => toggleCreateItem()}>+</div>

                                        <form>
                                            <label>Element Type: </label>
                                            <select onChange={(e) => { updateSelectedItemType(e.target.value) }} className='cms__form__create__new'>
                                                <option value="" defaultValue hidden>Choose here</option>
                                                <option>Subtitle</option>
                                                <option>Paragraph</option>
                                                <option>Image</option>
                                                <option>Line</option>
                                            </select>
                                            {contentChoice.map((choice, index) => {
                                                if (choice === 'paragraph') {
                                                    return (<><textarea onChange={(e) => { updatePreview(e.target.value) }} key={index} />
                                                        <span className='edit__form__page__add__element' onClick={() => addNewItem(previewContent)}>Add Element</span></>)
                                                }
                                                if (choice === 'image') {
                                                    return (<>
                                                        <label key={index}>Image Link:</label><input type='text' onChange={(e) => { updatePreview(e.target.value) }}></input>
                                                        
                                                        <label>Alt Text:</label>
                                                    <input type='text' onChange={(e) => { updatePreviewAlt(e.target.value)}}></input>

                                                        <span className='edit__form__page__add__element' onClick={() => addNewItem(previewContent)}>Add Element</span></>)
                                                }
                                                if (choice === 'subtitle') {
                                                    return (<><input type='text' onChange={(e) => { updatePreview(e.target.value) }} key={index}></input><span className='edit__form__page__add__element' onClick={() => addNewItem(previewContent)}>Add Element</span></>)
                                                }
                                                if (choice === 'line') {
                                                    return (<span className='edit__form__page__add__element' onClick={() => addNewItem(previewContent)} key={index}>Add Element</span>)
                                                }



                                            })}
                                        </form>

                                    </div>

                                    <div className='edit__form__item__right'>
                                        <div className='edit__form__item__right__container'>
                                            <h1>Preview:</h1>
                                            {previewContent.map((i, index) => {
                                                if (i[0] === 'paragraph') {
                                                    return (<p key={index}>{i[1]}</p>);
                                                }
                                                if (i[0] === 'image') {
                                                    return (<><img src={i[1]} key={index}></img></>);
                                                }
                                                if (i[0] === 'line') {
                                                    return (<div className='preview__line__edit__cms' key={index}></div>)
                                                }
                                                if (i[0] === 'subtitle') {
                                                    return (<h2 className='preview__subtitle__edit__cms' key={index}>{i[1]}</h2>)
                                                }
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            )
                        }
                    })}
                </div>
            </div>

        </div>
    )
}

export default EditPageForm