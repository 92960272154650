import React, { useEffect, useState } from 'react'
import CrimsonBlockHeader from '../../../components/CrimsonBlockHeader/CrimsonBlockHeader'
import { primaryAPILink } from '../../../config/routes';
import AdminNavbar from '../AdminNavbar/AdminNavbar'
import ProjectModal from './ProjectModal/ProjectModal';
import './AdminProject.css'

const AdminProjects = () => {
    // Fetch data on component mount
  useEffect(() => {
    fetch(`${primaryAPILink}/api/projects/all`, {headers: {jwtKey: localStorage.getItem("loginKey")}})
      .then(res => res.json())
      .then(result => {
        if (result.length !== 0) {
          setProjectsList(result);
          setFilteredProjects(result);
        }
      });
  }, []);

  // Handle search input
  const handleSearch = input => {
    setSearchTerm(input);

    // Filter projects based on search term
    const filteredProjects = projectsList.filter(project => {
      const projectInfo = [
        project.Project_Title,
        project.Approach,
        project.Benefits,
        project.Issue,
        project.Funding_Source,
        project.Objective,
        project.Products
      ].join(' ').toLowerCase();

      return projectInfo.includes(input.toLowerCase());
    });
    setFilteredProjects(filteredProjects);
  };

  const [projectsList, setProjectsList] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');


  const [selectedProject, setSelectedProject] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleEditClick = project => {
    setSelectedProject(project);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setSelectedProject(null);
    setIsModalOpen(false);
  };

  const handleSaveProject = updatedProject => {
    // Implement the logic to save the updated project information
    // You can make a PUT request to your API here
    // After successful update, update the projectsList and filteredProjects states
  };

  return (
    <div className='news__edit__page__wrapper'>
        <AdminNavbar active="projects" />
        <div className='news__edit__page__content__section'>
        
            
      
            <CrimsonBlockHeader text="Research Projects" />
                <div className='projects__filters'>
                <input
                type='search'
                className='projects__search'
                placeholder='Search Projects'
                value={searchTerm}
                onChange={e => handleSearch(e.target.value)}
                /></div>
                <ul className={`project__admin__container ${isModalOpen ? 'active' : ''}`}>
                {filteredProjects.map((item, index)=>{
                    return(<li
            key={index}
            className='admin__research__project__item'
            onClick={() => handleEditClick(item)} // Open modal on click
          >
            {item.Project_Title}
          </li>)
                })}
                </ul>
                {isModalOpen && (
        <ProjectModal
          project={selectedProject}
          onClose={handleModalClose}
          onSave={handleSaveProject}
        />
      )}
                
         
        </div>
    </div>
  )
}

export default AdminProjects