import React, { useState } from 'react';
import { convertToDate } from '../../../../assets/Functions/HelpfulFunctions';
import AllNewsStoriesStory from '../../../News/AllNewsStories/AllNewsStoriesStory/AllNewsStoriesStory'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';



const NewsDisplay = (props) => {
  const itemsPerPage = 5; // Number of news items to display per page
  const maxPagesToShow = 4; // Maximum number of pages to show directly, rest will be hidden with ellipsis
  const [currentPage, setCurrentPage] = useState(1);
  const [showResults, setShowResults] = useState(true); // Added state for show/hide

  // Sort newsData by score in descending order
  const sortedNewsData = props.newsData.slice().sort((a, b) => b.score - a.score);

  const totalPages = Math.ceil(sortedNewsData.length / itemsPerPage);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedNewsData.slice(indexOfFirstItem, indexOfLastItem);

  const changePage = (newPage) => {
    setCurrentPage(newPage);
  };

  const toggleResults = () => {
    setShowResults(!showResults);
  };

  const renderPageButtons = () => {
    const pageButtons = [];

    for (let i = 1; i <= totalPages; i++) {
      if (
        i === 1 || // Always show the first page
        i === totalPages || // Always show the last page
        (i >= currentPage - maxPagesToShow / 2 && i <= currentPage + maxPagesToShow / 2)
      ) {
        pageButtons.push(
          <button
            key={i}
            onClick={() => changePage(i)}
            className={currentPage === i ? 'active' : ''}
          >
            {i}
          </button>
        );
      } else if (
        (i === currentPage - maxPagesToShow / 2 - 1 && currentPage - maxPagesToShow / 2 > 2) ||
        (i === currentPage + maxPagesToShow / 2 + 1 && currentPage + maxPagesToShow / 2 < totalPages - 1)
      ) {
        // Add ellipsis when there are hidden pages
        pageButtons.push(<span key="ellipsis">...</span>);
      }
    }

    return pageButtons;
  };

  return (
    <div className='advanced-search-term'>
      <h2>News:   <button onClick={toggleResults}>
        {showResults ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}
      </button></h2>
    
      {showResults && (
        <div>
          <div className="news-display">
            {currentItems.map((newsItem, index) => {
              return (
            
                <div key={index}>
                    <AllNewsStoriesStory snippet={newsItem.snippet} newsImage={newsItem.image} date={convertToDate(newsItem.date)} title={newsItem.title} tag={newsItem.category} link={newsItem.link}/>
                </div>
              );
            })}
          </div>
          {totalPages > 1 && <div className="pagination">{renderPageButtons()}</div>}
        </div>
      )}
    </div>
  );
};

export default NewsDisplay;
