import React from 'react'
import './AllNewsStoriesStory.css'

const AllNewsStoriesStory = (props) => {

  const formattedLink = props.link.startsWith('news/story/')
  ? props.link
  : `/news/story/${props.link}`;

  return (
    <a href={formattedLink} className='individual__news__story__all__stories'
    style={{animationDelay: `${0.1 * props.delay}s`}}>
        <div className='individual__news__story__all__stories__text' >
            <span>{props.tag}</span>
            <h2>{props.title}</h2>
            <span className='individual__news__story__all__stories__date'>{props.date}</span>
            <p>{props.snippet}</p>
        </div>
        <img src={props.newsImage} 
        onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src="https://data.igws.indiana.edu/filestore/5/0/6/9/4_ab03c112ee54682/49605scr_491465816ff08e8.jpg?v=1666789768";
        }}/>
    </a>
  )
}

export default AllNewsStoriesStory