export const geologyFacts = [
    "Southern Indiana is known for its karst topography, characterized by sinkholes, caves, and underground rivers.",
    "Wyandotte Cave in Crawford County, Indiana, is one of the largest caves in the United States.",
    "The northern part of Indiana features landscapes shaped by glacial deposits, including moraines and kettle lakes.",
    "Indiana's geology has been influenced by the proximity of the Great Lakes during past glacial periods.",
    "The state is famous for its high-quality limestone, used in iconic structures such as the Empire State Building and the Pentagon.",
    "Salem Limestone, a type of Indiana limestone, is known for its durability and attractive appearance.",
    "The Blue River in Indiana exposes Silurian and Devonian rock formations, providing insights into ancient marine environments.",
    "The Falls of the Ohio, located in southern Indiana, preserve a rich fossil record from the Late Devonian period.",
    "Indiana Dunes National Park showcases diverse geologic features, including sand dunes, forests, and wetlands.",
    "The Wabash River, Indiana's longest river, has carved its course through a variety of geological formations.",
    "Glacial erratic boulders, transported by glaciers, can be found in different parts of Indiana, revealing the region's glacial history.",
    "The Miami Limestone, found in parts of southern Indiana, contains marine fossils and provides clues about past sea levels.",
    "The Mitchell Karst Plain in Indiana is home to numerous sinkholes and underground caves formed by the dissolution of limestone.",
    "Indiana's bedrock is primarily composed of sedimentary rocks, including limestone, shale, and sandstone.",
    "The Indiana Geological and Water Survey (IGWS) plays a crucial role in studying and documenting the state's geology.",
    "The Cincinnati Arch, an ancient and stable geological feature, influences the landscape of southeastern Indiana.",
    "The Indiana State Museum in Indianapolis offers exhibits on the state's geology, including fossils and mineral specimens.",
    "Hoosier National Forest encompasses diverse geologic features, including rugged hills, valleys, and limestone outcrops.",
    "The Saint Meinrad Archabbey in southern Indiana is situated on a hill composed of Salem Limestone.",
    "Glacial meltwater channels, formed during the last Ice Age, can be observed in parts of Indiana.",
    "The geology of Indiana has played a role in shaping the state's agricultural landscapes and soil composition.",
    "Numerous quarries in Indiana have contributed to the state's reputation as the 'Limestone Capital of the World.'",
    "The Muscatatuck River, a tributary of the Ohio River, flows through scenic valleys and limestone bluffs.",
    "The Mitchell Plateau in southern Indiana showcases rugged terrain and unique geological formations.",
    "Fossilized coral reefs from the Devonian period are found in some parts of Indiana, providing insights into ancient marine ecosystems.",
    "The Indiana University Department of Geological Sciences conducts research and education in various geoscience disciplines.",
    "The Lafayette Meteorite Impact Site in Indiana is a remnant of a meteorite impact that occurred around 450 million years ago.",
    "The Hoosier Slide, a large sand dune complex in northern Indiana, reflects the dynamics of glacial outwash deposits.",
    "Shades State Park features canyons, cliffs, and sandstone rock formations, providing a glimpse into Indiana's geological diversity.",
    "The West Baden Springs Hotel in French Lick, Indiana, is constructed on a site with mineral springs and unique geological features.",
    "Indiana's water resources are influenced by the state's geology, impacting groundwater availability and quality.",
    "The Indiana Geological Survey Library houses a wealth of information on the state's geology, minerals, and fossils.",
    "The Big Clifty Sandstone, found in some parts of Indiana, contains fossilized tracks and traces of ancient organisms.",
    "Turkey Run State Park showcases sedimentary rock formations, including sandstone canyons and natural bridges.",
    "The Muscatatuck National Wildlife Refuge in Indiana features diverse habitats influenced by the region's geological history.",
    "Indiana's geological diversity contributes to the state's varied ecosystems, supporting a wide range of plant and animal species.",
    "The Crawfordsville Fossil Beds in Indiana preserve an exceptional collection of fossilized crinoids from the Mississippian period.",
    "The Fort Wayne Moraine, a glacial landform, influences the topography of northeastern Indiana.",
    "The Charlestown State Park in Indiana offers hiking trails with views of exposed Devonian bedrock and fossil-rich outcrops.",
    "The Indiana State Fairgrounds Coliseum is constructed using Indiana limestone, showcasing the versatility of this geological resource.",
    "The Lost River in southern Indiana flows underground for a portion of its course, resurfacing in the Hoosier National Forest.",
    "The Tipton Till Plain, formed by glacial till deposits, is a characteristic feature of central Indiana's landscape.",
    "The Hoosier Prairie Nature Preserve in Lake County, Indiana, protects a unique ecosystem shaped by glacial processes.",
    "The Indiana Geological and Water Survey collaborates with researchers, educators, and policymakers to advance understanding of the state's geology.",
    "The Mitchell Plain, located in south-central Indiana, features flat-topped hills and is underlain by limestone.",
  ];