import React from 'react'
import { useState, useEffect } from 'react'
import './CMSContentNav.css'

const CMSContentNav = (props) => {
  const linksList = props.content

  // Get the current path
  const currentPath = window.location.pathname;


  return (
    <div className={props.active ? "cmsContentNav active" : 'cmsContentNav'}>
      {linksList.map((item, index) => {

        const relativePath = item[1]
        // Check if the relative path matches the current path
        const isCurrentPage = currentPath === relativePath;
        return (<a href={item[1]} key={index} className={isCurrentPage ? 'current-page' : ''}>{item[0]}</a>)
      })}
    </div>
  )
}

export default CMSContentNav