import React from 'react'
import FullScreenMapTemplate from '../FullScreenMapTemplate/FullScreenMapTemplate'
import './IndianaMap.css'

const IndianaMap = () => {
  return (
    <>
    <FullScreenMapTemplate link={'https://maps.indiana.edu/'} />
    </>
  )
}

export default IndianaMap