import React from 'react'
import './StaffProfilePicture.css'

const StaffProfilePicture = (props) => {
  return (
    <div className='staff__profile__picture__wrapper'>
        <img src={props.pictureLink} alt={props.altText} onError={({currentTarget})=>{
          currentTarget.src='https://data.igws.indiana.edu/filestore/5/0/6/9/4_ab03c112ee54682/49605scr_491465816ff08e8.jpg?v=1666789768'
        }
          }/>
    </div>
  )
}

export default StaffProfilePicture