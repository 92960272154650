import React, { useState, useEffect } from 'react';
import CrimsonBlockHeader from '../../../components/CrimsonBlockHeader/CrimsonBlockHeader';
import './CMSCards.css';

const CMSCards = (props) => {
  const content = JSON.parse(props.content);
  const [cardTags, setCardTags] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const cardsPerPage = 20; // Change this to set the number of cards per page

  // Add card filtering
  useEffect(() => {
    let tagList = [];
    content.forEach((card) => {
      // if card has multiple tags, split them up
      let tempCardTags = card.cardTag.split(', ');
      tempCardTags.forEach((individualTag) => {
        if (!tagList.includes(individualTag)) {
          tagList.push(individualTag);
        }
      });
    });
    setCardTags(tagList);
  }, []);

  // Function to check if the link is an external link
  const isExternalLink = (link) => {
    return link.startsWith('http://') || link.startsWith('https://');
  };

  // Filtering Logic
  const filteredContent = content.filter((card) => {
    const cardTagList = card.cardTag.split(', ');
    return (
      card.cardTitle.toLowerCase().includes(searchQuery.toLowerCase()) ||
      card.cardDescription.toLowerCase().includes(searchQuery.toLowerCase()) ||
      cardTagList.some((tag) => tag.toLowerCase().includes(searchQuery.toLowerCase()))
    );
  });

  // Pagination Logic
  const indexOfLastCard = currentPage * cardsPerPage;
  const indexOfFirstCard = indexOfLastCard - cardsPerPage;
  const currentCards = filteredContent.slice(indexOfFirstCard, indexOfLastCard);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
    setTimeout(() => setCurrentPage(1), 0); // Delay the state update to avoid conflicts
  };

   // Use useEffect to reset the page when searchQuery changes
   useEffect(() => {
    setCurrentPage(1);
  }, [searchQuery]);

  return (
    <div className='cards__cms__section'>
      <CrimsonBlockHeader text={props.pageTitle} />
      <div className='search-bar'>
        <input
          type='search'
          placeholder='Search...'
          value={searchQuery}
          onChange={handleSearchInputChange}
        />
      </div>
      <div className='cardFiltersContainer'>
        <div className='cardFilters'>
          <button onClick={() => setSearchQuery('')}>All</button>
          {cardTags.map((option, index) => {
            if (option !== '') {
              return (
                <button key={index} onClick={() => { setSearchQuery(option); }}>
                  {option}
                </button>
              );
            }
          })}
        </div>
      </div>
      <div className='cms__cards__wrapper'>
        {currentCards.map((card, index) => {
          return (
            <a
              href={card.cardLink}
              key={index}
              className='cmsCardA'
              target={isExternalLink(card.cardLink) ? '_blank' : ''}
              rel={isExternalLink(card.cardLink) ? 'noopener noreferrer' : ''}
            >
              <div className='cmsCard'>
                <img src={card.cardImg} alt={card.cardTitle} />
                <div className='cmsCardText'>
                  <h2>{card.cardTitle}</h2>
                  <p>{card.cardDescription}</p>
                </div>
              </div>
            </a>
          );
        })}
      </div>
      {filteredContent.length > cardsPerPage && (
        <div className='pagination'>
          {Array.from(
            { length: Math.ceil(filteredContent.length / cardsPerPage) },
            (_, i) => (
              <button
                key={i}
                onClick={() => paginate(i + 1)}
                className={currentPage === i + 1 ? 'active' : ''}
              >
                {i + 1}
              </button>
            )
          )}
        </div>
      )}
    </div>
  );
};

export default CMSCards;
