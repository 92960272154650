import React from 'react'
import './EclipseLine.css'

const EclipseLine = (props) => {
  return (
    <div className='eclipseLine'>
        <div className='eclipseLinePart'></div>
        <h2>{props.text}</h2>
        <div className='eclipseLinePartRight'></div>
    
    </div>
  )
}

export default EclipseLine