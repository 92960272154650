import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import NewsLeafletMap from '../../../News/NewsStory/NewsStoryComponents/NewsLeafletMap/NewsLeafletMap'
import NewsStoryVideo from '../../../News/NewsStory/NewsStoryComponents/NewsStoryVideo/NewsStoryVideo'
import './NewsEditContent.css'
import { primaryAPILink } from '../../../../config/routes.js'
import NewsStoryImage from '../../../News/NewsStory/NewsStoryComponents/NewsStoryImage/NewsStoryImage'
import AdminContentExplain from '../../AdminContentExplain/AdminContentExplain'
import InfoPopupIcon from '../../../../components/InfoPopupIcon/InfoPopupIcon'

const NewsEditContent = (props) => {
    const [newsStoryId, setNewsStoryId] = useState("")
    const [newsStoryLink, setNewsStoryLink] = useState("")
    const [newsSnippet, setNewsSnippet] = useState("")
    const [newsDatePublished, setNewsDatePublished] = useState("")
    const [newsCategory, setNewsCategory] = useState("")
    const [newsTitle, setNewsTitle] = useState("")
    const [newsAuthor, setNewsAuthor] = useState("")
    const [isPublished, setIsPublished] = useState("")
    const [newsImage, setNewsImage] = useState("")
    const [newsContent, setNewsContent] = useState([])
    const [newItemType, setNewItemType] = useState("bold")



    //variable used for displaying if we need to update or not.
    const [updated, setUpdated] = useState(false);
    const [updatedText, setUpdatedText] = useState("Update Page");

    function updatePage() {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', jwtKey: localStorage.getItem("loginKey") },
            body: JSON.stringify({
                newsStoryId: newsStoryId,
                newsStoryLink: newsStoryLink,
                newsContent: (JSON.stringify(newsContent)),
                newsSnippet: newsSnippet,
                newsDatePublished: newsDatePublished,
                newsAuthor: newsAuthor,
                newsTitle: newsTitle,
                newsImage: newsImage,
                isPublished: isPublished,
                newsCategory: newsCategory
            })
        };
        fetch(`${primaryAPILink}/api/news/updatePage`, requestOptions)
        setUpdatedText("Updated")
        setUpdated(true);
    }

    function featurePage() {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', jwtKey: localStorage.getItem("loginKey") },
            body: JSON.stringify({
                newsStoryLink: newsStoryLink,
            })
        };
        fetch(`${primaryAPILink}/api/news/featuredPage`, requestOptions)
    }

    function deletePage() {
        if (window.confirm("Are you sure you wish to delete this news article?")) {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', jwtKey: localStorage.getItem("loginKey") },
                body: JSON.stringify({
                    newsStoryLink: newsStoryLink,
                })
            };
            fetch(`${primaryAPILink}/api/news/deleteStory`, requestOptions)
            window.location.reload();
        }
    }

    useEffect(() => {
        if (props.data.length !== 0) {
            setNewsStoryId(props.data[0].newsStoryId)
            setNewsStoryLink(props.data[0].newsStoryLink)
            setNewsSnippet(props.data[0].newsSnippet)
            setNewsDatePublished(props.data[0].newsDatePublished.split("T")[0])
            setNewsCategory(props.data[0].newsCategory)
            setNewsTitle(props.data[0].newsTitle)
            setNewsAuthor(props.data[0].newsAuthor)
            setIsPublished(props.data[0].isPublished)
            setNewsImage(props.data[0].newsImage)
            let content = JSON.parse(props.data[0].newsContent)
            setNewsContent(content);
        }
    }, [props.data])

    function deleteElement(index) {
        let tempNewsContent = [...newsContent];
        tempNewsContent.splice(index, 1);
        setNewsContent(tempNewsContent)
        setUpdatedText("Update Page")
        setUpdated(false);
    }

    function addElement() {
        if (newItemType === "bold") {
            let tempNewsContent = newsContent
            tempNewsContent.push({ type: "bold", text: "Add text" });
            setNewsContent([...tempNewsContent])
        }
        if (newItemType === "paragraph") {
            let tempNewsContent = newsContent
            tempNewsContent.push({ type: "paragraph", text: "Add text" });
            setNewsContent([...tempNewsContent])
        }
        if (newItemType === "map") {
            let tempNewsContent = newsContent
            tempNewsContent.push({ type: "map", message: "Add text", lat: '39.171670', long: '-86.52150' });
            setNewsContent([...tempNewsContent])
        }
        if (newItemType === "button") {
            let tempNewsContent = newsContent
            tempNewsContent.push({ type: "button", text: "Add text", link: "https://igws.indiana.edu" });
            setNewsContent([...tempNewsContent])
        }
        if (newItemType === "image") {
            let tempNewsContent = newsContent
            tempNewsContent.push({ type: "image", link: "link goes here", text: "alt text goes here" });
            setNewsContent([...tempNewsContent])
        }
        if (newItemType === "video") {
            let tempNewsContent = newsContent
            tempNewsContent.push({ type: "video", videoLink: "https://www.youtube.com/embed/TvlHsUYsaKk" });
            setNewsContent([...tempNewsContent])
        }
        if (newItemType === "cards") {
            let tempNewsContent = newsContent
            tempNewsContent.push({ type: "cards", cardList: [] });
            setNewsContent([...tempNewsContent])
        }
        if (newItemType === "gallery") {
            let tempNewsContent = newsContent
            tempNewsContent.push({ type: "gallery", label: "label goes here", images: [] });
            setNewsContent([...tempNewsContent])
        }
        if (newItemType === "float") {
            let tempNewsContent = newsContent
            tempNewsContent.push({ type: "float", link: "", text: "text goes here", leftRight: "left" });
            setNewsContent([...tempNewsContent])
        }
        setUpdatedText("Update Page")
        setUpdated(false);
    }

    function deleteCard(index, subindex) {

        let tempNewsContent = [...newsContent];
        tempNewsContent[index].cardList.splice(subindex, 1);
        setNewsContent(tempNewsContent);
        setUpdatedText("Update Page")
        setUpdated(false);
    }

    function deleteGallery(index, subindex) {
        let tempNewsContent = [...newsContent];
        tempNewsContent[index].images.splice(subindex, 1);
        setNewsContent(tempNewsContent);
        setUpdatedText("Update Page")
        setUpdated(false);
    }

    function renderEditOptions(item, index) {
        if (newsContent.length === 1) {
            return (
                <div className='news__edit__content__item__edit__section'>
                    <div className='news__edit__content__item__edit__arrows'>
                    </div>
                    <div className='news__edit__content__item__edit__section__trash' onClick={() => deleteElement(index)}>🗑️</div>
                </div>
            )
        }

        if ((index === (newsContent.length - 1))) {
            return (
                <div className='news__edit__content__item__edit__section'>
                    <div className='news__edit__content__item__edit__arrows'>
                        <div className='news__edit__content__item__edit__section__arrow' onClick={() => swapItems(index, (index - 1))
                        }>⬆️</div>
                    </div>
                    <div className='news__edit__content__item__edit__section__trash' onClick={() => deleteElement(index)}>🗑️</div>
                </div>)
        }

        if (index === 0) {
            return (
                <div className='news__edit__content__item__edit__section'>
                    <div className='news__edit__content__item__edit__arrows'>
                        <div className='news__edit__content__item__edit__section__arrow' onClick={() => swapItems(index, (index + 1))}>⬇️</div>
                    </div>
                    <div className='news__edit__content__item__edit__section__trash' onClick={() => deleteElement(index)}>🗑️</div>
                </div>)
        }

        return (
            <div className='news__edit__content__item__edit__section'>
                <div className='news__edit__content__item__edit__arrows'>
                    <div className='news__edit__content__item__edit__section__arrow' onClick={() => swapItems(index, (index - 1))}>⬆️</div>
                    <div className='news__edit__content__item__edit__section__arrow' onClick={() => swapItems(index, (index + 1))}>⬇️</div>
                </div>
                <div className='news__edit__content__item__edit__section__trash' onClick={() => deleteElement(index)}>🗑️</div>
            </div>
        )
    }

    function drawContent(item, index) {
        let ourItem = <>{item.text}</>
        if (item.type === "bold") {
            ourItem = <h2 className='news__story__bold__text'
                contentEditable="true"
                suppressContentEditableWarning={true}
                onInput={(event) => {
                    newsContent[index].text = event.target.innerText;
                    setNewsContent(newsContent);
                    setUpdatedText("Update Page")
                    setUpdated(false);
                }}>{item.text}</h2>
        }
        if (item.type === "paragraph") {
            ourItem = <p className='news__story__paragraph__text' contentEditable="true"
                suppressContentEditableWarning={true}
                onInput={(event) => {
                    newsContent[index].text = event.target.innerText;
                    setNewsContent(newsContent);
                    setUpdatedText("Update Page")
                    setUpdated(false);
                }}>{item.text}</p>
        }
        if (item.type === "button") {
            ourItem = <div className='news__edit__section__item__wrapper__complex__links__needed'>
                <p className='crimson__link__button' contentEditable="true" onInput={(event) => {
                    newsContent[index].text = event.target.innerText;
                    setNewsContent(newsContent);
                    setUpdatedText("Update Page")
                    setUpdated(false);
                }} suppressContentEditableWarning={true}>
                    {item.text}
                </p>
                <div className='edit__news__content__individual__item__wrapper__content__section'>
                    <label>Link:</label>
                    <input value={newsContent[index].link} onChange={
                        (event) => {
                            let tempNewsContent = [...newsContent];
                            tempNewsContent[index].link = event.target.value;
                            setNewsContent(tempNewsContent);
                            setUpdatedText("Update Page")
                            setUpdated(false);
                        }
                    } />

                </div>
            </div>
        }
        if (item.type === "image") {
            ourItem = <div className='news__edit__section__item__wrapper__complex__links__needed'>
                <img src={item.link} className="news__story__image__popup__wrapper" />

                <div className='edit__news__content__individual__item__wrapper__content__section'>
                    <label>Link:</label>
                    <input value={newsContent[index].link} onChange={
                        (event) => {
                            let tempNewsContent = [...newsContent];
                            tempNewsContent[index].link = event.target.value;
                            setNewsContent(tempNewsContent);
                            setUpdatedText("Update Page")
                            setUpdated(false);
                        }
                    } />
                    <label>Alt Text:</label>
                    <input value={newsContent[index].text} onChange={
                        (event) => {
                            let tempNewsContent = [...newsContent];
                            tempNewsContent[index].text = event.target.value;
                            setNewsContent(tempNewsContent);
                            setUpdatedText("Update Page")
                            setUpdated(false);
                        }
                    } />
                </div>
            </div>
        }
        if (item.type === "float") {
            ourItem = <div className='news__edit__section__item__wrapper__complex__links__needed'>
                <div className={'news__story__float__text__image ' + item.leftRight}>
                    <div className='news__story__image__popup__wrapper'>
                        <img src={item.link} />
                    </div>
                    <p className='news__story__paragraph__text' contentEditable="true" onInput={(event) => {
                        newsContent[index].text = event.target.innerText;
                        setNewsContent(newsContent);
                        setUpdatedText("Update Page")
                        setUpdated(false);
                    }} suppressContentEditableWarning={true}>{item.text}</p>
                </div>

                <div className='edit__news__content__individual__item__wrapper__content__section'>
                    <label>Link:</label>
                    <input value={newsContent[index].link} onChange={
                        (event) => {
                            let tempNewsContent = [...newsContent];
                            tempNewsContent[index].link = event.target.value;
                            setNewsContent(tempNewsContent);
                            setUpdatedText("Update Page")
                            setUpdated(false);
                        }
                    } />
                    <label>Float left or right:</label>
                    <select value={item.leftRight} onChange={
                        (event) => {
                            let tempNewsContent = [...newsContent];
                            tempNewsContent[index].leftRight = event.target.value;
                            setNewsContent(tempNewsContent);
                            setUpdatedText("Update Page")
                            setUpdated(false);
                        }
                    }>
                        <option>left</option>
                        <option>right</option>
                    </select>
                </div>
            </div>
        }
        if (item.type === "video") {
            ourItem = <div className='news__edit__section__item__wrapper__complex__links__needed'>
                <NewsStoryVideo videoLink={item.videoLink} />

                <div className='edit__news__content__individual__item__wrapper__content__section'>
                    <label>Video Link:</label>
                    <input value={newsContent[index].videoLink} onChange={
                        (event) => {
                            let tempNewsContent = [...newsContent];
                            tempNewsContent[index].videoLink = event.target.value;
                            setNewsContent(tempNewsContent);
                            setUpdatedText("Update Page")
                            setUpdated(false);
                        }
                    } />
                </div>
            </div>
        }
        if (item.type === "map") {
            ourItem = <div className='news__edit__section__item__wrapper__complex__links__needed'>
                <NewsLeafletMap positionLat={item.lat} positionLong={item.long} message={item.message} />

                <div className='edit__news__content__individual__item__wrapper__content__section'>
                    <label>Lat:</label>
                    <input value={newsContent[index].lat} onChange={
                        (event) => {
                            let tempNewsContent = [...newsContent];
                            tempNewsContent[index].lat = event.target.value;
                            setNewsContent(tempNewsContent);
                            setUpdatedText("Update Page")
                            setUpdated(false);
                        }
                    } />
                    <label>Long:</label>
                    <input value={newsContent[index].long} onChange={
                        (event) => {
                            let tempNewsContent = [...newsContent];
                            tempNewsContent[index].long = event.target.value;
                            setNewsContent(tempNewsContent);
                            setUpdatedText("Update Page")
                            setUpdated(false);
                        }
                    } />
                    <label>Message:</label>
                    <input value={newsContent[index].message} onChange={
                        (event) => {
                            let tempNewsContent = [...newsContent];
                            tempNewsContent[index].message = event.target.value;
                            setNewsContent(tempNewsContent);
                            setUpdatedText("Update Page")
                            setUpdated(false);
                        }
                    } />
                </div>
            </div>
        }
        if (item.type === "cards") {
            ourItem = <div className='news__story__multi__card'>
                {item.cardList.map((card, cardIndex) => {
                    return (
                        <div className='card__wrapper__news__editor'>
                            <figure className="news__story__multi__cards__card">
                                <img src={card.image} />
                                <figcaption contentEditable="true" suppressContentEditableWarning={true} onInput={(event) => {
                                    let tempNewsContent = newsContent;
                                    tempNewsContent[index].cardList[cardIndex].text = event.target.innerText;
                                    setNewsContent(tempNewsContent);
                                    setUpdatedText("Update Page")
                                    setUpdated(false);
                                }}

                                >{newsContent[index].cardList[cardIndex].text}</figcaption>
                            </figure>
                            <label>Image Link:</label>
                            <input value={newsContent[index].cardList[cardIndex].image} onChange={
                                (event) => {
                                    let tempNewsContent = [...newsContent];
                                    tempNewsContent[index].cardList[cardIndex].image = event.target.value;
                                    setNewsContent(tempNewsContent);
                                    setUpdatedText("Update Page")
                                    setUpdated(false);
                                }
                            } />
                            <label>Link:</label>
                            <input value={newsContent[index].cardList[cardIndex].link} onChange={
                                (event) => {
                                    let tempNewsContent = [...newsContent];
                                    tempNewsContent[index].cardList[cardIndex].link = event.target.value;
                                    setNewsContent(tempNewsContent);
                                    setUpdatedText("Update Page")
                                    setUpdated(false);
                                }
                            } />
                            <button onClick={() => deleteCard(index, cardIndex)}>Delete</button>
                        </div>
                    )
                })}
                <button onClick={() => {
                    let tempContent = [...newsContent];
                    tempContent[index].cardList.push({ image: "", text: "" })
                    setNewsContent(tempContent);
                    setUpdatedText("Update Page")
                    setUpdated(false);
                }} >Create new card</button>
            </div>

        }

        if (item.type === "gallery") {
            ourItem = <div className='news__story__gallery'>
                <label contentEditable="true" suppressContentEditableWarning={true}
                    onInput={(event) => {
                        console.log(event)
                        let tempNewsContent = newsContent;
                        tempNewsContent[index].label = event.target.innerText;
                        setNewsContent(tempNewsContent);
                        setUpdatedText("Update Page")
                        setUpdated(false);
                    }}>{newsContent[index].label}</label>
                {item.images.map((image, imageIndex) => {
                    return (
                        <><div className='news__story__image__container__gallery__section__wrapper'>
                            <div className='news__story__image__popup__wrapper'>
                                <img src={image.src} alt={image.text} />
                            </div>
                            <label className='edit__news__story__nonmoving__label'>Link:</label>
                            <input value={newsContent[index].images[imageIndex].src} onChange={
                                (event) => {
                                    let tempNewsContent = [...newsContent];
                                    tempNewsContent[index].images[imageIndex].src = event.target.value;
                                    setNewsContent(tempNewsContent);
                                    setUpdatedText("Update Page")
                                    setUpdated(false);
                                }
                            } />

                            <label className='edit__news__story__nonmoving__label'>Alt text:</label>
                            <input value={newsContent[index].images[imageIndex].text} onChange={
                                (event) => {
                                    let tempNewsContent = [...newsContent];
                                    tempNewsContent[index].images[imageIndex].text = event.target.value;
                                    setNewsContent(tempNewsContent);
                                    setUpdatedText("Update Page")
                                    setUpdated(false);
                                }
                            } />
                            <button onClick={() => deleteGallery(index, imageIndex)}>Delete</button>
                        </div>
                        </>
                    )
                })}
                <button onClick={() => {
                    let tempContent = [...newsContent];
                    tempContent[index].images.push({ src: "", text: "" })
                    setNewsContent(tempContent);
                    setUpdatedText("Update Page")
                    setUpdated(false);
                }}>Create new card</button>
            </div>

        }


        return (
            <div className='news__edit__content__entire__item__wrapper' key={index}>
                {ourItem}
                {renderEditOptions(item, index)}
            </div>
        )
    }

    function swapItems(beforeIndex, afterIndex) {
        let tempBefore = newsContent[beforeIndex];
        let tempAfter = newsContent[afterIndex];
        let tempPage = newsContent;
        tempPage[beforeIndex] = tempAfter;
        tempPage[afterIndex] = tempBefore;
        console.log(tempPage)
        setNewsContent([...tempPage]);
        setUpdatedText("Update Page")
        setUpdated(false);
    }


    return (
        <div className='news__edit__content__wrapper'>

            <div className='news__edit__content__head__article__section'>
                <div className='news__edit__content__head__article__section__item'>
                    <label>Author:</label>

                    <input type="text" value={newsAuthor} onInput={(event) => {
                        setNewsAuthor(event.target.value)
                        setUpdatedText("Update Page")
                        setUpdated(false);
                    }} />

                    <InfoPopupIcon text={["Name of author who wrote the article. For consistency try and limit to one author if possible."]} />

                </div>
                <div className='news__edit__content__head__article__section__item'>
                    <label>Link:</label>
                    <input type="text" value={newsStoryLink} onInput={(event) => {
                        //only allow valid input
                        let ourInput = event.target.value;
                        if (ourInput.match(/^[0-9a-zA-Z-]+$/) !== null) {
                            setNewsStoryLink(ourInput)
                        }
                        setUpdatedText("Update Page")
                        setUpdated(false);
                    }}
                    />
                    <InfoPopupIcon text={["Enter a single word or a collection of words separated by hyphens which will be used to find the news article. The story will appear under news/story/(link)"]} />
                </div>
                <div className='news__edit__content__head__article__section__item'>
                    <label>Date published:</label>
                    <input type="date" value={newsDatePublished} onChange={(event) => {
                        (setNewsDatePublished(event.target.value))
                        setUpdatedText("Update Page")
                        setUpdated(false);
                    }}></input>
                    <InfoPopupIcon text={["Date for the news story to be published on.", "A news story will not be visible to the public until the date has been reached."]} />
                </div>
                <div className='news__edit__content__head__article__section__item'>
                    <label>Published?:</label>
                    <input type="checkbox" checked={isPublished} onClick={() => {
                        setIsPublished(!isPublished);
                        setUpdatedText("Update Page")
                        setUpdated(false);
                    }} />
                    <InfoPopupIcon text={["If checked, article will be publicly visible to anyone on the site (as long as it is at or passed the date published). Wait until article is complete before checking."]} />
                </div>
                <div className='news__edit__content__head__article__section__item image__link'>
                    <label>Header Image Link:</label>
                    <input type="text" value={newsImage} onInput={(event) => {
                        setNewsImage(event.target.value)
                        setUpdatedText("Update Page")
                        setUpdated(false);
                    }} ></input>
                    <InfoPopupIcon text={["An image link which will be used for the primary header image of the story. Note: this needs to be a valid image link that is publically accessible. The general workflow for uploading a custom image is to publish the image to resource space and copy the image link.", "To do this, click the 'preview' button on resource space to get the full image, rightclick the image preview, and copy image address. That link will be pasted here.", "Try and use images atleast 1080 pixels wide and 500 pixels tall to allow images to look crisp on all devices."]} />
                </div>
                <button onClick={() => updatePage()} className={`news__editor__white__button__update ${updated ? "updated__news__editor__white__button" : ""}`}>{updatedText}</button>

                <button onClick={() => featurePage()} className="news__editor__white__button">Make Featured</button>

                <button onClick={() => deletePage()} className="news__editor__white__button">Delete Article</button>

            </div>
            <AdminContentExplain text={["Paragraph elements can use custom markup to modify how they are displayed:", "Make text bold by surrounding it with two *'s ex: **this is bold**", "Make text italic by surrounding it with two %'s ex: %%this is italic%%", "Make text a link by surrounding it with [text](link) ex: [IU Website](https://iu.edu)", "You may also write custom html directly within paragraph elements, but only do this if you know what you are doing"]}></AdminContentExplain>
            <input className='news__story__category__text'
                value={newsCategory}
                onInput={(event) => {
                    setNewsCategory(event.target.value)
                    setUpdatedText("Update Page")
                    setUpdated(false);
                }} ></input>
            <textarea className='news__story__title__text news__story__title__text__editor ' onInput={(event) => {

                setNewsTitle(event.target.value)
                setUpdatedText("Update Page")
                setUpdated(false);
            }} suppressContentEditableWarning={true} defaultValue={newsTitle}></textarea>
            <textarea className='news__story__snippet__text news__story__title__text__editor'
                onInput={(event) => {
                    setNewsSnippet(event.target.value)
                    setUpdatedText("Update Page")
                    setUpdated(false);
                }}
                value={newsSnippet}></textarea>
            <NewsStoryImage src={newsImage} text={newsTitle} />
            {newsContent.map((item, index) => {
                return (
                    drawContent(item, index));
            })}
            <label>Create new item</label>
            <select onChange={(event) => {
                setNewItemType(event.target.value)
            }}>
                <option value="bold">Heading</option>
                <option value="paragraph">Paragraph</option>
                <option value="image">Image</option>
                <option value="float">Float</option>
                <option value="map">Map</option>
                <option value="video">Video</option>
                <option value="cards">Cards</option>
                <option value="gallery">Gallery</option>
                <option value="button">Button</option>
            </select>
            <button onClick={() => addElement(newItemType)}>Add</button>

        </div>
    )
}

export default NewsEditContent