import React, { useEffect, useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import processedSolarTemp from './ProcessedSolarTempData.js';
import combinedTimestamps from './CombinedTimestamps.js';

const LiveEclipseChart = () => {
    const [solarAndTempData, setSolarAndTempData] = useState([]);
    const [showTemperature, setShowTemperature] = useState(false);
    const [xAxisDomain, setXAxisDomain] = useState([]);

    // Fetch data from API
    useEffect(() => {
        setXAxisDomain(combinedTimestamps);
        setSolarAndTempData(processedSolarTemp);


        
    }, []);

    const toggleData = () => {
        setShowTemperature(!showTemperature);
    };


    // Combine all data points into a single array
    const combinedData = xAxisDomain.map(time => {
        const combinedEntry = { time };
        if (showTemperature) {
            if (solarAndTempData.Bloomington && solarAndTempData.Bloomington.temp) {
                combinedEntry['Bloomington Temperature (°F)'] = solarAndTempData.Bloomington.temp.find(entry => entry.time === time)?.value;
            }
            if (solarAndTempData.Jasper && solarAndTempData.Jasper.temp) {
                combinedEntry['Jasper Temperature (°F)'] = solarAndTempData.Jasper.temp.find(entry => entry.time === time)?.value;
            }
            if (solarAndTempData.LakeStation && solarAndTempData.LakeStation.temp) {
                combinedEntry['LakeStation Temperature (°F)'] = solarAndTempData.LakeStation.temp.find(entry => entry.time === time)?.value;
            }
        } else {
            if (solarAndTempData.Bloomington && solarAndTempData.Bloomington.solar) {
                combinedEntry['Bloomington Solar Radiation (W/m^2)'] = solarAndTempData.Bloomington.solar.find(entry => entry.time === time)?.value;
            }
            if (solarAndTempData.Jasper && solarAndTempData.Jasper.solar) {
                combinedEntry['Jasper Solar Radiation (W/m^2)'] = solarAndTempData.Jasper.solar.find(entry => entry.time === time)?.value;
            }
            if (solarAndTempData.LakeStation && solarAndTempData.LakeStation.solar) {
                combinedEntry['LakeStation Solar Radiation (W/m^2)'] = solarAndTempData.LakeStation.solar.find(entry => entry.time === time)?.value;
            }
        }
        return combinedEntry;
    });

    // Remove undefined values (when data is not available for some lines)
    const cleanedData = combinedData.filter(entry => Object.values(entry).every(value => value !== undefined));
    const sortedData = cleanedData.sort((a, b) => new Date(a.time) - new Date(b.time));

    return (
        <section className='EclipseChartWrapper'>
            <h3 className='eclipsecenter'>{showTemperature ? 'Temperature (°F) on April 8th, 2024' : 'Solar Radiation (W/m^2) on April 8th, 2024'}</h3>
            <div style={{ position: 'relative', width: '800px', height: '400px' }} className="EclipseChart">
                <LineChart width={800} height={400} data={sortedData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="time" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    {/* Render Line components based on the selected data */}
                    {showTemperature ? (
                        <>
                            <Line type="monotone" dataKey="Bloomington Temperature (°F)" name="Bloomington Temp (°F)" stroke="#82ca9d" dot={false} />
                            <Line type="monotone" dataKey="Jasper Temperature (°F)" name="Jasper Temp (°F)" stroke="#8884d8" dot={false} />
                            <Line type="monotone" dataKey="LakeStation Temperature (°F)" name="LakeStation Temp (°F)" stroke="#ff0000" dot={false} />
                        </>
                    ) : (
                        <>
                            <Line type="monotone" dataKey="Bloomington Solar Radiation (W/m^2)" name="Bloomington (W/m^2)" stroke="#82ca9d" dot={false} />
                            <Line type="monotone" dataKey="Jasper Solar Radiation (W/m^2)" name="Jasper (W/m^2)" stroke="#8884d8" dot={false} />
                            <Line type="monotone" dataKey="LakeStation Solar Radiation (W/m^2)" name="LakeStation (W/m^2)" stroke="#ff0000" dot={false} />
                        </>
                    )}
                </LineChart>
            </div>
            <button
                onClick={toggleData}
                className='eclipseAnimatedBorder showTempButton'
                style={{
                    border: '1px solid #736a53',
                    padding: '0.5em 2em',
                    display: 'block',
                    width: '-moz-fit-content',
                    width: 'fit-content',
                    color: '#d2c398',
                    textDecoration: 'none',
                    cursor: 'pointer',
                    transition: '0.2s',

                    marginLeft: 'auto',
                    marginRight: 'auto',    
                    
            
                }}
            >
                {showTemperature ? 'Show Solar Radiation' : 'Show Temperature'}
            </button>
        </section>
    );
};

export default LiveEclipseChart;
