import React from 'react'
import "./Loading.css"
const Loading = () => {
  return (
    <div className='loading__ui'>
    <div className='loading__header'>Loading...</div>
    <div className='loading__content'>
      <div className='loading__content__2'></div>
      <div className='loading__content__1'></div>
      <div className='loading__content__2'></div>
      <div className='loading__content__3'></div>
      <div className='loading__content__image'></div>
      <div className='loading__content__1'></div>
      <div className='loading__content__2'></div>
    </div>
  </div>
  )
}

export default Loading