import React from 'react';
import { Document, Page, Text, View, StyleSheet, Link, Image } from '@react-pdf/renderer';
import IUTrident from '../../../assets/IUAssets/trident-large.png';
import IUTridentInverse from '../../../assets/IUAssets/iu_trident_web_crimson.png';

const GenerateDownloadPDF = ({ results, formData, img }) => {

    const currentYear = new Date().getFullYear();
    //List for metadata info
    let metadata = [];

    //check for any geologic hazards
    let geologicHazardsList = [];
    if (results.FloodplainsFIRM && results.FloodplainsFIRM.count > 0 && results.FloodplainsFIRM.floodStatus !== "") {
        geologicHazardsList.push(results.FloodplainsFIRM.floodStatus);
        metadata.push(results.FloodplainsFIRM.meta);
    }

    let karstFlag = false;
    if (results.KarstCaveDensity && results.KarstCaveDensity.count > 0) {
        karstFlag = true;
        metadata.push(results.KarstCaveDensity.meta);
    }
    if (results.KarstSinkholeAreas && results.KarstSinkholeAreas.count > 0) {
        karstFlag = true;
        metadata.push(results.KarstSinkholeAreas.meta);
    }
    if (results.KarstSprings && results.KarstSprings.count > 0) {
        karstFlag = true;
        metadata.push(results.KarstSprings.meta);
    }
    if (karstFlag) {
        geologicHazardsList.push("Potential Karst");
    }

    if (results.SeismicEarthquakeLiquefactionPotential && results.SeismicEarthquakeLiquefactionPotential.count > 0 && results.SeismicEarthquakeLiquefactionPotential.currentFlag !== "") {
        geologicHazardsList.push(results.SeismicEarthquakeLiquefactionPotential.currentFlag);
        metadata.push(results.SeismicEarthquakeLiquefactionPotential.meta);
    }

    if (results.BedrockGeology && results.BedrockGeology.bedrockResourceWarning) {
        geologicHazardsList.push(results.BedrockGeology.bedrockResourceWarning);
    }

    //check for any mineral resources
    let mineralResourcesList = [];
    if (results.BedrockGeology && results.BedrockGeology.count > 0 && results.BedrockGeology.bedrockResource !== "") {
        mineralResourcesList.push(`Bedrock Resource: ${results.BedrockGeology.bedrockResource}`);
        metadata.push(results.BedrockGeology.meta);
    }

    if (results.IndustrialMineralsSandGravelResources && results.IndustrialMineralsSandGravelResources.count > 0 && results.IndustrialMineralsSandGravelResources.currentFlag !== "") {
        mineralResourcesList.push(`Sand and Gravel Resource: ${results.IndustrialMineralsSandGravelResources.currentFlag}`);
        metadata.push(results.IndustrialMineralsSandGravelResources.meta);
    }

    //check for any active or abandoned mineral resources extraction sites
    let extractionSitesList = [];
    if (results.CoalMinesEntries && results.CoalMinesEntries.count > 0) {
        metadata.push(results.CoalMinesEntries.meta);
    }

    if (results.CoalMinesSurface && results.CoalMinesSurface.count > 0) {
        extractionSitesList.push("Surface Coal Mines");
        metadata.push(results.CoalMinesSurface.meta);
    }

    if (results.CoalMinesUnderground && results.CoalMinesUnderground.count > 0) {
        extractionSitesList.push("Underground Coal Mines");
        geologicHazardsList.push(<>Potential Mine Subsidence (<Link src="https://indnr.maps.arcgis.com/apps/webappviewer/index.html?id=f30ca6a781cb4209b6e614789ca7185b">CMIS</Link>)</>);
        metadata.push(results.CoalMinesUnderground.meta);
    }

    if (results.IndustrialMineralsQuarriesAbandoned && results.IndustrialMineralsQuarriesAbandoned.count > 0) {
        extractionSitesList.push("Abandoned Industrial Minerals Quarries");
        metadata.push(results.IndustrialMineralsQuarriesAbandoned.meta);
    }

    if (results.IndustrialMineralsSandGravelPitsAbandoned && results.IndustrialMineralsSandGravelPitsAbandoned.count > 0) {
        extractionSitesList.push("Abandoned Industrial Minerals Sand Gravel Pits");
        metadata.push(results.IndustrialMineralsSandGravelPitsAbandoned.meta);
    }

    if (results.IndustrialMineralsSites2016 && results.IndustrialMineralsSites2016.count > 0) {
        extractionSitesList.push(<>Active Industrial Minerals Sites (2016) (<Link src="https://legacy.igws.indiana.edu/IGSMap/?map=mineralproducers">Industrial Minerals</Link>)</>);
        metadata.push(results.IndustrialMineralsSites2016.meta);
    }

    if (results.PetroleumWells && results.PetroleumWells.count > 0) {
        extractionSitesList.push("Petroleum Exploration Wells");
        metadata.push(results.PetroleumWells.meta);
    }

    const styles = StyleSheet.create({
        page: {
            backgroundColor: '#E4E4E4',
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',

        },
        section: {
            margin: 10,
            padding: 10,
        },
        title: {
            fontSize: 18,
            fontWeight: 'bold',
            marginBottom: 10,
            textDecoration: 'underline',
            textAlign: 'center'
        },
        subtitle: {
            fontSize: 16,
            marginBottom: 8,
        },
        paragraph: {
            fontSize: 12,
            marginBottom: 6,
        },
        bold: {
            fontWeight: 'bold',
        },
        list: {
            marginLeft: 20,
        },
        listItem: {
            fontSize: 12,
            marginBottom: 4,
        },
        bullet: {
            marginRight: 5,
            fontSize: 12,
        },
        flexHorizontal: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            flexWrap: 'wrap'
        },
        flexWidth: {
            width: '45%'
        },
        banner: {
            display: 'flex',
            flexDirection: 'row',
            borderTop: "4px solid #800000",
            marginBottom: '10px'
        },
        footer: {
            display: 'flex',
            flexDirection: 'row',
            borderTop: "4px solid #800000",
            alignItems: 'center',
            justifyContent: 'space-between'
        },
        trident: {
            width: '6%',
            marginLeft: '2%',
            marginTop: '-1px'
        },
        tridentFooter: {
            width: '7%',
            display: 'flex',

        },
        footerText: {
            fontSize: 10
        },
        disclaimerText: {
            fontSize: 10,
            marginBottom: '10px'
        },
        footerImageWrapper: {
            display: 'flex',
            flexDirection: "row",
            alignItems: "center"
        },
        meta: {
            color: '#990000',
            fontSize: 7,
            marginLeft: 10,
            marginBottom: 4,
        },
        mapImage: {
            width: '99%',
            marginLeft: 'auto',
            marginRight: 'auto'
        }
    });

    const formatFieldName = (fieldName) => {
        // Add any additional formatting rules as needed
        switch (fieldName) {
            case 'fName':
                return 'First Name';
            case 'contactFirstName':
                return 'First Name';
            case 'lName':
                return 'Last Name';
            case 'oName':
                return 'Organization Name';
            case 'addressLine1':
                return 'Address Line 1';
            case 'addressLine2':
                return 'Address Line 2';

            // Exclude geometry and buffer from rendering
            case 'geometry':
            case 'buffer':
            case 'geometryType':
            case 'assessmentID':
                return null;
            case 'submissionDate':
                return null;

            // Default case: capitalize first letter and replace camel case with spaces
            default:
                return fieldName.charAt(0).toUpperCase() + fieldName.slice(1).replace(/([a-z])([A-Z])/g, '$1 $2');
        }
    };

    


    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                    <View style={styles.banner}>
                        <Image style={styles.trident} src={IUTrident} alt="Indiana University Logo" />
                    </View>

                    <Text style={styles.title}>Organization and Project Information</Text>
                    <View style={styles.flexHorizontal}>
                        {Object.keys(formData).map((fieldName, index) => (
                            // Check if the field should be rendered
                            formData[fieldName] !== '' && formatFieldName(fieldName) && (
                                <View style={styles.flexWidth} key={index}>
                                    <Text style={styles.paragraph}>
                                        <Text style={styles.bold}>{formatFieldName(fieldName)}: </Text>
                                        {formData[fieldName]}
                                    </Text>
                                </View>
                            )
                        ))}
                    </View>

                    <Text style={styles.title}>Environmental Assessment Report</Text>
                    {geologicHazardsList.length > 0 ?
                        <>
                            <Text style={styles.subtitle}>Geological Hazards:</Text>
                            <View style={styles.list}>
                                {geologicHazardsList.map((item, index) => {
                                    return (<Text key={index} style={styles.listItem}>{index + 1}. {item}</Text>)
                                })}
                            </View>
                        </>
                        : <></>
                    }

                    {geologicHazardsList.length > 0 ?
                        <>
                            <Text style={styles.subtitle}>Mineral Resources:</Text>
                            <View style={styles.list}>
                                {mineralResourcesList.map((item, index) => {
                                    return (<Text key={index} style={styles.listItem}>{index + 1}. {item}</Text>)
                                })}
                            </View>
                        </>
                        : <></>
                    }


                    {extractionSitesList.length > 0 ?
                        <>
                            <Text style={styles.subtitle}>Active or abandoned mineral resources extraction sites:</Text>
                            <View style={styles.list}>
                                {extractionSitesList.map((item, index) => {
                                    return (<Text key={index} style={styles.listItem}>{index + 1}. {item}</Text>)
                                })}
                            </View>
                        </>
                        : <></>
                    }
                </View>
                <View style={styles.section}>

                    <Text style={styles.subtitle}>Disclaimer:</Text>
                    <Text style={styles.disclaimerText}>This document was compiled by Indiana University, Indiana Geological Survey, using data believed to be accurate; however, a degree of error is inherent in all data. This product is distributed "AS-IS" without warranties of any kind, either expressed or implied, including but not limited to warranties of suitability to a particular purpose or use. No attempt has been made in either the design or production of these data and document to define the limits or jurisdiction of any federal, state, or local government. The data used to assemble this document are intended for use only at the published scale of the source data or smaller (see the metadata links below) and are for reference purposes only. They are not to be construed as a legal document or survey instrument. A detailed on-the-ground survey and historical analysis of a single site may differ from these data and this document.</Text>
                    <Text style={styles.paragraph}>This information was furnished by Indiana Geological Survey</Text>
                    <Text style={styles.paragraph}>Address: 1001 E. 10th St., Bloomington, IN 47405</Text>
                    <Text style={styles.paragraph}>Email: IGSEnvir@indiana.edu</Text>
                    <Text style={styles.paragraph}>Phone: (812) 855-7428</Text>

                    <View style={styles.footer}>
                        <View style={styles.footerImageWrapper}>
                            <Image style={styles.tridentFooter} src={IUTridentInverse} alt="Indiana University Logo" />
                            <Text style={styles.footerText}><Link src="https://www.iu.edu/copyright/index.html">Copyright</Link> © {currentYear} The Trustees of <Link src="https://www.iu.edu/index.html">Indiana University</Link>, <Link src="https://www.iu.edu/copyright/index.html">Copyright Complaints</Link></Text>
                        </View>
                        <Text style={styles.footerText}><Link src="https://www.iu.edu/comments/privacy.shtml">Privacy Notice</Link></Text>
                    </View>


                </View>
            </Page>
            {img ? 
            <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                    <View style={styles.banner}>
                     <Image style={styles.trident} src={IUTrident} alt="Indiana University Logo" />
                    </View>
                    <Image style={styles.mapImage} src={img} alt="Map of section assessed" />
                </View>

                <View style={styles.section}>
                    <View style={styles.footer}>
                        <View style={styles.footerImageWrapper}>
                            <Image style={styles.tridentFooter} src={IUTridentInverse} alt="Indiana University Logo" />
                            <Text style={styles.footerText}><Link src="https://www.iu.edu/copyright/index.html">Copyright</Link> © {currentYear} The Trustees of <Link src="https://www.iu.edu/index.html">Indiana University</Link>, <Link src="https://www.iu.edu/copyright/index.html">Copyright Complaints</Link></Text>
                        </View>
                        <Text style={styles.footerText}><Link src="https://www.iu.edu/comments/privacy.shtml">Privacy Notice</Link></Text>
                    </View>
                </View>
            </Page>
            :
            <></>    
        }
            <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                    <View style={styles.banner}>
                        <Image style={styles.trident} src={IUTrident} alt="Indiana University Logo" />
                    </View>
                    <Text style={styles.subtitle}>Metadata:</Text>
                    {metadata.map((item, index) => {
                        return (<Link src={item} key={index} style={styles.meta}><Text style={styles.meta}>{item}</Text></Link>)
                    })}
                </View>

                <View style={styles.section}>
                    <View style={styles.footer}>
                        <View style={styles.footerImageWrapper}>
                            <Image style={styles.tridentFooter} src={IUTridentInverse} alt="Indiana University Logo" />
                            <Text style={styles.footerText}><Link src="https://www.iu.edu/copyright/index.html">Copyright</Link> © {currentYear} The Trustees of <Link src="https://www.iu.edu/index.html">Indiana University</Link>, <Link src="https://www.iu.edu/copyright/index.html">Copyright Complaints</Link></Text>
                        </View>
                        <Text style={styles.footerText}><Link src="https://www.iu.edu/comments/privacy.shtml">Privacy Notice</Link></Text>
                    </View>
                </View>
            </Page>
        </Document>
    );
};

export default GenerateDownloadPDF;