import React from 'react'
import CrimsonBlockHeader from '../../components/CrimsonBlockHeader/CrimsonBlockHeader'
import {primaryAPILink} from '../../config/routes.js'
import {useState, useEffect} from 'react'
import './Employment.css'
import { Link } from 'react-router-dom'


const Employment = () => {

    document.title = 'IGWS | Employment';

    const [numPositions, setNumPositions] = useState("Loading...")
    const [jobList, setJobList] = useState([]);
    const [noJobs, setNoJobs] = useState("Loading...")

    useEffect(() => {
        fetch(`${primaryAPILink}/api/jobs`)
            .then(res => res.json())
            .then((result) => {
              if(result.length > 0) {
                setNumPositions(result.length)
                setJobList(result);
                setNoJobs("")
              }
              if(result.length === 0) {
                setNoJobs("No Positions Open At This Time")
                setNumPositions("No open positions at this time")
              }
            })}, []
    )

  return (
    <div className='employment__wrapper'>
        <CrimsonBlockHeader text="EMPLOYMENT OPPORTUNITIES"/>
        <h2>Open Positions: <span>{numPositions}</span></h2>
        <div className='employment__joblist__wrapper'>
            <div className='benefitsWrapper'>
                <a href="https://hr.iu.edu/benefits/" target="_blank"><div className='benefitsCard card1'>
                    <h2>Benefits</h2>
                </div>
                </a>
                <Link to="/about">
                <div className='benefitsCard card2'>
                    <h2>About the IGWS</h2>
                </div>
                </Link>
            </div>
            {jobList.map((job, index)=>{
                return(
                    <div className='jobWrapper' key={index}>
                        <div className='jobWrapperLeft'>
                            <h3>{job.title}</h3>
                            <p>{job.description}</p>
                        </div>
                        <a href={job.link} target="_blank">Learn More</a>
                    </div>
                )
            })}
            <h2>{noJobs}</h2>
        </div>

    </div>
  )
}

export default Employment