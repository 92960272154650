import React, { useEffect, useState } from 'react';
import { primaryAPILink } from '../../config/routes.js';
import ProjectIndividual from './ProjectIndividual/ProjectIndividual.jsx';
import CrimsonBlockHeader from '../../components/CrimsonBlockHeader/CrimsonBlockHeader.jsx';
import './Projects.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpAZ, faArrowDownAZ, faArrowUpWideShort } from '@fortawesome/free-solid-svg-icons';

import { useLocation } from 'react-router-dom'; // Import the useLocation hook

const Projects = () => {
  document.title = 'IGWS | Projects';
  // Initialize states
  const [projectsList, setProjectsList] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [filter, setFilter] = useState('All Projects');
  const [sortOrder, setSortOrder] = useState('asc');
  const [currentPage, setCurrentPage] = useState(1);
  const projectsPerPage = 25;
  const [searchTerm, setSearchTerm] = useState('');

  // Fetch data on component mount
  useEffect(() => {
    fetch(`${primaryAPILink}/api/projects`)
      .then((res) => res.json())
      .then((result) => {
        if (result.length !== 0) {
          setProjectsList(result);
          setFilteredProjects(result);
        }
      });
  }, []);

  // Handle search input
  const handleSearch = (input) => {
    setSearchTerm(input);

    // Filter projects based on search term
    const filteredProjects = projectsList.filter((project) => {
      const projectInfo = [
        project.Project_Title,
        project.Approach,
        project.Benefits,
        project.Issue,
        project.Funding_Source,
        project.Objective,
        project.Products,
        project.fName,
        project.lName,
        project.email,
      ].join(' ').toLowerCase();

      return projectInfo.includes(input.toLowerCase());
    });
    setFilteredProjects(filteredProjects);
  };

  // Filter and sort projects
  useEffect(() => {
    setCurrentPage(1);

    // Filter projects based on selected filter and search term
    const filteredByStatus =
      filter === 'All Projects' ? projectsList : projectsList.filter((project) => project.Status === filter);

    const filteredBySearch = filteredByStatus.filter((project) => {
      const projectInfo = [
        project.Project_Title,
        project.Approach,
        project.Benefits,
        project.Issue,
        project.Funding_Source,
        project.Objective,
        project.Products,
        project.fName,
        project.lName,
        project.email,
      ].join(' ').toLowerCase();

      return projectInfo.includes(searchTerm.toLowerCase());
    });

    // Sort projects based on sorting order
    const sortedProjects = filteredBySearch.slice().sort((a, b) => {
      const compareValue = a.Project_Title.localeCompare(b.Project_Title);
      return sortOrder === 'asc' ? compareValue : -compareValue;
    });

    setFilteredProjects(sortedProjects);
  }, [filter, searchTerm, projectsList, sortOrder]);

  // Handle sort order toggle
  const toggleSortOrder = () => {
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  // Pagination
  const totalProjects = filteredProjects.length;
  const totalPages = Math.ceil(totalProjects / projectsPerPage);
  const maxButtonsToShow = 4; // Adjust this to the desired number of buttons to show
  const ellipsisThreshold = 3; // Number of pages before an ellipsis is shown

  // Calculate the range of page numbers to display
  let startPage = Math.max(currentPage - Math.floor(maxButtonsToShow / 2), 1);
  let endPage = startPage + maxButtonsToShow - 1;
  if (endPage > totalPages) {
    endPage = totalPages;
    startPage = Math.max(endPage - maxButtonsToShow + 1, 1);
  }

  // Slice the filteredProjects array to get projects for the current page
  const indexOfLastProject = currentPage * projectsPerPage;
  const indexOfFirstProject = indexOfLastProject - projectsPerPage;
  const currentProjects = filteredProjects.slice(indexOfFirstProject, indexOfLastProject);

  // Handle pagination
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Get the current location using the useLocation hook
  const location = useLocation();

  // Extract the 'id' parameter from the query string
  const searchParams = new URLSearchParams(location.search);
  const idParam = searchParams.get('id');

  // Prepopulate the search input with the project based on the 'id' parameter
  useEffect(() => {
    if (idParam && projectsList.length > 0) {
      const project = projectsList.find((project) => project.projectID === Number(idParam));
      if (project) {
        setSearchTerm(project.Project_Title); // Prepopulate the search input
      }
    }
  }, [idParam, projectsList]);

  // Render pagination buttons
  const buttons = [];
  if (totalPages > 1) {
    if (startPage > 1) {
      buttons.push(
        <button key="1" className={currentPage === 1 ? 'active' : ''} onClick={() => paginate(1)}>
          1
        </button>
      );

      if (startPage > ellipsisThreshold) {
        buttons.push(<span key="ellipsis-start">...</span>);
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <button key={i} className={currentPage === i ? 'active' : ''} onClick={() => paginate(i)}>
          {i}
        </button>
      );
    }

    if (endPage < totalPages) {
      if (totalPages - endPage > ellipsisThreshold) {
        buttons.push(<span key="ellipsis-end">...</span>);
      }

      buttons.push(
        <button key={totalPages} className={currentPage === totalPages ? 'active' : ''} onClick={() => paginate(totalPages)}>
          {totalPages}
        </button>
      );
    }
  }

  return (
    <div className='research__projects'>
      <CrimsonBlockHeader text='Research Projects' />
      <div className='projects__left__right__wrapper'>
        <div className='projects__left'>
          <ul>
            <li onClick={() => setFilter('All Projects')} className={filter === 'All Projects' ? 'active' : ''}>
              All
            </li>
            <li onClick={() => setFilter('Active')} className={filter === 'Active' ? 'active' : ''}>
              Current
            </li>
            <li onClick={() => setFilter('completed')} className={filter === 'completed' ? 'active' : ''}>
              Completed
            </li>
          </ul>
        </div>
        <div className='projects__right'>
          <div className='projects__filters'>
            <input
              type='search'
              className='projects__search'
              placeholder='Search Projects'
              value={searchTerm}
              onChange={(e) => handleSearch(e.target.value)}
            />
            <div className='projects__right__search '>
              <div className='projects__a-z__search project__button project__button__left' onClick={toggleSortOrder}>
                {sortOrder === 'asc' ? <FontAwesomeIcon icon={faArrowDownAZ} /> : <FontAwesomeIcon icon={faArrowUpAZ} />}
              </div>
              <div className='projects__date__search project__button'><FontAwesomeIcon icon={faArrowUpWideShort} /></div>
            </div>
          </div>
          <div className='projects__wrapper'>
            {currentProjects.map((project, index) => (
              <ProjectIndividual
                title={project.Project_Title}
                approach={project.Approach}
                benefits={project.Benefits}
                issue={project.Issue}
                funding={project.Funding_Source}
                objective={project.Objective}
                products={project.Products}
                startDate={project.Date_Start}
                endDate={project.Date_End}
                status={project.Status}
                email={project.email}
                fName={project.fName}
                lName={project.lName}
                shortDesc={project.shortDesc}
                key={index}
              />
            ))}
          </div>
          {/* Pagination */}
          {totalPages > 1 && <div className="pagination">{buttons}</div>}
        </div>
      </div>
    </div>
  );
};

export default Projects;
