import React from 'react'
import './NewsStoryImage.css'

const NewsStoryImage = (props) => {
  return (
    <img src={props.src} alt={props.text} className="news__story__image__large"
    onError={({ currentTarget }) => {
      currentTarget.onerror = null; // prevents looping
      currentTarget.src="https://data.igws.indiana.edu/filestore/5/0/6/9/4_ab03c112ee54682/49605scr_491465816ff08e8.jpg?v=1666789768";
  }}></img>
  )
}

export default NewsStoryImage