import React from 'react'
import './NewsStoryDate.css'

const NewsStoryDate = (props) => {
    function convertToDate(date) {
        if(typeof date === "string") {
            let newDate = date.replace(/[-]/g, '/').split("T")[0];
    
            newDate = Date.parse(newDate);
            let jsDate = new Date(newDate).toDateString();
            return(jsDate.toString())   
        }
    }
  return (
    <p className='news__story__date__text'>{convertToDate(props.text)}</p>
  )
}

export default NewsStoryDate