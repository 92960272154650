import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

//This function fixes react router not scrolling back to the top on route changes.
const ScrollToTop = () => {
    // Extracts pathname property(key) from an object
    const { pathname } = useLocation();
  
    // Automatically scrolls to top whenever pathname changes
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
  }
  
  export default ScrollToTop;