import React, { useEffect, useState, useRef } from 'react';
import './DiscoverEclipse.css';
import { useInView } from 'react-intersection-observer';

const DiscoverEclipse = () => {

    const myGraphicRef = useRef(null);
    const [isSectionVisible, setIsSectionVisible] = useState(false);
    function generateEclipseGraphic() {
        const totalEclipsePics = 14;
        const myPicStages = 100 / totalEclipsePics;
        const scalingFactor = 1.5; // Adjust this factor for desired scaling
        let eclipseGraphics = [];

        for (let i = totalEclipsePics; i >= 0; i--) {
            let rawTopDistance = Math.pow(totalEclipsePics / 2 - i, 2) * myPicStages;
            let scaledTopDistance = rawTopDistance * scalingFactor;
            let topDistance = `${scaledTopDistance/ 7.0        }%`

            let moonShadow = "0 0 0 0"
            let zIndex = 1
            let overflow = "hidden"
            if(i == 7) {
                moonShadow = `0px 0px 10px 5px rgb(255, 255, 255)`
                zIndex = 2
                overflow = "visible"

            }
                

            let myMoonCSS = {
                backgroundColor: 'black',
                height: '50px',
                width: '50px',
                borderRadius: '50%',
                top: `${myPicStages * i - 50}%`,
                left: `${myPicStages * i - 50}%`,
                position: 'absolute',
                boxShadow: moonShadow,
                zIndex: zIndex

                
            }

            let mySunCSS = {
                background:
                    'radial-gradient(ellipse at 50% 50%, #e9e69f 0%, #f1ed76 51.74%, #f3c143 72.22%, #f5c44a 94%, #d27837 95%, #cc702e 100%) 0px 0px / 50px 50px no-repeat no-repeat',
                height: '50px',
                width: '50px',
                borderRadius: '50%',
            }



            eclipseGraphics.push(
                <div key={i}
                    className='smallEclipseGraphicSubSet'
                    style={{  position: 'relative', width: '50px', height: '50px', top: topDistance, margin: `0 3px`, animation: `fadeMoonIn 1s ${i * 0.1}s forwards`, opacity: 0, overflow:overflow, borderRadius: '50%'}}>
                        
                    <div className='miniSun' style={mySunCSS}></div>
                    <div className='miniMoon' style={myMoonCSS}></div>

                    
                    
                </div>);
        }

        return eclipseGraphics;
    }

    const [myGraphic, setMyGraphic] = useState(<></>)

    useEffect(() => {
        // Attach the scroll event listener
        window.addEventListener('scroll', handleScrollIntoView);
    
        // Clean up the event listener when the component is unmounted
        return () => {
          window.removeEventListener('scroll', handleScrollIntoView);
        };
      }, []); // Empty dependency array ensures that the effect runs only once
    

    const handleScrollIntoView = () => {
        // Get the position of the element relative to the viewport
        const elementPosition = myGraphicRef.current.getBoundingClientRect();
    
        // Check if the element is in the viewport
        if (elementPosition.top >= 0 && elementPosition.bottom <= window.innerHeight) {
          setMyGraphic(generateEclipseGraphic())
        }
      };

    return (
        <section className='discover__the__eclipse' ref={myGraphicRef}>
        <div className='eclipseSmallGraphicWrapper'>
            {myGraphic}
        </div>
            <div className='discover__the__eclipse__text'>
            <h2>Discover the Eclipse</h2>
            <p>What is a solar eclipse? Unravel the science behind this celestial phenomenon. Learn how the moon perfectly aligns itself between the Earth and the Sun, creating the spectacular show that captures the world’s attention.</p>
            <button onClick={() => setIsSectionVisible(!isSectionVisible)} className='eclipseAnimatedBorder'>
          {isSectionVisible ? 'Hide Eclipse Info' : 'Learn More'}
        </button>
        <div className={`eclipseSection ${isSectionVisible ? 'visible' : ''}`}>
          
            <h3>What is an Eclipse?</h3>
            <a href="https://science.nasa.gov/moon/eclipses/" target='_blank'>Information provided by NASA, 2024</a>
            <p>
            Solar eclipses happen only at the new moon phase, when the Moon is between Earth and the Sun. During a solar eclipse, the Moon casts a shadow on Earth, and blocks or partially blocks our view of the Sun. Though solar eclipses happen as often as lunar eclipses, they are visible from such a small area of Earth each time that it’s much rarer to encounter one. 
            </p>
            <p>During a solar eclipse, the Moon's shadow on Earth’s surface is only about 300 miles (480 km) wide. The shadow consists of two parts, the umbra, where the Sun is completely blocked, and the penumbra, where the Sun is partially obscured. People in the umbra will see a total eclipse, while people in the penumbra will see a partial eclipse. Though the shadow is narrow and the total eclipse lasts for only minutes, our planet rotates fast enough to bring the shadow a third of the way around Earth's surface before the Moon moves out of alignment with the Sun. </p>
            <p>That we often get such impressive solar eclipses on Earth is a lucky chance of nature. The Sun is vastly larger than the Moon ― its diameter is about 400 times the Moon’s. But the Moon is roughly 400 times closer to Earth. This makes it possible for the Moon to almost perfectly block out the Sun when everything aligns. </p>
            <p>This state of affairs won’t last forever. The Moon started its existence much closer to Earth, and has been slowly drifting outward at the rate of about 1.5 inches (3.8 cm) per year. Once it makes it past 14,600 miles (23,500 km), it’ll appear too small from Earth to cover the Sun. But don’t panic if you haven’t seen an eclipse yet ― you’ve got another 600-million-plus years before that border is breached. In the meantime, you can get a preview during an annular eclipse, when the Sun, Moon and Earth align but the Moon’s orbit places it too far away from Earth to entirely block the disk of the Sun. During an annular eclipse, the sky takes on a twilight cast, but some of the Sun still shows.</p>
          </div>

            </div>
        </section>
    );
}

export default DiscoverEclipse;
