import React from 'react'
import './EclipseNews.css'
import { Link } from 'react-router-dom'

const EclipseNews = () => {
    return (
        <section className='eclipseNews'>
            <div className='eclipseNewsArticles'>
                <a href="/news/story/2024-eclipseeffects" target="_blank">
                    <div className='eclipseNewsCard'>
                        <img src="https://data.igws.indiana.edu/filestore/1/6/1/0/6_2971150d2709143/60161scr_6ffea2363fd1d1a.jpg?v=1708716477" alt="Studying eclipse effects on geology, groundwater"></img>
                        <div className='eclipsenewstextwrapper'>
                            <h3>Studying eclipse effects on geology, groundwater</h3>
                            <p>On April 8, Eclipse Day in Indiana, most people’s eyes will be focused on the sky. IGWS researchers plan to be looking underground, too.</p>
                        </div>
                    </div>

                </a>

                <a href="/news/story/2023-eclipes" target="_blank">
                    <div className='eclipseNewsCard ' >
                        <img src="https://events.iu.edu/live/image/gid/256/width/250/17426_graphic.rev.1691085025.jpg" alt="Eclipses, the IWBN, and the IGWS"></img>
                        <div className='eclipsenewstextwrapper'>
                            <h3>Eclipses, the IWBN, and the IGWS</h3>
                            <p>What do eclipses have to do with the work of the Indiana Geological and Water Survey?</p>
                        </div>
                    </div>

                </a>

                <a href="/news/story/2024-day-no-sun" target="_blank">
                    <div className='eclipseNewsCard ' >
                        <img src="https://data.igws.indiana.edu/filestore/2/6/1/0/6_09b91d78c9b671a/60162web_f1df2f73e53375f.jpg?v=1709065890" alt="Imagine a day without the sun"></img>
                        <div className='eclipsenewstextwrapper'>
                            <h3>Imagine a day without the sun</h3>
                            <p>IGWS Research Geologist Ginger Davis provides insights into the scenario of a day without our sun, offering a glimpse into the intriguing landscape that would unfold.</p>
                        </div>
                    </div>

                </a>

                
            </div>
        </section>
    )
}

export default EclipseNews