import React from 'react'
import './NewsStoryGallery.css'
import NewsStoryPopupImage from '../NewsStoryPopupImage/NewsStoryPopupImage';

const NewsStoryGallery = (props) => {
  return (
    <div className='news__story__gallery'>
      <label>{props.label}</label>
      {props.images.map((item, index) => {
        return(<NewsStoryPopupImage className="news__story__gallery__item" src={item.src} text={item.text}/>)
      })}
    </div>
  )
}

export default NewsStoryGallery