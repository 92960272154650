import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { primaryAPILink } from '../../../../config/routes.js'
import './AdminCMSCardEdit.css'

const AdminCMSCardEdit = (props) => {
  const [visibileInNav, setVisibileInNav] = useState("");
  const [activeLink, setActiveLink] = useState("");
  const [link, setLink] = useState(props.content.cmsLink)
  const [cardContent, setCardContent] = useState(props.content.cmsContent)
  const [navLabel, setNavLabel] = useState(props.content.title)


  //variable used for displaying if we need to update or not.
  const [updated, setUpdated] = useState(false);
  const [updatedText, setUpdatedText] = useState("Update Page");

  function cmsUpdatePage() {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', jwtKey: localStorage.getItem("loginKey") },
      body: JSON.stringify({
        cmsShowInNav: visibileInNav,
        cmsActive: activeLink,
        cmsContent: (JSON.stringify(cardList)),
        title: navLabel,
        cmsLink: link,
        cmsID: props.content.cmsID
      })
    };
    fetch(`${primaryAPILink}/api/cms/updateCMSPage`, requestOptions)
    setUpdatedText("Updated")
    setUpdated(true);
  }

  function cmsDeletePage() {
    if (window.confirm("Are you sure you wish to delete this link?")) {
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', jwtKey: localStorage.getItem("loginKey") }
      }
      fetch(`${primaryAPILink}/api/cms/deleteCMSPage/${props.content.cmsID}`, requestOptions)
      window.location.reload()
    }
  }

  useEffect(() => {
    setVisibileInNav(props.content.cmsShowInNav);
    setActiveLink(props.content.cmsActive)
  }, [])


  const [cardList, setCardList] = useState([])
  useEffect(() => {
    setCardList(JSON.parse(props.content.cmsContent))
  }, [])
  function createCard() {
    let tempCardList = [...cardList];
    tempCardList.push({ cardTitle: "title", cardDescription: "description", cardImg: "", cardTag: "", cardLink: "" })
    setCardList(tempCardList);

  }

  function deleteCard(index){
    let tempCardList = [...cardList];
    tempCardList.splice(index, 1);
    setCardList(tempCardList);
  }



  return (
    <>
      <div className='page__type__header'><h2>Card Page</h2></div>
      <div className='admin__cms__content__head__wrapper'>
        <div className='admin__cms__content__head'>
          <div className='admin__cms__head__input__wrapper'>
            <label htmlFor="admin__cms__checkbox__nav">Visible in Nav:</label>
            <input id="admin__cms__checkbox__nav" type="checkbox" checked={visibileInNav} onClick={() => {
              setVisibileInNav(!visibileInNav)
              setUpdatedText("Update Page")
              setUpdated(false)
            }}></input>
            <div class="info">
              <i class="icon-info-sign"></i>

              <span class="extra-info">If checked the nav label will appear in the navbar at the top of the screen.
              </span>
            </div>
          </div>
          <div className='admin__cms__head__input__wrapper'>
            <label htmlFor="admin__cms__checkbox__active">Active Link:</label>
            <input id="admin__cms__checkbox__active" type="checkbox" checked={activeLink} onClick={() => {
              setActiveLink(!activeLink)
              setUpdatedText("Update Page")
              setUpdated(false)
            }}></input>
            <div class="info">
              <i class="icon-info-sign"></i>

              <span class="extra-info">If checked the link will be active and usable by anyone who accesses the website.
              </span>
            </div>
          </div>
          <div className='admin__cms__head__input__wrapper'>
            <label htmlFor="admin__cms__checkbox__link">Link:</label>
            <input id="admin__cms__checkbox__link" type="input" value={link} onChange={(event) => {
              setLink(event.target.value)
              setUpdatedText("Update Page")
              setUpdated(false)
            }}></input>
            <div class="info">
              <i class="icon-info-sign"></i>

              <span class="extra-info">This will be the actual link a user must go to in order to be redirected. ie. igws.indiana.edu/(parentGroup)/(link)
              </span>
            </div>
          </div>
          <div className='admin__cms__head__input__wrapper'>
            <label htmlFor="admin__cms__checkbox__navlabel">Nav Label:</label>
            <input id="admin__cms__checkbox__navlabel" type="input" value={navLabel} onChange={(event) => {
              setNavLabel(event.target.value)
              setUpdatedText("Update Page")
              setUpdated(false)
            }}></input>
            <div class="info">
              <i class="icon-info-sign"></i>

              <span class="extra-info">The label which will appear in the navigation bar if visible in nav is checked
              </span>
            </div>
          </div>
          <button onClick={() => cmsUpdatePage()} className={`cms__editor__button__update ${updated ? "updated__cms__editor__button" : ""}`}>{updatedText}</button>
          <button onClick={() => cmsDeletePage()} className="cms__editor__button__update">Delete Page</button>
        </div>
      </div>
      <div className='cms__card__admin__edit__wrapper'>
        {cardList.map((card, index) => {
          return (
            <div key={index} className="cms__card__edit__Wrapper">
              <div className='cmsCard'>
                <img src={card.cardImg}></img>
                <div class='cmsCardText'>
                  <h2 class='smallCardNum'>{index + 1}</h2>
                  <h2><textarea
                    onInput={(e) => {
                      let tempCards = [...cardList];
                      tempCards[index].cardTitle = e.target.value;
                      setCardList(tempCards)
                      setUpdatedText("Update Page")
                      setUpdated(false)
                    }} value={cardList[index].cardTitle}></textarea></h2>
                  <p><textarea rows="6" maxlength="150"
                    onInput={(e) => {
                      let tempCards = [...cardList];
                      tempCards[index].cardDescription = e.target.value;
                      setCardList(tempCards)
                      setUpdatedText("Update Page")
                      setUpdated(false)
                    }} value={cardList[index].cardDescription}></textarea></p>
                </div>
              </div>
              <div className='cms__card__edit__attributes'>
                <div className='cms__card__edit__attributes__item'>
                  <label>External Link:</label>
                  <input type="text" value={card.cardLink} onInput={(e) => {
                    let tempCards = [...cardList];
                    tempCards[index].cardLink = e.target.value;
                    setCardList(tempCards)
                    setUpdatedText("Update Page")
                    setUpdated(false)
                  }}></input>
                </div>

                <div className='cms__card__edit__attributes__item'>
                  <label>Tag:</label>
                  <input type="text" value={card.cardTag} onInput={(e) => {
                    let tempCards = [...cardList];
                    tempCards[index].cardTag = e.target.value;
                    setCardList(tempCards)
                    setUpdatedText("Update Page")
                    setUpdated(false)
                  }}></input>
                </div>

                <div className='cms__card__edit__attributes__item'>
                  <label>Image Link:</label>
                  <input type="text" value={card.cardImg} onInput={(e) => {
                    let tempCards = [...cardList];
                    tempCards[index].cardImg = e.target.value;
                    setCardList(tempCards)
                    setUpdatedText("Update Page")
                    setUpdated(false)
                  }}></input>
                  <button onClick={()=>deleteCard(index)}>delete</button>
                </div>
              </div>


            </div>
          )
        })}
        <button className='add__new__card__button__cms__card__editor' onClick={() => { createCard() }}>Add New Card</button>
      </div>
    </>
  )
}

export default AdminCMSCardEdit