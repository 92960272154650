import React from 'react'
import IndianaGeologyQuickGuideNew from '../../components/IndianaGeologyQuickGuideNew/IndianaGeologyQuickGuideNew'
import LatestNewsHome from '../../components/LatestNewsHome/LatestNewsHome'
import './Home.css'
import HomeCrimson from './HomeCrimson/HomeCrimson'
import HomeSwiper from './HomeSwiper/HomeSwiper'

const Home = () => {
  document.title = 'IGWS';
  return (
    <div>
      <HomeSwiper />
      <IndianaGeologyQuickGuideNew />
      <HomeCrimson />
      <LatestNewsHome />
    </div>
  )
}

export default Home