import React from 'react'
import {useState, useEffect} from 'react'

export const EditPageFormBase = () => {

    const [pageContent, setPageContent] = useState([])

    //---------------------------------------------------------------------------------------------
    //Fetch from the navigation api to find all the entires.
    //From these entries, loop through to find all unique parent names

    //Parent names are added to the navigationParents useState
    const [navigationParents, setNavigationParents] = useState([]);
    useEffect(() => {
        fetch(`http://molly.igs.indiana.edu:8080/api/nav`)
            .then(res => res.json())
            .then((result) => {
                    result.forEach(item => {
                        if (navigationParents.includes(item.parent)) {
                        } else {
                            let tempListOfParents = navigationParents;
                            tempListOfParents.push(item.parent);
                            setNavigationParents(tempListOfParents);
                        }})
            })}, []
    )


    //---------------------------------------------------------------------------------------------
    //Function used for given parent category, outputtin g the children cms pages which fall under that parent page

    //holds current parent choice
    const [activeParent, setActiveParent] = useState([])
    //holds current children pages based on active parent
    const [childrenOptions, setChildrenOptions] = useState([])
    //holds the current active child page
    const [activeChild, setActiveChild] = useState([])
    function updatePageOptions(parent) {
        setActiveParent(parent)
        setActiveChild("");

        fetch(`http://molly.igs.indiana.edu:8080/api/nav/fullPath${parent}`)
            .then(res => res.json())
            .then(
                (result) => {
                    setChildrenOptions(result);

                })
    }
    //---------------------------------------------------------------------------------------------


  return (
    <div>EditPageFormBase</div>
  )
}
