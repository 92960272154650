import React from 'react'
import { useState, useEffect } from 'react';
import * as L from "leaflet";
import { MapContainer, TileLayer, Marker, Popup} from 'react-leaflet';
import './NewsLeafletMap.css'

const NewsLeafletMap = (props) => {
  let lat = props.positionLat;
  let long = props.positionLong;
    if(isNaN(props.positionLat)){
      lat = 0;
    }
    if(isNaN(props.positionLong)){
      long = 0;
    }

    const position = [lat, long]
    const [message, setMessage] = useState()

    useEffect(() => {
        if(props.message !== undefined) {
            setMessage(<Popup>
                <p>{props.message}</p>
            </Popup>)
        }
        
    }, [])

    //  Create the Icon
  const LeafIcon = L.Icon.extend({
    options: {}
  });

  const greenIcon = new LeafIcon({
    iconUrl:
      "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|2ecc71&chf=a,s,ee00FFFF",
      iconSize: [21, 34]
  });

  //  Use the state hook:
const [icon, setIcon] = useState(greenIcon);


  return (
    <div className='leaflet__news__map__container'>
    <link rel="stylesheet" href="https://unpkg.com/leaflet@1.9.2/dist/leaflet.css" integrity="sha256-sA+zWATbFveLLNqWO2gtiw3HL/lh1giY/Inf1BJ0z14=" crossOrigin="" />

  <MapContainer center={position} zoom={13} maxZoom={16} minZoom={5} scrollWheelZoom={false}>
    
    <TileLayer
      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    />
    <Marker position={position} icon={icon}>
        {message}
    </Marker>
  </MapContainer>
  </div>

  )
}

export default NewsLeafletMap