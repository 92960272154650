import React, { useEffect, useState } from 'react';
import './UserPermissionsTable.css';
import { primaryAPILink } from '../../../../config/routes.js';
import UserPermissionTableRow from './UserPermissionTableRow/UserPermissionTableRow';

const UserPermissionsTable = () => {
  const [tableInfo, setTableInfo] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredTable, setFilteredTable] = useState([])

  useEffect(() => {
    fetch(`${primaryAPILink}/api/permissions/staffPermissions`, { headers: { jwtKey: localStorage.getItem("loginKey") } })
      .then(res => res.json())
      .then(result => {
        if (result.length > 0) {
          setTableInfo(result);
          setFilteredTable(result);
        }
      });
  }, []);

  // Filter tableInfo based on searchQuery
  useEffect(() => {
    if (searchQuery === "") {
      setFilteredTable(tableInfo)
    } else {
      let tempTable = []
      tableInfo.forEach((person) => {
        if (person.fName.toLowerCase().includes(searchQuery) || person.lName.toLowerCase().includes(searchQuery)) {
          console.log(person)
          tempTable.push(person)
        }
      })
      console.log(tempTable)
      setFilteredTable(tempTable);

    }

  }, [searchQuery])


  // Update searchQuery state on input change
  const handleSearchChange = (e) => {
    setSearchQuery((e.target.value).toLowerCase());
  };

  return (
    <div className='user__permissions__wrapper'>
      {/* Search bar */}
      <input
        type="text"
        placeholder="Search by name..."
        value={searchQuery}
        onChange={handleSearchChange}
      />

      <table className='user__permissions__admin'>
        <thead>
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Title</th>
            <th>Category</th>
            <th>Picture</th>
            <th>Active</th>
            <th>Admin</th>
            <th>Alerts</th>
            <th>News</th>
            <th>Jobs</th>
            <th>CMS</th>
            <th>IGNIS</th>
          </tr>
        </thead>
        <tbody>
          {filteredTable.map((user) => (
            <UserPermissionTableRow user={user} key={user.username} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UserPermissionsTable;
