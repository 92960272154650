import React from 'react';
import './EclipseImageGrid.css'; // Ensure to import your CSS file

const EclipseImageGrid = (props) => {
  const imageList = props.imageList;


  return (
    <section className='eclipse-image-grid-section'>
      {props.right ? (
        <>
          <div className='eclipse-image-grid-section-right'>
            <figure>
              <img src={imageList[1][0]} alt={imageList[1][1]} />
              <figcaption>{imageList[1][1]}</figcaption>
            </figure>
            <figure>
              <img src={imageList[2][0]} alt={imageList[2][1]} />
              <figcaption>{imageList[2][1]}</figcaption>
            </figure>
          </div>
          <div className='eclipse-image-grid-section-left'>
            <figure>
              <img src={imageList[0][0]} alt={imageList[0][1]} />
              <figcaption>{imageList[0][1]}</figcaption>
            </figure>
          </div>
        </>
      ) : (
        <>
          <div className='eclipse-image-grid-section-left'>
            <figure>
              <img src={imageList[0][0]} alt={imageList[0][1]} />
              <figcaption>{imageList[0][1]}</figcaption>
            </figure>
          </div>
          <div className='eclipse-image-grid-section-right'>
            <figure>
              <img src={imageList[1][0]} alt={imageList[1][1]} />
              <figcaption>{imageList[1][1]}</figcaption>
            </figure>
            <figure>
              <img src={imageList[2][0]} alt={imageList[2][1]} />
              <figcaption>{imageList[2][1]}</figcaption>
            </figure>
          </div>
        </>
      )}
    </section>
  );
  
}

export default EclipseImageGrid;
