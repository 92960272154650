import React from 'react'
import './CrimsonSquareLink.css'

function CrimsonSquareLink(title,description,link,target) {
  const accessibleName = `${title} - ${description}`;
  return (
    <a href={link} className='crimson__square__link' target={target} aria-label={accessibleName}> 
        <span className='crimson__square__header'>{title}</span>
        <p className='crimson__square__description'>{description}</p>
    </a>
    
  )
}

export default CrimsonSquareLink