import React from 'react'
import './StaffProfileName.css'

const StaffProfileName = (props) => {
  return (
    <div className='staff__profile__name__wrapper'>
        <h2>{props.fName} {props.lName}</h2>
    </div>
  )
}

export default StaffProfileName