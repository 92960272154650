import React from 'react'
import CrimsonBlockHeader from '../../../components/CrimsonBlockHeader/CrimsonBlockHeader'
import AdminContentExplain from '../AdminContentExplain/AdminContentExplain'
import AdminNavbar from '../AdminNavbar/AdminNavbar'
import PublicAlertForm from './PublicAlertForm/PublicAlertForm'

const PublicAlerts = () => {
  return (
    <div className='news__edit__page__wrapper'>
        <AdminNavbar active="alert"/>
        <div className='news__edit__page__content__section'>
            <CrimsonBlockHeader text="Public Alerts" />
            <AdminContentExplain text={["Public alerts display a bar at the top of the screen with a message and optional link.", "Use sparingly as these types of popups can be annoying to users.", "The message will display between the start and end dates."]}></AdminContentExplain>
            <PublicAlertForm />
        </div>
    </div>
  )
}

export default PublicAlerts