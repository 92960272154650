import React, { useEffect, useState } from 'react';
import { primaryAPILink } from '../../../config/routes';
import CrimsonBlockHeader from '../../../components/CrimsonBlockHeader/CrimsonBlockHeader';
import './Status.css'

const Status = () => {
  const [status, setStatus] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch(`${primaryAPILink}/api/iwbn`)
      .then(res => {
        if (!res.ok) {
          throw new Error(`Error: ${res.status}`);
        }
        return res.json();
      })
      .then((result) => {
        if (result.length > 0) {
          setStatus(result);
        } else {
          throw new Error('Error Fetching Data');
        }
      })
      .catch((error) => {
        setError(error.message);
        setStatus([]);
      });
  }, []);

  const timeSince = (date) => {
    const now = new Date();
    const past = new Date(date);
    const seconds = Math.floor((now - past) / 1000);

    let interval = Math.floor(seconds / 31536000);
    if (interval >= 1) {
      return `${interval} year${interval > 1 ? 's' : ''} ago`;
    }
    interval = Math.floor(seconds / 2592000);
    if (interval >= 1) {
      return `${interval} month${interval > 1 ? 's' : ''} ago`;
    }
    interval = Math.floor(seconds / 86400);
    if (interval >= 1) {
      return `${interval} day${interval > 1 ? 's' : ''} ago`;
    }
    interval = Math.floor(seconds / 3600);
    if (interval >= 1) {
      return `${interval} hour${interval > 1 ? 's' : ''} ago`;
    }
    interval = Math.floor(seconds / 60);
    if (interval >= 1) {
      return `${interval} minute${interval > 1 ? 's' : ''} ago`;
    }
    return `${seconds} second${seconds > 1 ? 's' : ''} ago`;
  };

  const getIcon = (date) => {
    const now = new Date();
    const past = new Date(date);
    const hours = (now - past) / 3600000; // Convert milliseconds to hours

    if (hours < 1) {
      return <span style={{ color: 'var(--rvt-green-500)' }}>●</span>;
    } else if (hours < 5) {
      return <span style={{ color: 'var(--rvt-orange-400)' }}>●</span>;
    } else {
      return <span style={{ color: 'var(--rvt-orange-700)' }}>●</span>;
    }
  };
  

  return (
    <div>
      <CrimsonBlockHeader text="IWBN Status"/>
      <div className='cmsPageWrapper'>
        <div className='cmsContentDisplayWrapper'>
        <div className='cmsContentDisplay'>
      {error ? <p>{error}</p> : (
        <table className='cms__table iwbn-table-status'>
          <thead>
            <tr>
              <th>Station Name</th>
              <th>Last Update</th>
            </tr>
          </thead>
          <tbody>
            {status.map((item, index) => (
              <tr key={index}>
                <td>{item.name}</td>
                <td>
                {getIcon(item.data[0].TmStamp)}
                {' '}
                  {timeSince(item.data[0].TmStamp)}
                 
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
    </div>
    </div>
    </div>
  );
};

export default Status;
