import React, { useEffect, useState } from 'react';
import { primaryAPILink } from '../../config/routes';
import CMSDisplay from './AdvancedSearchTypes/CMSDisplay/CMSDisplay';
import NewsDisplay from './AdvancedSearchTypes/NewsDisplay/NewsDisplay';
import StaffDisplay from './AdvancedSearchTypes/StaffDisplay/StaffDisplay';
import ProjectsDisplay from './AdvancedSearchTypes/ProjectsDisplay/ProjectsDisplay';
import RsDisplay from './AdvancedSearchTypes/RsDisplay/RsDisplay';
import CrimsonBlockHeader from '../../components/CrimsonBlockHeader/CrimsonBlockHeader';
import './AdvancedSearch.css';
import { useLocation, useNavigate } from 'react-router-dom';

const AdvancedSearch = () => {
  document.title = "IGWS | Search";

  // State for search results and search status
  const [searchResults, setSearchResults] = useState({});
  const [isSearching, setIsSearching] = useState(false);
  const [hasResults, setHasResults] = useState(false);
  const [showInstructions, setShowInstructions] = useState(true);

  // State variables to track the visibility of each search category
  const [showStaff, setShowStaff] = useState(true);
  const [showCMS, setShowCMS] = useState(true);
  const [showNews, setShowNews] = useState(true);
  const [showProjects, setShowProjects] = useState(true);
  const [showRs, setShowRs] = useState(true);

  // Extract search term from the URL query parameter or use an empty string
  const location = useLocation();
  const navigate = useNavigate();
  const searchParam = new URLSearchParams(location.search).get('q');
  const [searchTerm, setSearchTerm] = useState(searchParam || '');

  // Function to perform the search
  async function search() {
    setIsSearching(true);

    try {
      // Encode the search term before sending it to the server
      const encodedSearchTerm = encodeURIComponent(searchTerm);

      // Fetch search results from the API
      const response = await fetch(`${primaryAPILink}/api/search/advanced/${encodedSearchTerm}`);
      console.log(encodedSearchTerm)
      if (response.ok) {
        // Parse and set the search results
        const data = await response.json();
        setSearchResults(data);

        // Check if there are results in any category
        if (
          (showStaff && data.staffResults.length > 0) ||
          (showCMS && data.cmsResults.length > 0) ||
          (showNews && data.newsResults.length > 0) ||
          (showProjects && data.projectsResults.length > 0) ||
          (showRs && data.rsResults.length > 0)
        ) {
          setHasResults(true);
        } else {
          setHasResults(false);
        }
      } else {
        throw new Error('Request failed');
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsSearching(false);
    }
  }

  // Effect to update the URL with the search term when it changes
  useEffect(() => {
    navigate(`/search?q=${searchTerm}`);
  }, [searchTerm, navigate]);

  // Handler for search input change
  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Handler for search button click
  const handleSearchClick = () => {
    if (!isSearching) {
      // Update the URL with the search term
      navigate(`/search?q=${searchTerm}`);
      setAutoSearchEnabled(true); // Enable auto search
      setShowInstructions(false);
    }
  };

  // Flag to track whether the search should be automatically triggered
  const [autoSearchEnabled, setAutoSearchEnabled] = useState(!!searchParam);

  // Effect to handle the initial search when the component mounts and autoSearchEnabled is true
  useEffect(() => {
    if (autoSearchEnabled) {
      search();
      setAutoSearchEnabled(false); // Reset the flag
      setShowInstructions(false);
    }
  }, [autoSearchEnabled]);

  // Effect to extract and set the search term from the URL query parameter
  useEffect(() => {
    const searchParam = new URLSearchParams(location.search).get('q');
    setSearchTerm(searchParam || ''); // Set the search term in your state
  }, [location.search]);

  return (
    <div className='advanced-search-wrapper-wrapper'>
      <CrimsonBlockHeader text="Advanced Search" />
      <div className='advanced-search-search-wrapper'>
        <input
          type="search"
          value={searchTerm}
          onChange={handleInputChange}
          placeholder="Enter search term"
        />
        <button onClick={handleSearchClick} disabled={isSearching} className="searchButtonAdvanced">
          {isSearching ? 'Searching...' : 'Search'}
        </button>
        <div className='checkboxes_for_adv_search'>
          {/* Checkbox components for various search categories */}
          <div>
            <label>
              <input type="checkbox" checked={showStaff} onChange={() => setShowStaff(!showStaff)} />
              Staff
            </label>
          </div>
          <div>
            <label>
              <input type="checkbox" checked={showCMS} onChange={() => setShowCMS(!showCMS)} />
              Web Pages
            </label>
          </div>
          <div>
            <label>
              <input type="checkbox" checked={showNews} onChange={() => setShowNews(!showNews)} />
              News
            </label>
          </div>
          <div>
            <label>
              <input type="checkbox" checked={showProjects} onChange={() => setShowProjects(!showProjects)} />
              Projects
            </label>
          </div>
          <div>
            <label>
              <input type="checkbox" checked={showRs} onChange={() => setShowRs(!showRs)} />
              Digital Collections
            </label>
          </div>
        </div>
      </div>
      <div className='advanced-search-wrapper'>
        {showInstructions ? (
          <p>Enter your search term above and click the "Search" button to find results.</p>
        ) : !isSearching && !hasResults ? (
          <p className='subtle__message__text__search'>
            <span className="search__bold__nav">No results found:</span>
            <br />
            If you're looking for publications or related materials, consider exploring our{' '}
            <a href="https://data.igws.indiana.edu" target="_blank">
              Digital Collections
            </a>{' '}
            for a comprehensive selection of resources.
          </p>
        ) : (
          <>
            {searchResults && (
              <>
                {showStaff && searchResults.staffResults && searchResults.staffResults.length > 0 && (
                  <StaffDisplay staffData={searchResults.staffResults} />
                )}
                {showCMS && searchResults.cmsResults && searchResults.cmsResults.length > 0 && (
                  <CMSDisplay cmsData={searchResults.cmsResults} />
                )}
                {showNews && searchResults.newsResults && searchResults.newsResults.length > 0 && (
                  <NewsDisplay newsData={searchResults.newsResults} />
                )}
                {showProjects && searchResults.projectsResults && searchResults.projectsResults.length > 0 && (
                  <ProjectsDisplay projectsData={searchResults.projectsResults} />
                )}
                {showRs && searchResults.rsResults && searchResults.rsResults.length > 0 && (
                  <RsDisplay rsData={searchResults.rsResults} />
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default AdvancedSearch;
