import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import {primaryAPILink} from '../../../../config/routes.js'
import './AdminCMSLinkEdit.css'

const AdminCMSLinkEdit = (props) => {
  const [visibileInNav, setVisibileInNav] = useState("");
  const [activeLink, setActiveLink] = useState("");
  const [link, setLink] = useState(props.content.cmsLink)
  const [redirectLink, setRedirectLink] = useState(JSON.parse(props.content.cmsContent)[0].link)
  const [navLabel, setNavLabel] = useState(props.content.title)



  //variable used for displaying if we need to update or not.
  const [updated, setUpdated] = useState(false);
  const [updatedText, setUpdatedText] = useState("Update Page");

  function cmsUpdatePage(){
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', jwtKey: localStorage.getItem("loginKey")},
      body: JSON.stringify({
      cmsShowInNav: visibileInNav,
      cmsActive: activeLink,
      cmsContent: (JSON.stringify([{link: redirectLink}])),
      title: navLabel,
      cmsLink: link,
      cmsID: props.content.cmsID
    })
  };
  fetch(`${primaryAPILink}/api/cms/updateCMSPage`, requestOptions)
  setUpdatedText("Updated")
  setUpdated(true);
  }

  useEffect(()=>{
    setVisibileInNav(props.content.cmsShowInNav);
    setActiveLink(props.content.cmsActive)
  },[])

  function cmsDeletePage(){
    if(window.confirm("Are you sure you wish to delete this link?")){
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', jwtKey: localStorage.getItem("loginKey")}
      }
      fetch(`${primaryAPILink}/api/cms/deleteCMSPage/${props.content.cmsID}`, requestOptions)
      window.location.reload()
    }
  }

  


  return (
    <>
    <div className='page__type__header'><h2>Link Page</h2></div>
    <div className='admin__cms__content__head__wrapper'>
      <div className='admin__cms__content__head'>
          <div className='admin__cms__head__input__wrapper'>
            <label htmlFor="admin__cms__checkbox__nav">Visible in Nav:</label>
            <input id="admin__cms__checkbox__nav" type="checkbox" checked={visibileInNav} onClick={()=>{
              setVisibileInNav(!visibileInNav)
              setUpdatedText("Update Page")
              setUpdated(false)}}></input>
            <div class="info">
              <i class="icon-info-sign"></i>

              <span class="extra-info">If checked the nav label will appear in the navbar at the top of the screen. 
              </span>
            </div>
          </div>
          <div className='admin__cms__head__input__wrapper'>
            <label htmlFor="admin__cms__checkbox__active">Active Link:</label>
            <input id="admin__cms__checkbox__active" type="checkbox" checked={activeLink} onClick={()=>{setActiveLink(!activeLink)
            setUpdatedText("Update Page")
            setUpdated(false)}}></input>
            <div class="info">
              <i class="icon-info-sign"></i>

              <span class="extra-info">If checked the link will be active and usable by anyone who accesses the website. 
              </span>
            </div>
          </div>
          <div className='admin__cms__head__input__wrapper'>
            <label htmlFor="admin__cms__checkbox__link">Link:</label>
            <input id="admin__cms__checkbox__link" type="input" value={link} onChange={(event)=>{setLink(event.target.value)
            setUpdatedText("Update Page")
            setUpdated(false)}}></input>
            <div class="info">
              <i class="icon-info-sign"></i>

              <span class="extra-info">This will be the actual link a user must go to in order to be redirected. ie. igws.indiana.edu/(parentGroup)/(link)
              </span>
            </div>
          </div>
          <div className='admin__cms__head__input__wrapper'>
            <label htmlFor="admin__cms__checkbox__redirectlink">Redirect Link:</label>
            <input id="admin__cms__checkbox__redirectlink" type="input" value={redirectLink} onChange={(event)=>{setRedirectLink(event.target.value)
            setUpdatedText("Update Page")
            setUpdated(false)}}></input>
            <div class="info">
              <i class="icon-info-sign"></i>

              <span class="extra-info">The location the user will be redirected to when accessing the igws link. Note: External links must include the "https://"
              </span>
            </div>
          </div>
          <div className='admin__cms__head__input__wrapper'>
            <label htmlFor="admin__cms__checkbox__navlabel">Nav Label:</label>
            <input id="admin__cms__checkbox__navlabel" type="input" value={navLabel} onChange={(event)=>{setNavLabel(event.target.value)
            setUpdatedText("Update Page")
            setUpdated(false)}}></input>
            <div class="info">
              <i class="icon-info-sign"></i>

              <span class="extra-info">The label which will appear in the navigation bar if visible in nav is checked
              </span>
            </div>
          </div>
          <button onClick={()=>cmsUpdatePage()} className={`cms__editor__button__update ${updated ? "updated__cms__editor__button" : ""}`}>{updatedText}</button>
          <button onClick={()=>cmsDeletePage()} className="cms__editor__button__update">Delete Link</button>
      </div>
    </div>
    </>
  )
}

export default AdminCMSLinkEdit