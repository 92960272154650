import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import InfoPopupIcon from '../../../../components/InfoPopupIcon/InfoPopupIcon.jsx';
import { primaryAPILink } from '../../../../config/routes.js'
import NewsLeafletMap from '../../../News/NewsStory/NewsStoryComponents/NewsLeafletMap/NewsLeafletMap.jsx';
import NewsStoryVideo from '../../../News/NewsStory/NewsStoryComponents/NewsStoryVideo/NewsStoryVideo.jsx';
import AdminContentExplain from '../../AdminContentExplain/AdminContentExplain.jsx';
import './AdminCMSPage.css'

const AdminCMSPage = (props) => {
  const [visibileInNav, setVisibileInNav] = useState("");
  const [activeLink, setActiveLink] = useState("");
  const [link, setLink] = useState(props.content.cmsLink)
  const [content, setContent] = useState(JSON.parse(props.content.cmsContent)[0].content)
  const [externalLinks, setExternalLinks] = useState(JSON.parse(props.content.cmsContent)[0].links)
  const [navLabel, setNavLabel] = useState(props.content.title)

  // console.log(content)

  //variable used for displaying if we need to update or not.
  const [updated, setUpdated] = useState(false);
  const [updatedText, setUpdatedText] = useState("Update Page");

  function cmsUpdatePage() {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', jwtKey: localStorage.getItem("loginKey") },
      body: JSON.stringify({
        cmsShowInNav: visibileInNav,
        cmsActive: activeLink,
        cmsContent: (JSON.stringify([{ content: content, links: externalLinks }])),
        title: navLabel,
        cmsLink: link,
        cmsID: props.content.cmsID
      })
    };
    fetch(`${primaryAPILink}/api/cms/updateCMSPage`, requestOptions)
    setUpdatedText("Updated")
    setUpdated(true);
  }

  function cmsDeletePage() {
    if (window.confirm("Are you sure you wish to delete this link?")) {
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', jwtKey: localStorage.getItem("loginKey") }
      }
      fetch(`${primaryAPILink}/api/cms/deleteCMSPage/${props.content.cmsID}`, requestOptions)
      window.location.reload()
    }
  }

  useEffect(() => {
    setVisibileInNav(props.content.cmsShowInNav);
    setActiveLink(props.content.cmsActive)
  }, [])

  function swapLinks(beforeIndex, afterIndex) {
    let tempBefore = externalLinks[beforeIndex];
    let tempAfter = externalLinks[afterIndex];
    let tempPage = externalLinks;
    tempPage[beforeIndex] = tempAfter;
    tempPage[afterIndex] = tempBefore;
    setExternalLinks([...tempPage]);
    setUpdatedText("Update Page")
    setUpdated(false);
  }

  function updateLink(index, location, input) {
    let tempExternalLinks = [...externalLinks]
    tempExternalLinks[index][location] = input;
    setExternalLinks(tempExternalLinks);
    setUpdatedText("Update Page")
    setUpdated(false);
  }

  function drawLinks(index) {
    if (externalLinks.length === 1) {
      return (
        <tr className='admin__cms__editor__link'>
          <td><input value={externalLinks[index][0]} onInput={(event) => updateLink(index, 0, event.target.value)} /></td>
          <td><input value={externalLinks[index][1]} onInput={(event) => updateLink(index, 1, event.target.value)} /></td>
          <td><button onClick={() => { deleteLink(index) }}>❌</button></td>
        </tr>
      )
    }
    if (index === (externalLinks.length - 1)) {
      return (
        <tr className='admin__cms__editor__link'>
          <td><input value={externalLinks[index][0]} onInput={(event) => updateLink(index, 0, event.target.value)} /></td>
          <td><input value={externalLinks[index][1]} onInput={(event) => updateLink(index, 1, event.target.value)} /></td>
          <td><button onClick={() => { swapLinks(index, index - 1) }}>⬆️</button>
            <button onClick={() => { deleteLink(index) }}>❌</button></td>
        </tr>
      )
    }
    if (index === 0) {
      return (
        <tr className='admin__cms__editor__link'>
          <td><input value={externalLinks[index][0]} onInput={(event) => updateLink(index, 0, event.target.value)} /></td>
          <td><input value={externalLinks[index][1]} onInput={(event) => updateLink(index, 1, event.target.value)} /></td>
          <td><button onClick={() => { swapLinks(index, index + 1) }}>⬇️</button>
            <button onClick={() => { deleteLink(index) }}>❌</button></td>
        </tr>
      )
    }
    return (
      <tr className='admin__cms__editor__link'>
        <td><input value={externalLinks[index][0]} onInput={(event) => updateLink(index, 0, event.target.value)} /></td>
        <td><input value={externalLinks[index][1]} onInput={(event) => updateLink(index, 1, event.target.value)} /></td>
        <td><button onClick={() => { swapLinks(index, index - 1) }}>⬆️</button>
          <button onClick={() => { swapLinks(index, index + 1) }}>⬇️</button>
          <button onClick={() => { deleteLink(index) }}>❌</button></td>
      </tr>
    )
  }

  function deleteLink(index) {
    let tempLinks = [...externalLinks];
    tempLinks.splice(index, 1);
    setExternalLinks(tempLinks)
    setUpdatedText("Update Page")
    setUpdated(false);
  }

  function createLink() {
    let tempLinks = [...externalLinks];
    tempLinks.push(["", ""])
    setExternalLinks(tempLinks)
    setUpdatedText("Update Page")
    setUpdated(false);
  }

  function needsUpdate() {
    setUpdatedText("Update Page")
    setUpdated(false);
  }

  function deleteCard(index, subindex){

    let tempContent = [...content];
    tempContent[index].cardList.splice(subindex, 1);
    setContent(tempContent);
    needsUpdate()
}

function deleteGallery(index, subindex){
  let tempContent = [...content];
  tempContent[index].images.splice(subindex, 1);
  setContent(tempContent);
  needsUpdate();
}

function addTableRow(index){
  let tempTable = content[index].tableContent
  let totalColumns = content[index].tableColumns
  let tempTableRow = [];
  for(let i = 0; i < totalColumns; i++){
    tempTableRow.push("")
  }
  tempTable.push(tempTableRow);
  let tempContent = [...content];
  tempContent[index].tableContent = tempTable;
  setContent(tempContent);
  needsUpdate();
}

function addTableColumn(index){
  let tempTable = content[index].tableContent
  console.log(tempTable)
  let totalColumns = content[index].tableColumns
  for(let i = 0; i < content[index].tableContent.length; i++){
    tempTable[i].push("");
  }
  let tempContent = [...content];
  tempContent[index].tableContent = tempTable;
  tempContent[index].tableColumns = totalColumns + 1;
  setContent(tempContent);
  needsUpdate();
}

function deleteTableRow(index, rowNum) {
  console.log(index)
  console.log(rowNum)
  let tempTable = content[index].tableContent;
  tempTable.splice(rowNum, 1);
  let tempContent = [...content];
  tempContent[index].tableContent = tempTable;
  setContent(tempContent);
}

function deleteTableColumn(index){
  let tempTable = content[index].tableContent;
  let totalColumns = content[index].tableColumns
  for(let i = 0; i < content[index].tableContent.length; i++){
    tempTable[i].pop()
  }
  let tempContent = [...content];
  tempContent[index].tableColumns = (totalColumns - 1);
  tempContent[index].tableContent = tempTable;
  setContent(tempContent);
  console.log(content)
}

function checkForLineBreaks(input){
  console.log(input)
  let newInput = input.split("\n");
  function checkEmpty(value){
    if(value !== ""){
      return(value)
    }
  }
  newInput = newInput.filter(checkEmpty);
  console.log(newInput)
  return(newInput);
}

function addNewBulletItem(index) {
  console.log(content[index].bulletContent)
  console.log(content)
  let tempContent = [...content];
  tempContent[index].bulletContent.push("Add Text")
  setContent(tempContent)
  needsUpdate()
}

const [newItemType, setNewItemType] = useState("bold")
function addElement() {
  if(newItemType === "bold") {
      let tempContent = content
      tempContent.push({type: "bold", text: "Add text"});
      setContent([...tempContent])
  } 
  if(newItemType === "paragraph") {
      let tempContent = content
      tempContent.push({type: "paragraph", text: "Add text"});
      setContent([...tempContent])
  } 
  if(newItemType === "map") {
      let tempContent = content
      tempContent.push({type: "map", message: "Add text", lat: '39.171670', long: '-86.52150'});
      setContent([...tempContent])
  } 
  if(newItemType === "button") {
      let tempContent = content
      tempContent.push({type: "button", text: "Add text", link: "https://igws.indiana.edu"});
      setContent([...tempContent])
  } 
  if(newItemType === "image") {
      let tempContent = content
      tempContent.push({type: "image", link: "link goes here", text: "alt text goes here"});
      setContent([...tempContent])
  } 
  if(newItemType === "video") {
      let tempContent = content
      tempContent.push({type: "video", videoLink: "https://www.youtube.com/embed/TvlHsUYsaKk"});
      setContent([...tempContent])
  } 
  if(newItemType === "cards") {
      let tempContent = content
      tempContent.push({type: "cards", cardList: []});
      setContent([...tempContent])
  } 
  if(newItemType === "gallery") {
      let tempContent = content
      tempContent.push({type: "gallery", label: "label goes here", images: []});
      setContent([...tempContent])
  } 
  if(newItemType === "float") {
      let tempContent = content
      tempContent.push({type: "float", link: "", text: "text goes here", alt: "", leftRight: "left"});
      setContent([...tempContent])
  } 
  if(newItemType === "table") {
    let tempContent = content
    tempContent.push({type: "table", tableContent: [["", "",],["",""]], tableColumns: 2});
    setContent([...tempContent])
  } 
  if(newItemType === "bullets") {
    let tempContent = content
    tempContent.push({type: "bullets", bulletContent: ["Add Text"]});
    setContent([...tempContent])
  } 
  if(newItemType === "expand") {
    let tempContent = content
    tempContent.push({type: "expand", expandTitle: "Add Text", expandBody: "Add Text"});
    setContent([...tempContent])
  } 
  setUpdatedText("Update Page")
  setUpdated(false);
}

  function drawContent(index) {
    if (content[index].type === "bold") {
      return (
        <h2 className='news__story__bold__text'
          contentEditable="true"
          suppressContentEditableWarning={true}
          onInput={(event) => {
            content[index].text = event.target.innerText;
            setContent(content);
            needsUpdate()
          }}>{content[index].text}</h2>
      )
    }
    if (content[index].type === "paragraph") {
      return (
        <p className='news__story__paragraph__text' contentEditable="true"
          suppressContentEditableWarning={true}
          onInput={(event) => {
            let ourInput = checkForLineBreaks(event.target.innerText);
            if(ourInput.length === 1){
              console.log("no breaks")
              let tempContent = content;
              tempContent[index].text = event.target.innerText;
              setContent(tempContent);
            } else {
              console.log("breaks")
              let tempContent = content;
              tempContent[index].text = ourInput.shift();
              ourInput.forEach((item, itemIndex)=>{
                tempContent.splice(index+itemIndex, 0, {type: 'paragraph', text: item})
              })
              console.log(tempContent)
              setContent(tempContent);
            }
            needsUpdate()
          }}>{content[index].text}</p>
      )
    }
    if(content[index].type === "image"){
      return(<div className='news__edit__section__item__wrapper__complex__links__needed'>
          <img src={content[index].link} className="news__story__image__popup__wrapper" />
              
          <div className='edit__news__content__individual__item__wrapper__content__section'>
              <label>Link:</label>
              <input value={content[index].link} onChange={
                  (event)=>{
                      let tempContent = [...content];
                      tempContent[index].link = event.target.value;
                      setContent(tempContent);
                      needsUpdate();
                  }
              }/>
              <label>Alt Text:</label>
              <input value={content[index].text} onChange={
                  (event)=>{
                      let tempContent = [...content];
                      tempContent[index].text = event.target.value;
                      setContent(tempContent);
                      needsUpdate();
                  }
              }/>
          </div>
      </div>)
  }
    if (content[index].type === "button") {
      return (
        <div className='news__edit__section__item__wrapper__complex__links__needed'>
          <p className='crimson__link__button' contentEditable="true" onInput={(event) => {
            content[index].text = event.target.innerText;
            setContent(content);
            needsUpdate()
          }} suppressContentEditableWarning={true}>
            {content[index].text}
          </p>
          <div className='edit__news__content__individual__item__wrapper__content__section'>
            <label>Link:</label>
            <input value={content[index].link} onChange={
              (event) => {
                let tempContent = [...content];
                tempContent[index].link = event.target.value;
                setContent(tempContent);
                needsUpdate()
              }
            } />
          </div>
        </div>
      )
    }
    if (content[index].type === "bullets") {
      return (
        <div className='news__edit__section__item__wrapper__complex__links__needed'>
          <ul className="cms__edit__bullets">
            {content[index].bulletContent.map((item, index2)=>{
              return(<li><div className='cms__edit__bullet__div'><p contentEditable="true" onInput={(event) => {
                content[index].bulletContent[index2] = event.target.innerText;
                setContent(content);
                needsUpdate()
              }} suppressContentEditableWarning={true}>{item}</p><button onClick={()=>{
                let tempContent= [...content];
                tempContent[index].bulletContent.splice(index2, 1)
                setContent(tempContent);
                needsUpdate()
              }

              }
                >X</button></div></li>)
            })}
            <button onClick={()=>{
              addNewBulletItem(index)
              console.log("test")
            }}>+</button>
          </ul>
        </div>
      )
    }
    if (content[index].type === "float") {
      return (<div className='news__edit__section__item__wrapper__complex__links__needed'>
        <div className={'news__story__float__text__image ' + content[index].leftRight}>
          <div className='news__story__image__popup__wrapper'>
            <img src={content[index].link} />
          </div>
          <p className='news__story__paragraph__text' contentEditable="true" onInput={(event) => {
            content[index].text = event.target.innerText;
            setContent(content);
            needsUpdate()
          }} suppressContentEditableWarning={true}>{(content[index].text)}</p>
        </div>

        <div className='edit__news__content__individual__item__wrapper__content__section'>
          <label>Link:</label>
          <input value={content[index].link} onChange={
            (event) => {
              let tempContent = [...content];
              tempContent[index].link = event.target.value;
              setContent(tempContent);
              needsUpdate()
            }
          } />
          <label>Alt Text:</label>
          <input value={content[index].alt} onChange={
            (event) => {
              let tempContent = [...content];
              tempContent[index].alt = event.target.value;
              setContent(tempContent);
              needsUpdate()
            }
          } />
          <label>Float left or right:</label>
          <select value={content[index].leftRight} onChange={
            (event) => {
              let tempContent = [...content];
              tempContent[index].leftRight = event.target.value;
              setContent(tempContent);
              needsUpdate()
            }
          }>
            <option>left</option>
            <option>right</option>
          </select>
        </div>
      </div>)
    }
    if (content[index].type === "video") {
      return (<div className='news__edit__section__item__wrapper__complex__links__needed'>
        <NewsStoryVideo videoLink={content[index].videoLink} />

        <div className='edit__news__content__individual__item__wrapper__content__section'>
          <label>Video Link:</label>
          <input value={content[index].videoLink} onChange={
            (event) => {
              let tempContent = [...content];
              tempContent[index].videoLink = event.target.value;
              setContent(tempContent);
              needsUpdate()
            }
          } />
        </div>
      </div>)
    }
    if (content[index].type === "map") {
      return(<div className='news__edit__section__item__wrapper__complex__links__needed'>
        <NewsLeafletMap positionLat={content[index].lat} positionLong={content[index].long} message={content[index].message} />

        <div className='edit__news__content__individual__item__wrapper__content__section'>
          <label>Lat:</label>
          <input value={content[index].lat} onChange={
            (event) => {
              let tempContent = [...content];
              tempContent[index].lat = event.target.value;
              setContent(tempContent);
              needsUpdate()
            }
          } />
          <label>Long:</label>
          <input value={content[index].long} onChange={
            (event) => {
              let tempContent = [...content];
              tempContent[index].long = event.target.value;
              setContent(tempContent);
              needsUpdate()
            }
          } />
          <label>Message:</label>
          <input value={content[index].message} onChange={
            (event) => {
              let tempContent = [...content];
              tempContent[index].message = event.target.value;
              setContent(tempContent);
              needsUpdate()
            }
          } />
        </div>
      </div>)
    }
    if(content[index].type === "cards"){
      return(<div className='news__story__multi__card'>
          {content[index].cardList.map((card, cardIndex)=>{
              return(
              <div className='card__wrapper__news__editor'>
              <figure className="news__story__multi__cards__card">
                  <img src={card.image} />
                  <figcaption contentEditable="true" suppressContentEditableWarning={true} onInput={(event)=>{
                      let tempContent = content;
                      tempContent[index].cardList[cardIndex].text = event.target.innerText;
                      setContent(tempContent);
                      needsUpdate()
                  }}
                  
                  >{content[index].cardList[cardIndex].text}</figcaption>
              </figure>
              <div className='card__cms__admin__index__container'>
              <label>Image Link:</label>
              <input value={content[index].cardList[cardIndex].image} onChange={
                  (event)=>{
                      let tempContent = [...content];
                      tempContent[index].cardList[cardIndex].image = event.target.value;
                      setContent(tempContent);
                      needsUpdate()
                  }
              }/>
              </div>
              <div className='card__cms__admin__index__container'>
              <label>Link:</label>
              <input value={content[index].cardList[cardIndex].link} onChange={
                  (event)=>{
                      let tempContent = [...content];
                      tempContent[index].cardList[cardIndex].link = event.target.value;
                      setContent(tempContent);
                      needsUpdate()
                  }
              }/>
              </div>
              <button onClick={()=>deleteCard(index, cardIndex)}>Delete</button>
              </div>
              )
          })}
          <button onClick={()=>{
              let tempContent= [...content];
              tempContent[index].cardList.push({image: "", text: ""})
              setContent(tempContent);
              needsUpdate()
          }} >Create new card</button>
      </div>)

  }
  if(content[index].type === "gallery"){
    return(<div className='news__story__gallery'>
        <label contentEditable="true" suppressContentEditableWarning={true} 
        onInput={(event)=>{
                    let tempContent = content;
                    tempContent[index].label = event.target.innerText;
                    setContent(tempContent);
                    needsUpdate();
                }}>{content[index].label}</label>
        {content[index].images.map((image, imageIndex)=>{
            return(
                <><div className='news__story__image__container__gallery__section__wrapper'>
            <div className='news__story__image__popup__wrapper'>
                <img src={image.src} alt={image.text}/>
            </div>
            <label className='edit__news__story__nonmoving__label'>Link:</label>
            <input value={content[index].images[imageIndex].src} onChange={
                (event)=>{
                    let tempContent = [...content];
                    tempContent[index].images[imageIndex].src = event.target.value;
                    setContent(tempContent);
                    needsUpdate();
                }
            }/>
            
            <label className='edit__news__story__nonmoving__label'>Alt text:</label>
            <input value={content[index].images[imageIndex].text} onChange={
                (event)=>{
                    let tempContent = [...content];
                    tempContent[index].images[imageIndex].text = event.target.value;
                    setContent(tempContent);
                    needsUpdate();
                }
            }/>
            <button onClick={()=>deleteGallery(index, imageIndex)}>Delete</button>
            </div>
            </>
            )
        })}
        <button onClick={()=>{
            let tempContent= [...content];
            tempContent[index].images.push({src: "", text: ""})
            setContent(tempContent);
            needsUpdate();
        }}>Create new card</button>
    </div>)}

  if(content[index].type ==="expand"){
    return(<>
      <div className='cms__expand__container'>
        <div className='cms__expand__container__title'>
        <input value={content[index].expandTitle} onChange={(event)=>{
                      let tempContent = [...content];
                      tempContent[index].expandTitle = event.target.value
                      setContent(tempContent);
                      needsUpdate();
                    }}></input>
        </div>
        <div className='cms__expand__container__body'>
          <div contentEditable={true} suppressContentEditableWarning={true} innerText={content[index].expandBody} onInput={(event)=>{
                    let tempContent = content;
                    tempContent[index].expandBody = event.target.innerText;
                    setContent(tempContent);
                    needsUpdate();
                }}>{content[index].expandBody}</div>
        </div>
      </div>
    </>)
  }

    if(content[index].type ==="table"){
      return(
        <><div className='cms__table__container'>
            <table className='cms__table'>
              {content[index].tableContent.map((row, rowNum)=>{
                if(rowNum === 0){
                  return(<tr>
                    {row.map((item, columnNum)=>{
                    return(<th><input value={item} onChange={(event)=>{
                      let tempContent = [...content];
                      tempContent[index].tableContent[rowNum][columnNum] = event.target.value
                      setContent(tempContent);
                      needsUpdate();
                    }}></input></th>)
                  })}
                  <td><button onClick={()=>deleteTableRow(index, rowNum)}>X</button></td>
                  </tr>)
                    

                  
                }
                return(
                  <tr>
                  {row.map((item, columnNum)=>{
                  return(<td><input value={item} onChange={(event)=>{
                    let tempContent = [...content];
                    tempContent[index].tableContent[rowNum][columnNum] = event.target.value
                    setContent(tempContent);
                    needsUpdate();
                  }}></input></td>)
                })}
                <td><button onClick={()=>deleteTableRow(index, rowNum)}>X</button></td>
                </tr>)
              })}
            </table>
            <button onClick={()=>addTableColumn(index)}>add column</button>
            <button onClick={()=>addTableRow(index)}>add row</button>
            <button onClick={()=>deleteTableColumn(index)}>delete column</button>
            </div>
            </>
      )
    }
    
  }

  function renderEditOptions(index){
    if(content.length === 1){
        return(
            <div className='news__edit__content__item__edit__section'>
                <div className='news__edit__content__item__edit__arrows'>
                </div>
                <div className='news__edit__content__item__edit__section__trash' onClick={()=>deleteElement(index)}>🗑️</div>
            </div>
            )
    }

    if((index=== (content.length - 1))){
        return(
            <div className='news__edit__content__item__edit__section'>
                <div className='news__edit__content__item__edit__arrows'>
                    <div className='news__edit__content__item__edit__section__arrow' onClick={() => swapItems(index, (index - 1))
                    }>⬆️</div>
                </div>
                <div className='news__edit__content__item__edit__section__trash' onClick={()=>deleteElement(index)}>🗑️</div>
            </div>)
    }

    if(index=== 0){
        return(
            <div className='news__edit__content__item__edit__section'>
                <div className='news__edit__content__item__edit__arrows'>
                <div className='news__edit__content__item__edit__section__arrow' onClick={() => swapItems(index, (index + 1))}>⬇️</div>
                </div>
                <div className='news__edit__content__item__edit__section__trash' onClick={()=>deleteElement(index)}>🗑️</div>
            </div>)
    }

    return(
    <div className='news__edit__content__item__edit__section'>
        <div className='news__edit__content__item__edit__arrows'>
            <div className='news__edit__content__item__edit__section__arrow' onClick={() => swapItems(index, (index - 1))}>⬆️</div>
            <div className='news__edit__content__item__edit__section__arrow' onClick={() => swapItems(index, (index + 1))}>⬇️</div>
        </div>
        <div className='news__edit__content__item__edit__section__trash' onClick={()=>deleteElement(index)}>🗑️</div>
    </div>
    )
}
function swapItems(beforeIndex, afterIndex) {
  let tempBefore = content[beforeIndex];
  let tempAfter = content[afterIndex];
  let tempPage = content;
  tempPage[beforeIndex] = tempAfter;
  tempPage[afterIndex] = tempBefore;
  setContent([...tempPage]);
  needsUpdate()
}

function deleteElement(index) {
  let tempContent = [...content];
  tempContent.splice(index, 1);
  setContent(tempContent)
  needsUpdate();
}



  return (
    <>
      <div className='page__type__header'><h2>CMS Page</h2></div>
      <div className='admin__cms__content__head__wrapper'>
        <div className='admin__cms__content__head'>
          <div className='admin__cms__head__input__wrapper'>
            <label htmlFor="admin__cms__checkbox__nav">Visible in Nav:</label>
            <input id="admin__cms__checkbox__nav" type="checkbox" checked={visibileInNav} onClick={() => {
              setVisibileInNav(!visibileInNav)
              setUpdatedText("Update Page")
              setUpdated(false)
            }}></input>
            <InfoPopupIcon text={["If checked the nav label will appear in the navbar at the top of the screen."]} />
          </div>
          <div className='admin__cms__head__input__wrapper'>
            <label htmlFor="admin__cms__checkbox__active">Active Link:</label>
            <input id="admin__cms__checkbox__active" type="checkbox" checked={activeLink} onClick={() => {
              setActiveLink(!activeLink)
              setUpdatedText("Update Page")
              setUpdated(false)
            }}></input>
            <InfoPopupIcon text={["If checked the link will be active and usable by anyone who accesses the website."]} />

          </div>

          <div className='admin__cms__head__input__wrapper'>
            <label htmlFor="admin__cms__checkbox__link">Link:</label>
            <input id="admin__cms__checkbox__link" type="input" value={link} onChange={(event) => {
              setLink(event.target.value)
              setUpdatedText("Update Page")
              setUpdated(false)
            }}></input>
            <InfoPopupIcon text={["This will be the actual link a user must go to in order to be redirected. ie. igws.indiana.edu/(parentGroup)/(link)"]} />
          </div>
          <div className='admin__cms__head__input__wrapper'>
            <label htmlFor="admin__cms__checkbox__navlabel">Nav Label:</label>
            <input id="admin__cms__checkbox__navlabel" type="input" value={navLabel} onChange={(event) => {
              setNavLabel(event.target.value)
              setUpdatedText("Update Page")
              setUpdated(false)
            }}></input>
            <InfoPopupIcon text={["The label which will appear in the navigation bar if visible in nav is checked"]} />
          </div>
          <button onClick={() => cmsUpdatePage()} className={`cms__editor__button__update ${updated ? "updated__cms__editor__button" : ""}`}>{updatedText}</button>
          <button onClick={() => cmsDeletePage()} className="cms__editor__button__update">Delete Page</button>
        </div>
      </div>
      <h2 className='right__page__links__cms'>Right Page Links</h2>
      <div className='admin__cms__editor__links__container'>
      <InfoPopupIcon text={["Links the appear to the right side of a cms page.", "If no links are added, the right side window does not appear"]} />
        <table>
          <tr>
            <td>Label<InfoPopupIcon text={["Label for the link"]} /></td>
            <td>Link<InfoPopupIcon text={["Where the link takes you.","External links should use the 'https://link.com' format", "Internal links should use the format /parent/link. For example: to get to the facilities page do '/about/facilities'"]} /></td>
            <td>Reorder/ Delete</td>
          </tr>
          {externalLinks.map((item, index) => {

            return (drawLinks(index))

          })}
        </table>
        <button onClick={() => createLink()} className="cms__new__link__button">New Link</button>
      </div>
      <AdminContentExplain text={["Paragraph elements can use custom markup to modify how they are displayed:", "Make text bold by surrounding it with two *'s ex: **this is bold**", "Make text italic by surrounding it with two %'s ex: %%this is italic%%", "Make text a link by surrounding it with [text](link) ex: [IU Website](https://iu.edu)","You may also write custom html directly within paragraph elements, but only do this if you know what you are doing"]}></AdminContentExplain>
      <div className='admin__cms__content__container'>
        {content.map((item, index) => {
          return (
            <div className='admin__cms__content__item__wrapper'>
            {drawContent(index)}
            {renderEditOptions(index)}
            </div>
            )
        })}
        
      </div>
      <div className='add__new__cms__item'>
      <label>Create New Element</label>
        <select onChange={(event)=>{
            setNewItemType(event.target.value)}}>
            <option value="bold">Heading</option>
            <option value="paragraph">Paragraph</option>
            <option value="image">Image</option>
            <option value="float">Float</option>
            <option value="map">Map</option>
            <option value="video">Video</option>
            <option value="cards">Cards</option>
            <option value="gallery">Gallery</option>
            <option value="button">Button</option>
            <option value="table">Table</option>
            <option value="bullets">Bullets</option>
            <option value="expand">Expand</option>
        </select>
        <button onClick={()=>addElement(newItemType)}>Add</button>
      </div>
     
    </>
  )
}

export default AdminCMSPage