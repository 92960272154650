import React from 'react'
import CrimsonBlockHeader from '../../../components/CrimsonBlockHeader/CrimsonBlockHeader'
import HomeSwiper from '../../Home/HomeSwiper/HomeSwiper'
import SwiperSlideHero from '../../Home/HomeSwiper/SwiperSlideHero/SwiperSlideHero'
import AdminContentExplain from '../AdminContentExplain/AdminContentExplain'
import AdminNavbar from '../AdminNavbar/AdminNavbar'
import AdminHeroEditorForm from './AdminHeroEditorForm/AdminHeroEditorForm'

const AdminHeroEditor = () => {
  return (
    <div className='news__edit__page__wrapper'>
    <AdminNavbar active="hero"/>
    <div className='news__edit__page__content__section'>
        <CrimsonBlockHeader text="Hero Editor" />
        <AdminContentExplain text={["The hero editor changes the swiper that is displayed on the home screen."]}></AdminContentExplain>
        <div style={{backgroundColor: 'var(--rvt-black-100)', paddingBottom: '20px'}}>
          <h2 style={{color: 'var(--rvt-black)', fontFamily: 'var(--rvt-font)', textAlign: 'center'}}>Current Hero:</h2>
          <div style={{width: '80%', margin: '0 auto'}}>
            <HomeSwiper/>
          </div>
        </div>
        <AdminHeroEditorForm />
    </div>
</div>
  )
}

export default AdminHeroEditor