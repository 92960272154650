import React, { useState } from 'react';
import StaffCard from '../../../Staff/StaffCard/StaffCard';
import './StaffDisplay.css'; // Import your CSS file for styling
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const StaffDisplay = (props) => {
  const [showResults, setShowResults] = useState(true);

  const toggleResults = () => {
    setShowResults(!showResults);
  };

  return (
    <div className='advanced-search-term'>
      <h2>
        Staff Results: 
        <button onClick={toggleResults}>
        {showResults ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}
      </button>
      </h2>
      {showResults && (
        <div className="staff-grid">
          {props.staffData.map((staff, index) => {
            return (
              <StaffCard
                key={index}
                link={`/staff/${staff.username}`}
                image={staff.picture}
                fName={staff.fName}
                lName={staff.lName}
                role={staff.title}
              />
            );
          })}
        </div>
      )}
    </div>
  );
}

export default StaffDisplay;
