import React from 'react'
import { useState, useEffect } from 'react'
import CrimsonBlockHeader from '../../../components/CrimsonBlockHeader/CrimsonBlockHeader';
import CMSContentDisplay from './CMSContentDisplay/CMSContentDisplay';
import CMSContentNav from './CMSContentNav/CMSContentNav';
import './CMSPage.css'
import { Link, useLocation } from 'react-router-dom';

const CMSPage = (props) => {
  
  const content=JSON.parse(props.content)
  const pageContent = content;
  const [ourLinks, setOurLinks] = useState(<></>)
  const [activeLinks, setActiveLinks] = useState(false)
  const buttonContent = `>`;
  const [showButton, setShowButton] = useState(false);
  useEffect(()=>{
    //check if there are any links or not;
    if(pageContent[0].links.length > 0){
      setOurLinks(<CMSContentNav content={pageContent[0].links} active={activeLinks}/>)
      setShowButton(true);
    } 
  },[activeLinks]);

  const location = useLocation();
  const [breadCrumb, setBreadCrumb] = useState(<></>)
  useEffect(() => {
    const pathSegments = location.pathname.split('/').filter(Boolean);

    //Get breadcrumbs and create an element for it.
    let currentLink = ""
    let currentElement = []
    pathSegments.forEach((segment)=>{
      currentLink += `/${segment}`;
      currentElement.push(<><Link to={currentLink}>{segment} </Link> / </>)
    })
    setBreadCrumb(<p className='breadcrumbs'>
      {currentElement.map((item, index)=>{return(<React.Fragment key={index}>{item}</React.Fragment>)})}
      </p>)
  }, [location]);


  return (
    <div className='cmsPage'>
    <CrimsonBlockHeader text={props.pageTitle}/>
    
    <div className={showButton ? 'cmsPageWrapper showButton' : 'cmsPageWrapper'}>
      <button className={activeLinks ? "display__links active" : "display__links"} onClick={()=>setActiveLinks(!activeLinks)}>{buttonContent}</button>
      <CMSContentDisplay content={pageContent[0].content} breadcrumbs={breadCrumb}/>
      {ourLinks}
    </div>
    </div>
  )
}

export default CMSPage