import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { primaryAPILink } from '../../../../config/routes.js'
import './AdminHeroEditorForm.css'

const AdminHeroEditorForm = () => {
    const [swiperList, setSwiperList] = useState([])

    useEffect(() => {
      fetch(`${primaryAPILink}/api/eventbanner/homehero`)
        .then(res => res.json())
        .then((result) => {
          if (result.length > 0) {
            setSwiperList(result)
            console.log(swiperList)
          }
        })
    }, []
    )

    function updateHero(){
      swiperList.map((item, index)=>{
        console.log(item)
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', jwtKey: localStorage.getItem("loginKey") },
          body: JSON.stringify({
              swiperID: item.swiperID,
              link: item.link,
              heading: item.heading,
              paragraph: item.paragraph,
              category: item.category,
              image: item.image,
              })
      };
      fetch(`${primaryAPILink}/api/eventbanner/updateHomeHero`, requestOptions)
      })

    }

    function deleteHero(swiperID){
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', jwtKey: localStorage.getItem("loginKey") },
        body: JSON.stringify({
            swiperID: swiperID,
            })
      };
      fetch(`${primaryAPILink}/api/eventbanner/deleteHomeHero`, requestOptions)
      window.location.reload();
      }

      function createNewHero(){
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', jwtKey: localStorage.getItem("loginKey") },
        };
        fetch(`${primaryAPILink}/api/eventbanner/createHomeHero`, requestOptions)
        window.location.reload();
        }
    

    


  return (
    <div className='home__hero__swiper__wrapper'>

        <button onClick={()=>updateHero()}>Update</button>

        <button onClick={()=>createNewHero()}>Create New Page</button>
        
        {swiperList.map((item, index)=>{
            return(
            <div className='admin__swiper__hero__form' key={index}>
                <button className='admin__swiper__hero__form__delete' onClick={()=>{
                  deleteHero(item.swiperID)
                }}>X</button>
                <div className='admin__swiper__hero__item__form__wrapper'>
                    <label>Category</label>
                    <input defaultValue={item.category}
                    
                    onInput={(event)=>{
                      let tempList = [...swiperList];
                      tempList[index].category = event.target.value;
                      setSwiperList(swiperList);
                    }}></input>
                </div>
                <div className='admin__swiper__hero__item__form__wrapper'>
                    <label>Heading</label>
                    <input defaultValue={item.heading}
                    onInput={(event)=>{
                      let tempList = [...swiperList];
                      tempList[index].heading = event.target.value;
                      setSwiperList(swiperList);
                    }}></input>
                </div>
                <div className='admin__swiper__hero__item__form__wrapper'>
                    <label>Description (optional)</label>
                    <input defaultValue={item.paragraph}
                    
                    onInput={(event)=>{
                      let tempList = [...swiperList];
                      tempList[index].paragraph = event.target.value;
                      setSwiperList(swiperList);
                    }}></input>
                </div>
                <div className='admin__swiper__hero__item__form__wrapper'>
                    <label>Background Image</label>
                    <input defaultValue={item.image}
                    onInput={(event)=>{
                      let tempList = [...swiperList];
                      tempList[index].image = event.target.value;
                      setSwiperList(swiperList);
                    }}></input>
                </div>
                <div className='admin__swiper__hero__item__form__wrapper'>
                    <label>Link</label>
                    <input defaultValue={item.link}
                    
                    onInput={(event)=>{
                      let tempList = [...swiperList];
                      tempList[index].link = event.target.value;
                      setSwiperList(swiperList);
                    }}></input>
                </div>
            </div>)
        })}
    </div>
  )
}

export default AdminHeroEditorForm