import React, { useState, useEffect } from 'react';
import CrimsonBlockHeader from '../../components/CrimsonBlockHeader/CrimsonBlockHeader';
import './IGNIS.css';
import NewsStoryBold from '../News/NewsStory/NewsStoryComponents/NewsStoryBold/NewsStoryBold';
import NewsStoryParagraph from '../News/NewsStory/NewsStoryComponents/NewsStoryParagraph/NewsStoryParagraph';
import Highlighter from 'react-highlight-words';
import IGNISModal from './IGNISModal/IGNISModal';
import { primaryAPILink } from '../../config/routes';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import Indiana from '../../assets/Images/Indiana';
import '../../components/CrimsonLinkButton/CrimsonLinkButton'
import CrimsonLinkButton from '../../components/CrimsonLinkButton/CrimsonLinkButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const IGNIS = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [allResults, setAllResults] = useState([]);
    const [filteredResults, setFilteredResults] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [sortType, setSortType] = useState('alphabetical');
    const [selectedItem, setSelectedItem] = useState(null); // Track selected item for modal display
    const [loading, setLoading] = useState(true); // Track loading state
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [cosuna1, setCosuna1] = useState(false)
    const [cosuna2, setCosuna2] = useState(false)
    const [cosuna3, setCosuna3] = useState(false)
    const [cosuna4, setCosuna4] = useState(false)
    const [cosuna5, setCosuna5] = useState(false)
    const [pennsylvanian, setPennsylvanian] = useState(false)
    const [mississippian, setMississippian] = useState(false)
    const [devonian, setDevonian] = useState(false)
    const [silurian, setSilurian] = useState(false)
    const [ordovician, setOrdovician] = useState(false)
    const [cambrian, setCambrian] = useState(false)
    const [precambrian, setPrecambrian] = useState(false)


    document.title = 'IGWS | Indiana Geologic Names Information System';
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${primaryAPILink}/api/ignis//search?q=&regionIDs=${cosuna1 ? "1," : ""}${cosuna2 ? "2," : ""}${cosuna3 ? "3," : ""}${cosuna4 ? "4," : ""}${cosuna5 ? "5," : ""}&ages=${pennsylvanian ? "Pennsylvanian," : ""}${mississippian ? "Mississippian," : ""}${devonian ? "Devonian," : ""}${silurian ? "Silurian," : ""}${ordovician ? "Ordovician," : ""}${cambrian ? "Cambrian," : ""}${precambrian ? "Precambrian," : ""}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }
                const data = await response.json();
                console.log(data)
                setAllResults(data);
                filterAndSortResults(searchTerm, data, sortType); // Ensure to pass sortType
                setLoading(false); // Set loading to false after data is fetched
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [cosuna1, cosuna2, cosuna3, cosuna4, cosuna5, pennsylvanian, mississippian, devonian, silurian, ordovician, cambrian, precambrian]); // Empty dependency array ensures this effect runs only once on component mount

    useEffect(() => {
        // Check if there's a StratId parameter in the URL
        const urlParams = new URLSearchParams(location.search);
        const stratId = urlParams.get('StratId');

        if (stratId && !loading) {
            // Find the item by StratId from allResults
            const item = allResults.find(result => result.StratId === stratId);
            if (item) {
                setSelectedItem(item); // Set selected item to open modal
                setIsModalOpen(true); // Open the modal
            }
        }
    }, [location.search, allResults, loading]);

    useEffect(() => {
        if (isModalOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }

        return () => {
            document.body.style.overflow = 'auto'; // Cleanup in case component unmounts
        };
    }, [isModalOpen]);

    // Function to handle search term change
    const handleSearchChange = (value) => {
        setSearchTerm(value);
        filterAndSortResults(value, allResults, sortType); // Call filter and sort function on search term change
    };

    // Function to handle sort type change
    const handleSortChange = (e) => {
        const selectedSortType = e.target.value;
        setSortType(selectedSortType); // Update sort type state
        filterAndSortResults(searchTerm, filteredResults, selectedSortType); // Filter and sort based on new sort type
    };

    // Function to filter and sort results based on search term and sort type
    const filterAndSortResults = (value, results, type) => {
        let filtered = [...results];

        if (value.trim() !== '') {
            filtered = filtered.filter(result =>
                result.StratigraphicName.toLowerCase().includes(value.trim().toLowerCase())
            );
        }

        if (type === 'alphabetical') {
            filtered.sort((a, b) => a.StratigraphicName.localeCompare(b.StratigraphicName));
        } else if (type === 'stratigraphic') {
            // Assuming results are already sorted by StratOrder from the API
            // Adjust sorting logic based on your backend data structure if needed
            filtered.sort((a, b) => a.StratOrder - b.StratOrder);
        }

        setFilteredResults(filtered); // Set filtered and sorted results
    };

    // Function to handle click on an item to open modal
    const handleItemClick = (item) => {
        document.body.classList.add('no-scroll');
        setSelectedItem(item); // Set selected item for modal display
        navigate(`/ignisdev?StratId=${item.StratId}`); // Update URL when modal opens
    };

    // Function to close modal
    const closeModal = () => {
        document.body.classList.remove('no-scroll');
        setSelectedItem(null); // Reset selected item to close modal
        navigate('/ignisdev'); // Update URL when modal closes
        setIsModalOpen(false);
    };


    // Calculate the count of filtered results
    const filteredCount = filteredResults.length;

    const initialIGNISTextOpen = localStorage.getItem('ignisTextOpen') === 'true';
    const [IGNISTextOpen, setIGNISTextOpen] = useState(initialIGNISTextOpen)

    function toggleIGNISText() {

        const newValue = !IGNISTextOpen;
        setIGNISTextOpen(newValue);
        localStorage.setItem('ignisTextOpen', newValue);
    }

    const [showFiltersMobile, setShowFiltersMobile] = useState(false)

    return (
        <div className='ignisPageWrapper'>
            <CrimsonBlockHeader text="IGNIS" />

            {/* Main content display */}
            <div className='ignisWrapper'>
                <div className='cmsContentDisplayWrapper'>
                    <div className='cmsContentDisplay'>
                        <p className='breadcrumbs'><Link to="/research">Research</Link> / <Link to="/research/minerals">Minerals</Link> / <Link to="/ignis">IGNIS</Link></p>

                        <div className="colapsableWhatIsEnvAssess" onClick={toggleIGNISText}><NewsStoryBold text="What is the Indiana Geologic Names Information System?" /> {IGNISTextOpen ? (<FontAwesomeIcon icon={faEye} className='news__story__bold__text' />) : (<FontAwesomeIcon icon={faEyeSlash} className='news__story__bold__text' />)}</div>
                        {IGNISTextOpen ? (
                            <>
                                <NewsStoryParagraph text="The Indiana Geologic Names Information System provides access to current information on geologic names recognized by the Indiana Geological and Water Survey (IGWS). It details formal stratigraphic names used in Indiana, including IGWS nomenclature for specific units, equivalent correlative units, miscellaneous and abandoned names, and higher-rank stratigraphic terms. The coverage spans five Indiana regions as delineated in the Midwestern Basin and Arches Region chart (AAPG COSUNA Chart, 1984), documenting geographic changes in rank, lithic terms, and assignments to higher-ranked units." />
                                <CrimsonLinkButton text="Generalized Stratigraphic Column" link="https://data.igws.indiana.edu/pages/download.php?direct=1&noattach=true&ref=41874&ext=pdf&k=" />
                                <CrimsonLinkButton text="USGS Geolex" link="https://ngmdb.usgs.gov/Geolex/search" />
                                <CrimsonLinkButton text="North American Stratigraphic Code" link="https://ngmdb.usgs.gov/Info/NACSN/Code2/code2.html" />
                                <CrimsonLinkButton text="AAPG COSUNA Chart" link="https://store.aapg.org/detail.aspx?id=DD0013" />
                            </>
                        )
                            :
                            <></>
                        }

                        <div className='ignisNavMobile'>
                            <div className='ignisNavContent'>
                                <div className='ignisNavText'>
                                <NewsStoryBold text="Filters" />
                                    <p>Use the map or buttons below to filter by region</p>
                                </div>
                                <Indiana setCosuna1={setCosuna1} setCosuna2={setCosuna2} setCosuna3={setCosuna3} setCosuna4={setCosuna4} setCosuna5={setCosuna5} cosuna1={cosuna1} cosuna2={cosuna2} cosuna3={cosuna3} cosuna4={cosuna4} cosuna5={cosuna5} />

                                <div className='cosuna-buttons-wrapper'>
                                    <button className={cosuna1 ? "cosuna-button cosuna-button-1 active" : "cosuna-button cosuna-button-1"} onClick={() => setCosuna1(!cosuna1)}>Region 11</button>
                                    <button className={cosuna2 ? "cosuna-button cosuna-button-2 active" : "cosuna-button cosuna-button-2"} onClick={() => setCosuna2(!cosuna2)}>Region 12</button>
                                    <button className={cosuna3 ? "cosuna-button cosuna-button-3 active" : "cosuna-button cosuna-button-3"} onClick={() => setCosuna3(!cosuna3)}>Region 13</button>
                                    <button className={cosuna4 ? "cosuna-button cosuna-button-4 active" : "cosuna-button cosuna-button-4"} onClick={() => setCosuna4(!cosuna4)}>Region 14</button>
                                    <button className={cosuna5 ? "cosuna-button cosuna-button-5 active" : "cosuna-button cosuna-button-5"} onClick={() => setCosuna5(!cosuna5)}>Region 15</button>
                                </div>
                                <div className='system-checkboxes-wrapper'>
                                    <label><input type="checkbox" checked={pennsylvanian} onChange={(e) => setPennsylvanian(e.target.checked)} /> Pennsylvanian</label>
                                    <label><input type="checkbox" checked={mississippian} onChange={(e) => setMississippian(e.target.checked)} /> Mississippian</label>
                                    <label><input type="checkbox" checked={devonian} onChange={(e) => setDevonian(e.target.checked)} /> Devonian</label>
                                    <label><input type="checkbox" checked={silurian} onChange={(e) => setSilurian(e.target.checked)} /> Silurian</label>
                                    <label><input type="checkbox" checked={ordovician} onChange={(e) => setOrdovician(e.target.checked)} /> Ordovician</label>
                                    <label><input type="checkbox" checked={cambrian} onChange={(e) => setCambrian(e.target.checked)} /> Cambrian</label>
                                    <label><input type="checkbox" checked={precambrian} onChange={(e) => setPrecambrian(e.target.checked)} /> Precambrian</label>

                                </div>
                                </div>
                                </div>


                                <NewsStoryBold text="Results" />

                                {/* Search input and sort dropdown */}
                                <div className='resultsItemsWrapper'>
                                    <div className='ignis-search-container'>
                                        <div className='ignis-search-item '>
                                            <label htmlFor="search-input" className='ignis-label'>Search Terms</label>
                                            <input
                                                type="text"
                                                id="search-input"
                                                value={searchTerm}
                                                onChange={(e) => handleSearchChange(e.target.value)}
                                                placeholder="Search..."
                                                className='ignis-search-input'
                                            />
                                        </div>
                                        <div className='ignis-search-item-select'>
                                            <label htmlFor="sort-select" className='ignis-label'>Sort By</label>
                                            <select id="sort-select" value={sortType} onChange={handleSortChange}>
                                                <option value="alphabetical">Alphabetical</option>
                                                <option value="stratigraphic">Stratigraphic</option>
                                            </select>
                                        </div>
                                    </div>
                                    <p>Your search found <span className='ignis-filter-bold'>{filteredCount}</span> terms.</p>
                                    <ul className='ignis-results-list'>
                                        {filteredResults.map((result, index) => (
                                            <li key={index} onClick={() => handleItemClick(result)}>
                                                <span className='ignis-item-index'>{index + 1}. </span>
                                                <span className='ignis-result-item'>{result.StratigraphicName}</span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>

                                {/* Modal for displaying detailed item information */}
                                {selectedItem && (
                                    <IGNISModal isOpen={true} closeModal={closeModal} item={selectedItem} />
                                )}
                            </div>
                        </div>
                        <div className='ignisNav'>
                            <div className='ignisNavContent'>
                                <div className='ignisNavText'>
                                    <h2>Filters</h2>
                                    <p>Use the map or buttons below to filter by region</p>
                                </div>
                                <Indiana setCosuna1={setCosuna1} setCosuna2={setCosuna2} setCosuna3={setCosuna3} setCosuna4={setCosuna4} setCosuna5={setCosuna5} cosuna1={cosuna1} cosuna2={cosuna2} cosuna3={cosuna3} cosuna4={cosuna4} cosuna5={cosuna5} />

                                <div className='cosuna-buttons-wrapper'>
                                    <button className={cosuna1 ? "cosuna-button cosuna-button-1 active" : "cosuna-button cosuna-button-1"} onClick={() => setCosuna1(!cosuna1)}>Region 11</button>
                                    <button className={cosuna2 ? "cosuna-button cosuna-button-2 active" : "cosuna-button cosuna-button-2"} onClick={() => setCosuna2(!cosuna2)}>Region 12</button>
                                    <button className={cosuna3 ? "cosuna-button cosuna-button-3 active" : "cosuna-button cosuna-button-3"} onClick={() => setCosuna3(!cosuna3)}>Region 13</button>
                                    <button className={cosuna4 ? "cosuna-button cosuna-button-4 active" : "cosuna-button cosuna-button-4"} onClick={() => setCosuna4(!cosuna4)}>Region 14</button>
                                    <button className={cosuna5 ? "cosuna-button cosuna-button-5 active" : "cosuna-button cosuna-button-5"} onClick={() => setCosuna5(!cosuna5)}>Region 15</button>
                                </div>
                                <div className='system-checkboxes-wrapper'>
                                    <label><input type="checkbox" checked={pennsylvanian} onChange={(e) => setPennsylvanian(e.target.checked)} /> Pennsylvanian</label>
                                    <label><input type="checkbox" checked={mississippian} onChange={(e) => setMississippian(e.target.checked)} /> Mississippian</label>
                                    <label><input type="checkbox" checked={devonian} onChange={(e) => setDevonian(e.target.checked)} /> Devonian</label>
                                    <label><input type="checkbox" checked={silurian} onChange={(e) => setSilurian(e.target.checked)} /> Silurian</label>
                                    <label><input type="checkbox" checked={ordovician} onChange={(e) => setOrdovician(e.target.checked)} /> Ordovician</label>
                                    <label><input type="checkbox" checked={cambrian} onChange={(e) => setCambrian(e.target.checked)} /> Cambrian</label>
                                    <label><input type="checkbox" checked={precambrian} onChange={(e) => setPrecambrian(e.target.checked)} /> Precambrian</label>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                );
};

                export default IGNIS;
