import React, { useState, useEffect } from 'react';
import './Miner.css';
import song from './assets/song.mp3';
import caveSong1 from './assets/caveSong1.mp3'; // Example cave song imports
import caveSong2 from './assets/caveSong2.mp3';
import caveSong3 from './assets/caveSong3.mp3';
import caveSong4 from './assets/caveSong4.mp3';
import caveSong5 from './assets/caveSong5.mp3';

// Define gem collections with titles
const gemData = [
  { title: 'title', gems: [], pageNum: "1" }, // Empty first page
  { title: 'Cave 1 Collection', gems: [
    { name: 'Gem 1', description: 'Description for Gem 1', acquired: false },
    { name: 'Gem 2', description: 'Description for Gem 2', acquired: false },
    { name: 'Gem 3', description: 'Description for Gem 3', acquired: false },
    // Add more gems as needed
  ], pageNum: "2" },
  { title: 'Cave 2 Collection', gems: [
    { name: 'Cave Gem 1', description: 'Description for Cave Gem 1', acquired: false },
    { name: 'Cave Gem 2', description: 'Description for Cave Gem 2', acquired: false },
    { name: 'Cave Gem 3', description: 'Description for Cave Gem 3', acquired: false },
    // Add more cave-specific gems as needed
  ], pageNum: "3" },
  { title: 'Cave 3 Collection', gems: [
    { name: 'Cave Gem 4', description: 'Description for Cave Gem 4', acquired: false },
    { name: 'Cave Gem 5', description: 'Description for Cave Gem 5', acquired: false },
    { name: 'Cave Gem 6', description: 'Description for Cave Gem 6', acquired: false },
    // Add more cave-specific gems as needed
  ], pageNum: "4" },
  { title: 'Cave 4 Collection', gems: [
    { name: 'Cave Gem 4', description: 'Description for Cave Gem 4', acquired: false },
    { name: 'Cave Gem 5', description: 'Description for Cave Gem 5', acquired: false },
    { name: 'Cave Gem 6', description: 'Description for Cave Gem 6', acquired: false },
    // Add more cave-specific gems as needed
  ], pageNum: "5" },
  { title: 'Cave 5 Collection', gems: [
    { name: 'Cave Gem 4', description: 'Description for Cave Gem 4', acquired: false },
    { name: 'Cave Gem 5', description: 'Description for Cave Gem 5', acquired: false },
    { name: 'Cave Gem 6', description: 'Description for Cave Gem 6', acquired: false },
    // Add more cave-specific gems as needed
  ], pageNum: "6" },
  
  { title: 'Empty Page', gems: [], pageNum: "7" }, // Empty last page
];

const Miner = () => {
  const [gameStarted, setGameStarted] = useState(false);
  const [cavePopupVisible, setCavePopupVisible] = useState(false);
  const [selectedCave, setSelectedCave] = useState(null);
  const [collectionVisible, setCollectionVisible] = useState(false);
  const [pageIndex, setPageIndex] = useState(0); // Start with first collection
  const [selectedGem, setSelectedGem] = useState(null);
  const [musicVolume, setMusicVolume] = useState(0.5); // Default volume 50%
  const [soundsVolume, setSoundsVolume] = useState(0.5); // Default volume 50%
  const [musicPlaying, setMusicPlaying] = useState(true);
  const [masterSoundEnabled, setMasterSoundEnabled] = useState(true);

  // Audio element for music
  let musicRef = null;

  useEffect(() => {
    // Play music in a loop
    if (musicRef) {
      musicRef.loop = true;
      musicRef.volume = musicVolume;
      if (musicPlaying && masterSoundEnabled) {
        musicRef.play();
      } else {
        musicRef.pause();
      }
    }
  }, [musicPlaying, musicVolume, masterSoundEnabled]);

  useEffect(() => {
    // Handle cave-specific music when a cave is selected
    if (selectedCave) {
      switch (selectedCave) {
        case 'Cave 1':
          musicRef.src = caveSong1;
          break;
        case 'Cave 2':
          musicRef.src = caveSong2;
          break;
        case 'Cave 3':
          musicRef.src = caveSong3;
          break;
        case 'Cave 4':
          musicRef.src = caveSong4;
          break;
        case 'Cave 5':
          musicRef.src = caveSong5;
          break;
        default:
          musicRef.src = song; // Default back to main song
          break;
      }
      // Restart music if it was paused/stopped
      if (musicPlaying && masterSoundEnabled) {
        musicRef.play();
      }
    } else {
      musicRef.src = song;
      if (musicPlaying && masterSoundEnabled) {
        musicRef.play();
      }
    }
  }, [selectedCave, musicPlaying, masterSoundEnabled]);

  const startGame = () => {
    setGameStarted(true);
    setCavePopupVisible(true);
    setMusicPlaying(true); // Start playing music
  };

  const selectCave = (cave) => {
    setSelectedCave(cave);
    setCavePopupVisible(false);
  };

  const leaveMine = () => {
    setGameStarted(false);
    setCavePopupVisible(false);
    setSelectedCave(null)
    setCollectionVisible(false);
    setPageIndex(0); // Reset to first collection
    setSelectedGem(null);
  };

  const closeCollection = () => {
    setCollectionVisible(false);
    setSelectedGem(null);
    setPageIndex(0); // Reset to first collection
  };

  const closeCavePopup = () => {
    setCavePopupVisible(false);
  };

  const showCollection = () => {
    setCollectionVisible(true);
  };

  const nextPage = () => {
    if (pageIndex < gemData.length - 1) {
      setPageIndex(pageIndex + 1);
    }
  };

  const prevPage = () => {
    if (pageIndex > 0) {
      setPageIndex(pageIndex - 1);
    }
  };

  const showGemDetails = (gem) => {
    if (gem.name) {
      setSelectedGem(gem);
    }
  };

  const closeGemDetails = () => {
    setSelectedGem(null);
  };

  const handleMusicVolumeChange = (event) => {
    setMusicVolume(event.target.value);
  };

  const handleSoundsVolumeChange = (event) => {
    setSoundsVolume(event.target.value);
    // Implement sound effects volume control when needed
  };

  const toggleMasterSound = () => {
    setMasterSoundEnabled(!masterSoundEnabled);
    if (!masterSoundEnabled) {
      setMusicPlaying(true); // Resume music if master sound is enabled
    } else {
      setMusicPlaying(false); // Pause music if master sound is disabled
    }
  };

  return (
    <div id="mine-game-window">
      <div className='mine-game-ui'>
        {!selectedCave && (
          <div className='mine-game-navigation'>
            <h1 className='mine-game-title'>Mine Game<br />Mining</h1>
            <ul className='mine-game-navigation-buttons'>
              {!gameStarted ? (
                <>
                  <li><button onClick={startGame}>Play</button></li>
                  <li><button onClick={showCollection}>Collection</button></li>
                  <li><button>Log In</button></li>
                </>
              ) : (
                <li><button onClick={leaveMine}>Leave Mine</button></li>
              )}
            </ul>
            <ul className='mine-game-navigation-settings'>
              <li>
                <label>Music</label>
                <input
                  type="range"
                  min="0"
                  max="1"
                  step="0.01"
                  value={musicVolume}
                  onChange={handleMusicVolumeChange}
                  disabled={!masterSoundEnabled}
                />
              </li>
              <li>
                <label>Sounds</label>
                <input
                  type="range"
                  min="0"
                  max="1"
                  step="0.01"
                  value={soundsVolume}
                  onChange={handleSoundsVolumeChange}
                  disabled={!masterSoundEnabled}
                />
              </li>
              <li>
                <label>Master Sound</label>
                <input
                  type="checkbox"
                  checked={masterSoundEnabled}
                  onChange={toggleMasterSound}
                />
              </li>
            </ul>
          </div>
        )}

        {gameStarted && !selectedCave && (
          <div className='cave-selection-popup'>
            <div className='popup-header'>
              <h2>Select a Cave</h2>
              <button onClick={closeCavePopup}>X</button>
            </div>
            <ul className='mine-game-caves'>
              <li><button onClick={() => selectCave('Cave 1')}>Cave 1</button></li>
              <li><button onClick={() => selectCave('Cave 2')}>Cave 2</button></li>
              <li><button onClick={() => selectCave('Cave 3')}>Cave 3</button></li>
              <li><button onClick={() => selectCave('Cave 4')}>Cave 4</button></li>
              <li><button onClick={() => selectCave('Cave 5')}>Cave 5</button></li>
            </ul>
          </div>
        )}

        {gameStarted && selectedCave && (
          <div className='mine-game-content'>
            <h2>You are in {selectedCave}</h2>
            {/* Add game logic here */}
            <button onClick={leaveMine}>Leave Mine</button>
          </div>
        )}

        {collectionVisible && !selectedCave && (
          <div className={`collection-popup pageNumber${gemData[pageIndex].pageNum}`}>
            <div className='popup-header'>
              <h2>{gemData[pageIndex].title}</h2>
              <button onClick={closeCollection}>X</button>
            </div>
            <div className='collection-content'>
              <ul className='collection-page'>
                {gemData[pageIndex].gems.map((gem, index) => (
                  <li key={index}>
                    <button onClick={() => showGemDetails(gem)}>{gem.name}</button>
                  </li>
                ))}
              </ul>
              <div className='collection-navigation'>
                <button onClick={prevPage} disabled={pageIndex === 0}>Previous</button>
                <button onClick={nextPage} disabled={pageIndex === gemData.length - 1}>Next</button>
              </div>
            </div>
          </div>
        )}

        {selectedGem && (
          <div className='gem-details-popup'>
            <div className='popup-header'>
              <h2>{selectedGem.name}</h2>
              <button onClick={closeGemDetails}>X</button>
            </div>
            <div className='gem-details-content'>
              <p>{selectedGem.description}</p>
              <button>Acquire</button> {/* Implement acquire logic */}
            </div>
          </div>
        )}
      </div>

      <audio ref={(audio) => { musicRef = audio; }}>
        <source src={song} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
    </div>
  );
};

export default Miner;
