import React from 'react'
import { useState } from 'react'

import Cards from '../../../../assets/icons/Cards'
import Link from '../../../../assets/icons/Link'
import Page from '../../../../assets/icons/Page'
import AdminCMSCreateCards from './AdminCMSCreateCards/AdminCMSCreateCards'
import AdminCMSCreateContent from './AdminCMSCreateContent/AdminCMSCreateContent'
import AdminCMSCreateLink from './AdminCMSCreateLink/AdminCMSCreateLink'
import './AdminCMSCreatePage.css'

const AdminCMSCreatePage = () => {
    const [createPageActive, setCreatePageActive] = useState(false)
    let baseState = 
    <>
    <h3>Select Page Type:</h3>
    <div className='page__choice__options'>
        <div className='page__choice' onClick={()=>createNewContent()}><Page /><h4>Regular Content</h4></div>
        <div className='page__choice' onClick={()=>createNewCards()}><Cards /><h4>Card Page</h4></div>
        <div className='page__choice' onClick={()=>createNewLink()}><Link /><h4>Redirect Link</h4></div>
    </div>
    </>
    
    const [selectOption, setSelectOption] = useState(baseState)

    function renderNewCreatePage(){
        setCreatePageActive(!createPageActive)
        setSelectOption(baseState)
    }


    function createNewLink(){
        setSelectOption(<AdminCMSCreateLink />)
    }
    function createNewCards(){
        setSelectOption(<AdminCMSCreateCards />)
    }
    function createNewContent(){
        setSelectOption(<AdminCMSCreateContent />)
    }
  return (
    <div>
        <button onClick={()=>{renderNewCreatePage()}} className="cms__create__page__button">Create Page</button>
        <div className={createPageActive ? "create__page__popup active" : "create__page__popup"}>
            <div className='interaction__section'>
                {selectOption}
            </div>


            <div className='background__section' onClick={()=>{setCreatePageActive(!createPageActive)}}></div>
        </div>
    </div>
  )
}

export default AdminCMSCreatePage