import React, { useState, useRef, useEffect } from 'react';
import './EclipseNav.css';
import { Link } from 'react-router-dom';

const EclipseNav = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const dataSubmissionRef = useRef(null);

  const scrollToDataSubmission = () => {
    if (dataSubmissionRef.current) {
      dataSubmissionRef.current.scrollIntoView({ behavior: 'smooth' });
      // Close the mobile menu after scrolling to the section
      setMobileMenuOpen(false);
    }
  };

  const eclipseLinks = [
    { label: "Eclipse Home", link: "/eclipse", external: false, active: true },
    // { label: "Contribute Data", link: "#celestialSectText", external: false, active: true, onClick: scrollToDataSubmission },
    { label: "Live Data", link: "#eclipseData", external: false, active: true },
    { label: "Related Articles", link: "#eclipseNews", external: true, active: true }
  ];

  useEffect(() => {
    const handleBodyClick = (event) => {
      // Close the mobile menu if it is open and the click is outside the navigation menu
      if (isMobileMenuOpen && !event.target.closest('.eclipse-nav-wrapper')) {
        setMobileMenuOpen(false);
      }
    };

    // Add event listener to detect clicks anywhere in the body
    document.body.addEventListener('click', handleBodyClick);

    // Cleanup: remove event listener on component unmount
    return () => {
      document.body.removeEventListener('click', handleBodyClick);
    };
  }, [isMobileMenuOpen]);

  return (
    <nav className='eclipse-nav-wrapper'>
      <div className={`hamburger-icon-eclipse ${isMobileMenuOpen ? 'active' : ''}`} onClick={toggleMobileMenu}>
        <span className='line one'></span>
        <span className='line two'></span>
        <span className='line three'></span>
      </div>
      <ul className={`eclipse-nav ${isMobileMenuOpen ? 'mobile-menu-open' : ''}`}>
        {eclipseLinks.map((item, index) => (
          <li key={index}>
            {item.external ? (
              <a href={item.link} onClick={item.onClick}>{item.label}</a>
            ) : (
              <a href={item.link} onClick={item.onClick}>{item.label}</a>
            )}
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default EclipseNav;
