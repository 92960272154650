import React from 'react'
import { useState } from 'react'
import {primaryAPILink} from '../../../../config/routes.js'
import './JobRow.css'

const JobRow = (props) => {
    const [title, setTitle] = useState(props.info.title)
    const [description, setDescription] = useState(props.info.description)
    const [link, setLink] = useState(props.info.link)

    function updateJob() {
        
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', jwtKey: localStorage.getItem("loginKey") },
            body: JSON.stringify({
                jobID: props.info.jobID,
                link: link,
                description: description,
                title: title,
            })
        };
        fetch(`${primaryAPILink}/api/jobs/updateJob`, requestOptions)
    }

    function removeJob(){
        if(window.confirm(`Are you sure you wish to remove ${title} job?`)) {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', jwtKey: localStorage.getItem("loginKey") },
                body: JSON.stringify({
                    jobID: props.info.jobID,
                })
            };
            fetch(`${primaryAPILink}/api/jobs/removeJob`, requestOptions)
            window.location.reload();
        }
    }

  return (
    <tr className='job__row__section'>
        <td><input 
        contentEditable="true" 
        suppressContentEditableWarning={true}
        onInput={(event)=>{
            setTitle(event.target.value)
        }}
        onBlur={()=>updateJob()}
        value={title}></input></td>
        
        <td><input 
        contentEditable="true" 
        suppressContentEditableWarning={true}
        onInput={(event)=>{
            setDescription(event.target.value)
        }}
        onBlur={()=>updateJob()}
        value={description}></input></td>
        
        <td><input 
        contentEditable="true" 
        suppressContentEditableWarning={true}
        onInput={(event)=>{
            setLink(event.target.value)
        }}
        onBlur={()=>updateJob()}
        value={link}></input></td>

        <td><button onClick={()=>{removeJob()}}>X</button></td>
    </tr>
  )
}

export default JobRow