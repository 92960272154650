import React, { useState, useEffect, useRef } from 'react';
import './ImprovedIUBrandingBar.css';
import IUTridentLogo from '../../../assets/IUAssets/trident-large.png';
import { primaryAPILink } from '../../../config/routes';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV, faSearch } from '@fortawesome/free-solid-svg-icons';

const ImprovedIUBrandingBar = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState(null); // Initialize as null
  const inputRef = useRef(null);
  const ulRef = useRef(null);

  const [ulClassName, setUlClassName] = useState('iu__nav__searchResults');

  useEffect(() => {
    let delayTimeout;

    const fetchSearchResults = async () => {
      try {
        const encodedSearchTerm = encodeURIComponent(searchTerm);
        const response = await fetch(`${primaryAPILink}/api/search/${encodedSearchTerm}`);
        if (response.ok) {
          const data = await response.json();
          setSearchResults(data);
          if (data.length > 0) {
            setUlClassName('iu__nav__searchResults active');
          }
          console.log(data);
        } else {
          throw new Error('Request failed');
        }
      } catch (error) {
        console.error(error);
      }
    };

    const handleSearch = () => {
      clearTimeout(delayTimeout);
      delayTimeout = setTimeout(() => {
        // Perform the API request only if the search term is not empty
        if (searchTerm.trim() !== '') {
          fetchSearchResults();
        } else {
          // If the search term is empty, clear the search results
          setSearchResults([]);
          setUlClassName('iu__nav__searchResults');
        }
      }, 700); // Delay of 0.7 seconds (700 milliseconds)
    };

    handleSearch(); // Initial API request on component mount

    // Clean up the timeout on component unmount
    return () => clearTimeout(delayTimeout);
  }, [searchTerm]);

  const handleInputBlur = () => {
    setSearchResults([]);
    setUlClassName('iu__nav__searchResults');
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        ulRef.current &&
        !ulRef.current.contains(event.target) &&
        !inputRef.current.contains(event.target)
      ) {
        setSearchResults([]);
        setUlClassName('iu__nav__searchResults');
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    // Navigate to the advanced search page with the search terms as query parameters
    window.location.href = `/search?q=${encodeURIComponent(searchTerm)}`;
  };

  return (
    <div className='iu__branding__bar'>
      <div className='iu__branding__bar__content'>
        <div className='iu__branding__bar__brand__identity'>
          <img src={IUTridentLogo} alt="IU Logo"></img>
          <span>Indiana University</span>
        </div>
        <div className="iu__nav__search__wrapper">
          <form onSubmit={handleSubmit}>
            <input
              className="iu__nav__search"
              placeholder='Search Site...'
              type="text"
              value={searchTerm}
              aria-label='Search'
              ref={inputRef}
              onChange={(event) => setSearchTerm(event.target.value)}
            ></input>
            <button type="submit" className='advanced__search__link' title="Advanced Search">
              <FontAwesomeIcon icon={faSearch} />
              <FontAwesomeIcon icon={faEllipsisV} className="advanced__search__link__tiny__icon" />
            </button>
          </form>
        </div>
        <div className={ulClassName} ref={ulRef}>
          {searchResults === null ? ( // Check for null initial state
            <p className='subtle__message__text__search'>
              <span className="search__bold__nav">No results found:</span>
              <br />
              If you're looking for publications or related materials, consider exploring our{' '}
              <a href="https://data.igws.indiana.edu" target="_blank">
                Digital Collections
              </a>{' '}
              for a comprehensive selection of resources.
            </p>
          ) : searchResults.length > 0 ? (
            searchResults.map((item, index) => {
              if (item.external) {
                return <a href={item.link} key={index} target="_blank">{item.title}</a>;
              } else {
                return <Link to={item.link} key={index}>{item.title}</Link>;
              }
            })
          ) : (
            // Handle other cases where searchResults is not null
            // but there are no results (e.g., empty string)
            <p className='subtle__message__text__search'>
            <span className="search__bold__nav">No results found:</span>
            <br />
            If you're looking for publications or related materials, consider exploring our{' '}
            <a href="https://data.igws.indiana.edu" target="_blank">
              Digital Collections
            </a>{' '}
            for a comprehensive selection of resources.
          </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ImprovedIUBrandingBar;
