import React, { useState, useEffect } from 'react';
import './Eclipse.css'
import EclipseHeader from './EclipseSections/EclipseHeader/EclipseHeader'
import EclipseNav from './EclipseNav/EclipseNav'
import CelestialSect from './EclipseSections/CelestialSect/CelestialSect';
import DiscoverEclipse from './EclipseSections/DiscoverEclipse/DiscoverEclipse';
import EclipseLine from './EclipseSections/EclipseLine/EclipseLine';
import EclipseImageGrid from './EclipseSections/EclipseImageGrid/EclipseImageGrid';
import EclipseNews from './EclipseSections/EclipseNews/EclipseNews';
import EclipseChart from './EclipseSections/EclipseChart/EclipseChart';
import LiveEclipseChart from './EclipseSections/LiveEclipseChart/LiveEclipseChart';
import EclipseLiveExplained from './EclipseSections/EclipseLiveExplained/EclipseLiveExplained';
import NewsStoryVideo from '../../pages/News/NewsStory/NewsStoryComponents/NewsStoryVideo/NewsStoryVideo'
const Eclipse = () => {

    document.title = "IGWS | 2024 Solar Eclipse"

    const [screenWidth, setScreenWidth] = useState(window.innerWidth - 30);
    const [screenHeight, setScreenHeight] = useState(window.innerHeight);

    function calcTotalStars() {
        const reasonableMaxStars = 1000; // Choose a reasonable maximum number of stars
        let starCalc = Math.floor(screenWidth * screenHeight * 0.0003);
    
        starCalc = Math.max(0, starCalc); // Ensure starCalc is non-negative
    
        if (starCalc > reasonableMaxStars) {
            return reasonableMaxStars;
        } else {
            return starCalc;
        }
    }

    const totalStars = calcTotalStars();

    function getRandomNumber(max) {
        return Math.floor(Math.random() * max) + 1;
    }

    function getRandomPercentage() {
        return Math.random() * 100;
    }


    const handleResize = () => {
        const newWidth = window.innerWidth - 300;
        const newHeight = window.innerHeight;

        // Check if the width has changed
        if (newWidth !== screenWidth) {
            setScreenWidth(newWidth);
            setScreenHeight(newHeight);
        }
    };

    useEffect(() => {


        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [screenWidth]);


    function generateStar() {

        let x = getRandomPercentage();
        let y = getRandomPercentage();
        let size = getRandomNumber(4) + 1;

        let duration = getRandomNumber(5) + 3;
        let isBlurred = Math.random() < 0.15; // 15% chance of being blurred
        let isColoredStar = Math.random() < 0.4;




        const snowflakeStyle = {
            position: 'absolute',
            left: `${x - 5}%`,
            top: `${y}%`,
            width: `${size}px`,
            height: `${size}px`,
            filter: isBlurred ? 'blur(2px)' : 'none',
            backgroundColor: isColoredStar ? 'rgba(20, 79, 143, 0.676)' : 'white',
            borderRadius: '50%',
            opacity: 0,
            animation: `starsGlow ${duration}s ease 0s infinite`
        };

        return <div style={snowflakeStyle}></div>;

    }


    return (
        <div id="eclipsePageWrapper">
            <div className='starsWrapper'>
                {[...Array(totalStars)].map((_, index) => (
                    <React.Fragment key={index}>{generateStar()}</React.Fragment>
                ))}</div>
            <EclipseNav />
            <EclipseHeader />
            
            <EclipseLine text="The 2024 Solar Eclipse"></EclipseLine>

            <div id="celestialSectText" className='eclipseJumpSpacer'></div>
            <CelestialSect />
            <div className='eclipseHorizontalLine'></div>
            <DiscoverEclipse />
            {/* <WhenWhereEclipse /> */}

            <div id="eclipseNews" className='eclipseJumpSpacer'></div>
            <EclipseLine text="Related Articles"></EclipseLine>
            <EclipseNews />

            <div id="eclipseData" className='eclipseJumpSpacer'></div>
            <EclipseLine text="Live Data"></EclipseLine>
            
            
            <EclipseChart/>
            <EclipseLiveExplained />
            <LiveEclipseChart />
            <div className='eclipseVideoWrapper'>
                <div className="eclipseVideoVideo">
                <NewsStoryVideo videoLink="https://www.youtube.com/embed/E8FvW0bXXYE?si=X9d4pwYEuNzhgv4S"/>
            </div></div>
            
          
            <EclipseLine text="Featured Media"></EclipseLine>
            <EclipseImageGrid imageList={[["https://data.igws.indiana.edu/filestore/7/6/1/0/6_705175d0d1820d9/60167web_b841a976271ded5.jpg?v=1709066232", "IWBN Station gathering data"], 
            ["https://data.igws.indiana.edu/filestore/8/0/3/2/5_20d098aba390b52/52308pre_7729e21dd27a5e9.jpg?v=1680106983", "Aug 21, 2017: IGWS staff observing the partial solar eclipse outside of the Geology Building"],
            ["https://data.igws.indiana.edu/filestore/6/6/1/0/6_260aae47790cbd1/60166scr_82a97da8f8788cf.jpg?v=1709066231", "October 14th, 2023: Partial solar eclipse over Ballantine Hall, Bloomington, IN."]
            ]} right={true}/>
            <EclipseImageGrid imageList={[["https://data.igws.indiana.edu/filestore/0/9/3/5/5_db9128eb105d2bf/55390scr_99b41d20e6c24e0.jpg?v=1690913076", "IWBN Station"],
            ["https://data.igws.indiana.edu/filestore/2/7/3/5/5_c91d1538e53c968/55372web_d40d02f3d0b1c54.jpg?v=1690913051", "Solar panels and a net radiometer collaboratively capture and measure solar energy, shedding light on the eclipse's influence on environmental properties."], 
            ["https://data.igws.indiana.edu/filestore/3/3/9/9/5_b91f89b8adb6a43/59933scr_d1c21837b2fd879.jpg?v=1707495909", "The essential tools employed to monitor changes in properties detected during the eclipse, offering a glimpse into the intricate process of scientific observation."]]}/>
            
    
            
            


        </div>
    )
}

export default Eclipse