import React from 'react'
import {useState, useEffect} from 'react'
import {primaryAPILink} from '../../config/routes.js'
import CMSCards from './CMSCards/CMSCards.jsx'
import CMSLink from './CMSLink/CMSLink.jsx'
import CMSPage from './CMSPage/CMSPage.jsx'
import './CMS.css'
import { Link } from 'react-router-dom';
import Loading from '../Loading/Loading.jsx'

const CMS = (props) => {

  const ourCMSID = props.cmsID;
  const [parent, setParent] = useState("");
  const [link, setLink] = useState("")
  const [setPageType, setSetPageType] = useState(<Loading />)

        //Check if logged in user is an editor
        const [editorLoggedIn, setEditorLoggedIn] = useState(false)
        useEffect(() => {
          fetch(`${primaryAPILink}/api/staff/specificPersonJWT/${localStorage.getItem("loginKey")}`)
              .then(res => res.json())
              .then((result) => {
                if(result.length > 0) {
                  if(result[0].editCMS === 1)
                  setEditorLoggedIn(true)
                }})}, []
        )


  //Gather CMS information
    useEffect(() => {
        fetch(`${primaryAPILink}/api/cms/getCMSPage/${ourCMSID}`)
            .then(res => res.json())
            .then((result) => {
              if(result.length === 1) {
                document.title = `IGWS | ${result[0].title}`
                setParent(result[0].cmsParentGroup)
                setLink(result[0].cmsLink)
                if(result[0].cmsType === "cards") {
                  setSetPageType(<CMSCards content={result[0].cmsContent} pageTitle={result[0].title}/>)
                }
                if(result[0].cmsType === "link") {
                  setSetPageType(<CMSLink content={result[0].cmsContent}/>)
                }
                if(result[0].cmsType === "cmsPage") {
                  setSetPageType(<><CMSPage content={result[0].cmsContent} pageTitle={result[0].title}/>
                  </>)
                }

              } else {
                //this page could not load
                setSetPageType(<>Could not load page</>)
              }
            })}, []
    )

  return (
    <div>
      {setPageType}
      <Link to={`/admin/cms-edit?parent=${parent}&link=${link}`} className={`editorButton ${editorLoggedIn ? "editor": ""}`}>Edit Page</Link>
    </div>
  )
}

export default CMS