import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import './StaffProfile.css';
import { primaryAPILink } from '../../../config/routes.js';
import CrimsonBlockHeader from '../../../components/CrimsonBlockHeader/CrimsonBlockHeader';
import StaffProfileName from './StaffProfileName/StaffProfileName.jsx';
import StaffProfilePicture from './StaffProfilePicture/StaffProfilePicture.jsx';
import StaffProfileBanner from './StaffProfileBanner/StaffProfileBanner.jsx';
import StaffProfileTitle from './StaffProfileTitle/StaffProfileTitle.jsx';
import StaffProfileSpecialties from './StaffProfileSpecialties/StaffProfileSpecialties.jsx';

const StaffProfile = () => {
  const params = useParams();
  const [profileInfo, setProfileInfo] = useState({
    fName: 'Loading...',
    lName: 'Loading...',
    description: 'Loading...',
    picture: 'Loading...',
    title: 'Loading...',
    email: 'Loading...',
    phone: null,
    website: null,
    linkedin: null,
    specialties: null,
    bannerType: 0
  });

  const navigate = useNavigate();

  // Function to format phone number into (111) 111-1111 format
  const formatPhoneNumber = (phoneNumber) => {
    // Remove all non-digits
    const cleaned = phoneNumber.replace(/\D/g, '');
    // Format: (XXX) XXX-XXXX
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    // Return original if format doesn't match
    return phoneNumber;
  };

  // Function to extract domain from website URL
  const extractDomain = (url) => {
    let domain = url;
    // Remove "www." if present
    domain = domain.replace(/^www\./i, '');
    // Remove "https://" if present
    domain = domain.replace(/^https?:\/\//i, '');
    // Remove trailing slashes
    domain = domain.replace(/\/$/, '');
    return domain;
  };

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await fetch(`${primaryAPILink}/api/staff/specificperson/${params.staffLink}`);
        const result = await response.json();
        console.log(result)
        if (result.length === 0) {
          navigate('/staff');
        } else {
          const {
            fName,
            lName,
            description,
            picture,
            title,
            email,
            phone,
            website,
            linkedin,
            specialties,
            bannerType
          } = result[0];

          // Extract LinkedIn username from full URL if present
          const linkedinUsername = linkedin ? linkedin.split('/').filter(part => part !== '').pop() : null;
          // Extract domain from website URL
          const websiteDomain = website ? extractDomain(website) : null;

          setProfileInfo({
            fName,
            lName,
            description,
            picture,
            title,
            email,
            phone: phone ? formatPhoneNumber(phone) : null,
            website: websiteDomain, // Store only the domain part
            linkedin: linkedinUsername, // Store only the username
            specialties: specialties && specialties.split(',').length > 0 && specialties.split(',')[0] !== "" ? (
              <StaffProfileSpecialties specialties={specialties.split(',')} />
            ) : null,
            bannerType
          });

          document.title = `IGWS | ${fName} ${lName}`;
        }
      } catch (error) {
        console.error('Error fetching staff profile:', error);
        navigate('/staff');
      }
    };

    fetchProfile();
  }, [params.staffLink, navigate]);

  return (
    <div id="staff__profile__wrapper">
      <CrimsonBlockHeader text="Staff Listing" />
      <nav>
        <ul>
          <li><Link to="/staff">All Staff</Link></li>
        </ul>
      </nav>
      <div id="staff__profile__content">
        <StaffProfileBanner bannerType={`banner${profileInfo.bannerType}`} />
        <div className='staff__profile__head'>
          <StaffProfilePicture pictureLink={profileInfo.picture} altText={`${profileInfo.fName} ${profileInfo.lName} profile picture`} />
          <div className='staff__profile__head__text'>
            <StaffProfileName fName={profileInfo.fName} lName={profileInfo.lName} />
            <StaffProfileTitle title={profileInfo.title} />
          </div>
        </div>
        <div className='staff__profile__contact'>
          <div className='staff__profile__contact__item'>
            <h3>Email:</h3>
            <p>{profileInfo.email}</p>
          </div>
          {profileInfo.phone && (
            <div className='staff__profile__contact__item'>
              <h3>Phone:</h3>
              <p>{profileInfo.phone}</p>
            </div>
          )}
          {profileInfo.website && (
            <div className='staff__profile__contact__item'>
              <h3>Portfolio:</h3>
              <p><a href={`https://www.${profileInfo.website}`} target="_blank" rel="noopener noreferrer">{profileInfo.website}</a></p>
            </div>
          )}
          {profileInfo.linkedin && (
            <div className='staff__profile__contact__item'>
              <h3>LinkedIn:</h3>
              <p><a href={`https://www.linkedin.com/in/${profileInfo.linkedin}`} target="_blank" rel="noopener noreferrer">{profileInfo.linkedin}</a></p>
            </div>
          )}
        </div>
        {profileInfo.specialties}

        {profileInfo.description !== 'Loading...' && (
          <div className='staff__profile__bio'>
            <h3>Biography:</h3>
            <p>{profileInfo.description}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default StaffProfile;
