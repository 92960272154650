import React from 'react'
import Collapsible from 'react-collapsible'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleDown } from '@fortawesome/free-solid-svg-icons'
import './ProjectIndividual.css'

const formattedDate = isoDate => {
  if (!isoDate) return ''; // Handle empty or null values

  const date = new Date(isoDate);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
};

const ProjectIndividual = (props) => {
  return (
    <Collapsible trigger={[props.title, <FontAwesomeIcon icon={faChevronCircleDown} />]} className="project__individual">
      <table>
        <thead>
          <tr>
            <td>Status</td>
            <td>Start Date</td>
            <td>End Date</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{props.status}</td>
            <td>{formattedDate(props.startDate)}</td>
            <td>{formattedDate(props.endDate)}</td>
          </tr>
        </tbody>
      </table>

      {props.fName ? (
        <>
          <h3>Director:</h3>
          <p>{props.fName} {props.lName}</p>
        </>
      ): null}

      {props.shortDesc ? (
        <>
          <h3>Description:</h3>
          <p>{props.shortDesc}</p>
        </>
      ): null}
    </Collapsible>)
  
}

export default ProjectIndividual