import React from 'react';
import './NewsStoryMultiCards.css';
import NewsStoryMultiCardsCard from './NewsStoryMultiCardsCard/NewsStoryMultiCardsCard';

const NewsStoryMultiCards = (props) => {
  // Function to check if the link is an external link
  const isExternalLink = (link) => {
    return link.startsWith('http://') || link.startsWith('https://');
  };

  return (
    <div className='news__story__multi__card'>
      {props.cardList.map((item, index) => (
    <a
    href={item.link}
    key={index}
    className={item.link ? 'has-hover' : ''}
    target={isExternalLink(item.link) ? '_blank' : ''}
    rel={isExternalLink(item.link) ? 'noopener noreferrer' : undefined}
  >
    <NewsStoryMultiCardsCard image={item.image} text={item.text} />
  </a>
      ))}
    </div>
  );
}

export default NewsStoryMultiCards;
