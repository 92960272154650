import React, { useEffect, useState } from 'react';
import './EclipseHeader.css';

const EclipseHeader = () => {

  return (
    <header>
      <div className='eclipseGraphicMainWrapper'>
        <div className='sunGraphicEclipseOverlapSpinner' />
        <div className='sunGraphicEclipseOverlapSpinner2' />
        <div className='sunGraphicEclipse' />
        <div className='sunGraphicEclipseOverlap' />
        <div className='moonGraphicEclipse' />
        <div className='eclipseTextContainer'>
          <h1 className='largeSolarNumber'>2024</h1><h1>Solar Eclipse</h1>
        </div>
      </div>
    </header>
  );
};

export default EclipseHeader;
