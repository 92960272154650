import React from 'react'
import { useState } from 'react'
import './NewsStoryPopupImage.css'

const NewsStoryPopupImage = (props) => {
    const [pictureOpen, setPictureOpen] = useState(false)
  return (
    <figure  className={pictureOpen ? 'news__story__image__popup__wrapper open' : 'news__story__image__popup__wrapper'} onClick={()=> setPictureOpen(!pictureOpen)}>

        <img src={props.src} alt={props.text}/>
        <figcaption>{props.text}</figcaption>
    </figure>

  )
}

export default NewsStoryPopupImage