import React from 'react'
import {primaryAPILink} from '../../../../config/routes.js'
import { useEffect, useState } from 'react'
import AddJob from './AddJob/AddJob.jsx'

const JobsManager = () => {

  return (
    <AddJob />
  )
}

export default JobsManager