import React from 'react'
import './EclipseLiveExplained.css'

const EclipseLiveExplained = () => {
  return (
    <div className='eclipse_live_explained'>
      <div className="eclipseLiveContent">
        <img src="https://data.igws.indiana.edu/filestore/0/2/5/3/6_df5861fc88bbe40/63520pre_c9d52162ead0d63.jpg?v=1712254353" alt="Graphic of indiana showing location of lakestation, bloomington, and jasper IWBN stations relative to path of totality in the april 8th 2024 eclipse" />
        <div className='eclipseLiveContentP'>
          <h3>View Live Eclipse Data:</h3>
          <p>The IWBN broadcasted live data from our statewide network of climate-monitoring stations during the April 8th solar eclipse, offering crucial insights into the eclipse's impact on solar radiation and temperature patterns.</p>
          <p>Our stations in Bloomington, Jasper, and Lake Station represent varying positions relative to the eclipse's path. Bloomington sits within the path of totality, Jasper lies on its edge, and Lake Station remains outside, allowing us to observe and analyze how these different positions influence local environmental responses to the eclipse.</p>
          <p>In addition to our live data broadcast, the IGWS streamed a view of the April 8th solar eclipse live on <a href="https://www.youtube.com/@ingeosurvey" target="_blank">YouTube</a>. Video from the livestream will remain available on our YouTube channel after the event, allowing viewers to rewatch at their convenience.</p>
          </div>
      </div>
    </div>
  )
}

export default EclipseLiveExplained