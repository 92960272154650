import React, { useCallback } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import CrimsonBlockHeader from '../../components/CrimsonBlockHeader/CrimsonBlockHeader'
import './Staff.css'
import {primaryAPILink} from '../../config/routes.js'
import StaffCategoryContainer from './StaffCategoryContainer/StaffCategoryContainer'

const Staff = () => {
  document.title = 'IGWS | Staff';
  const [staffList, setStaffList] = useState([]);
  const [ourContent, setOurContent] = useState(<></>)
  

  const [adminList, setAdminList] = useState([]);
  const [businessList, setBusinessList] = useState([]);
  const [infoList, setInfoList] = useState([]);
  const [researchList, setResearchList] = useState([]);
  const [volunteerList, setVolunteerList] = useState([]);

    useEffect(() => {
    fetch(`${primaryAPILink}/api/staff`)
        .then(res => res.json())
        .then((result) => {
          if(result.length !== 0) {
            setStaffList(result.sort((a, b) => a.lName.localeCompare(b.lName)))
            let tempAdmin = [];
            let tempBusiness = [];
            let tempInfo = [];
            let tempResearch = [];
            let tempVolunteer = [];
              result.forEach((person)=>{
                if(person.category === 'admin'){
                  tempAdmin.push(person);
                }
                if(person.category === 'business'){
                  tempBusiness.push(person);
                }
                if(person.category === 'info'){
                  tempInfo.push(person);
                }
                if(person.category === 'research'){
                  tempResearch.push(person);
                }
                if(person.category === 'volunteer'){
                  tempVolunteer.push(person);
                }
              })
            setAdminList(tempAdmin);
            setBusinessList(tempBusiness);
            setInfoList(tempInfo);
            setResearchList(tempResearch);
            setVolunteerList(tempVolunteer);
          }
          
        })}, []
    )




    const filter = useCallback((filterCategory, filterList) => {
      if (filterCategory === "all") {
        setOurContent(<>
          <StaffCategoryContainer category={"Administration"} content={adminList} />
          <StaffCategoryContainer category={"Business Affairs"} content={businessList} />
          <StaffCategoryContainer category={"Information Services"} content={infoList} />
          <StaffCategoryContainer category={"Research"} content={researchList} />
          <StaffCategoryContainer category={"Volunteer Affiliates"} content={volunteerList} />
        </>);
      } else {
        setOurContent(
          <StaffCategoryContainer category={filterCategory} content={filterList}></StaffCategoryContainer>
        );
      }
    }, [adminList, businessList, infoList, researchList, volunteerList]);

    useEffect(()=>{
      filter("all")
    }, [staffList, filter])

  return (
    <div id='staff__listing__page'>
      <CrimsonBlockHeader text="Staff Listing"/>
      <div className='staff__listing__button__wrapper'>
        <div className='staff__listing__button__array'>
          <button onClick={() => filter("all", null)}>All</button>
          <button onClick={() => filter("Administration", adminList)}>Administration</button>
          <button onClick={() => filter("Business Affairs", businessList)}>Business Affairs</button>
          <button onClick={() => filter("Information Services", infoList)}>Information Services</button>
          <button onClick={() => filter("Research", researchList)}>Research</button>
          <button onClick={() => filter("Volunteer Affiliates", volunteerList)}>Volunteer Affiliates</button>
        </div>
      </div>
      {ourContent}

    </div>
  )
}

export default Staff