import React from 'react'
import './CrimsonBlockHeader.css'

const CrimsonBlockHeader = (props) => {
  return (
    <div className='title__bounding__box__crimson'>
      <div className='title__bounding__box__crimson__wrapper'>
        <h1>{props.text}</h1>
      </div>
    </div>
  )
}

export default CrimsonBlockHeader