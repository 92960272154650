import React, { useState, useEffect, useRef } from 'react';
import './CelestialSect.css';

const CelestialSect = () => {
  const topics = [
    {
      title: 'The Solar Eclipse',
      content: '<p>On April 8, 2024, a total solar eclipse will pass over Indiana. Several locations—including the IU campuses in Bloomington, Indianapolis, Kokomo, Richmond, and Columbus—will be in the path of totality. During a total solar eclipse, the moon passes between the Sun and Earth, blocking the sun’s light and darkening the sky as if it were late evening.</p><p>IGWS geologists and citizen scientists will collect data from March 21 to April 10, 2024, to observe how this extraterrestrial event affects water balance in Indiana. </p>',
    },
    {
      title: 'IWBN stations',
      content: '<p>The <a href="/research/iwbn" target="_blank">Indiana Water Balance Network</a> is a statewide network of IGWS monitoring stations that collect various hydrologic data, such as near-surface atmospheric conditions, physical soil parameters, and groundwater levels, to monitor long-term trends in the hydrologic cycle. </p><p>IGWS geologists will increase the frequency of temperature, wind, solar radiation, and groundwater level data collection at IWBN stations across the state leading up to solar eclipse on April 8. This research will allow citizen scientists to collect real-time data that will be published in an IGWS news article and lesson plan.</p>',
    },
    {
      title: 'Earth tides',
      content: '<p>The solid Earth deforms as it rotates within the gravitational fields of the Sun and Moon. As Earth deforms, it causes stress on the pore space within aquifers. Tidal effects can be witnessed in groundwater due to these natural deformations.</p><p>IGWS geologists will set up experiments surrounding the eclipse to determine if Earth tides can be detected in Indiana aquifers. This research will allow IGWS scientists to better understand underground drinking water reservoirs. </p>',
    },
    {
      title: 'How can I help?',
      content: '<p>Become a citizen scientist by collecting data with the IGWS!</p><p>Anyone with a cellphone in Indiana can participate in this easy, fun, and free citizen science project. Download a free “lux meter” app on your phone, measure the light outside beforex, during, and after the eclipse, and submit that data to our <a href="https://experience.arcgis.com/experience/ce52aa0849a2403fad48bda9421d5d84" target=_blank>Eclipse Citizen Science portal.</a> We are soliciting data collected between March 21 and April 10, 2024.<p>Citizen science data will be published in an IGWS news article and lesson plan after the April 8 eclipse. </p><a href="https://experience.arcgis.com/experience/ce52aa0849a2403fad48bda9421d5d84" class="submitDataEclipse" target="_blank">View Data</a>',
    },
  ];

  const [selectedTopic, setSelectedTopic] = useState(topics[0]);
  const celestialSectionRef = useRef(null);
  const handleTopicChange = (newTopic) => {
    setSelectedTopic(newTopic);
    scrollToCelestialSection();
  };

  const scrollToCelestialSection = () => {
    if (celestialSectionRef.current) {
      celestialSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    const handleHashChange = () => {
      // Check if the hash fragment #celestialSectText is present in the URL
      const hashFragment = window.location.hash;
  
      // If the hash fragment is present and matches the targeted section, set the corresponding section to active
      if (hashFragment === '#celestialSectText') {
        const celestialSectTextTopic = topics.find((topic) => topic.title === 'How can I help?');
        if (celestialSectTextTopic) {
          setSelectedTopic(celestialSectTextTopic);
        }
      }
    };
  
    // Add event listener for hashchange
    window.addEventListener('hashchange', handleHashChange);
  
    // Run the effect initially
    handleHashChange();
  
    // Cleanup: remove event listener on component unmount
    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, []); // Empty dependency array to run the effect only on mount and unmount
  


  return (
    <section className='celestial__section__wrapper' id="celestialSectText">
      <div className='celestial__section__quote eclipseAnimatedBorder'>
        <blockquote>
          <p>"Some people say they feel very small when they think about space. I felt more expansive, very connected to the universe." </p>

          <p className='celestial__section__quote__author'>- Mae Jemison,<br></br>  
            <cite>NASA astronaut and first African-American woman in space</cite></p>
        </blockquote>
      </div>
      <div className='celestial__section'>
        <div className='celestial__section__graphic'>
          <h2>Related Topics</h2>
          {topics.map((topic) => (
            <button
              key={topic.title}
              className={`celestial__left__section__link ${selectedTopic.title === topic.title ? 'active' : ''}`}
              onClick={() => handleTopicChange(topic)}
            >
              {topic.title}
            </button>
          ))}
        </div>
        <div className='celestial__section__text '>
          <h2>{`${selectedTopic.title}`}</h2>
          <div dangerouslySetInnerHTML={{ __html: selectedTopic.content }}></div>

        </div>
      </div>
    </section>
  );
};

export default CelestialSect;
