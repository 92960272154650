import React from 'react'
import './LatestNewsHome.css'
import { Link } from 'react-router-dom'
import {useState, useEffect} from 'react'
import {primaryAPILink} from '../../config/routes.js'

const LatestNewsHome = () => {

    //Fetch recent 3 news stories
    const [recentThreeStories, setRecentThreeStories] = useState([]);
    useEffect(() => {
        fetch(`${primaryAPILink}/api/news/recentthree`)
            .then(res => res.json())
            .then((result) => {
                setRecentThreeStories(result)
            })}, []
    )

    function convertToDate(date) {

        let newDate = date.replace(/[-]/g, '/').split("T")[0];
    
        newDate = Date.parse(newDate);
        let jsDate = new Date(newDate).toDateString();
        return(jsDate.toString())
    }

    return (
        <div id="news__section__home">
            <div id="news__section__home__header">
                <div className='news__section__home__decorative__line'></div>
                <h2>Latest News</h2>
                <p>Get the latest stories from the IGWS</p>
                <div className='news__section__home__header__all__stories__wrapper'>
                    <Link to='/news/all-stories' className='news__section__home__header__all__stories1'>All News Stories</Link>
                    <Link to='/newsletters' className='news__section__home__header__all__stories2'>Past Newsletters</Link>
                </div>
            </div>
            <div className='news__section__home__stories'>
                {recentThreeStories.map((data, index) => {
                    return (
                        <a href={"/news/story/" + data.newsStoryLink} key={index}>
                            <article>
                                <img src={data.newsImage} alt={data.newsTitle} onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src="https://data.igws.indiana.edu/filestore/5/0/6/9/4_ab03c112ee54682/49605scr_491465816ff08e8.jpg?v=1666789768";
                                }}></img>
                                <p>{convertToDate(data.newsDatePublished)}</p>
                                <h3>{data.newsTitle}</h3>
                                <p>{data.newsSnippet}</p>
                            </article>
                        </a>)
                })}
            </div>
        </div>
    )
}

export default LatestNewsHome