import React from 'react'
import './NewsStoryVideo.css'

const NewsStoryVideo = (props) => {
  return (
    <div className='auto__resizing__news__video'>
        <iframe  src={props.videoLink} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen className='youtube__embedded__video'></iframe>
        <span>Video Loading . . .</span>
    </div>
  )
}

export default NewsStoryVideo