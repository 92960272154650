import React, { useEffect, useState } from 'react';
import AdminNavbar from '../AdminNavbar/AdminNavbar';
import CrimsonBlockHeader from '../../../components/CrimsonBlockHeader/CrimsonBlockHeader';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import './AdminEAssessment.css';
import { primaryAPILink } from '../../../config/routes';

const AdminEAssessment = () => {
  const currentDate = new Date();
  const tomorrow = new Date(currentDate);
  tomorrow.setDate(currentDate.getDate() + 1);

  const startDate = new Date();
  startDate.setMonth(currentDate.getMonth() - 1);

  const startDateString = startDate.toISOString().split('T')[0];
  const endDateString = tomorrow.toISOString().split('T')[0];

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', jwtKey: localStorage.getItem("loginKey") },
    body: JSON.stringify({
      startDate: startDateString,
      endDate: endDateString
    })
  };

  const [results, setResults] = useState([]);
  const [startDateSearch, setStartDateSearch] = useState(startDateString);
  const [stopDateSearch, setStopDateSearch] = useState(endDateString);
  const [sortOrder, setSortOrder] = useState({
    field: null,
    ascending: true,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20); // Set the number of items to display per page

  useEffect(() => {
    fetch(`${primaryAPILink}/api/eassessment/getAllAssessments`, requestOptions)
      .then(res => res.json())
      .then(result => {
        if (result.length !== 0) {
          setResults(result);
        }
      });
  }, []);

  function updateAssessments() {
    requestOptions.body =
      JSON.stringify({
        startDate: startDateSearch,
        endDate: stopDateSearch
      });

    fetch(`${primaryAPILink}/api/eassessment/getAllAssessments`, requestOptions)
      .then(res => res.json())
      .then(result => {
        if (result.length !== 0) {
          setResults(result);
          setCurrentPage(1); // Reset to the first page after each search
        } else {
          setResults([]);
        }
      });
  }

  function convertToNormalDateFormat(isoDateString) {
    const date = new Date(isoDateString);
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    };
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
    return formattedDate;
  }

  const sortByField = (field) => {
    const isAscending = sortOrder.field === field ? !sortOrder.ascending : true;
    setSortOrder({ field, ascending: isAscending });

    const sortedResults = [...results];
    sortedResults.sort((a, b) => {
      const valueA = a[field];
      const valueB = b[field];

      if (typeof valueA === 'string') {
        return isAscending ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
      }

      return isAscending ? valueA - valueB : valueB - valueA;
    });

    setResults(sortedResults);
    setCurrentPage(1); // Reset to the first page after sorting
  };

  const getSortIcon = (field) => {
    if (sortOrder.field === field) {
      return sortOrder.ascending ? faSortUp : faSortDown;
    }
    return faSort;
  };

  // Calculate the index range for the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = results.slice(indexOfFirstItem, indexOfLastItem);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <div className='news__edit__page__wrapper'>
            <AdminNavbar active="eassessment" />
            <div className='news__edit__page__content__section'>
                <CrimsonBlockHeader text="EAssessments" />
                <div className='eAssessmentSearch'>
                    <div className='leftRight'>
                    <div className='topDown'>
                        <label htmlFor="startDate">Start Date:</label>
                        <input type="date"
                            id="startDate"
                            name="startDate"
                            value={startDateSearch}
                            onChange={(e) => setStartDateSearch(e.target.value)} />
                    </div>

                    <div className='topDown'>
                        <label htmlFor="stopDate">Stop Date:</label>
                        <input type="date" id="stopDate" name="stopDate"
                            value={stopDateSearch}
                            onChange={(e) => setStopDateSearch(e.target.value)}
                        />
                    </div>

                    <button type="button" className='cms__create__page__button' onClick={() => {
                        updateAssessments();
                    }}>Search</button>
                    </div>

                    <p>Showing {results.length} Assessments</p>
                </div>



                <table className='eAssessmentAdminTable'>
          <thead>
            <tr>
              <th className='viewEyeAdmin'>View</th>
              <th onClick={() => sortByField('projectTitle')} style={{cursor: "pointer"}}>
                Project Title
                <FontAwesomeIcon icon={getSortIcon('projectTitle')} />
              </th>
              <th onClick={() => sortByField('fName')} style={{cursor: "pointer"}}>
                Name
                <FontAwesomeIcon icon={getSortIcon('fName')} />
              </th>
              <th onClick={() => sortByField('email')} style={{cursor: "pointer"}}>
                Email
                <FontAwesomeIcon icon={getSortIcon('email')} />
              </th>
              <th onClick={() => sortByField('submissionDate')} style={{cursor: "pointer"}}>
                Date
                <FontAwesomeIcon icon={getSortIcon('submissionDate')} />
              </th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((item, index) => {
              return (
                <tr key={index}>
                  <td className='viewEyeAdmin'>
                    <Link target="_blank" to={`/eAssessment?id=${item.assessmentID}`}>
                      <FontAwesomeIcon icon={faEye} />
                    </Link>
                  </td>
                  <td>
                    {item.projectTitle}
                  </td>
                  <td>
                    {item.fName} {item.lName}
                  </td>
                  <td>
                    {item.email}
                  </td>
                  <td>
                    {convertToNormalDateFormat(item.submissionDate)}
                  </td>
                </tr>)
            })}
          </tbody>
        </table>
{/* Pagination */}
{results.length > itemsPerPage && (
          <ul className="pagination" style={{listStyle: "none"}}>
            {Array.from({ length: Math.ceil(results.length / itemsPerPage) }).map((_, index) => (
              <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                <button className="page-link" onClick={() => paginate(index + 1)}>
                  {index + 1}
                </button>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default AdminEAssessment;