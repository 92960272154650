import React from 'react'
import { useState } from 'react'
import {primaryAPILink} from '../../../../../config/routes.js'

const AdminCMSCreateLink = () => {
    const [visibleInNav, setVisibleInNav] = useState(false)
    const [activeLink, setActiveLink] = useState(false)
    const [link, setLink] = useState("")
    const [redirectLink, setRedirectLink] = useState("")
    const [label, setLabel] = useState("")
    const [parent, setParent] = useState("about")

    function createNewPage(){
        //add logic for detecting valid links later
        let validLink = false;
        if(checkValidEntry(link, 'Link') && checkValidEntry(label, 'Label') && checkValidRedirect(redirectLink)) {
            validLink = true
        }
        
        if(validLink) {
            console.log("this worked")
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', jwtKey: localStorage.getItem("loginKey")},
                body: JSON.stringify({
                cmsShowInNav: visibleInNav,
                cmsActive: activeLink,
                cmsContent: (JSON.stringify([{link: redirectLink}])),
                title: label,
                cmsLink: link,
                cmsType: "link",
                cmsParentGroup: parent
              })
            };
            fetch(`${primaryAPILink}/api/cms/createCMSPage`, requestOptions)
            window.location.reload();
        }
    }

    function checkValidEntry(input, inputCategory){
        let letters = /^[0-9a-zA-Z-/]+$/;
        if(/\s/g.test(input) || input === "" || !(letters.test(input))){
            alert(`"${input}" is not a valid input for ${inputCategory}. Make sure your ${inputCategory} has no spaces, isn't empty, and only uses valid characters`);
            return(false);
        } else {
            return(true)
        }
    }

    function checkValidRedirect(link) {
        if(/\s/g.test(link)) {
            alert('Redirect links may not contain spaces')
            return(false)
        } else {
            return(true)
        }
    }

  return (
    <div className='admin__cms__create__new__page__popup__content'>
        <div className='admin__cms__create__new__page__popup__content__item'>
            <label>Visible in Navbar:</label>
            <input type="checkbox" onChange={(e)=>setVisibleInNav(!visibleInNav)}></input>
        </div>
        <div className='admin__cms__create__new__page__popup__content__item'>
            <label>Active Link:</label>
            <input type="checkbox" onChange={(e)=>setActiveLink(!activeLink)}></input>
        </div>
        <div className='admin__cms__create__new__page__popup__content__item'>
            <label>Parent Category</label>
            <select id="parent__category__cms" onChange={(e)=>{setParent(e.target.value)}}>
                <option value="" defaultValue hidden>Choose here</option>
                <option value="about">About</option>
                <option value="maps">Maps</option>
                <option value="collections">Collections</option>
                <option value="outreach">Outreach</option>
                <option value="research">Research</option>
                <option value="services">Services</option>
                <option value="other">Other</option>
            </select>
        </div>
        <div className='admin__cms__create__new__page__popup__content__item'>
            <label>Link:</label>
            <input type="input" onChange={(e)=>setLink(e.target.value)}></input>
        </div>
        <div className='admin__cms__create__new__page__popup__content__item'>
            <label>Redirect Link:</label>
            <input type="input" onChange={(e)=>setRedirectLink(e.target.value)}></input>
        </div>
        <div className='admin__cms__create__new__page__popup__content__item'>
            <label>Navbar Label:</label>
            <input type="input" onChange={(e)=>setLabel(e.target.value)}></input>
        </div>
        <button onClick={()=>{createNewPage()}}>Create Page</button>
    </div>
  )
}

export default AdminCMSCreateLink