import React from 'react'
import CrimsonBlockHeader from '../../../components/CrimsonBlockHeader/CrimsonBlockHeader'
import AdminContentExplain from '../AdminContentExplain/AdminContentExplain'
import AdminNavbar from '../AdminNavbar/AdminNavbar'


const AdminIgnis = () => {
  return (
    <div className='news__edit__page__wrapper'>
        <AdminNavbar active="ignis"/>
        <div className='news__edit__page__content__section'>
            <CrimsonBlockHeader text="IGNIS Editor" />
            <AdminContentExplain text={["Info here"]}></AdminContentExplain>

            
        </div>
    </div>
  )
}

export default AdminIgnis