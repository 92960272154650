import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import NewsStoryParagraph from '../NewsStoryParagraph/NewsStoryParagraph'
import NewsStoryPopupImage from '../NewsStoryPopupImage/NewsStoryPopupImage'
import './NewsStoryFloatTextImage.css'

const NewsStoryFloatTextImage = (props) => {
    const [ourClassName, setOurClassName] = useState('news__story__float__text__image')
    useEffect(() => {
        if(props.leftright === "right") {
            setOurClassName('news__story__float__text__image right')
        }
    }, [props.leftright]
    )
  return (
    <div className={ourClassName}>
        <NewsStoryPopupImage src={props.src} text={props.alt} />
        <NewsStoryParagraph text={props.text} />
    </div>
  )
}

export default NewsStoryFloatTextImage