import React from 'react'
import './NewsStoryTitle.css'

const NewsStoryTitle = (props) => {
  console.log(props.tableContent)
  return (
    <h1 className='news__story__title__text'>{props.text}</h1>
  )
}

export default NewsStoryTitle