const processedSolarTemp = {
    "Bloomington": {
        "solar": [
            {
                "time": "Mon, Apr 8, 5:00 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:00 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:01 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:01 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:02 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:02 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:03 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:03 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:04 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:04 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:05 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:05 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:06 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:06 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:07 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:07 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:08 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:08 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:09 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:09 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:10 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:10 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:11 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:11 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:12 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:12 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:13 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:13 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:14 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:14 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:15 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:15 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:16 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:16 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:17 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:17 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:18 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:18 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:19 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:19 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:20 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:20 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:21 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:21 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:22 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:22 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:23 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:23 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:24 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:24 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:25 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:25 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:26 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:26 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:27 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:27 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:28 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:28 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:29 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:29 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:30 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:30 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:31 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:31 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:32 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:32 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:33 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:33 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:34 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:34 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:35 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:35 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:36 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:36 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:37 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:37 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:38 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:38 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:39 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:39 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:40 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:40 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:41 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:41 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:42 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:42 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:43 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:43 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:44 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:44 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:45 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:45 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:46 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:46 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:47 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:47 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:48 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:48 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:49 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:49 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:50 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:50 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:51 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:51 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:52 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:52 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:53 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:53 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:54 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:54 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:55 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:55 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:56 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:56 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:57 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:57 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:58 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:58 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:59 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:59 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:00 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:00 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:01 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:01 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:02 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:02 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:03 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:03 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:04 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:04 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:05 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:05 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:06 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:06 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:07 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:07 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:08 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:08 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:09 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:09 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:10 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:10 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:11 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:11 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:12 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:12 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:13 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:13 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:14 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:14 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:15 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:15 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:16 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:16 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:17 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:17 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:18 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:18 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:19 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:19 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:20 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:20 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:21 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:21 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:22 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:22 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:23 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:23 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:24 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:24 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:25 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:25 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:26 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:26 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:27 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:27 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:28 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:28 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:29 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:29 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:30 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:30 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:31 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:31 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:32 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:32 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:33 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:33 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:34 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:34 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:35 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:35 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:36 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:36 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:37 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:37 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:38 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:38 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:39 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:39 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:40 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:40 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:41 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:41 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:42 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:42 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:43 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:43 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:44 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:44 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:45 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:45 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:46 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:46 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:47 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:47 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:48 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:48 AM",
                "value": 0.11400000005960464
            },
            {
                "time": "Mon, Apr 8, 6:49 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:49 AM",
                "value": 0.11400000005960464
            },
            {
                "time": "Mon, Apr 8, 6:50 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:50 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:51 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:51 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:52 AM",
                "value": 0.11400000005960464
            },
            {
                "time": "Mon, Apr 8, 6:52 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:53 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:53 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:54 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:54 AM",
                "value": 0.22699999809265137
            },
            {
                "time": "Mon, Apr 8, 6:55 AM",
                "value": 0.22699999809265137
            },
            {
                "time": "Mon, Apr 8, 6:55 AM",
                "value": 0.3409999907016754
            },
            {
                "time": "Mon, Apr 8, 6:56 AM",
                "value": 0.3409999907016754
            },
            {
                "time": "Mon, Apr 8, 6:56 AM",
                "value": 0.3409999907016754
            },
            {
                "time": "Mon, Apr 8, 6:57 AM",
                "value": 0.3409999907016754
            },
            {
                "time": "Mon, Apr 8, 6:57 AM",
                "value": 0.3409999907016754
            },
            {
                "time": "Mon, Apr 8, 6:58 AM",
                "value": 0.3409999907016754
            },
            {
                "time": "Mon, Apr 8, 6:58 AM",
                "value": 0.3409999907016754
            },
            {
                "time": "Mon, Apr 8, 6:59 AM",
                "value": 0.3409999907016754
            },
            {
                "time": "Mon, Apr 8, 6:59 AM",
                "value": 0.3409999907016754
            },
            {
                "time": "Mon, Apr 8, 7:00 AM",
                "value": 0.3409999907016754
            },
            {
                "time": "Mon, Apr 8, 7:00 AM",
                "value": 0.3409999907016754
            },
            {
                "time": "Mon, Apr 8, 7:01 AM",
                "value": 0.3409999907016754
            },
            {
                "time": "Mon, Apr 8, 7:01 AM",
                "value": 0.3409999907016754
            },
            {
                "time": "Mon, Apr 8, 7:02 AM",
                "value": 0.3409999907016754
            },
            {
                "time": "Mon, Apr 8, 7:02 AM",
                "value": 0.3409999907016754
            },
            {
                "time": "Mon, Apr 8, 7:03 AM",
                "value": 0.45399999618530273
            },
            {
                "time": "Mon, Apr 8, 7:03 AM",
                "value": 0.6819999814033508
            },
            {
                "time": "Mon, Apr 8, 7:04 AM",
                "value": 0.6819999814033508
            },
            {
                "time": "Mon, Apr 8, 7:04 AM",
                "value": 0.6819999814033508
            },
            {
                "time": "Mon, Apr 8, 7:05 AM",
                "value": 0.6819999814033508
            },
            {
                "time": "Mon, Apr 8, 7:05 AM",
                "value": 0.6819999814033508
            },
            {
                "time": "Mon, Apr 8, 7:06 AM",
                "value": 0.7950000166893005
            },
            {
                "time": "Mon, Apr 8, 7:06 AM",
                "value": 0.9089999794960022
            },
            {
                "time": "Mon, Apr 8, 7:07 AM",
                "value": 1.0230000019073486
            },
            {
                "time": "Mon, Apr 8, 7:07 AM",
                "value": 1.0230000019073486
            },
            {
                "time": "Mon, Apr 8, 7:08 AM",
                "value": 1.0230000019073486
            },
            {
                "time": "Mon, Apr 8, 7:08 AM",
                "value": 1.1360000371932983
            },
            {
                "time": "Mon, Apr 8, 7:09 AM",
                "value": 1.3639999628067017
            },
            {
                "time": "Mon, Apr 8, 7:09 AM",
                "value": 1.3639999628067017
            },
            {
                "time": "Mon, Apr 8, 7:10 AM",
                "value": 1.3639999628067017
            },
            {
                "time": "Mon, Apr 8, 7:10 AM",
                "value": 1.590999960899353
            },
            {
                "time": "Mon, Apr 8, 7:11 AM",
                "value": 1.7039999961853027
            },
            {
                "time": "Mon, Apr 8, 7:11 AM",
                "value": 1.7039999961853027
            },
            {
                "time": "Mon, Apr 8, 7:12 AM",
                "value": 1.7039999961853027
            },
            {
                "time": "Mon, Apr 8, 7:12 AM",
                "value": 1.7039999961853027
            },
            {
                "time": "Mon, Apr 8, 7:13 AM",
                "value": 1.9320000410079956
            },
            {
                "time": "Mon, Apr 8, 7:13 AM",
                "value": 2.0450000762939453
            },
            {
                "time": "Mon, Apr 8, 7:14 AM",
                "value": 2.385999917984009
            },
            {
                "time": "Mon, Apr 8, 7:14 AM",
                "value": 2.385999917984009
            },
            {
                "time": "Mon, Apr 8, 7:15 AM",
                "value": 2.7269999980926514
            },
            {
                "time": "Mon, Apr 8, 7:15 AM",
                "value": 2.7269999980926514
            },
            {
                "time": "Mon, Apr 8, 7:16 AM",
                "value": 3.068000078201294
            },
            {
                "time": "Mon, Apr 8, 7:16 AM",
                "value": 3.068000078201294
            },
            {
                "time": "Mon, Apr 8, 7:17 AM",
                "value": 3.4089999198913574
            },
            {
                "time": "Mon, Apr 8, 7:17 AM",
                "value": 3.5220000743865967
            },
            {
                "time": "Mon, Apr 8, 7:18 AM",
                "value": 3.75
            },
            {
                "time": "Mon, Apr 8, 7:18 AM",
                "value": 3.9769999980926514
            },
            {
                "time": "Mon, Apr 8, 7:19 AM",
                "value": 4.203999996185303
            },
            {
                "time": "Mon, Apr 8, 7:19 AM",
                "value": 4.431000232696533
            },
            {
                "time": "Mon, Apr 8, 7:20 AM",
                "value": 4.771999835968018
            },
            {
                "time": "Mon, Apr 8, 7:20 AM",
                "value": 4.999000072479248
            },
            {
                "time": "Mon, Apr 8, 7:21 AM",
                "value": 5.340000152587891
            },
            {
                "time": "Mon, Apr 8, 7:21 AM",
                "value": 5.567999839782715
            },
            {
                "time": "Mon, Apr 8, 7:22 AM",
                "value": 6.021999835968018
            },
            {
                "time": "Mon, Apr 8, 7:22 AM",
                "value": 6.36299991607666
            },
            {
                "time": "Mon, Apr 8, 7:23 AM",
                "value": 6.703999996185303
            },
            {
                "time": "Mon, Apr 8, 7:23 AM",
                "value": 7.1579999923706055
            },
            {
                "time": "Mon, Apr 8, 7:24 AM",
                "value": 7.499000072479248
            },
            {
                "time": "Mon, Apr 8, 7:24 AM",
                "value": 7.953999996185303
            },
            {
                "time": "Mon, Apr 8, 7:25 AM",
                "value": 8.289999961853027
            },
            {
                "time": "Mon, Apr 8, 7:25 AM",
                "value": 8.640000343322754
            },
            {
                "time": "Mon, Apr 8, 7:26 AM",
                "value": 9.09000015258789
            },
            {
                "time": "Mon, Apr 8, 7:26 AM",
                "value": 9.430000305175781
            },
            {
                "time": "Mon, Apr 8, 7:27 AM",
                "value": 9.890000343322754
            },
            {
                "time": "Mon, Apr 8, 7:27 AM",
                "value": 10.34000015258789
            },
            {
                "time": "Mon, Apr 8, 7:28 AM",
                "value": 10.680000305175781
            },
            {
                "time": "Mon, Apr 8, 7:28 AM",
                "value": 11.140000343322754
            },
            {
                "time": "Mon, Apr 8, 7:29 AM",
                "value": 11.479999542236328
            },
            {
                "time": "Mon, Apr 8, 7:29 AM",
                "value": 11.930000305175781
            },
            {
                "time": "Mon, Apr 8, 7:30 AM",
                "value": 12.390000343322754
            },
            {
                "time": "Mon, Apr 8, 7:30 AM",
                "value": 12.84000015258789
            },
            {
                "time": "Mon, Apr 8, 7:31 AM",
                "value": 13.180000305175781
            },
            {
                "time": "Mon, Apr 8, 7:31 AM",
                "value": 13.630000114440918
            },
            {
                "time": "Mon, Apr 8, 7:32 AM",
                "value": 14.199999809265137
            },
            {
                "time": "Mon, Apr 8, 7:32 AM",
                "value": 14.770000457763672
            },
            {
                "time": "Mon, Apr 8, 7:33 AM",
                "value": 15.229999542236328
            },
            {
                "time": "Mon, Apr 8, 7:33 AM",
                "value": 15.90999984741211
            },
            {
                "time": "Mon, Apr 8, 7:34 AM",
                "value": 16.479999542236328
            },
            {
                "time": "Mon, Apr 8, 7:34 AM",
                "value": 17.040000915527344
            },
            {
                "time": "Mon, Apr 8, 7:35 AM",
                "value": 18.06999969482422
            },
            {
                "time": "Mon, Apr 8, 7:35 AM",
                "value": 19.09000015258789
            },
            {
                "time": "Mon, Apr 8, 7:36 AM",
                "value": 20.110000610351562
            },
            {
                "time": "Mon, Apr 8, 7:36 AM",
                "value": 21.1299991607666
            },
            {
                "time": "Mon, Apr 8, 7:37 AM",
                "value": 22.270000457763672
            },
            {
                "time": "Mon, Apr 8, 7:37 AM",
                "value": 24.200000762939453
            },
            {
                "time": "Mon, Apr 8, 7:38 AM",
                "value": 25.790000915527344
            },
            {
                "time": "Mon, Apr 8, 7:38 AM",
                "value": 27.040000915527344
            },
            {
                "time": "Mon, Apr 8, 7:39 AM",
                "value": 28.290000915527344
            },
            {
                "time": "Mon, Apr 8, 7:39 AM",
                "value": 29.770000457763672
            },
            {
                "time": "Mon, Apr 8, 7:40 AM",
                "value": 32.040000915527344
            },
            {
                "time": "Mon, Apr 8, 7:40 AM",
                "value": 35.45000076293945
            },
            {
                "time": "Mon, Apr 8, 7:41 AM",
                "value": 38.52000045776367
            },
            {
                "time": "Mon, Apr 8, 7:41 AM",
                "value": 41.130001068115234
            },
            {
                "time": "Mon, Apr 8, 7:42 AM",
                "value": 42.5
            },
            {
                "time": "Mon, Apr 8, 7:42 AM",
                "value": 43.630001068115234
            },
            {
                "time": "Mon, Apr 8, 7:43 AM",
                "value": 44.77000045776367
            },
            {
                "time": "Mon, Apr 8, 7:43 AM",
                "value": 45.900001525878906
            },
            {
                "time": "Mon, Apr 8, 7:44 AM",
                "value": 47.27000045776367
            },
            {
                "time": "Mon, Apr 8, 7:44 AM",
                "value": 48.400001525878906
            },
            {
                "time": "Mon, Apr 8, 7:45 AM",
                "value": 49.43000030517578
            },
            {
                "time": "Mon, Apr 8, 7:45 AM",
                "value": 50.68000030517578
            },
            {
                "time": "Mon, Apr 8, 7:46 AM",
                "value": 51.810001373291016
            },
            {
                "time": "Mon, Apr 8, 7:46 AM",
                "value": 53.18000030517578
            },
            {
                "time": "Mon, Apr 8, 7:47 AM",
                "value": 54.20000076293945
            },
            {
                "time": "Mon, Apr 8, 7:47 AM",
                "value": 55.560001373291016
            },
            {
                "time": "Mon, Apr 8, 7:48 AM",
                "value": 56.59000015258789
            },
            {
                "time": "Mon, Apr 8, 7:48 AM",
                "value": 57.84000015258789
            },
            {
                "time": "Mon, Apr 8, 7:49 AM",
                "value": 58.970001220703125
            },
            {
                "time": "Mon, Apr 8, 7:49 AM",
                "value": 59.9900016784668
            },
            {
                "time": "Mon, Apr 8, 7:50 AM",
                "value": 61.130001068115234
            },
            {
                "time": "Mon, Apr 8, 7:50 AM",
                "value": 62.380001068115234
            },
            {
                "time": "Mon, Apr 8, 7:51 AM",
                "value": 63.7400016784668
            },
            {
                "time": "Mon, Apr 8, 7:51 AM",
                "value": 64.87999725341797
            },
            {
                "time": "Mon, Apr 8, 7:52 AM",
                "value": 66.23999786376953
            },
            {
                "time": "Mon, Apr 8, 7:52 AM",
                "value": 67.83000183105469
            },
            {
                "time": "Mon, Apr 8, 7:53 AM",
                "value": 69.08000183105469
            },
            {
                "time": "Mon, Apr 8, 7:53 AM",
                "value": 70.55999755859375
            },
            {
                "time": "Mon, Apr 8, 7:54 AM",
                "value": 71.69999694824219
            },
            {
                "time": "Mon, Apr 8, 7:54 AM",
                "value": 72.94999694824219
            },
            {
                "time": "Mon, Apr 8, 7:55 AM",
                "value": 73.97000122070312
            },
            {
                "time": "Mon, Apr 8, 7:55 AM",
                "value": 75.22000122070312
            },
            {
                "time": "Mon, Apr 8, 7:56 AM",
                "value": 76.47000122070312
            },
            {
                "time": "Mon, Apr 8, 7:56 AM",
                "value": 77.83000183105469
            },
            {
                "time": "Mon, Apr 8, 7:57 AM",
                "value": 79.08000183105469
            },
            {
                "time": "Mon, Apr 8, 7:57 AM",
                "value": 80.4000015258789
            },
            {
                "time": "Mon, Apr 8, 7:58 AM",
                "value": 81.9000015258789
            },
            {
                "time": "Mon, Apr 8, 7:58 AM",
                "value": 83.0999984741211
            },
            {
                "time": "Mon, Apr 8, 7:59 AM",
                "value": 84.4000015258789
            },
            {
                "time": "Mon, Apr 8, 7:59 AM",
                "value": 85.4000015258789
            },
            {
                "time": "Mon, Apr 8, 8:00 AM",
                "value": 86.9000015258789
            },
            {
                "time": "Mon, Apr 8, 8:00 AM",
                "value": 87.9000015258789
            },
            {
                "time": "Mon, Apr 8, 8:01 AM",
                "value": 89
            },
            {
                "time": "Mon, Apr 8, 8:01 AM",
                "value": 89.80000305175781
            },
            {
                "time": "Mon, Apr 8, 8:02 AM",
                "value": 90.69999694824219
            },
            {
                "time": "Mon, Apr 8, 8:02 AM",
                "value": 91.5
            },
            {
                "time": "Mon, Apr 8, 8:03 AM",
                "value": 92.30000305175781
            },
            {
                "time": "Mon, Apr 8, 8:03 AM",
                "value": 92.69999694824219
            },
            {
                "time": "Mon, Apr 8, 8:04 AM",
                "value": 93.9000015258789
            },
            {
                "time": "Mon, Apr 8, 8:04 AM",
                "value": 95.0999984741211
            },
            {
                "time": "Mon, Apr 8, 8:05 AM",
                "value": 96.5
            },
            {
                "time": "Mon, Apr 8, 8:05 AM",
                "value": 97.9000015258789
            },
            {
                "time": "Mon, Apr 8, 8:06 AM",
                "value": 99.0999984741211
            },
            {
                "time": "Mon, Apr 8, 8:06 AM",
                "value": 100.69999694824219
            },
            {
                "time": "Mon, Apr 8, 8:07 AM",
                "value": 102.4000015258789
            },
            {
                "time": "Mon, Apr 8, 8:07 AM",
                "value": 103.69999694824219
            },
            {
                "time": "Mon, Apr 8, 8:08 AM",
                "value": 105.4000015258789
            },
            {
                "time": "Mon, Apr 8, 8:08 AM",
                "value": 107.19999694824219
            },
            {
                "time": "Mon, Apr 8, 8:09 AM",
                "value": 108.5999984741211
            },
            {
                "time": "Mon, Apr 8, 8:09 AM",
                "value": 110
            },
            {
                "time": "Mon, Apr 8, 8:10 AM",
                "value": 110.69999694824219
            },
            {
                "time": "Mon, Apr 8, 8:10 AM",
                "value": 111.80000305175781
            },
            {
                "time": "Mon, Apr 8, 8:11 AM",
                "value": 113.30000305175781
            },
            {
                "time": "Mon, Apr 8, 8:11 AM",
                "value": 114.4000015258789
            },
            {
                "time": "Mon, Apr 8, 8:12 AM",
                "value": 115.4000015258789
            },
            {
                "time": "Mon, Apr 8, 8:12 AM",
                "value": 116.4000015258789
            },
            {
                "time": "Mon, Apr 8, 8:13 AM",
                "value": 116.9000015258789
            },
            {
                "time": "Mon, Apr 8, 8:13 AM",
                "value": 117.9000015258789
            },
            {
                "time": "Mon, Apr 8, 8:14 AM",
                "value": 119
            },
            {
                "time": "Mon, Apr 8, 8:14 AM",
                "value": 120.30000305175781
            },
            {
                "time": "Mon, Apr 8, 8:15 AM",
                "value": 122.30000305175781
            },
            {
                "time": "Mon, Apr 8, 8:15 AM",
                "value": 123.69999694824219
            },
            {
                "time": "Mon, Apr 8, 8:16 AM",
                "value": 125.0999984741211
            },
            {
                "time": "Mon, Apr 8, 8:16 AM",
                "value": 126.19999694824219
            },
            {
                "time": "Mon, Apr 8, 8:17 AM",
                "value": 127.30000305175781
            },
            {
                "time": "Mon, Apr 8, 8:17 AM",
                "value": 128.39999389648438
            },
            {
                "time": "Mon, Apr 8, 8:18 AM",
                "value": 129.39999389648438
            },
            {
                "time": "Mon, Apr 8, 8:18 AM",
                "value": 130.60000610351562
            },
            {
                "time": "Mon, Apr 8, 8:19 AM",
                "value": 132
            },
            {
                "time": "Mon, Apr 8, 8:19 AM",
                "value": 133.39999389648438
            },
            {
                "time": "Mon, Apr 8, 8:20 AM",
                "value": 135.10000610351562
            },
            {
                "time": "Mon, Apr 8, 8:20 AM",
                "value": 137
            },
            {
                "time": "Mon, Apr 8, 8:21 AM",
                "value": 138.39999389648438
            },
            {
                "time": "Mon, Apr 8, 8:21 AM",
                "value": 140
            },
            {
                "time": "Mon, Apr 8, 8:22 AM",
                "value": 141.3000030517578
            },
            {
                "time": "Mon, Apr 8, 8:22 AM",
                "value": 142.5
            },
            {
                "time": "Mon, Apr 8, 8:23 AM",
                "value": 143.8000030517578
            },
            {
                "time": "Mon, Apr 8, 8:23 AM",
                "value": 144.60000610351562
            },
            {
                "time": "Mon, Apr 8, 8:24 AM",
                "value": 145.6999969482422
            },
            {
                "time": "Mon, Apr 8, 8:24 AM",
                "value": 146.89999389648438
            },
            {
                "time": "Mon, Apr 8, 8:25 AM",
                "value": 148.39999389648438
            },
            {
                "time": "Mon, Apr 8, 8:25 AM",
                "value": 149.89999389648438
            },
            {
                "time": "Mon, Apr 8, 8:26 AM",
                "value": 151.1999969482422
            },
            {
                "time": "Mon, Apr 8, 8:26 AM",
                "value": 152.10000610351562
            },
            {
                "time": "Mon, Apr 8, 8:27 AM",
                "value": 153.5
            },
            {
                "time": "Mon, Apr 8, 8:27 AM",
                "value": 154.39999389648438
            },
            {
                "time": "Mon, Apr 8, 8:28 AM",
                "value": 155.10000610351562
            },
            {
                "time": "Mon, Apr 8, 8:28 AM",
                "value": 156.1999969482422
            },
            {
                "time": "Mon, Apr 8, 8:29 AM",
                "value": 157.6999969482422
            },
            {
                "time": "Mon, Apr 8, 8:29 AM",
                "value": 159.5
            },
            {
                "time": "Mon, Apr 8, 8:30 AM",
                "value": 161.10000610351562
            },
            {
                "time": "Mon, Apr 8, 8:30 AM",
                "value": 162.6999969482422
            },
            {
                "time": "Mon, Apr 8, 8:31 AM",
                "value": 163.6999969482422
            },
            {
                "time": "Mon, Apr 8, 8:31 AM",
                "value": 165.3000030517578
            },
            {
                "time": "Mon, Apr 8, 8:32 AM",
                "value": 166.8000030517578
            },
            {
                "time": "Mon, Apr 8, 8:32 AM",
                "value": 167.89999389648438
            },
            {
                "time": "Mon, Apr 8, 8:33 AM",
                "value": 169.10000610351562
            },
            {
                "time": "Mon, Apr 8, 8:33 AM",
                "value": 171
            },
            {
                "time": "Mon, Apr 8, 8:34 AM",
                "value": 172.8000030517578
            },
            {
                "time": "Mon, Apr 8, 8:34 AM",
                "value": 174.5
            },
            {
                "time": "Mon, Apr 8, 8:35 AM",
                "value": 175.8000030517578
            },
            {
                "time": "Mon, Apr 8, 8:35 AM",
                "value": 176.60000610351562
            },
            {
                "time": "Mon, Apr 8, 8:36 AM",
                "value": 177
            },
            {
                "time": "Mon, Apr 8, 8:36 AM",
                "value": 178
            },
            {
                "time": "Mon, Apr 8, 8:37 AM",
                "value": 179.60000610351562
            },
            {
                "time": "Mon, Apr 8, 8:37 AM",
                "value": 181.3000030517578
            },
            {
                "time": "Mon, Apr 8, 8:38 AM",
                "value": 182.6999969482422
            },
            {
                "time": "Mon, Apr 8, 8:38 AM",
                "value": 184
            },
            {
                "time": "Mon, Apr 8, 8:39 AM",
                "value": 185.5
            },
            {
                "time": "Mon, Apr 8, 8:39 AM",
                "value": 186.8000030517578
            },
            {
                "time": "Mon, Apr 8, 8:40 AM",
                "value": 188.3000030517578
            },
            {
                "time": "Mon, Apr 8, 8:40 AM",
                "value": 189.60000610351562
            },
            {
                "time": "Mon, Apr 8, 8:41 AM",
                "value": 189.89999389648438
            },
            {
                "time": "Mon, Apr 8, 8:41 AM",
                "value": 190.5
            },
            {
                "time": "Mon, Apr 8, 8:42 AM",
                "value": 191.60000610351562
            },
            {
                "time": "Mon, Apr 8, 8:42 AM",
                "value": 193.3000030517578
            },
            {
                "time": "Mon, Apr 8, 8:43 AM",
                "value": 194.10000610351562
            },
            {
                "time": "Mon, Apr 8, 8:43 AM",
                "value": 194.39999389648438
            },
            {
                "time": "Mon, Apr 8, 8:44 AM",
                "value": 195.39999389648438
            },
            {
                "time": "Mon, Apr 8, 8:44 AM",
                "value": 196.1999969482422
            },
            {
                "time": "Mon, Apr 8, 8:45 AM",
                "value": 196.8000030517578
            },
            {
                "time": "Mon, Apr 8, 8:45 AM",
                "value": 197.10000610351562
            },
            {
                "time": "Mon, Apr 8, 8:46 AM",
                "value": 198.39999389648438
            },
            {
                "time": "Mon, Apr 8, 8:46 AM",
                "value": 199.89999389648438
            },
            {
                "time": "Mon, Apr 8, 8:47 AM",
                "value": 199.5
            },
            {
                "time": "Mon, Apr 8, 8:47 AM",
                "value": 200.10000610351562
            },
            {
                "time": "Mon, Apr 8, 8:48 AM",
                "value": 201
            },
            {
                "time": "Mon, Apr 8, 8:48 AM",
                "value": 192.6999969482422
            },
            {
                "time": "Mon, Apr 8, 8:49 AM",
                "value": 195.1999969482422
            },
            {
                "time": "Mon, Apr 8, 8:49 AM",
                "value": 203
            },
            {
                "time": "Mon, Apr 8, 8:50 AM",
                "value": 204.39999389648438
            },
            {
                "time": "Mon, Apr 8, 8:50 AM",
                "value": 204.39999389648438
            },
            {
                "time": "Mon, Apr 8, 8:51 AM",
                "value": 185.1999969482422
            },
            {
                "time": "Mon, Apr 8, 8:51 AM",
                "value": 202.89999389648438
            },
            {
                "time": "Mon, Apr 8, 8:52 AM",
                "value": 172.60000610351562
            },
            {
                "time": "Mon, Apr 8, 8:52 AM",
                "value": 158.6999969482422
            },
            {
                "time": "Mon, Apr 8, 8:53 AM",
                "value": 139.39999389648438
            },
            {
                "time": "Mon, Apr 8, 8:53 AM",
                "value": 142.5
            },
            {
                "time": "Mon, Apr 8, 8:54 AM",
                "value": 133.1999969482422
            },
            {
                "time": "Mon, Apr 8, 8:54 AM",
                "value": 112
            },
            {
                "time": "Mon, Apr 8, 8:55 AM",
                "value": 112.80000305175781
            },
            {
                "time": "Mon, Apr 8, 8:55 AM",
                "value": 123.19999694824219
            },
            {
                "time": "Mon, Apr 8, 8:56 AM",
                "value": 113.5999984741211
            },
            {
                "time": "Mon, Apr 8, 8:56 AM",
                "value": 121.80000305175781
            },
            {
                "time": "Mon, Apr 8, 8:57 AM",
                "value": 125.80000305175781
            },
            {
                "time": "Mon, Apr 8, 8:57 AM",
                "value": 121.69999694824219
            },
            {
                "time": "Mon, Apr 8, 8:58 AM",
                "value": 120.80000305175781
            },
            {
                "time": "Mon, Apr 8, 8:58 AM",
                "value": 123.5999984741211
            },
            {
                "time": "Mon, Apr 8, 8:59 AM",
                "value": 124.5
            },
            {
                "time": "Mon, Apr 8, 8:59 AM",
                "value": 135
            },
            {
                "time": "Mon, Apr 8, 9:00 AM",
                "value": 129.5
            },
            {
                "time": "Mon, Apr 8, 9:00 AM",
                "value": 128.39999389648438
            },
            {
                "time": "Mon, Apr 8, 9:01 AM",
                "value": 140.5
            },
            {
                "time": "Mon, Apr 8, 9:01 AM",
                "value": 143.39999389648438
            },
            {
                "time": "Mon, Apr 8, 9:02 AM",
                "value": 131.60000610351562
            },
            {
                "time": "Mon, Apr 8, 9:02 AM",
                "value": 122.0999984741211
            },
            {
                "time": "Mon, Apr 8, 9:03 AM",
                "value": 139.39999389648438
            },
            {
                "time": "Mon, Apr 8, 9:03 AM",
                "value": 138.6999969482422
            },
            {
                "time": "Mon, Apr 8, 9:04 AM",
                "value": 130.39999389648438
            },
            {
                "time": "Mon, Apr 8, 9:04 AM",
                "value": 142.10000610351562
            },
            {
                "time": "Mon, Apr 8, 9:05 AM",
                "value": 145.60000610351562
            },
            {
                "time": "Mon, Apr 8, 9:05 AM",
                "value": 140.10000610351562
            },
            {
                "time": "Mon, Apr 8, 9:06 AM",
                "value": 129.5
            },
            {
                "time": "Mon, Apr 8, 9:06 AM",
                "value": 133.10000610351562
            },
            {
                "time": "Mon, Apr 8, 9:07 AM",
                "value": 136.10000610351562
            },
            {
                "time": "Mon, Apr 8, 9:07 AM",
                "value": 152.5
            },
            {
                "time": "Mon, Apr 8, 9:08 AM",
                "value": 130.39999389648438
            },
            {
                "time": "Mon, Apr 8, 9:08 AM",
                "value": 137.5
            },
            {
                "time": "Mon, Apr 8, 9:09 AM",
                "value": 156.6999969482422
            },
            {
                "time": "Mon, Apr 8, 9:09 AM",
                "value": 162.10000610351562
            },
            {
                "time": "Mon, Apr 8, 9:10 AM",
                "value": 161.1999969482422
            },
            {
                "time": "Mon, Apr 8, 9:10 AM",
                "value": 180.10000610351562
            },
            {
                "time": "Mon, Apr 8, 9:11 AM",
                "value": 144.8000030517578
            },
            {
                "time": "Mon, Apr 8, 9:11 AM",
                "value": 147.8000030517578
            },
            {
                "time": "Mon, Apr 8, 9:12 AM",
                "value": 148.8000030517578
            },
            {
                "time": "Mon, Apr 8, 9:12 AM",
                "value": 161.8000030517578
            },
            {
                "time": "Mon, Apr 8, 9:13 AM",
                "value": 172.5
            },
            {
                "time": "Mon, Apr 8, 9:13 AM",
                "value": 165.60000610351562
            },
            {
                "time": "Mon, Apr 8, 9:14 AM",
                "value": 146.6999969482422
            },
            {
                "time": "Mon, Apr 8, 9:14 AM",
                "value": 151.89999389648438
            },
            {
                "time": "Mon, Apr 8, 9:15 AM",
                "value": 145.10000610351562
            },
            {
                "time": "Mon, Apr 8, 9:15 AM",
                "value": 191.39999389648438
            },
            {
                "time": "Mon, Apr 8, 9:16 AM",
                "value": 190.6999969482422
            },
            {
                "time": "Mon, Apr 8, 9:16 AM",
                "value": 207
            },
            {
                "time": "Mon, Apr 8, 9:17 AM",
                "value": 165.60000610351562
            },
            {
                "time": "Mon, Apr 8, 9:17 AM",
                "value": 157.8000030517578
            },
            {
                "time": "Mon, Apr 8, 9:18 AM",
                "value": 147
            },
            {
                "time": "Mon, Apr 8, 9:18 AM",
                "value": 164.8000030517578
            },
            {
                "time": "Mon, Apr 8, 9:19 AM",
                "value": 176.3000030517578
            },
            {
                "time": "Mon, Apr 8, 9:19 AM",
                "value": 172
            },
            {
                "time": "Mon, Apr 8, 9:20 AM",
                "value": 170.3000030517578
            },
            {
                "time": "Mon, Apr 8, 9:20 AM",
                "value": 174
            },
            {
                "time": "Mon, Apr 8, 9:21 AM",
                "value": 194.89999389648438
            },
            {
                "time": "Mon, Apr 8, 9:21 AM",
                "value": 160.89999389648438
            },
            {
                "time": "Mon, Apr 8, 9:22 AM",
                "value": 187.6999969482422
            },
            {
                "time": "Mon, Apr 8, 9:22 AM",
                "value": 163.5
            },
            {
                "time": "Mon, Apr 8, 9:23 AM",
                "value": 172.3000030517578
            },
            {
                "time": "Mon, Apr 8, 9:23 AM",
                "value": 177.39999389648438
            },
            {
                "time": "Mon, Apr 8, 9:24 AM",
                "value": 180.6999969482422
            },
            {
                "time": "Mon, Apr 8, 9:24 AM",
                "value": 190
            },
            {
                "time": "Mon, Apr 8, 9:25 AM",
                "value": 172.1999969482422
            },
            {
                "time": "Mon, Apr 8, 9:25 AM",
                "value": 218
            },
            {
                "time": "Mon, Apr 8, 9:26 AM",
                "value": 225
            },
            {
                "time": "Mon, Apr 8, 9:26 AM",
                "value": 188.1999969482422
            },
            {
                "time": "Mon, Apr 8, 9:27 AM",
                "value": 166
            },
            {
                "time": "Mon, Apr 8, 9:27 AM",
                "value": 171.6999969482422
            },
            {
                "time": "Mon, Apr 8, 9:28 AM",
                "value": 199.8000030517578
            },
            {
                "time": "Mon, Apr 8, 9:28 AM",
                "value": 244.1999969482422
            },
            {
                "time": "Mon, Apr 8, 9:29 AM",
                "value": 212.5
            },
            {
                "time": "Mon, Apr 8, 9:29 AM",
                "value": 210.8000030517578
            },
            {
                "time": "Mon, Apr 8, 9:30 AM",
                "value": 194.1999969482422
            },
            {
                "time": "Mon, Apr 8, 9:30 AM",
                "value": 201.6999969482422
            },
            {
                "time": "Mon, Apr 8, 9:31 AM",
                "value": 213.8000030517578
            },
            {
                "time": "Mon, Apr 8, 9:31 AM",
                "value": 253.10000610351562
            },
            {
                "time": "Mon, Apr 8, 9:32 AM",
                "value": 220.5
            },
            {
                "time": "Mon, Apr 8, 9:32 AM",
                "value": 279.79998779296875
            },
            {
                "time": "Mon, Apr 8, 9:33 AM",
                "value": 203.5
            },
            {
                "time": "Mon, Apr 8, 9:33 AM",
                "value": 200.3000030517578
            },
            {
                "time": "Mon, Apr 8, 9:34 AM",
                "value": 204.6999969482422
            },
            {
                "time": "Mon, Apr 8, 9:34 AM",
                "value": 176.3000030517578
            },
            {
                "time": "Mon, Apr 8, 9:35 AM",
                "value": 189
            },
            {
                "time": "Mon, Apr 8, 9:35 AM",
                "value": 186.39999389648438
            },
            {
                "time": "Mon, Apr 8, 9:36 AM",
                "value": 189
            },
            {
                "time": "Mon, Apr 8, 9:36 AM",
                "value": 145.3000030517578
            },
            {
                "time": "Mon, Apr 8, 9:37 AM",
                "value": 181.60000610351562
            },
            {
                "time": "Mon, Apr 8, 9:37 AM",
                "value": 187.89999389648438
            },
            {
                "time": "Mon, Apr 8, 9:38 AM",
                "value": 173.8000030517578
            },
            {
                "time": "Mon, Apr 8, 9:38 AM",
                "value": 168.1999969482422
            },
            {
                "time": "Mon, Apr 8, 9:39 AM",
                "value": 158.60000610351562
            },
            {
                "time": "Mon, Apr 8, 9:39 AM",
                "value": 145.8000030517578
            },
            {
                "time": "Mon, Apr 8, 9:40 AM",
                "value": 141.39999389648438
            },
            {
                "time": "Mon, Apr 8, 9:40 AM",
                "value": 125.4000015258789
            },
            {
                "time": "Mon, Apr 8, 9:41 AM",
                "value": 111.9000015258789
            },
            {
                "time": "Mon, Apr 8, 9:41 AM",
                "value": 115.19999694824219
            },
            {
                "time": "Mon, Apr 8, 9:42 AM",
                "value": 143.3000030517578
            },
            {
                "time": "Mon, Apr 8, 9:42 AM",
                "value": 143.8000030517578
            },
            {
                "time": "Mon, Apr 8, 9:43 AM",
                "value": 165.39999389648438
            },
            {
                "time": "Mon, Apr 8, 9:43 AM",
                "value": 177.8000030517578
            },
            {
                "time": "Mon, Apr 8, 9:44 AM",
                "value": 227.60000610351562
            },
            {
                "time": "Mon, Apr 8, 9:44 AM",
                "value": 193.10000610351562
            },
            {
                "time": "Mon, Apr 8, 9:45 AM",
                "value": 260.5
            },
            {
                "time": "Mon, Apr 8, 9:45 AM",
                "value": 236.39999389648438
            },
            {
                "time": "Mon, Apr 8, 9:46 AM",
                "value": 217.39999389648438
            },
            {
                "time": "Mon, Apr 8, 9:46 AM",
                "value": 168
            },
            {
                "time": "Mon, Apr 8, 9:47 AM",
                "value": 260.8999938964844
            },
            {
                "time": "Mon, Apr 8, 9:47 AM",
                "value": 266.70001220703125
            },
            {
                "time": "Mon, Apr 8, 9:48 AM",
                "value": 191.1999969482422
            },
            {
                "time": "Mon, Apr 8, 9:48 AM",
                "value": 158.1999969482422
            },
            {
                "time": "Mon, Apr 8, 9:49 AM",
                "value": 314.3999938964844
            },
            {
                "time": "Mon, Apr 8, 9:49 AM",
                "value": 404.6000061035156
            },
            {
                "time": "Mon, Apr 8, 9:50 AM",
                "value": 377.79998779296875
            },
            {
                "time": "Mon, Apr 8, 9:50 AM",
                "value": 240.60000610351562
            },
            {
                "time": "Mon, Apr 8, 9:51 AM",
                "value": 352.8999938964844
            },
            {
                "time": "Mon, Apr 8, 9:51 AM",
                "value": 298.70001220703125
            },
            {
                "time": "Mon, Apr 8, 9:52 AM",
                "value": 235.60000610351562
            },
            {
                "time": "Mon, Apr 8, 9:52 AM",
                "value": 280.20001220703125
            },
            {
                "time": "Mon, Apr 8, 9:53 AM",
                "value": 332.6000061035156
            },
            {
                "time": "Mon, Apr 8, 9:53 AM",
                "value": 210.60000610351562
            },
            {
                "time": "Mon, Apr 8, 9:54 AM",
                "value": 232.8000030517578
            },
            {
                "time": "Mon, Apr 8, 9:54 AM",
                "value": 360.1000061035156
            },
            {
                "time": "Mon, Apr 8, 9:55 AM",
                "value": 347.3999938964844
            },
            {
                "time": "Mon, Apr 8, 9:55 AM",
                "value": 333.6000061035156
            },
            {
                "time": "Mon, Apr 8, 9:56 AM",
                "value": 335.29998779296875
            },
            {
                "time": "Mon, Apr 8, 9:56 AM",
                "value": 315.5
            },
            {
                "time": "Mon, Apr 8, 9:57 AM",
                "value": 283.79998779296875
            },
            {
                "time": "Mon, Apr 8, 9:57 AM",
                "value": 242.39999389648438
            },
            {
                "time": "Mon, Apr 8, 9:58 AM",
                "value": 253.1999969482422
            },
            {
                "time": "Mon, Apr 8, 9:58 AM",
                "value": 225.3000030517578
            },
            {
                "time": "Mon, Apr 8, 9:59 AM",
                "value": 192.60000610351562
            },
            {
                "time": "Mon, Apr 8, 9:59 AM",
                "value": 180.8000030517578
            },
            {
                "time": "Mon, Apr 8, 10:00 AM",
                "value": 156.6999969482422
            },
            {
                "time": "Mon, Apr 8, 10:00 AM",
                "value": 142.8000030517578
            },
            {
                "time": "Mon, Apr 8, 10:01 AM",
                "value": 135.1999969482422
            },
            {
                "time": "Mon, Apr 8, 10:01 AM",
                "value": 124.9000015258789
            },
            {
                "time": "Mon, Apr 8, 10:02 AM",
                "value": 114.5
            },
            {
                "time": "Mon, Apr 8, 10:02 AM",
                "value": 108
            },
            {
                "time": "Mon, Apr 8, 10:03 AM",
                "value": 101.30000305175781
            },
            {
                "time": "Mon, Apr 8, 10:03 AM",
                "value": 98
            },
            {
                "time": "Mon, Apr 8, 10:04 AM",
                "value": 96.80000305175781
            },
            {
                "time": "Mon, Apr 8, 10:04 AM",
                "value": 96.80000305175781
            },
            {
                "time": "Mon, Apr 8, 10:05 AM",
                "value": 97.5999984741211
            },
            {
                "time": "Mon, Apr 8, 10:05 AM",
                "value": 96.9000015258789
            },
            {
                "time": "Mon, Apr 8, 10:06 AM",
                "value": 97.30000305175781
            },
            {
                "time": "Mon, Apr 8, 10:06 AM",
                "value": 97.9000015258789
            },
            {
                "time": "Mon, Apr 8, 10:07 AM",
                "value": 98.5
            },
            {
                "time": "Mon, Apr 8, 10:07 AM",
                "value": 98.5999984741211
            },
            {
                "time": "Mon, Apr 8, 10:08 AM",
                "value": 98.5
            },
            {
                "time": "Mon, Apr 8, 10:08 AM",
                "value": 99.80000305175781
            },
            {
                "time": "Mon, Apr 8, 10:09 AM",
                "value": 102.19999694824219
            },
            {
                "time": "Mon, Apr 8, 10:09 AM",
                "value": 103.80000305175781
            },
            {
                "time": "Mon, Apr 8, 10:10 AM",
                "value": 104.0999984741211
            },
            {
                "time": "Mon, Apr 8, 10:10 AM",
                "value": 103.69999694824219
            },
            {
                "time": "Mon, Apr 8, 10:11 AM",
                "value": 103.80000305175781
            },
            {
                "time": "Mon, Apr 8, 10:11 AM",
                "value": 102.4000015258789
            },
            {
                "time": "Mon, Apr 8, 10:12 AM",
                "value": 101.19999694824219
            },
            {
                "time": "Mon, Apr 8, 10:12 AM",
                "value": 98.80000305175781
            },
            {
                "time": "Mon, Apr 8, 10:13 AM",
                "value": 98.9000015258789
            },
            {
                "time": "Mon, Apr 8, 10:13 AM",
                "value": 98.9000015258789
            },
            {
                "time": "Mon, Apr 8, 10:14 AM",
                "value": 97.5999984741211
            },
            {
                "time": "Mon, Apr 8, 10:14 AM",
                "value": 99.9000015258789
            },
            {
                "time": "Mon, Apr 8, 10:15 AM",
                "value": 101.0999984741211
            },
            {
                "time": "Mon, Apr 8, 10:15 AM",
                "value": 100.30000305175781
            },
            {
                "time": "Mon, Apr 8, 10:16 AM",
                "value": 99
            },
            {
                "time": "Mon, Apr 8, 10:16 AM",
                "value": 98.80000305175781
            },
            {
                "time": "Mon, Apr 8, 10:17 AM",
                "value": 99.5
            },
            {
                "time": "Mon, Apr 8, 10:17 AM",
                "value": 99.0999984741211
            },
            {
                "time": "Mon, Apr 8, 10:18 AM",
                "value": 99.30000305175781
            },
            {
                "time": "Mon, Apr 8, 10:18 AM",
                "value": 99
            },
            {
                "time": "Mon, Apr 8, 10:19 AM",
                "value": 98.69999694824219
            },
            {
                "time": "Mon, Apr 8, 10:19 AM",
                "value": 97.0999984741211
            },
            {
                "time": "Mon, Apr 8, 10:20 AM",
                "value": 95.30000305175781
            },
            {
                "time": "Mon, Apr 8, 10:20 AM",
                "value": 97.9000015258789
            },
            {
                "time": "Mon, Apr 8, 10:21 AM",
                "value": 99.4000015258789
            },
            {
                "time": "Mon, Apr 8, 10:21 AM",
                "value": 98.80000305175781
            },
            {
                "time": "Mon, Apr 8, 10:22 AM",
                "value": 97.9000015258789
            },
            {
                "time": "Mon, Apr 8, 10:22 AM",
                "value": 96.4000015258789
            },
            {
                "time": "Mon, Apr 8, 10:23 AM",
                "value": 95.30000305175781
            },
            {
                "time": "Mon, Apr 8, 10:23 AM",
                "value": 92.80000305175781
            },
            {
                "time": "Mon, Apr 8, 10:24 AM",
                "value": 89.5
            },
            {
                "time": "Mon, Apr 8, 10:24 AM",
                "value": 87.4000015258789
            },
            {
                "time": "Mon, Apr 8, 10:25 AM",
                "value": 85.5
            },
            {
                "time": "Mon, Apr 8, 10:25 AM",
                "value": 82.80000305175781
            },
            {
                "time": "Mon, Apr 8, 10:26 AM",
                "value": 82.69999694824219
            },
            {
                "time": "Mon, Apr 8, 10:26 AM",
                "value": 84.30000305175781
            },
            {
                "time": "Mon, Apr 8, 10:27 AM",
                "value": 82.80000305175781
            },
            {
                "time": "Mon, Apr 8, 10:27 AM",
                "value": 81.19999694824219
            },
            {
                "time": "Mon, Apr 8, 10:28 AM",
                "value": 81.19999694824219
            },
            {
                "time": "Mon, Apr 8, 10:28 AM",
                "value": 79.5
            },
            {
                "time": "Mon, Apr 8, 10:29 AM",
                "value": 80.0999984741211
            },
            {
                "time": "Mon, Apr 8, 10:29 AM",
                "value": 81.19999694824219
            },
            {
                "time": "Mon, Apr 8, 10:30 AM",
                "value": 81.19999694824219
            },
            {
                "time": "Mon, Apr 8, 10:30 AM",
                "value": 80.19999694824219
            },
            {
                "time": "Mon, Apr 8, 10:31 AM",
                "value": 79.61000061035156
            },
            {
                "time": "Mon, Apr 8, 10:31 AM",
                "value": 80.0999984741211
            },
            {
                "time": "Mon, Apr 8, 10:32 AM",
                "value": 80.9000015258789
            },
            {
                "time": "Mon, Apr 8, 10:32 AM",
                "value": 81.80000305175781
            },
            {
                "time": "Mon, Apr 8, 10:33 AM",
                "value": 82.69999694824219
            },
            {
                "time": "Mon, Apr 8, 10:33 AM",
                "value": 83.5999984741211
            },
            {
                "time": "Mon, Apr 8, 10:34 AM",
                "value": 83.69999694824219
            },
            {
                "time": "Mon, Apr 8, 10:34 AM",
                "value": 83.5
            },
            {
                "time": "Mon, Apr 8, 10:35 AM",
                "value": 82.9000015258789
            },
            {
                "time": "Mon, Apr 8, 10:35 AM",
                "value": 82.0999984741211
            },
            {
                "time": "Mon, Apr 8, 10:36 AM",
                "value": 80.5
            },
            {
                "time": "Mon, Apr 8, 10:36 AM",
                "value": 79.2699966430664
            },
            {
                "time": "Mon, Apr 8, 10:37 AM",
                "value": 78.0199966430664
            },
            {
                "time": "Mon, Apr 8, 10:37 AM",
                "value": 75.29000091552734
            },
            {
                "time": "Mon, Apr 8, 10:38 AM",
                "value": 75.86000061035156
            },
            {
                "time": "Mon, Apr 8, 10:38 AM",
                "value": 80.4000015258789
            },
            {
                "time": "Mon, Apr 8, 10:39 AM",
                "value": 80.4000015258789
            },
            {
                "time": "Mon, Apr 8, 10:39 AM",
                "value": 80.30000305175781
            },
            {
                "time": "Mon, Apr 8, 10:40 AM",
                "value": 80.30000305175781
            },
            {
                "time": "Mon, Apr 8, 10:40 AM",
                "value": 80.5999984741211
            },
            {
                "time": "Mon, Apr 8, 10:41 AM",
                "value": 81.0999984741211
            },
            {
                "time": "Mon, Apr 8, 10:41 AM",
                "value": 82.0999984741211
            },
            {
                "time": "Mon, Apr 8, 10:42 AM",
                "value": 83.4000015258789
            },
            {
                "time": "Mon, Apr 8, 10:42 AM",
                "value": 85.30000305175781
            },
            {
                "time": "Mon, Apr 8, 10:43 AM",
                "value": 86.4000015258789
            },
            {
                "time": "Mon, Apr 8, 10:43 AM",
                "value": 85.5999984741211
            },
            {
                "time": "Mon, Apr 8, 10:44 AM",
                "value": 83
            },
            {
                "time": "Mon, Apr 8, 10:44 AM",
                "value": 78.69999694824219
            },
            {
                "time": "Mon, Apr 8, 10:45 AM",
                "value": 79.5
            },
            {
                "time": "Mon, Apr 8, 10:45 AM",
                "value": 77.11000061035156
            },
            {
                "time": "Mon, Apr 8, 10:46 AM",
                "value": 78.58999633789062
            },
            {
                "time": "Mon, Apr 8, 10:46 AM",
                "value": 81.4000015258789
            },
            {
                "time": "Mon, Apr 8, 10:47 AM",
                "value": 86
            },
            {
                "time": "Mon, Apr 8, 10:47 AM",
                "value": 89.9000015258789
            },
            {
                "time": "Mon, Apr 8, 10:48 AM",
                "value": 99.80000305175781
            },
            {
                "time": "Mon, Apr 8, 10:48 AM",
                "value": 112.19999694824219
            },
            {
                "time": "Mon, Apr 8, 10:49 AM",
                "value": 128.60000610351562
            },
            {
                "time": "Mon, Apr 8, 10:49 AM",
                "value": 147.1999969482422
            },
            {
                "time": "Mon, Apr 8, 10:50 AM",
                "value": 160.60000610351562
            },
            {
                "time": "Mon, Apr 8, 10:50 AM",
                "value": 159.89999389648438
            },
            {
                "time": "Mon, Apr 8, 10:51 AM",
                "value": 155.39999389648438
            },
            {
                "time": "Mon, Apr 8, 10:51 AM",
                "value": 139.1999969482422
            },
            {
                "time": "Mon, Apr 8, 10:52 AM",
                "value": 122.4000015258789
            },
            {
                "time": "Mon, Apr 8, 10:52 AM",
                "value": 110.80000305175781
            },
            {
                "time": "Mon, Apr 8, 10:53 AM",
                "value": 105.30000305175781
            },
            {
                "time": "Mon, Apr 8, 10:53 AM",
                "value": 95.5
            },
            {
                "time": "Mon, Apr 8, 10:54 AM",
                "value": 88.0999984741211
            },
            {
                "time": "Mon, Apr 8, 10:54 AM",
                "value": 81.9000015258789
            },
            {
                "time": "Mon, Apr 8, 10:55 AM",
                "value": 78.80999755859375
            },
            {
                "time": "Mon, Apr 8, 10:55 AM",
                "value": 77.11000061035156
            },
            {
                "time": "Mon, Apr 8, 10:56 AM",
                "value": 76.6500015258789
            },
            {
                "time": "Mon, Apr 8, 10:56 AM",
                "value": 76.6500015258789
            },
            {
                "time": "Mon, Apr 8, 10:57 AM",
                "value": 76.6500015258789
            },
            {
                "time": "Mon, Apr 8, 10:57 AM",
                "value": 77.22000122070312
            },
            {
                "time": "Mon, Apr 8, 10:58 AM",
                "value": 78.12000274658203
            },
            {
                "time": "Mon, Apr 8, 10:58 AM",
                "value": 78.80000305175781
            },
            {
                "time": "Mon, Apr 8, 10:59 AM",
                "value": 78.91999816894531
            },
            {
                "time": "Mon, Apr 8, 10:59 AM",
                "value": 78.47000122070312
            },
            {
                "time": "Mon, Apr 8, 11:00 AM",
                "value": 78.01000213623047
            },
            {
                "time": "Mon, Apr 8, 11:00 AM",
                "value": 77.0999984741211
            },
            {
                "time": "Mon, Apr 8, 11:01 AM",
                "value": 76.30999755859375
            },
            {
                "time": "Mon, Apr 8, 11:01 AM",
                "value": 75.16999816894531
            },
            {
                "time": "Mon, Apr 8, 11:02 AM",
                "value": 75.05999755859375
            },
            {
                "time": "Mon, Apr 8, 11:02 AM",
                "value": 76.52999877929688
            },
            {
                "time": "Mon, Apr 8, 11:03 AM",
                "value": 78.01000213623047
            },
            {
                "time": "Mon, Apr 8, 11:03 AM",
                "value": 79.4800033569336
            },
            {
                "time": "Mon, Apr 8, 11:04 AM",
                "value": 81.0999984741211
            },
            {
                "time": "Mon, Apr 8, 11:04 AM",
                "value": 82.30000305175781
            },
            {
                "time": "Mon, Apr 8, 11:05 AM",
                "value": 83.30000305175781
            },
            {
                "time": "Mon, Apr 8, 11:05 AM",
                "value": 83.80000305175781
            },
            {
                "time": "Mon, Apr 8, 11:06 AM",
                "value": 83.80000305175781
            },
            {
                "time": "Mon, Apr 8, 11:06 AM",
                "value": 83.5
            },
            {
                "time": "Mon, Apr 8, 11:07 AM",
                "value": 82.4000015258789
            },
            {
                "time": "Mon, Apr 8, 11:07 AM",
                "value": 81.5999984741211
            },
            {
                "time": "Mon, Apr 8, 11:08 AM",
                "value": 80.80000305175781
            },
            {
                "time": "Mon, Apr 8, 11:08 AM",
                "value": 80.5
            },
            {
                "time": "Mon, Apr 8, 11:09 AM",
                "value": 80.69999694824219
            },
            {
                "time": "Mon, Apr 8, 11:09 AM",
                "value": 80.69999694824219
            },
            {
                "time": "Mon, Apr 8, 11:10 AM",
                "value": 81.0999984741211
            },
            {
                "time": "Mon, Apr 8, 11:10 AM",
                "value": 80.5
            },
            {
                "time": "Mon, Apr 8, 11:11 AM",
                "value": 79.13999938964844
            },
            {
                "time": "Mon, Apr 8, 11:11 AM",
                "value": 78.56999969482422
            },
            {
                "time": "Mon, Apr 8, 11:12 AM",
                "value": 78.69000244140625
            },
            {
                "time": "Mon, Apr 8, 11:12 AM",
                "value": 78.12000274658203
            },
            {
                "time": "Mon, Apr 8, 11:13 AM",
                "value": 77.20999908447266
            },
            {
                "time": "Mon, Apr 8, 11:13 AM",
                "value": 76.9800033569336
            },
            {
                "time": "Mon, Apr 8, 11:14 AM",
                "value": 76.41000366210938
            },
            {
                "time": "Mon, Apr 8, 11:14 AM",
                "value": 75.95999908447266
            },
            {
                "time": "Mon, Apr 8, 11:15 AM",
                "value": 75.8499984741211
            },
            {
                "time": "Mon, Apr 8, 11:15 AM",
                "value": 75.51000213623047
            },
            {
                "time": "Mon, Apr 8, 11:16 AM",
                "value": 75.05000305175781
            },
            {
                "time": "Mon, Apr 8, 11:16 AM",
                "value": 74.70999908447266
            },
            {
                "time": "Mon, Apr 8, 11:17 AM",
                "value": 74.58999633789062
            },
            {
                "time": "Mon, Apr 8, 11:17 AM",
                "value": 74.02999877929688
            },
            {
                "time": "Mon, Apr 8, 11:18 AM",
                "value": 73.91000366210938
            },
            {
                "time": "Mon, Apr 8, 11:18 AM",
                "value": 73.91000366210938
            },
            {
                "time": "Mon, Apr 8, 11:19 AM",
                "value": 73.69000244140625
            },
            {
                "time": "Mon, Apr 8, 11:19 AM",
                "value": 73.68000030517578
            },
            {
                "time": "Mon, Apr 8, 11:20 AM",
                "value": 73.56999969482422
            },
            {
                "time": "Mon, Apr 8, 11:20 AM",
                "value": 73.68000030517578
            },
            {
                "time": "Mon, Apr 8, 11:21 AM",
                "value": 73.56999969482422
            },
            {
                "time": "Mon, Apr 8, 11:21 AM",
                "value": 73.56999969482422
            },
            {
                "time": "Mon, Apr 8, 11:22 AM",
                "value": 73.56999969482422
            },
            {
                "time": "Mon, Apr 8, 11:22 AM",
                "value": 73.45999908447266
            },
            {
                "time": "Mon, Apr 8, 11:23 AM",
                "value": 73.56999969482422
            },
            {
                "time": "Mon, Apr 8, 11:23 AM",
                "value": 73.56999969482422
            },
            {
                "time": "Mon, Apr 8, 11:24 AM",
                "value": 73.56999969482422
            },
            {
                "time": "Mon, Apr 8, 11:24 AM",
                "value": 73.56999969482422
            },
            {
                "time": "Mon, Apr 8, 11:25 AM",
                "value": 73.68000030517578
            },
            {
                "time": "Mon, Apr 8, 11:25 AM",
                "value": 73.56999969482422
            },
            {
                "time": "Mon, Apr 8, 11:26 AM",
                "value": 74.13999938964844
            },
            {
                "time": "Mon, Apr 8, 11:26 AM",
                "value": 74.69999694824219
            },
            {
                "time": "Mon, Apr 8, 11:27 AM",
                "value": 73.79000091552734
            },
            {
                "time": "Mon, Apr 8, 11:27 AM",
                "value": 73.91000366210938
            },
            {
                "time": "Mon, Apr 8, 11:28 AM",
                "value": 74.0199966430664
            },
            {
                "time": "Mon, Apr 8, 11:28 AM",
                "value": 74.81999969482422
            },
            {
                "time": "Mon, Apr 8, 11:29 AM",
                "value": 75.04000091552734
            },
            {
                "time": "Mon, Apr 8, 11:29 AM",
                "value": 74.58999633789062
            },
            {
                "time": "Mon, Apr 8, 11:30 AM",
                "value": 74.93000030517578
            },
            {
                "time": "Mon, Apr 8, 11:30 AM",
                "value": 76.18000030517578
            },
            {
                "time": "Mon, Apr 8, 11:31 AM",
                "value": 78.44999694824219
            },
            {
                "time": "Mon, Apr 8, 11:31 AM",
                "value": 84.4000015258789
            },
            {
                "time": "Mon, Apr 8, 11:32 AM",
                "value": 95.30000305175781
            },
            {
                "time": "Mon, Apr 8, 11:32 AM",
                "value": 114.19999694824219
            },
            {
                "time": "Mon, Apr 8, 11:33 AM",
                "value": 141.1999969482422
            },
            {
                "time": "Mon, Apr 8, 11:33 AM",
                "value": 181
            },
            {
                "time": "Mon, Apr 8, 11:34 AM",
                "value": 237.1999969482422
            },
            {
                "time": "Mon, Apr 8, 11:34 AM",
                "value": 304.3999938964844
            },
            {
                "time": "Mon, Apr 8, 11:35 AM",
                "value": 378.5
            },
            {
                "time": "Mon, Apr 8, 11:35 AM",
                "value": 448.3999938964844
            },
            {
                "time": "Mon, Apr 8, 11:36 AM",
                "value": 504.8999938964844
            },
            {
                "time": "Mon, Apr 8, 11:36 AM",
                "value": 566.7000122070312
            },
            {
                "time": "Mon, Apr 8, 11:37 AM",
                "value": 608.5999755859375
            },
            {
                "time": "Mon, Apr 8, 11:37 AM",
                "value": 635.5
            },
            {
                "time": "Mon, Apr 8, 11:38 AM",
                "value": 655.2999877929688
            },
            {
                "time": "Mon, Apr 8, 11:38 AM",
                "value": 654.5
            },
            {
                "time": "Mon, Apr 8, 11:39 AM",
                "value": 673.7000122070312
            },
            {
                "time": "Mon, Apr 8, 11:39 AM",
                "value": 676.2999877929688
            },
            {
                "time": "Mon, Apr 8, 11:40 AM",
                "value": 676.9000244140625
            },
            {
                "time": "Mon, Apr 8, 11:40 AM",
                "value": 669.7000122070312
            },
            {
                "time": "Mon, Apr 8, 11:41 AM",
                "value": 647.7000122070312
            },
            {
                "time": "Mon, Apr 8, 11:41 AM",
                "value": 602
            },
            {
                "time": "Mon, Apr 8, 11:42 AM",
                "value": 513.0999755859375
            },
            {
                "time": "Mon, Apr 8, 11:42 AM",
                "value": 417.70001220703125
            },
            {
                "time": "Mon, Apr 8, 11:43 AM",
                "value": 339.29998779296875
            },
            {
                "time": "Mon, Apr 8, 11:43 AM",
                "value": 306.79998779296875
            },
            {
                "time": "Mon, Apr 8, 11:44 AM",
                "value": 350.8999938964844
            },
            {
                "time": "Mon, Apr 8, 11:44 AM",
                "value": 451.5
            },
            {
                "time": "Mon, Apr 8, 11:45 AM",
                "value": 557.2000122070312
            },
            {
                "time": "Mon, Apr 8, 11:45 AM",
                "value": 633.7999877929688
            },
            {
                "time": "Mon, Apr 8, 11:46 AM",
                "value": 669.5999755859375
            },
            {
                "time": "Mon, Apr 8, 11:46 AM",
                "value": 684.7999877929688
            },
            {
                "time": "Mon, Apr 8, 11:47 AM",
                "value": 689.9000244140625
            },
            {
                "time": "Mon, Apr 8, 11:47 AM",
                "value": 690.4000244140625
            },
            {
                "time": "Mon, Apr 8, 11:48 AM",
                "value": 685.5999755859375
            },
            {
                "time": "Mon, Apr 8, 11:48 AM",
                "value": 668
            },
            {
                "time": "Mon, Apr 8, 11:49 AM",
                "value": 626.5999755859375
            },
            {
                "time": "Mon, Apr 8, 11:49 AM",
                "value": 542.5
            },
            {
                "time": "Mon, Apr 8, 11:50 AM",
                "value": 433.3999938964844
            },
            {
                "time": "Mon, Apr 8, 11:50 AM",
                "value": 303.79998779296875
            },
            {
                "time": "Mon, Apr 8, 11:51 AM",
                "value": 195.89999389648438
            },
            {
                "time": "Mon, Apr 8, 11:51 AM",
                "value": 136.6999969482422
            },
            {
                "time": "Mon, Apr 8, 11:52 AM",
                "value": 106.5
            },
            {
                "time": "Mon, Apr 8, 11:52 AM",
                "value": 88.5999984741211
            },
            {
                "time": "Mon, Apr 8, 11:53 AM",
                "value": 81.0999984741211
            },
            {
                "time": "Mon, Apr 8, 11:53 AM",
                "value": 79.47000122070312
            },
            {
                "time": "Mon, Apr 8, 11:54 AM",
                "value": 79.23999786376953
            },
            {
                "time": "Mon, Apr 8, 11:54 AM",
                "value": 79.36000061035156
            },
            {
                "time": "Mon, Apr 8, 11:55 AM",
                "value": 79.80999755859375
            },
            {
                "time": "Mon, Apr 8, 11:55 AM",
                "value": 79.80999755859375
            },
            {
                "time": "Mon, Apr 8, 11:56 AM",
                "value": 80
            },
            {
                "time": "Mon, Apr 8, 11:56 AM",
                "value": 82.5
            },
            {
                "time": "Mon, Apr 8, 11:57 AM",
                "value": 81.69999694824219
            },
            {
                "time": "Mon, Apr 8, 11:57 AM",
                "value": 81.69999694824219
            },
            {
                "time": "Mon, Apr 8, 11:58 AM",
                "value": 82.0999984741211
            },
            {
                "time": "Mon, Apr 8, 11:58 AM",
                "value": 82.5
            },
            {
                "time": "Mon, Apr 8, 11:59 AM",
                "value": 83.30000305175781
            },
            {
                "time": "Mon, Apr 8, 11:59 AM",
                "value": 83.69999694824219
            },
            {
                "time": "Mon, Apr 8, 12:00 PM",
                "value": 84.19999694824219
            },
            {
                "time": "Mon, Apr 8, 12:00 PM",
                "value": 84.69999694824219
            },
            {
                "time": "Mon, Apr 8, 12:01 PM",
                "value": 85.4000015258789
            },
            {
                "time": "Mon, Apr 8, 12:01 PM",
                "value": 86.19999694824219
            },
            {
                "time": "Mon, Apr 8, 12:02 PM",
                "value": 87.0999984741211
            },
            {
                "time": "Mon, Apr 8, 12:02 PM",
                "value": 88.0999984741211
            },
            {
                "time": "Mon, Apr 8, 12:03 PM",
                "value": 89.19999694824219
            },
            {
                "time": "Mon, Apr 8, 12:03 PM",
                "value": 90.5999984741211
            },
            {
                "time": "Mon, Apr 8, 12:04 PM",
                "value": 94.9000015258789
            },
            {
                "time": "Mon, Apr 8, 12:04 PM",
                "value": 105.5999984741211
            },
            {
                "time": "Mon, Apr 8, 12:05 PM",
                "value": 134.60000610351562
            },
            {
                "time": "Mon, Apr 8, 12:05 PM",
                "value": 198.89999389648438
            },
            {
                "time": "Mon, Apr 8, 12:06 PM",
                "value": 303.79998779296875
            },
            {
                "time": "Mon, Apr 8, 12:06 PM",
                "value": 423.20001220703125
            },
            {
                "time": "Mon, Apr 8, 12:07 PM",
                "value": 520.5
            },
            {
                "time": "Mon, Apr 8, 12:07 PM",
                "value": 570.9000244140625
            },
            {
                "time": "Mon, Apr 8, 12:08 PM",
                "value": 579
            },
            {
                "time": "Mon, Apr 8, 12:08 PM",
                "value": 519.5999755859375
            },
            {
                "time": "Mon, Apr 8, 12:09 PM",
                "value": 433.29998779296875
            },
            {
                "time": "Mon, Apr 8, 12:09 PM",
                "value": 403.6000061035156
            },
            {
                "time": "Mon, Apr 8, 12:10 PM",
                "value": 474.8999938964844
            },
            {
                "time": "Mon, Apr 8, 12:10 PM",
                "value": 583.4000244140625
            },
            {
                "time": "Mon, Apr 8, 12:11 PM",
                "value": 670.2999877929688
            },
            {
                "time": "Mon, Apr 8, 12:11 PM",
                "value": 705.7999877929688
            },
            {
                "time": "Mon, Apr 8, 12:12 PM",
                "value": 740.2000122070312
            },
            {
                "time": "Mon, Apr 8, 12:12 PM",
                "value": 736.7999877929688
            },
            {
                "time": "Mon, Apr 8, 12:13 PM",
                "value": 757.7000122070312
            },
            {
                "time": "Mon, Apr 8, 12:13 PM",
                "value": 754.5999755859375
            },
            {
                "time": "Mon, Apr 8, 12:14 PM",
                "value": 728.7999877929688
            },
            {
                "time": "Mon, Apr 8, 12:14 PM",
                "value": 715.4000244140625
            },
            {
                "time": "Mon, Apr 8, 12:15 PM",
                "value": 721.5
            },
            {
                "time": "Mon, Apr 8, 12:15 PM",
                "value": 735.2999877929688
            },
            {
                "time": "Mon, Apr 8, 12:16 PM",
                "value": 735.2000122070312
            },
            {
                "time": "Mon, Apr 8, 12:16 PM",
                "value": 694.2999877929688
            },
            {
                "time": "Mon, Apr 8, 12:17 PM",
                "value": 677.5
            },
            {
                "time": "Mon, Apr 8, 12:17 PM",
                "value": 696.2999877929688
            },
            {
                "time": "Mon, Apr 8, 12:18 PM",
                "value": 729.5
            },
            {
                "time": "Mon, Apr 8, 12:18 PM",
                "value": 746.2000122070312
            },
            {
                "time": "Mon, Apr 8, 12:19 PM",
                "value": 747.2000122070312
            },
            {
                "time": "Mon, Apr 8, 12:19 PM",
                "value": 622.7999877929688
            },
            {
                "time": "Mon, Apr 8, 12:20 PM",
                "value": 586.0999755859375
            },
            {
                "time": "Mon, Apr 8, 12:20 PM",
                "value": 622
            },
            {
                "time": "Mon, Apr 8, 12:21 PM",
                "value": 495
            },
            {
                "time": "Mon, Apr 8, 12:21 PM",
                "value": 351.79998779296875
            },
            {
                "time": "Mon, Apr 8, 12:22 PM",
                "value": 241.10000610351562
            },
            {
                "time": "Mon, Apr 8, 12:22 PM",
                "value": 175.10000610351562
            },
            {
                "time": "Mon, Apr 8, 12:23 PM",
                "value": 149.89999389648438
            },
            {
                "time": "Mon, Apr 8, 12:23 PM",
                "value": 144
            },
            {
                "time": "Mon, Apr 8, 12:24 PM",
                "value": 142.1999969482422
            },
            {
                "time": "Mon, Apr 8, 12:24 PM",
                "value": 137.5
            },
            {
                "time": "Mon, Apr 8, 12:25 PM",
                "value": 132.5
            },
            {
                "time": "Mon, Apr 8, 12:25 PM",
                "value": 131.60000610351562
            },
            {
                "time": "Mon, Apr 8, 12:26 PM",
                "value": 130.89999389648438
            },
            {
                "time": "Mon, Apr 8, 12:26 PM",
                "value": 131.10000610351562
            },
            {
                "time": "Mon, Apr 8, 12:27 PM",
                "value": 131.5
            },
            {
                "time": "Mon, Apr 8, 12:27 PM",
                "value": 132.8000030517578
            },
            {
                "time": "Mon, Apr 8, 12:28 PM",
                "value": 134.60000610351562
            },
            {
                "time": "Mon, Apr 8, 12:28 PM",
                "value": 136.10000610351562
            },
            {
                "time": "Mon, Apr 8, 12:29 PM",
                "value": 138.1999969482422
            },
            {
                "time": "Mon, Apr 8, 12:29 PM",
                "value": 140.1999969482422
            },
            {
                "time": "Mon, Apr 8, 12:30 PM",
                "value": 142.5
            },
            {
                "time": "Mon, Apr 8, 12:30 PM",
                "value": 144.1999969482422
            },
            {
                "time": "Mon, Apr 8, 12:31 PM",
                "value": 146.1999969482422
            },
            {
                "time": "Mon, Apr 8, 12:31 PM",
                "value": 148.60000610351562
            },
            {
                "time": "Mon, Apr 8, 12:32 PM",
                "value": 150.3000030517578
            },
            {
                "time": "Mon, Apr 8, 12:32 PM",
                "value": 152.8000030517578
            },
            {
                "time": "Mon, Apr 8, 12:33 PM",
                "value": 156.10000610351562
            },
            {
                "time": "Mon, Apr 8, 12:33 PM",
                "value": 160.3000030517578
            },
            {
                "time": "Mon, Apr 8, 12:34 PM",
                "value": 166.8000030517578
            },
            {
                "time": "Mon, Apr 8, 12:34 PM",
                "value": 172
            },
            {
                "time": "Mon, Apr 8, 12:35 PM",
                "value": 177.89999389648438
            },
            {
                "time": "Mon, Apr 8, 12:35 PM",
                "value": 183.6999969482422
            },
            {
                "time": "Mon, Apr 8, 12:36 PM",
                "value": 188.39999389648438
            },
            {
                "time": "Mon, Apr 8, 12:36 PM",
                "value": 193.89999389648438
            },
            {
                "time": "Mon, Apr 8, 12:37 PM",
                "value": 201.3000030517578
            },
            {
                "time": "Mon, Apr 8, 12:37 PM",
                "value": 208.8000030517578
            },
            {
                "time": "Mon, Apr 8, 12:38 PM",
                "value": 212.39999389648438
            },
            {
                "time": "Mon, Apr 8, 12:38 PM",
                "value": 210.8000030517578
            },
            {
                "time": "Mon, Apr 8, 12:39 PM",
                "value": 209.10000610351562
            },
            {
                "time": "Mon, Apr 8, 12:39 PM",
                "value": 206.60000610351562
            },
            {
                "time": "Mon, Apr 8, 12:40 PM",
                "value": 214.89999389648438
            },
            {
                "time": "Mon, Apr 8, 12:40 PM",
                "value": 215.60000610351562
            },
            {
                "time": "Mon, Apr 8, 12:41 PM",
                "value": 239.60000610351562
            },
            {
                "time": "Mon, Apr 8, 12:41 PM",
                "value": 295
            },
            {
                "time": "Mon, Apr 8, 12:42 PM",
                "value": 388.70001220703125
            },
            {
                "time": "Mon, Apr 8, 12:42 PM",
                "value": 488.6000061035156
            },
            {
                "time": "Mon, Apr 8, 12:43 PM",
                "value": 604.5
            },
            {
                "time": "Mon, Apr 8, 12:43 PM",
                "value": 700.7999877929688
            },
            {
                "time": "Mon, Apr 8, 12:44 PM",
                "value": 736.0999755859375
            },
            {
                "time": "Mon, Apr 8, 12:44 PM",
                "value": 751.2999877929688
            },
            {
                "time": "Mon, Apr 8, 12:45 PM",
                "value": 737.9000244140625
            },
            {
                "time": "Mon, Apr 8, 12:45 PM",
                "value": 664.2000122070312
            },
            {
                "time": "Mon, Apr 8, 12:46 PM",
                "value": 701.5
            },
            {
                "time": "Mon, Apr 8, 12:46 PM",
                "value": 692
            },
            {
                "time": "Mon, Apr 8, 12:47 PM",
                "value": 681.2000122070312
            },
            {
                "time": "Mon, Apr 8, 12:47 PM",
                "value": 737.5
            },
            {
                "time": "Mon, Apr 8, 12:48 PM",
                "value": 751.0999755859375
            },
            {
                "time": "Mon, Apr 8, 12:48 PM",
                "value": 726.4000244140625
            },
            {
                "time": "Mon, Apr 8, 12:49 PM",
                "value": 756.2999877929688
            },
            {
                "time": "Mon, Apr 8, 12:49 PM",
                "value": 765.2999877929688
            },
            {
                "time": "Mon, Apr 8, 12:50 PM",
                "value": 728.7999877929688
            },
            {
                "time": "Mon, Apr 8, 12:50 PM",
                "value": 783.7000122070312
            },
            {
                "time": "Mon, Apr 8, 12:51 PM",
                "value": 774.2000122070312
            },
            {
                "time": "Mon, Apr 8, 12:51 PM",
                "value": 764.5
            },
            {
                "time": "Mon, Apr 8, 12:52 PM",
                "value": 760.4000244140625
            },
            {
                "time": "Mon, Apr 8, 12:52 PM",
                "value": 792.5
            },
            {
                "time": "Mon, Apr 8, 12:53 PM",
                "value": 812
            },
            {
                "time": "Mon, Apr 8, 12:53 PM",
                "value": 813
            },
            {
                "time": "Mon, Apr 8, 12:54 PM",
                "value": 795.7999877929688
            },
            {
                "time": "Mon, Apr 8, 12:54 PM",
                "value": 766.2000122070312
            },
            {
                "time": "Mon, Apr 8, 12:55 PM",
                "value": 706.4000244140625
            },
            {
                "time": "Mon, Apr 8, 12:55 PM",
                "value": 677.0999755859375
            },
            {
                "time": "Mon, Apr 8, 12:56 PM",
                "value": 685.7000122070312
            },
            {
                "time": "Mon, Apr 8, 12:56 PM",
                "value": 663.2999877929688
            },
            {
                "time": "Mon, Apr 8, 12:57 PM",
                "value": 675.7999877929688
            },
            {
                "time": "Mon, Apr 8, 12:57 PM",
                "value": 675.5999755859375
            },
            {
                "time": "Mon, Apr 8, 12:58 PM",
                "value": 693.7999877929688
            },
            {
                "time": "Mon, Apr 8, 12:58 PM",
                "value": 748.0999755859375
            },
            {
                "time": "Mon, Apr 8, 12:59 PM",
                "value": 770.9000244140625
            },
            {
                "time": "Mon, Apr 8, 12:59 PM",
                "value": 776.4000244140625
            },
            {
                "time": "Mon, Apr 8, 1:00 PM",
                "value": 784.5
            },
            {
                "time": "Mon, Apr 8, 1:00 PM",
                "value": 780.9000244140625
            },
            {
                "time": "Mon, Apr 8, 1:01 PM",
                "value": 788.4000244140625
            },
            {
                "time": "Mon, Apr 8, 1:01 PM",
                "value": 830
            },
            {
                "time": "Mon, Apr 8, 1:02 PM",
                "value": 845
            },
            {
                "time": "Mon, Apr 8, 1:02 PM",
                "value": 854
            },
            {
                "time": "Mon, Apr 8, 1:03 PM",
                "value": 870
            },
            {
                "time": "Mon, Apr 8, 1:03 PM",
                "value": 844
            },
            {
                "time": "Mon, Apr 8, 1:04 PM",
                "value": 832
            },
            {
                "time": "Mon, Apr 8, 1:04 PM",
                "value": 825
            },
            {
                "time": "Mon, Apr 8, 1:05 PM",
                "value": 771.5999755859375
            },
            {
                "time": "Mon, Apr 8, 1:05 PM",
                "value": 688.0999755859375
            },
            {
                "time": "Mon, Apr 8, 1:06 PM",
                "value": 743.5
            },
            {
                "time": "Mon, Apr 8, 1:06 PM",
                "value": 710.2999877929688
            },
            {
                "time": "Mon, Apr 8, 1:07 PM",
                "value": 763.2999877929688
            },
            {
                "time": "Mon, Apr 8, 1:07 PM",
                "value": 801
            },
            {
                "time": "Mon, Apr 8, 1:08 PM",
                "value": 798.7999877929688
            },
            {
                "time": "Mon, Apr 8, 1:08 PM",
                "value": 815
            },
            {
                "time": "Mon, Apr 8, 1:09 PM",
                "value": 804
            },
            {
                "time": "Mon, Apr 8, 1:09 PM",
                "value": 788.2999877929688
            },
            {
                "time": "Mon, Apr 8, 1:10 PM",
                "value": 801
            },
            {
                "time": "Mon, Apr 8, 1:10 PM",
                "value": 815
            },
            {
                "time": "Mon, Apr 8, 1:11 PM",
                "value": 801
            },
            {
                "time": "Mon, Apr 8, 1:11 PM",
                "value": 809
            },
            {
                "time": "Mon, Apr 8, 1:12 PM",
                "value": 791.7000122070312
            },
            {
                "time": "Mon, Apr 8, 1:12 PM",
                "value": 761.5
            },
            {
                "time": "Mon, Apr 8, 1:13 PM",
                "value": 763.0999755859375
            },
            {
                "time": "Mon, Apr 8, 1:13 PM",
                "value": 810
            },
            {
                "time": "Mon, Apr 8, 1:14 PM",
                "value": 814
            },
            {
                "time": "Mon, Apr 8, 1:14 PM",
                "value": 794.7000122070312
            },
            {
                "time": "Mon, Apr 8, 1:15 PM",
                "value": 769.2999877929688
            },
            {
                "time": "Mon, Apr 8, 1:15 PM",
                "value": 768.4000244140625
            },
            {
                "time": "Mon, Apr 8, 1:16 PM",
                "value": 777.7999877929688
            },
            {
                "time": "Mon, Apr 8, 1:16 PM",
                "value": 801
            },
            {
                "time": "Mon, Apr 8, 1:17 PM",
                "value": 815
            },
            {
                "time": "Mon, Apr 8, 1:17 PM",
                "value": 818
            },
            {
                "time": "Mon, Apr 8, 1:18 PM",
                "value": 828
            },
            {
                "time": "Mon, Apr 8, 1:18 PM",
                "value": 842
            },
            {
                "time": "Mon, Apr 8, 1:19 PM",
                "value": 820
            },
            {
                "time": "Mon, Apr 8, 1:19 PM",
                "value": 791.5999755859375
            },
            {
                "time": "Mon, Apr 8, 1:20 PM",
                "value": 780
            },
            {
                "time": "Mon, Apr 8, 1:20 PM",
                "value": 808
            },
            {
                "time": "Mon, Apr 8, 1:21 PM",
                "value": 770.7999877929688
            },
            {
                "time": "Mon, Apr 8, 1:21 PM",
                "value": 799.5
            },
            {
                "time": "Mon, Apr 8, 1:22 PM",
                "value": 795.4000244140625
            },
            {
                "time": "Mon, Apr 8, 1:22 PM",
                "value": 755.0999755859375
            },
            {
                "time": "Mon, Apr 8, 1:23 PM",
                "value": 794.2999877929688
            },
            {
                "time": "Mon, Apr 8, 1:23 PM",
                "value": 753.9000244140625
            },
            {
                "time": "Mon, Apr 8, 1:24 PM",
                "value": 707.5
            },
            {
                "time": "Mon, Apr 8, 1:24 PM",
                "value": 726
            },
            {
                "time": "Mon, Apr 8, 1:25 PM",
                "value": 711.5999755859375
            },
            {
                "time": "Mon, Apr 8, 1:25 PM",
                "value": 824
            },
            {
                "time": "Mon, Apr 8, 1:26 PM",
                "value": 843
            },
            {
                "time": "Mon, Apr 8, 1:26 PM",
                "value": 841
            },
            {
                "time": "Mon, Apr 8, 1:27 PM",
                "value": 836
            },
            {
                "time": "Mon, Apr 8, 1:27 PM",
                "value": 803
            },
            {
                "time": "Mon, Apr 8, 1:28 PM",
                "value": 791.0999755859375
            },
            {
                "time": "Mon, Apr 8, 1:28 PM",
                "value": 748.2000122070312
            },
            {
                "time": "Mon, Apr 8, 1:29 PM",
                "value": 710
            },
            {
                "time": "Mon, Apr 8, 1:29 PM",
                "value": 766.4000244140625
            },
            {
                "time": "Mon, Apr 8, 1:30 PM",
                "value": 785.7000122070312
            },
            {
                "time": "Mon, Apr 8, 1:30 PM",
                "value": 798.9000244140625
            },
            {
                "time": "Mon, Apr 8, 1:31 PM",
                "value": 825
            },
            {
                "time": "Mon, Apr 8, 1:31 PM",
                "value": 831
            },
            {
                "time": "Mon, Apr 8, 1:32 PM",
                "value": 835
            },
            {
                "time": "Mon, Apr 8, 1:32 PM",
                "value": 830
            },
            {
                "time": "Mon, Apr 8, 1:33 PM",
                "value": 848
            },
            {
                "time": "Mon, Apr 8, 1:33 PM",
                "value": 855
            },
            {
                "time": "Mon, Apr 8, 1:34 PM",
                "value": 853
            },
            {
                "time": "Mon, Apr 8, 1:34 PM",
                "value": 860
            },
            {
                "time": "Mon, Apr 8, 1:35 PM",
                "value": 862
            },
            {
                "time": "Mon, Apr 8, 1:35 PM",
                "value": 862
            },
            {
                "time": "Mon, Apr 8, 1:36 PM",
                "value": 864
            },
            {
                "time": "Mon, Apr 8, 1:36 PM",
                "value": 863
            },
            {
                "time": "Mon, Apr 8, 1:37 PM",
                "value": 861
            },
            {
                "time": "Mon, Apr 8, 1:37 PM",
                "value": 859
            },
            {
                "time": "Mon, Apr 8, 1:38 PM",
                "value": 858
            },
            {
                "time": "Mon, Apr 8, 1:38 PM",
                "value": 860
            },
            {
                "time": "Mon, Apr 8, 1:39 PM",
                "value": 860
            },
            {
                "time": "Mon, Apr 8, 1:39 PM",
                "value": 857
            },
            {
                "time": "Mon, Apr 8, 1:40 PM",
                "value": 855
            },
            {
                "time": "Mon, Apr 8, 1:40 PM",
                "value": 855
            },
            {
                "time": "Mon, Apr 8, 1:41 PM",
                "value": 858
            },
            {
                "time": "Mon, Apr 8, 1:41 PM",
                "value": 859
            },
            {
                "time": "Mon, Apr 8, 1:42 PM",
                "value": 860
            },
            {
                "time": "Mon, Apr 8, 1:42 PM",
                "value": 860
            },
            {
                "time": "Mon, Apr 8, 1:43 PM",
                "value": 860
            },
            {
                "time": "Mon, Apr 8, 1:43 PM",
                "value": 861
            },
            {
                "time": "Mon, Apr 8, 1:44 PM",
                "value": 860
            },
            {
                "time": "Mon, Apr 8, 1:44 PM",
                "value": 861
            },
            {
                "time": "Mon, Apr 8, 1:45 PM",
                "value": 860
            },
            {
                "time": "Mon, Apr 8, 1:45 PM",
                "value": 859
            },
            {
                "time": "Mon, Apr 8, 1:46 PM",
                "value": 860
            },
            {
                "time": "Mon, Apr 8, 1:46 PM",
                "value": 861
            },
            {
                "time": "Mon, Apr 8, 1:47 PM",
                "value": 860
            },
            {
                "time": "Mon, Apr 8, 1:47 PM",
                "value": 861
            },
            {
                "time": "Mon, Apr 8, 1:48 PM",
                "value": 862
            },
            {
                "time": "Mon, Apr 8, 1:48 PM",
                "value": 863
            },
            {
                "time": "Mon, Apr 8, 1:49 PM",
                "value": 864
            },
            {
                "time": "Mon, Apr 8, 1:49 PM",
                "value": 865
            },
            {
                "time": "Mon, Apr 8, 1:50 PM",
                "value": 862
            },
            {
                "time": "Mon, Apr 8, 1:50 PM",
                "value": 861
            },
            {
                "time": "Mon, Apr 8, 1:51 PM",
                "value": 859
            },
            {
                "time": "Mon, Apr 8, 1:51 PM",
                "value": 858
            },
            {
                "time": "Mon, Apr 8, 1:52 PM",
                "value": 856
            },
            {
                "time": "Mon, Apr 8, 1:52 PM",
                "value": 854
            },
            {
                "time": "Mon, Apr 8, 1:53 PM",
                "value": 851
            },
            {
                "time": "Mon, Apr 8, 1:53 PM",
                "value": 848
            },
            {
                "time": "Mon, Apr 8, 1:54 PM",
                "value": 846
            },
            {
                "time": "Mon, Apr 8, 1:54 PM",
                "value": 845
            },
            {
                "time": "Mon, Apr 8, 1:55 PM",
                "value": 840
            },
            {
                "time": "Mon, Apr 8, 1:55 PM",
                "value": 839
            },
            {
                "time": "Mon, Apr 8, 1:56 PM",
                "value": 838
            },
            {
                "time": "Mon, Apr 8, 1:56 PM",
                "value": 837
            },
            {
                "time": "Mon, Apr 8, 1:57 PM",
                "value": 836
            },
            {
                "time": "Mon, Apr 8, 1:57 PM",
                "value": 834
            },
            {
                "time": "Mon, Apr 8, 1:58 PM",
                "value": 832
            },
            {
                "time": "Mon, Apr 8, 1:58 PM",
                "value": 828
            },
            {
                "time": "Mon, Apr 8, 1:59 PM",
                "value": 827
            },
            {
                "time": "Mon, Apr 8, 1:59 PM",
                "value": 823
            },
            {
                "time": "Mon, Apr 8, 2:00 PM",
                "value": 820
            },
            {
                "time": "Mon, Apr 8, 2:00 PM",
                "value": 816
            },
            {
                "time": "Mon, Apr 8, 2:01 PM",
                "value": 812
            },
            {
                "time": "Mon, Apr 8, 2:01 PM",
                "value": 805
            },
            {
                "time": "Mon, Apr 8, 2:02 PM",
                "value": 800
            },
            {
                "time": "Mon, Apr 8, 2:02 PM",
                "value": 798.9000244140625
            },
            {
                "time": "Mon, Apr 8, 2:03 PM",
                "value": 795.7999877929688
            },
            {
                "time": "Mon, Apr 8, 2:03 PM",
                "value": 793.0999755859375
            },
            {
                "time": "Mon, Apr 8, 2:04 PM",
                "value": 788.9000244140625
            },
            {
                "time": "Mon, Apr 8, 2:04 PM",
                "value": 786.7999877929688
            },
            {
                "time": "Mon, Apr 8, 2:05 PM",
                "value": 783.9000244140625
            },
            {
                "time": "Mon, Apr 8, 2:05 PM",
                "value": 779.4000244140625
            },
            {
                "time": "Mon, Apr 8, 2:06 PM",
                "value": 773.7999877929688
            },
            {
                "time": "Mon, Apr 8, 2:06 PM",
                "value": 767.7999877929688
            },
            {
                "time": "Mon, Apr 8, 2:07 PM",
                "value": 757.4000244140625
            },
            {
                "time": "Mon, Apr 8, 2:07 PM",
                "value": 736.2000122070312
            },
            {
                "time": "Mon, Apr 8, 2:08 PM",
                "value": 729.5999755859375
            },
            {
                "time": "Mon, Apr 8, 2:08 PM",
                "value": 746.7999877929688
            },
            {
                "time": "Mon, Apr 8, 2:09 PM",
                "value": 743.4000244140625
            },
            {
                "time": "Mon, Apr 8, 2:09 PM",
                "value": 737
            },
            {
                "time": "Mon, Apr 8, 2:10 PM",
                "value": 730.5
            },
            {
                "time": "Mon, Apr 8, 2:10 PM",
                "value": 724.0999755859375
            },
            {
                "time": "Mon, Apr 8, 2:11 PM",
                "value": 718.2000122070312
            },
            {
                "time": "Mon, Apr 8, 2:11 PM",
                "value": 713.4000244140625
            },
            {
                "time": "Mon, Apr 8, 2:12 PM",
                "value": 707.7999877929688
            },
            {
                "time": "Mon, Apr 8, 2:12 PM",
                "value": 702.4000244140625
            },
            {
                "time": "Mon, Apr 8, 2:13 PM",
                "value": 695.5
            },
            {
                "time": "Mon, Apr 8, 2:13 PM",
                "value": 690
            },
            {
                "time": "Mon, Apr 8, 2:14 PM",
                "value": 684.4000244140625
            },
            {
                "time": "Mon, Apr 8, 2:14 PM",
                "value": 678.9000244140625
            },
            {
                "time": "Mon, Apr 8, 2:15 PM",
                "value": 674.2999877929688
            },
            {
                "time": "Mon, Apr 8, 2:15 PM",
                "value": 667.2000122070312
            },
            {
                "time": "Mon, Apr 8, 2:16 PM",
                "value": 652.0999755859375
            },
            {
                "time": "Mon, Apr 8, 2:16 PM",
                "value": 653.0999755859375
            },
            {
                "time": "Mon, Apr 8, 2:17 PM",
                "value": 649.7999877929688
            },
            {
                "time": "Mon, Apr 8, 2:17 PM",
                "value": 644.5999755859375
            },
            {
                "time": "Mon, Apr 8, 2:18 PM",
                "value": 638.0999755859375
            },
            {
                "time": "Mon, Apr 8, 2:18 PM",
                "value": 630.2000122070312
            },
            {
                "time": "Mon, Apr 8, 2:19 PM",
                "value": 623.4000244140625
            },
            {
                "time": "Mon, Apr 8, 2:19 PM",
                "value": 618
            },
            {
                "time": "Mon, Apr 8, 2:20 PM",
                "value": 612.2000122070312
            },
            {
                "time": "Mon, Apr 8, 2:20 PM",
                "value": 605.7999877929688
            },
            {
                "time": "Mon, Apr 8, 2:21 PM",
                "value": 598.9000244140625
            },
            {
                "time": "Mon, Apr 8, 2:21 PM",
                "value": 591.9000244140625
            },
            {
                "time": "Mon, Apr 8, 2:22 PM",
                "value": 583.9000244140625
            },
            {
                "time": "Mon, Apr 8, 2:22 PM",
                "value": 578
            },
            {
                "time": "Mon, Apr 8, 2:23 PM",
                "value": 571.0999755859375
            },
            {
                "time": "Mon, Apr 8, 2:23 PM",
                "value": 564.4000244140625
            },
            {
                "time": "Mon, Apr 8, 2:24 PM",
                "value": 556.9000244140625
            },
            {
                "time": "Mon, Apr 8, 2:24 PM",
                "value": 549
            },
            {
                "time": "Mon, Apr 8, 2:25 PM",
                "value": 537.7000122070312
            },
            {
                "time": "Mon, Apr 8, 2:25 PM",
                "value": 530.7999877929688
            },
            {
                "time": "Mon, Apr 8, 2:26 PM",
                "value": 523.4000244140625
            },
            {
                "time": "Mon, Apr 8, 2:26 PM",
                "value": 517
            },
            {
                "time": "Mon, Apr 8, 2:27 PM",
                "value": 512.7999877929688
            },
            {
                "time": "Mon, Apr 8, 2:27 PM",
                "value": 507.3999938964844
            },
            {
                "time": "Mon, Apr 8, 2:28 PM",
                "value": 500.29998779296875
            },
            {
                "time": "Mon, Apr 8, 2:28 PM",
                "value": 492.20001220703125
            },
            {
                "time": "Mon, Apr 8, 2:29 PM",
                "value": 482.8999938964844
            },
            {
                "time": "Mon, Apr 8, 2:29 PM",
                "value": 475
            },
            {
                "time": "Mon, Apr 8, 2:30 PM",
                "value": 472.70001220703125
            },
            {
                "time": "Mon, Apr 8, 2:30 PM",
                "value": 462
            },
            {
                "time": "Mon, Apr 8, 2:31 PM",
                "value": 460.20001220703125
            },
            {
                "time": "Mon, Apr 8, 2:31 PM",
                "value": 454.29998779296875
            },
            {
                "time": "Mon, Apr 8, 2:32 PM",
                "value": 448.70001220703125
            },
            {
                "time": "Mon, Apr 8, 2:32 PM",
                "value": 441.6000061035156
            },
            {
                "time": "Mon, Apr 8, 2:33 PM",
                "value": 434.8999938964844
            },
            {
                "time": "Mon, Apr 8, 2:33 PM",
                "value": 426.29998779296875
            },
            {
                "time": "Mon, Apr 8, 2:34 PM",
                "value": 417.6000061035156
            },
            {
                "time": "Mon, Apr 8, 2:34 PM",
                "value": 402.8999938964844
            },
            {
                "time": "Mon, Apr 8, 2:35 PM",
                "value": 401.29998779296875
            },
            {
                "time": "Mon, Apr 8, 2:35 PM",
                "value": 396.5
            },
            {
                "time": "Mon, Apr 8, 2:36 PM",
                "value": 391.6000061035156
            },
            {
                "time": "Mon, Apr 8, 2:36 PM",
                "value": 385
            },
            {
                "time": "Mon, Apr 8, 2:37 PM",
                "value": 377.29998779296875
            },
            {
                "time": "Mon, Apr 8, 2:37 PM",
                "value": 369.79998779296875
            },
            {
                "time": "Mon, Apr 8, 2:38 PM",
                "value": 361.8999938964844
            },
            {
                "time": "Mon, Apr 8, 2:38 PM",
                "value": 354.3999938964844
            },
            {
                "time": "Mon, Apr 8, 2:39 PM",
                "value": 347.3999938964844
            },
            {
                "time": "Mon, Apr 8, 2:39 PM",
                "value": 340
            },
            {
                "time": "Mon, Apr 8, 2:40 PM",
                "value": 332.29998779296875
            },
            {
                "time": "Mon, Apr 8, 2:40 PM",
                "value": 324.3999938964844
            },
            {
                "time": "Mon, Apr 8, 2:41 PM",
                "value": 316.8999938964844
            },
            {
                "time": "Mon, Apr 8, 2:41 PM",
                "value": 310.20001220703125
            },
            {
                "time": "Mon, Apr 8, 2:42 PM",
                "value": 302.1000061035156
            },
            {
                "time": "Mon, Apr 8, 2:42 PM",
                "value": 294.20001220703125
            },
            {
                "time": "Mon, Apr 8, 2:43 PM",
                "value": 282.8999938964844
            },
            {
                "time": "Mon, Apr 8, 2:43 PM",
                "value": 258.8999938964844
            },
            {
                "time": "Mon, Apr 8, 2:44 PM",
                "value": 249.60000610351562
            },
            {
                "time": "Mon, Apr 8, 2:44 PM",
                "value": 242
            },
            {
                "time": "Mon, Apr 8, 2:45 PM",
                "value": 236.8000030517578
            },
            {
                "time": "Mon, Apr 8, 2:45 PM",
                "value": 237.6999969482422
            },
            {
                "time": "Mon, Apr 8, 2:46 PM",
                "value": 235
            },
            {
                "time": "Mon, Apr 8, 2:46 PM",
                "value": 230.60000610351562
            },
            {
                "time": "Mon, Apr 8, 2:47 PM",
                "value": 226.5
            },
            {
                "time": "Mon, Apr 8, 2:47 PM",
                "value": 218.60000610351562
            },
            {
                "time": "Mon, Apr 8, 2:48 PM",
                "value": 211.60000610351562
            },
            {
                "time": "Mon, Apr 8, 2:48 PM",
                "value": 204.89999389648438
            },
            {
                "time": "Mon, Apr 8, 2:49 PM",
                "value": 196
            },
            {
                "time": "Mon, Apr 8, 2:49 PM",
                "value": 179.10000610351562
            },
            {
                "time": "Mon, Apr 8, 2:50 PM",
                "value": 176.39999389648438
            },
            {
                "time": "Mon, Apr 8, 2:50 PM",
                "value": 173.3000030517578
            },
            {
                "time": "Mon, Apr 8, 2:51 PM",
                "value": 164.5
            },
            {
                "time": "Mon, Apr 8, 2:51 PM",
                "value": 162.60000610351562
            },
            {
                "time": "Mon, Apr 8, 2:52 PM",
                "value": 154.5
            },
            {
                "time": "Mon, Apr 8, 2:52 PM",
                "value": 147.8000030517578
            },
            {
                "time": "Mon, Apr 8, 2:53 PM",
                "value": 141.6999969482422
            },
            {
                "time": "Mon, Apr 8, 2:53 PM",
                "value": 134.60000610351562
            },
            {
                "time": "Mon, Apr 8, 2:54 PM",
                "value": 127.5
            },
            {
                "time": "Mon, Apr 8, 2:54 PM",
                "value": 120.80000305175781
            },
            {
                "time": "Mon, Apr 8, 2:55 PM",
                "value": 113.69999694824219
            },
            {
                "time": "Mon, Apr 8, 2:55 PM",
                "value": 106.30000305175781
            },
            {
                "time": "Mon, Apr 8, 2:56 PM",
                "value": 100
            },
            {
                "time": "Mon, Apr 8, 2:56 PM",
                "value": 93.5
            },
            {
                "time": "Mon, Apr 8, 2:57 PM",
                "value": 86.69999694824219
            },
            {
                "time": "Mon, Apr 8, 2:57 PM",
                "value": 79.80000305175781
            },
            {
                "time": "Mon, Apr 8, 2:58 PM",
                "value": 72.19999694824219
            },
            {
                "time": "Mon, Apr 8, 2:58 PM",
                "value": 65.83999633789062
            },
            {
                "time": "Mon, Apr 8, 2:59 PM",
                "value": 54.599998474121094
            },
            {
                "time": "Mon, Apr 8, 2:59 PM",
                "value": 51.880001068115234
            },
            {
                "time": "Mon, Apr 8, 3:00 PM",
                "value": 45.75
            },
            {
                "time": "Mon, Apr 8, 3:00 PM",
                "value": 38.709999084472656
            },
            {
                "time": "Mon, Apr 8, 3:01 PM",
                "value": 31.670000076293945
            },
            {
                "time": "Mon, Apr 8, 3:01 PM",
                "value": 21.229999542236328
            },
            {
                "time": "Mon, Apr 8, 3:02 PM",
                "value": 21.56999969482422
            },
            {
                "time": "Mon, Apr 8, 3:02 PM",
                "value": 18.959999084472656
            },
            {
                "time": "Mon, Apr 8, 3:03 PM",
                "value": 14.079999923706055
            },
            {
                "time": "Mon, Apr 8, 3:03 PM",
                "value": 9.420000076293945
            },
            {
                "time": "Mon, Apr 8, 3:04 PM",
                "value": 5.2220001220703125
            },
            {
                "time": "Mon, Apr 8, 3:04 PM",
                "value": 2.1570000648498535
            },
            {
                "time": "Mon, Apr 8, 3:05 PM",
                "value": 0.3409999907016754
            },
            {
                "time": "Mon, Apr 8, 3:05 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 3:06 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 3:06 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 3:07 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 3:07 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 3:08 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 3:08 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 3:09 PM",
                "value": 0.11400000005960464
            },
            {
                "time": "Mon, Apr 8, 3:09 PM",
                "value": 1.1349999904632568
            },
            {
                "time": "Mon, Apr 8, 3:10 PM",
                "value": 3.746000051498413
            },
            {
                "time": "Mon, Apr 8, 3:10 PM",
                "value": 7.493000030517578
            },
            {
                "time": "Mon, Apr 8, 3:11 PM",
                "value": 11.579999923706055
            },
            {
                "time": "Mon, Apr 8, 3:11 PM",
                "value": 16.350000381469727
            },
            {
                "time": "Mon, Apr 8, 3:12 PM",
                "value": 20.889999389648438
            },
            {
                "time": "Mon, Apr 8, 3:12 PM",
                "value": 26.219999313354492
            },
            {
                "time": "Mon, Apr 8, 3:13 PM",
                "value": 31.670000076293945
            },
            {
                "time": "Mon, Apr 8, 3:13 PM",
                "value": 37.459999084472656
            },
            {
                "time": "Mon, Apr 8, 3:14 PM",
                "value": 43.25
            },
            {
                "time": "Mon, Apr 8, 3:14 PM",
                "value": 49.040000915527344
            },
            {
                "time": "Mon, Apr 8, 3:15 PM",
                "value": 54.720001220703125
            },
            {
                "time": "Mon, Apr 8, 3:15 PM",
                "value": 60.959999084472656
            },
            {
                "time": "Mon, Apr 8, 3:16 PM",
                "value": 67.55000305175781
            },
            {
                "time": "Mon, Apr 8, 3:16 PM",
                "value": 73.91000366210938
            },
            {
                "time": "Mon, Apr 8, 3:17 PM",
                "value": 80
            },
            {
                "time": "Mon, Apr 8, 3:17 PM",
                "value": 85.9000015258789
            },
            {
                "time": "Mon, Apr 8, 3:18 PM",
                "value": 92.19999694824219
            },
            {
                "time": "Mon, Apr 8, 3:18 PM",
                "value": 95.0999984741211
            },
            {
                "time": "Mon, Apr 8, 3:19 PM",
                "value": 96.5
            },
            {
                "time": "Mon, Apr 8, 3:19 PM",
                "value": 99.80000305175781
            },
            {
                "time": "Mon, Apr 8, 3:20 PM",
                "value": 110.69999694824219
            },
            {
                "time": "Mon, Apr 8, 3:20 PM",
                "value": 121.19999694824219
            },
            {
                "time": "Mon, Apr 8, 3:21 PM",
                "value": 127.5
            },
            {
                "time": "Mon, Apr 8, 3:21 PM",
                "value": 128.10000610351562
            },
            {
                "time": "Mon, Apr 8, 3:22 PM",
                "value": 130.39999389648438
            },
            {
                "time": "Mon, Apr 8, 3:22 PM",
                "value": 142.89999389648438
            },
            {
                "time": "Mon, Apr 8, 3:23 PM",
                "value": 149.89999389648438
            },
            {
                "time": "Mon, Apr 8, 3:23 PM",
                "value": 158.8000030517578
            },
            {
                "time": "Mon, Apr 8, 3:24 PM",
                "value": 170.3000030517578
            },
            {
                "time": "Mon, Apr 8, 3:24 PM",
                "value": 176.3000030517578
            },
            {
                "time": "Mon, Apr 8, 3:25 PM",
                "value": 176.39999389648438
            },
            {
                "time": "Mon, Apr 8, 3:25 PM",
                "value": 184.8000030517578
            },
            {
                "time": "Mon, Apr 8, 3:26 PM",
                "value": 195.6999969482422
            },
            {
                "time": "Mon, Apr 8, 3:26 PM",
                "value": 205.5
            },
            {
                "time": "Mon, Apr 8, 3:27 PM",
                "value": 213.8000030517578
            },
            {
                "time": "Mon, Apr 8, 3:27 PM",
                "value": 219.6999969482422
            },
            {
                "time": "Mon, Apr 8, 3:28 PM",
                "value": 224.39999389648438
            },
            {
                "time": "Mon, Apr 8, 3:28 PM",
                "value": 230.3000030517578
            },
            {
                "time": "Mon, Apr 8, 3:29 PM",
                "value": 234.6999969482422
            },
            {
                "time": "Mon, Apr 8, 3:29 PM",
                "value": 240.10000610351562
            },
            {
                "time": "Mon, Apr 8, 3:30 PM",
                "value": 251.1999969482422
            },
            {
                "time": "Mon, Apr 8, 3:30 PM",
                "value": 260
            },
            {
                "time": "Mon, Apr 8, 3:31 PM",
                "value": 262.6000061035156
            },
            {
                "time": "Mon, Apr 8, 3:31 PM",
                "value": 267.79998779296875
            },
            {
                "time": "Mon, Apr 8, 3:32 PM",
                "value": 275.79998779296875
            },
            {
                "time": "Mon, Apr 8, 3:32 PM",
                "value": 285
            },
            {
                "time": "Mon, Apr 8, 3:33 PM",
                "value": 294
            },
            {
                "time": "Mon, Apr 8, 3:33 PM",
                "value": 301.20001220703125
            },
            {
                "time": "Mon, Apr 8, 3:34 PM",
                "value": 307.79998779296875
            },
            {
                "time": "Mon, Apr 8, 3:34 PM",
                "value": 314.1000061035156
            },
            {
                "time": "Mon, Apr 8, 3:35 PM",
                "value": 321.20001220703125
            },
            {
                "time": "Mon, Apr 8, 3:35 PM",
                "value": 325.6000061035156
            },
            {
                "time": "Mon, Apr 8, 3:36 PM",
                "value": 325.79998779296875
            },
            {
                "time": "Mon, Apr 8, 3:36 PM",
                "value": 321.6000061035156
            },
            {
                "time": "Mon, Apr 8, 3:37 PM",
                "value": 334.20001220703125
            },
            {
                "time": "Mon, Apr 8, 3:37 PM",
                "value": 331.3999938964844
            },
            {
                "time": "Mon, Apr 8, 3:38 PM",
                "value": 344.8999938964844
            },
            {
                "time": "Mon, Apr 8, 3:38 PM",
                "value": 360.79998779296875
            },
            {
                "time": "Mon, Apr 8, 3:39 PM",
                "value": 361.5
            },
            {
                "time": "Mon, Apr 8, 3:39 PM",
                "value": 363.29998779296875
            },
            {
                "time": "Mon, Apr 8, 3:40 PM",
                "value": 371.5
            },
            {
                "time": "Mon, Apr 8, 3:40 PM",
                "value": 383.3999938964844
            },
            {
                "time": "Mon, Apr 8, 3:41 PM",
                "value": 397.29998779296875
            },
            {
                "time": "Mon, Apr 8, 3:41 PM",
                "value": 401.70001220703125
            },
            {
                "time": "Mon, Apr 8, 3:42 PM",
                "value": 405.3999938964844
            },
            {
                "time": "Mon, Apr 8, 3:42 PM",
                "value": 414.70001220703125
            },
            {
                "time": "Mon, Apr 8, 3:43 PM",
                "value": 427.29998779296875
            },
            {
                "time": "Mon, Apr 8, 3:43 PM",
                "value": 437.20001220703125
            },
            {
                "time": "Mon, Apr 8, 3:44 PM",
                "value": 443.5
            },
            {
                "time": "Mon, Apr 8, 3:44 PM",
                "value": 449.29998779296875
            },
            {
                "time": "Mon, Apr 8, 3:45 PM",
                "value": 454.29998779296875
            },
            {
                "time": "Mon, Apr 8, 3:45 PM",
                "value": 460.79998779296875
            },
            {
                "time": "Mon, Apr 8, 3:46 PM",
                "value": 464.70001220703125
            },
            {
                "time": "Mon, Apr 8, 3:46 PM",
                "value": 469.8999938964844
            },
            {
                "time": "Mon, Apr 8, 3:47 PM",
                "value": 470.70001220703125
            },
            {
                "time": "Mon, Apr 8, 3:47 PM",
                "value": 475.5
            },
            {
                "time": "Mon, Apr 8, 3:48 PM",
                "value": 481.6000061035156
            },
            {
                "time": "Mon, Apr 8, 3:48 PM",
                "value": 483.70001220703125
            },
            {
                "time": "Mon, Apr 8, 3:49 PM",
                "value": 484.29998779296875
            },
            {
                "time": "Mon, Apr 8, 3:49 PM",
                "value": 497.70001220703125
            },
            {
                "time": "Mon, Apr 8, 3:50 PM",
                "value": 506.20001220703125
            },
            {
                "time": "Mon, Apr 8, 3:50 PM",
                "value": 511.79998779296875
            },
            {
                "time": "Mon, Apr 8, 3:51 PM",
                "value": 516.4000244140625
            },
            {
                "time": "Mon, Apr 8, 3:51 PM",
                "value": 526.7999877929688
            },
            {
                "time": "Mon, Apr 8, 3:52 PM",
                "value": 534.9000244140625
            },
            {
                "time": "Mon, Apr 8, 3:52 PM",
                "value": 517.5999755859375
            },
            {
                "time": "Mon, Apr 8, 3:53 PM",
                "value": 534.4000244140625
            },
            {
                "time": "Mon, Apr 8, 3:53 PM",
                "value": 537.4000244140625
            },
            {
                "time": "Mon, Apr 8, 3:54 PM",
                "value": 552.2999877929688
            },
            {
                "time": "Mon, Apr 8, 3:54 PM",
                "value": 549.2000122070312
            },
            {
                "time": "Mon, Apr 8, 3:55 PM",
                "value": 556.5999755859375
            },
            {
                "time": "Mon, Apr 8, 3:55 PM",
                "value": 555.5999755859375
            },
            {
                "time": "Mon, Apr 8, 3:56 PM",
                "value": 559.9000244140625
            },
            {
                "time": "Mon, Apr 8, 3:56 PM",
                "value": 563.7000122070312
            },
            {
                "time": "Mon, Apr 8, 3:57 PM",
                "value": 578.2000122070312
            },
            {
                "time": "Mon, Apr 8, 3:57 PM",
                "value": 576.7999877929688
            },
            {
                "time": "Mon, Apr 8, 3:58 PM",
                "value": 580.9000244140625
            },
            {
                "time": "Mon, Apr 8, 3:58 PM",
                "value": 591.2000122070312
            },
            {
                "time": "Mon, Apr 8, 3:59 PM",
                "value": 602.5
            },
            {
                "time": "Mon, Apr 8, 3:59 PM",
                "value": 597.7999877929688
            },
            {
                "time": "Mon, Apr 8, 4:00 PM",
                "value": 609.9000244140625
            },
            {
                "time": "Mon, Apr 8, 4:00 PM",
                "value": 623.4000244140625
            },
            {
                "time": "Mon, Apr 8, 4:01 PM",
                "value": 611.2999877929688
            },
            {
                "time": "Mon, Apr 8, 4:01 PM",
                "value": 596.7000122070312
            },
            {
                "time": "Mon, Apr 8, 4:02 PM",
                "value": 563.2000122070312
            },
            {
                "time": "Mon, Apr 8, 4:02 PM",
                "value": 551.5999755859375
            },
            {
                "time": "Mon, Apr 8, 4:03 PM",
                "value": 550.4000244140625
            },
            {
                "time": "Mon, Apr 8, 4:03 PM",
                "value": 587.4000244140625
            },
            {
                "time": "Mon, Apr 8, 4:04 PM",
                "value": 616
            },
            {
                "time": "Mon, Apr 8, 4:04 PM",
                "value": 628.5999755859375
            },
            {
                "time": "Mon, Apr 8, 4:05 PM",
                "value": 636
            },
            {
                "time": "Mon, Apr 8, 4:05 PM",
                "value": 648.4000244140625
            },
            {
                "time": "Mon, Apr 8, 4:06 PM",
                "value": 648.9000244140625
            },
            {
                "time": "Mon, Apr 8, 4:06 PM",
                "value": 654.0999755859375
            },
            {
                "time": "Mon, Apr 8, 4:07 PM",
                "value": 659.5
            },
            {
                "time": "Mon, Apr 8, 4:07 PM",
                "value": 661.2000122070312
            },
            {
                "time": "Mon, Apr 8, 4:08 PM",
                "value": 667.5
            },
            {
                "time": "Mon, Apr 8, 4:08 PM",
                "value": 664.2000122070312
            },
            {
                "time": "Mon, Apr 8, 4:09 PM",
                "value": 650.0999755859375
            },
            {
                "time": "Mon, Apr 8, 4:09 PM",
                "value": 663.9000244140625
            },
            {
                "time": "Mon, Apr 8, 4:10 PM",
                "value": 661.5
            },
            {
                "time": "Mon, Apr 8, 4:10 PM",
                "value": 670.7999877929688
            },
            {
                "time": "Mon, Apr 8, 4:11 PM",
                "value": 678.9000244140625
            },
            {
                "time": "Mon, Apr 8, 4:11 PM",
                "value": 670.5999755859375
            },
            {
                "time": "Mon, Apr 8, 4:12 PM",
                "value": 651.2000122070312
            },
            {
                "time": "Mon, Apr 8, 4:12 PM",
                "value": 658.5999755859375
            },
            {
                "time": "Mon, Apr 8, 4:13 PM",
                "value": 644.2999877929688
            },
            {
                "time": "Mon, Apr 8, 4:13 PM",
                "value": 573.2999877929688
            },
            {
                "time": "Mon, Apr 8, 4:14 PM",
                "value": 618.9000244140625
            },
            {
                "time": "Mon, Apr 8, 4:14 PM",
                "value": 647.2000122070312
            },
            {
                "time": "Mon, Apr 8, 4:15 PM",
                "value": 653.7999877929688
            },
            {
                "time": "Mon, Apr 8, 4:15 PM",
                "value": 665.5999755859375
            },
            {
                "time": "Mon, Apr 8, 4:16 PM",
                "value": 668.0999755859375
            },
            {
                "time": "Mon, Apr 8, 4:16 PM",
                "value": 652.2000122070312
            },
            {
                "time": "Mon, Apr 8, 4:17 PM",
                "value": 654.4000244140625
            },
            {
                "time": "Mon, Apr 8, 4:17 PM",
                "value": 658.2999877929688
            },
            {
                "time": "Mon, Apr 8, 4:18 PM",
                "value": 656.2000122070312
            },
            {
                "time": "Mon, Apr 8, 4:18 PM",
                "value": 680.7999877929688
            },
            {
                "time": "Mon, Apr 8, 4:19 PM",
                "value": 689.5999755859375
            },
            {
                "time": "Mon, Apr 8, 4:19 PM",
                "value": 666.0999755859375
            },
            {
                "time": "Mon, Apr 8, 4:20 PM",
                "value": 653.2000122070312
            },
            {
                "time": "Mon, Apr 8, 4:20 PM",
                "value": 675.2999877929688
            },
            {
                "time": "Mon, Apr 8, 4:21 PM",
                "value": 683.5
            },
            {
                "time": "Mon, Apr 8, 4:21 PM",
                "value": 694.9000244140625
            },
            {
                "time": "Mon, Apr 8, 4:22 PM",
                "value": 702.5
            },
            {
                "time": "Mon, Apr 8, 4:22 PM",
                "value": 710.5
            },
            {
                "time": "Mon, Apr 8, 4:23 PM",
                "value": 704.2999877929688
            },
            {
                "time": "Mon, Apr 8, 4:23 PM",
                "value": 696.7000122070312
            },
            {
                "time": "Mon, Apr 8, 4:24 PM",
                "value": 694.2000122070312
            },
            {
                "time": "Mon, Apr 8, 4:24 PM",
                "value": 692.4000244140625
            },
            {
                "time": "Mon, Apr 8, 4:25 PM",
                "value": 686.4000244140625
            },
            {
                "time": "Mon, Apr 8, 4:25 PM",
                "value": 664.2999877929688
            },
            {
                "time": "Mon, Apr 8, 4:26 PM",
                "value": 660.7999877929688
            },
            {
                "time": "Mon, Apr 8, 4:26 PM",
                "value": 654.5999755859375
            },
            {
                "time": "Mon, Apr 8, 4:27 PM",
                "value": 660.7000122070312
            },
            {
                "time": "Mon, Apr 8, 4:27 PM",
                "value": 639.7000122070312
            },
            {
                "time": "Mon, Apr 8, 4:28 PM",
                "value": 613
            },
            {
                "time": "Mon, Apr 8, 4:28 PM",
                "value": 614.7999877929688
            },
            {
                "time": "Mon, Apr 8, 4:29 PM",
                "value": 636
            },
            {
                "time": "Mon, Apr 8, 4:29 PM",
                "value": 641.5
            },
            {
                "time": "Mon, Apr 8, 4:30 PM",
                "value": 656.4000244140625
            },
            {
                "time": "Mon, Apr 8, 4:30 PM",
                "value": 683.5999755859375
            },
            {
                "time": "Mon, Apr 8, 4:31 PM",
                "value": 698.7999877929688
            },
            {
                "time": "Mon, Apr 8, 4:31 PM",
                "value": 710
            },
            {
                "time": "Mon, Apr 8, 4:32 PM",
                "value": 708.5
            },
            {
                "time": "Mon, Apr 8, 4:32 PM",
                "value": 689.2000122070312
            },
            {
                "time": "Mon, Apr 8, 4:33 PM",
                "value": 671.9000244140625
            },
            {
                "time": "Mon, Apr 8, 4:33 PM",
                "value": 686.7999877929688
            },
            {
                "time": "Mon, Apr 8, 4:34 PM",
                "value": 687.5
            },
            {
                "time": "Mon, Apr 8, 4:34 PM",
                "value": 683.9000244140625
            },
            {
                "time": "Mon, Apr 8, 4:35 PM",
                "value": 683.4000244140625
            },
            {
                "time": "Mon, Apr 8, 4:35 PM",
                "value": 680.5999755859375
            },
            {
                "time": "Mon, Apr 8, 4:36 PM",
                "value": 659.5999755859375
            },
            {
                "time": "Mon, Apr 8, 4:36 PM",
                "value": 668.9000244140625
            },
            {
                "time": "Mon, Apr 8, 4:37 PM",
                "value": 662.9000244140625
            },
            {
                "time": "Mon, Apr 8, 4:37 PM",
                "value": 650.5
            },
            {
                "time": "Mon, Apr 8, 4:38 PM",
                "value": 645.9000244140625
            },
            {
                "time": "Mon, Apr 8, 4:38 PM",
                "value": 623.2999877929688
            },
            {
                "time": "Mon, Apr 8, 4:39 PM",
                "value": 615.7999877929688
            },
            {
                "time": "Mon, Apr 8, 4:39 PM",
                "value": 622.4000244140625
            },
            {
                "time": "Mon, Apr 8, 4:40 PM",
                "value": 641.7000122070312
            },
            {
                "time": "Mon, Apr 8, 4:40 PM",
                "value": 647.5999755859375
            },
            {
                "time": "Mon, Apr 8, 4:41 PM",
                "value": 638.4000244140625
            },
            {
                "time": "Mon, Apr 8, 4:41 PM",
                "value": 648.4000244140625
            },
            {
                "time": "Mon, Apr 8, 4:42 PM",
                "value": 657.2000122070312
            },
            {
                "time": "Mon, Apr 8, 4:42 PM",
                "value": 675.2999877929688
            },
            {
                "time": "Mon, Apr 8, 4:43 PM",
                "value": 679.5
            },
            {
                "time": "Mon, Apr 8, 4:43 PM",
                "value": 673.7999877929688
            },
            {
                "time": "Mon, Apr 8, 4:44 PM",
                "value": 672.2999877929688
            },
            {
                "time": "Mon, Apr 8, 4:44 PM",
                "value": 655.4000244140625
            },
            {
                "time": "Mon, Apr 8, 4:45 PM",
                "value": 645.2000122070312
            },
            {
                "time": "Mon, Apr 8, 4:45 PM",
                "value": 617.0999755859375
            },
            {
                "time": "Mon, Apr 8, 4:46 PM",
                "value": 628
            },
            {
                "time": "Mon, Apr 8, 4:46 PM",
                "value": 630.0999755859375
            },
            {
                "time": "Mon, Apr 8, 4:47 PM",
                "value": 634
            },
            {
                "time": "Mon, Apr 8, 4:47 PM",
                "value": 632.7999877929688
            },
            {
                "time": "Mon, Apr 8, 4:48 PM",
                "value": 623
            },
            {
                "time": "Mon, Apr 8, 4:48 PM",
                "value": 591.2000122070312
            },
            {
                "time": "Mon, Apr 8, 4:49 PM",
                "value": 581.2000122070312
            },
            {
                "time": "Mon, Apr 8, 4:49 PM",
                "value": 613.7999877929688
            },
            {
                "time": "Mon, Apr 8, 4:50 PM",
                "value": 606.2999877929688
            },
            {
                "time": "Mon, Apr 8, 4:50 PM",
                "value": 613.9000244140625
            },
            {
                "time": "Mon, Apr 8, 4:51 PM",
                "value": 620
            },
            {
                "time": "Mon, Apr 8, 4:51 PM",
                "value": 601.0999755859375
            },
            {
                "time": "Mon, Apr 8, 4:52 PM",
                "value": 633.5999755859375
            },
            {
                "time": "Mon, Apr 8, 4:52 PM",
                "value": 642.7999877929688
            },
            {
                "time": "Mon, Apr 8, 4:53 PM",
                "value": 634.2000122070312
            },
            {
                "time": "Mon, Apr 8, 4:53 PM",
                "value": 624.9000244140625
            },
            {
                "time": "Mon, Apr 8, 4:54 PM",
                "value": 584.7999877929688
            },
            {
                "time": "Mon, Apr 8, 4:54 PM",
                "value": 558.7000122070312
            },
            {
                "time": "Mon, Apr 8, 4:55 PM",
                "value": 623.5999755859375
            },
            {
                "time": "Mon, Apr 8, 4:55 PM",
                "value": 603.7999877929688
            },
            {
                "time": "Mon, Apr 8, 4:56 PM",
                "value": 631.9000244140625
            },
            {
                "time": "Mon, Apr 8, 4:56 PM",
                "value": 619.2000122070312
            },
            {
                "time": "Mon, Apr 8, 4:57 PM",
                "value": 617.7999877929688
            },
            {
                "time": "Mon, Apr 8, 4:57 PM",
                "value": 622.7999877929688
            },
            {
                "time": "Mon, Apr 8, 4:58 PM",
                "value": 623.7999877929688
            },
            {
                "time": "Mon, Apr 8, 4:58 PM",
                "value": 619.5999755859375
            },
            {
                "time": "Mon, Apr 8, 4:59 PM",
                "value": 618.4000244140625
            },
            {
                "time": "Mon, Apr 8, 4:59 PM",
                "value": 620.2999877929688
            },
            {
                "time": "Mon, Apr 8, 5:00 PM",
                "value": 611.0999755859375
            },
            {
                "time": "Mon, Apr 8, 5:00 PM",
                "value": 589.5999755859375
            },
            {
                "time": "Mon, Apr 8, 5:01 PM",
                "value": 605.5
            },
            {
                "time": "Mon, Apr 8, 5:01 PM",
                "value": 611.7999877929688
            },
            {
                "time": "Mon, Apr 8, 5:02 PM",
                "value": 613.0999755859375
            },
            {
                "time": "Mon, Apr 8, 5:02 PM",
                "value": 603.0999755859375
            },
            {
                "time": "Mon, Apr 8, 5:03 PM",
                "value": 598.7999877929688
            },
            {
                "time": "Mon, Apr 8, 5:03 PM",
                "value": 602.2000122070312
            },
            {
                "time": "Mon, Apr 8, 5:04 PM",
                "value": 602.5999755859375
            },
            {
                "time": "Mon, Apr 8, 5:04 PM",
                "value": 594.9000244140625
            },
            {
                "time": "Mon, Apr 8, 5:05 PM",
                "value": 590
            },
            {
                "time": "Mon, Apr 8, 5:05 PM",
                "value": 592.5
            },
            {
                "time": "Mon, Apr 8, 5:06 PM",
                "value": 594.2000122070312
            },
            {
                "time": "Mon, Apr 8, 5:06 PM",
                "value": 597.0999755859375
            },
            {
                "time": "Mon, Apr 8, 5:07 PM",
                "value": 599
            },
            {
                "time": "Mon, Apr 8, 5:07 PM",
                "value": 598.4000244140625
            },
            {
                "time": "Mon, Apr 8, 5:08 PM",
                "value": 596.0999755859375
            },
            {
                "time": "Mon, Apr 8, 5:08 PM",
                "value": 594.5999755859375
            },
            {
                "time": "Mon, Apr 8, 5:09 PM",
                "value": 593.2999877929688
            },
            {
                "time": "Mon, Apr 8, 5:09 PM",
                "value": 593.2999877929688
            },
            {
                "time": "Mon, Apr 8, 5:10 PM",
                "value": 591.5999755859375
            },
            {
                "time": "Mon, Apr 8, 5:10 PM",
                "value": 589.2999877929688
            },
            {
                "time": "Mon, Apr 8, 5:11 PM",
                "value": 585
            },
            {
                "time": "Mon, Apr 8, 5:11 PM",
                "value": 586.2999877929688
            },
            {
                "time": "Mon, Apr 8, 5:12 PM",
                "value": 584.5
            },
            {
                "time": "Mon, Apr 8, 5:12 PM",
                "value": 582.4000244140625
            },
            {
                "time": "Mon, Apr 8, 5:13 PM",
                "value": 581.2000122070312
            },
            {
                "time": "Mon, Apr 8, 5:13 PM",
                "value": 580.0999755859375
            },
            {
                "time": "Mon, Apr 8, 5:14 PM",
                "value": 568.4000244140625
            },
            {
                "time": "Mon, Apr 8, 5:14 PM",
                "value": 563.0999755859375
            },
            {
                "time": "Mon, Apr 8, 5:15 PM",
                "value": 550.2000122070312
            },
            {
                "time": "Mon, Apr 8, 5:15 PM",
                "value": 546.4000244140625
            },
            {
                "time": "Mon, Apr 8, 5:16 PM",
                "value": 565.4000244140625
            },
            {
                "time": "Mon, Apr 8, 5:16 PM",
                "value": 569
            },
            {
                "time": "Mon, Apr 8, 5:17 PM",
                "value": 567.2999877929688
            },
            {
                "time": "Mon, Apr 8, 5:17 PM",
                "value": 565.2000122070312
            },
            {
                "time": "Mon, Apr 8, 5:18 PM",
                "value": 563
            },
            {
                "time": "Mon, Apr 8, 5:18 PM",
                "value": 561
            },
            {
                "time": "Mon, Apr 8, 5:19 PM",
                "value": 559.4000244140625
            },
            {
                "time": "Mon, Apr 8, 5:19 PM",
                "value": 556.9000244140625
            },
            {
                "time": "Mon, Apr 8, 5:20 PM",
                "value": 554.0999755859375
            },
            {
                "time": "Mon, Apr 8, 5:20 PM",
                "value": 550.9000244140625
            },
            {
                "time": "Mon, Apr 8, 5:21 PM",
                "value": 548.9000244140625
            },
            {
                "time": "Mon, Apr 8, 5:21 PM",
                "value": 547.2000122070312
            },
            {
                "time": "Mon, Apr 8, 5:22 PM",
                "value": 545.5999755859375
            },
            {
                "time": "Mon, Apr 8, 5:22 PM",
                "value": 546.2999877929688
            },
            {
                "time": "Mon, Apr 8, 5:23 PM",
                "value": 545.5
            },
            {
                "time": "Mon, Apr 8, 5:23 PM",
                "value": 544
            },
            {
                "time": "Mon, Apr 8, 5:24 PM",
                "value": 542.7000122070312
            },
            {
                "time": "Mon, Apr 8, 5:24 PM",
                "value": 540.9000244140625
            },
            {
                "time": "Mon, Apr 8, 5:25 PM",
                "value": 539.2999877929688
            },
            {
                "time": "Mon, Apr 8, 5:25 PM",
                "value": 537.7000122070312
            },
            {
                "time": "Mon, Apr 8, 5:26 PM",
                "value": 536
            },
            {
                "time": "Mon, Apr 8, 5:26 PM",
                "value": 534.7999877929688
            },
            {
                "time": "Mon, Apr 8, 5:27 PM",
                "value": 533.5
            },
            {
                "time": "Mon, Apr 8, 5:27 PM",
                "value": 532.5
            },
            {
                "time": "Mon, Apr 8, 5:28 PM",
                "value": 531.2999877929688
            },
            {
                "time": "Mon, Apr 8, 5:28 PM",
                "value": 529.5
            },
            {
                "time": "Mon, Apr 8, 5:29 PM",
                "value": 528
            },
            {
                "time": "Mon, Apr 8, 5:29 PM",
                "value": 525.7000122070312
            },
            {
                "time": "Mon, Apr 8, 5:30 PM",
                "value": 523.5
            },
            {
                "time": "Mon, Apr 8, 5:30 PM",
                "value": 522.2000122070312
            },
            {
                "time": "Mon, Apr 8, 5:31 PM",
                "value": 520.5
            },
            {
                "time": "Mon, Apr 8, 5:31 PM",
                "value": 517.9000244140625
            },
            {
                "time": "Mon, Apr 8, 5:32 PM",
                "value": 516.2999877929688
            },
            {
                "time": "Mon, Apr 8, 5:32 PM",
                "value": 514.9000244140625
            },
            {
                "time": "Mon, Apr 8, 5:33 PM",
                "value": 512.7999877929688
            },
            {
                "time": "Mon, Apr 8, 5:33 PM",
                "value": 510.70001220703125
            },
            {
                "time": "Mon, Apr 8, 5:34 PM",
                "value": 493.1000061035156
            },
            {
                "time": "Mon, Apr 8, 5:34 PM",
                "value": 495.6000061035156
            },
            {
                "time": "Mon, Apr 8, 5:35 PM",
                "value": 506.8999938964844
            },
            {
                "time": "Mon, Apr 8, 5:35 PM",
                "value": 504.79998779296875
            },
            {
                "time": "Mon, Apr 8, 5:36 PM",
                "value": 504.1000061035156
            },
            {
                "time": "Mon, Apr 8, 5:36 PM",
                "value": 501.6000061035156
            },
            {
                "time": "Mon, Apr 8, 5:37 PM",
                "value": 501.6000061035156
            },
            {
                "time": "Mon, Apr 8, 5:37 PM",
                "value": 499.8999938964844
            },
            {
                "time": "Mon, Apr 8, 5:38 PM",
                "value": 497.3999938964844
            },
            {
                "time": "Mon, Apr 8, 5:38 PM",
                "value": 479.70001220703125
            },
            {
                "time": "Mon, Apr 8, 5:39 PM",
                "value": 486.5
            },
            {
                "time": "Mon, Apr 8, 5:39 PM",
                "value": 489.3999938964844
            },
            {
                "time": "Mon, Apr 8, 5:40 PM",
                "value": 485.5
            },
            {
                "time": "Mon, Apr 8, 5:40 PM",
                "value": 482
            },
            {
                "time": "Mon, Apr 8, 5:41 PM",
                "value": 476.6000061035156
            },
            {
                "time": "Mon, Apr 8, 5:41 PM",
                "value": 485.5
            },
            {
                "time": "Mon, Apr 8, 5:42 PM",
                "value": 484.20001220703125
            },
            {
                "time": "Mon, Apr 8, 5:42 PM",
                "value": 483
            },
            {
                "time": "Mon, Apr 8, 5:43 PM",
                "value": 480.8999938964844
            },
            {
                "time": "Mon, Apr 8, 5:43 PM",
                "value": 479.1000061035156
            },
            {
                "time": "Mon, Apr 8, 5:44 PM",
                "value": 478.29998779296875
            },
            {
                "time": "Mon, Apr 8, 5:44 PM",
                "value": 475
            },
            {
                "time": "Mon, Apr 8, 5:45 PM",
                "value": 475.5
            },
            {
                "time": "Mon, Apr 8, 5:45 PM",
                "value": 473
            },
            {
                "time": "Mon, Apr 8, 5:46 PM",
                "value": 472.8999938964844
            },
            {
                "time": "Mon, Apr 8, 5:46 PM",
                "value": 473
            },
            {
                "time": "Mon, Apr 8, 5:47 PM",
                "value": 471.3999938964844
            },
            {
                "time": "Mon, Apr 8, 5:47 PM",
                "value": 469.8999938964844
            },
            {
                "time": "Mon, Apr 8, 5:48 PM",
                "value": 460.5
            },
            {
                "time": "Mon, Apr 8, 5:48 PM",
                "value": 461.8999938964844
            },
            {
                "time": "Mon, Apr 8, 5:49 PM",
                "value": 459.70001220703125
            },
            {
                "time": "Mon, Apr 8, 5:49 PM",
                "value": 450.1000061035156
            },
            {
                "time": "Mon, Apr 8, 5:50 PM",
                "value": 444.5
            },
            {
                "time": "Mon, Apr 8, 5:50 PM",
                "value": 450.29998779296875
            },
            {
                "time": "Mon, Apr 8, 5:51 PM",
                "value": 452.20001220703125
            },
            {
                "time": "Mon, Apr 8, 5:51 PM",
                "value": 450.70001220703125
            },
            {
                "time": "Mon, Apr 8, 5:52 PM",
                "value": 450.29998779296875
            },
            {
                "time": "Mon, Apr 8, 5:52 PM",
                "value": 449.29998779296875
            },
            {
                "time": "Mon, Apr 8, 5:53 PM",
                "value": 445.5
            },
            {
                "time": "Mon, Apr 8, 5:53 PM",
                "value": 434.29998779296875
            },
            {
                "time": "Mon, Apr 8, 5:54 PM",
                "value": 432.6000061035156
            },
            {
                "time": "Mon, Apr 8, 5:54 PM",
                "value": 446.29998779296875
            },
            {
                "time": "Mon, Apr 8, 5:55 PM",
                "value": 441.1000061035156
            },
            {
                "time": "Mon, Apr 8, 5:55 PM",
                "value": 442.79998779296875
            },
            {
                "time": "Mon, Apr 8, 5:56 PM",
                "value": 441.79998779296875
            },
            {
                "time": "Mon, Apr 8, 5:56 PM",
                "value": 439.3999938964844
            },
            {
                "time": "Mon, Apr 8, 5:57 PM",
                "value": 437.6000061035156
            },
            {
                "time": "Mon, Apr 8, 5:57 PM",
                "value": 435.5
            },
            {
                "time": "Mon, Apr 8, 5:58 PM",
                "value": 433.79998779296875
            },
            {
                "time": "Mon, Apr 8, 5:58 PM",
                "value": 432.1000061035156
            },
            {
                "time": "Mon, Apr 8, 5:59 PM",
                "value": 430.5
            },
            {
                "time": "Mon, Apr 8, 5:59 PM",
                "value": 428.8999938964844
            },
            {
                "time": "Mon, Apr 8, 6:00 PM",
                "value": 428.29998779296875
            },
            {
                "time": "Mon, Apr 8, 6:00 PM",
                "value": 427
            },
            {
                "time": "Mon, Apr 8, 6:01 PM",
                "value": 426.20001220703125
            },
            {
                "time": "Mon, Apr 8, 6:01 PM",
                "value": 425
            },
            {
                "time": "Mon, Apr 8, 6:02 PM",
                "value": 409.5
            },
            {
                "time": "Mon, Apr 8, 6:02 PM",
                "value": 410.79998779296875
            },
            {
                "time": "Mon, Apr 8, 6:03 PM",
                "value": 416.6000061035156
            },
            {
                "time": "Mon, Apr 8, 6:03 PM",
                "value": 414.79998779296875
            },
            {
                "time": "Mon, Apr 8, 6:04 PM",
                "value": 403.3999938964844
            },
            {
                "time": "Mon, Apr 8, 6:04 PM",
                "value": 401.8999938964844
            },
            {
                "time": "Mon, Apr 8, 6:05 PM",
                "value": 395.6000061035156
            },
            {
                "time": "Mon, Apr 8, 6:05 PM",
                "value": 400.6000061035156
            },
            {
                "time": "Mon, Apr 8, 6:06 PM",
                "value": 390.1000061035156
            },
            {
                "time": "Mon, Apr 8, 6:06 PM",
                "value": 395.70001220703125
            },
            {
                "time": "Mon, Apr 8, 6:07 PM",
                "value": 377.79998779296875
            },
            {
                "time": "Mon, Apr 8, 6:07 PM",
                "value": 354
            },
            {
                "time": "Mon, Apr 8, 6:08 PM",
                "value": 396.1000061035156
            },
            {
                "time": "Mon, Apr 8, 6:08 PM",
                "value": 396.3999938964844
            },
            {
                "time": "Mon, Apr 8, 6:09 PM",
                "value": 382.8999938964844
            },
            {
                "time": "Mon, Apr 8, 6:09 PM",
                "value": 387.6000061035156
            },
            {
                "time": "Mon, Apr 8, 6:10 PM",
                "value": 398.3999938964844
            },
            {
                "time": "Mon, Apr 8, 6:10 PM",
                "value": 398.8999938964844
            },
            {
                "time": "Mon, Apr 8, 6:11 PM",
                "value": 398.29998779296875
            },
            {
                "time": "Mon, Apr 8, 6:11 PM",
                "value": 394.79998779296875
            },
            {
                "time": "Mon, Apr 8, 6:12 PM",
                "value": 386.8999938964844
            },
            {
                "time": "Mon, Apr 8, 6:12 PM",
                "value": 388.3999938964844
            },
            {
                "time": "Mon, Apr 8, 6:13 PM",
                "value": 387.5
            },
            {
                "time": "Mon, Apr 8, 6:13 PM",
                "value": 378.79998779296875
            },
            {
                "time": "Mon, Apr 8, 6:14 PM",
                "value": 370.70001220703125
            },
            {
                "time": "Mon, Apr 8, 6:14 PM",
                "value": 380.3999938964844
            },
            {
                "time": "Mon, Apr 8, 6:15 PM",
                "value": 379.70001220703125
            },
            {
                "time": "Mon, Apr 8, 6:15 PM",
                "value": 376.20001220703125
            },
            {
                "time": "Mon, Apr 8, 6:16 PM",
                "value": 374.5
            },
            {
                "time": "Mon, Apr 8, 6:16 PM",
                "value": 357.79998779296875
            },
            {
                "time": "Mon, Apr 8, 6:17 PM",
                "value": 343
            },
            {
                "time": "Mon, Apr 8, 6:17 PM",
                "value": 360.79998779296875
            },
            {
                "time": "Mon, Apr 8, 6:18 PM",
                "value": 367.29998779296875
            },
            {
                "time": "Mon, Apr 8, 6:18 PM",
                "value": 368.3999938964844
            },
            {
                "time": "Mon, Apr 8, 6:19 PM",
                "value": 365.5
            },
            {
                "time": "Mon, Apr 8, 6:19 PM",
                "value": 364.79998779296875
            },
            {
                "time": "Mon, Apr 8, 6:20 PM",
                "value": 361.6000061035156
            },
            {
                "time": "Mon, Apr 8, 6:20 PM",
                "value": 361.5
            },
            {
                "time": "Mon, Apr 8, 6:21 PM",
                "value": 346
            },
            {
                "time": "Mon, Apr 8, 6:21 PM",
                "value": 343.20001220703125
            },
            {
                "time": "Mon, Apr 8, 6:22 PM",
                "value": 349.29998779296875
            },
            {
                "time": "Mon, Apr 8, 6:22 PM",
                "value": 337.8999938964844
            },
            {
                "time": "Mon, Apr 8, 6:23 PM",
                "value": 329.20001220703125
            },
            {
                "time": "Mon, Apr 8, 6:23 PM",
                "value": 343.6000061035156
            },
            {
                "time": "Mon, Apr 8, 6:24 PM",
                "value": 341.29998779296875
            },
            {
                "time": "Mon, Apr 8, 6:24 PM",
                "value": 338.29998779296875
            },
            {
                "time": "Mon, Apr 8, 6:25 PM",
                "value": 340.1000061035156
            },
            {
                "time": "Mon, Apr 8, 6:25 PM",
                "value": 340.79998779296875
            },
            {
                "time": "Mon, Apr 8, 6:26 PM",
                "value": 338.5
            },
            {
                "time": "Mon, Apr 8, 6:26 PM",
                "value": 335.79998779296875
            },
            {
                "time": "Mon, Apr 8, 6:27 PM",
                "value": 336.3999938964844
            },
            {
                "time": "Mon, Apr 8, 6:27 PM",
                "value": 337.79998779296875
            },
            {
                "time": "Mon, Apr 8, 6:28 PM",
                "value": 333.8999938964844
            },
            {
                "time": "Mon, Apr 8, 6:28 PM",
                "value": 333.5
            },
            {
                "time": "Mon, Apr 8, 6:29 PM",
                "value": 334.1000061035156
            },
            {
                "time": "Mon, Apr 8, 6:29 PM",
                "value": 330.5
            },
            {
                "time": "Mon, Apr 8, 6:30 PM",
                "value": 330.5
            },
            {
                "time": "Mon, Apr 8, 6:30 PM",
                "value": 327.79998779296875
            },
            {
                "time": "Mon, Apr 8, 6:31 PM",
                "value": 325.20001220703125
            },
            {
                "time": "Mon, Apr 8, 6:31 PM",
                "value": 321.70001220703125
            },
            {
                "time": "Mon, Apr 8, 6:32 PM",
                "value": 316.20001220703125
            },
            {
                "time": "Mon, Apr 8, 6:32 PM",
                "value": 319.1000061035156
            },
            {
                "time": "Mon, Apr 8, 6:33 PM",
                "value": 317.8999938964844
            },
            {
                "time": "Mon, Apr 8, 6:33 PM",
                "value": 316.5
            },
            {
                "time": "Mon, Apr 8, 6:34 PM",
                "value": 314.20001220703125
            },
            {
                "time": "Mon, Apr 8, 6:34 PM",
                "value": 311.3999938964844
            },
            {
                "time": "Mon, Apr 8, 6:35 PM",
                "value": 309.79998779296875
            },
            {
                "time": "Mon, Apr 8, 6:35 PM",
                "value": 310.20001220703125
            },
            {
                "time": "Mon, Apr 8, 6:36 PM",
                "value": 309.6000061035156
            },
            {
                "time": "Mon, Apr 8, 6:36 PM",
                "value": 307.79998779296875
            },
            {
                "time": "Mon, Apr 8, 6:37 PM",
                "value": 306
            },
            {
                "time": "Mon, Apr 8, 6:37 PM",
                "value": 304
            },
            {
                "time": "Mon, Apr 8, 6:38 PM",
                "value": 300.8999938964844
            },
            {
                "time": "Mon, Apr 8, 6:38 PM",
                "value": 297.6000061035156
            },
            {
                "time": "Mon, Apr 8, 6:39 PM",
                "value": 299.79998779296875
            },
            {
                "time": "Mon, Apr 8, 6:39 PM",
                "value": 296.3999938964844
            },
            {
                "time": "Mon, Apr 8, 6:40 PM",
                "value": 270.79998779296875
            },
            {
                "time": "Mon, Apr 8, 6:40 PM",
                "value": 190.5
            },
            {
                "time": "Mon, Apr 8, 6:41 PM",
                "value": 168.8000030517578
            },
            {
                "time": "Mon, Apr 8, 6:41 PM",
                "value": 142.3000030517578
            },
            {
                "time": "Mon, Apr 8, 6:42 PM",
                "value": 243.5
            },
            {
                "time": "Mon, Apr 8, 6:42 PM",
                "value": 277.5
            },
            {
                "time": "Mon, Apr 8, 6:43 PM",
                "value": 261.20001220703125
            },
            {
                "time": "Mon, Apr 8, 6:43 PM",
                "value": 280.1000061035156
            },
            {
                "time": "Mon, Apr 8, 6:44 PM",
                "value": 226
            },
            {
                "time": "Mon, Apr 8, 6:44 PM",
                "value": 225.6999969482422
            },
            {
                "time": "Mon, Apr 8, 6:45 PM",
                "value": 232.89999389648438
            },
            {
                "time": "Mon, Apr 8, 6:45 PM",
                "value": 183
            },
            {
                "time": "Mon, Apr 8, 6:46 PM",
                "value": 255.10000610351562
            },
            {
                "time": "Mon, Apr 8, 6:46 PM",
                "value": 265.70001220703125
            },
            {
                "time": "Mon, Apr 8, 6:47 PM",
                "value": 269.8999938964844
            },
            {
                "time": "Mon, Apr 8, 6:47 PM",
                "value": 259.8999938964844
            },
            {
                "time": "Mon, Apr 8, 6:48 PM",
                "value": 229.6999969482422
            },
            {
                "time": "Mon, Apr 8, 6:48 PM",
                "value": 263.29998779296875
            },
            {
                "time": "Mon, Apr 8, 6:49 PM",
                "value": 263.20001220703125
            },
            {
                "time": "Mon, Apr 8, 6:49 PM",
                "value": 253.1999969482422
            },
            {
                "time": "Mon, Apr 8, 6:50 PM",
                "value": 259.79998779296875
            },
            {
                "time": "Mon, Apr 8, 6:50 PM",
                "value": 257.20001220703125
            },
            {
                "time": "Mon, Apr 8, 6:51 PM",
                "value": 254.5
            },
            {
                "time": "Mon, Apr 8, 6:51 PM",
                "value": 253.10000610351562
            },
            {
                "time": "Mon, Apr 8, 6:52 PM",
                "value": 251.5
            },
            {
                "time": "Mon, Apr 8, 6:52 PM",
                "value": 250.3000030517578
            },
            {
                "time": "Mon, Apr 8, 6:53 PM",
                "value": 249.3000030517578
            },
            {
                "time": "Mon, Apr 8, 6:53 PM",
                "value": 247.89999389648438
            },
            {
                "time": "Mon, Apr 8, 6:54 PM",
                "value": 247.1999969482422
            },
            {
                "time": "Mon, Apr 8, 6:54 PM",
                "value": 243
            },
            {
                "time": "Mon, Apr 8, 6:55 PM",
                "value": 238.89999389648438
            },
            {
                "time": "Mon, Apr 8, 6:55 PM",
                "value": 229.1999969482422
            },
            {
                "time": "Mon, Apr 8, 6:56 PM",
                "value": 220.1999969482422
            },
            {
                "time": "Mon, Apr 8, 6:56 PM",
                "value": 227.39999389648438
            },
            {
                "time": "Mon, Apr 8, 6:57 PM",
                "value": 236.6999969482422
            },
            {
                "time": "Mon, Apr 8, 6:57 PM",
                "value": 235.10000610351562
            },
            {
                "time": "Mon, Apr 8, 6:58 PM",
                "value": 232.8000030517578
            },
            {
                "time": "Mon, Apr 8, 6:58 PM",
                "value": 231.60000610351562
            },
            {
                "time": "Mon, Apr 8, 6:59 PM",
                "value": 230.39999389648438
            },
            {
                "time": "Mon, Apr 8, 6:59 PM",
                "value": 228.8000030517578
            },
            {
                "time": "Mon, Apr 8, 7:00 PM",
                "value": 227.8000030517578
            },
            {
                "time": "Mon, Apr 8, 7:00 PM",
                "value": 226.3000030517578
            },
            {
                "time": "Mon, Apr 8, 7:01 PM",
                "value": 224
            },
            {
                "time": "Mon, Apr 8, 7:01 PM",
                "value": 222.39999389648438
            },
            {
                "time": "Mon, Apr 8, 7:02 PM",
                "value": 221.60000610351562
            },
            {
                "time": "Mon, Apr 8, 7:02 PM",
                "value": 220.10000610351562
            },
            {
                "time": "Mon, Apr 8, 7:03 PM",
                "value": 218.39999389648438
            },
            {
                "time": "Mon, Apr 8, 7:03 PM",
                "value": 215.6999969482422
            },
            {
                "time": "Mon, Apr 8, 7:04 PM",
                "value": 214
            },
            {
                "time": "Mon, Apr 8, 7:04 PM",
                "value": 212.60000610351562
            },
            {
                "time": "Mon, Apr 8, 7:05 PM",
                "value": 210.39999389648438
            },
            {
                "time": "Mon, Apr 8, 7:05 PM",
                "value": 208.3000030517578
            },
            {
                "time": "Mon, Apr 8, 7:06 PM",
                "value": 204
            },
            {
                "time": "Mon, Apr 8, 7:06 PM",
                "value": 203.89999389648438
            },
            {
                "time": "Mon, Apr 8, 7:07 PM",
                "value": 198.10000610351562
            },
            {
                "time": "Mon, Apr 8, 7:07 PM",
                "value": 197
            },
            {
                "time": "Mon, Apr 8, 7:08 PM",
                "value": 194
            },
            {
                "time": "Mon, Apr 8, 7:08 PM",
                "value": 196
            },
            {
                "time": "Mon, Apr 8, 7:09 PM",
                "value": 195.60000610351562
            },
            {
                "time": "Mon, Apr 8, 7:09 PM",
                "value": 193.89999389648438
            },
            {
                "time": "Mon, Apr 8, 7:10 PM",
                "value": 192.10000610351562
            },
            {
                "time": "Mon, Apr 8, 7:10 PM",
                "value": 190.10000610351562
            },
            {
                "time": "Mon, Apr 8, 7:11 PM",
                "value": 187.89999389648438
            },
            {
                "time": "Mon, Apr 8, 7:11 PM",
                "value": 185.89999389648438
            },
            {
                "time": "Mon, Apr 8, 7:12 PM",
                "value": 183.5
            },
            {
                "time": "Mon, Apr 8, 7:12 PM",
                "value": 181.5
            },
            {
                "time": "Mon, Apr 8, 7:13 PM",
                "value": 179.3000030517578
            },
            {
                "time": "Mon, Apr 8, 7:13 PM",
                "value": 177.39999389648438
            },
            {
                "time": "Mon, Apr 8, 7:14 PM",
                "value": 175.89999389648438
            },
            {
                "time": "Mon, Apr 8, 7:14 PM",
                "value": 174
            },
            {
                "time": "Mon, Apr 8, 7:15 PM",
                "value": 172.10000610351562
            },
            {
                "time": "Mon, Apr 8, 7:15 PM",
                "value": 170.5
            },
            {
                "time": "Mon, Apr 8, 7:16 PM",
                "value": 169.10000610351562
            },
            {
                "time": "Mon, Apr 8, 7:16 PM",
                "value": 165.60000610351562
            },
            {
                "time": "Mon, Apr 8, 7:17 PM",
                "value": 162.8000030517578
            },
            {
                "time": "Mon, Apr 8, 7:17 PM",
                "value": 151.89999389648438
            },
            {
                "time": "Mon, Apr 8, 7:18 PM",
                "value": 149.5
            },
            {
                "time": "Mon, Apr 8, 7:18 PM",
                "value": 148.8000030517578
            },
            {
                "time": "Mon, Apr 8, 7:19 PM",
                "value": 155.1999969482422
            },
            {
                "time": "Mon, Apr 8, 7:19 PM",
                "value": 158.60000610351562
            },
            {
                "time": "Mon, Apr 8, 7:20 PM",
                "value": 156.8000030517578
            },
            {
                "time": "Mon, Apr 8, 7:20 PM",
                "value": 154.89999389648438
            },
            {
                "time": "Mon, Apr 8, 7:21 PM",
                "value": 153.89999389648438
            },
            {
                "time": "Mon, Apr 8, 7:21 PM",
                "value": 145.89999389648438
            },
            {
                "time": "Mon, Apr 8, 7:22 PM",
                "value": 124.69999694824219
            },
            {
                "time": "Mon, Apr 8, 7:22 PM",
                "value": 120.9000015258789
            },
            {
                "time": "Mon, Apr 8, 7:23 PM",
                "value": 129.89999389648438
            },
            {
                "time": "Mon, Apr 8, 7:23 PM",
                "value": 142.8000030517578
            },
            {
                "time": "Mon, Apr 8, 7:24 PM",
                "value": 143.6999969482422
            },
            {
                "time": "Mon, Apr 8, 7:24 PM",
                "value": 141.1999969482422
            },
            {
                "time": "Mon, Apr 8, 7:25 PM",
                "value": 138.39999389648438
            },
            {
                "time": "Mon, Apr 8, 7:25 PM",
                "value": 132.60000610351562
            },
            {
                "time": "Mon, Apr 8, 7:26 PM",
                "value": 128.8000030517578
            },
            {
                "time": "Mon, Apr 8, 7:26 PM",
                "value": 135.39999389648438
            },
            {
                "time": "Mon, Apr 8, 7:27 PM",
                "value": 134.8000030517578
            },
            {
                "time": "Mon, Apr 8, 7:27 PM",
                "value": 133.3000030517578
            },
            {
                "time": "Mon, Apr 8, 7:28 PM",
                "value": 132
            },
            {
                "time": "Mon, Apr 8, 7:28 PM",
                "value": 131
            },
            {
                "time": "Mon, Apr 8, 7:29 PM",
                "value": 130.10000610351562
            },
            {
                "time": "Mon, Apr 8, 7:29 PM",
                "value": 128.8000030517578
            },
            {
                "time": "Mon, Apr 8, 7:30 PM",
                "value": 125.5
            },
            {
                "time": "Mon, Apr 8, 7:30 PM",
                "value": 108.9000015258789
            },
            {
                "time": "Mon, Apr 8, 7:31 PM",
                "value": 112.80000305175781
            },
            {
                "time": "Mon, Apr 8, 7:31 PM",
                "value": 120
            },
            {
                "time": "Mon, Apr 8, 7:32 PM",
                "value": 117.30000305175781
            },
            {
                "time": "Mon, Apr 8, 7:32 PM",
                "value": 112.5999984741211
            },
            {
                "time": "Mon, Apr 8, 7:33 PM",
                "value": 111.19999694824219
            },
            {
                "time": "Mon, Apr 8, 7:33 PM",
                "value": 110.9000015258789
            },
            {
                "time": "Mon, Apr 8, 7:34 PM",
                "value": 112.5999984741211
            },
            {
                "time": "Mon, Apr 8, 7:34 PM",
                "value": 111.5
            },
            {
                "time": "Mon, Apr 8, 7:35 PM",
                "value": 109.30000305175781
            },
            {
                "time": "Mon, Apr 8, 7:35 PM",
                "value": 107.5999984741211
            },
            {
                "time": "Mon, Apr 8, 7:36 PM",
                "value": 106
            },
            {
                "time": "Mon, Apr 8, 7:36 PM",
                "value": 104.69999694824219
            },
            {
                "time": "Mon, Apr 8, 7:37 PM",
                "value": 103
            },
            {
                "time": "Mon, Apr 8, 7:37 PM",
                "value": 101.0999984741211
            },
            {
                "time": "Mon, Apr 8, 7:38 PM",
                "value": 97.80000305175781
            },
            {
                "time": "Mon, Apr 8, 7:38 PM",
                "value": 95.5999984741211
            },
            {
                "time": "Mon, Apr 8, 7:39 PM",
                "value": 96.30000305175781
            },
            {
                "time": "Mon, Apr 8, 7:39 PM",
                "value": 95.5999984741211
            },
            {
                "time": "Mon, Apr 8, 7:40 PM",
                "value": 94.19999694824219
            },
            {
                "time": "Mon, Apr 8, 7:40 PM",
                "value": 93
            },
            {
                "time": "Mon, Apr 8, 7:41 PM",
                "value": 91.5
            },
            {
                "time": "Mon, Apr 8, 7:41 PM",
                "value": 87.4000015258789
            },
            {
                "time": "Mon, Apr 8, 7:42 PM",
                "value": 85.5
            },
            {
                "time": "Mon, Apr 8, 7:42 PM",
                "value": 87.9000015258789
            },
            {
                "time": "Mon, Apr 8, 7:43 PM",
                "value": 81.30000305175781
            },
            {
                "time": "Mon, Apr 8, 7:43 PM",
                "value": 70.37999725341797
            },
            {
                "time": "Mon, Apr 8, 7:44 PM",
                "value": 71.16999816894531
            },
            {
                "time": "Mon, Apr 8, 7:44 PM",
                "value": 73.77999877929688
            },
            {
                "time": "Mon, Apr 8, 7:45 PM",
                "value": 73.9000015258789
            },
            {
                "time": "Mon, Apr 8, 7:45 PM",
                "value": 74.91999816894531
            },
            {
                "time": "Mon, Apr 8, 7:46 PM",
                "value": 77.19000244140625
            },
            {
                "time": "Mon, Apr 8, 7:46 PM",
                "value": 77.9800033569336
            },
            {
                "time": "Mon, Apr 8, 7:47 PM",
                "value": 77.30000305175781
            },
            {
                "time": "Mon, Apr 8, 7:47 PM",
                "value": 76.05000305175781
            },
            {
                "time": "Mon, Apr 8, 7:48 PM",
                "value": 74.69000244140625
            },
            {
                "time": "Mon, Apr 8, 7:48 PM",
                "value": 72.87999725341797
            },
            {
                "time": "Mon, Apr 8, 7:49 PM",
                "value": 71.05999755859375
            },
            {
                "time": "Mon, Apr 8, 7:49 PM",
                "value": 69.58999633789062
            },
            {
                "time": "Mon, Apr 8, 7:50 PM",
                "value": 68.44999694824219
            },
            {
                "time": "Mon, Apr 8, 7:50 PM",
                "value": 67.08999633789062
            },
            {
                "time": "Mon, Apr 8, 7:51 PM",
                "value": 65.83999633789062
            },
            {
                "time": "Mon, Apr 8, 7:51 PM",
                "value": 64.36000061035156
            },
            {
                "time": "Mon, Apr 8, 7:52 PM",
                "value": 62.77000045776367
            },
            {
                "time": "Mon, Apr 8, 7:52 PM",
                "value": 60.84000015258789
            },
            {
                "time": "Mon, Apr 8, 7:53 PM",
                "value": 57.54999923706055
            },
            {
                "time": "Mon, Apr 8, 7:53 PM",
                "value": 53.91999816894531
            },
            {
                "time": "Mon, Apr 8, 7:54 PM",
                "value": 52.439998626708984
            },
            {
                "time": "Mon, Apr 8, 7:54 PM",
                "value": 52.099998474121094
            },
            {
                "time": "Mon, Apr 8, 7:55 PM",
                "value": 50.7400016784668
            },
            {
                "time": "Mon, Apr 8, 7:55 PM",
                "value": 48.470001220703125
            },
            {
                "time": "Mon, Apr 8, 7:56 PM",
                "value": 46.65999984741211
            },
            {
                "time": "Mon, Apr 8, 7:56 PM",
                "value": 44.95000076293945
            },
            {
                "time": "Mon, Apr 8, 7:57 PM",
                "value": 42.90999984741211
            },
            {
                "time": "Mon, Apr 8, 7:57 PM",
                "value": 40.529998779296875
            },
            {
                "time": "Mon, Apr 8, 7:58 PM",
                "value": 38.47999954223633
            },
            {
                "time": "Mon, Apr 8, 7:58 PM",
                "value": 36.439998626708984
            },
            {
                "time": "Mon, Apr 8, 7:59 PM",
                "value": 34.619998931884766
            },
            {
                "time": "Mon, Apr 8, 7:59 PM",
                "value": 33.029998779296875
            },
            {
                "time": "Mon, Apr 8, 8:00 PM",
                "value": 32.2400016784668
            },
            {
                "time": "Mon, Apr 8, 8:00 PM",
                "value": 32.0099983215332
            },
            {
                "time": "Mon, Apr 8, 8:01 PM",
                "value": 31.670000076293945
            },
            {
                "time": "Mon, Apr 8, 8:01 PM",
                "value": 30.200000762939453
            },
            {
                "time": "Mon, Apr 8, 8:02 PM",
                "value": 27.1299991607666
            },
            {
                "time": "Mon, Apr 8, 8:02 PM",
                "value": 23.729999542236328
            },
            {
                "time": "Mon, Apr 8, 8:03 PM",
                "value": 20.770000457763672
            },
            {
                "time": "Mon, Apr 8, 8:03 PM",
                "value": 18.6200008392334
            },
            {
                "time": "Mon, Apr 8, 8:04 PM",
                "value": 17.260000228881836
            },
            {
                "time": "Mon, Apr 8, 8:04 PM",
                "value": 16.010000228881836
            },
            {
                "time": "Mon, Apr 8, 8:05 PM",
                "value": 14.989999771118164
            },
            {
                "time": "Mon, Apr 8, 8:05 PM",
                "value": 14.300000190734863
            },
            {
                "time": "Mon, Apr 8, 8:06 PM",
                "value": 13.739999771118164
            },
            {
                "time": "Mon, Apr 8, 8:06 PM",
                "value": 13.170000076293945
            },
            {
                "time": "Mon, Apr 8, 8:07 PM",
                "value": 12.489999771118164
            },
            {
                "time": "Mon, Apr 8, 8:07 PM",
                "value": 11.920000076293945
            },
            {
                "time": "Mon, Apr 8, 8:08 PM",
                "value": 11.350000381469727
            },
            {
                "time": "Mon, Apr 8, 8:08 PM",
                "value": 10.779999732971191
            },
            {
                "time": "Mon, Apr 8, 8:09 PM",
                "value": 10.329999923706055
            },
            {
                "time": "Mon, Apr 8, 8:09 PM",
                "value": 9.760000228881836
            },
            {
                "time": "Mon, Apr 8, 8:10 PM",
                "value": 9.079999923706055
            },
            {
                "time": "Mon, Apr 8, 8:10 PM",
                "value": 8.630000114440918
            },
            {
                "time": "Mon, Apr 8, 8:11 PM",
                "value": 8.0600004196167
            },
            {
                "time": "Mon, Apr 8, 8:11 PM",
                "value": 7.605999946594238
            },
            {
                "time": "Mon, Apr 8, 8:12 PM",
                "value": 7.265999794006348
            },
            {
                "time": "Mon, Apr 8, 8:12 PM",
                "value": 6.811999797821045
            },
            {
                "time": "Mon, Apr 8, 8:13 PM",
                "value": 6.4710001945495605
            },
            {
                "time": "Mon, Apr 8, 8:13 PM",
                "value": 6.24399995803833
            },
            {
                "time": "Mon, Apr 8, 8:14 PM",
                "value": 5.9029998779296875
            },
            {
                "time": "Mon, Apr 8, 8:14 PM",
                "value": 5.563000202178955
            },
            {
                "time": "Mon, Apr 8, 8:15 PM",
                "value": 5.335999965667725
            },
            {
                "time": "Mon, Apr 8, 8:15 PM",
                "value": 4.994999885559082
            },
            {
                "time": "Mon, Apr 8, 8:16 PM",
                "value": 4.65500020980835
            },
            {
                "time": "Mon, Apr 8, 8:16 PM",
                "value": 4.427999973297119
            },
            {
                "time": "Mon, Apr 8, 8:17 PM",
                "value": 4.086999893188477
            },
            {
                "time": "Mon, Apr 8, 8:17 PM",
                "value": 3.9730000495910645
            },
            {
                "time": "Mon, Apr 8, 8:18 PM",
                "value": 3.746000051498413
            },
            {
                "time": "Mon, Apr 8, 8:18 PM",
                "value": 3.5190000534057617
            },
            {
                "time": "Mon, Apr 8, 8:19 PM",
                "value": 3.4059998989105225
            },
            {
                "time": "Mon, Apr 8, 8:19 PM",
                "value": 3.178999900817871
            },
            {
                "time": "Mon, Apr 8, 8:20 PM",
                "value": 2.9519999027252197
            },
            {
                "time": "Mon, Apr 8, 8:20 PM",
                "value": 2.7249999046325684
            },
            {
                "time": "Mon, Apr 8, 8:21 PM",
                "value": 2.497999906539917
            },
            {
                "time": "Mon, Apr 8, 8:21 PM",
                "value": 2.2709999084472656
            },
            {
                "time": "Mon, Apr 8, 8:22 PM",
                "value": 2.0429999828338623
            },
            {
                "time": "Mon, Apr 8, 8:22 PM",
                "value": 2.0429999828338623
            },
            {
                "time": "Mon, Apr 8, 8:23 PM",
                "value": 1.7029999494552612
            },
            {
                "time": "Mon, Apr 8, 8:23 PM",
                "value": 1.7029999494552612
            },
            {
                "time": "Mon, Apr 8, 8:24 PM",
                "value": 1.7029999494552612
            },
            {
                "time": "Mon, Apr 8, 8:24 PM",
                "value": 1.7029999494552612
            },
            {
                "time": "Mon, Apr 8, 8:25 PM",
                "value": 1.5889999866485596
            },
            {
                "time": "Mon, Apr 8, 8:25 PM",
                "value": 1.3619999885559082
            },
            {
                "time": "Mon, Apr 8, 8:26 PM",
                "value": 1.3619999885559082
            },
            {
                "time": "Mon, Apr 8, 8:26 PM",
                "value": 1.3619999885559082
            },
            {
                "time": "Mon, Apr 8, 8:27 PM",
                "value": 1.1349999904632568
            },
            {
                "time": "Mon, Apr 8, 8:27 PM",
                "value": 1.0219999551773071
            },
            {
                "time": "Mon, Apr 8, 8:28 PM",
                "value": 1.0219999551773071
            },
            {
                "time": "Mon, Apr 8, 8:28 PM",
                "value": 1.0219999551773071
            },
            {
                "time": "Mon, Apr 8, 8:29 PM",
                "value": 1.0219999551773071
            },
            {
                "time": "Mon, Apr 8, 8:29 PM",
                "value": 0.6809999942779541
            },
            {
                "time": "Mon, Apr 8, 8:30 PM",
                "value": 0.6809999942779541
            },
            {
                "time": "Mon, Apr 8, 8:30 PM",
                "value": 0.6809999942779541
            },
            {
                "time": "Mon, Apr 8, 8:31 PM",
                "value": 0.6809999942779541
            },
            {
                "time": "Mon, Apr 8, 8:31 PM",
                "value": 0.6809999942779541
            },
            {
                "time": "Mon, Apr 8, 8:32 PM",
                "value": 0.6809999942779541
            },
            {
                "time": "Mon, Apr 8, 8:32 PM",
                "value": 0.6809999942779541
            },
            {
                "time": "Mon, Apr 8, 8:33 PM",
                "value": 0.3409999907016754
            },
            {
                "time": "Mon, Apr 8, 8:33 PM",
                "value": 0.3409999907016754
            },
            {
                "time": "Mon, Apr 8, 8:34 PM",
                "value": 0.3409999907016754
            },
            {
                "time": "Mon, Apr 8, 8:34 PM",
                "value": 0.3409999907016754
            },
            {
                "time": "Mon, Apr 8, 8:35 PM",
                "value": 0.3409999907016754
            },
            {
                "time": "Mon, Apr 8, 8:35 PM",
                "value": 0.3409999907016754
            },
            {
                "time": "Mon, Apr 8, 8:36 PM",
                "value": 0.3409999907016754
            },
            {
                "time": "Mon, Apr 8, 8:36 PM",
                "value": 0.3409999907016754
            },
            {
                "time": "Mon, Apr 8, 8:37 PM",
                "value": 0.3409999907016754
            },
            {
                "time": "Mon, Apr 8, 8:37 PM",
                "value": 0.3409999907016754
            },
            {
                "time": "Mon, Apr 8, 8:38 PM",
                "value": 0.3409999907016754
            },
            {
                "time": "Mon, Apr 8, 8:38 PM",
                "value": 0.3409999907016754
            },
            {
                "time": "Mon, Apr 8, 8:39 PM",
                "value": 0.3409999907016754
            },
            {
                "time": "Mon, Apr 8, 8:39 PM",
                "value": 0.3409999907016754
            },
            {
                "time": "Mon, Apr 8, 8:40 PM",
                "value": 0.3409999907016754
            },
            {
                "time": "Mon, Apr 8, 8:40 PM",
                "value": 0.11400000005960464
            },
            {
                "time": "Mon, Apr 8, 8:41 PM",
                "value": 0.11400000005960464
            },
            {
                "time": "Mon, Apr 8, 8:41 PM",
                "value": 0.11400000005960464
            },
            {
                "time": "Mon, Apr 8, 8:42 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:42 PM",
                "value": 0.11400000005960464
            },
            {
                "time": "Mon, Apr 8, 8:43 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:43 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:44 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:44 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:45 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:45 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:46 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:46 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:47 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:47 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:48 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:48 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:49 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:49 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:50 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:50 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:51 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:51 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:52 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:52 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:53 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:53 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:54 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:54 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:55 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:55 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:56 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:56 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:57 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:57 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:58 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:58 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:59 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:59 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:00 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:00 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:01 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:01 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:02 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:02 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:03 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:03 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:04 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:04 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:05 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:05 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:06 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:06 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:07 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:07 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:08 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:08 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:09 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:09 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:10 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:10 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:11 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:11 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:12 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:12 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:13 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:13 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:14 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:14 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:15 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:15 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:16 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:16 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:17 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:17 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:18 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:18 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:19 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:19 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:20 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:20 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:21 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:21 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:22 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:22 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:23 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:23 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:24 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:24 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:25 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:25 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:26 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:26 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:27 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:27 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:28 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:28 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:29 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:29 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:30 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:30 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:31 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:31 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:32 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:32 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:33 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:33 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:34 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:34 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:35 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:35 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:36 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:36 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:37 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:37 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:38 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:38 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:39 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:39 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:40 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:40 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:41 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:41 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:42 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:42 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:43 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:43 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:44 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:44 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:45 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:45 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:46 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:46 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:47 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:47 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:48 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:48 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:49 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:49 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:50 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:50 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:51 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:51 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:52 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:52 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:53 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:53 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:54 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:54 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:55 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:55 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:56 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:56 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:57 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:57 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:58 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:58 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:59 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:59 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:00 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:00 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:01 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:01 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:02 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:02 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:03 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:03 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:04 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:04 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:05 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:05 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:06 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:06 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:07 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:07 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:08 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:08 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:09 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:09 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:10 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:10 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:11 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:11 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:12 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:12 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:13 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:13 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:14 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:14 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:15 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:15 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:16 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:16 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:17 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:17 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:18 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:18 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:19 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:19 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:20 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:20 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:21 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:21 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:22 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:22 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:23 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:23 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:24 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:24 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:25 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:25 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:26 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:26 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:27 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:27 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:28 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:28 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:29 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:29 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:30 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:30 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:31 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:31 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:32 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:32 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:33 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:33 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:34 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:34 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:35 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:35 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:36 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:36 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:37 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:37 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:38 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:38 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:39 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:39 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:40 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:40 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:41 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:41 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:42 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:42 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:43 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:43 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:44 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:44 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:45 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:45 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:46 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:46 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:47 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:47 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:48 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:48 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:49 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:49 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:50 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:50 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:51 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:51 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:52 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:52 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:53 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:53 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:54 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:54 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:55 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:55 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:56 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:56 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:57 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:57 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:58 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:58 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:59 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:59 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:00 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:00 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:01 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:01 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:02 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:02 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:03 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:03 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:04 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:04 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:05 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:05 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:06 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:06 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:07 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:07 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:08 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:08 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:09 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:09 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:10 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:10 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:11 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:11 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:12 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:12 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:13 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:13 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:14 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:14 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:15 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:15 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:16 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:16 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:17 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:17 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:18 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:18 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:19 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:19 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:20 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:20 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:21 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:21 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:22 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:22 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:23 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:23 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:24 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:24 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:25 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:25 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:26 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:26 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:27 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:27 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:28 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:28 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:29 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:29 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:30 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:30 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:31 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:31 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:32 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:32 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:33 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:33 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:34 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:34 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:35 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:35 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:36 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:36 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:37 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:37 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:38 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:38 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:39 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:39 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:40 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:40 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:41 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:41 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:42 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:42 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:43 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:43 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:44 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:44 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:45 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:45 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:46 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:46 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:47 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:47 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:48 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:48 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:49 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:49 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:50 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:50 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:51 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:51 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:52 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:52 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:53 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:53 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:54 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:54 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:55 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:55 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:56 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:56 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:57 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:57 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:58 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:58 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:59 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:59 PM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:00 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:00 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:01 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:01 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:02 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:02 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:03 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:03 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:04 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:04 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:05 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:05 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:06 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:06 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:07 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:07 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:08 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:08 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:09 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:09 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:10 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:10 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:11 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:11 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:12 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:12 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:13 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:13 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:14 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:14 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:15 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:15 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:16 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:16 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:17 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:17 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:18 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:18 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:19 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:19 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:20 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:20 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:21 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:21 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:22 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:22 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:23 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:23 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:24 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:24 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:25 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:25 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:26 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:26 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:27 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:27 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:28 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:28 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:29 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:29 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:30 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:30 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:31 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:31 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:32 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:32 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:33 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:33 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:34 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:34 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:35 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:35 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:36 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:36 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:37 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:37 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:38 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:38 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:39 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:39 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:40 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:40 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:41 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:41 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:42 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:42 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:43 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:43 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:44 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:44 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:45 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:45 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:46 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:46 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:47 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:47 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:48 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:48 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:49 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:49 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:50 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:50 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:51 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:51 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:52 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:52 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:53 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:53 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:54 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:54 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:55 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:55 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:56 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:56 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:57 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:57 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:58 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:58 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:59 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:59 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:00 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:00 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:01 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:01 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:02 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:02 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:03 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:03 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:04 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:04 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:05 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:05 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:06 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:06 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:07 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:07 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:08 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:08 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:09 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:09 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:10 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:10 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:11 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:11 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:12 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:12 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:13 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:13 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:14 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:14 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:15 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:15 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:16 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:16 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:17 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:17 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:18 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:18 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:19 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:19 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:20 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:20 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:21 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:21 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:22 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:22 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:23 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:23 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:24 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:24 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:25 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:25 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:26 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:26 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:27 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:27 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:28 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:28 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:29 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:29 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:30 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:30 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:31 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:31 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:32 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:32 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:33 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:33 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:34 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:34 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:35 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:35 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:36 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:36 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:37 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:37 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:38 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:38 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:39 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:39 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:40 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:40 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:41 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:41 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:42 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:42 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:43 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:43 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:44 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:44 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:45 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:45 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:46 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:46 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:47 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:47 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:48 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:48 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:49 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:49 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:50 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:50 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:51 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:51 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:52 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:52 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:53 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:53 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:54 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:54 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:55 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:55 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:56 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:56 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:57 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:57 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:58 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:58 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:59 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:59 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:00 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:00 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:01 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:01 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:02 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:02 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:03 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:03 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:04 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:04 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:05 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:05 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:06 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:06 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:07 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:07 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:08 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:08 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:09 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:09 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:10 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:10 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:11 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:11 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:12 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:12 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:13 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:13 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:14 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:14 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:15 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:15 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:16 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:16 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:17 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:17 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:18 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:18 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:19 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:19 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:20 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:20 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:21 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:21 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:22 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:22 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:23 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:23 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:24 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:24 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:25 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:25 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:26 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:26 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:27 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:27 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:28 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:28 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:29 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:29 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:30 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:30 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:31 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:31 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:32 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:32 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:33 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:33 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:34 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:34 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:35 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:35 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:36 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:36 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:37 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:37 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:38 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:38 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:39 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:39 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:40 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:40 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:41 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:41 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:42 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:42 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:43 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:43 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:44 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:44 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:45 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:45 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:46 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:46 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:47 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:47 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:48 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:48 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:49 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:49 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:50 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:50 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:51 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:51 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:52 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:52 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:53 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:53 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:54 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:54 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:55 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:55 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:56 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:56 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:57 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:57 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:58 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:58 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:59 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:59 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:00 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:00 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:01 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:01 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:02 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:02 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:03 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:03 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:04 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:04 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:05 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:05 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:06 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:06 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:07 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:07 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:08 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:08 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:09 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:09 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:10 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:10 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:11 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:11 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:12 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:12 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:13 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:13 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:14 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:14 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:15 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:15 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:16 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:16 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:17 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:17 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:18 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:18 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:19 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:19 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:20 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:20 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:21 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:21 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:22 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:22 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:23 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:23 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:24 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:24 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:25 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:25 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:26 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:26 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:27 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:27 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:28 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:28 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:29 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:29 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:30 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:30 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:31 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:31 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:32 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:32 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:33 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:33 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:34 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:34 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:35 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:35 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:36 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:36 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:37 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:37 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:38 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:38 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:39 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:39 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:40 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:40 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:41 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:41 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:42 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:42 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:43 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:43 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:44 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:44 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:45 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:45 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:46 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:46 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:47 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:47 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:48 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:48 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:49 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:49 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:50 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:50 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:51 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:51 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:52 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:52 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:53 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:53 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:54 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:54 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:55 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:55 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:56 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:56 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:57 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:57 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:58 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:58 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:59 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:59 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:00 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:00 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:01 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:01 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:02 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:02 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:03 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:03 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:04 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:04 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:05 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:05 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:06 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:06 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:07 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:07 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:08 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:08 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:09 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:09 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:10 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:10 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:11 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:11 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:12 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:12 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:13 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:13 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:14 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:14 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:15 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:15 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:16 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:16 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:17 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:17 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:18 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:18 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:19 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:19 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:20 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:20 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:21 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:21 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:22 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:22 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:23 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:23 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:24 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:24 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:25 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:25 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:26 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:26 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:27 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:27 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:28 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:28 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:29 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:29 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:30 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:30 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:31 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:31 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:32 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:32 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:33 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:33 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:34 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:34 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:35 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:35 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:36 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:36 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:37 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:37 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:38 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:38 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:39 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:39 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:40 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:40 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:41 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:41 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:42 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:42 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:43 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:43 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:44 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:44 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:45 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:45 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:46 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:46 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:47 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:47 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:48 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:48 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:49 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:49 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:50 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:50 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:51 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:51 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:52 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:52 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:53 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:53 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:54 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:54 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:55 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:55 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:56 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:56 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:57 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:57 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:58 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:58 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:59 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:59 AM",
                "value": 0
            }
        ],
        "temp": [
            {
                "time": "Mon, Apr 8, 5:00 AM",
                "value": 47.93000030517578
            },
            {
                "time": "Mon, Apr 8, 5:00 AM",
                "value": 47.86000061035156
            },
            {
                "time": "Mon, Apr 8, 5:01 AM",
                "value": 47.77000045776367
            },
            {
                "time": "Mon, Apr 8, 5:01 AM",
                "value": 47.75
            },
            {
                "time": "Mon, Apr 8, 5:02 AM",
                "value": 47.75
            },
            {
                "time": "Mon, Apr 8, 5:02 AM",
                "value": 47.810001373291016
            },
            {
                "time": "Mon, Apr 8, 5:03 AM",
                "value": 47.9900016784668
            },
            {
                "time": "Mon, Apr 8, 5:03 AM",
                "value": 48.119998931884766
            },
            {
                "time": "Mon, Apr 8, 5:04 AM",
                "value": 48.369998931884766
            },
            {
                "time": "Mon, Apr 8, 5:04 AM",
                "value": 48.54999923706055
            },
            {
                "time": "Mon, Apr 8, 5:05 AM",
                "value": 48.75
            },
            {
                "time": "Mon, Apr 8, 5:05 AM",
                "value": 48.880001068115234
            },
            {
                "time": "Mon, Apr 8, 5:06 AM",
                "value": 48.72999954223633
            },
            {
                "time": "Mon, Apr 8, 5:06 AM",
                "value": 48.54999923706055
            },
            {
                "time": "Mon, Apr 8, 5:07 AM",
                "value": 48.439998626708984
            },
            {
                "time": "Mon, Apr 8, 5:07 AM",
                "value": 48.349998474121094
            },
            {
                "time": "Mon, Apr 8, 5:08 AM",
                "value": 48.349998474121094
            },
            {
                "time": "Mon, Apr 8, 5:08 AM",
                "value": 48.29999923706055
            },
            {
                "time": "Mon, Apr 8, 5:09 AM",
                "value": 48.16999816894531
            },
            {
                "time": "Mon, Apr 8, 5:09 AM",
                "value": 47.970001220703125
            },
            {
                "time": "Mon, Apr 8, 5:10 AM",
                "value": 47.93000030517578
            },
            {
                "time": "Mon, Apr 8, 5:10 AM",
                "value": 47.970001220703125
            },
            {
                "time": "Mon, Apr 8, 5:11 AM",
                "value": 48.16999816894531
            },
            {
                "time": "Mon, Apr 8, 5:11 AM",
                "value": 48.33000183105469
            },
            {
                "time": "Mon, Apr 8, 5:12 AM",
                "value": 48.41999816894531
            },
            {
                "time": "Mon, Apr 8, 5:12 AM",
                "value": 48.56999969482422
            },
            {
                "time": "Mon, Apr 8, 5:13 AM",
                "value": 48.369998931884766
            },
            {
                "time": "Mon, Apr 8, 5:13 AM",
                "value": 48.220001220703125
            },
            {
                "time": "Mon, Apr 8, 5:14 AM",
                "value": 48.08000183105469
            },
            {
                "time": "Mon, Apr 8, 5:14 AM",
                "value": 48.279998779296875
            },
            {
                "time": "Mon, Apr 8, 5:15 AM",
                "value": 48.349998474121094
            },
            {
                "time": "Mon, Apr 8, 5:15 AM",
                "value": 48.38999938964844
            },
            {
                "time": "Mon, Apr 8, 5:16 AM",
                "value": 48.59000015258789
            },
            {
                "time": "Mon, Apr 8, 5:16 AM",
                "value": 48.84000015258789
            },
            {
                "time": "Mon, Apr 8, 5:17 AM",
                "value": 49.02000045776367
            },
            {
                "time": "Mon, Apr 8, 5:17 AM",
                "value": 49.02000045776367
            },
            {
                "time": "Mon, Apr 8, 5:18 AM",
                "value": 48.880001068115234
            },
            {
                "time": "Mon, Apr 8, 5:18 AM",
                "value": 48.61000061035156
            },
            {
                "time": "Mon, Apr 8, 5:19 AM",
                "value": 48.439998626708984
            },
            {
                "time": "Mon, Apr 8, 5:19 AM",
                "value": 48.189998626708984
            },
            {
                "time": "Mon, Apr 8, 5:20 AM",
                "value": 48.13999938964844
            },
            {
                "time": "Mon, Apr 8, 5:20 AM",
                "value": 47.86000061035156
            },
            {
                "time": "Mon, Apr 8, 5:21 AM",
                "value": 47.83000183105469
            },
            {
                "time": "Mon, Apr 8, 5:21 AM",
                "value": 47.900001525878906
            },
            {
                "time": "Mon, Apr 8, 5:22 AM",
                "value": 47.95000076293945
            },
            {
                "time": "Mon, Apr 8, 5:22 AM",
                "value": 47.790000915527344
            },
            {
                "time": "Mon, Apr 8, 5:23 AM",
                "value": 47.65999984741211
            },
            {
                "time": "Mon, Apr 8, 5:23 AM",
                "value": 47.56999969482422
            },
            {
                "time": "Mon, Apr 8, 5:24 AM",
                "value": 47.459999084472656
            },
            {
                "time": "Mon, Apr 8, 5:24 AM",
                "value": 47.310001373291016
            },
            {
                "time": "Mon, Apr 8, 5:25 AM",
                "value": 47.349998474121094
            },
            {
                "time": "Mon, Apr 8, 5:25 AM",
                "value": 47.43000030517578
            },
            {
                "time": "Mon, Apr 8, 5:26 AM",
                "value": 47.529998779296875
            },
            {
                "time": "Mon, Apr 8, 5:26 AM",
                "value": 47.790000915527344
            },
            {
                "time": "Mon, Apr 8, 5:27 AM",
                "value": 47.9900016784668
            },
            {
                "time": "Mon, Apr 8, 5:27 AM",
                "value": 47.91999816894531
            },
            {
                "time": "Mon, Apr 8, 5:28 AM",
                "value": 47.790000915527344
            },
            {
                "time": "Mon, Apr 8, 5:28 AM",
                "value": 47.880001068115234
            },
            {
                "time": "Mon, Apr 8, 5:29 AM",
                "value": 48.08000183105469
            },
            {
                "time": "Mon, Apr 8, 5:29 AM",
                "value": 47.86000061035156
            },
            {
                "time": "Mon, Apr 8, 5:30 AM",
                "value": 47.63999938964844
            },
            {
                "time": "Mon, Apr 8, 5:30 AM",
                "value": 47.5
            },
            {
                "time": "Mon, Apr 8, 5:31 AM",
                "value": 47.47999954223633
            },
            {
                "time": "Mon, Apr 8, 5:31 AM",
                "value": 47.43000030517578
            },
            {
                "time": "Mon, Apr 8, 5:32 AM",
                "value": 47.33000183105469
            },
            {
                "time": "Mon, Apr 8, 5:32 AM",
                "value": 47.310001373291016
            },
            {
                "time": "Mon, Apr 8, 5:33 AM",
                "value": 47.2400016784668
            },
            {
                "time": "Mon, Apr 8, 5:33 AM",
                "value": 47.310001373291016
            },
            {
                "time": "Mon, Apr 8, 5:34 AM",
                "value": 47.369998931884766
            },
            {
                "time": "Mon, Apr 8, 5:34 AM",
                "value": 47.38999938964844
            },
            {
                "time": "Mon, Apr 8, 5:35 AM",
                "value": 47.369998931884766
            },
            {
                "time": "Mon, Apr 8, 5:35 AM",
                "value": 47.40999984741211
            },
            {
                "time": "Mon, Apr 8, 5:36 AM",
                "value": 47.33000183105469
            },
            {
                "time": "Mon, Apr 8, 5:36 AM",
                "value": 47.279998779296875
            },
            {
                "time": "Mon, Apr 8, 5:37 AM",
                "value": 47.279998779296875
            },
            {
                "time": "Mon, Apr 8, 5:37 AM",
                "value": 47.2599983215332
            },
            {
                "time": "Mon, Apr 8, 5:38 AM",
                "value": 47.2400016784668
            },
            {
                "time": "Mon, Apr 8, 5:38 AM",
                "value": 47.16999816894531
            },
            {
                "time": "Mon, Apr 8, 5:39 AM",
                "value": 47.16999816894531
            },
            {
                "time": "Mon, Apr 8, 5:39 AM",
                "value": 47.209999084472656
            },
            {
                "time": "Mon, Apr 8, 5:40 AM",
                "value": 47.40999984741211
            },
            {
                "time": "Mon, Apr 8, 5:40 AM",
                "value": 47.43000030517578
            },
            {
                "time": "Mon, Apr 8, 5:41 AM",
                "value": 47.38999938964844
            },
            {
                "time": "Mon, Apr 8, 5:41 AM",
                "value": 47.349998474121094
            },
            {
                "time": "Mon, Apr 8, 5:42 AM",
                "value": 47.33000183105469
            },
            {
                "time": "Mon, Apr 8, 5:42 AM",
                "value": 47.349998474121094
            },
            {
                "time": "Mon, Apr 8, 5:43 AM",
                "value": 47.38999938964844
            },
            {
                "time": "Mon, Apr 8, 5:43 AM",
                "value": 47.369998931884766
            },
            {
                "time": "Mon, Apr 8, 5:44 AM",
                "value": 47.43000030517578
            },
            {
                "time": "Mon, Apr 8, 5:44 AM",
                "value": 47.38999938964844
            },
            {
                "time": "Mon, Apr 8, 5:45 AM",
                "value": 47.5
            },
            {
                "time": "Mon, Apr 8, 5:45 AM",
                "value": 47.68000030517578
            },
            {
                "time": "Mon, Apr 8, 5:46 AM",
                "value": 47.56999969482422
            },
            {
                "time": "Mon, Apr 8, 5:46 AM",
                "value": 47.529998779296875
            },
            {
                "time": "Mon, Apr 8, 5:47 AM",
                "value": 47.47999954223633
            },
            {
                "time": "Mon, Apr 8, 5:47 AM",
                "value": 47.619998931884766
            },
            {
                "time": "Mon, Apr 8, 5:48 AM",
                "value": 47.880001068115234
            },
            {
                "time": "Mon, Apr 8, 5:48 AM",
                "value": 47.61000061035156
            },
            {
                "time": "Mon, Apr 8, 5:49 AM",
                "value": 47.43000030517578
            },
            {
                "time": "Mon, Apr 8, 5:49 AM",
                "value": 47.38999938964844
            },
            {
                "time": "Mon, Apr 8, 5:50 AM",
                "value": 47.349998474121094
            },
            {
                "time": "Mon, Apr 8, 5:50 AM",
                "value": 47.38999938964844
            },
            {
                "time": "Mon, Apr 8, 5:51 AM",
                "value": 47.61000061035156
            },
            {
                "time": "Mon, Apr 8, 5:51 AM",
                "value": 47.720001220703125
            },
            {
                "time": "Mon, Apr 8, 5:52 AM",
                "value": 47.84000015258789
            },
            {
                "time": "Mon, Apr 8, 5:52 AM",
                "value": 47.83000183105469
            },
            {
                "time": "Mon, Apr 8, 5:53 AM",
                "value": 47.75
            },
            {
                "time": "Mon, Apr 8, 5:53 AM",
                "value": 47.900001525878906
            },
            {
                "time": "Mon, Apr 8, 5:54 AM",
                "value": 48.08000183105469
            },
            {
                "time": "Mon, Apr 8, 5:54 AM",
                "value": 48.040000915527344
            },
            {
                "time": "Mon, Apr 8, 5:55 AM",
                "value": 48.31999969482422
            },
            {
                "time": "Mon, Apr 8, 5:55 AM",
                "value": 48.38999938964844
            },
            {
                "time": "Mon, Apr 8, 5:56 AM",
                "value": 48.41999816894531
            },
            {
                "time": "Mon, Apr 8, 5:56 AM",
                "value": 48.2599983215332
            },
            {
                "time": "Mon, Apr 8, 5:57 AM",
                "value": 48.439998626708984
            },
            {
                "time": "Mon, Apr 8, 5:57 AM",
                "value": 48.41999816894531
            },
            {
                "time": "Mon, Apr 8, 5:58 AM",
                "value": 48.54999923706055
            },
            {
                "time": "Mon, Apr 8, 5:58 AM",
                "value": 48.63999938964844
            },
            {
                "time": "Mon, Apr 8, 5:59 AM",
                "value": 48.5
            },
            {
                "time": "Mon, Apr 8, 5:59 AM",
                "value": 48.439998626708984
            },
            {
                "time": "Mon, Apr 8, 6:00 AM",
                "value": 48.47999954223633
            },
            {
                "time": "Mon, Apr 8, 6:00 AM",
                "value": 48.619998931884766
            },
            {
                "time": "Mon, Apr 8, 6:01 AM",
                "value": 48.439998626708984
            },
            {
                "time": "Mon, Apr 8, 6:01 AM",
                "value": 48.31999969482422
            },
            {
                "time": "Mon, Apr 8, 6:02 AM",
                "value": 48.439998626708984
            },
            {
                "time": "Mon, Apr 8, 6:02 AM",
                "value": 48.56999969482422
            },
            {
                "time": "Mon, Apr 8, 6:03 AM",
                "value": 48.33000183105469
            },
            {
                "time": "Mon, Apr 8, 6:03 AM",
                "value": 48.0099983215332
            },
            {
                "time": "Mon, Apr 8, 6:04 AM",
                "value": 48.060001373291016
            },
            {
                "time": "Mon, Apr 8, 6:04 AM",
                "value": 48.59000015258789
            },
            {
                "time": "Mon, Apr 8, 6:05 AM",
                "value": 49.130001068115234
            },
            {
                "time": "Mon, Apr 8, 6:05 AM",
                "value": 49.130001068115234
            },
            {
                "time": "Mon, Apr 8, 6:06 AM",
                "value": 49.2400016784668
            },
            {
                "time": "Mon, Apr 8, 6:06 AM",
                "value": 49.2400016784668
            },
            {
                "time": "Mon, Apr 8, 6:07 AM",
                "value": 49.16999816894531
            },
            {
                "time": "Mon, Apr 8, 6:07 AM",
                "value": 49.279998779296875
            },
            {
                "time": "Mon, Apr 8, 6:08 AM",
                "value": 49.310001373291016
            },
            {
                "time": "Mon, Apr 8, 6:08 AM",
                "value": 49.400001525878906
            },
            {
                "time": "Mon, Apr 8, 6:09 AM",
                "value": 49.439998626708984
            },
            {
                "time": "Mon, Apr 8, 6:09 AM",
                "value": 49.54999923706055
            },
            {
                "time": "Mon, Apr 8, 6:10 AM",
                "value": 49.529998779296875
            },
            {
                "time": "Mon, Apr 8, 6:10 AM",
                "value": 49.56999969482422
            },
            {
                "time": "Mon, Apr 8, 6:11 AM",
                "value": 49.470001220703125
            },
            {
                "time": "Mon, Apr 8, 6:11 AM",
                "value": 49.56999969482422
            },
            {
                "time": "Mon, Apr 8, 6:12 AM",
                "value": 49.68000030517578
            },
            {
                "time": "Mon, Apr 8, 6:12 AM",
                "value": 49.81999969482422
            },
            {
                "time": "Mon, Apr 8, 6:13 AM",
                "value": 49.779998779296875
            },
            {
                "time": "Mon, Apr 8, 6:13 AM",
                "value": 49.689998626708984
            },
            {
                "time": "Mon, Apr 8, 6:14 AM",
                "value": 49.619998931884766
            },
            {
                "time": "Mon, Apr 8, 6:14 AM",
                "value": 49.459999084472656
            },
            {
                "time": "Mon, Apr 8, 6:15 AM",
                "value": 49.54999923706055
            },
            {
                "time": "Mon, Apr 8, 6:15 AM",
                "value": 49.709999084472656
            },
            {
                "time": "Mon, Apr 8, 6:16 AM",
                "value": 49.75
            },
            {
                "time": "Mon, Apr 8, 6:16 AM",
                "value": 49.79999923706055
            },
            {
                "time": "Mon, Apr 8, 6:17 AM",
                "value": 49.86000061035156
            },
            {
                "time": "Mon, Apr 8, 6:17 AM",
                "value": 49.88999938964844
            },
            {
                "time": "Mon, Apr 8, 6:18 AM",
                "value": 49.75
            },
            {
                "time": "Mon, Apr 8, 6:18 AM",
                "value": 49.56999969482422
            },
            {
                "time": "Mon, Apr 8, 6:19 AM",
                "value": 49.38999938964844
            },
            {
                "time": "Mon, Apr 8, 6:19 AM",
                "value": 49.130001068115234
            },
            {
                "time": "Mon, Apr 8, 6:20 AM",
                "value": 48.90999984741211
            },
            {
                "time": "Mon, Apr 8, 6:20 AM",
                "value": 48.79999923706055
            },
            {
                "time": "Mon, Apr 8, 6:21 AM",
                "value": 48.79999923706055
            },
            {
                "time": "Mon, Apr 8, 6:21 AM",
                "value": 48.689998626708984
            },
            {
                "time": "Mon, Apr 8, 6:22 AM",
                "value": 48.41999816894531
            },
            {
                "time": "Mon, Apr 8, 6:22 AM",
                "value": 48.47999954223633
            },
            {
                "time": "Mon, Apr 8, 6:23 AM",
                "value": 48.349998474121094
            },
            {
                "time": "Mon, Apr 8, 6:23 AM",
                "value": 48.369998931884766
            },
            {
                "time": "Mon, Apr 8, 6:24 AM",
                "value": 48.38999938964844
            },
            {
                "time": "Mon, Apr 8, 6:24 AM",
                "value": 48.29999923706055
            },
            {
                "time": "Mon, Apr 8, 6:25 AM",
                "value": 48.369998931884766
            },
            {
                "time": "Mon, Apr 8, 6:25 AM",
                "value": 48.439998626708984
            },
            {
                "time": "Mon, Apr 8, 6:26 AM",
                "value": 48.689998626708984
            },
            {
                "time": "Mon, Apr 8, 6:26 AM",
                "value": 48.90999984741211
            },
            {
                "time": "Mon, Apr 8, 6:27 AM",
                "value": 48.95000076293945
            },
            {
                "time": "Mon, Apr 8, 6:27 AM",
                "value": 49.099998474121094
            },
            {
                "time": "Mon, Apr 8, 6:28 AM",
                "value": 49.150001525878906
            },
            {
                "time": "Mon, Apr 8, 6:28 AM",
                "value": 49.310001373291016
            },
            {
                "time": "Mon, Apr 8, 6:29 AM",
                "value": 49.400001525878906
            },
            {
                "time": "Mon, Apr 8, 6:29 AM",
                "value": 49.439998626708984
            },
            {
                "time": "Mon, Apr 8, 6:30 AM",
                "value": 49.290000915527344
            },
            {
                "time": "Mon, Apr 8, 6:30 AM",
                "value": 49.20000076293945
            },
            {
                "time": "Mon, Apr 8, 6:31 AM",
                "value": 49.279998779296875
            },
            {
                "time": "Mon, Apr 8, 6:31 AM",
                "value": 49.040000915527344
            },
            {
                "time": "Mon, Apr 8, 6:32 AM",
                "value": 48.81999969482422
            },
            {
                "time": "Mon, Apr 8, 6:32 AM",
                "value": 48.72999954223633
            },
            {
                "time": "Mon, Apr 8, 6:33 AM",
                "value": 48.75
            },
            {
                "time": "Mon, Apr 8, 6:33 AM",
                "value": 48.72999954223633
            },
            {
                "time": "Mon, Apr 8, 6:34 AM",
                "value": 48.65999984741211
            },
            {
                "time": "Mon, Apr 8, 6:34 AM",
                "value": 48.33000183105469
            },
            {
                "time": "Mon, Apr 8, 6:35 AM",
                "value": 48.0099983215332
            },
            {
                "time": "Mon, Apr 8, 6:35 AM",
                "value": 47.63999938964844
            },
            {
                "time": "Mon, Apr 8, 6:36 AM",
                "value": 47.38999938964844
            },
            {
                "time": "Mon, Apr 8, 6:36 AM",
                "value": 47.68000030517578
            },
            {
                "time": "Mon, Apr 8, 6:37 AM",
                "value": 48.150001525878906
            },
            {
                "time": "Mon, Apr 8, 6:37 AM",
                "value": 48.189998626708984
            },
            {
                "time": "Mon, Apr 8, 6:38 AM",
                "value": 48.119998931884766
            },
            {
                "time": "Mon, Apr 8, 6:38 AM",
                "value": 47.970001220703125
            },
            {
                "time": "Mon, Apr 8, 6:39 AM",
                "value": 47.72999954223633
            },
            {
                "time": "Mon, Apr 8, 6:39 AM",
                "value": 47.70000076293945
            },
            {
                "time": "Mon, Apr 8, 6:40 AM",
                "value": 47.93000030517578
            },
            {
                "time": "Mon, Apr 8, 6:40 AM",
                "value": 47.5
            },
            {
                "time": "Mon, Apr 8, 6:41 AM",
                "value": 48.099998474121094
            },
            {
                "time": "Mon, Apr 8, 6:41 AM",
                "value": 48.54999923706055
            },
            {
                "time": "Mon, Apr 8, 6:42 AM",
                "value": 48.95000076293945
            },
            {
                "time": "Mon, Apr 8, 6:42 AM",
                "value": 48.790000915527344
            },
            {
                "time": "Mon, Apr 8, 6:43 AM",
                "value": 48.95000076293945
            },
            {
                "time": "Mon, Apr 8, 6:43 AM",
                "value": 49.33000183105469
            },
            {
                "time": "Mon, Apr 8, 6:44 AM",
                "value": 49.41999816894531
            },
            {
                "time": "Mon, Apr 8, 6:44 AM",
                "value": 49.02000045776367
            },
            {
                "time": "Mon, Apr 8, 6:45 AM",
                "value": 48.90999984741211
            },
            {
                "time": "Mon, Apr 8, 6:45 AM",
                "value": 48.90999984741211
            },
            {
                "time": "Mon, Apr 8, 6:46 AM",
                "value": 48.97999954223633
            },
            {
                "time": "Mon, Apr 8, 6:46 AM",
                "value": 49.189998626708984
            },
            {
                "time": "Mon, Apr 8, 6:47 AM",
                "value": 49.38999938964844
            },
            {
                "time": "Mon, Apr 8, 6:47 AM",
                "value": 49.529998779296875
            },
            {
                "time": "Mon, Apr 8, 6:48 AM",
                "value": 49.41999816894531
            },
            {
                "time": "Mon, Apr 8, 6:48 AM",
                "value": 49.20000076293945
            },
            {
                "time": "Mon, Apr 8, 6:49 AM",
                "value": 49.310001373291016
            },
            {
                "time": "Mon, Apr 8, 6:49 AM",
                "value": 48.84000015258789
            },
            {
                "time": "Mon, Apr 8, 6:50 AM",
                "value": 48.68000030517578
            },
            {
                "time": "Mon, Apr 8, 6:50 AM",
                "value": 48.97999954223633
            },
            {
                "time": "Mon, Apr 8, 6:51 AM",
                "value": 49.02000045776367
            },
            {
                "time": "Mon, Apr 8, 6:51 AM",
                "value": 48.689998626708984
            },
            {
                "time": "Mon, Apr 8, 6:52 AM",
                "value": 48.84000015258789
            },
            {
                "time": "Mon, Apr 8, 6:52 AM",
                "value": 48.880001068115234
            },
            {
                "time": "Mon, Apr 8, 6:53 AM",
                "value": 48.72999954223633
            },
            {
                "time": "Mon, Apr 8, 6:53 AM",
                "value": 48.33000183105469
            },
            {
                "time": "Mon, Apr 8, 6:54 AM",
                "value": 47.970001220703125
            },
            {
                "time": "Mon, Apr 8, 6:54 AM",
                "value": 47.84000015258789
            },
            {
                "time": "Mon, Apr 8, 6:55 AM",
                "value": 47.970001220703125
            },
            {
                "time": "Mon, Apr 8, 6:55 AM",
                "value": 48.119998931884766
            },
            {
                "time": "Mon, Apr 8, 6:56 AM",
                "value": 48.060001373291016
            },
            {
                "time": "Mon, Apr 8, 6:56 AM",
                "value": 48.040000915527344
            },
            {
                "time": "Mon, Apr 8, 6:57 AM",
                "value": 48.13999938964844
            },
            {
                "time": "Mon, Apr 8, 6:57 AM",
                "value": 48.150001525878906
            },
            {
                "time": "Mon, Apr 8, 6:58 AM",
                "value": 48.08000183105469
            },
            {
                "time": "Mon, Apr 8, 6:58 AM",
                "value": 48.16999816894531
            },
            {
                "time": "Mon, Apr 8, 6:59 AM",
                "value": 48.150001525878906
            },
            {
                "time": "Mon, Apr 8, 6:59 AM",
                "value": 48.08000183105469
            },
            {
                "time": "Mon, Apr 8, 7:00 AM",
                "value": 47.880001068115234
            },
            {
                "time": "Mon, Apr 8, 7:00 AM",
                "value": 47.970001220703125
            },
            {
                "time": "Mon, Apr 8, 7:01 AM",
                "value": 47.970001220703125
            },
            {
                "time": "Mon, Apr 8, 7:01 AM",
                "value": 47.95000076293945
            },
            {
                "time": "Mon, Apr 8, 7:02 AM",
                "value": 47.52000045776367
            },
            {
                "time": "Mon, Apr 8, 7:02 AM",
                "value": 47.16999816894531
            },
            {
                "time": "Mon, Apr 8, 7:03 AM",
                "value": 47.099998474121094
            },
            {
                "time": "Mon, Apr 8, 7:03 AM",
                "value": 47.279998779296875
            },
            {
                "time": "Mon, Apr 8, 7:04 AM",
                "value": 47.43000030517578
            },
            {
                "time": "Mon, Apr 8, 7:04 AM",
                "value": 47.68000030517578
            },
            {
                "time": "Mon, Apr 8, 7:05 AM",
                "value": 47.56999969482422
            },
            {
                "time": "Mon, Apr 8, 7:05 AM",
                "value": 47.5
            },
            {
                "time": "Mon, Apr 8, 7:06 AM",
                "value": 47.72999954223633
            },
            {
                "time": "Mon, Apr 8, 7:06 AM",
                "value": 47.86000061035156
            },
            {
                "time": "Mon, Apr 8, 7:07 AM",
                "value": 47.86000061035156
            },
            {
                "time": "Mon, Apr 8, 7:07 AM",
                "value": 48.0099983215332
            },
            {
                "time": "Mon, Apr 8, 7:08 AM",
                "value": 48.119998931884766
            },
            {
                "time": "Mon, Apr 8, 7:08 AM",
                "value": 48.0099983215332
            },
            {
                "time": "Mon, Apr 8, 7:09 AM",
                "value": 48.0099983215332
            },
            {
                "time": "Mon, Apr 8, 7:09 AM",
                "value": 48.220001220703125
            },
            {
                "time": "Mon, Apr 8, 7:10 AM",
                "value": 48.189998626708984
            },
            {
                "time": "Mon, Apr 8, 7:10 AM",
                "value": 47.720001220703125
            },
            {
                "time": "Mon, Apr 8, 7:11 AM",
                "value": 47.130001068115234
            },
            {
                "time": "Mon, Apr 8, 7:11 AM",
                "value": 46.95000076293945
            },
            {
                "time": "Mon, Apr 8, 7:12 AM",
                "value": 47.130001068115234
            },
            {
                "time": "Mon, Apr 8, 7:12 AM",
                "value": 47.38999938964844
            },
            {
                "time": "Mon, Apr 8, 7:13 AM",
                "value": 47.43000030517578
            },
            {
                "time": "Mon, Apr 8, 7:13 AM",
                "value": 47.349998474121094
            },
            {
                "time": "Mon, Apr 8, 7:14 AM",
                "value": 47.43000030517578
            },
            {
                "time": "Mon, Apr 8, 7:14 AM",
                "value": 47.349998474121094
            },
            {
                "time": "Mon, Apr 8, 7:15 AM",
                "value": 47.150001525878906
            },
            {
                "time": "Mon, Apr 8, 7:15 AM",
                "value": 47.16999816894531
            },
            {
                "time": "Mon, Apr 8, 7:16 AM",
                "value": 47.22999954223633
            },
            {
                "time": "Mon, Apr 8, 7:16 AM",
                "value": 47.2599983215332
            },
            {
                "time": "Mon, Apr 8, 7:17 AM",
                "value": 47.310001373291016
            },
            {
                "time": "Mon, Apr 8, 7:17 AM",
                "value": 47.43000030517578
            },
            {
                "time": "Mon, Apr 8, 7:18 AM",
                "value": 47.459999084472656
            },
            {
                "time": "Mon, Apr 8, 7:18 AM",
                "value": 47.29999923706055
            },
            {
                "time": "Mon, Apr 8, 7:19 AM",
                "value": 47.2599983215332
            },
            {
                "time": "Mon, Apr 8, 7:19 AM",
                "value": 47.529998779296875
            },
            {
                "time": "Mon, Apr 8, 7:20 AM",
                "value": 47.54999923706055
            },
            {
                "time": "Mon, Apr 8, 7:20 AM",
                "value": 47.5
            },
            {
                "time": "Mon, Apr 8, 7:21 AM",
                "value": 47.56999969482422
            },
            {
                "time": "Mon, Apr 8, 7:21 AM",
                "value": 47.56999969482422
            },
            {
                "time": "Mon, Apr 8, 7:22 AM",
                "value": 47.75
            },
            {
                "time": "Mon, Apr 8, 7:22 AM",
                "value": 47.790000915527344
            },
            {
                "time": "Mon, Apr 8, 7:23 AM",
                "value": 47.880001068115234
            },
            {
                "time": "Mon, Apr 8, 7:23 AM",
                "value": 47.56999969482422
            },
            {
                "time": "Mon, Apr 8, 7:24 AM",
                "value": 47.720001220703125
            },
            {
                "time": "Mon, Apr 8, 7:24 AM",
                "value": 47.880001068115234
            },
            {
                "time": "Mon, Apr 8, 7:25 AM",
                "value": 47.93000030517578
            },
            {
                "time": "Mon, Apr 8, 7:25 AM",
                "value": 48.16999816894531
            },
            {
                "time": "Mon, Apr 8, 7:26 AM",
                "value": 48.08000183105469
            },
            {
                "time": "Mon, Apr 8, 7:26 AM",
                "value": 47.91999816894531
            },
            {
                "time": "Mon, Apr 8, 7:27 AM",
                "value": 48.119998931884766
            },
            {
                "time": "Mon, Apr 8, 7:27 AM",
                "value": 48.41999816894531
            },
            {
                "time": "Mon, Apr 8, 7:28 AM",
                "value": 48.5
            },
            {
                "time": "Mon, Apr 8, 7:28 AM",
                "value": 48.56999969482422
            },
            {
                "time": "Mon, Apr 8, 7:29 AM",
                "value": 48.369998931884766
            },
            {
                "time": "Mon, Apr 8, 7:29 AM",
                "value": 48.29999923706055
            },
            {
                "time": "Mon, Apr 8, 7:30 AM",
                "value": 48.2400016784668
            },
            {
                "time": "Mon, Apr 8, 7:30 AM",
                "value": 48.150001525878906
            },
            {
                "time": "Mon, Apr 8, 7:31 AM",
                "value": 47.86000061035156
            },
            {
                "time": "Mon, Apr 8, 7:31 AM",
                "value": 47.970001220703125
            },
            {
                "time": "Mon, Apr 8, 7:32 AM",
                "value": 48.040000915527344
            },
            {
                "time": "Mon, Apr 8, 7:32 AM",
                "value": 47.900001525878906
            },
            {
                "time": "Mon, Apr 8, 7:33 AM",
                "value": 47.810001373291016
            },
            {
                "time": "Mon, Apr 8, 7:33 AM",
                "value": 48.0099983215332
            },
            {
                "time": "Mon, Apr 8, 7:34 AM",
                "value": 47.810001373291016
            },
            {
                "time": "Mon, Apr 8, 7:34 AM",
                "value": 47.54999923706055
            },
            {
                "time": "Mon, Apr 8, 7:35 AM",
                "value": 47.880001068115234
            },
            {
                "time": "Mon, Apr 8, 7:35 AM",
                "value": 47.83000183105469
            },
            {
                "time": "Mon, Apr 8, 7:36 AM",
                "value": 47.529998779296875
            },
            {
                "time": "Mon, Apr 8, 7:36 AM",
                "value": 47.2599983215332
            },
            {
                "time": "Mon, Apr 8, 7:37 AM",
                "value": 47.119998931884766
            },
            {
                "time": "Mon, Apr 8, 7:37 AM",
                "value": 47.08000183105469
            },
            {
                "time": "Mon, Apr 8, 7:38 AM",
                "value": 47.38999938964844
            },
            {
                "time": "Mon, Apr 8, 7:38 AM",
                "value": 47.459999084472656
            },
            {
                "time": "Mon, Apr 8, 7:39 AM",
                "value": 47.61000061035156
            },
            {
                "time": "Mon, Apr 8, 7:39 AM",
                "value": 47.880001068115234
            },
            {
                "time": "Mon, Apr 8, 7:40 AM",
                "value": 48.529998779296875
            },
            {
                "time": "Mon, Apr 8, 7:40 AM",
                "value": 48.59000015258789
            },
            {
                "time": "Mon, Apr 8, 7:41 AM",
                "value": 48.40999984741211
            },
            {
                "time": "Mon, Apr 8, 7:41 AM",
                "value": 48.279998779296875
            },
            {
                "time": "Mon, Apr 8, 7:42 AM",
                "value": 47.63999938964844
            },
            {
                "time": "Mon, Apr 8, 7:42 AM",
                "value": 47.130001068115234
            },
            {
                "time": "Mon, Apr 8, 7:43 AM",
                "value": 46.75
            },
            {
                "time": "Mon, Apr 8, 7:43 AM",
                "value": 47.439998626708984
            },
            {
                "time": "Mon, Apr 8, 7:44 AM",
                "value": 47.22999954223633
            },
            {
                "time": "Mon, Apr 8, 7:44 AM",
                "value": 47.38999938964844
            },
            {
                "time": "Mon, Apr 8, 7:45 AM",
                "value": 47.790000915527344
            },
            {
                "time": "Mon, Apr 8, 7:45 AM",
                "value": 47.459999084472656
            },
            {
                "time": "Mon, Apr 8, 7:46 AM",
                "value": 47.02000045776367
            },
            {
                "time": "Mon, Apr 8, 7:46 AM",
                "value": 46.91999816894531
            },
            {
                "time": "Mon, Apr 8, 7:47 AM",
                "value": 47.209999084472656
            },
            {
                "time": "Mon, Apr 8, 7:47 AM",
                "value": 47.29999923706055
            },
            {
                "time": "Mon, Apr 8, 7:48 AM",
                "value": 47.099998474121094
            },
            {
                "time": "Mon, Apr 8, 7:48 AM",
                "value": 47.529998779296875
            },
            {
                "time": "Mon, Apr 8, 7:49 AM",
                "value": 48.0099983215332
            },
            {
                "time": "Mon, Apr 8, 7:49 AM",
                "value": 47.72999954223633
            },
            {
                "time": "Mon, Apr 8, 7:50 AM",
                "value": 48.150001525878906
            },
            {
                "time": "Mon, Apr 8, 7:50 AM",
                "value": 47.63999938964844
            },
            {
                "time": "Mon, Apr 8, 7:51 AM",
                "value": 47.02000045776367
            },
            {
                "time": "Mon, Apr 8, 7:51 AM",
                "value": 47.040000915527344
            },
            {
                "time": "Mon, Apr 8, 7:52 AM",
                "value": 48.040000915527344
            },
            {
                "time": "Mon, Apr 8, 7:52 AM",
                "value": 48.790000915527344
            },
            {
                "time": "Mon, Apr 8, 7:53 AM",
                "value": 49.20000076293945
            },
            {
                "time": "Mon, Apr 8, 7:53 AM",
                "value": 49.310001373291016
            },
            {
                "time": "Mon, Apr 8, 7:54 AM",
                "value": 49.310001373291016
            },
            {
                "time": "Mon, Apr 8, 7:54 AM",
                "value": 49.38999938964844
            },
            {
                "time": "Mon, Apr 8, 7:55 AM",
                "value": 49.599998474121094
            },
            {
                "time": "Mon, Apr 8, 7:55 AM",
                "value": 49.220001220703125
            },
            {
                "time": "Mon, Apr 8, 7:56 AM",
                "value": 49.41999816894531
            },
            {
                "time": "Mon, Apr 8, 7:56 AM",
                "value": 49.060001373291016
            },
            {
                "time": "Mon, Apr 8, 7:57 AM",
                "value": 48.33000183105469
            },
            {
                "time": "Mon, Apr 8, 7:57 AM",
                "value": 48.220001220703125
            },
            {
                "time": "Mon, Apr 8, 7:58 AM",
                "value": 48.40999984741211
            },
            {
                "time": "Mon, Apr 8, 7:58 AM",
                "value": 48.5099983215332
            },
            {
                "time": "Mon, Apr 8, 7:59 AM",
                "value": 48.72999954223633
            },
            {
                "time": "Mon, Apr 8, 7:59 AM",
                "value": 49.279998779296875
            },
            {
                "time": "Mon, Apr 8, 8:00 AM",
                "value": 49.779998779296875
            },
            {
                "time": "Mon, Apr 8, 8:00 AM",
                "value": 50.310001373291016
            },
            {
                "time": "Mon, Apr 8, 8:01 AM",
                "value": 50.65999984741211
            },
            {
                "time": "Mon, Apr 8, 8:01 AM",
                "value": 50.869998931884766
            },
            {
                "time": "Mon, Apr 8, 8:02 AM",
                "value": 51.09000015258789
            },
            {
                "time": "Mon, Apr 8, 8:02 AM",
                "value": 51.20000076293945
            },
            {
                "time": "Mon, Apr 8, 8:03 AM",
                "value": 51.15999984741211
            },
            {
                "time": "Mon, Apr 8, 8:03 AM",
                "value": 51.130001068115234
            },
            {
                "time": "Mon, Apr 8, 8:04 AM",
                "value": 50.709999084472656
            },
            {
                "time": "Mon, Apr 8, 8:04 AM",
                "value": 50.400001525878906
            },
            {
                "time": "Mon, Apr 8, 8:05 AM",
                "value": 50.220001220703125
            },
            {
                "time": "Mon, Apr 8, 8:05 AM",
                "value": 50.16999816894531
            },
            {
                "time": "Mon, Apr 8, 8:06 AM",
                "value": 50.2400016784668
            },
            {
                "time": "Mon, Apr 8, 8:06 AM",
                "value": 50.02000045776367
            },
            {
                "time": "Mon, Apr 8, 8:07 AM",
                "value": 50.279998779296875
            },
            {
                "time": "Mon, Apr 8, 8:07 AM",
                "value": 50.130001068115234
            },
            {
                "time": "Mon, Apr 8, 8:08 AM",
                "value": 50.369998931884766
            },
            {
                "time": "Mon, Apr 8, 8:08 AM",
                "value": 50.869998931884766
            },
            {
                "time": "Mon, Apr 8, 8:09 AM",
                "value": 51.06999969482422
            },
            {
                "time": "Mon, Apr 8, 8:09 AM",
                "value": 51.36000061035156
            },
            {
                "time": "Mon, Apr 8, 8:10 AM",
                "value": 51.5099983215332
            },
            {
                "time": "Mon, Apr 8, 8:10 AM",
                "value": 51.36000061035156
            },
            {
                "time": "Mon, Apr 8, 8:11 AM",
                "value": 51.349998474121094
            },
            {
                "time": "Mon, Apr 8, 8:11 AM",
                "value": 51.58000183105469
            },
            {
                "time": "Mon, Apr 8, 8:12 AM",
                "value": 51.720001220703125
            },
            {
                "time": "Mon, Apr 8, 8:12 AM",
                "value": 51.619998931884766
            },
            {
                "time": "Mon, Apr 8, 8:13 AM",
                "value": 51.560001373291016
            },
            {
                "time": "Mon, Apr 8, 8:13 AM",
                "value": 51.849998474121094
            },
            {
                "time": "Mon, Apr 8, 8:14 AM",
                "value": 51.849998474121094
            },
            {
                "time": "Mon, Apr 8, 8:14 AM",
                "value": 52.06999969482422
            },
            {
                "time": "Mon, Apr 8, 8:15 AM",
                "value": 52.220001220703125
            },
            {
                "time": "Mon, Apr 8, 8:15 AM",
                "value": 52.22999954223633
            },
            {
                "time": "Mon, Apr 8, 8:16 AM",
                "value": 52.11000061035156
            },
            {
                "time": "Mon, Apr 8, 8:16 AM",
                "value": 52.029998779296875
            },
            {
                "time": "Mon, Apr 8, 8:17 AM",
                "value": 51.939998626708984
            },
            {
                "time": "Mon, Apr 8, 8:17 AM",
                "value": 51.540000915527344
            },
            {
                "time": "Mon, Apr 8, 8:18 AM",
                "value": 51.40999984741211
            },
            {
                "time": "Mon, Apr 8, 8:18 AM",
                "value": 51.40999984741211
            },
            {
                "time": "Mon, Apr 8, 8:19 AM",
                "value": 51.66999816894531
            },
            {
                "time": "Mon, Apr 8, 8:19 AM",
                "value": 51.5099983215332
            },
            {
                "time": "Mon, Apr 8, 8:20 AM",
                "value": 51.81999969482422
            },
            {
                "time": "Mon, Apr 8, 8:20 AM",
                "value": 52.02000045776367
            },
            {
                "time": "Mon, Apr 8, 8:21 AM",
                "value": 51.959999084472656
            },
            {
                "time": "Mon, Apr 8, 8:21 AM",
                "value": 52.029998779296875
            },
            {
                "time": "Mon, Apr 8, 8:22 AM",
                "value": 52
            },
            {
                "time": "Mon, Apr 8, 8:22 AM",
                "value": 52.36000061035156
            },
            {
                "time": "Mon, Apr 8, 8:23 AM",
                "value": 52.630001068115234
            },
            {
                "time": "Mon, Apr 8, 8:23 AM",
                "value": 52.79999923706055
            },
            {
                "time": "Mon, Apr 8, 8:24 AM",
                "value": 52.7400016784668
            },
            {
                "time": "Mon, Apr 8, 8:24 AM",
                "value": 53.04999923706055
            },
            {
                "time": "Mon, Apr 8, 8:25 AM",
                "value": 53.09000015258789
            },
            {
                "time": "Mon, Apr 8, 8:25 AM",
                "value": 53.15999984741211
            },
            {
                "time": "Mon, Apr 8, 8:26 AM",
                "value": 53.31999969482422
            },
            {
                "time": "Mon, Apr 8, 8:26 AM",
                "value": 53.47999954223633
            },
            {
                "time": "Mon, Apr 8, 8:27 AM",
                "value": 53.630001068115234
            },
            {
                "time": "Mon, Apr 8, 8:27 AM",
                "value": 53.810001373291016
            },
            {
                "time": "Mon, Apr 8, 8:28 AM",
                "value": 54.029998779296875
            },
            {
                "time": "Mon, Apr 8, 8:28 AM",
                "value": 53.939998626708984
            },
            {
                "time": "Mon, Apr 8, 8:29 AM",
                "value": 53.959999084472656
            },
            {
                "time": "Mon, Apr 8, 8:29 AM",
                "value": 53.9900016784668
            },
            {
                "time": "Mon, Apr 8, 8:30 AM",
                "value": 54.150001525878906
            },
            {
                "time": "Mon, Apr 8, 8:30 AM",
                "value": 54.2400016784668
            },
            {
                "time": "Mon, Apr 8, 8:31 AM",
                "value": 54.36000061035156
            },
            {
                "time": "Mon, Apr 8, 8:31 AM",
                "value": 54.38999938964844
            },
            {
                "time": "Mon, Apr 8, 8:32 AM",
                "value": 54.52000045776367
            },
            {
                "time": "Mon, Apr 8, 8:32 AM",
                "value": 54.540000915527344
            },
            {
                "time": "Mon, Apr 8, 8:33 AM",
                "value": 54.61000061035156
            },
            {
                "time": "Mon, Apr 8, 8:33 AM",
                "value": 54.84000015258789
            },
            {
                "time": "Mon, Apr 8, 8:34 AM",
                "value": 54.86000061035156
            },
            {
                "time": "Mon, Apr 8, 8:34 AM",
                "value": 54.939998626708984
            },
            {
                "time": "Mon, Apr 8, 8:35 AM",
                "value": 55.040000915527344
            },
            {
                "time": "Mon, Apr 8, 8:35 AM",
                "value": 55.130001068115234
            },
            {
                "time": "Mon, Apr 8, 8:36 AM",
                "value": 55.220001220703125
            },
            {
                "time": "Mon, Apr 8, 8:36 AM",
                "value": 55.279998779296875
            },
            {
                "time": "Mon, Apr 8, 8:37 AM",
                "value": 55.209999084472656
            },
            {
                "time": "Mon, Apr 8, 8:37 AM",
                "value": 55.310001373291016
            },
            {
                "time": "Mon, Apr 8, 8:38 AM",
                "value": 55.43000030517578
            },
            {
                "time": "Mon, Apr 8, 8:38 AM",
                "value": 55.47999954223633
            },
            {
                "time": "Mon, Apr 8, 8:39 AM",
                "value": 55.439998626708984
            },
            {
                "time": "Mon, Apr 8, 8:39 AM",
                "value": 55.59000015258789
            },
            {
                "time": "Mon, Apr 8, 8:40 AM",
                "value": 55.619998931884766
            },
            {
                "time": "Mon, Apr 8, 8:40 AM",
                "value": 55.52000045776367
            },
            {
                "time": "Mon, Apr 8, 8:41 AM",
                "value": 55.54999923706055
            },
            {
                "time": "Mon, Apr 8, 8:41 AM",
                "value": 55.33000183105469
            },
            {
                "time": "Mon, Apr 8, 8:42 AM",
                "value": 55.0099983215332
            },
            {
                "time": "Mon, Apr 8, 8:42 AM",
                "value": 54.900001525878906
            },
            {
                "time": "Mon, Apr 8, 8:43 AM",
                "value": 54.45000076293945
            },
            {
                "time": "Mon, Apr 8, 8:43 AM",
                "value": 54.13999938964844
            },
            {
                "time": "Mon, Apr 8, 8:44 AM",
                "value": 54.540000915527344
            },
            {
                "time": "Mon, Apr 8, 8:44 AM",
                "value": 54.880001068115234
            },
            {
                "time": "Mon, Apr 8, 8:45 AM",
                "value": 55.040000915527344
            },
            {
                "time": "Mon, Apr 8, 8:45 AM",
                "value": 55.16999816894531
            },
            {
                "time": "Mon, Apr 8, 8:46 AM",
                "value": 55.150001525878906
            },
            {
                "time": "Mon, Apr 8, 8:46 AM",
                "value": 54.970001220703125
            },
            {
                "time": "Mon, Apr 8, 8:47 AM",
                "value": 55.2599983215332
            },
            {
                "time": "Mon, Apr 8, 8:47 AM",
                "value": 55.38999938964844
            },
            {
                "time": "Mon, Apr 8, 8:48 AM",
                "value": 55.59000015258789
            },
            {
                "time": "Mon, Apr 8, 8:48 AM",
                "value": 55.68000030517578
            },
            {
                "time": "Mon, Apr 8, 8:49 AM",
                "value": 55.77000045776367
            },
            {
                "time": "Mon, Apr 8, 8:49 AM",
                "value": 55.77000045776367
            },
            {
                "time": "Mon, Apr 8, 8:50 AM",
                "value": 55.880001068115234
            },
            {
                "time": "Mon, Apr 8, 8:50 AM",
                "value": 55.90999984741211
            },
            {
                "time": "Mon, Apr 8, 8:51 AM",
                "value": 56.08000183105469
            },
            {
                "time": "Mon, Apr 8, 8:51 AM",
                "value": 56.040000915527344
            },
            {
                "time": "Mon, Apr 8, 8:52 AM",
                "value": 55.95000076293945
            },
            {
                "time": "Mon, Apr 8, 8:52 AM",
                "value": 55.810001373291016
            },
            {
                "time": "Mon, Apr 8, 8:53 AM",
                "value": 55.72999954223633
            },
            {
                "time": "Mon, Apr 8, 8:53 AM",
                "value": 55.90999984741211
            },
            {
                "time": "Mon, Apr 8, 8:54 AM",
                "value": 55.70000076293945
            },
            {
                "time": "Mon, Apr 8, 8:54 AM",
                "value": 55.709999084472656
            },
            {
                "time": "Mon, Apr 8, 8:55 AM",
                "value": 55.68000030517578
            },
            {
                "time": "Mon, Apr 8, 8:55 AM",
                "value": 55.70000076293945
            },
            {
                "time": "Mon, Apr 8, 8:56 AM",
                "value": 55.70000076293945
            },
            {
                "time": "Mon, Apr 8, 8:56 AM",
                "value": 55.77000045776367
            },
            {
                "time": "Mon, Apr 8, 8:57 AM",
                "value": 55.77000045776367
            },
            {
                "time": "Mon, Apr 8, 8:57 AM",
                "value": 55.65999984741211
            },
            {
                "time": "Mon, Apr 8, 8:58 AM",
                "value": 55.70000076293945
            },
            {
                "time": "Mon, Apr 8, 8:58 AM",
                "value": 55.68000030517578
            },
            {
                "time": "Mon, Apr 8, 8:59 AM",
                "value": 55.65999984741211
            },
            {
                "time": "Mon, Apr 8, 8:59 AM",
                "value": 55.619998931884766
            },
            {
                "time": "Mon, Apr 8, 9:00 AM",
                "value": 55.68000030517578
            },
            {
                "time": "Mon, Apr 8, 9:00 AM",
                "value": 55.790000915527344
            },
            {
                "time": "Mon, Apr 8, 9:01 AM",
                "value": 55.84000015258789
            },
            {
                "time": "Mon, Apr 8, 9:01 AM",
                "value": 55.81999969482422
            },
            {
                "time": "Mon, Apr 8, 9:02 AM",
                "value": 55.84000015258789
            },
            {
                "time": "Mon, Apr 8, 9:02 AM",
                "value": 55.93000030517578
            },
            {
                "time": "Mon, Apr 8, 9:03 AM",
                "value": 55.9900016784668
            },
            {
                "time": "Mon, Apr 8, 9:03 AM",
                "value": 56.130001068115234
            },
            {
                "time": "Mon, Apr 8, 9:04 AM",
                "value": 56.130001068115234
            },
            {
                "time": "Mon, Apr 8, 9:04 AM",
                "value": 56.150001525878906
            },
            {
                "time": "Mon, Apr 8, 9:05 AM",
                "value": 56.2599983215332
            },
            {
                "time": "Mon, Apr 8, 9:05 AM",
                "value": 56.310001373291016
            },
            {
                "time": "Mon, Apr 8, 9:06 AM",
                "value": 56.36000061035156
            },
            {
                "time": "Mon, Apr 8, 9:06 AM",
                "value": 56.33000183105469
            },
            {
                "time": "Mon, Apr 8, 9:07 AM",
                "value": 56.41999816894531
            },
            {
                "time": "Mon, Apr 8, 9:07 AM",
                "value": 56.63999938964844
            },
            {
                "time": "Mon, Apr 8, 9:08 AM",
                "value": 56.540000915527344
            },
            {
                "time": "Mon, Apr 8, 9:08 AM",
                "value": 56.599998474121094
            },
            {
                "time": "Mon, Apr 8, 9:09 AM",
                "value": 56.75
            },
            {
                "time": "Mon, Apr 8, 9:09 AM",
                "value": 56.779998779296875
            },
            {
                "time": "Mon, Apr 8, 9:10 AM",
                "value": 56.79999923706055
            },
            {
                "time": "Mon, Apr 8, 9:10 AM",
                "value": 56.779998779296875
            },
            {
                "time": "Mon, Apr 8, 9:11 AM",
                "value": 56.84000015258789
            },
            {
                "time": "Mon, Apr 8, 9:11 AM",
                "value": 57.11000061035156
            },
            {
                "time": "Mon, Apr 8, 9:12 AM",
                "value": 57.310001373291016
            },
            {
                "time": "Mon, Apr 8, 9:12 AM",
                "value": 57.33000183105469
            },
            {
                "time": "Mon, Apr 8, 9:13 AM",
                "value": 57.25
            },
            {
                "time": "Mon, Apr 8, 9:13 AM",
                "value": 57.2400016784668
            },
            {
                "time": "Mon, Apr 8, 9:14 AM",
                "value": 57.380001068115234
            },
            {
                "time": "Mon, Apr 8, 9:14 AM",
                "value": 57.41999816894531
            },
            {
                "time": "Mon, Apr 8, 9:15 AM",
                "value": 57.619998931884766
            },
            {
                "time": "Mon, Apr 8, 9:15 AM",
                "value": 57.709999084472656
            },
            {
                "time": "Mon, Apr 8, 9:16 AM",
                "value": 57.66999816894531
            },
            {
                "time": "Mon, Apr 8, 9:16 AM",
                "value": 57.779998779296875
            },
            {
                "time": "Mon, Apr 8, 9:17 AM",
                "value": 57.7599983215332
            },
            {
                "time": "Mon, Apr 8, 9:17 AM",
                "value": 57.72999954223633
            },
            {
                "time": "Mon, Apr 8, 9:18 AM",
                "value": 57.72999954223633
            },
            {
                "time": "Mon, Apr 8, 9:18 AM",
                "value": 57.7599983215332
            },
            {
                "time": "Mon, Apr 8, 9:19 AM",
                "value": 57.7400016784668
            },
            {
                "time": "Mon, Apr 8, 9:19 AM",
                "value": 57.90999984741211
            },
            {
                "time": "Mon, Apr 8, 9:20 AM",
                "value": 57.869998931884766
            },
            {
                "time": "Mon, Apr 8, 9:20 AM",
                "value": 57.83000183105469
            },
            {
                "time": "Mon, Apr 8, 9:21 AM",
                "value": 57.7599983215332
            },
            {
                "time": "Mon, Apr 8, 9:21 AM",
                "value": 57.619998931884766
            },
            {
                "time": "Mon, Apr 8, 9:22 AM",
                "value": 57.689998626708984
            },
            {
                "time": "Mon, Apr 8, 9:22 AM",
                "value": 57.66999816894531
            },
            {
                "time": "Mon, Apr 8, 9:23 AM",
                "value": 57.650001525878906
            },
            {
                "time": "Mon, Apr 8, 9:23 AM",
                "value": 57.779998779296875
            },
            {
                "time": "Mon, Apr 8, 9:24 AM",
                "value": 57.83000183105469
            },
            {
                "time": "Mon, Apr 8, 9:24 AM",
                "value": 57.7599983215332
            },
            {
                "time": "Mon, Apr 8, 9:25 AM",
                "value": 57.849998474121094
            },
            {
                "time": "Mon, Apr 8, 9:25 AM",
                "value": 57.97999954223633
            },
            {
                "time": "Mon, Apr 8, 9:26 AM",
                "value": 58.0099983215332
            },
            {
                "time": "Mon, Apr 8, 9:26 AM",
                "value": 58.04999923706055
            },
            {
                "time": "Mon, Apr 8, 9:27 AM",
                "value": 57.959999084472656
            },
            {
                "time": "Mon, Apr 8, 9:27 AM",
                "value": 57.939998626708984
            },
            {
                "time": "Mon, Apr 8, 9:28 AM",
                "value": 57.959999084472656
            },
            {
                "time": "Mon, Apr 8, 9:28 AM",
                "value": 58.09000015258789
            },
            {
                "time": "Mon, Apr 8, 9:29 AM",
                "value": 58.189998626708984
            },
            {
                "time": "Mon, Apr 8, 9:29 AM",
                "value": 58.27000045776367
            },
            {
                "time": "Mon, Apr 8, 9:30 AM",
                "value": 58.43000030517578
            },
            {
                "time": "Mon, Apr 8, 9:30 AM",
                "value": 58.310001373291016
            },
            {
                "time": "Mon, Apr 8, 9:31 AM",
                "value": 58.15999984741211
            },
            {
                "time": "Mon, Apr 8, 9:31 AM",
                "value": 58.11000061035156
            },
            {
                "time": "Mon, Apr 8, 9:32 AM",
                "value": 58.119998931884766
            },
            {
                "time": "Mon, Apr 8, 9:32 AM",
                "value": 58.189998626708984
            },
            {
                "time": "Mon, Apr 8, 9:33 AM",
                "value": 58.119998931884766
            },
            {
                "time": "Mon, Apr 8, 9:33 AM",
                "value": 58.15999984741211
            },
            {
                "time": "Mon, Apr 8, 9:34 AM",
                "value": 58
            },
            {
                "time": "Mon, Apr 8, 9:34 AM",
                "value": 57.869998931884766
            },
            {
                "time": "Mon, Apr 8, 9:35 AM",
                "value": 57.79999923706055
            },
            {
                "time": "Mon, Apr 8, 9:35 AM",
                "value": 57.7400016784668
            },
            {
                "time": "Mon, Apr 8, 9:36 AM",
                "value": 57.630001068115234
            },
            {
                "time": "Mon, Apr 8, 9:36 AM",
                "value": 57.529998779296875
            },
            {
                "time": "Mon, Apr 8, 9:37 AM",
                "value": 57.43000030517578
            },
            {
                "time": "Mon, Apr 8, 9:37 AM",
                "value": 57.41999816894531
            },
            {
                "time": "Mon, Apr 8, 9:38 AM",
                "value": 57.470001220703125
            },
            {
                "time": "Mon, Apr 8, 9:38 AM",
                "value": 57.45000076293945
            },
            {
                "time": "Mon, Apr 8, 9:39 AM",
                "value": 57.540000915527344
            },
            {
                "time": "Mon, Apr 8, 9:39 AM",
                "value": 57.79999923706055
            },
            {
                "time": "Mon, Apr 8, 9:40 AM",
                "value": 57.689998626708984
            },
            {
                "time": "Mon, Apr 8, 9:40 AM",
                "value": 57.779998779296875
            },
            {
                "time": "Mon, Apr 8, 9:41 AM",
                "value": 57.650001525878906
            },
            {
                "time": "Mon, Apr 8, 9:41 AM",
                "value": 57.58000183105469
            },
            {
                "time": "Mon, Apr 8, 9:42 AM",
                "value": 57.43000030517578
            },
            {
                "time": "Mon, Apr 8, 9:42 AM",
                "value": 57.5099983215332
            },
            {
                "time": "Mon, Apr 8, 9:43 AM",
                "value": 57.380001068115234
            },
            {
                "time": "Mon, Apr 8, 9:43 AM",
                "value": 57.45000076293945
            },
            {
                "time": "Mon, Apr 8, 9:44 AM",
                "value": 57.599998474121094
            },
            {
                "time": "Mon, Apr 8, 9:44 AM",
                "value": 57.58000183105469
            },
            {
                "time": "Mon, Apr 8, 9:45 AM",
                "value": 57.43000030517578
            },
            {
                "time": "Mon, Apr 8, 9:45 AM",
                "value": 57.400001525878906
            },
            {
                "time": "Mon, Apr 8, 9:46 AM",
                "value": 57.34000015258789
            },
            {
                "time": "Mon, Apr 8, 9:46 AM",
                "value": 57.4900016784668
            },
            {
                "time": "Mon, Apr 8, 9:47 AM",
                "value": 57.560001373291016
            },
            {
                "time": "Mon, Apr 8, 9:47 AM",
                "value": 57.41999816894531
            },
            {
                "time": "Mon, Apr 8, 9:48 AM",
                "value": 57.470001220703125
            },
            {
                "time": "Mon, Apr 8, 9:48 AM",
                "value": 57.540000915527344
            },
            {
                "time": "Mon, Apr 8, 9:49 AM",
                "value": 57.619998931884766
            },
            {
                "time": "Mon, Apr 8, 9:49 AM",
                "value": 57.72999954223633
            },
            {
                "time": "Mon, Apr 8, 9:50 AM",
                "value": 57.79999923706055
            },
            {
                "time": "Mon, Apr 8, 9:50 AM",
                "value": 58
            },
            {
                "time": "Mon, Apr 8, 9:51 AM",
                "value": 58.209999084472656
            },
            {
                "time": "Mon, Apr 8, 9:51 AM",
                "value": 58.380001068115234
            },
            {
                "time": "Mon, Apr 8, 9:52 AM",
                "value": 58.189998626708984
            },
            {
                "time": "Mon, Apr 8, 9:52 AM",
                "value": 58.15999984741211
            },
            {
                "time": "Mon, Apr 8, 9:53 AM",
                "value": 58.189998626708984
            },
            {
                "time": "Mon, Apr 8, 9:53 AM",
                "value": 58.13999938964844
            },
            {
                "time": "Mon, Apr 8, 9:54 AM",
                "value": 58.06999969482422
            },
            {
                "time": "Mon, Apr 8, 9:54 AM",
                "value": 58.119998931884766
            },
            {
                "time": "Mon, Apr 8, 9:55 AM",
                "value": 58.06999969482422
            },
            {
                "time": "Mon, Apr 8, 9:55 AM",
                "value": 58.34000015258789
            },
            {
                "time": "Mon, Apr 8, 9:56 AM",
                "value": 58.4900016784668
            },
            {
                "time": "Mon, Apr 8, 9:56 AM",
                "value": 58.380001068115234
            },
            {
                "time": "Mon, Apr 8, 9:57 AM",
                "value": 58.52000045776367
            },
            {
                "time": "Mon, Apr 8, 9:57 AM",
                "value": 58.52000045776367
            },
            {
                "time": "Mon, Apr 8, 9:58 AM",
                "value": 58.470001220703125
            },
            {
                "time": "Mon, Apr 8, 9:58 AM",
                "value": 58.66999816894531
            },
            {
                "time": "Mon, Apr 8, 9:59 AM",
                "value": 58.900001525878906
            },
            {
                "time": "Mon, Apr 8, 9:59 AM",
                "value": 58.810001373291016
            },
            {
                "time": "Mon, Apr 8, 10:00 AM",
                "value": 58.880001068115234
            },
            {
                "time": "Mon, Apr 8, 10:00 AM",
                "value": 58.91999816894531
            },
            {
                "time": "Mon, Apr 8, 10:01 AM",
                "value": 59.189998626708984
            },
            {
                "time": "Mon, Apr 8, 10:01 AM",
                "value": 59.209999084472656
            },
            {
                "time": "Mon, Apr 8, 10:02 AM",
                "value": 59.29999923706055
            },
            {
                "time": "Mon, Apr 8, 10:02 AM",
                "value": 59.439998626708984
            },
            {
                "time": "Mon, Apr 8, 10:03 AM",
                "value": 59.63999938964844
            },
            {
                "time": "Mon, Apr 8, 10:03 AM",
                "value": 59.459999084472656
            },
            {
                "time": "Mon, Apr 8, 10:04 AM",
                "value": 59.56999969482422
            },
            {
                "time": "Mon, Apr 8, 10:04 AM",
                "value": 59.43000030517578
            },
            {
                "time": "Mon, Apr 8, 10:05 AM",
                "value": 59.209999084472656
            },
            {
                "time": "Mon, Apr 8, 10:05 AM",
                "value": 59.369998931884766
            },
            {
                "time": "Mon, Apr 8, 10:06 AM",
                "value": 59.56999969482422
            },
            {
                "time": "Mon, Apr 8, 10:06 AM",
                "value": 59.68000030517578
            },
            {
                "time": "Mon, Apr 8, 10:07 AM",
                "value": 59.77000045776367
            },
            {
                "time": "Mon, Apr 8, 10:07 AM",
                "value": 59.810001373291016
            },
            {
                "time": "Mon, Apr 8, 10:08 AM",
                "value": 59.86000061035156
            },
            {
                "time": "Mon, Apr 8, 10:08 AM",
                "value": 59.900001525878906
            },
            {
                "time": "Mon, Apr 8, 10:09 AM",
                "value": 60.119998931884766
            },
            {
                "time": "Mon, Apr 8, 10:09 AM",
                "value": 60.08000183105469
            },
            {
                "time": "Mon, Apr 8, 10:10 AM",
                "value": 60.16999816894531
            },
            {
                "time": "Mon, Apr 8, 10:10 AM",
                "value": 60.400001525878906
            },
            {
                "time": "Mon, Apr 8, 10:11 AM",
                "value": 60.439998626708984
            },
            {
                "time": "Mon, Apr 8, 10:11 AM",
                "value": 60.189998626708984
            },
            {
                "time": "Mon, Apr 8, 10:12 AM",
                "value": 60.369998931884766
            },
            {
                "time": "Mon, Apr 8, 10:12 AM",
                "value": 60.47999954223633
            },
            {
                "time": "Mon, Apr 8, 10:13 AM",
                "value": 60.56999969482422
            },
            {
                "time": "Mon, Apr 8, 10:13 AM",
                "value": 60.63999938964844
            },
            {
                "time": "Mon, Apr 8, 10:14 AM",
                "value": 60.65999984741211
            },
            {
                "time": "Mon, Apr 8, 10:14 AM",
                "value": 60.70000076293945
            },
            {
                "time": "Mon, Apr 8, 10:15 AM",
                "value": 60.790000915527344
            },
            {
                "time": "Mon, Apr 8, 10:15 AM",
                "value": 61.06999969482422
            },
            {
                "time": "Mon, Apr 8, 10:16 AM",
                "value": 61.310001373291016
            },
            {
                "time": "Mon, Apr 8, 10:16 AM",
                "value": 61.63999938964844
            },
            {
                "time": "Mon, Apr 8, 10:17 AM",
                "value": 61.93000030517578
            },
            {
                "time": "Mon, Apr 8, 10:17 AM",
                "value": 61.90999984741211
            },
            {
                "time": "Mon, Apr 8, 10:18 AM",
                "value": 62.06999969482422
            },
            {
                "time": "Mon, Apr 8, 10:18 AM",
                "value": 62.209999084472656
            },
            {
                "time": "Mon, Apr 8, 10:19 AM",
                "value": 62.43000030517578
            },
            {
                "time": "Mon, Apr 8, 10:19 AM",
                "value": 62.56999969482422
            },
            {
                "time": "Mon, Apr 8, 10:20 AM",
                "value": 62.83000183105469
            },
            {
                "time": "Mon, Apr 8, 10:20 AM",
                "value": 62.7599983215332
            },
            {
                "time": "Mon, Apr 8, 10:21 AM",
                "value": 62.5
            },
            {
                "time": "Mon, Apr 8, 10:21 AM",
                "value": 62.36000061035156
            },
            {
                "time": "Mon, Apr 8, 10:22 AM",
                "value": 62.25
            },
            {
                "time": "Mon, Apr 8, 10:22 AM",
                "value": 62.209999084472656
            },
            {
                "time": "Mon, Apr 8, 10:23 AM",
                "value": 62.18000030517578
            },
            {
                "time": "Mon, Apr 8, 10:23 AM",
                "value": 62.540000915527344
            },
            {
                "time": "Mon, Apr 8, 10:24 AM",
                "value": 62.70000076293945
            },
            {
                "time": "Mon, Apr 8, 10:24 AM",
                "value": 62.7599983215332
            },
            {
                "time": "Mon, Apr 8, 10:25 AM",
                "value": 62.70000076293945
            },
            {
                "time": "Mon, Apr 8, 10:25 AM",
                "value": 62.61000061035156
            },
            {
                "time": "Mon, Apr 8, 10:26 AM",
                "value": 62.630001068115234
            },
            {
                "time": "Mon, Apr 8, 10:26 AM",
                "value": 62.70000076293945
            },
            {
                "time": "Mon, Apr 8, 10:27 AM",
                "value": 62.70000076293945
            },
            {
                "time": "Mon, Apr 8, 10:27 AM",
                "value": 62.7400016784668
            },
            {
                "time": "Mon, Apr 8, 10:28 AM",
                "value": 62.880001068115234
            },
            {
                "time": "Mon, Apr 8, 10:28 AM",
                "value": 62.91999816894531
            },
            {
                "time": "Mon, Apr 8, 10:29 AM",
                "value": 62.70000076293945
            },
            {
                "time": "Mon, Apr 8, 10:29 AM",
                "value": 62.61000061035156
            },
            {
                "time": "Mon, Apr 8, 10:30 AM",
                "value": 62.650001525878906
            },
            {
                "time": "Mon, Apr 8, 10:30 AM",
                "value": 62.720001220703125
            },
            {
                "time": "Mon, Apr 8, 10:31 AM",
                "value": 62.7599983215332
            },
            {
                "time": "Mon, Apr 8, 10:31 AM",
                "value": 62.540000915527344
            },
            {
                "time": "Mon, Apr 8, 10:32 AM",
                "value": 62.5
            },
            {
                "time": "Mon, Apr 8, 10:32 AM",
                "value": 62.380001068115234
            },
            {
                "time": "Mon, Apr 8, 10:33 AM",
                "value": 62.540000915527344
            },
            {
                "time": "Mon, Apr 8, 10:33 AM",
                "value": 62.540000915527344
            },
            {
                "time": "Mon, Apr 8, 10:34 AM",
                "value": 62.43000030517578
            },
            {
                "time": "Mon, Apr 8, 10:34 AM",
                "value": 62.36000061035156
            },
            {
                "time": "Mon, Apr 8, 10:35 AM",
                "value": 62.61000061035156
            },
            {
                "time": "Mon, Apr 8, 10:35 AM",
                "value": 62.61000061035156
            },
            {
                "time": "Mon, Apr 8, 10:36 AM",
                "value": 62.650001525878906
            },
            {
                "time": "Mon, Apr 8, 10:36 AM",
                "value": 62.7599983215332
            },
            {
                "time": "Mon, Apr 8, 10:37 AM",
                "value": 62.939998626708984
            },
            {
                "time": "Mon, Apr 8, 10:37 AM",
                "value": 63.119998931884766
            },
            {
                "time": "Mon, Apr 8, 10:38 AM",
                "value": 63.31999969482422
            },
            {
                "time": "Mon, Apr 8, 10:38 AM",
                "value": 63.31999969482422
            },
            {
                "time": "Mon, Apr 8, 10:39 AM",
                "value": 63.279998779296875
            },
            {
                "time": "Mon, Apr 8, 10:39 AM",
                "value": 63.31999969482422
            },
            {
                "time": "Mon, Apr 8, 10:40 AM",
                "value": 63.54999923706055
            },
            {
                "time": "Mon, Apr 8, 10:40 AM",
                "value": 63.77000045776367
            },
            {
                "time": "Mon, Apr 8, 10:41 AM",
                "value": 63.880001068115234
            },
            {
                "time": "Mon, Apr 8, 10:41 AM",
                "value": 63.75
            },
            {
                "time": "Mon, Apr 8, 10:42 AM",
                "value": 63.56999969482422
            },
            {
                "time": "Mon, Apr 8, 10:42 AM",
                "value": 63.630001068115234
            },
            {
                "time": "Mon, Apr 8, 10:43 AM",
                "value": 63.81999969482422
            },
            {
                "time": "Mon, Apr 8, 10:43 AM",
                "value": 64.05999755859375
            },
            {
                "time": "Mon, Apr 8, 10:44 AM",
                "value": 64.19999694824219
            },
            {
                "time": "Mon, Apr 8, 10:44 AM",
                "value": 64.08999633789062
            },
            {
                "time": "Mon, Apr 8, 10:45 AM",
                "value": 64.23999786376953
            },
            {
                "time": "Mon, Apr 8, 10:45 AM",
                "value": 64.33000183105469
            },
            {
                "time": "Mon, Apr 8, 10:46 AM",
                "value": 64.52999877929688
            },
            {
                "time": "Mon, Apr 8, 10:46 AM",
                "value": 64.48999786376953
            },
            {
                "time": "Mon, Apr 8, 10:47 AM",
                "value": 64.37999725341797
            },
            {
                "time": "Mon, Apr 8, 10:47 AM",
                "value": 64.52999877929688
            },
            {
                "time": "Mon, Apr 8, 10:48 AM",
                "value": 64.41999816894531
            },
            {
                "time": "Mon, Apr 8, 10:48 AM",
                "value": 64.30999755859375
            },
            {
                "time": "Mon, Apr 8, 10:49 AM",
                "value": 64.19999694824219
            },
            {
                "time": "Mon, Apr 8, 10:49 AM",
                "value": 64.26000213623047
            },
            {
                "time": "Mon, Apr 8, 10:50 AM",
                "value": 64.30999755859375
            },
            {
                "time": "Mon, Apr 8, 10:50 AM",
                "value": 64.26000213623047
            },
            {
                "time": "Mon, Apr 8, 10:51 AM",
                "value": 64.30999755859375
            },
            {
                "time": "Mon, Apr 8, 10:51 AM",
                "value": 64.12999725341797
            },
            {
                "time": "Mon, Apr 8, 10:52 AM",
                "value": 64.23999786376953
            },
            {
                "time": "Mon, Apr 8, 10:52 AM",
                "value": 64.33000183105469
            },
            {
                "time": "Mon, Apr 8, 10:53 AM",
                "value": 64.37999725341797
            },
            {
                "time": "Mon, Apr 8, 10:53 AM",
                "value": 64.23999786376953
            },
            {
                "time": "Mon, Apr 8, 10:54 AM",
                "value": 64.12999725341797
            },
            {
                "time": "Mon, Apr 8, 10:54 AM",
                "value": 64.19000244140625
            },
            {
                "time": "Mon, Apr 8, 10:55 AM",
                "value": 64.1500015258789
            },
            {
                "time": "Mon, Apr 8, 10:55 AM",
                "value": 64.16999816894531
            },
            {
                "time": "Mon, Apr 8, 10:56 AM",
                "value": 64.19999694824219
            },
            {
                "time": "Mon, Apr 8, 10:56 AM",
                "value": 64.30000305175781
            },
            {
                "time": "Mon, Apr 8, 10:57 AM",
                "value": 64.69000244140625
            },
            {
                "time": "Mon, Apr 8, 10:57 AM",
                "value": 64.95999908447266
            },
            {
                "time": "Mon, Apr 8, 10:58 AM",
                "value": 65.08999633789062
            },
            {
                "time": "Mon, Apr 8, 10:58 AM",
                "value": 65.12999725341797
            },
            {
                "time": "Mon, Apr 8, 10:59 AM",
                "value": 64.94999694824219
            },
            {
                "time": "Mon, Apr 8, 10:59 AM",
                "value": 64.77999877929688
            },
            {
                "time": "Mon, Apr 8, 11:00 AM",
                "value": 64.66999816894531
            },
            {
                "time": "Mon, Apr 8, 11:00 AM",
                "value": 64.63999938964844
            },
            {
                "time": "Mon, Apr 8, 11:01 AM",
                "value": 64.7300033569336
            },
            {
                "time": "Mon, Apr 8, 11:01 AM",
                "value": 64.66999816894531
            },
            {
                "time": "Mon, Apr 8, 11:02 AM",
                "value": 64.7699966430664
            },
            {
                "time": "Mon, Apr 8, 11:02 AM",
                "value": 64.81999969482422
            },
            {
                "time": "Mon, Apr 8, 11:03 AM",
                "value": 64.83999633789062
            },
            {
                "time": "Mon, Apr 8, 11:03 AM",
                "value": 64.70999908447266
            },
            {
                "time": "Mon, Apr 8, 11:04 AM",
                "value": 64.66000366210938
            },
            {
                "time": "Mon, Apr 8, 11:04 AM",
                "value": 64.77999877929688
            },
            {
                "time": "Mon, Apr 8, 11:05 AM",
                "value": 64.91000366210938
            },
            {
                "time": "Mon, Apr 8, 11:05 AM",
                "value": 64.86000061035156
            },
            {
                "time": "Mon, Apr 8, 11:06 AM",
                "value": 64.88999938964844
            },
            {
                "time": "Mon, Apr 8, 11:06 AM",
                "value": 65.13999938964844
            },
            {
                "time": "Mon, Apr 8, 11:07 AM",
                "value": 65.11000061035156
            },
            {
                "time": "Mon, Apr 8, 11:07 AM",
                "value": 65.25
            },
            {
                "time": "Mon, Apr 8, 11:08 AM",
                "value": 65.33000183105469
            },
            {
                "time": "Mon, Apr 8, 11:08 AM",
                "value": 65.51000213623047
            },
            {
                "time": "Mon, Apr 8, 11:09 AM",
                "value": 65.62000274658203
            },
            {
                "time": "Mon, Apr 8, 11:09 AM",
                "value": 65.77999877929688
            },
            {
                "time": "Mon, Apr 8, 11:10 AM",
                "value": 66.01000213623047
            },
            {
                "time": "Mon, Apr 8, 11:10 AM",
                "value": 66.13999938964844
            },
            {
                "time": "Mon, Apr 8, 11:11 AM",
                "value": 66.30000305175781
            },
            {
                "time": "Mon, Apr 8, 11:11 AM",
                "value": 66.30000305175781
            },
            {
                "time": "Mon, Apr 8, 11:12 AM",
                "value": 66.45999908447266
            },
            {
                "time": "Mon, Apr 8, 11:12 AM",
                "value": 66.41000366210938
            },
            {
                "time": "Mon, Apr 8, 11:13 AM",
                "value": 66.37000274658203
            },
            {
                "time": "Mon, Apr 8, 11:13 AM",
                "value": 66.30000305175781
            },
            {
                "time": "Mon, Apr 8, 11:14 AM",
                "value": 66.31999969482422
            },
            {
                "time": "Mon, Apr 8, 11:14 AM",
                "value": 66.44000244140625
            },
            {
                "time": "Mon, Apr 8, 11:15 AM",
                "value": 66.31999969482422
            },
            {
                "time": "Mon, Apr 8, 11:15 AM",
                "value": 66.33999633789062
            },
            {
                "time": "Mon, Apr 8, 11:16 AM",
                "value": 66.55999755859375
            },
            {
                "time": "Mon, Apr 8, 11:16 AM",
                "value": 66.3499984741211
            },
            {
                "time": "Mon, Apr 8, 11:17 AM",
                "value": 66.33999633789062
            },
            {
                "time": "Mon, Apr 8, 11:17 AM",
                "value": 66.4800033569336
            },
            {
                "time": "Mon, Apr 8, 11:18 AM",
                "value": 66.66000366210938
            },
            {
                "time": "Mon, Apr 8, 11:18 AM",
                "value": 66.83999633789062
            },
            {
                "time": "Mon, Apr 8, 11:19 AM",
                "value": 66.91999816894531
            },
            {
                "time": "Mon, Apr 8, 11:19 AM",
                "value": 66.7699966430664
            },
            {
                "time": "Mon, Apr 8, 11:20 AM",
                "value": 66.75
            },
            {
                "time": "Mon, Apr 8, 11:20 AM",
                "value": 66.75
            },
            {
                "time": "Mon, Apr 8, 11:21 AM",
                "value": 66.63999938964844
            },
            {
                "time": "Mon, Apr 8, 11:21 AM",
                "value": 66.73999786376953
            },
            {
                "time": "Mon, Apr 8, 11:22 AM",
                "value": 66.94999694824219
            },
            {
                "time": "Mon, Apr 8, 11:22 AM",
                "value": 67.0199966430664
            },
            {
                "time": "Mon, Apr 8, 11:23 AM",
                "value": 67.05999755859375
            },
            {
                "time": "Mon, Apr 8, 11:23 AM",
                "value": 67.1500015258789
            },
            {
                "time": "Mon, Apr 8, 11:24 AM",
                "value": 67.19999694824219
            },
            {
                "time": "Mon, Apr 8, 11:24 AM",
                "value": 67.23999786376953
            },
            {
                "time": "Mon, Apr 8, 11:25 AM",
                "value": 67.38999938964844
            },
            {
                "time": "Mon, Apr 8, 11:25 AM",
                "value": 67.41999816894531
            },
            {
                "time": "Mon, Apr 8, 11:26 AM",
                "value": 67.27999877929688
            },
            {
                "time": "Mon, Apr 8, 11:26 AM",
                "value": 67.41999816894531
            },
            {
                "time": "Mon, Apr 8, 11:27 AM",
                "value": 67.68000030517578
            },
            {
                "time": "Mon, Apr 8, 11:27 AM",
                "value": 67.70999908447266
            },
            {
                "time": "Mon, Apr 8, 11:28 AM",
                "value": 67.88999938964844
            },
            {
                "time": "Mon, Apr 8, 11:28 AM",
                "value": 67.9800033569336
            },
            {
                "time": "Mon, Apr 8, 11:29 AM",
                "value": 68.25
            },
            {
                "time": "Mon, Apr 8, 11:29 AM",
                "value": 68.36000061035156
            },
            {
                "time": "Mon, Apr 8, 11:30 AM",
                "value": 68.13999938964844
            },
            {
                "time": "Mon, Apr 8, 11:30 AM",
                "value": 68.33000183105469
            },
            {
                "time": "Mon, Apr 8, 11:31 AM",
                "value": 68.12999725341797
            },
            {
                "time": "Mon, Apr 8, 11:31 AM",
                "value": 67.94999694824219
            },
            {
                "time": "Mon, Apr 8, 11:32 AM",
                "value": 68.11000061035156
            },
            {
                "time": "Mon, Apr 8, 11:32 AM",
                "value": 68.08999633789062
            },
            {
                "time": "Mon, Apr 8, 11:33 AM",
                "value": 68
            },
            {
                "time": "Mon, Apr 8, 11:33 AM",
                "value": 67.81999969482422
            },
            {
                "time": "Mon, Apr 8, 11:34 AM",
                "value": 67.77999877929688
            },
            {
                "time": "Mon, Apr 8, 11:34 AM",
                "value": 67.77999877929688
            },
            {
                "time": "Mon, Apr 8, 11:35 AM",
                "value": 67.86000061035156
            },
            {
                "time": "Mon, Apr 8, 11:35 AM",
                "value": 68.0199966430664
            },
            {
                "time": "Mon, Apr 8, 11:36 AM",
                "value": 68.04000091552734
            },
            {
                "time": "Mon, Apr 8, 11:36 AM",
                "value": 68.13999938964844
            },
            {
                "time": "Mon, Apr 8, 11:37 AM",
                "value": 68.18000030517578
            },
            {
                "time": "Mon, Apr 8, 11:37 AM",
                "value": 68.29000091552734
            },
            {
                "time": "Mon, Apr 8, 11:38 AM",
                "value": 68.47000122070312
            },
            {
                "time": "Mon, Apr 8, 11:38 AM",
                "value": 68.77999877929688
            },
            {
                "time": "Mon, Apr 8, 11:39 AM",
                "value": 68.88999938964844
            },
            {
                "time": "Mon, Apr 8, 11:39 AM",
                "value": 68.9000015258789
            },
            {
                "time": "Mon, Apr 8, 11:40 AM",
                "value": 68.9800033569336
            },
            {
                "time": "Mon, Apr 8, 11:40 AM",
                "value": 68.83000183105469
            },
            {
                "time": "Mon, Apr 8, 11:41 AM",
                "value": 68.69000244140625
            },
            {
                "time": "Mon, Apr 8, 11:41 AM",
                "value": 68.6500015258789
            },
            {
                "time": "Mon, Apr 8, 11:42 AM",
                "value": 68.72000122070312
            },
            {
                "time": "Mon, Apr 8, 11:42 AM",
                "value": 68.98999786376953
            },
            {
                "time": "Mon, Apr 8, 11:43 AM",
                "value": 69.18000030517578
            },
            {
                "time": "Mon, Apr 8, 11:43 AM",
                "value": 69.13999938964844
            },
            {
                "time": "Mon, Apr 8, 11:44 AM",
                "value": 69.2699966430664
            },
            {
                "time": "Mon, Apr 8, 11:44 AM",
                "value": 69.30000305175781
            },
            {
                "time": "Mon, Apr 8, 11:45 AM",
                "value": 69.20999908447266
            },
            {
                "time": "Mon, Apr 8, 11:45 AM",
                "value": 68.9000015258789
            },
            {
                "time": "Mon, Apr 8, 11:46 AM",
                "value": 68.6500015258789
            },
            {
                "time": "Mon, Apr 8, 11:46 AM",
                "value": 68.55999755859375
            },
            {
                "time": "Mon, Apr 8, 11:47 AM",
                "value": 68.4000015258789
            },
            {
                "time": "Mon, Apr 8, 11:47 AM",
                "value": 68.4000015258789
            },
            {
                "time": "Mon, Apr 8, 11:48 AM",
                "value": 68.55999755859375
            },
            {
                "time": "Mon, Apr 8, 11:48 AM",
                "value": 68.8499984741211
            },
            {
                "time": "Mon, Apr 8, 11:49 AM",
                "value": 69.16000366210938
            },
            {
                "time": "Mon, Apr 8, 11:49 AM",
                "value": 69.5
            },
            {
                "time": "Mon, Apr 8, 11:50 AM",
                "value": 69.73999786376953
            },
            {
                "time": "Mon, Apr 8, 11:50 AM",
                "value": 69.97000122070312
            },
            {
                "time": "Mon, Apr 8, 11:51 AM",
                "value": 70.08000183105469
            },
            {
                "time": "Mon, Apr 8, 11:51 AM",
                "value": 70.22000122070312
            },
            {
                "time": "Mon, Apr 8, 11:52 AM",
                "value": 70.08000183105469
            },
            {
                "time": "Mon, Apr 8, 11:52 AM",
                "value": 70.26000213623047
            },
            {
                "time": "Mon, Apr 8, 11:53 AM",
                "value": 70.1500015258789
            },
            {
                "time": "Mon, Apr 8, 11:53 AM",
                "value": 70.16999816894531
            },
            {
                "time": "Mon, Apr 8, 11:54 AM",
                "value": 70.33000183105469
            },
            {
                "time": "Mon, Apr 8, 11:54 AM",
                "value": 70.3499984741211
            },
            {
                "time": "Mon, Apr 8, 11:55 AM",
                "value": 70.12999725341797
            },
            {
                "time": "Mon, Apr 8, 11:55 AM",
                "value": 70.01000213623047
            },
            {
                "time": "Mon, Apr 8, 11:56 AM",
                "value": 69.98999786376953
            },
            {
                "time": "Mon, Apr 8, 11:56 AM",
                "value": 70.11000061035156
            },
            {
                "time": "Mon, Apr 8, 11:57 AM",
                "value": 70.27999877929688
            },
            {
                "time": "Mon, Apr 8, 11:57 AM",
                "value": 70.70999908447266
            },
            {
                "time": "Mon, Apr 8, 11:58 AM",
                "value": 70.95999908447266
            },
            {
                "time": "Mon, Apr 8, 11:58 AM",
                "value": 71.16000366210938
            },
            {
                "time": "Mon, Apr 8, 11:59 AM",
                "value": 71.36000061035156
            },
            {
                "time": "Mon, Apr 8, 11:59 AM",
                "value": 71.58000183105469
            },
            {
                "time": "Mon, Apr 8, 12:00 PM",
                "value": 71.72000122070312
            },
            {
                "time": "Mon, Apr 8, 12:00 PM",
                "value": 71.9000015258789
            },
            {
                "time": "Mon, Apr 8, 12:01 PM",
                "value": 71.98999786376953
            },
            {
                "time": "Mon, Apr 8, 12:01 PM",
                "value": 71.97000122070312
            },
            {
                "time": "Mon, Apr 8, 12:02 PM",
                "value": 71.87999725341797
            },
            {
                "time": "Mon, Apr 8, 12:02 PM",
                "value": 71.87000274658203
            },
            {
                "time": "Mon, Apr 8, 12:03 PM",
                "value": 71.47000122070312
            },
            {
                "time": "Mon, Apr 8, 12:03 PM",
                "value": 70.81999969482422
            },
            {
                "time": "Mon, Apr 8, 12:04 PM",
                "value": 70.37000274658203
            },
            {
                "time": "Mon, Apr 8, 12:04 PM",
                "value": 70.1500015258789
            },
            {
                "time": "Mon, Apr 8, 12:05 PM",
                "value": 70.27999877929688
            },
            {
                "time": "Mon, Apr 8, 12:05 PM",
                "value": 70.23999786376953
            },
            {
                "time": "Mon, Apr 8, 12:06 PM",
                "value": 70.23999786376953
            },
            {
                "time": "Mon, Apr 8, 12:06 PM",
                "value": 70.30000305175781
            },
            {
                "time": "Mon, Apr 8, 12:07 PM",
                "value": 70.52999877929688
            },
            {
                "time": "Mon, Apr 8, 12:07 PM",
                "value": 70.56999969482422
            },
            {
                "time": "Mon, Apr 8, 12:08 PM",
                "value": 70.63999938964844
            },
            {
                "time": "Mon, Apr 8, 12:08 PM",
                "value": 70.48999786376953
            },
            {
                "time": "Mon, Apr 8, 12:09 PM",
                "value": 70.45999908447266
            },
            {
                "time": "Mon, Apr 8, 12:09 PM",
                "value": 70.52999877929688
            },
            {
                "time": "Mon, Apr 8, 12:10 PM",
                "value": 70.63999938964844
            },
            {
                "time": "Mon, Apr 8, 12:10 PM",
                "value": 70.77999877929688
            },
            {
                "time": "Mon, Apr 8, 12:11 PM",
                "value": 70.88999938964844
            },
            {
                "time": "Mon, Apr 8, 12:11 PM",
                "value": 71.0199966430664
            },
            {
                "time": "Mon, Apr 8, 12:12 PM",
                "value": 70.91000366210938
            },
            {
                "time": "Mon, Apr 8, 12:12 PM",
                "value": 71
            },
            {
                "time": "Mon, Apr 8, 12:13 PM",
                "value": 71.08999633789062
            },
            {
                "time": "Mon, Apr 8, 12:13 PM",
                "value": 71.16000366210938
            },
            {
                "time": "Mon, Apr 8, 12:14 PM",
                "value": 71.23999786376953
            },
            {
                "time": "Mon, Apr 8, 12:14 PM",
                "value": 71.54000091552734
            },
            {
                "time": "Mon, Apr 8, 12:15 PM",
                "value": 71.61000061035156
            },
            {
                "time": "Mon, Apr 8, 12:15 PM",
                "value": 71.6500015258789
            },
            {
                "time": "Mon, Apr 8, 12:16 PM",
                "value": 71.5199966430664
            },
            {
                "time": "Mon, Apr 8, 12:16 PM",
                "value": 71.23999786376953
            },
            {
                "time": "Mon, Apr 8, 12:17 PM",
                "value": 71.33999633789062
            },
            {
                "time": "Mon, Apr 8, 12:17 PM",
                "value": 71.54000091552734
            },
            {
                "time": "Mon, Apr 8, 12:18 PM",
                "value": 71.5199966430664
            },
            {
                "time": "Mon, Apr 8, 12:18 PM",
                "value": 71.37999725341797
            },
            {
                "time": "Mon, Apr 8, 12:19 PM",
                "value": 71.2699966430664
            },
            {
                "time": "Mon, Apr 8, 12:19 PM",
                "value": 71.29000091552734
            },
            {
                "time": "Mon, Apr 8, 12:20 PM",
                "value": 71.5199966430664
            },
            {
                "time": "Mon, Apr 8, 12:20 PM",
                "value": 71.62999725341797
            },
            {
                "time": "Mon, Apr 8, 12:21 PM",
                "value": 71.58000183105469
            },
            {
                "time": "Mon, Apr 8, 12:21 PM",
                "value": 71.48999786376953
            },
            {
                "time": "Mon, Apr 8, 12:22 PM",
                "value": 71.29000091552734
            },
            {
                "time": "Mon, Apr 8, 12:22 PM",
                "value": 71.13999938964844
            },
            {
                "time": "Mon, Apr 8, 12:23 PM",
                "value": 71.06999969482422
            },
            {
                "time": "Mon, Apr 8, 12:23 PM",
                "value": 70.91000366210938
            },
            {
                "time": "Mon, Apr 8, 12:24 PM",
                "value": 70.95999908447266
            },
            {
                "time": "Mon, Apr 8, 12:24 PM",
                "value": 71
            },
            {
                "time": "Mon, Apr 8, 12:25 PM",
                "value": 71.08999633789062
            },
            {
                "time": "Mon, Apr 8, 12:25 PM",
                "value": 71.18000030517578
            },
            {
                "time": "Mon, Apr 8, 12:26 PM",
                "value": 71.13999938964844
            },
            {
                "time": "Mon, Apr 8, 12:26 PM",
                "value": 71.43000030517578
            },
            {
                "time": "Mon, Apr 8, 12:27 PM",
                "value": 71.6500015258789
            },
            {
                "time": "Mon, Apr 8, 12:27 PM",
                "value": 71.83000183105469
            },
            {
                "time": "Mon, Apr 8, 12:28 PM",
                "value": 72.08000183105469
            },
            {
                "time": "Mon, Apr 8, 12:28 PM",
                "value": 72.20999908447266
            },
            {
                "time": "Mon, Apr 8, 12:29 PM",
                "value": 72.4800033569336
            },
            {
                "time": "Mon, Apr 8, 12:29 PM",
                "value": 72.79000091552734
            },
            {
                "time": "Mon, Apr 8, 12:30 PM",
                "value": 72.91000366210938
            },
            {
                "time": "Mon, Apr 8, 12:30 PM",
                "value": 72.81999969482422
            },
            {
                "time": "Mon, Apr 8, 12:31 PM",
                "value": 72.61000061035156
            },
            {
                "time": "Mon, Apr 8, 12:31 PM",
                "value": 72.23999786376953
            },
            {
                "time": "Mon, Apr 8, 12:32 PM",
                "value": 72.19000244140625
            },
            {
                "time": "Mon, Apr 8, 12:32 PM",
                "value": 72.30000305175781
            },
            {
                "time": "Mon, Apr 8, 12:33 PM",
                "value": 72.41000366210938
            },
            {
                "time": "Mon, Apr 8, 12:33 PM",
                "value": 72.4800033569336
            },
            {
                "time": "Mon, Apr 8, 12:34 PM",
                "value": 72.80000305175781
            },
            {
                "time": "Mon, Apr 8, 12:34 PM",
                "value": 73.18000030517578
            },
            {
                "time": "Mon, Apr 8, 12:35 PM",
                "value": 73.08999633789062
            },
            {
                "time": "Mon, Apr 8, 12:35 PM",
                "value": 72.66000366210938
            },
            {
                "time": "Mon, Apr 8, 12:36 PM",
                "value": 72.38999938964844
            },
            {
                "time": "Mon, Apr 8, 12:36 PM",
                "value": 72.13999938964844
            },
            {
                "time": "Mon, Apr 8, 12:37 PM",
                "value": 71.79000091552734
            },
            {
                "time": "Mon, Apr 8, 12:37 PM",
                "value": 71.69000244140625
            },
            {
                "time": "Mon, Apr 8, 12:38 PM",
                "value": 71.87000274658203
            },
            {
                "time": "Mon, Apr 8, 12:38 PM",
                "value": 72.19000244140625
            },
            {
                "time": "Mon, Apr 8, 12:39 PM",
                "value": 72.33999633789062
            },
            {
                "time": "Mon, Apr 8, 12:39 PM",
                "value": 72.27999877929688
            },
            {
                "time": "Mon, Apr 8, 12:40 PM",
                "value": 72.37000274658203
            },
            {
                "time": "Mon, Apr 8, 12:40 PM",
                "value": 72.41000366210938
            },
            {
                "time": "Mon, Apr 8, 12:41 PM",
                "value": 72.30000305175781
            },
            {
                "time": "Mon, Apr 8, 12:41 PM",
                "value": 72.27999877929688
            },
            {
                "time": "Mon, Apr 8, 12:42 PM",
                "value": 72.75
            },
            {
                "time": "Mon, Apr 8, 12:42 PM",
                "value": 73.16999816894531
            },
            {
                "time": "Mon, Apr 8, 12:43 PM",
                "value": 73.05999755859375
            },
            {
                "time": "Mon, Apr 8, 12:43 PM",
                "value": 72.69999694824219
            },
            {
                "time": "Mon, Apr 8, 12:44 PM",
                "value": 72.69999694824219
            },
            {
                "time": "Mon, Apr 8, 12:44 PM",
                "value": 72.58999633789062
            },
            {
                "time": "Mon, Apr 8, 12:45 PM",
                "value": 72.5199966430664
            },
            {
                "time": "Mon, Apr 8, 12:45 PM",
                "value": 72.37000274658203
            },
            {
                "time": "Mon, Apr 8, 12:46 PM",
                "value": 72.69999694824219
            },
            {
                "time": "Mon, Apr 8, 12:46 PM",
                "value": 72.93000030517578
            },
            {
                "time": "Mon, Apr 8, 12:47 PM",
                "value": 73.12999725341797
            },
            {
                "time": "Mon, Apr 8, 12:47 PM",
                "value": 72.91000366210938
            },
            {
                "time": "Mon, Apr 8, 12:48 PM",
                "value": 72.87999725341797
            },
            {
                "time": "Mon, Apr 8, 12:48 PM",
                "value": 72.83999633789062
            },
            {
                "time": "Mon, Apr 8, 12:49 PM",
                "value": 72.81999969482422
            },
            {
                "time": "Mon, Apr 8, 12:49 PM",
                "value": 72.98999786376953
            },
            {
                "time": "Mon, Apr 8, 12:50 PM",
                "value": 72.94999694824219
            },
            {
                "time": "Mon, Apr 8, 12:50 PM",
                "value": 73
            },
            {
                "time": "Mon, Apr 8, 12:51 PM",
                "value": 72.79000091552734
            },
            {
                "time": "Mon, Apr 8, 12:51 PM",
                "value": 72.75
            },
            {
                "time": "Mon, Apr 8, 12:52 PM",
                "value": 72.83999633789062
            },
            {
                "time": "Mon, Apr 8, 12:52 PM",
                "value": 72.80000305175781
            },
            {
                "time": "Mon, Apr 8, 12:53 PM",
                "value": 73.08000183105469
            },
            {
                "time": "Mon, Apr 8, 12:53 PM",
                "value": 73.33000183105469
            },
            {
                "time": "Mon, Apr 8, 12:54 PM",
                "value": 73.41999816894531
            },
            {
                "time": "Mon, Apr 8, 12:54 PM",
                "value": 73.44000244140625
            },
            {
                "time": "Mon, Apr 8, 12:55 PM",
                "value": 73.18000030517578
            },
            {
                "time": "Mon, Apr 8, 12:55 PM",
                "value": 72.9000015258789
            },
            {
                "time": "Mon, Apr 8, 12:56 PM",
                "value": 72.70999908447266
            },
            {
                "time": "Mon, Apr 8, 12:56 PM",
                "value": 72.41000366210938
            },
            {
                "time": "Mon, Apr 8, 12:57 PM",
                "value": 72.13999938964844
            },
            {
                "time": "Mon, Apr 8, 12:57 PM",
                "value": 72.16999816894531
            },
            {
                "time": "Mon, Apr 8, 12:58 PM",
                "value": 72.37000274658203
            },
            {
                "time": "Mon, Apr 8, 12:58 PM",
                "value": 72.37000274658203
            },
            {
                "time": "Mon, Apr 8, 12:59 PM",
                "value": 72.33999633789062
            },
            {
                "time": "Mon, Apr 8, 12:59 PM",
                "value": 72.3499984741211
            },
            {
                "time": "Mon, Apr 8, 1:00 PM",
                "value": 72.38999938964844
            },
            {
                "time": "Mon, Apr 8, 1:00 PM",
                "value": 72.4800033569336
            },
            {
                "time": "Mon, Apr 8, 1:01 PM",
                "value": 72.7699966430664
            },
            {
                "time": "Mon, Apr 8, 1:01 PM",
                "value": 73.04000091552734
            },
            {
                "time": "Mon, Apr 8, 1:02 PM",
                "value": 73.37999725341797
            },
            {
                "time": "Mon, Apr 8, 1:02 PM",
                "value": 73.52999877929688
            },
            {
                "time": "Mon, Apr 8, 1:03 PM",
                "value": 73.58000183105469
            },
            {
                "time": "Mon, Apr 8, 1:03 PM",
                "value": 73.37999725341797
            },
            {
                "time": "Mon, Apr 8, 1:04 PM",
                "value": 73.18000030517578
            },
            {
                "time": "Mon, Apr 8, 1:04 PM",
                "value": 73.1500015258789
            },
            {
                "time": "Mon, Apr 8, 1:05 PM",
                "value": 73.44000244140625
            },
            {
                "time": "Mon, Apr 8, 1:05 PM",
                "value": 73.66999816894531
            },
            {
                "time": "Mon, Apr 8, 1:06 PM",
                "value": 73.8499984741211
            },
            {
                "time": "Mon, Apr 8, 1:06 PM",
                "value": 73.94000244140625
            },
            {
                "time": "Mon, Apr 8, 1:07 PM",
                "value": 73.88999938964844
            },
            {
                "time": "Mon, Apr 8, 1:07 PM",
                "value": 73.77999877929688
            },
            {
                "time": "Mon, Apr 8, 1:08 PM",
                "value": 73.8499984741211
            },
            {
                "time": "Mon, Apr 8, 1:08 PM",
                "value": 73.87000274658203
            },
            {
                "time": "Mon, Apr 8, 1:09 PM",
                "value": 73.44999694824219
            },
            {
                "time": "Mon, Apr 8, 1:09 PM",
                "value": 73.33000183105469
            },
            {
                "time": "Mon, Apr 8, 1:10 PM",
                "value": 73.30999755859375
            },
            {
                "time": "Mon, Apr 8, 1:10 PM",
                "value": 73.29000091552734
            },
            {
                "time": "Mon, Apr 8, 1:11 PM",
                "value": 72.94999694824219
            },
            {
                "time": "Mon, Apr 8, 1:11 PM",
                "value": 72.87999725341797
            },
            {
                "time": "Mon, Apr 8, 1:12 PM",
                "value": 72.75
            },
            {
                "time": "Mon, Apr 8, 1:12 PM",
                "value": 72.5
            },
            {
                "time": "Mon, Apr 8, 1:13 PM",
                "value": 72.41000366210938
            },
            {
                "time": "Mon, Apr 8, 1:13 PM",
                "value": 72.37000274658203
            },
            {
                "time": "Mon, Apr 8, 1:14 PM",
                "value": 72.4800033569336
            },
            {
                "time": "Mon, Apr 8, 1:14 PM",
                "value": 72.68000030517578
            },
            {
                "time": "Mon, Apr 8, 1:15 PM",
                "value": 73.08999633789062
            },
            {
                "time": "Mon, Apr 8, 1:15 PM",
                "value": 73.19999694824219
            },
            {
                "time": "Mon, Apr 8, 1:16 PM",
                "value": 73.19999694824219
            },
            {
                "time": "Mon, Apr 8, 1:16 PM",
                "value": 72.93000030517578
            },
            {
                "time": "Mon, Apr 8, 1:17 PM",
                "value": 72.69999694824219
            },
            {
                "time": "Mon, Apr 8, 1:17 PM",
                "value": 72.5
            },
            {
                "time": "Mon, Apr 8, 1:18 PM",
                "value": 72.55000305175781
            },
            {
                "time": "Mon, Apr 8, 1:18 PM",
                "value": 72.66000366210938
            },
            {
                "time": "Mon, Apr 8, 1:19 PM",
                "value": 72.94999694824219
            },
            {
                "time": "Mon, Apr 8, 1:19 PM",
                "value": 73.05999755859375
            },
            {
                "time": "Mon, Apr 8, 1:20 PM",
                "value": 73.05999755859375
            },
            {
                "time": "Mon, Apr 8, 1:20 PM",
                "value": 73.12999725341797
            },
            {
                "time": "Mon, Apr 8, 1:21 PM",
                "value": 73.08999633789062
            },
            {
                "time": "Mon, Apr 8, 1:21 PM",
                "value": 73.30999755859375
            },
            {
                "time": "Mon, Apr 8, 1:22 PM",
                "value": 73.4000015258789
            },
            {
                "time": "Mon, Apr 8, 1:22 PM",
                "value": 73.48999786376953
            },
            {
                "time": "Mon, Apr 8, 1:23 PM",
                "value": 73.62000274658203
            },
            {
                "time": "Mon, Apr 8, 1:23 PM",
                "value": 73.77999877929688
            },
            {
                "time": "Mon, Apr 8, 1:24 PM",
                "value": 73.69000244140625
            },
            {
                "time": "Mon, Apr 8, 1:24 PM",
                "value": 73.4000015258789
            },
            {
                "time": "Mon, Apr 8, 1:25 PM",
                "value": 73.23999786376953
            },
            {
                "time": "Mon, Apr 8, 1:25 PM",
                "value": 73.0199966430664
            },
            {
                "time": "Mon, Apr 8, 1:26 PM",
                "value": 72.98999786376953
            },
            {
                "time": "Mon, Apr 8, 1:26 PM",
                "value": 73.08999633789062
            },
            {
                "time": "Mon, Apr 8, 1:27 PM",
                "value": 73.12999725341797
            },
            {
                "time": "Mon, Apr 8, 1:27 PM",
                "value": 73.29000091552734
            },
            {
                "time": "Mon, Apr 8, 1:28 PM",
                "value": 73.30999755859375
            },
            {
                "time": "Mon, Apr 8, 1:28 PM",
                "value": 73.29000091552734
            },
            {
                "time": "Mon, Apr 8, 1:29 PM",
                "value": 73.11000061035156
            },
            {
                "time": "Mon, Apr 8, 1:29 PM",
                "value": 73.04000091552734
            },
            {
                "time": "Mon, Apr 8, 1:30 PM",
                "value": 72.9000015258789
            },
            {
                "time": "Mon, Apr 8, 1:30 PM",
                "value": 72.91000366210938
            },
            {
                "time": "Mon, Apr 8, 1:31 PM",
                "value": 72.87999725341797
            },
            {
                "time": "Mon, Apr 8, 1:31 PM",
                "value": 72.97000122070312
            },
            {
                "time": "Mon, Apr 8, 1:32 PM",
                "value": 73.05999755859375
            },
            {
                "time": "Mon, Apr 8, 1:32 PM",
                "value": 73.08999633789062
            },
            {
                "time": "Mon, Apr 8, 1:33 PM",
                "value": 73.2699966430664
            },
            {
                "time": "Mon, Apr 8, 1:33 PM",
                "value": 73.51000213623047
            },
            {
                "time": "Mon, Apr 8, 1:34 PM",
                "value": 73.63999938964844
            },
            {
                "time": "Mon, Apr 8, 1:34 PM",
                "value": 73.69000244140625
            },
            {
                "time": "Mon, Apr 8, 1:35 PM",
                "value": 73.73999786376953
            },
            {
                "time": "Mon, Apr 8, 1:35 PM",
                "value": 73.73999786376953
            },
            {
                "time": "Mon, Apr 8, 1:36 PM",
                "value": 73.73999786376953
            },
            {
                "time": "Mon, Apr 8, 1:36 PM",
                "value": 73.69000244140625
            },
            {
                "time": "Mon, Apr 8, 1:37 PM",
                "value": 73.73999786376953
            },
            {
                "time": "Mon, Apr 8, 1:37 PM",
                "value": 73.91000366210938
            },
            {
                "time": "Mon, Apr 8, 1:38 PM",
                "value": 74.33999633789062
            },
            {
                "time": "Mon, Apr 8, 1:38 PM",
                "value": 74.5
            },
            {
                "time": "Mon, Apr 8, 1:39 PM",
                "value": 74.54000091552734
            },
            {
                "time": "Mon, Apr 8, 1:39 PM",
                "value": 74.29000091552734
            },
            {
                "time": "Mon, Apr 8, 1:40 PM",
                "value": 74.20999908447266
            },
            {
                "time": "Mon, Apr 8, 1:40 PM",
                "value": 74.13999938964844
            },
            {
                "time": "Mon, Apr 8, 1:41 PM",
                "value": 74.2300033569336
            },
            {
                "time": "Mon, Apr 8, 1:41 PM",
                "value": 74.29000091552734
            },
            {
                "time": "Mon, Apr 8, 1:42 PM",
                "value": 74.31999969482422
            },
            {
                "time": "Mon, Apr 8, 1:42 PM",
                "value": 74.06999969482422
            },
            {
                "time": "Mon, Apr 8, 1:43 PM",
                "value": 73.94000244140625
            },
            {
                "time": "Mon, Apr 8, 1:43 PM",
                "value": 74.02999877929688
            },
            {
                "time": "Mon, Apr 8, 1:44 PM",
                "value": 74.29000091552734
            },
            {
                "time": "Mon, Apr 8, 1:44 PM",
                "value": 74.62999725341797
            },
            {
                "time": "Mon, Apr 8, 1:45 PM",
                "value": 74.56999969482422
            },
            {
                "time": "Mon, Apr 8, 1:45 PM",
                "value": 74.37999725341797
            },
            {
                "time": "Mon, Apr 8, 1:46 PM",
                "value": 74.30000305175781
            },
            {
                "time": "Mon, Apr 8, 1:46 PM",
                "value": 74.33999633789062
            },
            {
                "time": "Mon, Apr 8, 1:47 PM",
                "value": 74.16000366210938
            },
            {
                "time": "Mon, Apr 8, 1:47 PM",
                "value": 73.76000213623047
            },
            {
                "time": "Mon, Apr 8, 1:48 PM",
                "value": 73.73999786376953
            },
            {
                "time": "Mon, Apr 8, 1:48 PM",
                "value": 73.88999938964844
            },
            {
                "time": "Mon, Apr 8, 1:49 PM",
                "value": 73.76000213623047
            },
            {
                "time": "Mon, Apr 8, 1:49 PM",
                "value": 73.70999908447266
            },
            {
                "time": "Mon, Apr 8, 1:50 PM",
                "value": 74.06999969482422
            },
            {
                "time": "Mon, Apr 8, 1:50 PM",
                "value": 73.87000274658203
            },
            {
                "time": "Mon, Apr 8, 1:51 PM",
                "value": 73.80000305175781
            },
            {
                "time": "Mon, Apr 8, 1:51 PM",
                "value": 73.8499984741211
            },
            {
                "time": "Mon, Apr 8, 1:52 PM",
                "value": 74.16999816894531
            },
            {
                "time": "Mon, Apr 8, 1:52 PM",
                "value": 74.44999694824219
            },
            {
                "time": "Mon, Apr 8, 1:53 PM",
                "value": 74.54000091552734
            },
            {
                "time": "Mon, Apr 8, 1:53 PM",
                "value": 74.66000366210938
            },
            {
                "time": "Mon, Apr 8, 1:54 PM",
                "value": 74.55999755859375
            },
            {
                "time": "Mon, Apr 8, 1:54 PM",
                "value": 74.47000122070312
            },
            {
                "time": "Mon, Apr 8, 1:55 PM",
                "value": 74.47000122070312
            },
            {
                "time": "Mon, Apr 8, 1:55 PM",
                "value": 74.47000122070312
            },
            {
                "time": "Mon, Apr 8, 1:56 PM",
                "value": 74.5199966430664
            },
            {
                "time": "Mon, Apr 8, 1:56 PM",
                "value": 74.43000030517578
            },
            {
                "time": "Mon, Apr 8, 1:57 PM",
                "value": 74.12000274658203
            },
            {
                "time": "Mon, Apr 8, 1:57 PM",
                "value": 73.9800033569336
            },
            {
                "time": "Mon, Apr 8, 1:58 PM",
                "value": 74
            },
            {
                "time": "Mon, Apr 8, 1:58 PM",
                "value": 73.93000030517578
            },
            {
                "time": "Mon, Apr 8, 1:59 PM",
                "value": 74.08999633789062
            },
            {
                "time": "Mon, Apr 8, 1:59 PM",
                "value": 74.05000305175781
            },
            {
                "time": "Mon, Apr 8, 2:00 PM",
                "value": 73.91000366210938
            },
            {
                "time": "Mon, Apr 8, 2:00 PM",
                "value": 73.77999877929688
            },
            {
                "time": "Mon, Apr 8, 2:01 PM",
                "value": 73.81999969482422
            },
            {
                "time": "Mon, Apr 8, 2:01 PM",
                "value": 73.88999938964844
            },
            {
                "time": "Mon, Apr 8, 2:02 PM",
                "value": 73.87000274658203
            },
            {
                "time": "Mon, Apr 8, 2:02 PM",
                "value": 73.88999938964844
            },
            {
                "time": "Mon, Apr 8, 2:03 PM",
                "value": 73.83000183105469
            },
            {
                "time": "Mon, Apr 8, 2:03 PM",
                "value": 74
            },
            {
                "time": "Mon, Apr 8, 2:04 PM",
                "value": 74.05000305175781
            },
            {
                "time": "Mon, Apr 8, 2:04 PM",
                "value": 74.16999816894531
            },
            {
                "time": "Mon, Apr 8, 2:05 PM",
                "value": 74.37999725341797
            },
            {
                "time": "Mon, Apr 8, 2:05 PM",
                "value": 74.47000122070312
            },
            {
                "time": "Mon, Apr 8, 2:06 PM",
                "value": 74.61000061035156
            },
            {
                "time": "Mon, Apr 8, 2:06 PM",
                "value": 74.7699966430664
            },
            {
                "time": "Mon, Apr 8, 2:07 PM",
                "value": 74.72000122070312
            },
            {
                "time": "Mon, Apr 8, 2:07 PM",
                "value": 74.97000122070312
            },
            {
                "time": "Mon, Apr 8, 2:08 PM",
                "value": 75.08000183105469
            },
            {
                "time": "Mon, Apr 8, 2:08 PM",
                "value": 75.13999938964844
            },
            {
                "time": "Mon, Apr 8, 2:09 PM",
                "value": 75.11000061035156
            },
            {
                "time": "Mon, Apr 8, 2:09 PM",
                "value": 74.79000091552734
            },
            {
                "time": "Mon, Apr 8, 2:10 PM",
                "value": 74.69999694824219
            },
            {
                "time": "Mon, Apr 8, 2:10 PM",
                "value": 74.62999725341797
            },
            {
                "time": "Mon, Apr 8, 2:11 PM",
                "value": 74.43000030517578
            },
            {
                "time": "Mon, Apr 8, 2:11 PM",
                "value": 74.5199966430664
            },
            {
                "time": "Mon, Apr 8, 2:12 PM",
                "value": 74.55999755859375
            },
            {
                "time": "Mon, Apr 8, 2:12 PM",
                "value": 74.61000061035156
            },
            {
                "time": "Mon, Apr 8, 2:13 PM",
                "value": 74.61000061035156
            },
            {
                "time": "Mon, Apr 8, 2:13 PM",
                "value": 74.68000030517578
            },
            {
                "time": "Mon, Apr 8, 2:14 PM",
                "value": 74.62999725341797
            },
            {
                "time": "Mon, Apr 8, 2:14 PM",
                "value": 74.62999725341797
            },
            {
                "time": "Mon, Apr 8, 2:15 PM",
                "value": 74.73999786376953
            },
            {
                "time": "Mon, Apr 8, 2:15 PM",
                "value": 74.68000030517578
            },
            {
                "time": "Mon, Apr 8, 2:16 PM",
                "value": 74.54000091552734
            },
            {
                "time": "Mon, Apr 8, 2:16 PM",
                "value": 74.25
            },
            {
                "time": "Mon, Apr 8, 2:17 PM",
                "value": 74.25
            },
            {
                "time": "Mon, Apr 8, 2:17 PM",
                "value": 74.05000305175781
            },
            {
                "time": "Mon, Apr 8, 2:18 PM",
                "value": 73.94000244140625
            },
            {
                "time": "Mon, Apr 8, 2:18 PM",
                "value": 73.91000366210938
            },
            {
                "time": "Mon, Apr 8, 2:19 PM",
                "value": 73.83000183105469
            },
            {
                "time": "Mon, Apr 8, 2:19 PM",
                "value": 73.80000305175781
            },
            {
                "time": "Mon, Apr 8, 2:20 PM",
                "value": 73.73999786376953
            },
            {
                "time": "Mon, Apr 8, 2:20 PM",
                "value": 73.70999908447266
            },
            {
                "time": "Mon, Apr 8, 2:21 PM",
                "value": 73.62000274658203
            },
            {
                "time": "Mon, Apr 8, 2:21 PM",
                "value": 73.62000274658203
            },
            {
                "time": "Mon, Apr 8, 2:22 PM",
                "value": 73.8499984741211
            },
            {
                "time": "Mon, Apr 8, 2:22 PM",
                "value": 73.95999908447266
            },
            {
                "time": "Mon, Apr 8, 2:23 PM",
                "value": 74.2300033569336
            },
            {
                "time": "Mon, Apr 8, 2:23 PM",
                "value": 74.44999694824219
            },
            {
                "time": "Mon, Apr 8, 2:24 PM",
                "value": 74.37999725341797
            },
            {
                "time": "Mon, Apr 8, 2:24 PM",
                "value": 74.36000061035156
            },
            {
                "time": "Mon, Apr 8, 2:25 PM",
                "value": 74.47000122070312
            },
            {
                "time": "Mon, Apr 8, 2:25 PM",
                "value": 74.47000122070312
            },
            {
                "time": "Mon, Apr 8, 2:26 PM",
                "value": 74.54000091552734
            },
            {
                "time": "Mon, Apr 8, 2:26 PM",
                "value": 74.4800033569336
            },
            {
                "time": "Mon, Apr 8, 2:27 PM",
                "value": 74.5199966430664
            },
            {
                "time": "Mon, Apr 8, 2:27 PM",
                "value": 74.47000122070312
            },
            {
                "time": "Mon, Apr 8, 2:28 PM",
                "value": 74.38999938964844
            },
            {
                "time": "Mon, Apr 8, 2:28 PM",
                "value": 74.31999969482422
            },
            {
                "time": "Mon, Apr 8, 2:29 PM",
                "value": 74.19000244140625
            },
            {
                "time": "Mon, Apr 8, 2:29 PM",
                "value": 74.05000305175781
            },
            {
                "time": "Mon, Apr 8, 2:30 PM",
                "value": 74
            },
            {
                "time": "Mon, Apr 8, 2:30 PM",
                "value": 73.94000244140625
            },
            {
                "time": "Mon, Apr 8, 2:31 PM",
                "value": 73.83000183105469
            },
            {
                "time": "Mon, Apr 8, 2:31 PM",
                "value": 73.8499984741211
            },
            {
                "time": "Mon, Apr 8, 2:32 PM",
                "value": 73.77999877929688
            },
            {
                "time": "Mon, Apr 8, 2:32 PM",
                "value": 73.66999816894531
            },
            {
                "time": "Mon, Apr 8, 2:33 PM",
                "value": 73.6500015258789
            },
            {
                "time": "Mon, Apr 8, 2:33 PM",
                "value": 73.62000274658203
            },
            {
                "time": "Mon, Apr 8, 2:34 PM",
                "value": 73.5999984741211
            },
            {
                "time": "Mon, Apr 8, 2:34 PM",
                "value": 73.5999984741211
            },
            {
                "time": "Mon, Apr 8, 2:35 PM",
                "value": 73.48999786376953
            },
            {
                "time": "Mon, Apr 8, 2:35 PM",
                "value": 73.70999908447266
            },
            {
                "time": "Mon, Apr 8, 2:36 PM",
                "value": 73.69000244140625
            },
            {
                "time": "Mon, Apr 8, 2:36 PM",
                "value": 73.70999908447266
            },
            {
                "time": "Mon, Apr 8, 2:37 PM",
                "value": 73.81999969482422
            },
            {
                "time": "Mon, Apr 8, 2:37 PM",
                "value": 73.80000305175781
            },
            {
                "time": "Mon, Apr 8, 2:38 PM",
                "value": 73.91000366210938
            },
            {
                "time": "Mon, Apr 8, 2:38 PM",
                "value": 73.88999938964844
            },
            {
                "time": "Mon, Apr 8, 2:39 PM",
                "value": 73.73999786376953
            },
            {
                "time": "Mon, Apr 8, 2:39 PM",
                "value": 73.63999938964844
            },
            {
                "time": "Mon, Apr 8, 2:40 PM",
                "value": 73.51000213623047
            },
            {
                "time": "Mon, Apr 8, 2:40 PM",
                "value": 73.62000274658203
            },
            {
                "time": "Mon, Apr 8, 2:41 PM",
                "value": 73.55999755859375
            },
            {
                "time": "Mon, Apr 8, 2:41 PM",
                "value": 73.62000274658203
            },
            {
                "time": "Mon, Apr 8, 2:42 PM",
                "value": 73.6500015258789
            },
            {
                "time": "Mon, Apr 8, 2:42 PM",
                "value": 73.72000122070312
            },
            {
                "time": "Mon, Apr 8, 2:43 PM",
                "value": 73.62000274658203
            },
            {
                "time": "Mon, Apr 8, 2:43 PM",
                "value": 73.6500015258789
            },
            {
                "time": "Mon, Apr 8, 2:44 PM",
                "value": 73.47000122070312
            },
            {
                "time": "Mon, Apr 8, 2:44 PM",
                "value": 73.30999755859375
            },
            {
                "time": "Mon, Apr 8, 2:45 PM",
                "value": 73.30999755859375
            },
            {
                "time": "Mon, Apr 8, 2:45 PM",
                "value": 73.23999786376953
            },
            {
                "time": "Mon, Apr 8, 2:46 PM",
                "value": 73.29000091552734
            },
            {
                "time": "Mon, Apr 8, 2:46 PM",
                "value": 73.19999694824219
            },
            {
                "time": "Mon, Apr 8, 2:47 PM",
                "value": 73.23999786376953
            },
            {
                "time": "Mon, Apr 8, 2:47 PM",
                "value": 73.2699966430664
            },
            {
                "time": "Mon, Apr 8, 2:48 PM",
                "value": 73.2699966430664
            },
            {
                "time": "Mon, Apr 8, 2:48 PM",
                "value": 73.08999633789062
            },
            {
                "time": "Mon, Apr 8, 2:49 PM",
                "value": 73.18000030517578
            },
            {
                "time": "Mon, Apr 8, 2:49 PM",
                "value": 73.19999694824219
            },
            {
                "time": "Mon, Apr 8, 2:50 PM",
                "value": 72.91000366210938
            },
            {
                "time": "Mon, Apr 8, 2:50 PM",
                "value": 72.97000122070312
            },
            {
                "time": "Mon, Apr 8, 2:51 PM",
                "value": 72.94999694824219
            },
            {
                "time": "Mon, Apr 8, 2:51 PM",
                "value": 73.0199966430664
            },
            {
                "time": "Mon, Apr 8, 2:52 PM",
                "value": 73.05999755859375
            },
            {
                "time": "Mon, Apr 8, 2:52 PM",
                "value": 72.86000061035156
            },
            {
                "time": "Mon, Apr 8, 2:53 PM",
                "value": 72.9000015258789
            },
            {
                "time": "Mon, Apr 8, 2:53 PM",
                "value": 72.80000305175781
            },
            {
                "time": "Mon, Apr 8, 2:54 PM",
                "value": 72.93000030517578
            },
            {
                "time": "Mon, Apr 8, 2:54 PM",
                "value": 73.1500015258789
            },
            {
                "time": "Mon, Apr 8, 2:55 PM",
                "value": 73.05999755859375
            },
            {
                "time": "Mon, Apr 8, 2:55 PM",
                "value": 73.0199966430664
            },
            {
                "time": "Mon, Apr 8, 2:56 PM",
                "value": 73.04000091552734
            },
            {
                "time": "Mon, Apr 8, 2:56 PM",
                "value": 73.08999633789062
            },
            {
                "time": "Mon, Apr 8, 2:57 PM",
                "value": 73.16999816894531
            },
            {
                "time": "Mon, Apr 8, 2:57 PM",
                "value": 73.18000030517578
            },
            {
                "time": "Mon, Apr 8, 2:58 PM",
                "value": 73.22000122070312
            },
            {
                "time": "Mon, Apr 8, 2:58 PM",
                "value": 73.11000061035156
            },
            {
                "time": "Mon, Apr 8, 2:59 PM",
                "value": 73.11000061035156
            },
            {
                "time": "Mon, Apr 8, 2:59 PM",
                "value": 73.11000061035156
            },
            {
                "time": "Mon, Apr 8, 3:00 PM",
                "value": 72.87999725341797
            },
            {
                "time": "Mon, Apr 8, 3:00 PM",
                "value": 72.69999694824219
            },
            {
                "time": "Mon, Apr 8, 3:01 PM",
                "value": 72.4800033569336
            },
            {
                "time": "Mon, Apr 8, 3:01 PM",
                "value": 72.26000213623047
            },
            {
                "time": "Mon, Apr 8, 3:02 PM",
                "value": 72.33999633789062
            },
            {
                "time": "Mon, Apr 8, 3:02 PM",
                "value": 72.38999938964844
            },
            {
                "time": "Mon, Apr 8, 3:03 PM",
                "value": 72.20999908447266
            },
            {
                "time": "Mon, Apr 8, 3:03 PM",
                "value": 72.2300033569336
            },
            {
                "time": "Mon, Apr 8, 3:04 PM",
                "value": 72.31999969482422
            },
            {
                "time": "Mon, Apr 8, 3:04 PM",
                "value": 72.20999908447266
            },
            {
                "time": "Mon, Apr 8, 3:05 PM",
                "value": 72.12000274658203
            },
            {
                "time": "Mon, Apr 8, 3:05 PM",
                "value": 72.08000183105469
            },
            {
                "time": "Mon, Apr 8, 3:06 PM",
                "value": 72.05999755859375
            },
            {
                "time": "Mon, Apr 8, 3:06 PM",
                "value": 71.98999786376953
            },
            {
                "time": "Mon, Apr 8, 3:07 PM",
                "value": 71.8499984741211
            },
            {
                "time": "Mon, Apr 8, 3:07 PM",
                "value": 71.69999694824219
            },
            {
                "time": "Mon, Apr 8, 3:08 PM",
                "value": 71.22000122070312
            },
            {
                "time": "Mon, Apr 8, 3:08 PM",
                "value": 70.8499984741211
            },
            {
                "time": "Mon, Apr 8, 3:09 PM",
                "value": 70.81999969482422
            },
            {
                "time": "Mon, Apr 8, 3:09 PM",
                "value": 70.88999938964844
            },
            {
                "time": "Mon, Apr 8, 3:10 PM",
                "value": 70.95999908447266
            },
            {
                "time": "Mon, Apr 8, 3:10 PM",
                "value": 71
            },
            {
                "time": "Mon, Apr 8, 3:11 PM",
                "value": 71.05000305175781
            },
            {
                "time": "Mon, Apr 8, 3:11 PM",
                "value": 71.08999633789062
            },
            {
                "time": "Mon, Apr 8, 3:12 PM",
                "value": 71.13999938964844
            },
            {
                "time": "Mon, Apr 8, 3:12 PM",
                "value": 71.11000061035156
            },
            {
                "time": "Mon, Apr 8, 3:13 PM",
                "value": 70.87000274658203
            },
            {
                "time": "Mon, Apr 8, 3:13 PM",
                "value": 70.5999984741211
            },
            {
                "time": "Mon, Apr 8, 3:14 PM",
                "value": 70.44000244140625
            },
            {
                "time": "Mon, Apr 8, 3:14 PM",
                "value": 70.3499984741211
            },
            {
                "time": "Mon, Apr 8, 3:15 PM",
                "value": 70.37000274658203
            },
            {
                "time": "Mon, Apr 8, 3:15 PM",
                "value": 70.75
            },
            {
                "time": "Mon, Apr 8, 3:16 PM",
                "value": 70.70999908447266
            },
            {
                "time": "Mon, Apr 8, 3:16 PM",
                "value": 70.45999908447266
            },
            {
                "time": "Mon, Apr 8, 3:17 PM",
                "value": 70.3499984741211
            },
            {
                "time": "Mon, Apr 8, 3:17 PM",
                "value": 70.30999755859375
            },
            {
                "time": "Mon, Apr 8, 3:18 PM",
                "value": 70.41999816894531
            },
            {
                "time": "Mon, Apr 8, 3:18 PM",
                "value": 70.5999984741211
            },
            {
                "time": "Mon, Apr 8, 3:19 PM",
                "value": 70.88999938964844
            },
            {
                "time": "Mon, Apr 8, 3:19 PM",
                "value": 71.0199966430664
            },
            {
                "time": "Mon, Apr 8, 3:20 PM",
                "value": 70.93000030517578
            },
            {
                "time": "Mon, Apr 8, 3:20 PM",
                "value": 71.06999969482422
            },
            {
                "time": "Mon, Apr 8, 3:21 PM",
                "value": 71.11000061035156
            },
            {
                "time": "Mon, Apr 8, 3:21 PM",
                "value": 71.25
            },
            {
                "time": "Mon, Apr 8, 3:22 PM",
                "value": 71.13999938964844
            },
            {
                "time": "Mon, Apr 8, 3:22 PM",
                "value": 70.9800033569336
            },
            {
                "time": "Mon, Apr 8, 3:23 PM",
                "value": 70.87000274658203
            },
            {
                "time": "Mon, Apr 8, 3:23 PM",
                "value": 70.94000244140625
            },
            {
                "time": "Mon, Apr 8, 3:24 PM",
                "value": 71.11000061035156
            },
            {
                "time": "Mon, Apr 8, 3:24 PM",
                "value": 71.2699966430664
            },
            {
                "time": "Mon, Apr 8, 3:25 PM",
                "value": 71.02999877929688
            },
            {
                "time": "Mon, Apr 8, 3:25 PM",
                "value": 71.11000061035156
            },
            {
                "time": "Mon, Apr 8, 3:26 PM",
                "value": 71.29000091552734
            },
            {
                "time": "Mon, Apr 8, 3:26 PM",
                "value": 71.33999633789062
            },
            {
                "time": "Mon, Apr 8, 3:27 PM",
                "value": 71.33999633789062
            },
            {
                "time": "Mon, Apr 8, 3:27 PM",
                "value": 71.31999969482422
            },
            {
                "time": "Mon, Apr 8, 3:28 PM",
                "value": 71.47000122070312
            },
            {
                "time": "Mon, Apr 8, 3:28 PM",
                "value": 71.48999786376953
            },
            {
                "time": "Mon, Apr 8, 3:29 PM",
                "value": 71.6500015258789
            },
            {
                "time": "Mon, Apr 8, 3:29 PM",
                "value": 71.62999725341797
            },
            {
                "time": "Mon, Apr 8, 3:30 PM",
                "value": 71.80999755859375
            },
            {
                "time": "Mon, Apr 8, 3:30 PM",
                "value": 71.72000122070312
            },
            {
                "time": "Mon, Apr 8, 3:31 PM",
                "value": 71.7699966430664
            },
            {
                "time": "Mon, Apr 8, 3:31 PM",
                "value": 71.9000015258789
            },
            {
                "time": "Mon, Apr 8, 3:32 PM",
                "value": 71.94000244140625
            },
            {
                "time": "Mon, Apr 8, 3:32 PM",
                "value": 72.01000213623047
            },
            {
                "time": "Mon, Apr 8, 3:33 PM",
                "value": 72.05000305175781
            },
            {
                "time": "Mon, Apr 8, 3:33 PM",
                "value": 72.16999816894531
            },
            {
                "time": "Mon, Apr 8, 3:34 PM",
                "value": 72.19000244140625
            },
            {
                "time": "Mon, Apr 8, 3:34 PM",
                "value": 72.30000305175781
            },
            {
                "time": "Mon, Apr 8, 3:35 PM",
                "value": 72.43000030517578
            },
            {
                "time": "Mon, Apr 8, 3:35 PM",
                "value": 72.44999694824219
            },
            {
                "time": "Mon, Apr 8, 3:36 PM",
                "value": 72.58999633789062
            },
            {
                "time": "Mon, Apr 8, 3:36 PM",
                "value": 72.61000061035156
            },
            {
                "time": "Mon, Apr 8, 3:37 PM",
                "value": 72.79000091552734
            },
            {
                "time": "Mon, Apr 8, 3:37 PM",
                "value": 72.7699966430664
            },
            {
                "time": "Mon, Apr 8, 3:38 PM",
                "value": 72.93000030517578
            },
            {
                "time": "Mon, Apr 8, 3:38 PM",
                "value": 72.97000122070312
            },
            {
                "time": "Mon, Apr 8, 3:39 PM",
                "value": 73.08000183105469
            },
            {
                "time": "Mon, Apr 8, 3:39 PM",
                "value": 73.05999755859375
            },
            {
                "time": "Mon, Apr 8, 3:40 PM",
                "value": 72.98999786376953
            },
            {
                "time": "Mon, Apr 8, 3:40 PM",
                "value": 72.9000015258789
            },
            {
                "time": "Mon, Apr 8, 3:41 PM",
                "value": 72.9000015258789
            },
            {
                "time": "Mon, Apr 8, 3:41 PM",
                "value": 72.9000015258789
            },
            {
                "time": "Mon, Apr 8, 3:42 PM",
                "value": 72.93000030517578
            },
            {
                "time": "Mon, Apr 8, 3:42 PM",
                "value": 73.05999755859375
            },
            {
                "time": "Mon, Apr 8, 3:43 PM",
                "value": 73.12999725341797
            },
            {
                "time": "Mon, Apr 8, 3:43 PM",
                "value": 73.30999755859375
            },
            {
                "time": "Mon, Apr 8, 3:44 PM",
                "value": 73.44999694824219
            },
            {
                "time": "Mon, Apr 8, 3:44 PM",
                "value": 73.54000091552734
            },
            {
                "time": "Mon, Apr 8, 3:45 PM",
                "value": 73.44000244140625
            },
            {
                "time": "Mon, Apr 8, 3:45 PM",
                "value": 73.47000122070312
            },
            {
                "time": "Mon, Apr 8, 3:46 PM",
                "value": 73.48999786376953
            },
            {
                "time": "Mon, Apr 8, 3:46 PM",
                "value": 73.55999755859375
            },
            {
                "time": "Mon, Apr 8, 3:47 PM",
                "value": 73.55999755859375
            },
            {
                "time": "Mon, Apr 8, 3:47 PM",
                "value": 73.63999938964844
            },
            {
                "time": "Mon, Apr 8, 3:48 PM",
                "value": 73.70999908447266
            },
            {
                "time": "Mon, Apr 8, 3:48 PM",
                "value": 73.63999938964844
            },
            {
                "time": "Mon, Apr 8, 3:49 PM",
                "value": 73.6500015258789
            },
            {
                "time": "Mon, Apr 8, 3:49 PM",
                "value": 73.72000122070312
            },
            {
                "time": "Mon, Apr 8, 3:50 PM",
                "value": 73.66999816894531
            },
            {
                "time": "Mon, Apr 8, 3:50 PM",
                "value": 73.54000091552734
            },
            {
                "time": "Mon, Apr 8, 3:51 PM",
                "value": 73.62000274658203
            },
            {
                "time": "Mon, Apr 8, 3:51 PM",
                "value": 73.63999938964844
            },
            {
                "time": "Mon, Apr 8, 3:52 PM",
                "value": 73.77999877929688
            },
            {
                "time": "Mon, Apr 8, 3:52 PM",
                "value": 73.80000305175781
            },
            {
                "time": "Mon, Apr 8, 3:53 PM",
                "value": 73.95999908447266
            },
            {
                "time": "Mon, Apr 8, 3:53 PM",
                "value": 73.9800033569336
            },
            {
                "time": "Mon, Apr 8, 3:54 PM",
                "value": 74.01000213623047
            },
            {
                "time": "Mon, Apr 8, 3:54 PM",
                "value": 74
            },
            {
                "time": "Mon, Apr 8, 3:55 PM",
                "value": 74.02999877929688
            },
            {
                "time": "Mon, Apr 8, 3:55 PM",
                "value": 74.11000061035156
            },
            {
                "time": "Mon, Apr 8, 3:56 PM",
                "value": 74.16999816894531
            },
            {
                "time": "Mon, Apr 8, 3:56 PM",
                "value": 74.38999938964844
            },
            {
                "time": "Mon, Apr 8, 3:57 PM",
                "value": 74.5
            },
            {
                "time": "Mon, Apr 8, 3:57 PM",
                "value": 74.5
            },
            {
                "time": "Mon, Apr 8, 3:58 PM",
                "value": 74.55999755859375
            },
            {
                "time": "Mon, Apr 8, 3:58 PM",
                "value": 74.56999969482422
            },
            {
                "time": "Mon, Apr 8, 3:59 PM",
                "value": 74.55999755859375
            },
            {
                "time": "Mon, Apr 8, 3:59 PM",
                "value": 74.5
            },
            {
                "time": "Mon, Apr 8, 4:00 PM",
                "value": 74.61000061035156
            },
            {
                "time": "Mon, Apr 8, 4:00 PM",
                "value": 74.69999694824219
            },
            {
                "time": "Mon, Apr 8, 4:01 PM",
                "value": 74.69999694824219
            },
            {
                "time": "Mon, Apr 8, 4:01 PM",
                "value": 74.75
            },
            {
                "time": "Mon, Apr 8, 4:02 PM",
                "value": 74.79000091552734
            },
            {
                "time": "Mon, Apr 8, 4:02 PM",
                "value": 74.9000015258789
            },
            {
                "time": "Mon, Apr 8, 4:03 PM",
                "value": 74.97000122070312
            },
            {
                "time": "Mon, Apr 8, 4:03 PM",
                "value": 74.94999694824219
            },
            {
                "time": "Mon, Apr 8, 4:04 PM",
                "value": 74.75
            },
            {
                "time": "Mon, Apr 8, 4:04 PM",
                "value": 74.6500015258789
            },
            {
                "time": "Mon, Apr 8, 4:05 PM",
                "value": 74.55999755859375
            },
            {
                "time": "Mon, Apr 8, 4:05 PM",
                "value": 74.68000030517578
            },
            {
                "time": "Mon, Apr 8, 4:06 PM",
                "value": 74.72000122070312
            },
            {
                "time": "Mon, Apr 8, 4:06 PM",
                "value": 74.86000061035156
            },
            {
                "time": "Mon, Apr 8, 4:07 PM",
                "value": 75.04000091552734
            },
            {
                "time": "Mon, Apr 8, 4:07 PM",
                "value": 75.08000183105469
            },
            {
                "time": "Mon, Apr 8, 4:08 PM",
                "value": 75.08000183105469
            },
            {
                "time": "Mon, Apr 8, 4:08 PM",
                "value": 74.98999786376953
            },
            {
                "time": "Mon, Apr 8, 4:09 PM",
                "value": 75.05999755859375
            },
            {
                "time": "Mon, Apr 8, 4:09 PM",
                "value": 75.11000061035156
            },
            {
                "time": "Mon, Apr 8, 4:10 PM",
                "value": 75.20999908447266
            },
            {
                "time": "Mon, Apr 8, 4:10 PM",
                "value": 75.11000061035156
            },
            {
                "time": "Mon, Apr 8, 4:11 PM",
                "value": 75.37000274658203
            },
            {
                "time": "Mon, Apr 8, 4:11 PM",
                "value": 75.41999816894531
            },
            {
                "time": "Mon, Apr 8, 4:12 PM",
                "value": 75.51000213623047
            },
            {
                "time": "Mon, Apr 8, 4:12 PM",
                "value": 75.58999633789062
            },
            {
                "time": "Mon, Apr 8, 4:13 PM",
                "value": 75.37999725341797
            },
            {
                "time": "Mon, Apr 8, 4:13 PM",
                "value": 75.44000244140625
            },
            {
                "time": "Mon, Apr 8, 4:14 PM",
                "value": 75.4000015258789
            },
            {
                "time": "Mon, Apr 8, 4:14 PM",
                "value": 75.41999816894531
            },
            {
                "time": "Mon, Apr 8, 4:15 PM",
                "value": 75.33000183105469
            },
            {
                "time": "Mon, Apr 8, 4:15 PM",
                "value": 75.4000015258789
            },
            {
                "time": "Mon, Apr 8, 4:16 PM",
                "value": 75.55000305175781
            },
            {
                "time": "Mon, Apr 8, 4:16 PM",
                "value": 75.33000183105469
            },
            {
                "time": "Mon, Apr 8, 4:17 PM",
                "value": 75.13999938964844
            },
            {
                "time": "Mon, Apr 8, 4:17 PM",
                "value": 75.02999877929688
            },
            {
                "time": "Mon, Apr 8, 4:18 PM",
                "value": 74.79000091552734
            },
            {
                "time": "Mon, Apr 8, 4:18 PM",
                "value": 74.79000091552734
            },
            {
                "time": "Mon, Apr 8, 4:19 PM",
                "value": 74.87999725341797
            },
            {
                "time": "Mon, Apr 8, 4:19 PM",
                "value": 74.94999694824219
            },
            {
                "time": "Mon, Apr 8, 4:20 PM",
                "value": 74.9000015258789
            },
            {
                "time": "Mon, Apr 8, 4:20 PM",
                "value": 74.83000183105469
            },
            {
                "time": "Mon, Apr 8, 4:21 PM",
                "value": 74.68000030517578
            },
            {
                "time": "Mon, Apr 8, 4:21 PM",
                "value": 74.6500015258789
            },
            {
                "time": "Mon, Apr 8, 4:22 PM",
                "value": 74.83000183105469
            },
            {
                "time": "Mon, Apr 8, 4:22 PM",
                "value": 74.93000030517578
            },
            {
                "time": "Mon, Apr 8, 4:23 PM",
                "value": 75.05999755859375
            },
            {
                "time": "Mon, Apr 8, 4:23 PM",
                "value": 75.11000061035156
            },
            {
                "time": "Mon, Apr 8, 4:24 PM",
                "value": 75.30000305175781
            },
            {
                "time": "Mon, Apr 8, 4:24 PM",
                "value": 75.27999877929688
            },
            {
                "time": "Mon, Apr 8, 4:25 PM",
                "value": 75.26000213623047
            },
            {
                "time": "Mon, Apr 8, 4:25 PM",
                "value": 75.33000183105469
            },
            {
                "time": "Mon, Apr 8, 4:26 PM",
                "value": 75.23999786376953
            },
            {
                "time": "Mon, Apr 8, 4:26 PM",
                "value": 75.26000213623047
            },
            {
                "time": "Mon, Apr 8, 4:27 PM",
                "value": 75.13999938964844
            },
            {
                "time": "Mon, Apr 8, 4:27 PM",
                "value": 75.11000061035156
            },
            {
                "time": "Mon, Apr 8, 4:28 PM",
                "value": 75.01000213623047
            },
            {
                "time": "Mon, Apr 8, 4:28 PM",
                "value": 75.31999969482422
            },
            {
                "time": "Mon, Apr 8, 4:29 PM",
                "value": 75.27999877929688
            },
            {
                "time": "Mon, Apr 8, 4:29 PM",
                "value": 75.04000091552734
            },
            {
                "time": "Mon, Apr 8, 4:30 PM",
                "value": 74.94999694824219
            },
            {
                "time": "Mon, Apr 8, 4:30 PM",
                "value": 75.08000183105469
            },
            {
                "time": "Mon, Apr 8, 4:31 PM",
                "value": 75.20999908447266
            },
            {
                "time": "Mon, Apr 8, 4:31 PM",
                "value": 75.23999786376953
            },
            {
                "time": "Mon, Apr 8, 4:32 PM",
                "value": 75.20999908447266
            },
            {
                "time": "Mon, Apr 8, 4:32 PM",
                "value": 75.04000091552734
            },
            {
                "time": "Mon, Apr 8, 4:33 PM",
                "value": 75.04000091552734
            },
            {
                "time": "Mon, Apr 8, 4:33 PM",
                "value": 74.98999786376953
            },
            {
                "time": "Mon, Apr 8, 4:34 PM",
                "value": 75.04000091552734
            },
            {
                "time": "Mon, Apr 8, 4:34 PM",
                "value": 75.23999786376953
            },
            {
                "time": "Mon, Apr 8, 4:35 PM",
                "value": 75.55000305175781
            },
            {
                "time": "Mon, Apr 8, 4:35 PM",
                "value": 75.83999633789062
            },
            {
                "time": "Mon, Apr 8, 4:36 PM",
                "value": 75.77999877929688
            },
            {
                "time": "Mon, Apr 8, 4:36 PM",
                "value": 75.62000274658203
            },
            {
                "time": "Mon, Apr 8, 4:37 PM",
                "value": 75.4800033569336
            },
            {
                "time": "Mon, Apr 8, 4:37 PM",
                "value": 75.4800033569336
            },
            {
                "time": "Mon, Apr 8, 4:38 PM",
                "value": 75.55000305175781
            },
            {
                "time": "Mon, Apr 8, 4:38 PM",
                "value": 75.91000366210938
            },
            {
                "time": "Mon, Apr 8, 4:39 PM",
                "value": 75.91000366210938
            },
            {
                "time": "Mon, Apr 8, 4:39 PM",
                "value": 76
            },
            {
                "time": "Mon, Apr 8, 4:40 PM",
                "value": 76.0199966430664
            },
            {
                "time": "Mon, Apr 8, 4:40 PM",
                "value": 75.93000030517578
            },
            {
                "time": "Mon, Apr 8, 4:41 PM",
                "value": 75.69000244140625
            },
            {
                "time": "Mon, Apr 8, 4:41 PM",
                "value": 75.4800033569336
            },
            {
                "time": "Mon, Apr 8, 4:42 PM",
                "value": 75.3499984741211
            },
            {
                "time": "Mon, Apr 8, 4:42 PM",
                "value": 75.13999938964844
            },
            {
                "time": "Mon, Apr 8, 4:43 PM",
                "value": 75.0999984741211
            },
            {
                "time": "Mon, Apr 8, 4:43 PM",
                "value": 75.1500015258789
            },
            {
                "time": "Mon, Apr 8, 4:44 PM",
                "value": 75.19000244140625
            },
            {
                "time": "Mon, Apr 8, 4:44 PM",
                "value": 75.44000244140625
            },
            {
                "time": "Mon, Apr 8, 4:45 PM",
                "value": 75.37000274658203
            },
            {
                "time": "Mon, Apr 8, 4:45 PM",
                "value": 75.4800033569336
            },
            {
                "time": "Mon, Apr 8, 4:46 PM",
                "value": 75.4000015258789
            },
            {
                "time": "Mon, Apr 8, 4:46 PM",
                "value": 75.3499984741211
            },
            {
                "time": "Mon, Apr 8, 4:47 PM",
                "value": 75.11000061035156
            },
            {
                "time": "Mon, Apr 8, 4:47 PM",
                "value": 74.97000122070312
            },
            {
                "time": "Mon, Apr 8, 4:48 PM",
                "value": 74.93000030517578
            },
            {
                "time": "Mon, Apr 8, 4:48 PM",
                "value": 75.02999877929688
            },
            {
                "time": "Mon, Apr 8, 4:49 PM",
                "value": 75.02999877929688
            },
            {
                "time": "Mon, Apr 8, 4:49 PM",
                "value": 75.02999877929688
            },
            {
                "time": "Mon, Apr 8, 4:50 PM",
                "value": 75.13999938964844
            },
            {
                "time": "Mon, Apr 8, 4:50 PM",
                "value": 75.26000213623047
            },
            {
                "time": "Mon, Apr 8, 4:51 PM",
                "value": 75.4000015258789
            },
            {
                "time": "Mon, Apr 8, 4:51 PM",
                "value": 75.4800033569336
            },
            {
                "time": "Mon, Apr 8, 4:52 PM",
                "value": 75.33000183105469
            },
            {
                "time": "Mon, Apr 8, 4:52 PM",
                "value": 75.3499984741211
            },
            {
                "time": "Mon, Apr 8, 4:53 PM",
                "value": 75.4000015258789
            },
            {
                "time": "Mon, Apr 8, 4:53 PM",
                "value": 75.44000244140625
            },
            {
                "time": "Mon, Apr 8, 4:54 PM",
                "value": 75.33000183105469
            },
            {
                "time": "Mon, Apr 8, 4:54 PM",
                "value": 75.41999816894531
            },
            {
                "time": "Mon, Apr 8, 4:55 PM",
                "value": 75.55000305175781
            },
            {
                "time": "Mon, Apr 8, 4:55 PM",
                "value": 75.37000274658203
            },
            {
                "time": "Mon, Apr 8, 4:56 PM",
                "value": 75.41999816894531
            },
            {
                "time": "Mon, Apr 8, 4:56 PM",
                "value": 75.55999755859375
            },
            {
                "time": "Mon, Apr 8, 4:57 PM",
                "value": 75.63999938964844
            },
            {
                "time": "Mon, Apr 8, 4:57 PM",
                "value": 75.83999633789062
            },
            {
                "time": "Mon, Apr 8, 4:58 PM",
                "value": 76.0199966430664
            },
            {
                "time": "Mon, Apr 8, 4:58 PM",
                "value": 76.18000030517578
            },
            {
                "time": "Mon, Apr 8, 4:59 PM",
                "value": 76.16000366210938
            },
            {
                "time": "Mon, Apr 8, 4:59 PM",
                "value": 76.0199966430664
            },
            {
                "time": "Mon, Apr 8, 5:00 PM",
                "value": 75.77999877929688
            },
            {
                "time": "Mon, Apr 8, 5:00 PM",
                "value": 75.62000274658203
            },
            {
                "time": "Mon, Apr 8, 5:01 PM",
                "value": 75.69000244140625
            },
            {
                "time": "Mon, Apr 8, 5:01 PM",
                "value": 76
            },
            {
                "time": "Mon, Apr 8, 5:02 PM",
                "value": 76.19999694824219
            },
            {
                "time": "Mon, Apr 8, 5:02 PM",
                "value": 76.5
            },
            {
                "time": "Mon, Apr 8, 5:03 PM",
                "value": 76.73999786376953
            },
            {
                "time": "Mon, Apr 8, 5:03 PM",
                "value": 77.0999984741211
            },
            {
                "time": "Mon, Apr 8, 5:04 PM",
                "value": 76.8499984741211
            },
            {
                "time": "Mon, Apr 8, 5:04 PM",
                "value": 76.44999694824219
            },
            {
                "time": "Mon, Apr 8, 5:05 PM",
                "value": 76.30000305175781
            },
            {
                "time": "Mon, Apr 8, 5:05 PM",
                "value": 76.25
            },
            {
                "time": "Mon, Apr 8, 5:06 PM",
                "value": 76.08999633789062
            },
            {
                "time": "Mon, Apr 8, 5:06 PM",
                "value": 76.08999633789062
            },
            {
                "time": "Mon, Apr 8, 5:07 PM",
                "value": 76.22000122070312
            },
            {
                "time": "Mon, Apr 8, 5:07 PM",
                "value": 76.30000305175781
            },
            {
                "time": "Mon, Apr 8, 5:08 PM",
                "value": 76.43000030517578
            },
            {
                "time": "Mon, Apr 8, 5:08 PM",
                "value": 76.55999755859375
            },
            {
                "time": "Mon, Apr 8, 5:09 PM",
                "value": 76.80999755859375
            },
            {
                "time": "Mon, Apr 8, 5:09 PM",
                "value": 77.05999755859375
            },
            {
                "time": "Mon, Apr 8, 5:10 PM",
                "value": 76.98999786376953
            },
            {
                "time": "Mon, Apr 8, 5:10 PM",
                "value": 76.87999725341797
            },
            {
                "time": "Mon, Apr 8, 5:11 PM",
                "value": 76.94000244140625
            },
            {
                "time": "Mon, Apr 8, 5:11 PM",
                "value": 77.05999755859375
            },
            {
                "time": "Mon, Apr 8, 5:12 PM",
                "value": 77.05999755859375
            },
            {
                "time": "Mon, Apr 8, 5:12 PM",
                "value": 76.9000015258789
            },
            {
                "time": "Mon, Apr 8, 5:13 PM",
                "value": 76.5
            },
            {
                "time": "Mon, Apr 8, 5:13 PM",
                "value": 76.29000091552734
            },
            {
                "time": "Mon, Apr 8, 5:14 PM",
                "value": 76.05000305175781
            },
            {
                "time": "Mon, Apr 8, 5:14 PM",
                "value": 75.8499984741211
            },
            {
                "time": "Mon, Apr 8, 5:15 PM",
                "value": 75.62000274658203
            },
            {
                "time": "Mon, Apr 8, 5:15 PM",
                "value": 75.62000274658203
            },
            {
                "time": "Mon, Apr 8, 5:16 PM",
                "value": 75.58999633789062
            },
            {
                "time": "Mon, Apr 8, 5:16 PM",
                "value": 75.55999755859375
            },
            {
                "time": "Mon, Apr 8, 5:17 PM",
                "value": 75.75
            },
            {
                "time": "Mon, Apr 8, 5:17 PM",
                "value": 75.62000274658203
            },
            {
                "time": "Mon, Apr 8, 5:18 PM",
                "value": 75.69000244140625
            },
            {
                "time": "Mon, Apr 8, 5:18 PM",
                "value": 75.58999633789062
            },
            {
                "time": "Mon, Apr 8, 5:19 PM",
                "value": 75.66999816894531
            },
            {
                "time": "Mon, Apr 8, 5:19 PM",
                "value": 75.7699966430664
            },
            {
                "time": "Mon, Apr 8, 5:20 PM",
                "value": 75.87999725341797
            },
            {
                "time": "Mon, Apr 8, 5:20 PM",
                "value": 76.11000061035156
            },
            {
                "time": "Mon, Apr 8, 5:21 PM",
                "value": 76.18000030517578
            },
            {
                "time": "Mon, Apr 8, 5:21 PM",
                "value": 76.2699966430664
            },
            {
                "time": "Mon, Apr 8, 5:22 PM",
                "value": 76.22000122070312
            },
            {
                "time": "Mon, Apr 8, 5:22 PM",
                "value": 76.30000305175781
            },
            {
                "time": "Mon, Apr 8, 5:23 PM",
                "value": 76.47000122070312
            },
            {
                "time": "Mon, Apr 8, 5:23 PM",
                "value": 76.47000122070312
            },
            {
                "time": "Mon, Apr 8, 5:24 PM",
                "value": 76.48999786376953
            },
            {
                "time": "Mon, Apr 8, 5:24 PM",
                "value": 76.37999725341797
            },
            {
                "time": "Mon, Apr 8, 5:25 PM",
                "value": 76.11000061035156
            },
            {
                "time": "Mon, Apr 8, 5:25 PM",
                "value": 76
            },
            {
                "time": "Mon, Apr 8, 5:26 PM",
                "value": 76.19999694824219
            },
            {
                "time": "Mon, Apr 8, 5:26 PM",
                "value": 76.30000305175781
            },
            {
                "time": "Mon, Apr 8, 5:27 PM",
                "value": 76.47000122070312
            },
            {
                "time": "Mon, Apr 8, 5:27 PM",
                "value": 76.83000183105469
            },
            {
                "time": "Mon, Apr 8, 5:28 PM",
                "value": 77.04000091552734
            },
            {
                "time": "Mon, Apr 8, 5:28 PM",
                "value": 76.79000091552734
            },
            {
                "time": "Mon, Apr 8, 5:29 PM",
                "value": 76.58000183105469
            },
            {
                "time": "Mon, Apr 8, 5:29 PM",
                "value": 76.41000366210938
            },
            {
                "time": "Mon, Apr 8, 5:30 PM",
                "value": 76.4000015258789
            },
            {
                "time": "Mon, Apr 8, 5:30 PM",
                "value": 76.29000091552734
            },
            {
                "time": "Mon, Apr 8, 5:31 PM",
                "value": 76.4000015258789
            },
            {
                "time": "Mon, Apr 8, 5:31 PM",
                "value": 76.58000183105469
            },
            {
                "time": "Mon, Apr 8, 5:32 PM",
                "value": 76.66999816894531
            },
            {
                "time": "Mon, Apr 8, 5:32 PM",
                "value": 76.69000244140625
            },
            {
                "time": "Mon, Apr 8, 5:33 PM",
                "value": 76.6500015258789
            },
            {
                "time": "Mon, Apr 8, 5:33 PM",
                "value": 76.76000213623047
            },
            {
                "time": "Mon, Apr 8, 5:34 PM",
                "value": 76.61000061035156
            },
            {
                "time": "Mon, Apr 8, 5:34 PM",
                "value": 76.41000366210938
            },
            {
                "time": "Mon, Apr 8, 5:35 PM",
                "value": 76.44999694824219
            },
            {
                "time": "Mon, Apr 8, 5:35 PM",
                "value": 76.6500015258789
            },
            {
                "time": "Mon, Apr 8, 5:36 PM",
                "value": 76.72000122070312
            },
            {
                "time": "Mon, Apr 8, 5:36 PM",
                "value": 76.94999694824219
            },
            {
                "time": "Mon, Apr 8, 5:37 PM",
                "value": 77.02999877929688
            },
            {
                "time": "Mon, Apr 8, 5:37 PM",
                "value": 76.98999786376953
            },
            {
                "time": "Mon, Apr 8, 5:38 PM",
                "value": 76.87000274658203
            },
            {
                "time": "Mon, Apr 8, 5:38 PM",
                "value": 76.98999786376953
            },
            {
                "time": "Mon, Apr 8, 5:39 PM",
                "value": 76.77999877929688
            },
            {
                "time": "Mon, Apr 8, 5:39 PM",
                "value": 76.47000122070312
            },
            {
                "time": "Mon, Apr 8, 5:40 PM",
                "value": 76.30000305175781
            },
            {
                "time": "Mon, Apr 8, 5:40 PM",
                "value": 76.19999694824219
            },
            {
                "time": "Mon, Apr 8, 5:41 PM",
                "value": 76.29000091552734
            },
            {
                "time": "Mon, Apr 8, 5:41 PM",
                "value": 76.2699966430664
            },
            {
                "time": "Mon, Apr 8, 5:42 PM",
                "value": 76.37999725341797
            },
            {
                "time": "Mon, Apr 8, 5:42 PM",
                "value": 76.25
            },
            {
                "time": "Mon, Apr 8, 5:43 PM",
                "value": 76.22000122070312
            },
            {
                "time": "Mon, Apr 8, 5:43 PM",
                "value": 76.37999725341797
            },
            {
                "time": "Mon, Apr 8, 5:44 PM",
                "value": 76.6500015258789
            },
            {
                "time": "Mon, Apr 8, 5:44 PM",
                "value": 76.83000183105469
            },
            {
                "time": "Mon, Apr 8, 5:45 PM",
                "value": 76.91999816894531
            },
            {
                "time": "Mon, Apr 8, 5:45 PM",
                "value": 76.87999725341797
            },
            {
                "time": "Mon, Apr 8, 5:46 PM",
                "value": 76.98999786376953
            },
            {
                "time": "Mon, Apr 8, 5:46 PM",
                "value": 76.79000091552734
            },
            {
                "time": "Mon, Apr 8, 5:47 PM",
                "value": 76.69000244140625
            },
            {
                "time": "Mon, Apr 8, 5:47 PM",
                "value": 76.6500015258789
            },
            {
                "time": "Mon, Apr 8, 5:48 PM",
                "value": 76.69000244140625
            },
            {
                "time": "Mon, Apr 8, 5:48 PM",
                "value": 76.48999786376953
            },
            {
                "time": "Mon, Apr 8, 5:49 PM",
                "value": 76.18000030517578
            },
            {
                "time": "Mon, Apr 8, 5:49 PM",
                "value": 75.95999908447266
            },
            {
                "time": "Mon, Apr 8, 5:50 PM",
                "value": 75.83999633789062
            },
            {
                "time": "Mon, Apr 8, 5:50 PM",
                "value": 75.8499984741211
            },
            {
                "time": "Mon, Apr 8, 5:51 PM",
                "value": 75.94999694824219
            },
            {
                "time": "Mon, Apr 8, 5:51 PM",
                "value": 76.08999633789062
            },
            {
                "time": "Mon, Apr 8, 5:52 PM",
                "value": 76.11000061035156
            },
            {
                "time": "Mon, Apr 8, 5:52 PM",
                "value": 75.80000305175781
            },
            {
                "time": "Mon, Apr 8, 5:53 PM",
                "value": 75.7300033569336
            },
            {
                "time": "Mon, Apr 8, 5:53 PM",
                "value": 75.66000366210938
            },
            {
                "time": "Mon, Apr 8, 5:54 PM",
                "value": 75.48999786376953
            },
            {
                "time": "Mon, Apr 8, 5:54 PM",
                "value": 75.51000213623047
            },
            {
                "time": "Mon, Apr 8, 5:55 PM",
                "value": 75.70999908447266
            },
            {
                "time": "Mon, Apr 8, 5:55 PM",
                "value": 75.80000305175781
            },
            {
                "time": "Mon, Apr 8, 5:56 PM",
                "value": 76.08999633789062
            },
            {
                "time": "Mon, Apr 8, 5:56 PM",
                "value": 76.41000366210938
            },
            {
                "time": "Mon, Apr 8, 5:57 PM",
                "value": 76.73999786376953
            },
            {
                "time": "Mon, Apr 8, 5:57 PM",
                "value": 76.97000122070312
            },
            {
                "time": "Mon, Apr 8, 5:58 PM",
                "value": 76.76000213623047
            },
            {
                "time": "Mon, Apr 8, 5:58 PM",
                "value": 76.31999969482422
            },
            {
                "time": "Mon, Apr 8, 5:59 PM",
                "value": 75.9800033569336
            },
            {
                "time": "Mon, Apr 8, 5:59 PM",
                "value": 75.83999633789062
            },
            {
                "time": "Mon, Apr 8, 6:00 PM",
                "value": 75.62000274658203
            },
            {
                "time": "Mon, Apr 8, 6:00 PM",
                "value": 75.4800033569336
            },
            {
                "time": "Mon, Apr 8, 6:01 PM",
                "value": 75.4000015258789
            },
            {
                "time": "Mon, Apr 8, 6:01 PM",
                "value": 75.45999908447266
            },
            {
                "time": "Mon, Apr 8, 6:02 PM",
                "value": 75.26000213623047
            },
            {
                "time": "Mon, Apr 8, 6:02 PM",
                "value": 75.08000183105469
            },
            {
                "time": "Mon, Apr 8, 6:03 PM",
                "value": 75.04000091552734
            },
            {
                "time": "Mon, Apr 8, 6:03 PM",
                "value": 75.08000183105469
            },
            {
                "time": "Mon, Apr 8, 6:04 PM",
                "value": 75.05999755859375
            },
            {
                "time": "Mon, Apr 8, 6:04 PM",
                "value": 75.19000244140625
            },
            {
                "time": "Mon, Apr 8, 6:05 PM",
                "value": 75.31999969482422
            },
            {
                "time": "Mon, Apr 8, 6:05 PM",
                "value": 75.26000213623047
            },
            {
                "time": "Mon, Apr 8, 6:06 PM",
                "value": 75.1500015258789
            },
            {
                "time": "Mon, Apr 8, 6:06 PM",
                "value": 75.22000122070312
            },
            {
                "time": "Mon, Apr 8, 6:07 PM",
                "value": 75.26000213623047
            },
            {
                "time": "Mon, Apr 8, 6:07 PM",
                "value": 75.01000213623047
            },
            {
                "time": "Mon, Apr 8, 6:08 PM",
                "value": 75.04000091552734
            },
            {
                "time": "Mon, Apr 8, 6:08 PM",
                "value": 75.02999877929688
            },
            {
                "time": "Mon, Apr 8, 6:09 PM",
                "value": 75.01000213623047
            },
            {
                "time": "Mon, Apr 8, 6:09 PM",
                "value": 75.26000213623047
            },
            {
                "time": "Mon, Apr 8, 6:10 PM",
                "value": 75.30000305175781
            },
            {
                "time": "Mon, Apr 8, 6:10 PM",
                "value": 75.45999908447266
            },
            {
                "time": "Mon, Apr 8, 6:11 PM",
                "value": 75.62000274658203
            },
            {
                "time": "Mon, Apr 8, 6:11 PM",
                "value": 75.69000244140625
            },
            {
                "time": "Mon, Apr 8, 6:12 PM",
                "value": 75.77999877929688
            },
            {
                "time": "Mon, Apr 8, 6:12 PM",
                "value": 75.83999633789062
            },
            {
                "time": "Mon, Apr 8, 6:13 PM",
                "value": 75.80000305175781
            },
            {
                "time": "Mon, Apr 8, 6:13 PM",
                "value": 75.8499984741211
            },
            {
                "time": "Mon, Apr 8, 6:14 PM",
                "value": 76.05000305175781
            },
            {
                "time": "Mon, Apr 8, 6:14 PM",
                "value": 76.30000305175781
            },
            {
                "time": "Mon, Apr 8, 6:15 PM",
                "value": 76.36000061035156
            },
            {
                "time": "Mon, Apr 8, 6:15 PM",
                "value": 76.16000366210938
            },
            {
                "time": "Mon, Apr 8, 6:16 PM",
                "value": 76.22000122070312
            },
            {
                "time": "Mon, Apr 8, 6:16 PM",
                "value": 76.25
            },
            {
                "time": "Mon, Apr 8, 6:17 PM",
                "value": 76.37999725341797
            },
            {
                "time": "Mon, Apr 8, 6:17 PM",
                "value": 76.31999969482422
            },
            {
                "time": "Mon, Apr 8, 6:18 PM",
                "value": 76.37999725341797
            },
            {
                "time": "Mon, Apr 8, 6:18 PM",
                "value": 76.18000030517578
            },
            {
                "time": "Mon, Apr 8, 6:19 PM",
                "value": 75.9800033569336
            },
            {
                "time": "Mon, Apr 8, 6:19 PM",
                "value": 75.94999694824219
            },
            {
                "time": "Mon, Apr 8, 6:20 PM",
                "value": 75.80000305175781
            },
            {
                "time": "Mon, Apr 8, 6:20 PM",
                "value": 75.94999694824219
            },
            {
                "time": "Mon, Apr 8, 6:21 PM",
                "value": 76.12999725341797
            },
            {
                "time": "Mon, Apr 8, 6:21 PM",
                "value": 76.06999969482422
            },
            {
                "time": "Mon, Apr 8, 6:22 PM",
                "value": 76.2300033569336
            },
            {
                "time": "Mon, Apr 8, 6:22 PM",
                "value": 76.44999694824219
            },
            {
                "time": "Mon, Apr 8, 6:23 PM",
                "value": 76.5
            },
            {
                "time": "Mon, Apr 8, 6:23 PM",
                "value": 76.72000122070312
            },
            {
                "time": "Mon, Apr 8, 6:24 PM",
                "value": 76.73999786376953
            },
            {
                "time": "Mon, Apr 8, 6:24 PM",
                "value": 76.97000122070312
            },
            {
                "time": "Mon, Apr 8, 6:25 PM",
                "value": 77.0999984741211
            },
            {
                "time": "Mon, Apr 8, 6:25 PM",
                "value": 77.30000305175781
            },
            {
                "time": "Mon, Apr 8, 6:26 PM",
                "value": 77.48999786376953
            },
            {
                "time": "Mon, Apr 8, 6:26 PM",
                "value": 77.37000274658203
            },
            {
                "time": "Mon, Apr 8, 6:27 PM",
                "value": 77.19000244140625
            },
            {
                "time": "Mon, Apr 8, 6:27 PM",
                "value": 76.91999816894531
            },
            {
                "time": "Mon, Apr 8, 6:28 PM",
                "value": 76.66999816894531
            },
            {
                "time": "Mon, Apr 8, 6:28 PM",
                "value": 76.66999816894531
            },
            {
                "time": "Mon, Apr 8, 6:29 PM",
                "value": 76.55999755859375
            },
            {
                "time": "Mon, Apr 8, 6:29 PM",
                "value": 76.55999755859375
            },
            {
                "time": "Mon, Apr 8, 6:30 PM",
                "value": 76.54000091552734
            },
            {
                "time": "Mon, Apr 8, 6:30 PM",
                "value": 76.22000122070312
            },
            {
                "time": "Mon, Apr 8, 6:31 PM",
                "value": 75.94999694824219
            },
            {
                "time": "Mon, Apr 8, 6:31 PM",
                "value": 75.83999633789062
            },
            {
                "time": "Mon, Apr 8, 6:32 PM",
                "value": 75.80000305175781
            },
            {
                "time": "Mon, Apr 8, 6:32 PM",
                "value": 75.63999938964844
            },
            {
                "time": "Mon, Apr 8, 6:33 PM",
                "value": 75.66000366210938
            },
            {
                "time": "Mon, Apr 8, 6:33 PM",
                "value": 75.55000305175781
            },
            {
                "time": "Mon, Apr 8, 6:34 PM",
                "value": 75.37000274658203
            },
            {
                "time": "Mon, Apr 8, 6:34 PM",
                "value": 75.27999877929688
            },
            {
                "time": "Mon, Apr 8, 6:35 PM",
                "value": 75.33000183105469
            },
            {
                "time": "Mon, Apr 8, 6:35 PM",
                "value": 75.44000244140625
            },
            {
                "time": "Mon, Apr 8, 6:36 PM",
                "value": 75.44000244140625
            },
            {
                "time": "Mon, Apr 8, 6:36 PM",
                "value": 75.51000213623047
            },
            {
                "time": "Mon, Apr 8, 6:37 PM",
                "value": 75.51000213623047
            },
            {
                "time": "Mon, Apr 8, 6:37 PM",
                "value": 75.45999908447266
            },
            {
                "time": "Mon, Apr 8, 6:38 PM",
                "value": 75.51000213623047
            },
            {
                "time": "Mon, Apr 8, 6:38 PM",
                "value": 75.37999725341797
            },
            {
                "time": "Mon, Apr 8, 6:39 PM",
                "value": 75.45999908447266
            },
            {
                "time": "Mon, Apr 8, 6:39 PM",
                "value": 75.44000244140625
            },
            {
                "time": "Mon, Apr 8, 6:40 PM",
                "value": 75.48999786376953
            },
            {
                "time": "Mon, Apr 8, 6:40 PM",
                "value": 75.37000274658203
            },
            {
                "time": "Mon, Apr 8, 6:41 PM",
                "value": 75.22000122070312
            },
            {
                "time": "Mon, Apr 8, 6:41 PM",
                "value": 75.22000122070312
            },
            {
                "time": "Mon, Apr 8, 6:42 PM",
                "value": 75.30000305175781
            },
            {
                "time": "Mon, Apr 8, 6:42 PM",
                "value": 75.23999786376953
            },
            {
                "time": "Mon, Apr 8, 6:43 PM",
                "value": 75.26000213623047
            },
            {
                "time": "Mon, Apr 8, 6:43 PM",
                "value": 75.30000305175781
            },
            {
                "time": "Mon, Apr 8, 6:44 PM",
                "value": 75.37000274658203
            },
            {
                "time": "Mon, Apr 8, 6:44 PM",
                "value": 75.44000244140625
            },
            {
                "time": "Mon, Apr 8, 6:45 PM",
                "value": 75.45999908447266
            },
            {
                "time": "Mon, Apr 8, 6:45 PM",
                "value": 75.44000244140625
            },
            {
                "time": "Mon, Apr 8, 6:46 PM",
                "value": 75.26000213623047
            },
            {
                "time": "Mon, Apr 8, 6:46 PM",
                "value": 74.83000183105469
            },
            {
                "time": "Mon, Apr 8, 6:47 PM",
                "value": 74.86000061035156
            },
            {
                "time": "Mon, Apr 8, 6:47 PM",
                "value": 74.93000030517578
            },
            {
                "time": "Mon, Apr 8, 6:48 PM",
                "value": 74.97000122070312
            },
            {
                "time": "Mon, Apr 8, 6:48 PM",
                "value": 75.04000091552734
            },
            {
                "time": "Mon, Apr 8, 6:49 PM",
                "value": 75.05999755859375
            },
            {
                "time": "Mon, Apr 8, 6:49 PM",
                "value": 75.08000183105469
            },
            {
                "time": "Mon, Apr 8, 6:50 PM",
                "value": 75.1500015258789
            },
            {
                "time": "Mon, Apr 8, 6:50 PM",
                "value": 75.08000183105469
            },
            {
                "time": "Mon, Apr 8, 6:51 PM",
                "value": 75.01000213623047
            },
            {
                "time": "Mon, Apr 8, 6:51 PM",
                "value": 74.9000015258789
            },
            {
                "time": "Mon, Apr 8, 6:52 PM",
                "value": 74.83000183105469
            },
            {
                "time": "Mon, Apr 8, 6:52 PM",
                "value": 74.87999725341797
            },
            {
                "time": "Mon, Apr 8, 6:53 PM",
                "value": 74.83000183105469
            },
            {
                "time": "Mon, Apr 8, 6:53 PM",
                "value": 74.93000030517578
            },
            {
                "time": "Mon, Apr 8, 6:54 PM",
                "value": 74.86000061035156
            },
            {
                "time": "Mon, Apr 8, 6:54 PM",
                "value": 74.83000183105469
            },
            {
                "time": "Mon, Apr 8, 6:55 PM",
                "value": 74.83999633789062
            },
            {
                "time": "Mon, Apr 8, 6:55 PM",
                "value": 75.08000183105469
            },
            {
                "time": "Mon, Apr 8, 6:56 PM",
                "value": 75.16999816894531
            },
            {
                "time": "Mon, Apr 8, 6:56 PM",
                "value": 75.13999938964844
            },
            {
                "time": "Mon, Apr 8, 6:57 PM",
                "value": 75.11000061035156
            },
            {
                "time": "Mon, Apr 8, 6:57 PM",
                "value": 74.91999816894531
            },
            {
                "time": "Mon, Apr 8, 6:58 PM",
                "value": 74.79000091552734
            },
            {
                "time": "Mon, Apr 8, 6:58 PM",
                "value": 74.87999725341797
            },
            {
                "time": "Mon, Apr 8, 6:59 PM",
                "value": 74.9000015258789
            },
            {
                "time": "Mon, Apr 8, 6:59 PM",
                "value": 75.04000091552734
            },
            {
                "time": "Mon, Apr 8, 7:00 PM",
                "value": 75.05999755859375
            },
            {
                "time": "Mon, Apr 8, 7:00 PM",
                "value": 74.93000030517578
            },
            {
                "time": "Mon, Apr 8, 7:01 PM",
                "value": 75.02999877929688
            },
            {
                "time": "Mon, Apr 8, 7:01 PM",
                "value": 74.97000122070312
            },
            {
                "time": "Mon, Apr 8, 7:02 PM",
                "value": 74.9000015258789
            },
            {
                "time": "Mon, Apr 8, 7:02 PM",
                "value": 74.54000091552734
            },
            {
                "time": "Mon, Apr 8, 7:03 PM",
                "value": 74.44999694824219
            },
            {
                "time": "Mon, Apr 8, 7:03 PM",
                "value": 74.43000030517578
            },
            {
                "time": "Mon, Apr 8, 7:04 PM",
                "value": 74.43000030517578
            },
            {
                "time": "Mon, Apr 8, 7:04 PM",
                "value": 74.61000061035156
            },
            {
                "time": "Mon, Apr 8, 7:05 PM",
                "value": 74.69999694824219
            },
            {
                "time": "Mon, Apr 8, 7:05 PM",
                "value": 74.80999755859375
            },
            {
                "time": "Mon, Apr 8, 7:06 PM",
                "value": 74.83000183105469
            },
            {
                "time": "Mon, Apr 8, 7:06 PM",
                "value": 74.86000061035156
            },
            {
                "time": "Mon, Apr 8, 7:07 PM",
                "value": 74.79000091552734
            },
            {
                "time": "Mon, Apr 8, 7:07 PM",
                "value": 74.94999694824219
            },
            {
                "time": "Mon, Apr 8, 7:08 PM",
                "value": 74.7699966430664
            },
            {
                "time": "Mon, Apr 8, 7:08 PM",
                "value": 74.7699966430664
            },
            {
                "time": "Mon, Apr 8, 7:09 PM",
                "value": 74.83999633789062
            },
            {
                "time": "Mon, Apr 8, 7:09 PM",
                "value": 74.86000061035156
            },
            {
                "time": "Mon, Apr 8, 7:10 PM",
                "value": 74.79000091552734
            },
            {
                "time": "Mon, Apr 8, 7:10 PM",
                "value": 74.83000183105469
            },
            {
                "time": "Mon, Apr 8, 7:11 PM",
                "value": 74.91999816894531
            },
            {
                "time": "Mon, Apr 8, 7:11 PM",
                "value": 74.97000122070312
            },
            {
                "time": "Mon, Apr 8, 7:12 PM",
                "value": 74.86000061035156
            },
            {
                "time": "Mon, Apr 8, 7:12 PM",
                "value": 74.79000091552734
            },
            {
                "time": "Mon, Apr 8, 7:13 PM",
                "value": 74.68000030517578
            },
            {
                "time": "Mon, Apr 8, 7:13 PM",
                "value": 74.61000061035156
            },
            {
                "time": "Mon, Apr 8, 7:14 PM",
                "value": 74.61000061035156
            },
            {
                "time": "Mon, Apr 8, 7:14 PM",
                "value": 74.66000366210938
            },
            {
                "time": "Mon, Apr 8, 7:15 PM",
                "value": 74.37999725341797
            },
            {
                "time": "Mon, Apr 8, 7:15 PM",
                "value": 73.95999908447266
            },
            {
                "time": "Mon, Apr 8, 7:16 PM",
                "value": 73.80000305175781
            },
            {
                "time": "Mon, Apr 8, 7:16 PM",
                "value": 73.95999908447266
            },
            {
                "time": "Mon, Apr 8, 7:17 PM",
                "value": 74.13999938964844
            },
            {
                "time": "Mon, Apr 8, 7:17 PM",
                "value": 74.25
            },
            {
                "time": "Mon, Apr 8, 7:18 PM",
                "value": 74.05000305175781
            },
            {
                "time": "Mon, Apr 8, 7:18 PM",
                "value": 74.29000091552734
            },
            {
                "time": "Mon, Apr 8, 7:19 PM",
                "value": 74.44999694824219
            },
            {
                "time": "Mon, Apr 8, 7:19 PM",
                "value": 74.30000305175781
            },
            {
                "time": "Mon, Apr 8, 7:20 PM",
                "value": 73.76000213623047
            },
            {
                "time": "Mon, Apr 8, 7:20 PM",
                "value": 73.70999908447266
            },
            {
                "time": "Mon, Apr 8, 7:21 PM",
                "value": 73.70999908447266
            },
            {
                "time": "Mon, Apr 8, 7:21 PM",
                "value": 73.48999786376953
            },
            {
                "time": "Mon, Apr 8, 7:22 PM",
                "value": 73.36000061035156
            },
            {
                "time": "Mon, Apr 8, 7:22 PM",
                "value": 73.41999816894531
            },
            {
                "time": "Mon, Apr 8, 7:23 PM",
                "value": 73.36000061035156
            },
            {
                "time": "Mon, Apr 8, 7:23 PM",
                "value": 73.44999694824219
            },
            {
                "time": "Mon, Apr 8, 7:24 PM",
                "value": 73.44999694824219
            },
            {
                "time": "Mon, Apr 8, 7:24 PM",
                "value": 73.33000183105469
            },
            {
                "time": "Mon, Apr 8, 7:25 PM",
                "value": 73.37999725341797
            },
            {
                "time": "Mon, Apr 8, 7:25 PM",
                "value": 73.44000244140625
            },
            {
                "time": "Mon, Apr 8, 7:26 PM",
                "value": 73.52999877929688
            },
            {
                "time": "Mon, Apr 8, 7:26 PM",
                "value": 73.70999908447266
            },
            {
                "time": "Mon, Apr 8, 7:27 PM",
                "value": 73.81999969482422
            },
            {
                "time": "Mon, Apr 8, 7:27 PM",
                "value": 73.77999877929688
            },
            {
                "time": "Mon, Apr 8, 7:28 PM",
                "value": 73.5999984741211
            },
            {
                "time": "Mon, Apr 8, 7:28 PM",
                "value": 73.73999786376953
            },
            {
                "time": "Mon, Apr 8, 7:29 PM",
                "value": 73.73999786376953
            },
            {
                "time": "Mon, Apr 8, 7:29 PM",
                "value": 73.66999816894531
            },
            {
                "time": "Mon, Apr 8, 7:30 PM",
                "value": 73.73999786376953
            },
            {
                "time": "Mon, Apr 8, 7:30 PM",
                "value": 73.48999786376953
            },
            {
                "time": "Mon, Apr 8, 7:31 PM",
                "value": 73.47000122070312
            },
            {
                "time": "Mon, Apr 8, 7:31 PM",
                "value": 73.1500015258789
            },
            {
                "time": "Mon, Apr 8, 7:32 PM",
                "value": 73.30999755859375
            },
            {
                "time": "Mon, Apr 8, 7:32 PM",
                "value": 73.3499984741211
            },
            {
                "time": "Mon, Apr 8, 7:33 PM",
                "value": 73.2699966430664
            },
            {
                "time": "Mon, Apr 8, 7:33 PM",
                "value": 73.36000061035156
            },
            {
                "time": "Mon, Apr 8, 7:34 PM",
                "value": 73.23999786376953
            },
            {
                "time": "Mon, Apr 8, 7:34 PM",
                "value": 73.52999877929688
            },
            {
                "time": "Mon, Apr 8, 7:35 PM",
                "value": 73.5999984741211
            },
            {
                "time": "Mon, Apr 8, 7:35 PM",
                "value": 73.6500015258789
            },
            {
                "time": "Mon, Apr 8, 7:36 PM",
                "value": 73.69000244140625
            },
            {
                "time": "Mon, Apr 8, 7:36 PM",
                "value": 73.37999725341797
            },
            {
                "time": "Mon, Apr 8, 7:37 PM",
                "value": 73.16999816894531
            },
            {
                "time": "Mon, Apr 8, 7:37 PM",
                "value": 73.12999725341797
            },
            {
                "time": "Mon, Apr 8, 7:38 PM",
                "value": 72.80000305175781
            },
            {
                "time": "Mon, Apr 8, 7:38 PM",
                "value": 72.7699966430664
            },
            {
                "time": "Mon, Apr 8, 7:39 PM",
                "value": 72.9000015258789
            },
            {
                "time": "Mon, Apr 8, 7:39 PM",
                "value": 72.83999633789062
            },
            {
                "time": "Mon, Apr 8, 7:40 PM",
                "value": 73.04000091552734
            },
            {
                "time": "Mon, Apr 8, 7:40 PM",
                "value": 73.1500015258789
            },
            {
                "time": "Mon, Apr 8, 7:41 PM",
                "value": 73.0199966430664
            },
            {
                "time": "Mon, Apr 8, 7:41 PM",
                "value": 72.93000030517578
            },
            {
                "time": "Mon, Apr 8, 7:42 PM",
                "value": 72.7699966430664
            },
            {
                "time": "Mon, Apr 8, 7:42 PM",
                "value": 72.79000091552734
            },
            {
                "time": "Mon, Apr 8, 7:43 PM",
                "value": 72.91000366210938
            },
            {
                "time": "Mon, Apr 8, 7:43 PM",
                "value": 73.08000183105469
            },
            {
                "time": "Mon, Apr 8, 7:44 PM",
                "value": 72.93000030517578
            },
            {
                "time": "Mon, Apr 8, 7:44 PM",
                "value": 72.69999694824219
            },
            {
                "time": "Mon, Apr 8, 7:45 PM",
                "value": 72.61000061035156
            },
            {
                "time": "Mon, Apr 8, 7:45 PM",
                "value": 72.63999938964844
            },
            {
                "time": "Mon, Apr 8, 7:46 PM",
                "value": 72.5
            },
            {
                "time": "Mon, Apr 8, 7:46 PM",
                "value": 72.33999633789062
            },
            {
                "time": "Mon, Apr 8, 7:47 PM",
                "value": 72.30000305175781
            },
            {
                "time": "Mon, Apr 8, 7:47 PM",
                "value": 72.44999694824219
            },
            {
                "time": "Mon, Apr 8, 7:48 PM",
                "value": 72.70999908447266
            },
            {
                "time": "Mon, Apr 8, 7:48 PM",
                "value": 72.87999725341797
            },
            {
                "time": "Mon, Apr 8, 7:49 PM",
                "value": 72.81999969482422
            },
            {
                "time": "Mon, Apr 8, 7:49 PM",
                "value": 72.5199966430664
            },
            {
                "time": "Mon, Apr 8, 7:50 PM",
                "value": 72.30000305175781
            },
            {
                "time": "Mon, Apr 8, 7:50 PM",
                "value": 72.08000183105469
            },
            {
                "time": "Mon, Apr 8, 7:51 PM",
                "value": 72.44999694824219
            },
            {
                "time": "Mon, Apr 8, 7:51 PM",
                "value": 72.4800033569336
            },
            {
                "time": "Mon, Apr 8, 7:52 PM",
                "value": 72.45999908447266
            },
            {
                "time": "Mon, Apr 8, 7:52 PM",
                "value": 72.12000274658203
            },
            {
                "time": "Mon, Apr 8, 7:53 PM",
                "value": 71.73999786376953
            },
            {
                "time": "Mon, Apr 8, 7:53 PM",
                "value": 71.29000091552734
            },
            {
                "time": "Mon, Apr 8, 7:54 PM",
                "value": 71.72000122070312
            },
            {
                "time": "Mon, Apr 8, 7:54 PM",
                "value": 71.94000244140625
            },
            {
                "time": "Mon, Apr 8, 7:55 PM",
                "value": 71.97000122070312
            },
            {
                "time": "Mon, Apr 8, 7:55 PM",
                "value": 71.76000213623047
            },
            {
                "time": "Mon, Apr 8, 7:56 PM",
                "value": 71.9000015258789
            },
            {
                "time": "Mon, Apr 8, 7:56 PM",
                "value": 71.94000244140625
            },
            {
                "time": "Mon, Apr 8, 7:57 PM",
                "value": 71.97000122070312
            },
            {
                "time": "Mon, Apr 8, 7:57 PM",
                "value": 71.9000015258789
            },
            {
                "time": "Mon, Apr 8, 7:58 PM",
                "value": 72.05999755859375
            },
            {
                "time": "Mon, Apr 8, 7:58 PM",
                "value": 72.33999633789062
            },
            {
                "time": "Mon, Apr 8, 7:59 PM",
                "value": 72.37000274658203
            },
            {
                "time": "Mon, Apr 8, 7:59 PM",
                "value": 72.27999877929688
            },
            {
                "time": "Mon, Apr 8, 8:00 PM",
                "value": 72.2300033569336
            },
            {
                "time": "Mon, Apr 8, 8:00 PM",
                "value": 72.12000274658203
            },
            {
                "time": "Mon, Apr 8, 8:01 PM",
                "value": 71.73999786376953
            },
            {
                "time": "Mon, Apr 8, 8:01 PM",
                "value": 71.69000244140625
            },
            {
                "time": "Mon, Apr 8, 8:02 PM",
                "value": 71.87999725341797
            },
            {
                "time": "Mon, Apr 8, 8:02 PM",
                "value": 71.95999908447266
            },
            {
                "time": "Mon, Apr 8, 8:03 PM",
                "value": 72.05000305175781
            },
            {
                "time": "Mon, Apr 8, 8:03 PM",
                "value": 72.05999755859375
            },
            {
                "time": "Mon, Apr 8, 8:04 PM",
                "value": 72.02999877929688
            },
            {
                "time": "Mon, Apr 8, 8:04 PM",
                "value": 71.87000274658203
            },
            {
                "time": "Mon, Apr 8, 8:05 PM",
                "value": 71.80999755859375
            },
            {
                "time": "Mon, Apr 8, 8:05 PM",
                "value": 71.94000244140625
            },
            {
                "time": "Mon, Apr 8, 8:06 PM",
                "value": 72.08000183105469
            },
            {
                "time": "Mon, Apr 8, 8:06 PM",
                "value": 71.98999786376953
            },
            {
                "time": "Mon, Apr 8, 8:07 PM",
                "value": 71.87000274658203
            },
            {
                "time": "Mon, Apr 8, 8:07 PM",
                "value": 71.7699966430664
            },
            {
                "time": "Mon, Apr 8, 8:08 PM",
                "value": 71.69000244140625
            },
            {
                "time": "Mon, Apr 8, 8:08 PM",
                "value": 71.69000244140625
            },
            {
                "time": "Mon, Apr 8, 8:09 PM",
                "value": 71.69999694824219
            },
            {
                "time": "Mon, Apr 8, 8:09 PM",
                "value": 71.58999633789062
            },
            {
                "time": "Mon, Apr 8, 8:10 PM",
                "value": 71.69000244140625
            },
            {
                "time": "Mon, Apr 8, 8:10 PM",
                "value": 71.87000274658203
            },
            {
                "time": "Mon, Apr 8, 8:11 PM",
                "value": 71.76000213623047
            },
            {
                "time": "Mon, Apr 8, 8:11 PM",
                "value": 71.48999786376953
            },
            {
                "time": "Mon, Apr 8, 8:12 PM",
                "value": 71.4000015258789
            },
            {
                "time": "Mon, Apr 8, 8:12 PM",
                "value": 71.36000061035156
            },
            {
                "time": "Mon, Apr 8, 8:13 PM",
                "value": 71.41999816894531
            },
            {
                "time": "Mon, Apr 8, 8:13 PM",
                "value": 71.58000183105469
            },
            {
                "time": "Mon, Apr 8, 8:14 PM",
                "value": 71.76000213623047
            },
            {
                "time": "Mon, Apr 8, 8:14 PM",
                "value": 71.87999725341797
            },
            {
                "time": "Mon, Apr 8, 8:15 PM",
                "value": 71.79000091552734
            },
            {
                "time": "Mon, Apr 8, 8:15 PM",
                "value": 71.72000122070312
            },
            {
                "time": "Mon, Apr 8, 8:16 PM",
                "value": 71.61000061035156
            },
            {
                "time": "Mon, Apr 8, 8:16 PM",
                "value": 71.69000244140625
            },
            {
                "time": "Mon, Apr 8, 8:17 PM",
                "value": 71.55999755859375
            },
            {
                "time": "Mon, Apr 8, 8:17 PM",
                "value": 71.58000183105469
            },
            {
                "time": "Mon, Apr 8, 8:18 PM",
                "value": 71.58000183105469
            },
            {
                "time": "Mon, Apr 8, 8:18 PM",
                "value": 71.43000030517578
            },
            {
                "time": "Mon, Apr 8, 8:19 PM",
                "value": 71.2699966430664
            },
            {
                "time": "Mon, Apr 8, 8:19 PM",
                "value": 71.25
            },
            {
                "time": "Mon, Apr 8, 8:20 PM",
                "value": 70.9800033569336
            },
            {
                "time": "Mon, Apr 8, 8:20 PM",
                "value": 70.75
            },
            {
                "time": "Mon, Apr 8, 8:21 PM",
                "value": 70.5999984741211
            },
            {
                "time": "Mon, Apr 8, 8:21 PM",
                "value": 70.8499984741211
            },
            {
                "time": "Mon, Apr 8, 8:22 PM",
                "value": 71.05000305175781
            },
            {
                "time": "Mon, Apr 8, 8:22 PM",
                "value": 71
            },
            {
                "time": "Mon, Apr 8, 8:23 PM",
                "value": 70.80000305175781
            },
            {
                "time": "Mon, Apr 8, 8:23 PM",
                "value": 70.5999984741211
            },
            {
                "time": "Mon, Apr 8, 8:24 PM",
                "value": 70.51000213623047
            },
            {
                "time": "Mon, Apr 8, 8:24 PM",
                "value": 70.44000244140625
            },
            {
                "time": "Mon, Apr 8, 8:25 PM",
                "value": 70.23999786376953
            },
            {
                "time": "Mon, Apr 8, 8:25 PM",
                "value": 70.19000244140625
            },
            {
                "time": "Mon, Apr 8, 8:26 PM",
                "value": 70.20999908447266
            },
            {
                "time": "Mon, Apr 8, 8:26 PM",
                "value": 70.27999877929688
            },
            {
                "time": "Mon, Apr 8, 8:27 PM",
                "value": 70.48999786376953
            },
            {
                "time": "Mon, Apr 8, 8:27 PM",
                "value": 70.77999877929688
            },
            {
                "time": "Mon, Apr 8, 8:28 PM",
                "value": 71
            },
            {
                "time": "Mon, Apr 8, 8:28 PM",
                "value": 71.13999938964844
            },
            {
                "time": "Mon, Apr 8, 8:29 PM",
                "value": 71.18000030517578
            },
            {
                "time": "Mon, Apr 8, 8:29 PM",
                "value": 70.95999908447266
            },
            {
                "time": "Mon, Apr 8, 8:30 PM",
                "value": 70.87000274658203
            },
            {
                "time": "Mon, Apr 8, 8:30 PM",
                "value": 70.8499984741211
            },
            {
                "time": "Mon, Apr 8, 8:31 PM",
                "value": 70.88999938964844
            },
            {
                "time": "Mon, Apr 8, 8:31 PM",
                "value": 71
            },
            {
                "time": "Mon, Apr 8, 8:32 PM",
                "value": 70.95999908447266
            },
            {
                "time": "Mon, Apr 8, 8:32 PM",
                "value": 71.02999877929688
            },
            {
                "time": "Mon, Apr 8, 8:33 PM",
                "value": 70.9800033569336
            },
            {
                "time": "Mon, Apr 8, 8:33 PM",
                "value": 70.87000274658203
            },
            {
                "time": "Mon, Apr 8, 8:34 PM",
                "value": 70.88999938964844
            },
            {
                "time": "Mon, Apr 8, 8:34 PM",
                "value": 70.87000274658203
            },
            {
                "time": "Mon, Apr 8, 8:35 PM",
                "value": 70.8499984741211
            },
            {
                "time": "Mon, Apr 8, 8:35 PM",
                "value": 70.91000366210938
            },
            {
                "time": "Mon, Apr 8, 8:36 PM",
                "value": 70.95999908447266
            },
            {
                "time": "Mon, Apr 8, 8:36 PM",
                "value": 70.9800033569336
            },
            {
                "time": "Mon, Apr 8, 8:37 PM",
                "value": 70.93000030517578
            },
            {
                "time": "Mon, Apr 8, 8:37 PM",
                "value": 70.75
            },
            {
                "time": "Mon, Apr 8, 8:38 PM",
                "value": 70.66999816894531
            },
            {
                "time": "Mon, Apr 8, 8:38 PM",
                "value": 70.70999908447266
            },
            {
                "time": "Mon, Apr 8, 8:39 PM",
                "value": 70.75
            },
            {
                "time": "Mon, Apr 8, 8:39 PM",
                "value": 70.66999816894531
            },
            {
                "time": "Mon, Apr 8, 8:40 PM",
                "value": 70.56999969482422
            },
            {
                "time": "Mon, Apr 8, 8:40 PM",
                "value": 70.51000213623047
            },
            {
                "time": "Mon, Apr 8, 8:41 PM",
                "value": 70.51000213623047
            },
            {
                "time": "Mon, Apr 8, 8:41 PM",
                "value": 70.45999908447266
            },
            {
                "time": "Mon, Apr 8, 8:42 PM",
                "value": 70.3499984741211
            },
            {
                "time": "Mon, Apr 8, 8:42 PM",
                "value": 70.20999908447266
            },
            {
                "time": "Mon, Apr 8, 8:43 PM",
                "value": 70.0999984741211
            },
            {
                "time": "Mon, Apr 8, 8:43 PM",
                "value": 70.0999984741211
            },
            {
                "time": "Mon, Apr 8, 8:44 PM",
                "value": 70.12999725341797
            },
            {
                "time": "Mon, Apr 8, 8:44 PM",
                "value": 70.16999816894531
            },
            {
                "time": "Mon, Apr 8, 8:45 PM",
                "value": 70.16999816894531
            },
            {
                "time": "Mon, Apr 8, 8:45 PM",
                "value": 70.12999725341797
            },
            {
                "time": "Mon, Apr 8, 8:46 PM",
                "value": 70.01000213623047
            },
            {
                "time": "Mon, Apr 8, 8:46 PM",
                "value": 69.9000015258789
            },
            {
                "time": "Mon, Apr 8, 8:47 PM",
                "value": 69.80999755859375
            },
            {
                "time": "Mon, Apr 8, 8:47 PM",
                "value": 69.79000091552734
            },
            {
                "time": "Mon, Apr 8, 8:48 PM",
                "value": 69.7699966430664
            },
            {
                "time": "Mon, Apr 8, 8:48 PM",
                "value": 69.69999694824219
            },
            {
                "time": "Mon, Apr 8, 8:49 PM",
                "value": 69.62999725341797
            },
            {
                "time": "Mon, Apr 8, 8:49 PM",
                "value": 69.58999633789062
            },
            {
                "time": "Mon, Apr 8, 8:50 PM",
                "value": 69.5
            },
            {
                "time": "Mon, Apr 8, 8:50 PM",
                "value": 69.43000030517578
            },
            {
                "time": "Mon, Apr 8, 8:51 PM",
                "value": 69.43000030517578
            },
            {
                "time": "Mon, Apr 8, 8:51 PM",
                "value": 69.37999725341797
            },
            {
                "time": "Mon, Apr 8, 8:52 PM",
                "value": 69.20999908447266
            },
            {
                "time": "Mon, Apr 8, 8:52 PM",
                "value": 69.01000213623047
            },
            {
                "time": "Mon, Apr 8, 8:53 PM",
                "value": 68.81999969482422
            },
            {
                "time": "Mon, Apr 8, 8:53 PM",
                "value": 68.6500015258789
            },
            {
                "time": "Mon, Apr 8, 8:54 PM",
                "value": 68.5999984741211
            },
            {
                "time": "Mon, Apr 8, 8:54 PM",
                "value": 68.55999755859375
            },
            {
                "time": "Mon, Apr 8, 8:55 PM",
                "value": 68.76000213623047
            },
            {
                "time": "Mon, Apr 8, 8:55 PM",
                "value": 69.20999908447266
            },
            {
                "time": "Mon, Apr 8, 8:56 PM",
                "value": 69.31999969482422
            },
            {
                "time": "Mon, Apr 8, 8:56 PM",
                "value": 69.2300033569336
            },
            {
                "time": "Mon, Apr 8, 8:57 PM",
                "value": 69.13999938964844
            },
            {
                "time": "Mon, Apr 8, 8:57 PM",
                "value": 69.12000274658203
            },
            {
                "time": "Mon, Apr 8, 8:58 PM",
                "value": 69.18000030517578
            },
            {
                "time": "Mon, Apr 8, 8:58 PM",
                "value": 69.01000213623047
            },
            {
                "time": "Mon, Apr 8, 8:59 PM",
                "value": 68.9000015258789
            },
            {
                "time": "Mon, Apr 8, 8:59 PM",
                "value": 68.77999877929688
            },
            {
                "time": "Mon, Apr 8, 9:00 PM",
                "value": 68.69000244140625
            },
            {
                "time": "Mon, Apr 8, 9:00 PM",
                "value": 68.69000244140625
            },
            {
                "time": "Mon, Apr 8, 9:01 PM",
                "value": 68.69000244140625
            },
            {
                "time": "Mon, Apr 8, 9:01 PM",
                "value": 68.73999786376953
            },
            {
                "time": "Mon, Apr 8, 9:02 PM",
                "value": 68.83000183105469
            },
            {
                "time": "Mon, Apr 8, 9:02 PM",
                "value": 68.80000305175781
            },
            {
                "time": "Mon, Apr 8, 9:03 PM",
                "value": 68.81999969482422
            },
            {
                "time": "Mon, Apr 8, 9:03 PM",
                "value": 68.8499984741211
            },
            {
                "time": "Mon, Apr 8, 9:04 PM",
                "value": 68.81999969482422
            },
            {
                "time": "Mon, Apr 8, 9:04 PM",
                "value": 68.69000244140625
            },
            {
                "time": "Mon, Apr 8, 9:05 PM",
                "value": 68.6500015258789
            },
            {
                "time": "Mon, Apr 8, 9:05 PM",
                "value": 68.72000122070312
            },
            {
                "time": "Mon, Apr 8, 9:06 PM",
                "value": 68.80000305175781
            },
            {
                "time": "Mon, Apr 8, 9:06 PM",
                "value": 68.88999938964844
            },
            {
                "time": "Mon, Apr 8, 9:07 PM",
                "value": 68.81999969482422
            },
            {
                "time": "Mon, Apr 8, 9:07 PM",
                "value": 68.69000244140625
            },
            {
                "time": "Mon, Apr 8, 9:08 PM",
                "value": 68.54000091552734
            },
            {
                "time": "Mon, Apr 8, 9:08 PM",
                "value": 68.52999877929688
            },
            {
                "time": "Mon, Apr 8, 9:09 PM",
                "value": 68.55999755859375
            },
            {
                "time": "Mon, Apr 8, 9:09 PM",
                "value": 68.6500015258789
            },
            {
                "time": "Mon, Apr 8, 9:10 PM",
                "value": 68.44999694824219
            },
            {
                "time": "Mon, Apr 8, 9:10 PM",
                "value": 68.06999969482422
            },
            {
                "time": "Mon, Apr 8, 9:11 PM",
                "value": 67.87000274658203
            },
            {
                "time": "Mon, Apr 8, 9:11 PM",
                "value": 67.94999694824219
            },
            {
                "time": "Mon, Apr 8, 9:12 PM",
                "value": 68.06999969482422
            },
            {
                "time": "Mon, Apr 8, 9:12 PM",
                "value": 68.16000366210938
            },
            {
                "time": "Mon, Apr 8, 9:13 PM",
                "value": 68
            },
            {
                "time": "Mon, Apr 8, 9:13 PM",
                "value": 67.95999908447266
            },
            {
                "time": "Mon, Apr 8, 9:14 PM",
                "value": 68.06999969482422
            },
            {
                "time": "Mon, Apr 8, 9:14 PM",
                "value": 68.13999938964844
            },
            {
                "time": "Mon, Apr 8, 9:15 PM",
                "value": 68.18000030517578
            },
            {
                "time": "Mon, Apr 8, 9:15 PM",
                "value": 68.22000122070312
            },
            {
                "time": "Mon, Apr 8, 9:16 PM",
                "value": 68.22000122070312
            },
            {
                "time": "Mon, Apr 8, 9:16 PM",
                "value": 67.93000030517578
            },
            {
                "time": "Mon, Apr 8, 9:17 PM",
                "value": 67.70999908447266
            },
            {
                "time": "Mon, Apr 8, 9:17 PM",
                "value": 67.56999969482422
            },
            {
                "time": "Mon, Apr 8, 9:18 PM",
                "value": 67.5
            },
            {
                "time": "Mon, Apr 8, 9:18 PM",
                "value": 67.52999877929688
            },
            {
                "time": "Mon, Apr 8, 9:19 PM",
                "value": 67.5
            },
            {
                "time": "Mon, Apr 8, 9:19 PM",
                "value": 67.30999755859375
            },
            {
                "time": "Mon, Apr 8, 9:20 PM",
                "value": 67.22000122070312
            },
            {
                "time": "Mon, Apr 8, 9:20 PM",
                "value": 67.16999816894531
            },
            {
                "time": "Mon, Apr 8, 9:21 PM",
                "value": 67.0999984741211
            },
            {
                "time": "Mon, Apr 8, 9:21 PM",
                "value": 66.91999816894531
            },
            {
                "time": "Mon, Apr 8, 9:22 PM",
                "value": 66.75
            },
            {
                "time": "Mon, Apr 8, 9:22 PM",
                "value": 66.62999725341797
            },
            {
                "time": "Mon, Apr 8, 9:23 PM",
                "value": 66.4800033569336
            },
            {
                "time": "Mon, Apr 8, 9:23 PM",
                "value": 66.45999908447266
            },
            {
                "time": "Mon, Apr 8, 9:24 PM",
                "value": 66.44000244140625
            },
            {
                "time": "Mon, Apr 8, 9:24 PM",
                "value": 66.62999725341797
            },
            {
                "time": "Mon, Apr 8, 9:25 PM",
                "value": 66.61000061035156
            },
            {
                "time": "Mon, Apr 8, 9:25 PM",
                "value": 66.4800033569336
            },
            {
                "time": "Mon, Apr 8, 9:26 PM",
                "value": 66.4800033569336
            },
            {
                "time": "Mon, Apr 8, 9:26 PM",
                "value": 66.58999633789062
            },
            {
                "time": "Mon, Apr 8, 9:27 PM",
                "value": 66.87999725341797
            },
            {
                "time": "Mon, Apr 8, 9:27 PM",
                "value": 66.87999725341797
            },
            {
                "time": "Mon, Apr 8, 9:28 PM",
                "value": 66.68000030517578
            },
            {
                "time": "Mon, Apr 8, 9:28 PM",
                "value": 66.5199966430664
            },
            {
                "time": "Mon, Apr 8, 9:29 PM",
                "value": 66.5199966430664
            },
            {
                "time": "Mon, Apr 8, 9:29 PM",
                "value": 66.66000366210938
            },
            {
                "time": "Mon, Apr 8, 9:30 PM",
                "value": 66.79000091552734
            },
            {
                "time": "Mon, Apr 8, 9:30 PM",
                "value": 66.62999725341797
            },
            {
                "time": "Mon, Apr 8, 9:31 PM",
                "value": 66.44000244140625
            },
            {
                "time": "Mon, Apr 8, 9:31 PM",
                "value": 66.73999786376953
            },
            {
                "time": "Mon, Apr 8, 9:32 PM",
                "value": 66.94999694824219
            },
            {
                "time": "Mon, Apr 8, 9:32 PM",
                "value": 66.73999786376953
            },
            {
                "time": "Mon, Apr 8, 9:33 PM",
                "value": 66.5199966430664
            },
            {
                "time": "Mon, Apr 8, 9:33 PM",
                "value": 66.4800033569336
            },
            {
                "time": "Mon, Apr 8, 9:34 PM",
                "value": 66.44000244140625
            },
            {
                "time": "Mon, Apr 8, 9:34 PM",
                "value": 66.38999938964844
            },
            {
                "time": "Mon, Apr 8, 9:35 PM",
                "value": 66.30000305175781
            },
            {
                "time": "Mon, Apr 8, 9:35 PM",
                "value": 66.12000274658203
            },
            {
                "time": "Mon, Apr 8, 9:36 PM",
                "value": 66.05000305175781
            },
            {
                "time": "Mon, Apr 8, 9:36 PM",
                "value": 66.16999816894531
            },
            {
                "time": "Mon, Apr 8, 9:37 PM",
                "value": 66.27999877929688
            },
            {
                "time": "Mon, Apr 8, 9:37 PM",
                "value": 66.25
            },
            {
                "time": "Mon, Apr 8, 9:38 PM",
                "value": 66.16000366210938
            },
            {
                "time": "Mon, Apr 8, 9:38 PM",
                "value": 66.12000274658203
            },
            {
                "time": "Mon, Apr 8, 9:39 PM",
                "value": 66.05000305175781
            },
            {
                "time": "Mon, Apr 8, 9:39 PM",
                "value": 66.16000366210938
            },
            {
                "time": "Mon, Apr 8, 9:40 PM",
                "value": 66.37000274658203
            },
            {
                "time": "Mon, Apr 8, 9:40 PM",
                "value": 66.5
            },
            {
                "time": "Mon, Apr 8, 9:41 PM",
                "value": 66.58999633789062
            },
            {
                "time": "Mon, Apr 8, 9:41 PM",
                "value": 66.56999969482422
            },
            {
                "time": "Mon, Apr 8, 9:42 PM",
                "value": 66.31999969482422
            },
            {
                "time": "Mon, Apr 8, 9:42 PM",
                "value": 66.2699966430664
            },
            {
                "time": "Mon, Apr 8, 9:43 PM",
                "value": 66.38999938964844
            },
            {
                "time": "Mon, Apr 8, 9:43 PM",
                "value": 66.43000030517578
            },
            {
                "time": "Mon, Apr 8, 9:44 PM",
                "value": 66.3499984741211
            },
            {
                "time": "Mon, Apr 8, 9:44 PM",
                "value": 66.3499984741211
            },
            {
                "time": "Mon, Apr 8, 9:45 PM",
                "value": 66.4800033569336
            },
            {
                "time": "Mon, Apr 8, 9:45 PM",
                "value": 66.58999633789062
            },
            {
                "time": "Mon, Apr 8, 9:46 PM",
                "value": 66.63999938964844
            },
            {
                "time": "Mon, Apr 8, 9:46 PM",
                "value": 66.75
            },
            {
                "time": "Mon, Apr 8, 9:47 PM",
                "value": 66.66000366210938
            },
            {
                "time": "Mon, Apr 8, 9:47 PM",
                "value": 66.56999969482422
            },
            {
                "time": "Mon, Apr 8, 9:48 PM",
                "value": 66.37000274658203
            },
            {
                "time": "Mon, Apr 8, 9:48 PM",
                "value": 66.27999877929688
            },
            {
                "time": "Mon, Apr 8, 9:49 PM",
                "value": 66.30000305175781
            },
            {
                "time": "Mon, Apr 8, 9:49 PM",
                "value": 66.30000305175781
            },
            {
                "time": "Mon, Apr 8, 9:50 PM",
                "value": 66.19000244140625
            },
            {
                "time": "Mon, Apr 8, 9:50 PM",
                "value": 66.12000274658203
            },
            {
                "time": "Mon, Apr 8, 9:51 PM",
                "value": 66.01000213623047
            },
            {
                "time": "Mon, Apr 8, 9:51 PM",
                "value": 65.98999786376953
            },
            {
                "time": "Mon, Apr 8, 9:52 PM",
                "value": 65.91999816894531
            },
            {
                "time": "Mon, Apr 8, 9:52 PM",
                "value": 65.9000015258789
            },
            {
                "time": "Mon, Apr 8, 9:53 PM",
                "value": 65.9800033569336
            },
            {
                "time": "Mon, Apr 8, 9:53 PM",
                "value": 65.95999908447266
            },
            {
                "time": "Mon, Apr 8, 9:54 PM",
                "value": 65.94000244140625
            },
            {
                "time": "Mon, Apr 8, 9:54 PM",
                "value": 65.87000274658203
            },
            {
                "time": "Mon, Apr 8, 9:55 PM",
                "value": 65.80000305175781
            },
            {
                "time": "Mon, Apr 8, 9:55 PM",
                "value": 65.77999877929688
            },
            {
                "time": "Mon, Apr 8, 9:56 PM",
                "value": 65.80000305175781
            },
            {
                "time": "Mon, Apr 8, 9:56 PM",
                "value": 65.80999755859375
            },
            {
                "time": "Mon, Apr 8, 9:57 PM",
                "value": 65.83000183105469
            },
            {
                "time": "Mon, Apr 8, 9:57 PM",
                "value": 65.9800033569336
            },
            {
                "time": "Mon, Apr 8, 9:58 PM",
                "value": 66.01000213623047
            },
            {
                "time": "Mon, Apr 8, 9:58 PM",
                "value": 66.01000213623047
            },
            {
                "time": "Mon, Apr 8, 9:59 PM",
                "value": 66.05000305175781
            },
            {
                "time": "Mon, Apr 8, 9:59 PM",
                "value": 66.01000213623047
            },
            {
                "time": "Mon, Apr 8, 10:00 PM",
                "value": 66.13999938964844
            },
            {
                "time": "Mon, Apr 8, 10:00 PM",
                "value": 66.16000366210938
            },
            {
                "time": "Mon, Apr 8, 10:01 PM",
                "value": 66.16000366210938
            },
            {
                "time": "Mon, Apr 8, 10:01 PM",
                "value": 65.98999786376953
            },
            {
                "time": "Mon, Apr 8, 10:02 PM",
                "value": 65.83000183105469
            },
            {
                "time": "Mon, Apr 8, 10:02 PM",
                "value": 65.6500015258789
            },
            {
                "time": "Mon, Apr 8, 10:03 PM",
                "value": 65.5999984741211
            },
            {
                "time": "Mon, Apr 8, 10:03 PM",
                "value": 65.54000091552734
            },
            {
                "time": "Mon, Apr 8, 10:04 PM",
                "value": 65.62000274658203
            },
            {
                "time": "Mon, Apr 8, 10:04 PM",
                "value": 65.62000274658203
            },
            {
                "time": "Mon, Apr 8, 10:05 PM",
                "value": 65.5999984741211
            },
            {
                "time": "Mon, Apr 8, 10:05 PM",
                "value": 65.6500015258789
            },
            {
                "time": "Mon, Apr 8, 10:06 PM",
                "value": 65.73999786376953
            },
            {
                "time": "Mon, Apr 8, 10:06 PM",
                "value": 65.72000122070312
            },
            {
                "time": "Mon, Apr 8, 10:07 PM",
                "value": 65.62999725341797
            },
            {
                "time": "Mon, Apr 8, 10:07 PM",
                "value": 65.4000015258789
            },
            {
                "time": "Mon, Apr 8, 10:08 PM",
                "value": 65.25
            },
            {
                "time": "Mon, Apr 8, 10:08 PM",
                "value": 65.19999694824219
            },
            {
                "time": "Mon, Apr 8, 10:09 PM",
                "value": 65.13999938964844
            },
            {
                "time": "Mon, Apr 8, 10:09 PM",
                "value": 65.04000091552734
            },
            {
                "time": "Mon, Apr 8, 10:10 PM",
                "value": 65.05999755859375
            },
            {
                "time": "Mon, Apr 8, 10:10 PM",
                "value": 64.88999938964844
            },
            {
                "time": "Mon, Apr 8, 10:11 PM",
                "value": 64.66999816894531
            },
            {
                "time": "Mon, Apr 8, 10:11 PM",
                "value": 64.56999969482422
            },
            {
                "time": "Mon, Apr 8, 10:12 PM",
                "value": 64.4800033569336
            },
            {
                "time": "Mon, Apr 8, 10:12 PM",
                "value": 64.41999816894531
            },
            {
                "time": "Mon, Apr 8, 10:13 PM",
                "value": 64.30999755859375
            },
            {
                "time": "Mon, Apr 8, 10:13 PM",
                "value": 64.19999694824219
            },
            {
                "time": "Mon, Apr 8, 10:14 PM",
                "value": 64.19999694824219
            },
            {
                "time": "Mon, Apr 8, 10:14 PM",
                "value": 64.22000122070312
            },
            {
                "time": "Mon, Apr 8, 10:15 PM",
                "value": 64.37999725341797
            },
            {
                "time": "Mon, Apr 8, 10:15 PM",
                "value": 64.7300033569336
            },
            {
                "time": "Mon, Apr 8, 10:16 PM",
                "value": 64.7699966430664
            },
            {
                "time": "Mon, Apr 8, 10:16 PM",
                "value": 64.70999908447266
            },
            {
                "time": "Mon, Apr 8, 10:17 PM",
                "value": 64.77999877929688
            },
            {
                "time": "Mon, Apr 8, 10:17 PM",
                "value": 65
            },
            {
                "time": "Mon, Apr 8, 10:18 PM",
                "value": 65.23999786376953
            },
            {
                "time": "Mon, Apr 8, 10:18 PM",
                "value": 65.41999816894531
            },
            {
                "time": "Mon, Apr 8, 10:19 PM",
                "value": 65.6500015258789
            },
            {
                "time": "Mon, Apr 8, 10:19 PM",
                "value": 65.73999786376953
            },
            {
                "time": "Mon, Apr 8, 10:20 PM",
                "value": 65.73999786376953
            },
            {
                "time": "Mon, Apr 8, 10:20 PM",
                "value": 65.77999877929688
            },
            {
                "time": "Mon, Apr 8, 10:21 PM",
                "value": 65.72000122070312
            },
            {
                "time": "Mon, Apr 8, 10:21 PM",
                "value": 65.6500015258789
            },
            {
                "time": "Mon, Apr 8, 10:22 PM",
                "value": 65.47000122070312
            },
            {
                "time": "Mon, Apr 8, 10:22 PM",
                "value": 65.4000015258789
            },
            {
                "time": "Mon, Apr 8, 10:23 PM",
                "value": 65.29000091552734
            },
            {
                "time": "Mon, Apr 8, 10:23 PM",
                "value": 65.30999755859375
            },
            {
                "time": "Mon, Apr 8, 10:24 PM",
                "value": 65.47000122070312
            },
            {
                "time": "Mon, Apr 8, 10:24 PM",
                "value": 65.48999786376953
            },
            {
                "time": "Mon, Apr 8, 10:25 PM",
                "value": 65.22000122070312
            },
            {
                "time": "Mon, Apr 8, 10:25 PM",
                "value": 65.0199966430664
            },
            {
                "time": "Mon, Apr 8, 10:26 PM",
                "value": 64.95999908447266
            },
            {
                "time": "Mon, Apr 8, 10:26 PM",
                "value": 65.0199966430664
            },
            {
                "time": "Mon, Apr 8, 10:27 PM",
                "value": 64.94999694824219
            },
            {
                "time": "Mon, Apr 8, 10:27 PM",
                "value": 64.86000061035156
            },
            {
                "time": "Mon, Apr 8, 10:28 PM",
                "value": 64.70999908447266
            },
            {
                "time": "Mon, Apr 8, 10:28 PM",
                "value": 64.66999816894531
            },
            {
                "time": "Mon, Apr 8, 10:29 PM",
                "value": 64.4800033569336
            },
            {
                "time": "Mon, Apr 8, 10:29 PM",
                "value": 64.19999694824219
            },
            {
                "time": "Mon, Apr 8, 10:30 PM",
                "value": 63.90999984741211
            },
            {
                "time": "Mon, Apr 8, 10:30 PM",
                "value": 63.70000076293945
            },
            {
                "time": "Mon, Apr 8, 10:31 PM",
                "value": 63.59000015258789
            },
            {
                "time": "Mon, Apr 8, 10:31 PM",
                "value": 63.40999984741211
            },
            {
                "time": "Mon, Apr 8, 10:32 PM",
                "value": 63.16999816894531
            },
            {
                "time": "Mon, Apr 8, 10:32 PM",
                "value": 62.939998626708984
            },
            {
                "time": "Mon, Apr 8, 10:33 PM",
                "value": 62.77000045776367
            },
            {
                "time": "Mon, Apr 8, 10:33 PM",
                "value": 62.7400016784668
            },
            {
                "time": "Mon, Apr 8, 10:34 PM",
                "value": 62.849998474121094
            },
            {
                "time": "Mon, Apr 8, 10:34 PM",
                "value": 62.9900016784668
            },
            {
                "time": "Mon, Apr 8, 10:35 PM",
                "value": 62.9900016784668
            },
            {
                "time": "Mon, Apr 8, 10:35 PM",
                "value": 62.900001525878906
            },
            {
                "time": "Mon, Apr 8, 10:36 PM",
                "value": 62.720001220703125
            },
            {
                "time": "Mon, Apr 8, 10:36 PM",
                "value": 62.52000045776367
            },
            {
                "time": "Mon, Apr 8, 10:37 PM",
                "value": 62.36000061035156
            },
            {
                "time": "Mon, Apr 8, 10:37 PM",
                "value": 62.209999084472656
            },
            {
                "time": "Mon, Apr 8, 10:38 PM",
                "value": 62.09000015258789
            },
            {
                "time": "Mon, Apr 8, 10:38 PM",
                "value": 61.959999084472656
            },
            {
                "time": "Mon, Apr 8, 10:39 PM",
                "value": 61.72999954223633
            },
            {
                "time": "Mon, Apr 8, 10:39 PM",
                "value": 61.560001373291016
            },
            {
                "time": "Mon, Apr 8, 10:40 PM",
                "value": 61.5099983215332
            },
            {
                "time": "Mon, Apr 8, 10:40 PM",
                "value": 61.470001220703125
            },
            {
                "time": "Mon, Apr 8, 10:41 PM",
                "value": 61.41999816894531
            },
            {
                "time": "Mon, Apr 8, 10:41 PM",
                "value": 61.279998779296875
            },
            {
                "time": "Mon, Apr 8, 10:42 PM",
                "value": 61.20000076293945
            },
            {
                "time": "Mon, Apr 8, 10:42 PM",
                "value": 61.18000030517578
            },
            {
                "time": "Mon, Apr 8, 10:43 PM",
                "value": 61.040000915527344
            },
            {
                "time": "Mon, Apr 8, 10:43 PM",
                "value": 60.95000076293945
            },
            {
                "time": "Mon, Apr 8, 10:44 PM",
                "value": 60.90999984741211
            },
            {
                "time": "Mon, Apr 8, 10:44 PM",
                "value": 60.79999923706055
            },
            {
                "time": "Mon, Apr 8, 10:45 PM",
                "value": 60.72999954223633
            },
            {
                "time": "Mon, Apr 8, 10:45 PM",
                "value": 60.599998474121094
            },
            {
                "time": "Mon, Apr 8, 10:46 PM",
                "value": 60.599998474121094
            },
            {
                "time": "Mon, Apr 8, 10:46 PM",
                "value": 60.75
            },
            {
                "time": "Mon, Apr 8, 10:47 PM",
                "value": 60.90999984741211
            },
            {
                "time": "Mon, Apr 8, 10:47 PM",
                "value": 61.06999969482422
            },
            {
                "time": "Mon, Apr 8, 10:48 PM",
                "value": 61.150001525878906
            },
            {
                "time": "Mon, Apr 8, 10:48 PM",
                "value": 61.09000015258789
            },
            {
                "time": "Mon, Apr 8, 10:49 PM",
                "value": 61.2400016784668
            },
            {
                "time": "Mon, Apr 8, 10:49 PM",
                "value": 61.130001068115234
            },
            {
                "time": "Mon, Apr 8, 10:50 PM",
                "value": 61.310001373291016
            },
            {
                "time": "Mon, Apr 8, 10:50 PM",
                "value": 61.7599983215332
            },
            {
                "time": "Mon, Apr 8, 10:51 PM",
                "value": 61.939998626708984
            },
            {
                "time": "Mon, Apr 8, 10:51 PM",
                "value": 62.04999923706055
            },
            {
                "time": "Mon, Apr 8, 10:52 PM",
                "value": 62.02000045776367
            },
            {
                "time": "Mon, Apr 8, 10:52 PM",
                "value": 62
            },
            {
                "time": "Mon, Apr 8, 10:53 PM",
                "value": 62
            },
            {
                "time": "Mon, Apr 8, 10:53 PM",
                "value": 62.04999923706055
            },
            {
                "time": "Mon, Apr 8, 10:54 PM",
                "value": 62.06999969482422
            },
            {
                "time": "Mon, Apr 8, 10:54 PM",
                "value": 62.119998931884766
            },
            {
                "time": "Mon, Apr 8, 10:55 PM",
                "value": 62.06999969482422
            },
            {
                "time": "Mon, Apr 8, 10:55 PM",
                "value": 62.209999084472656
            },
            {
                "time": "Mon, Apr 8, 10:56 PM",
                "value": 62.29999923706055
            },
            {
                "time": "Mon, Apr 8, 10:56 PM",
                "value": 62.40999984741211
            },
            {
                "time": "Mon, Apr 8, 10:57 PM",
                "value": 62.27000045776367
            },
            {
                "time": "Mon, Apr 8, 10:57 PM",
                "value": 62.02000045776367
            },
            {
                "time": "Mon, Apr 8, 10:58 PM",
                "value": 61.689998626708984
            },
            {
                "time": "Mon, Apr 8, 10:58 PM",
                "value": 61.380001068115234
            },
            {
                "time": "Mon, Apr 8, 10:59 PM",
                "value": 61.2400016784668
            },
            {
                "time": "Mon, Apr 8, 10:59 PM",
                "value": 61.310001373291016
            },
            {
                "time": "Mon, Apr 8, 11:00 PM",
                "value": 61.41999816894531
            },
            {
                "time": "Mon, Apr 8, 11:00 PM",
                "value": 61.290000915527344
            },
            {
                "time": "Mon, Apr 8, 11:01 PM",
                "value": 61.150001525878906
            },
            {
                "time": "Mon, Apr 8, 11:01 PM",
                "value": 61.060001373291016
            },
            {
                "time": "Mon, Apr 8, 11:02 PM",
                "value": 61.040000915527344
            },
            {
                "time": "Mon, Apr 8, 11:02 PM",
                "value": 61
            },
            {
                "time": "Mon, Apr 8, 11:03 PM",
                "value": 60.93000030517578
            },
            {
                "time": "Mon, Apr 8, 11:03 PM",
                "value": 60.97999954223633
            },
            {
                "time": "Mon, Apr 8, 11:04 PM",
                "value": 61.060001373291016
            },
            {
                "time": "Mon, Apr 8, 11:04 PM",
                "value": 61
            },
            {
                "time": "Mon, Apr 8, 11:05 PM",
                "value": 60.86000061035156
            },
            {
                "time": "Mon, Apr 8, 11:05 PM",
                "value": 60.93000030517578
            },
            {
                "time": "Mon, Apr 8, 11:06 PM",
                "value": 60.95000076293945
            },
            {
                "time": "Mon, Apr 8, 11:06 PM",
                "value": 60.86000061035156
            },
            {
                "time": "Mon, Apr 8, 11:07 PM",
                "value": 60.88999938964844
            },
            {
                "time": "Mon, Apr 8, 11:07 PM",
                "value": 61
            },
            {
                "time": "Mon, Apr 8, 11:08 PM",
                "value": 61.130001068115234
            },
            {
                "time": "Mon, Apr 8, 11:08 PM",
                "value": 61.09000015258789
            },
            {
                "time": "Mon, Apr 8, 11:09 PM",
                "value": 61.06999969482422
            },
            {
                "time": "Mon, Apr 8, 11:09 PM",
                "value": 60.88999938964844
            },
            {
                "time": "Mon, Apr 8, 11:10 PM",
                "value": 60.619998931884766
            },
            {
                "time": "Mon, Apr 8, 11:10 PM",
                "value": 60.369998931884766
            },
            {
                "time": "Mon, Apr 8, 11:11 PM",
                "value": 60.2400016784668
            },
            {
                "time": "Mon, Apr 8, 11:11 PM",
                "value": 60.38999938964844
            },
            {
                "time": "Mon, Apr 8, 11:12 PM",
                "value": 60.2400016784668
            },
            {
                "time": "Mon, Apr 8, 11:12 PM",
                "value": 60.189998626708984
            },
            {
                "time": "Mon, Apr 8, 11:13 PM",
                "value": 60.33000183105469
            },
            {
                "time": "Mon, Apr 8, 11:13 PM",
                "value": 60.65999984741211
            },
            {
                "time": "Mon, Apr 8, 11:14 PM",
                "value": 60.70000076293945
            },
            {
                "time": "Mon, Apr 8, 11:14 PM",
                "value": 60.599998474121094
            },
            {
                "time": "Mon, Apr 8, 11:15 PM",
                "value": 60.529998779296875
            },
            {
                "time": "Mon, Apr 8, 11:15 PM",
                "value": 60.310001373291016
            },
            {
                "time": "Mon, Apr 8, 11:16 PM",
                "value": 60.220001220703125
            },
            {
                "time": "Mon, Apr 8, 11:16 PM",
                "value": 60.209999084472656
            },
            {
                "time": "Mon, Apr 8, 11:17 PM",
                "value": 60.119998931884766
            },
            {
                "time": "Mon, Apr 8, 11:17 PM",
                "value": 60.150001525878906
            },
            {
                "time": "Mon, Apr 8, 11:18 PM",
                "value": 60
            },
            {
                "time": "Mon, Apr 8, 11:18 PM",
                "value": 60.02000045776367
            },
            {
                "time": "Mon, Apr 8, 11:19 PM",
                "value": 60.400001525878906
            },
            {
                "time": "Mon, Apr 8, 11:19 PM",
                "value": 60.220001220703125
            },
            {
                "time": "Mon, Apr 8, 11:20 PM",
                "value": 59.81999969482422
            },
            {
                "time": "Mon, Apr 8, 11:20 PM",
                "value": 59.61000061035156
            },
            {
                "time": "Mon, Apr 8, 11:21 PM",
                "value": 59.77000045776367
            },
            {
                "time": "Mon, Apr 8, 11:21 PM",
                "value": 60.220001220703125
            },
            {
                "time": "Mon, Apr 8, 11:22 PM",
                "value": 60.54999923706055
            },
            {
                "time": "Mon, Apr 8, 11:22 PM",
                "value": 60.790000915527344
            },
            {
                "time": "Mon, Apr 8, 11:23 PM",
                "value": 60.65999984741211
            },
            {
                "time": "Mon, Apr 8, 11:23 PM",
                "value": 60.970001220703125
            },
            {
                "time": "Mon, Apr 8, 11:24 PM",
                "value": 60.84000015258789
            },
            {
                "time": "Mon, Apr 8, 11:24 PM",
                "value": 60.619998931884766
            },
            {
                "time": "Mon, Apr 8, 11:25 PM",
                "value": 60.47999954223633
            },
            {
                "time": "Mon, Apr 8, 11:25 PM",
                "value": 60.439998626708984
            },
            {
                "time": "Mon, Apr 8, 11:26 PM",
                "value": 60.79999923706055
            },
            {
                "time": "Mon, Apr 8, 11:26 PM",
                "value": 60.880001068115234
            },
            {
                "time": "Mon, Apr 8, 11:27 PM",
                "value": 60.97999954223633
            },
            {
                "time": "Mon, Apr 8, 11:27 PM",
                "value": 60.97999954223633
            },
            {
                "time": "Mon, Apr 8, 11:28 PM",
                "value": 60.880001068115234
            },
            {
                "time": "Mon, Apr 8, 11:28 PM",
                "value": 60.95000076293945
            },
            {
                "time": "Mon, Apr 8, 11:29 PM",
                "value": 61.06999969482422
            },
            {
                "time": "Mon, Apr 8, 11:29 PM",
                "value": 61.150001525878906
            },
            {
                "time": "Mon, Apr 8, 11:30 PM",
                "value": 60.97999954223633
            },
            {
                "time": "Mon, Apr 8, 11:30 PM",
                "value": 60.880001068115234
            },
            {
                "time": "Mon, Apr 8, 11:31 PM",
                "value": 60.72999954223633
            },
            {
                "time": "Mon, Apr 8, 11:31 PM",
                "value": 60.63999938964844
            },
            {
                "time": "Mon, Apr 8, 11:32 PM",
                "value": 60.599998474121094
            },
            {
                "time": "Mon, Apr 8, 11:32 PM",
                "value": 60.459999084472656
            },
            {
                "time": "Mon, Apr 8, 11:33 PM",
                "value": 60.400001525878906
            },
            {
                "time": "Mon, Apr 8, 11:33 PM",
                "value": 60.369998931884766
            },
            {
                "time": "Mon, Apr 8, 11:34 PM",
                "value": 60.150001525878906
            },
            {
                "time": "Mon, Apr 8, 11:34 PM",
                "value": 60.040000915527344
            },
            {
                "time": "Mon, Apr 8, 11:35 PM",
                "value": 60.130001068115234
            },
            {
                "time": "Mon, Apr 8, 11:35 PM",
                "value": 60.040000915527344
            },
            {
                "time": "Mon, Apr 8, 11:36 PM",
                "value": 60
            },
            {
                "time": "Mon, Apr 8, 11:36 PM",
                "value": 60.369998931884766
            },
            {
                "time": "Mon, Apr 8, 11:37 PM",
                "value": 60.47999954223633
            },
            {
                "time": "Mon, Apr 8, 11:37 PM",
                "value": 60.75
            },
            {
                "time": "Mon, Apr 8, 11:38 PM",
                "value": 60.4900016784668
            },
            {
                "time": "Mon, Apr 8, 11:38 PM",
                "value": 60.33000183105469
            },
            {
                "time": "Mon, Apr 8, 11:39 PM",
                "value": 60.33000183105469
            },
            {
                "time": "Mon, Apr 8, 11:39 PM",
                "value": 60.41999816894531
            },
            {
                "time": "Mon, Apr 8, 11:40 PM",
                "value": 60.5099983215332
            },
            {
                "time": "Mon, Apr 8, 11:40 PM",
                "value": 60.54999923706055
            },
            {
                "time": "Mon, Apr 8, 11:41 PM",
                "value": 60.79999923706055
            },
            {
                "time": "Mon, Apr 8, 11:41 PM",
                "value": 60.72999954223633
            },
            {
                "time": "Mon, Apr 8, 11:42 PM",
                "value": 60.75
            },
            {
                "time": "Mon, Apr 8, 11:42 PM",
                "value": 60.81999969482422
            },
            {
                "time": "Mon, Apr 8, 11:43 PM",
                "value": 60.68000030517578
            },
            {
                "time": "Mon, Apr 8, 11:43 PM",
                "value": 60.47999954223633
            },
            {
                "time": "Mon, Apr 8, 11:44 PM",
                "value": 60.349998474121094
            },
            {
                "time": "Mon, Apr 8, 11:44 PM",
                "value": 60.209999084472656
            },
            {
                "time": "Mon, Apr 8, 11:45 PM",
                "value": 60.02000045776367
            },
            {
                "time": "Mon, Apr 8, 11:45 PM",
                "value": 59.900001525878906
            },
            {
                "time": "Mon, Apr 8, 11:46 PM",
                "value": 59.810001373291016
            },
            {
                "time": "Mon, Apr 8, 11:46 PM",
                "value": 59.65999984741211
            },
            {
                "time": "Mon, Apr 8, 11:47 PM",
                "value": 59.56999969482422
            },
            {
                "time": "Mon, Apr 8, 11:47 PM",
                "value": 59.52000045776367
            },
            {
                "time": "Mon, Apr 8, 11:48 PM",
                "value": 59.61000061035156
            },
            {
                "time": "Mon, Apr 8, 11:48 PM",
                "value": 59.86000061035156
            },
            {
                "time": "Mon, Apr 8, 11:49 PM",
                "value": 59.939998626708984
            },
            {
                "time": "Mon, Apr 8, 11:49 PM",
                "value": 59.81999969482422
            },
            {
                "time": "Mon, Apr 8, 11:50 PM",
                "value": 59.459999084472656
            },
            {
                "time": "Mon, Apr 8, 11:50 PM",
                "value": 59.189998626708984
            },
            {
                "time": "Mon, Apr 8, 11:51 PM",
                "value": 58.959999084472656
            },
            {
                "time": "Mon, Apr 8, 11:51 PM",
                "value": 58.880001068115234
            },
            {
                "time": "Mon, Apr 8, 11:52 PM",
                "value": 59.04999923706055
            },
            {
                "time": "Mon, Apr 8, 11:52 PM",
                "value": 58.9900016784668
            },
            {
                "time": "Mon, Apr 8, 11:53 PM",
                "value": 58.790000915527344
            },
            {
                "time": "Mon, Apr 8, 11:53 PM",
                "value": 58.790000915527344
            },
            {
                "time": "Mon, Apr 8, 11:54 PM",
                "value": 58.869998931884766
            },
            {
                "time": "Mon, Apr 8, 11:54 PM",
                "value": 58.91999816894531
            },
            {
                "time": "Mon, Apr 8, 11:55 PM",
                "value": 58.7400016784668
            },
            {
                "time": "Mon, Apr 8, 11:55 PM",
                "value": 58.66999816894531
            },
            {
                "time": "Mon, Apr 8, 11:56 PM",
                "value": 58.630001068115234
            },
            {
                "time": "Mon, Apr 8, 11:56 PM",
                "value": 58.58000183105469
            },
            {
                "time": "Mon, Apr 8, 11:57 PM",
                "value": 58.4900016784668
            },
            {
                "time": "Mon, Apr 8, 11:57 PM",
                "value": 58.380001068115234
            },
            {
                "time": "Mon, Apr 8, 11:58 PM",
                "value": 58.560001373291016
            },
            {
                "time": "Mon, Apr 8, 11:58 PM",
                "value": 58.849998474121094
            },
            {
                "time": "Mon, Apr 8, 11:59 PM",
                "value": 58.70000076293945
            },
            {
                "time": "Mon, Apr 8, 11:59 PM",
                "value": 58.7400016784668
            },
            {
                "time": "Tue, Apr 9, 12:00 AM",
                "value": 58.779998779296875
            },
            {
                "time": "Tue, Apr 9, 12:00 AM",
                "value": 58.810001373291016
            },
            {
                "time": "Tue, Apr 9, 12:01 AM",
                "value": 58.66999816894531
            },
            {
                "time": "Tue, Apr 9, 12:01 AM",
                "value": 58.31999969482422
            },
            {
                "time": "Tue, Apr 9, 12:02 AM",
                "value": 58.27000045776367
            },
            {
                "time": "Tue, Apr 9, 12:02 AM",
                "value": 58.34000015258789
            },
            {
                "time": "Tue, Apr 9, 12:03 AM",
                "value": 58.34000015258789
            },
            {
                "time": "Tue, Apr 9, 12:03 AM",
                "value": 58.31999969482422
            },
            {
                "time": "Tue, Apr 9, 12:04 AM",
                "value": 58.34000015258789
            },
            {
                "time": "Tue, Apr 9, 12:04 AM",
                "value": 58.470001220703125
            },
            {
                "time": "Tue, Apr 9, 12:05 AM",
                "value": 58.59000015258789
            },
            {
                "time": "Tue, Apr 9, 12:05 AM",
                "value": 58.68000030517578
            },
            {
                "time": "Tue, Apr 9, 12:06 AM",
                "value": 58.540000915527344
            },
            {
                "time": "Tue, Apr 9, 12:06 AM",
                "value": 58.5
            },
            {
                "time": "Tue, Apr 9, 12:07 AM",
                "value": 58.540000915527344
            },
            {
                "time": "Tue, Apr 9, 12:07 AM",
                "value": 58.59000015258789
            },
            {
                "time": "Tue, Apr 9, 12:08 AM",
                "value": 58.58000183105469
            },
            {
                "time": "Tue, Apr 9, 12:08 AM",
                "value": 58.4900016784668
            },
            {
                "time": "Tue, Apr 9, 12:09 AM",
                "value": 58.34000015258789
            },
            {
                "time": "Tue, Apr 9, 12:09 AM",
                "value": 58.22999954223633
            },
            {
                "time": "Tue, Apr 9, 12:10 AM",
                "value": 58.15999984741211
            },
            {
                "time": "Tue, Apr 9, 12:10 AM",
                "value": 58
            },
            {
                "time": "Tue, Apr 9, 12:11 AM",
                "value": 58
            },
            {
                "time": "Tue, Apr 9, 12:11 AM",
                "value": 58.0099983215332
            },
            {
                "time": "Tue, Apr 9, 12:12 AM",
                "value": 58.09000015258789
            },
            {
                "time": "Tue, Apr 9, 12:12 AM",
                "value": 58.09000015258789
            },
            {
                "time": "Tue, Apr 9, 12:13 AM",
                "value": 58.029998779296875
            },
            {
                "time": "Tue, Apr 9, 12:13 AM",
                "value": 58.18000030517578
            },
            {
                "time": "Tue, Apr 9, 12:14 AM",
                "value": 58.22999954223633
            },
            {
                "time": "Tue, Apr 9, 12:14 AM",
                "value": 57.939998626708984
            },
            {
                "time": "Tue, Apr 9, 12:15 AM",
                "value": 57.79999923706055
            },
            {
                "time": "Tue, Apr 9, 12:15 AM",
                "value": 57.90999984741211
            },
            {
                "time": "Tue, Apr 9, 12:16 AM",
                "value": 57.90999984741211
            },
            {
                "time": "Tue, Apr 9, 12:16 AM",
                "value": 57.83000183105469
            },
            {
                "time": "Tue, Apr 9, 12:17 AM",
                "value": 57.83000183105469
            },
            {
                "time": "Tue, Apr 9, 12:17 AM",
                "value": 57.90999984741211
            },
            {
                "time": "Tue, Apr 9, 12:18 AM",
                "value": 58.0099983215332
            },
            {
                "time": "Tue, Apr 9, 12:18 AM",
                "value": 58.04999923706055
            },
            {
                "time": "Tue, Apr 9, 12:19 AM",
                "value": 58.06999969482422
            },
            {
                "time": "Tue, Apr 9, 12:19 AM",
                "value": 58.029998779296875
            },
            {
                "time": "Tue, Apr 9, 12:20 AM",
                "value": 57.97999954223633
            },
            {
                "time": "Tue, Apr 9, 12:20 AM",
                "value": 57.90999984741211
            },
            {
                "time": "Tue, Apr 9, 12:21 AM",
                "value": 57.97999954223633
            },
            {
                "time": "Tue, Apr 9, 12:21 AM",
                "value": 58.04999923706055
            },
            {
                "time": "Tue, Apr 9, 12:22 AM",
                "value": 58.11000061035156
            },
            {
                "time": "Tue, Apr 9, 12:22 AM",
                "value": 58
            },
            {
                "time": "Tue, Apr 9, 12:23 AM",
                "value": 58.0099983215332
            },
            {
                "time": "Tue, Apr 9, 12:23 AM",
                "value": 58.04999923706055
            },
            {
                "time": "Tue, Apr 9, 12:24 AM",
                "value": 57.869998931884766
            },
            {
                "time": "Tue, Apr 9, 12:24 AM",
                "value": 57.7400016784668
            },
            {
                "time": "Tue, Apr 9, 12:25 AM",
                "value": 57.650001525878906
            },
            {
                "time": "Tue, Apr 9, 12:25 AM",
                "value": 57.58000183105469
            },
            {
                "time": "Tue, Apr 9, 12:26 AM",
                "value": 57.599998474121094
            },
            {
                "time": "Tue, Apr 9, 12:26 AM",
                "value": 57.43000030517578
            },
            {
                "time": "Tue, Apr 9, 12:27 AM",
                "value": 57.220001220703125
            },
            {
                "time": "Tue, Apr 9, 12:27 AM",
                "value": 57.20000076293945
            },
            {
                "time": "Tue, Apr 9, 12:28 AM",
                "value": 57.27000045776367
            },
            {
                "time": "Tue, Apr 9, 12:28 AM",
                "value": 57.25
            },
            {
                "time": "Tue, Apr 9, 12:29 AM",
                "value": 57.220001220703125
            },
            {
                "time": "Tue, Apr 9, 12:29 AM",
                "value": 57.18000030517578
            },
            {
                "time": "Tue, Apr 9, 12:30 AM",
                "value": 57.130001068115234
            },
            {
                "time": "Tue, Apr 9, 12:30 AM",
                "value": 57.11000061035156
            },
            {
                "time": "Tue, Apr 9, 12:31 AM",
                "value": 57.130001068115234
            },
            {
                "time": "Tue, Apr 9, 12:31 AM",
                "value": 57.18000030517578
            },
            {
                "time": "Tue, Apr 9, 12:32 AM",
                "value": 57.13999938964844
            },
            {
                "time": "Tue, Apr 9, 12:32 AM",
                "value": 57.040000915527344
            },
            {
                "time": "Tue, Apr 9, 12:33 AM",
                "value": 56.93000030517578
            },
            {
                "time": "Tue, Apr 9, 12:33 AM",
                "value": 56.88999938964844
            },
            {
                "time": "Tue, Apr 9, 12:34 AM",
                "value": 56.90999984741211
            },
            {
                "time": "Tue, Apr 9, 12:34 AM",
                "value": 57.58000183105469
            },
            {
                "time": "Tue, Apr 9, 12:35 AM",
                "value": 57.7599983215332
            },
            {
                "time": "Tue, Apr 9, 12:35 AM",
                "value": 57.779998779296875
            },
            {
                "time": "Tue, Apr 9, 12:36 AM",
                "value": 58.189998626708984
            },
            {
                "time": "Tue, Apr 9, 12:36 AM",
                "value": 58.11000061035156
            },
            {
                "time": "Tue, Apr 9, 12:37 AM",
                "value": 57.79999923706055
            },
            {
                "time": "Tue, Apr 9, 12:37 AM",
                "value": 57.58000183105469
            },
            {
                "time": "Tue, Apr 9, 12:38 AM",
                "value": 57.540000915527344
            },
            {
                "time": "Tue, Apr 9, 12:38 AM",
                "value": 57.619998931884766
            },
            {
                "time": "Tue, Apr 9, 12:39 AM",
                "value": 57.709999084472656
            },
            {
                "time": "Tue, Apr 9, 12:39 AM",
                "value": 58.119998931884766
            },
            {
                "time": "Tue, Apr 9, 12:40 AM",
                "value": 58.4900016784668
            },
            {
                "time": "Tue, Apr 9, 12:40 AM",
                "value": 58.7400016784668
            },
            {
                "time": "Tue, Apr 9, 12:41 AM",
                "value": 59.0099983215332
            },
            {
                "time": "Tue, Apr 9, 12:41 AM",
                "value": 59.04999923706055
            },
            {
                "time": "Tue, Apr 9, 12:42 AM",
                "value": 59.209999084472656
            },
            {
                "time": "Tue, Apr 9, 12:42 AM",
                "value": 59.38999938964844
            },
            {
                "time": "Tue, Apr 9, 12:43 AM",
                "value": 59.459999084472656
            },
            {
                "time": "Tue, Apr 9, 12:43 AM",
                "value": 59.63999938964844
            },
            {
                "time": "Tue, Apr 9, 12:44 AM",
                "value": 59.68000030517578
            },
            {
                "time": "Tue, Apr 9, 12:44 AM",
                "value": 59.59000015258789
            },
            {
                "time": "Tue, Apr 9, 12:45 AM",
                "value": 59.22999954223633
            },
            {
                "time": "Tue, Apr 9, 12:45 AM",
                "value": 58.83000183105469
            },
            {
                "time": "Tue, Apr 9, 12:46 AM",
                "value": 58.59000015258789
            },
            {
                "time": "Tue, Apr 9, 12:46 AM",
                "value": 58.880001068115234
            },
            {
                "time": "Tue, Apr 9, 12:47 AM",
                "value": 58.630001068115234
            },
            {
                "time": "Tue, Apr 9, 12:47 AM",
                "value": 58.25
            },
            {
                "time": "Tue, Apr 9, 12:48 AM",
                "value": 58.18000030517578
            },
            {
                "time": "Tue, Apr 9, 12:48 AM",
                "value": 58.15999984741211
            },
            {
                "time": "Tue, Apr 9, 12:49 AM",
                "value": 58.27000045776367
            },
            {
                "time": "Tue, Apr 9, 12:49 AM",
                "value": 58.119998931884766
            },
            {
                "time": "Tue, Apr 9, 12:50 AM",
                "value": 58.40999984741211
            },
            {
                "time": "Tue, Apr 9, 12:50 AM",
                "value": 58.560001373291016
            },
            {
                "time": "Tue, Apr 9, 12:51 AM",
                "value": 58.630001068115234
            },
            {
                "time": "Tue, Apr 9, 12:51 AM",
                "value": 58.4900016784668
            },
            {
                "time": "Tue, Apr 9, 12:52 AM",
                "value": 58.70000076293945
            },
            {
                "time": "Tue, Apr 9, 12:52 AM",
                "value": 59.209999084472656
            },
            {
                "time": "Tue, Apr 9, 12:53 AM",
                "value": 59.68000030517578
            },
            {
                "time": "Tue, Apr 9, 12:53 AM",
                "value": 59.77000045776367
            },
            {
                "time": "Tue, Apr 9, 12:54 AM",
                "value": 59.790000915527344
            },
            {
                "time": "Tue, Apr 9, 12:54 AM",
                "value": 59.84000015258789
            },
            {
                "time": "Tue, Apr 9, 12:55 AM",
                "value": 59.86000061035156
            },
            {
                "time": "Tue, Apr 9, 12:55 AM",
                "value": 59.65999984741211
            },
            {
                "time": "Tue, Apr 9, 12:56 AM",
                "value": 59.369998931884766
            },
            {
                "time": "Tue, Apr 9, 12:56 AM",
                "value": 59.2400016784668
            },
            {
                "time": "Tue, Apr 9, 12:57 AM",
                "value": 59.189998626708984
            },
            {
                "time": "Tue, Apr 9, 12:57 AM",
                "value": 59.189998626708984
            },
            {
                "time": "Tue, Apr 9, 12:58 AM",
                "value": 59.189998626708984
            },
            {
                "time": "Tue, Apr 9, 12:58 AM",
                "value": 59.31999969482422
            },
            {
                "time": "Tue, Apr 9, 12:59 AM",
                "value": 59.16999816894531
            },
            {
                "time": "Tue, Apr 9, 12:59 AM",
                "value": 59.209999084472656
            },
            {
                "time": "Tue, Apr 9, 1:00 AM",
                "value": 59.459999084472656
            },
            {
                "time": "Tue, Apr 9, 1:00 AM",
                "value": 59.63999938964844
            },
            {
                "time": "Tue, Apr 9, 1:01 AM",
                "value": 59.86000061035156
            },
            {
                "time": "Tue, Apr 9, 1:01 AM",
                "value": 59.939998626708984
            },
            {
                "time": "Tue, Apr 9, 1:02 AM",
                "value": 60.060001373291016
            },
            {
                "time": "Tue, Apr 9, 1:02 AM",
                "value": 59.939998626708984
            },
            {
                "time": "Tue, Apr 9, 1:03 AM",
                "value": 59.540000915527344
            },
            {
                "time": "Tue, Apr 9, 1:03 AM",
                "value": 59.540000915527344
            },
            {
                "time": "Tue, Apr 9, 1:04 AM",
                "value": 59.279998779296875
            },
            {
                "time": "Tue, Apr 9, 1:04 AM",
                "value": 58.91999816894531
            },
            {
                "time": "Tue, Apr 9, 1:05 AM",
                "value": 58.849998474121094
            },
            {
                "time": "Tue, Apr 9, 1:05 AM",
                "value": 58.790000915527344
            },
            {
                "time": "Tue, Apr 9, 1:06 AM",
                "value": 58.66999816894531
            },
            {
                "time": "Tue, Apr 9, 1:06 AM",
                "value": 58.70000076293945
            },
            {
                "time": "Tue, Apr 9, 1:07 AM",
                "value": 58.9900016784668
            },
            {
                "time": "Tue, Apr 9, 1:07 AM",
                "value": 59.22999954223633
            },
            {
                "time": "Tue, Apr 9, 1:08 AM",
                "value": 59.439998626708984
            },
            {
                "time": "Tue, Apr 9, 1:08 AM",
                "value": 59.61000061035156
            },
            {
                "time": "Tue, Apr 9, 1:09 AM",
                "value": 59.77000045776367
            },
            {
                "time": "Tue, Apr 9, 1:09 AM",
                "value": 59.72999954223633
            },
            {
                "time": "Tue, Apr 9, 1:10 AM",
                "value": 59.54999923706055
            },
            {
                "time": "Tue, Apr 9, 1:10 AM",
                "value": 59.70000076293945
            },
            {
                "time": "Tue, Apr 9, 1:11 AM",
                "value": 59.790000915527344
            },
            {
                "time": "Tue, Apr 9, 1:11 AM",
                "value": 59.81999969482422
            },
            {
                "time": "Tue, Apr 9, 1:12 AM",
                "value": 59.75
            },
            {
                "time": "Tue, Apr 9, 1:12 AM",
                "value": 59.720001220703125
            },
            {
                "time": "Tue, Apr 9, 1:13 AM",
                "value": 59.720001220703125
            },
            {
                "time": "Tue, Apr 9, 1:13 AM",
                "value": 59.75
            },
            {
                "time": "Tue, Apr 9, 1:14 AM",
                "value": 60
            },
            {
                "time": "Tue, Apr 9, 1:14 AM",
                "value": 60.369998931884766
            },
            {
                "time": "Tue, Apr 9, 1:15 AM",
                "value": 60.5099983215332
            },
            {
                "time": "Tue, Apr 9, 1:15 AM",
                "value": 60.529998779296875
            },
            {
                "time": "Tue, Apr 9, 1:16 AM",
                "value": 60.529998779296875
            },
            {
                "time": "Tue, Apr 9, 1:16 AM",
                "value": 60.65999984741211
            },
            {
                "time": "Tue, Apr 9, 1:17 AM",
                "value": 60.70000076293945
            },
            {
                "time": "Tue, Apr 9, 1:17 AM",
                "value": 60.77000045776367
            },
            {
                "time": "Tue, Apr 9, 1:18 AM",
                "value": 60.880001068115234
            },
            {
                "time": "Tue, Apr 9, 1:18 AM",
                "value": 60.84000015258789
            },
            {
                "time": "Tue, Apr 9, 1:19 AM",
                "value": 60.77000045776367
            },
            {
                "time": "Tue, Apr 9, 1:19 AM",
                "value": 60.63999938964844
            },
            {
                "time": "Tue, Apr 9, 1:20 AM",
                "value": 60.41999816894531
            },
            {
                "time": "Tue, Apr 9, 1:20 AM",
                "value": 60.33000183105469
            },
            {
                "time": "Tue, Apr 9, 1:21 AM",
                "value": 60.349998474121094
            },
            {
                "time": "Tue, Apr 9, 1:21 AM",
                "value": 60.310001373291016
            },
            {
                "time": "Tue, Apr 9, 1:22 AM",
                "value": 60.4900016784668
            },
            {
                "time": "Tue, Apr 9, 1:22 AM",
                "value": 60.5099983215332
            },
            {
                "time": "Tue, Apr 9, 1:23 AM",
                "value": 60.369998931884766
            },
            {
                "time": "Tue, Apr 9, 1:23 AM",
                "value": 60.2400016784668
            },
            {
                "time": "Tue, Apr 9, 1:24 AM",
                "value": 60
            },
            {
                "time": "Tue, Apr 9, 1:24 AM",
                "value": 59.70000076293945
            },
            {
                "time": "Tue, Apr 9, 1:25 AM",
                "value": 59.439998626708984
            },
            {
                "time": "Tue, Apr 9, 1:25 AM",
                "value": 59.540000915527344
            },
            {
                "time": "Tue, Apr 9, 1:26 AM",
                "value": 59.70000076293945
            },
            {
                "time": "Tue, Apr 9, 1:26 AM",
                "value": 60.150001525878906
            },
            {
                "time": "Tue, Apr 9, 1:27 AM",
                "value": 60.29999923706055
            },
            {
                "time": "Tue, Apr 9, 1:27 AM",
                "value": 60.29999923706055
            },
            {
                "time": "Tue, Apr 9, 1:28 AM",
                "value": 60.209999084472656
            },
            {
                "time": "Tue, Apr 9, 1:28 AM",
                "value": 60.29999923706055
            },
            {
                "time": "Tue, Apr 9, 1:29 AM",
                "value": 60.349998474121094
            },
            {
                "time": "Tue, Apr 9, 1:29 AM",
                "value": 60.29999923706055
            },
            {
                "time": "Tue, Apr 9, 1:30 AM",
                "value": 60.220001220703125
            },
            {
                "time": "Tue, Apr 9, 1:30 AM",
                "value": 60.119998931884766
            },
            {
                "time": "Tue, Apr 9, 1:31 AM",
                "value": 60.08000183105469
            },
            {
                "time": "Tue, Apr 9, 1:31 AM",
                "value": 60.060001373291016
            },
            {
                "time": "Tue, Apr 9, 1:32 AM",
                "value": 60.08000183105469
            },
            {
                "time": "Tue, Apr 9, 1:32 AM",
                "value": 60.16999816894531
            },
            {
                "time": "Tue, Apr 9, 1:33 AM",
                "value": 60.33000183105469
            },
            {
                "time": "Tue, Apr 9, 1:33 AM",
                "value": 60.33000183105469
            },
            {
                "time": "Tue, Apr 9, 1:34 AM",
                "value": 60.33000183105469
            },
            {
                "time": "Tue, Apr 9, 1:34 AM",
                "value": 60.33000183105469
            },
            {
                "time": "Tue, Apr 9, 1:35 AM",
                "value": 60.2599983215332
            },
            {
                "time": "Tue, Apr 9, 1:35 AM",
                "value": 60.150001525878906
            },
            {
                "time": "Tue, Apr 9, 1:36 AM",
                "value": 60.150001525878906
            },
            {
                "time": "Tue, Apr 9, 1:36 AM",
                "value": 60
            },
            {
                "time": "Tue, Apr 9, 1:37 AM",
                "value": 59.939998626708984
            },
            {
                "time": "Tue, Apr 9, 1:37 AM",
                "value": 59.95000076293945
            },
            {
                "time": "Tue, Apr 9, 1:38 AM",
                "value": 60.060001373291016
            },
            {
                "time": "Tue, Apr 9, 1:38 AM",
                "value": 60.060001373291016
            },
            {
                "time": "Tue, Apr 9, 1:39 AM",
                "value": 59.9900016784668
            },
            {
                "time": "Tue, Apr 9, 1:39 AM",
                "value": 59.81999969482422
            },
            {
                "time": "Tue, Apr 9, 1:40 AM",
                "value": 59.720001220703125
            },
            {
                "time": "Tue, Apr 9, 1:40 AM",
                "value": 59.5
            },
            {
                "time": "Tue, Apr 9, 1:41 AM",
                "value": 59.38999938964844
            },
            {
                "time": "Tue, Apr 9, 1:41 AM",
                "value": 59.61000061035156
            },
            {
                "time": "Tue, Apr 9, 1:42 AM",
                "value": 59.880001068115234
            },
            {
                "time": "Tue, Apr 9, 1:42 AM",
                "value": 60.220001220703125
            },
            {
                "time": "Tue, Apr 9, 1:43 AM",
                "value": 60.4900016784668
            },
            {
                "time": "Tue, Apr 9, 1:43 AM",
                "value": 60.72999954223633
            },
            {
                "time": "Tue, Apr 9, 1:44 AM",
                "value": 60.72999954223633
            },
            {
                "time": "Tue, Apr 9, 1:44 AM",
                "value": 60.65999984741211
            },
            {
                "time": "Tue, Apr 9, 1:45 AM",
                "value": 60.619998931884766
            },
            {
                "time": "Tue, Apr 9, 1:45 AM",
                "value": 60.5099983215332
            },
            {
                "time": "Tue, Apr 9, 1:46 AM",
                "value": 60.349998474121094
            },
            {
                "time": "Tue, Apr 9, 1:46 AM",
                "value": 60.130001068115234
            },
            {
                "time": "Tue, Apr 9, 1:47 AM",
                "value": 60.099998474121094
            },
            {
                "time": "Tue, Apr 9, 1:47 AM",
                "value": 60
            },
            {
                "time": "Tue, Apr 9, 1:48 AM",
                "value": 60.040000915527344
            },
            {
                "time": "Tue, Apr 9, 1:48 AM",
                "value": 60.040000915527344
            },
            {
                "time": "Tue, Apr 9, 1:49 AM",
                "value": 60.02000045776367
            },
            {
                "time": "Tue, Apr 9, 1:49 AM",
                "value": 59.939998626708984
            },
            {
                "time": "Tue, Apr 9, 1:50 AM",
                "value": 59.970001220703125
            },
            {
                "time": "Tue, Apr 9, 1:50 AM",
                "value": 59.86000061035156
            },
            {
                "time": "Tue, Apr 9, 1:51 AM",
                "value": 59.77000045776367
            },
            {
                "time": "Tue, Apr 9, 1:51 AM",
                "value": 59.810001373291016
            },
            {
                "time": "Tue, Apr 9, 1:52 AM",
                "value": 59.72999954223633
            },
            {
                "time": "Tue, Apr 9, 1:52 AM",
                "value": 59.47999954223633
            },
            {
                "time": "Tue, Apr 9, 1:53 AM",
                "value": 59.31999969482422
            },
            {
                "time": "Tue, Apr 9, 1:53 AM",
                "value": 58.9900016784668
            },
            {
                "time": "Tue, Apr 9, 1:54 AM",
                "value": 58.810001373291016
            },
            {
                "time": "Tue, Apr 9, 1:54 AM",
                "value": 58.66999816894531
            },
            {
                "time": "Tue, Apr 9, 1:55 AM",
                "value": 58.560001373291016
            },
            {
                "time": "Tue, Apr 9, 1:55 AM",
                "value": 58.880001068115234
            },
            {
                "time": "Tue, Apr 9, 1:56 AM",
                "value": 59.060001373291016
            },
            {
                "time": "Tue, Apr 9, 1:56 AM",
                "value": 58.91999816894531
            },
            {
                "time": "Tue, Apr 9, 1:57 AM",
                "value": 58.70000076293945
            },
            {
                "time": "Tue, Apr 9, 1:57 AM",
                "value": 58.310001373291016
            },
            {
                "time": "Tue, Apr 9, 1:58 AM",
                "value": 58.0099983215332
            },
            {
                "time": "Tue, Apr 9, 1:58 AM",
                "value": 58.13999938964844
            },
            {
                "time": "Tue, Apr 9, 1:59 AM",
                "value": 58.27000045776367
            },
            {
                "time": "Tue, Apr 9, 1:59 AM",
                "value": 58.61000061035156
            },
            {
                "time": "Tue, Apr 9, 2:00 AM",
                "value": 58.880001068115234
            },
            {
                "time": "Tue, Apr 9, 2:00 AM",
                "value": 59.0099983215332
            },
            {
                "time": "Tue, Apr 9, 2:01 AM",
                "value": 58.83000183105469
            },
            {
                "time": "Tue, Apr 9, 2:01 AM",
                "value": 58.849998474121094
            },
            {
                "time": "Tue, Apr 9, 2:02 AM",
                "value": 59.029998779296875
            },
            {
                "time": "Tue, Apr 9, 2:02 AM",
                "value": 58.790000915527344
            },
            {
                "time": "Tue, Apr 9, 2:03 AM",
                "value": 58.4900016784668
            },
            {
                "time": "Tue, Apr 9, 2:03 AM",
                "value": 58.45000076293945
            },
            {
                "time": "Tue, Apr 9, 2:04 AM",
                "value": 58.7400016784668
            },
            {
                "time": "Tue, Apr 9, 2:04 AM",
                "value": 58.849998474121094
            },
            {
                "time": "Tue, Apr 9, 2:05 AM",
                "value": 59.029998779296875
            },
            {
                "time": "Tue, Apr 9, 2:05 AM",
                "value": 58.880001068115234
            },
            {
                "time": "Tue, Apr 9, 2:06 AM",
                "value": 58.59000015258789
            },
            {
                "time": "Tue, Apr 9, 2:06 AM",
                "value": 58.45000076293945
            },
            {
                "time": "Tue, Apr 9, 2:07 AM",
                "value": 58.310001373291016
            },
            {
                "time": "Tue, Apr 9, 2:07 AM",
                "value": 58.22999954223633
            },
            {
                "time": "Tue, Apr 9, 2:08 AM",
                "value": 58.09000015258789
            },
            {
                "time": "Tue, Apr 9, 2:08 AM",
                "value": 57.939998626708984
            },
            {
                "time": "Tue, Apr 9, 2:09 AM",
                "value": 57.72999954223633
            },
            {
                "time": "Tue, Apr 9, 2:09 AM",
                "value": 57.72999954223633
            },
            {
                "time": "Tue, Apr 9, 2:10 AM",
                "value": 57.779998779296875
            },
            {
                "time": "Tue, Apr 9, 2:10 AM",
                "value": 57.650001525878906
            },
            {
                "time": "Tue, Apr 9, 2:11 AM",
                "value": 57.650001525878906
            },
            {
                "time": "Tue, Apr 9, 2:11 AM",
                "value": 57.58000183105469
            },
            {
                "time": "Tue, Apr 9, 2:12 AM",
                "value": 57.58000183105469
            },
            {
                "time": "Tue, Apr 9, 2:12 AM",
                "value": 57.650001525878906
            },
            {
                "time": "Tue, Apr 9, 2:13 AM",
                "value": 57.869998931884766
            },
            {
                "time": "Tue, Apr 9, 2:13 AM",
                "value": 57.97999954223633
            },
            {
                "time": "Tue, Apr 9, 2:14 AM",
                "value": 58.11000061035156
            },
            {
                "time": "Tue, Apr 9, 2:14 AM",
                "value": 58.25
            },
            {
                "time": "Tue, Apr 9, 2:15 AM",
                "value": 58.34000015258789
            },
            {
                "time": "Tue, Apr 9, 2:15 AM",
                "value": 58.290000915527344
            },
            {
                "time": "Tue, Apr 9, 2:16 AM",
                "value": 58.310001373291016
            },
            {
                "time": "Tue, Apr 9, 2:16 AM",
                "value": 58.27000045776367
            },
            {
                "time": "Tue, Apr 9, 2:17 AM",
                "value": 58.22999954223633
            },
            {
                "time": "Tue, Apr 9, 2:17 AM",
                "value": 58.15999984741211
            },
            {
                "time": "Tue, Apr 9, 2:18 AM",
                "value": 58.119998931884766
            },
            {
                "time": "Tue, Apr 9, 2:18 AM",
                "value": 58.25
            },
            {
                "time": "Tue, Apr 9, 2:19 AM",
                "value": 58.04999923706055
            },
            {
                "time": "Tue, Apr 9, 2:19 AM",
                "value": 57.79999923706055
            },
            {
                "time": "Tue, Apr 9, 2:20 AM",
                "value": 57.689998626708984
            },
            {
                "time": "Tue, Apr 9, 2:20 AM",
                "value": 57.83000183105469
            },
            {
                "time": "Tue, Apr 9, 2:21 AM",
                "value": 57.88999938964844
            },
            {
                "time": "Tue, Apr 9, 2:21 AM",
                "value": 57.939998626708984
            },
            {
                "time": "Tue, Apr 9, 2:22 AM",
                "value": 57.869998931884766
            },
            {
                "time": "Tue, Apr 9, 2:22 AM",
                "value": 57.79999923706055
            },
            {
                "time": "Tue, Apr 9, 2:23 AM",
                "value": 57.81999969482422
            },
            {
                "time": "Tue, Apr 9, 2:23 AM",
                "value": 57.7599983215332
            },
            {
                "time": "Tue, Apr 9, 2:24 AM",
                "value": 57.66999816894531
            },
            {
                "time": "Tue, Apr 9, 2:24 AM",
                "value": 57.400001525878906
            },
            {
                "time": "Tue, Apr 9, 2:25 AM",
                "value": 57.400001525878906
            },
            {
                "time": "Tue, Apr 9, 2:25 AM",
                "value": 57.400001525878906
            },
            {
                "time": "Tue, Apr 9, 2:26 AM",
                "value": 57.34000015258789
            },
            {
                "time": "Tue, Apr 9, 2:26 AM",
                "value": 57.43000030517578
            },
            {
                "time": "Tue, Apr 9, 2:27 AM",
                "value": 57.630001068115234
            },
            {
                "time": "Tue, Apr 9, 2:27 AM",
                "value": 57.58000183105469
            },
            {
                "time": "Tue, Apr 9, 2:28 AM",
                "value": 57.7599983215332
            },
            {
                "time": "Tue, Apr 9, 2:28 AM",
                "value": 57.83000183105469
            },
            {
                "time": "Tue, Apr 9, 2:29 AM",
                "value": 57.689998626708984
            },
            {
                "time": "Tue, Apr 9, 2:29 AM",
                "value": 57.5099983215332
            },
            {
                "time": "Tue, Apr 9, 2:30 AM",
                "value": 57.470001220703125
            },
            {
                "time": "Tue, Apr 9, 2:30 AM",
                "value": 57.290000915527344
            },
            {
                "time": "Tue, Apr 9, 2:31 AM",
                "value": 57.36000061035156
            },
            {
                "time": "Tue, Apr 9, 2:31 AM",
                "value": 57.81999969482422
            },
            {
                "time": "Tue, Apr 9, 2:32 AM",
                "value": 58.09000015258789
            },
            {
                "time": "Tue, Apr 9, 2:32 AM",
                "value": 57.869998931884766
            },
            {
                "time": "Tue, Apr 9, 2:33 AM",
                "value": 57.7599983215332
            },
            {
                "time": "Tue, Apr 9, 2:33 AM",
                "value": 57.849998474121094
            },
            {
                "time": "Tue, Apr 9, 2:34 AM",
                "value": 57.90999984741211
            },
            {
                "time": "Tue, Apr 9, 2:34 AM",
                "value": 58
            },
            {
                "time": "Tue, Apr 9, 2:35 AM",
                "value": 57.939998626708984
            },
            {
                "time": "Tue, Apr 9, 2:35 AM",
                "value": 57.97999954223633
            },
            {
                "time": "Tue, Apr 9, 2:36 AM",
                "value": 58.0099983215332
            },
            {
                "time": "Tue, Apr 9, 2:36 AM",
                "value": 58.119998931884766
            },
            {
                "time": "Tue, Apr 9, 2:37 AM",
                "value": 57.83000183105469
            },
            {
                "time": "Tue, Apr 9, 2:37 AM",
                "value": 57.689998626708984
            },
            {
                "time": "Tue, Apr 9, 2:38 AM",
                "value": 57.5099983215332
            },
            {
                "time": "Tue, Apr 9, 2:38 AM",
                "value": 57.34000015258789
            },
            {
                "time": "Tue, Apr 9, 2:39 AM",
                "value": 57.06999969482422
            },
            {
                "time": "Tue, Apr 9, 2:39 AM",
                "value": 56.81999969482422
            },
            {
                "time": "Tue, Apr 9, 2:40 AM",
                "value": 56.72999954223633
            },
            {
                "time": "Tue, Apr 9, 2:40 AM",
                "value": 56.66999816894531
            },
            {
                "time": "Tue, Apr 9, 2:41 AM",
                "value": 56.849998474121094
            },
            {
                "time": "Tue, Apr 9, 2:41 AM",
                "value": 57.11000061035156
            },
            {
                "time": "Tue, Apr 9, 2:42 AM",
                "value": 57.36000061035156
            },
            {
                "time": "Tue, Apr 9, 2:42 AM",
                "value": 57.540000915527344
            },
            {
                "time": "Tue, Apr 9, 2:43 AM",
                "value": 57.58000183105469
            },
            {
                "time": "Tue, Apr 9, 2:43 AM",
                "value": 57.33000183105469
            },
            {
                "time": "Tue, Apr 9, 2:44 AM",
                "value": 57.15999984741211
            },
            {
                "time": "Tue, Apr 9, 2:44 AM",
                "value": 57
            },
            {
                "time": "Tue, Apr 9, 2:45 AM",
                "value": 57.220001220703125
            },
            {
                "time": "Tue, Apr 9, 2:45 AM",
                "value": 57.34000015258789
            },
            {
                "time": "Tue, Apr 9, 2:46 AM",
                "value": 57.45000076293945
            },
            {
                "time": "Tue, Apr 9, 2:46 AM",
                "value": 57.630001068115234
            },
            {
                "time": "Tue, Apr 9, 2:47 AM",
                "value": 58.09000015258789
            },
            {
                "time": "Tue, Apr 9, 2:47 AM",
                "value": 58.25
            },
            {
                "time": "Tue, Apr 9, 2:48 AM",
                "value": 58.380001068115234
            },
            {
                "time": "Tue, Apr 9, 2:48 AM",
                "value": 58.45000076293945
            },
            {
                "time": "Tue, Apr 9, 2:49 AM",
                "value": 58.540000915527344
            },
            {
                "time": "Tue, Apr 9, 2:49 AM",
                "value": 58.900001525878906
            },
            {
                "time": "Tue, Apr 9, 2:50 AM",
                "value": 59.2599983215332
            },
            {
                "time": "Tue, Apr 9, 2:50 AM",
                "value": 59.349998474121094
            },
            {
                "time": "Tue, Apr 9, 2:51 AM",
                "value": 59.099998474121094
            },
            {
                "time": "Tue, Apr 9, 2:51 AM",
                "value": 58.900001525878906
            },
            {
                "time": "Tue, Apr 9, 2:52 AM",
                "value": 58.810001373291016
            },
            {
                "time": "Tue, Apr 9, 2:52 AM",
                "value": 58.650001525878906
            },
            {
                "time": "Tue, Apr 9, 2:53 AM",
                "value": 58.380001068115234
            },
            {
                "time": "Tue, Apr 9, 2:53 AM",
                "value": 58.40999984741211
            },
            {
                "time": "Tue, Apr 9, 2:54 AM",
                "value": 58.540000915527344
            },
            {
                "time": "Tue, Apr 9, 2:54 AM",
                "value": 58.540000915527344
            },
            {
                "time": "Tue, Apr 9, 2:55 AM",
                "value": 58.4900016784668
            },
            {
                "time": "Tue, Apr 9, 2:55 AM",
                "value": 58.66999816894531
            },
            {
                "time": "Tue, Apr 9, 2:56 AM",
                "value": 58.869998931884766
            },
            {
                "time": "Tue, Apr 9, 2:56 AM",
                "value": 58.880001068115234
            },
            {
                "time": "Tue, Apr 9, 2:57 AM",
                "value": 58.9900016784668
            },
            {
                "time": "Tue, Apr 9, 2:57 AM",
                "value": 59.099998474121094
            },
            {
                "time": "Tue, Apr 9, 2:58 AM",
                "value": 59.209999084472656
            },
            {
                "time": "Tue, Apr 9, 2:58 AM",
                "value": 59.189998626708984
            },
            {
                "time": "Tue, Apr 9, 2:59 AM",
                "value": 59.13999938964844
            },
            {
                "time": "Tue, Apr 9, 2:59 AM",
                "value": 59.029998779296875
            },
            {
                "time": "Tue, Apr 9, 3:00 AM",
                "value": 58.849998474121094
            },
            {
                "time": "Tue, Apr 9, 3:00 AM",
                "value": 59.08000183105469
            },
            {
                "time": "Tue, Apr 9, 3:01 AM",
                "value": 59.34000015258789
            },
            {
                "time": "Tue, Apr 9, 3:01 AM",
                "value": 59.40999984741211
            },
            {
                "time": "Tue, Apr 9, 3:02 AM",
                "value": 59.2400016784668
            },
            {
                "time": "Tue, Apr 9, 3:02 AM",
                "value": 59.13999938964844
            },
            {
                "time": "Tue, Apr 9, 3:03 AM",
                "value": 59.119998931884766
            },
            {
                "time": "Tue, Apr 9, 3:03 AM",
                "value": 58.900001525878906
            },
            {
                "time": "Tue, Apr 9, 3:04 AM",
                "value": 58.810001373291016
            },
            {
                "time": "Tue, Apr 9, 3:04 AM",
                "value": 58.779998779296875
            },
            {
                "time": "Tue, Apr 9, 3:05 AM",
                "value": 58.849998474121094
            },
            {
                "time": "Tue, Apr 9, 3:05 AM",
                "value": 58.900001525878906
            },
            {
                "time": "Tue, Apr 9, 3:06 AM",
                "value": 59.04999923706055
            },
            {
                "time": "Tue, Apr 9, 3:06 AM",
                "value": 59.22999954223633
            },
            {
                "time": "Tue, Apr 9, 3:07 AM",
                "value": 59.540000915527344
            },
            {
                "time": "Tue, Apr 9, 3:07 AM",
                "value": 59.810001373291016
            },
            {
                "time": "Tue, Apr 9, 3:08 AM",
                "value": 60.099998474121094
            },
            {
                "time": "Tue, Apr 9, 3:08 AM",
                "value": 60.119998931884766
            },
            {
                "time": "Tue, Apr 9, 3:09 AM",
                "value": 60.02000045776367
            },
            {
                "time": "Tue, Apr 9, 3:09 AM",
                "value": 60
            },
            {
                "time": "Tue, Apr 9, 3:10 AM",
                "value": 59.81999969482422
            },
            {
                "time": "Tue, Apr 9, 3:10 AM",
                "value": 59.84000015258789
            },
            {
                "time": "Tue, Apr 9, 3:11 AM",
                "value": 59.900001525878906
            },
            {
                "time": "Tue, Apr 9, 3:11 AM",
                "value": 59.790000915527344
            },
            {
                "time": "Tue, Apr 9, 3:12 AM",
                "value": 60.220001220703125
            },
            {
                "time": "Tue, Apr 9, 3:12 AM",
                "value": 60.439998626708984
            },
            {
                "time": "Tue, Apr 9, 3:13 AM",
                "value": 60.529998779296875
            },
            {
                "time": "Tue, Apr 9, 3:13 AM",
                "value": 60.880001068115234
            },
            {
                "time": "Tue, Apr 9, 3:14 AM",
                "value": 61.02000045776367
            },
            {
                "time": "Tue, Apr 9, 3:14 AM",
                "value": 60.93000030517578
            },
            {
                "time": "Tue, Apr 9, 3:15 AM",
                "value": 60.65999984741211
            },
            {
                "time": "Tue, Apr 9, 3:15 AM",
                "value": 60.619998931884766
            },
            {
                "time": "Tue, Apr 9, 3:16 AM",
                "value": 60.70000076293945
            },
            {
                "time": "Tue, Apr 9, 3:16 AM",
                "value": 60.75
            },
            {
                "time": "Tue, Apr 9, 3:17 AM",
                "value": 60.84000015258789
            },
            {
                "time": "Tue, Apr 9, 3:17 AM",
                "value": 60.95000076293945
            },
            {
                "time": "Tue, Apr 9, 3:18 AM",
                "value": 60.880001068115234
            },
            {
                "time": "Tue, Apr 9, 3:18 AM",
                "value": 60.72999954223633
            },
            {
                "time": "Tue, Apr 9, 3:19 AM",
                "value": 60.63999938964844
            },
            {
                "time": "Tue, Apr 9, 3:19 AM",
                "value": 60.619998931884766
            },
            {
                "time": "Tue, Apr 9, 3:20 AM",
                "value": 60.58000183105469
            },
            {
                "time": "Tue, Apr 9, 3:20 AM",
                "value": 60.54999923706055
            },
            {
                "time": "Tue, Apr 9, 3:21 AM",
                "value": 60.38999938964844
            },
            {
                "time": "Tue, Apr 9, 3:21 AM",
                "value": 60.369998931884766
            },
            {
                "time": "Tue, Apr 9, 3:22 AM",
                "value": 60.56999969482422
            },
            {
                "time": "Tue, Apr 9, 3:22 AM",
                "value": 60.65999984741211
            },
            {
                "time": "Tue, Apr 9, 3:23 AM",
                "value": 60.619998931884766
            },
            {
                "time": "Tue, Apr 9, 3:23 AM",
                "value": 60.47999954223633
            },
            {
                "time": "Tue, Apr 9, 3:24 AM",
                "value": 60.400001525878906
            },
            {
                "time": "Tue, Apr 9, 3:24 AM",
                "value": 60.439998626708984
            },
            {
                "time": "Tue, Apr 9, 3:25 AM",
                "value": 60.529998779296875
            },
            {
                "time": "Tue, Apr 9, 3:25 AM",
                "value": 60.5099983215332
            },
            {
                "time": "Tue, Apr 9, 3:26 AM",
                "value": 60.47999954223633
            },
            {
                "time": "Tue, Apr 9, 3:26 AM",
                "value": 60.369998931884766
            },
            {
                "time": "Tue, Apr 9, 3:27 AM",
                "value": 60.2599983215332
            },
            {
                "time": "Tue, Apr 9, 3:27 AM",
                "value": 60.209999084472656
            },
            {
                "time": "Tue, Apr 9, 3:28 AM",
                "value": 60.29999923706055
            },
            {
                "time": "Tue, Apr 9, 3:28 AM",
                "value": 60.220001220703125
            },
            {
                "time": "Tue, Apr 9, 3:29 AM",
                "value": 60.08000183105469
            },
            {
                "time": "Tue, Apr 9, 3:29 AM",
                "value": 60
            },
            {
                "time": "Tue, Apr 9, 3:30 AM",
                "value": 59.91999816894531
            },
            {
                "time": "Tue, Apr 9, 3:30 AM",
                "value": 59.970001220703125
            },
            {
                "time": "Tue, Apr 9, 3:31 AM",
                "value": 60.02000045776367
            },
            {
                "time": "Tue, Apr 9, 3:31 AM",
                "value": 60.16999816894531
            },
            {
                "time": "Tue, Apr 9, 3:32 AM",
                "value": 60.099998474121094
            },
            {
                "time": "Tue, Apr 9, 3:32 AM",
                "value": 60
            },
            {
                "time": "Tue, Apr 9, 3:33 AM",
                "value": 60.16999816894531
            },
            {
                "time": "Tue, Apr 9, 3:33 AM",
                "value": 60.29999923706055
            },
            {
                "time": "Tue, Apr 9, 3:34 AM",
                "value": 60.2400016784668
            },
            {
                "time": "Tue, Apr 9, 3:34 AM",
                "value": 59.939998626708984
            },
            {
                "time": "Tue, Apr 9, 3:35 AM",
                "value": 60.02000045776367
            },
            {
                "time": "Tue, Apr 9, 3:35 AM",
                "value": 60.189998626708984
            },
            {
                "time": "Tue, Apr 9, 3:36 AM",
                "value": 60.209999084472656
            },
            {
                "time": "Tue, Apr 9, 3:36 AM",
                "value": 60.209999084472656
            },
            {
                "time": "Tue, Apr 9, 3:37 AM",
                "value": 59.9900016784668
            },
            {
                "time": "Tue, Apr 9, 3:37 AM",
                "value": 59.81999969482422
            },
            {
                "time": "Tue, Apr 9, 3:38 AM",
                "value": 60.02000045776367
            },
            {
                "time": "Tue, Apr 9, 3:38 AM",
                "value": 60.119998931884766
            },
            {
                "time": "Tue, Apr 9, 3:39 AM",
                "value": 60.29999923706055
            },
            {
                "time": "Tue, Apr 9, 3:39 AM",
                "value": 60.33000183105469
            },
            {
                "time": "Tue, Apr 9, 3:40 AM",
                "value": 60.2599983215332
            },
            {
                "time": "Tue, Apr 9, 3:40 AM",
                "value": 60.220001220703125
            },
            {
                "time": "Tue, Apr 9, 3:41 AM",
                "value": 60.279998779296875
            },
            {
                "time": "Tue, Apr 9, 3:41 AM",
                "value": 60.369998931884766
            },
            {
                "time": "Tue, Apr 9, 3:42 AM",
                "value": 60.439998626708984
            },
            {
                "time": "Tue, Apr 9, 3:42 AM",
                "value": 60.47999954223633
            },
            {
                "time": "Tue, Apr 9, 3:43 AM",
                "value": 60.4900016784668
            },
            {
                "time": "Tue, Apr 9, 3:43 AM",
                "value": 60.400001525878906
            },
            {
                "time": "Tue, Apr 9, 3:44 AM",
                "value": 60.220001220703125
            },
            {
                "time": "Tue, Apr 9, 3:44 AM",
                "value": 60.220001220703125
            },
            {
                "time": "Tue, Apr 9, 3:45 AM",
                "value": 60.33000183105469
            },
            {
                "time": "Tue, Apr 9, 3:45 AM",
                "value": 60.400001525878906
            },
            {
                "time": "Tue, Apr 9, 3:46 AM",
                "value": 60.33000183105469
            },
            {
                "time": "Tue, Apr 9, 3:46 AM",
                "value": 60.29999923706055
            },
            {
                "time": "Tue, Apr 9, 3:47 AM",
                "value": 60.119998931884766
            },
            {
                "time": "Tue, Apr 9, 3:47 AM",
                "value": 60.220001220703125
            },
            {
                "time": "Tue, Apr 9, 3:48 AM",
                "value": 60.2400016784668
            },
            {
                "time": "Tue, Apr 9, 3:48 AM",
                "value": 60.16999816894531
            },
            {
                "time": "Tue, Apr 9, 3:49 AM",
                "value": 60.130001068115234
            },
            {
                "time": "Tue, Apr 9, 3:49 AM",
                "value": 60.08000183105469
            },
            {
                "time": "Tue, Apr 9, 3:50 AM",
                "value": 60
            },
            {
                "time": "Tue, Apr 9, 3:50 AM",
                "value": 60.060001373291016
            },
            {
                "time": "Tue, Apr 9, 3:51 AM",
                "value": 60.130001068115234
            },
            {
                "time": "Tue, Apr 9, 3:51 AM",
                "value": 60.2400016784668
            },
            {
                "time": "Tue, Apr 9, 3:52 AM",
                "value": 60.439998626708984
            },
            {
                "time": "Tue, Apr 9, 3:52 AM",
                "value": 60.369998931884766
            },
            {
                "time": "Tue, Apr 9, 3:53 AM",
                "value": 60.38999938964844
            },
            {
                "time": "Tue, Apr 9, 3:53 AM",
                "value": 60.38999938964844
            },
            {
                "time": "Tue, Apr 9, 3:54 AM",
                "value": 60.369998931884766
            },
            {
                "time": "Tue, Apr 9, 3:54 AM",
                "value": 60.33000183105469
            },
            {
                "time": "Tue, Apr 9, 3:55 AM",
                "value": 60.150001525878906
            },
            {
                "time": "Tue, Apr 9, 3:55 AM",
                "value": 60.040000915527344
            },
            {
                "time": "Tue, Apr 9, 3:56 AM",
                "value": 60.02000045776367
            },
            {
                "time": "Tue, Apr 9, 3:56 AM",
                "value": 59.95000076293945
            },
            {
                "time": "Tue, Apr 9, 3:57 AM",
                "value": 59.880001068115234
            },
            {
                "time": "Tue, Apr 9, 3:57 AM",
                "value": 59.880001068115234
            },
            {
                "time": "Tue, Apr 9, 3:58 AM",
                "value": 59.86000061035156
            },
            {
                "time": "Tue, Apr 9, 3:58 AM",
                "value": 59.939998626708984
            },
            {
                "time": "Tue, Apr 9, 3:59 AM",
                "value": 60
            },
            {
                "time": "Tue, Apr 9, 3:59 AM",
                "value": 59.9900016784668
            },
            {
                "time": "Tue, Apr 9, 4:00 AM",
                "value": 59.91999816894531
            },
            {
                "time": "Tue, Apr 9, 4:00 AM",
                "value": 59.939998626708984
            },
            {
                "time": "Tue, Apr 9, 4:01 AM",
                "value": 60
            },
            {
                "time": "Tue, Apr 9, 4:01 AM",
                "value": 59.939998626708984
            },
            {
                "time": "Tue, Apr 9, 4:02 AM",
                "value": 59.84000015258789
            },
            {
                "time": "Tue, Apr 9, 4:02 AM",
                "value": 59.880001068115234
            },
            {
                "time": "Tue, Apr 9, 4:03 AM",
                "value": 59.970001220703125
            },
            {
                "time": "Tue, Apr 9, 4:03 AM",
                "value": 59.970001220703125
            },
            {
                "time": "Tue, Apr 9, 4:04 AM",
                "value": 59.86000061035156
            },
            {
                "time": "Tue, Apr 9, 4:04 AM",
                "value": 59.720001220703125
            },
            {
                "time": "Tue, Apr 9, 4:05 AM",
                "value": 59.56999969482422
            },
            {
                "time": "Tue, Apr 9, 4:05 AM",
                "value": 59.369998931884766
            },
            {
                "time": "Tue, Apr 9, 4:06 AM",
                "value": 59.47999954223633
            },
            {
                "time": "Tue, Apr 9, 4:06 AM",
                "value": 59.439998626708984
            },
            {
                "time": "Tue, Apr 9, 4:07 AM",
                "value": 59.349998474121094
            },
            {
                "time": "Tue, Apr 9, 4:07 AM",
                "value": 59.459999084472656
            },
            {
                "time": "Tue, Apr 9, 4:08 AM",
                "value": 59.540000915527344
            },
            {
                "time": "Tue, Apr 9, 4:08 AM",
                "value": 59.61000061035156
            },
            {
                "time": "Tue, Apr 9, 4:09 AM",
                "value": 59.630001068115234
            },
            {
                "time": "Tue, Apr 9, 4:09 AM",
                "value": 59.70000076293945
            },
            {
                "time": "Tue, Apr 9, 4:10 AM",
                "value": 59.61000061035156
            },
            {
                "time": "Tue, Apr 9, 4:10 AM",
                "value": 59.63999938964844
            },
            {
                "time": "Tue, Apr 9, 4:11 AM",
                "value": 59.630001068115234
            },
            {
                "time": "Tue, Apr 9, 4:11 AM",
                "value": 59.63999938964844
            },
            {
                "time": "Tue, Apr 9, 4:12 AM",
                "value": 59.68000030517578
            },
            {
                "time": "Tue, Apr 9, 4:12 AM",
                "value": 59.61000061035156
            },
            {
                "time": "Tue, Apr 9, 4:13 AM",
                "value": 59.47999954223633
            },
            {
                "time": "Tue, Apr 9, 4:13 AM",
                "value": 59.63999938964844
            },
            {
                "time": "Tue, Apr 9, 4:14 AM",
                "value": 59.72999954223633
            },
            {
                "time": "Tue, Apr 9, 4:14 AM",
                "value": 59.68000030517578
            },
            {
                "time": "Tue, Apr 9, 4:15 AM",
                "value": 59.63999938964844
            },
            {
                "time": "Tue, Apr 9, 4:15 AM",
                "value": 59.65999984741211
            },
            {
                "time": "Tue, Apr 9, 4:16 AM",
                "value": 59.68000030517578
            },
            {
                "time": "Tue, Apr 9, 4:16 AM",
                "value": 59.68000030517578
            },
            {
                "time": "Tue, Apr 9, 4:17 AM",
                "value": 59.54999923706055
            },
            {
                "time": "Tue, Apr 9, 4:17 AM",
                "value": 59.43000030517578
            },
            {
                "time": "Tue, Apr 9, 4:18 AM",
                "value": 59.279998779296875
            },
            {
                "time": "Tue, Apr 9, 4:18 AM",
                "value": 59.22999954223633
            },
            {
                "time": "Tue, Apr 9, 4:19 AM",
                "value": 59.2400016784668
            },
            {
                "time": "Tue, Apr 9, 4:19 AM",
                "value": 59.029998779296875
            },
            {
                "time": "Tue, Apr 9, 4:20 AM",
                "value": 58.869998931884766
            },
            {
                "time": "Tue, Apr 9, 4:20 AM",
                "value": 58.779998779296875
            },
            {
                "time": "Tue, Apr 9, 4:21 AM",
                "value": 58.7400016784668
            },
            {
                "time": "Tue, Apr 9, 4:21 AM",
                "value": 58.66999816894531
            },
            {
                "time": "Tue, Apr 9, 4:22 AM",
                "value": 58.66999816894531
            },
            {
                "time": "Tue, Apr 9, 4:22 AM",
                "value": 58.630001068115234
            },
            {
                "time": "Tue, Apr 9, 4:23 AM",
                "value": 58.58000183105469
            },
            {
                "time": "Tue, Apr 9, 4:23 AM",
                "value": 58.650001525878906
            },
            {
                "time": "Tue, Apr 9, 4:24 AM",
                "value": 58.560001373291016
            },
            {
                "time": "Tue, Apr 9, 4:24 AM",
                "value": 58.650001525878906
            },
            {
                "time": "Tue, Apr 9, 4:25 AM",
                "value": 58.66999816894531
            },
            {
                "time": "Tue, Apr 9, 4:25 AM",
                "value": 58.5
            },
            {
                "time": "Tue, Apr 9, 4:26 AM",
                "value": 58.400001525878906
            },
            {
                "time": "Tue, Apr 9, 4:26 AM",
                "value": 58.36000061035156
            },
            {
                "time": "Tue, Apr 9, 4:27 AM",
                "value": 58.5
            },
            {
                "time": "Tue, Apr 9, 4:27 AM",
                "value": 58.59000015258789
            },
            {
                "time": "Tue, Apr 9, 4:28 AM",
                "value": 58.59000015258789
            },
            {
                "time": "Tue, Apr 9, 4:28 AM",
                "value": 58.52000045776367
            },
            {
                "time": "Tue, Apr 9, 4:29 AM",
                "value": 58.7599983215332
            },
            {
                "time": "Tue, Apr 9, 4:29 AM",
                "value": 58.9900016784668
            },
            {
                "time": "Tue, Apr 9, 4:30 AM",
                "value": 59.04999923706055
            },
            {
                "time": "Tue, Apr 9, 4:30 AM",
                "value": 59.13999938964844
            },
            {
                "time": "Tue, Apr 9, 4:31 AM",
                "value": 59.209999084472656
            },
            {
                "time": "Tue, Apr 9, 4:31 AM",
                "value": 59.13999938964844
            },
            {
                "time": "Tue, Apr 9, 4:32 AM",
                "value": 58.959999084472656
            },
            {
                "time": "Tue, Apr 9, 4:32 AM",
                "value": 58.7599983215332
            },
            {
                "time": "Tue, Apr 9, 4:33 AM",
                "value": 58.61000061035156
            },
            {
                "time": "Tue, Apr 9, 4:33 AM",
                "value": 58.470001220703125
            },
            {
                "time": "Tue, Apr 9, 4:34 AM",
                "value": 58.45000076293945
            },
            {
                "time": "Tue, Apr 9, 4:34 AM",
                "value": 58.4900016784668
            },
            {
                "time": "Tue, Apr 9, 4:35 AM",
                "value": 58.52000045776367
            },
            {
                "time": "Tue, Apr 9, 4:35 AM",
                "value": 58.66999816894531
            },
            {
                "time": "Tue, Apr 9, 4:36 AM",
                "value": 58.7400016784668
            },
            {
                "time": "Tue, Apr 9, 4:36 AM",
                "value": 58.849998474121094
            },
            {
                "time": "Tue, Apr 9, 4:37 AM",
                "value": 59.0099983215332
            },
            {
                "time": "Tue, Apr 9, 4:37 AM",
                "value": 58.9900016784668
            },
            {
                "time": "Tue, Apr 9, 4:38 AM",
                "value": 58.9900016784668
            },
            {
                "time": "Tue, Apr 9, 4:38 AM",
                "value": 58.959999084472656
            },
            {
                "time": "Tue, Apr 9, 4:39 AM",
                "value": 59.13999938964844
            },
            {
                "time": "Tue, Apr 9, 4:39 AM",
                "value": 59.369998931884766
            },
            {
                "time": "Tue, Apr 9, 4:40 AM",
                "value": 59.459999084472656
            },
            {
                "time": "Tue, Apr 9, 4:40 AM",
                "value": 59.2599983215332
            },
            {
                "time": "Tue, Apr 9, 4:41 AM",
                "value": 59.08000183105469
            },
            {
                "time": "Tue, Apr 9, 4:41 AM",
                "value": 59.150001525878906
            },
            {
                "time": "Tue, Apr 9, 4:42 AM",
                "value": 59.22999954223633
            },
            {
                "time": "Tue, Apr 9, 4:42 AM",
                "value": 59.279998779296875
            },
            {
                "time": "Tue, Apr 9, 4:43 AM",
                "value": 59.2599983215332
            },
            {
                "time": "Tue, Apr 9, 4:43 AM",
                "value": 59.279998779296875
            },
            {
                "time": "Tue, Apr 9, 4:44 AM",
                "value": 59.349998474121094
            },
            {
                "time": "Tue, Apr 9, 4:44 AM",
                "value": 59.31999969482422
            },
            {
                "time": "Tue, Apr 9, 4:45 AM",
                "value": 59.43000030517578
            },
            {
                "time": "Tue, Apr 9, 4:45 AM",
                "value": 59.459999084472656
            },
            {
                "time": "Tue, Apr 9, 4:46 AM",
                "value": 59.279998779296875
            },
            {
                "time": "Tue, Apr 9, 4:46 AM",
                "value": 59.099998474121094
            },
            {
                "time": "Tue, Apr 9, 4:47 AM",
                "value": 58.880001068115234
            },
            {
                "time": "Tue, Apr 9, 4:47 AM",
                "value": 58.7400016784668
            },
            {
                "time": "Tue, Apr 9, 4:48 AM",
                "value": 58.720001220703125
            },
            {
                "time": "Tue, Apr 9, 4:48 AM",
                "value": 58.630001068115234
            },
            {
                "time": "Tue, Apr 9, 4:49 AM",
                "value": 58.61000061035156
            },
            {
                "time": "Tue, Apr 9, 4:49 AM",
                "value": 58.66999816894531
            },
            {
                "time": "Tue, Apr 9, 4:50 AM",
                "value": 58.66999816894531
            },
            {
                "time": "Tue, Apr 9, 4:50 AM",
                "value": 58.70000076293945
            },
            {
                "time": "Tue, Apr 9, 4:51 AM",
                "value": 58.880001068115234
            },
            {
                "time": "Tue, Apr 9, 4:51 AM",
                "value": 58.939998626708984
            },
            {
                "time": "Tue, Apr 9, 4:52 AM",
                "value": 58.91999816894531
            },
            {
                "time": "Tue, Apr 9, 4:52 AM",
                "value": 58.880001068115234
            },
            {
                "time": "Tue, Apr 9, 4:53 AM",
                "value": 58.7400016784668
            },
            {
                "time": "Tue, Apr 9, 4:53 AM",
                "value": 58.790000915527344
            },
            {
                "time": "Tue, Apr 9, 4:54 AM",
                "value": 59.04999923706055
            },
            {
                "time": "Tue, Apr 9, 4:54 AM",
                "value": 59.2400016784668
            },
            {
                "time": "Tue, Apr 9, 4:55 AM",
                "value": 59.34000015258789
            },
            {
                "time": "Tue, Apr 9, 4:55 AM",
                "value": 59.38999938964844
            },
            {
                "time": "Tue, Apr 9, 4:56 AM",
                "value": 59.279998779296875
            },
            {
                "time": "Tue, Apr 9, 4:56 AM",
                "value": 59.2400016784668
            },
            {
                "time": "Tue, Apr 9, 4:57 AM",
                "value": 59.349998474121094
            },
            {
                "time": "Tue, Apr 9, 4:57 AM",
                "value": 59.349998474121094
            },
            {
                "time": "Tue, Apr 9, 4:58 AM",
                "value": 59.38999938964844
            },
            {
                "time": "Tue, Apr 9, 4:58 AM",
                "value": 59.349998474121094
            },
            {
                "time": "Tue, Apr 9, 4:59 AM",
                "value": 59.279998779296875
            },
            {
                "time": "Tue, Apr 9, 4:59 AM",
                "value": 59.349998474121094
            }
        ]
    },
    "Jasper": {
        "solar": [
            {
                "time": "Mon, Apr 8, 5:00 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:00 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:01 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:01 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:02 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:02 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:03 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:03 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:04 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:04 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:05 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:05 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:06 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:06 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:07 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:07 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:08 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:08 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:09 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:09 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:10 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:10 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:11 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:11 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:12 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:12 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:13 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:13 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:14 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:14 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:15 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:15 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:16 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:16 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:17 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:17 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:18 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:18 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:19 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:19 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:20 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:20 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:21 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:21 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:22 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:22 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:23 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:23 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:24 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:24 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:25 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:25 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:26 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:26 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:27 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:27 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:28 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:28 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:29 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:29 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:30 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:30 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:31 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:31 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:32 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:32 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:33 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:33 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:34 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:34 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:35 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:35 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:36 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:36 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:37 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:37 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:38 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:38 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:39 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:39 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:40 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:40 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:41 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:41 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:42 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:42 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:43 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:43 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:44 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:44 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:45 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:45 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:46 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:46 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:47 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:47 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:48 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:48 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:49 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:49 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:50 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:50 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:51 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:51 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:52 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:52 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:53 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:53 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:54 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:54 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:55 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:55 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:56 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:56 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:57 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:57 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:58 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:58 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:59 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 5:59 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:00 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:00 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:01 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:01 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:02 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:02 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:03 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:03 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:04 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:04 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:05 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:05 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:06 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:06 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:07 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:07 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:08 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:08 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:09 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:09 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:10 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:10 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:11 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:11 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:12 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:12 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:13 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:13 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:14 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:14 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:15 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:15 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:16 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:16 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:17 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:17 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:18 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:18 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:19 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:19 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:20 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:20 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:21 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:21 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:22 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:22 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:23 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:23 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:24 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:24 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:25 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:25 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:26 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:26 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:27 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:27 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:28 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:28 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:29 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:29 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:30 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:30 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:31 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:31 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:32 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:32 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:33 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:33 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:34 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:34 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:35 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:35 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:36 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:36 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:37 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:37 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:38 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:38 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:39 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:39 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:40 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:40 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:41 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:41 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:42 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:42 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:43 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:43 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:44 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:44 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:45 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:45 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:46 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:46 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:47 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:47 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:48 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:48 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:49 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:49 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:50 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:50 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:51 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:51 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:52 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:52 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:53 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:53 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:54 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:54 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:55 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:55 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:56 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:56 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:57 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:57 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:58 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:58 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:59 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:59 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 7:00 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 7:00 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 7:01 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 7:01 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 7:02 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 7:02 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 7:03 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 7:03 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 7:04 AM",
                "value": 0.0020000000949949026
            },
            {
                "time": "Mon, Apr 8, 7:04 AM",
                "value": 0.0949999988079071
            },
            {
                "time": "Mon, Apr 8, 7:05 AM",
                "value": 0.06400000303983688
            },
            {
                "time": "Mon, Apr 8, 7:05 AM",
                "value": 0.08399999886751175
            },
            {
                "time": "Mon, Apr 8, 7:06 AM",
                "value": 0.15600000321865082
            },
            {
                "time": "Mon, Apr 8, 7:06 AM",
                "value": 0.19900000095367432
            },
            {
                "time": "Mon, Apr 8, 7:07 AM",
                "value": 0.2630000114440918
            },
            {
                "time": "Mon, Apr 8, 7:07 AM",
                "value": 0.31299999356269836
            },
            {
                "time": "Mon, Apr 8, 7:08 AM",
                "value": 0.33000001311302185
            },
            {
                "time": "Mon, Apr 8, 7:08 AM",
                "value": 0.4440000057220459
            },
            {
                "time": "Mon, Apr 8, 7:09 AM",
                "value": 0.5059999823570251
            },
            {
                "time": "Mon, Apr 8, 7:09 AM",
                "value": 0.5350000262260437
            },
            {
                "time": "Mon, Apr 8, 7:10 AM",
                "value": 0.6570000052452087
            },
            {
                "time": "Mon, Apr 8, 7:10 AM",
                "value": 0.7620000243186951
            },
            {
                "time": "Mon, Apr 8, 7:11 AM",
                "value": 0.8159999847412109
            },
            {
                "time": "Mon, Apr 8, 7:11 AM",
                "value": 0.9449999928474426
            },
            {
                "time": "Mon, Apr 8, 7:12 AM",
                "value": 0.9940000176429749
            },
            {
                "time": "Mon, Apr 8, 7:12 AM",
                "value": 1.1319999694824219
            },
            {
                "time": "Mon, Apr 8, 7:13 AM",
                "value": 1.1890000104904175
            },
            {
                "time": "Mon, Apr 8, 7:13 AM",
                "value": 1.315999984741211
            },
            {
                "time": "Mon, Apr 8, 7:14 AM",
                "value": 1.4390000104904175
            },
            {
                "time": "Mon, Apr 8, 7:14 AM",
                "value": 1.649999976158142
            },
            {
                "time": "Mon, Apr 8, 7:15 AM",
                "value": 1.781999945640564
            },
            {
                "time": "Mon, Apr 8, 7:15 AM",
                "value": 1.871000051498413
            },
            {
                "time": "Mon, Apr 8, 7:16 AM",
                "value": 2.1589999198913574
            },
            {
                "time": "Mon, Apr 8, 7:16 AM",
                "value": 2.299999952316284
            },
            {
                "time": "Mon, Apr 8, 7:17 AM",
                "value": 2.4609999656677246
            },
            {
                "time": "Mon, Apr 8, 7:17 AM",
                "value": 2.7070000171661377
            },
            {
                "time": "Mon, Apr 8, 7:18 AM",
                "value": 2.815999984741211
            },
            {
                "time": "Mon, Apr 8, 7:18 AM",
                "value": 3.0329999923706055
            },
            {
                "time": "Mon, Apr 8, 7:19 AM",
                "value": 3.1740000247955322
            },
            {
                "time": "Mon, Apr 8, 7:19 AM",
                "value": 3.315000057220459
            },
            {
                "time": "Mon, Apr 8, 7:20 AM",
                "value": 3.497999906539917
            },
            {
                "time": "Mon, Apr 8, 7:20 AM",
                "value": 3.734999895095825
            },
            {
                "time": "Mon, Apr 8, 7:21 AM",
                "value": 3.9749999046325684
            },
            {
                "time": "Mon, Apr 8, 7:21 AM",
                "value": 4.175000190734863
            },
            {
                "time": "Mon, Apr 8, 7:22 AM",
                "value": 4.374000072479248
            },
            {
                "time": "Mon, Apr 8, 7:22 AM",
                "value": 4.664999961853027
            },
            {
                "time": "Mon, Apr 8, 7:23 AM",
                "value": 4.929999828338623
            },
            {
                "time": "Mon, Apr 8, 7:23 AM",
                "value": 5.218999862670898
            },
            {
                "time": "Mon, Apr 8, 7:24 AM",
                "value": 5.491000175476074
            },
            {
                "time": "Mon, Apr 8, 7:24 AM",
                "value": 5.824999809265137
            },
            {
                "time": "Mon, Apr 8, 7:25 AM",
                "value": 6.165999889373779
            },
            {
                "time": "Mon, Apr 8, 7:25 AM",
                "value": 6.553999900817871
            },
            {
                "time": "Mon, Apr 8, 7:26 AM",
                "value": 6.8429999351501465
            },
            {
                "time": "Mon, Apr 8, 7:26 AM",
                "value": 7.23799991607666
            },
            {
                "time": "Mon, Apr 8, 7:27 AM",
                "value": 7.708000183105469
            },
            {
                "time": "Mon, Apr 8, 7:27 AM",
                "value": 8.109999656677246
            },
            {
                "time": "Mon, Apr 8, 7:28 AM",
                "value": 8.5600004196167
            },
            {
                "time": "Mon, Apr 8, 7:28 AM",
                "value": 8.9399995803833
            },
            {
                "time": "Mon, Apr 8, 7:29 AM",
                "value": 9.350000381469727
            },
            {
                "time": "Mon, Apr 8, 7:29 AM",
                "value": 9.829999923706055
            },
            {
                "time": "Mon, Apr 8, 7:30 AM",
                "value": 10.25
            },
            {
                "time": "Mon, Apr 8, 7:30 AM",
                "value": 10.75
            },
            {
                "time": "Mon, Apr 8, 7:31 AM",
                "value": 11.109999656677246
            },
            {
                "time": "Mon, Apr 8, 7:31 AM",
                "value": 11.609999656677246
            },
            {
                "time": "Mon, Apr 8, 7:32 AM",
                "value": 12.109999656677246
            },
            {
                "time": "Mon, Apr 8, 7:32 AM",
                "value": 12.630000114440918
            },
            {
                "time": "Mon, Apr 8, 7:33 AM",
                "value": 13.130000114440918
            },
            {
                "time": "Mon, Apr 8, 7:33 AM",
                "value": 13.630000114440918
            },
            {
                "time": "Mon, Apr 8, 7:34 AM",
                "value": 14.09000015258789
            },
            {
                "time": "Mon, Apr 8, 7:34 AM",
                "value": 14.649999618530273
            },
            {
                "time": "Mon, Apr 8, 7:35 AM",
                "value": 15.199999809265137
            },
            {
                "time": "Mon, Apr 8, 7:35 AM",
                "value": 15.770000457763672
            },
            {
                "time": "Mon, Apr 8, 7:36 AM",
                "value": 16.31999969482422
            },
            {
                "time": "Mon, Apr 8, 7:36 AM",
                "value": 17.010000228881836
            },
            {
                "time": "Mon, Apr 8, 7:37 AM",
                "value": 17.84000015258789
            },
            {
                "time": "Mon, Apr 8, 7:37 AM",
                "value": 18.479999542236328
            },
            {
                "time": "Mon, Apr 8, 7:38 AM",
                "value": 19.010000228881836
            },
            {
                "time": "Mon, Apr 8, 7:38 AM",
                "value": 19.719999313354492
            },
            {
                "time": "Mon, Apr 8, 7:39 AM",
                "value": 20.540000915527344
            },
            {
                "time": "Mon, Apr 8, 7:39 AM",
                "value": 21.709999084472656
            },
            {
                "time": "Mon, Apr 8, 7:40 AM",
                "value": 22.6200008392334
            },
            {
                "time": "Mon, Apr 8, 7:40 AM",
                "value": 23.729999542236328
            },
            {
                "time": "Mon, Apr 8, 7:41 AM",
                "value": 24.899999618530273
            },
            {
                "time": "Mon, Apr 8, 7:41 AM",
                "value": 26.139999389648438
            },
            {
                "time": "Mon, Apr 8, 7:42 AM",
                "value": 27.290000915527344
            },
            {
                "time": "Mon, Apr 8, 7:42 AM",
                "value": 28.690000534057617
            },
            {
                "time": "Mon, Apr 8, 7:43 AM",
                "value": 29.84000015258789
            },
            {
                "time": "Mon, Apr 8, 7:43 AM",
                "value": 30.790000915527344
            },
            {
                "time": "Mon, Apr 8, 7:44 AM",
                "value": 31.84000015258789
            },
            {
                "time": "Mon, Apr 8, 7:44 AM",
                "value": 33.72999954223633
            },
            {
                "time": "Mon, Apr 8, 7:45 AM",
                "value": 36.08000183105469
            },
            {
                "time": "Mon, Apr 8, 7:45 AM",
                "value": 38.20000076293945
            },
            {
                "time": "Mon, Apr 8, 7:46 AM",
                "value": 39.880001068115234
            },
            {
                "time": "Mon, Apr 8, 7:46 AM",
                "value": 41.2599983215332
            },
            {
                "time": "Mon, Apr 8, 7:47 AM",
                "value": 42.25
            },
            {
                "time": "Mon, Apr 8, 7:47 AM",
                "value": 43.060001373291016
            },
            {
                "time": "Mon, Apr 8, 7:48 AM",
                "value": 43.84000015258789
            },
            {
                "time": "Mon, Apr 8, 7:48 AM",
                "value": 43.970001220703125
            },
            {
                "time": "Mon, Apr 8, 7:49 AM",
                "value": 44.869998931884766
            },
            {
                "time": "Mon, Apr 8, 7:49 AM",
                "value": 46.599998474121094
            },
            {
                "time": "Mon, Apr 8, 7:50 AM",
                "value": 48.72999954223633
            },
            {
                "time": "Mon, Apr 8, 7:50 AM",
                "value": 50.880001068115234
            },
            {
                "time": "Mon, Apr 8, 7:51 AM",
                "value": 51.869998931884766
            },
            {
                "time": "Mon, Apr 8, 7:51 AM",
                "value": 51.83000183105469
            },
            {
                "time": "Mon, Apr 8, 7:52 AM",
                "value": 50.2599983215332
            },
            {
                "time": "Mon, Apr 8, 7:52 AM",
                "value": 48.75
            },
            {
                "time": "Mon, Apr 8, 7:53 AM",
                "value": 46.83000183105469
            },
            {
                "time": "Mon, Apr 8, 7:53 AM",
                "value": 45.34000015258789
            },
            {
                "time": "Mon, Apr 8, 7:54 AM",
                "value": 44.58000183105469
            },
            {
                "time": "Mon, Apr 8, 7:54 AM",
                "value": 43.86000061035156
            },
            {
                "time": "Mon, Apr 8, 7:55 AM",
                "value": 43.869998931884766
            },
            {
                "time": "Mon, Apr 8, 7:55 AM",
                "value": 45.45000076293945
            },
            {
                "time": "Mon, Apr 8, 7:56 AM",
                "value": 47.349998474121094
            },
            {
                "time": "Mon, Apr 8, 7:56 AM",
                "value": 49.86000061035156
            },
            {
                "time": "Mon, Apr 8, 7:57 AM",
                "value": 50.77000045776367
            },
            {
                "time": "Mon, Apr 8, 7:57 AM",
                "value": 49.38999938964844
            },
            {
                "time": "Mon, Apr 8, 7:58 AM",
                "value": 47.88999938964844
            },
            {
                "time": "Mon, Apr 8, 7:58 AM",
                "value": 47.5
            },
            {
                "time": "Mon, Apr 8, 7:59 AM",
                "value": 49.349998474121094
            },
            {
                "time": "Mon, Apr 8, 7:59 AM",
                "value": 51.33000183105469
            },
            {
                "time": "Mon, Apr 8, 8:00 AM",
                "value": 52.83000183105469
            },
            {
                "time": "Mon, Apr 8, 8:00 AM",
                "value": 53.16999816894531
            },
            {
                "time": "Mon, Apr 8, 8:01 AM",
                "value": 55.56999969482422
            },
            {
                "time": "Mon, Apr 8, 8:01 AM",
                "value": 62.40999984741211
            },
            {
                "time": "Mon, Apr 8, 8:02 AM",
                "value": 70.62000274658203
            },
            {
                "time": "Mon, Apr 8, 8:02 AM",
                "value": 78.87000274658203
            },
            {
                "time": "Mon, Apr 8, 8:03 AM",
                "value": 83.69999694824219
            },
            {
                "time": "Mon, Apr 8, 8:03 AM",
                "value": 85.80000305175781
            },
            {
                "time": "Mon, Apr 8, 8:04 AM",
                "value": 87.4000015258789
            },
            {
                "time": "Mon, Apr 8, 8:04 AM",
                "value": 88.80000305175781
            },
            {
                "time": "Mon, Apr 8, 8:05 AM",
                "value": 90.0999984741211
            },
            {
                "time": "Mon, Apr 8, 8:05 AM",
                "value": 91.5999984741211
            },
            {
                "time": "Mon, Apr 8, 8:06 AM",
                "value": 93.19999694824219
            },
            {
                "time": "Mon, Apr 8, 8:06 AM",
                "value": 94.5999984741211
            },
            {
                "time": "Mon, Apr 8, 8:07 AM",
                "value": 96.0999984741211
            },
            {
                "time": "Mon, Apr 8, 8:07 AM",
                "value": 97.5999984741211
            },
            {
                "time": "Mon, Apr 8, 8:08 AM",
                "value": 99
            },
            {
                "time": "Mon, Apr 8, 8:08 AM",
                "value": 100.4000015258789
            },
            {
                "time": "Mon, Apr 8, 8:09 AM",
                "value": 101.9000015258789
            },
            {
                "time": "Mon, Apr 8, 8:09 AM",
                "value": 103.4000015258789
            },
            {
                "time": "Mon, Apr 8, 8:10 AM",
                "value": 105
            },
            {
                "time": "Mon, Apr 8, 8:10 AM",
                "value": 106.5
            },
            {
                "time": "Mon, Apr 8, 8:11 AM",
                "value": 108
            },
            {
                "time": "Mon, Apr 8, 8:11 AM",
                "value": 109.80000305175781
            },
            {
                "time": "Mon, Apr 8, 8:12 AM",
                "value": 111.5
            },
            {
                "time": "Mon, Apr 8, 8:12 AM",
                "value": 113.0999984741211
            },
            {
                "time": "Mon, Apr 8, 8:13 AM",
                "value": 114.80000305175781
            },
            {
                "time": "Mon, Apr 8, 8:13 AM",
                "value": 116.5999984741211
            },
            {
                "time": "Mon, Apr 8, 8:14 AM",
                "value": 118.0999984741211
            },
            {
                "time": "Mon, Apr 8, 8:14 AM",
                "value": 119.80000305175781
            },
            {
                "time": "Mon, Apr 8, 8:15 AM",
                "value": 121.5999984741211
            },
            {
                "time": "Mon, Apr 8, 8:15 AM",
                "value": 123.19999694824219
            },
            {
                "time": "Mon, Apr 8, 8:16 AM",
                "value": 124.9000015258789
            },
            {
                "time": "Mon, Apr 8, 8:16 AM",
                "value": 126.5999984741211
            },
            {
                "time": "Mon, Apr 8, 8:17 AM",
                "value": 128.60000610351562
            },
            {
                "time": "Mon, Apr 8, 8:17 AM",
                "value": 130.3000030517578
            },
            {
                "time": "Mon, Apr 8, 8:18 AM",
                "value": 132.1999969482422
            },
            {
                "time": "Mon, Apr 8, 8:18 AM",
                "value": 134.1999969482422
            },
            {
                "time": "Mon, Apr 8, 8:19 AM",
                "value": 136.1999969482422
            },
            {
                "time": "Mon, Apr 8, 8:19 AM",
                "value": 138.10000610351562
            },
            {
                "time": "Mon, Apr 8, 8:20 AM",
                "value": 139.89999389648438
            },
            {
                "time": "Mon, Apr 8, 8:20 AM",
                "value": 141.8000030517578
            },
            {
                "time": "Mon, Apr 8, 8:21 AM",
                "value": 143.89999389648438
            },
            {
                "time": "Mon, Apr 8, 8:21 AM",
                "value": 146
            },
            {
                "time": "Mon, Apr 8, 8:22 AM",
                "value": 148
            },
            {
                "time": "Mon, Apr 8, 8:22 AM",
                "value": 150.39999389648438
            },
            {
                "time": "Mon, Apr 8, 8:23 AM",
                "value": 152.6999969482422
            },
            {
                "time": "Mon, Apr 8, 8:23 AM",
                "value": 154.89999389648438
            },
            {
                "time": "Mon, Apr 8, 8:24 AM",
                "value": 157.3000030517578
            },
            {
                "time": "Mon, Apr 8, 8:24 AM",
                "value": 159.3000030517578
            },
            {
                "time": "Mon, Apr 8, 8:25 AM",
                "value": 161.5
            },
            {
                "time": "Mon, Apr 8, 8:25 AM",
                "value": 164.10000610351562
            },
            {
                "time": "Mon, Apr 8, 8:26 AM",
                "value": 167
            },
            {
                "time": "Mon, Apr 8, 8:26 AM",
                "value": 157.10000610351562
            },
            {
                "time": "Mon, Apr 8, 8:27 AM",
                "value": 163.10000610351562
            },
            {
                "time": "Mon, Apr 8, 8:27 AM",
                "value": 174.60000610351562
            },
            {
                "time": "Mon, Apr 8, 8:28 AM",
                "value": 176.6999969482422
            },
            {
                "time": "Mon, Apr 8, 8:28 AM",
                "value": 178.5
            },
            {
                "time": "Mon, Apr 8, 8:29 AM",
                "value": 181.10000610351562
            },
            {
                "time": "Mon, Apr 8, 8:29 AM",
                "value": 183.10000610351562
            },
            {
                "time": "Mon, Apr 8, 8:30 AM",
                "value": 185.6999969482422
            },
            {
                "time": "Mon, Apr 8, 8:30 AM",
                "value": 187.89999389648438
            },
            {
                "time": "Mon, Apr 8, 8:31 AM",
                "value": 190.3000030517578
            },
            {
                "time": "Mon, Apr 8, 8:31 AM",
                "value": 192.89999389648438
            },
            {
                "time": "Mon, Apr 8, 8:32 AM",
                "value": 195.39999389648438
            },
            {
                "time": "Mon, Apr 8, 8:32 AM",
                "value": 197.8000030517578
            },
            {
                "time": "Mon, Apr 8, 8:33 AM",
                "value": 200.5
            },
            {
                "time": "Mon, Apr 8, 8:33 AM",
                "value": 200.8000030517578
            },
            {
                "time": "Mon, Apr 8, 8:34 AM",
                "value": 198.60000610351562
            },
            {
                "time": "Mon, Apr 8, 8:34 AM",
                "value": 181.89999389648438
            },
            {
                "time": "Mon, Apr 8, 8:35 AM",
                "value": 181.39999389648438
            },
            {
                "time": "Mon, Apr 8, 8:35 AM",
                "value": 197.89999389648438
            },
            {
                "time": "Mon, Apr 8, 8:36 AM",
                "value": 208.1999969482422
            },
            {
                "time": "Mon, Apr 8, 8:36 AM",
                "value": 216.60000610351562
            },
            {
                "time": "Mon, Apr 8, 8:37 AM",
                "value": 222.1999969482422
            },
            {
                "time": "Mon, Apr 8, 8:37 AM",
                "value": 227.5
            },
            {
                "time": "Mon, Apr 8, 8:38 AM",
                "value": 209.1999969482422
            },
            {
                "time": "Mon, Apr 8, 8:38 AM",
                "value": 168.60000610351562
            },
            {
                "time": "Mon, Apr 8, 8:39 AM",
                "value": 175.89999389648438
            },
            {
                "time": "Mon, Apr 8, 8:39 AM",
                "value": 154.1999969482422
            },
            {
                "time": "Mon, Apr 8, 8:40 AM",
                "value": 196.10000610351562
            },
            {
                "time": "Mon, Apr 8, 8:40 AM",
                "value": 203.39999389648438
            },
            {
                "time": "Mon, Apr 8, 8:41 AM",
                "value": 225.3000030517578
            },
            {
                "time": "Mon, Apr 8, 8:41 AM",
                "value": 233.1999969482422
            },
            {
                "time": "Mon, Apr 8, 8:42 AM",
                "value": 222.6999969482422
            },
            {
                "time": "Mon, Apr 8, 8:42 AM",
                "value": 233.8000030517578
            },
            {
                "time": "Mon, Apr 8, 8:43 AM",
                "value": 243.1999969482422
            },
            {
                "time": "Mon, Apr 8, 8:43 AM",
                "value": 245.39999389648438
            },
            {
                "time": "Mon, Apr 8, 8:44 AM",
                "value": 245.39999389648438
            },
            {
                "time": "Mon, Apr 8, 8:44 AM",
                "value": 245.3000030517578
            },
            {
                "time": "Mon, Apr 8, 8:45 AM",
                "value": 247.89999389648438
            },
            {
                "time": "Mon, Apr 8, 8:45 AM",
                "value": 241.3000030517578
            },
            {
                "time": "Mon, Apr 8, 8:46 AM",
                "value": 232.89999389648438
            },
            {
                "time": "Mon, Apr 8, 8:46 AM",
                "value": 203.5
            },
            {
                "time": "Mon, Apr 8, 8:47 AM",
                "value": 183
            },
            {
                "time": "Mon, Apr 8, 8:47 AM",
                "value": 192.8000030517578
            },
            {
                "time": "Mon, Apr 8, 8:48 AM",
                "value": 194.89999389648438
            },
            {
                "time": "Mon, Apr 8, 8:48 AM",
                "value": 206
            },
            {
                "time": "Mon, Apr 8, 8:49 AM",
                "value": 195.6999969482422
            },
            {
                "time": "Mon, Apr 8, 8:49 AM",
                "value": 182.6999969482422
            },
            {
                "time": "Mon, Apr 8, 8:50 AM",
                "value": 172.8000030517578
            },
            {
                "time": "Mon, Apr 8, 8:50 AM",
                "value": 172.5
            },
            {
                "time": "Mon, Apr 8, 8:51 AM",
                "value": 175
            },
            {
                "time": "Mon, Apr 8, 8:51 AM",
                "value": 176.1999969482422
            },
            {
                "time": "Mon, Apr 8, 8:52 AM",
                "value": 186.60000610351562
            },
            {
                "time": "Mon, Apr 8, 8:52 AM",
                "value": 203.60000610351562
            },
            {
                "time": "Mon, Apr 8, 8:53 AM",
                "value": 196.10000610351562
            },
            {
                "time": "Mon, Apr 8, 8:53 AM",
                "value": 193.10000610351562
            },
            {
                "time": "Mon, Apr 8, 8:54 AM",
                "value": 190.5
            },
            {
                "time": "Mon, Apr 8, 8:54 AM",
                "value": 186.3000030517578
            },
            {
                "time": "Mon, Apr 8, 8:55 AM",
                "value": 181
            },
            {
                "time": "Mon, Apr 8, 8:55 AM",
                "value": 190.39999389648438
            },
            {
                "time": "Mon, Apr 8, 8:56 AM",
                "value": 189.5
            },
            {
                "time": "Mon, Apr 8, 8:56 AM",
                "value": 182.1999969482422
            },
            {
                "time": "Mon, Apr 8, 8:57 AM",
                "value": 182.39999389648438
            },
            {
                "time": "Mon, Apr 8, 8:57 AM",
                "value": 186.10000610351562
            },
            {
                "time": "Mon, Apr 8, 8:58 AM",
                "value": 218.5
            },
            {
                "time": "Mon, Apr 8, 8:58 AM",
                "value": 241.6999969482422
            },
            {
                "time": "Mon, Apr 8, 8:59 AM",
                "value": 243.10000610351562
            },
            {
                "time": "Mon, Apr 8, 8:59 AM",
                "value": 200.89999389648438
            },
            {
                "time": "Mon, Apr 8, 9:00 AM",
                "value": 231.39999389648438
            },
            {
                "time": "Mon, Apr 8, 9:00 AM",
                "value": 231.1999969482422
            },
            {
                "time": "Mon, Apr 8, 9:01 AM",
                "value": 199.5
            },
            {
                "time": "Mon, Apr 8, 9:01 AM",
                "value": 257.70001220703125
            },
            {
                "time": "Mon, Apr 8, 9:02 AM",
                "value": 289
            },
            {
                "time": "Mon, Apr 8, 9:02 AM",
                "value": 266.29998779296875
            },
            {
                "time": "Mon, Apr 8, 9:03 AM",
                "value": 268.5
            },
            {
                "time": "Mon, Apr 8, 9:03 AM",
                "value": 296.79998779296875
            },
            {
                "time": "Mon, Apr 8, 9:04 AM",
                "value": 293.29998779296875
            },
            {
                "time": "Mon, Apr 8, 9:04 AM",
                "value": 290.70001220703125
            },
            {
                "time": "Mon, Apr 8, 9:05 AM",
                "value": 285
            },
            {
                "time": "Mon, Apr 8, 9:05 AM",
                "value": 282.5
            },
            {
                "time": "Mon, Apr 8, 9:06 AM",
                "value": 265.3999938964844
            },
            {
                "time": "Mon, Apr 8, 9:06 AM",
                "value": 235.1999969482422
            },
            {
                "time": "Mon, Apr 8, 9:07 AM",
                "value": 214.89999389648438
            },
            {
                "time": "Mon, Apr 8, 9:07 AM",
                "value": 199.1999969482422
            },
            {
                "time": "Mon, Apr 8, 9:08 AM",
                "value": 194.60000610351562
            },
            {
                "time": "Mon, Apr 8, 9:08 AM",
                "value": 176.60000610351562
            },
            {
                "time": "Mon, Apr 8, 9:09 AM",
                "value": 209.10000610351562
            },
            {
                "time": "Mon, Apr 8, 9:09 AM",
                "value": 244.1999969482422
            },
            {
                "time": "Mon, Apr 8, 9:10 AM",
                "value": 225.8000030517578
            },
            {
                "time": "Mon, Apr 8, 9:10 AM",
                "value": 224.10000610351562
            },
            {
                "time": "Mon, Apr 8, 9:11 AM",
                "value": 196.1999969482422
            },
            {
                "time": "Mon, Apr 8, 9:11 AM",
                "value": 256.8999938964844
            },
            {
                "time": "Mon, Apr 8, 9:12 AM",
                "value": 244.3000030517578
            },
            {
                "time": "Mon, Apr 8, 9:12 AM",
                "value": 254.6999969482422
            },
            {
                "time": "Mon, Apr 8, 9:13 AM",
                "value": 256.8999938964844
            },
            {
                "time": "Mon, Apr 8, 9:13 AM",
                "value": 253.39999389648438
            },
            {
                "time": "Mon, Apr 8, 9:14 AM",
                "value": 218.39999389648438
            },
            {
                "time": "Mon, Apr 8, 9:14 AM",
                "value": 233.8000030517578
            },
            {
                "time": "Mon, Apr 8, 9:15 AM",
                "value": 307.20001220703125
            },
            {
                "time": "Mon, Apr 8, 9:15 AM",
                "value": 312.70001220703125
            },
            {
                "time": "Mon, Apr 8, 9:16 AM",
                "value": 299.79998779296875
            },
            {
                "time": "Mon, Apr 8, 9:16 AM",
                "value": 327.29998779296875
            },
            {
                "time": "Mon, Apr 8, 9:17 AM",
                "value": 331.8999938964844
            },
            {
                "time": "Mon, Apr 8, 9:17 AM",
                "value": 328.29998779296875
            },
            {
                "time": "Mon, Apr 8, 9:18 AM",
                "value": 323.3999938964844
            },
            {
                "time": "Mon, Apr 8, 9:18 AM",
                "value": 327.29998779296875
            },
            {
                "time": "Mon, Apr 8, 9:19 AM",
                "value": 329.29998779296875
            },
            {
                "time": "Mon, Apr 8, 9:19 AM",
                "value": 343.3999938964844
            },
            {
                "time": "Mon, Apr 8, 9:20 AM",
                "value": 334.1000061035156
            },
            {
                "time": "Mon, Apr 8, 9:20 AM",
                "value": 321.20001220703125
            },
            {
                "time": "Mon, Apr 8, 9:21 AM",
                "value": 338.5
            },
            {
                "time": "Mon, Apr 8, 9:21 AM",
                "value": 353.1000061035156
            },
            {
                "time": "Mon, Apr 8, 9:22 AM",
                "value": 336.1000061035156
            },
            {
                "time": "Mon, Apr 8, 9:22 AM",
                "value": 310.70001220703125
            },
            {
                "time": "Mon, Apr 8, 9:23 AM",
                "value": 310.79998779296875
            },
            {
                "time": "Mon, Apr 8, 9:23 AM",
                "value": 299.8999938964844
            },
            {
                "time": "Mon, Apr 8, 9:24 AM",
                "value": 306.79998779296875
            },
            {
                "time": "Mon, Apr 8, 9:24 AM",
                "value": 321.8999938964844
            },
            {
                "time": "Mon, Apr 8, 9:25 AM",
                "value": 317.3999938964844
            },
            {
                "time": "Mon, Apr 8, 9:25 AM",
                "value": 285.79998779296875
            },
            {
                "time": "Mon, Apr 8, 9:26 AM",
                "value": 294.8999938964844
            },
            {
                "time": "Mon, Apr 8, 9:26 AM",
                "value": 290.20001220703125
            },
            {
                "time": "Mon, Apr 8, 9:27 AM",
                "value": 231.1999969482422
            },
            {
                "time": "Mon, Apr 8, 9:27 AM",
                "value": 243.6999969482422
            },
            {
                "time": "Mon, Apr 8, 9:28 AM",
                "value": 280.8999938964844
            },
            {
                "time": "Mon, Apr 8, 9:28 AM",
                "value": 323
            },
            {
                "time": "Mon, Apr 8, 9:29 AM",
                "value": 347.20001220703125
            },
            {
                "time": "Mon, Apr 8, 9:29 AM",
                "value": 338
            },
            {
                "time": "Mon, Apr 8, 9:30 AM",
                "value": 330.3999938964844
            },
            {
                "time": "Mon, Apr 8, 9:30 AM",
                "value": 324.70001220703125
            },
            {
                "time": "Mon, Apr 8, 9:31 AM",
                "value": 313.20001220703125
            },
            {
                "time": "Mon, Apr 8, 9:31 AM",
                "value": 286.79998779296875
            },
            {
                "time": "Mon, Apr 8, 9:32 AM",
                "value": 293.1000061035156
            },
            {
                "time": "Mon, Apr 8, 9:32 AM",
                "value": 289.6000061035156
            },
            {
                "time": "Mon, Apr 8, 9:33 AM",
                "value": 294.70001220703125
            },
            {
                "time": "Mon, Apr 8, 9:33 AM",
                "value": 310.8999938964844
            },
            {
                "time": "Mon, Apr 8, 9:34 AM",
                "value": 328.79998779296875
            },
            {
                "time": "Mon, Apr 8, 9:34 AM",
                "value": 321
            },
            {
                "time": "Mon, Apr 8, 9:35 AM",
                "value": 305.8999938964844
            },
            {
                "time": "Mon, Apr 8, 9:35 AM",
                "value": 307.29998779296875
            },
            {
                "time": "Mon, Apr 8, 9:36 AM",
                "value": 300.79998779296875
            },
            {
                "time": "Mon, Apr 8, 9:36 AM",
                "value": 315.5
            },
            {
                "time": "Mon, Apr 8, 9:37 AM",
                "value": 312.8999938964844
            },
            {
                "time": "Mon, Apr 8, 9:37 AM",
                "value": 271.5
            },
            {
                "time": "Mon, Apr 8, 9:38 AM",
                "value": 281.70001220703125
            },
            {
                "time": "Mon, Apr 8, 9:38 AM",
                "value": 327
            },
            {
                "time": "Mon, Apr 8, 9:39 AM",
                "value": 333.20001220703125
            },
            {
                "time": "Mon, Apr 8, 9:39 AM",
                "value": 380.29998779296875
            },
            {
                "time": "Mon, Apr 8, 9:40 AM",
                "value": 385.70001220703125
            },
            {
                "time": "Mon, Apr 8, 9:40 AM",
                "value": 382.29998779296875
            },
            {
                "time": "Mon, Apr 8, 9:41 AM",
                "value": 331.29998779296875
            },
            {
                "time": "Mon, Apr 8, 9:41 AM",
                "value": 330.29998779296875
            },
            {
                "time": "Mon, Apr 8, 9:42 AM",
                "value": 422
            },
            {
                "time": "Mon, Apr 8, 9:42 AM",
                "value": 403.3999938964844
            },
            {
                "time": "Mon, Apr 8, 9:43 AM",
                "value": 373.6000061035156
            },
            {
                "time": "Mon, Apr 8, 9:43 AM",
                "value": 377.3999938964844
            },
            {
                "time": "Mon, Apr 8, 9:44 AM",
                "value": 380.3999938964844
            },
            {
                "time": "Mon, Apr 8, 9:44 AM",
                "value": 385.8999938964844
            },
            {
                "time": "Mon, Apr 8, 9:45 AM",
                "value": 383.70001220703125
            },
            {
                "time": "Mon, Apr 8, 9:45 AM",
                "value": 376
            },
            {
                "time": "Mon, Apr 8, 9:46 AM",
                "value": 313
            },
            {
                "time": "Mon, Apr 8, 9:46 AM",
                "value": 330.3999938964844
            },
            {
                "time": "Mon, Apr 8, 9:47 AM",
                "value": 338.20001220703125
            },
            {
                "time": "Mon, Apr 8, 9:47 AM",
                "value": 329.79998779296875
            },
            {
                "time": "Mon, Apr 8, 9:48 AM",
                "value": 317.3999938964844
            },
            {
                "time": "Mon, Apr 8, 9:48 AM",
                "value": 307.3999938964844
            },
            {
                "time": "Mon, Apr 8, 9:49 AM",
                "value": 306.3999938964844
            },
            {
                "time": "Mon, Apr 8, 9:49 AM",
                "value": 322.5
            },
            {
                "time": "Mon, Apr 8, 9:50 AM",
                "value": 368.3999938964844
            },
            {
                "time": "Mon, Apr 8, 9:50 AM",
                "value": 392.29998779296875
            },
            {
                "time": "Mon, Apr 8, 9:51 AM",
                "value": 391.3999938964844
            },
            {
                "time": "Mon, Apr 8, 9:51 AM",
                "value": 357
            },
            {
                "time": "Mon, Apr 8, 9:52 AM",
                "value": 342.20001220703125
            },
            {
                "time": "Mon, Apr 8, 9:52 AM",
                "value": 349.5
            },
            {
                "time": "Mon, Apr 8, 9:53 AM",
                "value": 399.70001220703125
            },
            {
                "time": "Mon, Apr 8, 9:53 AM",
                "value": 423.3999938964844
            },
            {
                "time": "Mon, Apr 8, 9:54 AM",
                "value": 410
            },
            {
                "time": "Mon, Apr 8, 9:54 AM",
                "value": 325.3999938964844
            },
            {
                "time": "Mon, Apr 8, 9:55 AM",
                "value": 376.70001220703125
            },
            {
                "time": "Mon, Apr 8, 9:55 AM",
                "value": 376.20001220703125
            },
            {
                "time": "Mon, Apr 8, 9:56 AM",
                "value": 358.8999938964844
            },
            {
                "time": "Mon, Apr 8, 9:56 AM",
                "value": 344.3999938964844
            },
            {
                "time": "Mon, Apr 8, 9:57 AM",
                "value": 344.70001220703125
            },
            {
                "time": "Mon, Apr 8, 9:57 AM",
                "value": 385.29998779296875
            },
            {
                "time": "Mon, Apr 8, 9:58 AM",
                "value": 409
            },
            {
                "time": "Mon, Apr 8, 9:58 AM",
                "value": 439.70001220703125
            },
            {
                "time": "Mon, Apr 8, 9:59 AM",
                "value": 442.5
            },
            {
                "time": "Mon, Apr 8, 9:59 AM",
                "value": 419.20001220703125
            },
            {
                "time": "Mon, Apr 8, 10:00 AM",
                "value": 396.5
            },
            {
                "time": "Mon, Apr 8, 10:00 AM",
                "value": 385.5
            },
            {
                "time": "Mon, Apr 8, 10:01 AM",
                "value": 379.8999938964844
            },
            {
                "time": "Mon, Apr 8, 10:01 AM",
                "value": 351.29998779296875
            },
            {
                "time": "Mon, Apr 8, 10:02 AM",
                "value": 338.20001220703125
            },
            {
                "time": "Mon, Apr 8, 10:02 AM",
                "value": 338.3999938964844
            },
            {
                "time": "Mon, Apr 8, 10:03 AM",
                "value": 340.29998779296875
            },
            {
                "time": "Mon, Apr 8, 10:03 AM",
                "value": 339.6000061035156
            },
            {
                "time": "Mon, Apr 8, 10:04 AM",
                "value": 349.29998779296875
            },
            {
                "time": "Mon, Apr 8, 10:04 AM",
                "value": 354.70001220703125
            },
            {
                "time": "Mon, Apr 8, 10:05 AM",
                "value": 345.20001220703125
            },
            {
                "time": "Mon, Apr 8, 10:05 AM",
                "value": 351.79998779296875
            },
            {
                "time": "Mon, Apr 8, 10:06 AM",
                "value": 339.3999938964844
            },
            {
                "time": "Mon, Apr 8, 10:06 AM",
                "value": 364.29998779296875
            },
            {
                "time": "Mon, Apr 8, 10:07 AM",
                "value": 367.20001220703125
            },
            {
                "time": "Mon, Apr 8, 10:07 AM",
                "value": 376.5
            },
            {
                "time": "Mon, Apr 8, 10:08 AM",
                "value": 368.79998779296875
            },
            {
                "time": "Mon, Apr 8, 10:08 AM",
                "value": 380.8999938964844
            },
            {
                "time": "Mon, Apr 8, 10:09 AM",
                "value": 358.29998779296875
            },
            {
                "time": "Mon, Apr 8, 10:09 AM",
                "value": 303.79998779296875
            },
            {
                "time": "Mon, Apr 8, 10:10 AM",
                "value": 308.1000061035156
            },
            {
                "time": "Mon, Apr 8, 10:10 AM",
                "value": 293.1000061035156
            },
            {
                "time": "Mon, Apr 8, 10:11 AM",
                "value": 280.79998779296875
            },
            {
                "time": "Mon, Apr 8, 10:11 AM",
                "value": 269.8999938964844
            },
            {
                "time": "Mon, Apr 8, 10:12 AM",
                "value": 244.8000030517578
            },
            {
                "time": "Mon, Apr 8, 10:12 AM",
                "value": 252.39999389648438
            },
            {
                "time": "Mon, Apr 8, 10:13 AM",
                "value": 272.6000061035156
            },
            {
                "time": "Mon, Apr 8, 10:13 AM",
                "value": 286.1000061035156
            },
            {
                "time": "Mon, Apr 8, 10:14 AM",
                "value": 297.20001220703125
            },
            {
                "time": "Mon, Apr 8, 10:14 AM",
                "value": 329.5
            },
            {
                "time": "Mon, Apr 8, 10:15 AM",
                "value": 322.5
            },
            {
                "time": "Mon, Apr 8, 10:15 AM",
                "value": 353.5
            },
            {
                "time": "Mon, Apr 8, 10:16 AM",
                "value": 383.1000061035156
            },
            {
                "time": "Mon, Apr 8, 10:16 AM",
                "value": 371.8999938964844
            },
            {
                "time": "Mon, Apr 8, 10:17 AM",
                "value": 358.8999938964844
            },
            {
                "time": "Mon, Apr 8, 10:17 AM",
                "value": 375.79998779296875
            },
            {
                "time": "Mon, Apr 8, 10:18 AM",
                "value": 408.70001220703125
            },
            {
                "time": "Mon, Apr 8, 10:18 AM",
                "value": 401.3999938964844
            },
            {
                "time": "Mon, Apr 8, 10:19 AM",
                "value": 387.70001220703125
            },
            {
                "time": "Mon, Apr 8, 10:19 AM",
                "value": 389.3999938964844
            },
            {
                "time": "Mon, Apr 8, 10:20 AM",
                "value": 402.29998779296875
            },
            {
                "time": "Mon, Apr 8, 10:20 AM",
                "value": 389.29998779296875
            },
            {
                "time": "Mon, Apr 8, 10:21 AM",
                "value": 368.1000061035156
            },
            {
                "time": "Mon, Apr 8, 10:21 AM",
                "value": 345.79998779296875
            },
            {
                "time": "Mon, Apr 8, 10:22 AM",
                "value": 346.20001220703125
            },
            {
                "time": "Mon, Apr 8, 10:22 AM",
                "value": 338.70001220703125
            },
            {
                "time": "Mon, Apr 8, 10:23 AM",
                "value": 354.8999938964844
            },
            {
                "time": "Mon, Apr 8, 10:23 AM",
                "value": 358.1000061035156
            },
            {
                "time": "Mon, Apr 8, 10:24 AM",
                "value": 387.6000061035156
            },
            {
                "time": "Mon, Apr 8, 10:24 AM",
                "value": 392.1000061035156
            },
            {
                "time": "Mon, Apr 8, 10:25 AM",
                "value": 384.5
            },
            {
                "time": "Mon, Apr 8, 10:25 AM",
                "value": 496
            },
            {
                "time": "Mon, Apr 8, 10:26 AM",
                "value": 519.4000244140625
            },
            {
                "time": "Mon, Apr 8, 10:26 AM",
                "value": 531.2000122070312
            },
            {
                "time": "Mon, Apr 8, 10:27 AM",
                "value": 532
            },
            {
                "time": "Mon, Apr 8, 10:27 AM",
                "value": 530.7000122070312
            },
            {
                "time": "Mon, Apr 8, 10:28 AM",
                "value": 553.5999755859375
            },
            {
                "time": "Mon, Apr 8, 10:28 AM",
                "value": 540.2999877929688
            },
            {
                "time": "Mon, Apr 8, 10:29 AM",
                "value": 486.1000061035156
            },
            {
                "time": "Mon, Apr 8, 10:29 AM",
                "value": 575.7999877929688
            },
            {
                "time": "Mon, Apr 8, 10:30 AM",
                "value": 594.5
            },
            {
                "time": "Mon, Apr 8, 10:30 AM",
                "value": 578.4000244140625
            },
            {
                "time": "Mon, Apr 8, 10:31 AM",
                "value": 593.7999877929688
            },
            {
                "time": "Mon, Apr 8, 10:31 AM",
                "value": 593.9000244140625
            },
            {
                "time": "Mon, Apr 8, 10:32 AM",
                "value": 551.5
            },
            {
                "time": "Mon, Apr 8, 10:32 AM",
                "value": 541.2999877929688
            },
            {
                "time": "Mon, Apr 8, 10:33 AM",
                "value": 520.7999877929688
            },
            {
                "time": "Mon, Apr 8, 10:33 AM",
                "value": 447.8999938964844
            },
            {
                "time": "Mon, Apr 8, 10:34 AM",
                "value": 498.1000061035156
            },
            {
                "time": "Mon, Apr 8, 10:34 AM",
                "value": 508.70001220703125
            },
            {
                "time": "Mon, Apr 8, 10:35 AM",
                "value": 508.70001220703125
            },
            {
                "time": "Mon, Apr 8, 10:35 AM",
                "value": 564.7000122070312
            },
            {
                "time": "Mon, Apr 8, 10:36 AM",
                "value": 469
            },
            {
                "time": "Mon, Apr 8, 10:36 AM",
                "value": 556.7999877929688
            },
            {
                "time": "Mon, Apr 8, 10:37 AM",
                "value": 627.5
            },
            {
                "time": "Mon, Apr 8, 10:37 AM",
                "value": 642
            },
            {
                "time": "Mon, Apr 8, 10:38 AM",
                "value": 585.5999755859375
            },
            {
                "time": "Mon, Apr 8, 10:38 AM",
                "value": 609.2999877929688
            },
            {
                "time": "Mon, Apr 8, 10:39 AM",
                "value": 559.4000244140625
            },
            {
                "time": "Mon, Apr 8, 10:39 AM",
                "value": 625.9000244140625
            },
            {
                "time": "Mon, Apr 8, 10:40 AM",
                "value": 660.4000244140625
            },
            {
                "time": "Mon, Apr 8, 10:40 AM",
                "value": 661.4000244140625
            },
            {
                "time": "Mon, Apr 8, 10:41 AM",
                "value": 614.2000122070312
            },
            {
                "time": "Mon, Apr 8, 10:41 AM",
                "value": 611.4000244140625
            },
            {
                "time": "Mon, Apr 8, 10:42 AM",
                "value": 564.4000244140625
            },
            {
                "time": "Mon, Apr 8, 10:42 AM",
                "value": 535.0999755859375
            },
            {
                "time": "Mon, Apr 8, 10:43 AM",
                "value": 560.7000122070312
            },
            {
                "time": "Mon, Apr 8, 10:43 AM",
                "value": 543.7000122070312
            },
            {
                "time": "Mon, Apr 8, 10:44 AM",
                "value": 599.7999877929688
            },
            {
                "time": "Mon, Apr 8, 10:44 AM",
                "value": 642.5
            },
            {
                "time": "Mon, Apr 8, 10:45 AM",
                "value": 654.5999755859375
            },
            {
                "time": "Mon, Apr 8, 10:45 AM",
                "value": 664.2000122070312
            },
            {
                "time": "Mon, Apr 8, 10:46 AM",
                "value": 663.2999877929688
            },
            {
                "time": "Mon, Apr 8, 10:46 AM",
                "value": 665.2999877929688
            },
            {
                "time": "Mon, Apr 8, 10:47 AM",
                "value": 671
            },
            {
                "time": "Mon, Apr 8, 10:47 AM",
                "value": 657.7999877929688
            },
            {
                "time": "Mon, Apr 8, 10:48 AM",
                "value": 657.9000244140625
            },
            {
                "time": "Mon, Apr 8, 10:48 AM",
                "value": 652
            },
            {
                "time": "Mon, Apr 8, 10:49 AM",
                "value": 645.0999755859375
            },
            {
                "time": "Mon, Apr 8, 10:49 AM",
                "value": 624.7000122070312
            },
            {
                "time": "Mon, Apr 8, 10:50 AM",
                "value": 630.5999755859375
            },
            {
                "time": "Mon, Apr 8, 10:50 AM",
                "value": 653
            },
            {
                "time": "Mon, Apr 8, 10:51 AM",
                "value": 597.0999755859375
            },
            {
                "time": "Mon, Apr 8, 10:51 AM",
                "value": 617
            },
            {
                "time": "Mon, Apr 8, 10:52 AM",
                "value": 564.4000244140625
            },
            {
                "time": "Mon, Apr 8, 10:52 AM",
                "value": 478.8999938964844
            },
            {
                "time": "Mon, Apr 8, 10:53 AM",
                "value": 541.4000244140625
            },
            {
                "time": "Mon, Apr 8, 10:53 AM",
                "value": 612.7000122070312
            },
            {
                "time": "Mon, Apr 8, 10:54 AM",
                "value": 614.0999755859375
            },
            {
                "time": "Mon, Apr 8, 10:54 AM",
                "value": 618.7000122070312
            },
            {
                "time": "Mon, Apr 8, 10:55 AM",
                "value": 565.2999877929688
            },
            {
                "time": "Mon, Apr 8, 10:55 AM",
                "value": 581
            },
            {
                "time": "Mon, Apr 8, 10:56 AM",
                "value": 588.9000244140625
            },
            {
                "time": "Mon, Apr 8, 10:56 AM",
                "value": 602.9000244140625
            },
            {
                "time": "Mon, Apr 8, 10:57 AM",
                "value": 622.5
            },
            {
                "time": "Mon, Apr 8, 10:57 AM",
                "value": 614.2000122070312
            },
            {
                "time": "Mon, Apr 8, 10:58 AM",
                "value": 643.2000122070312
            },
            {
                "time": "Mon, Apr 8, 10:58 AM",
                "value": 657.5
            },
            {
                "time": "Mon, Apr 8, 10:59 AM",
                "value": 658.2000122070312
            },
            {
                "time": "Mon, Apr 8, 10:59 AM",
                "value": 661.2000122070312
            },
            {
                "time": "Mon, Apr 8, 11:00 AM",
                "value": 659
            },
            {
                "time": "Mon, Apr 8, 11:00 AM",
                "value": 660.9000244140625
            },
            {
                "time": "Mon, Apr 8, 11:01 AM",
                "value": 659.2000122070312
            },
            {
                "time": "Mon, Apr 8, 11:01 AM",
                "value": 657.5999755859375
            },
            {
                "time": "Mon, Apr 8, 11:02 AM",
                "value": 659.7000122070312
            },
            {
                "time": "Mon, Apr 8, 11:02 AM",
                "value": 661.7000122070312
            },
            {
                "time": "Mon, Apr 8, 11:03 AM",
                "value": 663.5999755859375
            },
            {
                "time": "Mon, Apr 8, 11:03 AM",
                "value": 666.5
            },
            {
                "time": "Mon, Apr 8, 11:04 AM",
                "value": 672.2000122070312
            },
            {
                "time": "Mon, Apr 8, 11:04 AM",
                "value": 675.9000244140625
            },
            {
                "time": "Mon, Apr 8, 11:05 AM",
                "value": 666
            },
            {
                "time": "Mon, Apr 8, 11:05 AM",
                "value": 669.7999877929688
            },
            {
                "time": "Mon, Apr 8, 11:06 AM",
                "value": 536.2999877929688
            },
            {
                "time": "Mon, Apr 8, 11:06 AM",
                "value": 470.79998779296875
            },
            {
                "time": "Mon, Apr 8, 11:07 AM",
                "value": 521
            },
            {
                "time": "Mon, Apr 8, 11:07 AM",
                "value": 587.5
            },
            {
                "time": "Mon, Apr 8, 11:08 AM",
                "value": 676.0999755859375
            },
            {
                "time": "Mon, Apr 8, 11:08 AM",
                "value": 641
            },
            {
                "time": "Mon, Apr 8, 11:09 AM",
                "value": 659.0999755859375
            },
            {
                "time": "Mon, Apr 8, 11:09 AM",
                "value": 548.0999755859375
            },
            {
                "time": "Mon, Apr 8, 11:10 AM",
                "value": 372.6000061035156
            },
            {
                "time": "Mon, Apr 8, 11:10 AM",
                "value": 562.2999877929688
            },
            {
                "time": "Mon, Apr 8, 11:11 AM",
                "value": 691.4000244140625
            },
            {
                "time": "Mon, Apr 8, 11:11 AM",
                "value": 605.0999755859375
            },
            {
                "time": "Mon, Apr 8, 11:12 AM",
                "value": 487.79998779296875
            },
            {
                "time": "Mon, Apr 8, 11:12 AM",
                "value": 714.7000122070312
            },
            {
                "time": "Mon, Apr 8, 11:13 AM",
                "value": 711.2000122070312
            },
            {
                "time": "Mon, Apr 8, 11:13 AM",
                "value": 708.7999877929688
            },
            {
                "time": "Mon, Apr 8, 11:14 AM",
                "value": 705.0999755859375
            },
            {
                "time": "Mon, Apr 8, 11:14 AM",
                "value": 701.0999755859375
            },
            {
                "time": "Mon, Apr 8, 11:15 AM",
                "value": 698.7000122070312
            },
            {
                "time": "Mon, Apr 8, 11:15 AM",
                "value": 701.4000244140625
            },
            {
                "time": "Mon, Apr 8, 11:16 AM",
                "value": 704.2000122070312
            },
            {
                "time": "Mon, Apr 8, 11:16 AM",
                "value": 704.9000244140625
            },
            {
                "time": "Mon, Apr 8, 11:17 AM",
                "value": 711.7000122070312
            },
            {
                "time": "Mon, Apr 8, 11:17 AM",
                "value": 716.5
            },
            {
                "time": "Mon, Apr 8, 11:18 AM",
                "value": 710.5
            },
            {
                "time": "Mon, Apr 8, 11:18 AM",
                "value": 716.5999755859375
            },
            {
                "time": "Mon, Apr 8, 11:19 AM",
                "value": 713.5999755859375
            },
            {
                "time": "Mon, Apr 8, 11:19 AM",
                "value": 710.7999877929688
            },
            {
                "time": "Mon, Apr 8, 11:20 AM",
                "value": 706.5
            },
            {
                "time": "Mon, Apr 8, 11:20 AM",
                "value": 705.0999755859375
            },
            {
                "time": "Mon, Apr 8, 11:21 AM",
                "value": 705.5
            },
            {
                "time": "Mon, Apr 8, 11:21 AM",
                "value": 701.2999877929688
            },
            {
                "time": "Mon, Apr 8, 11:22 AM",
                "value": 699.5
            },
            {
                "time": "Mon, Apr 8, 11:22 AM",
                "value": 702
            },
            {
                "time": "Mon, Apr 8, 11:23 AM",
                "value": 702.5
            },
            {
                "time": "Mon, Apr 8, 11:23 AM",
                "value": 703.9000244140625
            },
            {
                "time": "Mon, Apr 8, 11:24 AM",
                "value": 712.0999755859375
            },
            {
                "time": "Mon, Apr 8, 11:24 AM",
                "value": 708.2000122070312
            },
            {
                "time": "Mon, Apr 8, 11:25 AM",
                "value": 705.4000244140625
            },
            {
                "time": "Mon, Apr 8, 11:25 AM",
                "value": 701.0999755859375
            },
            {
                "time": "Mon, Apr 8, 11:26 AM",
                "value": 704.7000122070312
            },
            {
                "time": "Mon, Apr 8, 11:26 AM",
                "value": 701.5999755859375
            },
            {
                "time": "Mon, Apr 8, 11:27 AM",
                "value": 712.2000122070312
            },
            {
                "time": "Mon, Apr 8, 11:27 AM",
                "value": 711.7999877929688
            },
            {
                "time": "Mon, Apr 8, 11:28 AM",
                "value": 706.2000122070312
            },
            {
                "time": "Mon, Apr 8, 11:28 AM",
                "value": 664.2999877929688
            },
            {
                "time": "Mon, Apr 8, 11:29 AM",
                "value": 700
            },
            {
                "time": "Mon, Apr 8, 11:29 AM",
                "value": 714.7000122070312
            },
            {
                "time": "Mon, Apr 8, 11:30 AM",
                "value": 724.2000122070312
            },
            {
                "time": "Mon, Apr 8, 11:30 AM",
                "value": 726
            },
            {
                "time": "Mon, Apr 8, 11:31 AM",
                "value": 724.7000122070312
            },
            {
                "time": "Mon, Apr 8, 11:31 AM",
                "value": 725.4000244140625
            },
            {
                "time": "Mon, Apr 8, 11:32 AM",
                "value": 725.2999877929688
            },
            {
                "time": "Mon, Apr 8, 11:32 AM",
                "value": 724.7000122070312
            },
            {
                "time": "Mon, Apr 8, 11:33 AM",
                "value": 721.7000122070312
            },
            {
                "time": "Mon, Apr 8, 11:33 AM",
                "value": 724.5
            },
            {
                "time": "Mon, Apr 8, 11:34 AM",
                "value": 726.7000122070312
            },
            {
                "time": "Mon, Apr 8, 11:34 AM",
                "value": 727.7999877929688
            },
            {
                "time": "Mon, Apr 8, 11:35 AM",
                "value": 732.2000122070312
            },
            {
                "time": "Mon, Apr 8, 11:35 AM",
                "value": 736.2999877929688
            },
            {
                "time": "Mon, Apr 8, 11:36 AM",
                "value": 735.9000244140625
            },
            {
                "time": "Mon, Apr 8, 11:36 AM",
                "value": 731.7999877929688
            },
            {
                "time": "Mon, Apr 8, 11:37 AM",
                "value": 721.2999877929688
            },
            {
                "time": "Mon, Apr 8, 11:37 AM",
                "value": 729.2999877929688
            },
            {
                "time": "Mon, Apr 8, 11:38 AM",
                "value": 718.9000244140625
            },
            {
                "time": "Mon, Apr 8, 11:38 AM",
                "value": 724.0999755859375
            },
            {
                "time": "Mon, Apr 8, 11:39 AM",
                "value": 728.2999877929688
            },
            {
                "time": "Mon, Apr 8, 11:39 AM",
                "value": 734.2000122070312
            },
            {
                "time": "Mon, Apr 8, 11:40 AM",
                "value": 726.4000244140625
            },
            {
                "time": "Mon, Apr 8, 11:40 AM",
                "value": 734.0999755859375
            },
            {
                "time": "Mon, Apr 8, 11:41 AM",
                "value": 738.0999755859375
            },
            {
                "time": "Mon, Apr 8, 11:41 AM",
                "value": 743.4000244140625
            },
            {
                "time": "Mon, Apr 8, 11:42 AM",
                "value": 750
            },
            {
                "time": "Mon, Apr 8, 11:42 AM",
                "value": 749.7999877929688
            },
            {
                "time": "Mon, Apr 8, 11:43 AM",
                "value": 748.2999877929688
            },
            {
                "time": "Mon, Apr 8, 11:43 AM",
                "value": 746
            },
            {
                "time": "Mon, Apr 8, 11:44 AM",
                "value": 747.4000244140625
            },
            {
                "time": "Mon, Apr 8, 11:44 AM",
                "value": 748
            },
            {
                "time": "Mon, Apr 8, 11:45 AM",
                "value": 753.0999755859375
            },
            {
                "time": "Mon, Apr 8, 11:45 AM",
                "value": 755.5
            },
            {
                "time": "Mon, Apr 8, 11:46 AM",
                "value": 754.0999755859375
            },
            {
                "time": "Mon, Apr 8, 11:46 AM",
                "value": 746.5
            },
            {
                "time": "Mon, Apr 8, 11:47 AM",
                "value": 748.4000244140625
            },
            {
                "time": "Mon, Apr 8, 11:47 AM",
                "value": 748
            },
            {
                "time": "Mon, Apr 8, 11:48 AM",
                "value": 754.2999877929688
            },
            {
                "time": "Mon, Apr 8, 11:48 AM",
                "value": 766.4000244140625
            },
            {
                "time": "Mon, Apr 8, 11:49 AM",
                "value": 764.4000244140625
            },
            {
                "time": "Mon, Apr 8, 11:49 AM",
                "value": 761.9000244140625
            },
            {
                "time": "Mon, Apr 8, 11:50 AM",
                "value": 761.7999877929688
            },
            {
                "time": "Mon, Apr 8, 11:50 AM",
                "value": 767.5
            },
            {
                "time": "Mon, Apr 8, 11:51 AM",
                "value": 772.5
            },
            {
                "time": "Mon, Apr 8, 11:51 AM",
                "value": 770.9000244140625
            },
            {
                "time": "Mon, Apr 8, 11:52 AM",
                "value": 771.7999877929688
            },
            {
                "time": "Mon, Apr 8, 11:52 AM",
                "value": 774.2999877929688
            },
            {
                "time": "Mon, Apr 8, 11:53 AM",
                "value": 772.7999877929688
            },
            {
                "time": "Mon, Apr 8, 11:53 AM",
                "value": 773.0999755859375
            },
            {
                "time": "Mon, Apr 8, 11:54 AM",
                "value": 773.0999755859375
            },
            {
                "time": "Mon, Apr 8, 11:54 AM",
                "value": 772.5999755859375
            },
            {
                "time": "Mon, Apr 8, 11:55 AM",
                "value": 773.2999877929688
            },
            {
                "time": "Mon, Apr 8, 11:55 AM",
                "value": 770.9000244140625
            },
            {
                "time": "Mon, Apr 8, 11:56 AM",
                "value": 771.2999877929688
            },
            {
                "time": "Mon, Apr 8, 11:56 AM",
                "value": 776.9000244140625
            },
            {
                "time": "Mon, Apr 8, 11:57 AM",
                "value": 780
            },
            {
                "time": "Mon, Apr 8, 11:57 AM",
                "value": 783
            },
            {
                "time": "Mon, Apr 8, 11:58 AM",
                "value": 786.2999877929688
            },
            {
                "time": "Mon, Apr 8, 11:58 AM",
                "value": 787.4000244140625
            },
            {
                "time": "Mon, Apr 8, 11:59 AM",
                "value": 788.0999755859375
            },
            {
                "time": "Mon, Apr 8, 11:59 AM",
                "value": 789.2999877929688
            },
            {
                "time": "Mon, Apr 8, 12:00 PM",
                "value": 790.5999755859375
            },
            {
                "time": "Mon, Apr 8, 12:00 PM",
                "value": 792.7000122070312
            },
            {
                "time": "Mon, Apr 8, 12:01 PM",
                "value": 794.5
            },
            {
                "time": "Mon, Apr 8, 12:01 PM",
                "value": 795.7000122070312
            },
            {
                "time": "Mon, Apr 8, 12:02 PM",
                "value": 797.2999877929688
            },
            {
                "time": "Mon, Apr 8, 12:02 PM",
                "value": 797.9000244140625
            },
            {
                "time": "Mon, Apr 8, 12:03 PM",
                "value": 797.9000244140625
            },
            {
                "time": "Mon, Apr 8, 12:03 PM",
                "value": 798.5999755859375
            },
            {
                "time": "Mon, Apr 8, 12:04 PM",
                "value": 797.5999755859375
            },
            {
                "time": "Mon, Apr 8, 12:04 PM",
                "value": 801
            },
            {
                "time": "Mon, Apr 8, 12:05 PM",
                "value": 803
            },
            {
                "time": "Mon, Apr 8, 12:05 PM",
                "value": 803
            },
            {
                "time": "Mon, Apr 8, 12:06 PM",
                "value": 805
            },
            {
                "time": "Mon, Apr 8, 12:06 PM",
                "value": 807
            },
            {
                "time": "Mon, Apr 8, 12:07 PM",
                "value": 807
            },
            {
                "time": "Mon, Apr 8, 12:07 PM",
                "value": 805
            },
            {
                "time": "Mon, Apr 8, 12:08 PM",
                "value": 807
            },
            {
                "time": "Mon, Apr 8, 12:08 PM",
                "value": 809
            },
            {
                "time": "Mon, Apr 8, 12:09 PM",
                "value": 810
            },
            {
                "time": "Mon, Apr 8, 12:09 PM",
                "value": 808
            },
            {
                "time": "Mon, Apr 8, 12:10 PM",
                "value": 806
            },
            {
                "time": "Mon, Apr 8, 12:10 PM",
                "value": 807
            },
            {
                "time": "Mon, Apr 8, 12:11 PM",
                "value": 809
            },
            {
                "time": "Mon, Apr 8, 12:11 PM",
                "value": 811
            },
            {
                "time": "Mon, Apr 8, 12:12 PM",
                "value": 812
            },
            {
                "time": "Mon, Apr 8, 12:12 PM",
                "value": 814
            },
            {
                "time": "Mon, Apr 8, 12:13 PM",
                "value": 813
            },
            {
                "time": "Mon, Apr 8, 12:13 PM",
                "value": 814
            },
            {
                "time": "Mon, Apr 8, 12:14 PM",
                "value": 817
            },
            {
                "time": "Mon, Apr 8, 12:14 PM",
                "value": 818
            },
            {
                "time": "Mon, Apr 8, 12:15 PM",
                "value": 819
            },
            {
                "time": "Mon, Apr 8, 12:15 PM",
                "value": 818
            },
            {
                "time": "Mon, Apr 8, 12:16 PM",
                "value": 819
            },
            {
                "time": "Mon, Apr 8, 12:16 PM",
                "value": 820
            },
            {
                "time": "Mon, Apr 8, 12:17 PM",
                "value": 819
            },
            {
                "time": "Mon, Apr 8, 12:17 PM",
                "value": 827
            },
            {
                "time": "Mon, Apr 8, 12:18 PM",
                "value": 827
            },
            {
                "time": "Mon, Apr 8, 12:18 PM",
                "value": 825
            },
            {
                "time": "Mon, Apr 8, 12:19 PM",
                "value": 830
            },
            {
                "time": "Mon, Apr 8, 12:19 PM",
                "value": 829
            },
            {
                "time": "Mon, Apr 8, 12:20 PM",
                "value": 830
            },
            {
                "time": "Mon, Apr 8, 12:20 PM",
                "value": 826
            },
            {
                "time": "Mon, Apr 8, 12:21 PM",
                "value": 832
            },
            {
                "time": "Mon, Apr 8, 12:21 PM",
                "value": 836
            },
            {
                "time": "Mon, Apr 8, 12:22 PM",
                "value": 838
            },
            {
                "time": "Mon, Apr 8, 12:22 PM",
                "value": 838
            },
            {
                "time": "Mon, Apr 8, 12:23 PM",
                "value": 836
            },
            {
                "time": "Mon, Apr 8, 12:23 PM",
                "value": 830
            },
            {
                "time": "Mon, Apr 8, 12:24 PM",
                "value": 835
            },
            {
                "time": "Mon, Apr 8, 12:24 PM",
                "value": 844
            },
            {
                "time": "Mon, Apr 8, 12:25 PM",
                "value": 842
            },
            {
                "time": "Mon, Apr 8, 12:25 PM",
                "value": 843
            },
            {
                "time": "Mon, Apr 8, 12:26 PM",
                "value": 839
            },
            {
                "time": "Mon, Apr 8, 12:26 PM",
                "value": 845
            },
            {
                "time": "Mon, Apr 8, 12:27 PM",
                "value": 845
            },
            {
                "time": "Mon, Apr 8, 12:27 PM",
                "value": 845
            },
            {
                "time": "Mon, Apr 8, 12:28 PM",
                "value": 846
            },
            {
                "time": "Mon, Apr 8, 12:28 PM",
                "value": 847
            },
            {
                "time": "Mon, Apr 8, 12:29 PM",
                "value": 847
            },
            {
                "time": "Mon, Apr 8, 12:29 PM",
                "value": 848
            },
            {
                "time": "Mon, Apr 8, 12:30 PM",
                "value": 848
            },
            {
                "time": "Mon, Apr 8, 12:30 PM",
                "value": 849
            },
            {
                "time": "Mon, Apr 8, 12:31 PM",
                "value": 849
            },
            {
                "time": "Mon, Apr 8, 12:31 PM",
                "value": 848
            },
            {
                "time": "Mon, Apr 8, 12:32 PM",
                "value": 841
            },
            {
                "time": "Mon, Apr 8, 12:32 PM",
                "value": 841
            },
            {
                "time": "Mon, Apr 8, 12:33 PM",
                "value": 851
            },
            {
                "time": "Mon, Apr 8, 12:33 PM",
                "value": 850
            },
            {
                "time": "Mon, Apr 8, 12:34 PM",
                "value": 849
            },
            {
                "time": "Mon, Apr 8, 12:34 PM",
                "value": 852
            },
            {
                "time": "Mon, Apr 8, 12:35 PM",
                "value": 851
            },
            {
                "time": "Mon, Apr 8, 12:35 PM",
                "value": 850
            },
            {
                "time": "Mon, Apr 8, 12:36 PM",
                "value": 849
            },
            {
                "time": "Mon, Apr 8, 12:36 PM",
                "value": 853
            },
            {
                "time": "Mon, Apr 8, 12:37 PM",
                "value": 854
            },
            {
                "time": "Mon, Apr 8, 12:37 PM",
                "value": 852
            },
            {
                "time": "Mon, Apr 8, 12:38 PM",
                "value": 822
            },
            {
                "time": "Mon, Apr 8, 12:38 PM",
                "value": 816
            },
            {
                "time": "Mon, Apr 8, 12:39 PM",
                "value": 857
            },
            {
                "time": "Mon, Apr 8, 12:39 PM",
                "value": 864
            },
            {
                "time": "Mon, Apr 8, 12:40 PM",
                "value": 865
            },
            {
                "time": "Mon, Apr 8, 12:40 PM",
                "value": 864
            },
            {
                "time": "Mon, Apr 8, 12:41 PM",
                "value": 857
            },
            {
                "time": "Mon, Apr 8, 12:41 PM",
                "value": 842
            },
            {
                "time": "Mon, Apr 8, 12:42 PM",
                "value": 802
            },
            {
                "time": "Mon, Apr 8, 12:42 PM",
                "value": 776.2999877929688
            },
            {
                "time": "Mon, Apr 8, 12:43 PM",
                "value": 795.4000244140625
            },
            {
                "time": "Mon, Apr 8, 12:43 PM",
                "value": 830
            },
            {
                "time": "Mon, Apr 8, 12:44 PM",
                "value": 868
            },
            {
                "time": "Mon, Apr 8, 12:44 PM",
                "value": 864
            },
            {
                "time": "Mon, Apr 8, 12:45 PM",
                "value": 853
            },
            {
                "time": "Mon, Apr 8, 12:45 PM",
                "value": 862
            },
            {
                "time": "Mon, Apr 8, 12:46 PM",
                "value": 858
            },
            {
                "time": "Mon, Apr 8, 12:46 PM",
                "value": 862
            },
            {
                "time": "Mon, Apr 8, 12:47 PM",
                "value": 864
            },
            {
                "time": "Mon, Apr 8, 12:47 PM",
                "value": 865
            },
            {
                "time": "Mon, Apr 8, 12:48 PM",
                "value": 860
            },
            {
                "time": "Mon, Apr 8, 12:48 PM",
                "value": 863
            },
            {
                "time": "Mon, Apr 8, 12:49 PM",
                "value": 871
            },
            {
                "time": "Mon, Apr 8, 12:49 PM",
                "value": 875
            },
            {
                "time": "Mon, Apr 8, 12:50 PM",
                "value": 860
            },
            {
                "time": "Mon, Apr 8, 12:50 PM",
                "value": 861
            },
            {
                "time": "Mon, Apr 8, 12:51 PM",
                "value": 857
            },
            {
                "time": "Mon, Apr 8, 12:51 PM",
                "value": 861
            },
            {
                "time": "Mon, Apr 8, 12:52 PM",
                "value": 855
            },
            {
                "time": "Mon, Apr 8, 12:52 PM",
                "value": 846
            },
            {
                "time": "Mon, Apr 8, 12:53 PM",
                "value": 840
            },
            {
                "time": "Mon, Apr 8, 12:53 PM",
                "value": 858
            },
            {
                "time": "Mon, Apr 8, 12:54 PM",
                "value": 859
            },
            {
                "time": "Mon, Apr 8, 12:54 PM",
                "value": 865
            },
            {
                "time": "Mon, Apr 8, 12:55 PM",
                "value": 868
            },
            {
                "time": "Mon, Apr 8, 12:55 PM",
                "value": 864
            },
            {
                "time": "Mon, Apr 8, 12:56 PM",
                "value": 871
            },
            {
                "time": "Mon, Apr 8, 12:56 PM",
                "value": 876
            },
            {
                "time": "Mon, Apr 8, 12:57 PM",
                "value": 880
            },
            {
                "time": "Mon, Apr 8, 12:57 PM",
                "value": 794.2000122070312
            },
            {
                "time": "Mon, Apr 8, 12:58 PM",
                "value": 815
            },
            {
                "time": "Mon, Apr 8, 12:58 PM",
                "value": 888
            },
            {
                "time": "Mon, Apr 8, 12:59 PM",
                "value": 889
            },
            {
                "time": "Mon, Apr 8, 12:59 PM",
                "value": 890
            },
            {
                "time": "Mon, Apr 8, 1:00 PM",
                "value": 891
            },
            {
                "time": "Mon, Apr 8, 1:00 PM",
                "value": 880
            },
            {
                "time": "Mon, Apr 8, 1:01 PM",
                "value": 879
            },
            {
                "time": "Mon, Apr 8, 1:01 PM",
                "value": 883
            },
            {
                "time": "Mon, Apr 8, 1:02 PM",
                "value": 841
            },
            {
                "time": "Mon, Apr 8, 1:02 PM",
                "value": 883
            },
            {
                "time": "Mon, Apr 8, 1:03 PM",
                "value": 888
            },
            {
                "time": "Mon, Apr 8, 1:03 PM",
                "value": 887
            },
            {
                "time": "Mon, Apr 8, 1:04 PM",
                "value": 887
            },
            {
                "time": "Mon, Apr 8, 1:04 PM",
                "value": 868
            },
            {
                "time": "Mon, Apr 8, 1:05 PM",
                "value": 885
            },
            {
                "time": "Mon, Apr 8, 1:05 PM",
                "value": 886
            },
            {
                "time": "Mon, Apr 8, 1:06 PM",
                "value": 885
            },
            {
                "time": "Mon, Apr 8, 1:06 PM",
                "value": 892
            },
            {
                "time": "Mon, Apr 8, 1:07 PM",
                "value": 895
            },
            {
                "time": "Mon, Apr 8, 1:07 PM",
                "value": 884
            },
            {
                "time": "Mon, Apr 8, 1:08 PM",
                "value": 874
            },
            {
                "time": "Mon, Apr 8, 1:08 PM",
                "value": 874
            },
            {
                "time": "Mon, Apr 8, 1:09 PM",
                "value": 887
            },
            {
                "time": "Mon, Apr 8, 1:09 PM",
                "value": 899
            },
            {
                "time": "Mon, Apr 8, 1:10 PM",
                "value": 900
            },
            {
                "time": "Mon, Apr 8, 1:10 PM",
                "value": 900
            },
            {
                "time": "Mon, Apr 8, 1:11 PM",
                "value": 869
            },
            {
                "time": "Mon, Apr 8, 1:11 PM",
                "value": 869
            },
            {
                "time": "Mon, Apr 8, 1:12 PM",
                "value": 877
            },
            {
                "time": "Mon, Apr 8, 1:12 PM",
                "value": 901
            },
            {
                "time": "Mon, Apr 8, 1:13 PM",
                "value": 894
            },
            {
                "time": "Mon, Apr 8, 1:13 PM",
                "value": 893
            },
            {
                "time": "Mon, Apr 8, 1:14 PM",
                "value": 896
            },
            {
                "time": "Mon, Apr 8, 1:14 PM",
                "value": 897
            },
            {
                "time": "Mon, Apr 8, 1:15 PM",
                "value": 891
            },
            {
                "time": "Mon, Apr 8, 1:15 PM",
                "value": 891
            },
            {
                "time": "Mon, Apr 8, 1:16 PM",
                "value": 893
            },
            {
                "time": "Mon, Apr 8, 1:16 PM",
                "value": 883
            },
            {
                "time": "Mon, Apr 8, 1:17 PM",
                "value": 886
            },
            {
                "time": "Mon, Apr 8, 1:17 PM",
                "value": 867
            },
            {
                "time": "Mon, Apr 8, 1:18 PM",
                "value": 883
            },
            {
                "time": "Mon, Apr 8, 1:18 PM",
                "value": 894
            },
            {
                "time": "Mon, Apr 8, 1:19 PM",
                "value": 889
            },
            {
                "time": "Mon, Apr 8, 1:19 PM",
                "value": 893
            },
            {
                "time": "Mon, Apr 8, 1:20 PM",
                "value": 909
            },
            {
                "time": "Mon, Apr 8, 1:20 PM",
                "value": 918
            },
            {
                "time": "Mon, Apr 8, 1:21 PM",
                "value": 914
            },
            {
                "time": "Mon, Apr 8, 1:21 PM",
                "value": 897
            },
            {
                "time": "Mon, Apr 8, 1:22 PM",
                "value": 889
            },
            {
                "time": "Mon, Apr 8, 1:22 PM",
                "value": 874
            },
            {
                "time": "Mon, Apr 8, 1:23 PM",
                "value": 787.5
            },
            {
                "time": "Mon, Apr 8, 1:23 PM",
                "value": 771.2000122070312
            },
            {
                "time": "Mon, Apr 8, 1:24 PM",
                "value": 849
            },
            {
                "time": "Mon, Apr 8, 1:24 PM",
                "value": 853
            },
            {
                "time": "Mon, Apr 8, 1:25 PM",
                "value": 871
            },
            {
                "time": "Mon, Apr 8, 1:25 PM",
                "value": 870
            },
            {
                "time": "Mon, Apr 8, 1:26 PM",
                "value": 900
            },
            {
                "time": "Mon, Apr 8, 1:26 PM",
                "value": 890
            },
            {
                "time": "Mon, Apr 8, 1:27 PM",
                "value": 906
            },
            {
                "time": "Mon, Apr 8, 1:27 PM",
                "value": 894
            },
            {
                "time": "Mon, Apr 8, 1:28 PM",
                "value": 910
            },
            {
                "time": "Mon, Apr 8, 1:28 PM",
                "value": 905
            },
            {
                "time": "Mon, Apr 8, 1:29 PM",
                "value": 917
            },
            {
                "time": "Mon, Apr 8, 1:29 PM",
                "value": 923
            },
            {
                "time": "Mon, Apr 8, 1:30 PM",
                "value": 917
            },
            {
                "time": "Mon, Apr 8, 1:30 PM",
                "value": 909
            },
            {
                "time": "Mon, Apr 8, 1:31 PM",
                "value": 900
            },
            {
                "time": "Mon, Apr 8, 1:31 PM",
                "value": 892
            },
            {
                "time": "Mon, Apr 8, 1:32 PM",
                "value": 888
            },
            {
                "time": "Mon, Apr 8, 1:32 PM",
                "value": 885
            },
            {
                "time": "Mon, Apr 8, 1:33 PM",
                "value": 893
            },
            {
                "time": "Mon, Apr 8, 1:33 PM",
                "value": 894
            },
            {
                "time": "Mon, Apr 8, 1:34 PM",
                "value": 898
            },
            {
                "time": "Mon, Apr 8, 1:34 PM",
                "value": 905
            },
            {
                "time": "Mon, Apr 8, 1:35 PM",
                "value": 900
            },
            {
                "time": "Mon, Apr 8, 1:35 PM",
                "value": 883
            },
            {
                "time": "Mon, Apr 8, 1:36 PM",
                "value": 878
            },
            {
                "time": "Mon, Apr 8, 1:36 PM",
                "value": 867
            },
            {
                "time": "Mon, Apr 8, 1:37 PM",
                "value": 859
            },
            {
                "time": "Mon, Apr 8, 1:37 PM",
                "value": 894
            },
            {
                "time": "Mon, Apr 8, 1:38 PM",
                "value": 888
            },
            {
                "time": "Mon, Apr 8, 1:38 PM",
                "value": 890
            },
            {
                "time": "Mon, Apr 8, 1:39 PM",
                "value": 806
            },
            {
                "time": "Mon, Apr 8, 1:39 PM",
                "value": 840
            },
            {
                "time": "Mon, Apr 8, 1:40 PM",
                "value": 860
            },
            {
                "time": "Mon, Apr 8, 1:40 PM",
                "value": 908
            },
            {
                "time": "Mon, Apr 8, 1:41 PM",
                "value": 905
            },
            {
                "time": "Mon, Apr 8, 1:41 PM",
                "value": 918
            },
            {
                "time": "Mon, Apr 8, 1:42 PM",
                "value": 911
            },
            {
                "time": "Mon, Apr 8, 1:42 PM",
                "value": 883
            },
            {
                "time": "Mon, Apr 8, 1:43 PM",
                "value": 928
            },
            {
                "time": "Mon, Apr 8, 1:43 PM",
                "value": 808
            },
            {
                "time": "Mon, Apr 8, 1:44 PM",
                "value": 873
            },
            {
                "time": "Mon, Apr 8, 1:44 PM",
                "value": 912
            },
            {
                "time": "Mon, Apr 8, 1:45 PM",
                "value": 929
            },
            {
                "time": "Mon, Apr 8, 1:45 PM",
                "value": 933
            },
            {
                "time": "Mon, Apr 8, 1:46 PM",
                "value": 934
            },
            {
                "time": "Mon, Apr 8, 1:46 PM",
                "value": 927
            },
            {
                "time": "Mon, Apr 8, 1:47 PM",
                "value": 931
            },
            {
                "time": "Mon, Apr 8, 1:47 PM",
                "value": 925
            },
            {
                "time": "Mon, Apr 8, 1:48 PM",
                "value": 887
            },
            {
                "time": "Mon, Apr 8, 1:48 PM",
                "value": 879
            },
            {
                "time": "Mon, Apr 8, 1:49 PM",
                "value": 891
            },
            {
                "time": "Mon, Apr 8, 1:49 PM",
                "value": 922
            },
            {
                "time": "Mon, Apr 8, 1:50 PM",
                "value": 930
            },
            {
                "time": "Mon, Apr 8, 1:50 PM",
                "value": 935
            },
            {
                "time": "Mon, Apr 8, 1:51 PM",
                "value": 925
            },
            {
                "time": "Mon, Apr 8, 1:51 PM",
                "value": 908
            },
            {
                "time": "Mon, Apr 8, 1:52 PM",
                "value": 915
            },
            {
                "time": "Mon, Apr 8, 1:52 PM",
                "value": 931
            },
            {
                "time": "Mon, Apr 8, 1:53 PM",
                "value": 261.20001220703125
            },
            {
                "time": "Mon, Apr 8, 1:53 PM",
                "value": 575.5
            },
            {
                "time": "Mon, Apr 8, 1:54 PM",
                "value": 300.29998779296875
            },
            {
                "time": "Mon, Apr 8, 1:54 PM",
                "value": 631.4000244140625
            },
            {
                "time": "Mon, Apr 8, 1:55 PM",
                "value": 925
            },
            {
                "time": "Mon, Apr 8, 1:55 PM",
                "value": 914
            },
            {
                "time": "Mon, Apr 8, 1:56 PM",
                "value": 914
            },
            {
                "time": "Mon, Apr 8, 1:56 PM",
                "value": 914
            },
            {
                "time": "Mon, Apr 8, 1:57 PM",
                "value": 926
            },
            {
                "time": "Mon, Apr 8, 1:57 PM",
                "value": 648.7999877929688
            },
            {
                "time": "Mon, Apr 8, 1:58 PM",
                "value": 818
            },
            {
                "time": "Mon, Apr 8, 1:58 PM",
                "value": 977
            },
            {
                "time": "Mon, Apr 8, 1:59 PM",
                "value": 283.8999938964844
            },
            {
                "time": "Mon, Apr 8, 1:59 PM",
                "value": 317.1000061035156
            },
            {
                "time": "Mon, Apr 8, 2:00 PM",
                "value": 265.29998779296875
            },
            {
                "time": "Mon, Apr 8, 2:00 PM",
                "value": 291.29998779296875
            },
            {
                "time": "Mon, Apr 8, 2:01 PM",
                "value": 495.3999938964844
            },
            {
                "time": "Mon, Apr 8, 2:01 PM",
                "value": 863
            },
            {
                "time": "Mon, Apr 8, 2:02 PM",
                "value": 923
            },
            {
                "time": "Mon, Apr 8, 2:02 PM",
                "value": 900
            },
            {
                "time": "Mon, Apr 8, 2:03 PM",
                "value": 497.29998779296875
            },
            {
                "time": "Mon, Apr 8, 2:03 PM",
                "value": 260.6000061035156
            },
            {
                "time": "Mon, Apr 8, 2:04 PM",
                "value": 520.4000244140625
            },
            {
                "time": "Mon, Apr 8, 2:04 PM",
                "value": 587.7000122070312
            },
            {
                "time": "Mon, Apr 8, 2:05 PM",
                "value": 233.10000610351562
            },
            {
                "time": "Mon, Apr 8, 2:05 PM",
                "value": 221.3000030517578
            },
            {
                "time": "Mon, Apr 8, 2:06 PM",
                "value": 246.1999969482422
            },
            {
                "time": "Mon, Apr 8, 2:06 PM",
                "value": 395.20001220703125
            },
            {
                "time": "Mon, Apr 8, 2:07 PM",
                "value": 506.8999938964844
            },
            {
                "time": "Mon, Apr 8, 2:07 PM",
                "value": 229
            },
            {
                "time": "Mon, Apr 8, 2:08 PM",
                "value": 230.10000610351562
            },
            {
                "time": "Mon, Apr 8, 2:08 PM",
                "value": 614.9000244140625
            },
            {
                "time": "Mon, Apr 8, 2:09 PM",
                "value": 858
            },
            {
                "time": "Mon, Apr 8, 2:09 PM",
                "value": 833
            },
            {
                "time": "Mon, Apr 8, 2:10 PM",
                "value": 819
            },
            {
                "time": "Mon, Apr 8, 2:10 PM",
                "value": 808
            },
            {
                "time": "Mon, Apr 8, 2:11 PM",
                "value": 799.7999877929688
            },
            {
                "time": "Mon, Apr 8, 2:11 PM",
                "value": 788.0999755859375
            },
            {
                "time": "Mon, Apr 8, 2:12 PM",
                "value": 782.4000244140625
            },
            {
                "time": "Mon, Apr 8, 2:12 PM",
                "value": 450.3999938964844
            },
            {
                "time": "Mon, Apr 8, 2:13 PM",
                "value": 770.2999877929688
            },
            {
                "time": "Mon, Apr 8, 2:13 PM",
                "value": 339.5
            },
            {
                "time": "Mon, Apr 8, 2:14 PM",
                "value": 188.6999969482422
            },
            {
                "time": "Mon, Apr 8, 2:14 PM",
                "value": 164.8000030517578
            },
            {
                "time": "Mon, Apr 8, 2:15 PM",
                "value": 156.3000030517578
            },
            {
                "time": "Mon, Apr 8, 2:15 PM",
                "value": 154.39999389648438
            },
            {
                "time": "Mon, Apr 8, 2:16 PM",
                "value": 144.89999389648438
            },
            {
                "time": "Mon, Apr 8, 2:16 PM",
                "value": 141.8000030517578
            },
            {
                "time": "Mon, Apr 8, 2:17 PM",
                "value": 139
            },
            {
                "time": "Mon, Apr 8, 2:17 PM",
                "value": 141.60000610351562
            },
            {
                "time": "Mon, Apr 8, 2:18 PM",
                "value": 155.3000030517578
            },
            {
                "time": "Mon, Apr 8, 2:18 PM",
                "value": 263
            },
            {
                "time": "Mon, Apr 8, 2:19 PM",
                "value": 687.0999755859375
            },
            {
                "time": "Mon, Apr 8, 2:19 PM",
                "value": 216.39999389648438
            },
            {
                "time": "Mon, Apr 8, 2:20 PM",
                "value": 165.10000610351562
            },
            {
                "time": "Mon, Apr 8, 2:20 PM",
                "value": 150.1999969482422
            },
            {
                "time": "Mon, Apr 8, 2:21 PM",
                "value": 144.39999389648438
            },
            {
                "time": "Mon, Apr 8, 2:21 PM",
                "value": 142.89999389648438
            },
            {
                "time": "Mon, Apr 8, 2:22 PM",
                "value": 176.3000030517578
            },
            {
                "time": "Mon, Apr 8, 2:22 PM",
                "value": 457.1000061035156
            },
            {
                "time": "Mon, Apr 8, 2:23 PM",
                "value": 191.8000030517578
            },
            {
                "time": "Mon, Apr 8, 2:23 PM",
                "value": 260.20001220703125
            },
            {
                "time": "Mon, Apr 8, 2:24 PM",
                "value": 206.5
            },
            {
                "time": "Mon, Apr 8, 2:24 PM",
                "value": 151.89999389648438
            },
            {
                "time": "Mon, Apr 8, 2:25 PM",
                "value": 144.10000610351562
            },
            {
                "time": "Mon, Apr 8, 2:25 PM",
                "value": 153.5
            },
            {
                "time": "Mon, Apr 8, 2:26 PM",
                "value": 495.6000061035156
            },
            {
                "time": "Mon, Apr 8, 2:26 PM",
                "value": 601.2000122070312
            },
            {
                "time": "Mon, Apr 8, 2:27 PM",
                "value": 586.0999755859375
            },
            {
                "time": "Mon, Apr 8, 2:27 PM",
                "value": 567.0999755859375
            },
            {
                "time": "Mon, Apr 8, 2:28 PM",
                "value": 559.2999877929688
            },
            {
                "time": "Mon, Apr 8, 2:28 PM",
                "value": 543.4000244140625
            },
            {
                "time": "Mon, Apr 8, 2:29 PM",
                "value": 387.5
            },
            {
                "time": "Mon, Apr 8, 2:29 PM",
                "value": 103.19999694824219
            },
            {
                "time": "Mon, Apr 8, 2:30 PM",
                "value": 142.10000610351562
            },
            {
                "time": "Mon, Apr 8, 2:30 PM",
                "value": 133
            },
            {
                "time": "Mon, Apr 8, 2:31 PM",
                "value": 133.3000030517578
            },
            {
                "time": "Mon, Apr 8, 2:31 PM",
                "value": 120.5
            },
            {
                "time": "Mon, Apr 8, 2:32 PM",
                "value": 108.0999984741211
            },
            {
                "time": "Mon, Apr 8, 2:32 PM",
                "value": 105.30000305175781
            },
            {
                "time": "Mon, Apr 8, 2:33 PM",
                "value": 105
            },
            {
                "time": "Mon, Apr 8, 2:33 PM",
                "value": 101.69999694824219
            },
            {
                "time": "Mon, Apr 8, 2:34 PM",
                "value": 98.19999694824219
            },
            {
                "time": "Mon, Apr 8, 2:34 PM",
                "value": 95.69999694824219
            },
            {
                "time": "Mon, Apr 8, 2:35 PM",
                "value": 104.5999984741211
            },
            {
                "time": "Mon, Apr 8, 2:35 PM",
                "value": 327.20001220703125
            },
            {
                "time": "Mon, Apr 8, 2:36 PM",
                "value": 102.5
            },
            {
                "time": "Mon, Apr 8, 2:36 PM",
                "value": 98.30000305175781
            },
            {
                "time": "Mon, Apr 8, 2:37 PM",
                "value": 114.5
            },
            {
                "time": "Mon, Apr 8, 2:37 PM",
                "value": 396.1000061035156
            },
            {
                "time": "Mon, Apr 8, 2:38 PM",
                "value": 402.70001220703125
            },
            {
                "time": "Mon, Apr 8, 2:38 PM",
                "value": 273.29998779296875
            },
            {
                "time": "Mon, Apr 8, 2:39 PM",
                "value": 99.19999694824219
            },
            {
                "time": "Mon, Apr 8, 2:39 PM",
                "value": 103.30000305175781
            },
            {
                "time": "Mon, Apr 8, 2:40 PM",
                "value": 83.19999694824219
            },
            {
                "time": "Mon, Apr 8, 2:40 PM",
                "value": 71.6500015258789
            },
            {
                "time": "Mon, Apr 8, 2:41 PM",
                "value": 94.80000305175781
            },
            {
                "time": "Mon, Apr 8, 2:41 PM",
                "value": 309.1000061035156
            },
            {
                "time": "Mon, Apr 8, 2:42 PM",
                "value": 305.1000061035156
            },
            {
                "time": "Mon, Apr 8, 2:42 PM",
                "value": 302.5
            },
            {
                "time": "Mon, Apr 8, 2:43 PM",
                "value": 295.8999938964844
            },
            {
                "time": "Mon, Apr 8, 2:43 PM",
                "value": 275.70001220703125
            },
            {
                "time": "Mon, Apr 8, 2:44 PM",
                "value": 57.06999969482422
            },
            {
                "time": "Mon, Apr 8, 2:44 PM",
                "value": 96.4000015258789
            },
            {
                "time": "Mon, Apr 8, 2:45 PM",
                "value": 252.10000610351562
            },
            {
                "time": "Mon, Apr 8, 2:45 PM",
                "value": 247.3000030517578
            },
            {
                "time": "Mon, Apr 8, 2:46 PM",
                "value": 240.10000610351562
            },
            {
                "time": "Mon, Apr 8, 2:46 PM",
                "value": 232.60000610351562
            },
            {
                "time": "Mon, Apr 8, 2:47 PM",
                "value": 222.1999969482422
            },
            {
                "time": "Mon, Apr 8, 2:47 PM",
                "value": 212.60000610351562
            },
            {
                "time": "Mon, Apr 8, 2:48 PM",
                "value": 182.60000610351562
            },
            {
                "time": "Mon, Apr 8, 2:48 PM",
                "value": 54.209999084472656
            },
            {
                "time": "Mon, Apr 8, 2:49 PM",
                "value": 33.439998626708984
            },
            {
                "time": "Mon, Apr 8, 2:49 PM",
                "value": 39.27000045776367
            },
            {
                "time": "Mon, Apr 8, 2:50 PM",
                "value": 92.19999694824219
            },
            {
                "time": "Mon, Apr 8, 2:50 PM",
                "value": 173.1999969482422
            },
            {
                "time": "Mon, Apr 8, 2:51 PM",
                "value": 162.6999969482422
            },
            {
                "time": "Mon, Apr 8, 2:51 PM",
                "value": 152.89999389648438
            },
            {
                "time": "Mon, Apr 8, 2:52 PM",
                "value": 143.5
            },
            {
                "time": "Mon, Apr 8, 2:52 PM",
                "value": 134.1999969482422
            },
            {
                "time": "Mon, Apr 8, 2:53 PM",
                "value": 126.69999694824219
            },
            {
                "time": "Mon, Apr 8, 2:53 PM",
                "value": 119.4000015258789
            },
            {
                "time": "Mon, Apr 8, 2:54 PM",
                "value": 112.0999984741211
            },
            {
                "time": "Mon, Apr 8, 2:54 PM",
                "value": 104.69999694824219
            },
            {
                "time": "Mon, Apr 8, 2:55 PM",
                "value": 97.4000015258789
            },
            {
                "time": "Mon, Apr 8, 2:55 PM",
                "value": 90.4000015258789
            },
            {
                "time": "Mon, Apr 8, 2:56 PM",
                "value": 83.30000305175781
            },
            {
                "time": "Mon, Apr 8, 2:56 PM",
                "value": 76.43000030517578
            },
            {
                "time": "Mon, Apr 8, 2:57 PM",
                "value": 69.62999725341797
            },
            {
                "time": "Mon, Apr 8, 2:57 PM",
                "value": 63.13999938964844
            },
            {
                "time": "Mon, Apr 8, 2:58 PM",
                "value": 56.849998474121094
            },
            {
                "time": "Mon, Apr 8, 2:58 PM",
                "value": 50.59000015258789
            },
            {
                "time": "Mon, Apr 8, 2:59 PM",
                "value": 44.5
            },
            {
                "time": "Mon, Apr 8, 2:59 PM",
                "value": 38.68000030517578
            },
            {
                "time": "Mon, Apr 8, 3:00 PM",
                "value": 33.029998779296875
            },
            {
                "time": "Mon, Apr 8, 3:00 PM",
                "value": 27.59000015258789
            },
            {
                "time": "Mon, Apr 8, 3:01 PM",
                "value": 22.280000686645508
            },
            {
                "time": "Mon, Apr 8, 3:01 PM",
                "value": 17.100000381469727
            },
            {
                "time": "Mon, Apr 8, 3:02 PM",
                "value": 12.6899995803833
            },
            {
                "time": "Mon, Apr 8, 3:02 PM",
                "value": 8.579999923706055
            },
            {
                "time": "Mon, Apr 8, 3:03 PM",
                "value": 4.952000141143799
            },
            {
                "time": "Mon, Apr 8, 3:03 PM",
                "value": 2.0450000762939453
            },
            {
                "time": "Mon, Apr 8, 3:04 PM",
                "value": 0.2549999952316284
            },
            {
                "time": "Mon, Apr 8, 3:04 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 3:05 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 3:05 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 3:06 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 3:06 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 3:07 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 3:07 PM",
                "value": 0.5339999794960022
            },
            {
                "time": "Mon, Apr 8, 3:08 PM",
                "value": 2.6500000953674316
            },
            {
                "time": "Mon, Apr 8, 3:08 PM",
                "value": 5.646999835968018
            },
            {
                "time": "Mon, Apr 8, 3:09 PM",
                "value": 9.380000114440918
            },
            {
                "time": "Mon, Apr 8, 3:09 PM",
                "value": 13.59000015258789
            },
            {
                "time": "Mon, Apr 8, 3:10 PM",
                "value": 18.200000762939453
            },
            {
                "time": "Mon, Apr 8, 3:10 PM",
                "value": 23.15999984741211
            },
            {
                "time": "Mon, Apr 8, 3:11 PM",
                "value": 28.3700008392334
            },
            {
                "time": "Mon, Apr 8, 3:11 PM",
                "value": 33.810001373291016
            },
            {
                "time": "Mon, Apr 8, 3:12 PM",
                "value": 39.33000183105469
            },
            {
                "time": "Mon, Apr 8, 3:12 PM",
                "value": 44.93000030517578
            },
            {
                "time": "Mon, Apr 8, 3:13 PM",
                "value": 50.83000183105469
            },
            {
                "time": "Mon, Apr 8, 3:13 PM",
                "value": 56.689998626708984
            },
            {
                "time": "Mon, Apr 8, 3:14 PM",
                "value": 62.59000015258789
            },
            {
                "time": "Mon, Apr 8, 3:14 PM",
                "value": 68.55999755859375
            },
            {
                "time": "Mon, Apr 8, 3:15 PM",
                "value": 74.4000015258789
            },
            {
                "time": "Mon, Apr 8, 3:15 PM",
                "value": 80.5
            },
            {
                "time": "Mon, Apr 8, 3:16 PM",
                "value": 86.4000015258789
            },
            {
                "time": "Mon, Apr 8, 3:16 PM",
                "value": 93
            },
            {
                "time": "Mon, Apr 8, 3:17 PM",
                "value": 98.0999984741211
            },
            {
                "time": "Mon, Apr 8, 3:17 PM",
                "value": 103
            },
            {
                "time": "Mon, Apr 8, 3:18 PM",
                "value": 111.5999984741211
            },
            {
                "time": "Mon, Apr 8, 3:18 PM",
                "value": 118.69999694824219
            },
            {
                "time": "Mon, Apr 8, 3:19 PM",
                "value": 125.19999694824219
            },
            {
                "time": "Mon, Apr 8, 3:19 PM",
                "value": 131.8000030517578
            },
            {
                "time": "Mon, Apr 8, 3:20 PM",
                "value": 138.60000610351562
            },
            {
                "time": "Mon, Apr 8, 3:20 PM",
                "value": 145.5
            },
            {
                "time": "Mon, Apr 8, 3:21 PM",
                "value": 152.39999389648438
            },
            {
                "time": "Mon, Apr 8, 3:21 PM",
                "value": 159.5
            },
            {
                "time": "Mon, Apr 8, 3:22 PM",
                "value": 166.6999969482422
            },
            {
                "time": "Mon, Apr 8, 3:22 PM",
                "value": 173.39999389648438
            },
            {
                "time": "Mon, Apr 8, 3:23 PM",
                "value": 180.39999389648438
            },
            {
                "time": "Mon, Apr 8, 3:23 PM",
                "value": 187.5
            },
            {
                "time": "Mon, Apr 8, 3:24 PM",
                "value": 194.8000030517578
            },
            {
                "time": "Mon, Apr 8, 3:24 PM",
                "value": 202.10000610351562
            },
            {
                "time": "Mon, Apr 8, 3:25 PM",
                "value": 209.3000030517578
            },
            {
                "time": "Mon, Apr 8, 3:25 PM",
                "value": 216.1999969482422
            },
            {
                "time": "Mon, Apr 8, 3:26 PM",
                "value": 223.3000030517578
            },
            {
                "time": "Mon, Apr 8, 3:26 PM",
                "value": 230.3000030517578
            },
            {
                "time": "Mon, Apr 8, 3:27 PM",
                "value": 237
            },
            {
                "time": "Mon, Apr 8, 3:27 PM",
                "value": 242.8000030517578
            },
            {
                "time": "Mon, Apr 8, 3:28 PM",
                "value": 248.60000610351562
            },
            {
                "time": "Mon, Apr 8, 3:28 PM",
                "value": 255.8000030517578
            },
            {
                "time": "Mon, Apr 8, 3:29 PM",
                "value": 263.6000061035156
            },
            {
                "time": "Mon, Apr 8, 3:29 PM",
                "value": 271
            },
            {
                "time": "Mon, Apr 8, 3:30 PM",
                "value": 277.20001220703125
            },
            {
                "time": "Mon, Apr 8, 3:30 PM",
                "value": 283.79998779296875
            },
            {
                "time": "Mon, Apr 8, 3:31 PM",
                "value": 290.1000061035156
            },
            {
                "time": "Mon, Apr 8, 3:31 PM",
                "value": 296.70001220703125
            },
            {
                "time": "Mon, Apr 8, 3:32 PM",
                "value": 303.8999938964844
            },
            {
                "time": "Mon, Apr 8, 3:32 PM",
                "value": 310.3999938964844
            },
            {
                "time": "Mon, Apr 8, 3:33 PM",
                "value": 317
            },
            {
                "time": "Mon, Apr 8, 3:33 PM",
                "value": 323.70001220703125
            },
            {
                "time": "Mon, Apr 8, 3:34 PM",
                "value": 330.6000061035156
            },
            {
                "time": "Mon, Apr 8, 3:34 PM",
                "value": 337.1000061035156
            },
            {
                "time": "Mon, Apr 8, 3:35 PM",
                "value": 344.1000061035156
            },
            {
                "time": "Mon, Apr 8, 3:35 PM",
                "value": 350.79998779296875
            },
            {
                "time": "Mon, Apr 8, 3:36 PM",
                "value": 357.29998779296875
            },
            {
                "time": "Mon, Apr 8, 3:36 PM",
                "value": 363.8999938964844
            },
            {
                "time": "Mon, Apr 8, 3:37 PM",
                "value": 370.29998779296875
            },
            {
                "time": "Mon, Apr 8, 3:37 PM",
                "value": 377.29998779296875
            },
            {
                "time": "Mon, Apr 8, 3:38 PM",
                "value": 384.70001220703125
            },
            {
                "time": "Mon, Apr 8, 3:38 PM",
                "value": 390.8999938964844
            },
            {
                "time": "Mon, Apr 8, 3:39 PM",
                "value": 396.79998779296875
            },
            {
                "time": "Mon, Apr 8, 3:39 PM",
                "value": 403.6000061035156
            },
            {
                "time": "Mon, Apr 8, 3:40 PM",
                "value": 411.5
            },
            {
                "time": "Mon, Apr 8, 3:40 PM",
                "value": 418.70001220703125
            },
            {
                "time": "Mon, Apr 8, 3:41 PM",
                "value": 425.6000061035156
            },
            {
                "time": "Mon, Apr 8, 3:41 PM",
                "value": 434.29998779296875
            },
            {
                "time": "Mon, Apr 8, 3:42 PM",
                "value": 441.20001220703125
            },
            {
                "time": "Mon, Apr 8, 3:42 PM",
                "value": 446.29998779296875
            },
            {
                "time": "Mon, Apr 8, 3:43 PM",
                "value": 445.8999938964844
            },
            {
                "time": "Mon, Apr 8, 3:43 PM",
                "value": 437.70001220703125
            },
            {
                "time": "Mon, Apr 8, 3:44 PM",
                "value": 414.20001220703125
            },
            {
                "time": "Mon, Apr 8, 3:44 PM",
                "value": 367.70001220703125
            },
            {
                "time": "Mon, Apr 8, 3:45 PM",
                "value": 369
            },
            {
                "time": "Mon, Apr 8, 3:45 PM",
                "value": 404.1000061035156
            },
            {
                "time": "Mon, Apr 8, 3:46 PM",
                "value": 405.8999938964844
            },
            {
                "time": "Mon, Apr 8, 3:46 PM",
                "value": 428
            },
            {
                "time": "Mon, Apr 8, 3:47 PM",
                "value": 463.1000061035156
            },
            {
                "time": "Mon, Apr 8, 3:47 PM",
                "value": 489.20001220703125
            },
            {
                "time": "Mon, Apr 8, 3:48 PM",
                "value": 506
            },
            {
                "time": "Mon, Apr 8, 3:48 PM",
                "value": 522
            },
            {
                "time": "Mon, Apr 8, 3:49 PM",
                "value": 532.4000244140625
            },
            {
                "time": "Mon, Apr 8, 3:49 PM",
                "value": 524.5
            },
            {
                "time": "Mon, Apr 8, 3:50 PM",
                "value": 454.5
            },
            {
                "time": "Mon, Apr 8, 3:50 PM",
                "value": 461.79998779296875
            },
            {
                "time": "Mon, Apr 8, 3:51 PM",
                "value": 504.1000061035156
            },
            {
                "time": "Mon, Apr 8, 3:51 PM",
                "value": 509.79998779296875
            },
            {
                "time": "Mon, Apr 8, 3:52 PM",
                "value": 471.79998779296875
            },
            {
                "time": "Mon, Apr 8, 3:52 PM",
                "value": 518.4000244140625
            },
            {
                "time": "Mon, Apr 8, 3:53 PM",
                "value": 544.5999755859375
            },
            {
                "time": "Mon, Apr 8, 3:53 PM",
                "value": 555.7000122070312
            },
            {
                "time": "Mon, Apr 8, 3:54 PM",
                "value": 564.2999877929688
            },
            {
                "time": "Mon, Apr 8, 3:54 PM",
                "value": 583.4000244140625
            },
            {
                "time": "Mon, Apr 8, 3:55 PM",
                "value": 594.5999755859375
            },
            {
                "time": "Mon, Apr 8, 3:55 PM",
                "value": 596.7000122070312
            },
            {
                "time": "Mon, Apr 8, 3:56 PM",
                "value": 599.2000122070312
            },
            {
                "time": "Mon, Apr 8, 3:56 PM",
                "value": 605.7000122070312
            },
            {
                "time": "Mon, Apr 8, 3:57 PM",
                "value": 609.5
            },
            {
                "time": "Mon, Apr 8, 3:57 PM",
                "value": 609.5999755859375
            },
            {
                "time": "Mon, Apr 8, 3:58 PM",
                "value": 611.9000244140625
            },
            {
                "time": "Mon, Apr 8, 3:58 PM",
                "value": 622
            },
            {
                "time": "Mon, Apr 8, 3:59 PM",
                "value": 626.0999755859375
            },
            {
                "time": "Mon, Apr 8, 3:59 PM",
                "value": 629.4000244140625
            },
            {
                "time": "Mon, Apr 8, 4:00 PM",
                "value": 633.7000122070312
            },
            {
                "time": "Mon, Apr 8, 4:00 PM",
                "value": 638.9000244140625
            },
            {
                "time": "Mon, Apr 8, 4:01 PM",
                "value": 643.5
            },
            {
                "time": "Mon, Apr 8, 4:01 PM",
                "value": 642.5
            },
            {
                "time": "Mon, Apr 8, 4:02 PM",
                "value": 650.7000122070312
            },
            {
                "time": "Mon, Apr 8, 4:02 PM",
                "value": 648.5
            },
            {
                "time": "Mon, Apr 8, 4:03 PM",
                "value": 644.4000244140625
            },
            {
                "time": "Mon, Apr 8, 4:03 PM",
                "value": 646.0999755859375
            },
            {
                "time": "Mon, Apr 8, 4:04 PM",
                "value": 651.2000122070312
            },
            {
                "time": "Mon, Apr 8, 4:04 PM",
                "value": 655.7000122070312
            },
            {
                "time": "Mon, Apr 8, 4:05 PM",
                "value": 656.7999877929688
            },
            {
                "time": "Mon, Apr 8, 4:05 PM",
                "value": 662
            },
            {
                "time": "Mon, Apr 8, 4:06 PM",
                "value": 670.7999877929688
            },
            {
                "time": "Mon, Apr 8, 4:06 PM",
                "value": 680.0999755859375
            },
            {
                "time": "Mon, Apr 8, 4:07 PM",
                "value": 677.2000122070312
            },
            {
                "time": "Mon, Apr 8, 4:07 PM",
                "value": 669.7000122070312
            },
            {
                "time": "Mon, Apr 8, 4:08 PM",
                "value": 666.5999755859375
            },
            {
                "time": "Mon, Apr 8, 4:08 PM",
                "value": 677.4000244140625
            },
            {
                "time": "Mon, Apr 8, 4:09 PM",
                "value": 688.2999877929688
            },
            {
                "time": "Mon, Apr 8, 4:09 PM",
                "value": 697.5999755859375
            },
            {
                "time": "Mon, Apr 8, 4:10 PM",
                "value": 675.5
            },
            {
                "time": "Mon, Apr 8, 4:10 PM",
                "value": 686.4000244140625
            },
            {
                "time": "Mon, Apr 8, 4:11 PM",
                "value": 688.2000122070312
            },
            {
                "time": "Mon, Apr 8, 4:11 PM",
                "value": 668
            },
            {
                "time": "Mon, Apr 8, 4:12 PM",
                "value": 644.2999877929688
            },
            {
                "time": "Mon, Apr 8, 4:12 PM",
                "value": 605.5999755859375
            },
            {
                "time": "Mon, Apr 8, 4:13 PM",
                "value": 601.5999755859375
            },
            {
                "time": "Mon, Apr 8, 4:13 PM",
                "value": 671.7999877929688
            },
            {
                "time": "Mon, Apr 8, 4:14 PM",
                "value": 657
            },
            {
                "time": "Mon, Apr 8, 4:14 PM",
                "value": 639.2000122070312
            },
            {
                "time": "Mon, Apr 8, 4:15 PM",
                "value": 649.2999877929688
            },
            {
                "time": "Mon, Apr 8, 4:15 PM",
                "value": 653.9000244140625
            },
            {
                "time": "Mon, Apr 8, 4:16 PM",
                "value": 655.4000244140625
            },
            {
                "time": "Mon, Apr 8, 4:16 PM",
                "value": 654.5999755859375
            },
            {
                "time": "Mon, Apr 8, 4:17 PM",
                "value": 687.5
            },
            {
                "time": "Mon, Apr 8, 4:17 PM",
                "value": 703.2000122070312
            },
            {
                "time": "Mon, Apr 8, 4:18 PM",
                "value": 671.7999877929688
            },
            {
                "time": "Mon, Apr 8, 4:18 PM",
                "value": 665.7999877929688
            },
            {
                "time": "Mon, Apr 8, 4:19 PM",
                "value": 666.9000244140625
            },
            {
                "time": "Mon, Apr 8, 4:19 PM",
                "value": 666.7000122070312
            },
            {
                "time": "Mon, Apr 8, 4:20 PM",
                "value": 692.2999877929688
            },
            {
                "time": "Mon, Apr 8, 4:20 PM",
                "value": 686.7999877929688
            },
            {
                "time": "Mon, Apr 8, 4:21 PM",
                "value": 717
            },
            {
                "time": "Mon, Apr 8, 4:21 PM",
                "value": 734.5999755859375
            },
            {
                "time": "Mon, Apr 8, 4:22 PM",
                "value": 731.5999755859375
            },
            {
                "time": "Mon, Apr 8, 4:22 PM",
                "value": 737.2000122070312
            },
            {
                "time": "Mon, Apr 8, 4:23 PM",
                "value": 734.5
            },
            {
                "time": "Mon, Apr 8, 4:23 PM",
                "value": 744.7000122070312
            },
            {
                "time": "Mon, Apr 8, 4:24 PM",
                "value": 723.7000122070312
            },
            {
                "time": "Mon, Apr 8, 4:24 PM",
                "value": 725.2000122070312
            },
            {
                "time": "Mon, Apr 8, 4:25 PM",
                "value": 703
            },
            {
                "time": "Mon, Apr 8, 4:25 PM",
                "value": 680.0999755859375
            },
            {
                "time": "Mon, Apr 8, 4:26 PM",
                "value": 700
            },
            {
                "time": "Mon, Apr 8, 4:26 PM",
                "value": 715.0999755859375
            },
            {
                "time": "Mon, Apr 8, 4:27 PM",
                "value": 716.9000244140625
            },
            {
                "time": "Mon, Apr 8, 4:27 PM",
                "value": 716
            },
            {
                "time": "Mon, Apr 8, 4:28 PM",
                "value": 715
            },
            {
                "time": "Mon, Apr 8, 4:28 PM",
                "value": 713.0999755859375
            },
            {
                "time": "Mon, Apr 8, 4:29 PM",
                "value": 712.4000244140625
            },
            {
                "time": "Mon, Apr 8, 4:29 PM",
                "value": 710.2000122070312
            },
            {
                "time": "Mon, Apr 8, 4:30 PM",
                "value": 706.5
            },
            {
                "time": "Mon, Apr 8, 4:30 PM",
                "value": 707.2000122070312
            },
            {
                "time": "Mon, Apr 8, 4:31 PM",
                "value": 712.5999755859375
            },
            {
                "time": "Mon, Apr 8, 4:31 PM",
                "value": 716.7999877929688
            },
            {
                "time": "Mon, Apr 8, 4:32 PM",
                "value": 594.5
            },
            {
                "time": "Mon, Apr 8, 4:32 PM",
                "value": 713.4000244140625
            },
            {
                "time": "Mon, Apr 8, 4:33 PM",
                "value": 417.29998779296875
            },
            {
                "time": "Mon, Apr 8, 4:33 PM",
                "value": 678.5
            },
            {
                "time": "Mon, Apr 8, 4:34 PM",
                "value": 677.7000122070312
            },
            {
                "time": "Mon, Apr 8, 4:34 PM",
                "value": 671.5999755859375
            },
            {
                "time": "Mon, Apr 8, 4:35 PM",
                "value": 715.5999755859375
            },
            {
                "time": "Mon, Apr 8, 4:35 PM",
                "value": 719.7999877929688
            },
            {
                "time": "Mon, Apr 8, 4:36 PM",
                "value": 605.7000122070312
            },
            {
                "time": "Mon, Apr 8, 4:36 PM",
                "value": 717.9000244140625
            },
            {
                "time": "Mon, Apr 8, 4:37 PM",
                "value": 714.5999755859375
            },
            {
                "time": "Mon, Apr 8, 4:37 PM",
                "value": 711.0999755859375
            },
            {
                "time": "Mon, Apr 8, 4:38 PM",
                "value": 707.0999755859375
            },
            {
                "time": "Mon, Apr 8, 4:38 PM",
                "value": 706.4000244140625
            },
            {
                "time": "Mon, Apr 8, 4:39 PM",
                "value": 708.7999877929688
            },
            {
                "time": "Mon, Apr 8, 4:39 PM",
                "value": 721.5999755859375
            },
            {
                "time": "Mon, Apr 8, 4:40 PM",
                "value": 723.4000244140625
            },
            {
                "time": "Mon, Apr 8, 4:40 PM",
                "value": 725.2999877929688
            },
            {
                "time": "Mon, Apr 8, 4:41 PM",
                "value": 730.4000244140625
            },
            {
                "time": "Mon, Apr 8, 4:41 PM",
                "value": 732.5
            },
            {
                "time": "Mon, Apr 8, 4:42 PM",
                "value": 734.4000244140625
            },
            {
                "time": "Mon, Apr 8, 4:42 PM",
                "value": 735.0999755859375
            },
            {
                "time": "Mon, Apr 8, 4:43 PM",
                "value": 732.2999877929688
            },
            {
                "time": "Mon, Apr 8, 4:43 PM",
                "value": 730
            },
            {
                "time": "Mon, Apr 8, 4:44 PM",
                "value": 730.5
            },
            {
                "time": "Mon, Apr 8, 4:44 PM",
                "value": 735.7999877929688
            },
            {
                "time": "Mon, Apr 8, 4:45 PM",
                "value": 719.9000244140625
            },
            {
                "time": "Mon, Apr 8, 4:45 PM",
                "value": 673.4000244140625
            },
            {
                "time": "Mon, Apr 8, 4:46 PM",
                "value": 233.60000610351562
            },
            {
                "time": "Mon, Apr 8, 4:46 PM",
                "value": 166.89999389648438
            },
            {
                "time": "Mon, Apr 8, 4:47 PM",
                "value": 220.1999969482422
            },
            {
                "time": "Mon, Apr 8, 4:47 PM",
                "value": 304
            },
            {
                "time": "Mon, Apr 8, 4:48 PM",
                "value": 155.8000030517578
            },
            {
                "time": "Mon, Apr 8, 4:48 PM",
                "value": 152.1999969482422
            },
            {
                "time": "Mon, Apr 8, 4:49 PM",
                "value": 161.10000610351562
            },
            {
                "time": "Mon, Apr 8, 4:49 PM",
                "value": 375.79998779296875
            },
            {
                "time": "Mon, Apr 8, 4:50 PM",
                "value": 686
            },
            {
                "time": "Mon, Apr 8, 4:50 PM",
                "value": 621.7000122070312
            },
            {
                "time": "Mon, Apr 8, 4:51 PM",
                "value": 464.3999938964844
            },
            {
                "time": "Mon, Apr 8, 4:51 PM",
                "value": 583.4000244140625
            },
            {
                "time": "Mon, Apr 8, 4:52 PM",
                "value": 228.8000030517578
            },
            {
                "time": "Mon, Apr 8, 4:52 PM",
                "value": 425.29998779296875
            },
            {
                "time": "Mon, Apr 8, 4:53 PM",
                "value": 694.9000244140625
            },
            {
                "time": "Mon, Apr 8, 4:53 PM",
                "value": 563.0999755859375
            },
            {
                "time": "Mon, Apr 8, 4:54 PM",
                "value": 669.7000122070312
            },
            {
                "time": "Mon, Apr 8, 4:54 PM",
                "value": 641.5
            },
            {
                "time": "Mon, Apr 8, 4:55 PM",
                "value": 682.0999755859375
            },
            {
                "time": "Mon, Apr 8, 4:55 PM",
                "value": 286.79998779296875
            },
            {
                "time": "Mon, Apr 8, 4:56 PM",
                "value": 528.4000244140625
            },
            {
                "time": "Mon, Apr 8, 4:56 PM",
                "value": 712.7000122070312
            },
            {
                "time": "Mon, Apr 8, 4:57 PM",
                "value": 704.5
            },
            {
                "time": "Mon, Apr 8, 4:57 PM",
                "value": 360.29998779296875
            },
            {
                "time": "Mon, Apr 8, 4:58 PM",
                "value": 249
            },
            {
                "time": "Mon, Apr 8, 4:58 PM",
                "value": 196.60000610351562
            },
            {
                "time": "Mon, Apr 8, 4:59 PM",
                "value": 169.1999969482422
            },
            {
                "time": "Mon, Apr 8, 4:59 PM",
                "value": 152.1999969482422
            },
            {
                "time": "Mon, Apr 8, 5:00 PM",
                "value": 145.8000030517578
            },
            {
                "time": "Mon, Apr 8, 5:00 PM",
                "value": 145.10000610351562
            },
            {
                "time": "Mon, Apr 8, 5:01 PM",
                "value": 146
            },
            {
                "time": "Mon, Apr 8, 5:01 PM",
                "value": 148.5
            },
            {
                "time": "Mon, Apr 8, 5:02 PM",
                "value": 154.5
            },
            {
                "time": "Mon, Apr 8, 5:02 PM",
                "value": 161.60000610351562
            },
            {
                "time": "Mon, Apr 8, 5:03 PM",
                "value": 168.10000610351562
            },
            {
                "time": "Mon, Apr 8, 5:03 PM",
                "value": 174.39999389648438
            },
            {
                "time": "Mon, Apr 8, 5:04 PM",
                "value": 179.60000610351562
            },
            {
                "time": "Mon, Apr 8, 5:04 PM",
                "value": 186.6999969482422
            },
            {
                "time": "Mon, Apr 8, 5:05 PM",
                "value": 193.10000610351562
            },
            {
                "time": "Mon, Apr 8, 5:05 PM",
                "value": 197.1999969482422
            },
            {
                "time": "Mon, Apr 8, 5:06 PM",
                "value": 201.60000610351562
            },
            {
                "time": "Mon, Apr 8, 5:06 PM",
                "value": 203
            },
            {
                "time": "Mon, Apr 8, 5:07 PM",
                "value": 202.39999389648438
            },
            {
                "time": "Mon, Apr 8, 5:07 PM",
                "value": 200.89999389648438
            },
            {
                "time": "Mon, Apr 8, 5:08 PM",
                "value": 208.10000610351562
            },
            {
                "time": "Mon, Apr 8, 5:08 PM",
                "value": 244.1999969482422
            },
            {
                "time": "Mon, Apr 8, 5:09 PM",
                "value": 311.29998779296875
            },
            {
                "time": "Mon, Apr 8, 5:09 PM",
                "value": 369.20001220703125
            },
            {
                "time": "Mon, Apr 8, 5:10 PM",
                "value": 378.79998779296875
            },
            {
                "time": "Mon, Apr 8, 5:10 PM",
                "value": 368.3999938964844
            },
            {
                "time": "Mon, Apr 8, 5:11 PM",
                "value": 359.3999938964844
            },
            {
                "time": "Mon, Apr 8, 5:11 PM",
                "value": 429.6000061035156
            },
            {
                "time": "Mon, Apr 8, 5:12 PM",
                "value": 472.5
            },
            {
                "time": "Mon, Apr 8, 5:12 PM",
                "value": 476.29998779296875
            },
            {
                "time": "Mon, Apr 8, 5:13 PM",
                "value": 421.79998779296875
            },
            {
                "time": "Mon, Apr 8, 5:13 PM",
                "value": 393.1000061035156
            },
            {
                "time": "Mon, Apr 8, 5:14 PM",
                "value": 353.3999938964844
            },
            {
                "time": "Mon, Apr 8, 5:14 PM",
                "value": 555.4000244140625
            },
            {
                "time": "Mon, Apr 8, 5:15 PM",
                "value": 296
            },
            {
                "time": "Mon, Apr 8, 5:15 PM",
                "value": 246.5
            },
            {
                "time": "Mon, Apr 8, 5:16 PM",
                "value": 407.29998779296875
            },
            {
                "time": "Mon, Apr 8, 5:16 PM",
                "value": 291.29998779296875
            },
            {
                "time": "Mon, Apr 8, 5:17 PM",
                "value": 432.70001220703125
            },
            {
                "time": "Mon, Apr 8, 5:17 PM",
                "value": 564.5999755859375
            },
            {
                "time": "Mon, Apr 8, 5:18 PM",
                "value": 454
            },
            {
                "time": "Mon, Apr 8, 5:18 PM",
                "value": 712.7000122070312
            },
            {
                "time": "Mon, Apr 8, 5:19 PM",
                "value": 704.2999877929688
            },
            {
                "time": "Mon, Apr 8, 5:19 PM",
                "value": 697.5999755859375
            },
            {
                "time": "Mon, Apr 8, 5:20 PM",
                "value": 673.4000244140625
            },
            {
                "time": "Mon, Apr 8, 5:20 PM",
                "value": 509.79998779296875
            },
            {
                "time": "Mon, Apr 8, 5:21 PM",
                "value": 394.3999938964844
            },
            {
                "time": "Mon, Apr 8, 5:21 PM",
                "value": 259.1000061035156
            },
            {
                "time": "Mon, Apr 8, 5:22 PM",
                "value": 187.1999969482422
            },
            {
                "time": "Mon, Apr 8, 5:22 PM",
                "value": 177.6999969482422
            },
            {
                "time": "Mon, Apr 8, 5:23 PM",
                "value": 178.8000030517578
            },
            {
                "time": "Mon, Apr 8, 5:23 PM",
                "value": 176
            },
            {
                "time": "Mon, Apr 8, 5:24 PM",
                "value": 173.6999969482422
            },
            {
                "time": "Mon, Apr 8, 5:24 PM",
                "value": 172.3000030517578
            },
            {
                "time": "Mon, Apr 8, 5:25 PM",
                "value": 170.5
            },
            {
                "time": "Mon, Apr 8, 5:25 PM",
                "value": 170.10000610351562
            },
            {
                "time": "Mon, Apr 8, 5:26 PM",
                "value": 172.8000030517578
            },
            {
                "time": "Mon, Apr 8, 5:26 PM",
                "value": 176.89999389648438
            },
            {
                "time": "Mon, Apr 8, 5:27 PM",
                "value": 184.1999969482422
            },
            {
                "time": "Mon, Apr 8, 5:27 PM",
                "value": 195.8000030517578
            },
            {
                "time": "Mon, Apr 8, 5:28 PM",
                "value": 208.1999969482422
            },
            {
                "time": "Mon, Apr 8, 5:28 PM",
                "value": 302.5
            },
            {
                "time": "Mon, Apr 8, 5:29 PM",
                "value": 337.5
            },
            {
                "time": "Mon, Apr 8, 5:29 PM",
                "value": 338.79998779296875
            },
            {
                "time": "Mon, Apr 8, 5:30 PM",
                "value": 535
            },
            {
                "time": "Mon, Apr 8, 5:30 PM",
                "value": 547.5
            },
            {
                "time": "Mon, Apr 8, 5:31 PM",
                "value": 558
            },
            {
                "time": "Mon, Apr 8, 5:31 PM",
                "value": 561.0999755859375
            },
            {
                "time": "Mon, Apr 8, 5:32 PM",
                "value": 431.29998779296875
            },
            {
                "time": "Mon, Apr 8, 5:32 PM",
                "value": 655.5
            },
            {
                "time": "Mon, Apr 8, 5:33 PM",
                "value": 655.0999755859375
            },
            {
                "time": "Mon, Apr 8, 5:33 PM",
                "value": 642.7999877929688
            },
            {
                "time": "Mon, Apr 8, 5:34 PM",
                "value": 631.5999755859375
            },
            {
                "time": "Mon, Apr 8, 5:34 PM",
                "value": 617.7000122070312
            },
            {
                "time": "Mon, Apr 8, 5:35 PM",
                "value": 592.7000122070312
            },
            {
                "time": "Mon, Apr 8, 5:35 PM",
                "value": 567.2999877929688
            },
            {
                "time": "Mon, Apr 8, 5:36 PM",
                "value": 542
            },
            {
                "time": "Mon, Apr 8, 5:36 PM",
                "value": 513.2999877929688
            },
            {
                "time": "Mon, Apr 8, 5:37 PM",
                "value": 262.3999938964844
            },
            {
                "time": "Mon, Apr 8, 5:37 PM",
                "value": 295
            },
            {
                "time": "Mon, Apr 8, 5:38 PM",
                "value": 487.79998779296875
            },
            {
                "time": "Mon, Apr 8, 5:38 PM",
                "value": 532.2999877929688
            },
            {
                "time": "Mon, Apr 8, 5:39 PM",
                "value": 529.7000122070312
            },
            {
                "time": "Mon, Apr 8, 5:39 PM",
                "value": 490.20001220703125
            },
            {
                "time": "Mon, Apr 8, 5:40 PM",
                "value": 477.3999938964844
            },
            {
                "time": "Mon, Apr 8, 5:40 PM",
                "value": 269.5
            },
            {
                "time": "Mon, Apr 8, 5:41 PM",
                "value": 199.8000030517578
            },
            {
                "time": "Mon, Apr 8, 5:41 PM",
                "value": 145.10000610351562
            },
            {
                "time": "Mon, Apr 8, 5:42 PM",
                "value": 127.69999694824219
            },
            {
                "time": "Mon, Apr 8, 5:42 PM",
                "value": 115
            },
            {
                "time": "Mon, Apr 8, 5:43 PM",
                "value": 105.5
            },
            {
                "time": "Mon, Apr 8, 5:43 PM",
                "value": 99.69999694824219
            },
            {
                "time": "Mon, Apr 8, 5:44 PM",
                "value": 93
            },
            {
                "time": "Mon, Apr 8, 5:44 PM",
                "value": 86.4000015258789
            },
            {
                "time": "Mon, Apr 8, 5:45 PM",
                "value": 81.19999694824219
            },
            {
                "time": "Mon, Apr 8, 5:45 PM",
                "value": 79.9000015258789
            },
            {
                "time": "Mon, Apr 8, 5:46 PM",
                "value": 80.5999984741211
            },
            {
                "time": "Mon, Apr 8, 5:46 PM",
                "value": 82.69999694824219
            },
            {
                "time": "Mon, Apr 8, 5:47 PM",
                "value": 86.5
            },
            {
                "time": "Mon, Apr 8, 5:47 PM",
                "value": 91.30000305175781
            },
            {
                "time": "Mon, Apr 8, 5:48 PM",
                "value": 98.80000305175781
            },
            {
                "time": "Mon, Apr 8, 5:48 PM",
                "value": 129.39999389648438
            },
            {
                "time": "Mon, Apr 8, 5:49 PM",
                "value": 246.89999389648438
            },
            {
                "time": "Mon, Apr 8, 5:49 PM",
                "value": 471.3999938964844
            },
            {
                "time": "Mon, Apr 8, 5:50 PM",
                "value": 455.8999938964844
            },
            {
                "time": "Mon, Apr 8, 5:50 PM",
                "value": 455.20001220703125
            },
            {
                "time": "Mon, Apr 8, 5:51 PM",
                "value": 452.20001220703125
            },
            {
                "time": "Mon, Apr 8, 5:51 PM",
                "value": 510.5
            },
            {
                "time": "Mon, Apr 8, 5:52 PM",
                "value": 525.5999755859375
            },
            {
                "time": "Mon, Apr 8, 5:52 PM",
                "value": 525.5999755859375
            },
            {
                "time": "Mon, Apr 8, 5:53 PM",
                "value": 518.2000122070312
            },
            {
                "time": "Mon, Apr 8, 5:53 PM",
                "value": 488.8999938964844
            },
            {
                "time": "Mon, Apr 8, 5:54 PM",
                "value": 456.70001220703125
            },
            {
                "time": "Mon, Apr 8, 5:54 PM",
                "value": 426
            },
            {
                "time": "Mon, Apr 8, 5:55 PM",
                "value": 419
            },
            {
                "time": "Mon, Apr 8, 5:55 PM",
                "value": 439.29998779296875
            },
            {
                "time": "Mon, Apr 8, 5:56 PM",
                "value": 458
            },
            {
                "time": "Mon, Apr 8, 5:56 PM",
                "value": 455.79998779296875
            },
            {
                "time": "Mon, Apr 8, 5:57 PM",
                "value": 439.79998779296875
            },
            {
                "time": "Mon, Apr 8, 5:57 PM",
                "value": 318
            },
            {
                "time": "Mon, Apr 8, 5:58 PM",
                "value": 340.6000061035156
            },
            {
                "time": "Mon, Apr 8, 5:58 PM",
                "value": 361.79998779296875
            },
            {
                "time": "Mon, Apr 8, 5:59 PM",
                "value": 137.10000610351562
            },
            {
                "time": "Mon, Apr 8, 5:59 PM",
                "value": 205.1999969482422
            },
            {
                "time": "Mon, Apr 8, 6:00 PM",
                "value": 112.4000015258789
            },
            {
                "time": "Mon, Apr 8, 6:00 PM",
                "value": 102.69999694824219
            },
            {
                "time": "Mon, Apr 8, 6:01 PM",
                "value": 99.5999984741211
            },
            {
                "time": "Mon, Apr 8, 6:01 PM",
                "value": 100.5999984741211
            },
            {
                "time": "Mon, Apr 8, 6:02 PM",
                "value": 103.5
            },
            {
                "time": "Mon, Apr 8, 6:02 PM",
                "value": 102.19999694824219
            },
            {
                "time": "Mon, Apr 8, 6:03 PM",
                "value": 100
            },
            {
                "time": "Mon, Apr 8, 6:03 PM",
                "value": 98.4000015258789
            },
            {
                "time": "Mon, Apr 8, 6:04 PM",
                "value": 97.5
            },
            {
                "time": "Mon, Apr 8, 6:04 PM",
                "value": 97.4000015258789
            },
            {
                "time": "Mon, Apr 8, 6:05 PM",
                "value": 96.19999694824219
            },
            {
                "time": "Mon, Apr 8, 6:05 PM",
                "value": 96.30000305175781
            },
            {
                "time": "Mon, Apr 8, 6:06 PM",
                "value": 98.69999694824219
            },
            {
                "time": "Mon, Apr 8, 6:06 PM",
                "value": 103
            },
            {
                "time": "Mon, Apr 8, 6:07 PM",
                "value": 112.9000015258789
            },
            {
                "time": "Mon, Apr 8, 6:07 PM",
                "value": 138
            },
            {
                "time": "Mon, Apr 8, 6:08 PM",
                "value": 217.6999969482422
            },
            {
                "time": "Mon, Apr 8, 6:08 PM",
                "value": 209.6999969482422
            },
            {
                "time": "Mon, Apr 8, 6:09 PM",
                "value": 114.80000305175781
            },
            {
                "time": "Mon, Apr 8, 6:09 PM",
                "value": 102.69999694824219
            },
            {
                "time": "Mon, Apr 8, 6:10 PM",
                "value": 96.80000305175781
            },
            {
                "time": "Mon, Apr 8, 6:10 PM",
                "value": 94
            },
            {
                "time": "Mon, Apr 8, 6:11 PM",
                "value": 92.4000015258789
            },
            {
                "time": "Mon, Apr 8, 6:11 PM",
                "value": 95
            },
            {
                "time": "Mon, Apr 8, 6:12 PM",
                "value": 89.80000305175781
            },
            {
                "time": "Mon, Apr 8, 6:12 PM",
                "value": 88.4000015258789
            },
            {
                "time": "Mon, Apr 8, 6:13 PM",
                "value": 90.30000305175781
            },
            {
                "time": "Mon, Apr 8, 6:13 PM",
                "value": 93.5999984741211
            },
            {
                "time": "Mon, Apr 8, 6:14 PM",
                "value": 98.19999694824219
            },
            {
                "time": "Mon, Apr 8, 6:14 PM",
                "value": 101.5
            },
            {
                "time": "Mon, Apr 8, 6:15 PM",
                "value": 102.80000305175781
            },
            {
                "time": "Mon, Apr 8, 6:15 PM",
                "value": 127.19999694824219
            },
            {
                "time": "Mon, Apr 8, 6:16 PM",
                "value": 123.9000015258789
            },
            {
                "time": "Mon, Apr 8, 6:16 PM",
                "value": 108.0999984741211
            },
            {
                "time": "Mon, Apr 8, 6:17 PM",
                "value": 124.19999694824219
            },
            {
                "time": "Mon, Apr 8, 6:17 PM",
                "value": 313.70001220703125
            },
            {
                "time": "Mon, Apr 8, 6:18 PM",
                "value": 243.1999969482422
            },
            {
                "time": "Mon, Apr 8, 6:18 PM",
                "value": 143.39999389648438
            },
            {
                "time": "Mon, Apr 8, 6:19 PM",
                "value": 113.4000015258789
            },
            {
                "time": "Mon, Apr 8, 6:19 PM",
                "value": 112.5
            },
            {
                "time": "Mon, Apr 8, 6:20 PM",
                "value": 114.80000305175781
            },
            {
                "time": "Mon, Apr 8, 6:20 PM",
                "value": 119.5999984741211
            },
            {
                "time": "Mon, Apr 8, 6:21 PM",
                "value": 165.3000030517578
            },
            {
                "time": "Mon, Apr 8, 6:21 PM",
                "value": 134.1999969482422
            },
            {
                "time": "Mon, Apr 8, 6:22 PM",
                "value": 218.89999389648438
            },
            {
                "time": "Mon, Apr 8, 6:22 PM",
                "value": 246.89999389648438
            },
            {
                "time": "Mon, Apr 8, 6:23 PM",
                "value": 148.3000030517578
            },
            {
                "time": "Mon, Apr 8, 6:23 PM",
                "value": 168.89999389648438
            },
            {
                "time": "Mon, Apr 8, 6:24 PM",
                "value": 215.6999969482422
            },
            {
                "time": "Mon, Apr 8, 6:24 PM",
                "value": 152.8000030517578
            },
            {
                "time": "Mon, Apr 8, 6:25 PM",
                "value": 120.5
            },
            {
                "time": "Mon, Apr 8, 6:25 PM",
                "value": 114.4000015258789
            },
            {
                "time": "Mon, Apr 8, 6:26 PM",
                "value": 110.19999694824219
            },
            {
                "time": "Mon, Apr 8, 6:26 PM",
                "value": 103.9000015258789
            },
            {
                "time": "Mon, Apr 8, 6:27 PM",
                "value": 101.5999984741211
            },
            {
                "time": "Mon, Apr 8, 6:27 PM",
                "value": 101.30000305175781
            },
            {
                "time": "Mon, Apr 8, 6:28 PM",
                "value": 100.5999984741211
            },
            {
                "time": "Mon, Apr 8, 6:28 PM",
                "value": 99.5999984741211
            },
            {
                "time": "Mon, Apr 8, 6:29 PM",
                "value": 100.0999984741211
            },
            {
                "time": "Mon, Apr 8, 6:29 PM",
                "value": 101.0999984741211
            },
            {
                "time": "Mon, Apr 8, 6:30 PM",
                "value": 102.30000305175781
            },
            {
                "time": "Mon, Apr 8, 6:30 PM",
                "value": 104.5999984741211
            },
            {
                "time": "Mon, Apr 8, 6:31 PM",
                "value": 107.30000305175781
            },
            {
                "time": "Mon, Apr 8, 6:31 PM",
                "value": 111.80000305175781
            },
            {
                "time": "Mon, Apr 8, 6:32 PM",
                "value": 112.9000015258789
            },
            {
                "time": "Mon, Apr 8, 6:32 PM",
                "value": 138.39999389648438
            },
            {
                "time": "Mon, Apr 8, 6:33 PM",
                "value": 287.8999938964844
            },
            {
                "time": "Mon, Apr 8, 6:33 PM",
                "value": 286
            },
            {
                "time": "Mon, Apr 8, 6:34 PM",
                "value": 271.8999938964844
            },
            {
                "time": "Mon, Apr 8, 6:34 PM",
                "value": 275.20001220703125
            },
            {
                "time": "Mon, Apr 8, 6:35 PM",
                "value": 273.70001220703125
            },
            {
                "time": "Mon, Apr 8, 6:35 PM",
                "value": 266.20001220703125
            },
            {
                "time": "Mon, Apr 8, 6:36 PM",
                "value": 242.8000030517578
            },
            {
                "time": "Mon, Apr 8, 6:36 PM",
                "value": 241.60000610351562
            },
            {
                "time": "Mon, Apr 8, 6:37 PM",
                "value": 236
            },
            {
                "time": "Mon, Apr 8, 6:37 PM",
                "value": 231.6999969482422
            },
            {
                "time": "Mon, Apr 8, 6:38 PM",
                "value": 223.6999969482422
            },
            {
                "time": "Mon, Apr 8, 6:38 PM",
                "value": 212.3000030517578
            },
            {
                "time": "Mon, Apr 8, 6:39 PM",
                "value": 202.39999389648438
            },
            {
                "time": "Mon, Apr 8, 6:39 PM",
                "value": 190.5
            },
            {
                "time": "Mon, Apr 8, 6:40 PM",
                "value": 171.3000030517578
            },
            {
                "time": "Mon, Apr 8, 6:40 PM",
                "value": 168.8000030517578
            },
            {
                "time": "Mon, Apr 8, 6:41 PM",
                "value": 159.8000030517578
            },
            {
                "time": "Mon, Apr 8, 6:41 PM",
                "value": 154.5
            },
            {
                "time": "Mon, Apr 8, 6:42 PM",
                "value": 158
            },
            {
                "time": "Mon, Apr 8, 6:42 PM",
                "value": 155.60000610351562
            },
            {
                "time": "Mon, Apr 8, 6:43 PM",
                "value": 141.60000610351562
            },
            {
                "time": "Mon, Apr 8, 6:43 PM",
                "value": 147.60000610351562
            },
            {
                "time": "Mon, Apr 8, 6:44 PM",
                "value": 151.10000610351562
            },
            {
                "time": "Mon, Apr 8, 6:44 PM",
                "value": 149.6999969482422
            },
            {
                "time": "Mon, Apr 8, 6:45 PM",
                "value": 150.39999389648438
            },
            {
                "time": "Mon, Apr 8, 6:45 PM",
                "value": 173
            },
            {
                "time": "Mon, Apr 8, 6:46 PM",
                "value": 190.5
            },
            {
                "time": "Mon, Apr 8, 6:46 PM",
                "value": 201.8000030517578
            },
            {
                "time": "Mon, Apr 8, 6:47 PM",
                "value": 216.3000030517578
            },
            {
                "time": "Mon, Apr 8, 6:47 PM",
                "value": 226.8000030517578
            },
            {
                "time": "Mon, Apr 8, 6:48 PM",
                "value": 220
            },
            {
                "time": "Mon, Apr 8, 6:48 PM",
                "value": 212.89999389648438
            },
            {
                "time": "Mon, Apr 8, 6:49 PM",
                "value": 204.60000610351562
            },
            {
                "time": "Mon, Apr 8, 6:49 PM",
                "value": 202.39999389648438
            },
            {
                "time": "Mon, Apr 8, 6:50 PM",
                "value": 180.5
            },
            {
                "time": "Mon, Apr 8, 6:50 PM",
                "value": 153.3000030517578
            },
            {
                "time": "Mon, Apr 8, 6:51 PM",
                "value": 159.5
            },
            {
                "time": "Mon, Apr 8, 6:51 PM",
                "value": 203.1999969482422
            },
            {
                "time": "Mon, Apr 8, 6:52 PM",
                "value": 224.10000610351562
            },
            {
                "time": "Mon, Apr 8, 6:52 PM",
                "value": 222.6999969482422
            },
            {
                "time": "Mon, Apr 8, 6:53 PM",
                "value": 213.39999389648438
            },
            {
                "time": "Mon, Apr 8, 6:53 PM",
                "value": 194.5
            },
            {
                "time": "Mon, Apr 8, 6:54 PM",
                "value": 169.89999389648438
            },
            {
                "time": "Mon, Apr 8, 6:54 PM",
                "value": 154.39999389648438
            },
            {
                "time": "Mon, Apr 8, 6:55 PM",
                "value": 127
            },
            {
                "time": "Mon, Apr 8, 6:55 PM",
                "value": 118.4000015258789
            },
            {
                "time": "Mon, Apr 8, 6:56 PM",
                "value": 140.3000030517578
            },
            {
                "time": "Mon, Apr 8, 6:56 PM",
                "value": 155.5
            },
            {
                "time": "Mon, Apr 8, 6:57 PM",
                "value": 178.10000610351562
            },
            {
                "time": "Mon, Apr 8, 6:57 PM",
                "value": 181
            },
            {
                "time": "Mon, Apr 8, 6:58 PM",
                "value": 192.60000610351562
            },
            {
                "time": "Mon, Apr 8, 6:58 PM",
                "value": 199.10000610351562
            },
            {
                "time": "Mon, Apr 8, 6:59 PM",
                "value": 187.10000610351562
            },
            {
                "time": "Mon, Apr 8, 6:59 PM",
                "value": 173.5
            },
            {
                "time": "Mon, Apr 8, 7:00 PM",
                "value": 155.3000030517578
            },
            {
                "time": "Mon, Apr 8, 7:00 PM",
                "value": 154.39999389648438
            },
            {
                "time": "Mon, Apr 8, 7:01 PM",
                "value": 156.60000610351562
            },
            {
                "time": "Mon, Apr 8, 7:01 PM",
                "value": 164.39999389648438
            },
            {
                "time": "Mon, Apr 8, 7:02 PM",
                "value": 158.39999389648438
            },
            {
                "time": "Mon, Apr 8, 7:02 PM",
                "value": 164.89999389648438
            },
            {
                "time": "Mon, Apr 8, 7:03 PM",
                "value": 178.10000610351562
            },
            {
                "time": "Mon, Apr 8, 7:03 PM",
                "value": 189.39999389648438
            },
            {
                "time": "Mon, Apr 8, 7:04 PM",
                "value": 194.89999389648438
            },
            {
                "time": "Mon, Apr 8, 7:04 PM",
                "value": 195.6999969482422
            },
            {
                "time": "Mon, Apr 8, 7:05 PM",
                "value": 194.39999389648438
            },
            {
                "time": "Mon, Apr 8, 7:05 PM",
                "value": 182.5
            },
            {
                "time": "Mon, Apr 8, 7:06 PM",
                "value": 171.6999969482422
            },
            {
                "time": "Mon, Apr 8, 7:06 PM",
                "value": 157.60000610351562
            },
            {
                "time": "Mon, Apr 8, 7:07 PM",
                "value": 154.1999969482422
            },
            {
                "time": "Mon, Apr 8, 7:07 PM",
                "value": 158.6999969482422
            },
            {
                "time": "Mon, Apr 8, 7:08 PM",
                "value": 153.1999969482422
            },
            {
                "time": "Mon, Apr 8, 7:08 PM",
                "value": 148.39999389648438
            },
            {
                "time": "Mon, Apr 8, 7:09 PM",
                "value": 129.5
            },
            {
                "time": "Mon, Apr 8, 7:09 PM",
                "value": 119.30000305175781
            },
            {
                "time": "Mon, Apr 8, 7:10 PM",
                "value": 125.80000305175781
            },
            {
                "time": "Mon, Apr 8, 7:10 PM",
                "value": 127.80000305175781
            },
            {
                "time": "Mon, Apr 8, 7:11 PM",
                "value": 146.60000610351562
            },
            {
                "time": "Mon, Apr 8, 7:11 PM",
                "value": 138.6999969482422
            },
            {
                "time": "Mon, Apr 8, 7:12 PM",
                "value": 140.89999389648438
            },
            {
                "time": "Mon, Apr 8, 7:12 PM",
                "value": 140.10000610351562
            },
            {
                "time": "Mon, Apr 8, 7:13 PM",
                "value": 116.69999694824219
            },
            {
                "time": "Mon, Apr 8, 7:13 PM",
                "value": 91.5999984741211
            },
            {
                "time": "Mon, Apr 8, 7:14 PM",
                "value": 80.69999694824219
            },
            {
                "time": "Mon, Apr 8, 7:14 PM",
                "value": 94.5
            },
            {
                "time": "Mon, Apr 8, 7:15 PM",
                "value": 108
            },
            {
                "time": "Mon, Apr 8, 7:15 PM",
                "value": 123.9000015258789
            },
            {
                "time": "Mon, Apr 8, 7:16 PM",
                "value": 125.69999694824219
            },
            {
                "time": "Mon, Apr 8, 7:16 PM",
                "value": 122.30000305175781
            },
            {
                "time": "Mon, Apr 8, 7:17 PM",
                "value": 122.0999984741211
            },
            {
                "time": "Mon, Apr 8, 7:17 PM",
                "value": 125.0999984741211
            },
            {
                "time": "Mon, Apr 8, 7:18 PM",
                "value": 126.69999694824219
            },
            {
                "time": "Mon, Apr 8, 7:18 PM",
                "value": 133.10000610351562
            },
            {
                "time": "Mon, Apr 8, 7:19 PM",
                "value": 126.30000305175781
            },
            {
                "time": "Mon, Apr 8, 7:19 PM",
                "value": 110.80000305175781
            },
            {
                "time": "Mon, Apr 8, 7:20 PM",
                "value": 98.30000305175781
            },
            {
                "time": "Mon, Apr 8, 7:20 PM",
                "value": 90
            },
            {
                "time": "Mon, Apr 8, 7:21 PM",
                "value": 83.4000015258789
            },
            {
                "time": "Mon, Apr 8, 7:21 PM",
                "value": 79.95999908447266
            },
            {
                "time": "Mon, Apr 8, 7:22 PM",
                "value": 84.19999694824219
            },
            {
                "time": "Mon, Apr 8, 7:22 PM",
                "value": 92.19999694824219
            },
            {
                "time": "Mon, Apr 8, 7:23 PM",
                "value": 91.4000015258789
            },
            {
                "time": "Mon, Apr 8, 7:23 PM",
                "value": 88.30000305175781
            },
            {
                "time": "Mon, Apr 8, 7:24 PM",
                "value": 87.19999694824219
            },
            {
                "time": "Mon, Apr 8, 7:24 PM",
                "value": 78.81999969482422
            },
            {
                "time": "Mon, Apr 8, 7:25 PM",
                "value": 79.5199966430664
            },
            {
                "time": "Mon, Apr 8, 7:25 PM",
                "value": 79.83000183105469
            },
            {
                "time": "Mon, Apr 8, 7:26 PM",
                "value": 71.88999938964844
            },
            {
                "time": "Mon, Apr 8, 7:26 PM",
                "value": 67.13999938964844
            },
            {
                "time": "Mon, Apr 8, 7:27 PM",
                "value": 72.30000305175781
            },
            {
                "time": "Mon, Apr 8, 7:27 PM",
                "value": 75.11000061035156
            },
            {
                "time": "Mon, Apr 8, 7:28 PM",
                "value": 73.37000274658203
            },
            {
                "time": "Mon, Apr 8, 7:28 PM",
                "value": 68.8499984741211
            },
            {
                "time": "Mon, Apr 8, 7:29 PM",
                "value": 62.439998626708984
            },
            {
                "time": "Mon, Apr 8, 7:29 PM",
                "value": 61.4900016784668
            },
            {
                "time": "Mon, Apr 8, 7:30 PM",
                "value": 62.470001220703125
            },
            {
                "time": "Mon, Apr 8, 7:30 PM",
                "value": 60.099998474121094
            },
            {
                "time": "Mon, Apr 8, 7:31 PM",
                "value": 67.20999908447266
            },
            {
                "time": "Mon, Apr 8, 7:31 PM",
                "value": 70.12000274658203
            },
            {
                "time": "Mon, Apr 8, 7:32 PM",
                "value": 69.54000091552734
            },
            {
                "time": "Mon, Apr 8, 7:32 PM",
                "value": 64.66999816894531
            },
            {
                "time": "Mon, Apr 8, 7:33 PM",
                "value": 59.529998779296875
            },
            {
                "time": "Mon, Apr 8, 7:33 PM",
                "value": 56.369998931884766
            },
            {
                "time": "Mon, Apr 8, 7:34 PM",
                "value": 57.09000015258789
            },
            {
                "time": "Mon, Apr 8, 7:34 PM",
                "value": 55.27000045776367
            },
            {
                "time": "Mon, Apr 8, 7:35 PM",
                "value": 51.5
            },
            {
                "time": "Mon, Apr 8, 7:35 PM",
                "value": 49.150001525878906
            },
            {
                "time": "Mon, Apr 8, 7:36 PM",
                "value": 47.36000061035156
            },
            {
                "time": "Mon, Apr 8, 7:36 PM",
                "value": 46.77000045776367
            },
            {
                "time": "Mon, Apr 8, 7:37 PM",
                "value": 47.93000030517578
            },
            {
                "time": "Mon, Apr 8, 7:37 PM",
                "value": 51.04999923706055
            },
            {
                "time": "Mon, Apr 8, 7:38 PM",
                "value": 54.11000061035156
            },
            {
                "time": "Mon, Apr 8, 7:38 PM",
                "value": 57.61000061035156
            },
            {
                "time": "Mon, Apr 8, 7:39 PM",
                "value": 56.84000015258789
            },
            {
                "time": "Mon, Apr 8, 7:39 PM",
                "value": 50.470001220703125
            },
            {
                "time": "Mon, Apr 8, 7:40 PM",
                "value": 45.810001373291016
            },
            {
                "time": "Mon, Apr 8, 7:40 PM",
                "value": 43.97999954223633
            },
            {
                "time": "Mon, Apr 8, 7:41 PM",
                "value": 42.70000076293945
            },
            {
                "time": "Mon, Apr 8, 7:41 PM",
                "value": 41.41999816894531
            },
            {
                "time": "Mon, Apr 8, 7:42 PM",
                "value": 41.4900016784668
            },
            {
                "time": "Mon, Apr 8, 7:42 PM",
                "value": 43.11000061035156
            },
            {
                "time": "Mon, Apr 8, 7:43 PM",
                "value": 44.810001373291016
            },
            {
                "time": "Mon, Apr 8, 7:43 PM",
                "value": 47.779998779296875
            },
            {
                "time": "Mon, Apr 8, 7:44 PM",
                "value": 51.72999954223633
            },
            {
                "time": "Mon, Apr 8, 7:44 PM",
                "value": 55.86000061035156
            },
            {
                "time": "Mon, Apr 8, 7:45 PM",
                "value": 56.58000183105469
            },
            {
                "time": "Mon, Apr 8, 7:45 PM",
                "value": 54.02000045776367
            },
            {
                "time": "Mon, Apr 8, 7:46 PM",
                "value": 51.099998474121094
            },
            {
                "time": "Mon, Apr 8, 7:46 PM",
                "value": 49.25
            },
            {
                "time": "Mon, Apr 8, 7:47 PM",
                "value": 46.650001525878906
            },
            {
                "time": "Mon, Apr 8, 7:47 PM",
                "value": 46.45000076293945
            },
            {
                "time": "Mon, Apr 8, 7:48 PM",
                "value": 48.75
            },
            {
                "time": "Mon, Apr 8, 7:48 PM",
                "value": 48.27000045776367
            },
            {
                "time": "Mon, Apr 8, 7:49 PM",
                "value": 42.400001525878906
            },
            {
                "time": "Mon, Apr 8, 7:49 PM",
                "value": 36.220001220703125
            },
            {
                "time": "Mon, Apr 8, 7:50 PM",
                "value": 34.08000183105469
            },
            {
                "time": "Mon, Apr 8, 7:50 PM",
                "value": 34.349998474121094
            },
            {
                "time": "Mon, Apr 8, 7:51 PM",
                "value": 35.150001525878906
            },
            {
                "time": "Mon, Apr 8, 7:51 PM",
                "value": 34.9900016784668
            },
            {
                "time": "Mon, Apr 8, 7:52 PM",
                "value": 33.630001068115234
            },
            {
                "time": "Mon, Apr 8, 7:52 PM",
                "value": 33.130001068115234
            },
            {
                "time": "Mon, Apr 8, 7:53 PM",
                "value": 34.029998779296875
            },
            {
                "time": "Mon, Apr 8, 7:53 PM",
                "value": 35.29999923706055
            },
            {
                "time": "Mon, Apr 8, 7:54 PM",
                "value": 37.34000015258789
            },
            {
                "time": "Mon, Apr 8, 7:54 PM",
                "value": 38.560001373291016
            },
            {
                "time": "Mon, Apr 8, 7:55 PM",
                "value": 37.83000183105469
            },
            {
                "time": "Mon, Apr 8, 7:55 PM",
                "value": 36.2599983215332
            },
            {
                "time": "Mon, Apr 8, 7:56 PM",
                "value": 36.04999923706055
            },
            {
                "time": "Mon, Apr 8, 7:56 PM",
                "value": 36.18000030517578
            },
            {
                "time": "Mon, Apr 8, 7:57 PM",
                "value": 35.459999084472656
            },
            {
                "time": "Mon, Apr 8, 7:57 PM",
                "value": 35.11000061035156
            },
            {
                "time": "Mon, Apr 8, 7:58 PM",
                "value": 34.38999938964844
            },
            {
                "time": "Mon, Apr 8, 7:58 PM",
                "value": 33.33000183105469
            },
            {
                "time": "Mon, Apr 8, 7:59 PM",
                "value": 31.059999465942383
            },
            {
                "time": "Mon, Apr 8, 7:59 PM",
                "value": 29.219999313354492
            },
            {
                "time": "Mon, Apr 8, 8:00 PM",
                "value": 27.43000030517578
            },
            {
                "time": "Mon, Apr 8, 8:00 PM",
                "value": 25.729999542236328
            },
            {
                "time": "Mon, Apr 8, 8:01 PM",
                "value": 23.420000076293945
            },
            {
                "time": "Mon, Apr 8, 8:01 PM",
                "value": 20.739999771118164
            },
            {
                "time": "Mon, Apr 8, 8:02 PM",
                "value": 18.799999237060547
            },
            {
                "time": "Mon, Apr 8, 8:02 PM",
                "value": 17.93000030517578
            },
            {
                "time": "Mon, Apr 8, 8:03 PM",
                "value": 17.6299991607666
            },
            {
                "time": "Mon, Apr 8, 8:03 PM",
                "value": 17.329999923706055
            },
            {
                "time": "Mon, Apr 8, 8:04 PM",
                "value": 16.90999984741211
            },
            {
                "time": "Mon, Apr 8, 8:04 PM",
                "value": 16.209999084472656
            },
            {
                "time": "Mon, Apr 8, 8:05 PM",
                "value": 15.210000038146973
            },
            {
                "time": "Mon, Apr 8, 8:05 PM",
                "value": 14.170000076293945
            },
            {
                "time": "Mon, Apr 8, 8:06 PM",
                "value": 13.350000381469727
            },
            {
                "time": "Mon, Apr 8, 8:06 PM",
                "value": 12.640000343322754
            },
            {
                "time": "Mon, Apr 8, 8:07 PM",
                "value": 11.920000076293945
            },
            {
                "time": "Mon, Apr 8, 8:07 PM",
                "value": 11.329999923706055
            },
            {
                "time": "Mon, Apr 8, 8:08 PM",
                "value": 10.65999984741211
            },
            {
                "time": "Mon, Apr 8, 8:08 PM",
                "value": 10.039999961853027
            },
            {
                "time": "Mon, Apr 8, 8:09 PM",
                "value": 9.470000267028809
            },
            {
                "time": "Mon, Apr 8, 8:09 PM",
                "value": 8.850000381469727
            },
            {
                "time": "Mon, Apr 8, 8:10 PM",
                "value": 8.369999885559082
            },
            {
                "time": "Mon, Apr 8, 8:10 PM",
                "value": 7.961999893188477
            },
            {
                "time": "Mon, Apr 8, 8:11 PM",
                "value": 7.650000095367432
            },
            {
                "time": "Mon, Apr 8, 8:11 PM",
                "value": 7.419000148773193
            },
            {
                "time": "Mon, Apr 8, 8:12 PM",
                "value": 7.166999816894531
            },
            {
                "time": "Mon, Apr 8, 8:12 PM",
                "value": 6.861999988555908
            },
            {
                "time": "Mon, Apr 8, 8:13 PM",
                "value": 6.519000053405762
            },
            {
                "time": "Mon, Apr 8, 8:13 PM",
                "value": 6.165999889373779
            },
            {
                "time": "Mon, Apr 8, 8:14 PM",
                "value": 5.8470001220703125
            },
            {
                "time": "Mon, Apr 8, 8:14 PM",
                "value": 5.514999866485596
            },
            {
                "time": "Mon, Apr 8, 8:15 PM",
                "value": 5.214000225067139
            },
            {
                "time": "Mon, Apr 8, 8:15 PM",
                "value": 4.923999786376953
            },
            {
                "time": "Mon, Apr 8, 8:16 PM",
                "value": 4.624000072479248
            },
            {
                "time": "Mon, Apr 8, 8:16 PM",
                "value": 4.326000213623047
            },
            {
                "time": "Mon, Apr 8, 8:17 PM",
                "value": 4.034999847412109
            },
            {
                "time": "Mon, Apr 8, 8:17 PM",
                "value": 3.8320000171661377
            },
            {
                "time": "Mon, Apr 8, 8:18 PM",
                "value": 3.5420000553131104
            },
            {
                "time": "Mon, Apr 8, 8:18 PM",
                "value": 3.385999917984009
            },
            {
                "time": "Mon, Apr 8, 8:19 PM",
                "value": 3.131999969482422
            },
            {
                "time": "Mon, Apr 8, 8:19 PM",
                "value": 3.0199999809265137
            },
            {
                "time": "Mon, Apr 8, 8:20 PM",
                "value": 2.812000036239624
            },
            {
                "time": "Mon, Apr 8, 8:20 PM",
                "value": 2.6640000343322754
            },
            {
                "time": "Mon, Apr 8, 8:21 PM",
                "value": 2.561000108718872
            },
            {
                "time": "Mon, Apr 8, 8:21 PM",
                "value": 2.4019999504089355
            },
            {
                "time": "Mon, Apr 8, 8:22 PM",
                "value": 2.2850000858306885
            },
            {
                "time": "Mon, Apr 8, 8:22 PM",
                "value": 2.180999994277954
            },
            {
                "time": "Mon, Apr 8, 8:23 PM",
                "value": 2.069000005722046
            },
            {
                "time": "Mon, Apr 8, 8:23 PM",
                "value": 1.9950000047683716
            },
            {
                "time": "Mon, Apr 8, 8:24 PM",
                "value": 1.8259999752044678
            },
            {
                "time": "Mon, Apr 8, 8:24 PM",
                "value": 1.722000002861023
            },
            {
                "time": "Mon, Apr 8, 8:25 PM",
                "value": 1.5700000524520874
            },
            {
                "time": "Mon, Apr 8, 8:25 PM",
                "value": 1.3680000305175781
            },
            {
                "time": "Mon, Apr 8, 8:26 PM",
                "value": 1.2339999675750732
            },
            {
                "time": "Mon, Apr 8, 8:26 PM",
                "value": 1.1109999418258667
            },
            {
                "time": "Mon, Apr 8, 8:27 PM",
                "value": 1.0089999437332153
            },
            {
                "time": "Mon, Apr 8, 8:27 PM",
                "value": 0.9120000004768372
            },
            {
                "time": "Mon, Apr 8, 8:28 PM",
                "value": 0.8190000057220459
            },
            {
                "time": "Mon, Apr 8, 8:28 PM",
                "value": 0.7260000109672546
            },
            {
                "time": "Mon, Apr 8, 8:29 PM",
                "value": 0.6050000190734863
            },
            {
                "time": "Mon, Apr 8, 8:29 PM",
                "value": 0.5410000085830688
            },
            {
                "time": "Mon, Apr 8, 8:30 PM",
                "value": 0.4880000054836273
            },
            {
                "time": "Mon, Apr 8, 8:30 PM",
                "value": 0.42500001192092896
            },
            {
                "time": "Mon, Apr 8, 8:31 PM",
                "value": 0.29600000381469727
            },
            {
                "time": "Mon, Apr 8, 8:31 PM",
                "value": 0.23499999940395355
            },
            {
                "time": "Mon, Apr 8, 8:32 PM",
                "value": 0.17299999296665192
            },
            {
                "time": "Mon, Apr 8, 8:32 PM",
                "value": 0.12399999797344208
            },
            {
                "time": "Mon, Apr 8, 8:33 PM",
                "value": 0.06499999761581421
            },
            {
                "time": "Mon, Apr 8, 8:33 PM",
                "value": 0.03700000047683716
            },
            {
                "time": "Mon, Apr 8, 8:34 PM",
                "value": 0.01600000075995922
            },
            {
                "time": "Mon, Apr 8, 8:34 PM",
                "value": 0.012000000104308128
            },
            {
                "time": "Mon, Apr 8, 8:35 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:35 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:36 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:36 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:37 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:37 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:38 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:38 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:39 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:39 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:40 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:40 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:41 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:41 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:42 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:42 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:43 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:43 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:44 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:44 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:45 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:45 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:46 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:46 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:47 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:47 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:48 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:48 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:49 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:49 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:50 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:50 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:51 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:51 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:52 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:52 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:53 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:53 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:54 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:54 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:55 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:55 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:56 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:56 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:57 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:57 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:58 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:58 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:59 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:59 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:00 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:00 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:01 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:01 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:02 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:02 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:03 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:03 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:04 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:04 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:05 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:05 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:06 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:06 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:07 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:07 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:08 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:08 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:09 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:09 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:10 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:10 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:11 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:11 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:12 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:12 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:13 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:13 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:14 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:14 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:15 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:15 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:16 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:16 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:17 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:17 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:18 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:18 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:19 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:19 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:20 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:20 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:21 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:21 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:22 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:22 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:23 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:23 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:24 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:24 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:25 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:25 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:26 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:26 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:27 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:27 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:28 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:28 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:29 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:29 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:30 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:30 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:31 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:31 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:32 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:32 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:33 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:33 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:34 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:34 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:35 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:35 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:36 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:36 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:37 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:37 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:38 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:38 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:39 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:39 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:40 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:40 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:41 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:41 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:42 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:42 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:43 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:43 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:44 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:44 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:45 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:45 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:46 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:46 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:47 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:47 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:48 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:48 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:49 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:49 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:50 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:50 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:51 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:51 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:52 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:52 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:53 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:53 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:54 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:54 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:55 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:55 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:56 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:56 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:57 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:57 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:58 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:58 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:59 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:59 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:00 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:00 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:01 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:01 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:02 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:02 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:03 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:03 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:04 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:04 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:05 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:05 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:06 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:06 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:07 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:07 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:08 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:08 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:09 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:09 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:10 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:10 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:11 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:11 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:12 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:12 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:13 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:13 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:14 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:14 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:15 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:15 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:16 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:16 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:17 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:17 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:18 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:18 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:19 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:19 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:20 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:20 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:21 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:21 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:22 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:22 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:23 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:23 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:24 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:24 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:25 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:25 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:26 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:26 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:27 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:27 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:28 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:28 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:29 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:29 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:30 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:30 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:31 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:31 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:32 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:32 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:33 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:33 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:34 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:34 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:35 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:35 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:36 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:36 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:37 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:37 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:38 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:38 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:39 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:39 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:40 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:40 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:41 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:41 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:42 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:42 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:43 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:43 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:44 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:44 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:45 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:45 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:46 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:46 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:47 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:47 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:48 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:48 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:49 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:49 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:50 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:50 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:51 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:51 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:52 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:52 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:53 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:53 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:54 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:54 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:55 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:55 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:56 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:56 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:57 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:57 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:58 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:58 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:59 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:59 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:00 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:00 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:01 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:01 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:02 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:02 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:03 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:03 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:04 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:04 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:05 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:05 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:06 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:06 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:07 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:07 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:08 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:08 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:09 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:09 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:10 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:10 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:11 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:11 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:12 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:12 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:13 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:13 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:14 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:14 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:15 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:15 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:16 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:16 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:17 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:17 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:18 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:18 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:19 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:19 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:20 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:20 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:21 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:21 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:22 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:22 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:23 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:23 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:24 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:24 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:25 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:25 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:26 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:26 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:27 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:27 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:28 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:28 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:29 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:29 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:30 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:30 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:31 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:31 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:32 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:32 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:33 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:33 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:34 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:34 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:35 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:35 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:36 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:36 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:37 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:37 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:38 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:38 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:39 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:39 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:40 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:40 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:41 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:41 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:42 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:42 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:43 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:43 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:44 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:44 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:45 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:45 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:46 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:46 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:47 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:47 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:48 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:48 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:49 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:49 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:50 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:50 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:51 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:51 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:52 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:52 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:53 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:53 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:54 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:54 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:55 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:55 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:56 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:56 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:57 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:57 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:58 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:58 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:59 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:59 PM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:00 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:00 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:01 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:01 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:02 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:02 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:03 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:03 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:04 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:04 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:05 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:05 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:06 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:06 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:07 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:07 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:08 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:08 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:09 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:09 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:10 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:10 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:11 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:11 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:12 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:12 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:13 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:13 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:14 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:14 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:15 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:15 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:16 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:16 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:17 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:17 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:18 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:18 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:19 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:19 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:20 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:20 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:21 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:21 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:22 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:22 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:23 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:23 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:24 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:24 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:25 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:25 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:26 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:26 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:27 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:27 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:28 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:28 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:29 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:29 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:30 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:30 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:31 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:31 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:32 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:32 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:33 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:33 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:34 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:34 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:35 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:35 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:36 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:36 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:37 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:37 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:38 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:38 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:39 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:39 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:40 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:40 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:41 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:41 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:42 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:42 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:43 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:43 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:44 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:44 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:45 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:45 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:46 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:46 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:47 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:47 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:48 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:48 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:49 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:49 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:50 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:50 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:51 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:51 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:52 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:52 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:53 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:53 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:54 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:54 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:55 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:55 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:56 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:56 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:57 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:57 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:58 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:58 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:59 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:59 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:00 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:00 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:01 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:01 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:02 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:02 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:03 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:03 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:04 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:04 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:05 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:05 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:06 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:06 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:07 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:07 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:08 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:08 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:09 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:09 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:10 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:10 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:11 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:11 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:12 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:12 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:13 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:13 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:14 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:14 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:15 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:15 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:16 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:16 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:17 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:17 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:18 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:18 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:19 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:19 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:20 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:20 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:21 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:21 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:22 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:22 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:23 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:23 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:24 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:24 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:25 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:25 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:26 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:26 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:27 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:27 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:28 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:28 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:29 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:29 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:30 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:30 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:31 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:31 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:32 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:32 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:33 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:33 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:34 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:34 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:35 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:35 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:36 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:36 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:37 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:37 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:38 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:38 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:39 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:39 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:40 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:40 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:41 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:41 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:42 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:42 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:43 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:43 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:44 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:44 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:45 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:45 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:46 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:46 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:47 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:47 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:48 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:48 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:49 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:49 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:50 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:50 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:51 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:51 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:52 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:52 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:53 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:53 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:54 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:54 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:55 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:55 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:56 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:56 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:57 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:57 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:58 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:58 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:59 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:59 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:00 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:00 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:01 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:01 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:02 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:02 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:03 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:03 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:04 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:04 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:05 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:05 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:06 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:06 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:07 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:07 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:08 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:08 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:09 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:09 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:10 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:10 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:11 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:11 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:12 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:12 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:13 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:13 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:14 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:14 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:15 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:15 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:16 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:16 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:17 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:17 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:18 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:18 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:19 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:19 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:20 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:20 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:21 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:21 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:22 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:22 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:23 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:23 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:24 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:24 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:25 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:25 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:26 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:26 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:27 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:27 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:28 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:28 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:29 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:29 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:30 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:30 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:31 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:31 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:32 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:32 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:33 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:33 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:34 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:34 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:35 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:35 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:36 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:36 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:37 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:37 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:38 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:38 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:39 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:39 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:40 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:40 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:41 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:41 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:42 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:42 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:43 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:43 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:44 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:44 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:45 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:45 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:46 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:46 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:47 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:47 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:48 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:48 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:49 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:49 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:50 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:50 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:51 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:51 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:52 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:52 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:53 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:53 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:54 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:54 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:55 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:55 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:56 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:56 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:57 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:57 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:58 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:58 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:59 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:59 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:00 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:00 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:01 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:01 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:02 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:02 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:03 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:03 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:04 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:04 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:05 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:05 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:06 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:06 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:07 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:07 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:08 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:08 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:09 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:09 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:10 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:10 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:11 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:11 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:12 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:12 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:13 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:13 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:14 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:14 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:15 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:15 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:16 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:16 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:17 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:17 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:18 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:18 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:19 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:19 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:20 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:20 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:21 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:21 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:22 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:22 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:23 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:23 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:24 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:24 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:25 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:25 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:26 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:26 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:27 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:27 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:28 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:28 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:29 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:29 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:30 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:30 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:31 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:31 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:32 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:32 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:33 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:33 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:34 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:34 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:35 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:35 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:36 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:36 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:37 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:37 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:38 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:38 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:39 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:39 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:40 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:40 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:41 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:41 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:42 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:42 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:43 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:43 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:44 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:44 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:45 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:45 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:46 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:46 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:47 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:47 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:48 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:48 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:49 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:49 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:50 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:50 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:51 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:51 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:52 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:52 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:53 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:53 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:54 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:54 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:55 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:55 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:56 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:56 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:57 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:57 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:58 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:58 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:59 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:59 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:00 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:00 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:01 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:01 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:02 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:02 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:03 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:03 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:04 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:04 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:05 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:05 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:06 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:06 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:07 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:07 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:08 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:08 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:09 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:09 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:10 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:10 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:11 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:11 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:12 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:12 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:13 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:13 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:14 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:14 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:15 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:15 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:16 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:16 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:17 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:17 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:18 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:18 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:19 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:19 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:20 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:20 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:21 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:21 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:22 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:22 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:23 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:23 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:24 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:24 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:25 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:25 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:26 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:26 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:27 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:27 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:28 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:28 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:29 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:29 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:30 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:30 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:31 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:31 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:32 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:32 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:33 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:33 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:34 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:34 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:35 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:35 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:36 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:36 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:37 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:37 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:38 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:38 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:39 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:39 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:40 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:40 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:41 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:41 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:42 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:42 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:43 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:43 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:44 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:44 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:45 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:45 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:46 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:46 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:47 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:47 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:48 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:48 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:49 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:49 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:50 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:50 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:51 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:51 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:52 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:52 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:53 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:53 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:54 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:54 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:55 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:55 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:56 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:56 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:57 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:57 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:58 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:58 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:59 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:59 AM",
                "value": 0
            }
        ],
        "temp": [
            {
                "time": "Mon, Apr 8, 5:00 AM",
                "value": 55.470001220703125
            },
            {
                "time": "Mon, Apr 8, 5:00 AM",
                "value": 55.470001220703125
            },
            {
                "time": "Mon, Apr 8, 5:01 AM",
                "value": 55.5099983215332
            },
            {
                "time": "Mon, Apr 8, 5:01 AM",
                "value": 55.5099983215332
            },
            {
                "time": "Mon, Apr 8, 5:02 AM",
                "value": 55.470001220703125
            },
            {
                "time": "Mon, Apr 8, 5:02 AM",
                "value": 55.470001220703125
            },
            {
                "time": "Mon, Apr 8, 5:03 AM",
                "value": 55.470001220703125
            },
            {
                "time": "Mon, Apr 8, 5:03 AM",
                "value": 55.43000030517578
            },
            {
                "time": "Mon, Apr 8, 5:04 AM",
                "value": 55.43000030517578
            },
            {
                "time": "Mon, Apr 8, 5:04 AM",
                "value": 55.400001525878906
            },
            {
                "time": "Mon, Apr 8, 5:05 AM",
                "value": 55.400001525878906
            },
            {
                "time": "Mon, Apr 8, 5:05 AM",
                "value": 55.36000061035156
            },
            {
                "time": "Mon, Apr 8, 5:06 AM",
                "value": 55.31999969482422
            },
            {
                "time": "Mon, Apr 8, 5:06 AM",
                "value": 55.290000915527344
            },
            {
                "time": "Mon, Apr 8, 5:07 AM",
                "value": 55.25
            },
            {
                "time": "Mon, Apr 8, 5:07 AM",
                "value": 55.220001220703125
            },
            {
                "time": "Mon, Apr 8, 5:08 AM",
                "value": 55.220001220703125
            },
            {
                "time": "Mon, Apr 8, 5:08 AM",
                "value": 55.220001220703125
            },
            {
                "time": "Mon, Apr 8, 5:09 AM",
                "value": 55.220001220703125
            },
            {
                "time": "Mon, Apr 8, 5:09 AM",
                "value": 55.18000030517578
            },
            {
                "time": "Mon, Apr 8, 5:10 AM",
                "value": 55.150001525878906
            },
            {
                "time": "Mon, Apr 8, 5:10 AM",
                "value": 55.11000061035156
            },
            {
                "time": "Mon, Apr 8, 5:11 AM",
                "value": 55.09000015258789
            },
            {
                "time": "Mon, Apr 8, 5:11 AM",
                "value": 54.959999084472656
            },
            {
                "time": "Mon, Apr 8, 5:12 AM",
                "value": 54.93000030517578
            },
            {
                "time": "Mon, Apr 8, 5:12 AM",
                "value": 54.88999938964844
            },
            {
                "time": "Mon, Apr 8, 5:13 AM",
                "value": 54.88999938964844
            },
            {
                "time": "Mon, Apr 8, 5:13 AM",
                "value": 54.86000061035156
            },
            {
                "time": "Mon, Apr 8, 5:14 AM",
                "value": 54.86000061035156
            },
            {
                "time": "Mon, Apr 8, 5:14 AM",
                "value": 54.81999969482422
            },
            {
                "time": "Mon, Apr 8, 5:15 AM",
                "value": 54.810001373291016
            },
            {
                "time": "Mon, Apr 8, 5:15 AM",
                "value": 54.75
            },
            {
                "time": "Mon, Apr 8, 5:16 AM",
                "value": 54.709999084472656
            },
            {
                "time": "Mon, Apr 8, 5:16 AM",
                "value": 54.709999084472656
            },
            {
                "time": "Mon, Apr 8, 5:17 AM",
                "value": 54.68000030517578
            },
            {
                "time": "Mon, Apr 8, 5:17 AM",
                "value": 54.66999816894531
            },
            {
                "time": "Mon, Apr 8, 5:18 AM",
                "value": 54.709999084472656
            },
            {
                "time": "Mon, Apr 8, 5:18 AM",
                "value": 54.75
            },
            {
                "time": "Mon, Apr 8, 5:19 AM",
                "value": 54.779998779296875
            },
            {
                "time": "Mon, Apr 8, 5:19 AM",
                "value": 54.779998779296875
            },
            {
                "time": "Mon, Apr 8, 5:20 AM",
                "value": 54.779998779296875
            },
            {
                "time": "Mon, Apr 8, 5:20 AM",
                "value": 54.81999969482422
            },
            {
                "time": "Mon, Apr 8, 5:21 AM",
                "value": 54.81999969482422
            },
            {
                "time": "Mon, Apr 8, 5:21 AM",
                "value": 54.81999969482422
            },
            {
                "time": "Mon, Apr 8, 5:22 AM",
                "value": 54.81999969482422
            },
            {
                "time": "Mon, Apr 8, 5:22 AM",
                "value": 54.849998474121094
            },
            {
                "time": "Mon, Apr 8, 5:23 AM",
                "value": 54.86000061035156
            },
            {
                "time": "Mon, Apr 8, 5:23 AM",
                "value": 54.86000061035156
            },
            {
                "time": "Mon, Apr 8, 5:24 AM",
                "value": 54.88999938964844
            },
            {
                "time": "Mon, Apr 8, 5:24 AM",
                "value": 54.88999938964844
            },
            {
                "time": "Mon, Apr 8, 5:25 AM",
                "value": 54.88999938964844
            },
            {
                "time": "Mon, Apr 8, 5:25 AM",
                "value": 54.88999938964844
            },
            {
                "time": "Mon, Apr 8, 5:26 AM",
                "value": 54.88999938964844
            },
            {
                "time": "Mon, Apr 8, 5:26 AM",
                "value": 54.849998474121094
            },
            {
                "time": "Mon, Apr 8, 5:27 AM",
                "value": 54.86000061035156
            },
            {
                "time": "Mon, Apr 8, 5:27 AM",
                "value": 54.849998474121094
            },
            {
                "time": "Mon, Apr 8, 5:28 AM",
                "value": 54.88999938964844
            },
            {
                "time": "Mon, Apr 8, 5:28 AM",
                "value": 54.88999938964844
            },
            {
                "time": "Mon, Apr 8, 5:29 AM",
                "value": 54.88999938964844
            },
            {
                "time": "Mon, Apr 8, 5:29 AM",
                "value": 54.88999938964844
            },
            {
                "time": "Mon, Apr 8, 5:30 AM",
                "value": 54.88999938964844
            },
            {
                "time": "Mon, Apr 8, 5:30 AM",
                "value": 54.88999938964844
            },
            {
                "time": "Mon, Apr 8, 5:31 AM",
                "value": 54.939998626708984
            },
            {
                "time": "Mon, Apr 8, 5:31 AM",
                "value": 54.88999938964844
            },
            {
                "time": "Mon, Apr 8, 5:32 AM",
                "value": 54.88999938964844
            },
            {
                "time": "Mon, Apr 8, 5:32 AM",
                "value": 54.88999938964844
            },
            {
                "time": "Mon, Apr 8, 5:33 AM",
                "value": 54.88999938964844
            },
            {
                "time": "Mon, Apr 8, 5:33 AM",
                "value": 54.88999938964844
            },
            {
                "time": "Mon, Apr 8, 5:34 AM",
                "value": 54.88999938964844
            },
            {
                "time": "Mon, Apr 8, 5:34 AM",
                "value": 54.88999938964844
            },
            {
                "time": "Mon, Apr 8, 5:35 AM",
                "value": 54.88999938964844
            },
            {
                "time": "Mon, Apr 8, 5:35 AM",
                "value": 54.88999938964844
            },
            {
                "time": "Mon, Apr 8, 5:36 AM",
                "value": 54.849998474121094
            },
            {
                "time": "Mon, Apr 8, 5:36 AM",
                "value": 54.81999969482422
            },
            {
                "time": "Mon, Apr 8, 5:37 AM",
                "value": 54.81999969482422
            },
            {
                "time": "Mon, Apr 8, 5:37 AM",
                "value": 54.779998779296875
            },
            {
                "time": "Mon, Apr 8, 5:38 AM",
                "value": 54.709999084472656
            },
            {
                "time": "Mon, Apr 8, 5:38 AM",
                "value": 54.709999084472656
            },
            {
                "time": "Mon, Apr 8, 5:39 AM",
                "value": 54.709999084472656
            },
            {
                "time": "Mon, Apr 8, 5:39 AM",
                "value": 54.68000030517578
            },
            {
                "time": "Mon, Apr 8, 5:40 AM",
                "value": 54.66999816894531
            },
            {
                "time": "Mon, Apr 8, 5:40 AM",
                "value": 54.63999938964844
            },
            {
                "time": "Mon, Apr 8, 5:41 AM",
                "value": 54.599998474121094
            },
            {
                "time": "Mon, Apr 8, 5:41 AM",
                "value": 54.56999969482422
            },
            {
                "time": "Mon, Apr 8, 5:42 AM",
                "value": 54.529998779296875
            },
            {
                "time": "Mon, Apr 8, 5:42 AM",
                "value": 54.4900016784668
            },
            {
                "time": "Mon, Apr 8, 5:43 AM",
                "value": 54.52000045776367
            },
            {
                "time": "Mon, Apr 8, 5:43 AM",
                "value": 54.459999084472656
            },
            {
                "time": "Mon, Apr 8, 5:44 AM",
                "value": 54.41999816894531
            },
            {
                "time": "Mon, Apr 8, 5:44 AM",
                "value": 54.41999816894531
            },
            {
                "time": "Mon, Apr 8, 5:45 AM",
                "value": 54.41999816894531
            },
            {
                "time": "Mon, Apr 8, 5:45 AM",
                "value": 54.41999816894531
            },
            {
                "time": "Mon, Apr 8, 5:46 AM",
                "value": 54.41999816894531
            },
            {
                "time": "Mon, Apr 8, 5:46 AM",
                "value": 54.41999816894531
            },
            {
                "time": "Mon, Apr 8, 5:47 AM",
                "value": 54.41999816894531
            },
            {
                "time": "Mon, Apr 8, 5:47 AM",
                "value": 54.41999816894531
            },
            {
                "time": "Mon, Apr 8, 5:48 AM",
                "value": 54.41999816894531
            },
            {
                "time": "Mon, Apr 8, 5:48 AM",
                "value": 54.41999816894531
            },
            {
                "time": "Mon, Apr 8, 5:49 AM",
                "value": 54.41999816894531
            },
            {
                "time": "Mon, Apr 8, 5:49 AM",
                "value": 54.38999938964844
            },
            {
                "time": "Mon, Apr 8, 5:50 AM",
                "value": 54.38999938964844
            },
            {
                "time": "Mon, Apr 8, 5:50 AM",
                "value": 54.38999938964844
            },
            {
                "time": "Mon, Apr 8, 5:51 AM",
                "value": 54.349998474121094
            },
            {
                "time": "Mon, Apr 8, 5:51 AM",
                "value": 54.349998474121094
            },
            {
                "time": "Mon, Apr 8, 5:52 AM",
                "value": 54.310001373291016
            },
            {
                "time": "Mon, Apr 8, 5:52 AM",
                "value": 54.310001373291016
            },
            {
                "time": "Mon, Apr 8, 5:53 AM",
                "value": 54.310001373291016
            },
            {
                "time": "Mon, Apr 8, 5:53 AM",
                "value": 54.31999969482422
            },
            {
                "time": "Mon, Apr 8, 5:54 AM",
                "value": 54.310001373291016
            },
            {
                "time": "Mon, Apr 8, 5:54 AM",
                "value": 54.349998474121094
            },
            {
                "time": "Mon, Apr 8, 5:55 AM",
                "value": 54.38999938964844
            },
            {
                "time": "Mon, Apr 8, 5:55 AM",
                "value": 54.41999816894531
            },
            {
                "time": "Mon, Apr 8, 5:56 AM",
                "value": 54.459999084472656
            },
            {
                "time": "Mon, Apr 8, 5:56 AM",
                "value": 54.4900016784668
            },
            {
                "time": "Mon, Apr 8, 5:57 AM",
                "value": 54.529998779296875
            },
            {
                "time": "Mon, Apr 8, 5:57 AM",
                "value": 54.529998779296875
            },
            {
                "time": "Mon, Apr 8, 5:58 AM",
                "value": 54.529998779296875
            },
            {
                "time": "Mon, Apr 8, 5:58 AM",
                "value": 54.4900016784668
            },
            {
                "time": "Mon, Apr 8, 5:59 AM",
                "value": 54.56999969482422
            },
            {
                "time": "Mon, Apr 8, 5:59 AM",
                "value": 54.459999084472656
            },
            {
                "time": "Mon, Apr 8, 6:00 AM",
                "value": 54.459999084472656
            },
            {
                "time": "Mon, Apr 8, 6:00 AM",
                "value": 54.459999084472656
            },
            {
                "time": "Mon, Apr 8, 6:01 AM",
                "value": 54.459999084472656
            },
            {
                "time": "Mon, Apr 8, 6:01 AM",
                "value": 54.459999084472656
            },
            {
                "time": "Mon, Apr 8, 6:02 AM",
                "value": 54.459999084472656
            },
            {
                "time": "Mon, Apr 8, 6:02 AM",
                "value": 54.459999084472656
            },
            {
                "time": "Mon, Apr 8, 6:03 AM",
                "value": 54.459999084472656
            },
            {
                "time": "Mon, Apr 8, 6:03 AM",
                "value": 54.41999816894531
            },
            {
                "time": "Mon, Apr 8, 6:04 AM",
                "value": 54.38999938964844
            },
            {
                "time": "Mon, Apr 8, 6:04 AM",
                "value": 54.38999938964844
            },
            {
                "time": "Mon, Apr 8, 6:05 AM",
                "value": 54.349998474121094
            },
            {
                "time": "Mon, Apr 8, 6:05 AM",
                "value": 54.349998474121094
            },
            {
                "time": "Mon, Apr 8, 6:06 AM",
                "value": 54.310001373291016
            },
            {
                "time": "Mon, Apr 8, 6:06 AM",
                "value": 54.310001373291016
            },
            {
                "time": "Mon, Apr 8, 6:07 AM",
                "value": 54.34000015258789
            },
            {
                "time": "Mon, Apr 8, 6:07 AM",
                "value": 54.31999969482422
            },
            {
                "time": "Mon, Apr 8, 6:08 AM",
                "value": 54.310001373291016
            },
            {
                "time": "Mon, Apr 8, 6:08 AM",
                "value": 54.279998779296875
            },
            {
                "time": "Mon, Apr 8, 6:09 AM",
                "value": 54.279998779296875
            },
            {
                "time": "Mon, Apr 8, 6:09 AM",
                "value": 54.27000045776367
            },
            {
                "time": "Mon, Apr 8, 6:10 AM",
                "value": 54.279998779296875
            },
            {
                "time": "Mon, Apr 8, 6:10 AM",
                "value": 54.2400016784668
            },
            {
                "time": "Mon, Apr 8, 6:11 AM",
                "value": 54.2400016784668
            },
            {
                "time": "Mon, Apr 8, 6:11 AM",
                "value": 54.2400016784668
            },
            {
                "time": "Mon, Apr 8, 6:12 AM",
                "value": 54.2400016784668
            },
            {
                "time": "Mon, Apr 8, 6:12 AM",
                "value": 54.20000076293945
            },
            {
                "time": "Mon, Apr 8, 6:13 AM",
                "value": 54.16999816894531
            },
            {
                "time": "Mon, Apr 8, 6:13 AM",
                "value": 54.130001068115234
            },
            {
                "time": "Mon, Apr 8, 6:14 AM",
                "value": 54.099998474121094
            },
            {
                "time": "Mon, Apr 8, 6:14 AM",
                "value": 54.209999084472656
            },
            {
                "time": "Mon, Apr 8, 6:15 AM",
                "value": 54.16999816894531
            },
            {
                "time": "Mon, Apr 8, 6:15 AM",
                "value": 54.209999084472656
            },
            {
                "time": "Mon, Apr 8, 6:16 AM",
                "value": 54.20000076293945
            },
            {
                "time": "Mon, Apr 8, 6:16 AM",
                "value": 54.209999084472656
            },
            {
                "time": "Mon, Apr 8, 6:17 AM",
                "value": 54.2400016784668
            },
            {
                "time": "Mon, Apr 8, 6:17 AM",
                "value": 54.2400016784668
            },
            {
                "time": "Mon, Apr 8, 6:18 AM",
                "value": 54.2400016784668
            },
            {
                "time": "Mon, Apr 8, 6:18 AM",
                "value": 54.2400016784668
            },
            {
                "time": "Mon, Apr 8, 6:19 AM",
                "value": 54.2400016784668
            },
            {
                "time": "Mon, Apr 8, 6:19 AM",
                "value": 54.279998779296875
            },
            {
                "time": "Mon, Apr 8, 6:20 AM",
                "value": 54.310001373291016
            },
            {
                "time": "Mon, Apr 8, 6:20 AM",
                "value": 54.349998474121094
            },
            {
                "time": "Mon, Apr 8, 6:21 AM",
                "value": 54.349998474121094
            },
            {
                "time": "Mon, Apr 8, 6:21 AM",
                "value": 54.38999938964844
            },
            {
                "time": "Mon, Apr 8, 6:22 AM",
                "value": 54.38999938964844
            },
            {
                "time": "Mon, Apr 8, 6:22 AM",
                "value": 54.380001068115234
            },
            {
                "time": "Mon, Apr 8, 6:23 AM",
                "value": 54.41999816894531
            },
            {
                "time": "Mon, Apr 8, 6:23 AM",
                "value": 54.41999816894531
            },
            {
                "time": "Mon, Apr 8, 6:24 AM",
                "value": 54.459999084472656
            },
            {
                "time": "Mon, Apr 8, 6:24 AM",
                "value": 54.41999816894531
            },
            {
                "time": "Mon, Apr 8, 6:25 AM",
                "value": 54.41999816894531
            },
            {
                "time": "Mon, Apr 8, 6:25 AM",
                "value": 54.41999816894531
            },
            {
                "time": "Mon, Apr 8, 6:26 AM",
                "value": 54.41999816894531
            },
            {
                "time": "Mon, Apr 8, 6:26 AM",
                "value": 54.41999816894531
            },
            {
                "time": "Mon, Apr 8, 6:27 AM",
                "value": 54.41999816894531
            },
            {
                "time": "Mon, Apr 8, 6:27 AM",
                "value": 54.41999816894531
            },
            {
                "time": "Mon, Apr 8, 6:28 AM",
                "value": 54.41999816894531
            },
            {
                "time": "Mon, Apr 8, 6:28 AM",
                "value": 54.41999816894531
            },
            {
                "time": "Mon, Apr 8, 6:29 AM",
                "value": 54.41999816894531
            },
            {
                "time": "Mon, Apr 8, 6:29 AM",
                "value": 54.41999816894531
            },
            {
                "time": "Mon, Apr 8, 6:30 AM",
                "value": 54.41999816894531
            },
            {
                "time": "Mon, Apr 8, 6:30 AM",
                "value": 54.4900016784668
            },
            {
                "time": "Mon, Apr 8, 6:31 AM",
                "value": 54.41999816894531
            },
            {
                "time": "Mon, Apr 8, 6:31 AM",
                "value": 54.41999816894531
            },
            {
                "time": "Mon, Apr 8, 6:32 AM",
                "value": 54.41999816894531
            },
            {
                "time": "Mon, Apr 8, 6:32 AM",
                "value": 54.41999816894531
            },
            {
                "time": "Mon, Apr 8, 6:33 AM",
                "value": 54.38999938964844
            },
            {
                "time": "Mon, Apr 8, 6:33 AM",
                "value": 54.38999938964844
            },
            {
                "time": "Mon, Apr 8, 6:34 AM",
                "value": 54.349998474121094
            },
            {
                "time": "Mon, Apr 8, 6:34 AM",
                "value": 54.45000076293945
            },
            {
                "time": "Mon, Apr 8, 6:35 AM",
                "value": 54.38999938964844
            },
            {
                "time": "Mon, Apr 8, 6:35 AM",
                "value": 54.41999816894531
            },
            {
                "time": "Mon, Apr 8, 6:36 AM",
                "value": 54.41999816894531
            },
            {
                "time": "Mon, Apr 8, 6:36 AM",
                "value": 54.41999816894531
            },
            {
                "time": "Mon, Apr 8, 6:37 AM",
                "value": 54.38999938964844
            },
            {
                "time": "Mon, Apr 8, 6:37 AM",
                "value": 54.41999816894531
            },
            {
                "time": "Mon, Apr 8, 6:38 AM",
                "value": 54.41999816894531
            },
            {
                "time": "Mon, Apr 8, 6:38 AM",
                "value": 54.5
            },
            {
                "time": "Mon, Apr 8, 6:39 AM",
                "value": 54.459999084472656
            },
            {
                "time": "Mon, Apr 8, 6:39 AM",
                "value": 54.459999084472656
            },
            {
                "time": "Mon, Apr 8, 6:40 AM",
                "value": 54.459999084472656
            },
            {
                "time": "Mon, Apr 8, 6:40 AM",
                "value": 54.459999084472656
            },
            {
                "time": "Mon, Apr 8, 6:41 AM",
                "value": 54.459999084472656
            },
            {
                "time": "Mon, Apr 8, 6:41 AM",
                "value": 54.459999084472656
            },
            {
                "time": "Mon, Apr 8, 6:42 AM",
                "value": 54.459999084472656
            },
            {
                "time": "Mon, Apr 8, 6:42 AM",
                "value": 54.459999084472656
            },
            {
                "time": "Mon, Apr 8, 6:43 AM",
                "value": 54.459999084472656
            },
            {
                "time": "Mon, Apr 8, 6:43 AM",
                "value": 54.459999084472656
            },
            {
                "time": "Mon, Apr 8, 6:44 AM",
                "value": 54.459999084472656
            },
            {
                "time": "Mon, Apr 8, 6:44 AM",
                "value": 54.459999084472656
            },
            {
                "time": "Mon, Apr 8, 6:45 AM",
                "value": 54.459999084472656
            },
            {
                "time": "Mon, Apr 8, 6:45 AM",
                "value": 54.459999084472656
            },
            {
                "time": "Mon, Apr 8, 6:46 AM",
                "value": 54.459999084472656
            },
            {
                "time": "Mon, Apr 8, 6:46 AM",
                "value": 54.459999084472656
            },
            {
                "time": "Mon, Apr 8, 6:47 AM",
                "value": 54.459999084472656
            },
            {
                "time": "Mon, Apr 8, 6:47 AM",
                "value": 54.41999816894531
            },
            {
                "time": "Mon, Apr 8, 6:48 AM",
                "value": 54.41999816894531
            },
            {
                "time": "Mon, Apr 8, 6:48 AM",
                "value": 54.41999816894531
            },
            {
                "time": "Mon, Apr 8, 6:49 AM",
                "value": 54.41999816894531
            },
            {
                "time": "Mon, Apr 8, 6:49 AM",
                "value": 54.41999816894531
            },
            {
                "time": "Mon, Apr 8, 6:50 AM",
                "value": 54.41999816894531
            },
            {
                "time": "Mon, Apr 8, 6:50 AM",
                "value": 54.439998626708984
            },
            {
                "time": "Mon, Apr 8, 6:51 AM",
                "value": 54.380001068115234
            },
            {
                "time": "Mon, Apr 8, 6:51 AM",
                "value": 54.38999938964844
            },
            {
                "time": "Mon, Apr 8, 6:52 AM",
                "value": 54.380001068115234
            },
            {
                "time": "Mon, Apr 8, 6:52 AM",
                "value": 54.41999816894531
            },
            {
                "time": "Mon, Apr 8, 6:53 AM",
                "value": 54.41999816894531
            },
            {
                "time": "Mon, Apr 8, 6:53 AM",
                "value": 54.41999816894531
            },
            {
                "time": "Mon, Apr 8, 6:54 AM",
                "value": 54.41999816894531
            },
            {
                "time": "Mon, Apr 8, 6:54 AM",
                "value": 54.529998779296875
            },
            {
                "time": "Mon, Apr 8, 6:55 AM",
                "value": 54.459999084472656
            },
            {
                "time": "Mon, Apr 8, 6:55 AM",
                "value": 54.4900016784668
            },
            {
                "time": "Mon, Apr 8, 6:56 AM",
                "value": 54.4900016784668
            },
            {
                "time": "Mon, Apr 8, 6:56 AM",
                "value": 54.5
            },
            {
                "time": "Mon, Apr 8, 6:57 AM",
                "value": 54.529998779296875
            },
            {
                "time": "Mon, Apr 8, 6:57 AM",
                "value": 54.4900016784668
            },
            {
                "time": "Mon, Apr 8, 6:58 AM",
                "value": 54.4900016784668
            },
            {
                "time": "Mon, Apr 8, 6:58 AM",
                "value": 54.52000045776367
            },
            {
                "time": "Mon, Apr 8, 6:59 AM",
                "value": 54.4900016784668
            },
            {
                "time": "Mon, Apr 8, 6:59 AM",
                "value": 54.5
            },
            {
                "time": "Mon, Apr 8, 7:00 AM",
                "value": 54.4900016784668
            },
            {
                "time": "Mon, Apr 8, 7:00 AM",
                "value": 54.4900016784668
            },
            {
                "time": "Mon, Apr 8, 7:01 AM",
                "value": 54.529998779296875
            },
            {
                "time": "Mon, Apr 8, 7:01 AM",
                "value": 54.56999969482422
            },
            {
                "time": "Mon, Apr 8, 7:02 AM",
                "value": 54.56999969482422
            },
            {
                "time": "Mon, Apr 8, 7:02 AM",
                "value": 54.599998474121094
            },
            {
                "time": "Mon, Apr 8, 7:03 AM",
                "value": 54.56999969482422
            },
            {
                "time": "Mon, Apr 8, 7:03 AM",
                "value": 54.56999969482422
            },
            {
                "time": "Mon, Apr 8, 7:04 AM",
                "value": 54.56999969482422
            },
            {
                "time": "Mon, Apr 8, 7:04 AM",
                "value": 54.599998474121094
            },
            {
                "time": "Mon, Apr 8, 7:05 AM",
                "value": 54.599998474121094
            },
            {
                "time": "Mon, Apr 8, 7:05 AM",
                "value": 54.599998474121094
            },
            {
                "time": "Mon, Apr 8, 7:06 AM",
                "value": 54.56999969482422
            },
            {
                "time": "Mon, Apr 8, 7:06 AM",
                "value": 54.56999969482422
            },
            {
                "time": "Mon, Apr 8, 7:07 AM",
                "value": 54.599998474121094
            },
            {
                "time": "Mon, Apr 8, 7:07 AM",
                "value": 54.599998474121094
            },
            {
                "time": "Mon, Apr 8, 7:08 AM",
                "value": 54.63999938964844
            },
            {
                "time": "Mon, Apr 8, 7:08 AM",
                "value": 54.599998474121094
            },
            {
                "time": "Mon, Apr 8, 7:09 AM",
                "value": 54.56999969482422
            },
            {
                "time": "Mon, Apr 8, 7:09 AM",
                "value": 54.56999969482422
            },
            {
                "time": "Mon, Apr 8, 7:10 AM",
                "value": 54.529998779296875
            },
            {
                "time": "Mon, Apr 8, 7:10 AM",
                "value": 54.529998779296875
            },
            {
                "time": "Mon, Apr 8, 7:11 AM",
                "value": 54.4900016784668
            },
            {
                "time": "Mon, Apr 8, 7:11 AM",
                "value": 54.4900016784668
            },
            {
                "time": "Mon, Apr 8, 7:12 AM",
                "value": 54.4900016784668
            },
            {
                "time": "Mon, Apr 8, 7:12 AM",
                "value": 54.4900016784668
            },
            {
                "time": "Mon, Apr 8, 7:13 AM",
                "value": 54.459999084472656
            },
            {
                "time": "Mon, Apr 8, 7:13 AM",
                "value": 54.459999084472656
            },
            {
                "time": "Mon, Apr 8, 7:14 AM",
                "value": 54.529998779296875
            },
            {
                "time": "Mon, Apr 8, 7:14 AM",
                "value": 54.459999084472656
            },
            {
                "time": "Mon, Apr 8, 7:15 AM",
                "value": 54.459999084472656
            },
            {
                "time": "Mon, Apr 8, 7:15 AM",
                "value": 54.459999084472656
            },
            {
                "time": "Mon, Apr 8, 7:16 AM",
                "value": 54.459999084472656
            },
            {
                "time": "Mon, Apr 8, 7:16 AM",
                "value": 54.459999084472656
            },
            {
                "time": "Mon, Apr 8, 7:17 AM",
                "value": 54.459999084472656
            },
            {
                "time": "Mon, Apr 8, 7:17 AM",
                "value": 54.459999084472656
            },
            {
                "time": "Mon, Apr 8, 7:18 AM",
                "value": 54.52000045776367
            },
            {
                "time": "Mon, Apr 8, 7:18 AM",
                "value": 54.4900016784668
            },
            {
                "time": "Mon, Apr 8, 7:19 AM",
                "value": 54.4900016784668
            },
            {
                "time": "Mon, Apr 8, 7:19 AM",
                "value": 54.5
            },
            {
                "time": "Mon, Apr 8, 7:20 AM",
                "value": 54.4900016784668
            },
            {
                "time": "Mon, Apr 8, 7:20 AM",
                "value": 54.4900016784668
            },
            {
                "time": "Mon, Apr 8, 7:21 AM",
                "value": 54.459999084472656
            },
            {
                "time": "Mon, Apr 8, 7:21 AM",
                "value": 54.459999084472656
            },
            {
                "time": "Mon, Apr 8, 7:22 AM",
                "value": 54.4900016784668
            },
            {
                "time": "Mon, Apr 8, 7:22 AM",
                "value": 54.5
            },
            {
                "time": "Mon, Apr 8, 7:23 AM",
                "value": 54.4900016784668
            },
            {
                "time": "Mon, Apr 8, 7:23 AM",
                "value": 54.4900016784668
            },
            {
                "time": "Mon, Apr 8, 7:24 AM",
                "value": 54.4900016784668
            },
            {
                "time": "Mon, Apr 8, 7:24 AM",
                "value": 54.4900016784668
            },
            {
                "time": "Mon, Apr 8, 7:25 AM",
                "value": 54.4900016784668
            },
            {
                "time": "Mon, Apr 8, 7:25 AM",
                "value": 54.4900016784668
            },
            {
                "time": "Mon, Apr 8, 7:26 AM",
                "value": 54.4900016784668
            },
            {
                "time": "Mon, Apr 8, 7:26 AM",
                "value": 54.5
            },
            {
                "time": "Mon, Apr 8, 7:27 AM",
                "value": 54.459999084472656
            },
            {
                "time": "Mon, Apr 8, 7:27 AM",
                "value": 54.459999084472656
            },
            {
                "time": "Mon, Apr 8, 7:28 AM",
                "value": 54.459999084472656
            },
            {
                "time": "Mon, Apr 8, 7:28 AM",
                "value": 54.459999084472656
            },
            {
                "time": "Mon, Apr 8, 7:29 AM",
                "value": 54.459999084472656
            },
            {
                "time": "Mon, Apr 8, 7:29 AM",
                "value": 54.4900016784668
            },
            {
                "time": "Mon, Apr 8, 7:30 AM",
                "value": 54.41999816894531
            },
            {
                "time": "Mon, Apr 8, 7:30 AM",
                "value": 54.41999816894531
            },
            {
                "time": "Mon, Apr 8, 7:31 AM",
                "value": 54.459999084472656
            },
            {
                "time": "Mon, Apr 8, 7:31 AM",
                "value": 54.41999816894531
            },
            {
                "time": "Mon, Apr 8, 7:32 AM",
                "value": 54.41999816894531
            },
            {
                "time": "Mon, Apr 8, 7:32 AM",
                "value": 54.459999084472656
            },
            {
                "time": "Mon, Apr 8, 7:33 AM",
                "value": 54.459999084472656
            },
            {
                "time": "Mon, Apr 8, 7:33 AM",
                "value": 54.5
            },
            {
                "time": "Mon, Apr 8, 7:34 AM",
                "value": 54.459999084472656
            },
            {
                "time": "Mon, Apr 8, 7:34 AM",
                "value": 54.459999084472656
            },
            {
                "time": "Mon, Apr 8, 7:35 AM",
                "value": 54.4900016784668
            },
            {
                "time": "Mon, Apr 8, 7:35 AM",
                "value": 54.4900016784668
            },
            {
                "time": "Mon, Apr 8, 7:36 AM",
                "value": 54.4900016784668
            },
            {
                "time": "Mon, Apr 8, 7:36 AM",
                "value": 54.4900016784668
            },
            {
                "time": "Mon, Apr 8, 7:37 AM",
                "value": 54.4900016784668
            },
            {
                "time": "Mon, Apr 8, 7:37 AM",
                "value": 54.459999084472656
            },
            {
                "time": "Mon, Apr 8, 7:38 AM",
                "value": 54.459999084472656
            },
            {
                "time": "Mon, Apr 8, 7:38 AM",
                "value": 54.5
            },
            {
                "time": "Mon, Apr 8, 7:39 AM",
                "value": 54.4900016784668
            },
            {
                "time": "Mon, Apr 8, 7:39 AM",
                "value": 54.529998779296875
            },
            {
                "time": "Mon, Apr 8, 7:40 AM",
                "value": 54.529998779296875
            },
            {
                "time": "Mon, Apr 8, 7:40 AM",
                "value": 54.529998779296875
            },
            {
                "time": "Mon, Apr 8, 7:41 AM",
                "value": 54.529998779296875
            },
            {
                "time": "Mon, Apr 8, 7:41 AM",
                "value": 54.529998779296875
            },
            {
                "time": "Mon, Apr 8, 7:42 AM",
                "value": 54.529998779296875
            },
            {
                "time": "Mon, Apr 8, 7:42 AM",
                "value": 54.529998779296875
            },
            {
                "time": "Mon, Apr 8, 7:43 AM",
                "value": 54.529998779296875
            },
            {
                "time": "Mon, Apr 8, 7:43 AM",
                "value": 54.529998779296875
            },
            {
                "time": "Mon, Apr 8, 7:44 AM",
                "value": 54.560001373291016
            },
            {
                "time": "Mon, Apr 8, 7:44 AM",
                "value": 54.599998474121094
            },
            {
                "time": "Mon, Apr 8, 7:45 AM",
                "value": 54.63999938964844
            },
            {
                "time": "Mon, Apr 8, 7:45 AM",
                "value": 54.63999938964844
            },
            {
                "time": "Mon, Apr 8, 7:46 AM",
                "value": 54.66999816894531
            },
            {
                "time": "Mon, Apr 8, 7:46 AM",
                "value": 54.709999084472656
            },
            {
                "time": "Mon, Apr 8, 7:47 AM",
                "value": 54.709999084472656
            },
            {
                "time": "Mon, Apr 8, 7:47 AM",
                "value": 54.75
            },
            {
                "time": "Mon, Apr 8, 7:48 AM",
                "value": 54.779998779296875
            },
            {
                "time": "Mon, Apr 8, 7:48 AM",
                "value": 54.81999969482422
            },
            {
                "time": "Mon, Apr 8, 7:49 AM",
                "value": 54.86000061035156
            },
            {
                "time": "Mon, Apr 8, 7:49 AM",
                "value": 54.88999938964844
            },
            {
                "time": "Mon, Apr 8, 7:50 AM",
                "value": 54.88999938964844
            },
            {
                "time": "Mon, Apr 8, 7:50 AM",
                "value": 54.93000030517578
            },
            {
                "time": "Mon, Apr 8, 7:51 AM",
                "value": 54.959999084472656
            },
            {
                "time": "Mon, Apr 8, 7:51 AM",
                "value": 54.959999084472656
            },
            {
                "time": "Mon, Apr 8, 7:52 AM",
                "value": 55
            },
            {
                "time": "Mon, Apr 8, 7:52 AM",
                "value": 55.029998779296875
            },
            {
                "time": "Mon, Apr 8, 7:53 AM",
                "value": 55.150001525878906
            },
            {
                "time": "Mon, Apr 8, 7:53 AM",
                "value": 55.11000061035156
            },
            {
                "time": "Mon, Apr 8, 7:54 AM",
                "value": 55.18000030517578
            },
            {
                "time": "Mon, Apr 8, 7:54 AM",
                "value": 55.220001220703125
            },
            {
                "time": "Mon, Apr 8, 7:55 AM",
                "value": 55.209999084472656
            },
            {
                "time": "Mon, Apr 8, 7:55 AM",
                "value": 55.25
            },
            {
                "time": "Mon, Apr 8, 7:56 AM",
                "value": 55.25
            },
            {
                "time": "Mon, Apr 8, 7:56 AM",
                "value": 55.290000915527344
            },
            {
                "time": "Mon, Apr 8, 7:57 AM",
                "value": 55.349998474121094
            },
            {
                "time": "Mon, Apr 8, 7:57 AM",
                "value": 55.31999969482422
            },
            {
                "time": "Mon, Apr 8, 7:58 AM",
                "value": 55.31999969482422
            },
            {
                "time": "Mon, Apr 8, 7:58 AM",
                "value": 55.36000061035156
            },
            {
                "time": "Mon, Apr 8, 7:59 AM",
                "value": 55.36000061035156
            },
            {
                "time": "Mon, Apr 8, 7:59 AM",
                "value": 55.36000061035156
            },
            {
                "time": "Mon, Apr 8, 8:00 AM",
                "value": 55.36000061035156
            },
            {
                "time": "Mon, Apr 8, 8:00 AM",
                "value": 55.400001525878906
            },
            {
                "time": "Mon, Apr 8, 8:01 AM",
                "value": 55.400001525878906
            },
            {
                "time": "Mon, Apr 8, 8:01 AM",
                "value": 55.400001525878906
            },
            {
                "time": "Mon, Apr 8, 8:02 AM",
                "value": 55.400001525878906
            },
            {
                "time": "Mon, Apr 8, 8:02 AM",
                "value": 55.400001525878906
            },
            {
                "time": "Mon, Apr 8, 8:03 AM",
                "value": 55.400001525878906
            },
            {
                "time": "Mon, Apr 8, 8:03 AM",
                "value": 55.43000030517578
            },
            {
                "time": "Mon, Apr 8, 8:04 AM",
                "value": 55.43000030517578
            },
            {
                "time": "Mon, Apr 8, 8:04 AM",
                "value": 55.43000030517578
            },
            {
                "time": "Mon, Apr 8, 8:05 AM",
                "value": 55.5099983215332
            },
            {
                "time": "Mon, Apr 8, 8:05 AM",
                "value": 55.540000915527344
            },
            {
                "time": "Mon, Apr 8, 8:06 AM",
                "value": 55.58000183105469
            },
            {
                "time": "Mon, Apr 8, 8:06 AM",
                "value": 55.650001525878906
            },
            {
                "time": "Mon, Apr 8, 8:07 AM",
                "value": 55.720001220703125
            },
            {
                "time": "Mon, Apr 8, 8:07 AM",
                "value": 55.790000915527344
            },
            {
                "time": "Mon, Apr 8, 8:08 AM",
                "value": 55.83000183105469
            },
            {
                "time": "Mon, Apr 8, 8:08 AM",
                "value": 55.900001525878906
            },
            {
                "time": "Mon, Apr 8, 8:09 AM",
                "value": 56.11000061035156
            },
            {
                "time": "Mon, Apr 8, 8:09 AM",
                "value": 56.119998931884766
            },
            {
                "time": "Mon, Apr 8, 8:10 AM",
                "value": 56.22999954223633
            },
            {
                "time": "Mon, Apr 8, 8:10 AM",
                "value": 56.34000015258789
            },
            {
                "time": "Mon, Apr 8, 8:11 AM",
                "value": 56.40999984741211
            },
            {
                "time": "Mon, Apr 8, 8:11 AM",
                "value": 56.52000045776367
            },
            {
                "time": "Mon, Apr 8, 8:12 AM",
                "value": 56.630001068115234
            },
            {
                "time": "Mon, Apr 8, 8:12 AM",
                "value": 56.72999954223633
            },
            {
                "time": "Mon, Apr 8, 8:13 AM",
                "value": 56.86000061035156
            },
            {
                "time": "Mon, Apr 8, 8:13 AM",
                "value": 56.95000076293945
            },
            {
                "time": "Mon, Apr 8, 8:14 AM",
                "value": 57.02000045776367
            },
            {
                "time": "Mon, Apr 8, 8:14 AM",
                "value": 57.09000015258789
            },
            {
                "time": "Mon, Apr 8, 8:15 AM",
                "value": 57.20000076293945
            },
            {
                "time": "Mon, Apr 8, 8:15 AM",
                "value": 57.27000045776367
            },
            {
                "time": "Mon, Apr 8, 8:16 AM",
                "value": 57.349998474121094
            },
            {
                "time": "Mon, Apr 8, 8:16 AM",
                "value": 57.470001220703125
            },
            {
                "time": "Mon, Apr 8, 8:17 AM",
                "value": 57.630001068115234
            },
            {
                "time": "Mon, Apr 8, 8:17 AM",
                "value": 57.66999816894531
            },
            {
                "time": "Mon, Apr 8, 8:18 AM",
                "value": 57.75
            },
            {
                "time": "Mon, Apr 8, 8:18 AM",
                "value": 57.81999969482422
            },
            {
                "time": "Mon, Apr 8, 8:19 AM",
                "value": 57.88999938964844
            },
            {
                "time": "Mon, Apr 8, 8:19 AM",
                "value": 57.959999084472656
            },
            {
                "time": "Mon, Apr 8, 8:20 AM",
                "value": 58.06999969482422
            },
            {
                "time": "Mon, Apr 8, 8:20 AM",
                "value": 58.11000061035156
            },
            {
                "time": "Mon, Apr 8, 8:21 AM",
                "value": 58.16999816894531
            },
            {
                "time": "Mon, Apr 8, 8:21 AM",
                "value": 58.18000030517578
            },
            {
                "time": "Mon, Apr 8, 8:22 AM",
                "value": 58.220001220703125
            },
            {
                "time": "Mon, Apr 8, 8:22 AM",
                "value": 58.25
            },
            {
                "time": "Mon, Apr 8, 8:23 AM",
                "value": 58.290000915527344
            },
            {
                "time": "Mon, Apr 8, 8:23 AM",
                "value": 58.31999969482422
            },
            {
                "time": "Mon, Apr 8, 8:24 AM",
                "value": 58.36000061035156
            },
            {
                "time": "Mon, Apr 8, 8:24 AM",
                "value": 58.400001525878906
            },
            {
                "time": "Mon, Apr 8, 8:25 AM",
                "value": 58.470001220703125
            },
            {
                "time": "Mon, Apr 8, 8:25 AM",
                "value": 58.470001220703125
            },
            {
                "time": "Mon, Apr 8, 8:26 AM",
                "value": 58.540000915527344
            },
            {
                "time": "Mon, Apr 8, 8:26 AM",
                "value": 58.61000061035156
            },
            {
                "time": "Mon, Apr 8, 8:27 AM",
                "value": 58.689998626708984
            },
            {
                "time": "Mon, Apr 8, 8:27 AM",
                "value": 58.720001220703125
            },
            {
                "time": "Mon, Apr 8, 8:28 AM",
                "value": 58.7599983215332
            },
            {
                "time": "Mon, Apr 8, 8:28 AM",
                "value": 58.7599983215332
            },
            {
                "time": "Mon, Apr 8, 8:29 AM",
                "value": 58.7599983215332
            },
            {
                "time": "Mon, Apr 8, 8:29 AM",
                "value": 58.720001220703125
            },
            {
                "time": "Mon, Apr 8, 8:30 AM",
                "value": 58.720001220703125
            },
            {
                "time": "Mon, Apr 8, 8:30 AM",
                "value": 58.7599983215332
            },
            {
                "time": "Mon, Apr 8, 8:31 AM",
                "value": 58.7599983215332
            },
            {
                "time": "Mon, Apr 8, 8:31 AM",
                "value": 58.790000915527344
            },
            {
                "time": "Mon, Apr 8, 8:32 AM",
                "value": 58.83000183105469
            },
            {
                "time": "Mon, Apr 8, 8:32 AM",
                "value": 58.869998931884766
            },
            {
                "time": "Mon, Apr 8, 8:33 AM",
                "value": 58.939998626708984
            },
            {
                "time": "Mon, Apr 8, 8:33 AM",
                "value": 59.0099983215332
            },
            {
                "time": "Mon, Apr 8, 8:34 AM",
                "value": 59.08000183105469
            },
            {
                "time": "Mon, Apr 8, 8:34 AM",
                "value": 59.119998931884766
            },
            {
                "time": "Mon, Apr 8, 8:35 AM",
                "value": 59.15999984741211
            },
            {
                "time": "Mon, Apr 8, 8:35 AM",
                "value": 59.189998626708984
            },
            {
                "time": "Mon, Apr 8, 8:36 AM",
                "value": 59.220001220703125
            },
            {
                "time": "Mon, Apr 8, 8:36 AM",
                "value": 59.29999923706055
            },
            {
                "time": "Mon, Apr 8, 8:37 AM",
                "value": 59.2599983215332
            },
            {
                "time": "Mon, Apr 8, 8:37 AM",
                "value": 59.29999923706055
            },
            {
                "time": "Mon, Apr 8, 8:38 AM",
                "value": 59.34000015258789
            },
            {
                "time": "Mon, Apr 8, 8:38 AM",
                "value": 59.34000015258789
            },
            {
                "time": "Mon, Apr 8, 8:39 AM",
                "value": 59.34000015258789
            },
            {
                "time": "Mon, Apr 8, 8:39 AM",
                "value": 59.34000015258789
            },
            {
                "time": "Mon, Apr 8, 8:40 AM",
                "value": 59.29999923706055
            },
            {
                "time": "Mon, Apr 8, 8:40 AM",
                "value": 59.29999923706055
            },
            {
                "time": "Mon, Apr 8, 8:41 AM",
                "value": 59.2599983215332
            },
            {
                "time": "Mon, Apr 8, 8:41 AM",
                "value": 59.2599983215332
            },
            {
                "time": "Mon, Apr 8, 8:42 AM",
                "value": 59.27000045776367
            },
            {
                "time": "Mon, Apr 8, 8:42 AM",
                "value": 59.29999923706055
            },
            {
                "time": "Mon, Apr 8, 8:43 AM",
                "value": 59.34000015258789
            },
            {
                "time": "Mon, Apr 8, 8:43 AM",
                "value": 59.369998931884766
            },
            {
                "time": "Mon, Apr 8, 8:44 AM",
                "value": 59.369998931884766
            },
            {
                "time": "Mon, Apr 8, 8:44 AM",
                "value": 59.40999984741211
            },
            {
                "time": "Mon, Apr 8, 8:45 AM",
                "value": 59.439998626708984
            },
            {
                "time": "Mon, Apr 8, 8:45 AM",
                "value": 59.58000183105469
            },
            {
                "time": "Mon, Apr 8, 8:46 AM",
                "value": 59.65999984741211
            },
            {
                "time": "Mon, Apr 8, 8:46 AM",
                "value": 59.70000076293945
            },
            {
                "time": "Mon, Apr 8, 8:47 AM",
                "value": 59.72999954223633
            },
            {
                "time": "Mon, Apr 8, 8:47 AM",
                "value": 59.72999954223633
            },
            {
                "time": "Mon, Apr 8, 8:48 AM",
                "value": 59.77000045776367
            },
            {
                "time": "Mon, Apr 8, 8:48 AM",
                "value": 59.77000045776367
            },
            {
                "time": "Mon, Apr 8, 8:49 AM",
                "value": 59.77000045776367
            },
            {
                "time": "Mon, Apr 8, 8:49 AM",
                "value": 59.72999954223633
            },
            {
                "time": "Mon, Apr 8, 8:50 AM",
                "value": 59.630001068115234
            },
            {
                "time": "Mon, Apr 8, 8:50 AM",
                "value": 59.54999923706055
            },
            {
                "time": "Mon, Apr 8, 8:51 AM",
                "value": 59.47999954223633
            },
            {
                "time": "Mon, Apr 8, 8:51 AM",
                "value": 59.47999954223633
            },
            {
                "time": "Mon, Apr 8, 8:52 AM",
                "value": 59.45000076293945
            },
            {
                "time": "Mon, Apr 8, 8:52 AM",
                "value": 59.45000076293945
            },
            {
                "time": "Mon, Apr 8, 8:53 AM",
                "value": 59.40999984741211
            },
            {
                "time": "Mon, Apr 8, 8:53 AM",
                "value": 59.369998931884766
            },
            {
                "time": "Mon, Apr 8, 8:54 AM",
                "value": 59.310001373291016
            },
            {
                "time": "Mon, Apr 8, 8:54 AM",
                "value": 59.29999923706055
            },
            {
                "time": "Mon, Apr 8, 8:55 AM",
                "value": 59.22999954223633
            },
            {
                "time": "Mon, Apr 8, 8:55 AM",
                "value": 59.189998626708984
            },
            {
                "time": "Mon, Apr 8, 8:56 AM",
                "value": 59.15999984741211
            },
            {
                "time": "Mon, Apr 8, 8:56 AM",
                "value": 59.15999984741211
            },
            {
                "time": "Mon, Apr 8, 8:57 AM",
                "value": 59.189998626708984
            },
            {
                "time": "Mon, Apr 8, 8:57 AM",
                "value": 59.20000076293945
            },
            {
                "time": "Mon, Apr 8, 8:58 AM",
                "value": 59.189998626708984
            },
            {
                "time": "Mon, Apr 8, 8:58 AM",
                "value": 59.189998626708984
            },
            {
                "time": "Mon, Apr 8, 8:59 AM",
                "value": 59.189998626708984
            },
            {
                "time": "Mon, Apr 8, 8:59 AM",
                "value": 59.189998626708984
            },
            {
                "time": "Mon, Apr 8, 9:00 AM",
                "value": 59.189998626708984
            },
            {
                "time": "Mon, Apr 8, 9:00 AM",
                "value": 59.22999954223633
            },
            {
                "time": "Mon, Apr 8, 9:01 AM",
                "value": 59.27000045776367
            },
            {
                "time": "Mon, Apr 8, 9:01 AM",
                "value": 59.27000045776367
            },
            {
                "time": "Mon, Apr 8, 9:02 AM",
                "value": 59.29999923706055
            },
            {
                "time": "Mon, Apr 8, 9:02 AM",
                "value": 59.29999923706055
            },
            {
                "time": "Mon, Apr 8, 9:03 AM",
                "value": 59.34000015258789
            },
            {
                "time": "Mon, Apr 8, 9:03 AM",
                "value": 59.369998931884766
            },
            {
                "time": "Mon, Apr 8, 9:04 AM",
                "value": 59.47999954223633
            },
            {
                "time": "Mon, Apr 8, 9:04 AM",
                "value": 59.59000015258789
            },
            {
                "time": "Mon, Apr 8, 9:05 AM",
                "value": 59.65999984741211
            },
            {
                "time": "Mon, Apr 8, 9:05 AM",
                "value": 59.7400016784668
            },
            {
                "time": "Mon, Apr 8, 9:06 AM",
                "value": 59.810001373291016
            },
            {
                "time": "Mon, Apr 8, 9:06 AM",
                "value": 59.84000015258789
            },
            {
                "time": "Mon, Apr 8, 9:07 AM",
                "value": 59.880001068115234
            },
            {
                "time": "Mon, Apr 8, 9:07 AM",
                "value": 59.91999816894531
            },
            {
                "time": "Mon, Apr 8, 9:08 AM",
                "value": 59.970001220703125
            },
            {
                "time": "Mon, Apr 8, 9:08 AM",
                "value": 59.95000076293945
            },
            {
                "time": "Mon, Apr 8, 9:09 AM",
                "value": 59.91999816894531
            },
            {
                "time": "Mon, Apr 8, 9:09 AM",
                "value": 59.91999816894531
            },
            {
                "time": "Mon, Apr 8, 9:10 AM",
                "value": 59.880001068115234
            },
            {
                "time": "Mon, Apr 8, 9:10 AM",
                "value": 59.849998474121094
            },
            {
                "time": "Mon, Apr 8, 9:11 AM",
                "value": 59.849998474121094
            },
            {
                "time": "Mon, Apr 8, 9:11 AM",
                "value": 59.880001068115234
            },
            {
                "time": "Mon, Apr 8, 9:12 AM",
                "value": 60.029998779296875
            },
            {
                "time": "Mon, Apr 8, 9:12 AM",
                "value": 59.9900016784668
            },
            {
                "time": "Mon, Apr 8, 9:13 AM",
                "value": 60.029998779296875
            },
            {
                "time": "Mon, Apr 8, 9:13 AM",
                "value": 60.060001373291016
            },
            {
                "time": "Mon, Apr 8, 9:14 AM",
                "value": 60.09000015258789
            },
            {
                "time": "Mon, Apr 8, 9:14 AM",
                "value": 60.099998474121094
            },
            {
                "time": "Mon, Apr 8, 9:15 AM",
                "value": 60.099998474121094
            },
            {
                "time": "Mon, Apr 8, 9:15 AM",
                "value": 60.099998474121094
            },
            {
                "time": "Mon, Apr 8, 9:16 AM",
                "value": 60.099998474121094
            },
            {
                "time": "Mon, Apr 8, 9:16 AM",
                "value": 60.099998474121094
            },
            {
                "time": "Mon, Apr 8, 9:17 AM",
                "value": 60.130001068115234
            },
            {
                "time": "Mon, Apr 8, 9:17 AM",
                "value": 60.16999816894531
            },
            {
                "time": "Mon, Apr 8, 9:18 AM",
                "value": 60.209999084472656
            },
            {
                "time": "Mon, Apr 8, 9:18 AM",
                "value": 60.2400016784668
            },
            {
                "time": "Mon, Apr 8, 9:19 AM",
                "value": 60.310001373291016
            },
            {
                "time": "Mon, Apr 8, 9:19 AM",
                "value": 60.38999938964844
            },
            {
                "time": "Mon, Apr 8, 9:20 AM",
                "value": 60.5
            },
            {
                "time": "Mon, Apr 8, 9:20 AM",
                "value": 60.56999969482422
            },
            {
                "time": "Mon, Apr 8, 9:21 AM",
                "value": 60.709999084472656
            },
            {
                "time": "Mon, Apr 8, 9:21 AM",
                "value": 60.779998779296875
            },
            {
                "time": "Mon, Apr 8, 9:22 AM",
                "value": 60.900001525878906
            },
            {
                "time": "Mon, Apr 8, 9:22 AM",
                "value": 60.93000030517578
            },
            {
                "time": "Mon, Apr 8, 9:23 AM",
                "value": 60.959999084472656
            },
            {
                "time": "Mon, Apr 8, 9:23 AM",
                "value": 61
            },
            {
                "time": "Mon, Apr 8, 9:24 AM",
                "value": 61.06999969482422
            },
            {
                "time": "Mon, Apr 8, 9:24 AM",
                "value": 61.150001525878906
            },
            {
                "time": "Mon, Apr 8, 9:25 AM",
                "value": 61.18000030517578
            },
            {
                "time": "Mon, Apr 8, 9:25 AM",
                "value": 61.209999084472656
            },
            {
                "time": "Mon, Apr 8, 9:26 AM",
                "value": 61.220001220703125
            },
            {
                "time": "Mon, Apr 8, 9:26 AM",
                "value": 61.25
            },
            {
                "time": "Mon, Apr 8, 9:27 AM",
                "value": 61.25
            },
            {
                "time": "Mon, Apr 8, 9:27 AM",
                "value": 61.36000061035156
            },
            {
                "time": "Mon, Apr 8, 9:28 AM",
                "value": 61.33000183105469
            },
            {
                "time": "Mon, Apr 8, 9:28 AM",
                "value": 61.400001525878906
            },
            {
                "time": "Mon, Apr 8, 9:29 AM",
                "value": 61.5099983215332
            },
            {
                "time": "Mon, Apr 8, 9:29 AM",
                "value": 61.61000061035156
            },
            {
                "time": "Mon, Apr 8, 9:30 AM",
                "value": 61.68000030517578
            },
            {
                "time": "Mon, Apr 8, 9:30 AM",
                "value": 61.7599983215332
            },
            {
                "time": "Mon, Apr 8, 9:31 AM",
                "value": 61.79999923706055
            },
            {
                "time": "Mon, Apr 8, 9:31 AM",
                "value": 61.939998626708984
            },
            {
                "time": "Mon, Apr 8, 9:32 AM",
                "value": 61.939998626708984
            },
            {
                "time": "Mon, Apr 8, 9:32 AM",
                "value": 61.97999954223633
            },
            {
                "time": "Mon, Apr 8, 9:33 AM",
                "value": 62.0099983215332
            },
            {
                "time": "Mon, Apr 8, 9:33 AM",
                "value": 62.119998931884766
            },
            {
                "time": "Mon, Apr 8, 9:34 AM",
                "value": 62.20000076293945
            },
            {
                "time": "Mon, Apr 8, 9:34 AM",
                "value": 62.29999923706055
            },
            {
                "time": "Mon, Apr 8, 9:35 AM",
                "value": 62.41999816894531
            },
            {
                "time": "Mon, Apr 8, 9:35 AM",
                "value": 62.59000015258789
            },
            {
                "time": "Mon, Apr 8, 9:36 AM",
                "value": 62.560001373291016
            },
            {
                "time": "Mon, Apr 8, 9:36 AM",
                "value": 62.630001068115234
            },
            {
                "time": "Mon, Apr 8, 9:37 AM",
                "value": 62.65999984741211
            },
            {
                "time": "Mon, Apr 8, 9:37 AM",
                "value": 62.65999984741211
            },
            {
                "time": "Mon, Apr 8, 9:38 AM",
                "value": 62.65999984741211
            },
            {
                "time": "Mon, Apr 8, 9:38 AM",
                "value": 62.65999984741211
            },
            {
                "time": "Mon, Apr 8, 9:39 AM",
                "value": 62.70000076293945
            },
            {
                "time": "Mon, Apr 8, 9:39 AM",
                "value": 62.70000076293945
            },
            {
                "time": "Mon, Apr 8, 9:40 AM",
                "value": 62.77000045776367
            },
            {
                "time": "Mon, Apr 8, 9:40 AM",
                "value": 62.90999984741211
            },
            {
                "time": "Mon, Apr 8, 9:41 AM",
                "value": 63.02000045776367
            },
            {
                "time": "Mon, Apr 8, 9:41 AM",
                "value": 63.099998474121094
            },
            {
                "time": "Mon, Apr 8, 9:42 AM",
                "value": 63.16999816894531
            },
            {
                "time": "Mon, Apr 8, 9:42 AM",
                "value": 63.16999816894531
            },
            {
                "time": "Mon, Apr 8, 9:43 AM",
                "value": 63.16999816894531
            },
            {
                "time": "Mon, Apr 8, 9:43 AM",
                "value": 63.20000076293945
            },
            {
                "time": "Mon, Apr 8, 9:44 AM",
                "value": 63.2400016784668
            },
            {
                "time": "Mon, Apr 8, 9:44 AM",
                "value": 63.349998474121094
            },
            {
                "time": "Mon, Apr 8, 9:45 AM",
                "value": 63.5
            },
            {
                "time": "Mon, Apr 8, 9:45 AM",
                "value": 63.599998474121094
            },
            {
                "time": "Mon, Apr 8, 9:46 AM",
                "value": 63.66999816894531
            },
            {
                "time": "Mon, Apr 8, 9:46 AM",
                "value": 63.709999084472656
            },
            {
                "time": "Mon, Apr 8, 9:47 AM",
                "value": 63.709999084472656
            },
            {
                "time": "Mon, Apr 8, 9:47 AM",
                "value": 63.68000030517578
            },
            {
                "time": "Mon, Apr 8, 9:48 AM",
                "value": 63.68000030517578
            },
            {
                "time": "Mon, Apr 8, 9:48 AM",
                "value": 63.599998474121094
            },
            {
                "time": "Mon, Apr 8, 9:49 AM",
                "value": 63.56999969482422
            },
            {
                "time": "Mon, Apr 8, 9:49 AM",
                "value": 63.529998779296875
            },
            {
                "time": "Mon, Apr 8, 9:50 AM",
                "value": 63.459999084472656
            },
            {
                "time": "Mon, Apr 8, 9:50 AM",
                "value": 63.41999816894531
            },
            {
                "time": "Mon, Apr 8, 9:51 AM",
                "value": 63.349998474121094
            },
            {
                "time": "Mon, Apr 8, 9:51 AM",
                "value": 63.349998474121094
            },
            {
                "time": "Mon, Apr 8, 9:52 AM",
                "value": 63.34000015258789
            },
            {
                "time": "Mon, Apr 8, 9:52 AM",
                "value": 63.38999938964844
            },
            {
                "time": "Mon, Apr 8, 9:53 AM",
                "value": 63.41999816894531
            },
            {
                "time": "Mon, Apr 8, 9:53 AM",
                "value": 63.41999816894531
            },
            {
                "time": "Mon, Apr 8, 9:54 AM",
                "value": 63.41999816894531
            },
            {
                "time": "Mon, Apr 8, 9:54 AM",
                "value": 63.459999084472656
            },
            {
                "time": "Mon, Apr 8, 9:55 AM",
                "value": 63.459999084472656
            },
            {
                "time": "Mon, Apr 8, 9:55 AM",
                "value": 63.459999084472656
            },
            {
                "time": "Mon, Apr 8, 9:56 AM",
                "value": 63.459999084472656
            },
            {
                "time": "Mon, Apr 8, 9:56 AM",
                "value": 63.41999816894531
            },
            {
                "time": "Mon, Apr 8, 9:57 AM",
                "value": 63.349998474121094
            },
            {
                "time": "Mon, Apr 8, 9:57 AM",
                "value": 63.279998779296875
            },
            {
                "time": "Mon, Apr 8, 9:58 AM",
                "value": 63.279998779296875
            },
            {
                "time": "Mon, Apr 8, 9:58 AM",
                "value": 63.310001373291016
            },
            {
                "time": "Mon, Apr 8, 9:59 AM",
                "value": 63.310001373291016
            },
            {
                "time": "Mon, Apr 8, 9:59 AM",
                "value": 63.459999084472656
            },
            {
                "time": "Mon, Apr 8, 10:00 AM",
                "value": 63.380001068115234
            },
            {
                "time": "Mon, Apr 8, 10:00 AM",
                "value": 63.41999816894531
            },
            {
                "time": "Mon, Apr 8, 10:01 AM",
                "value": 63.459999084472656
            },
            {
                "time": "Mon, Apr 8, 10:01 AM",
                "value": 63.459999084472656
            },
            {
                "time": "Mon, Apr 8, 10:02 AM",
                "value": 63.459999084472656
            },
            {
                "time": "Mon, Apr 8, 10:02 AM",
                "value": 63.459999084472656
            },
            {
                "time": "Mon, Apr 8, 10:03 AM",
                "value": 63.459999084472656
            },
            {
                "time": "Mon, Apr 8, 10:03 AM",
                "value": 63.52000045776367
            },
            {
                "time": "Mon, Apr 8, 10:04 AM",
                "value": 63.41999816894531
            },
            {
                "time": "Mon, Apr 8, 10:04 AM",
                "value": 63.41999816894531
            },
            {
                "time": "Mon, Apr 8, 10:05 AM",
                "value": 63.41999816894531
            },
            {
                "time": "Mon, Apr 8, 10:05 AM",
                "value": 63.459999084472656
            },
            {
                "time": "Mon, Apr 8, 10:06 AM",
                "value": 63.4900016784668
            },
            {
                "time": "Mon, Apr 8, 10:06 AM",
                "value": 63.5
            },
            {
                "time": "Mon, Apr 8, 10:07 AM",
                "value": 63.529998779296875
            },
            {
                "time": "Mon, Apr 8, 10:07 AM",
                "value": 63.59000015258789
            },
            {
                "time": "Mon, Apr 8, 10:08 AM",
                "value": 63.459999084472656
            },
            {
                "time": "Mon, Apr 8, 10:08 AM",
                "value": 63.41999816894531
            },
            {
                "time": "Mon, Apr 8, 10:09 AM",
                "value": 63.38999938964844
            },
            {
                "time": "Mon, Apr 8, 10:09 AM",
                "value": 63.349998474121094
            },
            {
                "time": "Mon, Apr 8, 10:10 AM",
                "value": 63.349998474121094
            },
            {
                "time": "Mon, Apr 8, 10:10 AM",
                "value": 63.310001373291016
            },
            {
                "time": "Mon, Apr 8, 10:11 AM",
                "value": 63.310001373291016
            },
            {
                "time": "Mon, Apr 8, 10:11 AM",
                "value": 63.41999816894531
            },
            {
                "time": "Mon, Apr 8, 10:12 AM",
                "value": 63.310001373291016
            },
            {
                "time": "Mon, Apr 8, 10:12 AM",
                "value": 63.279998779296875
            },
            {
                "time": "Mon, Apr 8, 10:13 AM",
                "value": 63.2400016784668
            },
            {
                "time": "Mon, Apr 8, 10:13 AM",
                "value": 63.16999816894531
            },
            {
                "time": "Mon, Apr 8, 10:14 AM",
                "value": 63.060001373291016
            },
            {
                "time": "Mon, Apr 8, 10:14 AM",
                "value": 62.93000030517578
            },
            {
                "time": "Mon, Apr 8, 10:15 AM",
                "value": 62.880001068115234
            },
            {
                "time": "Mon, Apr 8, 10:15 AM",
                "value": 62.95000076293945
            },
            {
                "time": "Mon, Apr 8, 10:16 AM",
                "value": 62.91999816894531
            },
            {
                "time": "Mon, Apr 8, 10:16 AM",
                "value": 62.9900016784668
            },
            {
                "time": "Mon, Apr 8, 10:17 AM",
                "value": 63.099998474121094
            },
            {
                "time": "Mon, Apr 8, 10:17 AM",
                "value": 63.16999816894531
            },
            {
                "time": "Mon, Apr 8, 10:18 AM",
                "value": 63.310001373291016
            },
            {
                "time": "Mon, Apr 8, 10:18 AM",
                "value": 63.41999816894531
            },
            {
                "time": "Mon, Apr 8, 10:19 AM",
                "value": 63.459999084472656
            },
            {
                "time": "Mon, Apr 8, 10:19 AM",
                "value": 63.529998779296875
            },
            {
                "time": "Mon, Apr 8, 10:20 AM",
                "value": 63.4900016784668
            },
            {
                "time": "Mon, Apr 8, 10:20 AM",
                "value": 63.4900016784668
            },
            {
                "time": "Mon, Apr 8, 10:21 AM",
                "value": 63.529998779296875
            },
            {
                "time": "Mon, Apr 8, 10:21 AM",
                "value": 63.56999969482422
            },
            {
                "time": "Mon, Apr 8, 10:22 AM",
                "value": 63.56999969482422
            },
            {
                "time": "Mon, Apr 8, 10:22 AM",
                "value": 63.59000015258789
            },
            {
                "time": "Mon, Apr 8, 10:23 AM",
                "value": 63.599998474121094
            },
            {
                "time": "Mon, Apr 8, 10:23 AM",
                "value": 63.599998474121094
            },
            {
                "time": "Mon, Apr 8, 10:24 AM",
                "value": 63.63999938964844
            },
            {
                "time": "Mon, Apr 8, 10:24 AM",
                "value": 63.68000030517578
            },
            {
                "time": "Mon, Apr 8, 10:25 AM",
                "value": 63.709999084472656
            },
            {
                "time": "Mon, Apr 8, 10:25 AM",
                "value": 63.790000915527344
            },
            {
                "time": "Mon, Apr 8, 10:26 AM",
                "value": 63.88999938964844
            },
            {
                "time": "Mon, Apr 8, 10:26 AM",
                "value": 64.04000091552734
            },
            {
                "time": "Mon, Apr 8, 10:27 AM",
                "value": 64.11000061035156
            },
            {
                "time": "Mon, Apr 8, 10:27 AM",
                "value": 64.18000030517578
            },
            {
                "time": "Mon, Apr 8, 10:28 AM",
                "value": 64.26000213623047
            },
            {
                "time": "Mon, Apr 8, 10:28 AM",
                "value": 64.4000015258789
            },
            {
                "time": "Mon, Apr 8, 10:29 AM",
                "value": 64.54000091552734
            },
            {
                "time": "Mon, Apr 8, 10:29 AM",
                "value": 64.69000244140625
            },
            {
                "time": "Mon, Apr 8, 10:30 AM",
                "value": 64.83000183105469
            },
            {
                "time": "Mon, Apr 8, 10:30 AM",
                "value": 64.9800033569336
            },
            {
                "time": "Mon, Apr 8, 10:31 AM",
                "value": 65.20999908447266
            },
            {
                "time": "Mon, Apr 8, 10:31 AM",
                "value": 65.19000244140625
            },
            {
                "time": "Mon, Apr 8, 10:32 AM",
                "value": 65.2699966430664
            },
            {
                "time": "Mon, Apr 8, 10:32 AM",
                "value": 65.41000366210938
            },
            {
                "time": "Mon, Apr 8, 10:33 AM",
                "value": 65.5199966430664
            },
            {
                "time": "Mon, Apr 8, 10:33 AM",
                "value": 65.58999633789062
            },
            {
                "time": "Mon, Apr 8, 10:34 AM",
                "value": 65.58999633789062
            },
            {
                "time": "Mon, Apr 8, 10:34 AM",
                "value": 65.62999725341797
            },
            {
                "time": "Mon, Apr 8, 10:35 AM",
                "value": 65.62999725341797
            },
            {
                "time": "Mon, Apr 8, 10:35 AM",
                "value": 65.62999725341797
            },
            {
                "time": "Mon, Apr 8, 10:36 AM",
                "value": 65.62000274658203
            },
            {
                "time": "Mon, Apr 8, 10:36 AM",
                "value": 65.62999725341797
            },
            {
                "time": "Mon, Apr 8, 10:37 AM",
                "value": 65.62999725341797
            },
            {
                "time": "Mon, Apr 8, 10:37 AM",
                "value": 65.4800033569336
            },
            {
                "time": "Mon, Apr 8, 10:38 AM",
                "value": 65.37000274658203
            },
            {
                "time": "Mon, Apr 8, 10:38 AM",
                "value": 65.37999725341797
            },
            {
                "time": "Mon, Apr 8, 10:39 AM",
                "value": 65.33999633789062
            },
            {
                "time": "Mon, Apr 8, 10:39 AM",
                "value": 65.33999633789062
            },
            {
                "time": "Mon, Apr 8, 10:40 AM",
                "value": 65.26000213623047
            },
            {
                "time": "Mon, Apr 8, 10:40 AM",
                "value": 65.08000183105469
            },
            {
                "time": "Mon, Apr 8, 10:41 AM",
                "value": 64.91000366210938
            },
            {
                "time": "Mon, Apr 8, 10:41 AM",
                "value": 64.79000091552734
            },
            {
                "time": "Mon, Apr 8, 10:42 AM",
                "value": 64.76000213623047
            },
            {
                "time": "Mon, Apr 8, 10:42 AM",
                "value": 64.88999938964844
            },
            {
                "time": "Mon, Apr 8, 10:43 AM",
                "value": 65.01000213623047
            },
            {
                "time": "Mon, Apr 8, 10:43 AM",
                "value": 65.16000366210938
            },
            {
                "time": "Mon, Apr 8, 10:44 AM",
                "value": 65.30000305175781
            },
            {
                "time": "Mon, Apr 8, 10:44 AM",
                "value": 65.41000366210938
            },
            {
                "time": "Mon, Apr 8, 10:45 AM",
                "value": 65.58000183105469
            },
            {
                "time": "Mon, Apr 8, 10:45 AM",
                "value": 65.6500015258789
            },
            {
                "time": "Mon, Apr 8, 10:46 AM",
                "value": 65.7699966430664
            },
            {
                "time": "Mon, Apr 8, 10:46 AM",
                "value": 65.95999908447266
            },
            {
                "time": "Mon, Apr 8, 10:47 AM",
                "value": 66.0999984741211
            },
            {
                "time": "Mon, Apr 8, 10:47 AM",
                "value": 66.16999816894531
            },
            {
                "time": "Mon, Apr 8, 10:48 AM",
                "value": 66.16999816894531
            },
            {
                "time": "Mon, Apr 8, 10:48 AM",
                "value": 66.0999984741211
            },
            {
                "time": "Mon, Apr 8, 10:49 AM",
                "value": 66.0999984741211
            },
            {
                "time": "Mon, Apr 8, 10:49 AM",
                "value": 66.0999984741211
            },
            {
                "time": "Mon, Apr 8, 10:50 AM",
                "value": 66.12999725341797
            },
            {
                "time": "Mon, Apr 8, 10:50 AM",
                "value": 66.25
            },
            {
                "time": "Mon, Apr 8, 10:51 AM",
                "value": 65.98999786376953
            },
            {
                "time": "Mon, Apr 8, 10:51 AM",
                "value": 65.94999694824219
            },
            {
                "time": "Mon, Apr 8, 10:52 AM",
                "value": 65.98999786376953
            },
            {
                "time": "Mon, Apr 8, 10:52 AM",
                "value": 66.08999633789062
            },
            {
                "time": "Mon, Apr 8, 10:53 AM",
                "value": 66.18000030517578
            },
            {
                "time": "Mon, Apr 8, 10:53 AM",
                "value": 66.23999786376953
            },
            {
                "time": "Mon, Apr 8, 10:54 AM",
                "value": 66.23999786376953
            },
            {
                "time": "Mon, Apr 8, 10:54 AM",
                "value": 66.37000274658203
            },
            {
                "time": "Mon, Apr 8, 10:55 AM",
                "value": 66.3499984741211
            },
            {
                "time": "Mon, Apr 8, 10:55 AM",
                "value": 66.30999755859375
            },
            {
                "time": "Mon, Apr 8, 10:56 AM",
                "value": 66.27999877929688
            },
            {
                "time": "Mon, Apr 8, 10:56 AM",
                "value": 66.23999786376953
            },
            {
                "time": "Mon, Apr 8, 10:57 AM",
                "value": 66.18000030517578
            },
            {
                "time": "Mon, Apr 8, 10:57 AM",
                "value": 65.91999816894531
            },
            {
                "time": "Mon, Apr 8, 10:58 AM",
                "value": 65.7699966430664
            },
            {
                "time": "Mon, Apr 8, 10:58 AM",
                "value": 65.7699966430664
            },
            {
                "time": "Mon, Apr 8, 10:59 AM",
                "value": 65.69999694824219
            },
            {
                "time": "Mon, Apr 8, 10:59 AM",
                "value": 65.80999755859375
            },
            {
                "time": "Mon, Apr 8, 11:00 AM",
                "value": 65.98999786376953
            },
            {
                "time": "Mon, Apr 8, 11:00 AM",
                "value": 66.13999938964844
            },
            {
                "time": "Mon, Apr 8, 11:01 AM",
                "value": 66.30999755859375
            },
            {
                "time": "Mon, Apr 8, 11:01 AM",
                "value": 66.45999908447266
            },
            {
                "time": "Mon, Apr 8, 11:02 AM",
                "value": 66.52999877929688
            },
            {
                "time": "Mon, Apr 8, 11:02 AM",
                "value": 66.56999969482422
            },
            {
                "time": "Mon, Apr 8, 11:03 AM",
                "value": 66.5999984741211
            },
            {
                "time": "Mon, Apr 8, 11:03 AM",
                "value": 66.5999984741211
            },
            {
                "time": "Mon, Apr 8, 11:04 AM",
                "value": 66.45999908447266
            },
            {
                "time": "Mon, Apr 8, 11:04 AM",
                "value": 66.45999908447266
            },
            {
                "time": "Mon, Apr 8, 11:05 AM",
                "value": 66.52999877929688
            },
            {
                "time": "Mon, Apr 8, 11:05 AM",
                "value": 66.5999984741211
            },
            {
                "time": "Mon, Apr 8, 11:06 AM",
                "value": 66.63999938964844
            },
            {
                "time": "Mon, Apr 8, 11:06 AM",
                "value": 66.75
            },
            {
                "time": "Mon, Apr 8, 11:07 AM",
                "value": 66.81999969482422
            },
            {
                "time": "Mon, Apr 8, 11:07 AM",
                "value": 66.88999938964844
            },
            {
                "time": "Mon, Apr 8, 11:08 AM",
                "value": 66.93000030517578
            },
            {
                "time": "Mon, Apr 8, 11:08 AM",
                "value": 66.93000030517578
            },
            {
                "time": "Mon, Apr 8, 11:09 AM",
                "value": 66.88999938964844
            },
            {
                "time": "Mon, Apr 8, 11:09 AM",
                "value": 66.93000030517578
            },
            {
                "time": "Mon, Apr 8, 11:10 AM",
                "value": 66.97000122070312
            },
            {
                "time": "Mon, Apr 8, 11:10 AM",
                "value": 67.04000091552734
            },
            {
                "time": "Mon, Apr 8, 11:11 AM",
                "value": 67.1500015258789
            },
            {
                "time": "Mon, Apr 8, 11:11 AM",
                "value": 67.25
            },
            {
                "time": "Mon, Apr 8, 11:12 AM",
                "value": 67.33000183105469
            },
            {
                "time": "Mon, Apr 8, 11:12 AM",
                "value": 67.33000183105469
            },
            {
                "time": "Mon, Apr 8, 11:13 AM",
                "value": 67.31999969482422
            },
            {
                "time": "Mon, Apr 8, 11:13 AM",
                "value": 67.18000030517578
            },
            {
                "time": "Mon, Apr 8, 11:14 AM",
                "value": 67
            },
            {
                "time": "Mon, Apr 8, 11:14 AM",
                "value": 66.88999938964844
            },
            {
                "time": "Mon, Apr 8, 11:15 AM",
                "value": 66.88999938964844
            },
            {
                "time": "Mon, Apr 8, 11:15 AM",
                "value": 66.88999938964844
            },
            {
                "time": "Mon, Apr 8, 11:16 AM",
                "value": 66.93000030517578
            },
            {
                "time": "Mon, Apr 8, 11:16 AM",
                "value": 67.11000061035156
            },
            {
                "time": "Mon, Apr 8, 11:17 AM",
                "value": 67.29000091552734
            },
            {
                "time": "Mon, Apr 8, 11:17 AM",
                "value": 67.47000122070312
            },
            {
                "time": "Mon, Apr 8, 11:18 AM",
                "value": 67.58000183105469
            },
            {
                "time": "Mon, Apr 8, 11:18 AM",
                "value": 67.69999694824219
            },
            {
                "time": "Mon, Apr 8, 11:19 AM",
                "value": 67.83000183105469
            },
            {
                "time": "Mon, Apr 8, 11:19 AM",
                "value": 67.94000244140625
            },
            {
                "time": "Mon, Apr 8, 11:20 AM",
                "value": 67.94000244140625
            },
            {
                "time": "Mon, Apr 8, 11:20 AM",
                "value": 67.94000244140625
            },
            {
                "time": "Mon, Apr 8, 11:21 AM",
                "value": 67.87000274658203
            },
            {
                "time": "Mon, Apr 8, 11:21 AM",
                "value": 67.80000305175781
            },
            {
                "time": "Mon, Apr 8, 11:22 AM",
                "value": 67.76000213623047
            },
            {
                "time": "Mon, Apr 8, 11:22 AM",
                "value": 67.80000305175781
            },
            {
                "time": "Mon, Apr 8, 11:23 AM",
                "value": 67.83000183105469
            },
            {
                "time": "Mon, Apr 8, 11:23 AM",
                "value": 67.87000274658203
            },
            {
                "time": "Mon, Apr 8, 11:24 AM",
                "value": 67.9000015258789
            },
            {
                "time": "Mon, Apr 8, 11:24 AM",
                "value": 67.94000244140625
            },
            {
                "time": "Mon, Apr 8, 11:25 AM",
                "value": 68.05000305175781
            },
            {
                "time": "Mon, Apr 8, 11:25 AM",
                "value": 68.08999633789062
            },
            {
                "time": "Mon, Apr 8, 11:26 AM",
                "value": 68.12000274658203
            },
            {
                "time": "Mon, Apr 8, 11:26 AM",
                "value": 68.19000244140625
            },
            {
                "time": "Mon, Apr 8, 11:27 AM",
                "value": 68.26000213623047
            },
            {
                "time": "Mon, Apr 8, 11:27 AM",
                "value": 68.26000213623047
            },
            {
                "time": "Mon, Apr 8, 11:28 AM",
                "value": 68.02999877929688
            },
            {
                "time": "Mon, Apr 8, 11:28 AM",
                "value": 67.69000244140625
            },
            {
                "time": "Mon, Apr 8, 11:29 AM",
                "value": 67.5199966430664
            },
            {
                "time": "Mon, Apr 8, 11:29 AM",
                "value": 67.47000122070312
            },
            {
                "time": "Mon, Apr 8, 11:30 AM",
                "value": 67.5199966430664
            },
            {
                "time": "Mon, Apr 8, 11:30 AM",
                "value": 67.54000091552734
            },
            {
                "time": "Mon, Apr 8, 11:31 AM",
                "value": 67.72000122070312
            },
            {
                "time": "Mon, Apr 8, 11:31 AM",
                "value": 67.9000015258789
            },
            {
                "time": "Mon, Apr 8, 11:32 AM",
                "value": 68.05000305175781
            },
            {
                "time": "Mon, Apr 8, 11:32 AM",
                "value": 68.05000305175781
            },
            {
                "time": "Mon, Apr 8, 11:33 AM",
                "value": 67.72000122070312
            },
            {
                "time": "Mon, Apr 8, 11:33 AM",
                "value": 67.36000061035156
            },
            {
                "time": "Mon, Apr 8, 11:34 AM",
                "value": 67.18000030517578
            },
            {
                "time": "Mon, Apr 8, 11:34 AM",
                "value": 66.86000061035156
            },
            {
                "time": "Mon, Apr 8, 11:35 AM",
                "value": 66.75
            },
            {
                "time": "Mon, Apr 8, 11:35 AM",
                "value": 66.72000122070312
            },
            {
                "time": "Mon, Apr 8, 11:36 AM",
                "value": 66.70999908447266
            },
            {
                "time": "Mon, Apr 8, 11:36 AM",
                "value": 66.70999908447266
            },
            {
                "time": "Mon, Apr 8, 11:37 AM",
                "value": 66.91000366210938
            },
            {
                "time": "Mon, Apr 8, 11:37 AM",
                "value": 67.08000183105469
            },
            {
                "time": "Mon, Apr 8, 11:38 AM",
                "value": 67.26000213623047
            },
            {
                "time": "Mon, Apr 8, 11:38 AM",
                "value": 67.29000091552734
            },
            {
                "time": "Mon, Apr 8, 11:39 AM",
                "value": 67.4000015258789
            },
            {
                "time": "Mon, Apr 8, 11:39 AM",
                "value": 67.54000091552734
            },
            {
                "time": "Mon, Apr 8, 11:40 AM",
                "value": 67.6500015258789
            },
            {
                "time": "Mon, Apr 8, 11:40 AM",
                "value": 67.79000091552734
            },
            {
                "time": "Mon, Apr 8, 11:41 AM",
                "value": 67.91000366210938
            },
            {
                "time": "Mon, Apr 8, 11:41 AM",
                "value": 68.05000305175781
            },
            {
                "time": "Mon, Apr 8, 11:42 AM",
                "value": 68.12000274658203
            },
            {
                "time": "Mon, Apr 8, 11:42 AM",
                "value": 68.30000305175781
            },
            {
                "time": "Mon, Apr 8, 11:43 AM",
                "value": 68.5199966430664
            },
            {
                "time": "Mon, Apr 8, 11:43 AM",
                "value": 68.69999694824219
            },
            {
                "time": "Mon, Apr 8, 11:44 AM",
                "value": 68.7699966430664
            },
            {
                "time": "Mon, Apr 8, 11:44 AM",
                "value": 68.83999633789062
            },
            {
                "time": "Mon, Apr 8, 11:45 AM",
                "value": 68.94999694824219
            },
            {
                "time": "Mon, Apr 8, 11:45 AM",
                "value": 69.05999755859375
            },
            {
                "time": "Mon, Apr 8, 11:46 AM",
                "value": 69.0999984741211
            },
            {
                "time": "Mon, Apr 8, 11:46 AM",
                "value": 69.12999725341797
            },
            {
                "time": "Mon, Apr 8, 11:47 AM",
                "value": 69.31999969482422
            },
            {
                "time": "Mon, Apr 8, 11:47 AM",
                "value": 69.45999908447266
            },
            {
                "time": "Mon, Apr 8, 11:48 AM",
                "value": 69.5999984741211
            },
            {
                "time": "Mon, Apr 8, 11:48 AM",
                "value": 69.75
            },
            {
                "time": "Mon, Apr 8, 11:49 AM",
                "value": 69.81999969482422
            },
            {
                "time": "Mon, Apr 8, 11:49 AM",
                "value": 69.7699966430664
            },
            {
                "time": "Mon, Apr 8, 11:50 AM",
                "value": 69.5999984741211
            },
            {
                "time": "Mon, Apr 8, 11:50 AM",
                "value": 69.56999969482422
            },
            {
                "time": "Mon, Apr 8, 11:51 AM",
                "value": 69.38999938964844
            },
            {
                "time": "Mon, Apr 8, 11:51 AM",
                "value": 69.16999816894531
            },
            {
                "time": "Mon, Apr 8, 11:52 AM",
                "value": 69.0999984741211
            },
            {
                "time": "Mon, Apr 8, 11:52 AM",
                "value": 69.0999984741211
            },
            {
                "time": "Mon, Apr 8, 11:53 AM",
                "value": 69.16999816894531
            },
            {
                "time": "Mon, Apr 8, 11:53 AM",
                "value": 69.3499984741211
            },
            {
                "time": "Mon, Apr 8, 11:54 AM",
                "value": 69.45999908447266
            },
            {
                "time": "Mon, Apr 8, 11:54 AM",
                "value": 69.5
            },
            {
                "time": "Mon, Apr 8, 11:55 AM",
                "value": 69.48999786376953
            },
            {
                "time": "Mon, Apr 8, 11:55 AM",
                "value": 69.41999816894531
            },
            {
                "time": "Mon, Apr 8, 11:56 AM",
                "value": 69.41999816894531
            },
            {
                "time": "Mon, Apr 8, 11:56 AM",
                "value": 69.38999938964844
            },
            {
                "time": "Mon, Apr 8, 11:57 AM",
                "value": 69.3499984741211
            },
            {
                "time": "Mon, Apr 8, 11:57 AM",
                "value": 69.3499984741211
            },
            {
                "time": "Mon, Apr 8, 11:58 AM",
                "value": 69.30999755859375
            },
            {
                "time": "Mon, Apr 8, 11:58 AM",
                "value": 69.20999908447266
            },
            {
                "time": "Mon, Apr 8, 11:59 AM",
                "value": 69.16999816894531
            },
            {
                "time": "Mon, Apr 8, 11:59 AM",
                "value": 69.2699966430664
            },
            {
                "time": "Mon, Apr 8, 12:00 PM",
                "value": 69.30999755859375
            },
            {
                "time": "Mon, Apr 8, 12:00 PM",
                "value": 69.38999938964844
            },
            {
                "time": "Mon, Apr 8, 12:01 PM",
                "value": 69.38999938964844
            },
            {
                "time": "Mon, Apr 8, 12:01 PM",
                "value": 69.38999938964844
            },
            {
                "time": "Mon, Apr 8, 12:02 PM",
                "value": 69.30999755859375
            },
            {
                "time": "Mon, Apr 8, 12:02 PM",
                "value": 69.16999816894531
            },
            {
                "time": "Mon, Apr 8, 12:03 PM",
                "value": 69.12999725341797
            },
            {
                "time": "Mon, Apr 8, 12:03 PM",
                "value": 69.12999725341797
            },
            {
                "time": "Mon, Apr 8, 12:04 PM",
                "value": 69.0999984741211
            },
            {
                "time": "Mon, Apr 8, 12:04 PM",
                "value": 69.0999984741211
            },
            {
                "time": "Mon, Apr 8, 12:05 PM",
                "value": 69.0999984741211
            },
            {
                "time": "Mon, Apr 8, 12:05 PM",
                "value": 69.0999984741211
            },
            {
                "time": "Mon, Apr 8, 12:06 PM",
                "value": 69.0999984741211
            },
            {
                "time": "Mon, Apr 8, 12:06 PM",
                "value": 69.0999984741211
            },
            {
                "time": "Mon, Apr 8, 12:07 PM",
                "value": 69.0199966430664
            },
            {
                "time": "Mon, Apr 8, 12:07 PM",
                "value": 68.91000366210938
            },
            {
                "time": "Mon, Apr 8, 12:08 PM",
                "value": 68.8499984741211
            },
            {
                "time": "Mon, Apr 8, 12:08 PM",
                "value": 68.73999786376953
            },
            {
                "time": "Mon, Apr 8, 12:09 PM",
                "value": 68.7300033569336
            },
            {
                "time": "Mon, Apr 8, 12:09 PM",
                "value": 68.83000183105469
            },
            {
                "time": "Mon, Apr 8, 12:10 PM",
                "value": 68.94999694824219
            },
            {
                "time": "Mon, Apr 8, 12:10 PM",
                "value": 69.11000061035156
            },
            {
                "time": "Mon, Apr 8, 12:11 PM",
                "value": 69.31999969482422
            },
            {
                "time": "Mon, Apr 8, 12:11 PM",
                "value": 69.45999908447266
            },
            {
                "time": "Mon, Apr 8, 12:12 PM",
                "value": 69.56999969482422
            },
            {
                "time": "Mon, Apr 8, 12:12 PM",
                "value": 69.70999908447266
            },
            {
                "time": "Mon, Apr 8, 12:13 PM",
                "value": 69.94000244140625
            },
            {
                "time": "Mon, Apr 8, 12:13 PM",
                "value": 70.06999969482422
            },
            {
                "time": "Mon, Apr 8, 12:14 PM",
                "value": 70.18000030517578
            },
            {
                "time": "Mon, Apr 8, 12:14 PM",
                "value": 70.33000183105469
            },
            {
                "time": "Mon, Apr 8, 12:15 PM",
                "value": 70.33000183105469
            },
            {
                "time": "Mon, Apr 8, 12:15 PM",
                "value": 70.25
            },
            {
                "time": "Mon, Apr 8, 12:16 PM",
                "value": 70.22000122070312
            },
            {
                "time": "Mon, Apr 8, 12:16 PM",
                "value": 70.26000213623047
            },
            {
                "time": "Mon, Apr 8, 12:17 PM",
                "value": 70.25
            },
            {
                "time": "Mon, Apr 8, 12:17 PM",
                "value": 70.25
            },
            {
                "time": "Mon, Apr 8, 12:18 PM",
                "value": 70.26000213623047
            },
            {
                "time": "Mon, Apr 8, 12:18 PM",
                "value": 70.22000122070312
            },
            {
                "time": "Mon, Apr 8, 12:19 PM",
                "value": 70.22000122070312
            },
            {
                "time": "Mon, Apr 8, 12:19 PM",
                "value": 70.22000122070312
            },
            {
                "time": "Mon, Apr 8, 12:20 PM",
                "value": 70.29000091552734
            },
            {
                "time": "Mon, Apr 8, 12:20 PM",
                "value": 70.47000122070312
            },
            {
                "time": "Mon, Apr 8, 12:21 PM",
                "value": 70.5999984741211
            },
            {
                "time": "Mon, Apr 8, 12:21 PM",
                "value": 70.7699966430664
            },
            {
                "time": "Mon, Apr 8, 12:22 PM",
                "value": 70.94000244140625
            },
            {
                "time": "Mon, Apr 8, 12:22 PM",
                "value": 71.05000305175781
            },
            {
                "time": "Mon, Apr 8, 12:23 PM",
                "value": 71.16000366210938
            },
            {
                "time": "Mon, Apr 8, 12:23 PM",
                "value": 71.2699966430664
            },
            {
                "time": "Mon, Apr 8, 12:24 PM",
                "value": 71.37999725341797
            },
            {
                "time": "Mon, Apr 8, 12:24 PM",
                "value": 71.41000366210938
            },
            {
                "time": "Mon, Apr 8, 12:25 PM",
                "value": 71.44999694824219
            },
            {
                "time": "Mon, Apr 8, 12:25 PM",
                "value": 71.44999694824219
            },
            {
                "time": "Mon, Apr 8, 12:26 PM",
                "value": 71.44999694824219
            },
            {
                "time": "Mon, Apr 8, 12:26 PM",
                "value": 71.4800033569336
            },
            {
                "time": "Mon, Apr 8, 12:27 PM",
                "value": 71.62999725341797
            },
            {
                "time": "Mon, Apr 8, 12:27 PM",
                "value": 71.80999755859375
            },
            {
                "time": "Mon, Apr 8, 12:28 PM",
                "value": 72.06999969482422
            },
            {
                "time": "Mon, Apr 8, 12:28 PM",
                "value": 72.05999755859375
            },
            {
                "time": "Mon, Apr 8, 12:29 PM",
                "value": 72.13999938964844
            },
            {
                "time": "Mon, Apr 8, 12:29 PM",
                "value": 72.16999816894531
            },
            {
                "time": "Mon, Apr 8, 12:30 PM",
                "value": 72.16999816894531
            },
            {
                "time": "Mon, Apr 8, 12:30 PM",
                "value": 71.83000183105469
            },
            {
                "time": "Mon, Apr 8, 12:31 PM",
                "value": 71.62999725341797
            },
            {
                "time": "Mon, Apr 8, 12:31 PM",
                "value": 71.30000305175781
            },
            {
                "time": "Mon, Apr 8, 12:32 PM",
                "value": 71.0199966430664
            },
            {
                "time": "Mon, Apr 8, 12:32 PM",
                "value": 70.76000213623047
            },
            {
                "time": "Mon, Apr 8, 12:33 PM",
                "value": 70.6500015258789
            },
            {
                "time": "Mon, Apr 8, 12:33 PM",
                "value": 70.51000213623047
            },
            {
                "time": "Mon, Apr 8, 12:34 PM",
                "value": 70.56999969482422
            },
            {
                "time": "Mon, Apr 8, 12:34 PM",
                "value": 70.61000061035156
            },
            {
                "time": "Mon, Apr 8, 12:35 PM",
                "value": 70.87000274658203
            },
            {
                "time": "Mon, Apr 8, 12:35 PM",
                "value": 71.05000305175781
            },
            {
                "time": "Mon, Apr 8, 12:36 PM",
                "value": 71.2300033569336
            },
            {
                "time": "Mon, Apr 8, 12:36 PM",
                "value": 71.2699966430664
            },
            {
                "time": "Mon, Apr 8, 12:37 PM",
                "value": 71.2300033569336
            },
            {
                "time": "Mon, Apr 8, 12:37 PM",
                "value": 71.19000244140625
            },
            {
                "time": "Mon, Apr 8, 12:38 PM",
                "value": 71.2300033569336
            },
            {
                "time": "Mon, Apr 8, 12:38 PM",
                "value": 71.30000305175781
            },
            {
                "time": "Mon, Apr 8, 12:39 PM",
                "value": 71.44999694824219
            },
            {
                "time": "Mon, Apr 8, 12:39 PM",
                "value": 71.55000305175781
            },
            {
                "time": "Mon, Apr 8, 12:40 PM",
                "value": 71.58999633789062
            },
            {
                "time": "Mon, Apr 8, 12:40 PM",
                "value": 71.58999633789062
            },
            {
                "time": "Mon, Apr 8, 12:41 PM",
                "value": 71.62999725341797
            },
            {
                "time": "Mon, Apr 8, 12:41 PM",
                "value": 71.44999694824219
            },
            {
                "time": "Mon, Apr 8, 12:42 PM",
                "value": 71.16000366210938
            },
            {
                "time": "Mon, Apr 8, 12:42 PM",
                "value": 71.0199966430664
            },
            {
                "time": "Mon, Apr 8, 12:43 PM",
                "value": 70.94000244140625
            },
            {
                "time": "Mon, Apr 8, 12:43 PM",
                "value": 70.87000274658203
            },
            {
                "time": "Mon, Apr 8, 12:44 PM",
                "value": 70.87000274658203
            },
            {
                "time": "Mon, Apr 8, 12:44 PM",
                "value": 70.94000244140625
            },
            {
                "time": "Mon, Apr 8, 12:45 PM",
                "value": 71.05000305175781
            },
            {
                "time": "Mon, Apr 8, 12:45 PM",
                "value": 71.1500015258789
            },
            {
                "time": "Mon, Apr 8, 12:46 PM",
                "value": 71.3499984741211
            },
            {
                "time": "Mon, Apr 8, 12:46 PM",
                "value": 71.44999694824219
            },
            {
                "time": "Mon, Apr 8, 12:47 PM",
                "value": 71.41000366210938
            },
            {
                "time": "Mon, Apr 8, 12:47 PM",
                "value": 71.41000366210938
            },
            {
                "time": "Mon, Apr 8, 12:48 PM",
                "value": 71.44999694824219
            },
            {
                "time": "Mon, Apr 8, 12:48 PM",
                "value": 71.55999755859375
            },
            {
                "time": "Mon, Apr 8, 12:49 PM",
                "value": 71.66000366210938
            },
            {
                "time": "Mon, Apr 8, 12:49 PM",
                "value": 71.8499984741211
            },
            {
                "time": "Mon, Apr 8, 12:50 PM",
                "value": 71.98999786376953
            },
            {
                "time": "Mon, Apr 8, 12:50 PM",
                "value": 72.05999755859375
            },
            {
                "time": "Mon, Apr 8, 12:51 PM",
                "value": 72.0999984741211
            },
            {
                "time": "Mon, Apr 8, 12:51 PM",
                "value": 72.05999755859375
            },
            {
                "time": "Mon, Apr 8, 12:52 PM",
                "value": 71.83999633789062
            },
            {
                "time": "Mon, Apr 8, 12:52 PM",
                "value": 71.66999816894531
            },
            {
                "time": "Mon, Apr 8, 12:53 PM",
                "value": 71.44999694824219
            },
            {
                "time": "Mon, Apr 8, 12:53 PM",
                "value": 71.2699966430664
            },
            {
                "time": "Mon, Apr 8, 12:54 PM",
                "value": 71.2300033569336
            },
            {
                "time": "Mon, Apr 8, 12:54 PM",
                "value": 71.30999755859375
            },
            {
                "time": "Mon, Apr 8, 12:55 PM",
                "value": 71.44999694824219
            },
            {
                "time": "Mon, Apr 8, 12:55 PM",
                "value": 71.66999816894531
            },
            {
                "time": "Mon, Apr 8, 12:56 PM",
                "value": 71.73999786376953
            },
            {
                "time": "Mon, Apr 8, 12:56 PM",
                "value": 71.8499984741211
            },
            {
                "time": "Mon, Apr 8, 12:57 PM",
                "value": 71.94999694824219
            },
            {
                "time": "Mon, Apr 8, 12:57 PM",
                "value": 72.06999969482422
            },
            {
                "time": "Mon, Apr 8, 12:58 PM",
                "value": 72.05999755859375
            },
            {
                "time": "Mon, Apr 8, 12:58 PM",
                "value": 72.0999984741211
            },
            {
                "time": "Mon, Apr 8, 12:59 PM",
                "value": 72.16999816894531
            },
            {
                "time": "Mon, Apr 8, 12:59 PM",
                "value": 72.3499984741211
            },
            {
                "time": "Mon, Apr 8, 1:00 PM",
                "value": 72.55999755859375
            },
            {
                "time": "Mon, Apr 8, 1:00 PM",
                "value": 72.72000122070312
            },
            {
                "time": "Mon, Apr 8, 1:01 PM",
                "value": 72.81999969482422
            },
            {
                "time": "Mon, Apr 8, 1:01 PM",
                "value": 72.86000061035156
            },
            {
                "time": "Mon, Apr 8, 1:02 PM",
                "value": 72.81999969482422
            },
            {
                "time": "Mon, Apr 8, 1:02 PM",
                "value": 72.86000061035156
            },
            {
                "time": "Mon, Apr 8, 1:03 PM",
                "value": 72.93000030517578
            },
            {
                "time": "Mon, Apr 8, 1:03 PM",
                "value": 72.97000122070312
            },
            {
                "time": "Mon, Apr 8, 1:04 PM",
                "value": 72.97000122070312
            },
            {
                "time": "Mon, Apr 8, 1:04 PM",
                "value": 72.9800033569336
            },
            {
                "time": "Mon, Apr 8, 1:05 PM",
                "value": 72.86000061035156
            },
            {
                "time": "Mon, Apr 8, 1:05 PM",
                "value": 72.68000030517578
            },
            {
                "time": "Mon, Apr 8, 1:06 PM",
                "value": 72.61000061035156
            },
            {
                "time": "Mon, Apr 8, 1:06 PM",
                "value": 72.5999984741211
            },
            {
                "time": "Mon, Apr 8, 1:07 PM",
                "value": 72.61000061035156
            },
            {
                "time": "Mon, Apr 8, 1:07 PM",
                "value": 72.6500015258789
            },
            {
                "time": "Mon, Apr 8, 1:08 PM",
                "value": 72.75
            },
            {
                "time": "Mon, Apr 8, 1:08 PM",
                "value": 72.86000061035156
            },
            {
                "time": "Mon, Apr 8, 1:09 PM",
                "value": 73.01000213623047
            },
            {
                "time": "Mon, Apr 8, 1:09 PM",
                "value": 73.11000061035156
            },
            {
                "time": "Mon, Apr 8, 1:10 PM",
                "value": 73.22000122070312
            },
            {
                "time": "Mon, Apr 8, 1:10 PM",
                "value": 73.37000274658203
            },
            {
                "time": "Mon, Apr 8, 1:11 PM",
                "value": 73.4800033569336
            },
            {
                "time": "Mon, Apr 8, 1:11 PM",
                "value": 73.58000183105469
            },
            {
                "time": "Mon, Apr 8, 1:12 PM",
                "value": 73.69000244140625
            },
            {
                "time": "Mon, Apr 8, 1:12 PM",
                "value": 73.75
            },
            {
                "time": "Mon, Apr 8, 1:13 PM",
                "value": 73.80000305175781
            },
            {
                "time": "Mon, Apr 8, 1:13 PM",
                "value": 73.83000183105469
            },
            {
                "time": "Mon, Apr 8, 1:14 PM",
                "value": 73.80000305175781
            },
            {
                "time": "Mon, Apr 8, 1:14 PM",
                "value": 73.83000183105469
            },
            {
                "time": "Mon, Apr 8, 1:15 PM",
                "value": 73.87000274658203
            },
            {
                "time": "Mon, Apr 8, 1:15 PM",
                "value": 73.91000366210938
            },
            {
                "time": "Mon, Apr 8, 1:16 PM",
                "value": 73.91000366210938
            },
            {
                "time": "Mon, Apr 8, 1:16 PM",
                "value": 73.69000244140625
            },
            {
                "time": "Mon, Apr 8, 1:17 PM",
                "value": 73.44000244140625
            },
            {
                "time": "Mon, Apr 8, 1:17 PM",
                "value": 73.22000122070312
            },
            {
                "time": "Mon, Apr 8, 1:18 PM",
                "value": 73.18000030517578
            },
            {
                "time": "Mon, Apr 8, 1:18 PM",
                "value": 73.22000122070312
            },
            {
                "time": "Mon, Apr 8, 1:19 PM",
                "value": 73.29000091552734
            },
            {
                "time": "Mon, Apr 8, 1:19 PM",
                "value": 73.4000015258789
            },
            {
                "time": "Mon, Apr 8, 1:20 PM",
                "value": 73.55000305175781
            },
            {
                "time": "Mon, Apr 8, 1:20 PM",
                "value": 73.66000366210938
            },
            {
                "time": "Mon, Apr 8, 1:21 PM",
                "value": 73.80000305175781
            },
            {
                "time": "Mon, Apr 8, 1:21 PM",
                "value": 73.91000366210938
            },
            {
                "time": "Mon, Apr 8, 1:22 PM",
                "value": 73.9800033569336
            },
            {
                "time": "Mon, Apr 8, 1:22 PM",
                "value": 74.0199966430664
            },
            {
                "time": "Mon, Apr 8, 1:23 PM",
                "value": 74.05000305175781
            },
            {
                "time": "Mon, Apr 8, 1:23 PM",
                "value": 74.12000274658203
            },
            {
                "time": "Mon, Apr 8, 1:24 PM",
                "value": 74.2300033569336
            },
            {
                "time": "Mon, Apr 8, 1:24 PM",
                "value": 74.2699966430664
            },
            {
                "time": "Mon, Apr 8, 1:25 PM",
                "value": 74.2699966430664
            },
            {
                "time": "Mon, Apr 8, 1:25 PM",
                "value": 74.19000244140625
            },
            {
                "time": "Mon, Apr 8, 1:26 PM",
                "value": 74.08000183105469
            },
            {
                "time": "Mon, Apr 8, 1:26 PM",
                "value": 73.94999694824219
            },
            {
                "time": "Mon, Apr 8, 1:27 PM",
                "value": 73.87000274658203
            },
            {
                "time": "Mon, Apr 8, 1:27 PM",
                "value": 73.87999725341797
            },
            {
                "time": "Mon, Apr 8, 1:28 PM",
                "value": 73.91000366210938
            },
            {
                "time": "Mon, Apr 8, 1:28 PM",
                "value": 73.91000366210938
            },
            {
                "time": "Mon, Apr 8, 1:29 PM",
                "value": 73.87000274658203
            },
            {
                "time": "Mon, Apr 8, 1:29 PM",
                "value": 73.91000366210938
            },
            {
                "time": "Mon, Apr 8, 1:30 PM",
                "value": 73.87000274658203
            },
            {
                "time": "Mon, Apr 8, 1:30 PM",
                "value": 73.80000305175781
            },
            {
                "time": "Mon, Apr 8, 1:31 PM",
                "value": 73.7300033569336
            },
            {
                "time": "Mon, Apr 8, 1:31 PM",
                "value": 73.69000244140625
            },
            {
                "time": "Mon, Apr 8, 1:32 PM",
                "value": 73.6500015258789
            },
            {
                "time": "Mon, Apr 8, 1:32 PM",
                "value": 73.66000366210938
            },
            {
                "time": "Mon, Apr 8, 1:33 PM",
                "value": 73.62000274658203
            },
            {
                "time": "Mon, Apr 8, 1:33 PM",
                "value": 73.66000366210938
            },
            {
                "time": "Mon, Apr 8, 1:34 PM",
                "value": 73.69000244140625
            },
            {
                "time": "Mon, Apr 8, 1:34 PM",
                "value": 73.76000213623047
            },
            {
                "time": "Mon, Apr 8, 1:35 PM",
                "value": 73.7300033569336
            },
            {
                "time": "Mon, Apr 8, 1:35 PM",
                "value": 73.61000061035156
            },
            {
                "time": "Mon, Apr 8, 1:36 PM",
                "value": 73.51000213623047
            },
            {
                "time": "Mon, Apr 8, 1:36 PM",
                "value": 73.4800033569336
            },
            {
                "time": "Mon, Apr 8, 1:37 PM",
                "value": 73.4800033569336
            },
            {
                "time": "Mon, Apr 8, 1:37 PM",
                "value": 73.51000213623047
            },
            {
                "time": "Mon, Apr 8, 1:38 PM",
                "value": 73.58000183105469
            },
            {
                "time": "Mon, Apr 8, 1:38 PM",
                "value": 73.66000366210938
            },
            {
                "time": "Mon, Apr 8, 1:39 PM",
                "value": 73.76000213623047
            },
            {
                "time": "Mon, Apr 8, 1:39 PM",
                "value": 73.80000305175781
            },
            {
                "time": "Mon, Apr 8, 1:40 PM",
                "value": 73.80000305175781
            },
            {
                "time": "Mon, Apr 8, 1:40 PM",
                "value": 73.76000213623047
            },
            {
                "time": "Mon, Apr 8, 1:41 PM",
                "value": 73.7300033569336
            },
            {
                "time": "Mon, Apr 8, 1:41 PM",
                "value": 73.76000213623047
            },
            {
                "time": "Mon, Apr 8, 1:42 PM",
                "value": 73.76000213623047
            },
            {
                "time": "Mon, Apr 8, 1:42 PM",
                "value": 73.83000183105469
            },
            {
                "time": "Mon, Apr 8, 1:43 PM",
                "value": 74.0199966430664
            },
            {
                "time": "Mon, Apr 8, 1:43 PM",
                "value": 74.08999633789062
            },
            {
                "time": "Mon, Apr 8, 1:44 PM",
                "value": 74.16000366210938
            },
            {
                "time": "Mon, Apr 8, 1:44 PM",
                "value": 74.2699966430664
            },
            {
                "time": "Mon, Apr 8, 1:45 PM",
                "value": 74.33999633789062
            },
            {
                "time": "Mon, Apr 8, 1:45 PM",
                "value": 74.44999694824219
            },
            {
                "time": "Mon, Apr 8, 1:46 PM",
                "value": 74.48999786376953
            },
            {
                "time": "Mon, Apr 8, 1:46 PM",
                "value": 74.55999755859375
            },
            {
                "time": "Mon, Apr 8, 1:47 PM",
                "value": 74.55999755859375
            },
            {
                "time": "Mon, Apr 8, 1:47 PM",
                "value": 74.5999984741211
            },
            {
                "time": "Mon, Apr 8, 1:48 PM",
                "value": 74.69999694824219
            },
            {
                "time": "Mon, Apr 8, 1:48 PM",
                "value": 74.91000366210938
            },
            {
                "time": "Mon, Apr 8, 1:49 PM",
                "value": 75.02999877929688
            },
            {
                "time": "Mon, Apr 8, 1:49 PM",
                "value": 75.0999984741211
            },
            {
                "time": "Mon, Apr 8, 1:50 PM",
                "value": 75.18000030517578
            },
            {
                "time": "Mon, Apr 8, 1:50 PM",
                "value": 75.11000061035156
            },
            {
                "time": "Mon, Apr 8, 1:51 PM",
                "value": 75.0999984741211
            },
            {
                "time": "Mon, Apr 8, 1:51 PM",
                "value": 75.0999984741211
            },
            {
                "time": "Mon, Apr 8, 1:52 PM",
                "value": 75.0999984741211
            },
            {
                "time": "Mon, Apr 8, 1:52 PM",
                "value": 75.13999938964844
            },
            {
                "time": "Mon, Apr 8, 1:53 PM",
                "value": 75.20999908447266
            },
            {
                "time": "Mon, Apr 8, 1:53 PM",
                "value": 75.20999908447266
            },
            {
                "time": "Mon, Apr 8, 1:54 PM",
                "value": 75.20999908447266
            },
            {
                "time": "Mon, Apr 8, 1:54 PM",
                "value": 75.16999816894531
            },
            {
                "time": "Mon, Apr 8, 1:55 PM",
                "value": 75.13999938964844
            },
            {
                "time": "Mon, Apr 8, 1:55 PM",
                "value": 75.13999938964844
            },
            {
                "time": "Mon, Apr 8, 1:56 PM",
                "value": 75.13999938964844
            },
            {
                "time": "Mon, Apr 8, 1:56 PM",
                "value": 75.13999938964844
            },
            {
                "time": "Mon, Apr 8, 1:57 PM",
                "value": 75.16999816894531
            },
            {
                "time": "Mon, Apr 8, 1:57 PM",
                "value": 75.20999908447266
            },
            {
                "time": "Mon, Apr 8, 1:58 PM",
                "value": 75.27999877929688
            },
            {
                "time": "Mon, Apr 8, 1:58 PM",
                "value": 75.43000030517578
            },
            {
                "time": "Mon, Apr 8, 1:59 PM",
                "value": 75.54000091552734
            },
            {
                "time": "Mon, Apr 8, 1:59 PM",
                "value": 75.54000091552734
            },
            {
                "time": "Mon, Apr 8, 2:00 PM",
                "value": 75.5
            },
            {
                "time": "Mon, Apr 8, 2:00 PM",
                "value": 75.16999816894531
            },
            {
                "time": "Mon, Apr 8, 2:01 PM",
                "value": 74.62999725341797
            },
            {
                "time": "Mon, Apr 8, 2:01 PM",
                "value": 74.16999816894531
            },
            {
                "time": "Mon, Apr 8, 2:02 PM",
                "value": 73.76000213623047
            },
            {
                "time": "Mon, Apr 8, 2:02 PM",
                "value": 73.58999633789062
            },
            {
                "time": "Mon, Apr 8, 2:03 PM",
                "value": 73.44000244140625
            },
            {
                "time": "Mon, Apr 8, 2:03 PM",
                "value": 73.41000366210938
            },
            {
                "time": "Mon, Apr 8, 2:04 PM",
                "value": 73.33000183105469
            },
            {
                "time": "Mon, Apr 8, 2:04 PM",
                "value": 73.29000091552734
            },
            {
                "time": "Mon, Apr 8, 2:05 PM",
                "value": 73.22000122070312
            },
            {
                "time": "Mon, Apr 8, 2:05 PM",
                "value": 73.1500015258789
            },
            {
                "time": "Mon, Apr 8, 2:06 PM",
                "value": 73.08000183105469
            },
            {
                "time": "Mon, Apr 8, 2:06 PM",
                "value": 73
            },
            {
                "time": "Mon, Apr 8, 2:07 PM",
                "value": 72.93000030517578
            },
            {
                "time": "Mon, Apr 8, 2:07 PM",
                "value": 72.86000061035156
            },
            {
                "time": "Mon, Apr 8, 2:08 PM",
                "value": 72.81999969482422
            },
            {
                "time": "Mon, Apr 8, 2:08 PM",
                "value": 72.80999755859375
            },
            {
                "time": "Mon, Apr 8, 2:09 PM",
                "value": 72.79000091552734
            },
            {
                "time": "Mon, Apr 8, 2:09 PM",
                "value": 72.9000015258789
            },
            {
                "time": "Mon, Apr 8, 2:10 PM",
                "value": 73
            },
            {
                "time": "Mon, Apr 8, 2:10 PM",
                "value": 73.08000183105469
            },
            {
                "time": "Mon, Apr 8, 2:11 PM",
                "value": 73.1500015258789
            },
            {
                "time": "Mon, Apr 8, 2:11 PM",
                "value": 73.33000183105469
            },
            {
                "time": "Mon, Apr 8, 2:12 PM",
                "value": 73.44000244140625
            },
            {
                "time": "Mon, Apr 8, 2:12 PM",
                "value": 73.58000183105469
            },
            {
                "time": "Mon, Apr 8, 2:13 PM",
                "value": 73.66000366210938
            },
            {
                "time": "Mon, Apr 8, 2:13 PM",
                "value": 73.68000030517578
            },
            {
                "time": "Mon, Apr 8, 2:14 PM",
                "value": 73.69000244140625
            },
            {
                "time": "Mon, Apr 8, 2:14 PM",
                "value": 73.73999786376953
            },
            {
                "time": "Mon, Apr 8, 2:15 PM",
                "value": 73.62000274658203
            },
            {
                "time": "Mon, Apr 8, 2:15 PM",
                "value": 73.58000183105469
            },
            {
                "time": "Mon, Apr 8, 2:16 PM",
                "value": 73.55000305175781
            },
            {
                "time": "Mon, Apr 8, 2:16 PM",
                "value": 73.4800033569336
            },
            {
                "time": "Mon, Apr 8, 2:17 PM",
                "value": 73.33000183105469
            },
            {
                "time": "Mon, Apr 8, 2:17 PM",
                "value": 73.1500015258789
            },
            {
                "time": "Mon, Apr 8, 2:18 PM",
                "value": 72.9000015258789
            },
            {
                "time": "Mon, Apr 8, 2:18 PM",
                "value": 72.6500015258789
            },
            {
                "time": "Mon, Apr 8, 2:19 PM",
                "value": 72.54000091552734
            },
            {
                "time": "Mon, Apr 8, 2:19 PM",
                "value": 72.54000091552734
            },
            {
                "time": "Mon, Apr 8, 2:20 PM",
                "value": 72.5
            },
            {
                "time": "Mon, Apr 8, 2:20 PM",
                "value": 72.5
            },
            {
                "time": "Mon, Apr 8, 2:21 PM",
                "value": 72.5
            },
            {
                "time": "Mon, Apr 8, 2:21 PM",
                "value": 72.54000091552734
            },
            {
                "time": "Mon, Apr 8, 2:22 PM",
                "value": 72.54000091552734
            },
            {
                "time": "Mon, Apr 8, 2:22 PM",
                "value": 72.54000091552734
            },
            {
                "time": "Mon, Apr 8, 2:23 PM",
                "value": 72.56999969482422
            },
            {
                "time": "Mon, Apr 8, 2:23 PM",
                "value": 72.61000061035156
            },
            {
                "time": "Mon, Apr 8, 2:24 PM",
                "value": 72.61000061035156
            },
            {
                "time": "Mon, Apr 8, 2:24 PM",
                "value": 72.61000061035156
            },
            {
                "time": "Mon, Apr 8, 2:25 PM",
                "value": 72.61000061035156
            },
            {
                "time": "Mon, Apr 8, 2:25 PM",
                "value": 72.61000061035156
            },
            {
                "time": "Mon, Apr 8, 2:26 PM",
                "value": 72.56999969482422
            },
            {
                "time": "Mon, Apr 8, 2:26 PM",
                "value": 72.54000091552734
            },
            {
                "time": "Mon, Apr 8, 2:27 PM",
                "value": 72.61000061035156
            },
            {
                "time": "Mon, Apr 8, 2:27 PM",
                "value": 72.52999877929688
            },
            {
                "time": "Mon, Apr 8, 2:28 PM",
                "value": 72.56999969482422
            },
            {
                "time": "Mon, Apr 8, 2:28 PM",
                "value": 72.5999984741211
            },
            {
                "time": "Mon, Apr 8, 2:29 PM",
                "value": 72.68000030517578
            },
            {
                "time": "Mon, Apr 8, 2:29 PM",
                "value": 72.72000122070312
            },
            {
                "time": "Mon, Apr 8, 2:30 PM",
                "value": 72.83000183105469
            },
            {
                "time": "Mon, Apr 8, 2:30 PM",
                "value": 72.70999908447266
            },
            {
                "time": "Mon, Apr 8, 2:31 PM",
                "value": 72.63999938964844
            },
            {
                "time": "Mon, Apr 8, 2:31 PM",
                "value": 72.61000061035156
            },
            {
                "time": "Mon, Apr 8, 2:32 PM",
                "value": 72.43000030517578
            },
            {
                "time": "Mon, Apr 8, 2:32 PM",
                "value": 72.27999877929688
            },
            {
                "time": "Mon, Apr 8, 2:33 PM",
                "value": 72.31999969482422
            },
            {
                "time": "Mon, Apr 8, 2:33 PM",
                "value": 72.16999816894531
            },
            {
                "time": "Mon, Apr 8, 2:34 PM",
                "value": 72.0999984741211
            },
            {
                "time": "Mon, Apr 8, 2:34 PM",
                "value": 72.02999877929688
            },
            {
                "time": "Mon, Apr 8, 2:35 PM",
                "value": 71.95999908447266
            },
            {
                "time": "Mon, Apr 8, 2:35 PM",
                "value": 71.87999725341797
            },
            {
                "time": "Mon, Apr 8, 2:36 PM",
                "value": 71.8499984741211
            },
            {
                "time": "Mon, Apr 8, 2:36 PM",
                "value": 71.80999755859375
            },
            {
                "time": "Mon, Apr 8, 2:37 PM",
                "value": 71.7699966430664
            },
            {
                "time": "Mon, Apr 8, 2:37 PM",
                "value": 71.69999694824219
            },
            {
                "time": "Mon, Apr 8, 2:38 PM",
                "value": 71.69999694824219
            },
            {
                "time": "Mon, Apr 8, 2:38 PM",
                "value": 71.66999816894531
            },
            {
                "time": "Mon, Apr 8, 2:39 PM",
                "value": 71.69999694824219
            },
            {
                "time": "Mon, Apr 8, 2:39 PM",
                "value": 71.69999694824219
            },
            {
                "time": "Mon, Apr 8, 2:40 PM",
                "value": 71.69999694824219
            },
            {
                "time": "Mon, Apr 8, 2:40 PM",
                "value": 71.69999694824219
            },
            {
                "time": "Mon, Apr 8, 2:41 PM",
                "value": 71.66000366210938
            },
            {
                "time": "Mon, Apr 8, 2:41 PM",
                "value": 71.66000366210938
            },
            {
                "time": "Mon, Apr 8, 2:42 PM",
                "value": 71.62999725341797
            },
            {
                "time": "Mon, Apr 8, 2:42 PM",
                "value": 71.62999725341797
            },
            {
                "time": "Mon, Apr 8, 2:43 PM",
                "value": 71.62999725341797
            },
            {
                "time": "Mon, Apr 8, 2:43 PM",
                "value": 71.62999725341797
            },
            {
                "time": "Mon, Apr 8, 2:44 PM",
                "value": 71.66999816894531
            },
            {
                "time": "Mon, Apr 8, 2:44 PM",
                "value": 71.62999725341797
            },
            {
                "time": "Mon, Apr 8, 2:45 PM",
                "value": 71.62999725341797
            },
            {
                "time": "Mon, Apr 8, 2:45 PM",
                "value": 71.69999694824219
            },
            {
                "time": "Mon, Apr 8, 2:46 PM",
                "value": 71.62999725341797
            },
            {
                "time": "Mon, Apr 8, 2:46 PM",
                "value": 71.62999725341797
            },
            {
                "time": "Mon, Apr 8, 2:47 PM",
                "value": 71.62999725341797
            },
            {
                "time": "Mon, Apr 8, 2:47 PM",
                "value": 71.66999816894531
            },
            {
                "time": "Mon, Apr 8, 2:48 PM",
                "value": 71.66999816894531
            },
            {
                "time": "Mon, Apr 8, 2:48 PM",
                "value": 71.73999786376953
            },
            {
                "time": "Mon, Apr 8, 2:49 PM",
                "value": 71.66999816894531
            },
            {
                "time": "Mon, Apr 8, 2:49 PM",
                "value": 71.66999816894531
            },
            {
                "time": "Mon, Apr 8, 2:50 PM",
                "value": 71.66999816894531
            },
            {
                "time": "Mon, Apr 8, 2:50 PM",
                "value": 71.62999725341797
            },
            {
                "time": "Mon, Apr 8, 2:51 PM",
                "value": 71.58999633789062
            },
            {
                "time": "Mon, Apr 8, 2:51 PM",
                "value": 71.66000366210938
            },
            {
                "time": "Mon, Apr 8, 2:52 PM",
                "value": 71.58999633789062
            },
            {
                "time": "Mon, Apr 8, 2:52 PM",
                "value": 71.58999633789062
            },
            {
                "time": "Mon, Apr 8, 2:53 PM",
                "value": 71.58999633789062
            },
            {
                "time": "Mon, Apr 8, 2:53 PM",
                "value": 71.55999755859375
            },
            {
                "time": "Mon, Apr 8, 2:54 PM",
                "value": 71.4800033569336
            },
            {
                "time": "Mon, Apr 8, 2:54 PM",
                "value": 71.48999786376953
            },
            {
                "time": "Mon, Apr 8, 2:55 PM",
                "value": 71.37999725341797
            },
            {
                "time": "Mon, Apr 8, 2:55 PM",
                "value": 71.30000305175781
            },
            {
                "time": "Mon, Apr 8, 2:56 PM",
                "value": 71.2300033569336
            },
            {
                "time": "Mon, Apr 8, 2:56 PM",
                "value": 71.16000366210938
            },
            {
                "time": "Mon, Apr 8, 2:57 PM",
                "value": 71.08999633789062
            },
            {
                "time": "Mon, Apr 8, 2:57 PM",
                "value": 71.0199966430664
            },
            {
                "time": "Mon, Apr 8, 2:58 PM",
                "value": 70.87000274658203
            },
            {
                "time": "Mon, Apr 8, 2:58 PM",
                "value": 70.76000213623047
            },
            {
                "time": "Mon, Apr 8, 2:59 PM",
                "value": 70.69000244140625
            },
            {
                "time": "Mon, Apr 8, 2:59 PM",
                "value": 70.6500015258789
            },
            {
                "time": "Mon, Apr 8, 3:00 PM",
                "value": 70.58000183105469
            },
            {
                "time": "Mon, Apr 8, 3:00 PM",
                "value": 70.5999984741211
            },
            {
                "time": "Mon, Apr 8, 3:01 PM",
                "value": 70.4000015258789
            },
            {
                "time": "Mon, Apr 8, 3:01 PM",
                "value": 70.33000183105469
            },
            {
                "time": "Mon, Apr 8, 3:02 PM",
                "value": 70.22000122070312
            },
            {
                "time": "Mon, Apr 8, 3:02 PM",
                "value": 70.1500015258789
            },
            {
                "time": "Mon, Apr 8, 3:03 PM",
                "value": 70.11000061035156
            },
            {
                "time": "Mon, Apr 8, 3:03 PM",
                "value": 70.0199966430664
            },
            {
                "time": "Mon, Apr 8, 3:04 PM",
                "value": 69.93000030517578
            },
            {
                "time": "Mon, Apr 8, 3:04 PM",
                "value": 69.81999969482422
            },
            {
                "time": "Mon, Apr 8, 3:05 PM",
                "value": 69.75
            },
            {
                "time": "Mon, Apr 8, 3:05 PM",
                "value": 69.68000030517578
            },
            {
                "time": "Mon, Apr 8, 3:06 PM",
                "value": 69.63999938964844
            },
            {
                "time": "Mon, Apr 8, 3:06 PM",
                "value": 69.56999969482422
            },
            {
                "time": "Mon, Apr 8, 3:07 PM",
                "value": 69.5
            },
            {
                "time": "Mon, Apr 8, 3:07 PM",
                "value": 69.41999816894531
            },
            {
                "time": "Mon, Apr 8, 3:08 PM",
                "value": 69.3499984741211
            },
            {
                "time": "Mon, Apr 8, 3:08 PM",
                "value": 69.27999877929688
            },
            {
                "time": "Mon, Apr 8, 3:09 PM",
                "value": 69.20999908447266
            },
            {
                "time": "Mon, Apr 8, 3:09 PM",
                "value": 69.16999816894531
            },
            {
                "time": "Mon, Apr 8, 3:10 PM",
                "value": 69.16999816894531
            },
            {
                "time": "Mon, Apr 8, 3:10 PM",
                "value": 69.13999938964844
            },
            {
                "time": "Mon, Apr 8, 3:11 PM",
                "value": 69.0999984741211
            },
            {
                "time": "Mon, Apr 8, 3:11 PM",
                "value": 69.05999755859375
            },
            {
                "time": "Mon, Apr 8, 3:12 PM",
                "value": 69.06999969482422
            },
            {
                "time": "Mon, Apr 8, 3:12 PM",
                "value": 69.06999969482422
            },
            {
                "time": "Mon, Apr 8, 3:13 PM",
                "value": 69.02999877929688
            },
            {
                "time": "Mon, Apr 8, 3:13 PM",
                "value": 68.98999786376953
            },
            {
                "time": "Mon, Apr 8, 3:14 PM",
                "value": 68.95999908447266
            },
            {
                "time": "Mon, Apr 8, 3:14 PM",
                "value": 68.95999908447266
            },
            {
                "time": "Mon, Apr 8, 3:15 PM",
                "value": 68.91999816894531
            },
            {
                "time": "Mon, Apr 8, 3:15 PM",
                "value": 68.87999725341797
            },
            {
                "time": "Mon, Apr 8, 3:16 PM",
                "value": 68.8499984741211
            },
            {
                "time": "Mon, Apr 8, 3:16 PM",
                "value": 68.80999755859375
            },
            {
                "time": "Mon, Apr 8, 3:17 PM",
                "value": 68.80999755859375
            },
            {
                "time": "Mon, Apr 8, 3:17 PM",
                "value": 68.7699966430664
            },
            {
                "time": "Mon, Apr 8, 3:18 PM",
                "value": 68.77999877929688
            },
            {
                "time": "Mon, Apr 8, 3:18 PM",
                "value": 68.77999877929688
            },
            {
                "time": "Mon, Apr 8, 3:19 PM",
                "value": 68.7699966430664
            },
            {
                "time": "Mon, Apr 8, 3:19 PM",
                "value": 68.77999877929688
            },
            {
                "time": "Mon, Apr 8, 3:20 PM",
                "value": 68.77999877929688
            },
            {
                "time": "Mon, Apr 8, 3:20 PM",
                "value": 68.80000305175781
            },
            {
                "time": "Mon, Apr 8, 3:21 PM",
                "value": 68.80999755859375
            },
            {
                "time": "Mon, Apr 8, 3:21 PM",
                "value": 68.8499984741211
            },
            {
                "time": "Mon, Apr 8, 3:22 PM",
                "value": 68.87999725341797
            },
            {
                "time": "Mon, Apr 8, 3:22 PM",
                "value": 68.91999816894531
            },
            {
                "time": "Mon, Apr 8, 3:23 PM",
                "value": 68.95999908447266
            },
            {
                "time": "Mon, Apr 8, 3:23 PM",
                "value": 69.02999877929688
            },
            {
                "time": "Mon, Apr 8, 3:24 PM",
                "value": 69.02999877929688
            },
            {
                "time": "Mon, Apr 8, 3:24 PM",
                "value": 69.05999755859375
            },
            {
                "time": "Mon, Apr 8, 3:25 PM",
                "value": 69.0999984741211
            },
            {
                "time": "Mon, Apr 8, 3:25 PM",
                "value": 69.13999938964844
            },
            {
                "time": "Mon, Apr 8, 3:26 PM",
                "value": 69.16999816894531
            },
            {
                "time": "Mon, Apr 8, 3:26 PM",
                "value": 69.20999908447266
            },
            {
                "time": "Mon, Apr 8, 3:27 PM",
                "value": 69.27999877929688
            },
            {
                "time": "Mon, Apr 8, 3:27 PM",
                "value": 69.3499984741211
            },
            {
                "time": "Mon, Apr 8, 3:28 PM",
                "value": 69.38999938964844
            },
            {
                "time": "Mon, Apr 8, 3:28 PM",
                "value": 69.5
            },
            {
                "time": "Mon, Apr 8, 3:29 PM",
                "value": 69.54000091552734
            },
            {
                "time": "Mon, Apr 8, 3:29 PM",
                "value": 69.61000061035156
            },
            {
                "time": "Mon, Apr 8, 3:30 PM",
                "value": 69.68000030517578
            },
            {
                "time": "Mon, Apr 8, 3:30 PM",
                "value": 69.75
            },
            {
                "time": "Mon, Apr 8, 3:31 PM",
                "value": 69.87000274658203
            },
            {
                "time": "Mon, Apr 8, 3:31 PM",
                "value": 69.9000015258789
            },
            {
                "time": "Mon, Apr 8, 3:32 PM",
                "value": 70.01000213623047
            },
            {
                "time": "Mon, Apr 8, 3:32 PM",
                "value": 70.06999969482422
            },
            {
                "time": "Mon, Apr 8, 3:33 PM",
                "value": 70.11000061035156
            },
            {
                "time": "Mon, Apr 8, 3:33 PM",
                "value": 70.19999694824219
            },
            {
                "time": "Mon, Apr 8, 3:34 PM",
                "value": 70.37999725341797
            },
            {
                "time": "Mon, Apr 8, 3:34 PM",
                "value": 70.4000015258789
            },
            {
                "time": "Mon, Apr 8, 3:35 PM",
                "value": 70.51000213623047
            },
            {
                "time": "Mon, Apr 8, 3:35 PM",
                "value": 70.58000183105469
            },
            {
                "time": "Mon, Apr 8, 3:36 PM",
                "value": 70.62000274658203
            },
            {
                "time": "Mon, Apr 8, 3:36 PM",
                "value": 70.7300033569336
            },
            {
                "time": "Mon, Apr 8, 3:37 PM",
                "value": 70.8499984741211
            },
            {
                "time": "Mon, Apr 8, 3:37 PM",
                "value": 70.94999694824219
            },
            {
                "time": "Mon, Apr 8, 3:38 PM",
                "value": 71.0199966430664
            },
            {
                "time": "Mon, Apr 8, 3:38 PM",
                "value": 71.08999633789062
            },
            {
                "time": "Mon, Apr 8, 3:39 PM",
                "value": 71.16000366210938
            },
            {
                "time": "Mon, Apr 8, 3:39 PM",
                "value": 71.2699966430664
            },
            {
                "time": "Mon, Apr 8, 3:40 PM",
                "value": 71.37000274658203
            },
            {
                "time": "Mon, Apr 8, 3:40 PM",
                "value": 71.48999786376953
            },
            {
                "time": "Mon, Apr 8, 3:41 PM",
                "value": 71.55999755859375
            },
            {
                "time": "Mon, Apr 8, 3:41 PM",
                "value": 71.62999725341797
            },
            {
                "time": "Mon, Apr 8, 3:42 PM",
                "value": 71.7300033569336
            },
            {
                "time": "Mon, Apr 8, 3:42 PM",
                "value": 71.80999755859375
            },
            {
                "time": "Mon, Apr 8, 3:43 PM",
                "value": 71.87999725341797
            },
            {
                "time": "Mon, Apr 8, 3:43 PM",
                "value": 71.95999908447266
            },
            {
                "time": "Mon, Apr 8, 3:44 PM",
                "value": 72
            },
            {
                "time": "Mon, Apr 8, 3:44 PM",
                "value": 72.02999877929688
            },
            {
                "time": "Mon, Apr 8, 3:45 PM",
                "value": 72.06999969482422
            },
            {
                "time": "Mon, Apr 8, 3:45 PM",
                "value": 72.08000183105469
            },
            {
                "time": "Mon, Apr 8, 3:46 PM",
                "value": 72.0999984741211
            },
            {
                "time": "Mon, Apr 8, 3:46 PM",
                "value": 72.13999938964844
            },
            {
                "time": "Mon, Apr 8, 3:47 PM",
                "value": 72.18000030517578
            },
            {
                "time": "Mon, Apr 8, 3:47 PM",
                "value": 72.20999908447266
            },
            {
                "time": "Mon, Apr 8, 3:48 PM",
                "value": 72.27999877929688
            },
            {
                "time": "Mon, Apr 8, 3:48 PM",
                "value": 72.31999969482422
            },
            {
                "time": "Mon, Apr 8, 3:49 PM",
                "value": 72.38999938964844
            },
            {
                "time": "Mon, Apr 8, 3:49 PM",
                "value": 72.43000030517578
            },
            {
                "time": "Mon, Apr 8, 3:50 PM",
                "value": 72.45999908447266
            },
            {
                "time": "Mon, Apr 8, 3:50 PM",
                "value": 72.5
            },
            {
                "time": "Mon, Apr 8, 3:51 PM",
                "value": 72.52999877929688
            },
            {
                "time": "Mon, Apr 8, 3:51 PM",
                "value": 72.55999755859375
            },
            {
                "time": "Mon, Apr 8, 3:52 PM",
                "value": 72.56999969482422
            },
            {
                "time": "Mon, Apr 8, 3:52 PM",
                "value": 72.63999938964844
            },
            {
                "time": "Mon, Apr 8, 3:53 PM",
                "value": 72.79000091552734
            },
            {
                "time": "Mon, Apr 8, 3:53 PM",
                "value": 72.93000030517578
            },
            {
                "time": "Mon, Apr 8, 3:54 PM",
                "value": 73.01000213623047
            },
            {
                "time": "Mon, Apr 8, 3:54 PM",
                "value": 73.08000183105469
            },
            {
                "time": "Mon, Apr 8, 3:55 PM",
                "value": 73.11000061035156
            },
            {
                "time": "Mon, Apr 8, 3:55 PM",
                "value": 73.11000061035156
            },
            {
                "time": "Mon, Apr 8, 3:56 PM",
                "value": 73.1500015258789
            },
            {
                "time": "Mon, Apr 8, 3:56 PM",
                "value": 73.19000244140625
            },
            {
                "time": "Mon, Apr 8, 3:57 PM",
                "value": 73.26000213623047
            },
            {
                "time": "Mon, Apr 8, 3:57 PM",
                "value": 73.36000061035156
            },
            {
                "time": "Mon, Apr 8, 3:58 PM",
                "value": 73.4800033569336
            },
            {
                "time": "Mon, Apr 8, 3:58 PM",
                "value": 73.51000213623047
            },
            {
                "time": "Mon, Apr 8, 3:59 PM",
                "value": 73.58999633789062
            },
            {
                "time": "Mon, Apr 8, 3:59 PM",
                "value": 73.69000244140625
            },
            {
                "time": "Mon, Apr 8, 4:00 PM",
                "value": 73.80000305175781
            },
            {
                "time": "Mon, Apr 8, 4:00 PM",
                "value": 73.91000366210938
            },
            {
                "time": "Mon, Apr 8, 4:01 PM",
                "value": 74.0199966430664
            },
            {
                "time": "Mon, Apr 8, 4:01 PM",
                "value": 74.08999633789062
            },
            {
                "time": "Mon, Apr 8, 4:02 PM",
                "value": 74.19999694824219
            },
            {
                "time": "Mon, Apr 8, 4:02 PM",
                "value": 74.2699966430664
            },
            {
                "time": "Mon, Apr 8, 4:03 PM",
                "value": 74.30999755859375
            },
            {
                "time": "Mon, Apr 8, 4:03 PM",
                "value": 74.33999633789062
            },
            {
                "time": "Mon, Apr 8, 4:04 PM",
                "value": 74.33999633789062
            },
            {
                "time": "Mon, Apr 8, 4:04 PM",
                "value": 74.41999816894531
            },
            {
                "time": "Mon, Apr 8, 4:05 PM",
                "value": 74.33999633789062
            },
            {
                "time": "Mon, Apr 8, 4:05 PM",
                "value": 74.41000366210938
            },
            {
                "time": "Mon, Apr 8, 4:06 PM",
                "value": 74.5199966430664
            },
            {
                "time": "Mon, Apr 8, 4:06 PM",
                "value": 74.5199966430664
            },
            {
                "time": "Mon, Apr 8, 4:07 PM",
                "value": 74.5199966430664
            },
            {
                "time": "Mon, Apr 8, 4:07 PM",
                "value": 74.56999969482422
            },
            {
                "time": "Mon, Apr 8, 4:08 PM",
                "value": 74.5199966430664
            },
            {
                "time": "Mon, Apr 8, 4:08 PM",
                "value": 74.52999877929688
            },
            {
                "time": "Mon, Apr 8, 4:09 PM",
                "value": 74.5999984741211
            },
            {
                "time": "Mon, Apr 8, 4:09 PM",
                "value": 74.5999984741211
            },
            {
                "time": "Mon, Apr 8, 4:10 PM",
                "value": 74.5999984741211
            },
            {
                "time": "Mon, Apr 8, 4:10 PM",
                "value": 74.62999725341797
            },
            {
                "time": "Mon, Apr 8, 4:11 PM",
                "value": 74.66999816894531
            },
            {
                "time": "Mon, Apr 8, 4:11 PM",
                "value": 74.73999786376953
            },
            {
                "time": "Mon, Apr 8, 4:12 PM",
                "value": 74.7699966430664
            },
            {
                "time": "Mon, Apr 8, 4:12 PM",
                "value": 74.80999755859375
            },
            {
                "time": "Mon, Apr 8, 4:13 PM",
                "value": 74.81999969482422
            },
            {
                "time": "Mon, Apr 8, 4:13 PM",
                "value": 74.81999969482422
            },
            {
                "time": "Mon, Apr 8, 4:14 PM",
                "value": 74.8499984741211
            },
            {
                "time": "Mon, Apr 8, 4:14 PM",
                "value": 74.91999816894531
            },
            {
                "time": "Mon, Apr 8, 4:15 PM",
                "value": 74.95999908447266
            },
            {
                "time": "Mon, Apr 8, 4:15 PM",
                "value": 74.95999908447266
            },
            {
                "time": "Mon, Apr 8, 4:16 PM",
                "value": 74.95999908447266
            },
            {
                "time": "Mon, Apr 8, 4:16 PM",
                "value": 74.95999908447266
            },
            {
                "time": "Mon, Apr 8, 4:17 PM",
                "value": 74.91999816894531
            },
            {
                "time": "Mon, Apr 8, 4:17 PM",
                "value": 74.91999816894531
            },
            {
                "time": "Mon, Apr 8, 4:18 PM",
                "value": 74.95999908447266
            },
            {
                "time": "Mon, Apr 8, 4:18 PM",
                "value": 74.95999908447266
            },
            {
                "time": "Mon, Apr 8, 4:19 PM",
                "value": 74.95999908447266
            },
            {
                "time": "Mon, Apr 8, 4:19 PM",
                "value": 74.91999816894531
            },
            {
                "time": "Mon, Apr 8, 4:20 PM",
                "value": 74.95999908447266
            },
            {
                "time": "Mon, Apr 8, 4:20 PM",
                "value": 74.98999786376953
            },
            {
                "time": "Mon, Apr 8, 4:21 PM",
                "value": 75.02999877929688
            },
            {
                "time": "Mon, Apr 8, 4:21 PM",
                "value": 75.02999877929688
            },
            {
                "time": "Mon, Apr 8, 4:22 PM",
                "value": 75.06999969482422
            },
            {
                "time": "Mon, Apr 8, 4:22 PM",
                "value": 75.13999938964844
            },
            {
                "time": "Mon, Apr 8, 4:23 PM",
                "value": 75.20999908447266
            },
            {
                "time": "Mon, Apr 8, 4:23 PM",
                "value": 75.20999908447266
            },
            {
                "time": "Mon, Apr 8, 4:24 PM",
                "value": 75.18000030517578
            },
            {
                "time": "Mon, Apr 8, 4:24 PM",
                "value": 75.13999938964844
            },
            {
                "time": "Mon, Apr 8, 4:25 PM",
                "value": 75.13999938964844
            },
            {
                "time": "Mon, Apr 8, 4:25 PM",
                "value": 75.13999938964844
            },
            {
                "time": "Mon, Apr 8, 4:26 PM",
                "value": 75.12999725341797
            },
            {
                "time": "Mon, Apr 8, 4:26 PM",
                "value": 75.13999938964844
            },
            {
                "time": "Mon, Apr 8, 4:27 PM",
                "value": 75.0999984741211
            },
            {
                "time": "Mon, Apr 8, 4:27 PM",
                "value": 75.0999984741211
            },
            {
                "time": "Mon, Apr 8, 4:28 PM",
                "value": 75.0999984741211
            },
            {
                "time": "Mon, Apr 8, 4:28 PM",
                "value": 75.13999938964844
            },
            {
                "time": "Mon, Apr 8, 4:29 PM",
                "value": 75.27999877929688
            },
            {
                "time": "Mon, Apr 8, 4:29 PM",
                "value": 75.31999969482422
            },
            {
                "time": "Mon, Apr 8, 4:30 PM",
                "value": 75.38999938964844
            },
            {
                "time": "Mon, Apr 8, 4:30 PM",
                "value": 75.43000030517578
            },
            {
                "time": "Mon, Apr 8, 4:31 PM",
                "value": 75.47000122070312
            },
            {
                "time": "Mon, Apr 8, 4:31 PM",
                "value": 75.54000091552734
            },
            {
                "time": "Mon, Apr 8, 4:32 PM",
                "value": 75.56999969482422
            },
            {
                "time": "Mon, Apr 8, 4:32 PM",
                "value": 75.56999969482422
            },
            {
                "time": "Mon, Apr 8, 4:33 PM",
                "value": 75.61000061035156
            },
            {
                "time": "Mon, Apr 8, 4:33 PM",
                "value": 75.6500015258789
            },
            {
                "time": "Mon, Apr 8, 4:34 PM",
                "value": 75.6500015258789
            },
            {
                "time": "Mon, Apr 8, 4:34 PM",
                "value": 75.72000122070312
            },
            {
                "time": "Mon, Apr 8, 4:35 PM",
                "value": 75.76000213623047
            },
            {
                "time": "Mon, Apr 8, 4:35 PM",
                "value": 75.79000091552734
            },
            {
                "time": "Mon, Apr 8, 4:36 PM",
                "value": 75.81999969482422
            },
            {
                "time": "Mon, Apr 8, 4:36 PM",
                "value": 75.83000183105469
            },
            {
                "time": "Mon, Apr 8, 4:37 PM",
                "value": 75.86000061035156
            },
            {
                "time": "Mon, Apr 8, 4:37 PM",
                "value": 75.9000015258789
            },
            {
                "time": "Mon, Apr 8, 4:38 PM",
                "value": 75.97000122070312
            },
            {
                "time": "Mon, Apr 8, 4:38 PM",
                "value": 76.05000305175781
            },
            {
                "time": "Mon, Apr 8, 4:39 PM",
                "value": 76.08000183105469
            },
            {
                "time": "Mon, Apr 8, 4:39 PM",
                "value": 76.05000305175781
            },
            {
                "time": "Mon, Apr 8, 4:40 PM",
                "value": 76.01000213623047
            },
            {
                "time": "Mon, Apr 8, 4:40 PM",
                "value": 76.04000091552734
            },
            {
                "time": "Mon, Apr 8, 4:41 PM",
                "value": 76.04000091552734
            },
            {
                "time": "Mon, Apr 8, 4:41 PM",
                "value": 75.97000122070312
            },
            {
                "time": "Mon, Apr 8, 4:42 PM",
                "value": 75.97000122070312
            },
            {
                "time": "Mon, Apr 8, 4:42 PM",
                "value": 75.97000122070312
            },
            {
                "time": "Mon, Apr 8, 4:43 PM",
                "value": 75.97000122070312
            },
            {
                "time": "Mon, Apr 8, 4:43 PM",
                "value": 76.01000213623047
            },
            {
                "time": "Mon, Apr 8, 4:44 PM",
                "value": 76.05000305175781
            },
            {
                "time": "Mon, Apr 8, 4:44 PM",
                "value": 76.05000305175781
            },
            {
                "time": "Mon, Apr 8, 4:45 PM",
                "value": 76.12000274658203
            },
            {
                "time": "Mon, Apr 8, 4:45 PM",
                "value": 76.12000274658203
            },
            {
                "time": "Mon, Apr 8, 4:46 PM",
                "value": 76.11000061035156
            },
            {
                "time": "Mon, Apr 8, 4:46 PM",
                "value": 75.9800033569336
            },
            {
                "time": "Mon, Apr 8, 4:47 PM",
                "value": 75.6500015258789
            },
            {
                "time": "Mon, Apr 8, 4:47 PM",
                "value": 75.4000015258789
            },
            {
                "time": "Mon, Apr 8, 4:48 PM",
                "value": 75.06999969482422
            },
            {
                "time": "Mon, Apr 8, 4:48 PM",
                "value": 74.77999877929688
            },
            {
                "time": "Mon, Apr 8, 4:49 PM",
                "value": 74.66999816894531
            },
            {
                "time": "Mon, Apr 8, 4:49 PM",
                "value": 74.5999984741211
            },
            {
                "time": "Mon, Apr 8, 4:50 PM",
                "value": 74.55999755859375
            },
            {
                "time": "Mon, Apr 8, 4:50 PM",
                "value": 74.63999938964844
            },
            {
                "time": "Mon, Apr 8, 4:51 PM",
                "value": 74.63999938964844
            },
            {
                "time": "Mon, Apr 8, 4:51 PM",
                "value": 74.66999816894531
            },
            {
                "time": "Mon, Apr 8, 4:52 PM",
                "value": 74.77999877929688
            },
            {
                "time": "Mon, Apr 8, 4:52 PM",
                "value": 74.88999938964844
            },
            {
                "time": "Mon, Apr 8, 4:53 PM",
                "value": 75
            },
            {
                "time": "Mon, Apr 8, 4:53 PM",
                "value": 75.20999908447266
            },
            {
                "time": "Mon, Apr 8, 4:54 PM",
                "value": 75.20999908447266
            },
            {
                "time": "Mon, Apr 8, 4:54 PM",
                "value": 75.4000015258789
            },
            {
                "time": "Mon, Apr 8, 4:55 PM",
                "value": 75.54000091552734
            },
            {
                "time": "Mon, Apr 8, 4:55 PM",
                "value": 75.56999969482422
            },
            {
                "time": "Mon, Apr 8, 4:56 PM",
                "value": 75.5999984741211
            },
            {
                "time": "Mon, Apr 8, 4:56 PM",
                "value": 75.61000061035156
            },
            {
                "time": "Mon, Apr 8, 4:57 PM",
                "value": 75.61000061035156
            },
            {
                "time": "Mon, Apr 8, 4:57 PM",
                "value": 75.61000061035156
            },
            {
                "time": "Mon, Apr 8, 4:58 PM",
                "value": 75.68000030517578
            },
            {
                "time": "Mon, Apr 8, 4:58 PM",
                "value": 75.72000122070312
            },
            {
                "time": "Mon, Apr 8, 4:59 PM",
                "value": 75.6500015258789
            },
            {
                "time": "Mon, Apr 8, 4:59 PM",
                "value": 75.54000091552734
            },
            {
                "time": "Mon, Apr 8, 5:00 PM",
                "value": 75.43000030517578
            },
            {
                "time": "Mon, Apr 8, 5:00 PM",
                "value": 75.31999969482422
            },
            {
                "time": "Mon, Apr 8, 5:01 PM",
                "value": 75.20999908447266
            },
            {
                "time": "Mon, Apr 8, 5:01 PM",
                "value": 75.02999877929688
            },
            {
                "time": "Mon, Apr 8, 5:02 PM",
                "value": 74.81999969482422
            },
            {
                "time": "Mon, Apr 8, 5:02 PM",
                "value": 74.63999938964844
            },
            {
                "time": "Mon, Apr 8, 5:03 PM",
                "value": 74.44999694824219
            },
            {
                "time": "Mon, Apr 8, 5:03 PM",
                "value": 74.33999633789062
            },
            {
                "time": "Mon, Apr 8, 5:04 PM",
                "value": 74.19999694824219
            },
            {
                "time": "Mon, Apr 8, 5:04 PM",
                "value": 74.12999725341797
            },
            {
                "time": "Mon, Apr 8, 5:05 PM",
                "value": 74.05000305175781
            },
            {
                "time": "Mon, Apr 8, 5:05 PM",
                "value": 74.0199966430664
            },
            {
                "time": "Mon, Apr 8, 5:06 PM",
                "value": 73.94999694824219
            },
            {
                "time": "Mon, Apr 8, 5:06 PM",
                "value": 73.87000274658203
            },
            {
                "time": "Mon, Apr 8, 5:07 PM",
                "value": 73.80000305175781
            },
            {
                "time": "Mon, Apr 8, 5:07 PM",
                "value": 73.73999786376953
            },
            {
                "time": "Mon, Apr 8, 5:08 PM",
                "value": 73.69000244140625
            },
            {
                "time": "Mon, Apr 8, 5:08 PM",
                "value": 73.62000274658203
            },
            {
                "time": "Mon, Apr 8, 5:09 PM",
                "value": 73.66999816894531
            },
            {
                "time": "Mon, Apr 8, 5:09 PM",
                "value": 73.62000274658203
            },
            {
                "time": "Mon, Apr 8, 5:10 PM",
                "value": 73.58000183105469
            },
            {
                "time": "Mon, Apr 8, 5:10 PM",
                "value": 73.58000183105469
            },
            {
                "time": "Mon, Apr 8, 5:11 PM",
                "value": 73.62000274658203
            },
            {
                "time": "Mon, Apr 8, 5:11 PM",
                "value": 73.63999938964844
            },
            {
                "time": "Mon, Apr 8, 5:12 PM",
                "value": 73.69999694824219
            },
            {
                "time": "Mon, Apr 8, 5:12 PM",
                "value": 73.7699966430664
            },
            {
                "time": "Mon, Apr 8, 5:13 PM",
                "value": 73.83999633789062
            },
            {
                "time": "Mon, Apr 8, 5:13 PM",
                "value": 73.91000366210938
            },
            {
                "time": "Mon, Apr 8, 5:14 PM",
                "value": 74.0199966430664
            },
            {
                "time": "Mon, Apr 8, 5:14 PM",
                "value": 74.08999633789062
            },
            {
                "time": "Mon, Apr 8, 5:15 PM",
                "value": 74.12999725341797
            },
            {
                "time": "Mon, Apr 8, 5:15 PM",
                "value": 74.16999816894531
            },
            {
                "time": "Mon, Apr 8, 5:16 PM",
                "value": 74.19999694824219
            },
            {
                "time": "Mon, Apr 8, 5:16 PM",
                "value": 74.19999694824219
            },
            {
                "time": "Mon, Apr 8, 5:17 PM",
                "value": 74.16000366210938
            },
            {
                "time": "Mon, Apr 8, 5:17 PM",
                "value": 74.19999694824219
            },
            {
                "time": "Mon, Apr 8, 5:18 PM",
                "value": 74.33999633789062
            },
            {
                "time": "Mon, Apr 8, 5:18 PM",
                "value": 74.48999786376953
            },
            {
                "time": "Mon, Apr 8, 5:19 PM",
                "value": 74.5999984741211
            },
            {
                "time": "Mon, Apr 8, 5:19 PM",
                "value": 74.80999755859375
            },
            {
                "time": "Mon, Apr 8, 5:20 PM",
                "value": 74.98999786376953
            },
            {
                "time": "Mon, Apr 8, 5:20 PM",
                "value": 75.0999984741211
            },
            {
                "time": "Mon, Apr 8, 5:21 PM",
                "value": 75.13999938964844
            },
            {
                "time": "Mon, Apr 8, 5:21 PM",
                "value": 75.13999938964844
            },
            {
                "time": "Mon, Apr 8, 5:22 PM",
                "value": 75.18000030517578
            },
            {
                "time": "Mon, Apr 8, 5:22 PM",
                "value": 75.18000030517578
            },
            {
                "time": "Mon, Apr 8, 5:23 PM",
                "value": 75.13999938964844
            },
            {
                "time": "Mon, Apr 8, 5:23 PM",
                "value": 75.01000213623047
            },
            {
                "time": "Mon, Apr 8, 5:24 PM",
                "value": 74.91999816894531
            },
            {
                "time": "Mon, Apr 8, 5:24 PM",
                "value": 74.8499984741211
            },
            {
                "time": "Mon, Apr 8, 5:25 PM",
                "value": 74.77999877929688
            },
            {
                "time": "Mon, Apr 8, 5:25 PM",
                "value": 74.66999816894531
            },
            {
                "time": "Mon, Apr 8, 5:26 PM",
                "value": 74.56999969482422
            },
            {
                "time": "Mon, Apr 8, 5:26 PM",
                "value": 74.38999938964844
            },
            {
                "time": "Mon, Apr 8, 5:27 PM",
                "value": 74.23999786376953
            },
            {
                "time": "Mon, Apr 8, 5:27 PM",
                "value": 74.16000366210938
            },
            {
                "time": "Mon, Apr 8, 5:28 PM",
                "value": 74.0199966430664
            },
            {
                "time": "Mon, Apr 8, 5:28 PM",
                "value": 73.94999694824219
            },
            {
                "time": "Mon, Apr 8, 5:29 PM",
                "value": 73.91000366210938
            },
            {
                "time": "Mon, Apr 8, 5:29 PM",
                "value": 73.7699966430664
            },
            {
                "time": "Mon, Apr 8, 5:30 PM",
                "value": 73.69000244140625
            },
            {
                "time": "Mon, Apr 8, 5:30 PM",
                "value": 73.68000030517578
            },
            {
                "time": "Mon, Apr 8, 5:31 PM",
                "value": 73.62000274658203
            },
            {
                "time": "Mon, Apr 8, 5:31 PM",
                "value": 73.62000274658203
            },
            {
                "time": "Mon, Apr 8, 5:32 PM",
                "value": 73.7300033569336
            },
            {
                "time": "Mon, Apr 8, 5:32 PM",
                "value": 73.91000366210938
            },
            {
                "time": "Mon, Apr 8, 5:33 PM",
                "value": 74.08999633789062
            },
            {
                "time": "Mon, Apr 8, 5:33 PM",
                "value": 74.41000366210938
            },
            {
                "time": "Mon, Apr 8, 5:34 PM",
                "value": 74.5199966430664
            },
            {
                "time": "Mon, Apr 8, 5:34 PM",
                "value": 74.77999877929688
            },
            {
                "time": "Mon, Apr 8, 5:35 PM",
                "value": 74.95999908447266
            },
            {
                "time": "Mon, Apr 8, 5:35 PM",
                "value": 75.02999877929688
            },
            {
                "time": "Mon, Apr 8, 5:36 PM",
                "value": 75.16000366210938
            },
            {
                "time": "Mon, Apr 8, 5:36 PM",
                "value": 75.36000061035156
            },
            {
                "time": "Mon, Apr 8, 5:37 PM",
                "value": 75.31999969482422
            },
            {
                "time": "Mon, Apr 8, 5:37 PM",
                "value": 75.36000061035156
            },
            {
                "time": "Mon, Apr 8, 5:38 PM",
                "value": 75.4000015258789
            },
            {
                "time": "Mon, Apr 8, 5:38 PM",
                "value": 75.43000030517578
            },
            {
                "time": "Mon, Apr 8, 5:39 PM",
                "value": 75.5
            },
            {
                "time": "Mon, Apr 8, 5:39 PM",
                "value": 75.56999969482422
            },
            {
                "time": "Mon, Apr 8, 5:40 PM",
                "value": 75.61000061035156
            },
            {
                "time": "Mon, Apr 8, 5:40 PM",
                "value": 75.63999938964844
            },
            {
                "time": "Mon, Apr 8, 5:41 PM",
                "value": 75.68000030517578
            },
            {
                "time": "Mon, Apr 8, 5:41 PM",
                "value": 75.68000030517578
            },
            {
                "time": "Mon, Apr 8, 5:42 PM",
                "value": 75.68000030517578
            },
            {
                "time": "Mon, Apr 8, 5:42 PM",
                "value": 75.63999938964844
            },
            {
                "time": "Mon, Apr 8, 5:43 PM",
                "value": 75.54000091552734
            },
            {
                "time": "Mon, Apr 8, 5:43 PM",
                "value": 75.38999938964844
            },
            {
                "time": "Mon, Apr 8, 5:44 PM",
                "value": 75.20999908447266
            },
            {
                "time": "Mon, Apr 8, 5:44 PM",
                "value": 75.13999938964844
            },
            {
                "time": "Mon, Apr 8, 5:45 PM",
                "value": 75.02999877929688
            },
            {
                "time": "Mon, Apr 8, 5:45 PM",
                "value": 74.88999938964844
            },
            {
                "time": "Mon, Apr 8, 5:46 PM",
                "value": 74.70999908447266
            },
            {
                "time": "Mon, Apr 8, 5:46 PM",
                "value": 74.62999725341797
            },
            {
                "time": "Mon, Apr 8, 5:47 PM",
                "value": 74.48999786376953
            },
            {
                "time": "Mon, Apr 8, 5:47 PM",
                "value": 74.3499984741211
            },
            {
                "time": "Mon, Apr 8, 5:48 PM",
                "value": 74.23999786376953
            },
            {
                "time": "Mon, Apr 8, 5:48 PM",
                "value": 74.12999725341797
            },
            {
                "time": "Mon, Apr 8, 5:49 PM",
                "value": 73.9800033569336
            },
            {
                "time": "Mon, Apr 8, 5:49 PM",
                "value": 73.83999633789062
            },
            {
                "time": "Mon, Apr 8, 5:50 PM",
                "value": 73.7300033569336
            },
            {
                "time": "Mon, Apr 8, 5:50 PM",
                "value": 73.69000244140625
            },
            {
                "time": "Mon, Apr 8, 5:51 PM",
                "value": 73.69000244140625
            },
            {
                "time": "Mon, Apr 8, 5:51 PM",
                "value": 73.76000213623047
            },
            {
                "time": "Mon, Apr 8, 5:52 PM",
                "value": 73.91000366210938
            },
            {
                "time": "Mon, Apr 8, 5:52 PM",
                "value": 74.05999755859375
            },
            {
                "time": "Mon, Apr 8, 5:53 PM",
                "value": 74.12999725341797
            },
            {
                "time": "Mon, Apr 8, 5:53 PM",
                "value": 74.19999694824219
            },
            {
                "time": "Mon, Apr 8, 5:54 PM",
                "value": 74.2699966430664
            },
            {
                "time": "Mon, Apr 8, 5:54 PM",
                "value": 74.33999633789062
            },
            {
                "time": "Mon, Apr 8, 5:55 PM",
                "value": 74.37999725341797
            },
            {
                "time": "Mon, Apr 8, 5:55 PM",
                "value": 74.37999725341797
            },
            {
                "time": "Mon, Apr 8, 5:56 PM",
                "value": 74.37999725341797
            },
            {
                "time": "Mon, Apr 8, 5:56 PM",
                "value": 74.41999816894531
            },
            {
                "time": "Mon, Apr 8, 5:57 PM",
                "value": 74.5199966430664
            },
            {
                "time": "Mon, Apr 8, 5:57 PM",
                "value": 74.55999755859375
            },
            {
                "time": "Mon, Apr 8, 5:58 PM",
                "value": 74.5999984741211
            },
            {
                "time": "Mon, Apr 8, 5:58 PM",
                "value": 74.5999984741211
            },
            {
                "time": "Mon, Apr 8, 5:59 PM",
                "value": 74.5199966430664
            },
            {
                "time": "Mon, Apr 8, 5:59 PM",
                "value": 74.44999694824219
            },
            {
                "time": "Mon, Apr 8, 6:00 PM",
                "value": 74.37999725341797
            },
            {
                "time": "Mon, Apr 8, 6:00 PM",
                "value": 74.33999633789062
            },
            {
                "time": "Mon, Apr 8, 6:01 PM",
                "value": 74.2300033569336
            },
            {
                "time": "Mon, Apr 8, 6:01 PM",
                "value": 74.16000366210938
            },
            {
                "time": "Mon, Apr 8, 6:02 PM",
                "value": 74.05000305175781
            },
            {
                "time": "Mon, Apr 8, 6:02 PM",
                "value": 73.94999694824219
            },
            {
                "time": "Mon, Apr 8, 6:03 PM",
                "value": 73.80000305175781
            },
            {
                "time": "Mon, Apr 8, 6:03 PM",
                "value": 73.69000244140625
            },
            {
                "time": "Mon, Apr 8, 6:04 PM",
                "value": 73.66000366210938
            },
            {
                "time": "Mon, Apr 8, 6:04 PM",
                "value": 73.4000015258789
            },
            {
                "time": "Mon, Apr 8, 6:05 PM",
                "value": 73.26000213623047
            },
            {
                "time": "Mon, Apr 8, 6:05 PM",
                "value": 73.19000244140625
            },
            {
                "time": "Mon, Apr 8, 6:06 PM",
                "value": 73.04000091552734
            },
            {
                "time": "Mon, Apr 8, 6:06 PM",
                "value": 72.93000030517578
            },
            {
                "time": "Mon, Apr 8, 6:07 PM",
                "value": 72.83000183105469
            },
            {
                "time": "Mon, Apr 8, 6:07 PM",
                "value": 72.72000122070312
            },
            {
                "time": "Mon, Apr 8, 6:08 PM",
                "value": 72.63999938964844
            },
            {
                "time": "Mon, Apr 8, 6:08 PM",
                "value": 72.61000061035156
            },
            {
                "time": "Mon, Apr 8, 6:09 PM",
                "value": 72.61000061035156
            },
            {
                "time": "Mon, Apr 8, 6:09 PM",
                "value": 72.56999969482422
            },
            {
                "time": "Mon, Apr 8, 6:10 PM",
                "value": 72.54000091552734
            },
            {
                "time": "Mon, Apr 8, 6:10 PM",
                "value": 72.45999908447266
            },
            {
                "time": "Mon, Apr 8, 6:11 PM",
                "value": 72.43000030517578
            },
            {
                "time": "Mon, Apr 8, 6:11 PM",
                "value": 72.3499984741211
            },
            {
                "time": "Mon, Apr 8, 6:12 PM",
                "value": 72.31999969482422
            },
            {
                "time": "Mon, Apr 8, 6:12 PM",
                "value": 72.27999877929688
            },
            {
                "time": "Mon, Apr 8, 6:13 PM",
                "value": 72.23999786376953
            },
            {
                "time": "Mon, Apr 8, 6:13 PM",
                "value": 72.20999908447266
            },
            {
                "time": "Mon, Apr 8, 6:14 PM",
                "value": 72.16999816894531
            },
            {
                "time": "Mon, Apr 8, 6:14 PM",
                "value": 72.16999816894531
            },
            {
                "time": "Mon, Apr 8, 6:15 PM",
                "value": 72.16999816894531
            },
            {
                "time": "Mon, Apr 8, 6:15 PM",
                "value": 72.13999938964844
            },
            {
                "time": "Mon, Apr 8, 6:16 PM",
                "value": 72.13999938964844
            },
            {
                "time": "Mon, Apr 8, 6:16 PM",
                "value": 72.13999938964844
            },
            {
                "time": "Mon, Apr 8, 6:17 PM",
                "value": 72.0999984741211
            },
            {
                "time": "Mon, Apr 8, 6:17 PM",
                "value": 72.0999984741211
            },
            {
                "time": "Mon, Apr 8, 6:18 PM",
                "value": 72.13999938964844
            },
            {
                "time": "Mon, Apr 8, 6:18 PM",
                "value": 72.16999816894531
            },
            {
                "time": "Mon, Apr 8, 6:19 PM",
                "value": 72.20999908447266
            },
            {
                "time": "Mon, Apr 8, 6:19 PM",
                "value": 72.30999755859375
            },
            {
                "time": "Mon, Apr 8, 6:20 PM",
                "value": 72.23999786376953
            },
            {
                "time": "Mon, Apr 8, 6:20 PM",
                "value": 72.23999786376953
            },
            {
                "time": "Mon, Apr 8, 6:21 PM",
                "value": 72.23999786376953
            },
            {
                "time": "Mon, Apr 8, 6:21 PM",
                "value": 72.23999786376953
            },
            {
                "time": "Mon, Apr 8, 6:22 PM",
                "value": 72.23999786376953
            },
            {
                "time": "Mon, Apr 8, 6:22 PM",
                "value": 72.23999786376953
            },
            {
                "time": "Mon, Apr 8, 6:23 PM",
                "value": 72.27999877929688
            },
            {
                "time": "Mon, Apr 8, 6:23 PM",
                "value": 72.31999969482422
            },
            {
                "time": "Mon, Apr 8, 6:24 PM",
                "value": 72.3499984741211
            },
            {
                "time": "Mon, Apr 8, 6:24 PM",
                "value": 72.38999938964844
            },
            {
                "time": "Mon, Apr 8, 6:25 PM",
                "value": 72.44000244140625
            },
            {
                "time": "Mon, Apr 8, 6:25 PM",
                "value": 72.45999908447266
            },
            {
                "time": "Mon, Apr 8, 6:26 PM",
                "value": 72.5
            },
            {
                "time": "Mon, Apr 8, 6:26 PM",
                "value": 72.5
            },
            {
                "time": "Mon, Apr 8, 6:27 PM",
                "value": 72.5
            },
            {
                "time": "Mon, Apr 8, 6:27 PM",
                "value": 72.45999908447266
            },
            {
                "time": "Mon, Apr 8, 6:28 PM",
                "value": 72.41999816894531
            },
            {
                "time": "Mon, Apr 8, 6:28 PM",
                "value": 72.38999938964844
            },
            {
                "time": "Mon, Apr 8, 6:29 PM",
                "value": 72.3499984741211
            },
            {
                "time": "Mon, Apr 8, 6:29 PM",
                "value": 72.27999877929688
            },
            {
                "time": "Mon, Apr 8, 6:30 PM",
                "value": 72.27999877929688
            },
            {
                "time": "Mon, Apr 8, 6:30 PM",
                "value": 72.23999786376953
            },
            {
                "time": "Mon, Apr 8, 6:31 PM",
                "value": 72.25
            },
            {
                "time": "Mon, Apr 8, 6:31 PM",
                "value": 72.20999908447266
            },
            {
                "time": "Mon, Apr 8, 6:32 PM",
                "value": 72.20999908447266
            },
            {
                "time": "Mon, Apr 8, 6:32 PM",
                "value": 72.16999816894531
            },
            {
                "time": "Mon, Apr 8, 6:33 PM",
                "value": 72.16999816894531
            },
            {
                "time": "Mon, Apr 8, 6:33 PM",
                "value": 72.16999816894531
            },
            {
                "time": "Mon, Apr 8, 6:34 PM",
                "value": 72.23999786376953
            },
            {
                "time": "Mon, Apr 8, 6:34 PM",
                "value": 72.3499984741211
            },
            {
                "time": "Mon, Apr 8, 6:35 PM",
                "value": 72.45999908447266
            },
            {
                "time": "Mon, Apr 8, 6:35 PM",
                "value": 72.56999969482422
            },
            {
                "time": "Mon, Apr 8, 6:36 PM",
                "value": 72.63999938964844
            },
            {
                "time": "Mon, Apr 8, 6:36 PM",
                "value": 72.72000122070312
            },
            {
                "time": "Mon, Apr 8, 6:37 PM",
                "value": 72.81999969482422
            },
            {
                "time": "Mon, Apr 8, 6:37 PM",
                "value": 72.93000030517578
            },
            {
                "time": "Mon, Apr 8, 6:38 PM",
                "value": 73.01000213623047
            },
            {
                "time": "Mon, Apr 8, 6:38 PM",
                "value": 73.04000091552734
            },
            {
                "time": "Mon, Apr 8, 6:39 PM",
                "value": 73.11000061035156
            },
            {
                "time": "Mon, Apr 8, 6:39 PM",
                "value": 73.1500015258789
            },
            {
                "time": "Mon, Apr 8, 6:40 PM",
                "value": 73.18000030517578
            },
            {
                "time": "Mon, Apr 8, 6:40 PM",
                "value": 73.22000122070312
            },
            {
                "time": "Mon, Apr 8, 6:41 PM",
                "value": 73.22000122070312
            },
            {
                "time": "Mon, Apr 8, 6:41 PM",
                "value": 73.26000213623047
            },
            {
                "time": "Mon, Apr 8, 6:42 PM",
                "value": 73.26000213623047
            },
            {
                "time": "Mon, Apr 8, 6:42 PM",
                "value": 73.22000122070312
            },
            {
                "time": "Mon, Apr 8, 6:43 PM",
                "value": 73.22000122070312
            },
            {
                "time": "Mon, Apr 8, 6:43 PM",
                "value": 73.18000030517578
            },
            {
                "time": "Mon, Apr 8, 6:44 PM",
                "value": 73.19000244140625
            },
            {
                "time": "Mon, Apr 8, 6:44 PM",
                "value": 73.1500015258789
            },
            {
                "time": "Mon, Apr 8, 6:45 PM",
                "value": 73.1500015258789
            },
            {
                "time": "Mon, Apr 8, 6:45 PM",
                "value": 73.1500015258789
            },
            {
                "time": "Mon, Apr 8, 6:46 PM",
                "value": 73.1500015258789
            },
            {
                "time": "Mon, Apr 8, 6:46 PM",
                "value": 73.1500015258789
            },
            {
                "time": "Mon, Apr 8, 6:47 PM",
                "value": 73.1500015258789
            },
            {
                "time": "Mon, Apr 8, 6:47 PM",
                "value": 73.1500015258789
            },
            {
                "time": "Mon, Apr 8, 6:48 PM",
                "value": 73.19000244140625
            },
            {
                "time": "Mon, Apr 8, 6:48 PM",
                "value": 73.2300033569336
            },
            {
                "time": "Mon, Apr 8, 6:49 PM",
                "value": 73.26000213623047
            },
            {
                "time": "Mon, Apr 8, 6:49 PM",
                "value": 73.29000091552734
            },
            {
                "time": "Mon, Apr 8, 6:50 PM",
                "value": 73.33999633789062
            },
            {
                "time": "Mon, Apr 8, 6:50 PM",
                "value": 73.33000183105469
            },
            {
                "time": "Mon, Apr 8, 6:51 PM",
                "value": 73.37000274658203
            },
            {
                "time": "Mon, Apr 8, 6:51 PM",
                "value": 73.33000183105469
            },
            {
                "time": "Mon, Apr 8, 6:52 PM",
                "value": 73.33000183105469
            },
            {
                "time": "Mon, Apr 8, 6:52 PM",
                "value": 73.3499984741211
            },
            {
                "time": "Mon, Apr 8, 6:53 PM",
                "value": 73.44000244140625
            },
            {
                "time": "Mon, Apr 8, 6:53 PM",
                "value": 73.41000366210938
            },
            {
                "time": "Mon, Apr 8, 6:54 PM",
                "value": 73.4800033569336
            },
            {
                "time": "Mon, Apr 8, 6:54 PM",
                "value": 73.51000213623047
            },
            {
                "time": "Mon, Apr 8, 6:55 PM",
                "value": 73.51000213623047
            },
            {
                "time": "Mon, Apr 8, 6:55 PM",
                "value": 73.51000213623047
            },
            {
                "time": "Mon, Apr 8, 6:56 PM",
                "value": 73.55000305175781
            },
            {
                "time": "Mon, Apr 8, 6:56 PM",
                "value": 73.4000015258789
            },
            {
                "time": "Mon, Apr 8, 6:57 PM",
                "value": 73.33000183105469
            },
            {
                "time": "Mon, Apr 8, 6:57 PM",
                "value": 73.30000305175781
            },
            {
                "time": "Mon, Apr 8, 6:58 PM",
                "value": 73.30000305175781
            },
            {
                "time": "Mon, Apr 8, 6:58 PM",
                "value": 73.33000183105469
            },
            {
                "time": "Mon, Apr 8, 6:59 PM",
                "value": 73.41000366210938
            },
            {
                "time": "Mon, Apr 8, 6:59 PM",
                "value": 73.37000274658203
            },
            {
                "time": "Mon, Apr 8, 7:00 PM",
                "value": 73.41000366210938
            },
            {
                "time": "Mon, Apr 8, 7:00 PM",
                "value": 73.4000015258789
            },
            {
                "time": "Mon, Apr 8, 7:01 PM",
                "value": 73.41000366210938
            },
            {
                "time": "Mon, Apr 8, 7:01 PM",
                "value": 73.41000366210938
            },
            {
                "time": "Mon, Apr 8, 7:02 PM",
                "value": 73.43000030517578
            },
            {
                "time": "Mon, Apr 8, 7:02 PM",
                "value": 73.37000274658203
            },
            {
                "time": "Mon, Apr 8, 7:03 PM",
                "value": 73.37000274658203
            },
            {
                "time": "Mon, Apr 8, 7:03 PM",
                "value": 73.37000274658203
            },
            {
                "time": "Mon, Apr 8, 7:04 PM",
                "value": 73.37000274658203
            },
            {
                "time": "Mon, Apr 8, 7:04 PM",
                "value": 73.37000274658203
            },
            {
                "time": "Mon, Apr 8, 7:05 PM",
                "value": 73.37000274658203
            },
            {
                "time": "Mon, Apr 8, 7:05 PM",
                "value": 73.4000015258789
            },
            {
                "time": "Mon, Apr 8, 7:06 PM",
                "value": 73.44000244140625
            },
            {
                "time": "Mon, Apr 8, 7:06 PM",
                "value": 73.44000244140625
            },
            {
                "time": "Mon, Apr 8, 7:07 PM",
                "value": 73.44000244140625
            },
            {
                "time": "Mon, Apr 8, 7:07 PM",
                "value": 73.44000244140625
            },
            {
                "time": "Mon, Apr 8, 7:08 PM",
                "value": 73.44000244140625
            },
            {
                "time": "Mon, Apr 8, 7:08 PM",
                "value": 73.4000015258789
            },
            {
                "time": "Mon, Apr 8, 7:09 PM",
                "value": 73.37000274658203
            },
            {
                "time": "Mon, Apr 8, 7:09 PM",
                "value": 73.37000274658203
            },
            {
                "time": "Mon, Apr 8, 7:10 PM",
                "value": 73.37000274658203
            },
            {
                "time": "Mon, Apr 8, 7:10 PM",
                "value": 73.37000274658203
            },
            {
                "time": "Mon, Apr 8, 7:11 PM",
                "value": 73.37000274658203
            },
            {
                "time": "Mon, Apr 8, 7:11 PM",
                "value": 73.33999633789062
            },
            {
                "time": "Mon, Apr 8, 7:12 PM",
                "value": 73.33000183105469
            },
            {
                "time": "Mon, Apr 8, 7:12 PM",
                "value": 73.26000213623047
            },
            {
                "time": "Mon, Apr 8, 7:13 PM",
                "value": 73.26000213623047
            },
            {
                "time": "Mon, Apr 8, 7:13 PM",
                "value": 73.22000122070312
            },
            {
                "time": "Mon, Apr 8, 7:14 PM",
                "value": 73.19000244140625
            },
            {
                "time": "Mon, Apr 8, 7:14 PM",
                "value": 73.1500015258789
            },
            {
                "time": "Mon, Apr 8, 7:15 PM",
                "value": 73.12000274658203
            },
            {
                "time": "Mon, Apr 8, 7:15 PM",
                "value": 73.04000091552734
            },
            {
                "time": "Mon, Apr 8, 7:16 PM",
                "value": 73.01000213623047
            },
            {
                "time": "Mon, Apr 8, 7:16 PM",
                "value": 72.97000122070312
            },
            {
                "time": "Mon, Apr 8, 7:17 PM",
                "value": 72.97000122070312
            },
            {
                "time": "Mon, Apr 8, 7:17 PM",
                "value": 72.93000030517578
            },
            {
                "time": "Mon, Apr 8, 7:18 PM",
                "value": 72.94000244140625
            },
            {
                "time": "Mon, Apr 8, 7:18 PM",
                "value": 72.93000030517578
            },
            {
                "time": "Mon, Apr 8, 7:19 PM",
                "value": 72.9000015258789
            },
            {
                "time": "Mon, Apr 8, 7:19 PM",
                "value": 72.86000061035156
            },
            {
                "time": "Mon, Apr 8, 7:20 PM",
                "value": 72.83000183105469
            },
            {
                "time": "Mon, Apr 8, 7:20 PM",
                "value": 72.79000091552734
            },
            {
                "time": "Mon, Apr 8, 7:21 PM",
                "value": 72.75
            },
            {
                "time": "Mon, Apr 8, 7:21 PM",
                "value": 72.75
            },
            {
                "time": "Mon, Apr 8, 7:22 PM",
                "value": 72.68000030517578
            },
            {
                "time": "Mon, Apr 8, 7:22 PM",
                "value": 72.63999938964844
            },
            {
                "time": "Mon, Apr 8, 7:23 PM",
                "value": 72.61000061035156
            },
            {
                "time": "Mon, Apr 8, 7:23 PM",
                "value": 72.5999984741211
            },
            {
                "time": "Mon, Apr 8, 7:24 PM",
                "value": 72.54000091552734
            },
            {
                "time": "Mon, Apr 8, 7:24 PM",
                "value": 72.5
            },
            {
                "time": "Mon, Apr 8, 7:25 PM",
                "value": 72.47000122070312
            },
            {
                "time": "Mon, Apr 8, 7:25 PM",
                "value": 72.43000030517578
            },
            {
                "time": "Mon, Apr 8, 7:26 PM",
                "value": 72.36000061035156
            },
            {
                "time": "Mon, Apr 8, 7:26 PM",
                "value": 72.43000030517578
            },
            {
                "time": "Mon, Apr 8, 7:27 PM",
                "value": 72.31999969482422
            },
            {
                "time": "Mon, Apr 8, 7:27 PM",
                "value": 72.27999877929688
            },
            {
                "time": "Mon, Apr 8, 7:28 PM",
                "value": 72.25
            },
            {
                "time": "Mon, Apr 8, 7:28 PM",
                "value": 72.20999908447266
            },
            {
                "time": "Mon, Apr 8, 7:29 PM",
                "value": 72.20999908447266
            },
            {
                "time": "Mon, Apr 8, 7:29 PM",
                "value": 72.25
            },
            {
                "time": "Mon, Apr 8, 7:30 PM",
                "value": 72.13999938964844
            },
            {
                "time": "Mon, Apr 8, 7:30 PM",
                "value": 72.06999969482422
            },
            {
                "time": "Mon, Apr 8, 7:31 PM",
                "value": 72
            },
            {
                "time": "Mon, Apr 8, 7:31 PM",
                "value": 71.91999816894531
            },
            {
                "time": "Mon, Apr 8, 7:32 PM",
                "value": 71.8499984741211
            },
            {
                "time": "Mon, Apr 8, 7:32 PM",
                "value": 71.88999938964844
            },
            {
                "time": "Mon, Apr 8, 7:33 PM",
                "value": 71.73999786376953
            },
            {
                "time": "Mon, Apr 8, 7:33 PM",
                "value": 71.66999816894531
            },
            {
                "time": "Mon, Apr 8, 7:34 PM",
                "value": 71.62999725341797
            },
            {
                "time": "Mon, Apr 8, 7:34 PM",
                "value": 71.5999984741211
            },
            {
                "time": "Mon, Apr 8, 7:35 PM",
                "value": 71.55999755859375
            },
            {
                "time": "Mon, Apr 8, 7:35 PM",
                "value": 71.58999633789062
            },
            {
                "time": "Mon, Apr 8, 7:36 PM",
                "value": 71.44999694824219
            },
            {
                "time": "Mon, Apr 8, 7:36 PM",
                "value": 71.41000366210938
            },
            {
                "time": "Mon, Apr 8, 7:37 PM",
                "value": 71.33999633789062
            },
            {
                "time": "Mon, Apr 8, 7:37 PM",
                "value": 71.30999755859375
            },
            {
                "time": "Mon, Apr 8, 7:38 PM",
                "value": 71.2699966430664
            },
            {
                "time": "Mon, Apr 8, 7:38 PM",
                "value": 71.31999969482422
            },
            {
                "time": "Mon, Apr 8, 7:39 PM",
                "value": 71.2300033569336
            },
            {
                "time": "Mon, Apr 8, 7:39 PM",
                "value": 71.19999694824219
            },
            {
                "time": "Mon, Apr 8, 7:40 PM",
                "value": 71.16000366210938
            },
            {
                "time": "Mon, Apr 8, 7:40 PM",
                "value": 71.16000366210938
            },
            {
                "time": "Mon, Apr 8, 7:41 PM",
                "value": 71.12000274658203
            },
            {
                "time": "Mon, Apr 8, 7:41 PM",
                "value": 71.12999725341797
            },
            {
                "time": "Mon, Apr 8, 7:42 PM",
                "value": 71.12999725341797
            },
            {
                "time": "Mon, Apr 8, 7:42 PM",
                "value": 71.12999725341797
            },
            {
                "time": "Mon, Apr 8, 7:43 PM",
                "value": 71.08999633789062
            },
            {
                "time": "Mon, Apr 8, 7:43 PM",
                "value": 71.05000305175781
            },
            {
                "time": "Mon, Apr 8, 7:44 PM",
                "value": 71.0199966430664
            },
            {
                "time": "Mon, Apr 8, 7:44 PM",
                "value": 71.0199966430664
            },
            {
                "time": "Mon, Apr 8, 7:45 PM",
                "value": 71.0199966430664
            },
            {
                "time": "Mon, Apr 8, 7:45 PM",
                "value": 71.05000305175781
            },
            {
                "time": "Mon, Apr 8, 7:46 PM",
                "value": 71.05000305175781
            },
            {
                "time": "Mon, Apr 8, 7:46 PM",
                "value": 71.05000305175781
            },
            {
                "time": "Mon, Apr 8, 7:47 PM",
                "value": 71.05000305175781
            },
            {
                "time": "Mon, Apr 8, 7:47 PM",
                "value": 71.0199966430664
            },
            {
                "time": "Mon, Apr 8, 7:48 PM",
                "value": 70.94000244140625
            },
            {
                "time": "Mon, Apr 8, 7:48 PM",
                "value": 70.91000366210938
            },
            {
                "time": "Mon, Apr 8, 7:49 PM",
                "value": 70.83000183105469
            },
            {
                "time": "Mon, Apr 8, 7:49 PM",
                "value": 70.80000305175781
            },
            {
                "time": "Mon, Apr 8, 7:50 PM",
                "value": 70.72000122070312
            },
            {
                "time": "Mon, Apr 8, 7:50 PM",
                "value": 70.69000244140625
            },
            {
                "time": "Mon, Apr 8, 7:51 PM",
                "value": 70.70999908447266
            },
            {
                "time": "Mon, Apr 8, 7:51 PM",
                "value": 70.62000274658203
            },
            {
                "time": "Mon, Apr 8, 7:52 PM",
                "value": 70.58000183105469
            },
            {
                "time": "Mon, Apr 8, 7:52 PM",
                "value": 70.55000305175781
            },
            {
                "time": "Mon, Apr 8, 7:53 PM",
                "value": 70.51000213623047
            },
            {
                "time": "Mon, Apr 8, 7:53 PM",
                "value": 70.47000122070312
            },
            {
                "time": "Mon, Apr 8, 7:54 PM",
                "value": 70.44999694824219
            },
            {
                "time": "Mon, Apr 8, 7:54 PM",
                "value": 70.4000015258789
            },
            {
                "time": "Mon, Apr 8, 7:55 PM",
                "value": 70.37000274658203
            },
            {
                "time": "Mon, Apr 8, 7:55 PM",
                "value": 70.29000091552734
            },
            {
                "time": "Mon, Apr 8, 7:56 PM",
                "value": 70.26000213623047
            },
            {
                "time": "Mon, Apr 8, 7:56 PM",
                "value": 70.18000030517578
            },
            {
                "time": "Mon, Apr 8, 7:57 PM",
                "value": 70.23999786376953
            },
            {
                "time": "Mon, Apr 8, 7:57 PM",
                "value": 70.11000061035156
            },
            {
                "time": "Mon, Apr 8, 7:58 PM",
                "value": 70.11000061035156
            },
            {
                "time": "Mon, Apr 8, 7:58 PM",
                "value": 70.08000183105469
            },
            {
                "time": "Mon, Apr 8, 7:59 PM",
                "value": 70.04000091552734
            },
            {
                "time": "Mon, Apr 8, 7:59 PM",
                "value": 70
            },
            {
                "time": "Mon, Apr 8, 8:00 PM",
                "value": 70.06999969482422
            },
            {
                "time": "Mon, Apr 8, 8:00 PM",
                "value": 69.97000122070312
            },
            {
                "time": "Mon, Apr 8, 8:01 PM",
                "value": 69.93000030517578
            },
            {
                "time": "Mon, Apr 8, 8:01 PM",
                "value": 69.9000015258789
            },
            {
                "time": "Mon, Apr 8, 8:02 PM",
                "value": 69.86000061035156
            },
            {
                "time": "Mon, Apr 8, 8:02 PM",
                "value": 69.81999969482422
            },
            {
                "time": "Mon, Apr 8, 8:03 PM",
                "value": 69.79000091552734
            },
            {
                "time": "Mon, Apr 8, 8:03 PM",
                "value": 69.70999908447266
            },
            {
                "time": "Mon, Apr 8, 8:04 PM",
                "value": 69.68000030517578
            },
            {
                "time": "Mon, Apr 8, 8:04 PM",
                "value": 69.68000030517578
            },
            {
                "time": "Mon, Apr 8, 8:05 PM",
                "value": 69.63999938964844
            },
            {
                "time": "Mon, Apr 8, 8:05 PM",
                "value": 69.61000061035156
            },
            {
                "time": "Mon, Apr 8, 8:06 PM",
                "value": 69.56999969482422
            },
            {
                "time": "Mon, Apr 8, 8:06 PM",
                "value": 69.52999877929688
            },
            {
                "time": "Mon, Apr 8, 8:07 PM",
                "value": 69.5
            },
            {
                "time": "Mon, Apr 8, 8:07 PM",
                "value": 69.41999816894531
            },
            {
                "time": "Mon, Apr 8, 8:08 PM",
                "value": 69.3499984741211
            },
            {
                "time": "Mon, Apr 8, 8:08 PM",
                "value": 69.27999877929688
            },
            {
                "time": "Mon, Apr 8, 8:09 PM",
                "value": 69.20999908447266
            },
            {
                "time": "Mon, Apr 8, 8:09 PM",
                "value": 69.16999816894531
            },
            {
                "time": "Mon, Apr 8, 8:10 PM",
                "value": 69.0999984741211
            },
            {
                "time": "Mon, Apr 8, 8:10 PM",
                "value": 69.0199966430664
            },
            {
                "time": "Mon, Apr 8, 8:11 PM",
                "value": 68.91999816894531
            },
            {
                "time": "Mon, Apr 8, 8:11 PM",
                "value": 68.8499984741211
            },
            {
                "time": "Mon, Apr 8, 8:12 PM",
                "value": 68.7699966430664
            },
            {
                "time": "Mon, Apr 8, 8:12 PM",
                "value": 68.73999786376953
            },
            {
                "time": "Mon, Apr 8, 8:13 PM",
                "value": 68.66999816894531
            },
            {
                "time": "Mon, Apr 8, 8:13 PM",
                "value": 68.62999725341797
            },
            {
                "time": "Mon, Apr 8, 8:14 PM",
                "value": 68.55999755859375
            },
            {
                "time": "Mon, Apr 8, 8:14 PM",
                "value": 68.4800033569336
            },
            {
                "time": "Mon, Apr 8, 8:15 PM",
                "value": 68.41000366210938
            },
            {
                "time": "Mon, Apr 8, 8:15 PM",
                "value": 68.33999633789062
            },
            {
                "time": "Mon, Apr 8, 8:16 PM",
                "value": 68.2699966430664
            },
            {
                "time": "Mon, Apr 8, 8:16 PM",
                "value": 68.19000244140625
            },
            {
                "time": "Mon, Apr 8, 8:17 PM",
                "value": 68.16000366210938
            },
            {
                "time": "Mon, Apr 8, 8:17 PM",
                "value": 68.08999633789062
            },
            {
                "time": "Mon, Apr 8, 8:18 PM",
                "value": 68.01000213623047
            },
            {
                "time": "Mon, Apr 8, 8:18 PM",
                "value": 67.9800033569336
            },
            {
                "time": "Mon, Apr 8, 8:19 PM",
                "value": 67.91000366210938
            },
            {
                "time": "Mon, Apr 8, 8:19 PM",
                "value": 67.80000305175781
            },
            {
                "time": "Mon, Apr 8, 8:20 PM",
                "value": 67.76000213623047
            },
            {
                "time": "Mon, Apr 8, 8:20 PM",
                "value": 67.69000244140625
            },
            {
                "time": "Mon, Apr 8, 8:21 PM",
                "value": 67.6500015258789
            },
            {
                "time": "Mon, Apr 8, 8:21 PM",
                "value": 67.61000061035156
            },
            {
                "time": "Mon, Apr 8, 8:22 PM",
                "value": 67.58000183105469
            },
            {
                "time": "Mon, Apr 8, 8:22 PM",
                "value": 67.47000122070312
            },
            {
                "time": "Mon, Apr 8, 8:23 PM",
                "value": 67.44000244140625
            },
            {
                "time": "Mon, Apr 8, 8:23 PM",
                "value": 67.36000061035156
            },
            {
                "time": "Mon, Apr 8, 8:24 PM",
                "value": 67.29000091552734
            },
            {
                "time": "Mon, Apr 8, 8:24 PM",
                "value": 67.33000183105469
            },
            {
                "time": "Mon, Apr 8, 8:25 PM",
                "value": 67.22000122070312
            },
            {
                "time": "Mon, Apr 8, 8:25 PM",
                "value": 67.18000030517578
            },
            {
                "time": "Mon, Apr 8, 8:26 PM",
                "value": 67.1500015258789
            },
            {
                "time": "Mon, Apr 8, 8:26 PM",
                "value": 67.1500015258789
            },
            {
                "time": "Mon, Apr 8, 8:27 PM",
                "value": 67.11000061035156
            },
            {
                "time": "Mon, Apr 8, 8:27 PM",
                "value": 67.1500015258789
            },
            {
                "time": "Mon, Apr 8, 8:28 PM",
                "value": 67.04000091552734
            },
            {
                "time": "Mon, Apr 8, 8:28 PM",
                "value": 67
            },
            {
                "time": "Mon, Apr 8, 8:29 PM",
                "value": 66.95999908447266
            },
            {
                "time": "Mon, Apr 8, 8:29 PM",
                "value": 66.93000030517578
            },
            {
                "time": "Mon, Apr 8, 8:30 PM",
                "value": 66.88999938964844
            },
            {
                "time": "Mon, Apr 8, 8:30 PM",
                "value": 66.86000061035156
            },
            {
                "time": "Mon, Apr 8, 8:31 PM",
                "value": 66.81999969482422
            },
            {
                "time": "Mon, Apr 8, 8:31 PM",
                "value": 66.77999877929688
            },
            {
                "time": "Mon, Apr 8, 8:32 PM",
                "value": 66.75
            },
            {
                "time": "Mon, Apr 8, 8:32 PM",
                "value": 66.70999908447266
            },
            {
                "time": "Mon, Apr 8, 8:33 PM",
                "value": 66.70999908447266
            },
            {
                "time": "Mon, Apr 8, 8:33 PM",
                "value": 66.83000183105469
            },
            {
                "time": "Mon, Apr 8, 8:34 PM",
                "value": 66.68000030517578
            },
            {
                "time": "Mon, Apr 8, 8:34 PM",
                "value": 66.68000030517578
            },
            {
                "time": "Mon, Apr 8, 8:35 PM",
                "value": 66.63999938964844
            },
            {
                "time": "Mon, Apr 8, 8:35 PM",
                "value": 66.63999938964844
            },
            {
                "time": "Mon, Apr 8, 8:36 PM",
                "value": 66.5999984741211
            },
            {
                "time": "Mon, Apr 8, 8:36 PM",
                "value": 66.5999984741211
            },
            {
                "time": "Mon, Apr 8, 8:37 PM",
                "value": 66.56999969482422
            },
            {
                "time": "Mon, Apr 8, 8:37 PM",
                "value": 66.52999877929688
            },
            {
                "time": "Mon, Apr 8, 8:38 PM",
                "value": 66.5
            },
            {
                "time": "Mon, Apr 8, 8:38 PM",
                "value": 66.45999908447266
            },
            {
                "time": "Mon, Apr 8, 8:39 PM",
                "value": 66.41999816894531
            },
            {
                "time": "Mon, Apr 8, 8:39 PM",
                "value": 66.38999938964844
            },
            {
                "time": "Mon, Apr 8, 8:40 PM",
                "value": 66.3499984741211
            },
            {
                "time": "Mon, Apr 8, 8:40 PM",
                "value": 66.27999877929688
            },
            {
                "time": "Mon, Apr 8, 8:41 PM",
                "value": 66.20999908447266
            },
            {
                "time": "Mon, Apr 8, 8:41 PM",
                "value": 66.16999816894531
            },
            {
                "time": "Mon, Apr 8, 8:42 PM",
                "value": 66.12999725341797
            },
            {
                "time": "Mon, Apr 8, 8:42 PM",
                "value": 66.0999984741211
            },
            {
                "time": "Mon, Apr 8, 8:43 PM",
                "value": 65.98999786376953
            },
            {
                "time": "Mon, Apr 8, 8:43 PM",
                "value": 65.91999816894531
            },
            {
                "time": "Mon, Apr 8, 8:44 PM",
                "value": 65.83999633789062
            },
            {
                "time": "Mon, Apr 8, 8:44 PM",
                "value": 65.7699966430664
            },
            {
                "time": "Mon, Apr 8, 8:45 PM",
                "value": 65.7300033569336
            },
            {
                "time": "Mon, Apr 8, 8:45 PM",
                "value": 65.69999694824219
            },
            {
                "time": "Mon, Apr 8, 8:46 PM",
                "value": 65.66000366210938
            },
            {
                "time": "Mon, Apr 8, 8:46 PM",
                "value": 65.55000305175781
            },
            {
                "time": "Mon, Apr 8, 8:47 PM",
                "value": 65.4800033569336
            },
            {
                "time": "Mon, Apr 8, 8:47 PM",
                "value": 65.44999694824219
            },
            {
                "time": "Mon, Apr 8, 8:48 PM",
                "value": 65.37999725341797
            },
            {
                "time": "Mon, Apr 8, 8:48 PM",
                "value": 65.33999633789062
            },
            {
                "time": "Mon, Apr 8, 8:49 PM",
                "value": 65.30000305175781
            },
            {
                "time": "Mon, Apr 8, 8:49 PM",
                "value": 65.30000305175781
            },
            {
                "time": "Mon, Apr 8, 8:50 PM",
                "value": 65.2699966430664
            },
            {
                "time": "Mon, Apr 8, 8:50 PM",
                "value": 65.2300033569336
            },
            {
                "time": "Mon, Apr 8, 8:51 PM",
                "value": 65.19000244140625
            },
            {
                "time": "Mon, Apr 8, 8:51 PM",
                "value": 65.19000244140625
            },
            {
                "time": "Mon, Apr 8, 8:52 PM",
                "value": 65.20999908447266
            },
            {
                "time": "Mon, Apr 8, 8:52 PM",
                "value": 65.12000274658203
            },
            {
                "time": "Mon, Apr 8, 8:53 PM",
                "value": 65.05000305175781
            },
            {
                "time": "Mon, Apr 8, 8:53 PM",
                "value": 65.01000213623047
            },
            {
                "time": "Mon, Apr 8, 8:54 PM",
                "value": 64.91000366210938
            },
            {
                "time": "Mon, Apr 8, 8:54 PM",
                "value": 64.83000183105469
            },
            {
                "time": "Mon, Apr 8, 8:55 PM",
                "value": 64.9000015258789
            },
            {
                "time": "Mon, Apr 8, 8:55 PM",
                "value": 64.7300033569336
            },
            {
                "time": "Mon, Apr 8, 8:56 PM",
                "value": 64.69000244140625
            },
            {
                "time": "Mon, Apr 8, 8:56 PM",
                "value": 64.6500015258789
            },
            {
                "time": "Mon, Apr 8, 8:57 PM",
                "value": 64.58000183105469
            },
            {
                "time": "Mon, Apr 8, 8:57 PM",
                "value": 64.54000091552734
            },
            {
                "time": "Mon, Apr 8, 8:58 PM",
                "value": 64.47000122070312
            },
            {
                "time": "Mon, Apr 8, 8:58 PM",
                "value": 64.43000030517578
            },
            {
                "time": "Mon, Apr 8, 8:59 PM",
                "value": 64.36000061035156
            },
            {
                "time": "Mon, Apr 8, 8:59 PM",
                "value": 64.29000091552734
            },
            {
                "time": "Mon, Apr 8, 9:00 PM",
                "value": 64.25
            },
            {
                "time": "Mon, Apr 8, 9:00 PM",
                "value": 64.18000030517578
            },
            {
                "time": "Mon, Apr 8, 9:01 PM",
                "value": 64.11000061035156
            },
            {
                "time": "Mon, Apr 8, 9:01 PM",
                "value": 64.06999969482422
            },
            {
                "time": "Mon, Apr 8, 9:02 PM",
                "value": 64
            },
            {
                "time": "Mon, Apr 8, 9:02 PM",
                "value": 63.959999084472656
            },
            {
                "time": "Mon, Apr 8, 9:03 PM",
                "value": 63.959999084472656
            },
            {
                "time": "Mon, Apr 8, 9:03 PM",
                "value": 63.959999084472656
            },
            {
                "time": "Mon, Apr 8, 9:04 PM",
                "value": 64
            },
            {
                "time": "Mon, Apr 8, 9:04 PM",
                "value": 64.06999969482422
            },
            {
                "time": "Mon, Apr 8, 9:05 PM",
                "value": 64.11000061035156
            },
            {
                "time": "Mon, Apr 8, 9:05 PM",
                "value": 64.1500015258789
            },
            {
                "time": "Mon, Apr 8, 9:06 PM",
                "value": 64.18000030517578
            },
            {
                "time": "Mon, Apr 8, 9:06 PM",
                "value": 64.22000122070312
            },
            {
                "time": "Mon, Apr 8, 9:07 PM",
                "value": 64.29000091552734
            },
            {
                "time": "Mon, Apr 8, 9:07 PM",
                "value": 64.36000061035156
            },
            {
                "time": "Mon, Apr 8, 9:08 PM",
                "value": 64.4000015258789
            },
            {
                "time": "Mon, Apr 8, 9:08 PM",
                "value": 64.47000122070312
            },
            {
                "time": "Mon, Apr 8, 9:09 PM",
                "value": 64.47000122070312
            },
            {
                "time": "Mon, Apr 8, 9:09 PM",
                "value": 64.51000213623047
            },
            {
                "time": "Mon, Apr 8, 9:10 PM",
                "value": 64.51000213623047
            },
            {
                "time": "Mon, Apr 8, 9:10 PM",
                "value": 64.5
            },
            {
                "time": "Mon, Apr 8, 9:11 PM",
                "value": 64.54000091552734
            },
            {
                "time": "Mon, Apr 8, 9:11 PM",
                "value": 64.54000091552734
            },
            {
                "time": "Mon, Apr 8, 9:12 PM",
                "value": 64.54000091552734
            },
            {
                "time": "Mon, Apr 8, 9:12 PM",
                "value": 64.54000091552734
            },
            {
                "time": "Mon, Apr 8, 9:13 PM",
                "value": 64.54000091552734
            },
            {
                "time": "Mon, Apr 8, 9:13 PM",
                "value": 64.54000091552734
            },
            {
                "time": "Mon, Apr 8, 9:14 PM",
                "value": 64.58000183105469
            },
            {
                "time": "Mon, Apr 8, 9:14 PM",
                "value": 64.58000183105469
            },
            {
                "time": "Mon, Apr 8, 9:15 PM",
                "value": 64.62000274658203
            },
            {
                "time": "Mon, Apr 8, 9:15 PM",
                "value": 64.62000274658203
            },
            {
                "time": "Mon, Apr 8, 9:16 PM",
                "value": 64.62000274658203
            },
            {
                "time": "Mon, Apr 8, 9:16 PM",
                "value": 64.72000122070312
            },
            {
                "time": "Mon, Apr 8, 9:17 PM",
                "value": 64.6500015258789
            },
            {
                "time": "Mon, Apr 8, 9:17 PM",
                "value": 64.6500015258789
            },
            {
                "time": "Mon, Apr 8, 9:18 PM",
                "value": 64.6500015258789
            },
            {
                "time": "Mon, Apr 8, 9:18 PM",
                "value": 64.6500015258789
            },
            {
                "time": "Mon, Apr 8, 9:19 PM",
                "value": 64.6500015258789
            },
            {
                "time": "Mon, Apr 8, 9:19 PM",
                "value": 64.6500015258789
            },
            {
                "time": "Mon, Apr 8, 9:20 PM",
                "value": 64.61000061035156
            },
            {
                "time": "Mon, Apr 8, 9:20 PM",
                "value": 64.58000183105469
            },
            {
                "time": "Mon, Apr 8, 9:21 PM",
                "value": 64.54000091552734
            },
            {
                "time": "Mon, Apr 8, 9:21 PM",
                "value": 64.51000213623047
            },
            {
                "time": "Mon, Apr 8, 9:22 PM",
                "value": 64.47000122070312
            },
            {
                "time": "Mon, Apr 8, 9:22 PM",
                "value": 64.47000122070312
            },
            {
                "time": "Mon, Apr 8, 9:23 PM",
                "value": 64.44000244140625
            },
            {
                "time": "Mon, Apr 8, 9:23 PM",
                "value": 64.4000015258789
            },
            {
                "time": "Mon, Apr 8, 9:24 PM",
                "value": 64.36000061035156
            },
            {
                "time": "Mon, Apr 8, 9:24 PM",
                "value": 64.29000091552734
            },
            {
                "time": "Mon, Apr 8, 9:25 PM",
                "value": 64.25
            },
            {
                "time": "Mon, Apr 8, 9:25 PM",
                "value": 64.22000122070312
            },
            {
                "time": "Mon, Apr 8, 9:26 PM",
                "value": 64.22000122070312
            },
            {
                "time": "Mon, Apr 8, 9:26 PM",
                "value": 64.18000030517578
            },
            {
                "time": "Mon, Apr 8, 9:27 PM",
                "value": 64.13999938964844
            },
            {
                "time": "Mon, Apr 8, 9:27 PM",
                "value": 64.13999938964844
            },
            {
                "time": "Mon, Apr 8, 9:28 PM",
                "value": 64.11000061035156
            },
            {
                "time": "Mon, Apr 8, 9:28 PM",
                "value": 64.06999969482422
            },
            {
                "time": "Mon, Apr 8, 9:29 PM",
                "value": 64.06999969482422
            },
            {
                "time": "Mon, Apr 8, 9:29 PM",
                "value": 64.06999969482422
            },
            {
                "time": "Mon, Apr 8, 9:30 PM",
                "value": 64.06999969482422
            },
            {
                "time": "Mon, Apr 8, 9:30 PM",
                "value": 64.06999969482422
            },
            {
                "time": "Mon, Apr 8, 9:31 PM",
                "value": 64.04000091552734
            },
            {
                "time": "Mon, Apr 8, 9:31 PM",
                "value": 64
            },
            {
                "time": "Mon, Apr 8, 9:32 PM",
                "value": 63.93000030517578
            },
            {
                "time": "Mon, Apr 8, 9:32 PM",
                "value": 63.88999938964844
            },
            {
                "time": "Mon, Apr 8, 9:33 PM",
                "value": 63.779998779296875
            },
            {
                "time": "Mon, Apr 8, 9:33 PM",
                "value": 63.709999084472656
            },
            {
                "time": "Mon, Apr 8, 9:34 PM",
                "value": 63.66999816894531
            },
            {
                "time": "Mon, Apr 8, 9:34 PM",
                "value": 63.56999969482422
            },
            {
                "time": "Mon, Apr 8, 9:35 PM",
                "value": 63.5
            },
            {
                "time": "Mon, Apr 8, 9:35 PM",
                "value": 63.459999084472656
            },
            {
                "time": "Mon, Apr 8, 9:36 PM",
                "value": 63.41999816894531
            },
            {
                "time": "Mon, Apr 8, 9:36 PM",
                "value": 63.349998474121094
            },
            {
                "time": "Mon, Apr 8, 9:37 PM",
                "value": 63.2400016784668
            },
            {
                "time": "Mon, Apr 8, 9:37 PM",
                "value": 63.16999816894531
            },
            {
                "time": "Mon, Apr 8, 9:38 PM",
                "value": 63.119998931884766
            },
            {
                "time": "Mon, Apr 8, 9:38 PM",
                "value": 62.95000076293945
            },
            {
                "time": "Mon, Apr 8, 9:39 PM",
                "value": 62.810001373291016
            },
            {
                "time": "Mon, Apr 8, 9:39 PM",
                "value": 62.7400016784668
            },
            {
                "time": "Mon, Apr 8, 9:40 PM",
                "value": 62.70000076293945
            },
            {
                "time": "Mon, Apr 8, 9:40 PM",
                "value": 62.630001068115234
            },
            {
                "time": "Mon, Apr 8, 9:41 PM",
                "value": 62.54999923706055
            },
            {
                "time": "Mon, Apr 8, 9:41 PM",
                "value": 62.529998779296875
            },
            {
                "time": "Mon, Apr 8, 9:42 PM",
                "value": 62.45000076293945
            },
            {
                "time": "Mon, Apr 8, 9:42 PM",
                "value": 62.369998931884766
            },
            {
                "time": "Mon, Apr 8, 9:43 PM",
                "value": 62.29999923706055
            },
            {
                "time": "Mon, Apr 8, 9:43 PM",
                "value": 62.189998626708984
            },
            {
                "time": "Mon, Apr 8, 9:44 PM",
                "value": 62.0099983215332
            },
            {
                "time": "Mon, Apr 8, 9:44 PM",
                "value": 61.869998931884766
            },
            {
                "time": "Mon, Apr 8, 9:45 PM",
                "value": 61.790000915527344
            },
            {
                "time": "Mon, Apr 8, 9:45 PM",
                "value": 61.720001220703125
            },
            {
                "time": "Mon, Apr 8, 9:46 PM",
                "value": 61.61000061035156
            },
            {
                "time": "Mon, Apr 8, 9:46 PM",
                "value": 61.5099983215332
            },
            {
                "time": "Mon, Apr 8, 9:47 PM",
                "value": 61.43000030517578
            },
            {
                "time": "Mon, Apr 8, 9:47 PM",
                "value": 61.36000061035156
            },
            {
                "time": "Mon, Apr 8, 9:48 PM",
                "value": 61.290000915527344
            },
            {
                "time": "Mon, Apr 8, 9:48 PM",
                "value": 61.25
            },
            {
                "time": "Mon, Apr 8, 9:49 PM",
                "value": 61.18000030517578
            },
            {
                "time": "Mon, Apr 8, 9:49 PM",
                "value": 61.11000061035156
            },
            {
                "time": "Mon, Apr 8, 9:50 PM",
                "value": 61.06999969482422
            },
            {
                "time": "Mon, Apr 8, 9:50 PM",
                "value": 61
            },
            {
                "time": "Mon, Apr 8, 9:51 PM",
                "value": 60.93000030517578
            },
            {
                "time": "Mon, Apr 8, 9:51 PM",
                "value": 60.86000061035156
            },
            {
                "time": "Mon, Apr 8, 9:52 PM",
                "value": 60.81999969482422
            },
            {
                "time": "Mon, Apr 8, 9:52 PM",
                "value": 60.779998779296875
            },
            {
                "time": "Mon, Apr 8, 9:53 PM",
                "value": 60.790000915527344
            },
            {
                "time": "Mon, Apr 8, 9:53 PM",
                "value": 60.790000915527344
            },
            {
                "time": "Mon, Apr 8, 9:54 PM",
                "value": 60.75
            },
            {
                "time": "Mon, Apr 8, 9:54 PM",
                "value": 60.709999084472656
            },
            {
                "time": "Mon, Apr 8, 9:55 PM",
                "value": 60.68000030517578
            },
            {
                "time": "Mon, Apr 8, 9:55 PM",
                "value": 60.63999938964844
            },
            {
                "time": "Mon, Apr 8, 9:56 PM",
                "value": 60.56999969482422
            },
            {
                "time": "Mon, Apr 8, 9:56 PM",
                "value": 60.5
            },
            {
                "time": "Mon, Apr 8, 9:57 PM",
                "value": 60.459999084472656
            },
            {
                "time": "Mon, Apr 8, 9:57 PM",
                "value": 60.41999816894531
            },
            {
                "time": "Mon, Apr 8, 9:58 PM",
                "value": 60.38999938964844
            },
            {
                "time": "Mon, Apr 8, 9:58 PM",
                "value": 60.349998474121094
            },
            {
                "time": "Mon, Apr 8, 9:59 PM",
                "value": 60.310001373291016
            },
            {
                "time": "Mon, Apr 8, 9:59 PM",
                "value": 60.279998779296875
            },
            {
                "time": "Mon, Apr 8, 10:00 PM",
                "value": 60.2400016784668
            },
            {
                "time": "Mon, Apr 8, 10:00 PM",
                "value": 60.2400016784668
            },
            {
                "time": "Mon, Apr 8, 10:01 PM",
                "value": 60.16999816894531
            },
            {
                "time": "Mon, Apr 8, 10:01 PM",
                "value": 60.130001068115234
            },
            {
                "time": "Mon, Apr 8, 10:02 PM",
                "value": 60.16999816894531
            },
            {
                "time": "Mon, Apr 8, 10:02 PM",
                "value": 60.16999816894531
            },
            {
                "time": "Mon, Apr 8, 10:03 PM",
                "value": 60.16999816894531
            },
            {
                "time": "Mon, Apr 8, 10:03 PM",
                "value": 60.16999816894531
            },
            {
                "time": "Mon, Apr 8, 10:04 PM",
                "value": 60.130001068115234
            },
            {
                "time": "Mon, Apr 8, 10:04 PM",
                "value": 60.119998931884766
            },
            {
                "time": "Mon, Apr 8, 10:05 PM",
                "value": 60.08000183105469
            },
            {
                "time": "Mon, Apr 8, 10:05 PM",
                "value": 60.060001373291016
            },
            {
                "time": "Mon, Apr 8, 10:06 PM",
                "value": 60.029998779296875
            },
            {
                "time": "Mon, Apr 8, 10:06 PM",
                "value": 60.02000045776367
            },
            {
                "time": "Mon, Apr 8, 10:07 PM",
                "value": 60.02000045776367
            },
            {
                "time": "Mon, Apr 8, 10:07 PM",
                "value": 59.95000076293945
            },
            {
                "time": "Mon, Apr 8, 10:08 PM",
                "value": 59.91999816894531
            },
            {
                "time": "Mon, Apr 8, 10:08 PM",
                "value": 59.91999816894531
            },
            {
                "time": "Mon, Apr 8, 10:09 PM",
                "value": 59.90999984741211
            },
            {
                "time": "Mon, Apr 8, 10:09 PM",
                "value": 59.91999816894531
            },
            {
                "time": "Mon, Apr 8, 10:10 PM",
                "value": 59.91999816894531
            },
            {
                "time": "Mon, Apr 8, 10:10 PM",
                "value": 59.91999816894531
            },
            {
                "time": "Mon, Apr 8, 10:11 PM",
                "value": 59.95000076293945
            },
            {
                "time": "Mon, Apr 8, 10:11 PM",
                "value": 59.9900016784668
            },
            {
                "time": "Mon, Apr 8, 10:12 PM",
                "value": 59.9900016784668
            },
            {
                "time": "Mon, Apr 8, 10:12 PM",
                "value": 60.02000045776367
            },
            {
                "time": "Mon, Apr 8, 10:13 PM",
                "value": 60.060001373291016
            },
            {
                "time": "Mon, Apr 8, 10:13 PM",
                "value": 60.130001068115234
            },
            {
                "time": "Mon, Apr 8, 10:14 PM",
                "value": 60.16999816894531
            },
            {
                "time": "Mon, Apr 8, 10:14 PM",
                "value": 60.16999816894531
            },
            {
                "time": "Mon, Apr 8, 10:15 PM",
                "value": 60.16999816894531
            },
            {
                "time": "Mon, Apr 8, 10:15 PM",
                "value": 60.16999816894531
            },
            {
                "time": "Mon, Apr 8, 10:16 PM",
                "value": 60.16999816894531
            },
            {
                "time": "Mon, Apr 8, 10:16 PM",
                "value": 60.16999816894531
            },
            {
                "time": "Mon, Apr 8, 10:17 PM",
                "value": 60.27000045776367
            },
            {
                "time": "Mon, Apr 8, 10:17 PM",
                "value": 60.2400016784668
            },
            {
                "time": "Mon, Apr 8, 10:18 PM",
                "value": 60.310001373291016
            },
            {
                "time": "Mon, Apr 8, 10:18 PM",
                "value": 60.349998474121094
            },
            {
                "time": "Mon, Apr 8, 10:19 PM",
                "value": 60.38999938964844
            },
            {
                "time": "Mon, Apr 8, 10:19 PM",
                "value": 60.459999084472656
            },
            {
                "time": "Mon, Apr 8, 10:20 PM",
                "value": 60.529998779296875
            },
            {
                "time": "Mon, Apr 8, 10:20 PM",
                "value": 60.56999969482422
            },
            {
                "time": "Mon, Apr 8, 10:21 PM",
                "value": 60.650001525878906
            },
            {
                "time": "Mon, Apr 8, 10:21 PM",
                "value": 60.599998474121094
            },
            {
                "time": "Mon, Apr 8, 10:22 PM",
                "value": 60.599998474121094
            },
            {
                "time": "Mon, Apr 8, 10:22 PM",
                "value": 60.599998474121094
            },
            {
                "time": "Mon, Apr 8, 10:23 PM",
                "value": 60.599998474121094
            },
            {
                "time": "Mon, Apr 8, 10:23 PM",
                "value": 60.599998474121094
            },
            {
                "time": "Mon, Apr 8, 10:24 PM",
                "value": 60.599998474121094
            },
            {
                "time": "Mon, Apr 8, 10:24 PM",
                "value": 60.599998474121094
            },
            {
                "time": "Mon, Apr 8, 10:25 PM",
                "value": 60.66999816894531
            },
            {
                "time": "Mon, Apr 8, 10:25 PM",
                "value": 60.599998474121094
            },
            {
                "time": "Mon, Apr 8, 10:26 PM",
                "value": 60.599998474121094
            },
            {
                "time": "Mon, Apr 8, 10:26 PM",
                "value": 60.63999938964844
            },
            {
                "time": "Mon, Apr 8, 10:27 PM",
                "value": 60.68000030517578
            },
            {
                "time": "Mon, Apr 8, 10:27 PM",
                "value": 60.66999816894531
            },
            {
                "time": "Mon, Apr 8, 10:28 PM",
                "value": 60.63999938964844
            },
            {
                "time": "Mon, Apr 8, 10:28 PM",
                "value": 60.599998474121094
            },
            {
                "time": "Mon, Apr 8, 10:29 PM",
                "value": 60.56999969482422
            },
            {
                "time": "Mon, Apr 8, 10:29 PM",
                "value": 60.56999969482422
            },
            {
                "time": "Mon, Apr 8, 10:30 PM",
                "value": 60.56999969482422
            },
            {
                "time": "Mon, Apr 8, 10:30 PM",
                "value": 60.63999938964844
            },
            {
                "time": "Mon, Apr 8, 10:31 PM",
                "value": 60.709999084472656
            },
            {
                "time": "Mon, Apr 8, 10:31 PM",
                "value": 60.81999969482422
            },
            {
                "time": "Mon, Apr 8, 10:32 PM",
                "value": 60.93000030517578
            },
            {
                "time": "Mon, Apr 8, 10:32 PM",
                "value": 61
            },
            {
                "time": "Mon, Apr 8, 10:33 PM",
                "value": 61
            },
            {
                "time": "Mon, Apr 8, 10:33 PM",
                "value": 61
            },
            {
                "time": "Mon, Apr 8, 10:34 PM",
                "value": 60.959999084472656
            },
            {
                "time": "Mon, Apr 8, 10:34 PM",
                "value": 60.93000030517578
            },
            {
                "time": "Mon, Apr 8, 10:35 PM",
                "value": 60.959999084472656
            },
            {
                "time": "Mon, Apr 8, 10:35 PM",
                "value": 61.029998779296875
            },
            {
                "time": "Mon, Apr 8, 10:36 PM",
                "value": 61.040000915527344
            },
            {
                "time": "Mon, Apr 8, 10:36 PM",
                "value": 61.06999969482422
            },
            {
                "time": "Mon, Apr 8, 10:37 PM",
                "value": 61.18000030517578
            },
            {
                "time": "Mon, Apr 8, 10:37 PM",
                "value": 61.220001220703125
            },
            {
                "time": "Mon, Apr 8, 10:38 PM",
                "value": 61.25
            },
            {
                "time": "Mon, Apr 8, 10:38 PM",
                "value": 61.349998474121094
            },
            {
                "time": "Mon, Apr 8, 10:39 PM",
                "value": 61.33000183105469
            },
            {
                "time": "Mon, Apr 8, 10:39 PM",
                "value": 61.43000030517578
            },
            {
                "time": "Mon, Apr 8, 10:40 PM",
                "value": 61.58000183105469
            },
            {
                "time": "Mon, Apr 8, 10:40 PM",
                "value": 61.650001525878906
            },
            {
                "time": "Mon, Apr 8, 10:41 PM",
                "value": 61.650001525878906
            },
            {
                "time": "Mon, Apr 8, 10:41 PM",
                "value": 61.650001525878906
            },
            {
                "time": "Mon, Apr 8, 10:42 PM",
                "value": 61.650001525878906
            },
            {
                "time": "Mon, Apr 8, 10:42 PM",
                "value": 61.650001525878906
            },
            {
                "time": "Mon, Apr 8, 10:43 PM",
                "value": 61.58000183105469
            },
            {
                "time": "Mon, Apr 8, 10:43 PM",
                "value": 61.540000915527344
            },
            {
                "time": "Mon, Apr 8, 10:44 PM",
                "value": 61.470001220703125
            },
            {
                "time": "Mon, Apr 8, 10:44 PM",
                "value": 61.470001220703125
            },
            {
                "time": "Mon, Apr 8, 10:45 PM",
                "value": 61.5099983215332
            },
            {
                "time": "Mon, Apr 8, 10:45 PM",
                "value": 61.470001220703125
            },
            {
                "time": "Mon, Apr 8, 10:46 PM",
                "value": 61.470001220703125
            },
            {
                "time": "Mon, Apr 8, 10:46 PM",
                "value": 61.43000030517578
            },
            {
                "time": "Mon, Apr 8, 10:47 PM",
                "value": 61.400001525878906
            },
            {
                "time": "Mon, Apr 8, 10:47 PM",
                "value": 61.36000061035156
            },
            {
                "time": "Mon, Apr 8, 10:48 PM",
                "value": 61.400001525878906
            },
            {
                "time": "Mon, Apr 8, 10:48 PM",
                "value": 61.43000030517578
            },
            {
                "time": "Mon, Apr 8, 10:49 PM",
                "value": 61.36000061035156
            },
            {
                "time": "Mon, Apr 8, 10:49 PM",
                "value": 61.33000183105469
            },
            {
                "time": "Mon, Apr 8, 10:50 PM",
                "value": 61.31999969482422
            },
            {
                "time": "Mon, Apr 8, 10:50 PM",
                "value": 61.33000183105469
            },
            {
                "time": "Mon, Apr 8, 10:51 PM",
                "value": 61.400001525878906
            },
            {
                "time": "Mon, Apr 8, 10:51 PM",
                "value": 61.33000183105469
            },
            {
                "time": "Mon, Apr 8, 10:52 PM",
                "value": 61.36000061035156
            },
            {
                "time": "Mon, Apr 8, 10:52 PM",
                "value": 61.36000061035156
            },
            {
                "time": "Mon, Apr 8, 10:53 PM",
                "value": 61.400001525878906
            },
            {
                "time": "Mon, Apr 8, 10:53 PM",
                "value": 61.400001525878906
            },
            {
                "time": "Mon, Apr 8, 10:54 PM",
                "value": 61.439998626708984
            },
            {
                "time": "Mon, Apr 8, 10:54 PM",
                "value": 61.43000030517578
            },
            {
                "time": "Mon, Apr 8, 10:55 PM",
                "value": 61.43000030517578
            },
            {
                "time": "Mon, Apr 8, 10:55 PM",
                "value": 61.470001220703125
            },
            {
                "time": "Mon, Apr 8, 10:56 PM",
                "value": 61.470001220703125
            },
            {
                "time": "Mon, Apr 8, 10:56 PM",
                "value": 61.43000030517578
            },
            {
                "time": "Mon, Apr 8, 10:57 PM",
                "value": 61.43000030517578
            },
            {
                "time": "Mon, Apr 8, 10:57 PM",
                "value": 61.400001525878906
            },
            {
                "time": "Mon, Apr 8, 10:58 PM",
                "value": 61.400001525878906
            },
            {
                "time": "Mon, Apr 8, 10:58 PM",
                "value": 61.400001525878906
            },
            {
                "time": "Mon, Apr 8, 10:59 PM",
                "value": 61.400001525878906
            },
            {
                "time": "Mon, Apr 8, 10:59 PM",
                "value": 61.43000030517578
            },
            {
                "time": "Mon, Apr 8, 11:00 PM",
                "value": 61.470001220703125
            },
            {
                "time": "Mon, Apr 8, 11:00 PM",
                "value": 61.58000183105469
            },
            {
                "time": "Mon, Apr 8, 11:01 PM",
                "value": 61.5099983215332
            },
            {
                "time": "Mon, Apr 8, 11:01 PM",
                "value": 61.540000915527344
            },
            {
                "time": "Mon, Apr 8, 11:02 PM",
                "value": 61.5099983215332
            },
            {
                "time": "Mon, Apr 8, 11:02 PM",
                "value": 61.5099983215332
            },
            {
                "time": "Mon, Apr 8, 11:03 PM",
                "value": 61.45000076293945
            },
            {
                "time": "Mon, Apr 8, 11:03 PM",
                "value": 61.400001525878906
            },
            {
                "time": "Mon, Apr 8, 11:04 PM",
                "value": 61.36000061035156
            },
            {
                "time": "Mon, Apr 8, 11:04 PM",
                "value": 61.36000061035156
            },
            {
                "time": "Mon, Apr 8, 11:05 PM",
                "value": 61.290000915527344
            },
            {
                "time": "Mon, Apr 8, 11:05 PM",
                "value": 61.25
            },
            {
                "time": "Mon, Apr 8, 11:06 PM",
                "value": 61.220001220703125
            },
            {
                "time": "Mon, Apr 8, 11:06 PM",
                "value": 61.18000030517578
            },
            {
                "time": "Mon, Apr 8, 11:07 PM",
                "value": 61.13999938964844
            },
            {
                "time": "Mon, Apr 8, 11:07 PM",
                "value": 61.11000061035156
            },
            {
                "time": "Mon, Apr 8, 11:08 PM",
                "value": 61.040000915527344
            },
            {
                "time": "Mon, Apr 8, 11:08 PM",
                "value": 60.959999084472656
            },
            {
                "time": "Mon, Apr 8, 11:09 PM",
                "value": 60.88999938964844
            },
            {
                "time": "Mon, Apr 8, 11:09 PM",
                "value": 60.88999938964844
            },
            {
                "time": "Mon, Apr 8, 11:10 PM",
                "value": 60.88999938964844
            },
            {
                "time": "Mon, Apr 8, 11:10 PM",
                "value": 60.88999938964844
            },
            {
                "time": "Mon, Apr 8, 11:11 PM",
                "value": 60.88999938964844
            },
            {
                "time": "Mon, Apr 8, 11:11 PM",
                "value": 60.849998474121094
            },
            {
                "time": "Mon, Apr 8, 11:12 PM",
                "value": 60.81999969482422
            },
            {
                "time": "Mon, Apr 8, 11:12 PM",
                "value": 60.75
            },
            {
                "time": "Mon, Apr 8, 11:13 PM",
                "value": 60.709999084472656
            },
            {
                "time": "Mon, Apr 8, 11:13 PM",
                "value": 60.66999816894531
            },
            {
                "time": "Mon, Apr 8, 11:14 PM",
                "value": 60.68000030517578
            },
            {
                "time": "Mon, Apr 8, 11:14 PM",
                "value": 60.529998779296875
            },
            {
                "time": "Mon, Apr 8, 11:15 PM",
                "value": 60.459999084472656
            },
            {
                "time": "Mon, Apr 8, 11:15 PM",
                "value": 60.41999816894531
            },
            {
                "time": "Mon, Apr 8, 11:16 PM",
                "value": 60.459999084472656
            },
            {
                "time": "Mon, Apr 8, 11:16 PM",
                "value": 60.529998779296875
            },
            {
                "time": "Mon, Apr 8, 11:17 PM",
                "value": 60.529998779296875
            },
            {
                "time": "Mon, Apr 8, 11:17 PM",
                "value": 60.459999084472656
            },
            {
                "time": "Mon, Apr 8, 11:18 PM",
                "value": 60.349998474121094
            },
            {
                "time": "Mon, Apr 8, 11:18 PM",
                "value": 60.279998779296875
            },
            {
                "time": "Mon, Apr 8, 11:19 PM",
                "value": 60.2400016784668
            },
            {
                "time": "Mon, Apr 8, 11:19 PM",
                "value": 60.209999084472656
            },
            {
                "time": "Mon, Apr 8, 11:20 PM",
                "value": 60.16999816894531
            },
            {
                "time": "Mon, Apr 8, 11:20 PM",
                "value": 60.130001068115234
            },
            {
                "time": "Mon, Apr 8, 11:21 PM",
                "value": 60.060001373291016
            },
            {
                "time": "Mon, Apr 8, 11:21 PM",
                "value": 59.9900016784668
            },
            {
                "time": "Mon, Apr 8, 11:22 PM",
                "value": 59.95000076293945
            },
            {
                "time": "Mon, Apr 8, 11:22 PM",
                "value": 59.91999816894531
            },
            {
                "time": "Mon, Apr 8, 11:23 PM",
                "value": 59.95000076293945
            },
            {
                "time": "Mon, Apr 8, 11:23 PM",
                "value": 59.9900016784668
            },
            {
                "time": "Mon, Apr 8, 11:24 PM",
                "value": 60.029998779296875
            },
            {
                "time": "Mon, Apr 8, 11:24 PM",
                "value": 60.029998779296875
            },
            {
                "time": "Mon, Apr 8, 11:25 PM",
                "value": 60.02000045776367
            },
            {
                "time": "Mon, Apr 8, 11:25 PM",
                "value": 59.9900016784668
            },
            {
                "time": "Mon, Apr 8, 11:26 PM",
                "value": 59.9900016784668
            },
            {
                "time": "Mon, Apr 8, 11:26 PM",
                "value": 60.02000045776367
            },
            {
                "time": "Mon, Apr 8, 11:27 PM",
                "value": 60.060001373291016
            },
            {
                "time": "Mon, Apr 8, 11:27 PM",
                "value": 60.130001068115234
            },
            {
                "time": "Mon, Apr 8, 11:28 PM",
                "value": 60.130001068115234
            },
            {
                "time": "Mon, Apr 8, 11:28 PM",
                "value": 60.20000076293945
            },
            {
                "time": "Mon, Apr 8, 11:29 PM",
                "value": 60.2400016784668
            },
            {
                "time": "Mon, Apr 8, 11:29 PM",
                "value": 60.34000015258789
            },
            {
                "time": "Mon, Apr 8, 11:30 PM",
                "value": 60.380001068115234
            },
            {
                "time": "Mon, Apr 8, 11:30 PM",
                "value": 60.459999084472656
            },
            {
                "time": "Mon, Apr 8, 11:31 PM",
                "value": 60.5
            },
            {
                "time": "Mon, Apr 8, 11:31 PM",
                "value": 60.4900016784668
            },
            {
                "time": "Mon, Apr 8, 11:32 PM",
                "value": 60.4900016784668
            },
            {
                "time": "Mon, Apr 8, 11:32 PM",
                "value": 60.529998779296875
            },
            {
                "time": "Mon, Apr 8, 11:33 PM",
                "value": 60.56999969482422
            },
            {
                "time": "Mon, Apr 8, 11:33 PM",
                "value": 60.56999969482422
            },
            {
                "time": "Mon, Apr 8, 11:34 PM",
                "value": 60.56999969482422
            },
            {
                "time": "Mon, Apr 8, 11:34 PM",
                "value": 60.56999969482422
            },
            {
                "time": "Mon, Apr 8, 11:35 PM",
                "value": 60.63999938964844
            },
            {
                "time": "Mon, Apr 8, 11:35 PM",
                "value": 60.66999816894531
            },
            {
                "time": "Mon, Apr 8, 11:36 PM",
                "value": 60.79999923706055
            },
            {
                "time": "Mon, Apr 8, 11:36 PM",
                "value": 61.06999969482422
            },
            {
                "time": "Mon, Apr 8, 11:37 PM",
                "value": 61.25
            },
            {
                "time": "Mon, Apr 8, 11:37 PM",
                "value": 61.31999969482422
            },
            {
                "time": "Mon, Apr 8, 11:38 PM",
                "value": 61.36000061035156
            },
            {
                "time": "Mon, Apr 8, 11:38 PM",
                "value": 61.36000061035156
            },
            {
                "time": "Mon, Apr 8, 11:39 PM",
                "value": 61.36000061035156
            },
            {
                "time": "Mon, Apr 8, 11:39 PM",
                "value": 61.36000061035156
            },
            {
                "time": "Mon, Apr 8, 11:40 PM",
                "value": 61.400001525878906
            },
            {
                "time": "Mon, Apr 8, 11:40 PM",
                "value": 61.33000183105469
            },
            {
                "time": "Mon, Apr 8, 11:41 PM",
                "value": 61.290000915527344
            },
            {
                "time": "Mon, Apr 8, 11:41 PM",
                "value": 61.25
            },
            {
                "time": "Mon, Apr 8, 11:42 PM",
                "value": 61.25
            },
            {
                "time": "Mon, Apr 8, 11:42 PM",
                "value": 61.220001220703125
            },
            {
                "time": "Mon, Apr 8, 11:43 PM",
                "value": 61.16999816894531
            },
            {
                "time": "Mon, Apr 8, 11:43 PM",
                "value": 61.11000061035156
            },
            {
                "time": "Mon, Apr 8, 11:44 PM",
                "value": 61.040000915527344
            },
            {
                "time": "Mon, Apr 8, 11:44 PM",
                "value": 61
            },
            {
                "time": "Mon, Apr 8, 11:45 PM",
                "value": 60.959999084472656
            },
            {
                "time": "Mon, Apr 8, 11:45 PM",
                "value": 60.959999084472656
            },
            {
                "time": "Mon, Apr 8, 11:46 PM",
                "value": 61
            },
            {
                "time": "Mon, Apr 8, 11:46 PM",
                "value": 61
            },
            {
                "time": "Mon, Apr 8, 11:47 PM",
                "value": 61.040000915527344
            },
            {
                "time": "Mon, Apr 8, 11:47 PM",
                "value": 61.040000915527344
            },
            {
                "time": "Mon, Apr 8, 11:48 PM",
                "value": 61.040000915527344
            },
            {
                "time": "Mon, Apr 8, 11:48 PM",
                "value": 61.13999938964844
            },
            {
                "time": "Mon, Apr 8, 11:49 PM",
                "value": 61.220001220703125
            },
            {
                "time": "Mon, Apr 8, 11:49 PM",
                "value": 61.25
            },
            {
                "time": "Mon, Apr 8, 11:50 PM",
                "value": 61.290000915527344
            },
            {
                "time": "Mon, Apr 8, 11:50 PM",
                "value": 61.36000061035156
            },
            {
                "time": "Mon, Apr 8, 11:51 PM",
                "value": 61.36000061035156
            },
            {
                "time": "Mon, Apr 8, 11:51 PM",
                "value": 61.400001525878906
            },
            {
                "time": "Mon, Apr 8, 11:52 PM",
                "value": 61.400001525878906
            },
            {
                "time": "Mon, Apr 8, 11:52 PM",
                "value": 61.400001525878906
            },
            {
                "time": "Mon, Apr 8, 11:53 PM",
                "value": 61.400001525878906
            },
            {
                "time": "Mon, Apr 8, 11:53 PM",
                "value": 61.400001525878906
            },
            {
                "time": "Mon, Apr 8, 11:54 PM",
                "value": 61.31999969482422
            },
            {
                "time": "Mon, Apr 8, 11:54 PM",
                "value": 61.31999969482422
            },
            {
                "time": "Mon, Apr 8, 11:55 PM",
                "value": 61.36000061035156
            },
            {
                "time": "Mon, Apr 8, 11:55 PM",
                "value": 61.43000030517578
            },
            {
                "time": "Mon, Apr 8, 11:56 PM",
                "value": 61.470001220703125
            },
            {
                "time": "Mon, Apr 8, 11:56 PM",
                "value": 61.540000915527344
            },
            {
                "time": "Mon, Apr 8, 11:57 PM",
                "value": 61.61000061035156
            },
            {
                "time": "Mon, Apr 8, 11:57 PM",
                "value": 61.650001525878906
            },
            {
                "time": "Mon, Apr 8, 11:58 PM",
                "value": 61.650001525878906
            },
            {
                "time": "Mon, Apr 8, 11:58 PM",
                "value": 61.689998626708984
            },
            {
                "time": "Mon, Apr 8, 11:59 PM",
                "value": 61.689998626708984
            },
            {
                "time": "Mon, Apr 8, 11:59 PM",
                "value": 61.720001220703125
            },
            {
                "time": "Tue, Apr 9, 12:00 AM",
                "value": 61.720001220703125
            },
            {
                "time": "Tue, Apr 9, 12:00 AM",
                "value": 61.7599983215332
            },
            {
                "time": "Tue, Apr 9, 12:01 AM",
                "value": 61.79999923706055
            },
            {
                "time": "Tue, Apr 9, 12:01 AM",
                "value": 61.83000183105469
            },
            {
                "time": "Tue, Apr 9, 12:02 AM",
                "value": 61.83000183105469
            },
            {
                "time": "Tue, Apr 9, 12:02 AM",
                "value": 61.869998931884766
            },
            {
                "time": "Tue, Apr 9, 12:03 AM",
                "value": 61.869998931884766
            },
            {
                "time": "Tue, Apr 9, 12:03 AM",
                "value": 61.869998931884766
            },
            {
                "time": "Tue, Apr 9, 12:04 AM",
                "value": 61.869998931884766
            },
            {
                "time": "Tue, Apr 9, 12:04 AM",
                "value": 61.869998931884766
            },
            {
                "time": "Tue, Apr 9, 12:05 AM",
                "value": 61.939998626708984
            },
            {
                "time": "Tue, Apr 9, 12:05 AM",
                "value": 61.869998931884766
            },
            {
                "time": "Tue, Apr 9, 12:06 AM",
                "value": 61.869998931884766
            },
            {
                "time": "Tue, Apr 9, 12:06 AM",
                "value": 61.869998931884766
            },
            {
                "time": "Tue, Apr 9, 12:07 AM",
                "value": 61.83000183105469
            },
            {
                "time": "Tue, Apr 9, 12:07 AM",
                "value": 61.83000183105469
            },
            {
                "time": "Tue, Apr 9, 12:08 AM",
                "value": 61.83000183105469
            },
            {
                "time": "Tue, Apr 9, 12:08 AM",
                "value": 61.83000183105469
            },
            {
                "time": "Tue, Apr 9, 12:09 AM",
                "value": 61.900001525878906
            },
            {
                "time": "Tue, Apr 9, 12:09 AM",
                "value": 61.83000183105469
            },
            {
                "time": "Tue, Apr 9, 12:10 AM",
                "value": 61.869998931884766
            },
            {
                "time": "Tue, Apr 9, 12:10 AM",
                "value": 61.869998931884766
            },
            {
                "time": "Tue, Apr 9, 12:11 AM",
                "value": 61.900001525878906
            },
            {
                "time": "Tue, Apr 9, 12:11 AM",
                "value": 61.900001525878906
            },
            {
                "time": "Tue, Apr 9, 12:12 AM",
                "value": 61.900001525878906
            },
            {
                "time": "Tue, Apr 9, 12:12 AM",
                "value": 61.869998931884766
            },
            {
                "time": "Tue, Apr 9, 12:13 AM",
                "value": 61.83000183105469
            },
            {
                "time": "Tue, Apr 9, 12:13 AM",
                "value": 61.83000183105469
            },
            {
                "time": "Tue, Apr 9, 12:14 AM",
                "value": 61.79999923706055
            },
            {
                "time": "Tue, Apr 9, 12:14 AM",
                "value": 61.790000915527344
            },
            {
                "time": "Tue, Apr 9, 12:15 AM",
                "value": 61.7599983215332
            },
            {
                "time": "Tue, Apr 9, 12:15 AM",
                "value": 61.7599983215332
            },
            {
                "time": "Tue, Apr 9, 12:16 AM",
                "value": 61.7599983215332
            },
            {
                "time": "Tue, Apr 9, 12:16 AM",
                "value": 61.83000183105469
            },
            {
                "time": "Tue, Apr 9, 12:17 AM",
                "value": 61.7599983215332
            },
            {
                "time": "Tue, Apr 9, 12:17 AM",
                "value": 61.7599983215332
            },
            {
                "time": "Tue, Apr 9, 12:18 AM",
                "value": 61.7599983215332
            },
            {
                "time": "Tue, Apr 9, 12:18 AM",
                "value": 61.7599983215332
            },
            {
                "time": "Tue, Apr 9, 12:19 AM",
                "value": 61.7599983215332
            },
            {
                "time": "Tue, Apr 9, 12:19 AM",
                "value": 61.790000915527344
            },
            {
                "time": "Tue, Apr 9, 12:20 AM",
                "value": 61.83000183105469
            },
            {
                "time": "Tue, Apr 9, 12:20 AM",
                "value": 61.900001525878906
            },
            {
                "time": "Tue, Apr 9, 12:21 AM",
                "value": 61.83000183105469
            },
            {
                "time": "Tue, Apr 9, 12:21 AM",
                "value": 61.83000183105469
            },
            {
                "time": "Tue, Apr 9, 12:22 AM",
                "value": 61.869998931884766
            },
            {
                "time": "Tue, Apr 9, 12:22 AM",
                "value": 61.869998931884766
            },
            {
                "time": "Tue, Apr 9, 12:23 AM",
                "value": 61.869998931884766
            },
            {
                "time": "Tue, Apr 9, 12:23 AM",
                "value": 61.869998931884766
            },
            {
                "time": "Tue, Apr 9, 12:24 AM",
                "value": 61.869998931884766
            },
            {
                "time": "Tue, Apr 9, 12:24 AM",
                "value": 61.83000183105469
            },
            {
                "time": "Tue, Apr 9, 12:25 AM",
                "value": 61.83000183105469
            },
            {
                "time": "Tue, Apr 9, 12:25 AM",
                "value": 61.869998931884766
            },
            {
                "time": "Tue, Apr 9, 12:26 AM",
                "value": 61.869998931884766
            },
            {
                "time": "Tue, Apr 9, 12:26 AM",
                "value": 61.869998931884766
            },
            {
                "time": "Tue, Apr 9, 12:27 AM",
                "value": 61.869998931884766
            },
            {
                "time": "Tue, Apr 9, 12:27 AM",
                "value": 61.869998931884766
            },
            {
                "time": "Tue, Apr 9, 12:28 AM",
                "value": 61.869998931884766
            },
            {
                "time": "Tue, Apr 9, 12:28 AM",
                "value": 61.869998931884766
            },
            {
                "time": "Tue, Apr 9, 12:29 AM",
                "value": 61.83000183105469
            },
            {
                "time": "Tue, Apr 9, 12:29 AM",
                "value": 61.83000183105469
            },
            {
                "time": "Tue, Apr 9, 12:30 AM",
                "value": 61.83000183105469
            },
            {
                "time": "Tue, Apr 9, 12:30 AM",
                "value": 61.83000183105469
            },
            {
                "time": "Tue, Apr 9, 12:31 AM",
                "value": 61.869998931884766
            },
            {
                "time": "Tue, Apr 9, 12:31 AM",
                "value": 61.869998931884766
            },
            {
                "time": "Tue, Apr 9, 12:32 AM",
                "value": 61.869998931884766
            },
            {
                "time": "Tue, Apr 9, 12:32 AM",
                "value": 61.900001525878906
            },
            {
                "time": "Tue, Apr 9, 12:33 AM",
                "value": 61.900001525878906
            },
            {
                "time": "Tue, Apr 9, 12:33 AM",
                "value": 61.900001525878906
            },
            {
                "time": "Tue, Apr 9, 12:34 AM",
                "value": 61.900001525878906
            },
            {
                "time": "Tue, Apr 9, 12:34 AM",
                "value": 61.939998626708984
            },
            {
                "time": "Tue, Apr 9, 12:35 AM",
                "value": 61.939998626708984
            },
            {
                "time": "Tue, Apr 9, 12:35 AM",
                "value": 61.939998626708984
            },
            {
                "time": "Tue, Apr 9, 12:36 AM",
                "value": 61.939998626708984
            },
            {
                "time": "Tue, Apr 9, 12:36 AM",
                "value": 61.939998626708984
            },
            {
                "time": "Tue, Apr 9, 12:37 AM",
                "value": 61.939998626708984
            },
            {
                "time": "Tue, Apr 9, 12:37 AM",
                "value": 61.97999954223633
            },
            {
                "time": "Tue, Apr 9, 12:38 AM",
                "value": 61.97999954223633
            },
            {
                "time": "Tue, Apr 9, 12:38 AM",
                "value": 61.970001220703125
            },
            {
                "time": "Tue, Apr 9, 12:39 AM",
                "value": 61.97999954223633
            },
            {
                "time": "Tue, Apr 9, 12:39 AM",
                "value": 61.970001220703125
            },
            {
                "time": "Tue, Apr 9, 12:40 AM",
                "value": 61.97999954223633
            },
            {
                "time": "Tue, Apr 9, 12:40 AM",
                "value": 61.970001220703125
            },
            {
                "time": "Tue, Apr 9, 12:41 AM",
                "value": 61.939998626708984
            },
            {
                "time": "Tue, Apr 9, 12:41 AM",
                "value": 61.939998626708984
            },
            {
                "time": "Tue, Apr 9, 12:42 AM",
                "value": 61.900001525878906
            },
            {
                "time": "Tue, Apr 9, 12:42 AM",
                "value": 61.900001525878906
            },
            {
                "time": "Tue, Apr 9, 12:43 AM",
                "value": 61.900001525878906
            },
            {
                "time": "Tue, Apr 9, 12:43 AM",
                "value": 61.869998931884766
            },
            {
                "time": "Tue, Apr 9, 12:44 AM",
                "value": 61.88999938964844
            },
            {
                "time": "Tue, Apr 9, 12:44 AM",
                "value": 61.83000183105469
            },
            {
                "time": "Tue, Apr 9, 12:45 AM",
                "value": 61.83000183105469
            },
            {
                "time": "Tue, Apr 9, 12:45 AM",
                "value": 61.83000183105469
            },
            {
                "time": "Tue, Apr 9, 12:46 AM",
                "value": 61.83000183105469
            },
            {
                "time": "Tue, Apr 9, 12:46 AM",
                "value": 61.79999923706055
            },
            {
                "time": "Tue, Apr 9, 12:47 AM",
                "value": 61.790000915527344
            },
            {
                "time": "Tue, Apr 9, 12:47 AM",
                "value": 61.83000183105469
            },
            {
                "time": "Tue, Apr 9, 12:48 AM",
                "value": 61.90999984741211
            },
            {
                "time": "Tue, Apr 9, 12:48 AM",
                "value": 61.79999923706055
            },
            {
                "time": "Tue, Apr 9, 12:49 AM",
                "value": 61.79999923706055
            },
            {
                "time": "Tue, Apr 9, 12:49 AM",
                "value": 61.7599983215332
            },
            {
                "time": "Tue, Apr 9, 12:50 AM",
                "value": 61.7599983215332
            },
            {
                "time": "Tue, Apr 9, 12:50 AM",
                "value": 61.790000915527344
            },
            {
                "time": "Tue, Apr 9, 12:51 AM",
                "value": 61.790000915527344
            },
            {
                "time": "Tue, Apr 9, 12:51 AM",
                "value": 61.7599983215332
            },
            {
                "time": "Tue, Apr 9, 12:52 AM",
                "value": 61.7599983215332
            },
            {
                "time": "Tue, Apr 9, 12:52 AM",
                "value": 61.720001220703125
            },
            {
                "time": "Tue, Apr 9, 12:53 AM",
                "value": 61.720001220703125
            },
            {
                "time": "Tue, Apr 9, 12:53 AM",
                "value": 61.68000030517578
            },
            {
                "time": "Tue, Apr 9, 12:54 AM",
                "value": 61.689998626708984
            },
            {
                "time": "Tue, Apr 9, 12:54 AM",
                "value": 61.689998626708984
            },
            {
                "time": "Tue, Apr 9, 12:55 AM",
                "value": 61.68000030517578
            },
            {
                "time": "Tue, Apr 9, 12:55 AM",
                "value": 61.689998626708984
            },
            {
                "time": "Tue, Apr 9, 12:56 AM",
                "value": 61.7599983215332
            },
            {
                "time": "Tue, Apr 9, 12:56 AM",
                "value": 61.720001220703125
            },
            {
                "time": "Tue, Apr 9, 12:57 AM",
                "value": 61.720001220703125
            },
            {
                "time": "Tue, Apr 9, 12:57 AM",
                "value": 61.7599983215332
            },
            {
                "time": "Tue, Apr 9, 12:58 AM",
                "value": 61.7599983215332
            },
            {
                "time": "Tue, Apr 9, 12:58 AM",
                "value": 61.790000915527344
            },
            {
                "time": "Tue, Apr 9, 12:59 AM",
                "value": 61.79999923706055
            },
            {
                "time": "Tue, Apr 9, 12:59 AM",
                "value": 61.790000915527344
            },
            {
                "time": "Tue, Apr 9, 1:00 AM",
                "value": 61.790000915527344
            },
            {
                "time": "Tue, Apr 9, 1:00 AM",
                "value": 61.83000183105469
            },
            {
                "time": "Tue, Apr 9, 1:01 AM",
                "value": 61.869998931884766
            },
            {
                "time": "Tue, Apr 9, 1:01 AM",
                "value": 61.900001525878906
            },
            {
                "time": "Tue, Apr 9, 1:02 AM",
                "value": 61.900001525878906
            },
            {
                "time": "Tue, Apr 9, 1:02 AM",
                "value": 61.939998626708984
            },
            {
                "time": "Tue, Apr 9, 1:03 AM",
                "value": 61.939998626708984
            },
            {
                "time": "Tue, Apr 9, 1:03 AM",
                "value": 61.939998626708984
            },
            {
                "time": "Tue, Apr 9, 1:04 AM",
                "value": 61.9900016784668
            },
            {
                "time": "Tue, Apr 9, 1:04 AM",
                "value": 61.97999954223633
            },
            {
                "time": "Tue, Apr 9, 1:05 AM",
                "value": 61.970001220703125
            },
            {
                "time": "Tue, Apr 9, 1:05 AM",
                "value": 61.97999954223633
            },
            {
                "time": "Tue, Apr 9, 1:06 AM",
                "value": 61.970001220703125
            },
            {
                "time": "Tue, Apr 9, 1:06 AM",
                "value": 61.939998626708984
            },
            {
                "time": "Tue, Apr 9, 1:07 AM",
                "value": 61.939998626708984
            },
            {
                "time": "Tue, Apr 9, 1:07 AM",
                "value": 61.939998626708984
            },
            {
                "time": "Tue, Apr 9, 1:08 AM",
                "value": 61.959999084472656
            },
            {
                "time": "Tue, Apr 9, 1:08 AM",
                "value": 61.97999954223633
            },
            {
                "time": "Tue, Apr 9, 1:09 AM",
                "value": 61.970001220703125
            },
            {
                "time": "Tue, Apr 9, 1:09 AM",
                "value": 61.97999954223633
            },
            {
                "time": "Tue, Apr 9, 1:10 AM",
                "value": 61.97999954223633
            },
            {
                "time": "Tue, Apr 9, 1:10 AM",
                "value": 61.939998626708984
            },
            {
                "time": "Tue, Apr 9, 1:11 AM",
                "value": 61.939998626708984
            },
            {
                "time": "Tue, Apr 9, 1:11 AM",
                "value": 61.939998626708984
            },
            {
                "time": "Tue, Apr 9, 1:12 AM",
                "value": 62.0099983215332
            },
            {
                "time": "Tue, Apr 9, 1:12 AM",
                "value": 61.939998626708984
            },
            {
                "time": "Tue, Apr 9, 1:13 AM",
                "value": 61.97999954223633
            },
            {
                "time": "Tue, Apr 9, 1:13 AM",
                "value": 61.97999954223633
            },
            {
                "time": "Tue, Apr 9, 1:14 AM",
                "value": 62.0099983215332
            },
            {
                "time": "Tue, Apr 9, 1:14 AM",
                "value": 62.0099983215332
            },
            {
                "time": "Tue, Apr 9, 1:15 AM",
                "value": 62.04999923706055
            },
            {
                "time": "Tue, Apr 9, 1:15 AM",
                "value": 62.08000183105469
            },
            {
                "time": "Tue, Apr 9, 1:16 AM",
                "value": 62.119998931884766
            },
            {
                "time": "Tue, Apr 9, 1:16 AM",
                "value": 62.119998931884766
            },
            {
                "time": "Tue, Apr 9, 1:17 AM",
                "value": 62.15999984741211
            },
            {
                "time": "Tue, Apr 9, 1:17 AM",
                "value": 62.15999984741211
            },
            {
                "time": "Tue, Apr 9, 1:18 AM",
                "value": 62.15999984741211
            },
            {
                "time": "Tue, Apr 9, 1:18 AM",
                "value": 62.150001525878906
            },
            {
                "time": "Tue, Apr 9, 1:19 AM",
                "value": 62.119998931884766
            },
            {
                "time": "Tue, Apr 9, 1:19 AM",
                "value": 62.150001525878906
            },
            {
                "time": "Tue, Apr 9, 1:20 AM",
                "value": 62.150001525878906
            },
            {
                "time": "Tue, Apr 9, 1:20 AM",
                "value": 62.15999984741211
            },
            {
                "time": "Tue, Apr 9, 1:21 AM",
                "value": 62.189998626708984
            },
            {
                "time": "Tue, Apr 9, 1:21 AM",
                "value": 62.22999954223633
            },
            {
                "time": "Tue, Apr 9, 1:22 AM",
                "value": 62.22999954223633
            },
            {
                "time": "Tue, Apr 9, 1:22 AM",
                "value": 62.22999954223633
            },
            {
                "time": "Tue, Apr 9, 1:23 AM",
                "value": 62.22999954223633
            },
            {
                "time": "Tue, Apr 9, 1:23 AM",
                "value": 62.34000015258789
            },
            {
                "time": "Tue, Apr 9, 1:24 AM",
                "value": 62.2599983215332
            },
            {
                "time": "Tue, Apr 9, 1:24 AM",
                "value": 62.2599983215332
            },
            {
                "time": "Tue, Apr 9, 1:25 AM",
                "value": 62.2599983215332
            },
            {
                "time": "Tue, Apr 9, 1:25 AM",
                "value": 62.27000045776367
            },
            {
                "time": "Tue, Apr 9, 1:26 AM",
                "value": 62.2599983215332
            },
            {
                "time": "Tue, Apr 9, 1:26 AM",
                "value": 62.2599983215332
            },
            {
                "time": "Tue, Apr 9, 1:27 AM",
                "value": 62.22999954223633
            },
            {
                "time": "Tue, Apr 9, 1:27 AM",
                "value": 62.29999923706055
            },
            {
                "time": "Tue, Apr 9, 1:28 AM",
                "value": 62.22999954223633
            },
            {
                "time": "Tue, Apr 9, 1:28 AM",
                "value": 62.22999954223633
            },
            {
                "time": "Tue, Apr 9, 1:29 AM",
                "value": 62.22999954223633
            },
            {
                "time": "Tue, Apr 9, 1:29 AM",
                "value": 62.22999954223633
            },
            {
                "time": "Tue, Apr 9, 1:30 AM",
                "value": 62.189998626708984
            },
            {
                "time": "Tue, Apr 9, 1:30 AM",
                "value": 62.189998626708984
            },
            {
                "time": "Tue, Apr 9, 1:31 AM",
                "value": 62.189998626708984
            },
            {
                "time": "Tue, Apr 9, 1:31 AM",
                "value": 62.189998626708984
            },
            {
                "time": "Tue, Apr 9, 1:32 AM",
                "value": 62.189998626708984
            },
            {
                "time": "Tue, Apr 9, 1:32 AM",
                "value": 62.15999984741211
            },
            {
                "time": "Tue, Apr 9, 1:33 AM",
                "value": 62.15999984741211
            },
            {
                "time": "Tue, Apr 9, 1:33 AM",
                "value": 62.150001525878906
            },
            {
                "time": "Tue, Apr 9, 1:34 AM",
                "value": 62.189998626708984
            },
            {
                "time": "Tue, Apr 9, 1:34 AM",
                "value": 62.189998626708984
            },
            {
                "time": "Tue, Apr 9, 1:35 AM",
                "value": 62.189998626708984
            },
            {
                "time": "Tue, Apr 9, 1:35 AM",
                "value": 62.189998626708984
            },
            {
                "time": "Tue, Apr 9, 1:36 AM",
                "value": 62.189998626708984
            },
            {
                "time": "Tue, Apr 9, 1:36 AM",
                "value": 62.189998626708984
            },
            {
                "time": "Tue, Apr 9, 1:37 AM",
                "value": 62.150001525878906
            },
            {
                "time": "Tue, Apr 9, 1:37 AM",
                "value": 62.119998931884766
            },
            {
                "time": "Tue, Apr 9, 1:38 AM",
                "value": 62.119998931884766
            },
            {
                "time": "Tue, Apr 9, 1:38 AM",
                "value": 62.150001525878906
            },
            {
                "time": "Tue, Apr 9, 1:39 AM",
                "value": 62.150001525878906
            },
            {
                "time": "Tue, Apr 9, 1:39 AM",
                "value": 62.150001525878906
            },
            {
                "time": "Tue, Apr 9, 1:40 AM",
                "value": 62.15999984741211
            },
            {
                "time": "Tue, Apr 9, 1:40 AM",
                "value": 62.15999984741211
            },
            {
                "time": "Tue, Apr 9, 1:41 AM",
                "value": 62.150001525878906
            },
            {
                "time": "Tue, Apr 9, 1:41 AM",
                "value": 62.119998931884766
            },
            {
                "time": "Tue, Apr 9, 1:42 AM",
                "value": 62.08000183105469
            },
            {
                "time": "Tue, Apr 9, 1:42 AM",
                "value": 62.04999923706055
            },
            {
                "time": "Tue, Apr 9, 1:43 AM",
                "value": 62.0099983215332
            },
            {
                "time": "Tue, Apr 9, 1:43 AM",
                "value": 62
            },
            {
                "time": "Tue, Apr 9, 1:44 AM",
                "value": 61.970001220703125
            },
            {
                "time": "Tue, Apr 9, 1:44 AM",
                "value": 61.970001220703125
            },
            {
                "time": "Tue, Apr 9, 1:45 AM",
                "value": 61.939998626708984
            },
            {
                "time": "Tue, Apr 9, 1:45 AM",
                "value": 61.939998626708984
            },
            {
                "time": "Tue, Apr 9, 1:46 AM",
                "value": 61.900001525878906
            },
            {
                "time": "Tue, Apr 9, 1:46 AM",
                "value": 61.900001525878906
            },
            {
                "time": "Tue, Apr 9, 1:47 AM",
                "value": 61.900001525878906
            },
            {
                "time": "Tue, Apr 9, 1:47 AM",
                "value": 61.939998626708984
            },
            {
                "time": "Tue, Apr 9, 1:48 AM",
                "value": 61.900001525878906
            },
            {
                "time": "Tue, Apr 9, 1:48 AM",
                "value": 61.869998931884766
            },
            {
                "time": "Tue, Apr 9, 1:49 AM",
                "value": 61.900001525878906
            },
            {
                "time": "Tue, Apr 9, 1:49 AM",
                "value": 61.900001525878906
            },
            {
                "time": "Tue, Apr 9, 1:50 AM",
                "value": 61.939998626708984
            },
            {
                "time": "Tue, Apr 9, 1:50 AM",
                "value": 61.939998626708984
            },
            {
                "time": "Tue, Apr 9, 1:51 AM",
                "value": 61.939998626708984
            },
            {
                "time": "Tue, Apr 9, 1:51 AM",
                "value": 62.040000915527344
            },
            {
                "time": "Tue, Apr 9, 1:52 AM",
                "value": 61.970001220703125
            },
            {
                "time": "Tue, Apr 9, 1:52 AM",
                "value": 62.0099983215332
            },
            {
                "time": "Tue, Apr 9, 1:53 AM",
                "value": 62.0099983215332
            },
            {
                "time": "Tue, Apr 9, 1:53 AM",
                "value": 62.0099983215332
            },
            {
                "time": "Tue, Apr 9, 1:54 AM",
                "value": 62.0099983215332
            },
            {
                "time": "Tue, Apr 9, 1:54 AM",
                "value": 62.0099983215332
            },
            {
                "time": "Tue, Apr 9, 1:55 AM",
                "value": 62.0099983215332
            },
            {
                "time": "Tue, Apr 9, 1:55 AM",
                "value": 62.0099983215332
            },
            {
                "time": "Tue, Apr 9, 1:56 AM",
                "value": 62.0099983215332
            },
            {
                "time": "Tue, Apr 9, 1:56 AM",
                "value": 62.0099983215332
            },
            {
                "time": "Tue, Apr 9, 1:57 AM",
                "value": 61.970001220703125
            },
            {
                "time": "Tue, Apr 9, 1:57 AM",
                "value": 61.970001220703125
            },
            {
                "time": "Tue, Apr 9, 1:58 AM",
                "value": 61.970001220703125
            },
            {
                "time": "Tue, Apr 9, 1:58 AM",
                "value": 61.970001220703125
            },
            {
                "time": "Tue, Apr 9, 1:59 AM",
                "value": 61.939998626708984
            },
            {
                "time": "Tue, Apr 9, 1:59 AM",
                "value": 61.939998626708984
            },
            {
                "time": "Tue, Apr 9, 2:00 AM",
                "value": 61.939998626708984
            },
            {
                "time": "Tue, Apr 9, 2:00 AM",
                "value": 61.970001220703125
            },
            {
                "time": "Tue, Apr 9, 2:01 AM",
                "value": 62.0099983215332
            },
            {
                "time": "Tue, Apr 9, 2:01 AM",
                "value": 62.0099983215332
            },
            {
                "time": "Tue, Apr 9, 2:02 AM",
                "value": 62.0099983215332
            },
            {
                "time": "Tue, Apr 9, 2:02 AM",
                "value": 62.0099983215332
            },
            {
                "time": "Tue, Apr 9, 2:03 AM",
                "value": 62.06999969482422
            },
            {
                "time": "Tue, Apr 9, 2:03 AM",
                "value": 62.0099983215332
            },
            {
                "time": "Tue, Apr 9, 2:04 AM",
                "value": 62.0099983215332
            },
            {
                "time": "Tue, Apr 9, 2:04 AM",
                "value": 62.0099983215332
            },
            {
                "time": "Tue, Apr 9, 2:05 AM",
                "value": 62.04999923706055
            },
            {
                "time": "Tue, Apr 9, 2:05 AM",
                "value": 62.08000183105469
            },
            {
                "time": "Tue, Apr 9, 2:06 AM",
                "value": 62.119998931884766
            },
            {
                "time": "Tue, Apr 9, 2:06 AM",
                "value": 62.150001525878906
            },
            {
                "time": "Tue, Apr 9, 2:07 AM",
                "value": 62.22999954223633
            },
            {
                "time": "Tue, Apr 9, 2:07 AM",
                "value": 62.15999984741211
            },
            {
                "time": "Tue, Apr 9, 2:08 AM",
                "value": 62.189998626708984
            },
            {
                "time": "Tue, Apr 9, 2:08 AM",
                "value": 62.189998626708984
            },
            {
                "time": "Tue, Apr 9, 2:09 AM",
                "value": 62.189998626708984
            },
            {
                "time": "Tue, Apr 9, 2:09 AM",
                "value": 62.189998626708984
            },
            {
                "time": "Tue, Apr 9, 2:10 AM",
                "value": 62.189998626708984
            },
            {
                "time": "Tue, Apr 9, 2:10 AM",
                "value": 62.189998626708984
            },
            {
                "time": "Tue, Apr 9, 2:11 AM",
                "value": 62.189998626708984
            },
            {
                "time": "Tue, Apr 9, 2:11 AM",
                "value": 62.189998626708984
            },
            {
                "time": "Tue, Apr 9, 2:12 AM",
                "value": 62.189998626708984
            },
            {
                "time": "Tue, Apr 9, 2:12 AM",
                "value": 62.189998626708984
            },
            {
                "time": "Tue, Apr 9, 2:13 AM",
                "value": 62.15999984741211
            },
            {
                "time": "Tue, Apr 9, 2:13 AM",
                "value": 62.150001525878906
            },
            {
                "time": "Tue, Apr 9, 2:14 AM",
                "value": 62.119998931884766
            },
            {
                "time": "Tue, Apr 9, 2:14 AM",
                "value": 62.119998931884766
            },
            {
                "time": "Tue, Apr 9, 2:15 AM",
                "value": 62.119998931884766
            },
            {
                "time": "Tue, Apr 9, 2:15 AM",
                "value": 62.08000183105469
            },
            {
                "time": "Tue, Apr 9, 2:16 AM",
                "value": 62.119998931884766
            },
            {
                "time": "Tue, Apr 9, 2:16 AM",
                "value": 62.119998931884766
            },
            {
                "time": "Tue, Apr 9, 2:17 AM",
                "value": 62.119998931884766
            },
            {
                "time": "Tue, Apr 9, 2:17 AM",
                "value": 62.08000183105469
            },
            {
                "time": "Tue, Apr 9, 2:18 AM",
                "value": 62.08000183105469
            },
            {
                "time": "Tue, Apr 9, 2:18 AM",
                "value": 62.08000183105469
            },
            {
                "time": "Tue, Apr 9, 2:19 AM",
                "value": 62.04999923706055
            },
            {
                "time": "Tue, Apr 9, 2:19 AM",
                "value": 62.04999923706055
            },
            {
                "time": "Tue, Apr 9, 2:20 AM",
                "value": 62.04999923706055
            },
            {
                "time": "Tue, Apr 9, 2:20 AM",
                "value": 62.04999923706055
            },
            {
                "time": "Tue, Apr 9, 2:21 AM",
                "value": 62.04999923706055
            },
            {
                "time": "Tue, Apr 9, 2:21 AM",
                "value": 62.04999923706055
            },
            {
                "time": "Tue, Apr 9, 2:22 AM",
                "value": 62.0099983215332
            },
            {
                "time": "Tue, Apr 9, 2:22 AM",
                "value": 62.0099983215332
            },
            {
                "time": "Tue, Apr 9, 2:23 AM",
                "value": 62.04999923706055
            },
            {
                "time": "Tue, Apr 9, 2:23 AM",
                "value": 61.970001220703125
            },
            {
                "time": "Tue, Apr 9, 2:24 AM",
                "value": 61.939998626708984
            },
            {
                "time": "Tue, Apr 9, 2:24 AM",
                "value": 61.900001525878906
            },
            {
                "time": "Tue, Apr 9, 2:25 AM",
                "value": 61.869998931884766
            },
            {
                "time": "Tue, Apr 9, 2:25 AM",
                "value": 61.83000183105469
            },
            {
                "time": "Tue, Apr 9, 2:26 AM",
                "value": 61.83000183105469
            },
            {
                "time": "Tue, Apr 9, 2:26 AM",
                "value": 61.83000183105469
            },
            {
                "time": "Tue, Apr 9, 2:27 AM",
                "value": 61.90999984741211
            },
            {
                "time": "Tue, Apr 9, 2:27 AM",
                "value": 61.790000915527344
            },
            {
                "time": "Tue, Apr 9, 2:28 AM",
                "value": 61.790000915527344
            },
            {
                "time": "Tue, Apr 9, 2:28 AM",
                "value": 61.7599983215332
            },
            {
                "time": "Tue, Apr 9, 2:29 AM",
                "value": 61.7599983215332
            },
            {
                "time": "Tue, Apr 9, 2:29 AM",
                "value": 61.720001220703125
            },
            {
                "time": "Tue, Apr 9, 2:30 AM",
                "value": 61.720001220703125
            },
            {
                "time": "Tue, Apr 9, 2:30 AM",
                "value": 61.689998626708984
            },
            {
                "time": "Tue, Apr 9, 2:31 AM",
                "value": 61.68000030517578
            },
            {
                "time": "Tue, Apr 9, 2:31 AM",
                "value": 61.689998626708984
            },
            {
                "time": "Tue, Apr 9, 2:32 AM",
                "value": 61.689998626708984
            },
            {
                "time": "Tue, Apr 9, 2:32 AM",
                "value": 61.650001525878906
            },
            {
                "time": "Tue, Apr 9, 2:33 AM",
                "value": 61.650001525878906
            },
            {
                "time": "Tue, Apr 9, 2:33 AM",
                "value": 61.650001525878906
            },
            {
                "time": "Tue, Apr 9, 2:34 AM",
                "value": 61.61000061035156
            },
            {
                "time": "Tue, Apr 9, 2:34 AM",
                "value": 61.61000061035156
            },
            {
                "time": "Tue, Apr 9, 2:35 AM",
                "value": 61.65999984741211
            },
            {
                "time": "Tue, Apr 9, 2:35 AM",
                "value": 61.61000061035156
            },
            {
                "time": "Tue, Apr 9, 2:36 AM",
                "value": 61.61000061035156
            },
            {
                "time": "Tue, Apr 9, 2:36 AM",
                "value": 61.61000061035156
            },
            {
                "time": "Tue, Apr 9, 2:37 AM",
                "value": 61.61000061035156
            },
            {
                "time": "Tue, Apr 9, 2:37 AM",
                "value": 61.58000183105469
            },
            {
                "time": "Tue, Apr 9, 2:38 AM",
                "value": 61.58000183105469
            },
            {
                "time": "Tue, Apr 9, 2:38 AM",
                "value": 61.540000915527344
            },
            {
                "time": "Tue, Apr 9, 2:39 AM",
                "value": 61.59000015258789
            },
            {
                "time": "Tue, Apr 9, 2:39 AM",
                "value": 61.5
            },
            {
                "time": "Tue, Apr 9, 2:40 AM",
                "value": 61.5
            },
            {
                "time": "Tue, Apr 9, 2:40 AM",
                "value": 61.470001220703125
            },
            {
                "time": "Tue, Apr 9, 2:41 AM",
                "value": 61.470001220703125
            },
            {
                "time": "Tue, Apr 9, 2:41 AM",
                "value": 61.43000030517578
            },
            {
                "time": "Tue, Apr 9, 2:42 AM",
                "value": 61.43000030517578
            },
            {
                "time": "Tue, Apr 9, 2:42 AM",
                "value": 61.38999938964844
            },
            {
                "time": "Tue, Apr 9, 2:43 AM",
                "value": 61.459999084472656
            },
            {
                "time": "Tue, Apr 9, 2:43 AM",
                "value": 61.36000061035156
            },
            {
                "time": "Tue, Apr 9, 2:44 AM",
                "value": 61.31999969482422
            },
            {
                "time": "Tue, Apr 9, 2:44 AM",
                "value": 61.31999969482422
            },
            {
                "time": "Tue, Apr 9, 2:45 AM",
                "value": 61.31999969482422
            },
            {
                "time": "Tue, Apr 9, 2:45 AM",
                "value": 61.31999969482422
            },
            {
                "time": "Tue, Apr 9, 2:46 AM",
                "value": 61.290000915527344
            },
            {
                "time": "Tue, Apr 9, 2:46 AM",
                "value": 61.290000915527344
            },
            {
                "time": "Tue, Apr 9, 2:47 AM",
                "value": 61.290000915527344
            },
            {
                "time": "Tue, Apr 9, 2:47 AM",
                "value": 61.25
            },
            {
                "time": "Tue, Apr 9, 2:48 AM",
                "value": 61.25
            },
            {
                "time": "Tue, Apr 9, 2:48 AM",
                "value": 61.25
            },
            {
                "time": "Tue, Apr 9, 2:49 AM",
                "value": 61.25
            },
            {
                "time": "Tue, Apr 9, 2:49 AM",
                "value": 61.25
            },
            {
                "time": "Tue, Apr 9, 2:50 AM",
                "value": 61.25
            },
            {
                "time": "Tue, Apr 9, 2:50 AM",
                "value": 61.25
            },
            {
                "time": "Tue, Apr 9, 2:51 AM",
                "value": 61.25
            },
            {
                "time": "Tue, Apr 9, 2:51 AM",
                "value": 61.279998779296875
            },
            {
                "time": "Tue, Apr 9, 2:52 AM",
                "value": 61.25
            },
            {
                "time": "Tue, Apr 9, 2:52 AM",
                "value": 61.290000915527344
            },
            {
                "time": "Tue, Apr 9, 2:53 AM",
                "value": 61.290000915527344
            },
            {
                "time": "Tue, Apr 9, 2:53 AM",
                "value": 61.290000915527344
            },
            {
                "time": "Tue, Apr 9, 2:54 AM",
                "value": 61.290000915527344
            },
            {
                "time": "Tue, Apr 9, 2:54 AM",
                "value": 61.36000061035156
            },
            {
                "time": "Tue, Apr 9, 2:55 AM",
                "value": 61.290000915527344
            },
            {
                "time": "Tue, Apr 9, 2:55 AM",
                "value": 61.31999969482422
            },
            {
                "time": "Tue, Apr 9, 2:56 AM",
                "value": 61.31999969482422
            },
            {
                "time": "Tue, Apr 9, 2:56 AM",
                "value": 61.290000915527344
            },
            {
                "time": "Tue, Apr 9, 2:57 AM",
                "value": 61.290000915527344
            },
            {
                "time": "Tue, Apr 9, 2:57 AM",
                "value": 61.290000915527344
            },
            {
                "time": "Tue, Apr 9, 2:58 AM",
                "value": 61.290000915527344
            },
            {
                "time": "Tue, Apr 9, 2:58 AM",
                "value": 61.290000915527344
            },
            {
                "time": "Tue, Apr 9, 2:59 AM",
                "value": 61.290000915527344
            },
            {
                "time": "Tue, Apr 9, 2:59 AM",
                "value": 61.290000915527344
            },
            {
                "time": "Tue, Apr 9, 3:00 AM",
                "value": 61.290000915527344
            },
            {
                "time": "Tue, Apr 9, 3:00 AM",
                "value": 61.290000915527344
            },
            {
                "time": "Tue, Apr 9, 3:01 AM",
                "value": 61.27000045776367
            },
            {
                "time": "Tue, Apr 9, 3:01 AM",
                "value": 61.290000915527344
            },
            {
                "time": "Tue, Apr 9, 3:02 AM",
                "value": 61.34000015258789
            },
            {
                "time": "Tue, Apr 9, 3:02 AM",
                "value": 61.290000915527344
            },
            {
                "time": "Tue, Apr 9, 3:03 AM",
                "value": 61.290000915527344
            },
            {
                "time": "Tue, Apr 9, 3:03 AM",
                "value": 61.290000915527344
            },
            {
                "time": "Tue, Apr 9, 3:04 AM",
                "value": 61.290000915527344
            },
            {
                "time": "Tue, Apr 9, 3:04 AM",
                "value": 61.290000915527344
            },
            {
                "time": "Tue, Apr 9, 3:05 AM",
                "value": 61.290000915527344
            },
            {
                "time": "Tue, Apr 9, 3:05 AM",
                "value": 61.33000183105469
            },
            {
                "time": "Tue, Apr 9, 3:06 AM",
                "value": 61.290000915527344
            },
            {
                "time": "Tue, Apr 9, 3:06 AM",
                "value": 61.290000915527344
            },
            {
                "time": "Tue, Apr 9, 3:07 AM",
                "value": 61.290000915527344
            },
            {
                "time": "Tue, Apr 9, 3:07 AM",
                "value": 61.290000915527344
            },
            {
                "time": "Tue, Apr 9, 3:08 AM",
                "value": 61.290000915527344
            },
            {
                "time": "Tue, Apr 9, 3:08 AM",
                "value": 61.290000915527344
            },
            {
                "time": "Tue, Apr 9, 3:09 AM",
                "value": 61.31999969482422
            },
            {
                "time": "Tue, Apr 9, 3:09 AM",
                "value": 61.31999969482422
            },
            {
                "time": "Tue, Apr 9, 3:10 AM",
                "value": 61.31999969482422
            },
            {
                "time": "Tue, Apr 9, 3:10 AM",
                "value": 61.31999969482422
            },
            {
                "time": "Tue, Apr 9, 3:11 AM",
                "value": 61.31999969482422
            },
            {
                "time": "Tue, Apr 9, 3:11 AM",
                "value": 61.349998474121094
            },
            {
                "time": "Tue, Apr 9, 3:12 AM",
                "value": 61.36000061035156
            },
            {
                "time": "Tue, Apr 9, 3:12 AM",
                "value": 61.36000061035156
            },
            {
                "time": "Tue, Apr 9, 3:13 AM",
                "value": 61.36000061035156
            },
            {
                "time": "Tue, Apr 9, 3:13 AM",
                "value": 61.36000061035156
            },
            {
                "time": "Tue, Apr 9, 3:14 AM",
                "value": 61.36000061035156
            },
            {
                "time": "Tue, Apr 9, 3:14 AM",
                "value": 61.36000061035156
            },
            {
                "time": "Tue, Apr 9, 3:15 AM",
                "value": 61.36000061035156
            },
            {
                "time": "Tue, Apr 9, 3:15 AM",
                "value": 61.36000061035156
            },
            {
                "time": "Tue, Apr 9, 3:16 AM",
                "value": 61.400001525878906
            },
            {
                "time": "Tue, Apr 9, 3:16 AM",
                "value": 61.38999938964844
            },
            {
                "time": "Tue, Apr 9, 3:17 AM",
                "value": 61.36000061035156
            },
            {
                "time": "Tue, Apr 9, 3:17 AM",
                "value": 61.43000030517578
            },
            {
                "time": "Tue, Apr 9, 3:18 AM",
                "value": 61.31999969482422
            },
            {
                "time": "Tue, Apr 9, 3:18 AM",
                "value": 61.31999969482422
            },
            {
                "time": "Tue, Apr 9, 3:19 AM",
                "value": 61.31999969482422
            },
            {
                "time": "Tue, Apr 9, 3:19 AM",
                "value": 61.290000915527344
            },
            {
                "time": "Tue, Apr 9, 3:20 AM",
                "value": 61.290000915527344
            },
            {
                "time": "Tue, Apr 9, 3:20 AM",
                "value": 61.290000915527344
            },
            {
                "time": "Tue, Apr 9, 3:21 AM",
                "value": 61.349998474121094
            },
            {
                "time": "Tue, Apr 9, 3:21 AM",
                "value": 61.290000915527344
            },
            {
                "time": "Tue, Apr 9, 3:22 AM",
                "value": 61.290000915527344
            },
            {
                "time": "Tue, Apr 9, 3:22 AM",
                "value": 61.25
            },
            {
                "time": "Tue, Apr 9, 3:23 AM",
                "value": 61.25
            },
            {
                "time": "Tue, Apr 9, 3:23 AM",
                "value": 61.25
            },
            {
                "time": "Tue, Apr 9, 3:24 AM",
                "value": 61.25
            },
            {
                "time": "Tue, Apr 9, 3:24 AM",
                "value": 61.25
            },
            {
                "time": "Tue, Apr 9, 3:25 AM",
                "value": 61.310001373291016
            },
            {
                "time": "Tue, Apr 9, 3:25 AM",
                "value": 61.25
            },
            {
                "time": "Tue, Apr 9, 3:26 AM",
                "value": 61.25
            },
            {
                "time": "Tue, Apr 9, 3:26 AM",
                "value": 61.25
            },
            {
                "time": "Tue, Apr 9, 3:27 AM",
                "value": 61.25
            },
            {
                "time": "Tue, Apr 9, 3:27 AM",
                "value": 61.220001220703125
            },
            {
                "time": "Tue, Apr 9, 3:28 AM",
                "value": 61.209999084472656
            },
            {
                "time": "Tue, Apr 9, 3:28 AM",
                "value": 61.209999084472656
            },
            {
                "time": "Tue, Apr 9, 3:29 AM",
                "value": 61.27000045776367
            },
            {
                "time": "Tue, Apr 9, 3:29 AM",
                "value": 61.18000030517578
            },
            {
                "time": "Tue, Apr 9, 3:30 AM",
                "value": 61.18000030517578
            },
            {
                "time": "Tue, Apr 9, 3:30 AM",
                "value": 61.18000030517578
            },
            {
                "time": "Tue, Apr 9, 3:31 AM",
                "value": 61.18000030517578
            },
            {
                "time": "Tue, Apr 9, 3:31 AM",
                "value": 61.18000030517578
            },
            {
                "time": "Tue, Apr 9, 3:32 AM",
                "value": 61.18000030517578
            },
            {
                "time": "Tue, Apr 9, 3:32 AM",
                "value": 61.18000030517578
            },
            {
                "time": "Tue, Apr 9, 3:33 AM",
                "value": 61.13999938964844
            },
            {
                "time": "Tue, Apr 9, 3:33 AM",
                "value": 61.11000061035156
            },
            {
                "time": "Tue, Apr 9, 3:34 AM",
                "value": 61.06999969482422
            },
            {
                "time": "Tue, Apr 9, 3:34 AM",
                "value": 61.029998779296875
            },
            {
                "time": "Tue, Apr 9, 3:35 AM",
                "value": 61.029998779296875
            },
            {
                "time": "Tue, Apr 9, 3:35 AM",
                "value": 61.040000915527344
            },
            {
                "time": "Tue, Apr 9, 3:36 AM",
                "value": 61.029998779296875
            },
            {
                "time": "Tue, Apr 9, 3:36 AM",
                "value": 61.029998779296875
            },
            {
                "time": "Tue, Apr 9, 3:37 AM",
                "value": 61.029998779296875
            },
            {
                "time": "Tue, Apr 9, 3:37 AM",
                "value": 61.029998779296875
            },
            {
                "time": "Tue, Apr 9, 3:38 AM",
                "value": 61.029998779296875
            },
            {
                "time": "Tue, Apr 9, 3:38 AM",
                "value": 61.029998779296875
            },
            {
                "time": "Tue, Apr 9, 3:39 AM",
                "value": 61
            },
            {
                "time": "Tue, Apr 9, 3:39 AM",
                "value": 61
            },
            {
                "time": "Tue, Apr 9, 3:40 AM",
                "value": 61
            },
            {
                "time": "Tue, Apr 9, 3:40 AM",
                "value": 61.029998779296875
            },
            {
                "time": "Tue, Apr 9, 3:41 AM",
                "value": 61.029998779296875
            },
            {
                "time": "Tue, Apr 9, 3:41 AM",
                "value": 61.029998779296875
            },
            {
                "time": "Tue, Apr 9, 3:42 AM",
                "value": 61.029998779296875
            },
            {
                "time": "Tue, Apr 9, 3:42 AM",
                "value": 61.029998779296875
            },
            {
                "time": "Tue, Apr 9, 3:43 AM",
                "value": 61.06999969482422
            },
            {
                "time": "Tue, Apr 9, 3:43 AM",
                "value": 61.06999969482422
            },
            {
                "time": "Tue, Apr 9, 3:44 AM",
                "value": 61.06999969482422
            },
            {
                "time": "Tue, Apr 9, 3:44 AM",
                "value": 61.18000030517578
            },
            {
                "time": "Tue, Apr 9, 3:45 AM",
                "value": 61.029998779296875
            },
            {
                "time": "Tue, Apr 9, 3:45 AM",
                "value": 61.029998779296875
            },
            {
                "time": "Tue, Apr 9, 3:46 AM",
                "value": 61.06999969482422
            },
            {
                "time": "Tue, Apr 9, 3:46 AM",
                "value": 61.06999969482422
            },
            {
                "time": "Tue, Apr 9, 3:47 AM",
                "value": 61.06999969482422
            },
            {
                "time": "Tue, Apr 9, 3:47 AM",
                "value": 61.06999969482422
            },
            {
                "time": "Tue, Apr 9, 3:48 AM",
                "value": 61.06999969482422
            },
            {
                "time": "Tue, Apr 9, 3:48 AM",
                "value": 61.06999969482422
            },
            {
                "time": "Tue, Apr 9, 3:49 AM",
                "value": 61.06999969482422
            },
            {
                "time": "Tue, Apr 9, 3:49 AM",
                "value": 61.06999969482422
            },
            {
                "time": "Tue, Apr 9, 3:50 AM",
                "value": 61.029998779296875
            },
            {
                "time": "Tue, Apr 9, 3:50 AM",
                "value": 61.029998779296875
            },
            {
                "time": "Tue, Apr 9, 3:51 AM",
                "value": 61.029998779296875
            },
            {
                "time": "Tue, Apr 9, 3:51 AM",
                "value": 61.029998779296875
            },
            {
                "time": "Tue, Apr 9, 3:52 AM",
                "value": 61.060001373291016
            },
            {
                "time": "Tue, Apr 9, 3:52 AM",
                "value": 61.029998779296875
            },
            {
                "time": "Tue, Apr 9, 3:53 AM",
                "value": 61
            },
            {
                "time": "Tue, Apr 9, 3:53 AM",
                "value": 61
            },
            {
                "time": "Tue, Apr 9, 3:54 AM",
                "value": 61
            },
            {
                "time": "Tue, Apr 9, 3:54 AM",
                "value": 61
            },
            {
                "time": "Tue, Apr 9, 3:55 AM",
                "value": 61
            },
            {
                "time": "Tue, Apr 9, 3:55 AM",
                "value": 61
            },
            {
                "time": "Tue, Apr 9, 3:56 AM",
                "value": 61
            },
            {
                "time": "Tue, Apr 9, 3:56 AM",
                "value": 61
            },
            {
                "time": "Tue, Apr 9, 3:57 AM",
                "value": 61
            },
            {
                "time": "Tue, Apr 9, 3:57 AM",
                "value": 61.029998779296875
            },
            {
                "time": "Tue, Apr 9, 3:58 AM",
                "value": 61.029998779296875
            },
            {
                "time": "Tue, Apr 9, 3:58 AM",
                "value": 61.029998779296875
            },
            {
                "time": "Tue, Apr 9, 3:59 AM",
                "value": 61.029998779296875
            },
            {
                "time": "Tue, Apr 9, 3:59 AM",
                "value": 61.029998779296875
            },
            {
                "time": "Tue, Apr 9, 4:00 AM",
                "value": 61.099998474121094
            },
            {
                "time": "Tue, Apr 9, 4:00 AM",
                "value": 61.029998779296875
            },
            {
                "time": "Tue, Apr 9, 4:01 AM",
                "value": 61.029998779296875
            },
            {
                "time": "Tue, Apr 9, 4:01 AM",
                "value": 61.029998779296875
            },
            {
                "time": "Tue, Apr 9, 4:02 AM",
                "value": 61.029998779296875
            },
            {
                "time": "Tue, Apr 9, 4:02 AM",
                "value": 61.029998779296875
            },
            {
                "time": "Tue, Apr 9, 4:03 AM",
                "value": 61.029998779296875
            },
            {
                "time": "Tue, Apr 9, 4:03 AM",
                "value": 61.040000915527344
            },
            {
                "time": "Tue, Apr 9, 4:04 AM",
                "value": 61.029998779296875
            },
            {
                "time": "Tue, Apr 9, 4:04 AM",
                "value": 61.029998779296875
            },
            {
                "time": "Tue, Apr 9, 4:05 AM",
                "value": 61.06999969482422
            },
            {
                "time": "Tue, Apr 9, 4:05 AM",
                "value": 61.11000061035156
            },
            {
                "time": "Tue, Apr 9, 4:06 AM",
                "value": 61.13999938964844
            },
            {
                "time": "Tue, Apr 9, 4:06 AM",
                "value": 61.13999938964844
            },
            {
                "time": "Tue, Apr 9, 4:07 AM",
                "value": 61.18000030517578
            },
            {
                "time": "Tue, Apr 9, 4:07 AM",
                "value": 61.209999084472656
            },
            {
                "time": "Tue, Apr 9, 4:08 AM",
                "value": 61.220001220703125
            },
            {
                "time": "Tue, Apr 9, 4:08 AM",
                "value": 61.209999084472656
            },
            {
                "time": "Tue, Apr 9, 4:09 AM",
                "value": 61.25
            },
            {
                "time": "Tue, Apr 9, 4:09 AM",
                "value": 61.25
            },
            {
                "time": "Tue, Apr 9, 4:10 AM",
                "value": 61.290000915527344
            },
            {
                "time": "Tue, Apr 9, 4:10 AM",
                "value": 61.290000915527344
            },
            {
                "time": "Tue, Apr 9, 4:11 AM",
                "value": 61.31999969482422
            },
            {
                "time": "Tue, Apr 9, 4:11 AM",
                "value": 61.31999969482422
            },
            {
                "time": "Tue, Apr 9, 4:12 AM",
                "value": 61.31999969482422
            },
            {
                "time": "Tue, Apr 9, 4:12 AM",
                "value": 61.36000061035156
            },
            {
                "time": "Tue, Apr 9, 4:13 AM",
                "value": 61.36000061035156
            },
            {
                "time": "Tue, Apr 9, 4:13 AM",
                "value": 61.36000061035156
            },
            {
                "time": "Tue, Apr 9, 4:14 AM",
                "value": 61.36000061035156
            },
            {
                "time": "Tue, Apr 9, 4:14 AM",
                "value": 61.400001525878906
            },
            {
                "time": "Tue, Apr 9, 4:15 AM",
                "value": 61.38999938964844
            },
            {
                "time": "Tue, Apr 9, 4:15 AM",
                "value": 61.38999938964844
            },
            {
                "time": "Tue, Apr 9, 4:16 AM",
                "value": 61.400001525878906
            },
            {
                "time": "Tue, Apr 9, 4:16 AM",
                "value": 61.43000030517578
            },
            {
                "time": "Tue, Apr 9, 4:17 AM",
                "value": 61.43000030517578
            },
            {
                "time": "Tue, Apr 9, 4:17 AM",
                "value": 61.43000030517578
            },
            {
                "time": "Tue, Apr 9, 4:18 AM",
                "value": 61.43000030517578
            },
            {
                "time": "Tue, Apr 9, 4:18 AM",
                "value": 61.470001220703125
            },
            {
                "time": "Tue, Apr 9, 4:19 AM",
                "value": 61.470001220703125
            },
            {
                "time": "Tue, Apr 9, 4:19 AM",
                "value": 61.5
            },
            {
                "time": "Tue, Apr 9, 4:20 AM",
                "value": 61.540000915527344
            },
            {
                "time": "Tue, Apr 9, 4:20 AM",
                "value": 61.540000915527344
            },
            {
                "time": "Tue, Apr 9, 4:21 AM",
                "value": 61.540000915527344
            },
            {
                "time": "Tue, Apr 9, 4:21 AM",
                "value": 61.540000915527344
            },
            {
                "time": "Tue, Apr 9, 4:22 AM",
                "value": 61.540000915527344
            },
            {
                "time": "Tue, Apr 9, 4:22 AM",
                "value": 61.540000915527344
            },
            {
                "time": "Tue, Apr 9, 4:23 AM",
                "value": 61.540000915527344
            },
            {
                "time": "Tue, Apr 9, 4:23 AM",
                "value": 61.54999923706055
            },
            {
                "time": "Tue, Apr 9, 4:24 AM",
                "value": 61.540000915527344
            },
            {
                "time": "Tue, Apr 9, 4:24 AM",
                "value": 61.540000915527344
            },
            {
                "time": "Tue, Apr 9, 4:25 AM",
                "value": 61.540000915527344
            },
            {
                "time": "Tue, Apr 9, 4:25 AM",
                "value": 61.540000915527344
            },
            {
                "time": "Tue, Apr 9, 4:26 AM",
                "value": 61.540000915527344
            },
            {
                "time": "Tue, Apr 9, 4:26 AM",
                "value": 61.540000915527344
            },
            {
                "time": "Tue, Apr 9, 4:27 AM",
                "value": 61.540000915527344
            },
            {
                "time": "Tue, Apr 9, 4:27 AM",
                "value": 61.58000183105469
            },
            {
                "time": "Tue, Apr 9, 4:28 AM",
                "value": 61.58000183105469
            },
            {
                "time": "Tue, Apr 9, 4:28 AM",
                "value": 61.58000183105469
            },
            {
                "time": "Tue, Apr 9, 4:29 AM",
                "value": 61.58000183105469
            },
            {
                "time": "Tue, Apr 9, 4:29 AM",
                "value": 61.58000183105469
            },
            {
                "time": "Tue, Apr 9, 4:30 AM",
                "value": 61.58000183105469
            },
            {
                "time": "Tue, Apr 9, 4:30 AM",
                "value": 61.58000183105469
            },
            {
                "time": "Tue, Apr 9, 4:31 AM",
                "value": 61.61000061035156
            },
            {
                "time": "Tue, Apr 9, 4:31 AM",
                "value": 61.58000183105469
            },
            {
                "time": "Tue, Apr 9, 4:32 AM",
                "value": 61.61000061035156
            },
            {
                "time": "Tue, Apr 9, 4:32 AM",
                "value": 61.61000061035156
            },
            {
                "time": "Tue, Apr 9, 4:33 AM",
                "value": 61.61000061035156
            },
            {
                "time": "Tue, Apr 9, 4:33 AM",
                "value": 61.61000061035156
            },
            {
                "time": "Tue, Apr 9, 4:34 AM",
                "value": 61.61000061035156
            },
            {
                "time": "Tue, Apr 9, 4:34 AM",
                "value": 61.61000061035156
            },
            {
                "time": "Tue, Apr 9, 4:35 AM",
                "value": 61.61000061035156
            },
            {
                "time": "Tue, Apr 9, 4:35 AM",
                "value": 61.61000061035156
            },
            {
                "time": "Tue, Apr 9, 4:36 AM",
                "value": 61.61000061035156
            },
            {
                "time": "Tue, Apr 9, 4:36 AM",
                "value": 61.650001525878906
            },
            {
                "time": "Tue, Apr 9, 4:37 AM",
                "value": 61.650001525878906
            },
            {
                "time": "Tue, Apr 9, 4:37 AM",
                "value": 61.650001525878906
            },
            {
                "time": "Tue, Apr 9, 4:38 AM",
                "value": 61.68000030517578
            },
            {
                "time": "Tue, Apr 9, 4:38 AM",
                "value": 61.689998626708984
            },
            {
                "time": "Tue, Apr 9, 4:39 AM",
                "value": 61.7400016784668
            },
            {
                "time": "Tue, Apr 9, 4:39 AM",
                "value": 61.68000030517578
            },
            {
                "time": "Tue, Apr 9, 4:40 AM",
                "value": 61.68000030517578
            },
            {
                "time": "Tue, Apr 9, 4:40 AM",
                "value": 61.68000030517578
            },
            {
                "time": "Tue, Apr 9, 4:41 AM",
                "value": 61.68000030517578
            },
            {
                "time": "Tue, Apr 9, 4:41 AM",
                "value": 61.68000030517578
            },
            {
                "time": "Tue, Apr 9, 4:42 AM",
                "value": 61.68000030517578
            },
            {
                "time": "Tue, Apr 9, 4:42 AM",
                "value": 61.720001220703125
            },
            {
                "time": "Tue, Apr 9, 4:43 AM",
                "value": 61.720001220703125
            },
            {
                "time": "Tue, Apr 9, 4:43 AM",
                "value": 61.720001220703125
            },
            {
                "time": "Tue, Apr 9, 4:44 AM",
                "value": 61.720001220703125
            },
            {
                "time": "Tue, Apr 9, 4:44 AM",
                "value": 61.720001220703125
            },
            {
                "time": "Tue, Apr 9, 4:45 AM",
                "value": 61.720001220703125
            },
            {
                "time": "Tue, Apr 9, 4:45 AM",
                "value": 61.720001220703125
            },
            {
                "time": "Tue, Apr 9, 4:46 AM",
                "value": 61.720001220703125
            },
            {
                "time": "Tue, Apr 9, 4:46 AM",
                "value": 61.720001220703125
            },
            {
                "time": "Tue, Apr 9, 4:47 AM",
                "value": 61.7599983215332
            },
            {
                "time": "Tue, Apr 9, 4:47 AM",
                "value": 61.7599983215332
            },
            {
                "time": "Tue, Apr 9, 4:48 AM",
                "value": 61.75
            },
            {
                "time": "Tue, Apr 9, 4:48 AM",
                "value": 61.7599983215332
            },
            {
                "time": "Tue, Apr 9, 4:49 AM",
                "value": 61.7599983215332
            },
            {
                "time": "Tue, Apr 9, 4:49 AM",
                "value": 61.7599983215332
            },
            {
                "time": "Tue, Apr 9, 4:50 AM",
                "value": 61.7599983215332
            },
            {
                "time": "Tue, Apr 9, 4:50 AM",
                "value": 61.7599983215332
            },
            {
                "time": "Tue, Apr 9, 4:51 AM",
                "value": 61.83000183105469
            },
            {
                "time": "Tue, Apr 9, 4:51 AM",
                "value": 61.7599983215332
            },
            {
                "time": "Tue, Apr 9, 4:52 AM",
                "value": 61.720001220703125
            },
            {
                "time": "Tue, Apr 9, 4:52 AM",
                "value": 61.720001220703125
            },
            {
                "time": "Tue, Apr 9, 4:53 AM",
                "value": 61.720001220703125
            },
            {
                "time": "Tue, Apr 9, 4:53 AM",
                "value": 61.720001220703125
            },
            {
                "time": "Tue, Apr 9, 4:54 AM",
                "value": 61.7599983215332
            },
            {
                "time": "Tue, Apr 9, 4:54 AM",
                "value": 61.7599983215332
            },
            {
                "time": "Tue, Apr 9, 4:55 AM",
                "value": 61.79999923706055
            },
            {
                "time": "Tue, Apr 9, 4:55 AM",
                "value": 61.7599983215332
            },
            {
                "time": "Tue, Apr 9, 4:56 AM",
                "value": 61.7599983215332
            },
            {
                "time": "Tue, Apr 9, 4:56 AM",
                "value": 61.7599983215332
            },
            {
                "time": "Tue, Apr 9, 4:57 AM",
                "value": 61.7599983215332
            },
            {
                "time": "Tue, Apr 9, 4:57 AM",
                "value": 61.7599983215332
            },
            {
                "time": "Tue, Apr 9, 4:58 AM",
                "value": 61.7599983215332
            },
            {
                "time": "Tue, Apr 9, 4:58 AM",
                "value": 61.7599983215332
            },
            {
                "time": "Tue, Apr 9, 4:59 AM",
                "value": 61.77000045776367
            },
            {
                "time": "Tue, Apr 9, 4:59 AM",
                "value": 61.720001220703125
            }
        ]
    },
    "LakeStation": {
        "solar": [
            {
                "time": "Mon, Apr 8, 6:00 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:00 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:01 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:01 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:02 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:02 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 6:03 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 6:03 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:04 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:04 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 6:05 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:05 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 6:06 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:06 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:07 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:07 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:08 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:08 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:09 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:09 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:10 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:10 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:11 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 6:11 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:12 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:12 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:13 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:13 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:14 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:14 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:15 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:15 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:16 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 6:16 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:17 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 6:17 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 6:18 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:18 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 6:19 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 6:19 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 6:20 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:20 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 6:21 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:21 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:22 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:22 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:23 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:23 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:24 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 6:24 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:25 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:25 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 6:26 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 6:26 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 6:27 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:27 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 6:28 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 6:28 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 6:29 AM",
                "value": 0.1120000034570694
            },
            {
                "time": "Mon, Apr 8, 6:29 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:30 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 6:30 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:31 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:31 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:32 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 6:32 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:33 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:33 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:34 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:34 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:35 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 6:35 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:36 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:36 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:37 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 6:37 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:38 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:38 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:39 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:39 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:40 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 6:40 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 6:41 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:41 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:42 AM",
                "value": -0.1120000034570694
            },
            {
                "time": "Mon, Apr 8, 6:42 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:43 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:43 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 6:44 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:44 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 6:45 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:45 AM",
                "value": 0.1120000034570694
            },
            {
                "time": "Mon, Apr 8, 6:46 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 6:46 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 6:47 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 6:47 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 6:48 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:48 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:49 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:49 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:50 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:50 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:51 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:51 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 6:52 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 6:52 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 6:53 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:53 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:54 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 6:54 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 6:55 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 6:55 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:56 AM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 6:56 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 6:57 AM",
                "value": 0.1679999977350235
            },
            {
                "time": "Mon, Apr 8, 6:57 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 6:58 AM",
                "value": 0.1120000034570694
            },
            {
                "time": "Mon, Apr 8, 6:58 AM",
                "value": 0.1679999977350235
            },
            {
                "time": "Mon, Apr 8, 6:59 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 6:59 AM",
                "value": 0.2240000069141388
            },
            {
                "time": "Mon, Apr 8, 7:00 AM",
                "value": 0.1679999977350235
            },
            {
                "time": "Mon, Apr 8, 7:00 AM",
                "value": 0.1679999977350235
            },
            {
                "time": "Mon, Apr 8, 7:01 AM",
                "value": 0.1679999977350235
            },
            {
                "time": "Mon, Apr 8, 7:01 AM",
                "value": 0.2800000011920929
            },
            {
                "time": "Mon, Apr 8, 7:02 AM",
                "value": 0.2800000011920929
            },
            {
                "time": "Mon, Apr 8, 7:02 AM",
                "value": 0.335999995470047
            },
            {
                "time": "Mon, Apr 8, 7:03 AM",
                "value": 0.335999995470047
            },
            {
                "time": "Mon, Apr 8, 7:03 AM",
                "value": 0.3919999897480011
            },
            {
                "time": "Mon, Apr 8, 7:04 AM",
                "value": 0.4480000138282776
            },
            {
                "time": "Mon, Apr 8, 7:04 AM",
                "value": 0.4480000138282776
            },
            {
                "time": "Mon, Apr 8, 7:05 AM",
                "value": 0.5040000081062317
            },
            {
                "time": "Mon, Apr 8, 7:05 AM",
                "value": 0.6159999966621399
            },
            {
                "time": "Mon, Apr 8, 7:06 AM",
                "value": 0.671999990940094
            },
            {
                "time": "Mon, Apr 8, 7:06 AM",
                "value": 0.671999990940094
            },
            {
                "time": "Mon, Apr 8, 7:07 AM",
                "value": 0.7839999794960022
            },
            {
                "time": "Mon, Apr 8, 7:07 AM",
                "value": 0.8389999866485596
            },
            {
                "time": "Mon, Apr 8, 7:08 AM",
                "value": 0.8949999809265137
            },
            {
                "time": "Mon, Apr 8, 7:08 AM",
                "value": 0.9509999752044678
            },
            {
                "time": "Mon, Apr 8, 7:09 AM",
                "value": 1.062999963760376
            },
            {
                "time": "Mon, Apr 8, 7:09 AM",
                "value": 1.11899995803833
            },
            {
                "time": "Mon, Apr 8, 7:10 AM",
                "value": 1.2869999408721924
            },
            {
                "time": "Mon, Apr 8, 7:10 AM",
                "value": 1.2869999408721924
            },
            {
                "time": "Mon, Apr 8, 7:11 AM",
                "value": 1.5110000371932983
            },
            {
                "time": "Mon, Apr 8, 7:11 AM",
                "value": 1.6230000257492065
            },
            {
                "time": "Mon, Apr 8, 7:12 AM",
                "value": 1.7910000085830688
            },
            {
                "time": "Mon, Apr 8, 7:12 AM",
                "value": 2.071000099182129
            },
            {
                "time": "Mon, Apr 8, 7:13 AM",
                "value": 2.183000087738037
            },
            {
                "time": "Mon, Apr 8, 7:13 AM",
                "value": 2.239000082015991
            },
            {
                "time": "Mon, Apr 8, 7:14 AM",
                "value": 2.4619998931884766
            },
            {
                "time": "Mon, Apr 8, 7:14 AM",
                "value": 2.5179998874664307
            },
            {
                "time": "Mon, Apr 8, 7:15 AM",
                "value": 2.742000102996826
            },
            {
                "time": "Mon, Apr 8, 7:15 AM",
                "value": 2.8540000915527344
            },
            {
                "time": "Mon, Apr 8, 7:16 AM",
                "value": 3.134000062942505
            },
            {
                "time": "Mon, Apr 8, 7:16 AM",
                "value": 3.246000051498413
            },
            {
                "time": "Mon, Apr 8, 7:17 AM",
                "value": 3.5260000228881836
            },
            {
                "time": "Mon, Apr 8, 7:17 AM",
                "value": 3.694000005722046
            },
            {
                "time": "Mon, Apr 8, 7:18 AM",
                "value": 3.861999988555908
            },
            {
                "time": "Mon, Apr 8, 7:18 AM",
                "value": 4.140999794006348
            },
            {
                "time": "Mon, Apr 8, 7:19 AM",
                "value": 4.309000015258789
            },
            {
                "time": "Mon, Apr 8, 7:19 AM",
                "value": 4.421000003814697
            },
            {
                "time": "Mon, Apr 8, 7:20 AM",
                "value": 4.644999980926514
            },
            {
                "time": "Mon, Apr 8, 7:20 AM",
                "value": 4.756999969482422
            },
            {
                "time": "Mon, Apr 8, 7:21 AM",
                "value": 4.980999946594238
            },
            {
                "time": "Mon, Apr 8, 7:21 AM",
                "value": 5.204999923706055
            },
            {
                "time": "Mon, Apr 8, 7:22 AM",
                "value": 5.540999889373779
            },
            {
                "time": "Mon, Apr 8, 7:22 AM",
                "value": 5.821000099182129
            },
            {
                "time": "Mon, Apr 8, 7:23 AM",
                "value": 6.099999904632568
            },
            {
                "time": "Mon, Apr 8, 7:23 AM",
                "value": 6.435999870300293
            },
            {
                "time": "Mon, Apr 8, 7:24 AM",
                "value": 6.828000068664551
            },
            {
                "time": "Mon, Apr 8, 7:24 AM",
                "value": 7.107999801635742
            },
            {
                "time": "Mon, Apr 8, 7:25 AM",
                "value": 7.443999767303467
            },
            {
                "time": "Mon, Apr 8, 7:25 AM",
                "value": 7.835000038146973
            },
            {
                "time": "Mon, Apr 8, 7:26 AM",
                "value": 8.170000076293945
            },
            {
                "time": "Mon, Apr 8, 7:26 AM",
                "value": 8.510000228881836
            },
            {
                "time": "Mon, Apr 8, 7:27 AM",
                "value": 9.010000228881836
            },
            {
                "time": "Mon, Apr 8, 7:27 AM",
                "value": 9.460000038146973
            },
            {
                "time": "Mon, Apr 8, 7:28 AM",
                "value": 9.90999984741211
            },
            {
                "time": "Mon, Apr 8, 7:28 AM",
                "value": 10.40999984741211
            },
            {
                "time": "Mon, Apr 8, 7:29 AM",
                "value": 10.800000190734863
            },
            {
                "time": "Mon, Apr 8, 7:29 AM",
                "value": 11.420000076293945
            },
            {
                "time": "Mon, Apr 8, 7:30 AM",
                "value": 11.859999656677246
            },
            {
                "time": "Mon, Apr 8, 7:30 AM",
                "value": 12.369999885559082
            },
            {
                "time": "Mon, Apr 8, 7:31 AM",
                "value": 12.869999885559082
            },
            {
                "time": "Mon, Apr 8, 7:31 AM",
                "value": 13.430000305175781
            },
            {
                "time": "Mon, Apr 8, 7:32 AM",
                "value": 14.050000190734863
            },
            {
                "time": "Mon, Apr 8, 7:32 AM",
                "value": 14.609999656677246
            },
            {
                "time": "Mon, Apr 8, 7:33 AM",
                "value": 15.279999732971191
            },
            {
                "time": "Mon, Apr 8, 7:33 AM",
                "value": 16.010000228881836
            },
            {
                "time": "Mon, Apr 8, 7:34 AM",
                "value": 16.6200008392334
            },
            {
                "time": "Mon, Apr 8, 7:34 AM",
                "value": 17.290000915527344
            },
            {
                "time": "Mon, Apr 8, 7:35 AM",
                "value": 17.969999313354492
            },
            {
                "time": "Mon, Apr 8, 7:35 AM",
                "value": 18.860000610351562
            },
            {
                "time": "Mon, Apr 8, 7:36 AM",
                "value": 9.289999961853027
            },
            {
                "time": "Mon, Apr 8, 7:36 AM",
                "value": 4.4770002365112305
            },
            {
                "time": "Mon, Apr 8, 7:37 AM",
                "value": 5.0929999351501465
            },
            {
                "time": "Mon, Apr 8, 7:37 AM",
                "value": 6.716000080108643
            },
            {
                "time": "Mon, Apr 8, 7:38 AM",
                "value": 8.619999885559082
            },
            {
                "time": "Mon, Apr 8, 7:38 AM",
                "value": 9.630000114440918
            },
            {
                "time": "Mon, Apr 8, 7:39 AM",
                "value": 9.850000381469727
            },
            {
                "time": "Mon, Apr 8, 7:39 AM",
                "value": 20.479999542236328
            },
            {
                "time": "Mon, Apr 8, 7:40 AM",
                "value": 25.350000381469727
            },
            {
                "time": "Mon, Apr 8, 7:40 AM",
                "value": 26.139999389648438
            },
            {
                "time": "Mon, Apr 8, 7:41 AM",
                "value": 26.809999465942383
            },
            {
                "time": "Mon, Apr 8, 7:41 AM",
                "value": 27.649999618530273
            },
            {
                "time": "Mon, Apr 8, 7:42 AM",
                "value": 28.31999969482422
            },
            {
                "time": "Mon, Apr 8, 7:42 AM",
                "value": 28.989999771118164
            },
            {
                "time": "Mon, Apr 8, 7:43 AM",
                "value": 29.829999923706055
            },
            {
                "time": "Mon, Apr 8, 7:43 AM",
                "value": 30.450000762939453
            },
            {
                "time": "Mon, Apr 8, 7:44 AM",
                "value": 31.290000915527344
            },
            {
                "time": "Mon, Apr 8, 7:44 AM",
                "value": 31.959999084472656
            },
            {
                "time": "Mon, Apr 8, 7:45 AM",
                "value": 32.79999923706055
            },
            {
                "time": "Mon, Apr 8, 7:45 AM",
                "value": 33.36000061035156
            },
            {
                "time": "Mon, Apr 8, 7:46 AM",
                "value": 34.08000183105469
            },
            {
                "time": "Mon, Apr 8, 7:46 AM",
                "value": 34.97999954223633
            },
            {
                "time": "Mon, Apr 8, 7:47 AM",
                "value": 35.81999969482422
            },
            {
                "time": "Mon, Apr 8, 7:47 AM",
                "value": 36.77000045776367
            },
            {
                "time": "Mon, Apr 8, 7:48 AM",
                "value": 37.54999923706055
            },
            {
                "time": "Mon, Apr 8, 7:48 AM",
                "value": 38.45000076293945
            },
            {
                "time": "Mon, Apr 8, 7:49 AM",
                "value": 39.34000015258789
            },
            {
                "time": "Mon, Apr 8, 7:49 AM",
                "value": 40.40999984741211
            },
            {
                "time": "Mon, Apr 8, 7:50 AM",
                "value": 41.189998626708984
            },
            {
                "time": "Mon, Apr 8, 7:50 AM",
                "value": 41.970001220703125
            },
            {
                "time": "Mon, Apr 8, 7:51 AM",
                "value": 42.810001373291016
            },
            {
                "time": "Mon, Apr 8, 7:51 AM",
                "value": 43.540000915527344
            },
            {
                "time": "Mon, Apr 8, 7:52 AM",
                "value": 44.099998474121094
            },
            {
                "time": "Mon, Apr 8, 7:52 AM",
                "value": 44.880001068115234
            },
            {
                "time": "Mon, Apr 8, 7:53 AM",
                "value": 45.15999984741211
            },
            {
                "time": "Mon, Apr 8, 7:53 AM",
                "value": 45.66999816894531
            },
            {
                "time": "Mon, Apr 8, 7:54 AM",
                "value": 46.22999954223633
            },
            {
                "time": "Mon, Apr 8, 7:54 AM",
                "value": 47.0099983215332
            },
            {
                "time": "Mon, Apr 8, 7:55 AM",
                "value": 47.79999923706055
            },
            {
                "time": "Mon, Apr 8, 7:55 AM",
                "value": 48.630001068115234
            },
            {
                "time": "Mon, Apr 8, 7:56 AM",
                "value": 49.470001220703125
            },
            {
                "time": "Mon, Apr 8, 7:56 AM",
                "value": 50.20000076293945
            },
            {
                "time": "Mon, Apr 8, 7:57 AM",
                "value": 50.81999969482422
            },
            {
                "time": "Mon, Apr 8, 7:57 AM",
                "value": 51.65999984741211
            },
            {
                "time": "Mon, Apr 8, 7:58 AM",
                "value": 52.720001220703125
            },
            {
                "time": "Mon, Apr 8, 7:58 AM",
                "value": 53.72999954223633
            },
            {
                "time": "Mon, Apr 8, 7:59 AM",
                "value": 54.400001525878906
            },
            {
                "time": "Mon, Apr 8, 7:59 AM",
                "value": 54.849998474121094
            },
            {
                "time": "Mon, Apr 8, 8:00 AM",
                "value": 55.459999084472656
            },
            {
                "time": "Mon, Apr 8, 8:00 AM",
                "value": 55.90999984741211
            },
            {
                "time": "Mon, Apr 8, 8:01 AM",
                "value": 55.90999984741211
            },
            {
                "time": "Mon, Apr 8, 8:01 AM",
                "value": 56.189998626708984
            },
            {
                "time": "Mon, Apr 8, 8:02 AM",
                "value": 56.29999923706055
            },
            {
                "time": "Mon, Apr 8, 8:02 AM",
                "value": 57.08000183105469
            },
            {
                "time": "Mon, Apr 8, 8:03 AM",
                "value": 58.93000030517578
            },
            {
                "time": "Mon, Apr 8, 8:03 AM",
                "value": 59.939998626708984
            },
            {
                "time": "Mon, Apr 8, 8:04 AM",
                "value": 60.95000076293945
            },
            {
                "time": "Mon, Apr 8, 8:04 AM",
                "value": 61.45000076293945
            },
            {
                "time": "Mon, Apr 8, 8:05 AM",
                "value": 61.66999816894531
            },
            {
                "time": "Mon, Apr 8, 8:05 AM",
                "value": 62.56999969482422
            },
            {
                "time": "Mon, Apr 8, 8:06 AM",
                "value": 64.13999938964844
            },
            {
                "time": "Mon, Apr 8, 8:06 AM",
                "value": 64.69999694824219
            },
            {
                "time": "Mon, Apr 8, 8:07 AM",
                "value": 64.9800033569336
            },
            {
                "time": "Mon, Apr 8, 8:07 AM",
                "value": 66.0999984741211
            },
            {
                "time": "Mon, Apr 8, 8:08 AM",
                "value": 66.81999969482422
            },
            {
                "time": "Mon, Apr 8, 8:08 AM",
                "value": 68.05000305175781
            },
            {
                "time": "Mon, Apr 8, 8:09 AM",
                "value": 69.2300033569336
            },
            {
                "time": "Mon, Apr 8, 8:09 AM",
                "value": 69.83999633789062
            },
            {
                "time": "Mon, Apr 8, 8:10 AM",
                "value": 70.4000015258789
            },
            {
                "time": "Mon, Apr 8, 8:10 AM",
                "value": 70.8499984741211
            },
            {
                "time": "Mon, Apr 8, 8:11 AM",
                "value": 71.36000061035156
            },
            {
                "time": "Mon, Apr 8, 8:11 AM",
                "value": 70.3499984741211
            },
            {
                "time": "Mon, Apr 8, 8:12 AM",
                "value": 69.44999694824219
            },
            {
                "time": "Mon, Apr 8, 8:12 AM",
                "value": 69.7300033569336
            },
            {
                "time": "Mon, Apr 8, 8:13 AM",
                "value": 71.08000183105469
            },
            {
                "time": "Mon, Apr 8, 8:13 AM",
                "value": 73.26000213623047
            },
            {
                "time": "Mon, Apr 8, 8:14 AM",
                "value": 75.22000122070312
            },
            {
                "time": "Mon, Apr 8, 8:14 AM",
                "value": 75.38999938964844
            },
            {
                "time": "Mon, Apr 8, 8:15 AM",
                "value": 77.4000015258789
            },
            {
                "time": "Mon, Apr 8, 8:15 AM",
                "value": 79.69000244140625
            },
            {
                "time": "Mon, Apr 8, 8:16 AM",
                "value": 82.4000015258789
            },
            {
                "time": "Mon, Apr 8, 8:16 AM",
                "value": 84.80000305175781
            },
            {
                "time": "Mon, Apr 8, 8:17 AM",
                "value": 87.0999984741211
            },
            {
                "time": "Mon, Apr 8, 8:17 AM",
                "value": 87.9000015258789
            },
            {
                "time": "Mon, Apr 8, 8:18 AM",
                "value": 87
            },
            {
                "time": "Mon, Apr 8, 8:18 AM",
                "value": 84.9000015258789
            },
            {
                "time": "Mon, Apr 8, 8:19 AM",
                "value": 83.9000015258789
            },
            {
                "time": "Mon, Apr 8, 8:19 AM",
                "value": 84.19999694824219
            },
            {
                "time": "Mon, Apr 8, 8:20 AM",
                "value": 86.0999984741211
            },
            {
                "time": "Mon, Apr 8, 8:20 AM",
                "value": 89.80000305175781
            },
            {
                "time": "Mon, Apr 8, 8:21 AM",
                "value": 90.9000015258789
            },
            {
                "time": "Mon, Apr 8, 8:21 AM",
                "value": 90.80000305175781
            },
            {
                "time": "Mon, Apr 8, 8:22 AM",
                "value": 90.80000305175781
            },
            {
                "time": "Mon, Apr 8, 8:22 AM",
                "value": 92.69999694824219
            },
            {
                "time": "Mon, Apr 8, 8:23 AM",
                "value": 93.80000305175781
            },
            {
                "time": "Mon, Apr 8, 8:23 AM",
                "value": 95.5
            },
            {
                "time": "Mon, Apr 8, 8:24 AM",
                "value": 95.30000305175781
            },
            {
                "time": "Mon, Apr 8, 8:24 AM",
                "value": 93.69999694824219
            },
            {
                "time": "Mon, Apr 8, 8:25 AM",
                "value": 91.9000015258789
            },
            {
                "time": "Mon, Apr 8, 8:25 AM",
                "value": 90
            },
            {
                "time": "Mon, Apr 8, 8:26 AM",
                "value": 91.30000305175781
            },
            {
                "time": "Mon, Apr 8, 8:26 AM",
                "value": 94.30000305175781
            },
            {
                "time": "Mon, Apr 8, 8:27 AM",
                "value": 96.5999984741211
            },
            {
                "time": "Mon, Apr 8, 8:27 AM",
                "value": 96.80000305175781
            },
            {
                "time": "Mon, Apr 8, 8:28 AM",
                "value": 95.9000015258789
            },
            {
                "time": "Mon, Apr 8, 8:28 AM",
                "value": 98.9000015258789
            },
            {
                "time": "Mon, Apr 8, 8:29 AM",
                "value": 104
            },
            {
                "time": "Mon, Apr 8, 8:29 AM",
                "value": 99.4000015258789
            },
            {
                "time": "Mon, Apr 8, 8:30 AM",
                "value": 94.30000305175781
            },
            {
                "time": "Mon, Apr 8, 8:30 AM",
                "value": 92.19999694824219
            },
            {
                "time": "Mon, Apr 8, 8:31 AM",
                "value": 92.9000015258789
            },
            {
                "time": "Mon, Apr 8, 8:31 AM",
                "value": 93.0999984741211
            },
            {
                "time": "Mon, Apr 8, 8:32 AM",
                "value": 94.9000015258789
            },
            {
                "time": "Mon, Apr 8, 8:32 AM",
                "value": 97.5999984741211
            },
            {
                "time": "Mon, Apr 8, 8:33 AM",
                "value": 102.5
            },
            {
                "time": "Mon, Apr 8, 8:33 AM",
                "value": 106.5999984741211
            },
            {
                "time": "Mon, Apr 8, 8:34 AM",
                "value": 108.30000305175781
            },
            {
                "time": "Mon, Apr 8, 8:34 AM",
                "value": 107
            },
            {
                "time": "Mon, Apr 8, 8:35 AM",
                "value": 110.5999984741211
            },
            {
                "time": "Mon, Apr 8, 8:35 AM",
                "value": 116.9000015258789
            },
            {
                "time": "Mon, Apr 8, 8:36 AM",
                "value": 120.19999694824219
            },
            {
                "time": "Mon, Apr 8, 8:36 AM",
                "value": 123.30000305175781
            },
            {
                "time": "Mon, Apr 8, 8:37 AM",
                "value": 126.0999984741211
            },
            {
                "time": "Mon, Apr 8, 8:37 AM",
                "value": 130.60000610351562
            },
            {
                "time": "Mon, Apr 8, 8:38 AM",
                "value": 128.3000030517578
            },
            {
                "time": "Mon, Apr 8, 8:38 AM",
                "value": 127.80000305175781
            },
            {
                "time": "Mon, Apr 8, 8:39 AM",
                "value": 116.80000305175781
            },
            {
                "time": "Mon, Apr 8, 8:39 AM",
                "value": 72.9800033569336
            },
            {
                "time": "Mon, Apr 8, 8:40 AM",
                "value": 47.90999984741211
            },
            {
                "time": "Mon, Apr 8, 8:40 AM",
                "value": 33.470001220703125
            },
            {
                "time": "Mon, Apr 8, 8:41 AM",
                "value": 36.65999984741211
            },
            {
                "time": "Mon, Apr 8, 8:41 AM",
                "value": 42.97999954223633
            },
            {
                "time": "Mon, Apr 8, 8:42 AM",
                "value": 142.8000030517578
            },
            {
                "time": "Mon, Apr 8, 8:42 AM",
                "value": 141.60000610351562
            },
            {
                "time": "Mon, Apr 8, 8:43 AM",
                "value": 134.89999389648438
            },
            {
                "time": "Mon, Apr 8, 8:43 AM",
                "value": 124
            },
            {
                "time": "Mon, Apr 8, 8:44 AM",
                "value": 118.30000305175781
            },
            {
                "time": "Mon, Apr 8, 8:44 AM",
                "value": 119.4000015258789
            },
            {
                "time": "Mon, Apr 8, 8:45 AM",
                "value": 124.9000015258789
            },
            {
                "time": "Mon, Apr 8, 8:45 AM",
                "value": 125.4000015258789
            },
            {
                "time": "Mon, Apr 8, 8:46 AM",
                "value": 120.69999694824219
            },
            {
                "time": "Mon, Apr 8, 8:46 AM",
                "value": 113.30000305175781
            },
            {
                "time": "Mon, Apr 8, 8:47 AM",
                "value": 105.4000015258789
            },
            {
                "time": "Mon, Apr 8, 8:47 AM",
                "value": 100.5999984741211
            },
            {
                "time": "Mon, Apr 8, 8:48 AM",
                "value": 99.0999984741211
            },
            {
                "time": "Mon, Apr 8, 8:48 AM",
                "value": 100.19999694824219
            },
            {
                "time": "Mon, Apr 8, 8:49 AM",
                "value": 105.4000015258789
            },
            {
                "time": "Mon, Apr 8, 8:49 AM",
                "value": 110.69999694824219
            },
            {
                "time": "Mon, Apr 8, 8:50 AM",
                "value": 116.0999984741211
            },
            {
                "time": "Mon, Apr 8, 8:50 AM",
                "value": 124.5
            },
            {
                "time": "Mon, Apr 8, 8:51 AM",
                "value": 126.5999984741211
            },
            {
                "time": "Mon, Apr 8, 8:51 AM",
                "value": 130.89999389648438
            },
            {
                "time": "Mon, Apr 8, 8:52 AM",
                "value": 132.5
            },
            {
                "time": "Mon, Apr 8, 8:52 AM",
                "value": 135.10000610351562
            },
            {
                "time": "Mon, Apr 8, 8:53 AM",
                "value": 136.1999969482422
            },
            {
                "time": "Mon, Apr 8, 8:53 AM",
                "value": 137.60000610351562
            },
            {
                "time": "Mon, Apr 8, 8:54 AM",
                "value": 134.10000610351562
            },
            {
                "time": "Mon, Apr 8, 8:54 AM",
                "value": 138.5
            },
            {
                "time": "Mon, Apr 8, 8:55 AM",
                "value": 143.60000610351562
            },
            {
                "time": "Mon, Apr 8, 8:55 AM",
                "value": 145.3000030517578
            },
            {
                "time": "Mon, Apr 8, 8:56 AM",
                "value": 145.3000030517578
            },
            {
                "time": "Mon, Apr 8, 8:56 AM",
                "value": 142.39999389648438
            },
            {
                "time": "Mon, Apr 8, 8:57 AM",
                "value": 141.1999969482422
            },
            {
                "time": "Mon, Apr 8, 8:57 AM",
                "value": 140.39999389648438
            },
            {
                "time": "Mon, Apr 8, 8:58 AM",
                "value": 142
            },
            {
                "time": "Mon, Apr 8, 8:58 AM",
                "value": 146.3000030517578
            },
            {
                "time": "Mon, Apr 8, 8:59 AM",
                "value": 149.10000610351562
            },
            {
                "time": "Mon, Apr 8, 8:59 AM",
                "value": 152.6999969482422
            },
            {
                "time": "Mon, Apr 8, 9:00 AM",
                "value": 154.60000610351562
            },
            {
                "time": "Mon, Apr 8, 9:00 AM",
                "value": 154.5
            },
            {
                "time": "Mon, Apr 8, 9:01 AM",
                "value": 151.1999969482422
            },
            {
                "time": "Mon, Apr 8, 9:01 AM",
                "value": 147.39999389648438
            },
            {
                "time": "Mon, Apr 8, 9:02 AM",
                "value": 149.89999389648438
            },
            {
                "time": "Mon, Apr 8, 9:02 AM",
                "value": 161.6999969482422
            },
            {
                "time": "Mon, Apr 8, 9:03 AM",
                "value": 175.8000030517578
            },
            {
                "time": "Mon, Apr 8, 9:03 AM",
                "value": 173.10000610351562
            },
            {
                "time": "Mon, Apr 8, 9:04 AM",
                "value": 176.10000610351562
            },
            {
                "time": "Mon, Apr 8, 9:04 AM",
                "value": 169.10000610351562
            },
            {
                "time": "Mon, Apr 8, 9:05 AM",
                "value": 161.60000610351562
            },
            {
                "time": "Mon, Apr 8, 9:05 AM",
                "value": 149.3000030517578
            },
            {
                "time": "Mon, Apr 8, 9:06 AM",
                "value": 133.5
            },
            {
                "time": "Mon, Apr 8, 9:06 AM",
                "value": 123.19999694824219
            },
            {
                "time": "Mon, Apr 8, 9:07 AM",
                "value": 121.0999984741211
            },
            {
                "time": "Mon, Apr 8, 9:07 AM",
                "value": 130.60000610351562
            },
            {
                "time": "Mon, Apr 8, 9:08 AM",
                "value": 144.39999389648438
            },
            {
                "time": "Mon, Apr 8, 9:08 AM",
                "value": 162
            },
            {
                "time": "Mon, Apr 8, 9:09 AM",
                "value": 171.5
            },
            {
                "time": "Mon, Apr 8, 9:09 AM",
                "value": 176
            },
            {
                "time": "Mon, Apr 8, 9:10 AM",
                "value": 173.10000610351562
            },
            {
                "time": "Mon, Apr 8, 9:10 AM",
                "value": 169.8000030517578
            },
            {
                "time": "Mon, Apr 8, 9:11 AM",
                "value": 160
            },
            {
                "time": "Mon, Apr 8, 9:11 AM",
                "value": 159.3000030517578
            },
            {
                "time": "Mon, Apr 8, 9:12 AM",
                "value": 167
            },
            {
                "time": "Mon, Apr 8, 9:12 AM",
                "value": 183.39999389648438
            },
            {
                "time": "Mon, Apr 8, 9:13 AM",
                "value": 182
            },
            {
                "time": "Mon, Apr 8, 9:13 AM",
                "value": 189.89999389648438
            },
            {
                "time": "Mon, Apr 8, 9:14 AM",
                "value": 192.10000610351562
            },
            {
                "time": "Mon, Apr 8, 9:14 AM",
                "value": 194.1999969482422
            },
            {
                "time": "Mon, Apr 8, 9:15 AM",
                "value": 192.89999389648438
            },
            {
                "time": "Mon, Apr 8, 9:15 AM",
                "value": 199.6999969482422
            },
            {
                "time": "Mon, Apr 8, 9:16 AM",
                "value": 196.89999389648438
            },
            {
                "time": "Mon, Apr 8, 9:16 AM",
                "value": 202
            },
            {
                "time": "Mon, Apr 8, 9:17 AM",
                "value": 204.8000030517578
            },
            {
                "time": "Mon, Apr 8, 9:17 AM",
                "value": 206.60000610351562
            },
            {
                "time": "Mon, Apr 8, 9:18 AM",
                "value": 208.1999969482422
            },
            {
                "time": "Mon, Apr 8, 9:18 AM",
                "value": 208.3000030517578
            },
            {
                "time": "Mon, Apr 8, 9:19 AM",
                "value": 213.89999389648438
            },
            {
                "time": "Mon, Apr 8, 9:19 AM",
                "value": 208.10000610351562
            },
            {
                "time": "Mon, Apr 8, 9:20 AM",
                "value": 214.60000610351562
            },
            {
                "time": "Mon, Apr 8, 9:20 AM",
                "value": 217.60000610351562
            },
            {
                "time": "Mon, Apr 8, 9:21 AM",
                "value": 193.60000610351562
            },
            {
                "time": "Mon, Apr 8, 9:21 AM",
                "value": 198.6999969482422
            },
            {
                "time": "Mon, Apr 8, 9:22 AM",
                "value": 193.60000610351562
            },
            {
                "time": "Mon, Apr 8, 9:22 AM",
                "value": 36.4900016784668
            },
            {
                "time": "Mon, Apr 8, 9:23 AM",
                "value": 66.26000213623047
            },
            {
                "time": "Mon, Apr 8, 9:23 AM",
                "value": 195.5
            },
            {
                "time": "Mon, Apr 8, 9:24 AM",
                "value": 188.8000030517578
            },
            {
                "time": "Mon, Apr 8, 9:24 AM",
                "value": 195.3000030517578
            },
            {
                "time": "Mon, Apr 8, 9:25 AM",
                "value": 203.89999389648438
            },
            {
                "time": "Mon, Apr 8, 9:25 AM",
                "value": 227.6999969482422
            },
            {
                "time": "Mon, Apr 8, 9:26 AM",
                "value": 233
            },
            {
                "time": "Mon, Apr 8, 9:26 AM",
                "value": 224.6999969482422
            },
            {
                "time": "Mon, Apr 8, 9:27 AM",
                "value": 234.39999389648438
            },
            {
                "time": "Mon, Apr 8, 9:27 AM",
                "value": 227.5
            },
            {
                "time": "Mon, Apr 8, 9:28 AM",
                "value": 229.3000030517578
            },
            {
                "time": "Mon, Apr 8, 9:28 AM",
                "value": 234.3000030517578
            },
            {
                "time": "Mon, Apr 8, 9:29 AM",
                "value": 238.8000030517578
            },
            {
                "time": "Mon, Apr 8, 9:29 AM",
                "value": 240.39999389648438
            },
            {
                "time": "Mon, Apr 8, 9:30 AM",
                "value": 180.10000610351562
            },
            {
                "time": "Mon, Apr 8, 9:30 AM",
                "value": 57.86000061035156
            },
            {
                "time": "Mon, Apr 8, 9:31 AM",
                "value": 60.939998626708984
            },
            {
                "time": "Mon, Apr 8, 9:31 AM",
                "value": 66.76000213623047
            },
            {
                "time": "Mon, Apr 8, 9:32 AM",
                "value": 67.54000091552734
            },
            {
                "time": "Mon, Apr 8, 9:32 AM",
                "value": 75.83000183105469
            },
            {
                "time": "Mon, Apr 8, 9:33 AM",
                "value": 73.08999633789062
            },
            {
                "time": "Mon, Apr 8, 9:33 AM",
                "value": 72.08000183105469
            },
            {
                "time": "Mon, Apr 8, 9:34 AM",
                "value": 202.89999389648438
            },
            {
                "time": "Mon, Apr 8, 9:34 AM",
                "value": 266.1000061035156
            },
            {
                "time": "Mon, Apr 8, 9:35 AM",
                "value": 270.1000061035156
            },
            {
                "time": "Mon, Apr 8, 9:35 AM",
                "value": 276.20001220703125
            },
            {
                "time": "Mon, Apr 8, 9:36 AM",
                "value": 285.3999938964844
            },
            {
                "time": "Mon, Apr 8, 9:36 AM",
                "value": 301.6000061035156
            },
            {
                "time": "Mon, Apr 8, 9:37 AM",
                "value": 309.1000061035156
            },
            {
                "time": "Mon, Apr 8, 9:37 AM",
                "value": 302.20001220703125
            },
            {
                "time": "Mon, Apr 8, 9:38 AM",
                "value": 297.5
            },
            {
                "time": "Mon, Apr 8, 9:38 AM",
                "value": 282.70001220703125
            },
            {
                "time": "Mon, Apr 8, 9:39 AM",
                "value": 295
            },
            {
                "time": "Mon, Apr 8, 9:39 AM",
                "value": 290.6000061035156
            },
            {
                "time": "Mon, Apr 8, 9:40 AM",
                "value": 288.6000061035156
            },
            {
                "time": "Mon, Apr 8, 9:40 AM",
                "value": 294.1000061035156
            },
            {
                "time": "Mon, Apr 8, 9:41 AM",
                "value": 299.3999938964844
            },
            {
                "time": "Mon, Apr 8, 9:41 AM",
                "value": 311.3999938964844
            },
            {
                "time": "Mon, Apr 8, 9:42 AM",
                "value": 322.6000061035156
            },
            {
                "time": "Mon, Apr 8, 9:42 AM",
                "value": 335.8999938964844
            },
            {
                "time": "Mon, Apr 8, 9:43 AM",
                "value": 340.70001220703125
            },
            {
                "time": "Mon, Apr 8, 9:43 AM",
                "value": 341.5
            },
            {
                "time": "Mon, Apr 8, 9:44 AM",
                "value": 344.1000061035156
            },
            {
                "time": "Mon, Apr 8, 9:44 AM",
                "value": 345.20001220703125
            },
            {
                "time": "Mon, Apr 8, 9:45 AM",
                "value": 344.20001220703125
            },
            {
                "time": "Mon, Apr 8, 9:45 AM",
                "value": 350.3999938964844
            },
            {
                "time": "Mon, Apr 8, 9:46 AM",
                "value": 354.20001220703125
            },
            {
                "time": "Mon, Apr 8, 9:46 AM",
                "value": 362.29998779296875
            },
            {
                "time": "Mon, Apr 8, 9:47 AM",
                "value": 366.1000061035156
            },
            {
                "time": "Mon, Apr 8, 9:47 AM",
                "value": 367.5
            },
            {
                "time": "Mon, Apr 8, 9:48 AM",
                "value": 370.1000061035156
            },
            {
                "time": "Mon, Apr 8, 9:48 AM",
                "value": 372.20001220703125
            },
            {
                "time": "Mon, Apr 8, 9:49 AM",
                "value": 369.29998779296875
            },
            {
                "time": "Mon, Apr 8, 9:49 AM",
                "value": 360.20001220703125
            },
            {
                "time": "Mon, Apr 8, 9:50 AM",
                "value": 373.6000061035156
            },
            {
                "time": "Mon, Apr 8, 9:50 AM",
                "value": 378
            },
            {
                "time": "Mon, Apr 8, 9:51 AM",
                "value": 380.5
            },
            {
                "time": "Mon, Apr 8, 9:51 AM",
                "value": 382.3999938964844
            },
            {
                "time": "Mon, Apr 8, 9:52 AM",
                "value": 384.70001220703125
            },
            {
                "time": "Mon, Apr 8, 9:52 AM",
                "value": 385
            },
            {
                "time": "Mon, Apr 8, 9:53 AM",
                "value": 386.29998779296875
            },
            {
                "time": "Mon, Apr 8, 9:53 AM",
                "value": 388.1000061035156
            },
            {
                "time": "Mon, Apr 8, 9:54 AM",
                "value": 389.29998779296875
            },
            {
                "time": "Mon, Apr 8, 9:54 AM",
                "value": 391.70001220703125
            },
            {
                "time": "Mon, Apr 8, 9:55 AM",
                "value": 394.20001220703125
            },
            {
                "time": "Mon, Apr 8, 9:55 AM",
                "value": 396
            },
            {
                "time": "Mon, Apr 8, 9:56 AM",
                "value": 397.6000061035156
            },
            {
                "time": "Mon, Apr 8, 9:56 AM",
                "value": 399.1000061035156
            },
            {
                "time": "Mon, Apr 8, 9:57 AM",
                "value": 399.8999938964844
            },
            {
                "time": "Mon, Apr 8, 9:57 AM",
                "value": 402.1000061035156
            },
            {
                "time": "Mon, Apr 8, 9:58 AM",
                "value": 404.5
            },
            {
                "time": "Mon, Apr 8, 9:58 AM",
                "value": 406.6000061035156
            },
            {
                "time": "Mon, Apr 8, 9:59 AM",
                "value": 409.3999938964844
            },
            {
                "time": "Mon, Apr 8, 9:59 AM",
                "value": 410.79998779296875
            },
            {
                "time": "Mon, Apr 8, 10:00 AM",
                "value": 412.6000061035156
            },
            {
                "time": "Mon, Apr 8, 10:00 AM",
                "value": 414.29998779296875
            },
            {
                "time": "Mon, Apr 8, 10:01 AM",
                "value": 416.29998779296875
            },
            {
                "time": "Mon, Apr 8, 10:01 AM",
                "value": 418.1000061035156
            },
            {
                "time": "Mon, Apr 8, 10:02 AM",
                "value": 420
            },
            {
                "time": "Mon, Apr 8, 10:02 AM",
                "value": 422.29998779296875
            },
            {
                "time": "Mon, Apr 8, 10:03 AM",
                "value": 424.29998779296875
            },
            {
                "time": "Mon, Apr 8, 10:03 AM",
                "value": 424.6000061035156
            },
            {
                "time": "Mon, Apr 8, 10:04 AM",
                "value": 426.29998779296875
            },
            {
                "time": "Mon, Apr 8, 10:04 AM",
                "value": 427.1000061035156
            },
            {
                "time": "Mon, Apr 8, 10:05 AM",
                "value": 428.3999938964844
            },
            {
                "time": "Mon, Apr 8, 10:05 AM",
                "value": 429.6000061035156
            },
            {
                "time": "Mon, Apr 8, 10:06 AM",
                "value": 431.29998779296875
            },
            {
                "time": "Mon, Apr 8, 10:06 AM",
                "value": 433.1000061035156
            },
            {
                "time": "Mon, Apr 8, 10:07 AM",
                "value": 435
            },
            {
                "time": "Mon, Apr 8, 10:07 AM",
                "value": 436.29998779296875
            },
            {
                "time": "Mon, Apr 8, 10:08 AM",
                "value": 438
            },
            {
                "time": "Mon, Apr 8, 10:08 AM",
                "value": 440.1000061035156
            },
            {
                "time": "Mon, Apr 8, 10:09 AM",
                "value": 441.29998779296875
            },
            {
                "time": "Mon, Apr 8, 10:09 AM",
                "value": 441.79998779296875
            },
            {
                "time": "Mon, Apr 8, 10:10 AM",
                "value": 444.20001220703125
            },
            {
                "time": "Mon, Apr 8, 10:10 AM",
                "value": 446.29998779296875
            },
            {
                "time": "Mon, Apr 8, 10:11 AM",
                "value": 448
            },
            {
                "time": "Mon, Apr 8, 10:11 AM",
                "value": 448.8999938964844
            },
            {
                "time": "Mon, Apr 8, 10:12 AM",
                "value": 449.70001220703125
            },
            {
                "time": "Mon, Apr 8, 10:12 AM",
                "value": 451.70001220703125
            },
            {
                "time": "Mon, Apr 8, 10:13 AM",
                "value": 453.5
            },
            {
                "time": "Mon, Apr 8, 10:13 AM",
                "value": 455.5
            },
            {
                "time": "Mon, Apr 8, 10:14 AM",
                "value": 457.3999938964844
            },
            {
                "time": "Mon, Apr 8, 10:14 AM",
                "value": 458.79998779296875
            },
            {
                "time": "Mon, Apr 8, 10:15 AM",
                "value": 459.6000061035156
            },
            {
                "time": "Mon, Apr 8, 10:15 AM",
                "value": 460.1000061035156
            },
            {
                "time": "Mon, Apr 8, 10:16 AM",
                "value": 460.8999938964844
            },
            {
                "time": "Mon, Apr 8, 10:16 AM",
                "value": 462.8999938964844
            },
            {
                "time": "Mon, Apr 8, 10:17 AM",
                "value": 464.3999938964844
            },
            {
                "time": "Mon, Apr 8, 10:17 AM",
                "value": 465.6000061035156
            },
            {
                "time": "Mon, Apr 8, 10:18 AM",
                "value": 466.79998779296875
            },
            {
                "time": "Mon, Apr 8, 10:18 AM",
                "value": 468.3999938964844
            },
            {
                "time": "Mon, Apr 8, 10:19 AM",
                "value": 470.6000061035156
            },
            {
                "time": "Mon, Apr 8, 10:19 AM",
                "value": 473.5
            },
            {
                "time": "Mon, Apr 8, 10:20 AM",
                "value": 475.6000061035156
            },
            {
                "time": "Mon, Apr 8, 10:20 AM",
                "value": 477
            },
            {
                "time": "Mon, Apr 8, 10:21 AM",
                "value": 478.70001220703125
            },
            {
                "time": "Mon, Apr 8, 10:21 AM",
                "value": 479.79998779296875
            },
            {
                "time": "Mon, Apr 8, 10:22 AM",
                "value": 480.8999938964844
            },
            {
                "time": "Mon, Apr 8, 10:22 AM",
                "value": 482.29998779296875
            },
            {
                "time": "Mon, Apr 8, 10:23 AM",
                "value": 484.29998779296875
            },
            {
                "time": "Mon, Apr 8, 10:23 AM",
                "value": 485.3999938964844
            },
            {
                "time": "Mon, Apr 8, 10:24 AM",
                "value": 486.20001220703125
            },
            {
                "time": "Mon, Apr 8, 10:24 AM",
                "value": 488.20001220703125
            },
            {
                "time": "Mon, Apr 8, 10:25 AM",
                "value": 489.8999938964844
            },
            {
                "time": "Mon, Apr 8, 10:25 AM",
                "value": 491.6000061035156
            },
            {
                "time": "Mon, Apr 8, 10:26 AM",
                "value": 492.70001220703125
            },
            {
                "time": "Mon, Apr 8, 10:26 AM",
                "value": 493.6000061035156
            },
            {
                "time": "Mon, Apr 8, 10:27 AM",
                "value": 494.5
            },
            {
                "time": "Mon, Apr 8, 10:27 AM",
                "value": 495.29998779296875
            },
            {
                "time": "Mon, Apr 8, 10:28 AM",
                "value": 497.5
            },
            {
                "time": "Mon, Apr 8, 10:28 AM",
                "value": 498.79998779296875
            },
            {
                "time": "Mon, Apr 8, 10:29 AM",
                "value": 500.5
            },
            {
                "time": "Mon, Apr 8, 10:29 AM",
                "value": 502.3999938964844
            },
            {
                "time": "Mon, Apr 8, 10:30 AM",
                "value": 503.79998779296875
            },
            {
                "time": "Mon, Apr 8, 10:30 AM",
                "value": 505.5
            },
            {
                "time": "Mon, Apr 8, 10:31 AM",
                "value": 507
            },
            {
                "time": "Mon, Apr 8, 10:31 AM",
                "value": 508.6000061035156
            },
            {
                "time": "Mon, Apr 8, 10:32 AM",
                "value": 509.29998779296875
            },
            {
                "time": "Mon, Apr 8, 10:32 AM",
                "value": 511.20001220703125
            },
            {
                "time": "Mon, Apr 8, 10:33 AM",
                "value": 513
            },
            {
                "time": "Mon, Apr 8, 10:33 AM",
                "value": 515.4000244140625
            },
            {
                "time": "Mon, Apr 8, 10:34 AM",
                "value": 517.0999755859375
            },
            {
                "time": "Mon, Apr 8, 10:34 AM",
                "value": 517.7000122070312
            },
            {
                "time": "Mon, Apr 8, 10:35 AM",
                "value": 519.2000122070312
            },
            {
                "time": "Mon, Apr 8, 10:35 AM",
                "value": 522.5
            },
            {
                "time": "Mon, Apr 8, 10:36 AM",
                "value": 523.5
            },
            {
                "time": "Mon, Apr 8, 10:36 AM",
                "value": 523.7999877929688
            },
            {
                "time": "Mon, Apr 8, 10:37 AM",
                "value": 524.5
            },
            {
                "time": "Mon, Apr 8, 10:37 AM",
                "value": 525.2000122070312
            },
            {
                "time": "Mon, Apr 8, 10:38 AM",
                "value": 526
            },
            {
                "time": "Mon, Apr 8, 10:38 AM",
                "value": 528.5999755859375
            },
            {
                "time": "Mon, Apr 8, 10:39 AM",
                "value": 530.5999755859375
            },
            {
                "time": "Mon, Apr 8, 10:39 AM",
                "value": 532.2000122070312
            },
            {
                "time": "Mon, Apr 8, 10:40 AM",
                "value": 532.0999755859375
            },
            {
                "time": "Mon, Apr 8, 10:40 AM",
                "value": 534.5
            },
            {
                "time": "Mon, Apr 8, 10:41 AM",
                "value": 536
            },
            {
                "time": "Mon, Apr 8, 10:41 AM",
                "value": 537.0999755859375
            },
            {
                "time": "Mon, Apr 8, 10:42 AM",
                "value": 538
            },
            {
                "time": "Mon, Apr 8, 10:42 AM",
                "value": 539.9000244140625
            },
            {
                "time": "Mon, Apr 8, 10:43 AM",
                "value": 541.7999877929688
            },
            {
                "time": "Mon, Apr 8, 10:43 AM",
                "value": 544.2000122070312
            },
            {
                "time": "Mon, Apr 8, 10:44 AM",
                "value": 544.7000122070312
            },
            {
                "time": "Mon, Apr 8, 10:44 AM",
                "value": 546.4000244140625
            },
            {
                "time": "Mon, Apr 8, 10:45 AM",
                "value": 548.0999755859375
            },
            {
                "time": "Mon, Apr 8, 10:45 AM",
                "value": 548.4000244140625
            },
            {
                "time": "Mon, Apr 8, 10:46 AM",
                "value": 550.9000244140625
            },
            {
                "time": "Mon, Apr 8, 10:46 AM",
                "value": 553.0999755859375
            },
            {
                "time": "Mon, Apr 8, 10:47 AM",
                "value": 554.9000244140625
            },
            {
                "time": "Mon, Apr 8, 10:47 AM",
                "value": 555.7999877929688
            },
            {
                "time": "Mon, Apr 8, 10:48 AM",
                "value": 557.0999755859375
            },
            {
                "time": "Mon, Apr 8, 10:48 AM",
                "value": 557.9000244140625
            },
            {
                "time": "Mon, Apr 8, 10:49 AM",
                "value": 558.7999877929688
            },
            {
                "time": "Mon, Apr 8, 10:49 AM",
                "value": 561.5
            },
            {
                "time": "Mon, Apr 8, 10:50 AM",
                "value": 562.2999877929688
            },
            {
                "time": "Mon, Apr 8, 10:50 AM",
                "value": 563.0999755859375
            },
            {
                "time": "Mon, Apr 8, 10:51 AM",
                "value": 564.5
            },
            {
                "time": "Mon, Apr 8, 10:51 AM",
                "value": 566.9000244140625
            },
            {
                "time": "Mon, Apr 8, 10:52 AM",
                "value": 568.7000122070312
            },
            {
                "time": "Mon, Apr 8, 10:52 AM",
                "value": 568.5
            },
            {
                "time": "Mon, Apr 8, 10:53 AM",
                "value": 569.7999877929688
            },
            {
                "time": "Mon, Apr 8, 10:53 AM",
                "value": 572.0999755859375
            },
            {
                "time": "Mon, Apr 8, 10:54 AM",
                "value": 573
            },
            {
                "time": "Mon, Apr 8, 10:54 AM",
                "value": 574.7999877929688
            },
            {
                "time": "Mon, Apr 8, 10:55 AM",
                "value": 576.9000244140625
            },
            {
                "time": "Mon, Apr 8, 10:55 AM",
                "value": 578
            },
            {
                "time": "Mon, Apr 8, 10:56 AM",
                "value": 579.4000244140625
            },
            {
                "time": "Mon, Apr 8, 10:56 AM",
                "value": 581
            },
            {
                "time": "Mon, Apr 8, 10:57 AM",
                "value": 582
            },
            {
                "time": "Mon, Apr 8, 10:57 AM",
                "value": 584.5999755859375
            },
            {
                "time": "Mon, Apr 8, 10:58 AM",
                "value": 586
            },
            {
                "time": "Mon, Apr 8, 10:58 AM",
                "value": 586.5
            },
            {
                "time": "Mon, Apr 8, 10:59 AM",
                "value": 587.2999877929688
            },
            {
                "time": "Mon, Apr 8, 10:59 AM",
                "value": 589
            },
            {
                "time": "Mon, Apr 8, 11:00 AM",
                "value": 590
            },
            {
                "time": "Mon, Apr 8, 11:00 AM",
                "value": 591.2999877929688
            },
            {
                "time": "Mon, Apr 8, 11:01 AM",
                "value": 593.0999755859375
            },
            {
                "time": "Mon, Apr 8, 11:01 AM",
                "value": 593.9000244140625
            },
            {
                "time": "Mon, Apr 8, 11:02 AM",
                "value": 595.0999755859375
            },
            {
                "time": "Mon, Apr 8, 11:02 AM",
                "value": 596.0999755859375
            },
            {
                "time": "Mon, Apr 8, 11:03 AM",
                "value": 596.5
            },
            {
                "time": "Mon, Apr 8, 11:03 AM",
                "value": 597.0999755859375
            },
            {
                "time": "Mon, Apr 8, 11:04 AM",
                "value": 597.5
            },
            {
                "time": "Mon, Apr 8, 11:04 AM",
                "value": 597.7999877929688
            },
            {
                "time": "Mon, Apr 8, 11:05 AM",
                "value": 598.5
            },
            {
                "time": "Mon, Apr 8, 11:05 AM",
                "value": 600.0999755859375
            },
            {
                "time": "Mon, Apr 8, 11:06 AM",
                "value": 601.7999877929688
            },
            {
                "time": "Mon, Apr 8, 11:06 AM",
                "value": 604
            },
            {
                "time": "Mon, Apr 8, 11:07 AM",
                "value": 606.5999755859375
            },
            {
                "time": "Mon, Apr 8, 11:07 AM",
                "value": 606.2000122070312
            },
            {
                "time": "Mon, Apr 8, 11:08 AM",
                "value": 607.0999755859375
            },
            {
                "time": "Mon, Apr 8, 11:08 AM",
                "value": 608.7999877929688
            },
            {
                "time": "Mon, Apr 8, 11:09 AM",
                "value": 609.9000244140625
            },
            {
                "time": "Mon, Apr 8, 11:09 AM",
                "value": 610.2000122070312
            },
            {
                "time": "Mon, Apr 8, 11:10 AM",
                "value": 611.4000244140625
            },
            {
                "time": "Mon, Apr 8, 11:10 AM",
                "value": 613
            },
            {
                "time": "Mon, Apr 8, 11:11 AM",
                "value": 612.2999877929688
            },
            {
                "time": "Mon, Apr 8, 11:11 AM",
                "value": 612.4000244140625
            },
            {
                "time": "Mon, Apr 8, 11:12 AM",
                "value": 612.5
            },
            {
                "time": "Mon, Apr 8, 11:12 AM",
                "value": 614.2999877929688
            },
            {
                "time": "Mon, Apr 8, 11:13 AM",
                "value": 615.9000244140625
            },
            {
                "time": "Mon, Apr 8, 11:13 AM",
                "value": 617.5999755859375
            },
            {
                "time": "Mon, Apr 8, 11:14 AM",
                "value": 620.0999755859375
            },
            {
                "time": "Mon, Apr 8, 11:14 AM",
                "value": 621.2000122070312
            },
            {
                "time": "Mon, Apr 8, 11:15 AM",
                "value": 622.5
            },
            {
                "time": "Mon, Apr 8, 11:15 AM",
                "value": 622.0999755859375
            },
            {
                "time": "Mon, Apr 8, 11:16 AM",
                "value": 623.4000244140625
            },
            {
                "time": "Mon, Apr 8, 11:16 AM",
                "value": 624.2000122070312
            },
            {
                "time": "Mon, Apr 8, 11:17 AM",
                "value": 625.0999755859375
            },
            {
                "time": "Mon, Apr 8, 11:17 AM",
                "value": 627.2999877929688
            },
            {
                "time": "Mon, Apr 8, 11:18 AM",
                "value": 629.4000244140625
            },
            {
                "time": "Mon, Apr 8, 11:18 AM",
                "value": 631.5
            },
            {
                "time": "Mon, Apr 8, 11:19 AM",
                "value": 632
            },
            {
                "time": "Mon, Apr 8, 11:19 AM",
                "value": 633.7999877929688
            },
            {
                "time": "Mon, Apr 8, 11:20 AM",
                "value": 633.9000244140625
            },
            {
                "time": "Mon, Apr 8, 11:20 AM",
                "value": 635.5
            },
            {
                "time": "Mon, Apr 8, 11:21 AM",
                "value": 637.9000244140625
            },
            {
                "time": "Mon, Apr 8, 11:21 AM",
                "value": 639.2999877929688
            },
            {
                "time": "Mon, Apr 8, 11:22 AM",
                "value": 638.9000244140625
            },
            {
                "time": "Mon, Apr 8, 11:22 AM",
                "value": 639.7999877929688
            },
            {
                "time": "Mon, Apr 8, 11:23 AM",
                "value": 640.7000122070312
            },
            {
                "time": "Mon, Apr 8, 11:23 AM",
                "value": 640.7000122070312
            },
            {
                "time": "Mon, Apr 8, 11:24 AM",
                "value": 644.9000244140625
            },
            {
                "time": "Mon, Apr 8, 11:24 AM",
                "value": 646.9000244140625
            },
            {
                "time": "Mon, Apr 8, 11:25 AM",
                "value": 645.5999755859375
            },
            {
                "time": "Mon, Apr 8, 11:25 AM",
                "value": 645.4000244140625
            },
            {
                "time": "Mon, Apr 8, 11:26 AM",
                "value": 644.0999755859375
            },
            {
                "time": "Mon, Apr 8, 11:26 AM",
                "value": 643.5999755859375
            },
            {
                "time": "Mon, Apr 8, 11:27 AM",
                "value": 643.7000122070312
            },
            {
                "time": "Mon, Apr 8, 11:27 AM",
                "value": 645.5999755859375
            },
            {
                "time": "Mon, Apr 8, 11:28 AM",
                "value": 646.0999755859375
            },
            {
                "time": "Mon, Apr 8, 11:28 AM",
                "value": 650.7000122070312
            },
            {
                "time": "Mon, Apr 8, 11:29 AM",
                "value": 654.5999755859375
            },
            {
                "time": "Mon, Apr 8, 11:29 AM",
                "value": 659
            },
            {
                "time": "Mon, Apr 8, 11:30 AM",
                "value": 659.2999877929688
            },
            {
                "time": "Mon, Apr 8, 11:30 AM",
                "value": 655
            },
            {
                "time": "Mon, Apr 8, 11:31 AM",
                "value": 652.5999755859375
            },
            {
                "time": "Mon, Apr 8, 11:31 AM",
                "value": 653.7000122070312
            },
            {
                "time": "Mon, Apr 8, 11:32 AM",
                "value": 652.7999877929688
            },
            {
                "time": "Mon, Apr 8, 11:32 AM",
                "value": 655.0999755859375
            },
            {
                "time": "Mon, Apr 8, 11:33 AM",
                "value": 657.7000122070312
            },
            {
                "time": "Mon, Apr 8, 11:33 AM",
                "value": 659.5999755859375
            },
            {
                "time": "Mon, Apr 8, 11:34 AM",
                "value": 662.0999755859375
            },
            {
                "time": "Mon, Apr 8, 11:34 AM",
                "value": 661.2000122070312
            },
            {
                "time": "Mon, Apr 8, 11:35 AM",
                "value": 664.2999877929688
            },
            {
                "time": "Mon, Apr 8, 11:35 AM",
                "value": 665.7000122070312
            },
            {
                "time": "Mon, Apr 8, 11:36 AM",
                "value": 665.7000122070312
            },
            {
                "time": "Mon, Apr 8, 11:36 AM",
                "value": 667.5999755859375
            },
            {
                "time": "Mon, Apr 8, 11:37 AM",
                "value": 668.7000122070312
            },
            {
                "time": "Mon, Apr 8, 11:37 AM",
                "value": 669
            },
            {
                "time": "Mon, Apr 8, 11:38 AM",
                "value": 670.9000244140625
            },
            {
                "time": "Mon, Apr 8, 11:38 AM",
                "value": 673.7000122070312
            },
            {
                "time": "Mon, Apr 8, 11:39 AM",
                "value": 670.5
            },
            {
                "time": "Mon, Apr 8, 11:39 AM",
                "value": 670.7000122070312
            },
            {
                "time": "Mon, Apr 8, 11:40 AM",
                "value": 672.7999877929688
            },
            {
                "time": "Mon, Apr 8, 11:40 AM",
                "value": 677.2999877929688
            },
            {
                "time": "Mon, Apr 8, 11:41 AM",
                "value": 678.7999877929688
            },
            {
                "time": "Mon, Apr 8, 11:41 AM",
                "value": 681.0999755859375
            },
            {
                "time": "Mon, Apr 8, 11:42 AM",
                "value": 681.7000122070312
            },
            {
                "time": "Mon, Apr 8, 11:42 AM",
                "value": 683.5999755859375
            },
            {
                "time": "Mon, Apr 8, 11:43 AM",
                "value": 684.7000122070312
            },
            {
                "time": "Mon, Apr 8, 11:43 AM",
                "value": 682.7000122070312
            },
            {
                "time": "Mon, Apr 8, 11:44 AM",
                "value": 687.2999877929688
            },
            {
                "time": "Mon, Apr 8, 11:44 AM",
                "value": 690
            },
            {
                "time": "Mon, Apr 8, 11:45 AM",
                "value": 691.7999877929688
            },
            {
                "time": "Mon, Apr 8, 11:45 AM",
                "value": 692.5
            },
            {
                "time": "Mon, Apr 8, 11:46 AM",
                "value": 688.7999877929688
            },
            {
                "time": "Mon, Apr 8, 11:46 AM",
                "value": 688.2999877929688
            },
            {
                "time": "Mon, Apr 8, 11:47 AM",
                "value": 686.5999755859375
            },
            {
                "time": "Mon, Apr 8, 11:47 AM",
                "value": 688.7000122070312
            },
            {
                "time": "Mon, Apr 8, 11:48 AM",
                "value": 691.2000122070312
            },
            {
                "time": "Mon, Apr 8, 11:48 AM",
                "value": 697.7999877929688
            },
            {
                "time": "Mon, Apr 8, 11:49 AM",
                "value": 702.9000244140625
            },
            {
                "time": "Mon, Apr 8, 11:49 AM",
                "value": 697.7000122070312
            },
            {
                "time": "Mon, Apr 8, 11:50 AM",
                "value": 703.5
            },
            {
                "time": "Mon, Apr 8, 11:50 AM",
                "value": 708.4000244140625
            },
            {
                "time": "Mon, Apr 8, 11:51 AM",
                "value": 710.2999877929688
            },
            {
                "time": "Mon, Apr 8, 11:51 AM",
                "value": 709.2000122070312
            },
            {
                "time": "Mon, Apr 8, 11:52 AM",
                "value": 708.2000122070312
            },
            {
                "time": "Mon, Apr 8, 11:52 AM",
                "value": 673.5
            },
            {
                "time": "Mon, Apr 8, 11:53 AM",
                "value": 642.9000244140625
            },
            {
                "time": "Mon, Apr 8, 11:53 AM",
                "value": 628
            },
            {
                "time": "Mon, Apr 8, 11:54 AM",
                "value": 669.7000122070312
            },
            {
                "time": "Mon, Apr 8, 11:54 AM",
                "value": 702
            },
            {
                "time": "Mon, Apr 8, 11:55 AM",
                "value": 713.4000244140625
            },
            {
                "time": "Mon, Apr 8, 11:55 AM",
                "value": 713.7999877929688
            },
            {
                "time": "Mon, Apr 8, 11:56 AM",
                "value": 713.2000122070312
            },
            {
                "time": "Mon, Apr 8, 11:56 AM",
                "value": 712.5
            },
            {
                "time": "Mon, Apr 8, 11:57 AM",
                "value": 715.4000244140625
            },
            {
                "time": "Mon, Apr 8, 11:57 AM",
                "value": 717.7000122070312
            },
            {
                "time": "Mon, Apr 8, 11:58 AM",
                "value": 717.4000244140625
            },
            {
                "time": "Mon, Apr 8, 11:58 AM",
                "value": 718.7999877929688
            },
            {
                "time": "Mon, Apr 8, 11:59 AM",
                "value": 720.7000122070312
            },
            {
                "time": "Mon, Apr 8, 11:59 AM",
                "value": 721
            },
            {
                "time": "Mon, Apr 8, 12:00 PM",
                "value": 724.9000244140625
            },
            {
                "time": "Mon, Apr 8, 12:00 PM",
                "value": 725
            },
            {
                "time": "Mon, Apr 8, 12:01 PM",
                "value": 724.2999877929688
            },
            {
                "time": "Mon, Apr 8, 12:01 PM",
                "value": 727.5999755859375
            },
            {
                "time": "Mon, Apr 8, 12:02 PM",
                "value": 728.7000122070312
            },
            {
                "time": "Mon, Apr 8, 12:02 PM",
                "value": 731.4000244140625
            },
            {
                "time": "Mon, Apr 8, 12:03 PM",
                "value": 732.7000122070312
            },
            {
                "time": "Mon, Apr 8, 12:03 PM",
                "value": 730.4000244140625
            },
            {
                "time": "Mon, Apr 8, 12:04 PM",
                "value": 735.4000244140625
            },
            {
                "time": "Mon, Apr 8, 12:04 PM",
                "value": 734.5999755859375
            },
            {
                "time": "Mon, Apr 8, 12:05 PM",
                "value": 736.9000244140625
            },
            {
                "time": "Mon, Apr 8, 12:05 PM",
                "value": 735.5
            },
            {
                "time": "Mon, Apr 8, 12:06 PM",
                "value": 736.2999877929688
            },
            {
                "time": "Mon, Apr 8, 12:06 PM",
                "value": 736.9000244140625
            },
            {
                "time": "Mon, Apr 8, 12:07 PM",
                "value": 736.0999755859375
            },
            {
                "time": "Mon, Apr 8, 12:07 PM",
                "value": 738.9000244140625
            },
            {
                "time": "Mon, Apr 8, 12:08 PM",
                "value": 738.5
            },
            {
                "time": "Mon, Apr 8, 12:08 PM",
                "value": 740.2999877929688
            },
            {
                "time": "Mon, Apr 8, 12:09 PM",
                "value": 738.4000244140625
            },
            {
                "time": "Mon, Apr 8, 12:09 PM",
                "value": 741.5999755859375
            },
            {
                "time": "Mon, Apr 8, 12:10 PM",
                "value": 740.2000122070312
            },
            {
                "time": "Mon, Apr 8, 12:10 PM",
                "value": 746.7000122070312
            },
            {
                "time": "Mon, Apr 8, 12:11 PM",
                "value": 750.0999755859375
            },
            {
                "time": "Mon, Apr 8, 12:11 PM",
                "value": 742.9000244140625
            },
            {
                "time": "Mon, Apr 8, 12:12 PM",
                "value": 750.2999877929688
            },
            {
                "time": "Mon, Apr 8, 12:12 PM",
                "value": 741
            },
            {
                "time": "Mon, Apr 8, 12:13 PM",
                "value": 745.7000122070312
            },
            {
                "time": "Mon, Apr 8, 12:13 PM",
                "value": 753
            },
            {
                "time": "Mon, Apr 8, 12:14 PM",
                "value": 750.5
            },
            {
                "time": "Mon, Apr 8, 12:14 PM",
                "value": 751.2999877929688
            },
            {
                "time": "Mon, Apr 8, 12:15 PM",
                "value": 752.0999755859375
            },
            {
                "time": "Mon, Apr 8, 12:15 PM",
                "value": 756.0999755859375
            },
            {
                "time": "Mon, Apr 8, 12:16 PM",
                "value": 755.7000122070312
            },
            {
                "time": "Mon, Apr 8, 12:16 PM",
                "value": 754.7000122070312
            },
            {
                "time": "Mon, Apr 8, 12:17 PM",
                "value": 756.9000244140625
            },
            {
                "time": "Mon, Apr 8, 12:17 PM",
                "value": 756
            },
            {
                "time": "Mon, Apr 8, 12:18 PM",
                "value": 756.2000122070312
            },
            {
                "time": "Mon, Apr 8, 12:18 PM",
                "value": 756.7000122070312
            },
            {
                "time": "Mon, Apr 8, 12:19 PM",
                "value": 756.2999877929688
            },
            {
                "time": "Mon, Apr 8, 12:19 PM",
                "value": 756.2999877929688
            },
            {
                "time": "Mon, Apr 8, 12:20 PM",
                "value": 757.0999755859375
            },
            {
                "time": "Mon, Apr 8, 12:20 PM",
                "value": 761.2999877929688
            },
            {
                "time": "Mon, Apr 8, 12:21 PM",
                "value": 764.9000244140625
            },
            {
                "time": "Mon, Apr 8, 12:21 PM",
                "value": 765.5
            },
            {
                "time": "Mon, Apr 8, 12:22 PM",
                "value": 765.0999755859375
            },
            {
                "time": "Mon, Apr 8, 12:22 PM",
                "value": 763.7000122070312
            },
            {
                "time": "Mon, Apr 8, 12:23 PM",
                "value": 762.0999755859375
            },
            {
                "time": "Mon, Apr 8, 12:23 PM",
                "value": 764.5999755859375
            },
            {
                "time": "Mon, Apr 8, 12:24 PM",
                "value": 765.2000122070312
            },
            {
                "time": "Mon, Apr 8, 12:24 PM",
                "value": 762.5
            },
            {
                "time": "Mon, Apr 8, 12:25 PM",
                "value": 768
            },
            {
                "time": "Mon, Apr 8, 12:25 PM",
                "value": 765.5
            },
            {
                "time": "Mon, Apr 8, 12:26 PM",
                "value": 763.5999755859375
            },
            {
                "time": "Mon, Apr 8, 12:26 PM",
                "value": 772
            },
            {
                "time": "Mon, Apr 8, 12:27 PM",
                "value": 768.4000244140625
            },
            {
                "time": "Mon, Apr 8, 12:27 PM",
                "value": 768.2000122070312
            },
            {
                "time": "Mon, Apr 8, 12:28 PM",
                "value": 776.0999755859375
            },
            {
                "time": "Mon, Apr 8, 12:28 PM",
                "value": 774.7999877929688
            },
            {
                "time": "Mon, Apr 8, 12:29 PM",
                "value": 773.7999877929688
            },
            {
                "time": "Mon, Apr 8, 12:29 PM",
                "value": 775.7999877929688
            },
            {
                "time": "Mon, Apr 8, 12:30 PM",
                "value": 773.5
            },
            {
                "time": "Mon, Apr 8, 12:30 PM",
                "value": 776.2999877929688
            },
            {
                "time": "Mon, Apr 8, 12:31 PM",
                "value": 777.7000122070312
            },
            {
                "time": "Mon, Apr 8, 12:31 PM",
                "value": 775.7000122070312
            },
            {
                "time": "Mon, Apr 8, 12:32 PM",
                "value": 776.4000244140625
            },
            {
                "time": "Mon, Apr 8, 12:32 PM",
                "value": 779.2000122070312
            },
            {
                "time": "Mon, Apr 8, 12:33 PM",
                "value": 780.7000122070312
            },
            {
                "time": "Mon, Apr 8, 12:33 PM",
                "value": 778.5999755859375
            },
            {
                "time": "Mon, Apr 8, 12:34 PM",
                "value": 779.2000122070312
            },
            {
                "time": "Mon, Apr 8, 12:34 PM",
                "value": 782.0999755859375
            },
            {
                "time": "Mon, Apr 8, 12:35 PM",
                "value": 779.7999877929688
            },
            {
                "time": "Mon, Apr 8, 12:35 PM",
                "value": 784.2999877929688
            },
            {
                "time": "Mon, Apr 8, 12:36 PM",
                "value": 784.2000122070312
            },
            {
                "time": "Mon, Apr 8, 12:36 PM",
                "value": 783.2999877929688
            },
            {
                "time": "Mon, Apr 8, 12:37 PM",
                "value": 786.2999877929688
            },
            {
                "time": "Mon, Apr 8, 12:37 PM",
                "value": 786.2000122070312
            },
            {
                "time": "Mon, Apr 8, 12:38 PM",
                "value": 785.7999877929688
            },
            {
                "time": "Mon, Apr 8, 12:38 PM",
                "value": 786.5999755859375
            },
            {
                "time": "Mon, Apr 8, 12:39 PM",
                "value": 788.2999877929688
            },
            {
                "time": "Mon, Apr 8, 12:39 PM",
                "value": 787.2000122070312
            },
            {
                "time": "Mon, Apr 8, 12:40 PM",
                "value": 786.7000122070312
            },
            {
                "time": "Mon, Apr 8, 12:40 PM",
                "value": 791.9000244140625
            },
            {
                "time": "Mon, Apr 8, 12:41 PM",
                "value": 788
            },
            {
                "time": "Mon, Apr 8, 12:41 PM",
                "value": 792.7999877929688
            },
            {
                "time": "Mon, Apr 8, 12:42 PM",
                "value": 790.2999877929688
            },
            {
                "time": "Mon, Apr 8, 12:42 PM",
                "value": 792.7999877929688
            },
            {
                "time": "Mon, Apr 8, 12:43 PM",
                "value": 794.5
            },
            {
                "time": "Mon, Apr 8, 12:43 PM",
                "value": 796.7000122070312
            },
            {
                "time": "Mon, Apr 8, 12:44 PM",
                "value": 797.7999877929688
            },
            {
                "time": "Mon, Apr 8, 12:44 PM",
                "value": 795.5
            },
            {
                "time": "Mon, Apr 8, 12:45 PM",
                "value": 796.5999755859375
            },
            {
                "time": "Mon, Apr 8, 12:45 PM",
                "value": 802
            },
            {
                "time": "Mon, Apr 8, 12:46 PM",
                "value": 801
            },
            {
                "time": "Mon, Apr 8, 12:46 PM",
                "value": 798.0999755859375
            },
            {
                "time": "Mon, Apr 8, 12:47 PM",
                "value": 797.9000244140625
            },
            {
                "time": "Mon, Apr 8, 12:47 PM",
                "value": 802
            },
            {
                "time": "Mon, Apr 8, 12:48 PM",
                "value": 801
            },
            {
                "time": "Mon, Apr 8, 12:48 PM",
                "value": 802
            },
            {
                "time": "Mon, Apr 8, 12:49 PM",
                "value": 798.5
            },
            {
                "time": "Mon, Apr 8, 12:49 PM",
                "value": 802
            },
            {
                "time": "Mon, Apr 8, 12:50 PM",
                "value": 801
            },
            {
                "time": "Mon, Apr 8, 12:50 PM",
                "value": 803
            },
            {
                "time": "Mon, Apr 8, 12:51 PM",
                "value": 803
            },
            {
                "time": "Mon, Apr 8, 12:51 PM",
                "value": 806
            },
            {
                "time": "Mon, Apr 8, 12:52 PM",
                "value": 806
            },
            {
                "time": "Mon, Apr 8, 12:52 PM",
                "value": 807
            },
            {
                "time": "Mon, Apr 8, 12:53 PM",
                "value": 805
            },
            {
                "time": "Mon, Apr 8, 12:53 PM",
                "value": 806
            },
            {
                "time": "Mon, Apr 8, 12:54 PM",
                "value": 806
            },
            {
                "time": "Mon, Apr 8, 12:54 PM",
                "value": 807
            },
            {
                "time": "Mon, Apr 8, 12:55 PM",
                "value": 806
            },
            {
                "time": "Mon, Apr 8, 12:55 PM",
                "value": 808
            },
            {
                "time": "Mon, Apr 8, 12:56 PM",
                "value": 811
            },
            {
                "time": "Mon, Apr 8, 12:56 PM",
                "value": 811
            },
            {
                "time": "Mon, Apr 8, 12:57 PM",
                "value": 814
            },
            {
                "time": "Mon, Apr 8, 12:57 PM",
                "value": 812
            },
            {
                "time": "Mon, Apr 8, 12:58 PM",
                "value": 811
            },
            {
                "time": "Mon, Apr 8, 12:58 PM",
                "value": 814
            },
            {
                "time": "Mon, Apr 8, 12:59 PM",
                "value": 813
            },
            {
                "time": "Mon, Apr 8, 12:59 PM",
                "value": 815
            },
            {
                "time": "Mon, Apr 8, 1:00 PM",
                "value": 814
            },
            {
                "time": "Mon, Apr 8, 1:00 PM",
                "value": 814
            },
            {
                "time": "Mon, Apr 8, 1:01 PM",
                "value": 814
            },
            {
                "time": "Mon, Apr 8, 1:01 PM",
                "value": 814
            },
            {
                "time": "Mon, Apr 8, 1:02 PM",
                "value": 816
            },
            {
                "time": "Mon, Apr 8, 1:02 PM",
                "value": 814
            },
            {
                "time": "Mon, Apr 8, 1:03 PM",
                "value": 816
            },
            {
                "time": "Mon, Apr 8, 1:03 PM",
                "value": 813
            },
            {
                "time": "Mon, Apr 8, 1:04 PM",
                "value": 818
            },
            {
                "time": "Mon, Apr 8, 1:04 PM",
                "value": 815
            },
            {
                "time": "Mon, Apr 8, 1:05 PM",
                "value": 817
            },
            {
                "time": "Mon, Apr 8, 1:05 PM",
                "value": 821
            },
            {
                "time": "Mon, Apr 8, 1:06 PM",
                "value": 820
            },
            {
                "time": "Mon, Apr 8, 1:06 PM",
                "value": 821
            },
            {
                "time": "Mon, Apr 8, 1:07 PM",
                "value": 822
            },
            {
                "time": "Mon, Apr 8, 1:07 PM",
                "value": 821
            },
            {
                "time": "Mon, Apr 8, 1:08 PM",
                "value": 821
            },
            {
                "time": "Mon, Apr 8, 1:08 PM",
                "value": 818
            },
            {
                "time": "Mon, Apr 8, 1:09 PM",
                "value": 819
            },
            {
                "time": "Mon, Apr 8, 1:09 PM",
                "value": 820
            },
            {
                "time": "Mon, Apr 8, 1:10 PM",
                "value": 819
            },
            {
                "time": "Mon, Apr 8, 1:10 PM",
                "value": 820
            },
            {
                "time": "Mon, Apr 8, 1:11 PM",
                "value": 824
            },
            {
                "time": "Mon, Apr 8, 1:11 PM",
                "value": 823
            },
            {
                "time": "Mon, Apr 8, 1:12 PM",
                "value": 824
            },
            {
                "time": "Mon, Apr 8, 1:12 PM",
                "value": 821
            },
            {
                "time": "Mon, Apr 8, 1:13 PM",
                "value": 824
            },
            {
                "time": "Mon, Apr 8, 1:13 PM",
                "value": 822
            },
            {
                "time": "Mon, Apr 8, 1:14 PM",
                "value": 820
            },
            {
                "time": "Mon, Apr 8, 1:14 PM",
                "value": 822
            },
            {
                "time": "Mon, Apr 8, 1:15 PM",
                "value": 825
            },
            {
                "time": "Mon, Apr 8, 1:15 PM",
                "value": 824
            },
            {
                "time": "Mon, Apr 8, 1:16 PM",
                "value": 827
            },
            {
                "time": "Mon, Apr 8, 1:16 PM",
                "value": 828
            },
            {
                "time": "Mon, Apr 8, 1:17 PM",
                "value": 825
            },
            {
                "time": "Mon, Apr 8, 1:17 PM",
                "value": 823
            },
            {
                "time": "Mon, Apr 8, 1:18 PM",
                "value": 824
            },
            {
                "time": "Mon, Apr 8, 1:18 PM",
                "value": 825
            },
            {
                "time": "Mon, Apr 8, 1:19 PM",
                "value": 826
            },
            {
                "time": "Mon, Apr 8, 1:19 PM",
                "value": 827
            },
            {
                "time": "Mon, Apr 8, 1:20 PM",
                "value": 826
            },
            {
                "time": "Mon, Apr 8, 1:20 PM",
                "value": 826
            },
            {
                "time": "Mon, Apr 8, 1:21 PM",
                "value": 827
            },
            {
                "time": "Mon, Apr 8, 1:21 PM",
                "value": 828
            },
            {
                "time": "Mon, Apr 8, 1:22 PM",
                "value": 829
            },
            {
                "time": "Mon, Apr 8, 1:22 PM",
                "value": 831
            },
            {
                "time": "Mon, Apr 8, 1:23 PM",
                "value": 830
            },
            {
                "time": "Mon, Apr 8, 1:23 PM",
                "value": 828
            },
            {
                "time": "Mon, Apr 8, 1:24 PM",
                "value": 828
            },
            {
                "time": "Mon, Apr 8, 1:24 PM",
                "value": 829
            },
            {
                "time": "Mon, Apr 8, 1:25 PM",
                "value": 830
            },
            {
                "time": "Mon, Apr 8, 1:25 PM",
                "value": 829
            },
            {
                "time": "Mon, Apr 8, 1:26 PM",
                "value": 829
            },
            {
                "time": "Mon, Apr 8, 1:26 PM",
                "value": 829
            },
            {
                "time": "Mon, Apr 8, 1:27 PM",
                "value": 830
            },
            {
                "time": "Mon, Apr 8, 1:27 PM",
                "value": 828
            },
            {
                "time": "Mon, Apr 8, 1:28 PM",
                "value": 832
            },
            {
                "time": "Mon, Apr 8, 1:28 PM",
                "value": 831
            },
            {
                "time": "Mon, Apr 8, 1:29 PM",
                "value": 830
            },
            {
                "time": "Mon, Apr 8, 1:29 PM",
                "value": 831
            },
            {
                "time": "Mon, Apr 8, 1:30 PM",
                "value": 831
            },
            {
                "time": "Mon, Apr 8, 1:30 PM",
                "value": 832
            },
            {
                "time": "Mon, Apr 8, 1:31 PM",
                "value": 835
            },
            {
                "time": "Mon, Apr 8, 1:31 PM",
                "value": 833
            },
            {
                "time": "Mon, Apr 8, 1:32 PM",
                "value": 835
            },
            {
                "time": "Mon, Apr 8, 1:32 PM",
                "value": 834
            },
            {
                "time": "Mon, Apr 8, 1:33 PM",
                "value": 833
            },
            {
                "time": "Mon, Apr 8, 1:33 PM",
                "value": 831
            },
            {
                "time": "Mon, Apr 8, 1:34 PM",
                "value": 832
            },
            {
                "time": "Mon, Apr 8, 1:34 PM",
                "value": 832
            },
            {
                "time": "Mon, Apr 8, 1:35 PM",
                "value": 832
            },
            {
                "time": "Mon, Apr 8, 1:35 PM",
                "value": 833
            },
            {
                "time": "Mon, Apr 8, 1:36 PM",
                "value": 835
            },
            {
                "time": "Mon, Apr 8, 1:36 PM",
                "value": 835
            },
            {
                "time": "Mon, Apr 8, 1:37 PM",
                "value": 833
            },
            {
                "time": "Mon, Apr 8, 1:37 PM",
                "value": 831
            },
            {
                "time": "Mon, Apr 8, 1:38 PM",
                "value": 830
            },
            {
                "time": "Mon, Apr 8, 1:38 PM",
                "value": 828
            },
            {
                "time": "Mon, Apr 8, 1:39 PM",
                "value": 834
            },
            {
                "time": "Mon, Apr 8, 1:39 PM",
                "value": 832
            },
            {
                "time": "Mon, Apr 8, 1:40 PM",
                "value": 832
            },
            {
                "time": "Mon, Apr 8, 1:40 PM",
                "value": 832
            },
            {
                "time": "Mon, Apr 8, 1:41 PM",
                "value": 833
            },
            {
                "time": "Mon, Apr 8, 1:41 PM",
                "value": 832
            },
            {
                "time": "Mon, Apr 8, 1:42 PM",
                "value": 832
            },
            {
                "time": "Mon, Apr 8, 1:42 PM",
                "value": 833
            },
            {
                "time": "Mon, Apr 8, 1:43 PM",
                "value": 835
            },
            {
                "time": "Mon, Apr 8, 1:43 PM",
                "value": 834
            },
            {
                "time": "Mon, Apr 8, 1:44 PM",
                "value": 834
            },
            {
                "time": "Mon, Apr 8, 1:44 PM",
                "value": 836
            },
            {
                "time": "Mon, Apr 8, 1:45 PM",
                "value": 834
            },
            {
                "time": "Mon, Apr 8, 1:45 PM",
                "value": 834
            },
            {
                "time": "Mon, Apr 8, 1:46 PM",
                "value": 832
            },
            {
                "time": "Mon, Apr 8, 1:46 PM",
                "value": 832
            },
            {
                "time": "Mon, Apr 8, 1:47 PM",
                "value": 835
            },
            {
                "time": "Mon, Apr 8, 1:47 PM",
                "value": 836
            },
            {
                "time": "Mon, Apr 8, 1:48 PM",
                "value": 837
            },
            {
                "time": "Mon, Apr 8, 1:48 PM",
                "value": 837
            },
            {
                "time": "Mon, Apr 8, 1:49 PM",
                "value": 835
            },
            {
                "time": "Mon, Apr 8, 1:49 PM",
                "value": 837
            },
            {
                "time": "Mon, Apr 8, 1:50 PM",
                "value": 838
            },
            {
                "time": "Mon, Apr 8, 1:50 PM",
                "value": 837
            },
            {
                "time": "Mon, Apr 8, 1:51 PM",
                "value": 835
            },
            {
                "time": "Mon, Apr 8, 1:51 PM",
                "value": 832
            },
            {
                "time": "Mon, Apr 8, 1:52 PM",
                "value": 835
            },
            {
                "time": "Mon, Apr 8, 1:52 PM",
                "value": 837
            },
            {
                "time": "Mon, Apr 8, 1:53 PM",
                "value": 839
            },
            {
                "time": "Mon, Apr 8, 1:53 PM",
                "value": 834
            },
            {
                "time": "Mon, Apr 8, 1:54 PM",
                "value": 833
            },
            {
                "time": "Mon, Apr 8, 1:54 PM",
                "value": 832
            },
            {
                "time": "Mon, Apr 8, 1:55 PM",
                "value": 832
            },
            {
                "time": "Mon, Apr 8, 1:55 PM",
                "value": 831
            },
            {
                "time": "Mon, Apr 8, 1:56 PM",
                "value": 827
            },
            {
                "time": "Mon, Apr 8, 1:56 PM",
                "value": 825
            },
            {
                "time": "Mon, Apr 8, 1:57 PM",
                "value": 823
            },
            {
                "time": "Mon, Apr 8, 1:57 PM",
                "value": 822
            },
            {
                "time": "Mon, Apr 8, 1:58 PM",
                "value": 820
            },
            {
                "time": "Mon, Apr 8, 1:58 PM",
                "value": 817
            },
            {
                "time": "Mon, Apr 8, 1:59 PM",
                "value": 815
            },
            {
                "time": "Mon, Apr 8, 1:59 PM",
                "value": 812
            },
            {
                "time": "Mon, Apr 8, 2:00 PM",
                "value": 808
            },
            {
                "time": "Mon, Apr 8, 2:00 PM",
                "value": 807
            },
            {
                "time": "Mon, Apr 8, 2:01 PM",
                "value": 807
            },
            {
                "time": "Mon, Apr 8, 2:01 PM",
                "value": 804
            },
            {
                "time": "Mon, Apr 8, 2:02 PM",
                "value": 796
            },
            {
                "time": "Mon, Apr 8, 2:02 PM",
                "value": 792.9000244140625
            },
            {
                "time": "Mon, Apr 8, 2:03 PM",
                "value": 790.2000122070312
            },
            {
                "time": "Mon, Apr 8, 2:03 PM",
                "value": 788.4000244140625
            },
            {
                "time": "Mon, Apr 8, 2:04 PM",
                "value": 785.9000244140625
            },
            {
                "time": "Mon, Apr 8, 2:04 PM",
                "value": 782.2000122070312
            },
            {
                "time": "Mon, Apr 8, 2:05 PM",
                "value": 775.7999877929688
            },
            {
                "time": "Mon, Apr 8, 2:05 PM",
                "value": 771.7999877929688
            },
            {
                "time": "Mon, Apr 8, 2:06 PM",
                "value": 768.0999755859375
            },
            {
                "time": "Mon, Apr 8, 2:06 PM",
                "value": 764.5
            },
            {
                "time": "Mon, Apr 8, 2:07 PM",
                "value": 761.7000122070312
            },
            {
                "time": "Mon, Apr 8, 2:07 PM",
                "value": 757.7999877929688
            },
            {
                "time": "Mon, Apr 8, 2:08 PM",
                "value": 750.5
            },
            {
                "time": "Mon, Apr 8, 2:08 PM",
                "value": 746.4000244140625
            },
            {
                "time": "Mon, Apr 8, 2:09 PM",
                "value": 741
            },
            {
                "time": "Mon, Apr 8, 2:09 PM",
                "value": 737.9000244140625
            },
            {
                "time": "Mon, Apr 8, 2:10 PM",
                "value": 733.2000122070312
            },
            {
                "time": "Mon, Apr 8, 2:10 PM",
                "value": 729.4000244140625
            },
            {
                "time": "Mon, Apr 8, 2:11 PM",
                "value": 722.5
            },
            {
                "time": "Mon, Apr 8, 2:11 PM",
                "value": 718.2999877929688
            },
            {
                "time": "Mon, Apr 8, 2:12 PM",
                "value": 714
            },
            {
                "time": "Mon, Apr 8, 2:12 PM",
                "value": 708.2999877929688
            },
            {
                "time": "Mon, Apr 8, 2:13 PM",
                "value": 702.4000244140625
            },
            {
                "time": "Mon, Apr 8, 2:13 PM",
                "value": 698
            },
            {
                "time": "Mon, Apr 8, 2:14 PM",
                "value": 693.4000244140625
            },
            {
                "time": "Mon, Apr 8, 2:14 PM",
                "value": 686.5
            },
            {
                "time": "Mon, Apr 8, 2:15 PM",
                "value": 680.2000122070312
            },
            {
                "time": "Mon, Apr 8, 2:15 PM",
                "value": 676.4000244140625
            },
            {
                "time": "Mon, Apr 8, 2:16 PM",
                "value": 672.4000244140625
            },
            {
                "time": "Mon, Apr 8, 2:16 PM",
                "value": 664.4000244140625
            },
            {
                "time": "Mon, Apr 8, 2:17 PM",
                "value": 657.4000244140625
            },
            {
                "time": "Mon, Apr 8, 2:17 PM",
                "value": 651.2000122070312
            },
            {
                "time": "Mon, Apr 8, 2:18 PM",
                "value": 648.5999755859375
            },
            {
                "time": "Mon, Apr 8, 2:18 PM",
                "value": 642
            },
            {
                "time": "Mon, Apr 8, 2:19 PM",
                "value": 634.9000244140625
            },
            {
                "time": "Mon, Apr 8, 2:19 PM",
                "value": 631.0999755859375
            },
            {
                "time": "Mon, Apr 8, 2:20 PM",
                "value": 625.5
            },
            {
                "time": "Mon, Apr 8, 2:20 PM",
                "value": 617.5999755859375
            },
            {
                "time": "Mon, Apr 8, 2:21 PM",
                "value": 612.4000244140625
            },
            {
                "time": "Mon, Apr 8, 2:21 PM",
                "value": 610
            },
            {
                "time": "Mon, Apr 8, 2:22 PM",
                "value": 601.2999877929688
            },
            {
                "time": "Mon, Apr 8, 2:22 PM",
                "value": 593.2999877929688
            },
            {
                "time": "Mon, Apr 8, 2:23 PM",
                "value": 586.2000122070312
            },
            {
                "time": "Mon, Apr 8, 2:23 PM",
                "value": 580.5
            },
            {
                "time": "Mon, Apr 8, 2:24 PM",
                "value": 576
            },
            {
                "time": "Mon, Apr 8, 2:24 PM",
                "value": 569.4000244140625
            },
            {
                "time": "Mon, Apr 8, 2:25 PM",
                "value": 562.0999755859375
            },
            {
                "time": "Mon, Apr 8, 2:25 PM",
                "value": 557
            },
            {
                "time": "Mon, Apr 8, 2:26 PM",
                "value": 551.4000244140625
            },
            {
                "time": "Mon, Apr 8, 2:26 PM",
                "value": 543.2999877929688
            },
            {
                "time": "Mon, Apr 8, 2:27 PM",
                "value": 536
            },
            {
                "time": "Mon, Apr 8, 2:27 PM",
                "value": 529.7999877929688
            },
            {
                "time": "Mon, Apr 8, 2:28 PM",
                "value": 523.7999877929688
            },
            {
                "time": "Mon, Apr 8, 2:28 PM",
                "value": 516.5
            },
            {
                "time": "Mon, Apr 8, 2:29 PM",
                "value": 508.20001220703125
            },
            {
                "time": "Mon, Apr 8, 2:29 PM",
                "value": 501.6000061035156
            },
            {
                "time": "Mon, Apr 8, 2:30 PM",
                "value": 494.70001220703125
            },
            {
                "time": "Mon, Apr 8, 2:30 PM",
                "value": 489.20001220703125
            },
            {
                "time": "Mon, Apr 8, 2:31 PM",
                "value": 481
            },
            {
                "time": "Mon, Apr 8, 2:31 PM",
                "value": 475.20001220703125
            },
            {
                "time": "Mon, Apr 8, 2:32 PM",
                "value": 468.8999938964844
            },
            {
                "time": "Mon, Apr 8, 2:32 PM",
                "value": 461.79998779296875
            },
            {
                "time": "Mon, Apr 8, 2:33 PM",
                "value": 454.3999938964844
            },
            {
                "time": "Mon, Apr 8, 2:33 PM",
                "value": 448.1000061035156
            },
            {
                "time": "Mon, Apr 8, 2:34 PM",
                "value": 440.3999938964844
            },
            {
                "time": "Mon, Apr 8, 2:34 PM",
                "value": 434.1000061035156
            },
            {
                "time": "Mon, Apr 8, 2:35 PM",
                "value": 426.5
            },
            {
                "time": "Mon, Apr 8, 2:35 PM",
                "value": 418.5
            },
            {
                "time": "Mon, Apr 8, 2:36 PM",
                "value": 411.70001220703125
            },
            {
                "time": "Mon, Apr 8, 2:36 PM",
                "value": 405.5
            },
            {
                "time": "Mon, Apr 8, 2:37 PM",
                "value": 398.1000061035156
            },
            {
                "time": "Mon, Apr 8, 2:37 PM",
                "value": 390.70001220703125
            },
            {
                "time": "Mon, Apr 8, 2:38 PM",
                "value": 383.6000061035156
            },
            {
                "time": "Mon, Apr 8, 2:38 PM",
                "value": 376.29998779296875
            },
            {
                "time": "Mon, Apr 8, 2:39 PM",
                "value": 368.6000061035156
            },
            {
                "time": "Mon, Apr 8, 2:39 PM",
                "value": 361.6000061035156
            },
            {
                "time": "Mon, Apr 8, 2:40 PM",
                "value": 356.6000061035156
            },
            {
                "time": "Mon, Apr 8, 2:40 PM",
                "value": 349.1000061035156
            },
            {
                "time": "Mon, Apr 8, 2:41 PM",
                "value": 341.29998779296875
            },
            {
                "time": "Mon, Apr 8, 2:41 PM",
                "value": 334.20001220703125
            },
            {
                "time": "Mon, Apr 8, 2:42 PM",
                "value": 326.6000061035156
            },
            {
                "time": "Mon, Apr 8, 2:42 PM",
                "value": 318.70001220703125
            },
            {
                "time": "Mon, Apr 8, 2:43 PM",
                "value": 312.1000061035156
            },
            {
                "time": "Mon, Apr 8, 2:43 PM",
                "value": 305.1000061035156
            },
            {
                "time": "Mon, Apr 8, 2:44 PM",
                "value": 299.1000061035156
            },
            {
                "time": "Mon, Apr 8, 2:44 PM",
                "value": 291.5
            },
            {
                "time": "Mon, Apr 8, 2:45 PM",
                "value": 283.6000061035156
            },
            {
                "time": "Mon, Apr 8, 2:45 PM",
                "value": 276.70001220703125
            },
            {
                "time": "Mon, Apr 8, 2:46 PM",
                "value": 270
            },
            {
                "time": "Mon, Apr 8, 2:46 PM",
                "value": 262.6000061035156
            },
            {
                "time": "Mon, Apr 8, 2:47 PM",
                "value": 255.6999969482422
            },
            {
                "time": "Mon, Apr 8, 2:47 PM",
                "value": 249.10000610351562
            },
            {
                "time": "Mon, Apr 8, 2:48 PM",
                "value": 242.1999969482422
            },
            {
                "time": "Mon, Apr 8, 2:48 PM",
                "value": 235.39999389648438
            },
            {
                "time": "Mon, Apr 8, 2:49 PM",
                "value": 228.8000030517578
            },
            {
                "time": "Mon, Apr 8, 2:49 PM",
                "value": 221.8000030517578
            },
            {
                "time": "Mon, Apr 8, 2:50 PM",
                "value": 214
            },
            {
                "time": "Mon, Apr 8, 2:50 PM",
                "value": 206.8000030517578
            },
            {
                "time": "Mon, Apr 8, 2:51 PM",
                "value": 200.5
            },
            {
                "time": "Mon, Apr 8, 2:51 PM",
                "value": 194.1999969482422
            },
            {
                "time": "Mon, Apr 8, 2:52 PM",
                "value": 188
            },
            {
                "time": "Mon, Apr 8, 2:52 PM",
                "value": 181.1999969482422
            },
            {
                "time": "Mon, Apr 8, 2:53 PM",
                "value": 173.3000030517578
            },
            {
                "time": "Mon, Apr 8, 2:53 PM",
                "value": 166.60000610351562
            },
            {
                "time": "Mon, Apr 8, 2:54 PM",
                "value": 160.6999969482422
            },
            {
                "time": "Mon, Apr 8, 2:54 PM",
                "value": 154.10000610351562
            },
            {
                "time": "Mon, Apr 8, 2:55 PM",
                "value": 147.60000610351562
            },
            {
                "time": "Mon, Apr 8, 2:55 PM",
                "value": 141.1999969482422
            },
            {
                "time": "Mon, Apr 8, 2:56 PM",
                "value": 134.39999389648438
            },
            {
                "time": "Mon, Apr 8, 2:56 PM",
                "value": 128.1999969482422
            },
            {
                "time": "Mon, Apr 8, 2:57 PM",
                "value": 122.4000015258789
            },
            {
                "time": "Mon, Apr 8, 2:57 PM",
                "value": 116.0999984741211
            },
            {
                "time": "Mon, Apr 8, 2:58 PM",
                "value": 110.19999694824219
            },
            {
                "time": "Mon, Apr 8, 2:58 PM",
                "value": 104.0999984741211
            },
            {
                "time": "Mon, Apr 8, 2:59 PM",
                "value": 98.4000015258789
            },
            {
                "time": "Mon, Apr 8, 2:59 PM",
                "value": 92.5999984741211
            },
            {
                "time": "Mon, Apr 8, 3:00 PM",
                "value": 87
            },
            {
                "time": "Mon, Apr 8, 3:00 PM",
                "value": 81.5
            },
            {
                "time": "Mon, Apr 8, 3:01 PM",
                "value": 76.43000030517578
            },
            {
                "time": "Mon, Apr 8, 3:01 PM",
                "value": 71
            },
            {
                "time": "Mon, Apr 8, 3:02 PM",
                "value": 65.95999908447266
            },
            {
                "time": "Mon, Apr 8, 3:02 PM",
                "value": 61.43000030517578
            },
            {
                "time": "Mon, Apr 8, 3:03 PM",
                "value": 56.849998474121094
            },
            {
                "time": "Mon, Apr 8, 3:03 PM",
                "value": 52.59000015258789
            },
            {
                "time": "Mon, Apr 8, 3:04 PM",
                "value": 48.5099983215332
            },
            {
                "time": "Mon, Apr 8, 3:04 PM",
                "value": 44.47999954223633
            },
            {
                "time": "Mon, Apr 8, 3:05 PM",
                "value": 40.900001525878906
            },
            {
                "time": "Mon, Apr 8, 3:05 PM",
                "value": 37.540000915527344
            },
            {
                "time": "Mon, Apr 8, 3:06 PM",
                "value": 34.630001068115234
            },
            {
                "time": "Mon, Apr 8, 3:06 PM",
                "value": 32.119998931884766
            },
            {
                "time": "Mon, Apr 8, 3:07 PM",
                "value": 30.049999237060547
            },
            {
                "time": "Mon, Apr 8, 3:07 PM",
                "value": 28.200000762939453
            },
            {
                "time": "Mon, Apr 8, 3:08 PM",
                "value": 26.799999237060547
            },
            {
                "time": "Mon, Apr 8, 3:08 PM",
                "value": 25.959999084472656
            },
            {
                "time": "Mon, Apr 8, 3:09 PM",
                "value": 25.6299991607666
            },
            {
                "time": "Mon, Apr 8, 3:09 PM",
                "value": 25.850000381469727
            },
            {
                "time": "Mon, Apr 8, 3:10 PM",
                "value": 26.459999084472656
            },
            {
                "time": "Mon, Apr 8, 3:10 PM",
                "value": 27.530000686645508
            },
            {
                "time": "Mon, Apr 8, 3:11 PM",
                "value": 29.09000015258789
            },
            {
                "time": "Mon, Apr 8, 3:11 PM",
                "value": 30.940000534057617
            },
            {
                "time": "Mon, Apr 8, 3:12 PM",
                "value": 33.400001525878906
            },
            {
                "time": "Mon, Apr 8, 3:12 PM",
                "value": 36.13999938964844
            },
            {
                "time": "Mon, Apr 8, 3:13 PM",
                "value": 39.16999816894531
            },
            {
                "time": "Mon, Apr 8, 3:13 PM",
                "value": 42.470001220703125
            },
            {
                "time": "Mon, Apr 8, 3:14 PM",
                "value": 46.27000045776367
            },
            {
                "time": "Mon, Apr 8, 3:14 PM",
                "value": 50.02000045776367
            },
            {
                "time": "Mon, Apr 8, 3:15 PM",
                "value": 54.27000045776367
            },
            {
                "time": "Mon, Apr 8, 3:15 PM",
                "value": 58.58000183105469
            },
            {
                "time": "Mon, Apr 8, 3:16 PM",
                "value": 63.38999938964844
            },
            {
                "time": "Mon, Apr 8, 3:16 PM",
                "value": 68.19999694824219
            },
            {
                "time": "Mon, Apr 8, 3:17 PM",
                "value": 73.0199966430664
            },
            {
                "time": "Mon, Apr 8, 3:17 PM",
                "value": 78.16000366210938
            },
            {
                "time": "Mon, Apr 8, 3:18 PM",
                "value": 83.19999694824219
            },
            {
                "time": "Mon, Apr 8, 3:18 PM",
                "value": 88.4000015258789
            },
            {
                "time": "Mon, Apr 8, 3:19 PM",
                "value": 93.69999694824219
            },
            {
                "time": "Mon, Apr 8, 3:19 PM",
                "value": 99.30000305175781
            },
            {
                "time": "Mon, Apr 8, 3:20 PM",
                "value": 104.9000015258789
            },
            {
                "time": "Mon, Apr 8, 3:20 PM",
                "value": 110.69999694824219
            },
            {
                "time": "Mon, Apr 8, 3:21 PM",
                "value": 116.4000015258789
            },
            {
                "time": "Mon, Apr 8, 3:21 PM",
                "value": 122.0999984741211
            },
            {
                "time": "Mon, Apr 8, 3:22 PM",
                "value": 128.10000610351562
            },
            {
                "time": "Mon, Apr 8, 3:22 PM",
                "value": 134.1999969482422
            },
            {
                "time": "Mon, Apr 8, 3:23 PM",
                "value": 140.1999969482422
            },
            {
                "time": "Mon, Apr 8, 3:23 PM",
                "value": 146.1999969482422
            },
            {
                "time": "Mon, Apr 8, 3:24 PM",
                "value": 152
            },
            {
                "time": "Mon, Apr 8, 3:24 PM",
                "value": 158.1999969482422
            },
            {
                "time": "Mon, Apr 8, 3:25 PM",
                "value": 164.1999969482422
            },
            {
                "time": "Mon, Apr 8, 3:25 PM",
                "value": 170.1999969482422
            },
            {
                "time": "Mon, Apr 8, 3:26 PM",
                "value": 176.5
            },
            {
                "time": "Mon, Apr 8, 3:26 PM",
                "value": 182.6999969482422
            },
            {
                "time": "Mon, Apr 8, 3:27 PM",
                "value": 188.8000030517578
            },
            {
                "time": "Mon, Apr 8, 3:27 PM",
                "value": 195.3000030517578
            },
            {
                "time": "Mon, Apr 8, 3:28 PM",
                "value": 201.39999389648438
            },
            {
                "time": "Mon, Apr 8, 3:28 PM",
                "value": 207.6999969482422
            },
            {
                "time": "Mon, Apr 8, 3:29 PM",
                "value": 214.10000610351562
            },
            {
                "time": "Mon, Apr 8, 3:29 PM",
                "value": 220.39999389648438
            },
            {
                "time": "Mon, Apr 8, 3:30 PM",
                "value": 226.60000610351562
            },
            {
                "time": "Mon, Apr 8, 3:30 PM",
                "value": 232.89999389648438
            },
            {
                "time": "Mon, Apr 8, 3:31 PM",
                "value": 239.10000610351562
            },
            {
                "time": "Mon, Apr 8, 3:31 PM",
                "value": 245.39999389648438
            },
            {
                "time": "Mon, Apr 8, 3:32 PM",
                "value": 251.89999389648438
            },
            {
                "time": "Mon, Apr 8, 3:32 PM",
                "value": 258.3999938964844
            },
            {
                "time": "Mon, Apr 8, 3:33 PM",
                "value": 264.5
            },
            {
                "time": "Mon, Apr 8, 3:33 PM",
                "value": 270.8999938964844
            },
            {
                "time": "Mon, Apr 8, 3:34 PM",
                "value": 277.3999938964844
            },
            {
                "time": "Mon, Apr 8, 3:34 PM",
                "value": 283.70001220703125
            },
            {
                "time": "Mon, Apr 8, 3:35 PM",
                "value": 289.8999938964844
            },
            {
                "time": "Mon, Apr 8, 3:35 PM",
                "value": 296.20001220703125
            },
            {
                "time": "Mon, Apr 8, 3:36 PM",
                "value": 302.5
            },
            {
                "time": "Mon, Apr 8, 3:36 PM",
                "value": 308.70001220703125
            },
            {
                "time": "Mon, Apr 8, 3:37 PM",
                "value": 314.8999938964844
            },
            {
                "time": "Mon, Apr 8, 3:37 PM",
                "value": 321.20001220703125
            },
            {
                "time": "Mon, Apr 8, 3:38 PM",
                "value": 327.79998779296875
            },
            {
                "time": "Mon, Apr 8, 3:38 PM",
                "value": 333.8999938964844
            },
            {
                "time": "Mon, Apr 8, 3:39 PM",
                "value": 340
            },
            {
                "time": "Mon, Apr 8, 3:39 PM",
                "value": 346
            },
            {
                "time": "Mon, Apr 8, 3:40 PM",
                "value": 352
            },
            {
                "time": "Mon, Apr 8, 3:40 PM",
                "value": 357.8999938964844
            },
            {
                "time": "Mon, Apr 8, 3:41 PM",
                "value": 364
            },
            {
                "time": "Mon, Apr 8, 3:41 PM",
                "value": 370.29998779296875
            },
            {
                "time": "Mon, Apr 8, 3:42 PM",
                "value": 376
            },
            {
                "time": "Mon, Apr 8, 3:42 PM",
                "value": 382.20001220703125
            },
            {
                "time": "Mon, Apr 8, 3:43 PM",
                "value": 388
            },
            {
                "time": "Mon, Apr 8, 3:43 PM",
                "value": 393.70001220703125
            },
            {
                "time": "Mon, Apr 8, 3:44 PM",
                "value": 399.70001220703125
            },
            {
                "time": "Mon, Apr 8, 3:44 PM",
                "value": 405.6000061035156
            },
            {
                "time": "Mon, Apr 8, 3:45 PM",
                "value": 411.20001220703125
            },
            {
                "time": "Mon, Apr 8, 3:45 PM",
                "value": 416.79998779296875
            },
            {
                "time": "Mon, Apr 8, 3:46 PM",
                "value": 422.29998779296875
            },
            {
                "time": "Mon, Apr 8, 3:46 PM",
                "value": 427.8999938964844
            },
            {
                "time": "Mon, Apr 8, 3:47 PM",
                "value": 433.70001220703125
            },
            {
                "time": "Mon, Apr 8, 3:47 PM",
                "value": 439.3999938964844
            },
            {
                "time": "Mon, Apr 8, 3:48 PM",
                "value": 445
            },
            {
                "time": "Mon, Apr 8, 3:48 PM",
                "value": 450.6000061035156
            },
            {
                "time": "Mon, Apr 8, 3:49 PM",
                "value": 456.1000061035156
            },
            {
                "time": "Mon, Apr 8, 3:49 PM",
                "value": 461.5
            },
            {
                "time": "Mon, Apr 8, 3:50 PM",
                "value": 466.6000061035156
            },
            {
                "time": "Mon, Apr 8, 3:50 PM",
                "value": 471.79998779296875
            },
            {
                "time": "Mon, Apr 8, 3:51 PM",
                "value": 477
            },
            {
                "time": "Mon, Apr 8, 3:51 PM",
                "value": 482.5
            },
            {
                "time": "Mon, Apr 8, 3:52 PM",
                "value": 487.70001220703125
            },
            {
                "time": "Mon, Apr 8, 3:52 PM",
                "value": 492.79998779296875
            },
            {
                "time": "Mon, Apr 8, 3:53 PM",
                "value": 497.8999938964844
            },
            {
                "time": "Mon, Apr 8, 3:53 PM",
                "value": 503
            },
            {
                "time": "Mon, Apr 8, 3:54 PM",
                "value": 507.70001220703125
            },
            {
                "time": "Mon, Apr 8, 3:54 PM",
                "value": 513.0999755859375
            },
            {
                "time": "Mon, Apr 8, 3:55 PM",
                "value": 518.0999755859375
            },
            {
                "time": "Mon, Apr 8, 3:55 PM",
                "value": 522.9000244140625
            },
            {
                "time": "Mon, Apr 8, 3:56 PM",
                "value": 527.7999877929688
            },
            {
                "time": "Mon, Apr 8, 3:56 PM",
                "value": 532.4000244140625
            },
            {
                "time": "Mon, Apr 8, 3:57 PM",
                "value": 536.5
            },
            {
                "time": "Mon, Apr 8, 3:57 PM",
                "value": 541.0999755859375
            },
            {
                "time": "Mon, Apr 8, 3:58 PM",
                "value": 545.7000122070312
            },
            {
                "time": "Mon, Apr 8, 3:58 PM",
                "value": 550.0999755859375
            },
            {
                "time": "Mon, Apr 8, 3:59 PM",
                "value": 555
            },
            {
                "time": "Mon, Apr 8, 3:59 PM",
                "value": 559.0999755859375
            },
            {
                "time": "Mon, Apr 8, 4:00 PM",
                "value": 563.2999877929688
            },
            {
                "time": "Mon, Apr 8, 4:00 PM",
                "value": 566.7999877929688
            },
            {
                "time": "Mon, Apr 8, 4:01 PM",
                "value": 571
            },
            {
                "time": "Mon, Apr 8, 4:01 PM",
                "value": 575.2999877929688
            },
            {
                "time": "Mon, Apr 8, 4:02 PM",
                "value": 579.2000122070312
            },
            {
                "time": "Mon, Apr 8, 4:02 PM",
                "value": 583
            },
            {
                "time": "Mon, Apr 8, 4:03 PM",
                "value": 586.9000244140625
            },
            {
                "time": "Mon, Apr 8, 4:03 PM",
                "value": 591
            },
            {
                "time": "Mon, Apr 8, 4:04 PM",
                "value": 594.2999877929688
            },
            {
                "time": "Mon, Apr 8, 4:04 PM",
                "value": 597.7000122070312
            },
            {
                "time": "Mon, Apr 8, 4:05 PM",
                "value": 601.2000122070312
            },
            {
                "time": "Mon, Apr 8, 4:05 PM",
                "value": 604.4000244140625
            },
            {
                "time": "Mon, Apr 8, 4:06 PM",
                "value": 607.9000244140625
            },
            {
                "time": "Mon, Apr 8, 4:06 PM",
                "value": 612.2999877929688
            },
            {
                "time": "Mon, Apr 8, 4:07 PM",
                "value": 616.0999755859375
            },
            {
                "time": "Mon, Apr 8, 4:07 PM",
                "value": 618.9000244140625
            },
            {
                "time": "Mon, Apr 8, 4:08 PM",
                "value": 621.9000244140625
            },
            {
                "time": "Mon, Apr 8, 4:08 PM",
                "value": 624.9000244140625
            },
            {
                "time": "Mon, Apr 8, 4:09 PM",
                "value": 627.2999877929688
            },
            {
                "time": "Mon, Apr 8, 4:09 PM",
                "value": 629.9000244140625
            },
            {
                "time": "Mon, Apr 8, 4:10 PM",
                "value": 633.0999755859375
            },
            {
                "time": "Mon, Apr 8, 4:10 PM",
                "value": 635
            },
            {
                "time": "Mon, Apr 8, 4:11 PM",
                "value": 637.7000122070312
            },
            {
                "time": "Mon, Apr 8, 4:11 PM",
                "value": 639.2000122070312
            },
            {
                "time": "Mon, Apr 8, 4:12 PM",
                "value": 641.4000244140625
            },
            {
                "time": "Mon, Apr 8, 4:12 PM",
                "value": 643.4000244140625
            },
            {
                "time": "Mon, Apr 8, 4:13 PM",
                "value": 646.0999755859375
            },
            {
                "time": "Mon, Apr 8, 4:13 PM",
                "value": 649.2000122070312
            },
            {
                "time": "Mon, Apr 8, 4:14 PM",
                "value": 651.2000122070312
            },
            {
                "time": "Mon, Apr 8, 4:14 PM",
                "value": 653.2999877929688
            },
            {
                "time": "Mon, Apr 8, 4:15 PM",
                "value": 655.2000122070312
            },
            {
                "time": "Mon, Apr 8, 4:15 PM",
                "value": 656.2999877929688
            },
            {
                "time": "Mon, Apr 8, 4:16 PM",
                "value": 657.2999877929688
            },
            {
                "time": "Mon, Apr 8, 4:16 PM",
                "value": 658.5999755859375
            },
            {
                "time": "Mon, Apr 8, 4:17 PM",
                "value": 659.5999755859375
            },
            {
                "time": "Mon, Apr 8, 4:17 PM",
                "value": 661.2999877929688
            },
            {
                "time": "Mon, Apr 8, 4:18 PM",
                "value": 662.4000244140625
            },
            {
                "time": "Mon, Apr 8, 4:18 PM",
                "value": 663
            },
            {
                "time": "Mon, Apr 8, 4:19 PM",
                "value": 663.7999877929688
            },
            {
                "time": "Mon, Apr 8, 4:19 PM",
                "value": 664.5
            },
            {
                "time": "Mon, Apr 8, 4:20 PM",
                "value": 665.5
            },
            {
                "time": "Mon, Apr 8, 4:20 PM",
                "value": 665
            },
            {
                "time": "Mon, Apr 8, 4:21 PM",
                "value": 664.5999755859375
            },
            {
                "time": "Mon, Apr 8, 4:21 PM",
                "value": 665.2999877929688
            },
            {
                "time": "Mon, Apr 8, 4:22 PM",
                "value": 666
            },
            {
                "time": "Mon, Apr 8, 4:22 PM",
                "value": 664.4000244140625
            },
            {
                "time": "Mon, Apr 8, 4:23 PM",
                "value": 663.4000244140625
            },
            {
                "time": "Mon, Apr 8, 4:23 PM",
                "value": 663.0999755859375
            },
            {
                "time": "Mon, Apr 8, 4:24 PM",
                "value": 661
            },
            {
                "time": "Mon, Apr 8, 4:24 PM",
                "value": 660.5999755859375
            },
            {
                "time": "Mon, Apr 8, 4:25 PM",
                "value": 661.7000122070312
            },
            {
                "time": "Mon, Apr 8, 4:25 PM",
                "value": 660.2000122070312
            },
            {
                "time": "Mon, Apr 8, 4:26 PM",
                "value": 660.2999877929688
            },
            {
                "time": "Mon, Apr 8, 4:26 PM",
                "value": 658.2000122070312
            },
            {
                "time": "Mon, Apr 8, 4:27 PM",
                "value": 659
            },
            {
                "time": "Mon, Apr 8, 4:27 PM",
                "value": 659
            },
            {
                "time": "Mon, Apr 8, 4:28 PM",
                "value": 655.0999755859375
            },
            {
                "time": "Mon, Apr 8, 4:28 PM",
                "value": 652.9000244140625
            },
            {
                "time": "Mon, Apr 8, 4:29 PM",
                "value": 649.0999755859375
            },
            {
                "time": "Mon, Apr 8, 4:29 PM",
                "value": 650.2000122070312
            },
            {
                "time": "Mon, Apr 8, 4:30 PM",
                "value": 651.2999877929688
            },
            {
                "time": "Mon, Apr 8, 4:30 PM",
                "value": 651.2000122070312
            },
            {
                "time": "Mon, Apr 8, 4:31 PM",
                "value": 648.4000244140625
            },
            {
                "time": "Mon, Apr 8, 4:31 PM",
                "value": 646.5999755859375
            },
            {
                "time": "Mon, Apr 8, 4:32 PM",
                "value": 644
            },
            {
                "time": "Mon, Apr 8, 4:32 PM",
                "value": 642.7999877929688
            },
            {
                "time": "Mon, Apr 8, 4:33 PM",
                "value": 641.5999755859375
            },
            {
                "time": "Mon, Apr 8, 4:33 PM",
                "value": 639.5
            },
            {
                "time": "Mon, Apr 8, 4:34 PM",
                "value": 638.2999877929688
            },
            {
                "time": "Mon, Apr 8, 4:34 PM",
                "value": 637.2000122070312
            },
            {
                "time": "Mon, Apr 8, 4:35 PM",
                "value": 633.4000244140625
            },
            {
                "time": "Mon, Apr 8, 4:35 PM",
                "value": 633
            },
            {
                "time": "Mon, Apr 8, 4:36 PM",
                "value": 633.2999877929688
            },
            {
                "time": "Mon, Apr 8, 4:36 PM",
                "value": 633.2000122070312
            },
            {
                "time": "Mon, Apr 8, 4:37 PM",
                "value": 631.9000244140625
            },
            {
                "time": "Mon, Apr 8, 4:37 PM",
                "value": 632.2000122070312
            },
            {
                "time": "Mon, Apr 8, 4:38 PM",
                "value": 630.2000122070312
            },
            {
                "time": "Mon, Apr 8, 4:38 PM",
                "value": 625.5
            },
            {
                "time": "Mon, Apr 8, 4:39 PM",
                "value": 624.2999877929688
            },
            {
                "time": "Mon, Apr 8, 4:39 PM",
                "value": 624.5
            },
            {
                "time": "Mon, Apr 8, 4:40 PM",
                "value": 623.7000122070312
            },
            {
                "time": "Mon, Apr 8, 4:40 PM",
                "value": 622.0999755859375
            },
            {
                "time": "Mon, Apr 8, 4:41 PM",
                "value": 621
            },
            {
                "time": "Mon, Apr 8, 4:41 PM",
                "value": 618.4000244140625
            },
            {
                "time": "Mon, Apr 8, 4:42 PM",
                "value": 616.0999755859375
            },
            {
                "time": "Mon, Apr 8, 4:42 PM",
                "value": 614.7999877929688
            },
            {
                "time": "Mon, Apr 8, 4:43 PM",
                "value": 614.2999877929688
            },
            {
                "time": "Mon, Apr 8, 4:43 PM",
                "value": 614.4000244140625
            },
            {
                "time": "Mon, Apr 8, 4:44 PM",
                "value": 613.2000122070312
            },
            {
                "time": "Mon, Apr 8, 4:44 PM",
                "value": 611.5999755859375
            },
            {
                "time": "Mon, Apr 8, 4:45 PM",
                "value": 608.2999877929688
            },
            {
                "time": "Mon, Apr 8, 4:45 PM",
                "value": 607.7000122070312
            },
            {
                "time": "Mon, Apr 8, 4:46 PM",
                "value": 607.0999755859375
            },
            {
                "time": "Mon, Apr 8, 4:46 PM",
                "value": 607.0999755859375
            },
            {
                "time": "Mon, Apr 8, 4:47 PM",
                "value": 608
            },
            {
                "time": "Mon, Apr 8, 4:47 PM",
                "value": 608.2999877929688
            },
            {
                "time": "Mon, Apr 8, 4:48 PM",
                "value": 605.4000244140625
            },
            {
                "time": "Mon, Apr 8, 4:48 PM",
                "value": 603.9000244140625
            },
            {
                "time": "Mon, Apr 8, 4:49 PM",
                "value": 602.5999755859375
            },
            {
                "time": "Mon, Apr 8, 4:49 PM",
                "value": 600.0999755859375
            },
            {
                "time": "Mon, Apr 8, 4:50 PM",
                "value": 599
            },
            {
                "time": "Mon, Apr 8, 4:50 PM",
                "value": 595.5999755859375
            },
            {
                "time": "Mon, Apr 8, 4:51 PM",
                "value": 593.0999755859375
            },
            {
                "time": "Mon, Apr 8, 4:51 PM",
                "value": 593.5999755859375
            },
            {
                "time": "Mon, Apr 8, 4:52 PM",
                "value": 594.7999877929688
            },
            {
                "time": "Mon, Apr 8, 4:52 PM",
                "value": 595.2999877929688
            },
            {
                "time": "Mon, Apr 8, 4:53 PM",
                "value": 593.2999877929688
            },
            {
                "time": "Mon, Apr 8, 4:53 PM",
                "value": 592.7000122070312
            },
            {
                "time": "Mon, Apr 8, 4:54 PM",
                "value": 591.7999877929688
            },
            {
                "time": "Mon, Apr 8, 4:54 PM",
                "value": 590.4000244140625
            },
            {
                "time": "Mon, Apr 8, 4:55 PM",
                "value": 585.2000122070312
            },
            {
                "time": "Mon, Apr 8, 4:55 PM",
                "value": 583.4000244140625
            },
            {
                "time": "Mon, Apr 8, 4:56 PM",
                "value": 582.0999755859375
            },
            {
                "time": "Mon, Apr 8, 4:56 PM",
                "value": 582.4000244140625
            },
            {
                "time": "Mon, Apr 8, 4:57 PM",
                "value": 581.9000244140625
            },
            {
                "time": "Mon, Apr 8, 4:57 PM",
                "value": 580.2999877929688
            },
            {
                "time": "Mon, Apr 8, 4:58 PM",
                "value": 578.4000244140625
            },
            {
                "time": "Mon, Apr 8, 4:58 PM",
                "value": 578
            },
            {
                "time": "Mon, Apr 8, 4:59 PM",
                "value": 577.0999755859375
            },
            {
                "time": "Mon, Apr 8, 4:59 PM",
                "value": 575.0999755859375
            },
            {
                "time": "Mon, Apr 8, 5:00 PM",
                "value": 573.7000122070312
            },
            {
                "time": "Mon, Apr 8, 5:00 PM",
                "value": 573.0999755859375
            },
            {
                "time": "Mon, Apr 8, 5:01 PM",
                "value": 573.7999877929688
            },
            {
                "time": "Mon, Apr 8, 5:01 PM",
                "value": 573.2999877929688
            },
            {
                "time": "Mon, Apr 8, 5:02 PM",
                "value": 572.5
            },
            {
                "time": "Mon, Apr 8, 5:02 PM",
                "value": 570.7000122070312
            },
            {
                "time": "Mon, Apr 8, 5:03 PM",
                "value": 567.7000122070312
            },
            {
                "time": "Mon, Apr 8, 5:03 PM",
                "value": 566.7999877929688
            },
            {
                "time": "Mon, Apr 8, 5:04 PM",
                "value": 565.9000244140625
            },
            {
                "time": "Mon, Apr 8, 5:04 PM",
                "value": 566.5
            },
            {
                "time": "Mon, Apr 8, 5:05 PM",
                "value": 563.2999877929688
            },
            {
                "time": "Mon, Apr 8, 5:05 PM",
                "value": 559.2000122070312
            },
            {
                "time": "Mon, Apr 8, 5:06 PM",
                "value": 557.5
            },
            {
                "time": "Mon, Apr 8, 5:06 PM",
                "value": 559.0999755859375
            },
            {
                "time": "Mon, Apr 8, 5:07 PM",
                "value": 558.2999877929688
            },
            {
                "time": "Mon, Apr 8, 5:07 PM",
                "value": 558.7999877929688
            },
            {
                "time": "Mon, Apr 8, 5:08 PM",
                "value": 558
            },
            {
                "time": "Mon, Apr 8, 5:08 PM",
                "value": 556.0999755859375
            },
            {
                "time": "Mon, Apr 8, 5:09 PM",
                "value": 555.5999755859375
            },
            {
                "time": "Mon, Apr 8, 5:09 PM",
                "value": 551.2999877929688
            },
            {
                "time": "Mon, Apr 8, 5:10 PM",
                "value": 550.0999755859375
            },
            {
                "time": "Mon, Apr 8, 5:10 PM",
                "value": 549.4000244140625
            },
            {
                "time": "Mon, Apr 8, 5:11 PM",
                "value": 545.9000244140625
            },
            {
                "time": "Mon, Apr 8, 5:11 PM",
                "value": 544.5
            },
            {
                "time": "Mon, Apr 8, 5:12 PM",
                "value": 544.4000244140625
            },
            {
                "time": "Mon, Apr 8, 5:12 PM",
                "value": 544
            },
            {
                "time": "Mon, Apr 8, 5:13 PM",
                "value": 543.2999877929688
            },
            {
                "time": "Mon, Apr 8, 5:13 PM",
                "value": 540.5
            },
            {
                "time": "Mon, Apr 8, 5:14 PM",
                "value": 539.7999877929688
            },
            {
                "time": "Mon, Apr 8, 5:14 PM",
                "value": 539.2000122070312
            },
            {
                "time": "Mon, Apr 8, 5:15 PM",
                "value": 537.5999755859375
            },
            {
                "time": "Mon, Apr 8, 5:15 PM",
                "value": 536.2000122070312
            },
            {
                "time": "Mon, Apr 8, 5:16 PM",
                "value": 534.7999877929688
            },
            {
                "time": "Mon, Apr 8, 5:16 PM",
                "value": 532.0999755859375
            },
            {
                "time": "Mon, Apr 8, 5:17 PM",
                "value": 530.5999755859375
            },
            {
                "time": "Mon, Apr 8, 5:17 PM",
                "value": 527
            },
            {
                "time": "Mon, Apr 8, 5:18 PM",
                "value": 525.7999877929688
            },
            {
                "time": "Mon, Apr 8, 5:18 PM",
                "value": 522.9000244140625
            },
            {
                "time": "Mon, Apr 8, 5:19 PM",
                "value": 521.7000122070312
            },
            {
                "time": "Mon, Apr 8, 5:19 PM",
                "value": 521.7000122070312
            },
            {
                "time": "Mon, Apr 8, 5:20 PM",
                "value": 519.5999755859375
            },
            {
                "time": "Mon, Apr 8, 5:20 PM",
                "value": 517.7999877929688
            },
            {
                "time": "Mon, Apr 8, 5:21 PM",
                "value": 516.9000244140625
            },
            {
                "time": "Mon, Apr 8, 5:21 PM",
                "value": 517
            },
            {
                "time": "Mon, Apr 8, 5:22 PM",
                "value": 516.4000244140625
            },
            {
                "time": "Mon, Apr 8, 5:22 PM",
                "value": 514.7999877929688
            },
            {
                "time": "Mon, Apr 8, 5:23 PM",
                "value": 512.2000122070312
            },
            {
                "time": "Mon, Apr 8, 5:23 PM",
                "value": 520.7000122070312
            },
            {
                "time": "Mon, Apr 8, 5:24 PM",
                "value": 525.0999755859375
            },
            {
                "time": "Mon, Apr 8, 5:24 PM",
                "value": 521.9000244140625
            },
            {
                "time": "Mon, Apr 8, 5:25 PM",
                "value": 516.5999755859375
            },
            {
                "time": "Mon, Apr 8, 5:25 PM",
                "value": 506
            },
            {
                "time": "Mon, Apr 8, 5:26 PM",
                "value": 504.1000061035156
            },
            {
                "time": "Mon, Apr 8, 5:26 PM",
                "value": 501.3999938964844
            },
            {
                "time": "Mon, Apr 8, 5:27 PM",
                "value": 501.20001220703125
            },
            {
                "time": "Mon, Apr 8, 5:27 PM",
                "value": 500.79998779296875
            },
            {
                "time": "Mon, Apr 8, 5:28 PM",
                "value": 147.89999389648438
            },
            {
                "time": "Mon, Apr 8, 5:28 PM",
                "value": 138
            },
            {
                "time": "Mon, Apr 8, 5:29 PM",
                "value": 549.0999755859375
            },
            {
                "time": "Mon, Apr 8, 5:29 PM",
                "value": 561.2000122070312
            },
            {
                "time": "Mon, Apr 8, 5:30 PM",
                "value": 567.4000244140625
            },
            {
                "time": "Mon, Apr 8, 5:30 PM",
                "value": 562.4000244140625
            },
            {
                "time": "Mon, Apr 8, 5:31 PM",
                "value": 527.5
            },
            {
                "time": "Mon, Apr 8, 5:31 PM",
                "value": 486.8999938964844
            },
            {
                "time": "Mon, Apr 8, 5:32 PM",
                "value": 487.6000061035156
            },
            {
                "time": "Mon, Apr 8, 5:32 PM",
                "value": 486.8999938964844
            },
            {
                "time": "Mon, Apr 8, 5:33 PM",
                "value": 485.29998779296875
            },
            {
                "time": "Mon, Apr 8, 5:33 PM",
                "value": 483.1000061035156
            },
            {
                "time": "Mon, Apr 8, 5:34 PM",
                "value": 481.70001220703125
            },
            {
                "time": "Mon, Apr 8, 5:34 PM",
                "value": 479.6000061035156
            },
            {
                "time": "Mon, Apr 8, 5:35 PM",
                "value": 478.6000061035156
            },
            {
                "time": "Mon, Apr 8, 5:35 PM",
                "value": 476.8999938964844
            },
            {
                "time": "Mon, Apr 8, 5:36 PM",
                "value": 475.6000061035156
            },
            {
                "time": "Mon, Apr 8, 5:36 PM",
                "value": 474.29998779296875
            },
            {
                "time": "Mon, Apr 8, 5:37 PM",
                "value": 472.29998779296875
            },
            {
                "time": "Mon, Apr 8, 5:37 PM",
                "value": 469.1000061035156
            },
            {
                "time": "Mon, Apr 8, 5:38 PM",
                "value": 468.70001220703125
            },
            {
                "time": "Mon, Apr 8, 5:38 PM",
                "value": 469.20001220703125
            },
            {
                "time": "Mon, Apr 8, 5:39 PM",
                "value": 468.29998779296875
            },
            {
                "time": "Mon, Apr 8, 5:39 PM",
                "value": 466.79998779296875
            },
            {
                "time": "Mon, Apr 8, 5:40 PM",
                "value": 464.79998779296875
            },
            {
                "time": "Mon, Apr 8, 5:40 PM",
                "value": 462.70001220703125
            },
            {
                "time": "Mon, Apr 8, 5:41 PM",
                "value": 461.5
            },
            {
                "time": "Mon, Apr 8, 5:41 PM",
                "value": 459.8999938964844
            },
            {
                "time": "Mon, Apr 8, 5:42 PM",
                "value": 458.6000061035156
            },
            {
                "time": "Mon, Apr 8, 5:42 PM",
                "value": 456.6000061035156
            },
            {
                "time": "Mon, Apr 8, 5:43 PM",
                "value": 453.8999938964844
            },
            {
                "time": "Mon, Apr 8, 5:43 PM",
                "value": 451.8999938964844
            },
            {
                "time": "Mon, Apr 8, 5:44 PM",
                "value": 451.70001220703125
            },
            {
                "time": "Mon, Apr 8, 5:44 PM",
                "value": 449.70001220703125
            },
            {
                "time": "Mon, Apr 8, 5:45 PM",
                "value": 448.5
            },
            {
                "time": "Mon, Apr 8, 5:45 PM",
                "value": 449.29998779296875
            },
            {
                "time": "Mon, Apr 8, 5:46 PM",
                "value": 447.5
            },
            {
                "time": "Mon, Apr 8, 5:46 PM",
                "value": 446.3999938964844
            },
            {
                "time": "Mon, Apr 8, 5:47 PM",
                "value": 445.5
            },
            {
                "time": "Mon, Apr 8, 5:47 PM",
                "value": 443.70001220703125
            },
            {
                "time": "Mon, Apr 8, 5:48 PM",
                "value": 441.1000061035156
            },
            {
                "time": "Mon, Apr 8, 5:48 PM",
                "value": 438.29998779296875
            },
            {
                "time": "Mon, Apr 8, 5:49 PM",
                "value": 436
            },
            {
                "time": "Mon, Apr 8, 5:49 PM",
                "value": 433.29998779296875
            },
            {
                "time": "Mon, Apr 8, 5:50 PM",
                "value": 435
            },
            {
                "time": "Mon, Apr 8, 5:50 PM",
                "value": 433.1000061035156
            },
            {
                "time": "Mon, Apr 8, 5:51 PM",
                "value": 430.6000061035156
            },
            {
                "time": "Mon, Apr 8, 5:51 PM",
                "value": 429.1000061035156
            },
            {
                "time": "Mon, Apr 8, 5:52 PM",
                "value": 428.6000061035156
            },
            {
                "time": "Mon, Apr 8, 5:52 PM",
                "value": 428
            },
            {
                "time": "Mon, Apr 8, 5:53 PM",
                "value": 425.29998779296875
            },
            {
                "time": "Mon, Apr 8, 5:53 PM",
                "value": 420.1000061035156
            },
            {
                "time": "Mon, Apr 8, 5:54 PM",
                "value": 423.5
            },
            {
                "time": "Mon, Apr 8, 5:54 PM",
                "value": 422.6000061035156
            },
            {
                "time": "Mon, Apr 8, 5:55 PM",
                "value": 419.20001220703125
            },
            {
                "time": "Mon, Apr 8, 5:55 PM",
                "value": 416.8999938964844
            },
            {
                "time": "Mon, Apr 8, 5:56 PM",
                "value": 415.5
            },
            {
                "time": "Mon, Apr 8, 5:56 PM",
                "value": 414.29998779296875
            },
            {
                "time": "Mon, Apr 8, 5:57 PM",
                "value": 414.20001220703125
            },
            {
                "time": "Mon, Apr 8, 5:57 PM",
                "value": 411.70001220703125
            },
            {
                "time": "Mon, Apr 8, 5:58 PM",
                "value": 411.3999938964844
            },
            {
                "time": "Mon, Apr 8, 5:58 PM",
                "value": 408.79998779296875
            },
            {
                "time": "Mon, Apr 8, 5:59 PM",
                "value": 407.70001220703125
            },
            {
                "time": "Mon, Apr 8, 5:59 PM",
                "value": 406.8999938964844
            },
            {
                "time": "Mon, Apr 8, 6:00 PM",
                "value": 404.5
            },
            {
                "time": "Mon, Apr 8, 6:00 PM",
                "value": 402.29998779296875
            },
            {
                "time": "Mon, Apr 8, 6:01 PM",
                "value": 399.5
            },
            {
                "time": "Mon, Apr 8, 6:01 PM",
                "value": 399.29998779296875
            },
            {
                "time": "Mon, Apr 8, 6:02 PM",
                "value": 397.29998779296875
            },
            {
                "time": "Mon, Apr 8, 6:02 PM",
                "value": 394.70001220703125
            },
            {
                "time": "Mon, Apr 8, 6:03 PM",
                "value": 393.79998779296875
            },
            {
                "time": "Mon, Apr 8, 6:03 PM",
                "value": 393.8999938964844
            },
            {
                "time": "Mon, Apr 8, 6:04 PM",
                "value": 389.70001220703125
            },
            {
                "time": "Mon, Apr 8, 6:04 PM",
                "value": 387.79998779296875
            },
            {
                "time": "Mon, Apr 8, 6:05 PM",
                "value": 388.20001220703125
            },
            {
                "time": "Mon, Apr 8, 6:05 PM",
                "value": 388.29998779296875
            },
            {
                "time": "Mon, Apr 8, 6:06 PM",
                "value": 382.8999938964844
            },
            {
                "time": "Mon, Apr 8, 6:06 PM",
                "value": 381.3999938964844
            },
            {
                "time": "Mon, Apr 8, 6:07 PM",
                "value": 381.8999938964844
            },
            {
                "time": "Mon, Apr 8, 6:07 PM",
                "value": 379.70001220703125
            },
            {
                "time": "Mon, Apr 8, 6:08 PM",
                "value": 378.8999938964844
            },
            {
                "time": "Mon, Apr 8, 6:08 PM",
                "value": 375.20001220703125
            },
            {
                "time": "Mon, Apr 8, 6:09 PM",
                "value": 372.79998779296875
            },
            {
                "time": "Mon, Apr 8, 6:09 PM",
                "value": 370.8999938964844
            },
            {
                "time": "Mon, Apr 8, 6:10 PM",
                "value": 370
            },
            {
                "time": "Mon, Apr 8, 6:10 PM",
                "value": 368.29998779296875
            },
            {
                "time": "Mon, Apr 8, 6:11 PM",
                "value": 366.20001220703125
            },
            {
                "time": "Mon, Apr 8, 6:11 PM",
                "value": 367.20001220703125
            },
            {
                "time": "Mon, Apr 8, 6:12 PM",
                "value": 368.79998779296875
            },
            {
                "time": "Mon, Apr 8, 6:12 PM",
                "value": 365.20001220703125
            },
            {
                "time": "Mon, Apr 8, 6:13 PM",
                "value": 360.5
            },
            {
                "time": "Mon, Apr 8, 6:13 PM",
                "value": 357.29998779296875
            },
            {
                "time": "Mon, Apr 8, 6:14 PM",
                "value": 354.70001220703125
            },
            {
                "time": "Mon, Apr 8, 6:14 PM",
                "value": 353.5
            },
            {
                "time": "Mon, Apr 8, 6:15 PM",
                "value": 351.29998779296875
            },
            {
                "time": "Mon, Apr 8, 6:15 PM",
                "value": 352.1000061035156
            },
            {
                "time": "Mon, Apr 8, 6:16 PM",
                "value": 349.8999938964844
            },
            {
                "time": "Mon, Apr 8, 6:16 PM",
                "value": 347.6000061035156
            },
            {
                "time": "Mon, Apr 8, 6:17 PM",
                "value": 348
            },
            {
                "time": "Mon, Apr 8, 6:17 PM",
                "value": 347.20001220703125
            },
            {
                "time": "Mon, Apr 8, 6:18 PM",
                "value": 346.6000061035156
            },
            {
                "time": "Mon, Apr 8, 6:18 PM",
                "value": 345.20001220703125
            },
            {
                "time": "Mon, Apr 8, 6:19 PM",
                "value": 341.79998779296875
            },
            {
                "time": "Mon, Apr 8, 6:19 PM",
                "value": 341.29998779296875
            },
            {
                "time": "Mon, Apr 8, 6:20 PM",
                "value": 338.3999938964844
            },
            {
                "time": "Mon, Apr 8, 6:20 PM",
                "value": 335.29998779296875
            },
            {
                "time": "Mon, Apr 8, 6:21 PM",
                "value": 333.5
            },
            {
                "time": "Mon, Apr 8, 6:21 PM",
                "value": 331.70001220703125
            },
            {
                "time": "Mon, Apr 8, 6:22 PM",
                "value": 328.70001220703125
            },
            {
                "time": "Mon, Apr 8, 6:22 PM",
                "value": 327.6000061035156
            },
            {
                "time": "Mon, Apr 8, 6:23 PM",
                "value": 326.70001220703125
            },
            {
                "time": "Mon, Apr 8, 6:23 PM",
                "value": 327.29998779296875
            },
            {
                "time": "Mon, Apr 8, 6:24 PM",
                "value": 327.6000061035156
            },
            {
                "time": "Mon, Apr 8, 6:24 PM",
                "value": 323.8999938964844
            },
            {
                "time": "Mon, Apr 8, 6:25 PM",
                "value": 319.5
            },
            {
                "time": "Mon, Apr 8, 6:25 PM",
                "value": 316.1000061035156
            },
            {
                "time": "Mon, Apr 8, 6:26 PM",
                "value": 317.20001220703125
            },
            {
                "time": "Mon, Apr 8, 6:26 PM",
                "value": 314.79998779296875
            },
            {
                "time": "Mon, Apr 8, 6:27 PM",
                "value": 314.3999938964844
            },
            {
                "time": "Mon, Apr 8, 6:27 PM",
                "value": 311.8999938964844
            },
            {
                "time": "Mon, Apr 8, 6:28 PM",
                "value": 310.6000061035156
            },
            {
                "time": "Mon, Apr 8, 6:28 PM",
                "value": 308.20001220703125
            },
            {
                "time": "Mon, Apr 8, 6:29 PM",
                "value": 306
            },
            {
                "time": "Mon, Apr 8, 6:29 PM",
                "value": 304.70001220703125
            },
            {
                "time": "Mon, Apr 8, 6:30 PM",
                "value": 301.5
            },
            {
                "time": "Mon, Apr 8, 6:30 PM",
                "value": 300.6000061035156
            },
            {
                "time": "Mon, Apr 8, 6:31 PM",
                "value": 298
            },
            {
                "time": "Mon, Apr 8, 6:31 PM",
                "value": 300.29998779296875
            },
            {
                "time": "Mon, Apr 8, 6:32 PM",
                "value": 299.6000061035156
            },
            {
                "time": "Mon, Apr 8, 6:32 PM",
                "value": 298
            },
            {
                "time": "Mon, Apr 8, 6:33 PM",
                "value": 295.5
            },
            {
                "time": "Mon, Apr 8, 6:33 PM",
                "value": 294.70001220703125
            },
            {
                "time": "Mon, Apr 8, 6:34 PM",
                "value": 292.1000061035156
            },
            {
                "time": "Mon, Apr 8, 6:34 PM",
                "value": 288.6000061035156
            },
            {
                "time": "Mon, Apr 8, 6:35 PM",
                "value": 290
            },
            {
                "time": "Mon, Apr 8, 6:35 PM",
                "value": 291.79998779296875
            },
            {
                "time": "Mon, Apr 8, 6:36 PM",
                "value": 287.1000061035156
            },
            {
                "time": "Mon, Apr 8, 6:36 PM",
                "value": 282.8999938964844
            },
            {
                "time": "Mon, Apr 8, 6:37 PM",
                "value": 282.3999938964844
            },
            {
                "time": "Mon, Apr 8, 6:37 PM",
                "value": 281
            },
            {
                "time": "Mon, Apr 8, 6:38 PM",
                "value": 280.8999938964844
            },
            {
                "time": "Mon, Apr 8, 6:38 PM",
                "value": 277.20001220703125
            },
            {
                "time": "Mon, Apr 8, 6:39 PM",
                "value": 276
            },
            {
                "time": "Mon, Apr 8, 6:39 PM",
                "value": 274.6000061035156
            },
            {
                "time": "Mon, Apr 8, 6:40 PM",
                "value": 272.79998779296875
            },
            {
                "time": "Mon, Apr 8, 6:40 PM",
                "value": 271.20001220703125
            },
            {
                "time": "Mon, Apr 8, 6:41 PM",
                "value": 270.3999938964844
            },
            {
                "time": "Mon, Apr 8, 6:41 PM",
                "value": 267.3999938964844
            },
            {
                "time": "Mon, Apr 8, 6:42 PM",
                "value": 263.5
            },
            {
                "time": "Mon, Apr 8, 6:42 PM",
                "value": 262.20001220703125
            },
            {
                "time": "Mon, Apr 8, 6:43 PM",
                "value": 262.1000061035156
            },
            {
                "time": "Mon, Apr 8, 6:43 PM",
                "value": 262.5
            },
            {
                "time": "Mon, Apr 8, 6:44 PM",
                "value": 259.20001220703125
            },
            {
                "time": "Mon, Apr 8, 6:44 PM",
                "value": 256.8999938964844
            },
            {
                "time": "Mon, Apr 8, 6:45 PM",
                "value": 256
            },
            {
                "time": "Mon, Apr 8, 6:45 PM",
                "value": 254.5
            },
            {
                "time": "Mon, Apr 8, 6:46 PM",
                "value": 253
            },
            {
                "time": "Mon, Apr 8, 6:46 PM",
                "value": 252
            },
            {
                "time": "Mon, Apr 8, 6:47 PM",
                "value": 251.3000030517578
            },
            {
                "time": "Mon, Apr 8, 6:47 PM",
                "value": 250.5
            },
            {
                "time": "Mon, Apr 8, 6:48 PM",
                "value": 248.5
            },
            {
                "time": "Mon, Apr 8, 6:48 PM",
                "value": 246.3000030517578
            },
            {
                "time": "Mon, Apr 8, 6:49 PM",
                "value": 244.60000610351562
            },
            {
                "time": "Mon, Apr 8, 6:49 PM",
                "value": 242.10000610351562
            },
            {
                "time": "Mon, Apr 8, 6:50 PM",
                "value": 240.39999389648438
            },
            {
                "time": "Mon, Apr 8, 6:50 PM",
                "value": 238.89999389648438
            },
            {
                "time": "Mon, Apr 8, 6:51 PM",
                "value": 238.39999389648438
            },
            {
                "time": "Mon, Apr 8, 6:51 PM",
                "value": 237.89999389648438
            },
            {
                "time": "Mon, Apr 8, 6:52 PM",
                "value": 236
            },
            {
                "time": "Mon, Apr 8, 6:52 PM",
                "value": 233.89999389648438
            },
            {
                "time": "Mon, Apr 8, 6:53 PM",
                "value": 230.10000610351562
            },
            {
                "time": "Mon, Apr 8, 6:53 PM",
                "value": 228.6999969482422
            },
            {
                "time": "Mon, Apr 8, 6:54 PM",
                "value": 228.6999969482422
            },
            {
                "time": "Mon, Apr 8, 6:54 PM",
                "value": 227.10000610351562
            },
            {
                "time": "Mon, Apr 8, 6:55 PM",
                "value": 224.8000030517578
            },
            {
                "time": "Mon, Apr 8, 6:55 PM",
                "value": 223.5
            },
            {
                "time": "Mon, Apr 8, 6:56 PM",
                "value": 221.6999969482422
            },
            {
                "time": "Mon, Apr 8, 6:56 PM",
                "value": 219.39999389648438
            },
            {
                "time": "Mon, Apr 8, 6:57 PM",
                "value": 219
            },
            {
                "time": "Mon, Apr 8, 6:57 PM",
                "value": 218.60000610351562
            },
            {
                "time": "Mon, Apr 8, 6:58 PM",
                "value": 216.60000610351562
            },
            {
                "time": "Mon, Apr 8, 6:58 PM",
                "value": 213.1999969482422
            },
            {
                "time": "Mon, Apr 8, 6:59 PM",
                "value": 211.1999969482422
            },
            {
                "time": "Mon, Apr 8, 6:59 PM",
                "value": 209.89999389648438
            },
            {
                "time": "Mon, Apr 8, 7:00 PM",
                "value": 208.39999389648438
            },
            {
                "time": "Mon, Apr 8, 7:00 PM",
                "value": 207
            },
            {
                "time": "Mon, Apr 8, 7:01 PM",
                "value": 206.10000610351562
            },
            {
                "time": "Mon, Apr 8, 7:01 PM",
                "value": 204.3000030517578
            },
            {
                "time": "Mon, Apr 8, 7:02 PM",
                "value": 202.8000030517578
            },
            {
                "time": "Mon, Apr 8, 7:02 PM",
                "value": 200.39999389648438
            },
            {
                "time": "Mon, Apr 8, 7:03 PM",
                "value": 198.39999389648438
            },
            {
                "time": "Mon, Apr 8, 7:03 PM",
                "value": 196.39999389648438
            },
            {
                "time": "Mon, Apr 8, 7:04 PM",
                "value": 195.89999389648438
            },
            {
                "time": "Mon, Apr 8, 7:04 PM",
                "value": 194.8000030517578
            },
            {
                "time": "Mon, Apr 8, 7:05 PM",
                "value": 193.1999969482422
            },
            {
                "time": "Mon, Apr 8, 7:05 PM",
                "value": 191.3000030517578
            },
            {
                "time": "Mon, Apr 8, 7:06 PM",
                "value": 188.89999389648438
            },
            {
                "time": "Mon, Apr 8, 7:06 PM",
                "value": 187
            },
            {
                "time": "Mon, Apr 8, 7:07 PM",
                "value": 185.8000030517578
            },
            {
                "time": "Mon, Apr 8, 7:07 PM",
                "value": 184.8000030517578
            },
            {
                "time": "Mon, Apr 8, 7:08 PM",
                "value": 183.60000610351562
            },
            {
                "time": "Mon, Apr 8, 7:08 PM",
                "value": 181.1999969482422
            },
            {
                "time": "Mon, Apr 8, 7:09 PM",
                "value": 179.1999969482422
            },
            {
                "time": "Mon, Apr 8, 7:09 PM",
                "value": 177.5
            },
            {
                "time": "Mon, Apr 8, 7:10 PM",
                "value": 176.5
            },
            {
                "time": "Mon, Apr 8, 7:10 PM",
                "value": 175.1999969482422
            },
            {
                "time": "Mon, Apr 8, 7:11 PM",
                "value": 174.10000610351562
            },
            {
                "time": "Mon, Apr 8, 7:11 PM",
                "value": 172.39999389648438
            },
            {
                "time": "Mon, Apr 8, 7:12 PM",
                "value": 170.89999389648438
            },
            {
                "time": "Mon, Apr 8, 7:12 PM",
                "value": 169.39999389648438
            },
            {
                "time": "Mon, Apr 8, 7:13 PM",
                "value": 168
            },
            {
                "time": "Mon, Apr 8, 7:13 PM",
                "value": 166.5
            },
            {
                "time": "Mon, Apr 8, 7:14 PM",
                "value": 164.8000030517578
            },
            {
                "time": "Mon, Apr 8, 7:14 PM",
                "value": 163.8000030517578
            },
            {
                "time": "Mon, Apr 8, 7:15 PM",
                "value": 162.89999389648438
            },
            {
                "time": "Mon, Apr 8, 7:15 PM",
                "value": 161.60000610351562
            },
            {
                "time": "Mon, Apr 8, 7:16 PM",
                "value": 159.8000030517578
            },
            {
                "time": "Mon, Apr 8, 7:16 PM",
                "value": 158.10000610351562
            },
            {
                "time": "Mon, Apr 8, 7:17 PM",
                "value": 156.3000030517578
            },
            {
                "time": "Mon, Apr 8, 7:17 PM",
                "value": 154.6999969482422
            },
            {
                "time": "Mon, Apr 8, 7:18 PM",
                "value": 153.60000610351562
            },
            {
                "time": "Mon, Apr 8, 7:18 PM",
                "value": 152.6999969482422
            },
            {
                "time": "Mon, Apr 8, 7:19 PM",
                "value": 151.5
            },
            {
                "time": "Mon, Apr 8, 7:19 PM",
                "value": 150
            },
            {
                "time": "Mon, Apr 8, 7:20 PM",
                "value": 148.89999389648438
            },
            {
                "time": "Mon, Apr 8, 7:20 PM",
                "value": 147.89999389648438
            },
            {
                "time": "Mon, Apr 8, 7:21 PM",
                "value": 146.3000030517578
            },
            {
                "time": "Mon, Apr 8, 7:21 PM",
                "value": 144.89999389648438
            },
            {
                "time": "Mon, Apr 8, 7:22 PM",
                "value": 143.89999389648438
            },
            {
                "time": "Mon, Apr 8, 7:22 PM",
                "value": 142.89999389648438
            },
            {
                "time": "Mon, Apr 8, 7:23 PM",
                "value": 140.89999389648438
            },
            {
                "time": "Mon, Apr 8, 7:23 PM",
                "value": 139.39999389648438
            },
            {
                "time": "Mon, Apr 8, 7:24 PM",
                "value": 138
            },
            {
                "time": "Mon, Apr 8, 7:24 PM",
                "value": 136.39999389648438
            },
            {
                "time": "Mon, Apr 8, 7:25 PM",
                "value": 134.5
            },
            {
                "time": "Mon, Apr 8, 7:25 PM",
                "value": 133.60000610351562
            },
            {
                "time": "Mon, Apr 8, 7:26 PM",
                "value": 132.1999969482422
            },
            {
                "time": "Mon, Apr 8, 7:26 PM",
                "value": 130.8000030517578
            },
            {
                "time": "Mon, Apr 8, 7:27 PM",
                "value": 128.89999389648438
            },
            {
                "time": "Mon, Apr 8, 7:27 PM",
                "value": 127.30000305175781
            },
            {
                "time": "Mon, Apr 8, 7:28 PM",
                "value": 125.80000305175781
            },
            {
                "time": "Mon, Apr 8, 7:28 PM",
                "value": 124.19999694824219
            },
            {
                "time": "Mon, Apr 8, 7:29 PM",
                "value": 122.80000305175781
            },
            {
                "time": "Mon, Apr 8, 7:29 PM",
                "value": 121.5
            },
            {
                "time": "Mon, Apr 8, 7:30 PM",
                "value": 119.80000305175781
            },
            {
                "time": "Mon, Apr 8, 7:30 PM",
                "value": 118.30000305175781
            },
            {
                "time": "Mon, Apr 8, 7:31 PM",
                "value": 116.30000305175781
            },
            {
                "time": "Mon, Apr 8, 7:31 PM",
                "value": 114.80000305175781
            },
            {
                "time": "Mon, Apr 8, 7:32 PM",
                "value": 113.5
            },
            {
                "time": "Mon, Apr 8, 7:32 PM",
                "value": 112.0999984741211
            },
            {
                "time": "Mon, Apr 8, 7:33 PM",
                "value": 110.4000015258789
            },
            {
                "time": "Mon, Apr 8, 7:33 PM",
                "value": 108.80000305175781
            },
            {
                "time": "Mon, Apr 8, 7:34 PM",
                "value": 107.30000305175781
            },
            {
                "time": "Mon, Apr 8, 7:34 PM",
                "value": 106
            },
            {
                "time": "Mon, Apr 8, 7:35 PM",
                "value": 104.30000305175781
            },
            {
                "time": "Mon, Apr 8, 7:35 PM",
                "value": 102.5999984741211
            },
            {
                "time": "Mon, Apr 8, 7:36 PM",
                "value": 101.30000305175781
            },
            {
                "time": "Mon, Apr 8, 7:36 PM",
                "value": 99.69999694824219
            },
            {
                "time": "Mon, Apr 8, 7:37 PM",
                "value": 98.19999694824219
            },
            {
                "time": "Mon, Apr 8, 7:37 PM",
                "value": 96.9000015258789
            },
            {
                "time": "Mon, Apr 8, 7:38 PM",
                "value": 95.5999984741211
            },
            {
                "time": "Mon, Apr 8, 7:38 PM",
                "value": 94.4000015258789
            },
            {
                "time": "Mon, Apr 8, 7:39 PM",
                "value": 93.30000305175781
            },
            {
                "time": "Mon, Apr 8, 7:39 PM",
                "value": 92.30000305175781
            },
            {
                "time": "Mon, Apr 8, 7:40 PM",
                "value": 91
            },
            {
                "time": "Mon, Apr 8, 7:40 PM",
                "value": 89.80000305175781
            },
            {
                "time": "Mon, Apr 8, 7:41 PM",
                "value": 88.69999694824219
            },
            {
                "time": "Mon, Apr 8, 7:41 PM",
                "value": 87.5
            },
            {
                "time": "Mon, Apr 8, 7:42 PM",
                "value": 86.4000015258789
            },
            {
                "time": "Mon, Apr 8, 7:42 PM",
                "value": 85.19999694824219
            },
            {
                "time": "Mon, Apr 8, 7:43 PM",
                "value": 83.5999984741211
            },
            {
                "time": "Mon, Apr 8, 7:43 PM",
                "value": 82
            },
            {
                "time": "Mon, Apr 8, 7:44 PM",
                "value": 80.69999694824219
            },
            {
                "time": "Mon, Apr 8, 7:44 PM",
                "value": 79.05999755859375
            },
            {
                "time": "Mon, Apr 8, 7:45 PM",
                "value": 77.87999725341797
            },
            {
                "time": "Mon, Apr 8, 7:45 PM",
                "value": 76.87999725341797
            },
            {
                "time": "Mon, Apr 8, 7:46 PM",
                "value": 75.69999694824219
            },
            {
                "time": "Mon, Apr 8, 7:46 PM",
                "value": 74.30000305175781
            },
            {
                "time": "Mon, Apr 8, 7:47 PM",
                "value": 72.95999908447266
            },
            {
                "time": "Mon, Apr 8, 7:47 PM",
                "value": 71.66999816894531
            },
            {
                "time": "Mon, Apr 8, 7:48 PM",
                "value": 70.38999938964844
            },
            {
                "time": "Mon, Apr 8, 7:48 PM",
                "value": 69.0999984741211
            },
            {
                "time": "Mon, Apr 8, 7:49 PM",
                "value": 67.69999694824219
            },
            {
                "time": "Mon, Apr 8, 7:49 PM",
                "value": 66.36000061035156
            },
            {
                "time": "Mon, Apr 8, 7:50 PM",
                "value": 65.0199966430664
            },
            {
                "time": "Mon, Apr 8, 7:50 PM",
                "value": 63.900001525878906
            },
            {
                "time": "Mon, Apr 8, 7:51 PM",
                "value": 62.61000061035156
            },
            {
                "time": "Mon, Apr 8, 7:51 PM",
                "value": 61.099998474121094
            },
            {
                "time": "Mon, Apr 8, 7:52 PM",
                "value": 59.63999938964844
            },
            {
                "time": "Mon, Apr 8, 7:52 PM",
                "value": 58.40999984741211
            },
            {
                "time": "Mon, Apr 8, 7:53 PM",
                "value": 57.18000030517578
            },
            {
                "time": "Mon, Apr 8, 7:53 PM",
                "value": 56.060001373291016
            },
            {
                "time": "Mon, Apr 8, 7:54 PM",
                "value": 54.83000183105469
            },
            {
                "time": "Mon, Apr 8, 7:54 PM",
                "value": 53.54999923706055
            },
            {
                "time": "Mon, Apr 8, 7:55 PM",
                "value": 52.310001373291016
            },
            {
                "time": "Mon, Apr 8, 7:55 PM",
                "value": 51.20000076293945
            },
            {
                "time": "Mon, Apr 8, 7:56 PM",
                "value": 50.08000183105469
            },
            {
                "time": "Mon, Apr 8, 7:56 PM",
                "value": 48.849998474121094
            },
            {
                "time": "Mon, Apr 8, 7:57 PM",
                "value": 47.45000076293945
            },
            {
                "time": "Mon, Apr 8, 7:57 PM",
                "value": 46.15999984741211
            },
            {
                "time": "Mon, Apr 8, 7:58 PM",
                "value": 44.869998931884766
            },
            {
                "time": "Mon, Apr 8, 7:58 PM",
                "value": 43.63999938964844
            },
            {
                "time": "Mon, Apr 8, 7:59 PM",
                "value": 42.470001220703125
            },
            {
                "time": "Mon, Apr 8, 7:59 PM",
                "value": 41.290000915527344
            },
            {
                "time": "Mon, Apr 8, 8:00 PM",
                "value": 40.22999954223633
            },
            {
                "time": "Mon, Apr 8, 8:00 PM",
                "value": 39.220001220703125
            },
            {
                "time": "Mon, Apr 8, 8:01 PM",
                "value": 37.9900016784668
            },
            {
                "time": "Mon, Apr 8, 8:01 PM",
                "value": 36.7599983215332
            },
            {
                "time": "Mon, Apr 8, 8:02 PM",
                "value": 35.59000015258789
            },
            {
                "time": "Mon, Apr 8, 8:02 PM",
                "value": 34.2400016784668
            },
            {
                "time": "Mon, Apr 8, 8:03 PM",
                "value": 33.06999969482422
            },
            {
                "time": "Mon, Apr 8, 8:03 PM",
                "value": 31.889999389648438
            },
            {
                "time": "Mon, Apr 8, 8:04 PM",
                "value": 30.829999923706055
            },
            {
                "time": "Mon, Apr 8, 8:04 PM",
                "value": 29.709999084472656
            },
            {
                "time": "Mon, Apr 8, 8:05 PM",
                "value": 28.59000015258789
            },
            {
                "time": "Mon, Apr 8, 8:05 PM",
                "value": 27.469999313354492
            },
            {
                "time": "Mon, Apr 8, 8:06 PM",
                "value": 26.350000381469727
            },
            {
                "time": "Mon, Apr 8, 8:06 PM",
                "value": 25.510000228881836
            },
            {
                "time": "Mon, Apr 8, 8:07 PM",
                "value": 24.84000015258789
            },
            {
                "time": "Mon, Apr 8, 8:07 PM",
                "value": 23.950000762939453
            },
            {
                "time": "Mon, Apr 8, 8:08 PM",
                "value": 22.8799991607666
            },
            {
                "time": "Mon, Apr 8, 8:08 PM",
                "value": 21.93000030517578
            },
            {
                "time": "Mon, Apr 8, 8:09 PM",
                "value": 21.040000915527344
            },
            {
                "time": "Mon, Apr 8, 8:09 PM",
                "value": 20.25
            },
            {
                "time": "Mon, Apr 8, 8:10 PM",
                "value": 19.360000610351562
            },
            {
                "time": "Mon, Apr 8, 8:10 PM",
                "value": 18.860000610351562
            },
            {
                "time": "Mon, Apr 8, 8:11 PM",
                "value": 18.299999237060547
            },
            {
                "time": "Mon, Apr 8, 8:11 PM",
                "value": 17.959999084472656
            },
            {
                "time": "Mon, Apr 8, 8:12 PM",
                "value": 17.229999542236328
            },
            {
                "time": "Mon, Apr 8, 8:12 PM",
                "value": 16.6200008392334
            },
            {
                "time": "Mon, Apr 8, 8:13 PM",
                "value": 15.890000343322754
            },
            {
                "time": "Mon, Apr 8, 8:13 PM",
                "value": 15.15999984741211
            },
            {
                "time": "Mon, Apr 8, 8:14 PM",
                "value": 14.380000114440918
            },
            {
                "time": "Mon, Apr 8, 8:14 PM",
                "value": 13.710000038146973
            },
            {
                "time": "Mon, Apr 8, 8:15 PM",
                "value": 12.760000228881836
            },
            {
                "time": "Mon, Apr 8, 8:15 PM",
                "value": 12.199999809265137
            },
            {
                "time": "Mon, Apr 8, 8:16 PM",
                "value": 11.640000343322754
            },
            {
                "time": "Mon, Apr 8, 8:16 PM",
                "value": 11.079999923706055
            },
            {
                "time": "Mon, Apr 8, 8:17 PM",
                "value": 10.630000114440918
            },
            {
                "time": "Mon, Apr 8, 8:17 PM",
                "value": 10.069999694824219
            },
            {
                "time": "Mon, Apr 8, 8:18 PM",
                "value": 9.739999771118164
            },
            {
                "time": "Mon, Apr 8, 8:18 PM",
                "value": 9.0600004196167
            },
            {
                "time": "Mon, Apr 8, 8:19 PM",
                "value": 8.779999732971191
            },
            {
                "time": "Mon, Apr 8, 8:19 PM",
                "value": 8.390000343322754
            },
            {
                "time": "Mon, Apr 8, 8:20 PM",
                "value": 7.888999938964844
            },
            {
                "time": "Mon, Apr 8, 8:20 PM",
                "value": 7.441999912261963
            },
            {
                "time": "Mon, Apr 8, 8:21 PM",
                "value": 7.105999946594238
            },
            {
                "time": "Mon, Apr 8, 8:21 PM",
                "value": 6.714000225067139
            },
            {
                "time": "Mon, Apr 8, 8:22 PM",
                "value": 6.379000186920166
            },
            {
                "time": "Mon, Apr 8, 8:22 PM",
                "value": 5.931000232696533
            },
            {
                "time": "Mon, Apr 8, 8:23 PM",
                "value": 5.651000022888184
            },
            {
                "time": "Mon, Apr 8, 8:23 PM",
                "value": 5.315000057220459
            },
            {
                "time": "Mon, Apr 8, 8:24 PM",
                "value": 5.035999774932861
            },
            {
                "time": "Mon, Apr 8, 8:24 PM",
                "value": 4.75600004196167
            },
            {
                "time": "Mon, Apr 8, 8:25 PM",
                "value": 4.5879998207092285
            },
            {
                "time": "Mon, Apr 8, 8:25 PM",
                "value": 4.420000076293945
            },
            {
                "time": "Mon, Apr 8, 8:26 PM",
                "value": 4.139999866485596
            },
            {
                "time": "Mon, Apr 8, 8:26 PM",
                "value": 3.8610000610351562
            },
            {
                "time": "Mon, Apr 8, 8:27 PM",
                "value": 3.63700008392334
            },
            {
                "time": "Mon, Apr 8, 8:27 PM",
                "value": 3.4130001068115234
            },
            {
                "time": "Mon, Apr 8, 8:28 PM",
                "value": 3.132999897003174
            },
            {
                "time": "Mon, Apr 8, 8:28 PM",
                "value": 3.0209999084472656
            },
            {
                "time": "Mon, Apr 8, 8:29 PM",
                "value": 2.742000102996826
            },
            {
                "time": "Mon, Apr 8, 8:29 PM",
                "value": 2.5739998817443848
            },
            {
                "time": "Mon, Apr 8, 8:30 PM",
                "value": 2.4619998931884766
            },
            {
                "time": "Mon, Apr 8, 8:30 PM",
                "value": 2.2939999103546143
            },
            {
                "time": "Mon, Apr 8, 8:31 PM",
                "value": 2.125999927520752
            },
            {
                "time": "Mon, Apr 8, 8:31 PM",
                "value": 1.9579999446868896
            },
            {
                "time": "Mon, Apr 8, 8:32 PM",
                "value": 1.8459999561309814
            },
            {
                "time": "Mon, Apr 8, 8:32 PM",
                "value": 1.7350000143051147
            },
            {
                "time": "Mon, Apr 8, 8:33 PM",
                "value": 1.5110000371932983
            },
            {
                "time": "Mon, Apr 8, 8:33 PM",
                "value": 1.5110000371932983
            },
            {
                "time": "Mon, Apr 8, 8:34 PM",
                "value": 1.3990000486373901
            },
            {
                "time": "Mon, Apr 8, 8:34 PM",
                "value": 1.1749999523162842
            },
            {
                "time": "Mon, Apr 8, 8:35 PM",
                "value": 1.1749999523162842
            },
            {
                "time": "Mon, Apr 8, 8:35 PM",
                "value": 1.062999963760376
            },
            {
                "time": "Mon, Apr 8, 8:36 PM",
                "value": 0.9509999752044678
            },
            {
                "time": "Mon, Apr 8, 8:36 PM",
                "value": 0.8949999809265137
            },
            {
                "time": "Mon, Apr 8, 8:37 PM",
                "value": 0.8389999866485596
            },
            {
                "time": "Mon, Apr 8, 8:37 PM",
                "value": 0.8389999866485596
            },
            {
                "time": "Mon, Apr 8, 8:38 PM",
                "value": 0.6710000038146973
            },
            {
                "time": "Mon, Apr 8, 8:38 PM",
                "value": 0.6710000038146973
            },
            {
                "time": "Mon, Apr 8, 8:39 PM",
                "value": 0.6710000038146973
            },
            {
                "time": "Mon, Apr 8, 8:39 PM",
                "value": 0.5600000023841858
            },
            {
                "time": "Mon, Apr 8, 8:40 PM",
                "value": 0.4480000138282776
            },
            {
                "time": "Mon, Apr 8, 8:40 PM",
                "value": 0.4480000138282776
            },
            {
                "time": "Mon, Apr 8, 8:41 PM",
                "value": 0.4480000138282776
            },
            {
                "time": "Mon, Apr 8, 8:41 PM",
                "value": 0.3919999897480011
            },
            {
                "time": "Mon, Apr 8, 8:42 PM",
                "value": 0.335999995470047
            },
            {
                "time": "Mon, Apr 8, 8:42 PM",
                "value": 0.2800000011920929
            },
            {
                "time": "Mon, Apr 8, 8:43 PM",
                "value": 0.2800000011920929
            },
            {
                "time": "Mon, Apr 8, 8:43 PM",
                "value": 0.2240000069141388
            },
            {
                "time": "Mon, Apr 8, 8:44 PM",
                "value": 0.1679999977350235
            },
            {
                "time": "Mon, Apr 8, 8:44 PM",
                "value": 0.1679999977350235
            },
            {
                "time": "Mon, Apr 8, 8:45 PM",
                "value": 0.1679999977350235
            },
            {
                "time": "Mon, Apr 8, 8:45 PM",
                "value": 0.1679999977350235
            },
            {
                "time": "Mon, Apr 8, 8:46 PM",
                "value": 0.1120000034570694
            },
            {
                "time": "Mon, Apr 8, 8:46 PM",
                "value": 0.1120000034570694
            },
            {
                "time": "Mon, Apr 8, 8:47 PM",
                "value": 0.2240000069141388
            },
            {
                "time": "Mon, Apr 8, 8:47 PM",
                "value": 0.1120000034570694
            },
            {
                "time": "Mon, Apr 8, 8:48 PM",
                "value": 0.1120000034570694
            },
            {
                "time": "Mon, Apr 8, 8:48 PM",
                "value": 0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 8:49 PM",
                "value": 0.1120000034570694
            },
            {
                "time": "Mon, Apr 8, 8:49 PM",
                "value": 0.1120000034570694
            },
            {
                "time": "Mon, Apr 8, 8:50 PM",
                "value": 0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 8:50 PM",
                "value": 0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 8:51 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:51 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:52 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:52 PM",
                "value": -0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 8:53 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:53 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:54 PM",
                "value": 0.1120000034570694
            },
            {
                "time": "Mon, Apr 8, 8:54 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:55 PM",
                "value": 0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 8:55 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:56 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:56 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:57 PM",
                "value": -0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 8:57 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:58 PM",
                "value": 0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 8:58 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:59 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 8:59 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:00 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:00 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:01 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:01 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:02 PM",
                "value": 0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 9:02 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:03 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:03 PM",
                "value": -0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 9:04 PM",
                "value": 0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 9:04 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:05 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:05 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:06 PM",
                "value": -0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 9:06 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:07 PM",
                "value": -0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 9:07 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:08 PM",
                "value": -0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 9:08 PM",
                "value": -0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 9:09 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:09 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:10 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:10 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:11 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:11 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:12 PM",
                "value": -0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 9:12 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:13 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:13 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:14 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:14 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:15 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:15 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:16 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:16 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:17 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:17 PM",
                "value": -0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 9:18 PM",
                "value": 0.1120000034570694
            },
            {
                "time": "Mon, Apr 8, 9:18 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:19 PM",
                "value": -0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 9:19 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:20 PM",
                "value": -0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 9:20 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:21 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:21 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:22 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:22 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:23 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:23 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:24 PM",
                "value": 0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 9:24 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:25 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:25 PM",
                "value": 0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 9:26 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:26 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:27 PM",
                "value": -0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 9:27 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:28 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:28 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:29 PM",
                "value": 0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 9:29 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:30 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:30 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:31 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:31 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:32 PM",
                "value": 0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 9:32 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:33 PM",
                "value": -0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 9:33 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:34 PM",
                "value": 0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 9:34 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:35 PM",
                "value": -0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 9:35 PM",
                "value": -0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 9:36 PM",
                "value": 0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 9:36 PM",
                "value": -0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 9:37 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:37 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:38 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:38 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:39 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:39 PM",
                "value": 0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 9:40 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:40 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:41 PM",
                "value": 0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 9:41 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:42 PM",
                "value": -0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 9:42 PM",
                "value": 0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 9:43 PM",
                "value": -0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 9:43 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:44 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:44 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:45 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:45 PM",
                "value": -0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 9:46 PM",
                "value": -0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 9:46 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:47 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:47 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:48 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:48 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:49 PM",
                "value": -0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 9:49 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:50 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:50 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:51 PM",
                "value": -0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 9:51 PM",
                "value": -0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 9:52 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:52 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:53 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:53 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:54 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:54 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:55 PM",
                "value": 0.1120000034570694
            },
            {
                "time": "Mon, Apr 8, 9:55 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:56 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:56 PM",
                "value": 0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 9:57 PM",
                "value": -0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 9:57 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:58 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:58 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:59 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 9:59 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:00 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:00 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:01 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:01 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:02 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:02 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:03 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:03 PM",
                "value": -0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 10:04 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:04 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:05 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:05 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:06 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:06 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:07 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:07 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:08 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:08 PM",
                "value": 0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 10:09 PM",
                "value": 0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 10:09 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:10 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:10 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:11 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:11 PM",
                "value": 0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 10:12 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:12 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:13 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:13 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:14 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:14 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:15 PM",
                "value": -0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 10:15 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:16 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:16 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:17 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:17 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:18 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:18 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:19 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:19 PM",
                "value": -0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 10:20 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:20 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:21 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:21 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:22 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:22 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:23 PM",
                "value": -0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 10:23 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:24 PM",
                "value": -0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 10:24 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:25 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:25 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:26 PM",
                "value": -0.1120000034570694
            },
            {
                "time": "Mon, Apr 8, 10:26 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:27 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:27 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:28 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:28 PM",
                "value": 0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 10:29 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:29 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:30 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:30 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:31 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:31 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:32 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:32 PM",
                "value": -0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 10:33 PM",
                "value": 0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 10:33 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:34 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:34 PM",
                "value": -0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 10:35 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:35 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:36 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:36 PM",
                "value": 0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 10:37 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:37 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:38 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:38 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:39 PM",
                "value": -0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 10:39 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:40 PM",
                "value": -0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 10:40 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:41 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:41 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:42 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:42 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:43 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:43 PM",
                "value": -0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 10:44 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:44 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:45 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:45 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:46 PM",
                "value": -0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 10:46 PM",
                "value": -0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 10:47 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:47 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:48 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:48 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:49 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:49 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:50 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:50 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:51 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:51 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:52 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:52 PM",
                "value": -0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 10:53 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:53 PM",
                "value": -0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 10:54 PM",
                "value": 0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 10:54 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:55 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:55 PM",
                "value": -0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 10:56 PM",
                "value": -0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 10:56 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:57 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:57 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:58 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:58 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:59 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 10:59 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:00 PM",
                "value": -0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 11:00 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:01 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:01 PM",
                "value": 0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 11:02 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:02 PM",
                "value": -0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 11:03 PM",
                "value": 0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 11:03 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:04 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:04 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:05 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:05 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:06 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:06 PM",
                "value": -0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 11:07 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:07 PM",
                "value": -0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 11:08 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:08 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:09 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:09 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:10 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:10 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:11 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:11 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:12 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:12 PM",
                "value": 0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 11:13 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:13 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:14 PM",
                "value": -0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 11:14 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:15 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:15 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:16 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:16 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:17 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:17 PM",
                "value": -0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 11:18 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:18 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:19 PM",
                "value": 0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 11:19 PM",
                "value": 0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 11:20 PM",
                "value": -0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 11:20 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:21 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:21 PM",
                "value": 0.1120000034570694
            },
            {
                "time": "Mon, Apr 8, 11:22 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:22 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:23 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:23 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:24 PM",
                "value": -0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 11:24 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:25 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:25 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:26 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:26 PM",
                "value": -0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 11:27 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:27 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:28 PM",
                "value": 0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 11:28 PM",
                "value": 0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 11:29 PM",
                "value": -0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 11:29 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:30 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:30 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:31 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:31 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:32 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:32 PM",
                "value": 0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 11:33 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:33 PM",
                "value": -0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 11:34 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:34 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:35 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:35 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:36 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:36 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:37 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:37 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:38 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:38 PM",
                "value": 0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 11:39 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:39 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:40 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:40 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:41 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:41 PM",
                "value": 0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 11:42 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:42 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:43 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:43 PM",
                "value": 0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 11:44 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:44 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:45 PM",
                "value": 0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 11:45 PM",
                "value": -0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 11:46 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:46 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:47 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:47 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:48 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:48 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:49 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:49 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:50 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:50 PM",
                "value": -0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 11:51 PM",
                "value": -0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 11:51 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:52 PM",
                "value": -0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 11:52 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:53 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:53 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:54 PM",
                "value": 0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 11:54 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:55 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:55 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:56 PM",
                "value": 0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 11:56 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:57 PM",
                "value": 0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 11:57 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:58 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:58 PM",
                "value": 0.0560000017285347
            },
            {
                "time": "Mon, Apr 8, 11:59 PM",
                "value": 0
            },
            {
                "time": "Mon, Apr 8, 11:59 PM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:00 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:00 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 12:01 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:01 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:02 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:02 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:03 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:03 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 12:04 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:04 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:05 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:05 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:06 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 12:06 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:07 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 12:07 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:08 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:08 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:09 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:09 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:10 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:10 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:11 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:11 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:12 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:12 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:13 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 12:13 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 12:14 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:14 AM",
                "value": -0.1120000034570694
            },
            {
                "time": "Tue, Apr 9, 12:15 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:15 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:16 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:16 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 12:17 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 12:17 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:18 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 12:18 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:19 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 12:19 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:20 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:20 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:21 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:21 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 12:22 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:22 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:23 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 12:23 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:24 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:24 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:25 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 12:25 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:26 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:26 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:27 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 12:27 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:28 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:28 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:29 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:29 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 12:30 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 12:30 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:31 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:31 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:32 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 12:32 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:33 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:33 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 12:34 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:34 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 12:35 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:35 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:36 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:36 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:37 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:37 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:38 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:38 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:39 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:39 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:40 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 12:40 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:41 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:41 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:42 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 12:42 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:43 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 12:43 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 12:44 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:44 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:45 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 12:45 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:46 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:46 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:47 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:47 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 12:48 AM",
                "value": 0.1120000034570694
            },
            {
                "time": "Tue, Apr 9, 12:48 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:49 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:49 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:50 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:50 AM",
                "value": -0.1120000034570694
            },
            {
                "time": "Tue, Apr 9, 12:51 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 12:51 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:52 AM",
                "value": -0.1120000034570694
            },
            {
                "time": "Tue, Apr 9, 12:52 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:53 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:53 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 12:54 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:54 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:55 AM",
                "value": 0.1120000034570694
            },
            {
                "time": "Tue, Apr 9, 12:55 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:56 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:56 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 12:57 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:57 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:58 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:58 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:59 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 12:59 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 1:00 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:00 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:01 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:01 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:02 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 1:02 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:03 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:03 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:04 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:04 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 1:05 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:05 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 1:06 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 1:06 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 1:07 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:07 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:08 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:08 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:09 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:09 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:10 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:10 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 1:11 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:11 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:12 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:12 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 1:13 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:13 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:14 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 1:14 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:15 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:15 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:16 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:16 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 1:17 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 1:17 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:18 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:18 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:19 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:19 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:20 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:20 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:21 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:21 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:22 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:22 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:23 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:23 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:24 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:24 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:25 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:25 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:26 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:26 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 1:27 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:27 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:28 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 1:28 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:29 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:29 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:30 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 1:30 AM",
                "value": -0.1120000034570694
            },
            {
                "time": "Tue, Apr 9, 1:31 AM",
                "value": -0.1120000034570694
            },
            {
                "time": "Tue, Apr 9, 1:31 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:32 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:32 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:33 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:33 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:34 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:34 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:35 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:35 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:36 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 1:36 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:37 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 1:37 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:38 AM",
                "value": -0.1120000034570694
            },
            {
                "time": "Tue, Apr 9, 1:38 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 1:39 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:39 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 1:40 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:40 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:41 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:41 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:42 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:42 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:43 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:43 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:44 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:44 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:45 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:45 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:46 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 1:46 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 1:47 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:47 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 1:48 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:48 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:49 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:49 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:50 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:50 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 1:51 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:51 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:52 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:52 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 1:53 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:53 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 1:54 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 1:54 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:55 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:55 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 1:56 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:56 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 1:57 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:57 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:58 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:58 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:59 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 1:59 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:00 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:00 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:01 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:01 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:02 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:02 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:03 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:03 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:04 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:04 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:05 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:05 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:06 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:06 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:07 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 2:07 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:08 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:08 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:09 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 2:09 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 2:10 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 2:10 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:11 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:11 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:12 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:12 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 2:13 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:13 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:14 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:14 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:15 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:15 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:16 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:16 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:17 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:17 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 2:18 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:18 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 2:19 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:19 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 2:20 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 2:20 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:21 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:21 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:22 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 2:22 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 2:23 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:23 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:24 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 2:24 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:25 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:25 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:26 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:26 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:27 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:27 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 2:28 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:28 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 2:29 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:29 AM",
                "value": 0.1120000034570694
            },
            {
                "time": "Tue, Apr 9, 2:30 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:30 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:31 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:31 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:32 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:32 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:33 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:33 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:34 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:34 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:35 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:35 AM",
                "value": -0.1120000034570694
            },
            {
                "time": "Tue, Apr 9, 2:36 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:36 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:37 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 2:37 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:38 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:38 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:39 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:39 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 2:40 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:40 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:41 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:41 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:42 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:42 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:43 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:43 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:44 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:44 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:45 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:45 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:46 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:46 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:47 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:47 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:48 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:48 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 2:49 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:49 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:50 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:50 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:51 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:51 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:52 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:52 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:53 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:53 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 2:54 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:54 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:55 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:55 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:56 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:56 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:57 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:57 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 2:58 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:58 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:59 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 2:59 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:00 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:00 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:01 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 3:01 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 3:02 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:02 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:03 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:03 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 3:04 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:04 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 3:05 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 3:05 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 3:06 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 3:06 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:07 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:07 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:08 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:08 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:09 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 3:09 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:10 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 3:10 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 3:11 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:11 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:12 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:12 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:13 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:13 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:14 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:14 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:15 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 3:15 AM",
                "value": 0.1120000034570694
            },
            {
                "time": "Tue, Apr 9, 3:16 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 3:16 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:17 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:17 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 3:18 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 3:18 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 3:19 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 3:19 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:20 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:20 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:21 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:21 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 3:22 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:22 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:23 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 3:23 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:24 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:24 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:25 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:25 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:26 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:26 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:27 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:27 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:28 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 3:28 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:29 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:29 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:30 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:30 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:31 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:31 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 3:32 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:32 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:33 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:33 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:34 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:34 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:35 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:35 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:36 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 3:36 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:37 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:37 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:38 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:38 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:39 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:39 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:40 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:40 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:41 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 3:41 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 3:42 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 3:42 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:43 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:43 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 3:44 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:44 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:45 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 3:45 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:46 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 3:46 AM",
                "value": 0.1120000034570694
            },
            {
                "time": "Tue, Apr 9, 3:47 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:47 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 3:48 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:48 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 3:49 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:49 AM",
                "value": 0.1120000034570694
            },
            {
                "time": "Tue, Apr 9, 3:50 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:50 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:51 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 3:51 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 3:52 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:52 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:53 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:53 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 3:54 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:54 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:55 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 3:55 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:56 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:56 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:57 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 3:57 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 3:58 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:58 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 3:59 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 3:59 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:00 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:00 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 4:01 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 4:01 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:02 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 4:02 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 4:03 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:03 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:04 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:04 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 4:05 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:05 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:06 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:06 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:07 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:07 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:08 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:08 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 4:09 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:09 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:10 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:10 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:11 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:11 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:12 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 4:12 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:13 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:13 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 4:14 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:14 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 4:15 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:15 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 4:16 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:16 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:17 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:17 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 4:18 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:18 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:19 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:19 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:20 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:20 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 4:21 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 4:21 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:22 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:22 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 4:23 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:23 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:24 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:24 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:25 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 4:25 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:26 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 4:26 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:27 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:27 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:28 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:28 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:29 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:29 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:30 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:30 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:31 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:31 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 4:32 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:32 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:33 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:33 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:34 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 4:34 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:35 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:35 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:36 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:36 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 4:37 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 4:37 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 4:38 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:38 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:39 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:39 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:40 AM",
                "value": 0.1120000034570694
            },
            {
                "time": "Tue, Apr 9, 4:40 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 4:41 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 4:41 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:42 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:42 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:43 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:43 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 4:44 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:44 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 4:45 AM",
                "value": 0.1120000034570694
            },
            {
                "time": "Tue, Apr 9, 4:45 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 4:46 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 4:46 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:47 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:47 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:48 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:48 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:49 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:49 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:50 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 4:50 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:51 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 4:51 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 4:52 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:52 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 4:53 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:53 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:54 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 4:54 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:55 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:55 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 4:56 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 4:56 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:57 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:57 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:58 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:58 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:59 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 4:59 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:00 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:00 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:01 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:01 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:02 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:02 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:03 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:03 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:04 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:04 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 5:05 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:05 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:06 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:06 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:07 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:07 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:08 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:08 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 5:09 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:09 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:10 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:10 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:11 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:11 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:12 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:12 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:13 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:13 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:14 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:14 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:15 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:15 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:16 AM",
                "value": -0.1120000034570694
            },
            {
                "time": "Tue, Apr 9, 5:16 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 5:17 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:17 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 5:18 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:18 AM",
                "value": -0.1120000034570694
            },
            {
                "time": "Tue, Apr 9, 5:19 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:19 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 5:20 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 5:20 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 5:21 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:21 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:22 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 5:22 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:23 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:23 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 5:24 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:24 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:25 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:25 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:26 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:26 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 5:27 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 5:27 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:28 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:28 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:29 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:29 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:30 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:30 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:31 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:31 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:32 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:32 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 5:33 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:33 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:34 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:34 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:35 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:35 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:36 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:36 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:37 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:37 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:38 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:38 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:39 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 5:39 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:40 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 5:40 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:41 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:41 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:42 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:42 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:43 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:43 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 5:44 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:44 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 5:45 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:45 AM",
                "value": -0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 5:46 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:46 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 5:47 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:47 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:48 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:48 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:49 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:49 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 5:50 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:50 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:51 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:51 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:52 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:52 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:53 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:53 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:54 AM",
                "value": 0.0560000017285347
            },
            {
                "time": "Tue, Apr 9, 5:54 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:55 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:55 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:56 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:56 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:57 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:57 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:58 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:58 AM",
                "value": 0
            },
            {
                "time": "Tue, Apr 9, 5:59 AM",
                "value": 0.1120000034570694
            },
            {
                "time": "Tue, Apr 9, 5:59 AM",
                "value": 0
            }
        ],
        "temp": [
            {
                "time": "Mon, Apr 8, 6:00 AM",
                "value": 47.7599983215332
            },
            {
                "time": "Mon, Apr 8, 6:00 AM",
                "value": 47.619998931884766
            },
            {
                "time": "Mon, Apr 8, 6:01 AM",
                "value": 47.79999923706055
            },
            {
                "time": "Mon, Apr 8, 6:01 AM",
                "value": 47.58000183105469
            },
            {
                "time": "Mon, Apr 8, 6:02 AM",
                "value": 47.70000076293945
            },
            {
                "time": "Mon, Apr 8, 6:02 AM",
                "value": 47.59000015258789
            },
            {
                "time": "Mon, Apr 8, 6:03 AM",
                "value": 47.59000015258789
            },
            {
                "time": "Mon, Apr 8, 6:03 AM",
                "value": 47.61000061035156
            },
            {
                "time": "Mon, Apr 8, 6:04 AM",
                "value": 47.66999816894531
            },
            {
                "time": "Mon, Apr 8, 6:04 AM",
                "value": 47.58000183105469
            },
            {
                "time": "Mon, Apr 8, 6:05 AM",
                "value": 47.68000030517578
            },
            {
                "time": "Mon, Apr 8, 6:05 AM",
                "value": 47.66999816894531
            },
            {
                "time": "Mon, Apr 8, 6:06 AM",
                "value": 47.59000015258789
            },
            {
                "time": "Mon, Apr 8, 6:06 AM",
                "value": 47.63999938964844
            },
            {
                "time": "Mon, Apr 8, 6:07 AM",
                "value": 47.59000015258789
            },
            {
                "time": "Mon, Apr 8, 6:07 AM",
                "value": 47.59000015258789
            },
            {
                "time": "Mon, Apr 8, 6:08 AM",
                "value": 47.68000030517578
            },
            {
                "time": "Mon, Apr 8, 6:08 AM",
                "value": 47.59000015258789
            },
            {
                "time": "Mon, Apr 8, 6:09 AM",
                "value": 47.66999816894531
            },
            {
                "time": "Mon, Apr 8, 6:09 AM",
                "value": 47.66999816894531
            },
            {
                "time": "Mon, Apr 8, 6:10 AM",
                "value": 47.63999938964844
            },
            {
                "time": "Mon, Apr 8, 6:10 AM",
                "value": 47.59000015258789
            },
            {
                "time": "Mon, Apr 8, 6:11 AM",
                "value": 47.59000015258789
            },
            {
                "time": "Mon, Apr 8, 6:11 AM",
                "value": 47.63999938964844
            },
            {
                "time": "Mon, Apr 8, 6:12 AM",
                "value": 47.59000015258789
            },
            {
                "time": "Mon, Apr 8, 6:12 AM",
                "value": 47.59000015258789
            },
            {
                "time": "Mon, Apr 8, 6:13 AM",
                "value": 47.70000076293945
            },
            {
                "time": "Mon, Apr 8, 6:13 AM",
                "value": 47.59000015258789
            },
            {
                "time": "Mon, Apr 8, 6:14 AM",
                "value": 47.66999816894531
            },
            {
                "time": "Mon, Apr 8, 6:14 AM",
                "value": 47.63999938964844
            },
            {
                "time": "Mon, Apr 8, 6:15 AM",
                "value": 47.72999954223633
            },
            {
                "time": "Mon, Apr 8, 6:15 AM",
                "value": 47.709999084472656
            },
            {
                "time": "Mon, Apr 8, 6:16 AM",
                "value": 47.68000030517578
            },
            {
                "time": "Mon, Apr 8, 6:16 AM",
                "value": 47.599998474121094
            },
            {
                "time": "Mon, Apr 8, 6:17 AM",
                "value": 47.68000030517578
            },
            {
                "time": "Mon, Apr 8, 6:17 AM",
                "value": 47.63999938964844
            },
            {
                "time": "Mon, Apr 8, 6:18 AM",
                "value": 47.59000015258789
            },
            {
                "time": "Mon, Apr 8, 6:18 AM",
                "value": 47.54999923706055
            },
            {
                "time": "Mon, Apr 8, 6:19 AM",
                "value": 47.68000030517578
            },
            {
                "time": "Mon, Apr 8, 6:19 AM",
                "value": 47.59000015258789
            },
            {
                "time": "Mon, Apr 8, 6:20 AM",
                "value": 47.68000030517578
            },
            {
                "time": "Mon, Apr 8, 6:20 AM",
                "value": 47.59000015258789
            },
            {
                "time": "Mon, Apr 8, 6:21 AM",
                "value": 47.709999084472656
            },
            {
                "time": "Mon, Apr 8, 6:21 AM",
                "value": 47.58000183105469
            },
            {
                "time": "Mon, Apr 8, 6:22 AM",
                "value": 47.7599983215332
            },
            {
                "time": "Mon, Apr 8, 6:22 AM",
                "value": 47.59000015258789
            },
            {
                "time": "Mon, Apr 8, 6:23 AM",
                "value": 47.68000030517578
            },
            {
                "time": "Mon, Apr 8, 6:23 AM",
                "value": 47.59000015258789
            },
            {
                "time": "Mon, Apr 8, 6:24 AM",
                "value": 47.68000030517578
            },
            {
                "time": "Mon, Apr 8, 6:24 AM",
                "value": 47.59000015258789
            },
            {
                "time": "Mon, Apr 8, 6:25 AM",
                "value": 47.689998626708984
            },
            {
                "time": "Mon, Apr 8, 6:25 AM",
                "value": 47.59000015258789
            },
            {
                "time": "Mon, Apr 8, 6:26 AM",
                "value": 47.68000030517578
            },
            {
                "time": "Mon, Apr 8, 6:26 AM",
                "value": 47.58000183105469
            },
            {
                "time": "Mon, Apr 8, 6:27 AM",
                "value": 47.68000030517578
            },
            {
                "time": "Mon, Apr 8, 6:27 AM",
                "value": 47.56999969482422
            },
            {
                "time": "Mon, Apr 8, 6:28 AM",
                "value": 47.63999938964844
            },
            {
                "time": "Mon, Apr 8, 6:28 AM",
                "value": 47.560001373291016
            },
            {
                "time": "Mon, Apr 8, 6:29 AM",
                "value": 47.650001525878906
            },
            {
                "time": "Mon, Apr 8, 6:29 AM",
                "value": 47.70000076293945
            },
            {
                "time": "Mon, Apr 8, 6:30 AM",
                "value": 47.650001525878906
            },
            {
                "time": "Mon, Apr 8, 6:30 AM",
                "value": 47.58000183105469
            },
            {
                "time": "Mon, Apr 8, 6:31 AM",
                "value": 47.68000030517578
            },
            {
                "time": "Mon, Apr 8, 6:31 AM",
                "value": 47.560001373291016
            },
            {
                "time": "Mon, Apr 8, 6:32 AM",
                "value": 47.650001525878906
            },
            {
                "time": "Mon, Apr 8, 6:32 AM",
                "value": 47.630001068115234
            },
            {
                "time": "Mon, Apr 8, 6:33 AM",
                "value": 47.63999938964844
            },
            {
                "time": "Mon, Apr 8, 6:33 AM",
                "value": 47.59000015258789
            },
            {
                "time": "Mon, Apr 8, 6:34 AM",
                "value": 47.63999938964844
            },
            {
                "time": "Mon, Apr 8, 6:34 AM",
                "value": 47.66999816894531
            },
            {
                "time": "Mon, Apr 8, 6:35 AM",
                "value": 47.58000183105469
            },
            {
                "time": "Mon, Apr 8, 6:35 AM",
                "value": 47.65999984741211
            },
            {
                "time": "Mon, Apr 8, 6:36 AM",
                "value": 47.75
            },
            {
                "time": "Mon, Apr 8, 6:36 AM",
                "value": 47.7400016784668
            },
            {
                "time": "Mon, Apr 8, 6:37 AM",
                "value": 47.790000915527344
            },
            {
                "time": "Mon, Apr 8, 6:37 AM",
                "value": 47.70000076293945
            },
            {
                "time": "Mon, Apr 8, 6:38 AM",
                "value": 47.790000915527344
            },
            {
                "time": "Mon, Apr 8, 6:38 AM",
                "value": 47.7400016784668
            },
            {
                "time": "Mon, Apr 8, 6:39 AM",
                "value": 47.86000061035156
            },
            {
                "time": "Mon, Apr 8, 6:39 AM",
                "value": 47.810001373291016
            },
            {
                "time": "Mon, Apr 8, 6:40 AM",
                "value": 47.93000030517578
            },
            {
                "time": "Mon, Apr 8, 6:40 AM",
                "value": 47.880001068115234
            },
            {
                "time": "Mon, Apr 8, 6:41 AM",
                "value": 47.959999084472656
            },
            {
                "time": "Mon, Apr 8, 6:41 AM",
                "value": 47.880001068115234
            },
            {
                "time": "Mon, Apr 8, 6:42 AM",
                "value": 47.959999084472656
            },
            {
                "time": "Mon, Apr 8, 6:42 AM",
                "value": 47.95000076293945
            },
            {
                "time": "Mon, Apr 8, 6:43 AM",
                "value": 47.970001220703125
            },
            {
                "time": "Mon, Apr 8, 6:43 AM",
                "value": 47.9900016784668
            },
            {
                "time": "Mon, Apr 8, 6:44 AM",
                "value": 47.959999084472656
            },
            {
                "time": "Mon, Apr 8, 6:44 AM",
                "value": 47.91999816894531
            },
            {
                "time": "Mon, Apr 8, 6:45 AM",
                "value": 48.0099983215332
            },
            {
                "time": "Mon, Apr 8, 6:45 AM",
                "value": 47.880001068115234
            },
            {
                "time": "Mon, Apr 8, 6:46 AM",
                "value": 47.959999084472656
            },
            {
                "time": "Mon, Apr 8, 6:46 AM",
                "value": 47.880001068115234
            },
            {
                "time": "Mon, Apr 8, 6:47 AM",
                "value": 48
            },
            {
                "time": "Mon, Apr 8, 6:47 AM",
                "value": 47.939998626708984
            },
            {
                "time": "Mon, Apr 8, 6:48 AM",
                "value": 48
            },
            {
                "time": "Mon, Apr 8, 6:48 AM",
                "value": 47.90999984741211
            },
            {
                "time": "Mon, Apr 8, 6:49 AM",
                "value": 48.029998779296875
            },
            {
                "time": "Mon, Apr 8, 6:49 AM",
                "value": 47.939998626708984
            },
            {
                "time": "Mon, Apr 8, 6:50 AM",
                "value": 48.04999923706055
            },
            {
                "time": "Mon, Apr 8, 6:50 AM",
                "value": 47.959999084472656
            },
            {
                "time": "Mon, Apr 8, 6:51 AM",
                "value": 47.95000076293945
            },
            {
                "time": "Mon, Apr 8, 6:51 AM",
                "value": 47.939998626708984
            },
            {
                "time": "Mon, Apr 8, 6:52 AM",
                "value": 48.040000915527344
            },
            {
                "time": "Mon, Apr 8, 6:52 AM",
                "value": 47.9900016784668
            },
            {
                "time": "Mon, Apr 8, 6:53 AM",
                "value": 48.18000030517578
            },
            {
                "time": "Mon, Apr 8, 6:53 AM",
                "value": 47.9900016784668
            },
            {
                "time": "Mon, Apr 8, 6:54 AM",
                "value": 48.08000183105469
            },
            {
                "time": "Mon, Apr 8, 6:54 AM",
                "value": 47.9900016784668
            },
            {
                "time": "Mon, Apr 8, 6:55 AM",
                "value": 48.08000183105469
            },
            {
                "time": "Mon, Apr 8, 6:55 AM",
                "value": 48
            },
            {
                "time": "Mon, Apr 8, 6:56 AM",
                "value": 48.11000061035156
            },
            {
                "time": "Mon, Apr 8, 6:56 AM",
                "value": 47.9900016784668
            },
            {
                "time": "Mon, Apr 8, 6:57 AM",
                "value": 48.06999969482422
            },
            {
                "time": "Mon, Apr 8, 6:57 AM",
                "value": 48.040000915527344
            },
            {
                "time": "Mon, Apr 8, 6:58 AM",
                "value": 48.040000915527344
            },
            {
                "time": "Mon, Apr 8, 6:58 AM",
                "value": 47.959999084472656
            },
            {
                "time": "Mon, Apr 8, 6:59 AM",
                "value": 48.08000183105469
            },
            {
                "time": "Mon, Apr 8, 6:59 AM",
                "value": 47.9900016784668
            },
            {
                "time": "Mon, Apr 8, 7:00 AM",
                "value": 48.06999969482422
            },
            {
                "time": "Mon, Apr 8, 7:00 AM",
                "value": 47.97999954223633
            },
            {
                "time": "Mon, Apr 8, 7:01 AM",
                "value": 48.11000061035156
            },
            {
                "time": "Mon, Apr 8, 7:01 AM",
                "value": 48.04999923706055
            },
            {
                "time": "Mon, Apr 8, 7:02 AM",
                "value": 48.15999984741211
            },
            {
                "time": "Mon, Apr 8, 7:02 AM",
                "value": 48.04999923706055
            },
            {
                "time": "Mon, Apr 8, 7:03 AM",
                "value": 48.150001525878906
            },
            {
                "time": "Mon, Apr 8, 7:03 AM",
                "value": 48.06999969482422
            },
            {
                "time": "Mon, Apr 8, 7:04 AM",
                "value": 48.15999984741211
            },
            {
                "time": "Mon, Apr 8, 7:04 AM",
                "value": 48.060001373291016
            },
            {
                "time": "Mon, Apr 8, 7:05 AM",
                "value": 48.150001525878906
            },
            {
                "time": "Mon, Apr 8, 7:05 AM",
                "value": 48.04999923706055
            },
            {
                "time": "Mon, Apr 8, 7:06 AM",
                "value": 48.04999923706055
            },
            {
                "time": "Mon, Apr 8, 7:06 AM",
                "value": 48.099998474121094
            },
            {
                "time": "Mon, Apr 8, 7:07 AM",
                "value": 48.130001068115234
            },
            {
                "time": "Mon, Apr 8, 7:07 AM",
                "value": 48.130001068115234
            },
            {
                "time": "Mon, Apr 8, 7:08 AM",
                "value": 48.220001220703125
            },
            {
                "time": "Mon, Apr 8, 7:08 AM",
                "value": 48.13999938964844
            },
            {
                "time": "Mon, Apr 8, 7:09 AM",
                "value": 48.220001220703125
            },
            {
                "time": "Mon, Apr 8, 7:09 AM",
                "value": 48.22999954223633
            },
            {
                "time": "Mon, Apr 8, 7:10 AM",
                "value": 48.220001220703125
            },
            {
                "time": "Mon, Apr 8, 7:10 AM",
                "value": 48.18000030517578
            },
            {
                "time": "Mon, Apr 8, 7:11 AM",
                "value": 48.209999084472656
            },
            {
                "time": "Mon, Apr 8, 7:11 AM",
                "value": 48.13999938964844
            },
            {
                "time": "Mon, Apr 8, 7:12 AM",
                "value": 48.27000045776367
            },
            {
                "time": "Mon, Apr 8, 7:12 AM",
                "value": 48.130001068115234
            },
            {
                "time": "Mon, Apr 8, 7:13 AM",
                "value": 48.25
            },
            {
                "time": "Mon, Apr 8, 7:13 AM",
                "value": 48.16999816894531
            },
            {
                "time": "Mon, Apr 8, 7:14 AM",
                "value": 48.25
            },
            {
                "time": "Mon, Apr 8, 7:14 AM",
                "value": 48.16999816894531
            },
            {
                "time": "Mon, Apr 8, 7:15 AM",
                "value": 48.209999084472656
            },
            {
                "time": "Mon, Apr 8, 7:15 AM",
                "value": 48.209999084472656
            },
            {
                "time": "Mon, Apr 8, 7:16 AM",
                "value": 48.29999923706055
            },
            {
                "time": "Mon, Apr 8, 7:16 AM",
                "value": 48.279998779296875
            },
            {
                "time": "Mon, Apr 8, 7:17 AM",
                "value": 48.33000183105469
            },
            {
                "time": "Mon, Apr 8, 7:17 AM",
                "value": 48.2400016784668
            },
            {
                "time": "Mon, Apr 8, 7:18 AM",
                "value": 48.369998931884766
            },
            {
                "time": "Mon, Apr 8, 7:18 AM",
                "value": 48.27000045776367
            },
            {
                "time": "Mon, Apr 8, 7:19 AM",
                "value": 48.459999084472656
            },
            {
                "time": "Mon, Apr 8, 7:19 AM",
                "value": 48.279998779296875
            },
            {
                "time": "Mon, Apr 8, 7:20 AM",
                "value": 48.36000061035156
            },
            {
                "time": "Mon, Apr 8, 7:20 AM",
                "value": 48.279998779296875
            },
            {
                "time": "Mon, Apr 8, 7:21 AM",
                "value": 48.38999938964844
            },
            {
                "time": "Mon, Apr 8, 7:21 AM",
                "value": 48.279998779296875
            },
            {
                "time": "Mon, Apr 8, 7:22 AM",
                "value": 48.380001068115234
            },
            {
                "time": "Mon, Apr 8, 7:22 AM",
                "value": 48.310001373291016
            },
            {
                "time": "Mon, Apr 8, 7:23 AM",
                "value": 48.349998474121094
            },
            {
                "time": "Mon, Apr 8, 7:23 AM",
                "value": 48.459999084472656
            },
            {
                "time": "Mon, Apr 8, 7:24 AM",
                "value": 48.45000076293945
            },
            {
                "time": "Mon, Apr 8, 7:24 AM",
                "value": 48.36000061035156
            },
            {
                "time": "Mon, Apr 8, 7:25 AM",
                "value": 48.439998626708984
            },
            {
                "time": "Mon, Apr 8, 7:25 AM",
                "value": 48.400001525878906
            },
            {
                "time": "Mon, Apr 8, 7:26 AM",
                "value": 48.470001220703125
            },
            {
                "time": "Mon, Apr 8, 7:26 AM",
                "value": 48.45000076293945
            },
            {
                "time": "Mon, Apr 8, 7:27 AM",
                "value": 48.5099983215332
            },
            {
                "time": "Mon, Apr 8, 7:27 AM",
                "value": 48.41999816894531
            },
            {
                "time": "Mon, Apr 8, 7:28 AM",
                "value": 48.5099983215332
            },
            {
                "time": "Mon, Apr 8, 7:28 AM",
                "value": 48.41999816894531
            },
            {
                "time": "Mon, Apr 8, 7:29 AM",
                "value": 48.5099983215332
            },
            {
                "time": "Mon, Apr 8, 7:29 AM",
                "value": 48.41999816894531
            },
            {
                "time": "Mon, Apr 8, 7:30 AM",
                "value": 48.5099983215332
            },
            {
                "time": "Mon, Apr 8, 7:30 AM",
                "value": 48.41999816894531
            },
            {
                "time": "Mon, Apr 8, 7:31 AM",
                "value": 48.5099983215332
            },
            {
                "time": "Mon, Apr 8, 7:31 AM",
                "value": 48.43000030517578
            },
            {
                "time": "Mon, Apr 8, 7:32 AM",
                "value": 48.5099983215332
            },
            {
                "time": "Mon, Apr 8, 7:32 AM",
                "value": 48.41999816894531
            },
            {
                "time": "Mon, Apr 8, 7:33 AM",
                "value": 48.470001220703125
            },
            {
                "time": "Mon, Apr 8, 7:33 AM",
                "value": 48.380001068115234
            },
            {
                "time": "Mon, Apr 8, 7:34 AM",
                "value": 48.470001220703125
            },
            {
                "time": "Mon, Apr 8, 7:34 AM",
                "value": 48.380001068115234
            },
            {
                "time": "Mon, Apr 8, 7:35 AM",
                "value": 48.470001220703125
            },
            {
                "time": "Mon, Apr 8, 7:35 AM",
                "value": 48.380001068115234
            },
            {
                "time": "Mon, Apr 8, 7:36 AM",
                "value": 48.470001220703125
            },
            {
                "time": "Mon, Apr 8, 7:36 AM",
                "value": 48.38999938964844
            },
            {
                "time": "Mon, Apr 8, 7:37 AM",
                "value": 48.470001220703125
            },
            {
                "time": "Mon, Apr 8, 7:37 AM",
                "value": 48.380001068115234
            },
            {
                "time": "Mon, Apr 8, 7:38 AM",
                "value": 48.470001220703125
            },
            {
                "time": "Mon, Apr 8, 7:38 AM",
                "value": 48.380001068115234
            },
            {
                "time": "Mon, Apr 8, 7:39 AM",
                "value": 48.470001220703125
            },
            {
                "time": "Mon, Apr 8, 7:39 AM",
                "value": 48.38999938964844
            },
            {
                "time": "Mon, Apr 8, 7:40 AM",
                "value": 48.470001220703125
            },
            {
                "time": "Mon, Apr 8, 7:40 AM",
                "value": 48.380001068115234
            },
            {
                "time": "Mon, Apr 8, 7:41 AM",
                "value": 48.470001220703125
            },
            {
                "time": "Mon, Apr 8, 7:41 AM",
                "value": 48.380001068115234
            },
            {
                "time": "Mon, Apr 8, 7:42 AM",
                "value": 48.47999954223633
            },
            {
                "time": "Mon, Apr 8, 7:42 AM",
                "value": 48.459999084472656
            },
            {
                "time": "Mon, Apr 8, 7:43 AM",
                "value": 48.5099983215332
            },
            {
                "time": "Mon, Apr 8, 7:43 AM",
                "value": 48.41999816894531
            },
            {
                "time": "Mon, Apr 8, 7:44 AM",
                "value": 48.5099983215332
            },
            {
                "time": "Mon, Apr 8, 7:44 AM",
                "value": 48.47999954223633
            },
            {
                "time": "Mon, Apr 8, 7:45 AM",
                "value": 48.540000915527344
            },
            {
                "time": "Mon, Apr 8, 7:45 AM",
                "value": 48.41999816894531
            },
            {
                "time": "Mon, Apr 8, 7:46 AM",
                "value": 48.47999954223633
            },
            {
                "time": "Mon, Apr 8, 7:46 AM",
                "value": 48.41999816894531
            },
            {
                "time": "Mon, Apr 8, 7:47 AM",
                "value": 48.52000045776367
            },
            {
                "time": "Mon, Apr 8, 7:47 AM",
                "value": 48.41999816894531
            },
            {
                "time": "Mon, Apr 8, 7:48 AM",
                "value": 48.5099983215332
            },
            {
                "time": "Mon, Apr 8, 7:48 AM",
                "value": 48.41999816894531
            },
            {
                "time": "Mon, Apr 8, 7:49 AM",
                "value": 48.5099983215332
            },
            {
                "time": "Mon, Apr 8, 7:49 AM",
                "value": 48.5
            },
            {
                "time": "Mon, Apr 8, 7:50 AM",
                "value": 48.54999923706055
            },
            {
                "time": "Mon, Apr 8, 7:50 AM",
                "value": 48.45000076293945
            },
            {
                "time": "Mon, Apr 8, 7:51 AM",
                "value": 48.59000015258789
            },
            {
                "time": "Mon, Apr 8, 7:51 AM",
                "value": 48.5
            },
            {
                "time": "Mon, Apr 8, 7:52 AM",
                "value": 48.58000183105469
            },
            {
                "time": "Mon, Apr 8, 7:52 AM",
                "value": 48.5
            },
            {
                "time": "Mon, Apr 8, 7:53 AM",
                "value": 48.56999969482422
            },
            {
                "time": "Mon, Apr 8, 7:53 AM",
                "value": 48.5
            },
            {
                "time": "Mon, Apr 8, 7:54 AM",
                "value": 48.54999923706055
            },
            {
                "time": "Mon, Apr 8, 7:54 AM",
                "value": 48.540000915527344
            },
            {
                "time": "Mon, Apr 8, 7:55 AM",
                "value": 48.56999969482422
            },
            {
                "time": "Mon, Apr 8, 7:55 AM",
                "value": 48.4900016784668
            },
            {
                "time": "Mon, Apr 8, 7:56 AM",
                "value": 48.619998931884766
            },
            {
                "time": "Mon, Apr 8, 7:56 AM",
                "value": 48.61000061035156
            },
            {
                "time": "Mon, Apr 8, 7:57 AM",
                "value": 48.68000030517578
            },
            {
                "time": "Mon, Apr 8, 7:57 AM",
                "value": 48.529998779296875
            },
            {
                "time": "Mon, Apr 8, 7:58 AM",
                "value": 48.65999984741211
            },
            {
                "time": "Mon, Apr 8, 7:58 AM",
                "value": 48.58000183105469
            },
            {
                "time": "Mon, Apr 8, 7:59 AM",
                "value": 48.65999984741211
            },
            {
                "time": "Mon, Apr 8, 7:59 AM",
                "value": 48.61000061035156
            },
            {
                "time": "Mon, Apr 8, 8:00 AM",
                "value": 48.689998626708984
            },
            {
                "time": "Mon, Apr 8, 8:00 AM",
                "value": 48.63999938964844
            },
            {
                "time": "Mon, Apr 8, 8:01 AM",
                "value": 48.72999954223633
            },
            {
                "time": "Mon, Apr 8, 8:01 AM",
                "value": 48.709999084472656
            },
            {
                "time": "Mon, Apr 8, 8:02 AM",
                "value": 48.720001220703125
            },
            {
                "time": "Mon, Apr 8, 8:02 AM",
                "value": 48.68000030517578
            },
            {
                "time": "Mon, Apr 8, 8:03 AM",
                "value": 48.7599983215332
            },
            {
                "time": "Mon, Apr 8, 8:03 AM",
                "value": 48.68000030517578
            },
            {
                "time": "Mon, Apr 8, 8:04 AM",
                "value": 48.779998779296875
            },
            {
                "time": "Mon, Apr 8, 8:04 AM",
                "value": 48.720001220703125
            },
            {
                "time": "Mon, Apr 8, 8:05 AM",
                "value": 48.79999923706055
            },
            {
                "time": "Mon, Apr 8, 8:05 AM",
                "value": 48.79999923706055
            },
            {
                "time": "Mon, Apr 8, 8:06 AM",
                "value": 48.79999923706055
            },
            {
                "time": "Mon, Apr 8, 8:06 AM",
                "value": 48.709999084472656
            },
            {
                "time": "Mon, Apr 8, 8:07 AM",
                "value": 48.83000183105469
            },
            {
                "time": "Mon, Apr 8, 8:07 AM",
                "value": 48.75
            },
            {
                "time": "Mon, Apr 8, 8:08 AM",
                "value": 48.880001068115234
            },
            {
                "time": "Mon, Apr 8, 8:08 AM",
                "value": 48.779998779296875
            },
            {
                "time": "Mon, Apr 8, 8:09 AM",
                "value": 48.90999984741211
            },
            {
                "time": "Mon, Apr 8, 8:09 AM",
                "value": 48.849998474121094
            },
            {
                "time": "Mon, Apr 8, 8:10 AM",
                "value": 48.88999938964844
            },
            {
                "time": "Mon, Apr 8, 8:10 AM",
                "value": 48.900001525878906
            },
            {
                "time": "Mon, Apr 8, 8:11 AM",
                "value": 48.97999954223633
            },
            {
                "time": "Mon, Apr 8, 8:11 AM",
                "value": 48.88999938964844
            },
            {
                "time": "Mon, Apr 8, 8:12 AM",
                "value": 49.02000045776367
            },
            {
                "time": "Mon, Apr 8, 8:12 AM",
                "value": 48.93000030517578
            },
            {
                "time": "Mon, Apr 8, 8:13 AM",
                "value": 49.060001373291016
            },
            {
                "time": "Mon, Apr 8, 8:13 AM",
                "value": 49.06999969482422
            },
            {
                "time": "Mon, Apr 8, 8:14 AM",
                "value": 49.09000015258789
            },
            {
                "time": "Mon, Apr 8, 8:14 AM",
                "value": 49
            },
            {
                "time": "Mon, Apr 8, 8:15 AM",
                "value": 49.130001068115234
            },
            {
                "time": "Mon, Apr 8, 8:15 AM",
                "value": 49.029998779296875
            },
            {
                "time": "Mon, Apr 8, 8:16 AM",
                "value": 49.27000045776367
            },
            {
                "time": "Mon, Apr 8, 8:16 AM",
                "value": 49.06999969482422
            },
            {
                "time": "Mon, Apr 8, 8:17 AM",
                "value": 49.20000076293945
            },
            {
                "time": "Mon, Apr 8, 8:17 AM",
                "value": 49.11000061035156
            },
            {
                "time": "Mon, Apr 8, 8:18 AM",
                "value": 49.15999984741211
            },
            {
                "time": "Mon, Apr 8, 8:18 AM",
                "value": 49.209999084472656
            },
            {
                "time": "Mon, Apr 8, 8:19 AM",
                "value": 49.2400016784668
            },
            {
                "time": "Mon, Apr 8, 8:19 AM",
                "value": 49.18000030517578
            },
            {
                "time": "Mon, Apr 8, 8:20 AM",
                "value": 49.33000183105469
            },
            {
                "time": "Mon, Apr 8, 8:20 AM",
                "value": 49.290000915527344
            },
            {
                "time": "Mon, Apr 8, 8:21 AM",
                "value": 49.380001068115234
            },
            {
                "time": "Mon, Apr 8, 8:21 AM",
                "value": 49.33000183105469
            },
            {
                "time": "Mon, Apr 8, 8:22 AM",
                "value": 49.45000076293945
            },
            {
                "time": "Mon, Apr 8, 8:22 AM",
                "value": 49.400001525878906
            },
            {
                "time": "Mon, Apr 8, 8:23 AM",
                "value": 49.59000015258789
            },
            {
                "time": "Mon, Apr 8, 8:23 AM",
                "value": 49.43000030517578
            },
            {
                "time": "Mon, Apr 8, 8:24 AM",
                "value": 49.52000045776367
            },
            {
                "time": "Mon, Apr 8, 8:24 AM",
                "value": 49.47999954223633
            },
            {
                "time": "Mon, Apr 8, 8:25 AM",
                "value": 49.59000015258789
            },
            {
                "time": "Mon, Apr 8, 8:25 AM",
                "value": 49.54999923706055
            },
            {
                "time": "Mon, Apr 8, 8:26 AM",
                "value": 49.630001068115234
            },
            {
                "time": "Mon, Apr 8, 8:26 AM",
                "value": 49.65999984741211
            },
            {
                "time": "Mon, Apr 8, 8:27 AM",
                "value": 49.70000076293945
            },
            {
                "time": "Mon, Apr 8, 8:27 AM",
                "value": 49.63999938964844
            },
            {
                "time": "Mon, Apr 8, 8:28 AM",
                "value": 49.7400016784668
            },
            {
                "time": "Mon, Apr 8, 8:28 AM",
                "value": 49.77000045776367
            },
            {
                "time": "Mon, Apr 8, 8:29 AM",
                "value": 49.810001373291016
            },
            {
                "time": "Mon, Apr 8, 8:29 AM",
                "value": 49.72999954223633
            },
            {
                "time": "Mon, Apr 8, 8:30 AM",
                "value": 49.86000061035156
            },
            {
                "time": "Mon, Apr 8, 8:30 AM",
                "value": 49.79999923706055
            },
            {
                "time": "Mon, Apr 8, 8:31 AM",
                "value": 49.90999984741211
            },
            {
                "time": "Mon, Apr 8, 8:31 AM",
                "value": 49.880001068115234
            },
            {
                "time": "Mon, Apr 8, 8:32 AM",
                "value": 50.0099983215332
            },
            {
                "time": "Mon, Apr 8, 8:32 AM",
                "value": 49.93000030517578
            },
            {
                "time": "Mon, Apr 8, 8:33 AM",
                "value": 50.029998779296875
            },
            {
                "time": "Mon, Apr 8, 8:33 AM",
                "value": 49.939998626708984
            },
            {
                "time": "Mon, Apr 8, 8:34 AM",
                "value": 50.0099983215332
            },
            {
                "time": "Mon, Apr 8, 8:34 AM",
                "value": 49.9900016784668
            },
            {
                "time": "Mon, Apr 8, 8:35 AM",
                "value": 50.099998474121094
            },
            {
                "time": "Mon, Apr 8, 8:35 AM",
                "value": 50.099998474121094
            },
            {
                "time": "Mon, Apr 8, 8:36 AM",
                "value": 50.189998626708984
            },
            {
                "time": "Mon, Apr 8, 8:36 AM",
                "value": 50.08000183105469
            },
            {
                "time": "Mon, Apr 8, 8:37 AM",
                "value": 50.220001220703125
            },
            {
                "time": "Mon, Apr 8, 8:37 AM",
                "value": 50.150001525878906
            },
            {
                "time": "Mon, Apr 8, 8:38 AM",
                "value": 50.279998779296875
            },
            {
                "time": "Mon, Apr 8, 8:38 AM",
                "value": 50.22999954223633
            },
            {
                "time": "Mon, Apr 8, 8:39 AM",
                "value": 50.439998626708984
            },
            {
                "time": "Mon, Apr 8, 8:39 AM",
                "value": 50.369998931884766
            },
            {
                "time": "Mon, Apr 8, 8:40 AM",
                "value": 50.459999084472656
            },
            {
                "time": "Mon, Apr 8, 8:40 AM",
                "value": 50.40999984741211
            },
            {
                "time": "Mon, Apr 8, 8:41 AM",
                "value": 50.5
            },
            {
                "time": "Mon, Apr 8, 8:41 AM",
                "value": 50.4900016784668
            },
            {
                "time": "Mon, Apr 8, 8:42 AM",
                "value": 50.63999938964844
            },
            {
                "time": "Mon, Apr 8, 8:42 AM",
                "value": 50.54999923706055
            },
            {
                "time": "Mon, Apr 8, 8:43 AM",
                "value": 50.68000030517578
            },
            {
                "time": "Mon, Apr 8, 8:43 AM",
                "value": 50.630001068115234
            },
            {
                "time": "Mon, Apr 8, 8:44 AM",
                "value": 50.7599983215332
            },
            {
                "time": "Mon, Apr 8, 8:44 AM",
                "value": 50.72999954223633
            },
            {
                "time": "Mon, Apr 8, 8:45 AM",
                "value": 50.86000061035156
            },
            {
                "time": "Mon, Apr 8, 8:45 AM",
                "value": 50.810001373291016
            },
            {
                "time": "Mon, Apr 8, 8:46 AM",
                "value": 50.93000030517578
            },
            {
                "time": "Mon, Apr 8, 8:46 AM",
                "value": 50.880001068115234
            },
            {
                "time": "Mon, Apr 8, 8:47 AM",
                "value": 51.0099983215332
            },
            {
                "time": "Mon, Apr 8, 8:47 AM",
                "value": 51
            },
            {
                "time": "Mon, Apr 8, 8:48 AM",
                "value": 51.04999923706055
            },
            {
                "time": "Mon, Apr 8, 8:48 AM",
                "value": 51.06999969482422
            },
            {
                "time": "Mon, Apr 8, 8:49 AM",
                "value": 51.11000061035156
            },
            {
                "time": "Mon, Apr 8, 8:49 AM",
                "value": 51.11000061035156
            },
            {
                "time": "Mon, Apr 8, 8:50 AM",
                "value": 51.189998626708984
            },
            {
                "time": "Mon, Apr 8, 8:50 AM",
                "value": 51.13999938964844
            },
            {
                "time": "Mon, Apr 8, 8:51 AM",
                "value": 51.220001220703125
            },
            {
                "time": "Mon, Apr 8, 8:51 AM",
                "value": 51.16999816894531
            },
            {
                "time": "Mon, Apr 8, 8:52 AM",
                "value": 51.290000915527344
            },
            {
                "time": "Mon, Apr 8, 8:52 AM",
                "value": 51.33000183105469
            },
            {
                "time": "Mon, Apr 8, 8:53 AM",
                "value": 51.380001068115234
            },
            {
                "time": "Mon, Apr 8, 8:53 AM",
                "value": 51.34000015258789
            },
            {
                "time": "Mon, Apr 8, 8:54 AM",
                "value": 51.43000030517578
            },
            {
                "time": "Mon, Apr 8, 8:54 AM",
                "value": 51.38999938964844
            },
            {
                "time": "Mon, Apr 8, 8:55 AM",
                "value": 51.5099983215332
            },
            {
                "time": "Mon, Apr 8, 8:55 AM",
                "value": 51.459999084472656
            },
            {
                "time": "Mon, Apr 8, 8:56 AM",
                "value": 51.650001525878906
            },
            {
                "time": "Mon, Apr 8, 8:56 AM",
                "value": 51.529998779296875
            },
            {
                "time": "Mon, Apr 8, 8:57 AM",
                "value": 51.65999984741211
            },
            {
                "time": "Mon, Apr 8, 8:57 AM",
                "value": 51.56999969482422
            },
            {
                "time": "Mon, Apr 8, 8:58 AM",
                "value": 51.599998474121094
            },
            {
                "time": "Mon, Apr 8, 8:58 AM",
                "value": 51.70000076293945
            },
            {
                "time": "Mon, Apr 8, 8:59 AM",
                "value": 51.68000030517578
            },
            {
                "time": "Mon, Apr 8, 8:59 AM",
                "value": 51.77000045776367
            },
            {
                "time": "Mon, Apr 8, 9:00 AM",
                "value": 51.86000061035156
            },
            {
                "time": "Mon, Apr 8, 9:00 AM",
                "value": 51.810001373291016
            },
            {
                "time": "Mon, Apr 8, 9:01 AM",
                "value": 51.90999984741211
            },
            {
                "time": "Mon, Apr 8, 9:01 AM",
                "value": 51.86000061035156
            },
            {
                "time": "Mon, Apr 8, 9:02 AM",
                "value": 52.0099983215332
            },
            {
                "time": "Mon, Apr 8, 9:02 AM",
                "value": 51.959999084472656
            },
            {
                "time": "Mon, Apr 8, 9:03 AM",
                "value": 52.15999984741211
            },
            {
                "time": "Mon, Apr 8, 9:03 AM",
                "value": 52
            },
            {
                "time": "Mon, Apr 8, 9:04 AM",
                "value": 52.08000183105469
            },
            {
                "time": "Mon, Apr 8, 9:04 AM",
                "value": 52.06999969482422
            },
            {
                "time": "Mon, Apr 8, 9:05 AM",
                "value": 52.130001068115234
            },
            {
                "time": "Mon, Apr 8, 9:05 AM",
                "value": 52.18000030517578
            },
            {
                "time": "Mon, Apr 8, 9:06 AM",
                "value": 52.220001220703125
            },
            {
                "time": "Mon, Apr 8, 9:06 AM",
                "value": 52.31999969482422
            },
            {
                "time": "Mon, Apr 8, 9:07 AM",
                "value": 52.38999938964844
            },
            {
                "time": "Mon, Apr 8, 9:07 AM",
                "value": 52.33000183105469
            },
            {
                "time": "Mon, Apr 8, 9:08 AM",
                "value": 52.45000076293945
            },
            {
                "time": "Mon, Apr 8, 9:08 AM",
                "value": 52.400001525878906
            },
            {
                "time": "Mon, Apr 8, 9:09 AM",
                "value": 52.52000045776367
            },
            {
                "time": "Mon, Apr 8, 9:09 AM",
                "value": 52.5
            },
            {
                "time": "Mon, Apr 8, 9:10 AM",
                "value": 52.560001373291016
            },
            {
                "time": "Mon, Apr 8, 9:10 AM",
                "value": 52.5
            },
            {
                "time": "Mon, Apr 8, 9:11 AM",
                "value": 52.630001068115234
            },
            {
                "time": "Mon, Apr 8, 9:11 AM",
                "value": 52.58000183105469
            },
            {
                "time": "Mon, Apr 8, 9:12 AM",
                "value": 52.7400016784668
            },
            {
                "time": "Mon, Apr 8, 9:12 AM",
                "value": 52.77000045776367
            },
            {
                "time": "Mon, Apr 8, 9:13 AM",
                "value": 52.90999984741211
            },
            {
                "time": "Mon, Apr 8, 9:13 AM",
                "value": 52.79999923706055
            },
            {
                "time": "Mon, Apr 8, 9:14 AM",
                "value": 52.83000183105469
            },
            {
                "time": "Mon, Apr 8, 9:14 AM",
                "value": 52.869998931884766
            },
            {
                "time": "Mon, Apr 8, 9:15 AM",
                "value": 53.040000915527344
            },
            {
                "time": "Mon, Apr 8, 9:15 AM",
                "value": 52.97999954223633
            },
            {
                "time": "Mon, Apr 8, 9:16 AM",
                "value": 53.06999969482422
            },
            {
                "time": "Mon, Apr 8, 9:16 AM",
                "value": 53.02000045776367
            },
            {
                "time": "Mon, Apr 8, 9:17 AM",
                "value": 53.16999816894531
            },
            {
                "time": "Mon, Apr 8, 9:17 AM",
                "value": 53.09000015258789
            },
            {
                "time": "Mon, Apr 8, 9:18 AM",
                "value": 53.209999084472656
            },
            {
                "time": "Mon, Apr 8, 9:18 AM",
                "value": 53.20000076293945
            },
            {
                "time": "Mon, Apr 8, 9:19 AM",
                "value": 53.279998779296875
            },
            {
                "time": "Mon, Apr 8, 9:19 AM",
                "value": 53.290000915527344
            },
            {
                "time": "Mon, Apr 8, 9:20 AM",
                "value": 53.36000061035156
            },
            {
                "time": "Mon, Apr 8, 9:20 AM",
                "value": 53.33000183105469
            },
            {
                "time": "Mon, Apr 8, 9:21 AM",
                "value": 53.470001220703125
            },
            {
                "time": "Mon, Apr 8, 9:21 AM",
                "value": 53.40999984741211
            },
            {
                "time": "Mon, Apr 8, 9:22 AM",
                "value": 53.5
            },
            {
                "time": "Mon, Apr 8, 9:22 AM",
                "value": 53.439998626708984
            },
            {
                "time": "Mon, Apr 8, 9:23 AM",
                "value": 53.4900016784668
            },
            {
                "time": "Mon, Apr 8, 9:23 AM",
                "value": 53.52000045776367
            },
            {
                "time": "Mon, Apr 8, 9:24 AM",
                "value": 53.63999938964844
            },
            {
                "time": "Mon, Apr 8, 9:24 AM",
                "value": 53.59000015258789
            },
            {
                "time": "Mon, Apr 8, 9:25 AM",
                "value": 53.72999954223633
            },
            {
                "time": "Mon, Apr 8, 9:25 AM",
                "value": 53.720001220703125
            },
            {
                "time": "Mon, Apr 8, 9:26 AM",
                "value": 53.72999954223633
            },
            {
                "time": "Mon, Apr 8, 9:26 AM",
                "value": 53.63999938964844
            },
            {
                "time": "Mon, Apr 8, 9:27 AM",
                "value": 53.77000045776367
            },
            {
                "time": "Mon, Apr 8, 9:27 AM",
                "value": 53.65999984741211
            },
            {
                "time": "Mon, Apr 8, 9:28 AM",
                "value": 53.77000045776367
            },
            {
                "time": "Mon, Apr 8, 9:28 AM",
                "value": 53.709999084472656
            },
            {
                "time": "Mon, Apr 8, 9:29 AM",
                "value": 53.86000061035156
            },
            {
                "time": "Mon, Apr 8, 9:29 AM",
                "value": 53.7599983215332
            },
            {
                "time": "Mon, Apr 8, 9:30 AM",
                "value": 53.7400016784668
            },
            {
                "time": "Mon, Apr 8, 9:30 AM",
                "value": 53.77000045776367
            },
            {
                "time": "Mon, Apr 8, 9:31 AM",
                "value": 53.869998931884766
            },
            {
                "time": "Mon, Apr 8, 9:31 AM",
                "value": 53.93000030517578
            },
            {
                "time": "Mon, Apr 8, 9:32 AM",
                "value": 53.95000076293945
            },
            {
                "time": "Mon, Apr 8, 9:32 AM",
                "value": 53.959999084472656
            },
            {
                "time": "Mon, Apr 8, 9:33 AM",
                "value": 53.9900016784668
            },
            {
                "time": "Mon, Apr 8, 9:33 AM",
                "value": 53.91999816894531
            },
            {
                "time": "Mon, Apr 8, 9:34 AM",
                "value": 54.040000915527344
            },
            {
                "time": "Mon, Apr 8, 9:34 AM",
                "value": 53.9900016784668
            },
            {
                "time": "Mon, Apr 8, 9:35 AM",
                "value": 54.150001525878906
            },
            {
                "time": "Mon, Apr 8, 9:35 AM",
                "value": 54.099998474121094
            },
            {
                "time": "Mon, Apr 8, 9:36 AM",
                "value": 54.22999954223633
            },
            {
                "time": "Mon, Apr 8, 9:36 AM",
                "value": 54.16999816894531
            },
            {
                "time": "Mon, Apr 8, 9:37 AM",
                "value": 54.2599983215332
            },
            {
                "time": "Mon, Apr 8, 9:37 AM",
                "value": 54.2400016784668
            },
            {
                "time": "Mon, Apr 8, 9:38 AM",
                "value": 54.25
            },
            {
                "time": "Mon, Apr 8, 9:38 AM",
                "value": 54.310001373291016
            },
            {
                "time": "Mon, Apr 8, 9:39 AM",
                "value": 54.369998931884766
            },
            {
                "time": "Mon, Apr 8, 9:39 AM",
                "value": 54.310001373291016
            },
            {
                "time": "Mon, Apr 8, 9:40 AM",
                "value": 54.400001525878906
            },
            {
                "time": "Mon, Apr 8, 9:40 AM",
                "value": 54.400001525878906
            },
            {
                "time": "Mon, Apr 8, 9:41 AM",
                "value": 54.439998626708984
            },
            {
                "time": "Mon, Apr 8, 9:41 AM",
                "value": 54.349998474121094
            },
            {
                "time": "Mon, Apr 8, 9:42 AM",
                "value": 54.47999954223633
            },
            {
                "time": "Mon, Apr 8, 9:42 AM",
                "value": 54.41999816894531
            },
            {
                "time": "Mon, Apr 8, 9:43 AM",
                "value": 54.52000045776367
            },
            {
                "time": "Mon, Apr 8, 9:43 AM",
                "value": 54.459999084472656
            },
            {
                "time": "Mon, Apr 8, 9:44 AM",
                "value": 54.61000061035156
            },
            {
                "time": "Mon, Apr 8, 9:44 AM",
                "value": 54.56999969482422
            },
            {
                "time": "Mon, Apr 8, 9:45 AM",
                "value": 54.720001220703125
            },
            {
                "time": "Mon, Apr 8, 9:45 AM",
                "value": 54.70000076293945
            },
            {
                "time": "Mon, Apr 8, 9:46 AM",
                "value": 54.810001373291016
            },
            {
                "time": "Mon, Apr 8, 9:46 AM",
                "value": 54.709999084472656
            },
            {
                "time": "Mon, Apr 8, 9:47 AM",
                "value": 54.849998474121094
            },
            {
                "time": "Mon, Apr 8, 9:47 AM",
                "value": 54.810001373291016
            },
            {
                "time": "Mon, Apr 8, 9:48 AM",
                "value": 54.790000915527344
            },
            {
                "time": "Mon, Apr 8, 9:48 AM",
                "value": 54.86000061035156
            },
            {
                "time": "Mon, Apr 8, 9:49 AM",
                "value": 54.959999084472656
            },
            {
                "time": "Mon, Apr 8, 9:49 AM",
                "value": 55.060001373291016
            },
            {
                "time": "Mon, Apr 8, 9:50 AM",
                "value": 55.09000015258789
            },
            {
                "time": "Mon, Apr 8, 9:50 AM",
                "value": 55.08000183105469
            },
            {
                "time": "Mon, Apr 8, 9:51 AM",
                "value": 55.2400016784668
            },
            {
                "time": "Mon, Apr 8, 9:51 AM",
                "value": 55.18000030517578
            },
            {
                "time": "Mon, Apr 8, 9:52 AM",
                "value": 55.369998931884766
            },
            {
                "time": "Mon, Apr 8, 9:52 AM",
                "value": 55.40999984741211
            },
            {
                "time": "Mon, Apr 8, 9:53 AM",
                "value": 55.459999084472656
            },
            {
                "time": "Mon, Apr 8, 9:53 AM",
                "value": 55.439998626708984
            },
            {
                "time": "Mon, Apr 8, 9:54 AM",
                "value": 55.58000183105469
            },
            {
                "time": "Mon, Apr 8, 9:54 AM",
                "value": 55.58000183105469
            },
            {
                "time": "Mon, Apr 8, 9:55 AM",
                "value": 55.779998779296875
            },
            {
                "time": "Mon, Apr 8, 9:55 AM",
                "value": 55.65999984741211
            },
            {
                "time": "Mon, Apr 8, 9:56 AM",
                "value": 55.77000045776367
            },
            {
                "time": "Mon, Apr 8, 9:56 AM",
                "value": 55.689998626708984
            },
            {
                "time": "Mon, Apr 8, 9:57 AM",
                "value": 55.7599983215332
            },
            {
                "time": "Mon, Apr 8, 9:57 AM",
                "value": 55.689998626708984
            },
            {
                "time": "Mon, Apr 8, 9:58 AM",
                "value": 55.79999923706055
            },
            {
                "time": "Mon, Apr 8, 9:58 AM",
                "value": 55.72999954223633
            },
            {
                "time": "Mon, Apr 8, 9:59 AM",
                "value": 55.849998474121094
            },
            {
                "time": "Mon, Apr 8, 9:59 AM",
                "value": 55.79999923706055
            },
            {
                "time": "Mon, Apr 8, 10:00 AM",
                "value": 55.939998626708984
            },
            {
                "time": "Mon, Apr 8, 10:00 AM",
                "value": 55.849998474121094
            },
            {
                "time": "Mon, Apr 8, 10:01 AM",
                "value": 55.959999084472656
            },
            {
                "time": "Mon, Apr 8, 10:01 AM",
                "value": 55.90999984741211
            },
            {
                "time": "Mon, Apr 8, 10:02 AM",
                "value": 56.060001373291016
            },
            {
                "time": "Mon, Apr 8, 10:02 AM",
                "value": 56.099998474121094
            },
            {
                "time": "Mon, Apr 8, 10:03 AM",
                "value": 56.16999816894531
            },
            {
                "time": "Mon, Apr 8, 10:03 AM",
                "value": 56.119998931884766
            },
            {
                "time": "Mon, Apr 8, 10:04 AM",
                "value": 56.20000076293945
            },
            {
                "time": "Mon, Apr 8, 10:04 AM",
                "value": 56.34000015258789
            },
            {
                "time": "Mon, Apr 8, 10:05 AM",
                "value": 56.34000015258789
            },
            {
                "time": "Mon, Apr 8, 10:05 AM",
                "value": 56.369998931884766
            },
            {
                "time": "Mon, Apr 8, 10:06 AM",
                "value": 56.56999969482422
            },
            {
                "time": "Mon, Apr 8, 10:06 AM",
                "value": 56.59000015258789
            },
            {
                "time": "Mon, Apr 8, 10:07 AM",
                "value": 56.709999084472656
            },
            {
                "time": "Mon, Apr 8, 10:07 AM",
                "value": 56.70000076293945
            },
            {
                "time": "Mon, Apr 8, 10:08 AM",
                "value": 56.86000061035156
            },
            {
                "time": "Mon, Apr 8, 10:08 AM",
                "value": 56.810001373291016
            },
            {
                "time": "Mon, Apr 8, 10:09 AM",
                "value": 56.91999816894531
            },
            {
                "time": "Mon, Apr 8, 10:09 AM",
                "value": 56.880001068115234
            },
            {
                "time": "Mon, Apr 8, 10:10 AM",
                "value": 57.040000915527344
            },
            {
                "time": "Mon, Apr 8, 10:10 AM",
                "value": 56.9900016784668
            },
            {
                "time": "Mon, Apr 8, 10:11 AM",
                "value": 57.11000061035156
            },
            {
                "time": "Mon, Apr 8, 10:11 AM",
                "value": 57.119998931884766
            },
            {
                "time": "Mon, Apr 8, 10:12 AM",
                "value": 57.18000030517578
            },
            {
                "time": "Mon, Apr 8, 10:12 AM",
                "value": 57.130001068115234
            },
            {
                "time": "Mon, Apr 8, 10:13 AM",
                "value": 57.31999969482422
            },
            {
                "time": "Mon, Apr 8, 10:13 AM",
                "value": 57.2400016784668
            },
            {
                "time": "Mon, Apr 8, 10:14 AM",
                "value": 57.40999984741211
            },
            {
                "time": "Mon, Apr 8, 10:14 AM",
                "value": 57.310001373291016
            },
            {
                "time": "Mon, Apr 8, 10:15 AM",
                "value": 57.400001525878906
            },
            {
                "time": "Mon, Apr 8, 10:15 AM",
                "value": 57.349998474121094
            },
            {
                "time": "Mon, Apr 8, 10:16 AM",
                "value": 57.5099983215332
            },
            {
                "time": "Mon, Apr 8, 10:16 AM",
                "value": 57.5
            },
            {
                "time": "Mon, Apr 8, 10:17 AM",
                "value": 57.65999984741211
            },
            {
                "time": "Mon, Apr 8, 10:17 AM",
                "value": 57.63999938964844
            },
            {
                "time": "Mon, Apr 8, 10:18 AM",
                "value": 57.7599983215332
            },
            {
                "time": "Mon, Apr 8, 10:18 AM",
                "value": 57.75
            },
            {
                "time": "Mon, Apr 8, 10:19 AM",
                "value": 57.790000915527344
            },
            {
                "time": "Mon, Apr 8, 10:19 AM",
                "value": 57.86000061035156
            },
            {
                "time": "Mon, Apr 8, 10:20 AM",
                "value": 58.0099983215332
            },
            {
                "time": "Mon, Apr 8, 10:20 AM",
                "value": 57.97999954223633
            },
            {
                "time": "Mon, Apr 8, 10:21 AM",
                "value": 58.209999084472656
            },
            {
                "time": "Mon, Apr 8, 10:21 AM",
                "value": 58.09000015258789
            },
            {
                "time": "Mon, Apr 8, 10:22 AM",
                "value": 58.15999984741211
            },
            {
                "time": "Mon, Apr 8, 10:22 AM",
                "value": 58.130001068115234
            },
            {
                "time": "Mon, Apr 8, 10:23 AM",
                "value": 58.2599983215332
            },
            {
                "time": "Mon, Apr 8, 10:23 AM",
                "value": 58.220001220703125
            },
            {
                "time": "Mon, Apr 8, 10:24 AM",
                "value": 58.34000015258789
            },
            {
                "time": "Mon, Apr 8, 10:24 AM",
                "value": 58.380001068115234
            },
            {
                "time": "Mon, Apr 8, 10:25 AM",
                "value": 58.4900016784668
            },
            {
                "time": "Mon, Apr 8, 10:25 AM",
                "value": 58.47999954223633
            },
            {
                "time": "Mon, Apr 8, 10:26 AM",
                "value": 58.619998931884766
            },
            {
                "time": "Mon, Apr 8, 10:26 AM",
                "value": 58.619998931884766
            },
            {
                "time": "Mon, Apr 8, 10:27 AM",
                "value": 58.79999923706055
            },
            {
                "time": "Mon, Apr 8, 10:27 AM",
                "value": 58.790000915527344
            },
            {
                "time": "Mon, Apr 8, 10:28 AM",
                "value": 59.040000915527344
            },
            {
                "time": "Mon, Apr 8, 10:28 AM",
                "value": 58.959999084472656
            },
            {
                "time": "Mon, Apr 8, 10:29 AM",
                "value": 59.060001373291016
            },
            {
                "time": "Mon, Apr 8, 10:29 AM",
                "value": 59.029998779296875
            },
            {
                "time": "Mon, Apr 8, 10:30 AM",
                "value": 59.16999816894531
            },
            {
                "time": "Mon, Apr 8, 10:30 AM",
                "value": 59.20000076293945
            },
            {
                "time": "Mon, Apr 8, 10:31 AM",
                "value": 59.31999969482422
            },
            {
                "time": "Mon, Apr 8, 10:31 AM",
                "value": 59.29999923706055
            },
            {
                "time": "Mon, Apr 8, 10:32 AM",
                "value": 59.459999084472656
            },
            {
                "time": "Mon, Apr 8, 10:32 AM",
                "value": 59.47999954223633
            },
            {
                "time": "Mon, Apr 8, 10:33 AM",
                "value": 59.630001068115234
            },
            {
                "time": "Mon, Apr 8, 10:33 AM",
                "value": 59.58000183105469
            },
            {
                "time": "Mon, Apr 8, 10:34 AM",
                "value": 59.68000030517578
            },
            {
                "time": "Mon, Apr 8, 10:34 AM",
                "value": 59.63999938964844
            },
            {
                "time": "Mon, Apr 8, 10:35 AM",
                "value": 59.7599983215332
            },
            {
                "time": "Mon, Apr 8, 10:35 AM",
                "value": 59.779998779296875
            },
            {
                "time": "Mon, Apr 8, 10:36 AM",
                "value": 59.970001220703125
            },
            {
                "time": "Mon, Apr 8, 10:36 AM",
                "value": 59.959999084472656
            },
            {
                "time": "Mon, Apr 8, 10:37 AM",
                "value": 60.09000015258789
            },
            {
                "time": "Mon, Apr 8, 10:37 AM",
                "value": 60.060001373291016
            },
            {
                "time": "Mon, Apr 8, 10:38 AM",
                "value": 60.22999954223633
            },
            {
                "time": "Mon, Apr 8, 10:38 AM",
                "value": 60.2400016784668
            },
            {
                "time": "Mon, Apr 8, 10:39 AM",
                "value": 60.47999954223633
            },
            {
                "time": "Mon, Apr 8, 10:39 AM",
                "value": 60.459999084472656
            },
            {
                "time": "Mon, Apr 8, 10:40 AM",
                "value": 60.619998931884766
            },
            {
                "time": "Mon, Apr 8, 10:40 AM",
                "value": 60.599998474121094
            },
            {
                "time": "Mon, Apr 8, 10:41 AM",
                "value": 60.77000045776367
            },
            {
                "time": "Mon, Apr 8, 10:41 AM",
                "value": 60.790000915527344
            },
            {
                "time": "Mon, Apr 8, 10:42 AM",
                "value": 60.939998626708984
            },
            {
                "time": "Mon, Apr 8, 10:42 AM",
                "value": 61.040000915527344
            },
            {
                "time": "Mon, Apr 8, 10:43 AM",
                "value": 61.08000183105469
            },
            {
                "time": "Mon, Apr 8, 10:43 AM",
                "value": 61.11000061035156
            },
            {
                "time": "Mon, Apr 8, 10:44 AM",
                "value": 61.290000915527344
            },
            {
                "time": "Mon, Apr 8, 10:44 AM",
                "value": 61.220001220703125
            },
            {
                "time": "Mon, Apr 8, 10:45 AM",
                "value": 61.34000015258789
            },
            {
                "time": "Mon, Apr 8, 10:45 AM",
                "value": 61.29999923706055
            },
            {
                "time": "Mon, Apr 8, 10:46 AM",
                "value": 61.459999084472656
            },
            {
                "time": "Mon, Apr 8, 10:46 AM",
                "value": 61.400001525878906
            },
            {
                "time": "Mon, Apr 8, 10:47 AM",
                "value": 61.52000045776367
            },
            {
                "time": "Mon, Apr 8, 10:47 AM",
                "value": 61.47999954223633
            },
            {
                "time": "Mon, Apr 8, 10:48 AM",
                "value": 61.599998474121094
            },
            {
                "time": "Mon, Apr 8, 10:48 AM",
                "value": 61.54999923706055
            },
            {
                "time": "Mon, Apr 8, 10:49 AM",
                "value": 61.630001068115234
            },
            {
                "time": "Mon, Apr 8, 10:49 AM",
                "value": 61.61000061035156
            },
            {
                "time": "Mon, Apr 8, 10:50 AM",
                "value": 61.59000015258789
            },
            {
                "time": "Mon, Apr 8, 10:50 AM",
                "value": 61.58000183105469
            },
            {
                "time": "Mon, Apr 8, 10:51 AM",
                "value": 61.619998931884766
            },
            {
                "time": "Mon, Apr 8, 10:51 AM",
                "value": 61.630001068115234
            },
            {
                "time": "Mon, Apr 8, 10:52 AM",
                "value": 61.70000076293945
            },
            {
                "time": "Mon, Apr 8, 10:52 AM",
                "value": 61.61000061035156
            },
            {
                "time": "Mon, Apr 8, 10:53 AM",
                "value": 61.720001220703125
            },
            {
                "time": "Mon, Apr 8, 10:53 AM",
                "value": 61.650001525878906
            },
            {
                "time": "Mon, Apr 8, 10:54 AM",
                "value": 61.7599983215332
            },
            {
                "time": "Mon, Apr 8, 10:54 AM",
                "value": 61.66999816894531
            },
            {
                "time": "Mon, Apr 8, 10:55 AM",
                "value": 61.790000915527344
            },
            {
                "time": "Mon, Apr 8, 10:55 AM",
                "value": 61.70000076293945
            },
            {
                "time": "Mon, Apr 8, 10:56 AM",
                "value": 61.810001373291016
            },
            {
                "time": "Mon, Apr 8, 10:56 AM",
                "value": 61.72999954223633
            },
            {
                "time": "Mon, Apr 8, 10:57 AM",
                "value": 61.81999969482422
            },
            {
                "time": "Mon, Apr 8, 10:57 AM",
                "value": 61.72999954223633
            },
            {
                "time": "Mon, Apr 8, 10:58 AM",
                "value": 61.81999969482422
            },
            {
                "time": "Mon, Apr 8, 10:58 AM",
                "value": 61.81999969482422
            },
            {
                "time": "Mon, Apr 8, 10:59 AM",
                "value": 61.84000015258789
            },
            {
                "time": "Mon, Apr 8, 10:59 AM",
                "value": 61.91999816894531
            },
            {
                "time": "Mon, Apr 8, 11:00 AM",
                "value": 62.040000915527344
            },
            {
                "time": "Mon, Apr 8, 11:00 AM",
                "value": 61.97999954223633
            },
            {
                "time": "Mon, Apr 8, 11:01 AM",
                "value": 62.099998474121094
            },
            {
                "time": "Mon, Apr 8, 11:01 AM",
                "value": 62.02000045776367
            },
            {
                "time": "Mon, Apr 8, 11:02 AM",
                "value": 62.13999938964844
            },
            {
                "time": "Mon, Apr 8, 11:02 AM",
                "value": 62.13999938964844
            },
            {
                "time": "Mon, Apr 8, 11:03 AM",
                "value": 62.279998779296875
            },
            {
                "time": "Mon, Apr 8, 11:03 AM",
                "value": 62.27000045776367
            },
            {
                "time": "Mon, Apr 8, 11:04 AM",
                "value": 62.38999938964844
            },
            {
                "time": "Mon, Apr 8, 11:04 AM",
                "value": 62.34000015258789
            },
            {
                "time": "Mon, Apr 8, 11:05 AM",
                "value": 62.4900016784668
            },
            {
                "time": "Mon, Apr 8, 11:05 AM",
                "value": 62.47999954223633
            },
            {
                "time": "Mon, Apr 8, 11:06 AM",
                "value": 62.56999969482422
            },
            {
                "time": "Mon, Apr 8, 11:06 AM",
                "value": 62.66999816894531
            },
            {
                "time": "Mon, Apr 8, 11:07 AM",
                "value": 62.709999084472656
            },
            {
                "time": "Mon, Apr 8, 11:07 AM",
                "value": 62.70000076293945
            },
            {
                "time": "Mon, Apr 8, 11:08 AM",
                "value": 62.83000183105469
            },
            {
                "time": "Mon, Apr 8, 11:08 AM",
                "value": 62.720001220703125
            },
            {
                "time": "Mon, Apr 8, 11:09 AM",
                "value": 62.7599983215332
            },
            {
                "time": "Mon, Apr 8, 11:09 AM",
                "value": 62.66999816894531
            },
            {
                "time": "Mon, Apr 8, 11:10 AM",
                "value": 62.7599983215332
            },
            {
                "time": "Mon, Apr 8, 11:10 AM",
                "value": 62.630001068115234
            },
            {
                "time": "Mon, Apr 8, 11:11 AM",
                "value": 62.720001220703125
            },
            {
                "time": "Mon, Apr 8, 11:11 AM",
                "value": 62.630001068115234
            },
            {
                "time": "Mon, Apr 8, 11:12 AM",
                "value": 62.720001220703125
            },
            {
                "time": "Mon, Apr 8, 11:12 AM",
                "value": 62.66999816894531
            },
            {
                "time": "Mon, Apr 8, 11:13 AM",
                "value": 62.869998931884766
            },
            {
                "time": "Mon, Apr 8, 11:13 AM",
                "value": 62.880001068115234
            },
            {
                "time": "Mon, Apr 8, 11:14 AM",
                "value": 62.93000030517578
            },
            {
                "time": "Mon, Apr 8, 11:14 AM",
                "value": 62.88999938964844
            },
            {
                "time": "Mon, Apr 8, 11:15 AM",
                "value": 62.880001068115234
            },
            {
                "time": "Mon, Apr 8, 11:15 AM",
                "value": 62.849998474121094
            },
            {
                "time": "Mon, Apr 8, 11:16 AM",
                "value": 62.900001525878906
            },
            {
                "time": "Mon, Apr 8, 11:16 AM",
                "value": 62.849998474121094
            },
            {
                "time": "Mon, Apr 8, 11:17 AM",
                "value": 62.97999954223633
            },
            {
                "time": "Mon, Apr 8, 11:17 AM",
                "value": 63
            },
            {
                "time": "Mon, Apr 8, 11:18 AM",
                "value": 63.0099983215332
            },
            {
                "time": "Mon, Apr 8, 11:18 AM",
                "value": 63
            },
            {
                "time": "Mon, Apr 8, 11:19 AM",
                "value": 63
            },
            {
                "time": "Mon, Apr 8, 11:19 AM",
                "value": 62.849998474121094
            },
            {
                "time": "Mon, Apr 8, 11:20 AM",
                "value": 62.900001525878906
            },
            {
                "time": "Mon, Apr 8, 11:20 AM",
                "value": 62.83000183105469
            },
            {
                "time": "Mon, Apr 8, 11:21 AM",
                "value": 62.93000030517578
            },
            {
                "time": "Mon, Apr 8, 11:21 AM",
                "value": 62.91999816894531
            },
            {
                "time": "Mon, Apr 8, 11:22 AM",
                "value": 62.9900016784668
            },
            {
                "time": "Mon, Apr 8, 11:22 AM",
                "value": 63.029998779296875
            },
            {
                "time": "Mon, Apr 8, 11:23 AM",
                "value": 63.11000061035156
            },
            {
                "time": "Mon, Apr 8, 11:23 AM",
                "value": 63.189998626708984
            },
            {
                "time": "Mon, Apr 8, 11:24 AM",
                "value": 63.22999954223633
            },
            {
                "time": "Mon, Apr 8, 11:24 AM",
                "value": 63.13999938964844
            },
            {
                "time": "Mon, Apr 8, 11:25 AM",
                "value": 63.220001220703125
            },
            {
                "time": "Mon, Apr 8, 11:25 AM",
                "value": 63.220001220703125
            },
            {
                "time": "Mon, Apr 8, 11:26 AM",
                "value": 63.22999954223633
            },
            {
                "time": "Mon, Apr 8, 11:26 AM",
                "value": 63.13999938964844
            },
            {
                "time": "Mon, Apr 8, 11:27 AM",
                "value": 63.189998626708984
            },
            {
                "time": "Mon, Apr 8, 11:27 AM",
                "value": 63.11000061035156
            },
            {
                "time": "Mon, Apr 8, 11:28 AM",
                "value": 63.18000030517578
            },
            {
                "time": "Mon, Apr 8, 11:28 AM",
                "value": 63.13999938964844
            },
            {
                "time": "Mon, Apr 8, 11:29 AM",
                "value": 63.349998474121094
            },
            {
                "time": "Mon, Apr 8, 11:29 AM",
                "value": 63.33000183105469
            },
            {
                "time": "Mon, Apr 8, 11:30 AM",
                "value": 63.400001525878906
            },
            {
                "time": "Mon, Apr 8, 11:30 AM",
                "value": 63.400001525878906
            },
            {
                "time": "Mon, Apr 8, 11:31 AM",
                "value": 63.38999938964844
            },
            {
                "time": "Mon, Apr 8, 11:31 AM",
                "value": 63.38999938964844
            },
            {
                "time": "Mon, Apr 8, 11:32 AM",
                "value": 63.52000045776367
            },
            {
                "time": "Mon, Apr 8, 11:32 AM",
                "value": 63.439998626708984
            },
            {
                "time": "Mon, Apr 8, 11:33 AM",
                "value": 63.54999923706055
            },
            {
                "time": "Mon, Apr 8, 11:33 AM",
                "value": 63.459999084472656
            },
            {
                "time": "Mon, Apr 8, 11:34 AM",
                "value": 63.54999923706055
            },
            {
                "time": "Mon, Apr 8, 11:34 AM",
                "value": 63.470001220703125
            },
            {
                "time": "Mon, Apr 8, 11:35 AM",
                "value": 63.619998931884766
            },
            {
                "time": "Mon, Apr 8, 11:35 AM",
                "value": 63.54999923706055
            },
            {
                "time": "Mon, Apr 8, 11:36 AM",
                "value": 63.619998931884766
            },
            {
                "time": "Mon, Apr 8, 11:36 AM",
                "value": 63.529998779296875
            },
            {
                "time": "Mon, Apr 8, 11:37 AM",
                "value": 63.619998931884766
            },
            {
                "time": "Mon, Apr 8, 11:37 AM",
                "value": 63.58000183105469
            },
            {
                "time": "Mon, Apr 8, 11:38 AM",
                "value": 63.560001373291016
            },
            {
                "time": "Mon, Apr 8, 11:38 AM",
                "value": 63.529998779296875
            },
            {
                "time": "Mon, Apr 8, 11:39 AM",
                "value": 63.5
            },
            {
                "time": "Mon, Apr 8, 11:39 AM",
                "value": 63.5
            },
            {
                "time": "Mon, Apr 8, 11:40 AM",
                "value": 63.630001068115234
            },
            {
                "time": "Mon, Apr 8, 11:40 AM",
                "value": 63.61000061035156
            },
            {
                "time": "Mon, Apr 8, 11:41 AM",
                "value": 63.91999816894531
            },
            {
                "time": "Mon, Apr 8, 11:41 AM",
                "value": 63.900001525878906
            },
            {
                "time": "Mon, Apr 8, 11:42 AM",
                "value": 64.0199966430664
            },
            {
                "time": "Mon, Apr 8, 11:42 AM",
                "value": 63.95000076293945
            },
            {
                "time": "Mon, Apr 8, 11:43 AM",
                "value": 64.0199966430664
            },
            {
                "time": "Mon, Apr 8, 11:43 AM",
                "value": 63.95000076293945
            },
            {
                "time": "Mon, Apr 8, 11:44 AM",
                "value": 63.97999954223633
            },
            {
                "time": "Mon, Apr 8, 11:44 AM",
                "value": 63.900001525878906
            },
            {
                "time": "Mon, Apr 8, 11:45 AM",
                "value": 63.939998626708984
            },
            {
                "time": "Mon, Apr 8, 11:45 AM",
                "value": 63.83000183105469
            },
            {
                "time": "Mon, Apr 8, 11:46 AM",
                "value": 63.849998474121094
            },
            {
                "time": "Mon, Apr 8, 11:46 AM",
                "value": 63.7599983215332
            },
            {
                "time": "Mon, Apr 8, 11:47 AM",
                "value": 63.75
            },
            {
                "time": "Mon, Apr 8, 11:47 AM",
                "value": 63.72999954223633
            },
            {
                "time": "Mon, Apr 8, 11:48 AM",
                "value": 63.68000030517578
            },
            {
                "time": "Mon, Apr 8, 11:48 AM",
                "value": 63.65999984741211
            },
            {
                "time": "Mon, Apr 8, 11:49 AM",
                "value": 63.7400016784668
            },
            {
                "time": "Mon, Apr 8, 11:49 AM",
                "value": 63.779998779296875
            },
            {
                "time": "Mon, Apr 8, 11:50 AM",
                "value": 63.70000076293945
            },
            {
                "time": "Mon, Apr 8, 11:50 AM",
                "value": 63.61000061035156
            },
            {
                "time": "Mon, Apr 8, 11:51 AM",
                "value": 63.61000061035156
            },
            {
                "time": "Mon, Apr 8, 11:51 AM",
                "value": 63.720001220703125
            },
            {
                "time": "Mon, Apr 8, 11:52 AM",
                "value": 63.900001525878906
            },
            {
                "time": "Mon, Apr 8, 11:52 AM",
                "value": 63.86000061035156
            },
            {
                "time": "Mon, Apr 8, 11:53 AM",
                "value": 63.97999954223633
            },
            {
                "time": "Mon, Apr 8, 11:53 AM",
                "value": 63.900001525878906
            },
            {
                "time": "Mon, Apr 8, 11:54 AM",
                "value": 64.0199966430664
            },
            {
                "time": "Mon, Apr 8, 11:54 AM",
                "value": 63.970001220703125
            },
            {
                "time": "Mon, Apr 8, 11:55 AM",
                "value": 63.93000030517578
            },
            {
                "time": "Mon, Apr 8, 11:55 AM",
                "value": 63.939998626708984
            },
            {
                "time": "Mon, Apr 8, 11:56 AM",
                "value": 64.06999969482422
            },
            {
                "time": "Mon, Apr 8, 11:56 AM",
                "value": 64.01000213623047
            },
            {
                "time": "Mon, Apr 8, 11:57 AM",
                "value": 64.12999725341797
            },
            {
                "time": "Mon, Apr 8, 11:57 AM",
                "value": 64.16999816894531
            },
            {
                "time": "Mon, Apr 8, 11:58 AM",
                "value": 64.19999694824219
            },
            {
                "time": "Mon, Apr 8, 11:58 AM",
                "value": 64.12000274658203
            },
            {
                "time": "Mon, Apr 8, 11:59 AM",
                "value": 64.19999694824219
            },
            {
                "time": "Mon, Apr 8, 11:59 AM",
                "value": 64.12000274658203
            },
            {
                "time": "Mon, Apr 8, 12:00 PM",
                "value": 64.16999816894531
            },
            {
                "time": "Mon, Apr 8, 12:00 PM",
                "value": 64.08000183105469
            },
            {
                "time": "Mon, Apr 8, 12:01 PM",
                "value": 64.19999694824219
            },
            {
                "time": "Mon, Apr 8, 12:01 PM",
                "value": 64.1500015258789
            },
            {
                "time": "Mon, Apr 8, 12:02 PM",
                "value": 64.23999786376953
            },
            {
                "time": "Mon, Apr 8, 12:02 PM",
                "value": 64.12999725341797
            },
            {
                "time": "Mon, Apr 8, 12:03 PM",
                "value": 64.22000122070312
            },
            {
                "time": "Mon, Apr 8, 12:03 PM",
                "value": 64.19000244140625
            },
            {
                "time": "Mon, Apr 8, 12:04 PM",
                "value": 64.33999633789062
            },
            {
                "time": "Mon, Apr 8, 12:04 PM",
                "value": 64.33999633789062
            },
            {
                "time": "Mon, Apr 8, 12:05 PM",
                "value": 64.47000122070312
            },
            {
                "time": "Mon, Apr 8, 12:05 PM",
                "value": 64.44000244140625
            },
            {
                "time": "Mon, Apr 8, 12:06 PM",
                "value": 64.5199966430664
            },
            {
                "time": "Mon, Apr 8, 12:06 PM",
                "value": 64.5
            },
            {
                "time": "Mon, Apr 8, 12:07 PM",
                "value": 64.44000244140625
            },
            {
                "time": "Mon, Apr 8, 12:07 PM",
                "value": 64.52999877929688
            },
            {
                "time": "Mon, Apr 8, 12:08 PM",
                "value": 64.69999694824219
            },
            {
                "time": "Mon, Apr 8, 12:08 PM",
                "value": 64.58999633789062
            },
            {
                "time": "Mon, Apr 8, 12:09 PM",
                "value": 64.77999877929688
            },
            {
                "time": "Mon, Apr 8, 12:09 PM",
                "value": 64.69000244140625
            },
            {
                "time": "Mon, Apr 8, 12:10 PM",
                "value": 64.86000061035156
            },
            {
                "time": "Mon, Apr 8, 12:10 PM",
                "value": 64.66000366210938
            },
            {
                "time": "Mon, Apr 8, 12:11 PM",
                "value": 64.6500015258789
            },
            {
                "time": "Mon, Apr 8, 12:11 PM",
                "value": 64.51000213623047
            },
            {
                "time": "Mon, Apr 8, 12:12 PM",
                "value": 64.62999725341797
            },
            {
                "time": "Mon, Apr 8, 12:12 PM",
                "value": 64.4800033569336
            },
            {
                "time": "Mon, Apr 8, 12:13 PM",
                "value": 64.52999877929688
            },
            {
                "time": "Mon, Apr 8, 12:13 PM",
                "value": 64.44999694824219
            },
            {
                "time": "Mon, Apr 8, 12:14 PM",
                "value": 64.5999984741211
            },
            {
                "time": "Mon, Apr 8, 12:14 PM",
                "value": 64.51000213623047
            },
            {
                "time": "Mon, Apr 8, 12:15 PM",
                "value": 64.66000366210938
            },
            {
                "time": "Mon, Apr 8, 12:15 PM",
                "value": 64.62000274658203
            },
            {
                "time": "Mon, Apr 8, 12:16 PM",
                "value": 64.86000061035156
            },
            {
                "time": "Mon, Apr 8, 12:16 PM",
                "value": 64.69999694824219
            },
            {
                "time": "Mon, Apr 8, 12:17 PM",
                "value": 64.83000183105469
            },
            {
                "time": "Mon, Apr 8, 12:17 PM",
                "value": 64.80000305175781
            },
            {
                "time": "Mon, Apr 8, 12:18 PM",
                "value": 65.05000305175781
            },
            {
                "time": "Mon, Apr 8, 12:18 PM",
                "value": 64.87999725341797
            },
            {
                "time": "Mon, Apr 8, 12:19 PM",
                "value": 64.91000366210938
            },
            {
                "time": "Mon, Apr 8, 12:19 PM",
                "value": 64.91999816894531
            },
            {
                "time": "Mon, Apr 8, 12:20 PM",
                "value": 65
            },
            {
                "time": "Mon, Apr 8, 12:20 PM",
                "value": 64.91999816894531
            },
            {
                "time": "Mon, Apr 8, 12:21 PM",
                "value": 65.02999877929688
            },
            {
                "time": "Mon, Apr 8, 12:21 PM",
                "value": 64.9800033569336
            },
            {
                "time": "Mon, Apr 8, 12:22 PM",
                "value": 65.06999969482422
            },
            {
                "time": "Mon, Apr 8, 12:22 PM",
                "value": 65.0199966430664
            },
            {
                "time": "Mon, Apr 8, 12:23 PM",
                "value": 65.0999984741211
            },
            {
                "time": "Mon, Apr 8, 12:23 PM",
                "value": 65.06999969482422
            },
            {
                "time": "Mon, Apr 8, 12:24 PM",
                "value": 65.0999984741211
            },
            {
                "time": "Mon, Apr 8, 12:24 PM",
                "value": 65.0199966430664
            },
            {
                "time": "Mon, Apr 8, 12:25 PM",
                "value": 65.11000061035156
            },
            {
                "time": "Mon, Apr 8, 12:25 PM",
                "value": 65.04000091552734
            },
            {
                "time": "Mon, Apr 8, 12:26 PM",
                "value": 65.0999984741211
            },
            {
                "time": "Mon, Apr 8, 12:26 PM",
                "value": 65.0999984741211
            },
            {
                "time": "Mon, Apr 8, 12:27 PM",
                "value": 65.16999816894531
            },
            {
                "time": "Mon, Apr 8, 12:27 PM",
                "value": 65.33999633789062
            },
            {
                "time": "Mon, Apr 8, 12:28 PM",
                "value": 65.48999786376953
            },
            {
                "time": "Mon, Apr 8, 12:28 PM",
                "value": 65.44999694824219
            },
            {
                "time": "Mon, Apr 8, 12:29 PM",
                "value": 65.58000183105469
            },
            {
                "time": "Mon, Apr 8, 12:29 PM",
                "value": 65.5199966430664
            },
            {
                "time": "Mon, Apr 8, 12:30 PM",
                "value": 65.69999694824219
            },
            {
                "time": "Mon, Apr 8, 12:30 PM",
                "value": 65.48999786376953
            },
            {
                "time": "Mon, Apr 8, 12:31 PM",
                "value": 65.54000091552734
            },
            {
                "time": "Mon, Apr 8, 12:31 PM",
                "value": 65.45999908447266
            },
            {
                "time": "Mon, Apr 8, 12:32 PM",
                "value": 65.54000091552734
            },
            {
                "time": "Mon, Apr 8, 12:32 PM",
                "value": 65.45999908447266
            },
            {
                "time": "Mon, Apr 8, 12:33 PM",
                "value": 65.55000305175781
            },
            {
                "time": "Mon, Apr 8, 12:33 PM",
                "value": 65.45999908447266
            },
            {
                "time": "Mon, Apr 8, 12:34 PM",
                "value": 65.66000366210938
            },
            {
                "time": "Mon, Apr 8, 12:34 PM",
                "value": 65.5199966430664
            },
            {
                "time": "Mon, Apr 8, 12:35 PM",
                "value": 65.5
            },
            {
                "time": "Mon, Apr 8, 12:35 PM",
                "value": 65.5999984741211
            },
            {
                "time": "Mon, Apr 8, 12:36 PM",
                "value": 65.62000274658203
            },
            {
                "time": "Mon, Apr 8, 12:36 PM",
                "value": 65.56999969482422
            },
            {
                "time": "Mon, Apr 8, 12:37 PM",
                "value": 65.81999969482422
            },
            {
                "time": "Mon, Apr 8, 12:37 PM",
                "value": 65.79000091552734
            },
            {
                "time": "Mon, Apr 8, 12:38 PM",
                "value": 65.94000244140625
            },
            {
                "time": "Mon, Apr 8, 12:38 PM",
                "value": 65.8499984741211
            },
            {
                "time": "Mon, Apr 8, 12:39 PM",
                "value": 65.9800033569336
            },
            {
                "time": "Mon, Apr 8, 12:39 PM",
                "value": 65.93000030517578
            },
            {
                "time": "Mon, Apr 8, 12:40 PM",
                "value": 66.08000183105469
            },
            {
                "time": "Mon, Apr 8, 12:40 PM",
                "value": 66.04000091552734
            },
            {
                "time": "Mon, Apr 8, 12:41 PM",
                "value": 66.16999816894531
            },
            {
                "time": "Mon, Apr 8, 12:41 PM",
                "value": 66.19000244140625
            },
            {
                "time": "Mon, Apr 8, 12:42 PM",
                "value": 66.19000244140625
            },
            {
                "time": "Mon, Apr 8, 12:42 PM",
                "value": 66.11000061035156
            },
            {
                "time": "Mon, Apr 8, 12:43 PM",
                "value": 66.12000274658203
            },
            {
                "time": "Mon, Apr 8, 12:43 PM",
                "value": 66.02999877929688
            },
            {
                "time": "Mon, Apr 8, 12:44 PM",
                "value": 66.16000366210938
            },
            {
                "time": "Mon, Apr 8, 12:44 PM",
                "value": 66.06999969482422
            },
            {
                "time": "Mon, Apr 8, 12:45 PM",
                "value": 66.12000274658203
            },
            {
                "time": "Mon, Apr 8, 12:45 PM",
                "value": 66.04000091552734
            },
            {
                "time": "Mon, Apr 8, 12:46 PM",
                "value": 66.16000366210938
            },
            {
                "time": "Mon, Apr 8, 12:46 PM",
                "value": 66.06999969482422
            },
            {
                "time": "Mon, Apr 8, 12:47 PM",
                "value": 66.16000366210938
            },
            {
                "time": "Mon, Apr 8, 12:47 PM",
                "value": 66.04000091552734
            },
            {
                "time": "Mon, Apr 8, 12:48 PM",
                "value": 66.20999908447266
            },
            {
                "time": "Mon, Apr 8, 12:48 PM",
                "value": 66
            },
            {
                "time": "Mon, Apr 8, 12:49 PM",
                "value": 66.12999725341797
            },
            {
                "time": "Mon, Apr 8, 12:49 PM",
                "value": 66.12999725341797
            },
            {
                "time": "Mon, Apr 8, 12:50 PM",
                "value": 66.29000091552734
            },
            {
                "time": "Mon, Apr 8, 12:50 PM",
                "value": 66.1500015258789
            },
            {
                "time": "Mon, Apr 8, 12:51 PM",
                "value": 66.23999786376953
            },
            {
                "time": "Mon, Apr 8, 12:51 PM",
                "value": 66.12000274658203
            },
            {
                "time": "Mon, Apr 8, 12:52 PM",
                "value": 66.31999969482422
            },
            {
                "time": "Mon, Apr 8, 12:52 PM",
                "value": 66.11000061035156
            },
            {
                "time": "Mon, Apr 8, 12:53 PM",
                "value": 66.16000366210938
            },
            {
                "time": "Mon, Apr 8, 12:53 PM",
                "value": 66.04000091552734
            },
            {
                "time": "Mon, Apr 8, 12:54 PM",
                "value": 66.16999816894531
            },
            {
                "time": "Mon, Apr 8, 12:54 PM",
                "value": 66.01000213623047
            },
            {
                "time": "Mon, Apr 8, 12:55 PM",
                "value": 66.08999633789062
            },
            {
                "time": "Mon, Apr 8, 12:55 PM",
                "value": 66.06999969482422
            },
            {
                "time": "Mon, Apr 8, 12:56 PM",
                "value": 66.11000061035156
            },
            {
                "time": "Mon, Apr 8, 12:56 PM",
                "value": 65.91999816894531
            },
            {
                "time": "Mon, Apr 8, 12:57 PM",
                "value": 66.05999755859375
            },
            {
                "time": "Mon, Apr 8, 12:57 PM",
                "value": 65.97000122070312
            },
            {
                "time": "Mon, Apr 8, 12:58 PM",
                "value": 66.06999969482422
            },
            {
                "time": "Mon, Apr 8, 12:58 PM",
                "value": 66
            },
            {
                "time": "Mon, Apr 8, 12:59 PM",
                "value": 65.98999786376953
            },
            {
                "time": "Mon, Apr 8, 12:59 PM",
                "value": 66.02999877929688
            },
            {
                "time": "Mon, Apr 8, 1:00 PM",
                "value": 66.19000244140625
            },
            {
                "time": "Mon, Apr 8, 1:00 PM",
                "value": 66.1500015258789
            },
            {
                "time": "Mon, Apr 8, 1:01 PM",
                "value": 66.37000274658203
            },
            {
                "time": "Mon, Apr 8, 1:01 PM",
                "value": 66.47000122070312
            },
            {
                "time": "Mon, Apr 8, 1:02 PM",
                "value": 66.69999694824219
            },
            {
                "time": "Mon, Apr 8, 1:02 PM",
                "value": 66.69000244140625
            },
            {
                "time": "Mon, Apr 8, 1:03 PM",
                "value": 66.87999725341797
            },
            {
                "time": "Mon, Apr 8, 1:03 PM",
                "value": 66.86000061035156
            },
            {
                "time": "Mon, Apr 8, 1:04 PM",
                "value": 67.16999816894531
            },
            {
                "time": "Mon, Apr 8, 1:04 PM",
                "value": 67.05000305175781
            },
            {
                "time": "Mon, Apr 8, 1:05 PM",
                "value": 67.16999816894531
            },
            {
                "time": "Mon, Apr 8, 1:05 PM",
                "value": 67.05000305175781
            },
            {
                "time": "Mon, Apr 8, 1:06 PM",
                "value": 67.08000183105469
            },
            {
                "time": "Mon, Apr 8, 1:06 PM",
                "value": 67.01000213623047
            },
            {
                "time": "Mon, Apr 8, 1:07 PM",
                "value": 66.97000122070312
            },
            {
                "time": "Mon, Apr 8, 1:07 PM",
                "value": 66.97000122070312
            },
            {
                "time": "Mon, Apr 8, 1:08 PM",
                "value": 67.02999877929688
            },
            {
                "time": "Mon, Apr 8, 1:08 PM",
                "value": 66.94000244140625
            },
            {
                "time": "Mon, Apr 8, 1:09 PM",
                "value": 66.94999694824219
            },
            {
                "time": "Mon, Apr 8, 1:09 PM",
                "value": 66.86000061035156
            },
            {
                "time": "Mon, Apr 8, 1:10 PM",
                "value": 67.02999877929688
            },
            {
                "time": "Mon, Apr 8, 1:10 PM",
                "value": 66.87000274658203
            },
            {
                "time": "Mon, Apr 8, 1:11 PM",
                "value": 66.98999786376953
            },
            {
                "time": "Mon, Apr 8, 1:11 PM",
                "value": 66.94000244140625
            },
            {
                "time": "Mon, Apr 8, 1:12 PM",
                "value": 67.16999816894531
            },
            {
                "time": "Mon, Apr 8, 1:12 PM",
                "value": 67.01000213623047
            },
            {
                "time": "Mon, Apr 8, 1:13 PM",
                "value": 67.11000061035156
            },
            {
                "time": "Mon, Apr 8, 1:13 PM",
                "value": 67
            },
            {
                "time": "Mon, Apr 8, 1:14 PM",
                "value": 67.13999938964844
            },
            {
                "time": "Mon, Apr 8, 1:14 PM",
                "value": 66.97000122070312
            },
            {
                "time": "Mon, Apr 8, 1:15 PM",
                "value": 67.05999755859375
            },
            {
                "time": "Mon, Apr 8, 1:15 PM",
                "value": 66.94000244140625
            },
            {
                "time": "Mon, Apr 8, 1:16 PM",
                "value": 66.98999786376953
            },
            {
                "time": "Mon, Apr 8, 1:16 PM",
                "value": 67.01000213623047
            },
            {
                "time": "Mon, Apr 8, 1:17 PM",
                "value": 67.25
            },
            {
                "time": "Mon, Apr 8, 1:17 PM",
                "value": 67.26000213623047
            },
            {
                "time": "Mon, Apr 8, 1:18 PM",
                "value": 67.45999908447266
            },
            {
                "time": "Mon, Apr 8, 1:18 PM",
                "value": 67.26000213623047
            },
            {
                "time": "Mon, Apr 8, 1:19 PM",
                "value": 67.27999877929688
            },
            {
                "time": "Mon, Apr 8, 1:19 PM",
                "value": 67.12000274658203
            },
            {
                "time": "Mon, Apr 8, 1:20 PM",
                "value": 67.16000366210938
            },
            {
                "time": "Mon, Apr 8, 1:20 PM",
                "value": 66.94000244140625
            },
            {
                "time": "Mon, Apr 8, 1:21 PM",
                "value": 67.0199966430664
            },
            {
                "time": "Mon, Apr 8, 1:21 PM",
                "value": 66.93000030517578
            },
            {
                "time": "Mon, Apr 8, 1:22 PM",
                "value": 66.95999908447266
            },
            {
                "time": "Mon, Apr 8, 1:22 PM",
                "value": 66.9000015258789
            },
            {
                "time": "Mon, Apr 8, 1:23 PM",
                "value": 66.98999786376953
            },
            {
                "time": "Mon, Apr 8, 1:23 PM",
                "value": 66.94000244140625
            },
            {
                "time": "Mon, Apr 8, 1:24 PM",
                "value": 67.05000305175781
            },
            {
                "time": "Mon, Apr 8, 1:24 PM",
                "value": 66.9000015258789
            },
            {
                "time": "Mon, Apr 8, 1:25 PM",
                "value": 67
            },
            {
                "time": "Mon, Apr 8, 1:25 PM",
                "value": 66.94000244140625
            },
            {
                "time": "Mon, Apr 8, 1:26 PM",
                "value": 67.16999816894531
            },
            {
                "time": "Mon, Apr 8, 1:26 PM",
                "value": 67.16000366210938
            },
            {
                "time": "Mon, Apr 8, 1:27 PM",
                "value": 67.45999908447266
            },
            {
                "time": "Mon, Apr 8, 1:27 PM",
                "value": 67.44999694824219
            },
            {
                "time": "Mon, Apr 8, 1:28 PM",
                "value": 67.6500015258789
            },
            {
                "time": "Mon, Apr 8, 1:28 PM",
                "value": 67.62000274658203
            },
            {
                "time": "Mon, Apr 8, 1:29 PM",
                "value": 67.70999908447266
            },
            {
                "time": "Mon, Apr 8, 1:29 PM",
                "value": 67.66999816894531
            },
            {
                "time": "Mon, Apr 8, 1:30 PM",
                "value": 67.81999969482422
            },
            {
                "time": "Mon, Apr 8, 1:30 PM",
                "value": 67.80000305175781
            },
            {
                "time": "Mon, Apr 8, 1:31 PM",
                "value": 67.88999938964844
            },
            {
                "time": "Mon, Apr 8, 1:31 PM",
                "value": 67.91999816894531
            },
            {
                "time": "Mon, Apr 8, 1:32 PM",
                "value": 68.12999725341797
            },
            {
                "time": "Mon, Apr 8, 1:32 PM",
                "value": 68.20999908447266
            },
            {
                "time": "Mon, Apr 8, 1:33 PM",
                "value": 68.26000213623047
            },
            {
                "time": "Mon, Apr 8, 1:33 PM",
                "value": 68.16999816894531
            },
            {
                "time": "Mon, Apr 8, 1:34 PM",
                "value": 68.26000213623047
            },
            {
                "time": "Mon, Apr 8, 1:34 PM",
                "value": 68.13999938964844
            },
            {
                "time": "Mon, Apr 8, 1:35 PM",
                "value": 68.22000122070312
            },
            {
                "time": "Mon, Apr 8, 1:35 PM",
                "value": 68.12999725341797
            },
            {
                "time": "Mon, Apr 8, 1:36 PM",
                "value": 68.18000030517578
            },
            {
                "time": "Mon, Apr 8, 1:36 PM",
                "value": 68.05999755859375
            },
            {
                "time": "Mon, Apr 8, 1:37 PM",
                "value": 68.08000183105469
            },
            {
                "time": "Mon, Apr 8, 1:37 PM",
                "value": 67.94999694824219
            },
            {
                "time": "Mon, Apr 8, 1:38 PM",
                "value": 67.94000244140625
            },
            {
                "time": "Mon, Apr 8, 1:38 PM",
                "value": 67.80999755859375
            },
            {
                "time": "Mon, Apr 8, 1:39 PM",
                "value": 67.79000091552734
            },
            {
                "time": "Mon, Apr 8, 1:39 PM",
                "value": 67.66000366210938
            },
            {
                "time": "Mon, Apr 8, 1:40 PM",
                "value": 67.75
            },
            {
                "time": "Mon, Apr 8, 1:40 PM",
                "value": 67.68000030517578
            },
            {
                "time": "Mon, Apr 8, 1:41 PM",
                "value": 67.75
            },
            {
                "time": "Mon, Apr 8, 1:41 PM",
                "value": 67.66999816894531
            },
            {
                "time": "Mon, Apr 8, 1:42 PM",
                "value": 67.79000091552734
            },
            {
                "time": "Mon, Apr 8, 1:42 PM",
                "value": 67.7300033569336
            },
            {
                "time": "Mon, Apr 8, 1:43 PM",
                "value": 67.88999938964844
            },
            {
                "time": "Mon, Apr 8, 1:43 PM",
                "value": 67.83999633789062
            },
            {
                "time": "Mon, Apr 8, 1:44 PM",
                "value": 67.93000030517578
            },
            {
                "time": "Mon, Apr 8, 1:44 PM",
                "value": 67.93000030517578
            },
            {
                "time": "Mon, Apr 8, 1:45 PM",
                "value": 67.9800033569336
            },
            {
                "time": "Mon, Apr 8, 1:45 PM",
                "value": 67.93000030517578
            },
            {
                "time": "Mon, Apr 8, 1:46 PM",
                "value": 67.95999908447266
            },
            {
                "time": "Mon, Apr 8, 1:46 PM",
                "value": 67.91999816894531
            },
            {
                "time": "Mon, Apr 8, 1:47 PM",
                "value": 67.94999694824219
            },
            {
                "time": "Mon, Apr 8, 1:47 PM",
                "value": 68.01000213623047
            },
            {
                "time": "Mon, Apr 8, 1:48 PM",
                "value": 68.04000091552734
            },
            {
                "time": "Mon, Apr 8, 1:48 PM",
                "value": 67.97000122070312
            },
            {
                "time": "Mon, Apr 8, 1:49 PM",
                "value": 68.04000091552734
            },
            {
                "time": "Mon, Apr 8, 1:49 PM",
                "value": 67.91999816894531
            },
            {
                "time": "Mon, Apr 8, 1:50 PM",
                "value": 68.01000213623047
            },
            {
                "time": "Mon, Apr 8, 1:50 PM",
                "value": 67.87999725341797
            },
            {
                "time": "Mon, Apr 8, 1:51 PM",
                "value": 68
            },
            {
                "time": "Mon, Apr 8, 1:51 PM",
                "value": 67.97000122070312
            },
            {
                "time": "Mon, Apr 8, 1:52 PM",
                "value": 68.08999633789062
            },
            {
                "time": "Mon, Apr 8, 1:52 PM",
                "value": 68.04000091552734
            },
            {
                "time": "Mon, Apr 8, 1:53 PM",
                "value": 68.1500015258789
            },
            {
                "time": "Mon, Apr 8, 1:53 PM",
                "value": 68.05999755859375
            },
            {
                "time": "Mon, Apr 8, 1:54 PM",
                "value": 68.16000366210938
            },
            {
                "time": "Mon, Apr 8, 1:54 PM",
                "value": 68.13999938964844
            },
            {
                "time": "Mon, Apr 8, 1:55 PM",
                "value": 68.23999786376953
            },
            {
                "time": "Mon, Apr 8, 1:55 PM",
                "value": 68.27999877929688
            },
            {
                "time": "Mon, Apr 8, 1:56 PM",
                "value": 68.44000244140625
            },
            {
                "time": "Mon, Apr 8, 1:56 PM",
                "value": 68.3499984741211
            },
            {
                "time": "Mon, Apr 8, 1:57 PM",
                "value": 68.47000122070312
            },
            {
                "time": "Mon, Apr 8, 1:57 PM",
                "value": 68.38999938964844
            },
            {
                "time": "Mon, Apr 8, 1:58 PM",
                "value": 68.51000213623047
            },
            {
                "time": "Mon, Apr 8, 1:58 PM",
                "value": 68.43000030517578
            },
            {
                "time": "Mon, Apr 8, 1:59 PM",
                "value": 68.51000213623047
            },
            {
                "time": "Mon, Apr 8, 1:59 PM",
                "value": 68.41999816894531
            },
            {
                "time": "Mon, Apr 8, 2:00 PM",
                "value": 68.5
            },
            {
                "time": "Mon, Apr 8, 2:00 PM",
                "value": 68.38999938964844
            },
            {
                "time": "Mon, Apr 8, 2:01 PM",
                "value": 68.44000244140625
            },
            {
                "time": "Mon, Apr 8, 2:01 PM",
                "value": 68.3499984741211
            },
            {
                "time": "Mon, Apr 8, 2:02 PM",
                "value": 68.4000015258789
            },
            {
                "time": "Mon, Apr 8, 2:02 PM",
                "value": 68.30999755859375
            },
            {
                "time": "Mon, Apr 8, 2:03 PM",
                "value": 68.47000122070312
            },
            {
                "time": "Mon, Apr 8, 2:03 PM",
                "value": 68.45999908447266
            },
            {
                "time": "Mon, Apr 8, 2:04 PM",
                "value": 68.5
            },
            {
                "time": "Mon, Apr 8, 2:04 PM",
                "value": 68.45999908447266
            },
            {
                "time": "Mon, Apr 8, 2:05 PM",
                "value": 68.41999816894531
            },
            {
                "time": "Mon, Apr 8, 2:05 PM",
                "value": 68.44000244140625
            },
            {
                "time": "Mon, Apr 8, 2:06 PM",
                "value": 68.5199966430664
            },
            {
                "time": "Mon, Apr 8, 2:06 PM",
                "value": 68.37999725341797
            },
            {
                "time": "Mon, Apr 8, 2:07 PM",
                "value": 68.43000030517578
            },
            {
                "time": "Mon, Apr 8, 2:07 PM",
                "value": 68.31999969482422
            },
            {
                "time": "Mon, Apr 8, 2:08 PM",
                "value": 68.37000274658203
            },
            {
                "time": "Mon, Apr 8, 2:08 PM",
                "value": 68.23999786376953
            },
            {
                "time": "Mon, Apr 8, 2:09 PM",
                "value": 68.4000015258789
            },
            {
                "time": "Mon, Apr 8, 2:09 PM",
                "value": 68.3499984741211
            },
            {
                "time": "Mon, Apr 8, 2:10 PM",
                "value": 68.43000030517578
            },
            {
                "time": "Mon, Apr 8, 2:10 PM",
                "value": 68.38999938964844
            },
            {
                "time": "Mon, Apr 8, 2:11 PM",
                "value": 68.5199966430664
            },
            {
                "time": "Mon, Apr 8, 2:11 PM",
                "value": 68.41999816894531
            },
            {
                "time": "Mon, Apr 8, 2:12 PM",
                "value": 68.55000305175781
            },
            {
                "time": "Mon, Apr 8, 2:12 PM",
                "value": 68.45999908447266
            },
            {
                "time": "Mon, Apr 8, 2:13 PM",
                "value": 68.63999938964844
            },
            {
                "time": "Mon, Apr 8, 2:13 PM",
                "value": 68.54000091552734
            },
            {
                "time": "Mon, Apr 8, 2:14 PM",
                "value": 68.66000366210938
            },
            {
                "time": "Mon, Apr 8, 2:14 PM",
                "value": 68.66999816894531
            },
            {
                "time": "Mon, Apr 8, 2:15 PM",
                "value": 68.87000274658203
            },
            {
                "time": "Mon, Apr 8, 2:15 PM",
                "value": 68.86000061035156
            },
            {
                "time": "Mon, Apr 8, 2:16 PM",
                "value": 69.08000183105469
            },
            {
                "time": "Mon, Apr 8, 2:16 PM",
                "value": 69.04000091552734
            },
            {
                "time": "Mon, Apr 8, 2:17 PM",
                "value": 69.16000366210938
            },
            {
                "time": "Mon, Apr 8, 2:17 PM",
                "value": 69.11000061035156
            },
            {
                "time": "Mon, Apr 8, 2:18 PM",
                "value": 69.1500015258789
            },
            {
                "time": "Mon, Apr 8, 2:18 PM",
                "value": 69.0999984741211
            },
            {
                "time": "Mon, Apr 8, 2:19 PM",
                "value": 69.19000244140625
            },
            {
                "time": "Mon, Apr 8, 2:19 PM",
                "value": 69.16000366210938
            },
            {
                "time": "Mon, Apr 8, 2:20 PM",
                "value": 69.36000061035156
            },
            {
                "time": "Mon, Apr 8, 2:20 PM",
                "value": 69.23999786376953
            },
            {
                "time": "Mon, Apr 8, 2:21 PM",
                "value": 69.2699966430664
            },
            {
                "time": "Mon, Apr 8, 2:21 PM",
                "value": 69.1500015258789
            },
            {
                "time": "Mon, Apr 8, 2:22 PM",
                "value": 69.19999694824219
            },
            {
                "time": "Mon, Apr 8, 2:22 PM",
                "value": 69.08000183105469
            },
            {
                "time": "Mon, Apr 8, 2:23 PM",
                "value": 69.16000366210938
            },
            {
                "time": "Mon, Apr 8, 2:23 PM",
                "value": 69.06999969482422
            },
            {
                "time": "Mon, Apr 8, 2:24 PM",
                "value": 69.16999816894531
            },
            {
                "time": "Mon, Apr 8, 2:24 PM",
                "value": 69.06999969482422
            },
            {
                "time": "Mon, Apr 8, 2:25 PM",
                "value": 69.19000244140625
            },
            {
                "time": "Mon, Apr 8, 2:25 PM",
                "value": 69.13999938964844
            },
            {
                "time": "Mon, Apr 8, 2:26 PM",
                "value": 69.31999969482422
            },
            {
                "time": "Mon, Apr 8, 2:26 PM",
                "value": 69.2300033569336
            },
            {
                "time": "Mon, Apr 8, 2:27 PM",
                "value": 69.26000213623047
            },
            {
                "time": "Mon, Apr 8, 2:27 PM",
                "value": 69.26000213623047
            },
            {
                "time": "Mon, Apr 8, 2:28 PM",
                "value": 69.37999725341797
            },
            {
                "time": "Mon, Apr 8, 2:28 PM",
                "value": 69.31999969482422
            },
            {
                "time": "Mon, Apr 8, 2:29 PM",
                "value": 69.45999908447266
            },
            {
                "time": "Mon, Apr 8, 2:29 PM",
                "value": 69.36000061035156
            },
            {
                "time": "Mon, Apr 8, 2:30 PM",
                "value": 69.44999694824219
            },
            {
                "time": "Mon, Apr 8, 2:30 PM",
                "value": 69.37999725341797
            },
            {
                "time": "Mon, Apr 8, 2:31 PM",
                "value": 69.41000366210938
            },
            {
                "time": "Mon, Apr 8, 2:31 PM",
                "value": 69.33000183105469
            },
            {
                "time": "Mon, Apr 8, 2:32 PM",
                "value": 69.41999816894531
            },
            {
                "time": "Mon, Apr 8, 2:32 PM",
                "value": 69.33000183105469
            },
            {
                "time": "Mon, Apr 8, 2:33 PM",
                "value": 69.37999725341797
            },
            {
                "time": "Mon, Apr 8, 2:33 PM",
                "value": 69.33000183105469
            },
            {
                "time": "Mon, Apr 8, 2:34 PM",
                "value": 69.37999725341797
            },
            {
                "time": "Mon, Apr 8, 2:34 PM",
                "value": 69.1500015258789
            },
            {
                "time": "Mon, Apr 8, 2:35 PM",
                "value": 69.19000244140625
            },
            {
                "time": "Mon, Apr 8, 2:35 PM",
                "value": 69.08000183105469
            },
            {
                "time": "Mon, Apr 8, 2:36 PM",
                "value": 69.05999755859375
            },
            {
                "time": "Mon, Apr 8, 2:36 PM",
                "value": 68.91999816894531
            },
            {
                "time": "Mon, Apr 8, 2:37 PM",
                "value": 68.9800033569336
            },
            {
                "time": "Mon, Apr 8, 2:37 PM",
                "value": 68.80999755859375
            },
            {
                "time": "Mon, Apr 8, 2:38 PM",
                "value": 68.87000274658203
            },
            {
                "time": "Mon, Apr 8, 2:38 PM",
                "value": 68.70999908447266
            },
            {
                "time": "Mon, Apr 8, 2:39 PM",
                "value": 68.76000213623047
            },
            {
                "time": "Mon, Apr 8, 2:39 PM",
                "value": 68.68000030517578
            },
            {
                "time": "Mon, Apr 8, 2:40 PM",
                "value": 68.77999877929688
            },
            {
                "time": "Mon, Apr 8, 2:40 PM",
                "value": 68.72000122070312
            },
            {
                "time": "Mon, Apr 8, 2:41 PM",
                "value": 68.83999633789062
            },
            {
                "time": "Mon, Apr 8, 2:41 PM",
                "value": 68.77999877929688
            },
            {
                "time": "Mon, Apr 8, 2:42 PM",
                "value": 68.87000274658203
            },
            {
                "time": "Mon, Apr 8, 2:42 PM",
                "value": 68.83000183105469
            },
            {
                "time": "Mon, Apr 8, 2:43 PM",
                "value": 68.94000244140625
            },
            {
                "time": "Mon, Apr 8, 2:43 PM",
                "value": 68.86000061035156
            },
            {
                "time": "Mon, Apr 8, 2:44 PM",
                "value": 68.94000244140625
            },
            {
                "time": "Mon, Apr 8, 2:44 PM",
                "value": 68.86000061035156
            },
            {
                "time": "Mon, Apr 8, 2:45 PM",
                "value": 68.91999816894531
            },
            {
                "time": "Mon, Apr 8, 2:45 PM",
                "value": 68.8499984741211
            },
            {
                "time": "Mon, Apr 8, 2:46 PM",
                "value": 68.91000366210938
            },
            {
                "time": "Mon, Apr 8, 2:46 PM",
                "value": 68.81999969482422
            },
            {
                "time": "Mon, Apr 8, 2:47 PM",
                "value": 68.91000366210938
            },
            {
                "time": "Mon, Apr 8, 2:47 PM",
                "value": 68.83000183105469
            },
            {
                "time": "Mon, Apr 8, 2:48 PM",
                "value": 68.87999725341797
            },
            {
                "time": "Mon, Apr 8, 2:48 PM",
                "value": 68.75
            },
            {
                "time": "Mon, Apr 8, 2:49 PM",
                "value": 68.7699966430664
            },
            {
                "time": "Mon, Apr 8, 2:49 PM",
                "value": 68.6500015258789
            },
            {
                "time": "Mon, Apr 8, 2:50 PM",
                "value": 68.56999969482422
            },
            {
                "time": "Mon, Apr 8, 2:50 PM",
                "value": 68.55000305175781
            },
            {
                "time": "Mon, Apr 8, 2:51 PM",
                "value": 68.48999786376953
            },
            {
                "time": "Mon, Apr 8, 2:51 PM",
                "value": 68.45999908447266
            },
            {
                "time": "Mon, Apr 8, 2:52 PM",
                "value": 68.48999786376953
            },
            {
                "time": "Mon, Apr 8, 2:52 PM",
                "value": 68.38999938964844
            },
            {
                "time": "Mon, Apr 8, 2:53 PM",
                "value": 68.43000030517578
            },
            {
                "time": "Mon, Apr 8, 2:53 PM",
                "value": 68.33999633789062
            },
            {
                "time": "Mon, Apr 8, 2:54 PM",
                "value": 68.36000061035156
            },
            {
                "time": "Mon, Apr 8, 2:54 PM",
                "value": 68.23999786376953
            },
            {
                "time": "Mon, Apr 8, 2:55 PM",
                "value": 68.29000091552734
            },
            {
                "time": "Mon, Apr 8, 2:55 PM",
                "value": 68.16999816894531
            },
            {
                "time": "Mon, Apr 8, 2:56 PM",
                "value": 68.26000213623047
            },
            {
                "time": "Mon, Apr 8, 2:56 PM",
                "value": 68.13999938964844
            },
            {
                "time": "Mon, Apr 8, 2:57 PM",
                "value": 68.2300033569336
            },
            {
                "time": "Mon, Apr 8, 2:57 PM",
                "value": 68.12000274658203
            },
            {
                "time": "Mon, Apr 8, 2:58 PM",
                "value": 68.11000061035156
            },
            {
                "time": "Mon, Apr 8, 2:58 PM",
                "value": 68.05999755859375
            },
            {
                "time": "Mon, Apr 8, 2:59 PM",
                "value": 68.13999938964844
            },
            {
                "time": "Mon, Apr 8, 2:59 PM",
                "value": 67.98999786376953
            },
            {
                "time": "Mon, Apr 8, 3:00 PM",
                "value": 68.08000183105469
            },
            {
                "time": "Mon, Apr 8, 3:00 PM",
                "value": 67.95999908447266
            },
            {
                "time": "Mon, Apr 8, 3:01 PM",
                "value": 68.05000305175781
            },
            {
                "time": "Mon, Apr 8, 3:01 PM",
                "value": 67.91000366210938
            },
            {
                "time": "Mon, Apr 8, 3:02 PM",
                "value": 67.93000030517578
            },
            {
                "time": "Mon, Apr 8, 3:02 PM",
                "value": 67.7699966430664
            },
            {
                "time": "Mon, Apr 8, 3:03 PM",
                "value": 67.8499984741211
            },
            {
                "time": "Mon, Apr 8, 3:03 PM",
                "value": 67.69999694824219
            },
            {
                "time": "Mon, Apr 8, 3:04 PM",
                "value": 67.7300033569336
            },
            {
                "time": "Mon, Apr 8, 3:04 PM",
                "value": 67.62000274658203
            },
            {
                "time": "Mon, Apr 8, 3:05 PM",
                "value": 67.69000244140625
            },
            {
                "time": "Mon, Apr 8, 3:05 PM",
                "value": 67.58999633789062
            },
            {
                "time": "Mon, Apr 8, 3:06 PM",
                "value": 67.56999969482422
            },
            {
                "time": "Mon, Apr 8, 3:06 PM",
                "value": 67.5199966430664
            },
            {
                "time": "Mon, Apr 8, 3:07 PM",
                "value": 67.56999969482422
            },
            {
                "time": "Mon, Apr 8, 3:07 PM",
                "value": 67.44000244140625
            },
            {
                "time": "Mon, Apr 8, 3:08 PM",
                "value": 67.5
            },
            {
                "time": "Mon, Apr 8, 3:08 PM",
                "value": 67.33999633789062
            },
            {
                "time": "Mon, Apr 8, 3:09 PM",
                "value": 67.41000366210938
            },
            {
                "time": "Mon, Apr 8, 3:09 PM",
                "value": 67.30000305175781
            },
            {
                "time": "Mon, Apr 8, 3:10 PM",
                "value": 67.3499984741211
            },
            {
                "time": "Mon, Apr 8, 3:10 PM",
                "value": 67.2300033569336
            },
            {
                "time": "Mon, Apr 8, 3:11 PM",
                "value": 67.26000213623047
            },
            {
                "time": "Mon, Apr 8, 3:11 PM",
                "value": 67.12000274658203
            },
            {
                "time": "Mon, Apr 8, 3:12 PM",
                "value": 67.18000030517578
            },
            {
                "time": "Mon, Apr 8, 3:12 PM",
                "value": 67.05000305175781
            },
            {
                "time": "Mon, Apr 8, 3:13 PM",
                "value": 67.11000061035156
            },
            {
                "time": "Mon, Apr 8, 3:13 PM",
                "value": 67
            },
            {
                "time": "Mon, Apr 8, 3:14 PM",
                "value": 67
            },
            {
                "time": "Mon, Apr 8, 3:14 PM",
                "value": 66.87999725341797
            },
            {
                "time": "Mon, Apr 8, 3:15 PM",
                "value": 66.95999908447266
            },
            {
                "time": "Mon, Apr 8, 3:15 PM",
                "value": 66.83000183105469
            },
            {
                "time": "Mon, Apr 8, 3:16 PM",
                "value": 66.91999816894531
            },
            {
                "time": "Mon, Apr 8, 3:16 PM",
                "value": 66.83000183105469
            },
            {
                "time": "Mon, Apr 8, 3:17 PM",
                "value": 66.94999694824219
            },
            {
                "time": "Mon, Apr 8, 3:17 PM",
                "value": 66.86000061035156
            },
            {
                "time": "Mon, Apr 8, 3:18 PM",
                "value": 66.91999816894531
            },
            {
                "time": "Mon, Apr 8, 3:18 PM",
                "value": 66.83000183105469
            },
            {
                "time": "Mon, Apr 8, 3:19 PM",
                "value": 66.91999816894531
            },
            {
                "time": "Mon, Apr 8, 3:19 PM",
                "value": 66.87000274658203
            },
            {
                "time": "Mon, Apr 8, 3:20 PM",
                "value": 66.94999694824219
            },
            {
                "time": "Mon, Apr 8, 3:20 PM",
                "value": 66.91999816894531
            },
            {
                "time": "Mon, Apr 8, 3:21 PM",
                "value": 67
            },
            {
                "time": "Mon, Apr 8, 3:21 PM",
                "value": 66.9000015258789
            },
            {
                "time": "Mon, Apr 8, 3:22 PM",
                "value": 66.98999786376953
            },
            {
                "time": "Mon, Apr 8, 3:22 PM",
                "value": 66.94999694824219
            },
            {
                "time": "Mon, Apr 8, 3:23 PM",
                "value": 66.95999908447266
            },
            {
                "time": "Mon, Apr 8, 3:23 PM",
                "value": 66.83000183105469
            },
            {
                "time": "Mon, Apr 8, 3:24 PM",
                "value": 66.87999725341797
            },
            {
                "time": "Mon, Apr 8, 3:24 PM",
                "value": 66.7699966430664
            },
            {
                "time": "Mon, Apr 8, 3:25 PM",
                "value": 66.83999633789062
            },
            {
                "time": "Mon, Apr 8, 3:25 PM",
                "value": 66.79000091552734
            },
            {
                "time": "Mon, Apr 8, 3:26 PM",
                "value": 66.87999725341797
            },
            {
                "time": "Mon, Apr 8, 3:26 PM",
                "value": 66.87999725341797
            },
            {
                "time": "Mon, Apr 8, 3:27 PM",
                "value": 66.95999908447266
            },
            {
                "time": "Mon, Apr 8, 3:27 PM",
                "value": 66.86000061035156
            },
            {
                "time": "Mon, Apr 8, 3:28 PM",
                "value": 67
            },
            {
                "time": "Mon, Apr 8, 3:28 PM",
                "value": 66.9800033569336
            },
            {
                "time": "Mon, Apr 8, 3:29 PM",
                "value": 67
            },
            {
                "time": "Mon, Apr 8, 3:29 PM",
                "value": 66.9000015258789
            },
            {
                "time": "Mon, Apr 8, 3:30 PM",
                "value": 66.98999786376953
            },
            {
                "time": "Mon, Apr 8, 3:30 PM",
                "value": 67.02999877929688
            },
            {
                "time": "Mon, Apr 8, 3:31 PM",
                "value": 67.05999755859375
            },
            {
                "time": "Mon, Apr 8, 3:31 PM",
                "value": 67.0199966430664
            },
            {
                "time": "Mon, Apr 8, 3:32 PM",
                "value": 67.0999984741211
            },
            {
                "time": "Mon, Apr 8, 3:32 PM",
                "value": 67.13999938964844
            },
            {
                "time": "Mon, Apr 8, 3:33 PM",
                "value": 67.20999908447266
            },
            {
                "time": "Mon, Apr 8, 3:33 PM",
                "value": 67.08000183105469
            },
            {
                "time": "Mon, Apr 8, 3:34 PM",
                "value": 67.2300033569336
            },
            {
                "time": "Mon, Apr 8, 3:34 PM",
                "value": 67.27999877929688
            },
            {
                "time": "Mon, Apr 8, 3:35 PM",
                "value": 67.27999877929688
            },
            {
                "time": "Mon, Apr 8, 3:35 PM",
                "value": 67.2300033569336
            },
            {
                "time": "Mon, Apr 8, 3:36 PM",
                "value": 67.37999725341797
            },
            {
                "time": "Mon, Apr 8, 3:36 PM",
                "value": 67.4000015258789
            },
            {
                "time": "Mon, Apr 8, 3:37 PM",
                "value": 67.41999816894531
            },
            {
                "time": "Mon, Apr 8, 3:37 PM",
                "value": 67.37999725341797
            },
            {
                "time": "Mon, Apr 8, 3:38 PM",
                "value": 67.45999908447266
            },
            {
                "time": "Mon, Apr 8, 3:38 PM",
                "value": 67.41999816894531
            },
            {
                "time": "Mon, Apr 8, 3:39 PM",
                "value": 67.44999694824219
            },
            {
                "time": "Mon, Apr 8, 3:39 PM",
                "value": 67.4800033569336
            },
            {
                "time": "Mon, Apr 8, 3:40 PM",
                "value": 67.61000061035156
            },
            {
                "time": "Mon, Apr 8, 3:40 PM",
                "value": 67.51000213623047
            },
            {
                "time": "Mon, Apr 8, 3:41 PM",
                "value": 67.6500015258789
            },
            {
                "time": "Mon, Apr 8, 3:41 PM",
                "value": 67.56999969482422
            },
            {
                "time": "Mon, Apr 8, 3:42 PM",
                "value": 67.68000030517578
            },
            {
                "time": "Mon, Apr 8, 3:42 PM",
                "value": 67.62999725341797
            },
            {
                "time": "Mon, Apr 8, 3:43 PM",
                "value": 67.77999877929688
            },
            {
                "time": "Mon, Apr 8, 3:43 PM",
                "value": 67.80999755859375
            },
            {
                "time": "Mon, Apr 8, 3:44 PM",
                "value": 67.93000030517578
            },
            {
                "time": "Mon, Apr 8, 3:44 PM",
                "value": 67.87999725341797
            },
            {
                "time": "Mon, Apr 8, 3:45 PM",
                "value": 68
            },
            {
                "time": "Mon, Apr 8, 3:45 PM",
                "value": 67.94999694824219
            },
            {
                "time": "Mon, Apr 8, 3:46 PM",
                "value": 68.06999969482422
            },
            {
                "time": "Mon, Apr 8, 3:46 PM",
                "value": 68.02999877929688
            },
            {
                "time": "Mon, Apr 8, 3:47 PM",
                "value": 68.1500015258789
            },
            {
                "time": "Mon, Apr 8, 3:47 PM",
                "value": 68.11000061035156
            },
            {
                "time": "Mon, Apr 8, 3:48 PM",
                "value": 68.19999694824219
            },
            {
                "time": "Mon, Apr 8, 3:48 PM",
                "value": 68.12999725341797
            },
            {
                "time": "Mon, Apr 8, 3:49 PM",
                "value": 68.2300033569336
            },
            {
                "time": "Mon, Apr 8, 3:49 PM",
                "value": 68.16999816894531
            },
            {
                "time": "Mon, Apr 8, 3:50 PM",
                "value": 68.2699966430664
            },
            {
                "time": "Mon, Apr 8, 3:50 PM",
                "value": 68.20999908447266
            },
            {
                "time": "Mon, Apr 8, 3:51 PM",
                "value": 68.36000061035156
            },
            {
                "time": "Mon, Apr 8, 3:51 PM",
                "value": 68.3499984741211
            },
            {
                "time": "Mon, Apr 8, 3:52 PM",
                "value": 68.5199966430664
            },
            {
                "time": "Mon, Apr 8, 3:52 PM",
                "value": 68.47000122070312
            },
            {
                "time": "Mon, Apr 8, 3:53 PM",
                "value": 68.58999633789062
            },
            {
                "time": "Mon, Apr 8, 3:53 PM",
                "value": 68.58000183105469
            },
            {
                "time": "Mon, Apr 8, 3:54 PM",
                "value": 68.52999877929688
            },
            {
                "time": "Mon, Apr 8, 3:54 PM",
                "value": 68.52999877929688
            },
            {
                "time": "Mon, Apr 8, 3:55 PM",
                "value": 68.58000183105469
            },
            {
                "time": "Mon, Apr 8, 3:55 PM",
                "value": 68.6500015258789
            },
            {
                "time": "Mon, Apr 8, 3:56 PM",
                "value": 68.72000122070312
            },
            {
                "time": "Mon, Apr 8, 3:56 PM",
                "value": 68.76000213623047
            },
            {
                "time": "Mon, Apr 8, 3:57 PM",
                "value": 68.80999755859375
            },
            {
                "time": "Mon, Apr 8, 3:57 PM",
                "value": 68.75
            },
            {
                "time": "Mon, Apr 8, 3:58 PM",
                "value": 68.81999969482422
            },
            {
                "time": "Mon, Apr 8, 3:58 PM",
                "value": 68.66999816894531
            },
            {
                "time": "Mon, Apr 8, 3:59 PM",
                "value": 68.7300033569336
            },
            {
                "time": "Mon, Apr 8, 3:59 PM",
                "value": 68.63999938964844
            },
            {
                "time": "Mon, Apr 8, 4:00 PM",
                "value": 68.73999786376953
            },
            {
                "time": "Mon, Apr 8, 4:00 PM",
                "value": 68.76000213623047
            },
            {
                "time": "Mon, Apr 8, 4:01 PM",
                "value": 68.8499984741211
            },
            {
                "time": "Mon, Apr 8, 4:01 PM",
                "value": 68.81999969482422
            },
            {
                "time": "Mon, Apr 8, 4:02 PM",
                "value": 69.0199966430664
            },
            {
                "time": "Mon, Apr 8, 4:02 PM",
                "value": 69.01000213623047
            },
            {
                "time": "Mon, Apr 8, 4:03 PM",
                "value": 69.16000366210938
            },
            {
                "time": "Mon, Apr 8, 4:03 PM",
                "value": 69.1500015258789
            },
            {
                "time": "Mon, Apr 8, 4:04 PM",
                "value": 69.30999755859375
            },
            {
                "time": "Mon, Apr 8, 4:04 PM",
                "value": 69.27999877929688
            },
            {
                "time": "Mon, Apr 8, 4:05 PM",
                "value": 69.38999938964844
            },
            {
                "time": "Mon, Apr 8, 4:05 PM",
                "value": 69.33000183105469
            },
            {
                "time": "Mon, Apr 8, 4:06 PM",
                "value": 69.4800033569336
            },
            {
                "time": "Mon, Apr 8, 4:06 PM",
                "value": 69.37000274658203
            },
            {
                "time": "Mon, Apr 8, 4:07 PM",
                "value": 69.44999694824219
            },
            {
                "time": "Mon, Apr 8, 4:07 PM",
                "value": 69.37000274658203
            },
            {
                "time": "Mon, Apr 8, 4:08 PM",
                "value": 69.45999908447266
            },
            {
                "time": "Mon, Apr 8, 4:08 PM",
                "value": 69.41999816894531
            },
            {
                "time": "Mon, Apr 8, 4:09 PM",
                "value": 69.48999786376953
            },
            {
                "time": "Mon, Apr 8, 4:09 PM",
                "value": 69.44000244140625
            },
            {
                "time": "Mon, Apr 8, 4:10 PM",
                "value": 69.5999984741211
            },
            {
                "time": "Mon, Apr 8, 4:10 PM",
                "value": 69.55000305175781
            },
            {
                "time": "Mon, Apr 8, 4:11 PM",
                "value": 69.62999725341797
            },
            {
                "time": "Mon, Apr 8, 4:11 PM",
                "value": 69.55999755859375
            },
            {
                "time": "Mon, Apr 8, 4:12 PM",
                "value": 69.66000366210938
            },
            {
                "time": "Mon, Apr 8, 4:12 PM",
                "value": 69.58000183105469
            },
            {
                "time": "Mon, Apr 8, 4:13 PM",
                "value": 69.66000366210938
            },
            {
                "time": "Mon, Apr 8, 4:13 PM",
                "value": 69.62000274658203
            },
            {
                "time": "Mon, Apr 8, 4:14 PM",
                "value": 69.80999755859375
            },
            {
                "time": "Mon, Apr 8, 4:14 PM",
                "value": 69.80000305175781
            },
            {
                "time": "Mon, Apr 8, 4:15 PM",
                "value": 69.93000030517578
            },
            {
                "time": "Mon, Apr 8, 4:15 PM",
                "value": 69.91000366210938
            },
            {
                "time": "Mon, Apr 8, 4:16 PM",
                "value": 70
            },
            {
                "time": "Mon, Apr 8, 4:16 PM",
                "value": 69.95999908447266
            },
            {
                "time": "Mon, Apr 8, 4:17 PM",
                "value": 69.9800033569336
            },
            {
                "time": "Mon, Apr 8, 4:17 PM",
                "value": 70.01000213623047
            },
            {
                "time": "Mon, Apr 8, 4:18 PM",
                "value": 70.18000030517578
            },
            {
                "time": "Mon, Apr 8, 4:18 PM",
                "value": 70.16000366210938
            },
            {
                "time": "Mon, Apr 8, 4:19 PM",
                "value": 70.36000061035156
            },
            {
                "time": "Mon, Apr 8, 4:19 PM",
                "value": 70.33999633789062
            },
            {
                "time": "Mon, Apr 8, 4:20 PM",
                "value": 70.5
            },
            {
                "time": "Mon, Apr 8, 4:20 PM",
                "value": 70.48999786376953
            },
            {
                "time": "Mon, Apr 8, 4:21 PM",
                "value": 70.61000061035156
            },
            {
                "time": "Mon, Apr 8, 4:21 PM",
                "value": 70.55999755859375
            },
            {
                "time": "Mon, Apr 8, 4:22 PM",
                "value": 70.6500015258789
            },
            {
                "time": "Mon, Apr 8, 4:22 PM",
                "value": 70.62999725341797
            },
            {
                "time": "Mon, Apr 8, 4:23 PM",
                "value": 70.68000030517578
            },
            {
                "time": "Mon, Apr 8, 4:23 PM",
                "value": 70.58000183105469
            },
            {
                "time": "Mon, Apr 8, 4:24 PM",
                "value": 70.6500015258789
            },
            {
                "time": "Mon, Apr 8, 4:24 PM",
                "value": 70.62999725341797
            },
            {
                "time": "Mon, Apr 8, 4:25 PM",
                "value": 70.62999725341797
            },
            {
                "time": "Mon, Apr 8, 4:25 PM",
                "value": 70.5199966430664
            },
            {
                "time": "Mon, Apr 8, 4:26 PM",
                "value": 70.62000274658203
            },
            {
                "time": "Mon, Apr 8, 4:26 PM",
                "value": 70.62999725341797
            },
            {
                "time": "Mon, Apr 8, 4:27 PM",
                "value": 70.70999908447266
            },
            {
                "time": "Mon, Apr 8, 4:27 PM",
                "value": 70.66999816894531
            },
            {
                "time": "Mon, Apr 8, 4:28 PM",
                "value": 70.87000274658203
            },
            {
                "time": "Mon, Apr 8, 4:28 PM",
                "value": 70.8499984741211
            },
            {
                "time": "Mon, Apr 8, 4:29 PM",
                "value": 70.9000015258789
            },
            {
                "time": "Mon, Apr 8, 4:29 PM",
                "value": 70.87999725341797
            },
            {
                "time": "Mon, Apr 8, 4:30 PM",
                "value": 70.97000122070312
            },
            {
                "time": "Mon, Apr 8, 4:30 PM",
                "value": 70.94000244140625
            },
            {
                "time": "Mon, Apr 8, 4:31 PM",
                "value": 71.05000305175781
            },
            {
                "time": "Mon, Apr 8, 4:31 PM",
                "value": 70.95999908447266
            },
            {
                "time": "Mon, Apr 8, 4:32 PM",
                "value": 71.05000305175781
            },
            {
                "time": "Mon, Apr 8, 4:32 PM",
                "value": 71.0199966430664
            },
            {
                "time": "Mon, Apr 8, 4:33 PM",
                "value": 71.01000213623047
            },
            {
                "time": "Mon, Apr 8, 4:33 PM",
                "value": 70.93000030517578
            },
            {
                "time": "Mon, Apr 8, 4:34 PM",
                "value": 70.91999816894531
            },
            {
                "time": "Mon, Apr 8, 4:34 PM",
                "value": 71.01000213623047
            },
            {
                "time": "Mon, Apr 8, 4:35 PM",
                "value": 70.87999725341797
            },
            {
                "time": "Mon, Apr 8, 4:35 PM",
                "value": 70.88999938964844
            },
            {
                "time": "Mon, Apr 8, 4:36 PM",
                "value": 70.97000122070312
            },
            {
                "time": "Mon, Apr 8, 4:36 PM",
                "value": 70.95999908447266
            },
            {
                "time": "Mon, Apr 8, 4:37 PM",
                "value": 71.08999633789062
            },
            {
                "time": "Mon, Apr 8, 4:37 PM",
                "value": 71.13999938964844
            },
            {
                "time": "Mon, Apr 8, 4:38 PM",
                "value": 71.3499984741211
            },
            {
                "time": "Mon, Apr 8, 4:38 PM",
                "value": 71.30999755859375
            },
            {
                "time": "Mon, Apr 8, 4:39 PM",
                "value": 71.44000244140625
            },
            {
                "time": "Mon, Apr 8, 4:39 PM",
                "value": 71.38999938964844
            },
            {
                "time": "Mon, Apr 8, 4:40 PM",
                "value": 71.47000122070312
            },
            {
                "time": "Mon, Apr 8, 4:40 PM",
                "value": 71.4800033569336
            },
            {
                "time": "Mon, Apr 8, 4:41 PM",
                "value": 71.45999908447266
            },
            {
                "time": "Mon, Apr 8, 4:41 PM",
                "value": 71.31999969482422
            },
            {
                "time": "Mon, Apr 8, 4:42 PM",
                "value": 71.37999725341797
            },
            {
                "time": "Mon, Apr 8, 4:42 PM",
                "value": 71.37000274658203
            },
            {
                "time": "Mon, Apr 8, 4:43 PM",
                "value": 71.33000183105469
            },
            {
                "time": "Mon, Apr 8, 4:43 PM",
                "value": 71.16999816894531
            },
            {
                "time": "Mon, Apr 8, 4:44 PM",
                "value": 71.23999786376953
            },
            {
                "time": "Mon, Apr 8, 4:44 PM",
                "value": 71.16000366210938
            },
            {
                "time": "Mon, Apr 8, 4:45 PM",
                "value": 71.30000305175781
            },
            {
                "time": "Mon, Apr 8, 4:45 PM",
                "value": 71.26000213623047
            },
            {
                "time": "Mon, Apr 8, 4:46 PM",
                "value": 71.36000061035156
            },
            {
                "time": "Mon, Apr 8, 4:46 PM",
                "value": 71.4000015258789
            },
            {
                "time": "Mon, Apr 8, 4:47 PM",
                "value": 71.4000015258789
            },
            {
                "time": "Mon, Apr 8, 4:47 PM",
                "value": 71.31999969482422
            },
            {
                "time": "Mon, Apr 8, 4:48 PM",
                "value": 71.44000244140625
            },
            {
                "time": "Mon, Apr 8, 4:48 PM",
                "value": 71.3499984741211
            },
            {
                "time": "Mon, Apr 8, 4:49 PM",
                "value": 71.45999908447266
            },
            {
                "time": "Mon, Apr 8, 4:49 PM",
                "value": 71.3499984741211
            },
            {
                "time": "Mon, Apr 8, 4:50 PM",
                "value": 71.44000244140625
            },
            {
                "time": "Mon, Apr 8, 4:50 PM",
                "value": 71.4000015258789
            },
            {
                "time": "Mon, Apr 8, 4:51 PM",
                "value": 71.48999786376953
            },
            {
                "time": "Mon, Apr 8, 4:51 PM",
                "value": 71.38999938964844
            },
            {
                "time": "Mon, Apr 8, 4:52 PM",
                "value": 71.47000122070312
            },
            {
                "time": "Mon, Apr 8, 4:52 PM",
                "value": 71.47000122070312
            },
            {
                "time": "Mon, Apr 8, 4:53 PM",
                "value": 71.48999786376953
            },
            {
                "time": "Mon, Apr 8, 4:53 PM",
                "value": 71.38999938964844
            },
            {
                "time": "Mon, Apr 8, 4:54 PM",
                "value": 71.48999786376953
            },
            {
                "time": "Mon, Apr 8, 4:54 PM",
                "value": 71.38999938964844
            },
            {
                "time": "Mon, Apr 8, 4:55 PM",
                "value": 71.4800033569336
            },
            {
                "time": "Mon, Apr 8, 4:55 PM",
                "value": 71.4000015258789
            },
            {
                "time": "Mon, Apr 8, 4:56 PM",
                "value": 71.5199966430664
            },
            {
                "time": "Mon, Apr 8, 4:56 PM",
                "value": 71.5199966430664
            },
            {
                "time": "Mon, Apr 8, 4:57 PM",
                "value": 71.58000183105469
            },
            {
                "time": "Mon, Apr 8, 4:57 PM",
                "value": 71.54000091552734
            },
            {
                "time": "Mon, Apr 8, 4:58 PM",
                "value": 71.62999725341797
            },
            {
                "time": "Mon, Apr 8, 4:58 PM",
                "value": 71.54000091552734
            },
            {
                "time": "Mon, Apr 8, 4:59 PM",
                "value": 71.62000274658203
            },
            {
                "time": "Mon, Apr 8, 4:59 PM",
                "value": 71.54000091552734
            },
            {
                "time": "Mon, Apr 8, 5:00 PM",
                "value": 71.62000274658203
            },
            {
                "time": "Mon, Apr 8, 5:00 PM",
                "value": 71.73999786376953
            },
            {
                "time": "Mon, Apr 8, 5:01 PM",
                "value": 71.73999786376953
            },
            {
                "time": "Mon, Apr 8, 5:01 PM",
                "value": 71.83999633789062
            },
            {
                "time": "Mon, Apr 8, 5:02 PM",
                "value": 71.91000366210938
            },
            {
                "time": "Mon, Apr 8, 5:02 PM",
                "value": 71.87999725341797
            },
            {
                "time": "Mon, Apr 8, 5:03 PM",
                "value": 71.95999908447266
            },
            {
                "time": "Mon, Apr 8, 5:03 PM",
                "value": 71.86000061035156
            },
            {
                "time": "Mon, Apr 8, 5:04 PM",
                "value": 71.86000061035156
            },
            {
                "time": "Mon, Apr 8, 5:04 PM",
                "value": 71.81999969482422
            },
            {
                "time": "Mon, Apr 8, 5:05 PM",
                "value": 71.83999633789062
            },
            {
                "time": "Mon, Apr 8, 5:05 PM",
                "value": 71.83000183105469
            },
            {
                "time": "Mon, Apr 8, 5:06 PM",
                "value": 71.95999908447266
            },
            {
                "time": "Mon, Apr 8, 5:06 PM",
                "value": 71.9000015258789
            },
            {
                "time": "Mon, Apr 8, 5:07 PM",
                "value": 71.95999908447266
            },
            {
                "time": "Mon, Apr 8, 5:07 PM",
                "value": 72.0999984741211
            },
            {
                "time": "Mon, Apr 8, 5:08 PM",
                "value": 72.16999816894531
            },
            {
                "time": "Mon, Apr 8, 5:08 PM",
                "value": 72.19000244140625
            },
            {
                "time": "Mon, Apr 8, 5:09 PM",
                "value": 72.33999633789062
            },
            {
                "time": "Mon, Apr 8, 5:09 PM",
                "value": 72.25
            },
            {
                "time": "Mon, Apr 8, 5:10 PM",
                "value": 72.30999755859375
            },
            {
                "time": "Mon, Apr 8, 5:10 PM",
                "value": 72.16999816894531
            },
            {
                "time": "Mon, Apr 8, 5:11 PM",
                "value": 72.19000244140625
            },
            {
                "time": "Mon, Apr 8, 5:11 PM",
                "value": 72.08000183105469
            },
            {
                "time": "Mon, Apr 8, 5:12 PM",
                "value": 72.0999984741211
            },
            {
                "time": "Mon, Apr 8, 5:12 PM",
                "value": 72.01000213623047
            },
            {
                "time": "Mon, Apr 8, 5:13 PM",
                "value": 72.12999725341797
            },
            {
                "time": "Mon, Apr 8, 5:13 PM",
                "value": 72.08000183105469
            },
            {
                "time": "Mon, Apr 8, 5:14 PM",
                "value": 72.18000030517578
            },
            {
                "time": "Mon, Apr 8, 5:14 PM",
                "value": 72.0999984741211
            },
            {
                "time": "Mon, Apr 8, 5:15 PM",
                "value": 72.06999969482422
            },
            {
                "time": "Mon, Apr 8, 5:15 PM",
                "value": 72.08000183105469
            },
            {
                "time": "Mon, Apr 8, 5:16 PM",
                "value": 72.16999816894531
            },
            {
                "time": "Mon, Apr 8, 5:16 PM",
                "value": 72.16000366210938
            },
            {
                "time": "Mon, Apr 8, 5:17 PM",
                "value": 72.2300033569336
            },
            {
                "time": "Mon, Apr 8, 5:17 PM",
                "value": 72.25
            },
            {
                "time": "Mon, Apr 8, 5:18 PM",
                "value": 72.23999786376953
            },
            {
                "time": "Mon, Apr 8, 5:18 PM",
                "value": 72.11000061035156
            },
            {
                "time": "Mon, Apr 8, 5:19 PM",
                "value": 72.22000122070312
            },
            {
                "time": "Mon, Apr 8, 5:19 PM",
                "value": 72.11000061035156
            },
            {
                "time": "Mon, Apr 8, 5:20 PM",
                "value": 72.22000122070312
            },
            {
                "time": "Mon, Apr 8, 5:20 PM",
                "value": 72.1500015258789
            },
            {
                "time": "Mon, Apr 8, 5:21 PM",
                "value": 72.27999877929688
            },
            {
                "time": "Mon, Apr 8, 5:21 PM",
                "value": 72.13999938964844
            },
            {
                "time": "Mon, Apr 8, 5:22 PM",
                "value": 72.19999694824219
            },
            {
                "time": "Mon, Apr 8, 5:22 PM",
                "value": 72.1500015258789
            },
            {
                "time": "Mon, Apr 8, 5:23 PM",
                "value": 72.22000122070312
            },
            {
                "time": "Mon, Apr 8, 5:23 PM",
                "value": 72.41999816894531
            },
            {
                "time": "Mon, Apr 8, 5:24 PM",
                "value": 72.48999786376953
            },
            {
                "time": "Mon, Apr 8, 5:24 PM",
                "value": 72.61000061035156
            },
            {
                "time": "Mon, Apr 8, 5:25 PM",
                "value": 72.62999725341797
            },
            {
                "time": "Mon, Apr 8, 5:25 PM",
                "value": 72.66000366210938
            },
            {
                "time": "Mon, Apr 8, 5:26 PM",
                "value": 72.77999877929688
            },
            {
                "time": "Mon, Apr 8, 5:26 PM",
                "value": 72.6500015258789
            },
            {
                "time": "Mon, Apr 8, 5:27 PM",
                "value": 72.70999908447266
            },
            {
                "time": "Mon, Apr 8, 5:27 PM",
                "value": 72.62000274658203
            },
            {
                "time": "Mon, Apr 8, 5:28 PM",
                "value": 72.72000122070312
            },
            {
                "time": "Mon, Apr 8, 5:28 PM",
                "value": 72.69000244140625
            },
            {
                "time": "Mon, Apr 8, 5:29 PM",
                "value": 72.77999877929688
            },
            {
                "time": "Mon, Apr 8, 5:29 PM",
                "value": 72.73999786376953
            },
            {
                "time": "Mon, Apr 8, 5:30 PM",
                "value": 72.75
            },
            {
                "time": "Mon, Apr 8, 5:30 PM",
                "value": 72.69000244140625
            },
            {
                "time": "Mon, Apr 8, 5:31 PM",
                "value": 72.66000366210938
            },
            {
                "time": "Mon, Apr 8, 5:31 PM",
                "value": 72.62000274658203
            },
            {
                "time": "Mon, Apr 8, 5:32 PM",
                "value": 72.68000030517578
            },
            {
                "time": "Mon, Apr 8, 5:32 PM",
                "value": 72.62000274658203
            },
            {
                "time": "Mon, Apr 8, 5:33 PM",
                "value": 72.62999725341797
            },
            {
                "time": "Mon, Apr 8, 5:33 PM",
                "value": 72.55000305175781
            },
            {
                "time": "Mon, Apr 8, 5:34 PM",
                "value": 72.63999938964844
            },
            {
                "time": "Mon, Apr 8, 5:34 PM",
                "value": 72.66000366210938
            },
            {
                "time": "Mon, Apr 8, 5:35 PM",
                "value": 72.73999786376953
            },
            {
                "time": "Mon, Apr 8, 5:35 PM",
                "value": 72.69000244140625
            },
            {
                "time": "Mon, Apr 8, 5:36 PM",
                "value": 72.77999877929688
            },
            {
                "time": "Mon, Apr 8, 5:36 PM",
                "value": 72.73999786376953
            },
            {
                "time": "Mon, Apr 8, 5:37 PM",
                "value": 72.68000030517578
            },
            {
                "time": "Mon, Apr 8, 5:37 PM",
                "value": 72.54000091552734
            },
            {
                "time": "Mon, Apr 8, 5:38 PM",
                "value": 72.4800033569336
            },
            {
                "time": "Mon, Apr 8, 5:38 PM",
                "value": 72.4800033569336
            },
            {
                "time": "Mon, Apr 8, 5:39 PM",
                "value": 72.55000305175781
            },
            {
                "time": "Mon, Apr 8, 5:39 PM",
                "value": 72.52999877929688
            },
            {
                "time": "Mon, Apr 8, 5:40 PM",
                "value": 72.52999877929688
            },
            {
                "time": "Mon, Apr 8, 5:40 PM",
                "value": 72.44000244140625
            },
            {
                "time": "Mon, Apr 8, 5:41 PM",
                "value": 72.54000091552734
            },
            {
                "time": "Mon, Apr 8, 5:41 PM",
                "value": 72.4000015258789
            },
            {
                "time": "Mon, Apr 8, 5:42 PM",
                "value": 72.48999786376953
            },
            {
                "time": "Mon, Apr 8, 5:42 PM",
                "value": 72.44999694824219
            },
            {
                "time": "Mon, Apr 8, 5:43 PM",
                "value": 72.56999969482422
            },
            {
                "time": "Mon, Apr 8, 5:43 PM",
                "value": 72.51000213623047
            },
            {
                "time": "Mon, Apr 8, 5:44 PM",
                "value": 72.61000061035156
            },
            {
                "time": "Mon, Apr 8, 5:44 PM",
                "value": 72.48999786376953
            },
            {
                "time": "Mon, Apr 8, 5:45 PM",
                "value": 72.52999877929688
            },
            {
                "time": "Mon, Apr 8, 5:45 PM",
                "value": 72.43000030517578
            },
            {
                "time": "Mon, Apr 8, 5:46 PM",
                "value": 72.55999755859375
            },
            {
                "time": "Mon, Apr 8, 5:46 PM",
                "value": 72.44000244140625
            },
            {
                "time": "Mon, Apr 8, 5:47 PM",
                "value": 72.4800033569336
            },
            {
                "time": "Mon, Apr 8, 5:47 PM",
                "value": 72.47000122070312
            },
            {
                "time": "Mon, Apr 8, 5:48 PM",
                "value": 72.51000213623047
            },
            {
                "time": "Mon, Apr 8, 5:48 PM",
                "value": 72.68000030517578
            },
            {
                "time": "Mon, Apr 8, 5:49 PM",
                "value": 72.70999908447266
            },
            {
                "time": "Mon, Apr 8, 5:49 PM",
                "value": 72.68000030517578
            },
            {
                "time": "Mon, Apr 8, 5:50 PM",
                "value": 72.76000213623047
            },
            {
                "time": "Mon, Apr 8, 5:50 PM",
                "value": 72.73999786376953
            },
            {
                "time": "Mon, Apr 8, 5:51 PM",
                "value": 72.86000061035156
            },
            {
                "time": "Mon, Apr 8, 5:51 PM",
                "value": 72.81999969482422
            },
            {
                "time": "Mon, Apr 8, 5:52 PM",
                "value": 72.88999938964844
            },
            {
                "time": "Mon, Apr 8, 5:52 PM",
                "value": 72.80000305175781
            },
            {
                "time": "Mon, Apr 8, 5:53 PM",
                "value": 72.9000015258789
            },
            {
                "time": "Mon, Apr 8, 5:53 PM",
                "value": 72.80999755859375
            },
            {
                "time": "Mon, Apr 8, 5:54 PM",
                "value": 72.80999755859375
            },
            {
                "time": "Mon, Apr 8, 5:54 PM",
                "value": 72.81999969482422
            },
            {
                "time": "Mon, Apr 8, 5:55 PM",
                "value": 72.9000015258789
            },
            {
                "time": "Mon, Apr 8, 5:55 PM",
                "value": 72.81999969482422
            },
            {
                "time": "Mon, Apr 8, 5:56 PM",
                "value": 72.9000015258789
            },
            {
                "time": "Mon, Apr 8, 5:56 PM",
                "value": 72.80000305175781
            },
            {
                "time": "Mon, Apr 8, 5:57 PM",
                "value": 72.97000122070312
            },
            {
                "time": "Mon, Apr 8, 5:57 PM",
                "value": 72.83999633789062
            },
            {
                "time": "Mon, Apr 8, 5:58 PM",
                "value": 72.94999694824219
            },
            {
                "time": "Mon, Apr 8, 5:58 PM",
                "value": 72.80999755859375
            },
            {
                "time": "Mon, Apr 8, 5:59 PM",
                "value": 72.83000183105469
            },
            {
                "time": "Mon, Apr 8, 5:59 PM",
                "value": 72.69999694824219
            },
            {
                "time": "Mon, Apr 8, 6:00 PM",
                "value": 72.75
            },
            {
                "time": "Mon, Apr 8, 6:00 PM",
                "value": 72.62000274658203
            },
            {
                "time": "Mon, Apr 8, 6:01 PM",
                "value": 72.70999908447266
            },
            {
                "time": "Mon, Apr 8, 6:01 PM",
                "value": 72.62999725341797
            },
            {
                "time": "Mon, Apr 8, 6:02 PM",
                "value": 72.72000122070312
            },
            {
                "time": "Mon, Apr 8, 6:02 PM",
                "value": 72.58999633789062
            },
            {
                "time": "Mon, Apr 8, 6:03 PM",
                "value": 72.58999633789062
            },
            {
                "time": "Mon, Apr 8, 6:03 PM",
                "value": 72.5999984741211
            },
            {
                "time": "Mon, Apr 8, 6:04 PM",
                "value": 72.5999984741211
            },
            {
                "time": "Mon, Apr 8, 6:04 PM",
                "value": 72.58999633789062
            },
            {
                "time": "Mon, Apr 8, 6:05 PM",
                "value": 72.61000061035156
            },
            {
                "time": "Mon, Apr 8, 6:05 PM",
                "value": 72.62000274658203
            },
            {
                "time": "Mon, Apr 8, 6:06 PM",
                "value": 72.68000030517578
            },
            {
                "time": "Mon, Apr 8, 6:06 PM",
                "value": 72.68000030517578
            },
            {
                "time": "Mon, Apr 8, 6:07 PM",
                "value": 72.6500015258789
            },
            {
                "time": "Mon, Apr 8, 6:07 PM",
                "value": 72.55000305175781
            },
            {
                "time": "Mon, Apr 8, 6:08 PM",
                "value": 72.5999984741211
            },
            {
                "time": "Mon, Apr 8, 6:08 PM",
                "value": 72.51000213623047
            },
            {
                "time": "Mon, Apr 8, 6:09 PM",
                "value": 72.6500015258789
            },
            {
                "time": "Mon, Apr 8, 6:09 PM",
                "value": 72.55000305175781
            },
            {
                "time": "Mon, Apr 8, 6:10 PM",
                "value": 72.54000091552734
            },
            {
                "time": "Mon, Apr 8, 6:10 PM",
                "value": 72.58999633789062
            },
            {
                "time": "Mon, Apr 8, 6:11 PM",
                "value": 72.68000030517578
            },
            {
                "time": "Mon, Apr 8, 6:11 PM",
                "value": 72.58999633789062
            },
            {
                "time": "Mon, Apr 8, 6:12 PM",
                "value": 72.7699966430664
            },
            {
                "time": "Mon, Apr 8, 6:12 PM",
                "value": 72.62000274658203
            },
            {
                "time": "Mon, Apr 8, 6:13 PM",
                "value": 72.70999908447266
            },
            {
                "time": "Mon, Apr 8, 6:13 PM",
                "value": 72.6500015258789
            },
            {
                "time": "Mon, Apr 8, 6:14 PM",
                "value": 72.76000213623047
            },
            {
                "time": "Mon, Apr 8, 6:14 PM",
                "value": 72.69000244140625
            },
            {
                "time": "Mon, Apr 8, 6:15 PM",
                "value": 72.77999877929688
            },
            {
                "time": "Mon, Apr 8, 6:15 PM",
                "value": 72.70999908447266
            },
            {
                "time": "Mon, Apr 8, 6:16 PM",
                "value": 72.75
            },
            {
                "time": "Mon, Apr 8, 6:16 PM",
                "value": 72.62999725341797
            },
            {
                "time": "Mon, Apr 8, 6:17 PM",
                "value": 72.75
            },
            {
                "time": "Mon, Apr 8, 6:17 PM",
                "value": 72.62999725341797
            },
            {
                "time": "Mon, Apr 8, 6:18 PM",
                "value": 72.62000274658203
            },
            {
                "time": "Mon, Apr 8, 6:18 PM",
                "value": 72.62000274658203
            },
            {
                "time": "Mon, Apr 8, 6:19 PM",
                "value": 72.7300033569336
            },
            {
                "time": "Mon, Apr 8, 6:19 PM",
                "value": 72.62999725341797
            },
            {
                "time": "Mon, Apr 8, 6:20 PM",
                "value": 72.68000030517578
            },
            {
                "time": "Mon, Apr 8, 6:20 PM",
                "value": 72.66000366210938
            },
            {
                "time": "Mon, Apr 8, 6:21 PM",
                "value": 72.68000030517578
            },
            {
                "time": "Mon, Apr 8, 6:21 PM",
                "value": 72.58999633789062
            },
            {
                "time": "Mon, Apr 8, 6:22 PM",
                "value": 72.68000030517578
            },
            {
                "time": "Mon, Apr 8, 6:22 PM",
                "value": 72.62999725341797
            },
            {
                "time": "Mon, Apr 8, 6:23 PM",
                "value": 72.72000122070312
            },
            {
                "time": "Mon, Apr 8, 6:23 PM",
                "value": 72.69000244140625
            },
            {
                "time": "Mon, Apr 8, 6:24 PM",
                "value": 72.94999694824219
            },
            {
                "time": "Mon, Apr 8, 6:24 PM",
                "value": 72.83000183105469
            },
            {
                "time": "Mon, Apr 8, 6:25 PM",
                "value": 72.93000030517578
            },
            {
                "time": "Mon, Apr 8, 6:25 PM",
                "value": 72.87999725341797
            },
            {
                "time": "Mon, Apr 8, 6:26 PM",
                "value": 72.97000122070312
            },
            {
                "time": "Mon, Apr 8, 6:26 PM",
                "value": 72.94999694824219
            },
            {
                "time": "Mon, Apr 8, 6:27 PM",
                "value": 73.11000061035156
            },
            {
                "time": "Mon, Apr 8, 6:27 PM",
                "value": 73.1500015258789
            },
            {
                "time": "Mon, Apr 8, 6:28 PM",
                "value": 73.20999908447266
            },
            {
                "time": "Mon, Apr 8, 6:28 PM",
                "value": 73.05999755859375
            },
            {
                "time": "Mon, Apr 8, 6:29 PM",
                "value": 73.18000030517578
            },
            {
                "time": "Mon, Apr 8, 6:29 PM",
                "value": 73.16000366210938
            },
            {
                "time": "Mon, Apr 8, 6:30 PM",
                "value": 73.30000305175781
            },
            {
                "time": "Mon, Apr 8, 6:30 PM",
                "value": 73.16000366210938
            },
            {
                "time": "Mon, Apr 8, 6:31 PM",
                "value": 73.25
            },
            {
                "time": "Mon, Apr 8, 6:31 PM",
                "value": 73.12000274658203
            },
            {
                "time": "Mon, Apr 8, 6:32 PM",
                "value": 73.25
            },
            {
                "time": "Mon, Apr 8, 6:32 PM",
                "value": 73.08999633789062
            },
            {
                "time": "Mon, Apr 8, 6:33 PM",
                "value": 73.18000030517578
            },
            {
                "time": "Mon, Apr 8, 6:33 PM",
                "value": 73.08999633789062
            },
            {
                "time": "Mon, Apr 8, 6:34 PM",
                "value": 73.08999633789062
            },
            {
                "time": "Mon, Apr 8, 6:34 PM",
                "value": 73.0199966430664
            },
            {
                "time": "Mon, Apr 8, 6:35 PM",
                "value": 73.08000183105469
            },
            {
                "time": "Mon, Apr 8, 6:35 PM",
                "value": 72.94000244140625
            },
            {
                "time": "Mon, Apr 8, 6:36 PM",
                "value": 73
            },
            {
                "time": "Mon, Apr 8, 6:36 PM",
                "value": 72.91000366210938
            },
            {
                "time": "Mon, Apr 8, 6:37 PM",
                "value": 72.95999908447266
            },
            {
                "time": "Mon, Apr 8, 6:37 PM",
                "value": 72.8499984741211
            },
            {
                "time": "Mon, Apr 8, 6:38 PM",
                "value": 72.94000244140625
            },
            {
                "time": "Mon, Apr 8, 6:38 PM",
                "value": 72.76000213623047
            },
            {
                "time": "Mon, Apr 8, 6:39 PM",
                "value": 72.79000091552734
            },
            {
                "time": "Mon, Apr 8, 6:39 PM",
                "value": 72.6500015258789
            },
            {
                "time": "Mon, Apr 8, 6:40 PM",
                "value": 72.70999908447266
            },
            {
                "time": "Mon, Apr 8, 6:40 PM",
                "value": 72.58000183105469
            },
            {
                "time": "Mon, Apr 8, 6:41 PM",
                "value": 72.63999938964844
            },
            {
                "time": "Mon, Apr 8, 6:41 PM",
                "value": 72.51000213623047
            },
            {
                "time": "Mon, Apr 8, 6:42 PM",
                "value": 72.51000213623047
            },
            {
                "time": "Mon, Apr 8, 6:42 PM",
                "value": 72.51000213623047
            },
            {
                "time": "Mon, Apr 8, 6:43 PM",
                "value": 72.56999969482422
            },
            {
                "time": "Mon, Apr 8, 6:43 PM",
                "value": 72.5
            },
            {
                "time": "Mon, Apr 8, 6:44 PM",
                "value": 72.52999877929688
            },
            {
                "time": "Mon, Apr 8, 6:44 PM",
                "value": 72.44999694824219
            },
            {
                "time": "Mon, Apr 8, 6:45 PM",
                "value": 72.5
            },
            {
                "time": "Mon, Apr 8, 6:45 PM",
                "value": 72.44999694824219
            },
            {
                "time": "Mon, Apr 8, 6:46 PM",
                "value": 72.4800033569336
            },
            {
                "time": "Mon, Apr 8, 6:46 PM",
                "value": 72.4000015258789
            },
            {
                "time": "Mon, Apr 8, 6:47 PM",
                "value": 72.52999877929688
            },
            {
                "time": "Mon, Apr 8, 6:47 PM",
                "value": 72.48999786376953
            },
            {
                "time": "Mon, Apr 8, 6:48 PM",
                "value": 72.56999969482422
            },
            {
                "time": "Mon, Apr 8, 6:48 PM",
                "value": 72.51000213623047
            },
            {
                "time": "Mon, Apr 8, 6:49 PM",
                "value": 72.5999984741211
            },
            {
                "time": "Mon, Apr 8, 6:49 PM",
                "value": 72.58000183105469
            },
            {
                "time": "Mon, Apr 8, 6:50 PM",
                "value": 72.63999938964844
            },
            {
                "time": "Mon, Apr 8, 6:50 PM",
                "value": 72.56999969482422
            },
            {
                "time": "Mon, Apr 8, 6:51 PM",
                "value": 72.62000274658203
            },
            {
                "time": "Mon, Apr 8, 6:51 PM",
                "value": 72.5199966430664
            },
            {
                "time": "Mon, Apr 8, 6:52 PM",
                "value": 72.55999755859375
            },
            {
                "time": "Mon, Apr 8, 6:52 PM",
                "value": 72.44000244140625
            },
            {
                "time": "Mon, Apr 8, 6:53 PM",
                "value": 72.48999786376953
            },
            {
                "time": "Mon, Apr 8, 6:53 PM",
                "value": 72.36000061035156
            },
            {
                "time": "Mon, Apr 8, 6:54 PM",
                "value": 72.41999816894531
            },
            {
                "time": "Mon, Apr 8, 6:54 PM",
                "value": 72.29000091552734
            },
            {
                "time": "Mon, Apr 8, 6:55 PM",
                "value": 72.41999816894531
            },
            {
                "time": "Mon, Apr 8, 6:55 PM",
                "value": 72.2699966430664
            },
            {
                "time": "Mon, Apr 8, 6:56 PM",
                "value": 72.30999755859375
            },
            {
                "time": "Mon, Apr 8, 6:56 PM",
                "value": 72.19000244140625
            },
            {
                "time": "Mon, Apr 8, 6:57 PM",
                "value": 72.23999786376953
            },
            {
                "time": "Mon, Apr 8, 6:57 PM",
                "value": 72.1500015258789
            },
            {
                "time": "Mon, Apr 8, 6:58 PM",
                "value": 72.16000366210938
            },
            {
                "time": "Mon, Apr 8, 6:58 PM",
                "value": 72.18000030517578
            },
            {
                "time": "Mon, Apr 8, 6:59 PM",
                "value": 72.30999755859375
            },
            {
                "time": "Mon, Apr 8, 6:59 PM",
                "value": 72.22000122070312
            },
            {
                "time": "Mon, Apr 8, 7:00 PM",
                "value": 72.31999969482422
            },
            {
                "time": "Mon, Apr 8, 7:00 PM",
                "value": 72.22000122070312
            },
            {
                "time": "Mon, Apr 8, 7:01 PM",
                "value": 72.30999755859375
            },
            {
                "time": "Mon, Apr 8, 7:01 PM",
                "value": 72.22000122070312
            },
            {
                "time": "Mon, Apr 8, 7:02 PM",
                "value": 72.38999938964844
            },
            {
                "time": "Mon, Apr 8, 7:02 PM",
                "value": 72.22000122070312
            },
            {
                "time": "Mon, Apr 8, 7:03 PM",
                "value": 72.31999969482422
            },
            {
                "time": "Mon, Apr 8, 7:03 PM",
                "value": 72.19999694824219
            },
            {
                "time": "Mon, Apr 8, 7:04 PM",
                "value": 72.3499984741211
            },
            {
                "time": "Mon, Apr 8, 7:04 PM",
                "value": 72.19000244140625
            },
            {
                "time": "Mon, Apr 8, 7:05 PM",
                "value": 72.33999633789062
            },
            {
                "time": "Mon, Apr 8, 7:05 PM",
                "value": 72.16999816894531
            },
            {
                "time": "Mon, Apr 8, 7:06 PM",
                "value": 72.19000244140625
            },
            {
                "time": "Mon, Apr 8, 7:06 PM",
                "value": 72.12000274658203
            },
            {
                "time": "Mon, Apr 8, 7:07 PM",
                "value": 72.08000183105469
            },
            {
                "time": "Mon, Apr 8, 7:07 PM",
                "value": 72.08000183105469
            },
            {
                "time": "Mon, Apr 8, 7:08 PM",
                "value": 72.16999816894531
            },
            {
                "time": "Mon, Apr 8, 7:08 PM",
                "value": 72.0999984741211
            },
            {
                "time": "Mon, Apr 8, 7:09 PM",
                "value": 72.12999725341797
            },
            {
                "time": "Mon, Apr 8, 7:09 PM",
                "value": 72.12999725341797
            },
            {
                "time": "Mon, Apr 8, 7:10 PM",
                "value": 72.12999725341797
            },
            {
                "time": "Mon, Apr 8, 7:10 PM",
                "value": 72.01000213623047
            },
            {
                "time": "Mon, Apr 8, 7:11 PM",
                "value": 72.08999633789062
            },
            {
                "time": "Mon, Apr 8, 7:11 PM",
                "value": 71.97000122070312
            },
            {
                "time": "Mon, Apr 8, 7:12 PM",
                "value": 72.0199966430664
            },
            {
                "time": "Mon, Apr 8, 7:12 PM",
                "value": 71.93000030517578
            },
            {
                "time": "Mon, Apr 8, 7:13 PM",
                "value": 72.02999877929688
            },
            {
                "time": "Mon, Apr 8, 7:13 PM",
                "value": 71.93000030517578
            },
            {
                "time": "Mon, Apr 8, 7:14 PM",
                "value": 71.93000030517578
            },
            {
                "time": "Mon, Apr 8, 7:14 PM",
                "value": 71.93000030517578
            },
            {
                "time": "Mon, Apr 8, 7:15 PM",
                "value": 71.97000122070312
            },
            {
                "time": "Mon, Apr 8, 7:15 PM",
                "value": 71.97000122070312
            },
            {
                "time": "Mon, Apr 8, 7:16 PM",
                "value": 72.05999755859375
            },
            {
                "time": "Mon, Apr 8, 7:16 PM",
                "value": 71.97000122070312
            },
            {
                "time": "Mon, Apr 8, 7:17 PM",
                "value": 72.05999755859375
            },
            {
                "time": "Mon, Apr 8, 7:17 PM",
                "value": 71.97000122070312
            },
            {
                "time": "Mon, Apr 8, 7:18 PM",
                "value": 72.05999755859375
            },
            {
                "time": "Mon, Apr 8, 7:18 PM",
                "value": 72.0199966430664
            },
            {
                "time": "Mon, Apr 8, 7:19 PM",
                "value": 72.06999969482422
            },
            {
                "time": "Mon, Apr 8, 7:19 PM",
                "value": 72
            },
            {
                "time": "Mon, Apr 8, 7:20 PM",
                "value": 72.0199966430664
            },
            {
                "time": "Mon, Apr 8, 7:20 PM",
                "value": 71.93000030517578
            },
            {
                "time": "Mon, Apr 8, 7:21 PM",
                "value": 72.0199966430664
            },
            {
                "time": "Mon, Apr 8, 7:21 PM",
                "value": 71.88999938964844
            },
            {
                "time": "Mon, Apr 8, 7:22 PM",
                "value": 72.02999877929688
            },
            {
                "time": "Mon, Apr 8, 7:22 PM",
                "value": 71.86000061035156
            },
            {
                "time": "Mon, Apr 8, 7:23 PM",
                "value": 71.87999725341797
            },
            {
                "time": "Mon, Apr 8, 7:23 PM",
                "value": 71.87999725341797
            },
            {
                "time": "Mon, Apr 8, 7:24 PM",
                "value": 71.95999908447266
            },
            {
                "time": "Mon, Apr 8, 7:24 PM",
                "value": 71.83000183105469
            },
            {
                "time": "Mon, Apr 8, 7:25 PM",
                "value": 71.91999816894531
            },
            {
                "time": "Mon, Apr 8, 7:25 PM",
                "value": 71.83000183105469
            },
            {
                "time": "Mon, Apr 8, 7:26 PM",
                "value": 71.8499984741211
            },
            {
                "time": "Mon, Apr 8, 7:26 PM",
                "value": 71.76000213623047
            },
            {
                "time": "Mon, Apr 8, 7:27 PM",
                "value": 71.7699966430664
            },
            {
                "time": "Mon, Apr 8, 7:27 PM",
                "value": 71.68000030517578
            },
            {
                "time": "Mon, Apr 8, 7:28 PM",
                "value": 71.7300033569336
            },
            {
                "time": "Mon, Apr 8, 7:28 PM",
                "value": 71.6500015258789
            },
            {
                "time": "Mon, Apr 8, 7:29 PM",
                "value": 71.73999786376953
            },
            {
                "time": "Mon, Apr 8, 7:29 PM",
                "value": 71.61000061035156
            },
            {
                "time": "Mon, Apr 8, 7:30 PM",
                "value": 71.61000061035156
            },
            {
                "time": "Mon, Apr 8, 7:30 PM",
                "value": 71.56999969482422
            },
            {
                "time": "Mon, Apr 8, 7:31 PM",
                "value": 71.66000366210938
            },
            {
                "time": "Mon, Apr 8, 7:31 PM",
                "value": 71.56999969482422
            },
            {
                "time": "Mon, Apr 8, 7:32 PM",
                "value": 71.66999816894531
            },
            {
                "time": "Mon, Apr 8, 7:32 PM",
                "value": 71.6500015258789
            },
            {
                "time": "Mon, Apr 8, 7:33 PM",
                "value": 71.66000366210938
            },
            {
                "time": "Mon, Apr 8, 7:33 PM",
                "value": 71.66000366210938
            },
            {
                "time": "Mon, Apr 8, 7:34 PM",
                "value": 71.62000274658203
            },
            {
                "time": "Mon, Apr 8, 7:34 PM",
                "value": 71.48999786376953
            },
            {
                "time": "Mon, Apr 8, 7:35 PM",
                "value": 71.58000183105469
            },
            {
                "time": "Mon, Apr 8, 7:35 PM",
                "value": 71.45999908447266
            },
            {
                "time": "Mon, Apr 8, 7:36 PM",
                "value": 71.55000305175781
            },
            {
                "time": "Mon, Apr 8, 7:36 PM",
                "value": 71.4000015258789
            },
            {
                "time": "Mon, Apr 8, 7:37 PM",
                "value": 71.44999694824219
            },
            {
                "time": "Mon, Apr 8, 7:37 PM",
                "value": 71.36000061035156
            },
            {
                "time": "Mon, Apr 8, 7:38 PM",
                "value": 71.3499984741211
            },
            {
                "time": "Mon, Apr 8, 7:38 PM",
                "value": 71.3499984741211
            },
            {
                "time": "Mon, Apr 8, 7:39 PM",
                "value": 71.33999633789062
            },
            {
                "time": "Mon, Apr 8, 7:39 PM",
                "value": 71.33000183105469
            },
            {
                "time": "Mon, Apr 8, 7:40 PM",
                "value": 71.4000015258789
            },
            {
                "time": "Mon, Apr 8, 7:40 PM",
                "value": 71.33000183105469
            },
            {
                "time": "Mon, Apr 8, 7:41 PM",
                "value": 71.44000244140625
            },
            {
                "time": "Mon, Apr 8, 7:41 PM",
                "value": 71.31999969482422
            },
            {
                "time": "Mon, Apr 8, 7:42 PM",
                "value": 71.33000183105469
            },
            {
                "time": "Mon, Apr 8, 7:42 PM",
                "value": 71.23999786376953
            },
            {
                "time": "Mon, Apr 8, 7:43 PM",
                "value": 71.20999908447266
            },
            {
                "time": "Mon, Apr 8, 7:43 PM",
                "value": 71.12999725341797
            },
            {
                "time": "Mon, Apr 8, 7:44 PM",
                "value": 71.16000366210938
            },
            {
                "time": "Mon, Apr 8, 7:44 PM",
                "value": 71.12000274658203
            },
            {
                "time": "Mon, Apr 8, 7:45 PM",
                "value": 71.12999725341797
            },
            {
                "time": "Mon, Apr 8, 7:45 PM",
                "value": 71.0199966430664
            },
            {
                "time": "Mon, Apr 8, 7:46 PM",
                "value": 70.98999786376953
            },
            {
                "time": "Mon, Apr 8, 7:46 PM",
                "value": 70.9800033569336
            },
            {
                "time": "Mon, Apr 8, 7:47 PM",
                "value": 71.01000213623047
            },
            {
                "time": "Mon, Apr 8, 7:47 PM",
                "value": 70.93000030517578
            },
            {
                "time": "Mon, Apr 8, 7:48 PM",
                "value": 70.9800033569336
            },
            {
                "time": "Mon, Apr 8, 7:48 PM",
                "value": 70.97000122070312
            },
            {
                "time": "Mon, Apr 8, 7:49 PM",
                "value": 70.97000122070312
            },
            {
                "time": "Mon, Apr 8, 7:49 PM",
                "value": 70.87999725341797
            },
            {
                "time": "Mon, Apr 8, 7:50 PM",
                "value": 70.97000122070312
            },
            {
                "time": "Mon, Apr 8, 7:50 PM",
                "value": 70.8499984741211
            },
            {
                "time": "Mon, Apr 8, 7:51 PM",
                "value": 70.98999786376953
            },
            {
                "time": "Mon, Apr 8, 7:51 PM",
                "value": 70.8499984741211
            },
            {
                "time": "Mon, Apr 8, 7:52 PM",
                "value": 70.94000244140625
            },
            {
                "time": "Mon, Apr 8, 7:52 PM",
                "value": 70.76000213623047
            },
            {
                "time": "Mon, Apr 8, 7:53 PM",
                "value": 70.7300033569336
            },
            {
                "time": "Mon, Apr 8, 7:53 PM",
                "value": 70.62999725341797
            },
            {
                "time": "Mon, Apr 8, 7:54 PM",
                "value": 70.72000122070312
            },
            {
                "time": "Mon, Apr 8, 7:54 PM",
                "value": 70.62000274658203
            },
            {
                "time": "Mon, Apr 8, 7:55 PM",
                "value": 70.68000030517578
            },
            {
                "time": "Mon, Apr 8, 7:55 PM",
                "value": 70.58999633789062
            },
            {
                "time": "Mon, Apr 8, 7:56 PM",
                "value": 70.66000366210938
            },
            {
                "time": "Mon, Apr 8, 7:56 PM",
                "value": 70.55999755859375
            },
            {
                "time": "Mon, Apr 8, 7:57 PM",
                "value": 70.63999938964844
            },
            {
                "time": "Mon, Apr 8, 7:57 PM",
                "value": 70.52999877929688
            },
            {
                "time": "Mon, Apr 8, 7:58 PM",
                "value": 70.61000061035156
            },
            {
                "time": "Mon, Apr 8, 7:58 PM",
                "value": 70.52999877929688
            },
            {
                "time": "Mon, Apr 8, 7:59 PM",
                "value": 70.6500015258789
            },
            {
                "time": "Mon, Apr 8, 7:59 PM",
                "value": 70.55999755859375
            },
            {
                "time": "Mon, Apr 8, 8:00 PM",
                "value": 70.6500015258789
            },
            {
                "time": "Mon, Apr 8, 8:00 PM",
                "value": 70.52999877929688
            },
            {
                "time": "Mon, Apr 8, 8:01 PM",
                "value": 70.61000061035156
            },
            {
                "time": "Mon, Apr 8, 8:01 PM",
                "value": 70.5199966430664
            },
            {
                "time": "Mon, Apr 8, 8:02 PM",
                "value": 70.44999694824219
            },
            {
                "time": "Mon, Apr 8, 8:02 PM",
                "value": 70.41000366210938
            },
            {
                "time": "Mon, Apr 8, 8:03 PM",
                "value": 70.41000366210938
            },
            {
                "time": "Mon, Apr 8, 8:03 PM",
                "value": 70.37999725341797
            },
            {
                "time": "Mon, Apr 8, 8:04 PM",
                "value": 70.43000030517578
            },
            {
                "time": "Mon, Apr 8, 8:04 PM",
                "value": 70.30000305175781
            },
            {
                "time": "Mon, Apr 8, 8:05 PM",
                "value": 70.4000015258789
            },
            {
                "time": "Mon, Apr 8, 8:05 PM",
                "value": 70.29000091552734
            },
            {
                "time": "Mon, Apr 8, 8:06 PM",
                "value": 70.33000183105469
            },
            {
                "time": "Mon, Apr 8, 8:06 PM",
                "value": 70.19000244140625
            },
            {
                "time": "Mon, Apr 8, 8:07 PM",
                "value": 70.25
            },
            {
                "time": "Mon, Apr 8, 8:07 PM",
                "value": 70.16999816894531
            },
            {
                "time": "Mon, Apr 8, 8:08 PM",
                "value": 70.23999786376953
            },
            {
                "time": "Mon, Apr 8, 8:08 PM",
                "value": 70.12999725341797
            },
            {
                "time": "Mon, Apr 8, 8:09 PM",
                "value": 70.20999908447266
            },
            {
                "time": "Mon, Apr 8, 8:09 PM",
                "value": 70.08999633789062
            },
            {
                "time": "Mon, Apr 8, 8:10 PM",
                "value": 70.18000030517578
            },
            {
                "time": "Mon, Apr 8, 8:10 PM",
                "value": 70.0199966430664
            },
            {
                "time": "Mon, Apr 8, 8:11 PM",
                "value": 69.9800033569336
            },
            {
                "time": "Mon, Apr 8, 8:11 PM",
                "value": 70.04000091552734
            },
            {
                "time": "Mon, Apr 8, 8:12 PM",
                "value": 70
            },
            {
                "time": "Mon, Apr 8, 8:12 PM",
                "value": 69.87000274658203
            },
            {
                "time": "Mon, Apr 8, 8:13 PM",
                "value": 69.94000244140625
            },
            {
                "time": "Mon, Apr 8, 8:13 PM",
                "value": 69.83000183105469
            },
            {
                "time": "Mon, Apr 8, 8:14 PM",
                "value": 69.88999938964844
            },
            {
                "time": "Mon, Apr 8, 8:14 PM",
                "value": 69.72000122070312
            },
            {
                "time": "Mon, Apr 8, 8:15 PM",
                "value": 69.77999877929688
            },
            {
                "time": "Mon, Apr 8, 8:15 PM",
                "value": 69.6500015258789
            },
            {
                "time": "Mon, Apr 8, 8:16 PM",
                "value": 69.66999816894531
            },
            {
                "time": "Mon, Apr 8, 8:16 PM",
                "value": 69.58000183105469
            },
            {
                "time": "Mon, Apr 8, 8:17 PM",
                "value": 69.63999938964844
            },
            {
                "time": "Mon, Apr 8, 8:17 PM",
                "value": 69.51000213623047
            },
            {
                "time": "Mon, Apr 8, 8:18 PM",
                "value": 69.52999877929688
            },
            {
                "time": "Mon, Apr 8, 8:18 PM",
                "value": 69.44000244140625
            },
            {
                "time": "Mon, Apr 8, 8:19 PM",
                "value": 69.5
            },
            {
                "time": "Mon, Apr 8, 8:19 PM",
                "value": 69.36000061035156
            },
            {
                "time": "Mon, Apr 8, 8:20 PM",
                "value": 69.37999725341797
            },
            {
                "time": "Mon, Apr 8, 8:20 PM",
                "value": 69.22000122070312
            },
            {
                "time": "Mon, Apr 8, 8:21 PM",
                "value": 69.23999786376953
            },
            {
                "time": "Mon, Apr 8, 8:21 PM",
                "value": 69.11000061035156
            },
            {
                "time": "Mon, Apr 8, 8:22 PM",
                "value": 69.12999725341797
            },
            {
                "time": "Mon, Apr 8, 8:22 PM",
                "value": 69.04000091552734
            },
            {
                "time": "Mon, Apr 8, 8:23 PM",
                "value": 69.0999984741211
            },
            {
                "time": "Mon, Apr 8, 8:23 PM",
                "value": 68.9800033569336
            },
            {
                "time": "Mon, Apr 8, 8:24 PM",
                "value": 69.02999877929688
            },
            {
                "time": "Mon, Apr 8, 8:24 PM",
                "value": 68.9000015258789
            },
            {
                "time": "Mon, Apr 8, 8:25 PM",
                "value": 68.98999786376953
            },
            {
                "time": "Mon, Apr 8, 8:25 PM",
                "value": 68.88999938964844
            },
            {
                "time": "Mon, Apr 8, 8:26 PM",
                "value": 68.94999694824219
            },
            {
                "time": "Mon, Apr 8, 8:26 PM",
                "value": 68.87000274658203
            },
            {
                "time": "Mon, Apr 8, 8:27 PM",
                "value": 68.95999908447266
            },
            {
                "time": "Mon, Apr 8, 8:27 PM",
                "value": 68.81999969482422
            },
            {
                "time": "Mon, Apr 8, 8:28 PM",
                "value": 68.87999725341797
            },
            {
                "time": "Mon, Apr 8, 8:28 PM",
                "value": 68.75
            },
            {
                "time": "Mon, Apr 8, 8:29 PM",
                "value": 68.83999633789062
            },
            {
                "time": "Mon, Apr 8, 8:29 PM",
                "value": 68.75
            },
            {
                "time": "Mon, Apr 8, 8:30 PM",
                "value": 68.76000213623047
            },
            {
                "time": "Mon, Apr 8, 8:30 PM",
                "value": 68.63999938964844
            },
            {
                "time": "Mon, Apr 8, 8:31 PM",
                "value": 68.69999694824219
            },
            {
                "time": "Mon, Apr 8, 8:31 PM",
                "value": 68.5999984741211
            },
            {
                "time": "Mon, Apr 8, 8:32 PM",
                "value": 68.69000244140625
            },
            {
                "time": "Mon, Apr 8, 8:32 PM",
                "value": 68.55999755859375
            },
            {
                "time": "Mon, Apr 8, 8:33 PM",
                "value": 68.61000061035156
            },
            {
                "time": "Mon, Apr 8, 8:33 PM",
                "value": 68.51000213623047
            },
            {
                "time": "Mon, Apr 8, 8:34 PM",
                "value": 68.55000305175781
            },
            {
                "time": "Mon, Apr 8, 8:34 PM",
                "value": 68.45999908447266
            },
            {
                "time": "Mon, Apr 8, 8:35 PM",
                "value": 68.51000213623047
            },
            {
                "time": "Mon, Apr 8, 8:35 PM",
                "value": 68.43000030517578
            },
            {
                "time": "Mon, Apr 8, 8:36 PM",
                "value": 68.4800033569336
            },
            {
                "time": "Mon, Apr 8, 8:36 PM",
                "value": 68.47000122070312
            },
            {
                "time": "Mon, Apr 8, 8:37 PM",
                "value": 68.5199966430664
            },
            {
                "time": "Mon, Apr 8, 8:37 PM",
                "value": 68.38999938964844
            },
            {
                "time": "Mon, Apr 8, 8:38 PM",
                "value": 68.5199966430664
            },
            {
                "time": "Mon, Apr 8, 8:38 PM",
                "value": 68.33000183105469
            },
            {
                "time": "Mon, Apr 8, 8:39 PM",
                "value": 68.36000061035156
            },
            {
                "time": "Mon, Apr 8, 8:39 PM",
                "value": 68.27999877929688
            },
            {
                "time": "Mon, Apr 8, 8:40 PM",
                "value": 68.41999816894531
            },
            {
                "time": "Mon, Apr 8, 8:40 PM",
                "value": 68.20999908447266
            },
            {
                "time": "Mon, Apr 8, 8:41 PM",
                "value": 68.26000213623047
            },
            {
                "time": "Mon, Apr 8, 8:41 PM",
                "value": 68.19000244140625
            },
            {
                "time": "Mon, Apr 8, 8:42 PM",
                "value": 68.22000122070312
            },
            {
                "time": "Mon, Apr 8, 8:42 PM",
                "value": 68.16999816894531
            },
            {
                "time": "Mon, Apr 8, 8:43 PM",
                "value": 68.30000305175781
            },
            {
                "time": "Mon, Apr 8, 8:43 PM",
                "value": 68.19999694824219
            },
            {
                "time": "Mon, Apr 8, 8:44 PM",
                "value": 68.36000061035156
            },
            {
                "time": "Mon, Apr 8, 8:44 PM",
                "value": 68.12999725341797
            },
            {
                "time": "Mon, Apr 8, 8:45 PM",
                "value": 68.22000122070312
            },
            {
                "time": "Mon, Apr 8, 8:45 PM",
                "value": 68.0999984741211
            },
            {
                "time": "Mon, Apr 8, 8:46 PM",
                "value": 68.25
            },
            {
                "time": "Mon, Apr 8, 8:46 PM",
                "value": 68.05999755859375
            },
            {
                "time": "Mon, Apr 8, 8:47 PM",
                "value": 68.16999816894531
            },
            {
                "time": "Mon, Apr 8, 8:47 PM",
                "value": 68.04000091552734
            },
            {
                "time": "Mon, Apr 8, 8:48 PM",
                "value": 68.12999725341797
            },
            {
                "time": "Mon, Apr 8, 8:48 PM",
                "value": 67.94999694824219
            },
            {
                "time": "Mon, Apr 8, 8:49 PM",
                "value": 68.04000091552734
            },
            {
                "time": "Mon, Apr 8, 8:49 PM",
                "value": 67.91000366210938
            },
            {
                "time": "Mon, Apr 8, 8:50 PM",
                "value": 68.05000305175781
            },
            {
                "time": "Mon, Apr 8, 8:50 PM",
                "value": 67.79000091552734
            },
            {
                "time": "Mon, Apr 8, 8:51 PM",
                "value": 67.79000091552734
            },
            {
                "time": "Mon, Apr 8, 8:51 PM",
                "value": 67.66000366210938
            },
            {
                "time": "Mon, Apr 8, 8:52 PM",
                "value": 67.7699966430664
            },
            {
                "time": "Mon, Apr 8, 8:52 PM",
                "value": 67.68000030517578
            },
            {
                "time": "Mon, Apr 8, 8:53 PM",
                "value": 67.63999938964844
            },
            {
                "time": "Mon, Apr 8, 8:53 PM",
                "value": 67.5199966430664
            },
            {
                "time": "Mon, Apr 8, 8:54 PM",
                "value": 67.63999938964844
            },
            {
                "time": "Mon, Apr 8, 8:54 PM",
                "value": 67.44999694824219
            },
            {
                "time": "Mon, Apr 8, 8:55 PM",
                "value": 67.45999908447266
            },
            {
                "time": "Mon, Apr 8, 8:55 PM",
                "value": 67.2699966430664
            },
            {
                "time": "Mon, Apr 8, 8:56 PM",
                "value": 67.33000183105469
            },
            {
                "time": "Mon, Apr 8, 8:56 PM",
                "value": 67.19000244140625
            },
            {
                "time": "Mon, Apr 8, 8:57 PM",
                "value": 67.23999786376953
            },
            {
                "time": "Mon, Apr 8, 8:57 PM",
                "value": 67.12000274658203
            },
            {
                "time": "Mon, Apr 8, 8:58 PM",
                "value": 67.1500015258789
            },
            {
                "time": "Mon, Apr 8, 8:58 PM",
                "value": 67.01000213623047
            },
            {
                "time": "Mon, Apr 8, 8:59 PM",
                "value": 66.94000244140625
            },
            {
                "time": "Mon, Apr 8, 8:59 PM",
                "value": 66.9000015258789
            },
            {
                "time": "Mon, Apr 8, 9:00 PM",
                "value": 66.9800033569336
            },
            {
                "time": "Mon, Apr 8, 9:00 PM",
                "value": 66.80000305175781
            },
            {
                "time": "Mon, Apr 8, 9:01 PM",
                "value": 66.8499984741211
            },
            {
                "time": "Mon, Apr 8, 9:01 PM",
                "value": 66.80999755859375
            },
            {
                "time": "Mon, Apr 8, 9:02 PM",
                "value": 66.94000244140625
            },
            {
                "time": "Mon, Apr 8, 9:02 PM",
                "value": 66.68000030517578
            },
            {
                "time": "Mon, Apr 8, 9:03 PM",
                "value": 66.7300033569336
            },
            {
                "time": "Mon, Apr 8, 9:03 PM",
                "value": 66.6500015258789
            },
            {
                "time": "Mon, Apr 8, 9:04 PM",
                "value": 66.77999877929688
            },
            {
                "time": "Mon, Apr 8, 9:04 PM",
                "value": 66.61000061035156
            },
            {
                "time": "Mon, Apr 8, 9:05 PM",
                "value": 66.69000244140625
            },
            {
                "time": "Mon, Apr 8, 9:05 PM",
                "value": 66.56999969482422
            },
            {
                "time": "Mon, Apr 8, 9:06 PM",
                "value": 66.5999984741211
            },
            {
                "time": "Mon, Apr 8, 9:06 PM",
                "value": 66.45999908447266
            },
            {
                "time": "Mon, Apr 8, 9:07 PM",
                "value": 66.44000244140625
            },
            {
                "time": "Mon, Apr 8, 9:07 PM",
                "value": 66.36000061035156
            },
            {
                "time": "Mon, Apr 8, 9:08 PM",
                "value": 66.52999877929688
            },
            {
                "time": "Mon, Apr 8, 9:08 PM",
                "value": 66.25
            },
            {
                "time": "Mon, Apr 8, 9:09 PM",
                "value": 66.30999755859375
            },
            {
                "time": "Mon, Apr 8, 9:09 PM",
                "value": 66.18000030517578
            },
            {
                "time": "Mon, Apr 8, 9:10 PM",
                "value": 66.37000274658203
            },
            {
                "time": "Mon, Apr 8, 9:10 PM",
                "value": 66.0999984741211
            },
            {
                "time": "Mon, Apr 8, 9:11 PM",
                "value": 66.12999725341797
            },
            {
                "time": "Mon, Apr 8, 9:11 PM",
                "value": 65.98999786376953
            },
            {
                "time": "Mon, Apr 8, 9:12 PM",
                "value": 66.08999633789062
            },
            {
                "time": "Mon, Apr 8, 9:12 PM",
                "value": 65.9800033569336
            },
            {
                "time": "Mon, Apr 8, 9:13 PM",
                "value": 66.05999755859375
            },
            {
                "time": "Mon, Apr 8, 9:13 PM",
                "value": 65.95999908447266
            },
            {
                "time": "Mon, Apr 8, 9:14 PM",
                "value": 66.08000183105469
            },
            {
                "time": "Mon, Apr 8, 9:14 PM",
                "value": 65.93000030517578
            },
            {
                "time": "Mon, Apr 8, 9:15 PM",
                "value": 66.0199966430664
            },
            {
                "time": "Mon, Apr 8, 9:15 PM",
                "value": 65.95999908447266
            },
            {
                "time": "Mon, Apr 8, 9:16 PM",
                "value": 66.11000061035156
            },
            {
                "time": "Mon, Apr 8, 9:16 PM",
                "value": 65.91999816894531
            },
            {
                "time": "Mon, Apr 8, 9:17 PM",
                "value": 66.0199966430664
            },
            {
                "time": "Mon, Apr 8, 9:17 PM",
                "value": 65.88999938964844
            },
            {
                "time": "Mon, Apr 8, 9:18 PM",
                "value": 65.9800033569336
            },
            {
                "time": "Mon, Apr 8, 9:18 PM",
                "value": 65.88999938964844
            },
            {
                "time": "Mon, Apr 8, 9:19 PM",
                "value": 65.94000244140625
            },
            {
                "time": "Mon, Apr 8, 9:19 PM",
                "value": 65.8499984741211
            },
            {
                "time": "Mon, Apr 8, 9:20 PM",
                "value": 65.91000366210938
            },
            {
                "time": "Mon, Apr 8, 9:20 PM",
                "value": 65.83999633789062
            },
            {
                "time": "Mon, Apr 8, 9:21 PM",
                "value": 65.80999755859375
            },
            {
                "time": "Mon, Apr 8, 9:21 PM",
                "value": 65.66999816894531
            },
            {
                "time": "Mon, Apr 8, 9:22 PM",
                "value": 65.62999725341797
            },
            {
                "time": "Mon, Apr 8, 9:22 PM",
                "value": 65.5999984741211
            },
            {
                "time": "Mon, Apr 8, 9:23 PM",
                "value": 65.66000366210938
            },
            {
                "time": "Mon, Apr 8, 9:23 PM",
                "value": 65.6500015258789
            },
            {
                "time": "Mon, Apr 8, 9:24 PM",
                "value": 65.61000061035156
            },
            {
                "time": "Mon, Apr 8, 9:24 PM",
                "value": 65.54000091552734
            },
            {
                "time": "Mon, Apr 8, 9:25 PM",
                "value": 65.61000061035156
            },
            {
                "time": "Mon, Apr 8, 9:25 PM",
                "value": 65.48999786376953
            },
            {
                "time": "Mon, Apr 8, 9:26 PM",
                "value": 65.55000305175781
            },
            {
                "time": "Mon, Apr 8, 9:26 PM",
                "value": 65.52999877929688
            },
            {
                "time": "Mon, Apr 8, 9:27 PM",
                "value": 65.51000213623047
            },
            {
                "time": "Mon, Apr 8, 9:27 PM",
                "value": 65.41999816894531
            },
            {
                "time": "Mon, Apr 8, 9:28 PM",
                "value": 65.51000213623047
            },
            {
                "time": "Mon, Apr 8, 9:28 PM",
                "value": 65.41999816894531
            },
            {
                "time": "Mon, Apr 8, 9:29 PM",
                "value": 65.5199966430664
            },
            {
                "time": "Mon, Apr 8, 9:29 PM",
                "value": 65.3499984741211
            },
            {
                "time": "Mon, Apr 8, 9:30 PM",
                "value": 65.4000015258789
            },
            {
                "time": "Mon, Apr 8, 9:30 PM",
                "value": 65.27999877929688
            },
            {
                "time": "Mon, Apr 8, 9:31 PM",
                "value": 65.2699966430664
            },
            {
                "time": "Mon, Apr 8, 9:31 PM",
                "value": 65.33000183105469
            },
            {
                "time": "Mon, Apr 8, 9:32 PM",
                "value": 65.29000091552734
            },
            {
                "time": "Mon, Apr 8, 9:32 PM",
                "value": 65.20999908447266
            },
            {
                "time": "Mon, Apr 8, 9:33 PM",
                "value": 65.2699966430664
            },
            {
                "time": "Mon, Apr 8, 9:33 PM",
                "value": 65.12999725341797
            },
            {
                "time": "Mon, Apr 8, 9:34 PM",
                "value": 65.18000030517578
            },
            {
                "time": "Mon, Apr 8, 9:34 PM",
                "value": 65.06999969482422
            },
            {
                "time": "Mon, Apr 8, 9:35 PM",
                "value": 65.12999725341797
            },
            {
                "time": "Mon, Apr 8, 9:35 PM",
                "value": 65.0199966430664
            },
            {
                "time": "Mon, Apr 8, 9:36 PM",
                "value": 65.08000183105469
            },
            {
                "time": "Mon, Apr 8, 9:36 PM",
                "value": 64.94999694824219
            },
            {
                "time": "Mon, Apr 8, 9:37 PM",
                "value": 65.04000091552734
            },
            {
                "time": "Mon, Apr 8, 9:37 PM",
                "value": 64.91999816894531
            },
            {
                "time": "Mon, Apr 8, 9:38 PM",
                "value": 65
            },
            {
                "time": "Mon, Apr 8, 9:38 PM",
                "value": 64.91999816894531
            },
            {
                "time": "Mon, Apr 8, 9:39 PM",
                "value": 65
            },
            {
                "time": "Mon, Apr 8, 9:39 PM",
                "value": 64.88999938964844
            },
            {
                "time": "Mon, Apr 8, 9:40 PM",
                "value": 64.95999908447266
            },
            {
                "time": "Mon, Apr 8, 9:40 PM",
                "value": 64.87000274658203
            },
            {
                "time": "Mon, Apr 8, 9:41 PM",
                "value": 64.9800033569336
            },
            {
                "time": "Mon, Apr 8, 9:41 PM",
                "value": 64.87999725341797
            },
            {
                "time": "Mon, Apr 8, 9:42 PM",
                "value": 64.97000122070312
            },
            {
                "time": "Mon, Apr 8, 9:42 PM",
                "value": 64.87999725341797
            },
            {
                "time": "Mon, Apr 8, 9:43 PM",
                "value": 65.01000213623047
            },
            {
                "time": "Mon, Apr 8, 9:43 PM",
                "value": 64.87999725341797
            },
            {
                "time": "Mon, Apr 8, 9:44 PM",
                "value": 64.95999908447266
            },
            {
                "time": "Mon, Apr 8, 9:44 PM",
                "value": 64.87999725341797
            },
            {
                "time": "Mon, Apr 8, 9:45 PM",
                "value": 64.95999908447266
            },
            {
                "time": "Mon, Apr 8, 9:45 PM",
                "value": 64.87999725341797
            },
            {
                "time": "Mon, Apr 8, 9:46 PM",
                "value": 64.97000122070312
            },
            {
                "time": "Mon, Apr 8, 9:46 PM",
                "value": 64.9000015258789
            },
            {
                "time": "Mon, Apr 8, 9:47 PM",
                "value": 64.93000030517578
            },
            {
                "time": "Mon, Apr 8, 9:47 PM",
                "value": 64.91000366210938
            },
            {
                "time": "Mon, Apr 8, 9:48 PM",
                "value": 64.87000274658203
            },
            {
                "time": "Mon, Apr 8, 9:48 PM",
                "value": 64.75
            },
            {
                "time": "Mon, Apr 8, 9:49 PM",
                "value": 64.79000091552734
            },
            {
                "time": "Mon, Apr 8, 9:49 PM",
                "value": 64.66000366210938
            },
            {
                "time": "Mon, Apr 8, 9:50 PM",
                "value": 64.70999908447266
            },
            {
                "time": "Mon, Apr 8, 9:50 PM",
                "value": 64.62999725341797
            },
            {
                "time": "Mon, Apr 8, 9:51 PM",
                "value": 64.70999908447266
            },
            {
                "time": "Mon, Apr 8, 9:51 PM",
                "value": 64.62999725341797
            },
            {
                "time": "Mon, Apr 8, 9:52 PM",
                "value": 64.68000030517578
            },
            {
                "time": "Mon, Apr 8, 9:52 PM",
                "value": 64.55000305175781
            },
            {
                "time": "Mon, Apr 8, 9:53 PM",
                "value": 64.5999984741211
            },
            {
                "time": "Mon, Apr 8, 9:53 PM",
                "value": 64.5199966430664
            },
            {
                "time": "Mon, Apr 8, 9:54 PM",
                "value": 64.58000183105469
            },
            {
                "time": "Mon, Apr 8, 9:54 PM",
                "value": 64.5
            },
            {
                "time": "Mon, Apr 8, 9:55 PM",
                "value": 64.44000244140625
            },
            {
                "time": "Mon, Apr 8, 9:55 PM",
                "value": 64.44999694824219
            },
            {
                "time": "Mon, Apr 8, 9:56 PM",
                "value": 64.51000213623047
            },
            {
                "time": "Mon, Apr 8, 9:56 PM",
                "value": 64.37000274658203
            },
            {
                "time": "Mon, Apr 8, 9:57 PM",
                "value": 64.41999816894531
            },
            {
                "time": "Mon, Apr 8, 9:57 PM",
                "value": 64.30999755859375
            },
            {
                "time": "Mon, Apr 8, 9:58 PM",
                "value": 64.3499984741211
            },
            {
                "time": "Mon, Apr 8, 9:58 PM",
                "value": 64.2300033569336
            },
            {
                "time": "Mon, Apr 8, 9:59 PM",
                "value": 64.27999877929688
            },
            {
                "time": "Mon, Apr 8, 9:59 PM",
                "value": 64.19000244140625
            },
            {
                "time": "Mon, Apr 8, 10:00 PM",
                "value": 64.27999877929688
            },
            {
                "time": "Mon, Apr 8, 10:00 PM",
                "value": 64.1500015258789
            },
            {
                "time": "Mon, Apr 8, 10:01 PM",
                "value": 64.25
            },
            {
                "time": "Mon, Apr 8, 10:01 PM",
                "value": 64.11000061035156
            },
            {
                "time": "Mon, Apr 8, 10:02 PM",
                "value": 64.16999816894531
            },
            {
                "time": "Mon, Apr 8, 10:02 PM",
                "value": 64.08999633789062
            },
            {
                "time": "Mon, Apr 8, 10:03 PM",
                "value": 64.08999633789062
            },
            {
                "time": "Mon, Apr 8, 10:03 PM",
                "value": 64.05000305175781
            },
            {
                "time": "Mon, Apr 8, 10:04 PM",
                "value": 64.11000061035156
            },
            {
                "time": "Mon, Apr 8, 10:04 PM",
                "value": 63.97999954223633
            },
            {
                "time": "Mon, Apr 8, 10:05 PM",
                "value": 64.02999877929688
            },
            {
                "time": "Mon, Apr 8, 10:05 PM",
                "value": 63.95000076293945
            },
            {
                "time": "Mon, Apr 8, 10:06 PM",
                "value": 63.880001068115234
            },
            {
                "time": "Mon, Apr 8, 10:06 PM",
                "value": 63.72999954223633
            },
            {
                "time": "Mon, Apr 8, 10:07 PM",
                "value": 63.7400016784668
            },
            {
                "time": "Mon, Apr 8, 10:07 PM",
                "value": 63.619998931884766
            },
            {
                "time": "Mon, Apr 8, 10:08 PM",
                "value": 63.630001068115234
            },
            {
                "time": "Mon, Apr 8, 10:08 PM",
                "value": 63.630001068115234
            },
            {
                "time": "Mon, Apr 8, 10:09 PM",
                "value": 63.61000061035156
            },
            {
                "time": "Mon, Apr 8, 10:09 PM",
                "value": 63.470001220703125
            },
            {
                "time": "Mon, Apr 8, 10:10 PM",
                "value": 63.439998626708984
            },
            {
                "time": "Mon, Apr 8, 10:10 PM",
                "value": 63.40999984741211
            },
            {
                "time": "Mon, Apr 8, 10:11 PM",
                "value": 63.36000061035156
            },
            {
                "time": "Mon, Apr 8, 10:11 PM",
                "value": 63.349998474121094
            },
            {
                "time": "Mon, Apr 8, 10:12 PM",
                "value": 63.349998474121094
            },
            {
                "time": "Mon, Apr 8, 10:12 PM",
                "value": 63.349998474121094
            },
            {
                "time": "Mon, Apr 8, 10:13 PM",
                "value": 63.31999969482422
            },
            {
                "time": "Mon, Apr 8, 10:13 PM",
                "value": 63.2400016784668
            },
            {
                "time": "Mon, Apr 8, 10:14 PM",
                "value": 63.29999923706055
            },
            {
                "time": "Mon, Apr 8, 10:14 PM",
                "value": 63.18000030517578
            },
            {
                "time": "Mon, Apr 8, 10:15 PM",
                "value": 63.27000045776367
            },
            {
                "time": "Mon, Apr 8, 10:15 PM",
                "value": 63.18000030517578
            },
            {
                "time": "Mon, Apr 8, 10:16 PM",
                "value": 63.2400016784668
            },
            {
                "time": "Mon, Apr 8, 10:16 PM",
                "value": 63.13999938964844
            },
            {
                "time": "Mon, Apr 8, 10:17 PM",
                "value": 63.20000076293945
            },
            {
                "time": "Mon, Apr 8, 10:17 PM",
                "value": 63.099998474121094
            },
            {
                "time": "Mon, Apr 8, 10:18 PM",
                "value": 63.189998626708984
            },
            {
                "time": "Mon, Apr 8, 10:18 PM",
                "value": 63.099998474121094
            },
            {
                "time": "Mon, Apr 8, 10:19 PM",
                "value": 63.099998474121094
            },
            {
                "time": "Mon, Apr 8, 10:19 PM",
                "value": 63.06999969482422
            },
            {
                "time": "Mon, Apr 8, 10:20 PM",
                "value": 63.18000030517578
            },
            {
                "time": "Mon, Apr 8, 10:20 PM",
                "value": 63.06999969482422
            },
            {
                "time": "Mon, Apr 8, 10:21 PM",
                "value": 63.150001525878906
            },
            {
                "time": "Mon, Apr 8, 10:21 PM",
                "value": 63.060001373291016
            },
            {
                "time": "Mon, Apr 8, 10:22 PM",
                "value": 63.13999938964844
            },
            {
                "time": "Mon, Apr 8, 10:22 PM",
                "value": 63.09000015258789
            },
            {
                "time": "Mon, Apr 8, 10:23 PM",
                "value": 63.150001525878906
            },
            {
                "time": "Mon, Apr 8, 10:23 PM",
                "value": 63.029998779296875
            },
            {
                "time": "Mon, Apr 8, 10:24 PM",
                "value": 63.09000015258789
            },
            {
                "time": "Mon, Apr 8, 10:24 PM",
                "value": 63.0099983215332
            },
            {
                "time": "Mon, Apr 8, 10:25 PM",
                "value": 63.08000183105469
            },
            {
                "time": "Mon, Apr 8, 10:25 PM",
                "value": 63
            },
            {
                "time": "Mon, Apr 8, 10:26 PM",
                "value": 63.040000915527344
            },
            {
                "time": "Mon, Apr 8, 10:26 PM",
                "value": 63.040000915527344
            },
            {
                "time": "Mon, Apr 8, 10:27 PM",
                "value": 63.060001373291016
            },
            {
                "time": "Mon, Apr 8, 10:27 PM",
                "value": 62.91999816894531
            },
            {
                "time": "Mon, Apr 8, 10:28 PM",
                "value": 63.02000045776367
            },
            {
                "time": "Mon, Apr 8, 10:28 PM",
                "value": 62.91999816894531
            },
            {
                "time": "Mon, Apr 8, 10:29 PM",
                "value": 63.04999923706055
            },
            {
                "time": "Mon, Apr 8, 10:29 PM",
                "value": 63
            },
            {
                "time": "Mon, Apr 8, 10:30 PM",
                "value": 63.0099983215332
            },
            {
                "time": "Mon, Apr 8, 10:30 PM",
                "value": 62.9900016784668
            },
            {
                "time": "Mon, Apr 8, 10:31 PM",
                "value": 62.97999954223633
            },
            {
                "time": "Mon, Apr 8, 10:31 PM",
                "value": 62.810001373291016
            },
            {
                "time": "Mon, Apr 8, 10:32 PM",
                "value": 62.86000061035156
            },
            {
                "time": "Mon, Apr 8, 10:32 PM",
                "value": 62.81999969482422
            },
            {
                "time": "Mon, Apr 8, 10:33 PM",
                "value": 62.790000915527344
            },
            {
                "time": "Mon, Apr 8, 10:33 PM",
                "value": 62.630001068115234
            },
            {
                "time": "Mon, Apr 8, 10:34 PM",
                "value": 62.689998626708984
            },
            {
                "time": "Mon, Apr 8, 10:34 PM",
                "value": 62.61000061035156
            },
            {
                "time": "Mon, Apr 8, 10:35 PM",
                "value": 62.650001525878906
            },
            {
                "time": "Mon, Apr 8, 10:35 PM",
                "value": 62.540000915527344
            },
            {
                "time": "Mon, Apr 8, 10:36 PM",
                "value": 62.630001068115234
            },
            {
                "time": "Mon, Apr 8, 10:36 PM",
                "value": 62.630001068115234
            },
            {
                "time": "Mon, Apr 8, 10:37 PM",
                "value": 62.59000015258789
            },
            {
                "time": "Mon, Apr 8, 10:37 PM",
                "value": 62.4900016784668
            },
            {
                "time": "Mon, Apr 8, 10:38 PM",
                "value": 62.58000183105469
            },
            {
                "time": "Mon, Apr 8, 10:38 PM",
                "value": 62.459999084472656
            },
            {
                "time": "Mon, Apr 8, 10:39 PM",
                "value": 62.540000915527344
            },
            {
                "time": "Mon, Apr 8, 10:39 PM",
                "value": 62.43000030517578
            },
            {
                "time": "Mon, Apr 8, 10:40 PM",
                "value": 62.459999084472656
            },
            {
                "time": "Mon, Apr 8, 10:40 PM",
                "value": 62.45000076293945
            },
            {
                "time": "Mon, Apr 8, 10:41 PM",
                "value": 62.43000030517578
            },
            {
                "time": "Mon, Apr 8, 10:41 PM",
                "value": 62.34000015258789
            },
            {
                "time": "Mon, Apr 8, 10:42 PM",
                "value": 62.31999969482422
            },
            {
                "time": "Mon, Apr 8, 10:42 PM",
                "value": 62.36000061035156
            },
            {
                "time": "Mon, Apr 8, 10:43 PM",
                "value": 62.36000061035156
            },
            {
                "time": "Mon, Apr 8, 10:43 PM",
                "value": 62.279998779296875
            },
            {
                "time": "Mon, Apr 8, 10:44 PM",
                "value": 62.33000183105469
            },
            {
                "time": "Mon, Apr 8, 10:44 PM",
                "value": 62.310001373291016
            },
            {
                "time": "Mon, Apr 8, 10:45 PM",
                "value": 62.290000915527344
            },
            {
                "time": "Mon, Apr 8, 10:45 PM",
                "value": 62.189998626708984
            },
            {
                "time": "Mon, Apr 8, 10:46 PM",
                "value": 62.25
            },
            {
                "time": "Mon, Apr 8, 10:46 PM",
                "value": 62.25
            },
            {
                "time": "Mon, Apr 8, 10:47 PM",
                "value": 62.220001220703125
            },
            {
                "time": "Mon, Apr 8, 10:47 PM",
                "value": 62.130001068115234
            },
            {
                "time": "Mon, Apr 8, 10:48 PM",
                "value": 62.220001220703125
            },
            {
                "time": "Mon, Apr 8, 10:48 PM",
                "value": 62.209999084472656
            },
            {
                "time": "Mon, Apr 8, 10:49 PM",
                "value": 62.189998626708984
            },
            {
                "time": "Mon, Apr 8, 10:49 PM",
                "value": 62.04999923706055
            },
            {
                "time": "Mon, Apr 8, 10:50 PM",
                "value": 62.15999984741211
            },
            {
                "time": "Mon, Apr 8, 10:50 PM",
                "value": 62.04999923706055
            },
            {
                "time": "Mon, Apr 8, 10:51 PM",
                "value": 62.099998474121094
            },
            {
                "time": "Mon, Apr 8, 10:51 PM",
                "value": 62.029998779296875
            },
            {
                "time": "Mon, Apr 8, 10:52 PM",
                "value": 62.11000061035156
            },
            {
                "time": "Mon, Apr 8, 10:52 PM",
                "value": 62.02000045776367
            },
            {
                "time": "Mon, Apr 8, 10:53 PM",
                "value": 62.08000183105469
            },
            {
                "time": "Mon, Apr 8, 10:53 PM",
                "value": 62.02000045776367
            },
            {
                "time": "Mon, Apr 8, 10:54 PM",
                "value": 62.040000915527344
            },
            {
                "time": "Mon, Apr 8, 10:54 PM",
                "value": 61.91999816894531
            },
            {
                "time": "Mon, Apr 8, 10:55 PM",
                "value": 61.959999084472656
            },
            {
                "time": "Mon, Apr 8, 10:55 PM",
                "value": 61.84000015258789
            },
            {
                "time": "Mon, Apr 8, 10:56 PM",
                "value": 61.869998931884766
            },
            {
                "time": "Mon, Apr 8, 10:56 PM",
                "value": 61.779998779296875
            },
            {
                "time": "Mon, Apr 8, 10:57 PM",
                "value": 61.81999969482422
            },
            {
                "time": "Mon, Apr 8, 10:57 PM",
                "value": 61.72999954223633
            },
            {
                "time": "Mon, Apr 8, 10:58 PM",
                "value": 61.81999969482422
            },
            {
                "time": "Mon, Apr 8, 10:58 PM",
                "value": 61.689998626708984
            },
            {
                "time": "Mon, Apr 8, 10:59 PM",
                "value": 61.779998779296875
            },
            {
                "time": "Mon, Apr 8, 10:59 PM",
                "value": 61.689998626708984
            },
            {
                "time": "Mon, Apr 8, 11:00 PM",
                "value": 61.790000915527344
            },
            {
                "time": "Mon, Apr 8, 11:00 PM",
                "value": 61.849998474121094
            },
            {
                "time": "Mon, Apr 8, 11:01 PM",
                "value": 61.779998779296875
            },
            {
                "time": "Mon, Apr 8, 11:01 PM",
                "value": 61.779998779296875
            },
            {
                "time": "Mon, Apr 8, 11:02 PM",
                "value": 61.779998779296875
            },
            {
                "time": "Mon, Apr 8, 11:02 PM",
                "value": 61.790000915527344
            },
            {
                "time": "Mon, Apr 8, 11:03 PM",
                "value": 61.75
            },
            {
                "time": "Mon, Apr 8, 11:03 PM",
                "value": 61.70000076293945
            },
            {
                "time": "Mon, Apr 8, 11:04 PM",
                "value": 61.58000183105469
            },
            {
                "time": "Mon, Apr 8, 11:04 PM",
                "value": 61.58000183105469
            },
            {
                "time": "Mon, Apr 8, 11:05 PM",
                "value": 61.58000183105469
            },
            {
                "time": "Mon, Apr 8, 11:05 PM",
                "value": 61.540000915527344
            },
            {
                "time": "Mon, Apr 8, 11:06 PM",
                "value": 61.54999923706055
            },
            {
                "time": "Mon, Apr 8, 11:06 PM",
                "value": 61.54999923706055
            },
            {
                "time": "Mon, Apr 8, 11:07 PM",
                "value": 61.630001068115234
            },
            {
                "time": "Mon, Apr 8, 11:07 PM",
                "value": 61.56999969482422
            },
            {
                "time": "Mon, Apr 8, 11:08 PM",
                "value": 61.61000061035156
            },
            {
                "time": "Mon, Apr 8, 11:08 PM",
                "value": 61.5099983215332
            },
            {
                "time": "Mon, Apr 8, 11:09 PM",
                "value": 61.599998474121094
            },
            {
                "time": "Mon, Apr 8, 11:09 PM",
                "value": 61.56999969482422
            },
            {
                "time": "Mon, Apr 8, 11:10 PM",
                "value": 61.58000183105469
            },
            {
                "time": "Mon, Apr 8, 11:10 PM",
                "value": 61.56999969482422
            },
            {
                "time": "Mon, Apr 8, 11:11 PM",
                "value": 61.560001373291016
            },
            {
                "time": "Mon, Apr 8, 11:11 PM",
                "value": 61.5099983215332
            },
            {
                "time": "Mon, Apr 8, 11:12 PM",
                "value": 61.599998474121094
            },
            {
                "time": "Mon, Apr 8, 11:12 PM",
                "value": 61.65999984741211
            },
            {
                "time": "Mon, Apr 8, 11:13 PM",
                "value": 61.599998474121094
            },
            {
                "time": "Mon, Apr 8, 11:13 PM",
                "value": 61.540000915527344
            },
            {
                "time": "Mon, Apr 8, 11:14 PM",
                "value": 61.630001068115234
            },
            {
                "time": "Mon, Apr 8, 11:14 PM",
                "value": 61.58000183105469
            },
            {
                "time": "Mon, Apr 8, 11:15 PM",
                "value": 61.63999938964844
            },
            {
                "time": "Mon, Apr 8, 11:15 PM",
                "value": 61.5099983215332
            },
            {
                "time": "Mon, Apr 8, 11:16 PM",
                "value": 61.599998474121094
            },
            {
                "time": "Mon, Apr 8, 11:16 PM",
                "value": 61.540000915527344
            },
            {
                "time": "Mon, Apr 8, 11:17 PM",
                "value": 61.61000061035156
            },
            {
                "time": "Mon, Apr 8, 11:17 PM",
                "value": 61.54999923706055
            },
            {
                "time": "Mon, Apr 8, 11:18 PM",
                "value": 61.599998474121094
            },
            {
                "time": "Mon, Apr 8, 11:18 PM",
                "value": 61.470001220703125
            },
            {
                "time": "Mon, Apr 8, 11:19 PM",
                "value": 61.56999969482422
            },
            {
                "time": "Mon, Apr 8, 11:19 PM",
                "value": 61.439998626708984
            },
            {
                "time": "Mon, Apr 8, 11:20 PM",
                "value": 61.4900016784668
            },
            {
                "time": "Mon, Apr 8, 11:20 PM",
                "value": 61.369998931884766
            },
            {
                "time": "Mon, Apr 8, 11:21 PM",
                "value": 61.459999084472656
            },
            {
                "time": "Mon, Apr 8, 11:21 PM",
                "value": 61.31999969482422
            },
            {
                "time": "Mon, Apr 8, 11:22 PM",
                "value": 61.40999984741211
            },
            {
                "time": "Mon, Apr 8, 11:22 PM",
                "value": 61.349998474121094
            },
            {
                "time": "Mon, Apr 8, 11:23 PM",
                "value": 61.459999084472656
            },
            {
                "time": "Mon, Apr 8, 11:23 PM",
                "value": 61.369998931884766
            },
            {
                "time": "Mon, Apr 8, 11:24 PM",
                "value": 61.45000076293945
            },
            {
                "time": "Mon, Apr 8, 11:24 PM",
                "value": 61.5
            },
            {
                "time": "Mon, Apr 8, 11:25 PM",
                "value": 61.4900016784668
            },
            {
                "time": "Mon, Apr 8, 11:25 PM",
                "value": 61.439998626708984
            },
            {
                "time": "Mon, Apr 8, 11:26 PM",
                "value": 61.529998779296875
            },
            {
                "time": "Mon, Apr 8, 11:26 PM",
                "value": 61.45000076293945
            },
            {
                "time": "Mon, Apr 8, 11:27 PM",
                "value": 61.529998779296875
            },
            {
                "time": "Mon, Apr 8, 11:27 PM",
                "value": 61.439998626708984
            },
            {
                "time": "Mon, Apr 8, 11:28 PM",
                "value": 61.52000045776367
            },
            {
                "time": "Mon, Apr 8, 11:28 PM",
                "value": 61.45000076293945
            },
            {
                "time": "Mon, Apr 8, 11:29 PM",
                "value": 61.52000045776367
            },
            {
                "time": "Mon, Apr 8, 11:29 PM",
                "value": 61.43000030517578
            },
            {
                "time": "Mon, Apr 8, 11:30 PM",
                "value": 61.529998779296875
            },
            {
                "time": "Mon, Apr 8, 11:30 PM",
                "value": 61.4900016784668
            },
            {
                "time": "Mon, Apr 8, 11:31 PM",
                "value": 61.560001373291016
            },
            {
                "time": "Mon, Apr 8, 11:31 PM",
                "value": 61.47999954223633
            },
            {
                "time": "Mon, Apr 8, 11:32 PM",
                "value": 61.56999969482422
            },
            {
                "time": "Mon, Apr 8, 11:32 PM",
                "value": 61.5099983215332
            },
            {
                "time": "Mon, Apr 8, 11:33 PM",
                "value": 61.560001373291016
            },
            {
                "time": "Mon, Apr 8, 11:33 PM",
                "value": 61.470001220703125
            },
            {
                "time": "Mon, Apr 8, 11:34 PM",
                "value": 61.560001373291016
            },
            {
                "time": "Mon, Apr 8, 11:34 PM",
                "value": 61.470001220703125
            },
            {
                "time": "Mon, Apr 8, 11:35 PM",
                "value": 61.56999969482422
            },
            {
                "time": "Mon, Apr 8, 11:35 PM",
                "value": 61.54999923706055
            },
            {
                "time": "Mon, Apr 8, 11:36 PM",
                "value": 61.560001373291016
            },
            {
                "time": "Mon, Apr 8, 11:36 PM",
                "value": 61.47999954223633
            },
            {
                "time": "Mon, Apr 8, 11:37 PM",
                "value": 61.560001373291016
            },
            {
                "time": "Mon, Apr 8, 11:37 PM",
                "value": 61.439998626708984
            },
            {
                "time": "Mon, Apr 8, 11:38 PM",
                "value": 61.540000915527344
            },
            {
                "time": "Mon, Apr 8, 11:38 PM",
                "value": 61.529998779296875
            },
            {
                "time": "Mon, Apr 8, 11:39 PM",
                "value": 61.400001525878906
            },
            {
                "time": "Mon, Apr 8, 11:39 PM",
                "value": 61.400001525878906
            },
            {
                "time": "Mon, Apr 8, 11:40 PM",
                "value": 61.4900016784668
            },
            {
                "time": "Mon, Apr 8, 11:40 PM",
                "value": 61.400001525878906
            },
            {
                "time": "Mon, Apr 8, 11:41 PM",
                "value": 61.4900016784668
            },
            {
                "time": "Mon, Apr 8, 11:41 PM",
                "value": 61.400001525878906
            },
            {
                "time": "Mon, Apr 8, 11:42 PM",
                "value": 61.4900016784668
            },
            {
                "time": "Mon, Apr 8, 11:42 PM",
                "value": 61.400001525878906
            },
            {
                "time": "Mon, Apr 8, 11:43 PM",
                "value": 61.4900016784668
            },
            {
                "time": "Mon, Apr 8, 11:43 PM",
                "value": 61.40999984741211
            },
            {
                "time": "Mon, Apr 8, 11:44 PM",
                "value": 61.540000915527344
            },
            {
                "time": "Mon, Apr 8, 11:44 PM",
                "value": 61.45000076293945
            },
            {
                "time": "Mon, Apr 8, 11:45 PM",
                "value": 61.52000045776367
            },
            {
                "time": "Mon, Apr 8, 11:45 PM",
                "value": 61.47999954223633
            },
            {
                "time": "Mon, Apr 8, 11:46 PM",
                "value": 61.400001525878906
            },
            {
                "time": "Mon, Apr 8, 11:46 PM",
                "value": 61.41999816894531
            },
            {
                "time": "Mon, Apr 8, 11:47 PM",
                "value": 61.400001525878906
            },
            {
                "time": "Mon, Apr 8, 11:47 PM",
                "value": 61.45000076293945
            },
            {
                "time": "Mon, Apr 8, 11:48 PM",
                "value": 61.5
            },
            {
                "time": "Mon, Apr 8, 11:48 PM",
                "value": 61.400001525878906
            },
            {
                "time": "Mon, Apr 8, 11:49 PM",
                "value": 61.5
            },
            {
                "time": "Mon, Apr 8, 11:49 PM",
                "value": 61.36000061035156
            },
            {
                "time": "Mon, Apr 8, 11:50 PM",
                "value": 61.45000076293945
            },
            {
                "time": "Mon, Apr 8, 11:50 PM",
                "value": 61.36000061035156
            },
            {
                "time": "Mon, Apr 8, 11:51 PM",
                "value": 61.459999084472656
            },
            {
                "time": "Mon, Apr 8, 11:51 PM",
                "value": 61.369998931884766
            },
            {
                "time": "Mon, Apr 8, 11:52 PM",
                "value": 61.459999084472656
            },
            {
                "time": "Mon, Apr 8, 11:52 PM",
                "value": 61.36000061035156
            },
            {
                "time": "Mon, Apr 8, 11:53 PM",
                "value": 61.45000076293945
            },
            {
                "time": "Mon, Apr 8, 11:53 PM",
                "value": 61.34000015258789
            },
            {
                "time": "Mon, Apr 8, 11:54 PM",
                "value": 61.33000183105469
            },
            {
                "time": "Mon, Apr 8, 11:54 PM",
                "value": 61.33000183105469
            },
            {
                "time": "Mon, Apr 8, 11:55 PM",
                "value": 61.41999816894531
            },
            {
                "time": "Mon, Apr 8, 11:55 PM",
                "value": 61.29999923706055
            },
            {
                "time": "Mon, Apr 8, 11:56 PM",
                "value": 61.38999938964844
            },
            {
                "time": "Mon, Apr 8, 11:56 PM",
                "value": 61.29999923706055
            },
            {
                "time": "Mon, Apr 8, 11:57 PM",
                "value": 61.349998474121094
            },
            {
                "time": "Mon, Apr 8, 11:57 PM",
                "value": 61.29999923706055
            },
            {
                "time": "Mon, Apr 8, 11:58 PM",
                "value": 61.349998474121094
            },
            {
                "time": "Mon, Apr 8, 11:58 PM",
                "value": 61.220001220703125
            },
            {
                "time": "Mon, Apr 8, 11:59 PM",
                "value": 61.31999969482422
            },
            {
                "time": "Mon, Apr 8, 11:59 PM",
                "value": 61.22999954223633
            },
            {
                "time": "Tue, Apr 9, 12:00 AM",
                "value": 61.310001373291016
            },
            {
                "time": "Tue, Apr 9, 12:00 AM",
                "value": 61.29999923706055
            },
            {
                "time": "Tue, Apr 9, 12:01 AM",
                "value": 61.310001373291016
            },
            {
                "time": "Tue, Apr 9, 12:01 AM",
                "value": 61.220001220703125
            },
            {
                "time": "Tue, Apr 9, 12:02 AM",
                "value": 61.310001373291016
            },
            {
                "time": "Tue, Apr 9, 12:02 AM",
                "value": 61.220001220703125
            },
            {
                "time": "Tue, Apr 9, 12:03 AM",
                "value": 61.369998931884766
            },
            {
                "time": "Tue, Apr 9, 12:03 AM",
                "value": 61.220001220703125
            },
            {
                "time": "Tue, Apr 9, 12:04 AM",
                "value": 61.369998931884766
            },
            {
                "time": "Tue, Apr 9, 12:04 AM",
                "value": 61.31999969482422
            },
            {
                "time": "Tue, Apr 9, 12:05 AM",
                "value": 61.310001373291016
            },
            {
                "time": "Tue, Apr 9, 12:05 AM",
                "value": 61.22999954223633
            },
            {
                "time": "Tue, Apr 9, 12:06 AM",
                "value": 61.22999954223633
            },
            {
                "time": "Tue, Apr 9, 12:06 AM",
                "value": 61.189998626708984
            },
            {
                "time": "Tue, Apr 9, 12:07 AM",
                "value": 61.189998626708984
            },
            {
                "time": "Tue, Apr 9, 12:07 AM",
                "value": 61.290000915527344
            },
            {
                "time": "Tue, Apr 9, 12:08 AM",
                "value": 61.279998779296875
            },
            {
                "time": "Tue, Apr 9, 12:08 AM",
                "value": 61.2599983215332
            },
            {
                "time": "Tue, Apr 9, 12:09 AM",
                "value": 61.27000045776367
            },
            {
                "time": "Tue, Apr 9, 12:09 AM",
                "value": 61.189998626708984
            },
            {
                "time": "Tue, Apr 9, 12:10 AM",
                "value": 61.22999954223633
            },
            {
                "time": "Tue, Apr 9, 12:10 AM",
                "value": 61.189998626708984
            },
            {
                "time": "Tue, Apr 9, 12:11 AM",
                "value": 61.279998779296875
            },
            {
                "time": "Tue, Apr 9, 12:11 AM",
                "value": 61.189998626708984
            },
            {
                "time": "Tue, Apr 9, 12:12 AM",
                "value": 61.31999969482422
            },
            {
                "time": "Tue, Apr 9, 12:12 AM",
                "value": 61.22999954223633
            },
            {
                "time": "Tue, Apr 9, 12:13 AM",
                "value": 61.279998779296875
            },
            {
                "time": "Tue, Apr 9, 12:13 AM",
                "value": 61.220001220703125
            },
            {
                "time": "Tue, Apr 9, 12:14 AM",
                "value": 61.279998779296875
            },
            {
                "time": "Tue, Apr 9, 12:14 AM",
                "value": 61.189998626708984
            },
            {
                "time": "Tue, Apr 9, 12:15 AM",
                "value": 61.25
            },
            {
                "time": "Tue, Apr 9, 12:15 AM",
                "value": 61.15999984741211
            },
            {
                "time": "Tue, Apr 9, 12:16 AM",
                "value": 61.25
            },
            {
                "time": "Tue, Apr 9, 12:16 AM",
                "value": 61.18000030517578
            },
            {
                "time": "Tue, Apr 9, 12:17 AM",
                "value": 61.310001373291016
            },
            {
                "time": "Tue, Apr 9, 12:17 AM",
                "value": 61.189998626708984
            },
            {
                "time": "Tue, Apr 9, 12:18 AM",
                "value": 61.279998779296875
            },
            {
                "time": "Tue, Apr 9, 12:18 AM",
                "value": 61.189998626708984
            },
            {
                "time": "Tue, Apr 9, 12:19 AM",
                "value": 61.18000030517578
            },
            {
                "time": "Tue, Apr 9, 12:19 AM",
                "value": 61.18000030517578
            },
            {
                "time": "Tue, Apr 9, 12:20 AM",
                "value": 61.27000045776367
            },
            {
                "time": "Tue, Apr 9, 12:20 AM",
                "value": 61.18000030517578
            },
            {
                "time": "Tue, Apr 9, 12:21 AM",
                "value": 61.27000045776367
            },
            {
                "time": "Tue, Apr 9, 12:21 AM",
                "value": 61.16999816894531
            },
            {
                "time": "Tue, Apr 9, 12:22 AM",
                "value": 61.2400016784668
            },
            {
                "time": "Tue, Apr 9, 12:22 AM",
                "value": 61.18000030517578
            },
            {
                "time": "Tue, Apr 9, 12:23 AM",
                "value": 61.27000045776367
            },
            {
                "time": "Tue, Apr 9, 12:23 AM",
                "value": 61.18000030517578
            },
            {
                "time": "Tue, Apr 9, 12:24 AM",
                "value": 61.25
            },
            {
                "time": "Tue, Apr 9, 12:24 AM",
                "value": 61.119998931884766
            },
            {
                "time": "Tue, Apr 9, 12:25 AM",
                "value": 61.15999984741211
            },
            {
                "time": "Tue, Apr 9, 12:25 AM",
                "value": 61.040000915527344
            },
            {
                "time": "Tue, Apr 9, 12:26 AM",
                "value": 61
            },
            {
                "time": "Tue, Apr 9, 12:26 AM",
                "value": 61.040000915527344
            },
            {
                "time": "Tue, Apr 9, 12:27 AM",
                "value": 60.97999954223633
            },
            {
                "time": "Tue, Apr 9, 12:27 AM",
                "value": 60.88999938964844
            },
            {
                "time": "Tue, Apr 9, 12:28 AM",
                "value": 60.880001068115234
            },
            {
                "time": "Tue, Apr 9, 12:28 AM",
                "value": 60.790000915527344
            },
            {
                "time": "Tue, Apr 9, 12:29 AM",
                "value": 60.83000183105469
            },
            {
                "time": "Tue, Apr 9, 12:29 AM",
                "value": 60.7599983215332
            },
            {
                "time": "Tue, Apr 9, 12:30 AM",
                "value": 60.88999938964844
            },
            {
                "time": "Tue, Apr 9, 12:30 AM",
                "value": 60.7599983215332
            },
            {
                "time": "Tue, Apr 9, 12:31 AM",
                "value": 60.84000015258789
            },
            {
                "time": "Tue, Apr 9, 12:31 AM",
                "value": 60.689998626708984
            },
            {
                "time": "Tue, Apr 9, 12:32 AM",
                "value": 60.790000915527344
            },
            {
                "time": "Tue, Apr 9, 12:32 AM",
                "value": 60.66999816894531
            },
            {
                "time": "Tue, Apr 9, 12:33 AM",
                "value": 60.720001220703125
            },
            {
                "time": "Tue, Apr 9, 12:33 AM",
                "value": 60.66999816894531
            },
            {
                "time": "Tue, Apr 9, 12:34 AM",
                "value": 60.66999816894531
            },
            {
                "time": "Tue, Apr 9, 12:34 AM",
                "value": 60.599998474121094
            },
            {
                "time": "Tue, Apr 9, 12:35 AM",
                "value": 60.56999969482422
            },
            {
                "time": "Tue, Apr 9, 12:35 AM",
                "value": 60.599998474121094
            },
            {
                "time": "Tue, Apr 9, 12:36 AM",
                "value": 60.66999816894531
            },
            {
                "time": "Tue, Apr 9, 12:36 AM",
                "value": 60.63999938964844
            },
            {
                "time": "Tue, Apr 9, 12:37 AM",
                "value": 60.61000061035156
            },
            {
                "time": "Tue, Apr 9, 12:37 AM",
                "value": 60.529998779296875
            },
            {
                "time": "Tue, Apr 9, 12:38 AM",
                "value": 60.709999084472656
            },
            {
                "time": "Tue, Apr 9, 12:38 AM",
                "value": 60.599998474121094
            },
            {
                "time": "Tue, Apr 9, 12:39 AM",
                "value": 60.619998931884766
            },
            {
                "time": "Tue, Apr 9, 12:39 AM",
                "value": 60.56999969482422
            },
            {
                "time": "Tue, Apr 9, 12:40 AM",
                "value": 60.72999954223633
            },
            {
                "time": "Tue, Apr 9, 12:40 AM",
                "value": 60.540000915527344
            },
            {
                "time": "Tue, Apr 9, 12:41 AM",
                "value": 60.630001068115234
            },
            {
                "time": "Tue, Apr 9, 12:41 AM",
                "value": 60.540000915527344
            },
            {
                "time": "Tue, Apr 9, 12:42 AM",
                "value": 60.5
            },
            {
                "time": "Tue, Apr 9, 12:42 AM",
                "value": 60.4900016784668
            },
            {
                "time": "Tue, Apr 9, 12:43 AM",
                "value": 60.58000183105469
            },
            {
                "time": "Tue, Apr 9, 12:43 AM",
                "value": 60.5
            },
            {
                "time": "Tue, Apr 9, 12:44 AM",
                "value": 60.58000183105469
            },
            {
                "time": "Tue, Apr 9, 12:44 AM",
                "value": 60.4900016784668
            },
            {
                "time": "Tue, Apr 9, 12:45 AM",
                "value": 60.59000015258789
            },
            {
                "time": "Tue, Apr 9, 12:45 AM",
                "value": 60.58000183105469
            },
            {
                "time": "Tue, Apr 9, 12:46 AM",
                "value": 60.54999923706055
            },
            {
                "time": "Tue, Apr 9, 12:46 AM",
                "value": 60.459999084472656
            },
            {
                "time": "Tue, Apr 9, 12:47 AM",
                "value": 60.54999923706055
            },
            {
                "time": "Tue, Apr 9, 12:47 AM",
                "value": 60.459999084472656
            },
            {
                "time": "Tue, Apr 9, 12:48 AM",
                "value": 60.54999923706055
            },
            {
                "time": "Tue, Apr 9, 12:48 AM",
                "value": 60.459999084472656
            },
            {
                "time": "Tue, Apr 9, 12:49 AM",
                "value": 60.56999969482422
            },
            {
                "time": "Tue, Apr 9, 12:49 AM",
                "value": 60.5
            },
            {
                "time": "Tue, Apr 9, 12:50 AM",
                "value": 60.61000061035156
            },
            {
                "time": "Tue, Apr 9, 12:50 AM",
                "value": 60.4900016784668
            },
            {
                "time": "Tue, Apr 9, 12:51 AM",
                "value": 60.59000015258789
            },
            {
                "time": "Tue, Apr 9, 12:51 AM",
                "value": 60.5099983215332
            },
            {
                "time": "Tue, Apr 9, 12:52 AM",
                "value": 60.599998474121094
            },
            {
                "time": "Tue, Apr 9, 12:52 AM",
                "value": 60.5
            },
            {
                "time": "Tue, Apr 9, 12:53 AM",
                "value": 60.59000015258789
            },
            {
                "time": "Tue, Apr 9, 12:53 AM",
                "value": 60.56999969482422
            },
            {
                "time": "Tue, Apr 9, 12:54 AM",
                "value": 60.650001525878906
            },
            {
                "time": "Tue, Apr 9, 12:54 AM",
                "value": 60.63999938964844
            },
            {
                "time": "Tue, Apr 9, 12:55 AM",
                "value": 60.61000061035156
            },
            {
                "time": "Tue, Apr 9, 12:55 AM",
                "value": 60.52000045776367
            },
            {
                "time": "Tue, Apr 9, 12:56 AM",
                "value": 60.58000183105469
            },
            {
                "time": "Tue, Apr 9, 12:56 AM",
                "value": 60.54999923706055
            },
            {
                "time": "Tue, Apr 9, 12:57 AM",
                "value": 60.54999923706055
            },
            {
                "time": "Tue, Apr 9, 12:57 AM",
                "value": 60.4900016784668
            },
            {
                "time": "Tue, Apr 9, 12:58 AM",
                "value": 60.61000061035156
            },
            {
                "time": "Tue, Apr 9, 12:58 AM",
                "value": 60.5
            },
            {
                "time": "Tue, Apr 9, 12:59 AM",
                "value": 60.540000915527344
            },
            {
                "time": "Tue, Apr 9, 12:59 AM",
                "value": 60.459999084472656
            },
            {
                "time": "Tue, Apr 9, 1:00 AM",
                "value": 60.540000915527344
            },
            {
                "time": "Tue, Apr 9, 1:00 AM",
                "value": 60.41999816894531
            },
            {
                "time": "Tue, Apr 9, 1:01 AM",
                "value": 60.47999954223633
            },
            {
                "time": "Tue, Apr 9, 1:01 AM",
                "value": 60.38999938964844
            },
            {
                "time": "Tue, Apr 9, 1:02 AM",
                "value": 60.47999954223633
            },
            {
                "time": "Tue, Apr 9, 1:02 AM",
                "value": 60.380001068115234
            },
            {
                "time": "Tue, Apr 9, 1:03 AM",
                "value": 60.43000030517578
            },
            {
                "time": "Tue, Apr 9, 1:03 AM",
                "value": 60.349998474121094
            },
            {
                "time": "Tue, Apr 9, 1:04 AM",
                "value": 60.400001525878906
            },
            {
                "time": "Tue, Apr 9, 1:04 AM",
                "value": 60.310001373291016
            },
            {
                "time": "Tue, Apr 9, 1:05 AM",
                "value": 60.369998931884766
            },
            {
                "time": "Tue, Apr 9, 1:05 AM",
                "value": 60.290000915527344
            },
            {
                "time": "Tue, Apr 9, 1:06 AM",
                "value": 60.290000915527344
            },
            {
                "time": "Tue, Apr 9, 1:06 AM",
                "value": 60.279998779296875
            },
            {
                "time": "Tue, Apr 9, 1:07 AM",
                "value": 60.31999969482422
            },
            {
                "time": "Tue, Apr 9, 1:07 AM",
                "value": 60.38999938964844
            },
            {
                "time": "Tue, Apr 9, 1:08 AM",
                "value": 60.47999954223633
            },
            {
                "time": "Tue, Apr 9, 1:08 AM",
                "value": 60.4900016784668
            },
            {
                "time": "Tue, Apr 9, 1:09 AM",
                "value": 60.5099983215332
            },
            {
                "time": "Tue, Apr 9, 1:09 AM",
                "value": 60.43000030517578
            },
            {
                "time": "Tue, Apr 9, 1:10 AM",
                "value": 60.52000045776367
            },
            {
                "time": "Tue, Apr 9, 1:10 AM",
                "value": 60.41999816894531
            },
            {
                "time": "Tue, Apr 9, 1:11 AM",
                "value": 60.560001373291016
            },
            {
                "time": "Tue, Apr 9, 1:11 AM",
                "value": 60.459999084472656
            },
            {
                "time": "Tue, Apr 9, 1:12 AM",
                "value": 60.560001373291016
            },
            {
                "time": "Tue, Apr 9, 1:12 AM",
                "value": 60.459999084472656
            },
            {
                "time": "Tue, Apr 9, 1:13 AM",
                "value": 60.52000045776367
            },
            {
                "time": "Tue, Apr 9, 1:13 AM",
                "value": 60.38999938964844
            },
            {
                "time": "Tue, Apr 9, 1:14 AM",
                "value": 60.45000076293945
            },
            {
                "time": "Tue, Apr 9, 1:14 AM",
                "value": 60.310001373291016
            },
            {
                "time": "Tue, Apr 9, 1:15 AM",
                "value": 60.36000061035156
            },
            {
                "time": "Tue, Apr 9, 1:15 AM",
                "value": 60.25
            },
            {
                "time": "Tue, Apr 9, 1:16 AM",
                "value": 60.33000183105469
            },
            {
                "time": "Tue, Apr 9, 1:16 AM",
                "value": 60.209999084472656
            },
            {
                "time": "Tue, Apr 9, 1:17 AM",
                "value": 60.29999923706055
            },
            {
                "time": "Tue, Apr 9, 1:17 AM",
                "value": 60.220001220703125
            },
            {
                "time": "Tue, Apr 9, 1:18 AM",
                "value": 60.33000183105469
            },
            {
                "time": "Tue, Apr 9, 1:18 AM",
                "value": 60.25
            },
            {
                "time": "Tue, Apr 9, 1:19 AM",
                "value": 60.369998931884766
            },
            {
                "time": "Tue, Apr 9, 1:19 AM",
                "value": 60.279998779296875
            },
            {
                "time": "Tue, Apr 9, 1:20 AM",
                "value": 60.36000061035156
            },
            {
                "time": "Tue, Apr 9, 1:20 AM",
                "value": 60.279998779296875
            },
            {
                "time": "Tue, Apr 9, 1:21 AM",
                "value": 60.369998931884766
            },
            {
                "time": "Tue, Apr 9, 1:21 AM",
                "value": 60.25
            },
            {
                "time": "Tue, Apr 9, 1:22 AM",
                "value": 60.33000183105469
            },
            {
                "time": "Tue, Apr 9, 1:22 AM",
                "value": 60.34000015258789
            },
            {
                "time": "Tue, Apr 9, 1:23 AM",
                "value": 60.27000045776367
            },
            {
                "time": "Tue, Apr 9, 1:23 AM",
                "value": 60.13999938964844
            },
            {
                "time": "Tue, Apr 9, 1:24 AM",
                "value": 60.189998626708984
            },
            {
                "time": "Tue, Apr 9, 1:24 AM",
                "value": 60.15999984741211
            },
            {
                "time": "Tue, Apr 9, 1:25 AM",
                "value": 60.189998626708984
            },
            {
                "time": "Tue, Apr 9, 1:25 AM",
                "value": 60.06999969482422
            },
            {
                "time": "Tue, Apr 9, 1:26 AM",
                "value": 60.15999984741211
            },
            {
                "time": "Tue, Apr 9, 1:26 AM",
                "value": 60.130001068115234
            },
            {
                "time": "Tue, Apr 9, 1:27 AM",
                "value": 60.15999984741211
            },
            {
                "time": "Tue, Apr 9, 1:27 AM",
                "value": 60.06999969482422
            },
            {
                "time": "Tue, Apr 9, 1:28 AM",
                "value": 60.150001525878906
            },
            {
                "time": "Tue, Apr 9, 1:28 AM",
                "value": 60.189998626708984
            },
            {
                "time": "Tue, Apr 9, 1:29 AM",
                "value": 60.13999938964844
            },
            {
                "time": "Tue, Apr 9, 1:29 AM",
                "value": 60.06999969482422
            },
            {
                "time": "Tue, Apr 9, 1:30 AM",
                "value": 60.150001525878906
            },
            {
                "time": "Tue, Apr 9, 1:30 AM",
                "value": 60.13999938964844
            },
            {
                "time": "Tue, Apr 9, 1:31 AM",
                "value": 60.11000061035156
            },
            {
                "time": "Tue, Apr 9, 1:31 AM",
                "value": 60.04999923706055
            },
            {
                "time": "Tue, Apr 9, 1:32 AM",
                "value": 60.08000183105469
            },
            {
                "time": "Tue, Apr 9, 1:32 AM",
                "value": 60.060001373291016
            },
            {
                "time": "Tue, Apr 9, 1:33 AM",
                "value": 60.04999923706055
            },
            {
                "time": "Tue, Apr 9, 1:33 AM",
                "value": 59.880001068115234
            },
            {
                "time": "Tue, Apr 9, 1:34 AM",
                "value": 59.900001525878906
            },
            {
                "time": "Tue, Apr 9, 1:34 AM",
                "value": 59.86000061035156
            },
            {
                "time": "Tue, Apr 9, 1:35 AM",
                "value": 59.790000915527344
            },
            {
                "time": "Tue, Apr 9, 1:35 AM",
                "value": 59.66999816894531
            },
            {
                "time": "Tue, Apr 9, 1:36 AM",
                "value": 59.709999084472656
            },
            {
                "time": "Tue, Apr 9, 1:36 AM",
                "value": 59.72999954223633
            },
            {
                "time": "Tue, Apr 9, 1:37 AM",
                "value": 59.470001220703125
            },
            {
                "time": "Tue, Apr 9, 1:37 AM",
                "value": 59.2599983215332
            },
            {
                "time": "Tue, Apr 9, 1:38 AM",
                "value": 59.209999084472656
            },
            {
                "time": "Tue, Apr 9, 1:38 AM",
                "value": 59
            },
            {
                "time": "Tue, Apr 9, 1:39 AM",
                "value": 58.77000045776367
            },
            {
                "time": "Tue, Apr 9, 1:39 AM",
                "value": 58.65999984741211
            },
            {
                "time": "Tue, Apr 9, 1:40 AM",
                "value": 58.529998779296875
            },
            {
                "time": "Tue, Apr 9, 1:40 AM",
                "value": 58.29999923706055
            },
            {
                "time": "Tue, Apr 9, 1:41 AM",
                "value": 58.2400016784668
            },
            {
                "time": "Tue, Apr 9, 1:41 AM",
                "value": 58.0099983215332
            },
            {
                "time": "Tue, Apr 9, 1:42 AM",
                "value": 57.900001525878906
            },
            {
                "time": "Tue, Apr 9, 1:42 AM",
                "value": 57.709999084472656
            },
            {
                "time": "Tue, Apr 9, 1:43 AM",
                "value": 57.72999954223633
            },
            {
                "time": "Tue, Apr 9, 1:43 AM",
                "value": 57.5
            },
            {
                "time": "Tue, Apr 9, 1:44 AM",
                "value": 57.47999954223633
            },
            {
                "time": "Tue, Apr 9, 1:44 AM",
                "value": 57.380001068115234
            },
            {
                "time": "Tue, Apr 9, 1:45 AM",
                "value": 57.310001373291016
            },
            {
                "time": "Tue, Apr 9, 1:45 AM",
                "value": 57.209999084472656
            },
            {
                "time": "Tue, Apr 9, 1:46 AM",
                "value": 57.18000030517578
            },
            {
                "time": "Tue, Apr 9, 1:46 AM",
                "value": 57.150001525878906
            },
            {
                "time": "Tue, Apr 9, 1:47 AM",
                "value": 57.18000030517578
            },
            {
                "time": "Tue, Apr 9, 1:47 AM",
                "value": 57.02000045776367
            },
            {
                "time": "Tue, Apr 9, 1:48 AM",
                "value": 57.04999923706055
            },
            {
                "time": "Tue, Apr 9, 1:48 AM",
                "value": 56.939998626708984
            },
            {
                "time": "Tue, Apr 9, 1:49 AM",
                "value": 57.02000045776367
            },
            {
                "time": "Tue, Apr 9, 1:49 AM",
                "value": 56.86000061035156
            },
            {
                "time": "Tue, Apr 9, 1:50 AM",
                "value": 56.959999084472656
            },
            {
                "time": "Tue, Apr 9, 1:50 AM",
                "value": 56.79999923706055
            },
            {
                "time": "Tue, Apr 9, 1:51 AM",
                "value": 56.88999938964844
            },
            {
                "time": "Tue, Apr 9, 1:51 AM",
                "value": 56.869998931884766
            },
            {
                "time": "Tue, Apr 9, 1:52 AM",
                "value": 56.86000061035156
            },
            {
                "time": "Tue, Apr 9, 1:52 AM",
                "value": 56.7400016784668
            },
            {
                "time": "Tue, Apr 9, 1:53 AM",
                "value": 56.779998779296875
            },
            {
                "time": "Tue, Apr 9, 1:53 AM",
                "value": 56.709999084472656
            },
            {
                "time": "Tue, Apr 9, 1:54 AM",
                "value": 56.709999084472656
            },
            {
                "time": "Tue, Apr 9, 1:54 AM",
                "value": 56.65999984741211
            },
            {
                "time": "Tue, Apr 9, 1:55 AM",
                "value": 56.75
            },
            {
                "time": "Tue, Apr 9, 1:55 AM",
                "value": 56.66999816894531
            },
            {
                "time": "Tue, Apr 9, 1:56 AM",
                "value": 56.7599983215332
            },
            {
                "time": "Tue, Apr 9, 1:56 AM",
                "value": 56.66999816894531
            },
            {
                "time": "Tue, Apr 9, 1:57 AM",
                "value": 56.75
            },
            {
                "time": "Tue, Apr 9, 1:57 AM",
                "value": 56.72999954223633
            },
            {
                "time": "Tue, Apr 9, 1:58 AM",
                "value": 56.7400016784668
            },
            {
                "time": "Tue, Apr 9, 1:58 AM",
                "value": 56.650001525878906
            },
            {
                "time": "Tue, Apr 9, 1:59 AM",
                "value": 56.720001220703125
            },
            {
                "time": "Tue, Apr 9, 1:59 AM",
                "value": 56.650001525878906
            },
            {
                "time": "Tue, Apr 9, 2:00 AM",
                "value": 56.7400016784668
            },
            {
                "time": "Tue, Apr 9, 2:00 AM",
                "value": 56.77000045776367
            },
            {
                "time": "Tue, Apr 9, 2:01 AM",
                "value": 56.709999084472656
            },
            {
                "time": "Tue, Apr 9, 2:01 AM",
                "value": 56.66999816894531
            },
            {
                "time": "Tue, Apr 9, 2:02 AM",
                "value": 56.68000030517578
            },
            {
                "time": "Tue, Apr 9, 2:02 AM",
                "value": 56.709999084472656
            },
            {
                "time": "Tue, Apr 9, 2:03 AM",
                "value": 56.79999923706055
            },
            {
                "time": "Tue, Apr 9, 2:03 AM",
                "value": 56.790000915527344
            },
            {
                "time": "Tue, Apr 9, 2:04 AM",
                "value": 56.90999984741211
            },
            {
                "time": "Tue, Apr 9, 2:04 AM",
                "value": 56.95000076293945
            },
            {
                "time": "Tue, Apr 9, 2:05 AM",
                "value": 57.060001373291016
            },
            {
                "time": "Tue, Apr 9, 2:05 AM",
                "value": 57.0099983215332
            },
            {
                "time": "Tue, Apr 9, 2:06 AM",
                "value": 57.150001525878906
            },
            {
                "time": "Tue, Apr 9, 2:06 AM",
                "value": 57.150001525878906
            },
            {
                "time": "Tue, Apr 9, 2:07 AM",
                "value": 57.18000030517578
            },
            {
                "time": "Tue, Apr 9, 2:07 AM",
                "value": 57.209999084472656
            },
            {
                "time": "Tue, Apr 9, 2:08 AM",
                "value": 57.369998931884766
            },
            {
                "time": "Tue, Apr 9, 2:08 AM",
                "value": 57.33000183105469
            },
            {
                "time": "Tue, Apr 9, 2:09 AM",
                "value": 57.439998626708984
            },
            {
                "time": "Tue, Apr 9, 2:09 AM",
                "value": 57.470001220703125
            },
            {
                "time": "Tue, Apr 9, 2:10 AM",
                "value": 57.5
            },
            {
                "time": "Tue, Apr 9, 2:10 AM",
                "value": 57.59000015258789
            },
            {
                "time": "Tue, Apr 9, 2:11 AM",
                "value": 57.65999984741211
            },
            {
                "time": "Tue, Apr 9, 2:11 AM",
                "value": 57.689998626708984
            },
            {
                "time": "Tue, Apr 9, 2:12 AM",
                "value": 57.75
            },
            {
                "time": "Tue, Apr 9, 2:12 AM",
                "value": 57.720001220703125
            },
            {
                "time": "Tue, Apr 9, 2:13 AM",
                "value": 57.810001373291016
            },
            {
                "time": "Tue, Apr 9, 2:13 AM",
                "value": 57.84000015258789
            },
            {
                "time": "Tue, Apr 9, 2:14 AM",
                "value": 57.869998931884766
            },
            {
                "time": "Tue, Apr 9, 2:14 AM",
                "value": 57.81999969482422
            },
            {
                "time": "Tue, Apr 9, 2:15 AM",
                "value": 57.97999954223633
            },
            {
                "time": "Tue, Apr 9, 2:15 AM",
                "value": 57.900001525878906
            },
            {
                "time": "Tue, Apr 9, 2:16 AM",
                "value": 58.02000045776367
            },
            {
                "time": "Tue, Apr 9, 2:16 AM",
                "value": 58.0099983215332
            },
            {
                "time": "Tue, Apr 9, 2:17 AM",
                "value": 58.09000015258789
            },
            {
                "time": "Tue, Apr 9, 2:17 AM",
                "value": 58.060001373291016
            },
            {
                "time": "Tue, Apr 9, 2:18 AM",
                "value": 58.040000915527344
            },
            {
                "time": "Tue, Apr 9, 2:18 AM",
                "value": 58.08000183105469
            },
            {
                "time": "Tue, Apr 9, 2:19 AM",
                "value": 58.18000030517578
            },
            {
                "time": "Tue, Apr 9, 2:19 AM",
                "value": 58.119998931884766
            },
            {
                "time": "Tue, Apr 9, 2:20 AM",
                "value": 58.209999084472656
            },
            {
                "time": "Tue, Apr 9, 2:20 AM",
                "value": 58.15999984741211
            },
            {
                "time": "Tue, Apr 9, 2:21 AM",
                "value": 58.27000045776367
            },
            {
                "time": "Tue, Apr 9, 2:21 AM",
                "value": 58.189998626708984
            },
            {
                "time": "Tue, Apr 9, 2:22 AM",
                "value": 58.279998779296875
            },
            {
                "time": "Tue, Apr 9, 2:22 AM",
                "value": 58.189998626708984
            },
            {
                "time": "Tue, Apr 9, 2:23 AM",
                "value": 58.33000183105469
            },
            {
                "time": "Tue, Apr 9, 2:23 AM",
                "value": 58.189998626708984
            },
            {
                "time": "Tue, Apr 9, 2:24 AM",
                "value": 58.220001220703125
            },
            {
                "time": "Tue, Apr 9, 2:24 AM",
                "value": 58.18000030517578
            },
            {
                "time": "Tue, Apr 9, 2:25 AM",
                "value": 58.20000076293945
            },
            {
                "time": "Tue, Apr 9, 2:25 AM",
                "value": 58.150001525878906
            },
            {
                "time": "Tue, Apr 9, 2:26 AM",
                "value": 58.209999084472656
            },
            {
                "time": "Tue, Apr 9, 2:26 AM",
                "value": 58.119998931884766
            },
            {
                "time": "Tue, Apr 9, 2:27 AM",
                "value": 58.209999084472656
            },
            {
                "time": "Tue, Apr 9, 2:27 AM",
                "value": 58.11000061035156
            },
            {
                "time": "Tue, Apr 9, 2:28 AM",
                "value": 58.2400016784668
            },
            {
                "time": "Tue, Apr 9, 2:28 AM",
                "value": 58.15999984741211
            },
            {
                "time": "Tue, Apr 9, 2:29 AM",
                "value": 58.2400016784668
            },
            {
                "time": "Tue, Apr 9, 2:29 AM",
                "value": 58.119998931884766
            },
            {
                "time": "Tue, Apr 9, 2:30 AM",
                "value": 58.209999084472656
            },
            {
                "time": "Tue, Apr 9, 2:30 AM",
                "value": 58.20000076293945
            },
            {
                "time": "Tue, Apr 9, 2:31 AM",
                "value": 58.209999084472656
            },
            {
                "time": "Tue, Apr 9, 2:31 AM",
                "value": 58.119998931884766
            },
            {
                "time": "Tue, Apr 9, 2:32 AM",
                "value": 58.20000076293945
            },
            {
                "time": "Tue, Apr 9, 2:32 AM",
                "value": 58.11000061035156
            },
            {
                "time": "Tue, Apr 9, 2:33 AM",
                "value": 58.15999984741211
            },
            {
                "time": "Tue, Apr 9, 2:33 AM",
                "value": 58.130001068115234
            },
            {
                "time": "Tue, Apr 9, 2:34 AM",
                "value": 58.119998931884766
            },
            {
                "time": "Tue, Apr 9, 2:34 AM",
                "value": 58.15999984741211
            },
            {
                "time": "Tue, Apr 9, 2:35 AM",
                "value": 58.15999984741211
            },
            {
                "time": "Tue, Apr 9, 2:35 AM",
                "value": 58.13999938964844
            },
            {
                "time": "Tue, Apr 9, 2:36 AM",
                "value": 58.15999984741211
            },
            {
                "time": "Tue, Apr 9, 2:36 AM",
                "value": 58.06999969482422
            },
            {
                "time": "Tue, Apr 9, 2:37 AM",
                "value": 58.15999984741211
            },
            {
                "time": "Tue, Apr 9, 2:37 AM",
                "value": 58.06999969482422
            },
            {
                "time": "Tue, Apr 9, 2:38 AM",
                "value": 58.15999984741211
            },
            {
                "time": "Tue, Apr 9, 2:38 AM",
                "value": 58.06999969482422
            },
            {
                "time": "Tue, Apr 9, 2:39 AM",
                "value": 58.16999816894531
            },
            {
                "time": "Tue, Apr 9, 2:39 AM",
                "value": 58.06999969482422
            },
            {
                "time": "Tue, Apr 9, 2:40 AM",
                "value": 58.16999816894531
            },
            {
                "time": "Tue, Apr 9, 2:40 AM",
                "value": 58.06999969482422
            },
            {
                "time": "Tue, Apr 9, 2:41 AM",
                "value": 58.09000015258789
            },
            {
                "time": "Tue, Apr 9, 2:41 AM",
                "value": 58.08000183105469
            },
            {
                "time": "Tue, Apr 9, 2:42 AM",
                "value": 58.13999938964844
            },
            {
                "time": "Tue, Apr 9, 2:42 AM",
                "value": 58
            },
            {
                "time": "Tue, Apr 9, 2:43 AM",
                "value": 57.970001220703125
            },
            {
                "time": "Tue, Apr 9, 2:43 AM",
                "value": 57.95000076293945
            },
            {
                "time": "Tue, Apr 9, 2:44 AM",
                "value": 57.97999954223633
            },
            {
                "time": "Tue, Apr 9, 2:44 AM",
                "value": 57.86000061035156
            },
            {
                "time": "Tue, Apr 9, 2:45 AM",
                "value": 57.95000076293945
            },
            {
                "time": "Tue, Apr 9, 2:45 AM",
                "value": 57.81999969482422
            },
            {
                "time": "Tue, Apr 9, 2:46 AM",
                "value": 57.95000076293945
            },
            {
                "time": "Tue, Apr 9, 2:46 AM",
                "value": 57.81999969482422
            },
            {
                "time": "Tue, Apr 9, 2:47 AM",
                "value": 57.91999816894531
            },
            {
                "time": "Tue, Apr 9, 2:47 AM",
                "value": 57.81999969482422
            },
            {
                "time": "Tue, Apr 9, 2:48 AM",
                "value": 57.91999816894531
            },
            {
                "time": "Tue, Apr 9, 2:48 AM",
                "value": 57.81999969482422
            },
            {
                "time": "Tue, Apr 9, 2:49 AM",
                "value": 57.86000061035156
            },
            {
                "time": "Tue, Apr 9, 2:49 AM",
                "value": 57.86000061035156
            },
            {
                "time": "Tue, Apr 9, 2:50 AM",
                "value": 57.88999938964844
            },
            {
                "time": "Tue, Apr 9, 2:50 AM",
                "value": 57.88999938964844
            },
            {
                "time": "Tue, Apr 9, 2:51 AM",
                "value": 58.040000915527344
            },
            {
                "time": "Tue, Apr 9, 2:51 AM",
                "value": 57.90999984741211
            },
            {
                "time": "Tue, Apr 9, 2:52 AM",
                "value": 57.90999984741211
            },
            {
                "time": "Tue, Apr 9, 2:52 AM",
                "value": 57.88999938964844
            },
            {
                "time": "Tue, Apr 9, 2:53 AM",
                "value": 58.02000045776367
            },
            {
                "time": "Tue, Apr 9, 2:53 AM",
                "value": 57.93000030517578
            },
            {
                "time": "Tue, Apr 9, 2:54 AM",
                "value": 58.060001373291016
            },
            {
                "time": "Tue, Apr 9, 2:54 AM",
                "value": 58.040000915527344
            },
            {
                "time": "Tue, Apr 9, 2:55 AM",
                "value": 58.06999969482422
            },
            {
                "time": "Tue, Apr 9, 2:55 AM",
                "value": 58
            },
            {
                "time": "Tue, Apr 9, 2:56 AM",
                "value": 58.119998931884766
            },
            {
                "time": "Tue, Apr 9, 2:56 AM",
                "value": 58.040000915527344
            },
            {
                "time": "Tue, Apr 9, 2:57 AM",
                "value": 58.09000015258789
            },
            {
                "time": "Tue, Apr 9, 2:57 AM",
                "value": 58.060001373291016
            },
            {
                "time": "Tue, Apr 9, 2:58 AM",
                "value": 58.130001068115234
            },
            {
                "time": "Tue, Apr 9, 2:58 AM",
                "value": 58
            },
            {
                "time": "Tue, Apr 9, 2:59 AM",
                "value": 58
            },
            {
                "time": "Tue, Apr 9, 2:59 AM",
                "value": 58
            },
            {
                "time": "Tue, Apr 9, 3:00 AM",
                "value": 58.130001068115234
            },
            {
                "time": "Tue, Apr 9, 3:00 AM",
                "value": 57.97999954223633
            },
            {
                "time": "Tue, Apr 9, 3:01 AM",
                "value": 58.0099983215332
            },
            {
                "time": "Tue, Apr 9, 3:01 AM",
                "value": 57.88999938964844
            },
            {
                "time": "Tue, Apr 9, 3:02 AM",
                "value": 58.029998779296875
            },
            {
                "time": "Tue, Apr 9, 3:02 AM",
                "value": 57.81999969482422
            },
            {
                "time": "Tue, Apr 9, 3:03 AM",
                "value": 57.90999984741211
            },
            {
                "time": "Tue, Apr 9, 3:03 AM",
                "value": 57.869998931884766
            },
            {
                "time": "Tue, Apr 9, 3:04 AM",
                "value": 57.84000015258789
            },
            {
                "time": "Tue, Apr 9, 3:04 AM",
                "value": 57.75
            },
            {
                "time": "Tue, Apr 9, 3:05 AM",
                "value": 57.84000015258789
            },
            {
                "time": "Tue, Apr 9, 3:05 AM",
                "value": 57.709999084472656
            },
            {
                "time": "Tue, Apr 9, 3:06 AM",
                "value": 57.709999084472656
            },
            {
                "time": "Tue, Apr 9, 3:06 AM",
                "value": 57.7400016784668
            },
            {
                "time": "Tue, Apr 9, 3:07 AM",
                "value": 57.689998626708984
            },
            {
                "time": "Tue, Apr 9, 3:07 AM",
                "value": 57.650001525878906
            },
            {
                "time": "Tue, Apr 9, 3:08 AM",
                "value": 57.65999984741211
            },
            {
                "time": "Tue, Apr 9, 3:08 AM",
                "value": 57.529998779296875
            },
            {
                "time": "Tue, Apr 9, 3:09 AM",
                "value": 57.59000015258789
            },
            {
                "time": "Tue, Apr 9, 3:09 AM",
                "value": 57.43000030517578
            },
            {
                "time": "Tue, Apr 9, 3:10 AM",
                "value": 57.459999084472656
            },
            {
                "time": "Tue, Apr 9, 3:10 AM",
                "value": 57.279998779296875
            },
            {
                "time": "Tue, Apr 9, 3:11 AM",
                "value": 57.290000915527344
            },
            {
                "time": "Tue, Apr 9, 3:11 AM",
                "value": 57.130001068115234
            },
            {
                "time": "Tue, Apr 9, 3:12 AM",
                "value": 57.189998626708984
            },
            {
                "time": "Tue, Apr 9, 3:12 AM",
                "value": 56.97999954223633
            },
            {
                "time": "Tue, Apr 9, 3:13 AM",
                "value": 57
            },
            {
                "time": "Tue, Apr 9, 3:13 AM",
                "value": 56.880001068115234
            },
            {
                "time": "Tue, Apr 9, 3:14 AM",
                "value": 56.900001525878906
            },
            {
                "time": "Tue, Apr 9, 3:14 AM",
                "value": 56.81999969482422
            },
            {
                "time": "Tue, Apr 9, 3:15 AM",
                "value": 56.869998931884766
            },
            {
                "time": "Tue, Apr 9, 3:15 AM",
                "value": 56.72999954223633
            },
            {
                "time": "Tue, Apr 9, 3:16 AM",
                "value": 56.790000915527344
            },
            {
                "time": "Tue, Apr 9, 3:16 AM",
                "value": 56.65999984741211
            },
            {
                "time": "Tue, Apr 9, 3:17 AM",
                "value": 56.75
            },
            {
                "time": "Tue, Apr 9, 3:17 AM",
                "value": 56.619998931884766
            },
            {
                "time": "Tue, Apr 9, 3:18 AM",
                "value": 56.650001525878906
            },
            {
                "time": "Tue, Apr 9, 3:18 AM",
                "value": 56.54999923706055
            },
            {
                "time": "Tue, Apr 9, 3:19 AM",
                "value": 56.61000061035156
            },
            {
                "time": "Tue, Apr 9, 3:19 AM",
                "value": 56.5
            },
            {
                "time": "Tue, Apr 9, 3:20 AM",
                "value": 56.43000030517578
            },
            {
                "time": "Tue, Apr 9, 3:20 AM",
                "value": 56.349998474121094
            },
            {
                "time": "Tue, Apr 9, 3:21 AM",
                "value": 56.22999954223633
            },
            {
                "time": "Tue, Apr 9, 3:21 AM",
                "value": 56.2400016784668
            },
            {
                "time": "Tue, Apr 9, 3:22 AM",
                "value": 56.20000076293945
            },
            {
                "time": "Tue, Apr 9, 3:22 AM",
                "value": 56.18000030517578
            },
            {
                "time": "Tue, Apr 9, 3:23 AM",
                "value": 56.119998931884766
            },
            {
                "time": "Tue, Apr 9, 3:23 AM",
                "value": 56.09000015258789
            },
            {
                "time": "Tue, Apr 9, 3:24 AM",
                "value": 56.20000076293945
            },
            {
                "time": "Tue, Apr 9, 3:24 AM",
                "value": 56.16999816894531
            },
            {
                "time": "Tue, Apr 9, 3:25 AM",
                "value": 56.18000030517578
            },
            {
                "time": "Tue, Apr 9, 3:25 AM",
                "value": 56.08000183105469
            },
            {
                "time": "Tue, Apr 9, 3:26 AM",
                "value": 56.18000030517578
            },
            {
                "time": "Tue, Apr 9, 3:26 AM",
                "value": 56.08000183105469
            },
            {
                "time": "Tue, Apr 9, 3:27 AM",
                "value": 56.25
            },
            {
                "time": "Tue, Apr 9, 3:27 AM",
                "value": 56.119998931884766
            },
            {
                "time": "Tue, Apr 9, 3:28 AM",
                "value": 56.27000045776367
            },
            {
                "time": "Tue, Apr 9, 3:28 AM",
                "value": 56.20000076293945
            },
            {
                "time": "Tue, Apr 9, 3:29 AM",
                "value": 56.209999084472656
            },
            {
                "time": "Tue, Apr 9, 3:29 AM",
                "value": 56.119998931884766
            },
            {
                "time": "Tue, Apr 9, 3:30 AM",
                "value": 56.209999084472656
            },
            {
                "time": "Tue, Apr 9, 3:30 AM",
                "value": 56.119998931884766
            },
            {
                "time": "Tue, Apr 9, 3:31 AM",
                "value": 56.20000076293945
            },
            {
                "time": "Tue, Apr 9, 3:31 AM",
                "value": 56.2400016784668
            },
            {
                "time": "Tue, Apr 9, 3:32 AM",
                "value": 56.15999984741211
            },
            {
                "time": "Tue, Apr 9, 3:32 AM",
                "value": 56.150001525878906
            },
            {
                "time": "Tue, Apr 9, 3:33 AM",
                "value": 56.29999923706055
            },
            {
                "time": "Tue, Apr 9, 3:33 AM",
                "value": 56.189998626708984
            },
            {
                "time": "Tue, Apr 9, 3:34 AM",
                "value": 56.31999969482422
            },
            {
                "time": "Tue, Apr 9, 3:34 AM",
                "value": 56.22999954223633
            },
            {
                "time": "Tue, Apr 9, 3:35 AM",
                "value": 56.279998779296875
            },
            {
                "time": "Tue, Apr 9, 3:35 AM",
                "value": 56.20000076293945
            },
            {
                "time": "Tue, Apr 9, 3:36 AM",
                "value": 56.36000061035156
            },
            {
                "time": "Tue, Apr 9, 3:36 AM",
                "value": 56.22999954223633
            },
            {
                "time": "Tue, Apr 9, 3:37 AM",
                "value": 56.349998474121094
            },
            {
                "time": "Tue, Apr 9, 3:37 AM",
                "value": 56.29999923706055
            },
            {
                "time": "Tue, Apr 9, 3:38 AM",
                "value": 56.29999923706055
            },
            {
                "time": "Tue, Apr 9, 3:38 AM",
                "value": 56.33000183105469
            },
            {
                "time": "Tue, Apr 9, 3:39 AM",
                "value": 56.41999816894531
            },
            {
                "time": "Tue, Apr 9, 3:39 AM",
                "value": 56.33000183105469
            },
            {
                "time": "Tue, Apr 9, 3:40 AM",
                "value": 56.459999084472656
            },
            {
                "time": "Tue, Apr 9, 3:40 AM",
                "value": 56.459999084472656
            },
            {
                "time": "Tue, Apr 9, 3:41 AM",
                "value": 56.54999923706055
            },
            {
                "time": "Tue, Apr 9, 3:41 AM",
                "value": 56.52000045776367
            },
            {
                "time": "Tue, Apr 9, 3:42 AM",
                "value": 56.65999984741211
            },
            {
                "time": "Tue, Apr 9, 3:42 AM",
                "value": 56.630001068115234
            },
            {
                "time": "Tue, Apr 9, 3:43 AM",
                "value": 56.720001220703125
            },
            {
                "time": "Tue, Apr 9, 3:43 AM",
                "value": 56.75
            },
            {
                "time": "Tue, Apr 9, 3:44 AM",
                "value": 56.779998779296875
            },
            {
                "time": "Tue, Apr 9, 3:44 AM",
                "value": 56.70000076293945
            },
            {
                "time": "Tue, Apr 9, 3:45 AM",
                "value": 56.790000915527344
            },
            {
                "time": "Tue, Apr 9, 3:45 AM",
                "value": 56.70000076293945
            },
            {
                "time": "Tue, Apr 9, 3:46 AM",
                "value": 56.81999969482422
            },
            {
                "time": "Tue, Apr 9, 3:46 AM",
                "value": 56.779998779296875
            },
            {
                "time": "Tue, Apr 9, 3:47 AM",
                "value": 56.93000030517578
            },
            {
                "time": "Tue, Apr 9, 3:47 AM",
                "value": 56.91999816894531
            },
            {
                "time": "Tue, Apr 9, 3:48 AM",
                "value": 57.119998931884766
            },
            {
                "time": "Tue, Apr 9, 3:48 AM",
                "value": 57.099998474121094
            },
            {
                "time": "Tue, Apr 9, 3:49 AM",
                "value": 57.25
            },
            {
                "time": "Tue, Apr 9, 3:49 AM",
                "value": 57.209999084472656
            },
            {
                "time": "Tue, Apr 9, 3:50 AM",
                "value": 57.369998931884766
            },
            {
                "time": "Tue, Apr 9, 3:50 AM",
                "value": 57.310001373291016
            },
            {
                "time": "Tue, Apr 9, 3:51 AM",
                "value": 57.560001373291016
            },
            {
                "time": "Tue, Apr 9, 3:51 AM",
                "value": 57.529998779296875
            },
            {
                "time": "Tue, Apr 9, 3:52 AM",
                "value": 57.7400016784668
            },
            {
                "time": "Tue, Apr 9, 3:52 AM",
                "value": 57.75
            },
            {
                "time": "Tue, Apr 9, 3:53 AM",
                "value": 57.86000061035156
            },
            {
                "time": "Tue, Apr 9, 3:53 AM",
                "value": 57.939998626708984
            },
            {
                "time": "Tue, Apr 9, 3:54 AM",
                "value": 58
            },
            {
                "time": "Tue, Apr 9, 3:54 AM",
                "value": 58.06999969482422
            },
            {
                "time": "Tue, Apr 9, 3:55 AM",
                "value": 58.209999084472656
            },
            {
                "time": "Tue, Apr 9, 3:55 AM",
                "value": 58.189998626708984
            },
            {
                "time": "Tue, Apr 9, 3:56 AM",
                "value": 58.29999923706055
            },
            {
                "time": "Tue, Apr 9, 3:56 AM",
                "value": 58.290000915527344
            },
            {
                "time": "Tue, Apr 9, 3:57 AM",
                "value": 58.5099983215332
            },
            {
                "time": "Tue, Apr 9, 3:57 AM",
                "value": 58.47999954223633
            },
            {
                "time": "Tue, Apr 9, 3:58 AM",
                "value": 58.63999938964844
            },
            {
                "time": "Tue, Apr 9, 3:58 AM",
                "value": 58.58000183105469
            },
            {
                "time": "Tue, Apr 9, 3:59 AM",
                "value": 58.72999954223633
            },
            {
                "time": "Tue, Apr 9, 3:59 AM",
                "value": 58.650001525878906
            },
            {
                "time": "Tue, Apr 9, 4:00 AM",
                "value": 58.779998779296875
            },
            {
                "time": "Tue, Apr 9, 4:00 AM",
                "value": 58.84000015258789
            },
            {
                "time": "Tue, Apr 9, 4:01 AM",
                "value": 59.06999969482422
            },
            {
                "time": "Tue, Apr 9, 4:01 AM",
                "value": 59.02000045776367
            },
            {
                "time": "Tue, Apr 9, 4:02 AM",
                "value": 59.13999938964844
            },
            {
                "time": "Tue, Apr 9, 4:02 AM",
                "value": 59.130001068115234
            },
            {
                "time": "Tue, Apr 9, 4:03 AM",
                "value": 59.209999084472656
            },
            {
                "time": "Tue, Apr 9, 4:03 AM",
                "value": 59.15999984741211
            },
            {
                "time": "Tue, Apr 9, 4:04 AM",
                "value": 59.15999984741211
            },
            {
                "time": "Tue, Apr 9, 4:04 AM",
                "value": 59.20000076293945
            },
            {
                "time": "Tue, Apr 9, 4:05 AM",
                "value": 59.20000076293945
            },
            {
                "time": "Tue, Apr 9, 4:05 AM",
                "value": 59.2400016784668
            },
            {
                "time": "Tue, Apr 9, 4:06 AM",
                "value": 59.36000061035156
            },
            {
                "time": "Tue, Apr 9, 4:06 AM",
                "value": 59.29999923706055
            },
            {
                "time": "Tue, Apr 9, 4:07 AM",
                "value": 59.470001220703125
            },
            {
                "time": "Tue, Apr 9, 4:07 AM",
                "value": 59.380001068115234
            },
            {
                "time": "Tue, Apr 9, 4:08 AM",
                "value": 59.52000045776367
            },
            {
                "time": "Tue, Apr 9, 4:08 AM",
                "value": 59.40999984741211
            },
            {
                "time": "Tue, Apr 9, 4:09 AM",
                "value": 59.5
            },
            {
                "time": "Tue, Apr 9, 4:09 AM",
                "value": 59.439998626708984
            },
            {
                "time": "Tue, Apr 9, 4:10 AM",
                "value": 59.5
            },
            {
                "time": "Tue, Apr 9, 4:10 AM",
                "value": 59.439998626708984
            },
            {
                "time": "Tue, Apr 9, 4:11 AM",
                "value": 59.540000915527344
            },
            {
                "time": "Tue, Apr 9, 4:11 AM",
                "value": 59.54999923706055
            },
            {
                "time": "Tue, Apr 9, 4:12 AM",
                "value": 59.58000183105469
            },
            {
                "time": "Tue, Apr 9, 4:12 AM",
                "value": 59.47999954223633
            },
            {
                "time": "Tue, Apr 9, 4:13 AM",
                "value": 59.619998931884766
            },
            {
                "time": "Tue, Apr 9, 4:13 AM",
                "value": 59.630001068115234
            },
            {
                "time": "Tue, Apr 9, 4:14 AM",
                "value": 59.619998931884766
            },
            {
                "time": "Tue, Apr 9, 4:14 AM",
                "value": 59.52000045776367
            },
            {
                "time": "Tue, Apr 9, 4:15 AM",
                "value": 59.61000061035156
            },
            {
                "time": "Tue, Apr 9, 4:15 AM",
                "value": 59.58000183105469
            },
            {
                "time": "Tue, Apr 9, 4:16 AM",
                "value": 59.63999938964844
            },
            {
                "time": "Tue, Apr 9, 4:16 AM",
                "value": 59.52000045776367
            },
            {
                "time": "Tue, Apr 9, 4:17 AM",
                "value": 59.61000061035156
            },
            {
                "time": "Tue, Apr 9, 4:17 AM",
                "value": 59.529998779296875
            },
            {
                "time": "Tue, Apr 9, 4:18 AM",
                "value": 59.619998931884766
            },
            {
                "time": "Tue, Apr 9, 4:18 AM",
                "value": 59.47999954223633
            },
            {
                "time": "Tue, Apr 9, 4:19 AM",
                "value": 59.58000183105469
            },
            {
                "time": "Tue, Apr 9, 4:19 AM",
                "value": 59.4900016784668
            },
            {
                "time": "Tue, Apr 9, 4:20 AM",
                "value": 59.529998779296875
            },
            {
                "time": "Tue, Apr 9, 4:20 AM",
                "value": 59.529998779296875
            },
            {
                "time": "Tue, Apr 9, 4:21 AM",
                "value": 59.5
            },
            {
                "time": "Tue, Apr 9, 4:21 AM",
                "value": 59.40999984741211
            },
            {
                "time": "Tue, Apr 9, 4:22 AM",
                "value": 59.459999084472656
            },
            {
                "time": "Tue, Apr 9, 4:22 AM",
                "value": 59.40999984741211
            },
            {
                "time": "Tue, Apr 9, 4:23 AM",
                "value": 59.5099983215332
            },
            {
                "time": "Tue, Apr 9, 4:23 AM",
                "value": 59.5
            },
            {
                "time": "Tue, Apr 9, 4:24 AM",
                "value": 59.470001220703125
            },
            {
                "time": "Tue, Apr 9, 4:24 AM",
                "value": 59.33000183105469
            },
            {
                "time": "Tue, Apr 9, 4:25 AM",
                "value": 59.38999938964844
            },
            {
                "time": "Tue, Apr 9, 4:25 AM",
                "value": 59.279998779296875
            },
            {
                "time": "Tue, Apr 9, 4:26 AM",
                "value": 59.36000061035156
            },
            {
                "time": "Tue, Apr 9, 4:26 AM",
                "value": 59.22999954223633
            },
            {
                "time": "Tue, Apr 9, 4:27 AM",
                "value": 59.290000915527344
            },
            {
                "time": "Tue, Apr 9, 4:27 AM",
                "value": 59.189998626708984
            },
            {
                "time": "Tue, Apr 9, 4:28 AM",
                "value": 59.290000915527344
            },
            {
                "time": "Tue, Apr 9, 4:28 AM",
                "value": 59.22999954223633
            },
            {
                "time": "Tue, Apr 9, 4:29 AM",
                "value": 59.369998931884766
            },
            {
                "time": "Tue, Apr 9, 4:29 AM",
                "value": 59.34000015258789
            },
            {
                "time": "Tue, Apr 9, 4:30 AM",
                "value": 59.36000061035156
            },
            {
                "time": "Tue, Apr 9, 4:30 AM",
                "value": 59.2599983215332
            },
            {
                "time": "Tue, Apr 9, 4:31 AM",
                "value": 59.36000061035156
            },
            {
                "time": "Tue, Apr 9, 4:31 AM",
                "value": 59.36000061035156
            },
            {
                "time": "Tue, Apr 9, 4:32 AM",
                "value": 59.31999969482422
            },
            {
                "time": "Tue, Apr 9, 4:32 AM",
                "value": 59.22999954223633
            },
            {
                "time": "Tue, Apr 9, 4:33 AM",
                "value": 59.33000183105469
            },
            {
                "time": "Tue, Apr 9, 4:33 AM",
                "value": 59.2400016784668
            },
            {
                "time": "Tue, Apr 9, 4:34 AM",
                "value": 59.31999969482422
            },
            {
                "time": "Tue, Apr 9, 4:34 AM",
                "value": 59.27000045776367
            },
            {
                "time": "Tue, Apr 9, 4:35 AM",
                "value": 59.349998474121094
            },
            {
                "time": "Tue, Apr 9, 4:35 AM",
                "value": 59.2599983215332
            },
            {
                "time": "Tue, Apr 9, 4:36 AM",
                "value": 59.349998474121094
            },
            {
                "time": "Tue, Apr 9, 4:36 AM",
                "value": 59.27000045776367
            },
            {
                "time": "Tue, Apr 9, 4:37 AM",
                "value": 59.36000061035156
            },
            {
                "time": "Tue, Apr 9, 4:37 AM",
                "value": 59.29999923706055
            },
            {
                "time": "Tue, Apr 9, 4:38 AM",
                "value": 59.43000030517578
            },
            {
                "time": "Tue, Apr 9, 4:38 AM",
                "value": 59.380001068115234
            },
            {
                "time": "Tue, Apr 9, 4:39 AM",
                "value": 59.5
            },
            {
                "time": "Tue, Apr 9, 4:39 AM",
                "value": 59.40999984741211
            },
            {
                "time": "Tue, Apr 9, 4:40 AM",
                "value": 59.5
            },
            {
                "time": "Tue, Apr 9, 4:40 AM",
                "value": 59.40999984741211
            },
            {
                "time": "Tue, Apr 9, 4:41 AM",
                "value": 59.5
            },
            {
                "time": "Tue, Apr 9, 4:41 AM",
                "value": 59.40999984741211
            },
            {
                "time": "Tue, Apr 9, 4:42 AM",
                "value": 59.40999984741211
            },
            {
                "time": "Tue, Apr 9, 4:42 AM",
                "value": 59.380001068115234
            },
            {
                "time": "Tue, Apr 9, 4:43 AM",
                "value": 59.380001068115234
            },
            {
                "time": "Tue, Apr 9, 4:43 AM",
                "value": 59.40999984741211
            },
            {
                "time": "Tue, Apr 9, 4:44 AM",
                "value": 59.5
            },
            {
                "time": "Tue, Apr 9, 4:44 AM",
                "value": 59.5
            },
            {
                "time": "Tue, Apr 9, 4:45 AM",
                "value": 59.5
            },
            {
                "time": "Tue, Apr 9, 4:45 AM",
                "value": 59.40999984741211
            },
            {
                "time": "Tue, Apr 9, 4:46 AM",
                "value": 59.5
            },
            {
                "time": "Tue, Apr 9, 4:46 AM",
                "value": 59.380001068115234
            },
            {
                "time": "Tue, Apr 9, 4:47 AM",
                "value": 59.470001220703125
            },
            {
                "time": "Tue, Apr 9, 4:47 AM",
                "value": 59.380001068115234
            },
            {
                "time": "Tue, Apr 9, 4:48 AM",
                "value": 59.4900016784668
            },
            {
                "time": "Tue, Apr 9, 4:48 AM",
                "value": 59.369998931884766
            },
            {
                "time": "Tue, Apr 9, 4:49 AM",
                "value": 59.36000061035156
            },
            {
                "time": "Tue, Apr 9, 4:49 AM",
                "value": 59.349998474121094
            },
            {
                "time": "Tue, Apr 9, 4:50 AM",
                "value": 59.310001373291016
            },
            {
                "time": "Tue, Apr 9, 4:50 AM",
                "value": 59.29999923706055
            },
            {
                "time": "Tue, Apr 9, 4:51 AM",
                "value": 59.29999923706055
            },
            {
                "time": "Tue, Apr 9, 4:51 AM",
                "value": 59.29999923706055
            },
            {
                "time": "Tue, Apr 9, 4:52 AM",
                "value": 59.380001068115234
            },
            {
                "time": "Tue, Apr 9, 4:52 AM",
                "value": 59.27000045776367
            },
            {
                "time": "Tue, Apr 9, 4:53 AM",
                "value": 59.31999969482422
            },
            {
                "time": "Tue, Apr 9, 4:53 AM",
                "value": 59.220001220703125
            },
            {
                "time": "Tue, Apr 9, 4:54 AM",
                "value": 59.2599983215332
            },
            {
                "time": "Tue, Apr 9, 4:54 AM",
                "value": 59.15999984741211
            },
            {
                "time": "Tue, Apr 9, 4:55 AM",
                "value": 59.209999084472656
            },
            {
                "time": "Tue, Apr 9, 4:55 AM",
                "value": 59.09000015258789
            },
            {
                "time": "Tue, Apr 9, 4:56 AM",
                "value": 59.150001525878906
            },
            {
                "time": "Tue, Apr 9, 4:56 AM",
                "value": 59.029998779296875
            },
            {
                "time": "Tue, Apr 9, 4:57 AM",
                "value": 58.88999938964844
            },
            {
                "time": "Tue, Apr 9, 4:57 AM",
                "value": 58.83000183105469
            },
            {
                "time": "Tue, Apr 9, 4:58 AM",
                "value": 58.88999938964844
            },
            {
                "time": "Tue, Apr 9, 4:58 AM",
                "value": 58.72999954223633
            },
            {
                "time": "Tue, Apr 9, 4:59 AM",
                "value": 58.779998779296875
            },
            {
                "time": "Tue, Apr 9, 4:59 AM",
                "value": 58.65999984741211
            },
            {
                "time": "Tue, Apr 9, 5:00 AM",
                "value": 58.70000076293945
            },
            {
                "time": "Tue, Apr 9, 5:00 AM",
                "value": 58.58000183105469
            },
            {
                "time": "Tue, Apr 9, 5:01 AM",
                "value": 58.619998931884766
            },
            {
                "time": "Tue, Apr 9, 5:01 AM",
                "value": 58.43000030517578
            },
            {
                "time": "Tue, Apr 9, 5:02 AM",
                "value": 58.529998779296875
            },
            {
                "time": "Tue, Apr 9, 5:02 AM",
                "value": 58.470001220703125
            },
            {
                "time": "Tue, Apr 9, 5:03 AM",
                "value": 58.61000061035156
            },
            {
                "time": "Tue, Apr 9, 5:03 AM",
                "value": 58.52000045776367
            },
            {
                "time": "Tue, Apr 9, 5:04 AM",
                "value": 58.599998474121094
            },
            {
                "time": "Tue, Apr 9, 5:04 AM",
                "value": 58.5
            },
            {
                "time": "Tue, Apr 9, 5:05 AM",
                "value": 58.599998474121094
            },
            {
                "time": "Tue, Apr 9, 5:05 AM",
                "value": 58.5099983215332
            },
            {
                "time": "Tue, Apr 9, 5:06 AM",
                "value": 58.5099983215332
            },
            {
                "time": "Tue, Apr 9, 5:06 AM",
                "value": 58.529998779296875
            },
            {
                "time": "Tue, Apr 9, 5:07 AM",
                "value": 58.470001220703125
            },
            {
                "time": "Tue, Apr 9, 5:07 AM",
                "value": 58.41999816894531
            },
            {
                "time": "Tue, Apr 9, 5:08 AM",
                "value": 58.4900016784668
            },
            {
                "time": "Tue, Apr 9, 5:08 AM",
                "value": 58.369998931884766
            },
            {
                "time": "Tue, Apr 9, 5:09 AM",
                "value": 58.40999984741211
            },
            {
                "time": "Tue, Apr 9, 5:09 AM",
                "value": 58.33000183105469
            },
            {
                "time": "Tue, Apr 9, 5:10 AM",
                "value": 58.380001068115234
            },
            {
                "time": "Tue, Apr 9, 5:10 AM",
                "value": 58.290000915527344
            },
            {
                "time": "Tue, Apr 9, 5:11 AM",
                "value": 58.380001068115234
            },
            {
                "time": "Tue, Apr 9, 5:11 AM",
                "value": 58.2599983215332
            },
            {
                "time": "Tue, Apr 9, 5:12 AM",
                "value": 58.38999938964844
            },
            {
                "time": "Tue, Apr 9, 5:12 AM",
                "value": 58.18000030517578
            },
            {
                "time": "Tue, Apr 9, 5:13 AM",
                "value": 58.189998626708984
            },
            {
                "time": "Tue, Apr 9, 5:13 AM",
                "value": 58.150001525878906
            },
            {
                "time": "Tue, Apr 9, 5:14 AM",
                "value": 58.22999954223633
            },
            {
                "time": "Tue, Apr 9, 5:14 AM",
                "value": 58.119998931884766
            },
            {
                "time": "Tue, Apr 9, 5:15 AM",
                "value": 58.20000076293945
            },
            {
                "time": "Tue, Apr 9, 5:15 AM",
                "value": 58.16999816894531
            },
            {
                "time": "Tue, Apr 9, 5:16 AM",
                "value": 58.16999816894531
            },
            {
                "time": "Tue, Apr 9, 5:16 AM",
                "value": 58.16999816894531
            },
            {
                "time": "Tue, Apr 9, 5:17 AM",
                "value": 58.16999816894531
            },
            {
                "time": "Tue, Apr 9, 5:17 AM",
                "value": 58.06999969482422
            },
            {
                "time": "Tue, Apr 9, 5:18 AM",
                "value": 58.119998931884766
            },
            {
                "time": "Tue, Apr 9, 5:18 AM",
                "value": 58.040000915527344
            },
            {
                "time": "Tue, Apr 9, 5:19 AM",
                "value": 58.060001373291016
            },
            {
                "time": "Tue, Apr 9, 5:19 AM",
                "value": 57.939998626708984
            },
            {
                "time": "Tue, Apr 9, 5:20 AM",
                "value": 57.9900016784668
            },
            {
                "time": "Tue, Apr 9, 5:20 AM",
                "value": 57.849998474121094
            },
            {
                "time": "Tue, Apr 9, 5:21 AM",
                "value": 57.95000076293945
            },
            {
                "time": "Tue, Apr 9, 5:21 AM",
                "value": 57.81999969482422
            },
            {
                "time": "Tue, Apr 9, 5:22 AM",
                "value": 57.90999984741211
            },
            {
                "time": "Tue, Apr 9, 5:22 AM",
                "value": 57.790000915527344
            },
            {
                "time": "Tue, Apr 9, 5:23 AM",
                "value": 57.880001068115234
            },
            {
                "time": "Tue, Apr 9, 5:23 AM",
                "value": 57.91999816894531
            },
            {
                "time": "Tue, Apr 9, 5:24 AM",
                "value": 57.849998474121094
            },
            {
                "time": "Tue, Apr 9, 5:24 AM",
                "value": 57.77000045776367
            },
            {
                "time": "Tue, Apr 9, 5:25 AM",
                "value": 57.7599983215332
            },
            {
                "time": "Tue, Apr 9, 5:25 AM",
                "value": 57.63999938964844
            },
            {
                "time": "Tue, Apr 9, 5:26 AM",
                "value": 57.72999954223633
            },
            {
                "time": "Tue, Apr 9, 5:26 AM",
                "value": 57.599998474121094
            },
            {
                "time": "Tue, Apr 9, 5:27 AM",
                "value": 57.650001525878906
            },
            {
                "time": "Tue, Apr 9, 5:27 AM",
                "value": 57.529998779296875
            },
            {
                "time": "Tue, Apr 9, 5:28 AM",
                "value": 57.58000183105469
            },
            {
                "time": "Tue, Apr 9, 5:28 AM",
                "value": 57.47999954223633
            },
            {
                "time": "Tue, Apr 9, 5:29 AM",
                "value": 57.5099983215332
            },
            {
                "time": "Tue, Apr 9, 5:29 AM",
                "value": 57.43000030517578
            },
            {
                "time": "Tue, Apr 9, 5:30 AM",
                "value": 57.5099983215332
            },
            {
                "time": "Tue, Apr 9, 5:30 AM",
                "value": 57.41999816894531
            },
            {
                "time": "Tue, Apr 9, 5:31 AM",
                "value": 57.43000030517578
            },
            {
                "time": "Tue, Apr 9, 5:31 AM",
                "value": 57.349998474121094
            },
            {
                "time": "Tue, Apr 9, 5:32 AM",
                "value": 57.400001525878906
            },
            {
                "time": "Tue, Apr 9, 5:32 AM",
                "value": 57.349998474121094
            },
            {
                "time": "Tue, Apr 9, 5:33 AM",
                "value": 57.400001525878906
            },
            {
                "time": "Tue, Apr 9, 5:33 AM",
                "value": 57.310001373291016
            },
            {
                "time": "Tue, Apr 9, 5:34 AM",
                "value": 57.40999984741211
            },
            {
                "time": "Tue, Apr 9, 5:34 AM",
                "value": 57.34000015258789
            },
            {
                "time": "Tue, Apr 9, 5:35 AM",
                "value": 57.400001525878906
            },
            {
                "time": "Tue, Apr 9, 5:35 AM",
                "value": 57.279998779296875
            },
            {
                "time": "Tue, Apr 9, 5:36 AM",
                "value": 57.369998931884766
            },
            {
                "time": "Tue, Apr 9, 5:36 AM",
                "value": 57.20000076293945
            },
            {
                "time": "Tue, Apr 9, 5:37 AM",
                "value": 57.18000030517578
            },
            {
                "time": "Tue, Apr 9, 5:37 AM",
                "value": 57.189998626708984
            },
            {
                "time": "Tue, Apr 9, 5:38 AM",
                "value": 57.220001220703125
            },
            {
                "time": "Tue, Apr 9, 5:38 AM",
                "value": 57.13999938964844
            },
            {
                "time": "Tue, Apr 9, 5:39 AM",
                "value": 57.16999816894531
            },
            {
                "time": "Tue, Apr 9, 5:39 AM",
                "value": 57.2599983215332
            },
            {
                "time": "Tue, Apr 9, 5:40 AM",
                "value": 57.220001220703125
            },
            {
                "time": "Tue, Apr 9, 5:40 AM",
                "value": 57.22999954223633
            },
            {
                "time": "Tue, Apr 9, 5:41 AM",
                "value": 57.22999954223633
            },
            {
                "time": "Tue, Apr 9, 5:41 AM",
                "value": 57.16999816894531
            },
            {
                "time": "Tue, Apr 9, 5:42 AM",
                "value": 57.189998626708984
            },
            {
                "time": "Tue, Apr 9, 5:42 AM",
                "value": 56.9900016784668
            },
            {
                "time": "Tue, Apr 9, 5:43 AM",
                "value": 57.0099983215332
            },
            {
                "time": "Tue, Apr 9, 5:43 AM",
                "value": 56.880001068115234
            },
            {
                "time": "Tue, Apr 9, 5:44 AM",
                "value": 56.93000030517578
            },
            {
                "time": "Tue, Apr 9, 5:44 AM",
                "value": 56.84000015258789
            },
            {
                "time": "Tue, Apr 9, 5:45 AM",
                "value": 56.849998474121094
            },
            {
                "time": "Tue, Apr 9, 5:45 AM",
                "value": 56.84000015258789
            },
            {
                "time": "Tue, Apr 9, 5:46 AM",
                "value": 56.97999954223633
            },
            {
                "time": "Tue, Apr 9, 5:46 AM",
                "value": 56.810001373291016
            },
            {
                "time": "Tue, Apr 9, 5:47 AM",
                "value": 56.81999969482422
            },
            {
                "time": "Tue, Apr 9, 5:47 AM",
                "value": 56.72999954223633
            },
            {
                "time": "Tue, Apr 9, 5:48 AM",
                "value": 56.79999923706055
            },
            {
                "time": "Tue, Apr 9, 5:48 AM",
                "value": 56.70000076293945
            },
            {
                "time": "Tue, Apr 9, 5:49 AM",
                "value": 56.810001373291016
            },
            {
                "time": "Tue, Apr 9, 5:49 AM",
                "value": 56.70000076293945
            },
            {
                "time": "Tue, Apr 9, 5:50 AM",
                "value": 56.75
            },
            {
                "time": "Tue, Apr 9, 5:50 AM",
                "value": 56.66999816894531
            },
            {
                "time": "Tue, Apr 9, 5:51 AM",
                "value": 56.7599983215332
            },
            {
                "time": "Tue, Apr 9, 5:51 AM",
                "value": 56.630001068115234
            },
            {
                "time": "Tue, Apr 9, 5:52 AM",
                "value": 56.689998626708984
            },
            {
                "time": "Tue, Apr 9, 5:52 AM",
                "value": 56.4900016784668
            },
            {
                "time": "Tue, Apr 9, 5:53 AM",
                "value": 56.56999969482422
            },
            {
                "time": "Tue, Apr 9, 5:53 AM",
                "value": 56.5099983215332
            },
            {
                "time": "Tue, Apr 9, 5:54 AM",
                "value": 56.560001373291016
            },
            {
                "time": "Tue, Apr 9, 5:54 AM",
                "value": 56.54999923706055
            },
            {
                "time": "Tue, Apr 9, 5:55 AM",
                "value": 56.560001373291016
            },
            {
                "time": "Tue, Apr 9, 5:55 AM",
                "value": 56.40999984741211
            },
            {
                "time": "Tue, Apr 9, 5:56 AM",
                "value": 56.5
            },
            {
                "time": "Tue, Apr 9, 5:56 AM",
                "value": 56.36000061035156
            },
            {
                "time": "Tue, Apr 9, 5:57 AM",
                "value": 56.43000030517578
            },
            {
                "time": "Tue, Apr 9, 5:57 AM",
                "value": 56.34000015258789
            },
            {
                "time": "Tue, Apr 9, 5:58 AM",
                "value": 56.380001068115234
            },
            {
                "time": "Tue, Apr 9, 5:58 AM",
                "value": 56.29999923706055
            },
            {
                "time": "Tue, Apr 9, 5:59 AM",
                "value": 56.38999938964844
            },
            {
                "time": "Tue, Apr 9, 5:59 AM",
                "value": 56.27000045776367
            }
        ]
    }
}

export default processedSolarTemp;