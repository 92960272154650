import React from 'react'
import './NewsStoryBullets.css'

const NewsStoryBullets = (props) => {
  function convertTextLinks(paragraph) {
    let ourText = paragraph;
    let link = ourText.replace(/\[([^\]]+)\]\(([^)]+)\)/g, '<a target="_blank" class="news__story__paragraph__link" href="$2">$1</a>');
    link = link.replace(/\*{2}([^*]+)\*{2}/g, '<span class="bold__text">$1</span>');
    link = link.replace(/%{2}([^*]+)%{2}/g, '<span class="italic__text">$1</span>'); // Remove unnecessary escape characters
    link = "" + link + "";


    return (link)
  }
  return (
    <ul className='news__story__bullets'>
      {props.bulletContent.map((item, index) => {
        return (<li key={index} dangerouslySetInnerHTML={{ __html: convertTextLinks(item) }}></li>)
      })}
    </ul>
  )
}

export default NewsStoryBullets