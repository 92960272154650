import React from 'react'
import './UserPermissionAddStaff.css'
import { useEffect, useState } from 'react'
import {primaryAPILink} from '../../../../../config/routes.js'

const UserPermissionAddStaff = () => {
    const [fName, setFName] = useState("")
    const [lName, setLName] = useState("")
    const [email, setEmail] = useState("")
    const [title, setTitle] = useState("")
    const [phone, setPhone] = useState("")
    const [category, setCategory] = useState("")
    const [username, setUsername] = useState("")

    function addStaff(){
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', jwtKey: localStorage.getItem("loginKey") },
            body: JSON.stringify({
                fName: fName,
                lName: lName,
                email: email,
                title: title,
                phone: phone,
                category: category,
                username: username})
        };
        fetch(`${primaryAPILink}/api/permissions/addStaff`, requestOptions)
        window.location.reload();
      }


    return (
        <div className='add__new__staff__wrapper'>
            <h2>Add new staff member</h2>

            <div className='newStaffForm'>

                <div className='newStaffFormItem'>
                    <label>First Name</label>
                    <input placeholder='Add First Name' onInput={
                        (event)=>setFName(event.target.value)} 
                        value={fName}></input>
                </div>
                <div className='newStaffFormItem'>
                    <label>Last Name</label>
                    <input placeholder='Add Last Name' onInput={
                        (event)=>setLName(event.target.value)} value={lName} ></input>
                </div>

                <div className='newStaffFormItem'>
                    <label>Email</label>
                    <input placeholder='Add Email' onInput={
                        (event)=>setEmail(event.target.value)} value={email} ></input>
                </div>

                <div className='newStaffFormItem'>
                    <label>Job Title</label>
                    <input placeholder='Add Job Title' onInput={
                        (event)=>setTitle(event.target.value)} value={title} ></input>
                </div>

                <div className='newStaffFormItem'>
                    <label>Phone</label>
                    <input placeholder='Add Phone' onInput={
                        (event)=>setPhone(event.target.value)} value={phone}></input>
                </div>

                <div className='newStaffFormItem'>
                    <label>Category</label>
                    <select onInput={(event)=>setCategory(event.target.value)}>
                        <option value="administration">Admin</option>
                        <option value="info">Info Services</option>
                        <option value="business">Business</option>
                        <option value="research">Research</option>
                        <option value="volunteer">Volunteer</option>
                    </select>
                </div>

                <div className='newStaffFormItem'>
                    <label>Username</label>
                    <input placeholder='Add Username' onInput={
                        (event)=>setUsername(event.target.value)} value={username} ></input>
                </div>

                <button onClick={()=>addStaff()}>Add new staff</button>

            </div>
        </div>
    )
}

export default UserPermissionAddStaff