import React from 'react';
import { useState } from 'react';
import './NewsTable.css';

const NewsTable = (props) => {
  const [tableContent] = useState(props.tableContent);

  return (
    <div className='cms__table__container'>
      <table className='cms__table'>
        <tbody>
          {tableContent.map((row, rowIndex) => {
            if (rowIndex === 0) {
              return (
                <tr key={rowIndex}>
                  {row.map((column, columnIndex) => {
                    return (
                      <th key={`${rowIndex} ${columnIndex}`}>{column}</th>
                    );
                  })}
                </tr>
              );
            }
            return (
              <tr key={rowIndex}>
                {row.map((column, columnIndex) => {
                  return (
                    <td
                      key={`${rowIndex} ${columnIndex}`}
                      dangerouslySetInnerHTML={{ __html: column }}
                    ></td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default NewsTable;
