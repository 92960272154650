import React from 'react'
import './StaffProfileSpecialties.css'

const StaffProfileSpecialties = (props) => {

  return (
    <div className='staff__profile__specialties'>
        <h3>Specialties:</h3>
        <ul className='staff__profile__specialties__container'>
            {props.specialties.map((item, index)=>{
                return(<li key={index}>{item}</li>)
            })}
        </ul>
    </div>
  )
}

export default StaffProfileSpecialties