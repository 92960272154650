import * as React from "react"
const Indiana = ({ 
    setCosuna1, setCosuna2, setCosuna3, setCosuna4, setCosuna5, 
    cosuna1, cosuna2, cosuna3, cosuna4, cosuna5 
}) => (  <svg
        xmlns="http://www.w3.org/2000/svg"
        className="indiana-svg-ignis"
        strokeLinecap="round"
        strokeLinejoin="round"
        baseProfile="tiny"
        viewBox="0 0 810 810"
    >
        <g stroke="rgba(10, 10, 10, 1)" fill="transparent">
            <g 
            className={cosuna5 ? "cosuna5Group active" : "cosuna5Group"} 
            onClick={() =>setCosuna5(!cosuna5)}>
                <path d="m328.45 20.58 3.52-3.48 5.03-2.2 7.55-4.46 44.88-.06-.22 21.72 5.85.19.28 15.69-1.75 1.9.05 5.91-.81 2.37h-3.19l.14 17.29-17.68.15-4.28 3.78-4.32 2.24-1.14 4.32-2.39 4.12-2.41 7.71-3.2 2.82-3.31 4.42-4.68 2.4-8.37 1.81-7.55 3.9-1.44 1.65-.12-21.94.05-37.69-.39-16.18-.1-18.38Z" />
                <path d="m329.01 114.77 1.44-1.65 7.55-3.9 8.37-1.81 4.68-2.4 3.31-4.42 3.2-2.82 2.41-7.71 2.39-4.12 1.14-4.32 4.32-2.24 4.28-3.78 17.68-.15 8.72-.08.31 52.11-69.79.13-.01-12.84Z"  />
                <path d="m521.22 101.46 17.39-.39-.03 5.8 34.53-.26.04 16.92-4.33.11 1.13 34.64-17.6.34-28.95 1.09-5.94.18-.29-8.92-.78-26.23 5.1-.11-.27-23.17Z" />
                <path d="m518.52 8.96 52.5-1.17 16.64-.28 1.42 46.48-54.6 1.42-14.43.52-1.53-46.97Z" />
                <path d="m459.5 65.59.13 8.64.35 17.75 1.05 34.57-3.54.1-11.34.24-47.34.59-.31-52.11-.13-8.66 27.1-.67 34.03-.45Z" />
                <path d="m520.05 55.93 14.43-.52 54.6-1.42 1.44 52.24-17.41.38-34.53.26.03-5.8-17.39.39-.7-28.3-.47-17.23Z" />
                <path d="m573.11 106.61 17.41-.38 25.86-1.05 32.56-1.76 1.92 69.48-11.8.68-29.16 1.27-14.09.56-25.56.44-.3-17.57-1.13-34.64 4.33-.11-.04-16.92Z" />
                <path d="m589.08 53.99 28.05-.86 30.28-1.29 1.53 51.58-32.56 1.76-25.86 1.05-1.44-52.24Z" />
                <path d="m647.41 51.84-30.28 1.29-28.05.86-1.42-46.48 33.44-.71 24.93-.8.4 17.07.98 28.77Z" />
                <path d="m389.43 10.38 68.94-.85.52 21.71.61 34.35-34.03.45-27.1.67.13 8.66-8.72.08-.14-17.29h3.19l.81-2.37-.05-5.91 1.75-1.9-.28-15.69-5.85-.19.22-21.72Z" />
                <path d="m457.49 126.65 3.54-.1-1.05-34.57-.35-17.75 31.16-.23 29.73-.84.7 28.3.27 23.17-5.1.11.78 26.23-16.85.88-22.6.45-11.04.34-.07-8.81-8.67.35-.45-17.53Z" />
                <path d="m458.37 9.53 40.5-.25 19.65-.32 1.53 46.97.47 17.23-29.73.84-31.16.23-.13-8.64-.61-34.35-.52-21.71Z" />
            </g>

            <g 
            className={cosuna4 ? "cosuna4Group active" : "cosuna4Group"} 
            onClick={() =>setCosuna4(!cosuna4)}>
                <path d="m434.09 286.13-.03-5.82 58.02-1.47.05 5.68.51 14.47-.01 17.29-58.2 1.29-.34-31.44Z" />
                <path d="m554.16 228.67 17.34-.5-1.25-52.32 25.56-.44 14.09-.56 1.34 34.79 1.08 34.84-20.17.64-37.39.81-.6-17.26Z"   />
                <path d="m285.1 37.59.33 23.33-.07 34.62.19 18.19-1.14 1.3-7.46 3.35-7.35 6.44-8.5 2.7-1.94 1.93-3.1.1-4.07-2.18-12.84 1.33.12-26.31.49-57.28.31-24.44 7.12 6.63 7.7-2.93 2.4 3.96-3.66 4.22 9.59 3.82 11.99 1.86 9.89-.64Z"  />
                <path d="m537.22 370.03-.44-26.17 26.42-.34 29-.66.44 14.44 1.84-.07.63 26.04-3.04.11.3 16.96-12.38.42-45.42 1.04-.25-17.21 3.05-.03-.15-14.53Z"   />
                <path d="m523.4 159.71 28.95-1.09 17.6-.34.3 17.57 1.25 52.32-17.34.5-28.81.6-1.95-69.56Z" />
                <path d="m609.9 174.85 29.16-1.27 11.8-.68.75 24.3 1.17 45.5-40.46 1.78-1.08-34.84-1.34-34.79Z" />
                <path d="m381.85 179.49 17.1.1 34.21-.12 11.1-.26.3 8.73.07 24.4.96 36.03-31.84.55-.08-26.12-23.01.17-.05-8.57-8.59-.01.1-17.74-.27-17.16Z" />
                <path d="m554.76 245.93 37.39-.81.41 17.48-.86.05.38 19.96-1.99.07-17.05.51-17.18.15-1.1-37.41Z" />
                <path d="m365.21 325.46 40.37.09 28.97-.99.73 50.73-13.13.53-21.85.41-34.76.2-.33-50.97Z" />
                <path d="m491 230.07 34.35-.8 28.81-.6.6 17.26 1.1 37.41-20.47.28-43.26.9-.05-5.68-.67-31.6-.41-17.17Z" />
                <path d="m590.09 282.68 1.99-.07-.38-19.96.86-.05-.41-17.48 20.17-.64 40.46-1.78.48 32.02.62 20.21-32.6 1.02-30.72 1.14-.47-14.41Z" />
                <path d="m535.39 283.62 20.47-.28 17.18-.15 17.05-.51.47 14.41 1.64 45.77-29 .66-26.42.34-.53-34.08-.86-26.16Z" />
                <path d="m239.15 128.7 12.84-1.33 4.07 2.18 3.1-.1 1.94-1.93 8.5-2.7 7.35-6.44-.22 61.19 1.12-.01.19 34.85-39.24-.2.15-23.25.2-62.26Z" />
                <path d="m590.56 297.09 30.72-1.14 32.6-1.02.48 60.79-12.57.06-35.86 1.27-11.45.18-1.84.07-.44-14.44-1.64-45.77Z" />
                <path d="m413.75 248.92 31.84-.55 45.82-1.13.67 31.6-58.02 1.47.03 5.82-5.76.04-.04-2.91-4.34.04-.06-8.64-10.14.06v-25.8Z" />
                <path d="m365.2 318.34-.04-43.38 34.46.04 14.13-.28 10.14-.06.06 8.64 4.34-.04.04 2.91 5.76-.04.34 31.44.12 6.99-28.97.99-40.37-.09-.01-7.12Z" />
                <path d="m422.15 375.82 13.13-.53 46.53-.73.07 11.42-2.45.07.79 34.35.09 11.72-45.89 1.43-11.78.4-.67-46.6.18-11.53Z" />
                <path d="m276.95 118.38 7.46-3.35 1.14-1.3 3.97-4.61 9.22-4.19 1.37-.02 9.39 3.36 7.33 5.69 6.46 4.23 5.72-3.42.01 12.84.01 51.78-8.58.06.17 14.8-17.17.07.21 20.13-25.62-.04-.19-34.85-1.12.01.22-61.19Z" />
                <path d="m477.7 161.01 1.09 35 .86 34.36 11.35-.3.41 17.17-45.82 1.13-.96-36.03-.07-24.4-.3-8.73-.1-17.19 33.54-1.01Z" />
                <path d="m382.02 214.39 8.59.01.05 8.57 23.01-.17.08 26.12v25.8l-14.13.28-34.46-.04-.22-25.84H353.4l-.52-20.3 2.42-.06-.49-6.7 2.09-4.01-.06-3.89 25.18.23Z" />
                <path d="m329.03 179.39 35.4-.07 17.42.17.27 17.16-.1 17.74-25.18-.23.06 3.89-2.09 4.01.49 6.7-2.42.06.52 20.3-23.37.16-25.91-.24-.46-34.59-.21-20.13 17.17-.07-.17-14.8 8.58-.06Z" />
                <path d="m434.43 317.57 58.2-1.29-.02 17.12.68 37.73.06 2.92-11.54.51-46.53.73-.73-50.73-.12-6.99Z" />
                <path d="m594.48 357.23 11.45-.18 35.86-1.27 12.57-.06.47 38.04.45 17.45-23.92.55-10-.26.04 2.89-23.18.36-.06-14.52-5.79.11-.3-16.96 3.04-.11-.63-26.04Z" />
                <path d="m492.63 316.28.01-17.29-.51-14.47 43.26-.9.86 26.16.53 34.08.44 26.17-43.93 1.1-.68-37.73.02-17.12Z" />
                <path d="m477.7 161.01.02-8.71 22.6-.45 16.85-.88.29 8.92 5.94-.18 1.95 69.56-34.35.8-11.35.3-.86-34.36-1.09-35Z" />
                <path d="m329.02 127.61 69.79-.13.14 52.11-17.1-.1-17.42-.17-35.4.07-.01-51.78Z" />
                <path d="m285.1 37.59 9.06-2.23.6-1.23 8.84-2.02 10.64-4.57 14.21-6.96.1 18.38.39 16.18-.05 37.69.12 21.94-5.72 3.42-6.46-4.23-7.33-5.69-9.39-3.36-1.37.02-9.22 4.19-3.97 4.61-.19-18.19.07-34.62-.33-23.33Z" />
                <path d="m481.81 374.56 11.54-.51-.06-2.92 43.93-1.1.15 14.53-3.05.03.25 17.21-5.62.16.32 17.47-20.77.2-28.28.77-.79-34.35 2.45-.07-.07-11.42Z" />
                <path d="m398.95 179.59-.14-52.11 47.34-.59 11.34-.24.45 17.53 8.67-.35.07 8.81 11.04-.34-.02 8.71-33.54 1.01.1 17.19-11.1.26-34.21.12Z" />
            </g>

            <g 
            className={cosuna2 ? "cosuna2Group active" : "cosuna2Group"} 
            onClick={() =>setCosuna2(!cosuna2)}>
                <path d="m304.12 249.04 25.91.24 23.37-.16h11.54l.22 25.84.04 43.38-60.66.05.06-30.24-.23-21.74-.25-17.37Z"   />
                <path d="m424.89 550.13 22.65-.16 14.32-.49.08 2.84 34.1-1.39 2.03-3.8 3.74-2.02 3.75.18 1.27 51.98-5.34 9.02-1.7 4.3-3.85 2.41-3.43-.98-11.39-5.78-2.44 2.38-3.32-1.65-2.66.81-4.02-.73-3.09-3.2-2.32.5-3.32-1.5-.43 2.7-5.57 1.35-7.93-4-6.01.81-2.96 2.72-5 .41-1.2-45.25-5.76.08-.2-11.54Z" />
                <path d="m435.82 674.74 34.86.6.24 17.33h2.86l.08 5.75 2.98.15.52 5.58 2.94-.03.01 2.93 2.93.03-.27 14.3 9.03-.05.18 1.06-1.27 3.78.6 14.44-1.67 5.6-1.37 7.81-3.34 3.37-4.78.39-7.57 1.86-1.53 2.64.21 3.33-2.46.85-2.38-2.99-2.34-4.95-3.94-2.35-3.81-.67-7.6 1.26-6.99-3.51-2.01-2.26-5.37-3.37-1.7-5.1-.25-3.64 1.34-5.79-2.7-5.58-6.74-4.68 1.95.69 1.66-4.86 1.81-.26 2.8-4.68.25-10.53 1.51-3.18-1.87-8.83.85-4.19 2.9-8.96-.55-3.29Z" />
                <path d="m432.05 606.84 5-.41 2.96-2.72 6.01-.81 7.93 4 5.57-1.35.43-2.7 3.32 1.5 2.32-.5 3.09 3.2 4.02.73 2.66-.81 3.32 1.65 2.44-2.38 11.39 5.78.84 31.67 5.8-.25.14 2.99.09 2.81-2.94.05.09 5.71-2.89.09.03 2.93-11.32.44.17 2.59-5.81.15-.04 13.98-5.99.16-34.86-.6-8.41.04-.28-49.54-.17-3.28-.2-8.48 2.72-.9.82-3.99 1.75-1.75Z" />
                <path d="m368.47 622.64 34.85-.61.7.22 22.94-.29.17 3.28.28 49.54.02 5.73-22.41-.3-36.03.54-.26-26.21-.26-31.9Z" />
                <path d="M375.99 491.08v1.23l25.66.34 12.95-.39.37 17.66 1.16.03.77 32.11-.09 8.22 8.08-.15.2 11.54-56.76.06-.26-34.53-.56-33.91 2.79 1.29 2.78-5.2 2.91 1.7Z" />
                <path d="m368.99 680.75 36.03-.54 22.41.3-.02-5.73 8.41-.04.55 3.29-2.9 8.96-.85 4.19 1.87 8.83-1.51 3.18-.25 10.53-2.8 4.68-1.81.26-1.66 4.86-1.95-.69-4.44-3.37-2.52 1.11-.54 4.38 1.81 1.94 5.99 1.3 1.09 1.54-3.02 3.86-5.47-.53-4.65 4.98-3.88-3.85-4.95.85-.02-17.06-17.5-.03.18-11.71-17.19.73-.41-26.22Z" />
                <path d="m368.32 579.2.01-17.47 56.76-.06 5.76-.08 1.2 45.25-1.75 1.75-.82 3.99-2.72.9.2 8.48-22.94.29-.7-.22-34.85.61-.15-43.44Z" />
                <path d="m238.46 266.01 48.66.4h17.25l.23 21.74-6.01 5.18-5.89 1.14-11.41 5.08-.68 1.92-4.91 4.22-2.3 4.54-9.5 8.94-5.29 1.54-4.12 2.07-2.91 5.28 1.52 3 3.27 1.71.09 2.92-12.81-.04.01-4.18-6.28.03 1.08-65.49Z" />
                <path d="m238.8 214.21 39.24.2 25.62.04.46 34.59.25 17.37h-17.25l-48.66-.4.34-51.8Z" />
                <path d="m316.78 439.89.51-52.17 48.3.25 1.27-.01.12 46.69 7.04.05-2.27 5.72-.44 4.42 1.31 5.4-5.57 6.03.33 10.33-39.29-.54-11.51-.03.2-26.14Z" />
                <path d="m304.54 318.39 60.66-.05.01 7.12.33 50.97.05 11.54-48.3-.25-12.61-.1.09-17.08-.17-17.14-.06-35.01Z" />
                <path d="m237.38 331.5 6.28-.03-.01 4.18 12.81.04-1.7 13.7-2.71 6.41.64 11.04-1.5 2.13 3.07 1.58-2.08 3.43 4.39 4.86 3.83 1.39 1.7 2.69-1.25 3.38 3.2 1.76-1.61 4.35-2.94 1.88.28 5.69 1.37 2.47-.93 5.11.51 4.11-1.14 5.48.43 5.02-2 6.91.3 4.64 1.51 5.47-23.1.03.1-52.72.55-55Z" />
                <path d="m460.38 490.95 20.67-.88 23.71-.88 17.69-.23-.06 17.66.38 26-17.55.65.34 12.02-3.75-.18-3.74 2.02-2.03 3.8-34.1 1.39-.08-2.84.12-5.85-.75-29.2-.85-23.48Z" />
                <path d="m371.75 440.42 29.46-.05-.09-5.83 21.52-.59 11.78-.4.26 58.16-20.08.55-12.95.39-25.66-.34v-1.23l-.34-24.55-8.27.07-.33-10.33 5.57-6.03-1.31-5.4.44-4.42Z" />
                <path d="m414.6 492.26 20.08-.55 25.7-.76.85 23.48.75 29.2-.12 5.85-14.32.49-22.65.16-8.08.15.09-8.22-.77-32.11-1.16-.03-.37-17.66Z" />
                <path d="m328.09 466.06 39.29.54 8.27-.07.34 24.55-2.91-1.7-2.78 5.2-2.79-1.29.56 33.91-34.49-.3-23.17-.03.13-34.76 17.21.1-.02-17.39.36-8.76Z" />
                <path d="m434.42 433.55 45.89-1.43.56 28.5-.12 11.82.3 17.63-20.67.88-25.7.76-.26-58.16Z" />
                <path d="m254.26 370.55 50.51-.01-.09 17.08 12.61.1-.51 52.17-28.67-.47-28.28-.23-1.51-5.47-.3-4.64 2-6.91-.43-5.02 1.14-5.48-.51-4.11.93-5.11-1.37-2.47-.28-5.69 2.94-1.88 1.61-4.35-3.2-1.76 1.25-3.38-1.7-2.69-3.83-1.39-4.39-4.86 2.08-3.43Z" />
                <path d="m256.46 335.69-.09-2.92-3.27-1.71-1.52-3 2.91-5.28 4.12-2.07 5.29-1.54 9.5-8.94 2.3-4.54 4.91-4.22.68-1.92 11.41-5.08 5.89-1.14 6.01-5.18-.06 30.24.06 35.01.17 17.14-50.51.01-3.07-1.58 1.5-2.13-.64-11.04 2.71-6.41 1.7-13.7Z" />
                <path d="m365.54 376.43 34.76-.2 21.85-.41-.18 11.53.67 46.6-21.52.59.09 5.83-29.46.05 2.27-5.72-7.04-.05-.12-46.69-1.27.01-.05-11.54Z" />
            </g>

            <g 
            className={cosuna3 ? "cosuna3Group active" : "cosuna3Group"} 
            onClick={() =>setCosuna3(!cosuna3)}>                
                <path d="m560.85 519.08 21.97-14.89 12.03-8.25 13.18-.67 10.41.41-2.15 16.98-5.27 43.96-1.4 10.83-.44 3.69-10.43.43.01 2.93-37.65.89-.28-17.46.02-38.85Z"  />
                <path d="m621.4 414.39-.04-2.89 10 .26 23.92-.55.84 40.78-17.56-.06-14.48.34-2.06-.31-.62-37.57Z" />
                <path d="m561.11 575.39 37.65-.89.42 23.27.76 21.09-3.93-2.87-3.27-5.1-3.47-1.6-5.76-.16-10.41 2.41-5.95-1.22-4.04 1.79-2.43 4.09-.4 5.21 2.7 4.49.02 9.52 1.83 4.98-7.85.17-2.79-1.15-.08-2.89-11.47.31-.38-14.4-5.83.04-.17-5.8-5.79.06-.14-4.35-5.82-1.28-.31-15.66-.07-1.69 11.46-.25-.16-5.88 5.8-.09-.1-5.76 5.67-.12-.22-5.86 14.53-.41Z" />
                <path d="m492.51 612.02 3.43.98 3.85-2.41 1.7-4.3 5.34-9.02 3.53-.47 3.89-2.57 3.79-.21 6.16 1.43.31 15.66 5.82 1.28.14 4.35 5.79-.06.17 5.8 5.83-.04.38 14.4-28.15.5-6.82.3-4.28 5.92.09 2.78-4.19.09-.14-2.99-5.8.25-.84-31.67Z" />
                <path d="m609.62 567.45 1.4-10.83 5.27-43.96 2.15-16.98 38.14-.49.88 39.78-6.16 5.6-4.32 2.45-1.16 2.63 3.11 4.25-3.07 3.71-2.27-1.05-4.76 2.28-1.62 2.66-6.79-.23-1.35 3.8-5.41.62-2.52 3.51-4.04 1.47-1.97 3.16-2.54-.07-2.97-2.31Z" />
                <path d="m522.77 532.62 18.7-.68 19.38-12.86-.02 38.85.28 17.46-14.53.41.22 5.86-5.67.12.1 5.76-5.8.09.16 5.88-11.46.25.07 1.69-6.16-1.43-3.79.21-3.89 2.57-3.53.47-1.27-51.98-.34-12.02 17.55-.65Z" />
                <path d="m534.57 401.8 45.42-1.04.89 32.91.69 19.29.36 14.38-51.27 1.03-1.09-34.44-.3-14.5-.32-17.47 5.62-.16Z" />
                <path d="m581.57 452.96 26.71-.83 13.74-.17 2.06.31 14.48-.34 17.56.06.46 43.2-38.14.49-10.41-.41-13.18.67-12.03 8.25-.26-16.65-.63-20.2-.36-14.38Z" />
                <path d="m499.29 646.43 4.19-.09-.09-2.78 4.28-5.92 6.82-.3 28.15-.5 11.47-.31.08 2.89 2.79 1.15 7.85-.17 2.08 4.5-.93 6.12-6.88 4.53-4.86 8.57-4.03 2.18-9.57 1.66-4.98 4.81-2.2 6.15-.81 6.33-2.49 8.02-4.34 5.72-8.92 5.63-3.17-.77-1.82-2.68-2.96-.4-.58-7.37 2.25-3.61 2.87-1.56-7.51-9.9.03-.74-24.93.46-4.4-1.39-.01-1.48.04-13.98 5.81-.15-.17-2.59 11.32-.44-.03-2.93 2.89-.09-.09-5.71 2.94-.05-.09-2.81Z" />
                <path d="m480.31 432.12-.09-11.72 28.28-.77 20.77-.2.3 14.5 1.09 34.44.13 20.43-8.34.16-17.69.23-23.71.88-.3-17.63.12-11.82-.56-28.5Z" />
                <path d="m579.99 400.76 12.38-.42 5.79-.11.06 14.52 23.18-.36.62 37.57-13.74.17-26.71.83-.69-19.29-.89-32.91Z" />
                <path d="m609.18 571.14.44-3.69 2.97 2.31 2.54.07 1.97-3.16 4.04-1.47 2.52-3.51 5.41-.62 1.35-3.8 6.79.23 1.62-2.66 4.76-2.28 2.27 1.05 3.07-3.71 6.47 8.52 1.23 3.69-.77 2.3-5.4 5.86-1.27 2.64 1.34 2.85-9.34.36-32.6.29.59-5.27Z" />
                <path d="m470.68 675.34 5.99-.16.01 1.48 4.4 1.39 24.93-.46-.03.74 7.51 9.9-2.87 1.56-2.25 3.61.58 7.37-4.12 1.13-2.9 3.45-.39 5.09-2.04 3.57-4.34 3.76-2.98 4.62-.18-1.06-9.03.05.27-14.3-2.93-.03-.01-2.93-2.94.03-.52-5.58-2.98-.15-.08-5.75h-2.86l-.24-17.33Z" />
                <path d="m598.76 574.5-.01-2.93 10.43-.43-.59 5.27 32.6-.29 9.34-.36 11.1 1.84 2.2 1.71-.21 3.57-5.34 5.14-.48 4.11 2.54 5.09-4.05 1.67-7.76-1.78-7.05 3.61-9.2.01-2.93 2.36-9.4 5.28-3.96 2.89-5.97 5.56-5.63 2.56-4.45-.52-.76-21.09-.42-23.27Z" />
                <path d="m522.45 488.96 8.34-.16-.13-20.43 51.27-1.03.63 20.2.26 16.65-21.97 14.89-19.38 12.86-18.7.68-.38-26 .06-17.66Z" />
            </g>

            <g
            className={cosuna1 ? "cosuna1Group active" : "cosuna1Group"} 
            onClick={() =>setCosuna1(!cosuna1)}>
                <path d="m281.61 508.73.31-52.09 6 .16.19-17.38 28.67.47-.2 26.14 11.51.03-.36 8.76.02 17.39-17.21-.1-.13 34.76-28.89-.75.09-17.39Z" />
                <path d="m202.39 676.61 6.84-1.45 3.04-1.95 3.17-4.73 8.01 1.81 1.62-.78 4.08-8.01 5.22-.3-1.42-3.78 1.99-1.34 1.41 3.94 5.63-1.29 2.82-1.48 1.72-3.96.01 13.34 2.82.06-.03 5.85 5.83-.02-.08 12.11 4.33-1.55 4.25-.45 5.8 2.09-.22 25.73-.04 8.82-23.55.02-.11 7.14-34.79-.78.07-6.49-17.53-.31.12-5.31-29.01-.6 3.71-1.32 1.63-7.88 2.67-3.78 4.01.75-1.45 2.42 1.59 2.1 3.01-3.1 1.25-3.98 3.16 5.73 2.55-.27 2-4.2-.12-5.5 1.38-3.77 2.75-3.4 3.61-1.11 6.25-9.02Z" />
                <path d="m303.54 579.48 9.87.3 20.43-.38.04 17.27-2.92.06-.09 62.11-2.44 2.06-2.57-1.67-2.91.3-3.01-2.21-4.92 1.13-3.08-.69-3.09 1.66-1.19-2.42-2.98-1.49-2.54-3.09-4.34 3.69-2.49.66-.99-2.76-2.8-2.84-4.95-1.55-2.17 2.56-3.31-1.22.68-6.13-1.48-1.48.88-7.3-1.74-2.33 1.84-3.2-2.08-1.34-1.93-9.25 1.55-4.2-.89-1.69 2.82-3.63-2.02-4 2.34-2.1.47-7.98 3.32.04 6.35-2.21 1.18-3.33 4.64.68.74-5.66 5.78-6.37Z"/>
                <path d="m246.52 653.29 8.08 1.26 1.27-2.96 3.04.74.28 3.47 3.21-.53-1.35-2.99 3.01-.7 7.59 6.23 2.54-.46 4.74-2.74 2.16-3.65 3.31 1.22 2.17-2.56 4.95 1.55 2.8 2.84.99 2.76 2.49-.66 4.34-3.69 2.54 3.09 2.98 1.49-.1 56.18-25.12.31-10.3-.17.03-2.83-2.94-.04.22-25.73-5.8-2.09-4.25.45-4.33 1.55.08-12.11-5.83.02.03-5.85-2.82-.06-.01-13.34Z" />
                <path d="m237.54 579.63 43.84.3.01-1.25 17.31.13 4.84.67-5.78 6.37-.74 5.66-4.64-.68-1.18 3.33-6.35 2.21-3.32-.04-.47 7.98-2.34 2.1 2.02 4-2.82 3.63.89 1.69-1.55 4.2 1.93 9.25 2.08 1.34-1.84 3.2 1.74 2.33-.88 7.3 1.48 1.48-.68 6.13-2.16 3.65-4.74 2.74-2.54.46-7.59-6.23-3.01.7 1.35 2.99-3.21.53-.28-3.47-3.04-.74-1.27 2.96-8.08-1.26-1.72 3.96-2.82 1.48-5.63 1.29-1.41-3.94-1.99 1.34 1.42 3.78-5.22.3-4.08 8.01-1.62.78-8.01-1.81-3.17 4.73-3.04 1.95-6.84 1.45 1.89-7.24-3.75-1.04 1.8-4.25 4.8-.48 4.42-2.36 2.14-3.08 4.24-.52-3.7-6.32 2.92-2.87 1.76-3.55 2.62-2.39-.67-3.83 4.77-11.9 10.34-3.67 4.09-10.13 1.52-10.04-4.87-6.53.89-1.68-.64-4.43-2.58-3.57-.24-4.44 3.4-2.66Z" />
                <path d="m316.3 718.95 11.79.12 23.75-.5.05 4.99-1.79 1.94 1.17 2.59-.79 3.54 2 6.94 3.11.9-1.98 4.33 2.13 4.27-1.8 1.38 1.06 10.31-2.88-1.06-3.62 1.1-6.69 2.18-7.24 8.15-3.76 2.26-8.39.99-5.27 2.3-5.18 4.85.3 5.14-2.11 8.17-2.05 5.18-4.17 3.23-5.32-.27-1.87-5.91-3.67-4.99-8.79-1.55-7.68-5.77.19-9.39 6.68-9.25 6.3-4.94 2.74-4.37 2.47-1.01-.51-3.59 1.29-3.47 3.72 3.01 5.64-1.15-1.48-1.92.96-3.75 2.62-3.63.77-4.32 8.32-.05-.02-16.98Z" />
                <path d="m351.84 718.57-.13-8.75 17.79.14-.1-2.99 17.19-.73-.18 11.71 17.5.03.02 17.06.38 5.01 3.91 1.81.23 3.9-3.04 3.34-10.25 1.63-1.19 2.95.04 11.88 2.9 6.31-.61 2.06-3.65 1.83-8.59-.77-1.77 2.87.32 7.84-1.02 1.82-5.3 3.13-3.17-.27-.68-2.55 2.77-7.55-.85-3.15-5.68-.46-5.11 3.99-2.05-.23-3.1-3.68-4.59-8.47-2.49-6.76-2.84-1.72 3.62-1.1 2.88 1.06-1.06-10.31 1.8-1.38-2.13-4.27 1.98-4.33-3.11-.9-2-6.94.79-3.54-1.17-2.59 1.79-1.94-.05-4.99Z" />
                <path d="m281.52 526.12 28.89.75 23.17.03 34.49.3.26 34.53-.01 17.47-34.48.2-20.43.38-9.87-.3-4.84-.67-17.31-.13.13-52.56Z" />
                <path d="m333.84 579.4 34.48-.2.15 43.44.26 31.9-24.29.07-1.04 2.25-3.95 1.73-1.48 2.31-7.1-2.06.09-62.11 2.92-.06-.04-17.27Z" />
                <path d="m164.39 712.94 29.01.6-.12 5.31 17.53.31-.07 6.49-.14 8.7-1.24-.03-.92 45.21-3.62 1.11-6.16.22-3.78 2.8-6.85-.03-4.3-4.86-2.38-4.07-5.09-.82-5.36 6.74.3 4.56 3.33 3.67 1.7 6.36-.49 2.02-4.47 2.43-1.55 3.96-2.46.38-2.56-2.97-9.64-4.45-3.51 1.69-2.64-.71-2.13-2.44.71-2.5 4.36 1.43 3.97-.35-.02-3.49-4.11-1.4-2.95-6.08-2.57-2.93 1.41-2.38 4.84 2.93 6.64-.65-1.02-3.6-5.02-.65-.42-3.51 3.84.71-.95-5.32 2.52-.49 1.34-3.51-2.03-6.03 1.44-3.33-2.7-1.62.28-3.92 4.93.09 5.4-3.05 1.7-3.79-.57-2.57-7 2.95-1.87-2.67 2.93-2.21 3.62-.27.96-4.12 3.77.86 2.75-4.81 2.81-3.1-4.68-1.7-4.59-3.4-2.19-3.47.08-4.23Z" />
                <path d="m245.53 726.43.11-7.14 23.55-.02.04-8.82 2.94.04-.03 2.83 10.3.17 25.12-.31-.01 5.73 8.75.04.02 16.98-8.32.05-.77 4.32-2.62 3.63-.96 3.75 1.48 1.92-5.64 1.15-3.72-3.01-1.29 3.47.51 3.59-2.47 1.01-2.74 4.37-6.3 4.94-6.68 9.25-.19 9.39-8-5.5-3.97-1.13-6.38-5.3-4.4-1.42-5.97.7.28-18.73-3.65-.01.22-17.25.79-8.69Z" />
                <path d="m225.24 508.45 56.37.28-.09 17.39-.13 52.56-.01 1.25-43.84-.3-.53-3.78 2.01-6.89-2.5-3.19-7.3-2.98 1.07-7.24-1.58-3.76 1.2-3.05-4.41-4.83-2.3-.24-.36-4.33-5.34-.54 1.53-4.32-2.41-1.5 3.49-6.51 2.66-.63 2.56-3.82 2.56-1.64 1.88-3.73-.9-7.34-3.63-.86Z" />
                <path d="m210.74 725.65 34.79.78-.79 8.69-.22 17.25 3.65.01-.28 18.73-7.24 5.93-2.32.93-6.26-3.81-3.27-4.24-.54-4.21-4.08-.73-.65 4.21-3.56 5.53 1.11 2.91 5.29 4.58-.53 5.23-4 5.27-4.82 1.18-4.19-.66-1.43-5.35 2.38-4.75.01-3.17-1.93-1.44-3.42 1.01.92-45.21 1.24.03.14-8.7Z" />
                <path d="m307.66 657 1.19 2.42 3.09-1.66 3.08.69 4.92-1.13 3.01 2.21 2.91-.3 2.57 1.67 2.44-2.06 7.1 2.06 1.48-2.31 3.95-1.73 1.04-2.25 24.29-.07.26 26.21.41 26.22.1 2.99-17.79-.14.13 8.75-23.75.5-11.79-.12-8.75-.04.01-5.73.1-56.18Z" />
                <path d="m236.73 439.22 23.1-.03 28.28.23-.19 17.38-6-.16-.31 52.09-56.37-.28-.69-5.43 1.38-1.37-.91-3.49 2.63-4.51 4.4-.98 2.31-2.64 2.2.83.17-51.64Z" />
            </g>
        </g>
    </svg>
)
export default Indiana
