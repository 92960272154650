import React from 'react'
import './PublicAlertForm.css'
import { useEffect, useState } from 'react'
import {primaryAPILink} from '../../../../config/routes.js'


const PublicAlertForm = () => {
    const [bannerText, setBannerText] = useState("")
    const [bannerLink, setBannerLink] = useState("")
    const [bannerDateStart, setBannerDateStart] = useState("")
    const [bannerDateEnd, setBannerDateEnd] = useState("")

    useEffect(() => {
        fetch(`${primaryAPILink}/api/eventbanner/all`, {headers: {jwtKey: localStorage.getItem("loginKey")}})
              .then(res => res.json())
              .then(
                (result) => {
                  if(result.length > 0) {
                    setBannerText(result[0].message);
                    setBannerLink(result[0].link);
                    setBannerDateStart(result[0].dateStart.split("T")[0]);
                    setBannerDateEnd(result[0].dateEnd.split("T")[0]);
                  }
                }
              )
          
      }, [])

      function updateAlert(){
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', jwtKey: localStorage.getItem("loginKey") },
            body: JSON.stringify({
            startDate: bannerDateStart,
            endDate: bannerDateEnd,
            message: bannerText,
            link: bannerLink})
        };
        fetch(`${primaryAPILink}/api/eventbanner/updateEvent`, requestOptions)

      }

  return (
    <div className='public__alert__form__wrapper'>
        <div className='public__alert__form'>
            <div className='public__alert__form__header'>Modify Public Alert</div>
            <form>
                <div className='public__alert__form__pair'>
                    <div className='public__alert__form__item'>
                        <label for="public__alert__form__start__date">Start Date</label>
                        <input id="public__alert__form__start__date" 
                        type="date" 
                        value={bannerDateStart}
                        onInput={(event)=>{setBannerDateStart(event.target.value)}}></input>
                    </div>
                    <div className='public__alert__form__item'>
                        <label for="public__alert__form__end__date">End Date</label>
                        <input id="public__alert__form__end__date" 
                        type="date" 
                        value={bannerDateEnd}
                        onInput={(event)=>{setBannerDateEnd(event.target.value)}}></input>
                    </div>
                </div>
                <div className='public__alert__form__item'>
                    <label for="public__alert__form__message">Message</label>
                    <input id="public__alert__form__message" 
                    type="text" 
                    value={bannerText}
                    onInput={(event)=>{setBannerText(event.target.value)}}></input>
                </div>
                <div className='public__alert__form__item'>
                    <label for="public__alert__form__link">Link</label>
                    <input id="public__alert__form__link" 
                    type="text" 
                    value={bannerLink}
                    onInput={(event)=>{setBannerLink(event.target.value)}}></input>
                </div>

                <button onClick={()=>updateAlert()}>Update Alert</button>
                
            </form>
        </div>
    </div>
  )
}

export default PublicAlertForm