import React, { useEffect, useState } from 'react';
import './EventBanner.css';
import { primaryAPILink } from '../../../config/routes.js';

const EventBanner = () => {
  const [bannerText, setBannerText] = useState('');
  const [bannerLink, setBannerLink] = useState('');
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch(`${primaryAPILink}/api/eventbanner`)
      .then((res) => {
        if (!res.ok) {
          throw new Error('Error connecting to the backend');
        }
        return res.json();
      })
      .then(
        (result) => {
          if (result.length > 0) {
            setBannerText(result[0].message);
            setBannerLink(result[0].link);
          }
        }
      )
      .catch((err) => {
        setError(err.message);
      });
  }, []);

  function updateBanner() {
    if (error) {
      return <div className="error-banner">Certain website features are currently not functioning as expected. We are working to resolve these issues, and normal service is expected to resume soon.
      </div>;
    }

    if (bannerText !== '') {
      return (
        <a href={bannerLink} target="_blank" id="event__banner">
          {bannerText}
        </a>
      );
    }
  }

  return <>{updateBanner()}</>;
};

export default EventBanner;
