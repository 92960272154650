import React, { useState, useEffect, useRef, useCallback } from 'react';
import './IGNISModal.css';
import { primaryAPILink } from '../../../config/routes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import NewsStoryPopupImage from '../../News/NewsStory/NewsStoryComponents/NewsStoryPopupImage/NewsStoryPopupImage'

const IGNISModal = ({ isOpen, closeModal, item }) => {
    const [details, setDetails] = useState(null);
    const [activeSection, setActiveSection] = useState(null);
    const [images, setImages] = useState([])
    const sectionsRef = useRef({});

    useEffect(() => {
        const fetchDetails = async () => {
            try {
                const response = await fetch(`${primaryAPILink}/api/ignis/details/${item.StratId}`);
    
                if (!response.ok) {
                    throw new Error('Failed to fetch details');
                }
                const data = await response.json();
                setDetails(data);
    
                // Set document title
                document.title = data.StratigraphicName || 'IGNIS Modal';
    
                // Fetch images based on StratigraphicName
                if (data.StratigraphicName) {
                    fetchImages(data.StratigraphicName);
                }
            } catch (error) {
                console.error('Error fetching details:', error);
            }
        };
    
        const fetchImages = async (stratigraphicName) => {
            try {
                const response = await fetch(`${primaryAPILink}/api/ignis/photos/${stratigraphicName}`);
                
                if (!response.ok) {
                    throw new Error('Failed to fetch images');
                }
                const imagesData = await response.json();
                setImages(imagesData);
            } catch (error) {
                console.error('Error fetching images:', error);
            }
        };
    
        if (isOpen && item) {
            fetchDetails();
        }
    }, [isOpen, item, primaryAPILink]);





    const useIntersectionObserver = (setActiveSection) => {
        const observer = useRef();

        const observe = useCallback((nodes) => {
            if (observer.current) observer.current.disconnect();

            observer.current = new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
                            setActiveSection(entry.target.dataset.section);
                        }
                    });
                },
                { threshold: 0.7 }
            );

            nodes.forEach(node => {
                if (node) observer.current.observe(node);
            });
        }, [setActiveSection]);

        return observe;
    };

    const sectionRefs = useIntersectionObserver(setActiveSection);

    useEffect(() => {
        if (details) {
            const sections = Object.keys(sectionsRef.current).map(key => sectionsRef.current[key]);
            sectionRefs(sections);
        }
    }, [details, sectionRefs]);

    if (!isOpen) return null;

    const formatLegacyInlineHTML = (input) => {
        if (!input) return null;

        input = input.toString();
        input = input.replace(/<br\s*\/?>/gi, '<br>');
        input = input.replace(/<i><b>(.*?)<\/b><\/i>/gi, '<span class="italic bold">$1</span>');
        input = input.replace(/<i>(.*?)<\/i>/gi, '<span class="italic">$1</span>');
        input = input.replace(/<b>(.*?)<\/b>/gi, '<span class="bold">$1</span>');
        input = input.replace(/(?:<br>\s*)+/gi, '</p><p>');

        if (!input.startsWith('<p>') && !input.endsWith('</p>')) {
            input = `<p>${input}</p>`;
        } else if (input.startsWith('<p>') && !input.endsWith('</p>')) {
            input = input + '</p>';
        } else if (!input.startsWith('<p>') && input.endsWith('</p>')) {
            input = `<p>${input}`;
        }

        return (
            <>
                {React.createElement('div', { dangerouslySetInnerHTML: { __html: input } })}
            </>
        );
    };

    function formatDate(dateString) {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', options);
    }

    const scrollToSection = (sectionName) => {
        const section = sectionsRef.current[sectionName];
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
            setTimeout(() => {
                setActiveSection(sectionName);
            }, 300); //This delay is added to give the scrolling a sec to get into view.
        }
    };

    function getRegionIDName(id) {
        switch (id) {
            case 1:
                return "Illinois Basin (COSUNA 11)";
            case 2:
                return "Illinois Basin Margin (COSUNA 12)";
            case 3:
                return "Cincinnati Arch (COSUNA 13)";
            case 4:
                return "Kankakee Arch (COSUNA 14)";
            case 5:
                return "Michigan Basin (COSUNA 15)";
            default:
                return "Unknown Region";
        }
    }


    return (
        <div className='ignis-modal-overlay' onClick={closeModal}>
            <div className='ignis-modal-content' onClick={(e) => e.stopPropagation()}>
                {details ? (
                    <>
                        <nav className='ignis-modal-nav'>
                            <ul>
                                {details.System ? <li className={activeSection === 'Age' ? 'active' : ''} onClick={() => scrollToSection('Age')}>Age</li> : null}
                                {details.TypeLocDesc ? <li className={activeSection === 'Type designation' ? 'active' : ''} onClick={() => scrollToSection('Type designation')}>Type designation</li> : null}
                                {details.History && Array.isArray(details.History) && details.History.filter(item => item !== null).length > 0 && details.History[0] && (
                                    <li className={activeSection === 'History' ? 'active' : ''} onClick={() => scrollToSection('History')}>History</li>
                                )}
                                {details.Description ? <li className={activeSection === 'Description' ? 'active' : ''} onClick={() => scrollToSection('Description')}>Description</li> : null}

                                {details.Boundaries ? <li className={activeSection === 'Boundaries' ? 'active' : ''} onClick={() => scrollToSection('Boundaries')}>Boundaries</li> : null}

                                {details.Correlation ? <li className={activeSection === 'Correlations' ? 'active' : ''} onClick={() => scrollToSection('Correlations')}>Correlations</li> : null}
                                {details.EconomicImportance ? <li className={activeSection === 'Economic importance' ? 'active' : ''} onClick={() => scrollToSection('Economic importance')}>Economic importance</li> : null}
                                {details.StratNamesRegional ? <li className={activeSection === 'Regional indiana usage' ? 'active' : ''} onClick={() => scrollToSection('Regional indiana usage')}>Regional indiana usage</li> : null}
                                {details.MiscAbandonedNames ? <li className={activeSection === 'MiscAbandonedNames' ? 'active' : ''} onClick={() => scrollToSection('MiscAbandonedNames')}>Misc/abandoned names</li> : null}
                                {details.MapAbbrev ? <li className={activeSection === 'Geologic map unit designation' ? 'active' : ''} onClick={() => scrollToSection('Geologic map unit designation')}>Geologic map unit designation</li> : null}
                                {details.Citations ? <li className={activeSection === 'References' ? 'active' : ''} onClick={() => scrollToSection('References')}>References</li> : null}
                            </ul>
                        </nav>
                        <div className='ignis-modal-content-text'>
                            <div className='ignis-modal-relative'>
                            <h2>{details.StratigraphicName}</h2>
                            <button className='ignis-modal-close-btn' onClick={closeModal}>
                                <FontAwesomeIcon icon={faXmark}/> 
                            </button>
                                </div>

            

                            {details.System ? (<section ref={(el) => sectionsRef.current['Age'] = el} data-section='Age' className='ignis-modal-section'>
                                <h3>Age:</h3>
                                <p>{details.System}</p>
                            </section>) : null}

                            {images.length > 0 && (
                                <section ref={(el) => sectionsRef.current['Images'] = el} data-section='Images' className='ignis-modal-section'>
                                    <h3>Images:</h3>
                                    <div className='ignisImageGrid'>
                                        {images.map((image, key) => (
                                            <div className='ignisImageWrapper' key={key}>
                                                <NewsStoryPopupImage src={image.link} text={image.comment}/>
                                            </div>
                                        ))}
                                    </div>
                                </section>
                            )}

                            {details.TypeLocDesc ? (<section ref={(el) => sectionsRef.current['Type designation'] = el} data-section='Type designation' className='ignis-modal-section'>
                                <h3>Type designation:</h3>
                                {formatLegacyInlineHTML(details.TypeLocDesc)}
                            </section>) : null}

                            {details.History && Array.isArray(details.History) && details.History.filter(item => item !== null).length > 0 && (
                                <section ref={(el) => sectionsRef.current['History'] = el} data-section='History' className='ignis-modal-section'>
                                    <h3>History of usage:</h3>
                                    {details.History.filter(item => item !== null).map((item, index) => (
        
                                        <React.Fragment key={index}>
                                            {formatLegacyInlineHTML(item)}
                                        </React.Fragment>
                         
                                    ))}
                                </section>
                            )}

                            {details.Description ? (<section ref={(el) => sectionsRef.current['Description'] = el} data-section='Description' className='ignis-modal-section'>
                                <h3>Description:</h3>
                                {formatLegacyInlineHTML(details.Description)}
                            </section>) : null}

                            {details.Boundaries ? (<section ref={(el) => sectionsRef.current['Boundaries'] = el} data-section='Boundaries' className='ignis-modal-section'>
                                <h3>Boundaries:</h3>
                                {formatLegacyInlineHTML(details.Boundaries)}
                            </section>) : null}

                            {details.Correlation ? (<section ref={(el) => sectionsRef.current['Correlations'] = el} data-section='Correlations' className='ignis-modal-section'>
                                <h3>Correlations:</h3>
                                {formatLegacyInlineHTML(details.Correlation)}
                            </section>) : null}

                            {details.EconomicImportance ? (<section ref={(el) => sectionsRef.current['Economic importance'] = el} data-section='Economic importance' className='ignis-modal-section'>
                                <h3>Economic importance:</h3>
                                {formatLegacyInlineHTML(details.EconomicImportance)}
                            </section>) : null}

                            {details.StratNamesRegional && Array.isArray(details.StratNamesRegional) && details.StratNamesRegional.length > 0 && (
    <section ref={(el) => sectionsRef.current['Regional indiana usage'] = el} data-section='Regional indiana usage' className='ignis-modal-section'>
        <h3>Regional indiana usage:</h3>

        {details.StratNamesRegional
            .sort((a, b) => (a.RegionID ?? 0) - (b.RegionID ?? 0))
            .map((item, index) => (
                <div className="regional-ignis-modal-section" key={index}>
                    <h4>{getRegionIDName(item.RegionID)}</h4>
                    <ul className='regional-ignis-modal'>
                        {item.SuperGroup && <li>Supergroup: <span className='italic'>{item.SuperGroup}</span></li>}
                        {item.GroupName && <li>Group: <span className='italic'>{item.GroupName}</span></li>}
                        {item.Formation && <li>Formation: <span className='italic'>{item.Formation}</span></li>}
                        {item.Member && <li>Member: <span className='italic'>{item.Member}</span></li>}
                        {item.Bed && <li>Bed: <span className='italic'>{item.Bed}</span></li>}
                    </ul>
                </div>
            ))
        }
    </section>
)}


                            {details.MiscAbandonedNames ? (<section ref={(el) => sectionsRef.current['MiscAbandonedNames'] = el} data-section='MiscAbandonedNames' className='ignis-modal-section'>
                                <h3>Misc/Abandoned Names:</h3>
                                <p>{details.MiscAbandonedNames}</p>
                            </section>) : null}

                            <section className='ignis-modal-section'>
                                <h3>COSUNA areas and regional terminology:</h3>
                                <p>Names for geologic units vary across Indiana. The Midwestern Basin and Arches Region COSUNA chart (Shaver, 1984) was developed to strategically document such variations in terminology. The geologic map (below left) is derived from this chart and provides an index to the five defined COSUNA regions in Indiana. The regions are generally based on regional bedrock outcrop patterns and major structural features in Indiana. (Click the maps below to view more detailed maps of COSUNA regions and major structural features in Indiana.)</p>

                                <div className='ignis-figure-modals'>
                                    <figure>
                                        <img src="https://data.igws.indiana.edu/filestore/4/9/0/8/6_17739e5e68d75a5/68094pre_95179e206e0823c.jpg?v=1721050285" />
                                        <figcaption>
                                            COSUNA areas and numbers that approximate regional bedrock outcrop patterns and major structural features in Indiana.
                                        </figcaption>
                                    </figure>

                                    <figure>
                                        <img src="https://data.igws.indiana.edu/filestore/1/9/0/8/6_d9b22b08c07c7ac/68091pre_cf779e8cba96f8d.jpg?v=1721049974" />
                                        <figcaption>
                                            Major tectonic features that affect bedrock geology in Indiana.
                                        </figcaption>
                                    </figure>
                                </div>
                            </section>

                            {details.MapAbbrev ? (<section ref={(el) => sectionsRef.current['Geologic map unit designation'] = el} data-section='Geologic map unit designation' className='ignis-modal-section'>
                                <h3>Geologic map unit designation:</h3>
                                <p>{details.MapAbbrev}</p>
                                <p className='note'>Note: Hansen (1991, p. 52) in Suggestions to authors of the reports of the United States Geological Survey noted that letter symbols for map units are considered to be unique to each geologic map and that adjacent maps do not necessarily need to use the same symbols for the same map unit. Therefore, map unit abbreviations in the Indiana Geologic Names Information System should be regarded simply as recommendations.</p>
                            </section>) : null}

                            {details && details.Citations && details.Citations.length > 0 ? (
                                <section ref={(el) => sectionsRef.current['References'] = el} data-section='References' className='ignis-modal-section'>
                                    <h3>References:</h3>
                                    {details.Citations
                                        .filter(item => item.FullCitation)
                                        .sort((a, b) => a.FullCitation.localeCompare(b.FullCitation))
                                        .map((item, index) => (
                                            <cite key={index} dangerouslySetInnerHTML={{ __html: item.FullCitation }}></cite>
                                        ))}
                                </section>
                            ) : null}

                            <section className='ignis-modal-section'>
                                <h3>For additional information, contact:</h3>
                                <p>Nancy Hasenmueller (hasenmue@indiana.edu)</p>
                                {details.RevisedDate ? <p className='note'>Date last revised: {formatDate(details.RevisedDate)}</p> : null}
                            </section>
                        </div>

                    </>
                ) : (
                    <p>Loading...</p>
                )}
            </div>
        </div>
    );
};

export default IGNISModal;
