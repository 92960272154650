import React from 'react'
import CrimsonBlockHeader from '../../../components/CrimsonBlockHeader/CrimsonBlockHeader'
import AdminNavbar from '../AdminNavbar/AdminNavbar'
import './AdminHome.css'

const AdminHome = () => {
  return (
    <div className='admin__home__wrapper'>
        <AdminNavbar active="home"/>
        <div className='admin__primary__section'>
            <CrimsonBlockHeader text="Admin" />
            <div className='admin__home__primary__cards'>
                <div className='admin__home__primary__card'>
                    <div className='admin__home__primary__card__header'>External Links</div>
                    <ul>
                        <li><a href="https://data.igws.indiana.edu/" target="_blank">Resource Space</a></li>
                        <li><a href="" target="_blank">External Service Requests</a></li>
                        <li><a href="" target="_blank">Marketing Cloud</a></li>
                        <li><a href="" target="_blank">Salesforce</a></li>
                        <li><a href="" target="_blank">Big Commerce</a></li>
                        <li><a href="" target="_blank">LiveWhale External Event Calendar</a></li>
                    </ul>
                </div>

                <div className='admin__home__primary__card'>
                    <div className='admin__home__primary__card__header'>Upcoming Events</div>
                </div>
                

            </div>

        </div>

    </div>
  )
}

export default AdminHome