import React, { useState, useEffect, useRef } from 'react';
import './StaffProfileEditor.css';
import { primaryAPILink } from '../../../../config/routes.js';
import StaffProfileBanner from '../../../Staff/StaffProfile/StaffProfileBanner/StaffProfileBanner.jsx';
import StaffProfileName from '../../../Staff/StaffProfile/StaffProfileName/StaffProfileName.jsx';
import StaffProfilePicture from '../../../Staff/StaffProfile/StaffProfilePicture/StaffProfilePicture.jsx';
import StaffProfileTitle from '../../../Staff/StaffProfile/StaffProfileTitle/StaffProfileTitle.jsx';

const StaffProfileEditor = () => {
  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [title, setTitle] = useState("");
  const [picture, setPicture] = useState("");
  const [bannerType, setBannerType] = useState("");
  const [bannerEditOpen, setBannerEditOpen] = useState(false);
  const [email, setEmail] = useState("Loading...");
  const [phone, setPhone] = useState("Loading...");
  const [description, setDescription] = useState('Loading...');
  const pRef = useRef(null);

  useEffect(() => {
    if (pRef.current) {
      const selection = window.getSelection();
      const range = document.createRange();
  
      // Ensure there's a child node to work with
      const childNode = pRef.current.childNodes[0];
      if (childNode) {
        range.setStart(childNode, description.length);
        range.collapse(true);
        selection.removeAllRanges();
        selection.addRange(range);
      }
    }
  }, [description]);


  const handleInput = (event) => {
    let ourText = event.target.innerText;
    setDescription(ourText);
  };
  const [specialtiesList, setSpecialtiesList] = useState([]);
  const [linkedin, setLinkedin] = useState("");
  const [website, setWebsite] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [charLimit, setCharLimit] = useState(500); // description char limit

  useEffect(() => {
    fetch(`${primaryAPILink}/api/staff/specificPersonJWT/${localStorage.getItem("loginKey")}`)
      .then(res => res.json())
      .then(result => {
        if (result.length > 0) {
          console.log(result);
          setFName(result[0].fName);
          setLName(result[0].lName);
          setTitle(result[0].title);
          setPicture(result[0].picture);
          setBannerType(result[0].bannerType);
          setEmail(result[0].email);
          setPhone(result[0].phone);
          setDescription(result[0].description);
          setLinkedin(result[0].linkedin);
          setWebsite(result[0].website);
          if (result[0].specialties !== "") {
            setSpecialtiesList(result[0].specialties.split(","));
          }
        }
      });
  }, []);

  function updateBanner(value) {
    setBannerType(value);
    setBannerEditOpen(false);
  }

  function updateUserInfo() {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', jwtKey: localStorage.getItem("loginKey") },
      body: JSON.stringify({
        bannerType: bannerType,
        description: description,
        specialties: specialtiesList.toString(),
        linkedin: linkedin,
        website: website
      })
    };
    fetch(`${primaryAPILink}/api/staff/updateProfile`, requestOptions)
      .then(() => {
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 1000);
      });
  }

  const [drawButton, setDrawButton] = useState(<></>);
  useEffect(() => {
    if (specialtiesList.length > 3) {
      setDrawButton(<></>);
    } else {
      setDrawButton(
        <li>
          <input
            maxLength="30"
            onBlur={(event) => {
              let newList = [...specialtiesList];
              newList.push(event.target.value);
              setSpecialtiesList(newList);
            }}
          ></input>
        </li>
      );
    }
  }, [specialtiesList]);

  // Function to format phone number into (111) 111-1111 format
  const formatPhoneNumber = (phoneNumber) => {
    // Remove all non-digits
    const cleaned = phoneNumber.replace(/\D/g, '');
    // Format: (XXX) XXX-XXXX
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    // Return original if format doesn't match
    return phoneNumber;
  };

  return (
    <div id="staff__profile__content" className='admin__staff__profile__editor'>
      {showAlert && <div className="update-alert">✅ Page has been updated</div>}
      <StaffProfileBanner bannerType={`banner${bannerType}`} />
      <button className='staff__profile__editor__banner__button' onClick={() => setBannerEditOpen(true)}>Change Banner</button>
      <div className={bannerEditOpen ? 'bannerOptionsStaffProfileEditor open' : 'bannerOptionsStaffProfileEditor'}>
        <button className='bannerButton banner0' onClick={() => { updateBanner(0) }}></button>
        <button className='bannerButton banner1' onClick={() => { updateBanner(1) }}></button>
        <button className='bannerButton banner2' onClick={() => { updateBanner(2) }}></button>
        <button className='bannerButton banner3' onClick={() => { updateBanner(3) }}></button>
        <button className='bannerButton banner4' onClick={() => { updateBanner(4) }}></button>
        <button className='bannerButton banner5' onClick={() => { updateBanner(5) }}></button>
        <button className='bannerButton banner6' onClick={() => { updateBanner(6) }}></button>
        <button className='bannerButton banner7' onClick={() => { updateBanner(7) }}></button>
        <button className='bannerButton banner8' onClick={() => { updateBanner(8) }}></button>
        <button className='bannerButton banner9' onClick={() => { updateBanner(9) }}></button>
        <button className='bannerButton banner10' onClick={() => { updateBanner(10) }}></button>
      </div>
      <div className='staff__profile__head'>
        <StaffProfilePicture pictureLink={picture} altText={`${fName} ${lName} profile picture`} />
        <div className='staff__profile__head__text'>
          <StaffProfileName fName={fName} lName={lName} />
          <StaffProfileTitle title={title} />
        </div>
      </div>
      <div className='staff__profile__contact'>
        <div className='staff__profile__contact__item'>
          <h3>Email:</h3>
          <p>{email}</p>
        </div>
        <div className='staff__profile__contact__item'>
          <h3>Phone:</h3>
          <p>{phone ? formatPhoneNumber(phone) : "No listed number"}</p>
        </div>
        <div className='staff__profile__contact__item'>
          <h3>LinkedIn:</h3>
          <input
            type="text"
            value={linkedin}
            onChange={(event) => setLinkedin(event.target.value)}
            onBlur={() => updateUserInfo()}
          />
        </div>
        <div className='staff__profile__contact__item'>
          <h3>Website:</h3>
          <input
            type="text"
            value={website}
            onChange={(event) => setWebsite(event.target.value)}
            onBlur={() => updateUserInfo()}
          />
        </div>
      </div>
      <div className='staff__profile__bio'>
        <h3>Biography:</h3>
        <p
      contentEditable="true"
      ref={pRef}
      onInput={handleInput}
      suppressContentEditableWarning={true}
      dangerouslySetInnerHTML={{ __html: description }}
      style={{marginBottom: '10px'}}
    />


        <p  style={{ color: description.length > charLimit ? 'red' : 'black', fontSize: '14px', marginTop: '0' }}>Characters remaining: {charLimit - description.length}</p>

        <h3>Specialties:</h3>
        <ul className="staff__profile__specialties__container admin">
          {drawButton}
          {specialtiesList.map((specialty, index) => (
            <li key={index}>
              {specialty}
              <button
                onClick={() => {
                  let tempList = [...specialtiesList];
                  tempList.splice(index, 1);
                  setSpecialtiesList(tempList);
                }}
              >
                x
              </button>
            </li>
          ))}
        </ul>
      </div>
      <button onClick={() => updateUserInfo()} className='admin__staff__profile__update'
        
        style={{ pointerEvents: description.length > charLimit ? 'none' : 'auto',
         opacity: description.length > charLimit ? '0.3' : '1'
        }}
        >Update Page</button>
    </div>
  );
};

export default StaffProfileEditor;
