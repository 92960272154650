import React from 'react'
import './InfoPopupIcon.css'

const InfoPopupIcon = (props) => {
  return (
    <div className="info">
        <i className="icon-info-sign"></i>

        <div className='extra-info-wrapper'>
            {props.text.map((text)=>
            {
                return(<span className="extra-info">{text}
                </span>)
            })}
        </div>
    </div>
  )
}

export default InfoPopupIcon