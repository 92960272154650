import React, { useEffect } from 'react';
import { primaryAPILink } from '../../config/routes.js';
import { useNavigate } from 'react-router-dom';
import CrimsonBlockHeader from '../../components/CrimsonBlockHeader/CrimsonBlockHeader';
import './Login.css';

/* global google */

const Login = () => {
  document.title = 'IGWS | Login';
  const navigate = useNavigate();

  useEffect(() => {
    function handleCallbackResponse(response) {
      if (!response || !response.credential) {
        console.error('Invalid response or missing credential property.');
        return;
      }

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          jwt: response.credential,
        }),
      };

      fetch(`${primaryAPILink}/api/login/verifyLogin`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
          if (result) {
            localStorage.setItem('loginKey', result);
            navigate('/admin');
          } else {
            console.error('Failed to retrieve result from the server.');
          }
        })
        .catch((error) => {
          console.error('Error while fetching data:', error);
        });
    }

    function initializeGoogleSignIn() {
      if (window.google && window.google.accounts && window.google.accounts.id) {
        google.accounts.id.initialize({
          client_id: '338924793376-k8pnevtkbf5cktfbq93s31ubsj5hq3o2.apps.googleusercontent.com',
          callback: handleCallbackResponse,
        });
        google.accounts.id.renderButton(document.getElementById('signInDiv'), { theme: 'outline', size: 'large' });
      } else {
        console.error('Google library not available. Retry initialization later.');
        // Retry initialization after a delay
        setTimeout(initializeGoogleSignIn, 1000); // Retry after 1 second
      }
    }
  
    initializeGoogleSignIn();
  }, [navigate]);

  return (
    <div className='staff__login__page'>
      <CrimsonBlockHeader text='staff Login' />
      <div id='signInDiv'>
        {typeof window.google === 'undefined' && <p>Loading Google Sign-In...</p>}
      </div>
    </div>
  );
};

export default Login;
