import React, { useEffect, useState } from 'react'
import './NotFound.css'
import { geologyFacts } from './geologyFacts'; 


const NotFound = () => {
  document.title = 'IGWS | 404';
    
    const [whileLoading, setWhileLoading] = useState(<div className='loading__ui'>
    <div className='loading__header'>Loading...</div>
    <div className='loading__content'>
      <div className='loading__content__2'></div>
      <div className='loading__content__1'></div>
      <div className='loading__content__2'></div>
      <div className='loading__content__3'></div>
      <div className='loading__content__image'></div>
      <div className='loading__content__1'></div>
      <div className='loading__content__2'></div>
    </div>
  </div>)

const [funFact, setFunFact] = useState(geologyFacts[Math.floor(Math.random() * geologyFacts.length)])

function generateNewFunFact() {
  console.log('Generating new fun fact...');
  setFunFact(geologyFacts[Math.floor(Math.random() * geologyFacts.length)])
}

useEffect(() => {
  const timer = setTimeout(() => {
    // Code to be executed after 2 seconds
    setWhileLoading(
      <div className='page__not__found__wrapper'>
        <h1>404 Page Not Found</h1>
        <div className='page__not__found__graphic' onClick={() => generateNewFunFact()}>
         
            <div className='eye eye1'>
                <div className='pupil'></div>
            </div>
            <div className='eye eye2'>
                <div className='pupil'></div>
            </div>
            <div className='mouth'>
                <div className='mouth1 mouthpiece'></div>
                <div className='mouth2 mouthpiece'></div>
                <div className='mouth3 mouthpiece'></div>
                <div className='mouth4 mouthpiece'></div>
            </div>
           <img src="https://data.igws.indiana.edu/filestore/1/0/0/3/4_3164a4a74fe2ea9/43001scr_4c71a2dec402474.jpg?v=1662755540" alt="page not found graphic"></img>
         
        </div>
          <p className='geology-fun-facts'>Geology Fun Fact: {funFact}</p>
  
    </div>)
      document.title = "IGWS | 404 Not Found"
      }, 0);
  
      // Clear the timer if the component unmounts
      return () => clearTimeout(timer);
    }, [funFact]); // Empty dependency array to ensure it runs only once after the initial render
  
  return (
    <>
        {whileLoading}
    </>
  )
}

export default NotFound