import React from 'react'
import './NewsStoryParagraph.css'

const NewsStoryParagraph = (props) => {

  function convertTextLinks(paragraph) {
    let ourText = paragraph;
    var link = ourText.replace(/\[([^\]]+)]\(([^)]+)\)/g, '<a target="_blank" class="news__story__paragraph__link" href="$2">$1</a>');
    link = link.replace(/\*{2}([^*]+)\*{2}/g, '<span class="bold__text">$1</span>');
    link = link.replace(/%{2}([^*]+)%{2}/g, '<span class="italic__text">$1</span>');
    link = "" + link + "";
  
    return link;
  }
  

  return (
    <p className='news__story__paragraph__text' dangerouslySetInnerHTML={{__html: convertTextLinks(props.text)}}></p>
  )
}

export default NewsStoryParagraph