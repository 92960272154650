import React, { useState } from 'react';
import { primaryAPILink } from '../../../../config/routes';
import './ProjectModal.css';

const ProjectModal = ({ project, onClose, onSave }) => {
    const [editedProject, setEditedProject] = useState({ ...project });

    const handleFieldChange = (field, value) => {
        setEditedProject(prevProject => ({ ...prevProject, [field]: value }));
    };

    const handleSave = () => {
        onSave(editedProject);
        console.log(editedProject)
        updateProject();
        onClose();

        // Refresh the window after save
    window.location.reload();
    };

    const formattedDateForBackend = isoDate => {
        
        if (!isoDate) return null; // Handle empty or null values
    
        const date = new Date(isoDate);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');

        console.log( `${year}-${month}-${day}`)
    
        return `${year}-${month}-${day}`;
    };


    function updateProject(){
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', jwtKey: localStorage.getItem("loginKey")},
          body: JSON.stringify({
          id: editedProject.projectID,
          title: editedProject.Project_Title,
          status: editedProject.Status,
          startDate:  formattedDateForBackend(editedProject.Date_Start),
          endDate:  formattedDateForBackend(editedProject.Date_End),
          fName:  editedProject.fName,
          lName:  editedProject.lName,
          funding:  editedProject.fundingSource,
          issue:  editedProject.Issue,
          objective:  editedProject.Objective,
          approach: editedProject.Approach,
          shortDesc: editedProject.shortDesc,
          WebRelease: editedProject.WebRelease,
          email: editedProject.email,
          completionDate: formattedDateForBackend(editedProject.Completion_Date)
        })
      };
        fetch(`${primaryAPILink}/api/projects/updateProject`, requestOptions)
    }

    const formattedDate = isoDate => {
        if (!isoDate) return ''; // Handle empty or null values
    
        const date = new Date(isoDate);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
    
        return `${year}-${month}-${day}`;
    };



    return (
        <div className="project__modal">
            <div className="modal-content">
                <h2>Edit Project: {project.Project_Title}</h2>

                <div className="input-group">
                    <div className="label-input-pair">
                        <label>Project Title</label>
                        <input
                            type="text"
                            value={editedProject.Project_Title}
                            onChange={e => handleFieldChange('Project_Title', e.target.value)}
                        />
                    </div>

                    <div className="label-input-pair">
                        <label>Status</label>
                        <select
                            value={editedProject.Status}
                            onChange={e => handleFieldChange('Status', e.target.value)}
                        >
                            <option value="Active">Active</option>
                            <option value="Complete">Complete</option>
                        </select>
                    </div>
                </div>

                <div className="input-group">
                <div className="label-input-pair">
    <label>Start Date</label>
    <input
                        type="date"
                        value={formattedDate(editedProject.Date_Start) || ''}
                        onChange={e => handleFieldChange('Date_Start', e.target.value)}
                        pattern="\d{4}-\d{2}-\d{2}"
                        placeholder="YYYY-MM-DD"
                    />
</div>

<div className="label-input-pair">
    <label>End Date</label>
    <input
                        type="date"
                        value={formattedDate(editedProject.Date_End) || ''}
                        onChange={e => handleFieldChange('Date_End', e.target.value)}
                        pattern="\d{4}-\d{2}-\d{2}"
                        placeholder="YYYY-MM-DD"
                    />
</div>

                </div>

                <div className="input-group">
                    <div className="label-input-pair">
                        <label>Project Director First Name</label>
                        <input
                            type="text"
                            value={editedProject.fName}
                            onChange={e => handleFieldChange('fName', e.target.value)}
                        />
        
                    </div>

                    <div className="label-input-pair">
                        <label>Project Director Last Name</label>
                        <input
                            type="text"
                            value={editedProject.lName}
                            onChange={e => handleFieldChange('lName', e.target.value)}
                        />
        
                    </div>

                    <div className="label-input-pair">
                        <label>Funding Source</label>
                        <input
                            type="text"
                            value={editedProject.fundingSource}
                            onChange={e => handleFieldChange('fundingSource', e.target.value)}
                        />
                    </div>

                    <div className="label-input-pair">
                <label>Web Release</label>
                <select
                            value={editedProject.WebRelease}
                            onChange={e => handleFieldChange('WebRelease', e.target.value)}
                        >
                            <option value="0">Private</option>
                            <option value="1">Public</option>
                        </select>
            </div>
                </div>
                <div className="label-input-pair">
                    <label>Issue</label>
                    <textarea
                    className='expanding-textarea'
                        value={editedProject.Issue}
                        onChange={e => handleFieldChange('Issue', e.target.value)}
                    />
                </div>
                <div className="label-input-pair">
                    <label>Objective</label>
                    <textarea
                    className='expanding-textarea'
                        value={editedProject.Objective}
                        onChange={e => handleFieldChange('Objective', e.target.value)}
                    />
                </div>
                <div className="label-input-pair">
                    <label>Approach</label>
                    <textarea
                    className='expanding-textarea'
                        value={editedProject.Approach}
                        onChange={e => handleFieldChange('Approach', e.target.value)}
                    />
                </div>
 

            <div className="label-input-pair">
                <label>Short Description</label>
                <textarea
                    type="text"
                    value={editedProject.shortDesc}
                    onChange={e => handleFieldChange('shortDesc', e.target.value)}
                />
            </div>
            <div className="label-input-pair">
                <label>Email</label>
                <input
                    type="text"
                    value={editedProject.email}
                    onChange={e => handleFieldChange('email', e.target.value)}
                />
            </div>


            <button onClick={handleSave}>Save</button>
            <button onClick={onClose} >Cancel</button>
            <button onClick={onClose} className="modal__close__top__right">X</button>
        </div>
        </div >
    );
};

export default ProjectModal;
