import React from 'react'
import './NewsEditor.css'
import { useEffect, useState } from 'react'
import NewsEditContent from '../NewsEditContent/NewsEditContent'
import {primaryAPILink} from '../../../../config/routes.js'


const NewsEditor = (props) => {
    console.log(primaryAPILink)
    const [ourNewsLinks, setOurNewsLinks] = useState([])
    const [ourContent, setOurContent] = useState([])
    const [drawNewsContent, setDrawNewsContent] = useState(<></>)
    useEffect(() => {
        fetch(`${primaryAPILink}/api/news/alltrue`, {headers: {jwtKey: localStorage.getItem("loginKey")}})
            .then(res => res.json())
            .then(
                (result) => {
                let ourLinksTemp = [];
                //find all news links
                result.forEach(item => {
                    ourLinksTemp.push(item.newsStoryLink);   
                })
            setOurNewsLinks(ourLinksTemp);
         })
    }, [])

    useEffect(()=>{
        if(props.currentPage !== "") {
            loadArticleContent(props.currentPage);
        }
    },[])

    function loadArticleContent(link) {
        fetch(`${primaryAPILink}/api/news/specificstory/${link}`, {headers: {jwtKey: localStorage.getItem("loginKey")}})
            .then(res => res.json())
            .then(
                (result) => {
                    
                setOurContent(result)
                setDrawNewsContent(<NewsEditContent data={result}/>)
        })

    }

    function createNewNewsPage(){
        const userLink = prompt("Please enter a custom link for the news article");
        console.log(userLink)
        var letters = /^[0-9a-zA-Z-]+$/;
        if(letters.test(userLink)){
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' , jwtKey: localStorage.getItem("loginKey")},
                body: JSON.stringify({
                newsStoryLink: userLink})
            };
            fetch(`${primaryAPILink}/api/news/newPage`, requestOptions)
            window.location.reload(false);
        } else {
            alert("Please only use letters and numbers")
        }
    }


    return (
        <div className='news__editor__wrapper'>
            <div className='news__editor__header'>
                <div className='news__editor__header__option'>
                    <label>Article Link:</label>
                    <select onChange={(e)=>{
                        loadArticleContent(e.target.value)
                    }}>
                        {ourNewsLinks.map((link, index)=>{
                            return(<option key={index} value={link}>{link}</option>)
                        })}
                         <option value="" defaultValue hidden>Choose here</option>
                    </select>
                </div>
                <div className='news__editor__header__option'>
                    <button className="news__editor__white__button" onClick={()=>createNewNewsPage()}>Create New Story</button>
                </div>
            </div>
            {drawNewsContent}
            

      
        </div>
    )
}

export default NewsEditor