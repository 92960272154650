import React, { useState, useEffect } from 'react';
import { primaryAPILink } from '../../../../../config/routes.js';
import _ from 'lodash';

const UserPermissionTableRow = (props) => {
    const [info, setInfo] = useState(props.user);
    const [pageLoaded, setPageLoaded] = useState(false);
    const [popupMessage, setPopupMessage] = useState(null);

    useEffect(() => {
        if (popupMessage) {
            const timer = setTimeout(() => {
                setPopupMessage(null);
            }, 1500); // Popup disappears after 1 second
            return () => clearTimeout(timer);
        }
    }, [popupMessage]);

    useEffect(() => {
        if (pageLoaded) {
            updateStaff();
        } else {
            setPageLoaded(true);
        }
    }, [info]);

    function updateStaff() {
        console.log(info)
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', jwtKey: localStorage.getItem("loginKey") },
            body: JSON.stringify(info)
        };

        fetch(`${primaryAPILink}/api/permissions/staffPermissionsUpdate`, requestOptions)
            .then(response => {
                if (response.ok) {
                    setPopupMessage("✔️ Change attempted successfully");
                } else {
                    throw new Error('Failed to update staff permissions');
                }
            })
            .catch(error => {
                console.error('Error updating staff permissions:', error);
                setPopupMessage("Failed to update staff permissions. Please try again later.");
            });
    }

    function removeStaff() {
        if (window.confirm(`Are you sure you wish to remove ${info.fName} ${info.lName} from staff user group?`)) {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', jwtKey: localStorage.getItem("loginKey") },
                body: JSON.stringify({
                    email: info.email,
                })
            };
            fetch(`${primaryAPILink}/api/permissions/removeStaff`, requestOptions)
            window.location.reload();
        }
    }

    return (
        <>
            <tr>
                {/* First Name */}
                <td>
                    <input
                        type="text"
                        value={info.fName}
                        onChange={(event) => setInfo({ ...info, fName: event.target.value })}
                    />
                </td>

                {/* Last Name */}
                <td>
                    <input
                        type="text"
                        value={info.lName}
                        onChange={(event) => setInfo({ ...info, lName: event.target.value })}
                    />
                </td>

                {/* Email */}
                <td>
                    <input
                        type="text"
                        value={info.email}
                        onChange={(event) => setInfo({ ...info, email: event.target.value })}
                    />
                </td>

                {/* Phone */}
                <td>
                    <input
                        type="text"
                        value={info.phone}
                        onChange={(event) => setInfo({ ...info, phone: event.target.value })}
                    />
                </td>

                {/* Title */}
                <td>
                    <input
                        type="text"
                        value={info.title}
                        onChange={(event) => setInfo({ ...info, title: event.target.value })}
                    />
                </td>

                {/* Category */}
                <td>
                    <select
                        value={info.category}
                        onChange={(event) => setInfo({ ...info, category: event.target.value })}
                    >
                        <option value="admin">Admin</option>
                        <option value="business">Business</option>
                        <option value="info">Info Services</option>
                        <option value="research">Research</option>
                        <option value="volunteer">Volunteer</option>
                    </select>
                </td>

                {/* Picture */}
                <td>
                    <input
                        type="text"
                        value={info.picture}
                        onChange={(event) => setInfo({ ...info, picture: event.target.value })}
                    />
                </td>

                {/* Active */}
                <td>
                    <input
                        type="checkbox"
                        checked={info.active === 1}
                        onChange={(event) => setInfo({ ...info, active: event.target.checked ? 1 : 0 })}
                    />
                </td>

                {/* Admin */}
                <td>
                    <input
                        type="checkbox"
                        checked={info.isAdmin === 1}
                        onChange={(event) => setInfo({ ...info, isAdmin: event.target.checked ? 1 : 0 })}
                    />
                </td>

                {/* Alerts */}
                <td>
                    <input
                        type="checkbox"
                        checked={info.editAlert === 1}
                        onChange={(event) => setInfo({ ...info, editAlert: event.target.checked ? 1 : 0 })}
                    />
                </td>

                {/* News */}
                <td>
                    <input
                        type="checkbox"
                        checked={info.editNews === 1}
                        onChange={(event) => setInfo({ ...info, editNews: event.target.checked ? 1 : 0 })}
                    />
                </td>

                {/* Jobs */}
                <td>
                    <input
                        type="checkbox"
                        checked={info.editJobs === 1}
                        onChange={(event) => setInfo({ ...info, editJobs: event.target.checked ? 1 : 0 })}
                    />
                </td>

                {/* CMS */}
                <td>
                    <input
                        type="checkbox"
                        checked={info.editCMS === 1}
                        onChange={(event) => setInfo({ ...info, editCMS: event.target.checked ? 1 : 0 })}
                    />
                </td>

                {/* IGNIS */}
                <td>
                    <input
                        type="checkbox"
                        checked={info.editIGNIS === 1}
                        onChange={(event) => setInfo({ ...info, editIGNIS: event.target.checked ? 1 : 0 })}
                    />
                </td>

                {/* Remove Button */}
                <td>
                    <button className='remove__staff__admin' onClick={removeStaff}>X</button>
                </td>

                {/* Popup Message */}
                {popupMessage && <div className="popupAdminPermissions">{popupMessage}</div>}
            </tr>
        </>
    );
};

export default UserPermissionTableRow;
