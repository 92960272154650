import React from 'react'
import {useState, useEffect} from 'react'
import {Link, useParams, useNavigate, useLocation} from 'react-router-dom';
import LatestNewsHome from '../../../components/LatestNewsHome/LatestNewsHome';
import './NewsStory.css'
import NewsLeafletMap from './NewsStoryComponents/NewsLeafletMap/NewsLeafletMap';
import NewsStoryAuthor from './NewsStoryComponents/NewsStoryAuthor/NewsStoryAuthor';
import NewsStoryBold from './NewsStoryComponents/NewsStoryBold/NewsStoryBold';
import NewsStoryCategory from './NewsStoryComponents/NewsStoryCategory/NewsStoryCategory';
import NewsStoryDate from './NewsStoryComponents/NewsStoryDate/NewsStoryDate';
import NewsStoryFloatTextImage from './NewsStoryComponents/NewsStoryFloatTextImage/NewsStoryFloatTextImage';
import NewsStoryImage from './NewsStoryComponents/NewsStoryImage/NewsStoryImage';
import NewsStoryParagraph from './NewsStoryComponents/NewsStoryParagraph/NewsStoryParagraph';
import NewsStoryPopupImage from './NewsStoryComponents/NewsStoryPopupImage/NewsStoryPopupImage';
import NewsStorySnippet from './NewsStoryComponents/NewsStorySnippet/NewsStorySnippet';
import NewsStoryTitle from './NewsStoryComponents/NewsStoryTitle/NewsStoryTitle';
import CrimsonBlockHeader from '../../../components/CrimsonBlockHeader/CrimsonBlockHeader';
import NewsStoryVideo from './NewsStoryComponents/NewsStoryVideo/NewsStoryVideo';
import NewsStoryMultiCards from './NewsStoryComponents/NewsStoryMultiCards/NewsStoryMultiCards';
import NewsStoryGallery from './NewsStoryComponents/NewsStoryGallery/NewsStoryGallery';
import CrimsonLinkButton from '../../../components/CrimsonLinkButton/CrimsonLinkButton';
import NewsNavbar from '../NewsComponents/NewsNavbar/NewsNavbar';
import {primaryAPILink} from '../../../config/routes.js'

const NewsStory = (props) => {
    //get link from url
    const params = useParams();
    let ourNewsLink = params.newsLink;

    const location = useLocation();


    //Fetch news story from link
    const [ourTitle, setOurTitle] = useState([]);
    const [ourImage, setOurImage] = useState([]);
    const [ourDatePublished, setOurDatePublished] = useState([]);
    const [ourContent, setOurContent] = useState([]);
    const [ourAuthor, setOurAuthor] = useState([]);
    const [ourCategory, setOurCategory] = useState([]);
    const [ourSnippet, setOurSnippet] = useState([]);

    const navigate = useNavigate();
    const navigateToNews = () => {
      navigate('/news')
    }

    //Gather story information
    useEffect(() => {
        fetch(`${primaryAPILink}/api/news/story/${ourNewsLink}`)
            .then(res => res.json())
            .then((result) => {
              if(result.length === 0) {
                navigateToNews();
              } else {
                setOurTitle(result[0].newsTitle)
                setOurDatePublished(result[0].newsDatePublished)
                setOurImage(result[0].newsImage)
                setOurContent(result[0].newsContent)
                setOurAuthor(result[0].newsAuthor)
                setOurCategory(result[0].newsCategory)
                setOurSnippet(result[0].newsSnippet)
                if(result[0].newsContent !== "") {
                  setOurContent(JSON.parse(result[0].newsContent))
                } else {
                  setOurContent([])
                }

              }
            })}, [location.pathname]
    )


    //Check if logged in user is an editor
    const [editorLoggedIn, setEditorLoggedIn] = useState(false)
    useEffect(() => {
      fetch(`${primaryAPILink}/api/news/checkEditor`, {method: 'POST', headers: {jwtKey: localStorage.getItem("loginKey")}})
          .then(res => res.json())
          .then((result) => {
            setEditorLoggedIn(result[0].trueFalse)
          })}, []
    )


  return (
    <div className='newsStorySection'>
      <CrimsonBlockHeader text="IGWS News" />
      <NewsNavbar />
    <article className='news__story__article__wrapper'>
      <NewsStoryCategory text={ourCategory} />
      <NewsStoryTitle text={ourTitle} />
      <NewsStorySnippet text={ourSnippet} />
      <NewsStoryAuthor text={ourAuthor} />
      <NewsStoryDate text={ourDatePublished} />
      <NewsStoryImage src={ourImage} text={ourTitle} />

      {ourContent.map((item, index) => {
        if(item.type === "bold") {
          return(<NewsStoryBold text={item.text} key={index} />)
        }
        if(item.type === "paragraph") {
          return(<NewsStoryParagraph text={item.text} key={index}/>)
        }
        if(item.type==="map") {
          return(<NewsLeafletMap positionLat={item.lat} positionLong={item.long} message={item.message} key={index}/>)
        }
        if(item.type==="image") {
          return(<NewsStoryPopupImage src={item.link} text={item.text} key={index}/>)
        }
        if(item.type==="float") {
          return(<NewsStoryFloatTextImage leftright={item.leftRight} src={item.link} alt="" text={item.text} key={index}/>)
        }
        if(item.type==="video") {
          return(<NewsStoryVideo videoLink={item.videoLink}/>)
        }
        if(item.type==="cards"){
          return(<NewsStoryMultiCards cardList={item.cardList} />)
        }
        if(item.type==="gallery") {
          return(<NewsStoryGallery label={item.label}  images={item.images}/>)
        }
        if(item.type==="button") {
          return(<CrimsonLinkButton link={item.link} text={item.text} />)
        }
      })}
    
      <Link to="/admin/news-edit" state={{from: ourNewsLink}} className={`editorButton ${editorLoggedIn ? "editor": ""}`}>Edit Page</Link>
    </article>

    
    
    <div className='news__story__line' />
    <LatestNewsHome />
    </div>
  )
}

export default NewsStory