import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import CrimsonBlockHeader from '../../../components/CrimsonBlockHeader/CrimsonBlockHeader'
import AdminNavbar from '../AdminNavbar/AdminNavbar'
import './NewsEdit.css'
import NewsEditor from './NewsEditor/NewsEditor'
import { useLocation } from 'react-router-dom'
import AdminContentExplain from '../AdminContentExplain/AdminContentExplain'

const NewsEdit = () => {
  const [loggedIn, setLoggedIn] = useState(<p>Loading...</p>)
  const location = useLocation()

  //Checks if user is logged in
  useEffect(()=>{
    let today = new Date().getTime()
    // let savedDate = localStorage.endDate
    let savedDate = 100000000000000
    if(savedDate > today){
      //checks if we came from a specific article. If so, loads it.
      if(location.state !== null) {
        const { from } = location.state
        setLoggedIn(<NewsEditor currentPage={from} />)
      } else {
        setLoggedIn(<NewsEditor currentPage="" />)
      }
    } else {
      setLoggedIn(<Link to="/Login">you need to log in</Link>)
    }
  },[])
  return (
    <div className='news__edit__page__wrapper'>
      <AdminNavbar active="news"/>
      <div className='news__edit__page__content__section'>
        <CrimsonBlockHeader text="News Editor" />
        <AdminContentExplain text={["News editor is used to create articles under the /news section of the site", "Click 'Make Featured' to display the article on the main news page"]}></AdminContentExplain>
        {loggedIn}
      </div>
    </div>
  )
}

export default NewsEdit