import React, { useEffect, useState } from 'react'
import './ValentinesDay.css'

const ValentinesDay = () => {

    const colorList = ['#5E081E', '#B51A3A', '#E24767', '#E48397']

    function pickColor(){
        return(colorList[Math.floor(Math.random()*colorList.length)])
    }

    function generateHeart() {
        const heart = {
            size: Math.random() * 30 + 10, // Random size between 10 and 40
            x: Math.random() * 100, // Random horizontal position
            y: Math.random() * 100, // Start from the bottom
            floatSpeed: Math.random() * 4 + 6, // Random floating speed
            timeBetween: Math.random() * 4, //random time between
            color: pickColor(),
            rotation: Math.random() * 10,
            posNeg: Math.round(Math.random()) * 2 - 1,
            blur: Math.random() * 2,

          };

          return(heart)
    }

    const maxHearts = 20;

    const [hearts, setHearts] = useState([]);

    useEffect(()=>{
        let heartsList = [];
        for (let i=1;i<maxHearts ; i++) {
            heartsList.push(generateHeart())
          }
        setHearts(heartsList);
    },[])

    const heartSVG = `

    <svg height="100%" width="100%" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 471.701 471.701" xml:space="preserve"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> 
    <path d="M433.601,67.001c-24.7-24.7-57.4-38.2-92.3-38.2s-67.7,13.6-92.4,38.3l-12.9,12.9l-13.1-13.1 c-24.7-24.7-57.6-38.4-92.5-38.4c-34.8,0-67.6,13.6-92.2,38.2c-24.7,24.7-38.3,57.5-38.2,92.4c0,34.9,13.7,67.6,38.4,92.3 l187.8,187.8c2.6,2.6,6.1,4,9.5,4c3.4,0,6.9-1.3,9.5-3.9l188.2-187.5c24.7-24.7,38.3-57.5,38.3-92.4 C471.801,124.501,458.301,91.701,433.601,67.001z M414.401,232.701l-178.7,178l-178.3-178.3c-19.6-19.6-30.4-45.6-30.4-73.3 s10.7-53.7,30.3-73.2c19.5-19.5,45.5-30.3,73.1-30.3c27.7,0,53.8,10.8,73.4,30.4l22.6,22.6c5.3,5.3,13.8,5.3,19.1,0l22.4-22.4 c19.6-19.6,45.7-30.4,73.3-30.4c27.6,0,53.6,10.8,73.2,30.3c19.6,19.6,30.3,45.6,30.3,73.3 C444.801,187.101,434.001,213.101,414.401,232.701z"></path> </g> </g>

  </filter></svg>

 <svg class="blurred" height="100%" width="100%" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 471.701 471.701" xml:space="preserve"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> 
    <path d="M433.601,67.001c-24.7-24.7-57.4-38.2-92.3-38.2s-67.7,13.6-92.4,38.3l-12.9,12.9l-13.1-13.1 c-24.7-24.7-57.6-38.4-92.5-38.4c-34.8,0-67.6,13.6-92.2,38.2c-24.7,24.7-38.3,57.5-38.2,92.4c0,34.9,13.7,67.6,38.4,92.3 l187.8,187.8c2.6,2.6,6.1,4,9.5,4c3.4,0,6.9-1.3,9.5-3.9l188.2-187.5c24.7-24.7,38.3-57.5,38.3-92.4 C471.801,124.501,458.301,91.701,433.601,67.001z M414.401,232.701l-178.7,178l-178.3-178.3c-19.6-19.6-30.4-45.6-30.4-73.3 s10.7-53.7,30.3-73.2c19.5-19.5,45.5-30.3,73.1-30.3c27.7,0,53.8,10.8,73.4,30.4l22.6,22.6c5.3,5.3,13.8,5.3,19.1,0l22.4-22.4 c19.6-19.6,45.7-30.4,73.3-30.4c27.6,0,53.6,10.8,73.2,30.3c19.6,19.6,30.3,45.6,30.3,73.3 C444.801,187.101,434.001,213.101,414.401,232.701z"></path> </g> </g>

  </filter></svg>`


  return (
    <div className='valentinesWrapper'>
        {hearts.map((heart, index)=>{
            return(<div className='heart-floating' key={index} 
            style={{
                width: `${heart.size}px`, 
                height: `${heart.size}px`,
                left: `${heart.x}%`,
                top: `${heart.y}%`,
                position: 'absolute',
                animation: `animteFloatingHeart linear ${heart.floatSpeed}s infinite`,
                animationDelay: `${heart.timeBetween}s`,
                opacity: 0,
                fill: heart.color,
                transform: `rotate(${heart.posNeg}${heart.rotation}deg)`,
                filter: `blur(${heart.blur}px)`,
            
            
            
            }}
            
            dangerouslySetInnerHTML={{ __html: heartSVG }} 
            >

            </div>)
        })}

    </div>
  )
}

export default ValentinesDay