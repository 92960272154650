import React from 'react'
import CrimsonBlockHeader from '../../../components/CrimsonBlockHeader/CrimsonBlockHeader'
import AdminNavbar from '../AdminNavbar/AdminNavbar'
import './AdminStaffProfileEditor.css'
import StaffProfileEditor from './StaffProfileEditor/StaffProfileEditor'
import AdminContentExplain from '../AdminContentExplain/AdminContentExplain'

const AdminStaffProfileEditor = () => {
  return (
    <div className='news__edit__page__wrapper'>
        <AdminNavbar active="staff"/>
        <div className='news__edit__page__content__section'>
            <CrimsonBlockHeader text="Staff Profile Editor" />
            <AdminContentExplain text={["Staff Profiles display under the staff section of the website", "You can click the button on the top right to change your banner.", "Profile pictures, titles, emails, and phone numbers are set by admins, if you would like to change, submit a service request.", "LinkedIn can be either your full profile link or your username", "Portfolios are for portfolio websites relevant to your work at the IGWS", "To change biography, click in the area below the 'Biography' heading. From there type it out. We recommend keeping it around 1 paragraph.", "Specialties are optional, you may have up to 4.", "Make sure you press 'update page' after making changes"]}></AdminContentExplain>

            <StaffProfileEditor />
        </div>
    </div>
  )
}

export default AdminStaffProfileEditor