import React, { useState } from 'react'
import ContractIcon from '../../../assets/icons/ContractIcon';
import ExpandIcon from '../../../assets/icons/ExpandIcon';
import './FullScreenMapTemplate.css'

function FullScreenMapTemplate(props) {
    const [fullscreen, setFullscreen] = useState(true)
    const [moreInfo, setMoreInfo] = useState(true)
    const [toggleButton, setToggleButton] = useState(<ExpandIcon />)
    function toggleFullscreen() {
        setFullscreen(!fullscreen);
        if (fullscreen === false) {
            setToggleButton(<ExpandIcon />)
        }
        if (fullscreen === true) {
            setToggleButton(<ContractIcon />)
        }
    }
    function toggleMoreInfo() {
        setMoreInfo(!moreInfo);
    }
    return (

        <div className='fullscreen__map__wrapper'>
            <div className={fullscreen ? 'map__template' : 'map__template fullscreen'}>
                <nav className='fullscreen__map__navbar'>
                    <span>IGWS Map Viewer</span>
                    <div className='fullscreen__map__navbar__button__container'>
                        <div className='map__fullscreen__button' onClick={toggleFullscreen}>
                            {toggleButton}
                        </div>
                        <button className='map__fullscreen__button' onClick={toggleMoreInfo}>Learn More</button>
                    </div>
                </nav>
                <div className={moreInfo ? 'map__information' : 'map__information expanded'}>
                    <h2>Information</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deserunt dolore id, voluptate, laboriosam quos aliquam atque ipsam consequatur reiciendis voluptas animi suscipit at tenetur distinctio impedit velit adipisci, veritatis exercitationem!</p>
                    <div className='map__information__line'></div>
                </div>
                <iframe className={fullscreen ? 'map__iframe' : 'map__iframe fullscreen'} src={props.link} />
            </div>
        </div>
    )
}

export default FullScreenMapTemplate