import React from 'react'
import CrimsonBlockHeader from '../../../components/CrimsonBlockHeader/CrimsonBlockHeader'
import AdminNavbar from '../AdminNavbar/AdminNavbar'
import JobRow from './JobRow/JobRow'
import JobsManager from './JobsManager/JobsManager'
import {primaryAPILink} from '../../../config/routes.js'
import {useState, useEffect} from 'react'
import './JobsEditor.css'
import AdminContentExplain from '../AdminContentExplain/AdminContentExplain'


const JobsEditor = () => {
  const [jobList, setJobList] = useState([])
  useEffect(() => {
    fetch(`${primaryAPILink}/api/jobs`)
        .then(res => res.json())
        .then((result) => {
          if(result.length > 0) {
            setJobList(result);
          }
        })}, []
)
  return (
    <div className='news__edit__page__wrapper'>
        <AdminNavbar active="jobs"/>
        <div className='news__edit__page__content__section'>
            <CrimsonBlockHeader text="Jobs Editor" />
             <AdminContentExplain text={["Jobs editor is used to display current available positions at the IGWS", "Jobs are displayed under about/employment"]}></AdminContentExplain>
            <div className='jobs__edit__wrapper'>
              <table className='jobs__edit__table'>
                <tr>
                  <th>Title</th>
                  <th className='middleTable'>Description</th>
                  <th className='lastTable'>Link</th>
                </tr>
              {jobList.map((job, index)=>{
                return(<JobRow key={index} info={job} />)
              })}
              </table>
            </div>
            <JobsManager />
        </div>
    </div>
  )
}

export default JobsEditor