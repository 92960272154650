import React from 'react'
import './NewsStoryMultiCardsCard.css'

const NewsStoryMultiCardsCard = (props) => {
  return (
    <figure className='news__story__multi__cards__card'>
        <img src={props.image} alt={props.text + " graphic"} />
        <figcaption>{props.text}</figcaption>
    </figure>
  )
}

export default NewsStoryMultiCardsCard