import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ReferenceArea, Area, AreaChart } from 'recharts';
import './EclipseChart.css'


const SolarRadiationChart = () => {
    let dataByYear = {
        2018: [
            { hour: 1, average: 0 },
            { hour: 2, average: 0 },
            { hour: 3, average: 0 },
            { hour: 4, average: 0 },
            { hour: 5, average: 0 },
            { hour: 6, average: 0 },
            { hour: 7, average: 0 },
            { hour: 8, average: 0.0866302 },
            { hour: 9, average: 0.6321011 },
            { hour: 10, average: 1.247425 },
            { hour: 11, average: 2.067414 },
            { hour: 12, average: 2.394252 },
            { hour: 13, average: 2.436308 },
            { hour: 14, average: 2.49862 },
            { hour: 15, average: 1.698959 },
            { hour: 16, average: 1.760444 },
            { hour: 17, average: 1.398414 },
            { hour: 18, average: 0.8804875 },
            { hour: 19, average: 0.3217788 },
            { hour: 20, average: 0.09014157 },
            { hour: 21, average: 0.003800283 },
            { hour: 22, average: 0 },
            { hour: 23, average: 0 },
        ],
        2019: [
            { hour: 1, average: 0 },
            { hour: 2, average: 0 },
            { hour: 3, average: 0 },
            { hour: 4, average: 0 },
            { hour: 5, average: 0 },
            { hour: 6, average: 0 },
            { hour: 7, average: 0.01783389 },
            { hour: 8, average: 0.1251149 },
            { hour: 9, average: 0.2579849 },
            { hour: 10, average: 0.6961409 },
            { hour: 11, average: 0.8471646 },
            { hour: 12, average: 1.333437 },
            { hour: 13, average: 1.875144 },
            { hour: 14, average: 1.880872 },
            { hour: 15, average: 2.003726 },
            { hour: 16, average: 2.061813 },
            { hour: 17, average: 0.981242 },
            { hour: 18, average: 0.773876 },
            { hour: 19, average: 0.2585915 },
            { hour: 20, average: 0.01883646 },
            { hour: 21, average: 0. },
            { hour: 22, average: 0 },
            { hour: 23, average: 0 },
        ],
        2021: [
            { hour: 1, average: 0 },
            { hour: 2, average: 0 },
            { hour: 3, average: 0 },
            { hour: 4, average: 0 },
            { hour: 5, average: 0 },
            { hour: 6, average: 0 },
            { hour: 7, average: 0 },
            { hour: 8, average: 0.033 },
            { hour: 9, average: 0.176 },
            { hour: 10, average:0.601 },
            { hour: 11, average: 0.218 },
            { hour: 12, average: 0.703 },
            { hour: 13, average: 1.052 },
            { hour: 14, average: 1.162 },
            { hour: 15, average: 1.171 },
            { hour: 16, average: 0.846 },
            { hour: 17, average: 1.285 },
            { hour: 18, average: 1.164 },
            { hour: 19, average: 0.248 },
            { hour: 20, average: 0.123 },
            { hour: 21, average: 0.004 },
            { hour: 22, average: 0 },
            { hour: 23, average: 0 },
 
        ],
        2022: [
            { hour: 1, average: 0 },
            { hour: 2, average: 0 },
            { hour: 3, average: 0 },
            { hour: 4, average: 0 },
            { hour: 5, average: 0 },
            { hour: 6, average: 0 },
            { hour: 7, average: 0 },
            { hour: 8, average: 0.029 },
            { hour: 9, average: 0.302 },
            { hour: 10, average: 0.601 },
            { hour: 11, average: 0.694 },
            { hour: 12, average: 1.138 },
            { hour: 13, average: 1.678 },
            { hour: 14, average: 2.398 },
            { hour: 15, average: 1.447 },
            { hour: 16, average: 2.322 },
            { hour: 17, average: 0.519 },
            { hour: 18, average: 0.831 },
            { hour: 19, average: 0.409 },
            { hour: 20, average: 0.096 },
            { hour: 21, average: 0.004 },
            { hour: 22, average: 0 },
            { hour: 23, average: 0 },
        ],
        2023: [
            { hour: 1, average: 0 },
            { hour: 2, average: 0 },
            { hour: 3, average: 0 },
            { hour: 4, average: 0 },
            { hour: 5, average: 0 },
            { hour: 6, average: 0 },
            { hour: 7, average: 0.05 },
            { hour: 8, average: 0.33 },
            { hour: 9, average: 0.767 },
            { hour: 10, average: 0.572 },
            { hour: 11, average: 0.866 },
            { hour: 12, average: 1.617 },
            { hour: 13, average: 3.125 },
            { hour: 14, average: 3.161 },
            { hour: 15, average: 2.73 },
            { hour: 16, average: 2.497 },
            { hour: 17, average: 1.895 },
            { hour: 18, average: 1.173 },
            { hour: 19, average: 0.445 },
            { hour: 20, average: 0.015 },
            { hour: 21, average: 0 },
            { hour: 22, average: 0 },
            { hour: 23, average: 0 },
        ],
        min: [
            { hour: 1, average: 0 },
            { hour: 2, average: 0 },
            { hour: 3, average: 0 },
            { hour: 4, average: 0 },
            { hour: 5, average: 0 },
            { hour: 6, average: 0 },
            { hour: 7, average: 0 },
            { hour: 8, average: 0.029 },
            { hour: 9, average: 0.176 },
            { hour: 10, average: 0.309 },
            { hour: 11, average: 0.218 },
            { hour: 12, average: 0.703 },
            { hour: 13, average: 1.052 },
            { hour: 14, average: 1.162 },
            { hour: 15, average: 1.171 },
            { hour: 16, average: 0.846 },
            { hour: 17, average: 0.519 },
            { hour: 18, average: 0.773876 },
            { hour: 19, average: 0.248 },
            { hour: 20, average: 0.015 },
            { hour: 21, average: 0 },
            { hour: 22, average: 0 },
            { hour: 23, average: 0 },
        ],
        max: [
            { hour: 1, average: 0 },
            { hour: 2, average: 0 },
            { hour: 3, average: 0 },
            { hour: 4, average: 0 },
            { hour: 5, average: 0 },
            { hour: 6, average: 0 },
            { hour: 7, average: 0.05 },
            { hour: 8, average: 0.33 },
            { hour: 9, average: 0.767 },
            { hour: 10, average: 1.247425 },
            { hour: 11, average: 2.067414 },
            { hour: 12, average: 2.394252 },
            { hour: 13, average: 3.125 },
            { hour: 14, average: 3.161 },
            { hour: 15, average: 2.73 },
            { hour: 16, average: 2.497 },
            { hour: 17, average: 1.895 },
            { hour: 18, average: 1.173 },
            { hour: 19, average: 0.445 },
            { hour: 20, average: 0.123 },
            { hour: 21, average:0.004 },
            { hour: 22, average: 0 },
            { hour: 23, average: 0 },
        ],
        projected: [
            { hour: 1, average: 0 },
            { hour: 2, average: 0 },
            { hour: 3, average: 0 },
            { hour: 4, average: 0 },
            { hour: 5, average: 0 },
            { hour: 6, average: 0 },
            { hour: 7, average: 0 },
            { hour: 8, average: 0.206421178 },
            { hour: 9, average: 0.509677943 },
            { hour: 10, average: 0.90186497 },
            { hour: 11, average: 1.384232889 },
            { hour: 12, average: 1.764249303 },
            { hour: 13, average: 2.339438825 },
            { hour: 14, average: 1.8792 },
            { hour: 15, average: 0.0468 },
            { hour: 16, average: 0.3276 },
            { hour: 17, average: 1.385098827 },
            { hour: 18, average: 0.982940565 },
            { hour: 19, average: 0.356757161 },
            { hour: 20, average: 0.074205715 },
            { hour: 21, average: 0.001844031 },
            { hour: 22, average: 0 },
            { hour: 23, average: 0 },
        ]


    };

    for (let year in dataByYear) {
      // Iterate over each entry for the current year
      for (let i = 0; i < dataByYear[year].length; i++) {
          // Divide the average value by 0.0036
          dataByYear[year][i].average /= 0.0036;
      }
  }



    const allData = Object.keys(dataByYear).reduce((acc, year) => {
        dataByYear[year].forEach(entry => {
            const existingHourIndex = acc.findIndex(item => item.hour === entry.hour);
            if (existingHourIndex !== -1) {
                acc[existingHourIndex][year] = entry.average;
            } else {
                acc.push({
                    hour: entry.hour,
                    [year]: entry.average,
                });
            }
        });
        return acc;
    }, []);

    

    const formatHour = (value) => {
        const hour = ((value - 1) % 12 + 12) % 12 + 1; // Adjusting hour to start from 1
        const period = value <= 12 ? 'AM' : 'PM';
        return `${hour}:00 ${period}`;
    };

    const fillData = allData.map(entry => ({
        hour: entry.hour,
        min: entry.min,
        max: entry.max,
        range: [entry.min, entry.max],
      }));
    
      const lineChartHeight = 400;
      const legendHeight = 50;
      const lineColors = ['#6FA6D6', '#D66F6F', '#6FD6A6', '#D6D66F', '#6FD6D6'];
    
      // State to track whether the chart is in view
      const [chartInView, setChartInView] = useState(false);
    
      // Intersection observer hook
      const [ref, inView] = useInView({
        triggerOnce: false,
        rootMargin: '-100px 0px', // Adjust the root margin based on when you want the animation to start
      });
    
      // Set the chart in view when it enters the viewport
      useEffect(() => {
        if (inView) {
          setChartInView(true);
        }
      }, [inView]);


  
    
      return (
        <section className='EclipseChartWrapper'>
          <h3 className='eclipsecenter'>Solar Radiation (W/m^2) on April 8th (Previous Years)</h3>
        <div ref={ref} style={{ position: 'relative', width: '800px',  height: `${lineChartHeight}px` }}className="EclipseChart">
          {/* Line Chart */}

          <LineChart width={800} height={lineChartHeight} data={allData} style={{ top: 0, left: 0, zIndex: 3 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="hour" tickFormatter={formatHour} />
            <YAxis />
            <Tooltip />
            <Legend />
    
            {Object.keys(dataByYear).map((year, index) => (
              <Line
                key={index}
                type="monotone"
                dataKey={year}
                name={`${year}`}
                stroke={chartInView ? lineColors[index % lineColors.length] : 'transparent'}
                strokeDasharray={year === 'projected' ? '5 5' : 'solid'}
                animationDuration={2000}
                animationBegin={1000 * index}
              />
            ))}
          </LineChart>
    
          {/* Area Chart */}
          <AreaChart width={800} className="hidecharteclipse" height={lineChartHeight} data={fillData} style={{ position: 'absolute', top: 0, left: 0, pointerEvents: 'none' }}>
            <YAxis />
            <XAxis dataKey="hour" tickFormatter={formatHour} className='hideChart'/>
            <Legend className='hideChart'/>
            <Area
              type="monotone"
              dataKey="range"
              name="Range"
              stroke={chartInView ? lineColors[0] : 'transparent'}
              fill={chartInView ? lineColors[0] : 'transparent'}
              fillOpacity={0.5}
              animationDuration={2000}
            />
          </AreaChart>
        </div>
        </section>
      );
    };



export default SolarRadiationChart;


