import React from 'react'
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import CrimsonBlockHeader from '../../../../components/CrimsonBlockHeader/CrimsonBlockHeader';
import CrimsonLinkButton from '../../../../components/CrimsonLinkButton/CrimsonLinkButton';
import { primaryAPILink } from '../../../../config/routes';
import NewsNavbar from '../NewsNavbar/NewsNavbar';
import './NewsHeader.css'

const NewsHeader = () => {
    //fetch latest news story here
    const [featuredTitle, setFeaturedTitle] = useState("Loading Story...");
    const [featuredSnippet, setFeaturedSnippet] = useState("Loading Story...");
    const [featuredLink, setFeaturedLink] = useState("Loading Story...");
    const [featuredPicture, setFeaturedPicture] = useState("https://data.igws.indiana.edu/filestore/5/0/6/9/4_ab03c112ee54682/49605scr_491465816ff08e8.jpg?v=1666789768");

    useEffect(() => {
        fetch(`${primaryAPILink}/api/news/getFeaturedPage`)
            .then(res => res.json())
            .then((result) => {
              if(result.length === 0) {
                return
              } else {
                const ourLink = result[0].newsStoryLink;
                console.log(ourLink)
                fetch(`${primaryAPILink}/api/news/story/${ourLink}`)
                .then(res => res.json())
                .then((result) => {
                    console.log(result)
                    if(result.length !==0){
                        setFeaturedTitle(result[0].newsTitle);
                        setFeaturedPicture(result[0].newsImage);
                        setFeaturedSnippet(result[0].newsSnippet);
                        setFeaturedLink(result[0].newsStoryLink);
                    }
                })
            
              }
            })}, []
    )

    return (
        <div>
            <CrimsonBlockHeader text="IGWS News" />
            <NewsNavbar />
            <div className='news__featured__wrapper'>
                <div className='news__featured__article'>
                    <div className='news__featured__text'>
                        <span className='news__featured__article__feature'>Featured Story</span>
                        <h2>{featuredTitle}</h2>
                        <p>{featuredSnippet}</p>
                        <CrimsonLinkButton link={`./news/story/${featuredLink}`} text="Read More" />
                    </div>
                    <div className='news__featured__img__container'>
                        <Link to={`./story/${featuredLink}`}>
                            <img src={featuredPicture} alt={featuredTitle} 
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src="https://data.igws.indiana.edu/filestore/5/0/6/9/4_ab03c112ee54682/49605scr_491465816ff08e8.jpg?v=1666789768";
                            }}/>
                        </Link>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default NewsHeader