import React from 'react'
import { useState, useEffect } from 'react'
import './AdminCMSHead.css'
import {primaryAPILink} from '../../../../config/routes.js'
import AdminCMSLinkEdit from '../AdminCMSLinkEdit/AdminCMSLinkEdit'
import AdminCMSCardEdit from '../AdminCMSCardEdit/AdminCMSCardEdit'
import AdminCMSPage from '../AdminCMSPage/AdminCMSPage'
import AdminCMSCreatePage from '../AdminCMSCreatePage/AdminCMSCreatePage'


const AdminCMSHead = () => {

  const [cmsItems, setCmsItems] = useState([]);
  const searchParams = new URLSearchParams(window.location.search);
  const [cmsParent, setCmsParent] = useState(searchParams.get('parent'));
  const [cmsContent, setCmsContent] = useState(<></>)

  //Function to get different links which we can edit
  function queryCMSParent(input) {
    fetch(`${primaryAPILink}/api/cms/getCMSFromParent/${input}`, 
    {headers: {jwtKey: localStorage.getItem("loginKey")}})
    .then(res => res.json())
    .then(
        (result) => {
          setCmsItems(result);
          setCmsParent(input);
    })
  }

  useEffect(() => {
    // Parse the query string and extract the 'parent' parameter
    const searchParams = new URLSearchParams(window.location.search);
    const parentParam = searchParams.get('parent');
    const linkParam = searchParams.get('link');

    // If 'parent' parameter is present and equals 'about', set the parent category
    if (parentParam) {
      setCmsParent(parentParam);
      queryCMSParent(parentParam);
      if (linkParam) {
        setContent(linkParam);
      }
    }
  }, []);

  //Function which uses the parent and link to gather specific page content.
  function setContent(input){
    setCmsContent(<></>)
    let cmsRoute = `${primaryAPILink}/api/cms/getCMSFromContent/${cmsParent}/${input.replace("/",":")}`
    console.log(cmsRoute)
    fetch(cmsRoute, 
    {headers: {jwtKey: localStorage.getItem("loginKey")}})
    .then(res => res.json())
    .then(
        (result) => {
          if(result[0].cmsType === "link") {
            setCmsContent(<AdminCMSLinkEdit content={result[0]}/>)
          }
          if(result[0].cmsType === "cards") {
            setCmsContent(<AdminCMSCardEdit content={result[0]}/>)
          }
          if(result[0].cmsType === "cmsPage") {
            setCmsContent(<AdminCMSPage content={result[0]}/>)
          }
    })
  }

  return (
    <>
    <div className='admin__cms__head'>

      <div className='admin__cms__head__input__wrapper'>
        <label htmlFor="parent__category__cms">Parent Category:</label>
        <select id="parent__category__cms" onChange={(e)=>{queryCMSParent(e.target.value)}}  value={cmsParent}>
          <option value="" defaultValue hidden>Choose here</option>
          <option value="about">About</option>
          <option value="maps">Maps</option>
          <option value="collections">Collections</option>
          <option value="outreach">Outreach</option>
          <option value="research">Research</option>
          <option value="services">Services</option>
          <option value="other">Other</option>
        </select>
      </div>

      <div className='admin__cms__head__input__wrapper'>
        <label htmlFor="parent__link__cms">Page Link:</label>
          <select id="parent__link__cms" onChange={(e)=>{setContent(e.target.value)}}>
            <option value="" defaultValue hidden>Choose here</option>
            {cmsItems.map((cmsItem, index)=>{
              return(<option key={index} className={cmsItem.cmsType} value={cmsItem.cmsLink}>{cmsItem.cmsLink}</option>)
            })}
          </select>
      </div>
      <AdminCMSCreatePage />
    </div>
    {cmsContent}
    </>
  )
}

export default AdminCMSHead