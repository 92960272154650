import React from 'react'
import './StaffProfileTitle.css'

const StaffProfileTitle = (props) => {
  return (
    <div className='staff__profile__title__wrapper'>
        <p>{props.title}</p>
    </div>
  )
}

export default StaffProfileTitle