export const isDecember = () => {
    const currentDate = new Date();
    return currentDate.getMonth() === 11;
};

export const isValentinesDay = () => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1; // Months are zero-based, so we add 1
    const currentDay = currentDate.getDate();

    return currentMonth === 2 && currentDay === 14;
};

export const hasSeenPopup = () => {
    return localStorage.getItem('hasSeenPopup');
};

export const getShowSnowPreference = () => {
    const storedShowSnowPreference = localStorage.getItem('showSnowPreference');
    return storedShowSnowPreference !== null ? JSON.parse(storedShowSnowPreference) : null;
};

export const setSnowPreference = (preference) => {
    localStorage.setItem('showSnowPreference', JSON.stringify(preference));
};

export const setHasSeenPopup = () => {
    localStorage.setItem('hasSeenPopup', 'true');
};
