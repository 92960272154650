import React from 'react'
import {primaryAPILink} from '../../../../../config/routes.js'
import { useEffect, useState } from 'react'
import './AddJob.css'

const AddJob = () => {
    const [description, setDescription] = useState("")
    const [link, setLink] = useState("")
    const [title, setTitle] = useState("")

    function addJob(){
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', jwtKey: localStorage.getItem("loginKey") },
        body: JSON.stringify({
            title: title,
            description: description,
            link: link,
            })
    };
    fetch(`${primaryAPILink}/api/jobs/addJob`, requestOptions)
    window.location.reload();
    }

    return (
        <div className='AddJobWrapper'>

            <div className='jobs__items__wrapper'>
                <h2>Add New Job</h2>
                <div className='job__item__wrapper'>
                    <label>Title</label>
                    <input value={title} onInput={(event)=>{
                        setTitle(event.target.value)
                    }} placeholder="Enter Job Title"></input>
                </div>

                <div className='job__item__wrapper'>
                    <label>Job Description</label>
                    <textarea value={description} onInput={(event)=>{
                        setDescription(event.target.value)
                    }} placeholder="Enter Job Description"></textarea>
                </div>

                <div className='job__item__wrapper'>
                    <label>External Link</label>
                    <input value={link} onInput={(event)=>{
                        setLink(event.target.value)
                    }} placeholder="Enter Job External Link"></input>
                </div>

                <button onClick={()=>{
                    addJob()
                }}>Add Job</button>
            </div>
            
        </div>

    )
}

export default AddJob