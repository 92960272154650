import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const CMSLink = (props) => {
  const navigate = useNavigate();

  useEffect(() => {
    const ourLink = JSON.parse(props.content)[0].link;

    if (ourLink.includes(':')) {
      // External link
      // Attempt to open the external link in a new tab
      const newTab = window.open(ourLink, '_blank');

      // Check if the new tab was successfully opened
      if (newTab) {
        // Close the new tab to prevent clutter (optional)
        // newTab.close();
      } else {
        // Opening in a new tab was blocked, fallback to navigation
        window.location.href = ourLink;
      }
    } else {
      // Internal link
      navigate(ourLink);
    }
  }, [props.content, navigate]);

  return (
    <div>
      <h1>Redirecting...</h1>
    </div>
  );
};

export default CMSLink;
