import React from 'react'
import NewsHeader from './NewsComponents/NewsHeader/NewsHeader'
import LatestNewsHome from '../../components/LatestNewsHome/LatestNewsHome'

const News = () => {
  document.title = 'IGWS | News';
  return (
    <div>
        <NewsHeader />
        <LatestNewsHome />
    </div>
  )
}

export default News