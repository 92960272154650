export function convertToDate(date) {
    if(typeof date === 'string'){

    let newDate = date.replace(/[-]/g, '/').split("T")[0];

    newDate = Date.parse(newDate);
    let jsDate = new Date(newDate).toDateString();
    return(jsDate.toString())
    }
}

