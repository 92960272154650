import React from 'react';
import './CrimsonLinkButton.css';
import { primaryLink } from '../../config/routes.js';
import { useState, useEffect } from 'react';

const CrimsonLinkButton = (props) => {
  const [target, setTarget] = useState("_self");

  useEffect(() => {
    if (props.link.includes(":")) {
      setTarget("_blank");
    }

    //I added the / because we may go to data.igws.indiana.edu for resource space and in that case it should open in a new tab. A regular self link looks like https://igws.indiana.edu (https://${primaryLink}) and in that cas eit would still work. 
    if (props.link.includes(`/${primaryLink}`)) {
      setTarget("_self");
    }
  }, [props.link]); 

  return (
    <a className="crimson__link__button" target={target} href={props.link}>{props.text}</a>
  );
}

export default CrimsonLinkButton;
