import React from 'react'
import { Link } from 'react-router-dom'
import './StaffCard.css'

function StaffCard(props) {


  return (
    <Link to={props.link} className='staff__open__card__wrapper'>
    <div className='staff__card' style={{animationDelay: `${0.1 * props.delay}s`}}>
        <div className='staff__card__heading'>
            <img src={props.image} alt={props.fName + "Profile Pic"} onError={({currentTarget})=>{
          currentTarget.src='https://data.igws.indiana.edu/filestore/5/0/6/9/4_ab03c112ee54682/49605scr_491465816ff08e8.jpg?v=1666789768'
        }
          }></img>
            <span className='staff__card__heading__name'>{props.fName} {props.lName}</span>
        </div>
        <span className='staff__card__title'>{props.role}</span>
    </div>
    </Link>
  )
}

export default StaffCard